import { Attr, BelongsTo, Model } from 'spraypaint';
import { ApplicationRecord } from './ApplicationRecord';
import { Administrator } from './Administrator';
import { DataUpload } from './Models';

@Model()
export class DataUploadError extends ApplicationRecord {
  public static jsonapiType = 'data_upload_errors';
  @Attr() public dataUploadId: string;
  @Attr() public errorMessage: string;
  @Attr() public resolved: boolean;
  @Attr() public resolvedAt: string;
  @BelongsTo() public resolver: Administrator;
  @BelongsTo() public dataUpload: DataUpload;
}
