import * as React from 'react';

const footerLogo = require('../../../../../../images/logos/logo.svg').default;

export default function monitoringFooter({ index }) {
  return (
    <footer className="pdf-footer pdf-footer--blue pdf-footer--fixed">
      <table className="pdf-hidden-table">
        <tbody>
          <tr>
            <td style={{ width: '25%' }}>
              <img src={footerLogo} alt="" className="pdf-footer__logo" />
            </td>
            <td style={{ width: '45%' }}>
              <div className="pdf-footer__small-print text-white">Enhance Investment Reporting Limited</div>
              <div className="pdf-footer__small-print text-white">
                3rd Floor, 29 - 31 Esplanade, St Helier, Jersey JE2 3QA
              </div>
            </td>
            <td>
              <div className="pdf-footer__small-print text-white">www.enhance.group</div>
              <div className="pdf-footer__small-print text-white">+44 (0) 1534 761500</div>
            </td>
            <td className="pdf-footer__page-number">{index + 1}</td>
          </tr>
        </tbody>
      </table>
    </footer>
  );
}
