import * as React from 'react';

const beforeAction = Symbol('before');
const afterAction = Symbol('after');

type TInjectedFunction<
  TOriginalFunction extends (...args: TArgs) => TOFunctionReturn,
  TArgs extends any[] = any[],
  TOFunctionReturn = ReturnType<TOriginalFunction>
> = (
  action: typeof beforeAction | typeof afterAction,
  args: TArgs, // Parameters<TOriginalFunction>,
  result?: TOFunctionReturn, // TOFunctionReturn,
) => any; // TOFunctionReturn;

type TInjectFunction = <TOriginalFunction extends (...args: any) => any, TObject>(
  func: TInjectedFunction<TOriginalFunction>,
  property: keyof TObject,
  obj: TObject,
) => TObject;

const asyncFunctionInjector: TInjectFunction = (func, property, obj) => {
  if (typeof obj === 'undefined') return;

  const originalFunc = (obj[property] as unknown) as (...args: any) => any;
  const replacementFunction = func.bind(obj);
  const newFunction = async function (...args: any) {
    const newArgs = (await replacementFunction(beforeAction, args)) || args;
    const result = await originalFunc.call(this, ...newArgs);
    return (await replacementFunction(afterAction, args, result)) || result;
  };
  return Object.create(obj as any, {
    [property]: {
      value: newFunction,
    },
  });
};

const useAsyncFunctionInjector: TInjectFunction = (func, property, obj) => {
  const [state, setState] = React.useState(asyncFunctionInjector(func, property, obj));

  React.useEffect(() => {
    setState(asyncFunctionInjector(func, property, obj));
  }, [func, property, obj]);

  return state;
};

export {
  useAsyncFunctionInjector as default,
  useAsyncFunctionInjector,
  asyncFunctionInjector,
  beforeAction,
  afterAction,
};
