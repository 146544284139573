import * as React from 'react';

import { format } from 'date-fns';
import axios from 'axios';
import Slider from 'react-slick';
import Loading from 'components/shared/Loading';
import { SubtitleOne, SubtitleTwo } from 'components/shared/Typography';
var parser = require('fast-xml-parser');
import lodashTruncate from 'lodash.truncate';

import useLocalStorage from 'components/shared/customHooks/useLocalStorage';

export default function enhanceBlog() {
  const [parsedResponse, setParsedResponse] = React.useState();
  const { storedValue: isBlogVisible, setItem: setVisibility } = useLocalStorage('portal:blogPosts', 'true');

  function NextArrow(props) {
    const { styles, onClick } = props;

    return (
      <div
        className="icon-arrow-right icon-clickable icon-1-5x"
        style={{ ...styles, right: '-8px' }}
        onClick={onClick}
      ></div>
    );
  }

  function PrevArrow(props) {
    const { styles, onClick } = props;

    return (
      <div
        className="icon-arrow-left icon-clickable icon-1-5x"
        style={{ ...styles, left: '-25px' }}
        onClick={onClick}
      ></div>
    );
  }

  const arrowStyles = {
    position: 'absolute',
    top: '45%',
    display: 'block',
  };

  const settings = {
    autoplay: true,
    autoplaySpeed: 7000,
    dots: false,
    dotsClass: 'slick-dots slick-dot-custom',
    infinite: true,
    nextArrow: <NextArrow styles={arrowStyles} />,
    prevArrow: <PrevArrow styles={arrowStyles} />,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToScroll: 2,
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1150,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 720,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 1,
        },
      },
    ],
    slidesToScroll: 3,
    slidesToShow: 5,
    speed: 500,
  };

  React.useEffect(() => {
    getBlogPosts();
  }, []);

  async function getBlogPosts() {
    axios
      .get('https://blog.enhance.group/blog/rss.xml', { headers: { 'Content-Type': 'application/xml' } })
      .then(({ data }) => {
        setParsedResponse(parser.parse(data));
      });
  }

  function getImgSrc(contentString) {
    const regex = /src\s*=\s*"(.+?)"/;
    const matches = contentString.match(regex);
    if (matches.length > 0) {
      return matches[0].replace('src=', '').replaceAll('"', '');
    }
    return null;
  }

  function formattedBlogPosts() {
    if (!parsedResponse) {
      return <Loading />;
    }
    const items = parsedResponse.rss.channel.item;

    const sliderItems = items
      .filter((item) => item.title)
      .map((post, index) => {
        const backgroundImageSrc = getImgSrc(post['content:encoded']);

        const backgroundStyles = {
          backgroundColor: '#333',
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${backgroundImageSrc})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        };

        return (
          <div key={index} style={{ width: '250px', display: 'inline-block' }}>
            <div className="card card--slightly-rounded mar-r-2">
              <div
                className="platform-content"
                style={{ height: '100px', paddingTop: '10px', paddingLeft: '10px', paddingRight: '10px' }}
              >
                <div
                  className="frow frow--nowrap frow--direction-column frow--justify-between"
                  style={{ height: '100%' }}
                >
                  <div className="frow frow--direction-row frow--justify-between frow--items-start">
                    <div style={{ width: '80%' }}>
                      <SubtitleOne
                        text={lodashTruncate(post.title, { length: 50 })}
                        additionalClasses="text-white text-weight-500"
                      />
                    </div>
                    <a href={post.link} target="__blank" className="frow frow--centered-column">
                      <div className="icon-arrow-right-top icon-clickable icon-0-9x rt-tr-clickable-icon"></div>
                    </a>
                  </div>
                  <div>
                    <SubtitleTwo
                      text={`Published: ${format(post.pubDate, 'DD MMM YYYY')}`}
                      additionalClasses="text-grey-40 text-weight-500"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });

    return <Slider {...settings}>{sliderItems}</Slider>;
  }

  function handleToggleVisibility() {
    setVisibility(isBlogVisible === 'true' ? 'false' : 'true');
  }

  const styles: React.CSSProperties = {
    backgroundColor: '#191919',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderTop: '1px solid #777881',
    bottom: isBlogVisible === 'true' ? 0 : '-130px',
    left: 0,
    padding: '15px 30px 15px',
    position: 'fixed',
    right: 0,
  };

  return (
    <div style={styles}>
      <div className="frow frow--justify-between">
        <div>
          <SubtitleOne text="Latest from the Enhance Blog" additionalClasses="text-white mar-b-2 text-weight-500" />
        </div>
        <div style={{ marginRight: '20px' }}>
          <i
            className="icon-toggle-visibility-invisible icon-fw icon-1-7x icon-clickable"
            onClick={handleToggleVisibility}
          />
        </div>
      </div>
      {formattedBlogPosts()}
    </div>
  );
}
