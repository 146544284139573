import * as React from 'react';

import AutoResizeTextArea from 'components/shared/forms/AutoResizeTextArea';
import Button from 'components/shared/forms/Button';

interface IProps {
  handleFormSubmit(comment: string): void;
}

export default function form(props: IProps) {
  const [comment, setComment] = React.useState('');

  function handleChange(value) {
    setComment(value);
  }

  function handleSubmit() {
    if (!comment) return;

    setComment('');
    props.handleFormSubmit(comment);
  }

  return (
    <div className="modal__content">
      <div className="form form--light">
        <div className="frow frow--gutters">
          <div className="col-mc-1-1">
            <label htmlFor="rejection_reason" className="form__label">
              Comment
            </label>
            <AutoResizeTextArea
              name="comment"
              id="comment"
              value={comment}
              rows={10}
              placeholder=""
              onChange={handleChange}
            />
          </div>
          <div className="col-mc-1-1">
            <Button
              buttonClasses="button--compact mar-r-1"
              isDisabled={!comment}
              handleClick={handleSubmit}
              submittingText="Rejecting Review"
              text="Submit"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
