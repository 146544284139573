import React, { useContext } from 'react';
import { useDefaultTheme } from 'components/shared/customHooks/useThemeColours';

import RangeSliderFlag from './Flags/RangeSliderFlag';
import { Frow } from 'components/frow';
import Checkbox from '../Checkbox';
import { InvestmentRestrictionsContext } from './context';
import { Mandate } from 'javascript/models';

interface Props {
  namePrefix: string;
  mandate: Mandate;
  iRPrefix: string;
}

const TacticalAllocations = (props: Props) => {
  const { cashAndFixedIncomeLocked, setCashAndFixedIncomeLocked, investmentRestrictionsSetting } =
    useContext(InvestmentRestrictionsContext);
  const { namePrefix, mandate, iRPrefix } = props;

  const getColour = useDefaultTheme('assetClass');

  const handleCashAndFixedIncomeLocked = () => {
    setCashAndFixedIncomeLocked(!cashAndFixedIncomeLocked);
  };

  return (
    <fieldset className="form__fieldset">
      <h4 className="form__fieldset-label">Asset Allocation Tolerances</h4>
      <Frow className="mar-b-3">
        <Checkbox
          label="Combine Cash and Fixed Income"
          onToggle={handleCashAndFixedIncomeLocked}
          checked={cashAndFixedIncomeLocked}
          classNames="col-md-1-3"
        />
        <Checkbox
          checked={investmentRestrictionsSetting.tacticalAllocationEnabled}
          label="Enabled"
          param={`${iRPrefix}[tactical_allocation_enabled]`}
          classNames="col-md-1-3"
        />
        <Checkbox
          checked={investmentRestrictionsSetting.tacticalAllocationActionPointsEnabled}
          label="Generate action points"
          param={`${iRPrefix}[tactical_allocation_action_points_enabled]`}
          classNames="col-md-1-3"
        />
      </Frow>
      <RangeSliderFlag
        flagType="cash_tactical"
        backgroundColor={getColour('Cash')}
        namePrefix={namePrefix}
        label={cashAndFixedIncomeLocked ? 'Cash & Fixed Income' : 'Cash'}
      />

      {!cashAndFixedIncomeLocked && (
        <RangeSliderFlag
          flagType="fixed_income_tactical"
          backgroundColor={getColour('Fixed Income')}
          namePrefix={namePrefix}
          label="Fixed Income"
        />
      )}

      <RangeSliderFlag
        flagType="equity_tactical"
        backgroundColor={getColour('Equity')}
        namePrefix={namePrefix}
        label="Equity"
      />

      <RangeSliderFlag
        flagType="hedge_fund_tactical"
        backgroundColor={getColour('Hedge Fund')}
        namePrefix={namePrefix}
        label="Hedge Fund"
      />

      <RangeSliderFlag
        flagType="real_estate_tactical"
        backgroundColor={getColour('Real Estate')}
        namePrefix={namePrefix}
        label="Real Estate"
      />

      <RangeSliderFlag
        flagType="commodities_tactical"
        backgroundColor={getColour('Commodities')}
        namePrefix={namePrefix}
        label="Commodities"
      />

      <RangeSliderFlag
        flagType="private_equity_tactical"
        backgroundColor={getColour('Private Equity')}
        namePrefix={namePrefix}
        label="Private Equity"
      />

      <RangeSliderFlag
        flagType="multi_asset_tactical"
        backgroundColor={getColour('Multi Asset')}
        namePrefix={namePrefix}
        label="Multi Asset"
      />

      <RangeSliderFlag
        flagType="other_tactical"
        backgroundColor={getColour('Other')}
        namePrefix={namePrefix}
        label="Other"
      />
    </fieldset>
  );
};

export default TacticalAllocations;
