import * as React from 'react';
import turbolinks from 'turbolinks';

import CustomModal from 'components/shared/CustomModal';
import Button from 'components/shared/forms/Button';

import useVisibility from 'components/shared/customHooks/useVisibility';
import { graphitiErrorString } from 'components/shared/Utils';
import Alert from 'components/shared/Alert';
const routes = require('../../../javascript/application/routes');

import { ReportTemplate } from 'javascript/models';

export default function newModal() {
  const { isOpen, handleOpen, handleClose } = useVisibility(false);

  const [consolidated, setConsolidated] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [component, setComponent] = React.useState(false);
  const [name, setName] = React.useState('');
  const [orientation, setOrientation] = React.useState('portrait');

  function handleConsolidatedChange() {
    setConsolidated(!consolidated);
  }

  function handleChange(event) {
    setName(event.target.value);
  }

  function handleOrientationChange(event) {
    setOrientation(event.target.value);
  }

  function handleComponentChange(event) {
    setComponent(!component);
  }

  function handleSubmit(event) {
    event.preventDefault();
    setIsSubmitting(true);
    handleCreate().then((returnObject) => {
      setIsSubmitting(false);
      if (returnObject.success) {
        turbolinks.visit(
          routes.edit_platform_report_template_path(returnObject.reportTemplate.id, { selected_tab: 'theme' }),
        );
      } else {
        setErrorMessage(graphitiErrorString(returnObject.reportTemplate));
      }
    });
  }

  async function handleCreate() {
    const reportTemplate = new ReportTemplate({ consolidated, name, orientation, component });
    const success = await reportTemplate.save();
    return { success, reportTemplate };
  }

  return (
    <div>
      <button className="button button--highlight button--compact" onClick={handleOpen}>
        New Template
      </button>
      <CustomModal handleClose={handleClose} isOpen={isOpen} title="New Report Template">
        <div className="modal__content">
          <Alert message={errorMessage} modifier="dark" title="Missing Fields" type="error" />
          <form className="form form--light" onSubmit={handleSubmit}>
            <div className="frow frow--gutters-2x">
              <div className="col-sm-1-1">
                <div className="frow frow--gutters">
                  <div className="col-mc-1-1">
                    <label htmlFor="name" className="form__label">
                      Name
                    </label>
                    <input type="text" name="name" id="name" onChange={handleChange} value={name} />
                  </div>
                </div>
              </div>
              <div className="col-sm-1-1">
                <label className="form__label">Orientation</label>
                <div className="pretty pretty--large pretty--with-label p-round">
                  <input
                    type="radio"
                    name="orientation"
                    value="portrait"
                    checked={orientation === 'portrait'}
                    onChange={handleOrientationChange}
                  />
                  <div className="state p-blue-circle">
                    <label className="">Portrait</label>
                  </div>
                </div>
                <div className="pretty pretty--large pretty--with-label p-round">
                  <input
                    type="radio"
                    name="orientation"
                    value="landscape"
                    checked={orientation === 'landscape'}
                    onChange={handleOrientationChange}
                  />
                  <div className="state p-blue-circle">
                    <label className="">Landscape</label>
                  </div>
                </div>
              </div>

              <div className="col-sm-1-1">
                <label className="form__label">Consolidated Report</label>
                <div className="pretty pretty--large pretty--with-label p-round">
                  <input
                    type="radio"
                    name="consolidated"
                    value="false"
                    checked={!consolidated}
                    onChange={handleConsolidatedChange}
                  />
                  <div className="state p-blue-circle">
                    <label className="">No</label>
                  </div>
                </div>
                <div className="pretty pretty--large pretty--with-label p-round">
                  <input
                    type="radio"
                    name="consolidated"
                    value="true"
                    checked={consolidated}
                    onChange={handleConsolidatedChange}
                  />
                  <div className="state p-blue-circle">
                    <label className="">Yes</label>
                  </div>
                </div>
              </div>

              <div className="col-sm-1-1">
                <label className="form__label">Component Report</label>
                <div className="pretty pretty--large pretty--with-label p-round">
                  <input
                    type="radio"
                    name="component"
                    value="false"
                    checked={!component}
                    onChange={handleComponentChange}
                  />
                  <div className="state p-blue-circle">
                    <label className="">No</label>
                  </div>
                </div>
                <div className="pretty pretty--large pretty--with-label p-round">
                  <input
                    type="radio"
                    name="component"
                    value="true"
                    checked={component}
                    onChange={handleComponentChange}
                  />
                  <div className="state p-blue-circle">
                    <label className="">Yes</label>
                  </div>
                </div>
              </div>

              <div className="col-mc-1-1">
                <Button
                  buttonClasses="button--full-width button--light-themed"
                  style={{ minWidth: 'unset' }}
                  text="Create"
                  isSubmitting={isSubmitting}
                  submittingText="Creating"
                />
              </div>
            </div>
          </form>
        </div>
      </CustomModal>
    </div>
  );
}
