import React, { useEffect, useState, useContext } from 'react';
import { Flag } from 'javascript/models';
import FlagsSelector from './FlagsSelector';
import { SecurityClassification } from 'javascript/models';
import { InvestmentRestrictionsContext } from '../context';

type Props = {
  flagType: string;
  classificationType: string;
  title: string;
  baseParam: string;
  showSliders?: boolean;
  showAggregate?: boolean;
};

const Flags = (props: Props) => {
  const { flagType, classificationType, title, baseParam, showSliders = true, showAggregate = true } = props;
  const { investmentRestrictionsSetting, setInvestmentRestrictionsSetting } = useContext(InvestmentRestrictionsContext);
  const { flags } = investmentRestrictionsSetting;
  const flagParam = `${baseParam}[flags_attributes]`;
  const flagTypeAsCamelCase = flagType
    .replace(/_/g, ' ')
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => (index === 0 ? word.toLowerCase() : word.toUpperCase()))
    .replace(/\s+/g, '');
  const inheritFromClient = investmentRestrictionsSetting[`${flagTypeAsCamelCase}InheritFromClient`];

  const filteredFlags = flags.filter((flag) => flag.flagType === flagType);

  const addFlag = () => {
    setInvestmentRestrictionsSetting({
      ...investmentRestrictionsSetting,
      flags: [
        ...flags,
        new Flag({
          aggregate: false,
          generateActionPoint: true,
          criterion: null,
          flagType: flagType,
          id: '',
          maximum: 100,
          minimum: 0,
          enabled: true,
        }),
      ],
    });
  };

  return (
    <fieldset className="form__fieldset pad-v-2">
      <div className="frow frow--justify-between">
        <h4 className="form__fieldset-label">{title}</h4>
      </div>
      <input type="hidden" name={`${baseParam}[id]`} value={investmentRestrictionsSetting.id} />
      <FlagsSelector
        flags={filteredFlags}
        namePrefix={flagParam}
        flagType={flagType}
        addFlag={addFlag}
        classificationType={classificationType}
        showSliders={showSliders}
        showAggregate={showAggregate}
        inheritFromClient={inheritFromClient}
      />
    </fieldset>
  );
};

export default Flags;
