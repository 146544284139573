import * as React from 'react';

import DatePicker from 'react-datepicker';
import Dropzone from 'components/shared/forms/Dropzone';
import { DirectUpload } from 'activestorage';
import FormRow from 'components/shared/forms/FormRow';
import { format, parse } from 'date-fns';

type Document = {
  filename: string;
  created_at: string;
  signature_date: string;
};

type Props = {
  title: string;
  documentType: string;
  index: number;
  latestDocument: Document | null;
};

const DocumentUploader = (props: Props) => {
  const [signatureDate, setSignatureDate] = React.useState<Date | null>(null);
  const [blobSignedId, setBlobSignedId] = React.useState<string | null>(null);
  const [isProcessing, setIsProcessing] = React.useState<boolean>(false);

  const { title, documentType, index, latestDocument } = props;

  function handleFileUpload(files) {
    if (files.length < 1) return;

    setIsProcessing(true);
    const upload = new DirectUpload(files[0], '/rails/active_storage/direct_uploads');

    upload.create((error, blob) => {
      if (!error) {
        setBlobSignedId(blob.signed_id);
      }
      setIsProcessing(false);
    });
  }

  return (
    <fieldset className="form__fieldset">
      <FormRow>
        <div className="mar-b-2">
          <label className="form__label">{title}</label>
          {latestDocument && (
            <div className="mar-b-2">
              <div className="text-white text-large">Latest document</div>
              <div className="text-white text-small">{latestDocument.filename}</div>
              <div className="text-white text-small">
                Upload date: {format(parse(latestDocument.created_at), 'YYYY-MM-DD')}
              </div>
              <div className="text-white text-small">Signature date: {latestDocument.signature_date}</div>
            </div>
          )}
          <Dropzone
            handleRemoveFile={() => setBlobSignedId('')}
            onFileUpload={handleFileUpload}
            multiple={false}
            isSubmitting={isProcessing}
            shouldShowFiles={true}
            theme="dark"
          />
          <input type="hidden" name={`portfolio[documents_attributes][${index}][document]`} value={blobSignedId} />
        </div>
        <label className="form__label">Signature date</label>
        <DatePicker
          name={`portfolio[documents_attributes][${index}][signature_date]`}
          id="signature_date"
          selected={signatureDate}
          onChange={(date) => setSignatureDate(date)}
          dateFormat="yyyy/MM/dd"
          disabled={blobSignedId == ''}
          autoComplete="off"
        />
      </FormRow>
      <input
        type="hidden"
        name={`portfolio[documents_attributes][${index}][document_type]`}
        value={blobSignedId ? documentType : null}
      />
    </fieldset>
  );
};

export default DocumentUploader;
