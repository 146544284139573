import * as React from 'react';

import Presenter from './dataChaseTemplates/Presenter';

import { DataChaseTemplate } from 'javascript/models';

export default function dataChaseTemplates() {
  const [dataChaseTemplates, setDataChaseTemplates] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    getTemplates().then(() => setIsLoading(false));
  }, []);

  async function getTemplates() {
    const { data } = await DataChaseTemplate.all();

    setDataChaseTemplates(data);
  }

  return <Presenter {...{ dataChaseTemplates, getTemplates, isLoading }} />;
}
