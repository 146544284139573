import * as React from 'react';
import { sanitize } from 'dompurify';
import * as ReactTooltip from 'react-tooltip';
import lodashStartcase from 'lodash.startcase';

import { format, parse } from 'date-fns';
import { simpleFormat } from '../Utils';

export default function card({ dropdown, expanded, note }) {
  const { author, attachments, body, createdAt, noteCategory, noteType, notableType, status, tagList } = note;
  const fullName = author && author.fullName;
  const firstName = author && author.firstName;
  const [isExpanded, setIsExpanded] = React.useState(expanded);

  React.useEffect(() => {
    setIsExpanded(expanded);
  }, [expanded]);

  function renderAttachments() {
    if (attachments.length < 1) return;

    const formattedAttachments = attachments.map((attachment, index) => {
      return (
        <span key={index}>
          <a href={attachment.path} className="link link--blue">
            {attachment.name}
          </a>
        </span>
      );
    });

    return (
      <div className="col-mc-1-1" style={{ marginTop: 'auto' }}>
        <div className="comment__footer">
          <div className="frow frow--gutters">{formattedAttachments}</div>
        </div>
      </div>
    );
  }

  function renderTags() {
    const formattedTags = tagList.map((tag) => {
      return (
        <div key={tag}>
          <div className="tag tag--blue tag--small tag--inline">{tag}</div>
        </div>
      );
    });

    return (
      <div className="col-mc-1-1 mar-t-1" style={{ marginTop: 'auto' }}>
        <div className="frow frow--gutters">{formattedTags}</div>
      </div>
    );
  }

  function handleExpandedClick() {
    setIsExpanded(!isExpanded);
  }

  function renderAttributes() {
    if (isExpanded) {
      return (
        <React.Fragment>
          <div className="frow frow--gutters frow--items-center frow--nowrap">
            <div>
              <div className="user-icon mar-h-0" data-letter={author && firstName.charAt(0)} />
            </div>
            <div className="text-small">{fullName}</div>
          </div>
          <p className="text-tiny mar-b-0">Note For: {notableType}</p>
          <p className="text-tiny mar-b-0">Category: {lodashStartcase(noteCategory)}</p>
          <p className="text-tiny mar-b-0">Type: {lodashStartcase(noteType)}</p>
          <p className="text-tiny mar-b-0">Status: {lodashStartcase(status)}</p>
          <p className="text-tiny mar-b-0">{format(parse(createdAt), 'DD MMM YYYY [at] HH:mm')}</p>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <div className="frow frow--gutters frow--items-center frow--nowrap">
          <div>
            <div className="user-icon mar-h-0" data-letter={author && firstName.charAt(0)} />
          </div>
          <div className="text-small">{fullName}</div>
        </div>
        <p className="text-tiny mar-b-0">Note For: {notableType}</p>
        <p className="text-tiny mar-b-0">{format(parse(createdAt), 'DD MMM YYYY [at] HH:mm')}</p>
      </React.Fragment>
    );
  }

  const textStyles = {
    maxHeight: isExpanded ? '10000px' : '84px',
    overflow: 'hidden',
  };

  return (
    <div className="col-mc-1-1">
      <div
        className={`comment ${status === 'high_priority' && 'comment--highlighted'}`}
        onClick={handleExpandedClick}
        data-tip={`Click to ${isExpanded ? 'Collapse' : 'Expand'}`}
        style={{ cursor: 'pointer' }}
        data-for={`note-${note.id}`}
      >
        <div className="frow frow--gutters frow--nowrap">
          <div className="col-sm-2-12">{renderAttributes()}</div>
          <div className="col-sm-9-12">
            <div className="frow frow--direction-column" style={{ height: '100%' }}>
              <div className="col-mc-1-1">
                <div
                  className="text-wrap text-wrap--small"
                  dangerouslySetInnerHTML={{ __html: simpleFormat(sanitize(body)) }}
                  style={textStyles}
                />
              </div>
              {renderTags()}
              {renderAttachments()}
            </div>
          </div>
          {dropdown(note)}
        </div>
      </div>
      <ReactTooltip effect="solid" id={`note-${note.id}`} />
    </div>
  );
}
