import * as React from 'react';

import GraphitiTableWrapper from 'components/shared/GraphitiTableWrapper';

import columns from './table/columns';

import { Manager } from 'javascript/models';

interface IProps {
  isExportable: boolean;
  newPath?: string;
  scope?: { [key: string]: string | { [key: string]: string } };
}

export default function graphitiTable(props: IProps) {
  const { isExportable, newPath } = props;

  let scope = Manager.select(['name', 'manager_user_count', 'portfolio_count', 'show_path']);

  if (props.scope) {
    scope = scope.where(props.scope);
  }

  return (
    <GraphitiTableWrapper
      isExportable={isExportable}
      scope={scope}
      columns={columns()}
      hasHeader
      isSearchable
      searchPlaceholder="Search by name"
      newPath={newPath}
      newText="Create Manager"
      title="Investment Managers"
      identifier="manager:index"
    />
  );
}

graphitiTable.defaultProps = {
  hasHeader: true,
};
