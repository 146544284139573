import * as React from 'react';

import { ChromePicker } from 'react-color';

interface IProps {
  color: any;
  disabled: boolean;
  label: string;
  attribute: string;
  handleColorChange(attribute: string, color: { hex: string }): void;
}

export default function colorPicker(props: IProps) {
  function handleColorChange(color) {
    if (props.disabled) return;

    props.handleColorChange(props.attribute, color);
  }

  return (
    <React.Fragment>
      <label htmlFor="" className="form__label">
        {props.label}
      </label>
      <ChromePicker color={props.color} disableAlpha onChangeComplete={handleColorChange} />
    </React.Fragment>
  );
}
