import * as React from 'react';

import { DebounceInput } from 'react-debounce-input';

interface IProps {
  autoComplete?: string;
  hasClearText?: boolean;
  id?: string;
  isSearching?: boolean;
  modifier?: string;
  name?: string;
  placeholder?: string;
  readOnly?: boolean;
  shiftIconLeft?: boolean;
  type?: string;
  value?: string;
  width?: string;
  handleBlur?(): void;
  handleChange(searchTerm: string): void;
  handleFocus?(): void;
  handleKeyDown?(event: React.SyntheticEvent): void;
}

export default function search(props: IProps) {
  const [value, setValue] = React.useState<string>('');

  const { isSearching } = props;

  React.useEffect(() => {
    setValue('');
  }, [props.value]);

  React.useEffect(() => {
    props.handleChange(value);
  }, [value]);

  function handleChange(searchTerm: string) {
    setValue(searchTerm);
  }

  function renderRightIcon() {
    const searchStyles = { top: '-3px' };
    if (props.shiftIconLeft) {
      searchStyles['right'] = '90px';
    }
    if (isSearching) {
      return (
        <i className="fas fa-circle-notch fa-spin search__right-icon text-brand-windows-blue" style={searchStyles} />
      );
    }
  }

  function renderClear() {
    if (props.hasClearText) {
      return (
        <span className="search__clear" onClick={() => setValue('')}>
          Clear search
        </span>
      );
    }

    if (!value || isSearching) {
      return;
    }

    return (
      <span className="search__clear search__clear--inline" onClick={() => setValue('')}>
        <i className="icon-close icon-fw" />
      </span>
    );
  }

  const style = { width: props.width };
  return (
    <div className={`search search--${props.modifier}`}>
      <i className="icon-search-small search__search-icon" />
      <DebounceInput
        className="search__input"
        debounceTimeout={500}
        element="input"
        id={props.id}
        name={props.name}
        onBlur={props.handleBlur}
        onChange={(event: React.SyntheticEvent) => handleChange((event.target as HTMLInputElement).value)}
        onFocus={props.handleFocus}
        onKeyDown={props.handleKeyDown}
        placeholder={props.placeholder}
        type={props.type}
        value={value}
        style={style}
        readOnly={props.readOnly}
      />
      {renderRightIcon()}
      {renderClear()}
    </div>
  );
}

search.defaultProps = {
  hasClearText: true,
  shiftIconLeft: true,
  type: 'text',
};
