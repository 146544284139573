import { Attr, BelongsTo, Model } from 'spraypaint';
import { ApplicationRecord } from './ApplicationRecord';
import { Administrator } from './Administrator';

@Model()
export class Extract extends ApplicationRecord {
  public static jsonapiType = 'extracts';
  @Attr() public administratorId: string;
  @Attr() public createdAt: string;
  @Attr() public downloadPath: string;
  @Attr() public extractType: string;
  @Attr() public generated: boolean;
  @Attr() public options: string;
  @Attr() public clientName: string;

  @BelongsTo() public administrator: Administrator;
}
