import * as React from 'react';
import Context from '../../../../Context';

const headerLogo = require('../../../../../../../../images/logos/plus-divide-white.svg').default;

export default function monitoringHeader() {
  const context = React.useContext(Context);
  const { reportTemplate } = context.state;
  return (
    <header className="pdf-header pdf-header--landscape pdf-header--blue">
      <div className="pdf-header__logo-wrap">
        <img src={headerLogo} alt="" className="pdf-header__logo" />
        <span className="pdf-header__logo-text">Monitoring</span>
      </div>
      <div className="pdf-header__title-wrap">
        <div>
          <h3 className="pdf-subtitle text-white text-bold" style={{ fontSize: '2rem' }}>
            {reportTemplate.reportType}
          </h3>
          <h1 className="pdf-title text-white" style={{ fontSize: '4.6rem' }}>
            Entity name
          </h1>
          <h2 className="pdf-subtitle text-white text-bold" style={{ fontSize: '2rem' }}>
            Date
          </h2>
        </div>
      </div>
    </header>
  );
}
