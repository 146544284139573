import { first, last } from 'lodash';

type TickGapFn = (args: Pick<IGenerateNumericTicks, 'minDataPoint' | 'maxDataPoint'>) => number;

export interface IGenerateNumericTicks {
  tickArray: number[];
  minDataPoint: number;
  maxDataPoint: number;
  tickGap: TickGapFn | number;
}

export const generateNumericTicks = ({ tickArray, minDataPoint, maxDataPoint, tickGap }: IGenerateNumericTicks) => {
  const localTickGap = typeof tickGap === 'function' ? tickGap({ minDataPoint, maxDataPoint }) : tickGap;
  const maxTick = last(tickArray);
  const minTick = first(tickArray);

  if (maxTick >= maxDataPoint && minTick <= minDataPoint) return tickArray;

  if (maxTick < maxDataPoint) tickArray.push(maxTick + localTickGap);
  if (minTick > minDataPoint) tickArray.unshift(minTick - localTickGap);
  return generateNumericTicks({ tickArray, minDataPoint, maxDataPoint, tickGap: localTickGap });
};
