import * as React from 'react';

import lodashStartcase from 'lodash.startcase';

import FormRow from 'components/shared/forms/FormRow';

import ReactSelect from 'components/shared/forms/ReactSelect';

import ISelectOption from 'components/interfaces/ISelectOption';
import IEntity from 'components/interfaces/IEntity';

interface IProps {
  administrators: ISelectOption[];
  clientClassifications: ISelectOption[];
  contractOffices: ISelectOption[];
  entity: { data: IEntity };
  serviceOffices: ISelectOption[];
}

export default function customer(props: IProps) {
  const { administrators, clientClassifications, contractOffices, serviceOffices } = props;

  const {
    clientClassification,
    consultant1Id,
    consultant1Name,
    consultant2Id,
    consultant2Name,
    contractOffice,
    serviceOffice,
  } = props.entity.data.attributes;

  return (
    <fieldset className="form__fieldset">
      <h4 className="form__fieldset-label">Customer Details</h4>
      <div className="frow frow--gutters">
        <FormRow>
          <label htmlFor="client_classification" className="form__label">
            Client Classification
          </label>
          <ReactSelect
            name="entity[client_classification]"
            theme="dark"
            options={clientClassifications}
            id="client_classification"
            defaultValue={{ value: clientClassification, label: lodashStartcase(clientClassification) }}
          />
        </FormRow>
        <FormRow>
          <label htmlFor="contract_office" className="form__label">
            Contract Office
          </label>
          <ReactSelect
            name="entity[contract_office]"
            theme="dark"
            options={contractOffices}
            id="contract_office"
            defaultValue={{ value: contractOffice, label: contractOffice ? contractOffice.toUpperCase() : null }}
          />
        </FormRow>
        <FormRow>
          <label htmlFor="service_office" className="form__label">
            Service Office
          </label>
          <ReactSelect
            name="entity[service_office]"
            theme="dark"
            options={serviceOffices}
            id="service_office"
            defaultValue={{ value: serviceOffice, label: serviceOffice ? serviceOffice.toUpperCase() : null }}
          />
        </FormRow>
        <FormRow>
          <label htmlFor="consultant_1" className="form__label">
            Consultant 1
          </label>
          <ReactSelect
            name="entity[consultant_1]"
            theme="dark"
            options={administrators}
            id="consultant_1"
            defaultValue={{ value: consultant1Id, label: consultant1Name }}
          />
        </FormRow>
        <FormRow>
          <label htmlFor="consultant_2" className="form__label">
            Consultant 2
          </label>
          <ReactSelect
            name="entity[consultant_2]"
            theme="dark"
            options={administrators}
            id="consultant_2"
            defaultValue={{ value: consultant2Id, label: consultant2Name }}
          />
        </FormRow>
      </div>
    </fieldset>
  );
}
