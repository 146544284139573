import { Attr, Model } from 'spraypaint';
import { ApplicationRecord } from './ApplicationRecord';

@Model()
export class Balance extends ApplicationRecord {
  public static jsonapiType = 'balances';
  @Attr() public amount: number;
  @Attr() public currency: string;
  @Attr() public valueDate: string;
  @Attr() public discardedAt: Date;
  @Attr() public valueBaseCcy: number;
}
