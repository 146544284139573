import * as React from 'react';

import lodashStartcase from 'lodash.startcase';

export default function table({ portfolios }) {
  const tableHeaders = portfolios[0].map((portfolioHash, index) => {
    return (
      <th key={index} style={{ minWidth: '100px', color: `${portfolioHash.required ? '#CFB538' : ''}` }}>
        {lodashStartcase(portfolioHash.attribute)}
      </th>
    );
  });

  const tableRows = portfolios.map((portfolio, index) => {
    const tableCells = portfolio.map((portfolioHash, innerIndex) => {
      const error = (
        <div className="table-icon table-icon--warning" data-tip={portfolioHash.error_message}>
          {portfolioHash.attribute_value} <span style={{ opacity: 0 }}>Error</span>
        </div>
      );
      return (
        <td key={`${portfolioHash.attribute}_${innerIndex}`}>
          {portfolioHash.error ? error : portfolioHash.attribute_value}
        </td>
      );
    });

    return <tr key={index}>{tableCells}</tr>;
  });

  return (
    <div style={{ overflowX: 'scroll' }}>
      <table className="styled-table">
        <thead>
          <tr>{tableHeaders}</tr>
        </thead>
        <tbody>{tableRows}</tbody>
      </table>
    </div>
  );
}
