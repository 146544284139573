import * as React from 'react';

import { truncate } from 'lodash';
import { Intent, Menu, MenuItem, Popover, Position } from '@blueprintjs/core';
import useVisibility from 'components/shared/customHooks/useVisibility';
import Alert from 'components/shared/Alert';
import Form from '../../platform/clientTiles/Form';
import CustomModal from 'components/shared/CustomModal';
import { graphitiErrorString } from 'components/shared/Utils';
import useGraphitiForm from 'components/shared/customHooks/useGraphitiForm';
import { HeadingTwo } from 'components/shared/Typography';
import { darkRed } from 'components/shared/colours';

const resourceCard = ({ getTiles, handleDelete, resourceTypes, tile }) => {
  const { description, editable, name, resourceType, url } = tile;

  const [errorMessage, setErrorMessage] = React.useState(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [faviconImage, setFaviconImage] = React.useState(true);
  const { isOpen, handleClose, handleOpen } = useVisibility(false);

  const handleFormSubmission = async (event) => {
    const success = await resource.save();
    if (success) {
      handleClose();
      getTiles();
    } else {
      setErrorMessage(graphitiErrorString(resource));
    }
    setIsSubmitting(false);
  };

  const { resource, handleChange, handleManualChange, handleSubmit, resetResource } = useGraphitiForm(
    tile,
    handleFormSubmission,
  );

  const cardColour = () => {
    switch (resourceType) {
      case 'news':
        return 'mid-aqua';
      case 'blog':
        return 'mid-orange';
      case 'general':
        return 'grey-40';
      case 'regulators':
        return 'dark-green';
      case 'professional_bodies':
        return 'mid-blue';
      case 'index_providers':
        return 'dark-red';
    }
  };

  const defaultIcon = () => {
    switch (resourceType) {
      case 'news':
        return 'newspaper';
      case 'blog':
        return 'pen-fancy';
      case 'general':
        return 'globe';
      case 'regulators':
        return 'tape';
      case 'professional_bodies':
        return 'university';
      case 'index_providers':
        return 'chart-line';
    }
  };

  const handleDeleteClick = (event) => {
    event.preventDefault();
    handleDelete(tile);
  };

  const handleModalClose = () => {
    handleClose();
    setErrorMessage(null);
  };

  const handleFormSubmit = (event) => {
    setIsSubmitting(true);
    handleSubmit(event);
  };

  const handleOpenModal = (event) => {
    event.preventDefault();
    handleOpen();
  };

  const editOptions = (
    <div>
      <Popover usePortal={false} position={Position.BOTTOM_RIGHT}>
        <div onClick={(e) => e.preventDefault()} className="button button--icon-dark">
          <i className="icon-overflow-vertical-white icon text-white" />
        </div>
        <Menu>
          <li onClick={handleOpenModal} className="bp3-menu-list-item">
            Edit
          </li>
          <li onClick={handleDeleteClick} className="bp3-menu-list-item" style={{ color: darkRed }}>
            Delete
          </li>
        </Menu>
      </Popover>
      <CustomModal
        isOpen={isOpen}
        title="Edit tile"
        handleClose={handleModalClose}
        modifiers={['visible-overflow', 'dark']}
      >
        <div className="modal__content">
          <Alert message={errorMessage} modifier="dark" title="Missing Fields" type="error" />
          <form className="form" onSubmit={handleFormSubmit}>
            <Form {...{ resource, handleChange, handleManualChange, isSubmitting, resourceTypes }} />
          </form>
        </div>
      </CustomModal>
    </div>
  );

  const handleImageLoaded = () => {
    setFaviconImage(true);
  };

  const handleImageErrored = () => {
    setFaviconImage(false);
  };

  const TileIcon = () => {
    if (resource.image) {
      return <img src={resource.image.path} width="30" height="30" />;
    } else if (faviconImage) {
      return (
        <img
          src={`${url}/favicon.ico`}
          onLoad={handleImageLoaded}
          onError={handleImageErrored}
          width="30"
          height="30"
        />
      );
    }
    return <i className={`fas fa-2x fa-${defaultIcon()} text-white`} />;
  };

  return (
    <div className="col-md-1-2 col-lg-1-3">
      <a href={url} target="_blank" rel="noreferrer">
        <div className={`resource-box resource-box--rounded resource-box--${cardColour()}`}>
          <div className="frow frow--items-center frow--justify-between">
            <div className="frow frow--centered">
              <TileIcon />
              <p className="subtitle-two text-grey-40 text-rubik mar-l-2 mar-b-0">{truncate(url, { length: 40 })}</p>
            </div>
            {editable && editOptions}
          </div>
          <div className="frow frow--items-center">
            <HeadingTwo text={name} additionalClasses="text-rubik" />
          </div>
          <p className="mar-b-0 text-white text-rubik text-large">{description}</p>
        </div>
      </a>
    </div>
  );
};

export default resourceCard;
