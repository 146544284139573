import * as React from 'react';

import FormRow from 'components/shared/forms/FormRow';
import { PortfolioBenchmark } from 'javascript/models';
import ComponentBenchmark from './ComponentBenchmark';
import SelectedBenchmark from './SelectedBenchmark';
interface IProps {
  componentBenchmarks: {
    name: string;
    proportion: string;
    id: string;
    component_id: string;
    _destroy: boolean;
  }[];
  selectedBenchmarks: string[];
}

const ComponentBenchmarks = (props: IProps) => {
  const [componentBenchmarks, setComponentBenchmarks] = React.useState(props.componentBenchmarks);
  const [selectedBenchmarksFromBuilder, setSelectedBenchmarksFromBuilder] = React.useState([]);
  const { selectedBenchmarks } = props;
  React.useEffect(() => {
    getSelectedBenchmarks();
  }, []);

  const getSelectedBenchmarks = async () => {
    if (selectedBenchmarks.length < 1) {
      return;
    }
    const { data } = await PortfolioBenchmark.where({ id: selectedBenchmarks }).all();
    const result = data.map((benchmark) => ({ reference: benchmark.reference, id: benchmark.id }));
    setSelectedBenchmarksFromBuilder(result);
  };
  const handleNewComponent = () => {
    const benchmarks = [...componentBenchmarks];
    benchmarks.push({ name: '', proportion: '', id: '', _destroy: false, component_id: '' });
    setComponentBenchmarks(benchmarks);
  };

  function handleRemoveClick() {
    const benchmarks = [...componentBenchmarks];
    benchmarks[this]._destroy = true;
    setComponentBenchmarks(benchmarks);
  }

  const renderComponents = () =>
    componentBenchmarks.map((benchmark, index) => (
      <ComponentBenchmark
        key={index}
        componentBenchmark={benchmark}
        index={index}
        handleRemoveClick={handleRemoveClick}
      />
    ));

  const renderSelectedBenchmarks = () => {
    if (selectedBenchmarksFromBuilder.length < 1) {
      return null;
    }
    return selectedBenchmarksFromBuilder.map((benchmark, index) => (
      <SelectedBenchmark key={index} portfolioBenchmark={benchmark} index={index} />
    ));
  };

  return (
    <FormRow>
      <div className="frow frow--gutters-2x">
        {renderSelectedBenchmarks()}
        {renderComponents()}
        <div className="col-mc-1-1">
          <button type="button" className="button button--secondary button--compact" onClick={handleNewComponent}>
            Add another component
            <i className="icon-plus icon-fw mar-l-1" />
          </button>
        </div>
      </div>
    </FormRow>
  );
};

export default ComponentBenchmarks;
