import * as React from 'react';

import { PrivateAsset } from 'javascript/models';
import { Balance } from 'javascript/models';
import ReactSelect, { IReactSelectProps } from 'components/shared/forms/ReactSelect';
import ReactDatepicker from 'react-datepicker';

import Context from '../../../../Context';

import ISelectOption from 'components/interfaces/ISelectOption';

export interface IBalanceInputProps {
  handleAddBalance: (balance: Balance) => void;
}

export default function balanceInput(props: IBalanceInputProps) {
  const context = React.useContext(Context);

  const [selectedCurrency, setSelectedCurrency] = React.useState<ISelectOption>({ label: '', value: '' });
  /**
   * The union type on balance state is to deal with when a user deletes the zero from the number input which results in
   *   a NaN if we don't handle it as an empty string
   */
  const [newBalanceValue, setNewBalanceValue] = React.useState<number | string>(0);
  const [selectedBalanceDate, setSelectedBalanceDate] = React.useState(new Date());

  const handleCurrencyChange = (option: ISelectOption) => setSelectedCurrency(option);

  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewBalanceValue(event.target.valueAsNumber || '');
  };

  const handleDateChange = (date: Date) => setSelectedBalanceDate(date);

  const selectProps: IReactSelectProps = {
    /**
     * having to provide the height as the react select and other components are slightly different sizes and it looks
     * rubbish when they are next to each other on a line.
     */
    customSelectStyles: { control: (provided, state) => ({ ...provided, minHeight: '41px' }) },
    handleChange: handleCurrencyChange,
    id: 'balance_currency',
    name: 'currency',
    options: context.props.currencyOptions,
    theme: 'dark',
    value: selectedCurrency,
  };

  const handleAdd = () => {
    const balance: Balance = new Balance({
      amount: newBalanceValue,
      balanceableType: 'PrivateAsset',
      currency: selectedCurrency.value,
      valueDate: selectedBalanceDate,
    });
    props.handleAddBalance(balance);
  };

  return (
    <div className="frow frow--centered frow--justify-end mar-t-1">
      <label className="form__label col-flex-grow-1">Add New Valuation</label>
      <div className="col-sm-2-12 pad-r-1" style={{ height: '41px' }}>
        <ReactSelect {...selectProps} />
      </div>
      <div className="col-sm-2-12 pad-r-1">
        <input type="number" id="amount" value={newBalanceValue} onChange={handleAmountChange} />
      </div>
      <div className="col-sm-3-12">
        <ReactDatepicker selected={selectedBalanceDate} onChange={handleDateChange} dateFormat="yyyy-MM-dd" />
      </div>
      <div className="frow frow--justify-end mar-l-1">
        <button type="button" className="button button--secondary button--icon" onClick={handleAdd}>
          <i className="icon-tick icon-fw" />
        </button>
      </div>
    </div>
  );
}
