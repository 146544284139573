import * as React from 'react';

import { format, parse } from 'date-fns';

export default function columns() {
  return [
    {
      Cell: (row) => {
        return (
          <div>
            <a href={row.original.downloadPath} className="link link--secondary">
              {row.original.fileName}
            </a>
          </div>
        );
      },
      Header: 'File Name',
      accessor: 'fileName',
      columnName: 'File Name',
      id: 'file_name',
      sortable: false,
    },
    {
      Cell: (row) => {
        return <div>{format(parse(row.original.createdAt), 'DD MMM YYYY [at] HH:mm')}</div>;
      },
      Header: 'Date Uploaded',
      accessor: 'createdAt',
      columnName: 'Date Uploaded',
      id: 'created_at',
    },
    {
      Cell: (row) => {
        return (
          <div className={`${row.original.processing ? '' : 'table-icon table-icon--success'}`}>
            {row.original.processing ? (
              <i className="fas fa-1-5x fa-circle-notch fa-spin text-brand-windows-blue" />
            ) : (
              'Complete'
            )}
          </div>
        );
      },
      Header: 'Processing',
      accessor: 'processing',
      columnName: 'Processing',
      id: 'processing',
    },
    {
      Cell: (row) => {
        return (
          <div className={`table-icon table-icon--${row.original.hasErrorMessages ? 'warning' : 'success'}`}>
            {row.original.hasErrorMessages ? 'Yes' : 'No'}
          </div>
        );
      },
      Header: 'Errors',
      accessor: 'hasErrorMessages',
      columnName: 'Errors',
      id: 'has_error_message',
    },
    {
      Cell: (row) => {
        return (
          <a href={row.original.showPath} style={{ display: 'block' }}>
            <div className="rt-tr-clickable-icon">
              <i className="icon-arrow-right-top icon-0-8x" />
            </div>
          </a>
        );
      },
      Header: '',
      sortable: false,
    },
  ];
}
