import { Attr, HasOne } from 'spraypaint';

import { ApplicationRecord } from './ApplicationRecord';

export class Role<Roleable extends ApplicationRecord> extends ApplicationRecord {
  @Attr() public id: string;
  @Attr() public name: string;

  @HasOne() public roleable: Roleable;
}
