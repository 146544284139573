import * as React from 'react';

import { compose, converge, map, mergeRight, objOf, prop } from 'ramda';

import Select, { components } from 'react-select';
import { SelectComponents } from 'react-select/src/components';
import Creatable from 'react-select/creatable';

import ISelectOption from 'components/interfaces/ISelectOption';

type OptionType = ISelectOption | ISelectOption[] | { label: string; value: any };
export interface IReactSelectProps {
  createable?: boolean;
  defaultValue?: any;
  defaultValues?: { label: string; value: string }[];
  formatOptionLabel?: (props: ISelectOption<any>) => JSX.Element;
  customSelectStyles?: {};
  id?: string;
  isClearable?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  isMulti?: boolean;
  maxMenuHeight?: number;
  menuPortalTarget?: any;
  name?: string;
  options: ISelectOption[];
  // The type below is the 'Option' type which comes from the SelectComponents interface
  // which is provided by react-select.
  customOption?: SelectComponents<OptionType, boolean>['Option'];
  value?: OptionType;
  placeholder?: string;
  theme: 'light' | 'dark';
  handleChange?: (option: ISelectOption) => void;
  onChange?: (...args) => any;
  handleInputChange?(value: string): void;
}

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props} style={{ color: 'white' }}>
    {props.selectProps.menuIsOpen ? (
      <i className="icon-chevron-up icon-tiny" />
    ) : (
      <i className="icon-chevron-down icon-tiny" />
    )}
  </components.DropdownIndicator>
);

const optionGenerator = converge(mergeRight, [objOf('label'), objOf('value')]);
const objOptionGenerator = (labelProp) =>
  converge(mergeRight, [compose(objOf('label'), prop(labelProp)), objOf('value')]);

const optionsGenerator = map(optionGenerator);
const objOptionsGenerator = (labelProp) => map(objOptionGenerator(labelProp));

const ReactSelect = (props: IReactSelectProps) => {
  const {
    createable,
    handleInputChange,
    id,
    isClearable,
    isDisabled,
    isLoading,
    isMulti,
    menuPortalTarget,
    maxMenuHeight,
    name,
    options,
    formatOptionLabel,
    customSelectStyles,
    onChange,
    value,
    placeholder,
    theme,
    customOption,
  } = props;

  const localOnChange = (option) => {
    if (props.handleChange) {
      props.handleChange(option);
    }
  };

  const handleChange = onChange || localOnChange;

  const className = theme === 'dark' ? 'react-select-dark' : 'react-select';

  let defaultValue = props.defaultValue && props.defaultValue.value ? props.defaultValue : null;

  if (!defaultValue) {
    defaultValue = props.defaultValues && props.defaultValues.length > 0 ? props.defaultValues : null;
  }

  const selectComponents = customOption ? { DropdownIndicator, Option: customOption } : { DropdownIndicator };

  if (createable) {
    return (
      <Creatable
        className={className}
        classNamePrefix={className}
        components={selectComponents}
        defaultValue={defaultValue}
        id={id}
        isClearable={isClearable}
        isDisabled={isDisabled}
        isMulti={isMulti}
        isSearchable
        isLoading={isLoading}
        menuPortalTarget={menuPortalTarget}
        maxMenuHeight={maxMenuHeight}
        name={name}
        onChange={handleChange}
        options={options}
        value={value}
        placeholder={placeholder}
        onInputChange={handleInputChange}
      />
    );
  }

  return (
    <Select
      className={className}
      styles={customSelectStyles}
      classNamePrefix={className}
      components={selectComponents}
      defaultValue={defaultValue}
      formatOptionLabel={formatOptionLabel}
      id={id}
      isClearable={isClearable}
      isDisabled={isDisabled}
      isMulti={isMulti}
      isSearchable
      isLoading={isLoading}
      menuPortalTarget={menuPortalTarget}
      maxMenuHeight={maxMenuHeight}
      name={name}
      onChange={onChange || handleChange}
      options={options}
      value={value}
      placeholder={placeholder}
      onInputChange={handleInputChange}
    />
  );
};

ReactSelect.defaultProps = {
  createable: false,
  isDisabled: false,
  maxMenuHeight: 300,
  placeholder: 'Please Select',
  theme: 'light',
  menuPortalTarget: null,
};

export default ReactSelect;
export { optionsGenerator, objOptionsGenerator, optionGenerator, objOptionGenerator };
