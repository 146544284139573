import * as React from 'react';

export default function blank({ attributes, handleChange }) {
  return (
    <div className="frow frow--gutters">
      <div className="col-mc-1-1">
        <label className="form__label" htmlFor="">
          Height (% of page)
        </label>
        <input type="number" name="height" value={attributes.height || ''} onChange={handleChange} />
      </div>
    </div>
  );
}
