import * as React from 'react';
import Frow from '../frow/Frow';

type ShowDateTextType =
  | { showDateText?: false; currentYear?: never }
  | { showDateText: true; currentYear: string | number };
interface CommonProps {
  currentQuarter: string | number;
  height?: string;
}

type IProps = CommonProps & ShowDateTextType;

const quarterIndicator = ({ currentQuarter, currentYear, showDateText, height }: IProps) => {
  const quarterObj = {
    1: 'hollow-circle--one-quarter',
    2: 'hollow-circle--two-quarters',
    3: 'hollow-circle--three-quarters',
    4: 'hollow-circle--four-quarters',
  };

  return (
    <Frow itemAlignment="center">
      <div className={`mar-r-1 hollow-circle ${quarterObj[currentQuarter]}`} style={{ height, width: height }} />
      {showDateText && <p className="mar-l-1 text-white text-small">{`Q${currentQuarter} ${currentYear}`}</p>}
    </Frow>
  );
};

quarterIndicator.defaultProps = {
  height: '20px',
  showDateText: false,
};

export default quarterIndicator;
