import * as React from 'react';

const classNames = require('classnames');

interface IProps {
  color?: string;
  flexDirection?: string;
  height?: string;
  width?: string;
  hidden?: boolean;
  size?: 'sm' | 'md' | 'lg';
  text?: string;
  textColor?: string;
}

const Loading = (props: IProps) => {
  const display = props.hidden ? 'none' : 'flex';
  const style = {
    display,
    flexDirection: props.flexDirection as any,
    height: props.height ? props.height : '100px',
    width: props.width ? props.width : '100%',
  };

  const loadingClasses = classNames(
    'fas',
    'fa-circle-notch',
    'fa-spin',
    `text-brand-${props.color}`,
    { fa: props.size === 'sm' },
    { 'fa-2x': props.size === 'md' },
    { 'fa-3x': props.size === 'lg' },
  );

  function renderText() {
    if (!props.text) return;

    return <p className={`text-white text-${props.textColor} text-center`}>{props.text}</p>;
  }

  return (
    <div style={style}>
      <i className={loadingClasses} style={{ margin: 'auto' }} />
      {renderText()}
    </div>
  );
};

Loading.defaultProps = {
  color: 'windows-blue',
  flexDirection: 'row',
  size: 'md',
};

export default Loading;
