import * as React from 'react';

export default function consolidatedSortinoRatio({ large = false, showHeaders }) {
  const headers = (
    <thead>
      <tr>
        <th style={{ width: '80%', verticalAlign: 'top' }}>Reference</th>
        <th style={{ width: '20%', verticalAlign: 'top' }} className="text-right">
          Sortino Ratios
        </th>
      </tr>
    </thead>
  );

  return (
    <table className={`pdf-table pdf-table--striped ${large && 'pdf-table--large'}`}>
      {showHeaders && headers}
      <tbody>
        <tr>
          <td className="pdf-table__row-label">Consolidated Portfolio</td>
          <td className="text-right">Value</td>
        </tr>
        {/*<tr>*/}
        {/*  <td className="pdf-table__row-label">Enhance Benchmark</td>*/}
        {/*  <td className="text-right">Value</td>*/}
        {/*</tr>*/}
        {/*<tr>*/}
        {/*  <td className="pdf-table__row-label">Fixed Income</td>*/}
        {/*  <td className="text-right">Value</td>*/}
        {/*</tr>*/}
        {/*<tr>*/}
        {/*  <td className="pdf-table__row-label">Equity</td>*/}
        {/*  <td className="text-right">Value</td>*/}
        {/*</tr>*/}
        <tr>
          <td className="pdf-table__row-label">Constituent Portfolio</td>
          <td className="text-right">Value</td>
        </tr>
        <tr>
          <td className="pdf-table__row-label">Constituent Portfolio</td>
          <td className="text-right">Value</td>
        </tr>
      </tbody>
    </table>
  );
}
