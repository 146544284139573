import * as React from 'react';

import pluralize from 'pluralize';

import Loading from '../../../../shared/Loading';
import columns from './metrics/columns';
import Table from '../../../../shared/reactTable/Table';
import { portalClientPortfolioReturnMetricsPath } from 'javascript/application/ts_routes';
import { format } from 'date-fns';
import ClientPortalCard from '../../../shared/ClientPortalCard';
import useAjaxRequest from '../../../../shared/customHooks/useAjaxRequest';

export default function metrics({ currentReviewPeriod, portfolioObject }) {
  const { client_id, id } = portfolioObject;
  const [tableData, setTableData] = React.useState<{ data: any[]; meta: { endDate: string; monthCount: number } }>();

  const path = portalClientPortfolioReturnMetricsPath(client_id, id);

  interface IRequestParams {
    review_period: string;
  }

  const [getMetrics, , isLoading] = useAjaxRequest<IRequestParams, unknown>({
    path,
    stateSetter: setTableData,
    method: 'GET',
  });

  React.useEffect(() => {
    getMetrics({ review_period: currentReviewPeriod });
  }, [currentReviewPeriod]);

  function renderTable() {
    let columnHeaders = [
      'portfolio',
      'enhance_benchmark',
      'cash_benchmark',
      'managers_benchmark',
      'peer_group',
      'target_return',
    ];

    if (tableData.data.length) {
      // The slice gets rid of the 'Name' key (which describes the period we are talking about)
      columnHeaders = Object.keys(tableData.data[0]).slice(1);
    }

    return (
      <Table
        className="-wrap-headers-overflow"
        columns={columns(columnHeaders)}
        data={tableData.data}
        hasColumnSelector={false}
        hasPagination={false}
        isLoading={false}
        manual={false}
        meta={{ count: tableData.data.length }}
      />
    );
  }

  let timeFrame = '';
  if (tableData && tableData.meta && tableData.meta.endDate) {
    const { meta } = tableData;
    timeFrame = `${pluralize('month', meta.monthCount, true)} to ${format(meta.endDate, "DD MMM 'YY")}`;
  }

  return (
    <div className="col-lg-1-2">
      <ClientPortalCard title="Metrics" subTitle={timeFrame}>
        {isLoading ? <Loading /> : renderTable()}
      </ClientPortalCard>
    </div>
  );
}
