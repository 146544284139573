import { Attr, Model } from 'spraypaint';
import { ApplicationRecord } from './ApplicationRecord';

@Model()
export class AssetMapping extends ApplicationRecord {
  public static jsonapiType = 'asset_mappings';
  @Attr() public assetClass: string;
  @Attr() public assetName: string;
  @Attr() public editPath: string;
  @Attr() public isin: string;
}
