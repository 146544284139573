import * as React from 'react';
import * as $ from 'jquery';

import { SubtitleOne, SubtitleTwo } from 'components/shared/Typography';
import Loading from 'components/shared/Loading';
import { portalClientPortfolioStrategicAllocationsPath } from 'javascript/application/ts_routes';
import { format, parse } from 'date-fns';
import ReactFloatingBarChart from 'components/shared/charting/recharts/ReactFloatingBarChart';
import ReactPieChart from 'components/shared/charting/recharts/ReactPieChart';
import PieChartTableLegend from '../../show/PieChartTableLegend';

export default function mandate({ currentReviewPeriod, portfolioObject }) {
  const { client_id, id } = portfolioObject;
  const [isLoading, setIsLoading] = React.useState(true);
  const [mandateData, setMandateData] = React.useState([]);

  React.useEffect(() => {
    getMandateInformation();
  }, [currentReviewPeriod]);

  function getMandateInformation() {
    $.ajax({
      beforeSend: () => setIsLoading(true),
      cache: false,
      data: { review_period: currentReviewPeriod },
      dataType: 'json',
      success: (response) => {
        setMandateData(response);
        setIsLoading(false);
      },
      type: 'GET',
      url: portalClientPortfolioStrategicAllocationsPath(client_id, id),
    });
  }

  function renderCharts() {
    const formattedPieData = mandateData.map((assetClassItem) => {
      return { name: assetClassItem.name, color: assetClassItem.color, value: assetClassItem.strategicValue };
    });

    return (
      <div className="frow frow--gutters-2x">
        <div className="col-md-1-2">
          <div className="frow frow--gutters-2x">
            <div className="col-md-1-2">
              <ReactPieChart
                animationDuration={500}
                animationBegin={200}
                data={formattedPieData}
                height={300}
                labelText="Strategic Allocation"
                labelSize="1.5rem"
                noData={formattedPieData.length < 1}
                displayLegend={false}
              />
            </div>
            <PieChartTableLegend headerTitle="Asset Class" data={formattedPieData} />
          </div>
        </div>
        <div className="col-md-1-2">
          {mandateData.length > 0 && (
            <>
              <ReactFloatingBarChart
                barSize={30}
                data={mandateData}
                height={300}
                percentage
                labelBold={false}
                labelColor="#fff"
                labelSize={12}
                lineWidth={1}
                lineStroke="transparent"
                isResponsive
                showTooltip
                showTicks={false}
              />
              <p className="helper-text text-white text-right">
                Lighter line is current allocation, darker line is strategic allocation.
              </p>
            </>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="col-md-1-1">
      <div className="card card--rounded card--dark card--full-height">
        <div className="platform-content platform-content--padding-all h-100">
          <div className="mar-b-1">
            <SubtitleOne text="Strategic and Tactical Allocations" additionalClasses="text-white" />
            <SubtitleTwo text={format(parse(currentReviewPeriod), 'DD MMM YYYY')} additionalClasses="mar-b-0" />
          </div>
          {isLoading ? <Loading /> : renderCharts()}
        </div>
      </div>
    </div>
  );
}
