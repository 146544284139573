import * as React from 'react';

export const CustomXAxis = (props) => {
  const name = props.name || 'Axis Name';
  const { x, y, width } = props.viewBox;
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={width / 2 - 40} y={30} textAnchor="start" fill="#000" fontWeight="bold" fontSize="9px">
        {name.toUpperCase()}
      </text>
    </g>
  );
};

export const CustomYAxis = (props) => {
  const height = props.height || 150;
  const name = props.name || 'Axis Name';
  const { x, y } = props.viewBox;
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={-(height / 2)}
        y={25}
        textAnchor="start"
        fill="#000"
        transform="rotate(-90)"
        fontWeight="bold"
        fontSize="9px"
      >
        {name.toLocaleUpperCase()}
      </text>
    </g>
  );
};

export const CustomYAxisPrint = (props) => {
  const name = props.name || 'Axis Name';
  const { x, y } = props.viewBox;
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={-135} y={20} textAnchor="start" fill="#000" transform="rotate(-90)" fontWeight="bold" fontSize="10.8px">
        {name.toLocaleUpperCase()}
      </text>
    </g>
  );
};

export const CustomXAxisPrint = (props) => {
  const name = props.name || 'Axis Name';
  const { x, y } = props.viewBox;
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={325} y={35} textAnchor="start" fill="#000" fontWeight="bold" fontSize="10.8px">
        {name.toUpperCase()}
      </text>
    </g>
  );
};
