import * as React from 'react';
import IAttribute from './interfaces/IAttribute';

import Input from './Input';

interface IProps {
  attributes: IAttribute[];
  editPath: string;
  updateModel(event: React.SyntheticEvent): void;
}

class Presenter extends React.Component<IProps, {}> {
  public render() {
    return (
      <form action={this.props.editPath} className="form">
        <div className="frow frow--gutters">{this.renderAttributeInputs()}</div>
      </form>
    );
  }

  private renderAttributeInputs() {
    return this.props.attributes.map((attribute, index) => {
      return <Input key={index} attribute={attribute} updateModel={this.props.updateModel} />;
    });
  }
}

export default Presenter;
