import * as React from 'react';

import * as numeral from 'numeral';

export const CustomLabel = (props) => {
  const { height, labelSize, percentage, value, x, y } = props;
  const textAnchor = value < 0 ? 'right' : 'left';
  const xShift = value < 0 ? labelSize * 1 : -(labelSize * 4);
  return (
    <text
      x={x + xShift}
      y={y + 5 + height / 2}
      fill="#b5b6bd"
      fontSize={labelSize}
      fontWeight="bold"
      textAnchor={textAnchor}
    >
      {value}
    </text>
  );
};

export const CustomTooltip = (props) => {
  const { active, label, payload } = props;

  if (active) {
    const wrapperStyles = {
      background: 'rgba(39,42,49, 0.6)',
      padding: '12px 0',
      width: '300px',
    };

    const valueStyles = {
      color: 'white',
      fontSize: '13px',
      fontWeight: 'bold' as 'bold',
      margin: 0,
      textAlign: 'center' as any,
    };

    const labelStyles = {
      textAlign: 'center' as any,
    };

    return (
      <div style={wrapperStyles}>
        <p style={labelStyles} className="small-uppercase mar-t-0 text-white">
          {label}
        </p>
        <div>
          <div style={valueStyles} className="frow frow--items-center frow--gutters frow--nowrap">
            <div className="col-mc-2-5 col-md-2-8">{numeral(payload[1].payload.lowerValue).format('0,0.00')}%</div>
            <div className="col-mc-3-5 col-md-6-8 text-left">
              <span className="text-normal">Minimum</span>
            </div>
          </div>
          <div style={valueStyles} className="frow frow--items-center frow--gutters frow--nowrap">
            <div className="col-mc-2-5 col-md-2-8">{numeral(payload[1].payload.upperValue).format('0,0.00')}%</div>
            <div className="col-mc-3-5 col-md-6-8 text-left">
              <span className="text-normal">Maximum</span>
            </div>
          </div>
          <div style={valueStyles} className="frow frow--items-center frow--gutters frow--nowrap">
            <div className="col-mc-2-5 col-md-2-8">{numeral(payload[1].payload.strategicValue).format('0,0.00')}%</div>
            <div className="col-mc-3-5 col-md-6-8 text-left">
              <span className="text-normal">Strategic</span>
            </div>
          </div>
          <div style={valueStyles} className="frow frow--items-center frow--gutters frow--nowrap">
            <div className="col-mc-2-5 col-md-2-8">{numeral(payload[1].payload.currentValue).format('0,0.00')}%</div>
            <div className="col-mc-3-5 col-md-6-8 text-left">
              <span className="text-normal">Current</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return null;
};
