import * as React from 'react';

import Context from '../Context';

export default function marketCommentary() {
  const context = React.useContext(Context);
  const { reportTemplate } = context.state;

  const isLandscape = reportTemplate && reportTemplate.orientation === 'landscape';

  const exampleLandscapeCommentary = (
    <table className="pdf-hidden-table">
      <tbody>
        <tr>
          <td style={{ width: '48%', verticalAlign: 'top', paddingRight: '2%' }}>
            <div className="pdf-body-copy-wrap">
              <p className="pdf-body-copy">
                <strong>Super Mario's mini 'Bazooka' ignites recovery in cyclical assets</strong>
              </p>
              <img
                src="https://res.cloudinary.com/infuse-group/image/upload/v1582298971/Enhance/heat-map.png"
                alt="example-image"
                style={{ width: '100%' }}
              />
              <p className="pdf-body-copy">
                Central Banks around the world continue to cut interest rates in an uncoordinated but synchronised
                response to slowing global growth and fears of falling inflation expectations. Emerging markets have
                eased aggressively all year, led by India, in a push to offset deflationary pressure being exported from
                China. Several countries and regions contracted in the second quarter; Germany UK, Argentina, Hong Kong
                SAR, Russia and Singapore.
              </p>
              <p>
                In September Mario Draghi held his penultimate ECB meeting as the bank’s President, he leaves on 31
                October and will be replaced by ex-IMF chief Christine Lagarde. Being ‘Super Mario’ he clearly wanted to
                leave the ECB with the Italian spirit of ‘Veni, Vidi, Vici’ and so launched a mini monetary policy
                ‘Bazooka’. It included lowering the deposit rate by 10bp to -0.5% with a tiered scale to protect bank
                profits, a third TLTRO package of subsidised loans for banks, an open ended QE program of €20bn per
                month and a shift in forward guidance from a ‘date’ to a ‘state’ implying interest rates won’t rise and
                QE won’t stop until the ECB hits its inflation target.
              </p>
              <p>
                The aggressive stimulus package from the ECB allowed the Fed to move again in September. The Fed cut
                rates for the second time this quarter, lowering the target range for the federal funds rate by another
                25bp to 1.75 - 2 percent. The accompanying Fed ‘Dot Plots’ showed the FOMC has no majority for further
                rates cuts in 2019 and 2020. However, the market continues to disagree and is pricing in further easing.
                This suggests the Fed remains behind the curve primarily because after 50bp of rate cuts year to date
                the US yield curve has not steepened and the USD remains strong.
              </p>
            </div>
          </td>
          <td style={{ width: '48%', verticalAlign: 'top', paddingLeft: '2%' }}>
            <div className="pdf-body-copy-wrap">
              <p className="pdf-body-copy">
                By contrast the PBOC has only eased a little so far this year via a small reduction in the base interest
                rate and reserve requirement ratio. Economists expect the PBOC to begin easing further to offset the
                continuing negative effect that tariffs are having on economic growth. The US has placed tariffs on
                approximately US$350bn worth of goods imported from China and in December, President Trump is expected
                to place tariffs on the remaining US$150bn of Chinese imports. Eighteen months into the China-US trade
                dispute and US equities have outperformed Chinese equities by 34% since the start of the ‘trade war’.
                The problem however of expectations of any meaningful further easing is that Beijing is fully aware that
                tariffs of 5%, 10% or even 25% on all Chinese exports to the US is not enough to hurt China
                economically; $500bn at 25% tariff would be $125bn which is only around 1% of China’s $12 trillion
                economy. Beijing is also concerned about China’s current debt levels, given Japan’s two-decade battle
                with debt deflation, and therefore is being cautious.
              </p>
              <p>
                Globally inflation remains ‘barbell shaped’. On one side there are high inflation areas in Venezuela
                (2,800,000%), Argentina (54%), Iran (42%) and Turkey (15%) and on the other side deflation areas in the
                UAE (-2.2%) and Saudi Arabia (-1.3%). The rest, excluding smaller frontier markets, are generally
                between 0-2%. The high inflation countries suffer from a vicious circle of money printing to finance
                government deficits, which results in a sharp currency depreciation, a spike in inflation, severe
                interest rate hikes, a collapse in economic growth and more money printing... Whereas the deflation
                countries are being affected by exogenous not endogenous variables. It is not a coincidence that the UAE
                and Saudi Arabia are in deflation, as the AED (UAE Dirham) and SAR (Saudi Riyal) are pegged to a strong
                USD which is causing deflationary pressure at a time when oil prices are also weak. The USD is strong
                because of a combination of relatively tight monetary policy, loose fiscal policy and safe-haven demand
                status.
              </p>
              <p>
                In aggregate more countries are below their central bank inflation target, which is one of the core
                reasons for the global shift towards monetary policy easing.
              </p>
              <p>
                However, towards the end of the quarter global bond yields have risen from their mid-August lows. The
                reason is twofold; better economic data has been coming through and a fall in geopolitical tensions. The
                Citigroup Global Economic Surprise Index has been continually surprising to the upside indicating
                economic data releases globally have been better than expected. Plus, JP Morgan Global Manufacturing PMI
                data may have bottomed, as the index recently posted its first increase in 17 months, indicating a
                potential end to the global manufacturing recession. Geo-political tensions fell on an increased
                probability of a US-China trade deal by year end, an EU-UK withdrawal agreement by October 31, the
                Italians successfully formed a new pro EU government and Saudi-Iranian-US tensions are unlikely to
                result in military conflict. In 1988, Iran Air Flight 655 was shot down by USS Vincennes, resulting in
                the death of all 290 passengers and crew on board. The US claimed it was a mistake as it took place
                during the heightened tensions of the Iran-Iraq war. Eventually in 1996 the US paid a financial
                settlement to stop the case going to The International Court of Justice. This puts into perspective the
                recent drone strike on Saudi oil facilities which resulted in no casualties.
              </p>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );

  const examplePortraitCommentary = (
    <div className="pdf-body-copy-wrap mar-t-2">
      <p className="pdf-body-copy">
        <strong>Super Mario's mini 'Bazooka' ignites recovery in cyclical assets</strong>
      </p>
      <img
        src="https://res.cloudinary.com/infuse-group/image/upload/v1582298971/Enhance/heat-map.png"
        alt="example-image"
        style={{ width: '100%' }}
      />
      <p className="pdf-body-copy">
        Central Banks around the world continue to cut interest rates in an uncoordinated but synchronised response to
        slowing global growth and fears of falling inflation expectations. Emerging markets have eased aggressively all
        year, led by India, in a push to offset deflationary pressure being exported from China. Several countries and
        regions contracted in the second quarter; Germany UK, Argentina, Hong Kong SAR, Russia and Singapore.
      </p>
      <p>
        In September Mario Draghi held his penultimate ECB meeting as the bank’s President, he leaves on 31 October and
        will be replaced by ex-IMF chief Christine Lagarde. Being ‘Super Mario’ he clearly wanted to leave the ECB with
        the Italian spirit of ‘Veni, Vidi, Vici’ and so launched a mini monetary policy ‘Bazooka’. It included lowering
        the deposit rate by 10bp to -0.5% with a tiered scale to protect bank profits, a third TLTRO package of
        subsidised loans for banks, an open ended QE program of €20bn per month and a shift in forward guidance from a
        ‘date’ to a ‘state’ implying interest rates won’t rise and QE won’t stop until the ECB hits its inflation
        target.
      </p>
      <p>
        The aggressive stimulus package from the ECB allowed the Fed to move again in September. The Fed cut rates for
        the second time this quarter, lowering the target range for the federal funds rate by another 25bp to 1.75 - 2
        percent. The accompanying Fed ‘Dot Plots’ showed the FOMC has no majority for further rates cuts in 2019 and
        2020. However, the market continues to disagree and is pricing in further easing. This suggests the Fed remains
        behind the curve primarily because after 50bp of rate cuts year to date the US yield curve has not steepened and
        the USD remains strong.
      </p>
      <p>
        By contrast the PBOC has only eased a little so far this year via a small reduction in the base interest rate
        and reserve requirement ratio. Economists expect the PBOC to begin easing further to offset the continuing
        negative effect that tariffs are having on economic growth. The US has placed tariffs on approximately US$350bn
        worth of goods imported from China and in December, President Trump is expected to place tariffs on the
        remaining US$150bn of Chinese imports. Eighteen months into the China-US trade dispute and US equities have
        outperformed Chinese equities by 34% since the start of the ‘trade war’. The problem however of expectations of
        any meaningful further easing is that Beijing is fully aware that tariffs of 5%, 10% or even 25% on all Chinese
        exports to the US is not enough to hurt China economically; $500bn at 25% tariff would be $125bn which is only
        around 1% of China’s $12 trillion economy. Beijing is also concerned about China’s current debt levels, given
        Japan’s two-decade battle with debt deflation, and therefore is being cautious.
      </p>
      <p>
        Globally inflation remains ‘barbell shaped’. On one side there are high inflation areas in Venezuela
        (2,800,000%), Argentina (54%), Iran (42%) and Turkey (15%) and on the other side deflation areas in the UAE
        (-2.2%) and Saudi Arabia (-1.3%). The rest, excluding smaller frontier markets, are generally between 0-2%. The
        high inflation countries suffer from a vicious circle of money printing to finance government deficits, which
        results in a sharp currency depreciation, a spike in inflation, severe interest rate hikes, a collapse in
        economic growth and more money printing... Whereas the deflation countries are being affected by exogenous not
        endogenous variables. It is not a coincidence that the UAE and Saudi Arabia are in deflation, as the AED (UAE
        Dirham) and SAR (Saudi Riyal) are pegged to a strong USD which is causing deflationary pressure at a time when
        oil prices are also weak. The USD is strong because of a combination of relatively tight monetary policy, loose
        fiscal policy and safe-haven demand status.
      </p>
    </div>
  );

  return (
    <div>
      <h3 className="pdf-page-title">Market Commentary</h3>
      {isLandscape ? exampleLandscapeCommentary : examplePortraitCommentary}
    </div>
  );
}
