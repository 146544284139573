import * as React from 'react';

import Row from './Row';

export default function rows({ canEdit, dummyContainerRef, templateRows }) {
  if (!templateRows) return <div style={{ height: '100%' }} />;

  const rows = templateRows.map((row, index) => {
    return <Row key={row.id} {...{ canEdit, dummyContainerRef, row, index }} />;
  });

  return <div>{rows}</div>;
}
