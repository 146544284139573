import { Attr, Model } from 'spraypaint';
import { ApplicationRecord } from './ApplicationRecord';

@Model()
export class ReportTemplateTheme extends ApplicationRecord {
  public static jsonapiType = 'report_template_themes';
  // Typography
  @Attr() public fontSource: string;
  @Attr() public fontName: string;

  // Primary color
  @Attr() public primaryColor: string;
  @Attr() public secondaryColor: string;
  @Attr() public tertiaryColor: string;

  // Asset class colors
  @Attr() public cashColor: string;
  @Attr() public fixedIncomeColor: string;
  @Attr() public equityColor: string;
  @Attr() public hedgeFundColor: string;
  @Attr() public otherColor: string;

  // Currency Colors
  @Attr() public gbpColor: string;
  @Attr() public usdColor: string;
  @Attr() public eurColor: string;
  @Attr() public jpyColor: string;
  @Attr() public hkdColor: string;
  @Attr() public chfColor: string;
  @Attr() public sgdColor: string;
  @Attr() public zarColor: string;
  @Attr() public cadColor: string;
  @Attr() public audColor: string;
  @Attr() public nzdColor: string;
  @Attr() public sekColor: string;
  @Attr() public dkkColor: string;
  @Attr() public tbcColor: string;
  @Attr() public othColor: string;

  // Secondary colors
  @Attr() public secondaryColorOne: string;
  @Attr() public secondaryColorTwo: string;
  @Attr() public secondaryColorThree: string;
  @Attr() public secondaryColorFour: string;
  @Attr() public secondaryColorFive: string;

  @Attr() public secondaryColorSix: string;
  @Attr() public secondaryColorSeven: string;
  @Attr() public secondaryColorEight: string;
  @Attr() public secondaryColorNine: string;
  @Attr() public secondaryColorTen: string;

  // Header colors, images & heights
  @Attr() public headerBackground: string;
  @Attr() public headerBackgroundId: string;
  @Attr() public headerBackgroundName: string;
  @Attr() public headerColor: string;
  @Attr() public headerBorderColor: string;
  @Attr() public headerFontColor: string;
  @Attr() public headerHeight: string;
  @Attr() public headerLogoPosition: string;
  @Attr() public headerLogoHeight: number;
  @Attr() public titleLetterSpacing: number;
  @Attr() public titleFirstLine: string;
  @Attr() public titleSecondLine: string;
  @Attr() public titleThirdLine: string;
  @Attr() public titleUppercase: boolean;

  // Footer colors and images
  @Attr() public footerBackground: string;
  @Attr() public footerBackgroundId: string;
  @Attr() public footerBackgroundName: string;
  @Attr() public footerColor: string;
  @Attr() public footerFontColor: string;
  @Attr() public footerBorderColor: string;

  // Logo
  @Attr() public logo: string;
  @Attr() public logoId: string;
  @Attr() public logoName: string;

  // Header Logo
  @Attr() public headerLogo: string;
  @Attr() public headerLogoId: string;
  @Attr() public headerLogoName: string;

  // General
  @Attr() public address: string;
  @Attr() public benchmarkName: string;
  @Attr() public name: string;
  @Attr() public phone: string;
  @Attr() public website: string;
}
