import * as React from 'react';
import * as $ from 'jquery';

import lodashDebounce from 'lodash.debounce';
import { SortingRule } from 'react-table';

import Table from 'components/shared/reactTable/Table';
import OldCsvExport from './reactTable/OldCsvExport';
import { useCompare } from './customHooks/useCompare';
import { createAlert } from './Utils';

interface IProps {
  columns: any;
  defaultSorting: { attribute: string; direction: string };
  filters?: any;
  getTrProps?: any;
  formatting?: any;
  path: string;
  searchTerm?: string;
  selectable?: boolean;
  shouldUpdateTable?: boolean;
  showFilterAlert?: boolean;
  isExportable?: boolean;
  handleUpdatedSelection?(selection: string[]): void;
}

function reducer(state, action) {
  if (action.id) {
    return { selection: [...state.selection.toggle(action.id)] };
  }
  if (action.ids) {
    return { selection: [...action.ids] };
  }
}

export default function tableWrapper(props: IProps) {
  const { showFilterAlert } = props;

  const debouncedGetData = React.useCallback(lodashDebounce(getData, 100), []);

  const [data, setData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [meta, setMeta] = React.useState(null);
  const [state, dispatch] = React.useReducer(reducer, { selection: [] });
  // This checks if the component is mounted so that when we make AJAX requests we can check if the component is still
  // mounted before setting state. Set state is asynchronus so needs to just be a normal variable
  let isMounted = true;

  React.useEffect(() => {
    return () => {
      isMounted = false;
    };
  }, []);

  const [tableAttributes, setTableAttributes] = React.useState({
    filters: props.filters || { filters: {} },
    page: 1,
    pageSize: 20,
    path: props.path,
    sorting: props.defaultSorting,
  });

  const hasFiltersChanged = useCompare(tableAttributes.filters);

  React.useEffect(() => {
    if (hasFiltersChanged && showFilterAlert) {
      if (
        (Object.keys(tableAttributes.filters).length === 0 && tableAttributes.filters.constructor === Object) ||
        tableAttributes.filters === props.filters
      )
        return;

      createAlert('success', 'Filters applied', 1000);
    }
  }, [hasFiltersChanged]);

  React.useEffect(() => {
    debouncedGetData(tableAttributes, props.searchTerm);
  }, [tableAttributes]);

  React.useEffect(() => {
    if (props.shouldUpdateTable) {
      debouncedGetData(tableAttributes, props.searchTerm);
    }
  }, [props.shouldUpdateTable]);

  React.useEffect(() => {
    if (data && meta) {
      setIsLoading(false);
    }
  }, [data, meta]);

  function handleApplyFilters(key: string, values: string[]) {
    const newFilters = Object.create(tableAttributes.filters);
    newFilters[key] = values;
    if (tableAttributes.filters === newFilters) return;

    setTableAttributes({ ...tableAttributes, filters: newFilters });
  }

  function handlePageSizeChange(pageSize: number) {
    if (tableAttributes.pageSize === pageSize) return;

    setTableAttributes({ ...tableAttributes, pageSize });
  }

  function handlePageChange(page: number) {
    if (tableAttributes.page === page) return;

    setTableAttributes({ ...tableAttributes, page });
  }

  function handleSort(sorting: SortingRule[]) {
    const { id, desc } = sorting[0];
    const direction = desc ? 'desc' : 'asc';
    setTableAttributes({ ...tableAttributes, sorting: { direction, attribute: id } });
  }

  React.useEffect(() => {
    if (props.handleUpdatedSelection) {
      props.handleUpdatedSelection(state.selection);
    }
  }, [state]);

  function handleUpdateSelection(value: string) {
    typeof value === 'string' ? dispatch({ id: value }) : dispatch({ ids: value });
  }

  function getData(tableAttributes, searchTerm) {
    $.ajax({
      beforeSend: () => setIsLoading(true),
      cache: false,
      data: {
        filters: tableAttributes.filters,
        page: tableAttributes.page,
        page_size: tableAttributes.pageSize,
        search: searchTerm,
        sorting: tableAttributes.sorting,
      },
      dataType: 'json',
      success: (response) => {
        if (!isMounted) return;

        setData(response.data);
        setMeta(response.meta);
      },
      type: 'GET',
      url: tableAttributes.path,
    });
  }

  function csvExport() {
    if (props.isExportable) {
      return (
        <OldCsvExport
          columns={props.columns}
          filters={tableAttributes.filters}
          formatting={props.formatting}
          searchTerm={props.searchTerm}
          sorting={tableAttributes.sorting}
          meta={meta}
          path={tableAttributes.path}
        />
      );
    }
  }

  return (
    <Table
      columns={props.columns}
      csvExport={csvExport()}
      data={data}
      getTrProps={props.getTrProps}
      handleApplyFilters={handleApplyFilters}
      handlePageChange={handlePageChange}
      handlePageSizeChange={handlePageSizeChange}
      handleSort={handleSort}
      handleUpdateSelection={handleUpdateSelection}
      isLoading={isLoading}
      meta={meta}
      selectable={props.selectable}
    />
  );
}

tableWrapper.defaultProps = {
  showFilterAlert: true,
};
