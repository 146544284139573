import * as React from 'react';

import { groupBy, last, startCase, reject, mapValues } from 'lodash';

import { Security, SecurityState, SecurityStateClassificationWeighting } from 'javascript/models';

import finmasonLogo from 'images/logos/FINMASONlogo_transparentbg_crop.png';
import enhanceLogo from 'images/logos/logo_grey_2.svg';

import {
  IClassificationSelectOption,
  IClassificationSelectProps,
  IGroupedClassificationStates,
} from 'components/interfaces/SecurityInterfaces';

import useVisibility from 'components/shared/customHooks/useVisibility';
import { createAlert, graphitiErrorString } from 'components/shared/Utils';

import ReferenceDateSelector from './securityClassificationsForm/ReferenceDateSelector';
import ClassificationEdit from './securityClassificationsForm/ClassificationEdit';
import Confirm from './securityClassificationsForm/Confirm';

interface IProps {
  createDraftClassificationState: any;
  isSubmitting: boolean;
  groupedClassificationStates: IGroupedClassificationStates;
  security: Security;
}

const classificationSelectOptions: (classificationStates: SecurityState[]) => IClassificationSelectOption[] = (
  classificationStates,
) =>
  classificationStates.map((classificationState) => ({
    label: classificationState.status,
    value: classificationState,
  }));

const SecurityClassificationsForm = (props: IProps) => {
  const {
    createDraftClassificationState: createDraftClassificationStateProp,
    groupedClassificationStates,
    security,
  } = props;

  const [selectedDate, setSelectedDate] = React.useState(new Date(security.currentState.referenceDate));
  const [selectedState, setSelectedState] = React.useState(security.currentState);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const {
    isOpen: publishAlertOpen,
    handleClose: handlePublishAlertClose,
    handleOpen: handlePublishAlertOpen,
  } = useVisibility(false);
  const {
    isOpen: verifyAlertOpen,
    handleClose: handleVerifyAlertClose,
    handleOpen: handleVerifyAlertOpen,
  } = useVisibility(false);

  selectedState.cloneClassificationWeightingsIfUnset();

  const selectedClassificationStateOption = React.useMemo(
    () => ({
      label: selectedState.status,
      value: selectedState,
    }),
    [selectedState],
  );

  const dates = Object.keys(groupedClassificationStates);

  const selectedStateGroup = React.useMemo(() => groupedClassificationStates[selectedDate.toDateString()] || [], [
    selectedDate,
  ]);

  React.useEffect(() => {
    setSelectedDate(new Date(selectedState.referenceDate));
  }, [groupedClassificationStates]);

  const classificationStatesOptions = React.useMemo(() => classificationSelectOptions(selectedStateGroup), [
    selectedStateGroup,
  ]);

  const createDraftClassificationState = (referenceDate: Date, baseState?: SecurityState) =>
    setSelectedState(createDraftClassificationStateProp(referenceDate, baseState));

  const handleSelectedClassificationChange = (option: IClassificationSelectOption) => setSelectedState(option.value);

  const handleDateChange = (date: string) => {
    setSelectedDate(new Date(date));
    const availableClassificationStates = groupedClassificationStates[new Date(date).toDateString()];
    const liveClassificationState = availableClassificationStates.find((state) => state.status === 'live');
    setSelectedState(liveClassificationState ? liveClassificationState : last(availableClassificationStates));
  };

  const validateInput = () => {
    const liveClassifications: SecurityStateClassificationWeighting[] = reject(
      selectedState.classificationWeightings,
      'isMarkedForDestruction',
    );

    const undefinedClassification: boolean = liveClassifications
      .flatMap((esv) => esv.classification)
      .includes(undefined);

    if (undefinedClassification) {
      createAlert('error', 'You cannot submit a blank classification (This occured in ', 2500);
      return false;
    }

    const valueTypeWeights = mapValues(
      groupBy(liveClassifications, 'valueType'),
      (esvArray: SecurityStateClassificationWeighting[]) =>
        esvArray.reduce(
          (totalWeight: number, securityValue: SecurityStateClassificationWeighting) =>
            totalWeight + securityValue.weighting,
          0,
        ),
    );

    if (Object.values(valueTypeWeights).every((weight: number) => weight.toFixed(2) === '1.00')) {
      return true;
    }

    return false;
  };

  const handleConfirmation = (verification) => (verification ? handleVerifyAlertOpen : handlePublishAlertOpen);

  const handleDraftSave = () => handleSubmit(false, false);

  const handleVerify = () => handleSubmit(false, true);

  const confirmSubmit = () => handleSubmit(true, true);

  const handleSubmit = async (publish: boolean, verify: boolean) => {
    setIsSubmitting(true);
    if (!validateInput()) {
      setIsSubmitting(false);
      createAlert('error', 'Classifications do not sum to 100', 2500);
      return null;
    }

    if (publish) {
      const liveStates = selectedStateGroup.find((state) => state.status === 'live');
      if (liveStates) {
        liveStates.status = 'superseded';
        liveStates.dateSuperseded = new Date();
      }
      selectedState.status = 'live';
      selectedState.dateSuperseded = null;
    }

    if (verify) {
      selectedState.verified = true;
    }

    const success = await selectedState.save({
      displayQueryResult: true,
      with: [{ classificationWeightings: 'classification.id' }, 'security.id', 'author.id'],
    });
    setIsSubmitting(false);

    if (!success) return;

    const alertText = publish ? 'Security Classification successfully published.' : 'Draft successfully saved.';
    createAlert('success', alertText, 1500);
  };

  const handleDelete = async () => {
    setIsSubmitting(true);
    const status = selectedState.status;
    const classifiedBy = selectedState.classifiedBy;
    if (
      (status === 'superseded' && classifiedBy !== 'fin_mason') ||
      (classifiedBy === 'enhance' && status !== 'live')
    ) {
      const success = await selectedState.destroy();
      if (success) {
        createAlert('success', 'Successfully deleted.', 1500);
        setIsSubmitting(false);
        window.location.reload();
      } else {
        createAlert('error', graphitiErrorString(selectedState), 2500);
      }
    }
  };

  const logoStyle = { height: '15px', width: '15px' };

  const logoSwitch = (classifiedBy: string) => {
    const imgProps = {
      enhance: { src: enhanceLogo, alt: 'enhance' },
      fin_mason: { src: finmasonLogo, alt: 'finmason' },
    }[classifiedBy];

    return <img style={logoStyle} {...imgProps} />;
  };

  const formatOptionLabel = ({ value }) => (
    <div>
      <div className="frow col-sm-1-1 ">
        <div className="mar-r-1">{value.classifiedBy && logoSwitch(value.classifiedBy)}</div>
        <div>
          {value.createdAt ? `Created at: ${new Date(value.createdAt).toLocaleDateString()}, ` : ''}
          {startCase(value.status)}
        </div>
        <div>{value.author && `, Author: ${value.author.fullName}`}</div>
      </div>
    </div>
  );

  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      height: 42,
      width: 480,
    }),
    option: (provided) => ({
      ...provided,
      padding: 10,
    }),
    singleValue: (provided) => provided,
  };

  const selectProps: IClassificationSelectProps = {
    customSelectStyles,
    formatOptionLabel,
    handleChange: handleSelectedClassificationChange,
    options: classificationStatesOptions,
    id: 'selected_state',
    name: 'selected_state',
    theme: 'dark',
    value: selectedClassificationStateOption,
  };

  const classificationEditProps = {
    createDraftClassificationState,
    handleDateChange,
    isSubmitting,
    security,
    selectProps,
    selectedState,
    selectedStateGroup,
    submitHandlers: [handleConfirmation, handleDraftSave, confirmSubmit, handleDelete],
  };

  return (
    <React.Fragment>
      <div className="frow content-center">
        <div className="col-mc-1-8">
          <ReferenceDateSelector {...{ dates, selectedDate, handleDateChange }} />
        </div>
        <div className="col-mc-7-8 platform-content platform-content--padding-all">
          <ClassificationEdit {...classificationEditProps} />
        </div>
        <Confirm
          {...{
            alertOpen: publishAlertOpen,
            confirm: confirmSubmit,
            handleAlertClose: handlePublishAlertClose,
            isSubmitting,
          }}
        >
          <div>
            <h4 className="mar-t-0">Are you sure that you want to publish this draft?</h4>
            <p>Publishing this draft will supersede the live classification state for the selected date.</p>
          </div>
        </Confirm>
        <Confirm
          {...{
            alertOpen: verifyAlertOpen,
            confirm: selectedState.classifiedBy === 'fin_mason' ? confirmSubmit : handleVerify,
            handleAlertClose: handleVerifyAlertClose,
            isSubmitting,
          }}
        >
          <div>
            <h4 className="mar-t-0">Are you sure that you want to verify this classification?</h4>
            <p>Verifying this will supersede the live classification state for the selected date</p>
          </div>
        </Confirm>
      </div>
    </React.Fragment>
  );
};

export default SecurityClassificationsForm;
