import * as React from 'react';
import * as $ from 'jquery';

const reactRailsUJS = require('react_ujs');

import { platformAccountingDashboardReviewsPath } from 'javascript/application/ts_routes';

import Presenter from './reviewOverview/Presenter';

import IReviews from 'components/interfaces/IReviews';

interface IProps {
  companyId: number;
  quarter: number;
  year: number;
}

export default function reviewOverview(props: IProps) {
  const { companyId, quarter, year } = props;

  const admin = '*';
  const client = '*';
  // Needs to be underscored so we can send it straight through to the controller
  const client_type = '*';

  const [data, setData] = React.useState([]);
  const [valueData, setValueData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [totalValue, setTotalValue] = React.useState(null);
  const [totalCount, setTotalCount] = React.useState(null);

  React.useEffect(() => {
    document.addEventListener('dropdownFilters:change', updateReviews);
    getData({ admin, client, client_type, quarter, year });

    // Due to turbolinks and event listeners we need to unmount the whole component from the page in order to
    // avoid multiple listeners sticking around
    document.addEventListener('turbolinks:before-render', () => {
      reactRailsUJS.unmountComponents('.review-overview');
    });

    return () => {
      document.removeEventListener('dropdownFilters:change', updateReviews);
    };
  }, []);

  function getData(data = {}) {
    $.ajax({
      beforeSend: () => setIsLoading(true),
      cache: false,
      complete: () => setIsLoading(false),
      data,
      dataType: 'json',
      success: (response: IReviews) => {
        setData(response.meta.overview);
        setValueData(response.meta.accounting_value_overview);
        setTotalValue(response.meta.total_value);
        setTotalCount(response.meta.total);
      },
      type: 'GET',
      url: platformAccountingDashboardReviewsPath(),
    });
  }

  function updateReviews(event) {
    const { admin, client, client_type, jurisdiction, quarter, year } = event.detail;
    getData({ admin, client, client_type, jurisdiction, quarter, year });
  }

  return (
    <Presenter
      {...{ admin, client_type, companyId, data, valueData, isLoading, quarter, totalCount, totalValue, year }}
    />
  );
}
