import * as React from 'react';

import Context from './edit/Context';
import Presenter from './edit/Presenter';

import { UploadTemplate } from 'javascript/models';

interface IProps {
  attributeOptions: { value: string; label: string; type: string }[];
  dataFormatOptions: { value: string; label: string }[];
  managerId: string;
}

export default function edit(props: IProps) {
  const [uploadTemplates, setUploadTemplates] = React.useState([]);
  const { attributeOptions, dataFormatOptions, managerId } = props;

  React.useEffect(() => {
    getTemplates();
  }, []);

  async function getTemplates() {
    const { data } = await UploadTemplate.per(1000)
      .where({ manager_id: managerId, active: true })
      .includes({
        upload_template_sheets: [
          'upload_template_additional_attributes',
          { upload_template_sheet_columns: 'upload_template_conditions' },
        ],
      })
      .all();

    setUploadTemplates(data);
  }

  function handleNewTemplate() {
    const newTemplate = new UploadTemplate({ active: true, manager_id: managerId });
    const success = newTemplate.save();

    if (success) {
      const newUploadTemplates = [...uploadTemplates];
      newUploadTemplates.push(newTemplate);
      setUploadTemplates(newUploadTemplates);
    }
  }

  async function handleDestroyTemplate(templateId) {
    const template = uploadTemplates.find((template) => template.id === templateId);
    const success = await template.destroy();
    if (success) {
      const newTemplates = [...uploadTemplates];
      const index = newTemplates.indexOf(template);
      newTemplates.splice(index, 1);
      setUploadTemplates(newTemplates);
    }
  }

  const contextValues = {
    actions: { getTemplates, handleDestroyTemplate, handleNewTemplate },
    props: { attributeOptions, dataFormatOptions, managerId },
    state: { uploadTemplates },
  };

  return (
    <Context.Provider value={contextValues}>
      <Presenter />
    </Context.Provider>
  );
}
