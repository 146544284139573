import * as React from 'react';

export default function customHeader({ reportTemplate }) {
  const { reportTemplateTheme } = reportTemplate;

  const {
    headerBackground,
    headerBorderColor,
    headerColor,
    headerFontColor,
    headerHeight,
    headerLogo,
    headerLogoPosition,
    headerLogoHeight,
    logo,
    name,
    titleFirstLine,
    titleLetterSpacing,
    titleSecondLine,
    titleThirdLine,
    titleUppercase,
  } = reportTemplateTheme;

  const padding = 120 * (headerHeight / 100);

  const headerStyles = {
    backgroundColor: headerColor,
    backgroundImage: `url('${headerBackground}')`,
    borderColor: headerBorderColor,
    height: `${headerHeight}%`,
    padding: `${padding}px 40px`,
  };

  const fontColor = {
    color: headerFontColor,
  };

  const fontStyles: React.CSSProperties = {
    color: headerFontColor,
    letterSpacing: `${titleLetterSpacing}px`,
    textTransform: titleUppercase ? 'uppercase' : 'none',
  };

  const headerWrapStyles: React.CSSProperties = {
    left: '40px',
    right: 'auto',
    top: `${padding}px`,
  };

  if (headerLogoPosition === 'right') {
    headerWrapStyles.left = 'auto';
    headerWrapStyles.right = '40px';
  }

  const logoStyles: React.CSSProperties = {
    height: `${headerLogoHeight}px`,
    width: 'auto',
  };

  function renderLogo() {
    if (logo || headerLogo) {
      const logoSrc = headerLogo || logo;
      return (
        <div className="pdf-header__logo-wrap" style={headerWrapStyles}>
          <img src={logoSrc} alt="" className="pdf-header__logo" style={logoStyles} />
        </div>
      );
    }

    return (
      <div className="pdf-header__logo-wrap" style={headerWrapStyles}>
        {!logo && (
          <h2 style={fontColor} className="pdf-page-title">
            {name}
          </h2>
        )}
      </div>
    );
  }

  return (
    <header className="pdf-header pdf-header--custom" style={headerStyles}>
      {renderLogo()}
      <table className="pdf-hidden-table" style={{ height: '100%' }}>
        <tbody>
          <tr>
            <td style={{ verticalAlign: 'bottom' }}>
              <h1 className="pdf-title text-bold text-white" style={fontStyles}>
                {titleFirstLine}
              </h1>
              <div className="pdf-subtitle mar-t-1" style={fontStyles}>
                {titleSecondLine}
              </div>
              <div className="pdf-tiny-subtitle mar-t-1" style={fontStyles}>
                {titleThirdLine}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </header>
  );
}
