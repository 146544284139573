import * as React from 'react';

import OutsideClickHandler from 'react-outside-click-handler';

const classNames = require('classnames');

interface IProps {
  csvExport?: any;
  columnHeaders: string[];
  selectedColumns: string[];
  handleColumnSelect(column: string): void;
}

export default function columnSelector(props: IProps) {
  const { selectedColumns } = props;

  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const { columnHeaders } = props;

  const dropdownClasses = classNames('dropdown dropdown--floating dropdown--sw', { 'dropdown--active': isOpen });

  function handleClick(event) {
    event.stopPropagation();
    setIsOpen(!isOpen);
  }

  const columnOptions = columnHeaders
    .filter((header) => typeof header === 'string')
    .map((header, index) => {
      const handleColumnSelect = () => props.handleColumnSelect(header);

      return (
        <div className="dropdown__nav-link" key={`${index}_${selectedColumns.includes(header)}`}>
          <div className="pretty p-icon">
            <input type="checkbox" defaultChecked={selectedColumns.includes(header)} onChange={handleColumnSelect} />
            <div className="state p-blue">
              <i className="icon-tick icon" />
              <label>{header}</label>
            </div>
          </div>
        </div>
      );
    });

  function handleDropdownClick(event) {
    event.stopPropagation();
  }

  function handleOutsideClick() {
    setIsOpen(false);
  }

  return (
    <div className={dropdownClasses} style={{ width: '100%' }} onClick={handleDropdownClick}>
      <OutsideClickHandler onOutsideClick={handleOutsideClick}>
        <div className="dropdown__toggle" onClick={handleClick}>
          <div className="text-right">
            <i className="icon-overflow-vertical-white icon-clickable text-grey icon-1-5x mar-r-1" />
          </div>
        </div>
        <div className="dropdown__content dropdown__content--with-arrow" style={{ minWidth: '200px', width: '200px' }}>
          <div className="dropdown__content-inner">
            <div className={`platform-content ${props.csvExport ? 'platform-content--border-bottom' : ''} `}>
              <p className="subtitle-two mar-a-2">Columns</p>
              {columnOptions}
            </div>
          </div>
          {props.csvExport}
        </div>
      </OutsideClickHandler>
    </div>
  );
}
