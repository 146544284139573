import { Attr, BelongsTo, Model } from 'spraypaint';
import { ApplicationRecord } from './ApplicationRecord';

@Model()
export class InvestmentRiskAssessment extends ApplicationRecord {
  public static jsonapiType = 'investment_risk_assessments';
  @Attr() public administratorId: boolean;
  @Attr() public assessableId: string;
  @Attr() public assessableType: string;
  @Attr() public createdAt: string;
  @Attr() public currency: string;
  @Attr() public documentType: string;
  @Attr() public downloadPath: string;
  @Attr() public fileName: string;
  @Attr() public users: string;
  @Attr() public ownerName: string;
  @Attr() public ownableId: string;
  @Attr() public ownableType: string;
}
