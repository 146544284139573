import * as React from 'react';

export default function repeating({ templateSection }) {
  const preview = !templateSection;

  const wrapperStyles = { border: '1px dashed #CF7838' } as any;
  if (preview) {
    wrapperStyles.width = '100%';
    wrapperStyles.height = '320px';
    wrapperStyles.marginBottom = 0;
  }

  const previewDiv = (
    <div className="frow frow--centered-column h-100">
      <div className="pad-a-2">
        <p className="pdf-body-copy">Repeating Page</p>
      </div>
    </div>
  );

  return <div style={wrapperStyles}>{preview ? previewDiv : 'Repeating Section'}</div>;
}
