import React from 'react';
import useRadioSelect from 'components/shared/customHooks/useRadioSelect';

export type PortfolioType = 'emap' | 'cpar' | 'all';
export type PortfolioStatus = 'live' | 'component' | 'combined' | 'consolidated';

interface IRadioToggleOption {
  label: string;
  value: PortfolioStatus[];
  reviewProgressFilter: PortfolioType;
}

interface IEmapCparRadioTogglesProps {
  emapReviews: number;
  cparReviews: number;
  totalReviews: number;
  portfolioType: PortfolioType;
  setPortfolioType: React.Dispatch<React.SetStateAction<PortfolioType>>;
  filterState: [string[], React.Dispatch<React.SetStateAction<any>>];
}

const EmapCparRadioToggles = ({
  emapReviews,
  cparReviews,
  totalReviews,
  portfolioType,
  setPortfolioType,
  filterState,
}: IEmapCparRadioTogglesProps) => {
  const [portfolioTypeFilters, setPortfolioTypeFilters] = filterState;

  const radioToggleOptions: IRadioToggleOption[] = [
    { label: `EMAP (${emapReviews})`, value: ['live', 'component', 'combined'], reviewProgressFilter: 'emap' },
    { label: `CPAR (${cparReviews})`, value: ['consolidated'], reviewProgressFilter: 'cpar' },
    {
      label: `All (${totalReviews})`,
      reviewProgressFilter: 'all',
      value: ['live', 'component', 'combined', 'consolidated'],
    },
  ];

  const [radioToggle, selectedRadioRef, resetRadioSelection] = useRadioSelect(radioToggleOptions, 2);

  React.useEffect(() => {
    if (!portfolioTypeFilters) resetRadioSelection();
  }, [portfolioTypeFilters]);

  React.useEffect(() => {
    const toggledOption = radioToggleOptions[selectedRadioRef.current];

    if (toggledOption.reviewProgressFilter === portfolioType) return;
    setPortfolioType(toggledOption.reviewProgressFilter);

    if (!portfolioTypeFilters && toggledOption.reviewProgressFilter === 'all') return;
    setPortfolioTypeFilters(toggledOption.value);
  }, [selectedRadioRef.current]);

  return radioToggle;
};

export default EmapCparRadioToggles;
