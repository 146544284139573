import * as React from 'react';

import * as ReactTooltip from 'react-tooltip';

import Edit from './rating/Edit';

const classNames = require('classnames');

import ReviewContext from './ReviewContext';

export default function rating() {
  const reviewContext = React.useContext(ReviewContext);
  const { review } = reviewContext.props;

  const reviewRating = review.attributes.rating.data.attributes;

  const ratingValue = reviewRating.value ? reviewRating.value : '-';

  function fillColor() {
    switch (Math.round(ratingValue)) {
      case 1:
        return 'red';
      case 2:
        return 'orange';
      case 3:
        return 'yellow';
      case 4:
        return 'lime';
      case 5:
        return 'green';
      default:
        return 'grey';
    }
  }

  const metricClasses = classNames('metric', 'metric--promoted', 'metric--fixed-height', `metric--${fillColor()}-fill`);

  function changeAlert() {
    if (!reviewRating.manuallyAmendedAt) return;

    return (
      <div data-tip="This rating has been manually amended" data-for="rating-tooltip">
        <span className="alert-icon alert-icon--error" />
        <ReactTooltip effect="solid" id="rating-tooltip" />
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className="frow frow--justify-between frow--items-center mar-b-1">
        <p className="subtitle-two mar-v-0">Rating</p>
        {changeAlert()}
      </div>
      <div className={metricClasses}>
        <p className="metric__value">{ratingValue}</p>
        <Edit />
      </div>
    </React.Fragment>
  );
}
