import * as React from 'react';

import * as ReactTooltip from 'react-tooltip';
import { format, parse } from 'date-fns';

export default function columns() {
  return [
    {
      Header: 'Name',
      accessor: 'attributes.name',
      columnName: 'Name',
      sortable: false,
    },
    {
      Header: 'Additional Reference',
      accessor: 'attributes.additionalReference',
      columnName: 'Additional Reference',
      sortable: false,
    },
    {
      Header: 'Category',
      accessor: 'attributes.category',
      columnName: 'Category',
      sortable: false,
    },
    {
      Cell: (row) => {
        if (!row.original.attributes.currency) return <div />;
        return <div>{row.original.attributes.currency.toUpperCase()}</div>;
      },
      Header: 'Currency',
      accessor: 'attributes.currency',
      columnName: 'Currency',
      sortable: false,
    },
    {
      Cell: (row) => {
        return <div>{row.original.attributes.status}</div>;
      },
      Header: 'Status',
      accessor: 'attributes.status',
      columnName: 'Status',
      sortable: false,
    },
    {
      Cell: (row) => {
        return (
          <div data-tip={row.original.attributes.client}>
            {row.original.attributes.client}
            <ReactTooltip effect="solid" delayShow={300} />
          </div>
        );
      },
      Header: 'Client',
      accessor: 'attributes.client',
      columnName: 'Client',
      sortable: false,
    },
    {
      Header: 'Investment Manager',
      accessor: 'attributes.investmentManager',
      columnName: 'Investment Manager',
      sortable: false,
    },
    {
      Header: 'Entity',
      accessor: 'attributes.entity',
      columnName: 'Entity',
      sortable: false,
    },
    {
      Cell: (row) => {
        if (!row.original.attributes.clientTypeObject) {
          return <div />;
        }
        return (
          <div className="frow frow--items-center">
            <img
              alt="Client type icon"
              src={row.original.attributes.clientTypeObject.icon}
              className="mar-r-1 client-type-table-icon"
            />
          </div>
        );
      },
      Header: 'Client Type',
      columnName: 'Client Type',
      sortable: false,
    },
    {
      Cell: (row) => {
        return <div>{format(parse(row.original.attributes.updatedAt), 'DD MMM YYYY')}</div>;
      },
      Header: 'Last Updated',
      accessor: 'attributes.updatedAt',
      columnName: 'Last Updated',
      id: 'updated_at',
      sortable: false,
    },
    {
      Cell: (row) => {
        return (
          <a href={row.original.links.showPath} style={{ display: 'block' }}>
            <div className="rt-tr-clickable-icon">
              <i className="icon-arrow-right-top icon-0-8x" />
            </div>
          </a>
        );
      },
      Header: '',
      sortable: false,
    },
  ];
}
