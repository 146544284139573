import * as React from 'react';
import turbolinks from 'turbolinks';

import useGraphitiForm from 'components/shared/customHooks/useGraphitiForm';

import { User } from 'javascript/models';
import { graphitiErrorString } from 'components/shared/Utils';
import useVisibility from 'components/shared/customHooks/useVisibility';
import { platformUserPath } from 'javascript/application/ts_routes';
import FormJsx from './FormJsx';

interface IForm {
  portalManager: boolean;
  user: User;
}

const form = ({ portalManager, user }: IForm) => {
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [users, setUsers] = React.useState([]);

  const { isOpen, handleClose, handleOpen } = useVisibility(false);

  const { resource, handleChange, handleManualChange, handleSubmit } = useGraphitiForm(user, handleFormSubmission);

  async function handleFormSubmission(_) {
    setIsSubmitting(true);
    // Check if this user is like any others
    const searchCriteria = [resource.firstName, resource.lastName];
    if (resource.email) {
      searchCriteria.push(resource.email);
    }
    const { data } = await User.where({ search: { match: searchCriteria } }).all();

    setUsers(data);

    if (data.length > 0 && !user.isPersisted) {
      handleOpen();
    } else {
      updateResource();
    }
  }

  async function updateResource() {
    const success = await resource.save();
    setIsSubmitting(false);

    if (success) {
      turbolinks.visit(platformUserPath(resource.id));
    } else {
      setErrorMessage(graphitiErrorString(resource));
    }
  }

  function handleAlertClose() {
    setIsSubmitting(false);
    handleClose();
  }

  const formattedUsers = users.map((user) => (
    <div className="col-mc-1-1" key={user.id}>
      <div className="platform-content platform-content--border-bottom-grey text-grey pad-a-1">
        <a className="link link--blue" href={platformUserPath(user.id)}>
          {user.fullName} ({user.email ? user.email : 'No email'})
        </a>
      </div>
    </div>
  ));

  return (
    <FormJsx
      {...{
        errorMessage,
        handleManualChange,
        user,
        handleChange,
        handleSubmit,
        resource,
        portalManager,
        isSubmitting,
        isOpen,
        handleAlertClose,
        handleClose,
        updateResource,
        formattedUsers,
      }}
    />
  );
};

export default form;
