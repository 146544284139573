import * as React from 'react';

import lodashStartcase from 'lodash.startcase';
import ReactSelect from 'components/shared/forms/ReactSelect';

import FormRow from 'components/shared/forms/FormRow';
import Context from '../Context';

export default function documentInfo() {
  const context = React.useContext(Context);

  const { portfolio } = context.state;
  const { mandateDocTypeOptions } = context.props;
  const { dateSigned, docType, documentSigned } = portfolio.portfolioMandate;

  return (
    <fieldset className="form__fieldset">
      <h4 className="form__fieldset-label">Document Information</h4>
      <div className="frow frow--gutters">
        <FormRow note="" className="col-mc-1-1">
          <label htmlFor="doc_type" className="form__label">
            Document Type
          </label>
          <ReactSelect
            isClearable
            theme="dark"
            name="portfolio[portfolio_mandate_attributes][doc_type]"
            id="doc_type"
            options={mandateDocTypeOptions}
            defaultValue={{ label: lodashStartcase(docType), value: docType }}
          />
        </FormRow>
        <FormRow note="" className="col-mc-1-1">
          <label className="form__label">Document Signed?</label>
          <div className="pretty pretty--large pretty--with-label p-round">
            <input
              type="radio"
              name="portfolio[portfolio_mandate_attributes][document_signed]"
              value="1"
              defaultChecked={documentSigned}
            />
            <div className="state p-blue-circle">
              <label className="text-white">Yes</label>
            </div>
          </div>
          <div className="pretty pretty--large pretty--with-label p-round">
            <input
              type="radio"
              name="portfolio[portfolio_mandate_attributes][document_signed]"
              value="0"
              defaultChecked={!documentSigned}
            />
            <div className="state p-blue-circle">
              <label className="text-white">No</label>
            </div>
          </div>
        </FormRow>
        <FormRow note="" className="col-mc-1-1">
          <label htmlFor="date_signed" className="form__label">
            Date Signed
          </label>
          <input
            type="date"
            id="date_signed"
            name="portfolio[portfolio_mandate_attributes][date_signed]"
            defaultValue={dateSigned}
          />
        </FormRow>
      </div>
    </fieldset>
  );
}
