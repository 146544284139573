import { Attr, Model } from 'spraypaint';
import { ApplicationRecord } from './ApplicationRecord';

@Model()
export class DataChaseTemplate extends ApplicationRecord {
  public static jsonapiType = 'data_chase_templates';
  @Attr() public companyId: string;
  @Attr() public body: string;
  @Attr() public emailType: string;
  @Attr() public lastTriggeredAt: string;
  @Attr() public updatedAt: string;
  @Attr() public subject: string;
}
