import * as React from 'react';

import { format, parse } from 'date-fns';

import { Note } from 'javascript/models';
import { Frow, Column } from 'components/frow';

import AutoResizeTextArea from 'components/shared/forms/AutoResizeTextArea';
import Button from 'components/shared/forms/Button';
import Dropzone from 'components/shared/forms/Dropzone';

import Attachments from './Attachments';

interface IProps {
  currentUser: string;
  isSubmitting?: boolean;
  resource?: Note;
  handleChange?(event: any): void;
  onFileUpload(files): void;
}

const Form = (props: IProps) => {
  const { currentUser, resource } = props;

  return (
    <Frow gutterSize={2}>
      <Column columnSpan={2}>
        <Frow gutterSize={1}>
          <Column>
            <label htmlFor="title" className="form__label">
              Category
            </label>
            <div className="form__select-wrapper">
              <select
                name="note[noteCategory]"
                id="noteCategory"
                onChange={props.handleChange}
                value={resource.noteCategory}
              >
                <option value="">Please Select</option>
                <option value="email">Email</option>
                <option value="note">Note</option>
              </select>
            </div>
          </Column>
          <Column>
            <label htmlFor="title" className="form__label">
              Type
            </label>
            <div className="form__select-wrapper">
              <select name="note[note_type]" id="noteType" onChange={props.handleChange} value={resource.noteType}>
                <option value="">Please Select</option>
                <option value="general">General</option>
                <option value="action_point_response">Action Point Response</option>
                <option value="mandate">Mandate</option>
              </select>
            </div>
          </Column>
          <Column>
            <label htmlFor="title" className="form__label">
              Body
            </label>
            <AutoResizeTextArea
              id="body"
              name="note[body]"
              rows={10}
              value={resource.body}
              handleChange={props.handleChange}
            />
          </Column>
          <Column>
            <label htmlFor="title" className="form__label">
              Status
            </label>
            <div className="form__select-wrapper">
              <select name="note[status]" id="status" onChange={props.handleChange} value={resource.status}>
                <option value="">Please Select</option>
                <option value="normal">Normal</option>
                <option value="high_priority">High Priority</option>
              </select>
            </div>
          </Column>
          <Column>
            <label htmlFor="title" className="form__label">
              New Attachments
            </label>
            <Dropzone
              onFileUpload={props.onFileUpload}
              // handleRemoveFile={props.handleRemoveFile}
              multiple
            />
          </Column>
          <Column>
            <Button
              buttonClasses="button--full-width button--light-themed"
              style={{ minWidth: 'unset' }}
              text="Save"
              isSubmitting={props.isSubmitting}
              submittingText="Saving"
            />
          </Column>
        </Frow>
      </Column>
      <Column columnSpan={1}>
        <p className="mar-t-0 text-small">
          <span className="small-uppercase">Author</span>
          <br />
          <span className="text-black">{currentUser}</span>
        </p>
        <p className="mar-b-0 text-small">
          <span className="small-uppercase">Date</span>
          <br />
          <span className="text-black">{format(parse(resource.createdAt || new Date()), 'D MMM YYYY')}</span>
        </p>
        <Attachments note={resource} />
      </Column>
    </Frow>
  );
};

export default Form;
