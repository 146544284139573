import * as React from 'react';

export default function summary({ responseData }) {
  const { clients, errors, investment_managers, portfolios, entities, warnings } = responseData;

  const noErrors = <p className="text-white helper-text mar-v-0">There are no errors in the sheet</p>;

  const errorList = errors.map((error) => {
    return <li key={error}>{error}</li>;
  });

  const renderErrors = <ul className="list">{errorList}</ul>;

  const noWarnings = <p className="text-white helper-text mar-v-0">There are no warnings in the sheet</p>;

  const warningList = warnings.map((warning) => {
    return <li key={warning}>{warning}</li>;
  });

  const renderWarnings = <ul className="list">{warningList}</ul>;

  return (
    <React.Fragment>
      <h3 className="subtitle-one text-white mar-b-1">Warnings</h3>
      <div className="mar-b-2">{warnings.length > 0 ? renderWarnings : noWarnings}</div>
      <h3 className="subtitle-one text-white mar-b-1">Errors</h3>
      <div className="mar-b-2">{errors.length > 0 ? renderErrors : noErrors}</div>
      <h3 className="subtitle-one text-white mar-b-1">New Records</h3>
      <div className="frow mar-b-2">
        <div className="col-sm-1-3">
          <dl className="definition-list">
            <dt>New Clients</dt>
            <dd data-tip={clients.names.join(', ')}>{clients.count}</dd>
          </dl>
          <dl className="definition-list">
            <dt>New Entities</dt>
            <dd data-tip={entities.names.join(', ')}>{entities.count}</dd>
          </dl>
          <dl className="definition-list">
            <dt>New Investment Managers</dt>
            <dd data-tip={investment_managers.names.join(', ')}>{investment_managers.count}</dd>
          </dl>
          <dl className="definition-list">
            <dt>New Portfolios</dt>
            <dd>{portfolios.length}</dd>
          </dl>
        </div>
      </div>
    </React.Fragment>
  );
}
