import * as React from 'react';

import { portalClientPortfolioRiskReturnSeriesIndexPath } from 'javascript/application/ts_routes';

import ReactScatterChart from '../../../../shared/charting/recharts/ReactScatterChart';

import { assign, reject } from 'lodash';
import useRadioSelect from '../../../../shared/customHooks/useRadioSelect';
import useAjaxRequest from '../../../../shared/customHooks/useAjaxRequest';
import ClientPortalCard from '../../../shared/ClientPortalCard';
import { format } from 'date-fns';
import { IRequestParams } from './shared/types';
import Loading from '../../../../shared/Loading';
import ModalGraph from '../../../../modals/ModalGraph';
import { useClientPortalTheme } from 'components/shared/colours';
import { normaliseBenchmarkName } from 'components/shared/utilities/labelNormalisation';

export default function riskReturn({ currentReviewPeriod, portfolioObject }) {
  const { client_id, id, performancePeriodMonths } = portfolioObject;

  const chartDataURL = portalClientPortfolioRiskReturnSeriesIndexPath(client_id, id);

  const [chartData, setChartData] = React.useState();

  const noDataComponent = <p className="text-white helper-text mar-t-3 mar-b-4">No data to display</p>;

  const timePeriods = reject(portfolioObject.timePeriods, ({ value }) => ['3_months', '6_months'].includes(value));

  if (performancePeriodMonths < 12)
    return (
      <div className="col-md-1-2 hide-md-block">
        <ClientPortalCard title="Risk/Return">{noDataComponent}</ClientPortalCard>
      </div>
    );

  const [radioToggle, selectedRadioRef] = useRadioSelect(timePeriods, 1);
  const [getChartData, isError, isLoading] = useAjaxRequest<IRequestParams, any>({
    method: 'GET',
    path: chartDataURL,
    stateSetter: setChartData,
  });

  const requestParams = { time_period: undefined, review_period: currentReviewPeriod };
  if (timePeriods && typeof selectedRadioRef.current !== undefined) {
    requestParams.time_period = timePeriods[selectedRadioRef.current].value;
  }

  React.useEffect(() => {
    getChartData(requestParams);
  }, [currentReviewPeriod, selectedRadioRef.current]);

  const cardProps = {
    title: 'Risk / Return',
    subTitle: format(currentReviewPeriod, "DD MMM 'YY"),
    titleControls: radioToggle,
  };

  const getColour = useClientPortalTheme('benchmark');
  const colors =
    chartData &&
    assign(
      {},
      ...chartData.data.map((value) => {
        const label = value[0].name;
        return { [label]: getColour(normaliseBenchmarkName(label)) };
      }),
    );

  const scatterChartProps = {
    colors,
    series: chartData && chartData.data,
    yAxisName: chartData && chartData.y_axis,
    xAxisName: chartData && chartData.x_axis,
    height: 325,
    hasLegend: true,
  };

  const modalScatterProps = {
    height: 500,
  };

  const cardContent =
    performancePeriodMonths < 12 || isError ? (
      noDataComponent
    ) : (
      <>
        {isLoading ? <Loading /> : <ReactScatterChart {...scatterChartProps} />}
        <div className="mar-t-2">
          <ModalGraph title="Risk / Return">
            <>
              {radioToggle}
              <ReactScatterChart {...{ ...scatterChartProps, ...modalScatterProps }} />
            </>
          </ModalGraph>
        </div>
      </>
    );

  return (
    <div className="col-md-1-2 hide-md-block">
      <ClientPortalCard {...cardProps}>{cardContent}</ClientPortalCard>
    </div>
  );
}
