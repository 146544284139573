import * as React from 'react';
import * as $ from 'jquery';
import turbolinks from 'turbolinks';

const routes = require('../../../javascript/application/routes');

import { Alert, Intent } from '@blueprintjs/core';
import useVisibility from 'components/shared/customHooks/useVisibility';
import CsrfToken from 'components/shared/CsrfToken';
import { ajaxErrorString, createAlert } from 'components/shared/Utils';

export default function destroy({ destroyPath }) {
  const { isOpen, handleOpen, handleClose } = useVisibility(false);

  function confirmDelete() {
    $.ajax({
      data: { authenticity_token: CsrfToken() },
      dataType: 'json',
      error: (jqXhr) => createAlert('error', ajaxErrorString(jqXhr), 1500),
      success: () => turbolinks.visit(routes.platform_report_templates_path()),
      type: 'delete',
      url: destroyPath,
    });
  }

  return (
    <React.Fragment>
      <div className="dropdown__nav-link" onClick={handleOpen}>
        Delete
      </div>
      <Alert
        isOpen={isOpen}
        onClose={handleClose}
        canEscapeKeyCancel
        canOutsideClickCancel
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        intent={Intent.DANGER}
        onCancel={handleClose}
        onConfirm={confirmDelete}
        portalContainer={document.body}
      >
        <p className="mar-t-0">Are you sure you want to delete this report template?</p>
      </Alert>
    </React.Fragment>
  );
}
