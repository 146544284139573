import * as React from 'react';

import CountrySelect from 'components/shared/forms/CountrySelect';
import FormRow from 'components/shared/forms/FormRow';

import IEntity from 'components/interfaces/IEntity';

interface IProps {
  entity: { data: IEntity };
  shortForm: boolean;
}

export default function address(props: IProps) {
  const { entity, shortForm } = props;

  const { address1, address2, address3, address4, postcode, country } = entity.data.attributes;

  const addressLines = (
    <React.Fragment>
      <FormRow>
        <label htmlFor="address_2" className="form__label">
          Address line 2
        </label>
        <input type="text" name="entity[address_2]" defaultValue={address2} />
      </FormRow>
      <FormRow>
        <label htmlFor="address_3" className="form__label">
          Address line 3
        </label>
        <input type="text" name="entity[address_3]" defaultValue={address3} />
      </FormRow>
      <FormRow>
        <label htmlFor="address_4" className="form__label">
          Address line 4{' '}
        </label>
        <input type="text" name="entity[address_4]" defaultValue={address4} />
      </FormRow>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <FormRow>
        <label htmlFor="address_1" className="form__label">
          Address line 1
        </label>
        <input type="text" name="entity[address_1]" defaultValue={address1} />
      </FormRow>
      {!shortForm && addressLines}
      <FormRow>
        <label htmlFor="postcode" className="form__label">
          Postcode
        </label>
        <input type="text" name="entity[postcode]" defaultValue={postcode} />
      </FormRow>
      <FormRow>
        <label htmlFor="country" className="form__label">
          Country
        </label>
        <CountrySelect name="entity[country]" country={country} />
      </FormRow>
    </React.Fragment>
  );
}
