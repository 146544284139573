import React from 'react';
import { Frow } from 'components/frow';
import Checkbox from '../../Checkbox';

type Props = {
  name: string;
  flag: any;
};

const standardFlagTypes = [
  'ips_ima_held',
  'foreign_currency_exposure',
  'single_stock_concentration',
  'single_direct_bond_concentration',
  'single_fund_concentration',
  'non_investment_grade_bonds',
  'cash_tactical',
  'fixed_income_tactical',
  'equity_tactical',
  'hedge_fund_tactical',
  'other_tactical',
  'consecutive_ratings',
  'prior_action_point_status',
  'part_of_wider_structure',
];

const ConfigurationSettings = (props: Props) => {
  const { name, flag } = props;

  const flagEnabled = () => {
    if (flag.id) return flag.enabled;

    return standardFlagTypes.includes(flag.flagType);
  };

  return (
    <Frow>
      <Checkbox checked={flagEnabled()} label="Enabled" param={`${name}[enabled]`} classNames="col-md-1-3" />
      <Checkbox
        checked={flag.generateActionPoint}
        label="Generate action point"
        param={`${name}[generate_action_point]`}
        classNames="col-md-1-3"
      />
      <Checkbox
        checked={flag.inheritFromClient}
        label="Inherit from client"
        param={`${name}[inherit_from_client]`}
        classNames="col-md-1-3"
      />
    </Frow>
  );
};

export default ConfigurationSettings;
