import * as React from 'react';

import CustomModal from 'components/shared/CustomModal';
import useVisibility from 'components/shared/customHooks/useVisibility';

import FormRow from 'components/shared/forms/FormRow';

export default function edit({ handleClassificationUploadUpdate, classificationUpload }) {
  const { isOpen, handleClose, handleOpen } = useVisibility(false);
  const [fileName, setFileName] = React.useState(classificationUpload.fileName);
  const [description, setDescription] = React.useState(classificationUpload.description);

  function handleChange(event) {
    setFileName(event.target.value);
  }

  function handleDescriptionChange(event) {
    setDescription(event.target.value);
  }

  async function handleSubmit(event) {
    event.preventDefault();
    classificationUpload.fileName = fileName;
    classificationUpload.description = description;
    const success = await classificationUpload.save();

    if (success) {
      handleClose();
      handleClassificationUploadUpdate();
    }
  }

  return (
    <React.Fragment>
      <div className="button button--secondary button--icon mar-r-1" onClick={handleOpen}>
        <i className="icon-edit icon-fw" />
      </div>
      <CustomModal isOpen={isOpen} handleClose={handleClose} title="Edit Classification Upload">
        <div className="modal__content">
          <form className="form form--light" onSubmit={handleSubmit}>
            <div className="frow frow--gutters">
              <FormRow>
                <label htmlFor="" className="form__label">
                  File name
                </label>
                <input type="text" value={fileName} onChange={handleChange} />
              </FormRow>
              <FormRow>
                <label htmlFor="" className="form__label">
                  File description
                </label>
                <input
                  type="text"
                  name="classification_upload[description]"
                  onChange={handleDescriptionChange}
                  value={description}
                />
              </FormRow>
              <FormRow>
                <button className="button button--full-width button--light-themed">Update</button>
              </FormRow>
            </div>
          </form>
        </div>
      </CustomModal>
    </React.Fragment>
  );
}
