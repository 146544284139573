import React, { useState, useContext, useEffect } from 'react';

import Frow from 'components/frow/Frow';
import Loading from 'components/shared/Loading';
import StyledWarning from 'components/shared/styledAlerts/StyledWarning';
import { User } from 'javascript/models';

import { ActionPointContext } from '../../../Show';
import InProgressButton from './InProgressButton';

const InReviewActions = () => {
  const { isLoading, currentUser, client } = useContext(ActionPointContext);
  const [assignableUsers, setAssignableUsers] = useState<User[]>([]);

  if (isLoading) return <Loading />;

  const currentUserHasPermission = currentUser.hasPermission('action_point_responder', client.id);

  const getAssignableUsers = async () => {
    const users = await User.clientUsersWithPermission(client.id, 'action_point_closer');
    User.sortUsersByAttribute({ users, attr: 'firstName' });
    setAssignableUsers(users);
  };

  useEffect(() => {
    getAssignableUsers();
  }, []);

  if (!currentUserHasPermission)
    return (
      <StyledWarning message="You need the action point responder or closer permission to defer or complete action points" />
    );

  return (
    <Frow justifyContent="start">
      <div className="mar-r-1">
        <InProgressButton action="defer" releaseAssignees={assignableUsers} />
      </div>
      <InProgressButton action="complete" releaseAssignees={assignableUsers} />
    </Frow>
  );
};

export default InReviewActions;
