import * as React from 'react';
import { format, parse } from 'date-fns';

export default function columns() {
  return [
    {
      Header: 'Name',
      accessor: 'fullName',
      columnName: 'Full Name',
      id: 'first_name',
    },
    {
      Header: 'Email',
      accessor: 'email',
      columnName: 'Email',
      id: 'email',
    },
    {
      Header: 'Sign in Count',
      accessor: 'signInCount',
      columnName: 'Sign in count',
      id: 'sign_in_count',
    },
    {
      Cell: (row) => {
        if (!row.original.lastSignInAt) return <div>Never</div>;

        return <div>{format(parse(row.original.lastSignInAt), 'DD MMM YYYY')}</div>;
      },
      Header: 'Last sign in',
      columnName: 'Last sign in',
      id: 'last_sign_in_at',
    },
    {
      Cell: (row) => {
        return (
          <a href={row.original.showPath}>
            <div className="rt-tr-clickable-icon">
              <i className="icon-arrow-right-top icon-0-8x" />
            </div>
          </a>
        );
      },
      Header: '',
      sortable: false,
    },
  ];
}
