import * as React from 'react';
import Loading from 'components/shared/Loading';

import Frow from '../../../../frow/Frow';
import Column from '../../../../frow/Column';

const classNames = require('classnames');

interface IProps {
  attributes?: { name: any; value: any; bordered?: boolean }[];
  bordered: boolean;
  isLoading?: boolean;
  title?: string;
  size?: 2;
}

export default function portfolioInfo(props: IProps) {
  function renderAttributes() {
    if (props.isLoading) {
      return (
        <div className={`col-md-${props.size}-3`}>
          <Loading />
        </div>
      );
    }

    return props.attributes.map((attribute, index) => {
      return (
        <React.Fragment key={`${attribute.value}-${index}`}>
          {attribute.bordered && <hr />}
          <Frow tag="dl" additionalClassNames="definition-list">
            <Column tag="dt" breakpointSize="mc" maxColumns={5} columnSpan={2}>
              {attribute.name}
            </Column>
            <Column tag="dd" breakpointSize="mc" maxColumns={5} columnSpan={3} additionalClassNames="text-right">
              {attribute.value}
            </Column>
          </Frow>
        </React.Fragment>
      );
    });
  }

  const panelClasses = classNames(
    'platform-content',
    'platform-content--padding-all',
    'platform-content--border-bottom-at-md',
    'h-100',
    { 'platform-content--border-right-md': props.bordered },
  );

  const RenderTitle = () => {
    if (!props.title) return null;
    return (
      <Frow>
        <Column breakpointSize="mc" maxColumns={1} columnSpan={1}>
          <h4 className="subtitle-two text-white">{props.title}</h4>
        </Column>
      </Frow>
    );
  };

  return (
    <div className={`col-md-${props.size}-3`}>
      <div className={panelClasses} style={{ width: '100%' }}>
        <Frow>
          <RenderTitle />
          <Column breakpointSize="mc" maxColumns={1} columnSpan={1}>
            {renderAttributes()}
          </Column>
        </Frow>
      </div>
    </div>
  );
}

portfolioInfo.defaultProps = {
  isLoading: false,
  size: 1,
};
