import * as React from 'react';

import ColorPicker from './ColorPicker';

import Header from './Header';

import FormRow from 'components/shared/forms/FormRow';
import Uploader from './Uploader';

export default function customOptions(props) {
  const {
    colors,
    footerId,
    footerName,
    footerSrc,
    handleAttributeChange,
    handleColorChange,
    handleGetTheme,
    handleUpload,
    headerId,
    headerLogoHeight,
    headerLogoId,
    headerLogoName,
    headerLogoPosition,
    headerLogoSrc,
    headerName,
    headerSrc,
    logoId,
    logoName,
    logoSrc,
    reportTemplate,
    reportTemplateTheme,
    theme,
    titleFirstLine,
    titleLetterSpacing,
    titleSecondLine,
    titleThirdLine,
    titleUppercase,
  } = props;

  const [headerHeight, setHeaderHeight] = React.useState(300 * (colors.headerHeight / 100));

  function handleLogoUpload(signedId) {
    handleUpload('logo', signedId);
  }
  function handleHeaderBackgroundUpload(signedId) {
    handleUpload('headerBackground', signedId);
  }
  function handleFooterBackgroundUpload(signedId) {
    handleUpload('footerBackground', signedId);
  }
  function handleHeaderLogoUpload(signedId) {
    handleUpload('headerLogo', signedId);
  }

  function handleResize(event, data) {
    setHeaderHeight(data.size.height);
  }

  function handleResizeFinish(event, data) {
    // This is me being very lazy and using the existing function in the parent to update the theme instead of passing
    // a more generic function down. The function expects an input onChange to call this directly
    const percentageHeight = (data.size.height / 300) * 100;
    const fakeEvent = { target: { name: 'headerHeight', value: percentageHeight } };
    handleAttributeChange(fakeEvent);
  }

  const currencyOptions = [
    'gbp',
    'usd',
    'eur',
    'jpy',
    'hkd',
    'chf',
    'sgd',
    'zar',
    'cad',
    'aud',
    'nzd',
    'sek',
    'dkk',
    'tbc',
    'oth',
  ];

  const currencyColorPickers = currencyOptions.map((currency) => {
    return (
      <div className="col-md-1-5" key={currency}>
        <ColorPicker
          attribute={`${currency}Color`}
          color={colors[`${currency}Color`]}
          disabled={theme !== 'custom'}
          label={`${currency.toUpperCase()} Color`}
          handleColorChange={handleColorChange}
        />
      </div>
    );
  });

  return (
    <React.Fragment>
      <fieldset className="form__fieldset">
        <h4 className="form__fieldset-label">Typography</h4>
        <div className="frow frow--gutters">
          <FormRow
            className="col-mc-1-1"
            note="This is the URL where the font can be downloaded. E.g. https://fonts.googleapis.com/css?family=Noto+Sans&display=swap"
          >
            <label htmlFor="" className="form__label">
              Font Source
            </label>
            <input
              name="fontSource"
              type="text"
              defaultValue={reportTemplateTheme.fontSource}
              onChange={handleAttributeChange}
            />
          </FormRow>
          <FormRow note="This is the name of the font">
            <label htmlFor="" className="form__label">
              Font Name
            </label>
            <input
              name="fontName"
              type="text"
              defaultValue={reportTemplateTheme.fontName}
              onChange={handleAttributeChange}
            />
          </FormRow>
        </div>
      </fieldset>
      <Header
        {...{
          colors,
          handleAttributeChange,
          handleColorChange,
          handleGetTheme,
          handleHeaderBackgroundUpload,
          handleHeaderLogoUpload,
          handleResize,
          handleResizeFinish,
          headerHeight,
          headerId,
          headerLogoHeight,
          headerLogoId,
          headerLogoName,
          headerLogoPosition,
          headerLogoSrc,
          headerName,
          headerSrc,
          reportTemplate,
          reportTemplateTheme,
          theme,
          titleFirstLine,
          titleLetterSpacing,
          titleSecondLine,
          titleThirdLine,
          titleUppercase,
        }}
      />
      <fieldset className="form__fieldset">
        <h4 className="form__fieldset-label">Footer / Sidebar</h4>
        <h5>Sidebar is present in landscape reports</h5>
        <div className="frow frow--gutters-2x">
          <div className="col-md-1-5">
            <ColorPicker
              attribute="footerColor"
              color={colors.footerColor}
              disabled={theme !== 'custom'}
              label="Footer Background Color"
              handleColorChange={handleColorChange}
            />
          </div>
          <div className="col-md-1-5">
            <ColorPicker
              attribute="footerBorderColor"
              color={colors.footerBorderColor}
              disabled={theme !== 'custom'}
              label="Footer Top Border Color"
              handleColorChange={handleColorChange}
            />
          </div>
          <div className="col-md-1-5">
            <ColorPicker
              attribute="footerFontColor"
              color={colors.footerFontColor}
              disabled={theme !== 'custom'}
              label="Footer Font Color"
              handleColorChange={handleColorChange}
            />
          </div>
          <div className="col-md-1-1">
            <label htmlFor="" className="form__label">
              Footer / Sidebar Background Image
            </label>
            <Uploader
              handleUpload={handleFooterBackgroundUpload}
              imageName={footerName}
              imageSrc={footerSrc}
              attachmentId={footerId}
            />
          </div>
        </div>
      </fieldset>
      <fieldset className="form__fieldset">
        <h4 className="form__fieldset-label">Main Report Colors</h4>
        <div className="frow frow--gutters">
          <div className="col-md-1-5">
            <ColorPicker
              attribute="primaryColor"
              color={colors.primaryColor}
              disabled={theme !== 'custom'}
              label="Primary Color"
              handleColorChange={handleColorChange}
            />
          </div>
          <div className="col-md-1-5">
            <ColorPicker
              attribute="secondaryColor"
              color={colors.secondaryColor}
              disabled={theme !== 'custom'}
              label="Secondary Color"
              handleColorChange={handleColorChange}
            />
          </div>
          <div className="col-md-1-5">
            <ColorPicker
              attribute="tertiaryColor"
              color={colors.tertiaryColor}
              disabled={theme !== 'custom'}
              label="Tertiary Color"
              handleColorChange={handleColorChange}
            />
          </div>
        </div>
      </fieldset>
      <fieldset className="form__fieldset">
        <h4 className="form__fieldset-label">Asset Class Colors</h4>
        <div className="frow frow--gutters">
          <div className="col-md-1-5">
            <ColorPicker
              attribute="cashColor"
              color={colors.cashColor}
              disabled={theme !== 'custom'}
              label="Cash Color"
              handleColorChange={handleColorChange}
            />
          </div>
          <div className="col-md-1-5">
            <ColorPicker
              attribute="fixedIncomeColor"
              color={colors.fixedIncomeColor}
              disabled={theme !== 'custom'}
              label="Fixed Income Color"
              handleColorChange={handleColorChange}
            />
          </div>
          <div className="col-md-1-5">
            <ColorPicker
              attribute="equityColor"
              color={colors.equityColor}
              disabled={theme !== 'custom'}
              label="Equity Color"
              handleColorChange={handleColorChange}
            />
          </div>
          <div className="col-md-1-5">
            <ColorPicker
              attribute="hedgeFundColor"
              color={colors.hedgeFundColor}
              disabled={theme !== 'custom'}
              label="Hedge Fund Color"
              handleColorChange={handleColorChange}
            />
          </div>
          <div className="col-md-1-5">
            <ColorPicker
              attribute="otherColor"
              color={colors.otherColor}
              disabled={theme !== 'custom'}
              label="Other Color"
              handleColorChange={handleColorChange}
            />
          </div>
        </div>
      </fieldset>
      <fieldset className="form__fieldset">
        <h4 className="form__fieldset-label">Currency Colors</h4>
        <div className="frow frow--gutters">{currencyColorPickers}</div>
      </fieldset>
      <fieldset className="form__fieldset">
        <h4 className="form__fieldset-label">Secondary Colors</h4>
        <div className="frow frow--gutters">
          <div className="col-md-1-5">
            <ColorPicker
              attribute="secondaryColorOne"
              color={colors.secondaryColorOne}
              disabled={theme !== 'custom'}
              label="Secondary Color 1"
              handleColorChange={handleColorChange}
            />
          </div>
          <div className="col-md-1-5">
            <ColorPicker
              attribute="secondaryColorTwo"
              color={colors.secondaryColorTwo}
              disabled={theme !== 'custom'}
              label="Secondary Color 2"
              handleColorChange={handleColorChange}
            />
          </div>
          <div className="col-md-1-5">
            <ColorPicker
              attribute="secondaryColorThree"
              color={colors.secondaryColorThree}
              disabled={theme !== 'custom'}
              label="Secondary Color 3"
              handleColorChange={handleColorChange}
            />
          </div>
          <div className="col-md-1-5">
            <ColorPicker
              attribute="secondaryColorFour"
              color={colors.secondaryColorFour}
              disabled={theme !== 'custom'}
              label="Secondary Color 4"
              handleColorChange={handleColorChange}
            />
          </div>
          <div className="col-md-1-5">
            <ColorPicker
              attribute="secondaryColorFive"
              color={colors.secondaryColorFive}
              disabled={theme !== 'custom'}
              label="Secondary Color 5"
              handleColorChange={handleColorChange}
            />
          </div>
        </div>
      </fieldset>
      <fieldset className="form__fieldset">
        <h4 className="form__fieldset-label">General</h4>
        <div className="frow frow--gutters">
          <FormRow>
            <label htmlFor="" className="form__label">
              Name
            </label>
            <input name="name" type="text" defaultValue={reportTemplateTheme.name} onChange={handleAttributeChange} />
          </FormRow>
          <FormRow>
            <label htmlFor="" className="form__label">
              Benchmark Name
            </label>
            <input
              name="benchmarkName"
              type="text"
              defaultValue={reportTemplateTheme.benchmarkName}
              onChange={handleAttributeChange}
            />
          </FormRow>
          <FormRow>
            <label htmlFor="" className="form__label">
              Address
            </label>
            <input
              name="address"
              type="text"
              defaultValue={reportTemplateTheme.address}
              onChange={handleAttributeChange}
            />
          </FormRow>
          <FormRow>
            <label htmlFor="" className="form__label">
              Website
            </label>
            <input
              name="website"
              type="text"
              defaultValue={reportTemplateTheme.website}
              onChange={handleAttributeChange}
            />
          </FormRow>
          <FormRow>
            <label htmlFor="" className="form__label">
              Phone Number
            </label>
            <input name="phone" type="text" defaultValue={reportTemplateTheme.phone} onChange={handleAttributeChange} />
          </FormRow>
          <div className="col-mc-1-1">
            <label htmlFor="" className="form__label">
              Main Logo
            </label>
            <Uploader handleUpload={handleLogoUpload} imageName={logoName} imageSrc={logoSrc} attachmentId={logoId} />
          </div>
        </div>
      </fieldset>
    </React.Fragment>
  );
}
