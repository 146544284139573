import * as React from 'react';

import OutsideClickHandler from 'react-outside-click-handler';

import DropdownOption, { IPropsDDO } from './DropdownOption';

const classNames = require('classnames');

interface IProps {
  name: string;
  selectedOption: IPropsDDO;
  dropdownOptions: IPropsDDO[];
  iconClass: string;
  handleSelect(option: IPropsDDO): void;
}

export default function dropdown(props: IProps) {
  const { name, selectedOption, dropdownOptions, handleSelect, iconClass } = props;
  const [isOpen, setIsOpen] = React.useState(false);

  const [localSelectedOption, setLocalSelectedOption] = React.useState<IPropsDDO>(selectedOption);

  function toggleOpen() {
    setIsOpen((prevState) => !prevState);
  }

  function handleOutsideClick() {
    setIsOpen(false);
  }

  const dropdownClasses = classNames('dropdown dropdown--sw', { 'dropdown--active': isOpen });

  function localHandleSelect(option) {
    handleSelect(option);
    setLocalSelectedOption(option);
    setIsOpen(false);
  }

  const localDropdownOptions = dropdownOptions.map((option) => {
    option.key = option.key ? option.key : String(option.value);
    return option;
  });

  return (
    <div>
      <p className="subtitle-two">{name}</p>
      <OutsideClickHandler onOutsideClick={handleOutsideClick}>
        <div className={dropdownClasses}>
          <a className="dropdown__toggle dropdown__toggle--input" onClick={toggleOpen}>
            <div className="frow frow--items-center">
              <i className={`${iconClass} icon-fw mar-r-1`} />
              <span
                style={{ width: '140px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
                className="text-normal"
              >
                {localSelectedOption.display}
              </span>
              <i className={`button__icon fa fa-caret-down fa-animated ${isOpen ? 'fa-flipped' : ''}`} />
            </div>
          </a>
          <div className="dropdown__content">
            <div className="dropdown__content-inner">
              {localDropdownOptions.map((option) => (
                <DropdownOption {...option} handleSelect={localHandleSelect} />
              ))}
            </div>
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  );
}
