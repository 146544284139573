import { Attr, BelongsTo, Model } from 'spraypaint';

import { ApplicationRecord } from './ApplicationRecord';

@Model()
export class AdditionalAttribute extends ApplicationRecord {
  public static jsonapiType = 'additional_attributes';

  @Attr() public key: string;
  @Attr() public value: any;
}
