import * as React from 'react';

import { format } from 'date-fns';

import Messages from './Messages';
import FileDownload from './FileDownload';
import Notes from './Notes';
import ReProcess from './ReProcess';

import ErrorMessagesColumns from './messages/table/ErrorMessagesColumns';
import InformationalMessagesColumns from './messages/table/InformationalMessagesColumns';

import { NotesNotable } from 'javascript/models';
import { DataUpload, Note } from 'javascript/models';
import { platformManagerUploadTemplatesPath } from 'javascript/application/ts_routes';
import { DataUploadError } from 'javascript/models';

interface IProps {
  currentUserId: number;
  dataUpload: DataUpload;
  dataUploadId: string;
  isInputDisabled: boolean;
  isLoading: boolean;
  managerId: string;
  managerName: string;
  handleNewNote(): void;
  handleReProcess(): void;
}

export default function presenter(props: IProps) {
  const {
    currentUserId,
    dataUpload,
    dataUploadId,
    handleNewNote,
    handleReProcess,
    isLoading,
    isInputDisabled,
    managerId,
    managerName,
  } = props;

  const infoMessageScope = Note.includes('notes_notables').where({
    note_category: 'data_upload_info_message',
    for_specific: {
      notable_type: 'DataUpload',
      notable_id: dataUploadId,
    },
  });

  const errorMessageScope = DataUploadError.where({ data_upload_id: dataUploadId }).includes(['resolver']);

  return (
    <React.Fragment>
      <section className="platform-panel platform-panel--no-background">
        <a href={platformManagerUploadTemplatesPath(managerId)} className="link link--left-arrow">
          Back to Upload Templates
        </a>
        <div className="frow frow--justify-between frow--items-center">
          <div className="frow frow--items-center frow--justify-between">
            <div>
              <div className="frow frow--items-center">
                <h2 className="heading-one">{managerName}</h2>
                <span className="mar-h-4">|</span>
                <p className="subtitle-one">
                  Data Uploaded {dataUpload ? format(dataUpload.createdAt, 'DD MMM YYYY [at] HH:mm') : ''}
                </p>
              </div>
            </div>
          </div>
          <ReProcess {...{ dataUpload, handleReProcess, isLoading }} />
        </div>
      </section>
      <div className="frow frow--gutters-2x">
        <div className="col-md-1-1">
          <section className="platform-panel platform-panel--full-height">
            <header className="platform-panel__header">
              <h3 className="subtitle-one text-white">File</h3>
            </header>
            <div className="platform-content platform-content--padding-all">
              <FileDownload {...{ dataUpload, isLoading }} />
            </div>
          </section>
        </div>
      </div>
      <section className="platform-panel">
        <header className="platform-panel__header">
          <h3 className="subtitle-one text-white">Informational Messages</h3>
        </header>
        <div className="platform-content platform-content--padding-all">
          <Messages columns={InformationalMessagesColumns} scope={infoMessageScope} {...{ dataUpload, isLoading }} />
        </div>
      </section>
      <section className="platform-panel">
        <header className="platform-panel__header">
          <h3 className="subtitle-one text-white">Error Messages</h3>
        </header>
        <div className="platform-content platform-content--padding-all">
          <Messages columns={ErrorMessagesColumns} scope={errorMessageScope} {...{ dataUpload, isLoading }} />
        </div>
      </section>
      <section className="platform-panel">
        <Notes {...{ currentUserId, dataUpload, dataUploadId, handleNewNote, isLoading }} />
      </section>
    </React.Fragment>
  );
}
