import * as React from 'react';

export default function summary({ bulkImport }) {
  const { errors, warnings } = bulkImport.preview;

  const noErrors = <p className="text-white helper-text mar-v-0">There are no errors in the sheet</p>;

  const errorList = errors.map((error) => {
    return <li key={error}>{error}</li>;
  });

  const renderErrors = <ul className="list">{errorList}</ul>;

  const noWarnings = <p className="text-white helper-text mar-v-0">There are no warnings in the sheet</p>;

  const warningList = warnings.map((warning) => {
    return <li key={warning}>{warning}</li>;
  });

  const renderWarnings = <ul className="list">{warningList}</ul>;

  return (
    <React.Fragment>
      <h3 className="subtitle-one text-white mar-b-1">Warnings</h3>
      <div className="mar-b-2">{warnings.length > 0 ? renderWarnings : noWarnings}</div>
      <h3 className="subtitle-one text-white mar-b-1">Errors</h3>
      <div className="mar-b-2">{errors.length > 0 ? renderErrors : noErrors}</div>
    </React.Fragment>
  );
}
