import * as React from 'react';

import { SecurityStateClassificationWeighting } from 'javascript/models';

import ReactPieChart from 'components/shared/charting/recharts/ReactPieChart';

import lodashStartcase from 'lodash.startcase';
import CriterionHumanizer from 'components/shared/CriterionHumanizer';
import { useDefaultTheme } from 'components/shared/colours';

interface IProps {
  classificationWeightings: SecurityStateClassificationWeighting[];
  valueType: string;
  index: number;
}

const classificationBreakdown = (props: IProps) => {
  const { classificationWeightings, valueType, index } = props;

  const colourGenerator = useDefaultTheme('all');

  const pieChartData = () => {
    const valuesToMap = classificationWeightings;
    const tooManyValues = valuesToMap.length > 5;
    let otherWeighting = 0;

    if (tooManyValues) {
      valuesToMap.sort((securityValueA, securityValueB) => securityValueB.weighting - securityValueA.weighting);
      const excludedSecurityValues = valuesToMap.splice(5);
      otherWeighting = excludedSecurityValues.reduce((value, esv) => value + esv.weighting, 0);
    }

    const pieChartData = valuesToMap.map((classificationWeighting) => ({
      color: colourGenerator(classificationWeighting.classification.rootAlias.name),
      name: CriterionHumanizer(classificationWeighting.classification.rootAlias.name, valueType),
      value: Math.round(classificationWeighting.weighting * 100),
    }));

    if (tooManyValues) {
      pieChartData.push({
        color: colourGenerator('Other (total)'),
        name: 'Other (total)',
        value: Math.round(otherWeighting * 100),
      });
    }

    return pieChartData;
  };

  const renderPieChart = () => {
    const className = [
      'platform-content--full-height',
      'platform-content--padding-all',
      'platform-content--border-bottom',
    ];

    // tslint:disable-next-line: no-unused-expression
    if (index !== 2) className.push('platform-content--border-right');

    const pieChartProps = { data: pieChartData(), displayLegend: true, height: 200, labelText: '', maxLegendSize: 5 };

    return (
      <div className={className.join(' ')}>
        <h4 className="subtitle-two text-white">{lodashStartcase(valueType)}</h4>
        <ReactPieChart {...pieChartProps} />
      </div>
    );
  };

  return <div className="col-md-1-3">{renderPieChart()}</div>;
};

export default classificationBreakdown;
