import React from 'react';

import { User } from 'javascript/models';

import ReactSelect from 'components/shared/forms/ReactSelect';

import { Frow, Column } from 'components/frow';
import Loading from 'components/shared/Loading';

import { ActionPointContext } from '../../Show';

const ActionPointAssigner = () => {
  const { actionPoint, currentUser, client, getActionPoint } = React.useContext(ActionPointContext);

  const [assignableUsers, setAssignableUsers] = React.useState<User[]>();
  const [isLoadingUsers, setIsLoadingUsers] = React.useState(true);

  const permissionRequiredToBeAssigned = {
    inReview: 'action_point_closer',
    inProgress: 'action_point_responder',
  }[actionPoint.status];

  const getAssignableUsers = async () => {
    const users = await User.clientUsersWithPermission(client.id, permissionRequiredToBeAssigned);
    User.sortUsersByAttribute({ users, attr: 'firstName' });
    User.translateUserToFirstPosition({ user: currentUser, usersArray: users });
    setAssignableUsers(users);
  };

  React.useEffect(() => {
    getAssignableUsers().then(() => setIsLoadingUsers(false));
  }, []);

  const handleAssigneeChange = async (option: { label: string; value: User }) => {
    Object.assign(actionPoint, {
      assigneeId: option.value.id,
      assigneeType: 'User',
    });
    await actionPoint.save({ displayQueryResult: true });
    getActionPoint();
  };

  if (!currentUser || !actionPoint || isLoadingUsers) return <Loading />;

  if (actionPoint.status === 'released') return null;

  const content = currentUser.hasPermission('action_point_assigner', client.id) ? (
    <Column columnSpan={2} maxColumns={5}>
      <ReactSelect
        options={assignableUsers && assignableUsers.map((user) => ({ label: user.name, value: user }))}
        name="assigner"
        isLoading={isLoadingUsers}
        handleChange={handleAssigneeChange}
        defaultValue={{
          label: actionPoint.assignee ? actionPoint.assignee.fullName : 'not assigned',
          value: actionPoint.assignee,
        }}
        theme="dark"
      />
    </Column>
  ) : (
    <span>{actionPoint.assignee.fullName}</span>
  );

  return (
    <Column columnSpan={3} maxColumns={5}>
      <Frow justifyContent="end" itemAlignment="center" additionalClassNames="text-small text-white">
        <span className="text-bold mar-r-1">Assignee:</span>
        {content}
      </Frow>
    </Column>
  );
};

export default ActionPointAssigner;
