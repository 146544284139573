import * as React from 'react';

import { ReportTemplatesReportTemplateSection } from 'javascript/models';
import Context from '../../../Context';

export default function multiLayerPieChart({ attributes, handleChange, handleManualChange, isRepeating }) {
  const context = React.useContext(Context);
  const { reportTemplate } = context.state;

  const isConsolidated = reportTemplate.consolidated;

  const [target, setTarget] = React.useState(attributes.pieChartTarget);
  const [layers, setLayers] = React.useState(attributes.pieChartLayers || []);

  function updateAttributes() {
    const value = { layers, target };
    handleManualChange(value, 'pieChartDetails');
  }

  React.useEffect(() => {
    updateAttributes();
  }, [layers, target]);

  function handleLayerChange() {
    const updatedSeries = layers.toggle(this);
    setLayers(updatedSeries);
  }
  function handleTargetChange() {
    setTarget(this);
  }

  const targetOptions = ReportTemplatesReportTemplateSection.multiPietargetOptions.map((option) => {
    return (
      <div className="col-tn-1-3" key={option.value}>
        <div className="pretty pretty--large pretty--with-label p-round">
          <input
            type="radio"
            value={option.value}
            id="pie_target"
            onChange={handleTargetChange.bind(option.value)}
            checked={target === option.value}
          />
          <div className="state p-blue-circle">
            <label>{option.label}</label>
          </div>
        </div>
      </div>
    );
  });

  const layerOptions = ReportTemplatesReportTemplateSection.multiPieLayerOptions.map((option) => {
    return (
      <div className="col-tn-1-3" key={option.value}>
        <div className="pretty p-icon">
          <input
            type="checkbox"
            value={option.value}
            id="series"
            onChange={handleLayerChange.bind(option.value)}
            checked={layers.includes(option.value)}
          />
          <div className="state p-blue">
            <i className="icon-tick icon icon-push-down-small" />
            <label>{option.label}</label>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="frow frow--gutters">
      <div className="col-mc-1-1">
        <label className="form__label" htmlFor="">
          Target
        </label>
        <div className="frow">{targetOptions}</div>
      </div>
      <div className="col-mc-1-1">
        <label className="form__label" htmlFor="">
          Layers
        </label>
        <div className="frow">{layerOptions}</div>
      </div>
    </div>
  );
}
