import * as React from 'react';

import { Client, User } from 'javascript/models';

import CurrentUserContext from 'components/shared/context/CurrentUserContext';
import Loading from 'components/shared/Loading';
import useLocalStorage from 'components/shared/customHooks/useLocalStorage';
import ISelectOption from 'components/interfaces/ISelectOption';

import Presenter from './show/Presenter';

interface IProps {
  client: Pick<Client, 'id' | 'managesActionPointsInPortal'>;
  portfolioObject: any;
  reviewPeriods: ISelectOption[];
  user: { id: number };
}

export default function show(props: IProps) {
  // Passing this in so we aren't waiting for the portfolio before we start displaying stuff
  const { client, portfolioObject, reviewPeriods, user } = props;
  const defaultReviewPeriod = reviewPeriods.length > 0 ? reviewPeriods[0].value : null;
  const reviewID = defaultReviewPeriod ? reviewPeriods[0].id : null;
  const [currentReviewPeriod, setCurrentReviewPeriod] = React.useState(defaultReviewPeriod);
  const [currentUser, setCurrentUser] = React.useState<User>();

  const getUser = async () => {
    const { data } = await User.withPermissions.find(user.id);
    setCurrentUser(data);
  };

  React.useEffect(() => {
    getUser();
  }, []);

  function defaultLayout() {
    const defaultElements = [
      { name: 'Structure', width: 2 },
      { name: 'Value and Performance', width: 2 },
      { name: 'Reviews and Action Points', width: 2 },
      { name: 'Mandate Compliance', width: 2 },
      { name: 'Return and Risk', width: 2 },
      { name: 'Holding Analytics', width: 2 },
    ];

    let xPosition = 0;
    let yPosition = 0;
    const largeLayout = defaultElements.map((element) => {
      xPosition += element.width;
      if (xPosition > 2) {
        xPosition = 1;
        yPosition += 1;
      }
      const layoutObject = { i: element.name, x: xPosition - 1, y: yPosition, h: 1, w: element.width };
      if (element.width === 2) {
        xPosition = 0;
        yPosition += 1;
      }
      return layoutObject;
    });

    const smallLayout = defaultElements.map((element, index) => {
      return { i: element.name, x: 0, y: index, h: 1, w: 1 };
    });

    return { lg: largeLayout, sm: smallLayout };
  }

  const defaultDisplay = {
    Structure: true,
    'Value and Performance': true,
    'Reviews and Action Points': true,
    'Mandate Compliance': true,
    'Return and Risk': true,
    'Holding Analytics': true,
  };

  const { storedValue: layout, setItem } = useLocalStorage('portal:client:portfolio:dashboard', defaultLayout(), false);

  const { storedValue: layoutDisplay, setItem: setLayoutDisplay } = useLocalStorage(
    'portal:client:portfolio:dashboardDisplay',
    defaultDisplay,
    false,
  );

  if (!currentUser) return <Loading />;

  return (
    <CurrentUserContext.Provider value={currentUser}>
      <Presenter
        {...{
          client,
          currentReviewPeriod,
          layout,
          layoutDisplay,
          portfolioObject,
          reviewPeriods,
          reviewID,
          setCurrentReviewPeriod,
          setItem,
          setLayoutDisplay,
        }}
      />
    </CurrentUserContext.Provider>
  );
}
