import { Attr, Model } from 'spraypaint';
import { ApplicationRecord } from './ApplicationRecord';

@Model()
export class MarketCommentary extends ApplicationRecord {
  public static jsonapiType = 'market_commentaries';
  @Attr() public commentary: string;
  @Attr() public commentaryDate: string;
  @Attr() public editPath: string;
  @Attr() public title: string;
}
