import * as $ from 'jquery';

$(document).on('click', (e) => {
  // Closes all dropdowns unless we've clicked inside a sticky one
  if (!$(e.target).closest('.js-dropdown-sticky').length) {
    $('.js-dropdown').removeClass('dropdown--active');
  }
});

$(document).on('click', '.js-dropdown-toggle', (e) => {
  e.stopPropagation(); // So we dont also trigger the close event

  // Closes all sticky dropdowns if we've clicked a non sticky one
  // This allows multiple sticky dropdowns to be open at once

  if (!$(e.target).closest('.js-dropdown-sticky').length) {
    $('.js-dropdown-sticky').removeClass('dropdown--active');
  }

  // Toggle the active state of the dropdown we clicked on
  $(e.target).parents('.dropdown').toggleClass('dropdown--active');
});
