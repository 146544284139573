import * as React from 'react';

import { ReportTemplatesReportTemplateSection } from 'javascript/models';
import Context from '../../../Context';

export default function barChart({ attributes, handleChange, handleManualChange, isRepeating }) {
  const context = React.useContext(Context);
  const { reportTemplate } = context.state;

  const isConsolidated = reportTemplate.consolidated;

  const [selectedSeries, setSelectedSeries] = React.useState(attributes.barChartSeries || []);
  const [selectedDiscretePeriods, setSelectedDiscretePeriods] = React.useState(
    attributes.barChartDiscretePeriods || [],
  );
  const [selectedTimePeriod, setTimePeriod] = React.useState(attributes.barChartTimePeriod);
  const [selectedType, setSelectedType] = React.useState(attributes.barChartType);
  const [selectedLegend, setSelectedLegend] = React.useState(attributes.legendPosition);
  const [selectedLabel, setSelectedLabel] = React.useState(attributes.barChartLabel || 'false');

  function updateAttributes() {
    const value = {
      discrete_periods: selectedDiscretePeriods,
      series: selectedSeries,
      show_label: selectedLabel,
      time_period: selectedTimePeriod,
      type: selectedType,
    };
    handleManualChange(value, 'barChartDetails');
  }

  React.useEffect(() => {
    updateAttributes();
  }, [selectedSeries, selectedTimePeriod, selectedType, selectedDiscretePeriods, selectedLabel]);

  function handleSeriesChange() {
    const updatedSeries = selectedSeries.toggle(this);
    setSelectedSeries(updatedSeries);
  }

  function handleDiscretePeriodChange() {
    const updatedPeriods = selectedDiscretePeriods.toggle(this);
    setSelectedDiscretePeriods(updatedPeriods);
  }

  function handleTypeChange() {
    setSelectedType(this);
  }

  function handlePeriodChange() {
    setTimePeriod(this);
  }

  function handleLegendChange() {
    setSelectedLegend(this);
    handleManualChange(this, 'legendPosition');
  }

  function handleLabelChange() {
    setSelectedLabel(this);
  }

  let scopedSeriesOptions = ReportTemplatesReportTemplateSection.seriesOptions;

  if (isConsolidated) {
    scopedSeriesOptions = [
      'Consolidated Portfolio',
      'Constituent Portfolios',
      ...ReportTemplatesReportTemplateSection.seriesOptions.filter((option) => option !== 'Portfolio'),
    ];
  }

  if (isRepeating) {
    scopedSeriesOptions = [
      'Constituent Portfolio',
      ...ReportTemplatesReportTemplateSection.seriesOptions.filter((option) => option !== 'Portfolio'),
    ];
  }

  const seriesOptions = scopedSeriesOptions.map((option) => {
    return (
      <div className="col-tn-1-3" key={option}>
        <div className="pretty p-icon">
          <input
            type="checkbox"
            value={option}
            id="series"
            onChange={handleSeriesChange.bind(option)}
            checked={selectedSeries.includes(option)}
          />
          <div className="state p-blue">
            <i className="icon-tick icon icon-push-down-small" />
            <label>{option}</label>
          </div>
        </div>
      </div>
    );
  });

  const typeOptions = ReportTemplatesReportTemplateSection.barChartOptions.map((type) => {
    return (
      <div className="col-tn-1-3" key={type.value}>
        <div className="pretty pretty--large pretty--with-label p-round">
          <input
            type="radio"
            value={type.value}
            id="type"
            onChange={handleTypeChange.bind(type.value)}
            checked={selectedType === type.value}
          />
          <div className="state p-blue-circle">
            <label>{type.label}</label>
          </div>
        </div>
      </div>
    );
  });

  const timePeriodOptions = ReportTemplatesReportTemplateSection.timePeriodOptions.map((timePeriod) => {
    return (
      <div className="col-tn-1-3" key={timePeriod.value}>
        <div className="pretty pretty--large pretty--with-label p-round">
          <input
            type="radio"
            value={timePeriod.value}
            id="timePeriod"
            onChange={handlePeriodChange.bind(timePeriod.value)}
            checked={selectedTimePeriod === timePeriod.value}
          />
          <div className="state p-blue-circle">
            <label>{timePeriod.label}</label>
          </div>
        </div>
      </div>
    );
  });

  const legendOptions = ReportTemplatesReportTemplateSection.legendOptions.map((legend) => {
    return (
      <div className="col-tn-1-3" key={legend.value}>
        <div className="pretty pretty--large pretty--with-label p-round">
          <input
            type="radio"
            value={legend.value}
            id="timePeriod"
            onChange={handleLegendChange.bind(legend.value)}
            checked={selectedLegend === legend.value}
          />
          <div className="state p-blue-circle">
            <label>{legend.label}</label>
          </div>
        </div>
      </div>
    );
  });

  const labelOptions = [
    { value: 'true', label: 'Yes' },
    { value: 'false', label: 'No' },
  ].map((label) => {
    return (
      <div className="col-tn-1-3" key={label.label}>
        <div className="pretty pretty--large pretty--with-label p-round">
          <input
            type="radio"
            value={label.value}
            id="timePeriod"
            onChange={handleLabelChange.bind(label.value)}
            checked={selectedLabel === label.value}
          />
          <div className="state p-blue-circle">
            <label>{label.label}</label>
          </div>
        </div>
      </div>
    );
  });

  const discretePeriodOptions = ReportTemplatesReportTemplateSection.barChartSubOptions.map((subOption) => {
    return (
      <div className="col-tn-1-3" key={subOption.value}>
        <div className="pretty p-icon">
          <input
            type="checkbox"
            value={subOption.value}
            id="series"
            onChange={handleDiscretePeriodChange.bind(subOption.value)}
            checked={selectedDiscretePeriods.includes(subOption.value)}
          />
          <div className="state p-blue">
            <i className="icon-tick icon icon-push-down-small" />
            <label>{subOption.label}</label>
          </div>
        </div>
      </div>
    );
  });

  function timePeriod() {
    if (selectedType === 'return') {
      return (
        <div className="col-mc-1-1">
          <label className="form__label" htmlFor="">
            Time Period
          </label>
          <div className="frow ">{timePeriodOptions}</div>
        </div>
      );
    }
    return (
      <div className="col-mc-1-1">
        <label className="form__label" htmlFor="">
          Discrete Periods
        </label>
        <div className="frow ">{discretePeriodOptions}</div>
      </div>
    );
  }

  return (
    <div className="frow frow--gutters">
      <div className="col-mc-1-1">
        <label className="form__label" htmlFor="">
          Series
        </label>
        <div className="frow">{seriesOptions}</div>
      </div>
      <div className="col-mc-1-1">
        <label className="form__label" htmlFor="">
          Chart Type
        </label>
        <div className="frow ">{typeOptions}</div>
      </div>
      {timePeriod()}
      <div className="col-mc-1-1">
        <label className="form__label" htmlFor="">
          Show Bar Labels
        </label>
        <div className="frow ">{labelOptions}</div>
      </div>
      <div className="col-mc-1-1">
        <label className="form__label" htmlFor="">
          Legend Options
        </label>
        <div className="frow ">{legendOptions}</div>
      </div>
    </div>
  );
}
