import * as React from 'react';

import ajaxRequest, { IAjaxRequest } from 'components/shared/utilities/ajaxRequest';

type AjaxRequestPassthrough = Pick<IAjaxRequest<unknown>, 'cache' | 'path' | 'method' | 'includeCsrfToken'>;

interface IUseAjaxRequest<Response> extends AjaxRequestPassthrough {
  stateSetter: React.Dispatch<React.SetStateAction<Response>>;
  initialLoadingState?: boolean;
}

const useAjaxRequest = <RequestParams, Response>({
  includeCsrfToken = true,
  path,
  method,
  stateSetter,
  initialLoadingState = true,
}: IUseAjaxRequest<Response>): [(requestParams?: RequestParams) => void, boolean, boolean] => {
  const [isLoading, setIsLoading] = React.useState(initialLoadingState);
  const [isError, setIsError] = React.useState(false);

  const localBeforeSend = () => setIsLoading(true);

  const makeRequest = async (requestParams?: RequestParams) => {
    try {
      const response = await ajaxRequest<Response, RequestParams>({
        beforeSend: localBeforeSend,
        includeCsrfToken,
        method,
        params: requestParams,
        path,
      });
      stateSetter(response);
    } catch (e) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return [makeRequest, isError, isLoading];
};

export default useAjaxRequest;
