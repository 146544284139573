import * as React from 'react';

import lodashTruncate from 'lodash.truncate';
import Select from 'react-select';

import useDebounce from 'components/shared/customHooks/useDebounce';

import { PortfolioBenchmark } from 'javascript/models';

interface IProps {
  benchmarkType: string;
  defaultValue?: { label: string; value: string };
  index: number;
  name: string;
  readonly?: boolean;
  placeholder?: string;
  type: string;
  primaryChange?: any;
  primaryIndex?: number;
  secondaryIndex?: number;
  tertiaryIndex?: number;
  changeSecondaryIndex(index: number);
  changeTertiaryIndex(index: number);
}

export default function select(props: IProps) {
  const {
    benchmarkType,
    changeSecondaryIndex,
    changeTertiaryIndex,
    index,
    placeholder,
    primaryChange,
    primaryIndex,
    readonly,
    secondaryIndex,
    tertiaryIndex,
    type,
  } = props;

  const [isLoading, setIsLoading] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);

  const debouncedInputValue = useDebounce(inputValue, 600);

  React.useEffect(() => {
    setIsLoading(false);
  }, [options]);

  function handleInputChange(input) {
    setInputValue(input);
  }

  React.useEffect(() => {
    if (debouncedInputValue) {
      getBenchmarks();
    } else {
      setOptions([]);
    }
  }, [debouncedInputValue]);

  async function getBenchmarks() {
    let scope = PortfolioBenchmark.where({ search: { match: debouncedInputValue } })
      .where({ benchmark_type: type })
      .per(100)
      .select(['id', 'reference', 'description']);

    if (type === 'target_return') {
      scope = scope.where({ benchmark_type: type });
    } else {
      scope = scope.where({ benchmark_type: ['enhance_benchmark', 'managers_benchmark', 'peer_group'] });
    }

    const { data } = await scope.all();

    const options = data.map((portfolioBenchmark) => {
      return {
        label: lodashTruncate(`${portfolioBenchmark.reference} - ${portfolioBenchmark.description}`, { length: 50 }),
        value: portfolioBenchmark.id,
      };
    });

    setOptions(options);
  }

  const defaultValue = props.defaultValue && props.defaultValue.value ? props.defaultValue : null;

  const portfolioBenchmarkId = (
    <input
      type="hidden"
      name={`portfolio[portfolios_benchmarks_attributes][${index}][portfolio_benchmark_id]`}
      value={props.defaultValue.value}
    />
  );

  return (
    <React.Fragment>
      {type === 'enhance_benchmark' && props.defaultValue.value && portfolioBenchmarkId}
      <input
        type="hidden"
        name={`portfolio[portfolios_benchmarks_attributes][${index}][benchmark_type]`}
        value={benchmarkType}
      />
      <Select
        className="react-select-dark"
        classNamePrefix="react-select-dark"
        defaultValue={defaultValue}
        isSearchable
        isDisabled={readonly || type === 'enhance_benchmark'}
        isLoading={isLoading}
        name={`portfolio[portfolios_benchmarks_attributes][${index}][portfolio_benchmark_id]`}
        options={options}
        placeholder={placeholder}
        inputValue={inputValue}
        onInputChange={handleInputChange}
      />
      <div className="frow frow--gutters">
        <div>
          <div className="pretty p-icon mar-t-1">
            <input
              type="checkbox"
              name={`portfolio[portfolios_benchmarks_attributes][${index}][primary_benchmark]`}
              value="1"
              checked={primaryIndex === index}
              onChange={() => primaryChange(index)}
            />
            <div className="state p-blue">
              <i className="icon-tick icon icon-push-down-small" />
              <label className="text-white">Primary Benchmark</label>
            </div>
          </div>
        </div>
        <div>
          <div className="pretty p-icon mar-t-1">
            <input
              type="hidden"
              name={`portfolio[portfolios_benchmarks_attributes][${index}][secondary_benchmark]`}
              value="0"
            />
            <input
              type="checkbox"
              name={`portfolio[portfolios_benchmarks_attributes][${index}][secondary_benchmark]`}
              value="1"
              checked={secondaryIndex === index}
              onChange={() => changeSecondaryIndex(index)}
              disabled={primaryIndex === index || tertiaryIndex === index}
            />
            <div className="state p-blue">
              <i className="icon-tick icon icon-push-down-small" />
              <label className="text-white">Secondary Benchmark</label>
            </div>
          </div>
        </div>
        <div>
          <div className="pretty p-icon mar-t-1">
            <input
              type="hidden"
              name={`portfolio[portfolios_benchmarks_attributes][${index}][tertiary_benchmark]`}
              value="0"
            />
            <input
              type="checkbox"
              name={`portfolio[portfolios_benchmarks_attributes][${index}][tertiary_benchmark]`}
              value="1"
              checked={tertiaryIndex === index}
              onChange={() => changeTertiaryIndex(index)}
              disabled={primaryIndex === index || secondaryIndex === index}
            />
            <div className="state p-blue">
              <i className="icon-tick icon icon-push-down-small" />
              <label className="text-white">Tertiary Benchmark</label>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

select.defaultProps = {
  placeholder: 'Search for a Benchmark to select',
};
