export const countries = [
  'Afghanistan',
  'Angola',
  'Albania',
  'United Arab Emirates',
  'Argentina',
  'Armenia',
  'Antarctica',
  'Fr. S. Antarctic Lands',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Burundi',
  'Belgium',
  'Benin',
  'Burkina Faso',
  'Bangladesh',
  'Bulgaria',
  'Bahamas',
  'Bosnia and Herz.',
  'Belarus',
  'Belize',
  'Bolivia',
  'Brazil',
  'Brunei',
  'Bhutan',
  'Botswana',
  'Central African Rep.',
  'Canada',
  'Switzerland',
  'Chile',
  'China',
  "Côte d'Ivoire",
  'Cameroon',
  'Dem. Rep. Congo',
  'Congo',
  'Colombia',
  'Costa Rica',
  'Cuba',
  'N. Cyprus',
  'Cyprus',
  'Czechia',
  'Germany',
  'Djibouti',
  'Denmark',
  'Dominican Rep.',
  'Algeria',
  'Ecuador',
  'Egypt',
  'Eritrea',
  'Spain',
  'Estonia',
  'Ethiopia',
  'Finland',
  'Fiji',
  'Falkland Is.',
  'France',
  'Gabon',
  'United Kingdom',
  'Georgia',
  'Ghana',
  'Guinea',
  'Gambia',
  'Guinea-Bissau',
  'Eq. Guinea',
  'Greece',
  'Greenland',
  'Guatemala',
  'Guyana',
  'Honduras',
  'Croatia',
  'Haiti',
  'Hungary',
  'Indonesia',
  'India',
  'Ireland',
  'Iran',
  'Iraq',
  'Iceland',
  'Israel',
  'Italy',
  'Jamaica',
  'Jordan',
  'Japan',
  'Kazakhstan',
  'Kenya',
  'Kyrgyzstan',
  'Cambodia',
  'South Korea',
  'Kosovo',
  'Kuwait',
  'Laos',
  'Lebanon',
  'Liberia',
  'Libya',
  'Sri Lanka',
  'Lesotho',
  'Lithuania',
  'Luxembourg',
  'Latvia',
  'Morocco',
  'Moldova',
  'Madagascar',
  'Mexico',
  'Macedonia',
  'Mali',
  'Myanmar',
  'Montenegro',
  'Mongolia',
  'Mozambique',
  'Mauritania',
  'Malawi',
  'Malaysia',
  'Namibia',
  'New Caledonia',
  'Niger',
  'Nigeria',
  'Nicaragua',
  'Netherlands',
  'Norway',
  'Nepal',
  'New Zealand',
  'Oman',
  'Pakistan',
  'Panama',
  'Peru',
  'Philippines',
  'Papua New Guinea',
  'Poland',
  'Puerto Rico',
  'North Korea',
  'Portugal',
  'Paraguay',
  'Palestine',
  'Qatar',
  'Romania',
  'Russia',
  'Rwanda',
  'W. Sahara',
  'Saudi Arabia',
  'Sudan',
  'S. Sudan',
  'Senegal',
  'Solomon Is.',
  'Sierra Leone',
  'El Salvador',
  'Somaliland',
  'Somalia',
  'Serbia',
  'Suriname',
  'Slovakia',
  'Slovenia',
  'Sweden',
  'Swaziland',
  'Syria',
  'Chad',
  'Togo',
  'Thailand',
  'Tajikistan',
  'Turkmenistan',
  'Timor-Leste',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Taiwan',
  'Tanzania',
  'Uganda',
  'Ukraine',
  'Uruguay',
  'United States of America',
  'Uzbekistan',
  'Venezuela',
  'Vietnam',
  'Vanuatu',
  'Yemen',
  'South Africa',
  'Zambia',
  'Zimbabwe',
];

export const continents = ['Asia', 'Africa', 'Europe', 'South America', 'Oceania', 'North America'];

export const subRegions = [
  { name: 'Southern Asia', continent: 'Asia' },
  { name: 'Middle Africa', continent: 'Africa' },
  { name: 'Southern Europe', continent: 'Europe' },
  { name: 'Western Asia', continent: 'Asia' },
  { name: 'South America', continent: 'South America' },
  { name: 'Australia and New Zealand', continent: 'Oceania' },
  { name: 'Western Europe', continent: 'Europe' },
  { name: 'Eastern Africa', continent: 'Africa' },
  { name: 'Western Africa', continent: 'Africa' },
  { name: 'Eastern Europe', continent: 'Europe' },
  { name: 'Caribbean', continent: 'North America' },
  { name: 'Central America', continent: 'North America' },
  { name: 'South-Eastern Asia', continent: 'Asia' },
  { name: 'Southern Africa', continent: 'Africa' },
  { name: 'Northern America', continent: 'North America' },
  { name: 'Eastern Asia', continent: 'Asia' },
  { name: 'Northern Europe', continent: 'Europe' },
  { name: 'Northern Africa', continent: 'Africa' },
  { name: 'Melanesia', continent: 'Asia' },
  { name: 'Central Asia', continent: 'Asia' },
];
