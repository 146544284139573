import * as React from 'react';

import RangeSlider from 'components/shared/forms/RangeSlider';
import FormRow from 'components/shared/forms/FormRow';

export default function asset({ assetClass, backgroundColor, defaultMax, defaultMin, name }) {
  const [min, setMin] = React.useState(defaultMin || 0);
  const [max, setMax] = React.useState(defaultMax || 100);

  function handleSlideEnd(values) {
    setMin(values[0]);
    setMax(values[1]);
  }

  const defaultValue = [min, max];
  const pushable = 1;

  return (
    <FormRow>
      <label htmlFor="client_type" className="form__label">
        {assetClass} Range
      </label>
      <div className="mar-b-3">
        <RangeSlider {...{ backgroundColor, defaultValue, handleSlideEnd, pushable }} showValues />
      </div>
      <input
        type="hidden"
        name={`portfolio[portfolio_mandate_attributes][flags_attributes][${name}_minimum]`}
        value={min}
      />
      <input
        type="hidden"
        name={`portfolio[portfolio_mandate_attributes][flags_attributes][${name}_maximum]`}
        value={max}
      />
    </FormRow>
  );
}
