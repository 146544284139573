import * as React from 'react';

import ReactTooltip from 'react-tooltip';
import { format, parse } from 'date-fns';

import Destroy from './Destroy';

export default function columns(updateTable: () => void) {
  return [
    {
      Header: 'Name',
      accessor: 'fileName',
      columnName: 'Name',
      id: 'file_name',
      sortable: false,
    },
    {
      Cell: (row) => {
        return (
          <div data-tip={row.original.users}>
            {row.original.users}
            <ReactTooltip effect="solid" />
          </div>
        );
      },
      Header: 'Users',
      accessor: 'users',
      columnName: 'Users',
      id: 'users',
    },
    {
      Cell: (row) => {
        return <div>{row.original.currency ? row.original.currency.toUpperCase() : ''}</div>;
      },
      Header: 'Currency',
      accessor: 'currency',
      columnName: 'Currency',
      id: 'currency',
    },
    {
      Cell: (row) => {
        if (row.original.documentType === 'pdf')
          return (
            <div>
              <i className="far fa-file-pdf mar-r-1" />
              PDF
            </div>
          );

        return (
          <div>
            <i className="far fa-file-excel mar-r-1" />
            Excel
          </div>
        );
      },
      Header: 'Format',
      accessor: 'documentType',
      columnName: 'format',
      id: 'document_type',
    },
    {
      Cell: (row) => {
        return <div>{format(parse(row.original.createdAt), 'DD MMM YYYY [at] HH:mm')}</div>;
      },
      Header: 'Created At',
      accessor: 'createdAt',
      columnName: 'Created At',
      id: 'created_at',
    },
    {
      Header: 'Created By',
      accessor: 'ownerName',
      columnName: 'Created By',
      id: 'owner_name',
      sortable: false,
    },
    {
      Cell: (row) => {
        return (
          <a href={row.original.downloadPath} target="_blank">
            <div className="rt-tr-clickable-icon">
              <i className="icon-arrow-right-top icon-0-8x" />
            </div>
          </a>
        );
      },
      Header: '',
      sortable: false,
    },
    {
      Cell: (row) => {
        return (
          <div className="frow">
            <Destroy investmentRiskAssessment={row.original} updateTable={updateTable} />
          </div>
        );
      },
      Header: 'Actions',
      accessor: 'actions',
      columnName: 'Actions',
      id: 'actions',
      sortable: false,
    },
  ];
}
