import * as React from 'react';

import startCase from 'lodash.startcase';
import * as numeral from 'numeral';
import { format, parse } from 'date-fns';

export const CustomLegend = (props) => {
  const { payload, showXAxis } = props;

  const legends = payload.map((payloadItem, index) => {
    const lineStyles = {
      background: payloadItem.payload.fill,
      border: `2px solid ${payloadItem.payload.stroke}`,
      borderRadius: '50%',
      display: 'inline-block',
      height: '15px',
      marginBottom: '-2px',
      width: '15px',
    };

    return (
      <span key={index} className="">
        <span style={lineStyles} className="mar-r-1" />
        <span className="text-white mar-r-2" style={{ fontSize: '1.2rem', whiteSpace: 'nowrap' }}>
          {payloadItem.dataKey}
        </span>
      </span>
    );
  });

  return (
    <div
      style={{ border: '1px solid #e4e4e8', padding: '10px', textAlign: 'center', marginTop: showXAxis ? 0 : '15px' }}
    >
      {legends}
    </div>
  );
};

export const CustomTooltip = (props) => {
  const { active, label, payload, transformLabel } = props;

  function compare(a, b) {
    if (parseFloat(a.value) > parseFloat(b.value)) {
      return -1;
    }
    if (parseFloat(a.value) < parseFloat(b.value)) {
      return 1;
    }
    return 0;
  }

  if (active) {
    const wrapperStyles = {
      background: 'rgba(39,42,49, 0.3)',
      padding: '12px 0',
      width: '300px',
    };

    const valueStyles = {
      color: 'white',
      fontSize: '13px',
      fontWeight: 'bold' as 'bold',
      margin: 0,
      textAlign: 'center' as any,
    };

    const labelStyles = {
      textAlign: 'center' as any,
    };

    const tooltips = payload.sort(compare).map((payloadItem, index) => {
      const lineStyles = {
        backgroundColor: payloadItem.color,
        borderRadius: '50%',
        display: 'inline-block',
        height: '15px',
        marginRight: '15px',
        width: '15px',
      };

      return (
        <div key={index} style={valueStyles} className="frow frow--items-center frow--gutters frow--nowrap">
          <div className="col-mc-1-5 col-md-1-8">
            <span style={lineStyles} />
          </div>
          <div className="col-mc-1-5 col-md-2-8">
            {payloadItem.value === 0 ? '0%' : numeral(payloadItem.value).format('0,0.00')}
            {transformLabel && '%'}
          </div>
          <div className={`col-mc-3-5 col-md-6-8 ${transformLabel && 'text-left'}`}>
            <span className="text-normal">{startCase(payloadItem.dataKey)}</span>
          </div>
        </div>
      );
    });

    return (
      <div style={wrapperStyles}>
        <p style={labelStyles} className="small-uppercase mar-v-0 text-white">
          {transformLabel ? format(parse(label), 'DD MMM YYYY') : label}
        </p>
        <div>{tooltips}</div>
      </div>
    );
  }

  return null;
};
