import * as React from 'react';

const classNames = require('classnames');

import Chart from './Chart';
import Table from './Table';
import Context from '../../Context';

export default function presenter({ handleYearChange, selectedYear }) {
  const context = React.useContext(Context);
  const { returnsPresentForYears } = context.props.portfolio.attributes;

  function yearOption() {
    const yearOptions = [1, 2, 3, 4, 5, 6, 7, 8, 0];
    return yearOptions.map((yearNumber, index) => {
      const tabClasses = classNames(
        'button-group__button',
        { 'button-group__button--disabled': returnsPresentForYears < yearNumber },
        { 'button-group__button--active': selectedYear === yearNumber },
      );
      function selectYear() {
        if (returnsPresentForYears < yearNumber) return;

        handleYearChange(yearNumber);
      }
      return (
        <div className={tabClasses} key={index} onClick={selectYear}>
          {yearNumber === 0 ? 'All Time' : `${yearNumber}Y`}
        </div>
      );
    });
  }

  return (
    <div className="platform-panel__inner">
      <div className="button-group mar-t-1 mar-b-2">{yearOption()}</div>
      <div className="frow frow--gutters-2x">
        <div className="col-md-1-2">
          <Chart {...{ selectedYear }} />
        </div>
        <Table {...{ selectedYear }} />
      </div>
    </div>
  );
}
