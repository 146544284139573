import * as React from 'react';

import * as numeral from 'numeral';

// The casting below is because auto isn't assignable to maxWidth but this was the code that existed before
//   the type information was added.
export default function columns() {
  return [
    {
      Header: 'Manager',
      accessor: 'name',
      id: 'name',
      width: ('50%' as unknown) as number,
    },
    {
      Cell: (row) => {
        return <div>{numeral(row.original.value).format('0,0.00')}</div>;
      },
      Header: 'Amount (Client CCY)',
      accessor: 'value',
      id: 'value',
      width: ('30%' as unknown) as number,
    },
    {
      Cell: (row) => {
        return <div>{numeral(row.original.pct).format('0,0.00')}%</div>;
      },
      Header: 'Percent',
      accessor: 'pct',
      id: 'pct',
      width: ('20%' as unknown) as number,
    },
  ];
}
