import React, { useContext } from 'react';

import { format } from 'date-fns';
import { startCase } from 'lodash';

import { portalClientPortfolioPath } from 'javascript/application/ts_routes';

import { ActionPointContext } from '../Show';
import Actions from './actions/Actions';

const Information = () => {
  const {
    client,
    portfolioId,
    portfolioReference,
    actionPoint: { body, status, deferredUntil, actionPointType, firstIncludedDate, response },
  } = useContext(ActionPointContext);

  let responseText = `${startCase(response)}`;
  if (response === 'deferred') responseText += ` - ${format(deferredUntil, 'D MMM YYYY')}`;

  return (
    <section className="platform-panel">
      <header className="platform-panel__header">
        <h2 className="platform-panel__title">
          Action Point -
          <a
            href={portalClientPortfolioPath(client.id, portfolioId)}
            style={{ display: 'inline' }}
            target="_blank"
            rel="noreferrer"
          >
            {` ${portfolioReference}`}
          </a>
        </h2>
      </header>
      <div className="platform-content platform-content--padding-all">
        <div className="platform-content--border-bottom pad-b-2">
          <p className="text-small text-white mar-t-0 mar-b-2">{body}</p>
          <Actions />
        </div>
        <div className="pad-t-2">
          <div className="frow frow--gutters">
            {client.managesActionPointsInPortal === true && (
              <div className="text-small text-white">
                <span className="text-bold">Status: </span>
                <span>{startCase(status)}</span>
              </div>
            )}
            <div className="text-small text-white">
              <span className="text-bold">Response: </span>
              <span>{responseText}</span>
            </div>
            <div className="text-small text-white">
              <span className="text-bold">Type: </span>
              <span>{startCase(actionPointType)}</span>
            </div>
            <div className="text-small text-white">
              <span className="text-bold">First Included: </span>
              <span>{firstIncludedDate && format(firstIncludedDate, 'D MMM YYYY')}</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Information;
