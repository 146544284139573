import React from 'react';

import { startCase, truncate } from 'lodash';

import classNames from 'classnames';

type TagModifiers = 'small' | 'inline';

interface IStatusTagProps {
  status: string;
  modifiers?: TagModifiers[];
  truncateLength?: number;
}

const StatusTag = ({ status, modifiers = [], truncateLength }: IStatusTagProps) => {
  const tagClasses = classNames(
    'tag',
    { 'tag--small': modifiers.includes('small') },
    { 'tag--inline': modifiers.includes('inline') },
    { 'tag--mid-purple': status === 'live' },
    { 'tag--mid-red': status === 'terminated' },
    { 'tag--mid-blue': status === 'component_part' },
    { 'tag--mid-blue': status === 'loan' },
    { 'tag--mid-yellow': status === 'hold' },
    { 'tag--mid-orange': status === 'draft' },
    { 'tag--sienna': status === 'data_feed' },
  );

  let formattedStatus = startCase(status);

  if (truncateLength) formattedStatus = truncate(formattedStatus, { length: truncateLength, omission: '...' });
  return <span className={tagClasses}>{formattedStatus}</span>;
};

export default StatusTag;
