import * as React from 'react';
import * as $ from 'jquery';

import reactRailsUJS from 'react_ujs';

import {
  platformDashboardReviewsPath,
  platformRelationshipManagersDashboardReviewsPath,
} from 'javascript/application/ts_routes';

import Presenter from './reviewOverview/Presenter';

import IReviews from 'components/interfaces/IReviews';

export interface IReviewOverviewProps {
  canCreateReviews: boolean;
  companyId: number;
  quarter: number;
  year: number;
  relationshipManager?: boolean;
}

export default function reviewOverview(props: IReviewOverviewProps) {
  const { canCreateReviews, companyId, relationshipManager } = props;

  const [admin, setAdmin] = React.useState('*');
  const [client, setClient] = React.useState('*');
  const [client_type, setClient_type] = React.useState('*');
  const [data, setData] = React.useState([]);
  const [localRelationshipManager, setLocalRelationshipManager] = React.useState('*');
  const [jurisdiction, setJurisdiction] = React.useState('*');
  const [quarter, setQuarter] = React.useState(props.quarter);
  const [year, setYear] = React.useState(props.year);
  const [isLoading, setIsLoading] = React.useState(true);
  const [totalCount, setTotalCount] = React.useState(null);

  React.useEffect(() => {
    document.addEventListener('dropdownFilters:change', updateReviews);

    // Due to turbolinks and event listeners we need to unmount the whole component from the page in order to
    // avoid multiple listeners sticking around
    document.addEventListener('turbolinks:before-render', () => {
      reactRailsUJS.unmountComponents('.review-overview');
    });

    return () => {
      document.removeEventListener('dropdownFilters:change', updateReviews);
    };
  }, []);

  React.useEffect(() => {
    const customEvent = new CustomEvent('dropdownOverview:ready');
    document.dispatchEvent(customEvent);
  }, []);

  function getData(data = {}) {
    $.ajax({
      beforeSend: () => setIsLoading(true),
      cache: false,
      complete: () => setIsLoading(false),
      data,
      dataType: 'json',
      success: (response: IReviews) => {
        setData(response.meta.overview);
        setTotalCount(response.meta.total);
      },
      type: 'GET',
      url: relationshipManager ? platformRelationshipManagersDashboardReviewsPath() : platformDashboardReviewsPath(),
    });
  }

  function updateReviews(event) {
    const { admin, client, client_type, jurisdiction, quarter, relationship_manager, year } = event.detail;
    setAdmin(admin);
    setClient(client);
    setClient_type(client_type);
    setJurisdiction(jurisdiction);
    setLocalRelationshipManager(relationship_manager);
    setQuarter(quarter);
    setYear(year);
    getData({ admin, client, client_type, jurisdiction, quarter, relationship_manager, year });
  }

  const filterOptions = {
    jurisdiction,
    relationship_manager_id: localRelationshipManager,
  };

  return (
    <Presenter
      {...{
        admin,
        canCreateReviews,
        companyId,
        data,
        client_type,
        filterOptions,
        isLoading,
        totalCount,
        quarter,
        year,
      }}
    />
  );
}
