import * as React from 'react';
import * as $ from 'jquery';

import Alert from 'components/shared/Alert';
import CustomModal from 'components/shared/CustomModal';
import Form from './Form';
import FormTag from 'components/shared/forms/FormTag';
import { ajaxErrorString } from 'components/shared/Utils';
import useVisibility from 'components/shared/customHooks/useVisibility';

import { platformNotesPath } from 'javascript/application/ts_routes';
import ISelectOption from 'components/interfaces/ISelectOption';

interface IProps {
  currentUser: string;
  tags: ISelectOption[];
  handleSuccessfulFormSubmission(): void;
}

export default function newNote(props: IProps) {
  const { currentUser, tags } = props;

  const [errorMessage, setErrorMessage] = React.useState(null);
  const [files, setFiles] = React.useState([]);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [updateOnClose, setUpdateOnClose] = React.useState(false);

  const { isOpen, handleClose, handleOpen } = useVisibility(false);

  function handleUploadedFiles(files) {
    setFiles(files);
  }

  function handleFormSubmission(event) {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);
    files.forEach((file) => {
      formData.append('note[attachments][]', file);
    });
    $.ajax({
      beforeSend: () => {
        setIsSubmitting(true);
        setErrorMessage(null);
      },
      complete: () => setIsSubmitting(false),
      contentType: false,
      data: formData,
      dataType: 'json',
      error: (jqXhr) => setErrorMessage(ajaxErrorString(jqXhr)),
      processData: false,
      success: () => {
        handleClose();
        props.handleSuccessfulFormSubmission();
      },
      type: form.method,
      url: form.action,
    });
  }

  return (
    <React.Fragment>
      <a style={{ display: 'block', cursor: 'pointer' }} onClick={handleOpen}>
        <div className="button button--secondary button--no-min-width">
          Add Note <i className="icon-plus icon-fw mar-l-1 icon-push-down-1" />
        </div>
      </a>
      <CustomModal isOpen={isOpen} title="Add New Note" handleClose={handleClose}>
        <div className="modal__content">
          <div className="frow">
            <div className="col-md-2-3">
              <Alert message={errorMessage} modifier="dark" title="Missing Fields" type="error" />
            </div>
          </div>
          <FormTag className="form--light" method="post" onSubmit={handleFormSubmission} url={platformNotesPath()}>
            <Form
              {...{ handleClose, currentUser, tags }}
              handleSuccessfulFormSubmission={props.handleSuccessfulFormSubmission}
              handleUploadedFiles={handleUploadedFiles}
              isSubmitting={isSubmitting}
              setUpdateOnClose={setUpdateOnClose}
            />
          </FormTag>
        </div>
      </CustomModal>
    </React.Fragment>
  );
}
