import React from 'react';
import Button from 'components/shared/forms/Button';
import CustomAlert from 'components/shared/Alert';
import { Alert, Intent } from '@blueprintjs/core';
import PersonalDetails from './PersonalDetails';
import ClientPortalManagement from './ClientPortalManagement';
import ClientRoles from './ClientRoles';
import { Frow, Column } from 'components/frow';

const FormJsx = ({
  errorMessage,
  handleManualChange,
  user,
  handleChange,
  resource,
  portalManager,
  handleSubmit,
  isSubmitting,
  isOpen,
  handleAlertClose,
  handleClose,
  updateResource,
  formattedUsers,
}) => {
  return (
    <div className="form">
      <CustomAlert message={errorMessage} modifier="dark" title="Errors on submission" type="error" />
      <ClientRoles {...{ user }} />
      <fieldset className="form__fieldset">
        <h4 className="form__fieldset-label">Personal Details</h4>
        <div className="frow frow--gutters">
          <PersonalDetails {...{ handleChange, resource }} />
        </div>
      </fieldset>
      {portalManager && !user.discardedAt && (
        <ClientPortalManagement {...{ handleLoginToggle: handleManualChange, resource }} />
      )}
      <Frow gutterSize={1}>
        <Column>
          <Frow gutterSize={1} itemAlignment="center" columnContext={{ maxColumns: 5 }}>
            <Column columnSpan={2}>
              <Button
                buttonClasses="button--full-width"
                text="Save"
                handleClick={handleSubmit}
                isSubmitting={isSubmitting}
                submittingText="Saving"
              />
            </Column>
            <div className="col-sm-3-5" />
          </Frow>
        </Column>
      </Frow>
      <Alert
        isOpen={isOpen}
        onClose={handleAlertClose}
        canEscapeKeyCancel
        canOutsideClickCancel
        cancelButtonText="Cancel"
        confirmButtonText="Confirm"
        intent={Intent.PRIMARY}
        onCancel={handleClose}
        onConfirm={updateResource}
        portalContainer={document.body}
      >
        <p className="mar-t-0">We have found these users which are similar in some way:</p>
        <div className="frow frow--direction-column w-100">{formattedUsers}</div>
        <p>
          You can click on a user to go and edit that user instead if required. If you are sure this new user is
          required click confirm.
        </p>
      </Alert>
    </div>
  );
};

export default FormJsx;
