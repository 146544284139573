import { Attr, Model } from 'spraypaint';
import { ApplicationRecord } from './ApplicationRecord';

@Model()
export class CashFlow extends ApplicationRecord {
  public static jsonapiType = 'cash_flows';
  @Attr() public cashFlowableType: string;
  @Attr() public cashFlowableId: string;
  @Attr() public amount: number;
  @Attr() public description: string;
  @Attr() public valueDate: string;
  @Attr() public discardedAt: Date;
}
