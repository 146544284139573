import * as React from 'react';

type CT<Props> = React.ComponentType<Props>;

export interface IHeaderProps<ButtonProps, NewLinkProps, SearchProps> {
  title: React.ReactNode;
  Button: CT<ButtonProps>;
  buttonProps: React.PropsWithChildren<ButtonProps>;
  NewLink: CT<NewLinkProps>;
  newLinkProps: React.PropsWithChildren<NewLinkProps>;
  Search: CT<SearchProps>;
  searchProps: React.PropsWithChildren<SearchProps>;
}

export type TAnyHeaderProps = IHeaderProps<any, any, any>;

const header = <ButtonProps, NewLinkProps, SearchProps>(
  props: IHeaderProps<ButtonProps, NewLinkProps, SearchProps>,
) => {
  const { title, Button, buttonProps, NewLink, newLinkProps, Search, searchProps } = props;

  return (
    <div className="platform-panel platform-panel--no-background">
      <div className="frow frow--justify-between frow--items-center">
        <div className="frow frow--items-center">
          <h2 className="platform-panel__title mar-r-3">{title}</h2>
          {Search && <Search {...searchProps} />}
        </div>
        <div className="frow frow--items-center">
          <div>
            <NewLink {...newLinkProps} />
          </div>
          <div>
            <Button {...buttonProps} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default header;
