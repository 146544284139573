import * as React from 'react';

import { format, parse } from 'date-fns';
import { startCase, camelCase, snakeCase } from 'lodash';

const classNames = require('classnames');
const numeral = require('numeral');

export default function columns(mandate, assetClasses) {
  if (!mandate) return;
  const formattedRow = (row, attribute, min, max) => {
    const attributeSnakeCase = snakeCase(attribute);
    const value = parseInt(row.original[attributeSnakeCase], 10);
    const rowClasses = classNames('frow', 'frow--items-center', { 'text-error text-bold': value < min || value > max });
    return <div className={rowClasses}>{numeral(row.original[attributeSnakeCase]).format('0,0.00')}%</div>;
  };

  const assetClassColumns = assetClasses.map(camelCase).map((assetClass) => ({
    Cell: (row) => formattedRow(row, assetClass, mandate[`${assetClass}Minimum`], mandate[`${assetClass}Maximum`]),
    Header: startCase(assetClass),
    accessor: assetClass,
    id: assetClass,
  }));

  return [
    {
      Cell: (row) => {
        return <div>{format(parse(row.original.value_date), 'DD MMM YYYY')}</div>;
      },
      Header: 'Date',
      accessor: 'value_date',
      id: 'value_date',
    },
    ...assetClassColumns,
    {
      Cell: (row) => formattedRow(row, 'unclassified', 0, 0),
      Header: 'Unclassified',
      accessor: 'unclassified',
      id: 'unclassified',
    },
    {
      Cell: (row) => formattedRow(row, 'sum', 0, 101),
      Header: 'Sum',
      accessor: 'sum',
      id: 'sum',
    },
  ];
}
