import React from 'react';

import { equals, filter, propEq, pluck, pipe, prop } from 'ramda';

import { User } from 'javascript/models';

import ReactSelect from 'components/shared/forms/ReactSelect';
import useAsyncFunctionInjector, { afterAction } from 'components/shared/customHooks/useAsyncFunctionInjector';
import useRerender from 'components/shared/customHooks/useRerender';
import Loading from 'components/shared/Loading';

interface IRoleSelector {
  roleable: any;
  user: User;
}

const RoleSelector = ({ roleable, user }: IRoleSelector) => {
  const selectableRoles = roleable.roles || [];

  const rerender = useRerender();

  const newSave = React.useCallback((trigger) => {
    if (trigger === afterAction) rerender();
  }, []);

  const localUser: User = useAsyncFunctionInjector(newSave, 'save', user);

  if (selectableRoles.length === 0) return <span>This client currently has no roles</span>;
  if (!localUser) return <Loading />;

  const filterByRoleable = filter(pipe(prop('roleable'), propEq('id', roleable.id)));
  const roleableUserRoles = filterByRoleable(localUser.roles);

  const isMulti = false;

  const handleSelectChange = (newValue) => {
    const localNewValue = newValue && pluck('value', isMulti ? newValue : [newValue]);
    if (equals(localNewValue, roleableUserRoles)) return;

    localUser.removeRoles(...roleableUserRoles);
    if (newValue) localUser.addRoles(...localNewValue);

    return localUser.save({ displayQueryResult: true });
  };

  const customStyles = {
    menuPortal: (provided) => ({
      ...provided,
      zIndex: '9999999 !important',
    }),
  };

  const createOptions = (role) => ({ label: role.name, value: role });

  const roleOptions = selectableRoles.map(createOptions);
  const chosenRoles = roleableUserRoles.map(createOptions);

  return (
    <ReactSelect
      isClearable
      options={roleOptions}
      theme="dark"
      id="client_ids"
      name="client_ids"
      onChange={handleSelectChange}
      value={chosenRoles}
      isMulti={isMulti}
      customSelectStyles={customStyles}
      menuPortalTarget={document.body}
    />
  );
};

export default RoleSelector;
