import * as React from 'react';

import { UploadTemplateCondition } from 'javascript/models';

interface IProps {
  uploadTemplateCondition: UploadTemplateCondition;
  handleAttributeChange(attribute: string, value: string): void;
}

export default function matchCondition(props: IProps) {
  const { handleAttributeChange, uploadTemplateCondition } = props;

  const matchOptions = [
    { label: 'Ignore everything before', value: 'ignore_before' },
    { label: 'Ignore everything after', value: 'ignore_after' },
  ];

  function handleChange(event) {
    handleAttributeChange(event.target.id, event.target.value);
  }

  const options = matchOptions.map((option) => {
    return (
      <div key={option.value} className="pretty pretty--large pretty--with-label p-round">
        <input
          type="radio"
          id="matchOption"
          value={option.value}
          onChange={handleChange}
          defaultChecked={uploadTemplateCondition.matchOption === option.value}
        />
        <div className="state p-blue-circle">
          <label>{option.label}</label>
        </div>
      </div>
    );
  });

  return (
    <div
      className="platform-content platform-content--padding-bottom platform-content--border-dashed-bottom-grey
        platform-content--spacing-bottom"
    >
      <div className="frow frow--centered-column frow--gutters">
        <div className="col-mc-1-1">
          <label className="form__label">Condition</label>
          {options}
        </div>
        <div className="col-mc-1-1">
          <label className="form__label">Character or word</label>
          <input
            type="text"
            id="matchText"
            placeholder="Character or word to use to ignore before or after"
            onChange={handleChange}
            defaultValue={uploadTemplateCondition.matchText || ''}
          />
        </div>
      </div>
    </div>
  );
}
