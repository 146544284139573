export const rowOptions = [
  'Portfolio',
  'Benchmark 1',
  'Benchmark 2',
  'Benchmark 3',
  'Peer Group',
  'Peer Group Low',
  'Peer Group Med.',
  'Peer Group High',
  'Cash',
  'Fixed Income',
  'Equity',
  'Hedge',
  'Vs. Primary Measure',
  'Vs. Secondary Measure',
];
