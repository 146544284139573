import * as React from 'react';

import { Client } from 'javascript/models';

import { Frow, Column } from 'components/frow';

import ReactSelect, { optionsGenerator } from 'components/shared/forms/ReactSelect';

export interface IAdditionalPortfolioProps {
  client: Pick<Client, 'additionalPortfolioAttributes'>;
}

const AdditionalPortfolioAttributes = ({ client: { additionalPortfolioAttributes } }: IAdditionalPortfolioProps) => (
  <fieldset className="form__fieldset">
    <h4 className="form__fieldset-label">Additional Portfolio Attributes</h4>

    <Frow gutterSize={1}>
      <Column>
        <Frow gutterSize={1} itemAlignment="center" columnContext={{ maxColumns: 5 }}>
          <Column columnSpan={2}>
            <label className="form__label">Attribute Names</label>
            <ReactSelect
              createable
              isMulti
              name="client[additional_portfolio_attributes][]"
              options={[]}
              defaultValues={optionsGenerator(additionalPortfolioAttributes || [])}
              theme="dark"
            />
          </Column>

          <Column columnSpan={3} />
        </Frow>
      </Column>
    </Frow>
  </fieldset>
);

export default AdditionalPortfolioAttributes;
