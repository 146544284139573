import * as React from 'react';

const classNames = require('classnames');

import Graph from './Graph';
import Tables from './Tables';

export default function data() {
  const [selectedTab, setSelectedTab] = React.useState('tables');

  const tabTitles = ['graph', 'tables'];

  const tabs = tabTitles.map((tab, index) => {
    const tabClass = classNames('button-group__button', { 'button-group__button--active': selectedTab === tab });
    function selectTab() {
      setSelectedTab(tab);
    }
    return (
      <div key={index} className={tabClass} onClick={selectTab}>
        <span className="text-bold letter-spacing-1">{tab}</span>
      </div>
    );
  });

  function renderSection() {
    if (selectedTab === 'tables') {
      return <Tables />;
    }
    return <Graph />;
  }

  return (
    <div className="platform-content platform-content--padding-vertical platform-content--border-bottom">
      <p className="platform-panel__title">Data</p>
      <div className="button-group mar-v-4">{tabs}</div>
      {renderSection()}
    </div>
  );
}
