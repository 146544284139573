import * as React from 'react';

import { Menu, MenuItem, Popover, Position } from '@blueprintjs/core';

const classNames = require('classnames');
import lodashStartCase from 'lodash.startcase';

const monitoringOutline = require('../../../images/icons/monitoring-outline.svg').default;
const portfolioOutline = require('../../../images/icons/portfolio-outline.svg').default;
const consultingOutline = require('../../../images/icons/consultancy-outline.svg').default;
const groupOutline = require('../../../images/icons/bespoke-outline.svg').default;

import { platformReportTemplatePath } from 'javascript/application/ts_routes';

export default function reportTemplate({ fetchData, reportBuckets, template }) {
  async function handleBucketSelect() {
    template.reportTemplateBucketId = this;
    await template.save();
    fetchData();
  }

  const reportBucketOptions = reportBuckets.map((bucket) => {
    return <MenuItem key={bucket.value} text={bucket.label} onClick={handleBucketSelect.bind(bucket.value)} />;
  });

  function themeIcon() {
    switch (template.theme) {
      case 'monitoring':
        return <img src={monitoringOutline} alt="monitoring outline logo" className="mar-b-1" />;
      case 'portfolio':
        return <img src={portfolioOutline} alt="monitoring outline logo" className="mar-b-1" />;
      case 'consulting':
        return <img src={consultingOutline} alt="monitoring outline logo" className="mar-b-1" />;
      case 'group':
        return <img src={groupOutline} alt="monitoring outline logo" className="mar-b-1" />;
      case 'custom':
        return <div className="mar-b-1 text-white text-small text-uppercase">Custom</div>;
      default:
        return <span />;
    }
  }

  const indicatorClasses = classNames(
    'status-indicator',
    'mar-v-0',
    'text-white',
    'small-uppercase',
    { 'status-indicator--sage': template.status === 'live' },
    { 'status-indicator--orange': template.status !== 'live' },
  );

  return (
    <div className="col-sm-1-3 col-lg-1-5">
      <div className="card card--fixed-height" style={{ height: '100%' }}>
        <div className="card__highlight" style={{ backgroundColor: template.reportTemplateBucketColor }} />
        <div className="card__inner">
          <div className="frow frow--nowrap frow--direction-column frow--full-height">
            <div className="frow frow--items-end frow--justify-between mar-b-2">
              <p className={indicatorClasses}>{lodashStartCase(template.status)}</p>
              <Popover usePortal={false} position={Position.BOTTOM}>
                <div className="mar-r-1">
                  <i className="icon-overflow-horizontal-white icon text-white" />
                </div>
                <Menu>{reportBucketOptions}</Menu>
              </Popover>
            </div>
            <a style={{ marginTop: 'auto' }} href={platformReportTemplatePath(template.id)}>
              {themeIcon()}
              <p className="card__title">{template.name}</p>
              <div>
                <div className="frow frow--justify-between">
                  <p className="card__subtitle">Click to View</p>
                  <i
                    className={`fas fa-2x fa-${template.orientation === 'portrait' ? 'portrait' : 'image'} text-white`}
                  />
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
