import { Attr, Model } from 'spraypaint';
import { ApplicationRecord } from './ApplicationRecord';

@Model()
export class PortfolioBenchmark extends ApplicationRecord {
  public static jsonapiType = 'portfolio_benchmarks';
  @Attr() public benchmarkType: string;
  @Attr() public composite: boolean;
  @Attr() public canBeSurfaced: boolean;
  @Attr() public currency: string;
  @Attr() public dataFrequencyMonths: number;
  @Attr() public description: string;
  @Attr() public lastReturnDate: string;
  @Attr() public providerReferenceId: string;
  @Attr() public reference: string;
  @Attr() public risk: string;
  @Attr() public showPath: string;
  @Attr() public systemInformation: string;
}
