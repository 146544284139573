import * as React from 'react';

import Loading from 'components/shared/Loading';
import Overall from './verificationTargets/Overall';
import Individual from './verificationTargets/Individual';
import { addQuarters, differenceInCalendarWeeks, endOfQuarter, startOfQuarter, startOfYear } from 'date-fns';
import range from 'components/shared/utilities/range';
import ReactLineChart from 'components/shared/charting/recharts/ReactLineChart';

export default function verificationTargets({
  frequency,
  isLoading,
  meta,
  selectedDate,
  tracker,
  view,
  verificationTeamMembers,
}) {
  function renderData() {
    const nextQuarter = endOfQuarter(addQuarters(selectedDate, 1));
    const numberOfWeeksToAdd = differenceInCalendarWeeks(startOfQuarter(nextQuarter), startOfYear(nextQuarter));

    const { verification_complete_week } = meta.stats.grouped_by;
    const { for_verification } = meta.stats.total;
    const { overallTargets } = tracker;

    const lines = ['Verified', 'Target'];
    const lineStyles = {
      Verified: { stroke: '#4eacd0' },
      Target: { stroke: '#f6f6f8' }
    };
    let totalProcessed = 0;
    let totalTarget = 0;
    const currentWeek = differenceInCalendarWeeks(new Date(), startOfYear(nextQuarter)) - numberOfWeeksToAdd + 1;
    const data = range(1, 13).map((num, index) => {
      const processed = verification_complete_week[num + numberOfWeeksToAdd] || 0;
      const target = overallTargets[`week_${num}`] || 0;
      const targetNumber = for_verification * (target / 100);
      totalProcessed += processed;
      if (currentWeek < num) {
        totalProcessed = null;
      }
      totalTarget += targetNumber;
      return {
        name: num,
        series: [
          { label: 'Verified', value: totalProcessed },
          { label: 'Target', value: totalTarget },
        ],
      };
    });

    data.unshift({
      name: 0,
      series: [
        { label: 'Verified', value: 0 },
        { label: 'Target', value: 0 },
      ],
    });

    return (
      <React.Fragment>
        {renderTable()}
        <div className="mar-t-4">
          <ReactLineChart
            data={data}
            height={350}
            legend
            minData={0}
            maxData={for_verification}
            changeMinMax={false}
            showTooltip={false}
            useDateTickReducer={false}
            {...{ lines, lineStyles }}
          />
        </div>
      </React.Fragment>
    );
  }

  function renderTable() {
    if (view === 'overall') {
      return <Overall {...{ frequency, meta, selectedDate, tracker }} />;
    }
    return <Individual {...{ verificationTeamMembers, frequency, meta, selectedDate, tracker }} />;
  }

  return (
    <section className="platform-panel" style={{ overflowX: 'scroll', overflowY: 'hidden' }}>
      <header className="platform-panel__header">
        <h2 className="platform-panel__title">Verification Targets</h2>
      </header>
      <div className="platform-content platform-content--padding-all">{isLoading ? <Loading /> : renderData()}</div>
    </section>
  );
}
