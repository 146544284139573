import * as React from 'react';

interface IProps {
  large: boolean;
  showHeaders: boolean;
  templateSection?: { tableDetails: { time_period: string } };
}

export default function assetMovement(props: IProps) {
  const { showHeaders, large, templateSection } = props;

  const oneYear =
    templateSection && templateSection.tableDetails && templateSection.tableDetails.time_period === '1_year';

  const headers = (
    <thead>
      <tr>
        <th style={{ width: '34%', verticalAlign: 'top' }} className="text-left">
          Date
        </th>
        <th style={{ width: '22%', verticalAlign: 'top' }} className="text-right">
          Balance
        </th>
        <th style={{ width: '22%', verticalAlign: 'top' }} className="text-right">
          In
        </th>
        <th style={{ width: '22%', verticalAlign: 'top' }} className="text-right">
          Out
        </th>
      </tr>
    </thead>
  );

  if (oneYear) {
    return (
      <div className="frow frow--gutters">
        <div className="col-mc-1-2">
          <table className={`pdf-table pdf-table--striped ${large && 'pdf-table--large'}`}>
            {showHeaders && headers}
            <tbody>
              <tr>
                <td className="pdf-table__row-label">Date</td>
                <td className="text-right">Value</td>
                <td className="text-right">Value</td>
                <td className="text-right">Value</td>
              </tr>
              <tr>
                <td className="pdf-table__row-label">Date</td>
                <td className="text-right">Value</td>
                <td className="text-right">Value</td>
                <td className="text-right">Value</td>
              </tr>
              <tr>
                <td className="pdf-table__row-label">Date</td>
                <td className="text-right">Value</td>
                <td className="text-right">Value</td>
                <td className="text-right">Value</td>
              </tr>
              <tr>
                <td className="pdf-table__row-label">Date</td>
                <td className="text-right">Value</td>
                <td className="text-right">Value</td>
                <td className="text-right">Value</td>
              </tr>
              <tr>
                <td className="pdf-table__row-label">Date</td>
                <td className="text-right">Value</td>
                <td className="text-right">Value</td>
                <td className="text-right">Value</td>
              </tr>
              <tr>
                <td className="pdf-table__row-label">Date</td>
                <td className="text-right">Value</td>
                <td className="text-right">Value</td>
                <td className="text-right">Value</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col-mc-1-2">
          <table className={`pdf-table pdf-table--striped ${large && 'pdf-table--large'}`}>
            {showHeaders && headers}
            <tbody>
              <tr>
                <td className="pdf-table__row-label">Date</td>
                <td className="text-right">Value</td>
                <td className="text-right">Value</td>
                <td className="text-right">Value</td>
              </tr>
              <tr>
                <td className="pdf-table__row-label">Date</td>
                <td className="text-right">Value</td>
                <td className="text-right">Value</td>
                <td className="text-right">Value</td>
              </tr>
              <tr>
                <td className="pdf-table__row-label">Date</td>
                <td className="text-right">Value</td>
                <td className="text-right">Value</td>
                <td className="text-right">Value</td>
              </tr>
              <tr>
                <td className="pdf-table__row-label">Date</td>
                <td className="text-right">Value</td>
                <td className="text-right">Value</td>
                <td className="text-right">Value</td>
              </tr>
              <tr>
                <td className="pdf-table__row-label">Date</td>
                <td className="text-right">Value</td>
                <td className="text-right">Value</td>
                <td className="text-right">Value</td>
              </tr>
              <tr>
                <td className="pdf-table__row-label">Date</td>
                <td className="text-right">Value</td>
                <td className="text-right">Value</td>
                <td className="text-right">Value</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  return (
    <div className="frow frow--gutters">
      <div className="col-mc-1-2">
        <table className={`pdf-table pdf-table--striped ${large && 'pdf-table--large'}`}>
          {showHeaders && headers}
          <tbody>
            <tr>
              <td className="pdf-table__row-label">Date</td>
              <td className="text-right">Value</td>
              <td className="text-right">Value</td>
              <td className="text-right">Value</td>
            </tr>
            <tr>
              <td className="pdf-table__row-label">Date</td>
              <td className="text-right">Value</td>
              <td className="text-right">Value</td>
              <td className="text-right">Value</td>
            </tr>
            <tr>
              <td className="pdf-table__row-label">Date</td>
              <td className="text-right">Value</td>
              <td className="text-right">Value</td>
              <td className="text-right">Value</td>
            </tr>
            <tr>
              <td className="pdf-table__row-label">Date</td>
              <td className="text-right">Value</td>
              <td className="text-right">Value</td>
              <td className="text-right">Value</td>
            </tr>
            <tr>
              <td className="pdf-table__row-label">Date</td>
              <td className="text-right">Value</td>
              <td className="text-right">Value</td>
              <td className="text-right">Value</td>
            </tr>
            <tr>
              <td className="pdf-table__row-label">Date</td>
              <td className="text-right">Value</td>
              <td className="text-right">Value</td>
              <td className="text-right">Value</td>
            </tr>
            <tr>
              <td className="pdf-table__row-label">Date</td>
              <td className="text-right">Value</td>
              <td className="text-right">Value</td>
              <td className="text-right">Value</td>
            </tr>
            <tr>
              <td className="pdf-table__row-label">Date</td>
              <td className="text-right">Value</td>
              <td className="text-right">Value</td>
              <td className="text-right">Value</td>
            </tr>
            <tr>
              <td className="pdf-table__row-label">Date</td>
              <td className="text-right">Value</td>
              <td className="text-right">Value</td>
              <td className="text-right">Value</td>
            </tr>
            <tr>
              <td className="pdf-table__row-label">Date</td>
              <td className="text-right">Value</td>
              <td className="text-right">Value</td>
              <td className="text-right">Value</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="col-mc-1-2">
        <table className={`pdf-table pdf-table--striped ${large && 'pdf-table--large'}`}>
          {showHeaders && headers}
          <tbody>
            <tr>
              <td className="pdf-table__row-label">Date</td>
              <td className="text-right">Value</td>
              <td className="text-right">Value</td>
              <td className="text-right">Value</td>
            </tr>
            <tr>
              <td className="pdf-table__row-label">Date</td>
              <td className="text-right">Value</td>
              <td className="text-right">Value</td>
              <td className="text-right">Value</td>
            </tr>
            <tr>
              <td className="pdf-table__row-label">Date</td>
              <td className="text-right">Value</td>
              <td className="text-right">Value</td>
              <td className="text-right">Value</td>
            </tr>
            <tr>
              <td className="pdf-table__row-label">Date</td>
              <td className="text-right">Value</td>
              <td className="text-right">Value</td>
              <td className="text-right">Value</td>
            </tr>
            <tr>
              <td className="pdf-table__row-label">Date</td>
              <td className="text-right">Value</td>
              <td className="text-right">Value</td>
              <td className="text-right">Value</td>
            </tr>
            <tr>
              <td className="pdf-table__row-label">Date</td>
              <td className="text-right">Value</td>
              <td className="text-right">Value</td>
              <td className="text-right">Value</td>
            </tr>
            <tr>
              <td className="pdf-table__row-label">Date</td>
              <td className="text-right">Value</td>
              <td className="text-right">Value</td>
              <td className="text-right">Value</td>
            </tr>
            <tr>
              <td className="pdf-table__row-label">Date</td>
              <td className="text-right">Value</td>
              <td className="text-right">Value</td>
              <td className="text-right">Value</td>
            </tr>
            <tr>
              <td className="pdf-table__row-label">Date</td>
              <td className="text-right">Value</td>
              <td className="text-right">Value</td>
              <td className="text-right">Value</td>
            </tr>
            <tr>
              <td className="pdf-table__row-label">Date</td>
              <td className="text-right">Value</td>
              <td className="text-right">Value</td>
              <td className="text-right">Value</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
