import * as React from 'react';

import CustomModal from 'components/shared/CustomModal';
import useVisibility from 'components/shared/customHooks/useVisibility';

import { UploadTemplateSheetColumn } from 'javascript/models';
import Context from '../../Context';

interface IProps {
  uploadTemplateSheetColumn: UploadTemplateSheetColumn;
  handleDataFormatChange(format: string): void;
}

export default function dataFormat(props: IProps) {
  const { handleDataFormatChange, uploadTemplateSheetColumn } = props;

  const context = React.useContext(Context);
  const { dataFormatOptions } = context.props;

  const { isOpen, handleClose, handleOpen } = useVisibility(false);

  function handleChange(event) {
    handleDataFormatChange(event.target.value);
  }

  const formatOptions = dataFormatOptions.map((formatOption) => {
    return (
      <div key={formatOption.value}>
        <div className="pretty pretty--large pretty--with-label p-round">
          <input
            type="radio"
            value={formatOption.value}
            id="column_type"
            onChange={handleChange}
            checked={uploadTemplateSheetColumn.dataFormat === formatOption.value}
          />
          <div className="state p-blue-circle">
            <label>{formatOption.label}</label>
          </div>
        </div>
      </div>
    );
  });

  return (
    <React.Fragment>
      <span className="link link--secondary" onClick={handleOpen}>
        Data Format {uploadTemplateSheetColumn.dataFormat ? `(${uploadTemplateSheetColumn.dataFormat})` : '(default)'}
      </span>
      <CustomModal isOpen={isOpen} handleClose={handleClose} title="Data Format">
        <div className="modal__content">
          <div className="form form--light">
            <div className="frow frow--gutters-2x">
              <div className="col-mc-1-1">
                <label className="form__label">Select the data format</label>
                <p className="helper-text mar-b-2">
                  If you don't select a data format for this the column the default for the selected value will be used
                </p>
                <div className="frow frow--gutters frow--direction-column">{formatOptions}</div>
              </div>
              <div className="col-mc-1-1">
                <button className="button button--light-themed" onClick={handleClose}>
                  Save & Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </React.Fragment>
  );
}
