import * as React from 'react';

import { portalClientPortfolioDrawdownSeriesIndexPath } from 'javascript/application/ts_routes';

import { assign, reject, uniq } from 'lodash';
import useRadioSelect from '../../../../shared/customHooks/useRadioSelect';
import useAjaxRequest from '../../../../shared/customHooks/useAjaxRequest';
import LineChartWithModal from '../../../../shared/charting/composed/LineChartWithModal';
import ClientPortalCard from '../../../shared/ClientPortalCard';
import { format } from 'date-fns';
import { IRequestParams } from './shared/types';
import { useClientPortalTheme } from '../../../../shared/colours';
import { normaliseBenchmarkName } from 'components/shared/utilities/labelNormalisation';

export default function drawdown({ currentReviewPeriod, portfolioObject }) {
  const { client_id, id } = portfolioObject;

  const chartDataURL = portalClientPortfolioDrawdownSeriesIndexPath(client_id, id);

  const [chartData, setChartData] = React.useState();

  const timePeriods = reject(portfolioObject.timePeriods, ({ value }) => ['3_months', '6_months'].includes(value));

  const noDataComponent = <p className="text-white helper-text mar-t-3 mar-b-4">No data to display</p>;

  const [radioToggle, selectedRadioRef] = useRadioSelect(timePeriods, Math.min(1, timePeriods.length - 1));

  const [getChartData, isError, isLoading] = useAjaxRequest<IRequestParams, any>({
    method: 'GET',
    path: chartDataURL,
    stateSetter: setChartData,
  });

  React.useEffect(() => {
    getChartData({
      time_period: timePeriods[selectedRadioRef.current].value,
      review_period: currentReviewPeriod,
    });
  }, [currentReviewPeriod, selectedRadioRef.current]);

  const cardProps = {
    title: 'Drawdown',
    subTitle: format(currentReviewPeriod, "DD MMM 'YY"),
    titleControls: radioToggle,
  };

  const keys: string[] = chartData && uniq(chartData.data.flatMap((timeData) => timeData.series.map((key) => key.label))) || [];

  const getColour = useClientPortalTheme("benchmark");
  const lineStyles = assign(
    {},
    ...keys.map((key) => ({ [key]: { stroke: getColour(normaliseBenchmarkName(key)) } })),
  );

  const lineChartModifiers = {
    legend: true,
    showXAxis: true,
    showYAxis: true,
    height: 325,
  };

  const lineChartProps = {
    isLoading,
    chartData: chartData && chartData.data,
    radioToggle,
    lineChartModifiers,
    benchmarkOptions: { initial: true, showToggle: false },
    lineStyles,
  };

  const cardContent = isError ? noDataComponent : <LineChartWithModal {...lineChartProps} />;

  return (
    <div className="col-md-1-2 hide-md-block">
      <ClientPortalCard {...cardProps}>{cardContent}</ClientPortalCard>
    </div>
  );
}
