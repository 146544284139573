import * as React from 'react';
import * as $ from 'jquery';

const routes = require('../../../../../../../javascript/application/routes');

import INote from 'components/interfaces/INote';
import CsrfToken from 'components/shared/CsrfToken';

import { Note } from 'javascript/models/Models';
import { THasManyAttachedRcvd } from 'javascript/models/shared/types';

interface IProps {
  note?: Note;
  setUpdateOnClose: any;
  handleSuccessfulFormSubmission(): void;
}

export default function attachments(props: IProps) {
  const { note } = props;

  if (!note) return null;

  const [attachments, setAttachments] = React.useState(note.attachments as THasManyAttachedRcvd);

  function handleDeleteAttachment() {
    $.ajax({
      data: { authenticity_token: CsrfToken() },
      dataType: 'json',
      success: () => {
        props.setUpdateOnClose(true);
        const newAttachments = attachments.filter((attachment) => attachment.id !== this);
        setAttachments(newAttachments);
      },
      type: 'delete',
      url: routes.platform_note_attachment_path(note.id, this),
    });
  }

  const formattedAttachments = attachments.map((attachment) => {
    return (
      <span key={attachment.id}>
        <i
          className="icon-cross icon-clickable icon-0-9x text-brand-blue mar-r-1"
          onClick={handleDeleteAttachment.bind(attachment.id)}
        />
        <a className="link link--secondary link--blue" href={attachment.path} target="_blank">
          {attachment.name}
        </a>{' '}
        <br />
      </span>
    );
  });

  if (!formattedAttachments.length) return null;

  return (
    <p className="mar-b-0 text-small" style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
      <span className="small-uppercase">Saved Attachments</span>
      <br />
      <span className="text-black">{formattedAttachments}</span>
    </p>
  );
}
