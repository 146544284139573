import { Attr, BelongsTo, HasMany, Model } from 'spraypaint';
import { ApplicationRecord } from './ApplicationRecord';
import { ActionPoint } from './ActionPoint';

@Model()
export class ActionPointsReview extends ApplicationRecord {
  public static jsonapiType = 'action_points_reviews';
  @Attr() public reviewId: string;
  @Attr() public include: boolean;
  @BelongsTo() public actionPoint: ActionPoint;
}
