import * as React from 'react';

import TemplateSheet from './sheets/TemplateSheet';

import { UploadTemplate, UploadTemplateSheet } from 'javascript/models';

interface IProps {
  uploadTemplate: UploadTemplate;
}

export default function sheets(props: IProps) {
  const { uploadTemplate } = props;

  const [uploadTemplateSheets, setUploadTemplateSheets] = React.useState(props.uploadTemplate.uploadTemplateSheets);

  React.useEffect(() => {
    setUploadTemplateSheets(props.uploadTemplate.uploadTemplateSheets);
  }, [props.uploadTemplate.uploadTemplateSheets]);

  async function handleAddSheet() {
    const newSheet = new UploadTemplateSheet({
      uploadTemplateId: uploadTemplate.id,
      name: `sheet_${uploadTemplateSheets.length}`,
    });
    const success = await newSheet.save();
    const allSheets = [...uploadTemplateSheets];
    allSheets.push(newSheet);
    setUploadTemplateSheets(allSheets);
  }

  async function handleDestroySheet(sheetId) {
    const sheet = uploadTemplateSheets.find((sheet) => sheet.id === sheetId);
    const success = await sheet.destroy();
    if (success) {
      const newSheets = [...uploadTemplateSheets];
      const index = newSheets.indexOf(sheet);
      newSheets.splice(index, 1);
      setUploadTemplateSheets(newSheets);
    }
  }

  const sheets = uploadTemplateSheets.map((uploadTemplateSheet) => {
    return <TemplateSheet key={uploadTemplateSheet.id} {...{ handleDestroySheet, uploadTemplateSheet }} />;
  });

  return (
    <div className="platform-content platform-content--padding-top">
      <h3 className="subtitle-two text-white">Sheets</h3>
      {sheets}
      <div className="frow frow--gutters">
        <div>
          <button className="button button--secondary" type="button" onClick={handleAddSheet}>
            Add Sheet
          </button>
        </div>
      </div>
    </div>
  );
}
