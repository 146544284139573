import * as React from 'react';

import lodashStartcase from 'lodash.startcase';

const snakeToCamel = (str) =>
  str.replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''));

export default function commentaryPicker(props) {
  const commentaries = ['allocation_commentary', 'performance_commentary', 'rating_commentary', 'holding_commentary'];

  const commentary = commentaries.map((commentary, index) => {
    return (
      <div className="col-mc-1-1" key={index}>
        <div className="pretty p-icon">
          <input type="hidden" name={`client[${commentary}]`} value="0" />
          <input type="checkbox" name={`client[${commentary}]`} value="1" defaultChecked={props.client[commentary]} />
          <div className="state p-blue">
            <i className="icon-tick icon icon-push-down-small" />
            <label className="text-white">{lodashStartcase(commentary)}</label>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="col-mc-1-1">
      <label htmlFor="" className="form__label">
        Included Commentaries
      </label>
      <div className="frow frow--gutters">{commentary}</div>
    </div>
  );
}
