import { Attr, Model } from 'spraypaint';
import { ApplicationRecord } from './ApplicationRecord';

@Model()
export class TrackedJob extends ApplicationRecord {
  public static jsonapiType = 'tracked_jobs';
  @Attr() public createdAt: string;
  @Attr() public jobName: string;
  @Attr() public jobId: string;
  @Attr() public status: string;
}
