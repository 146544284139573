import * as React from 'react';

interface IProps {
  clientTypeSelect: any;
  quarterSelect: any;
  userSelect: any;
}

class Presenter extends React.Component<IProps, {}> {
  public render() {
    const { clientTypeSelect, quarterSelect, userSelect } = this.props;
    return (
      <div className="frow frow--gutters-2x frow--centered-column frow--row-md frow--items-center">
        <div>
          <p className="subtitle-two">Time Period</p>
          {quarterSelect}
        </div>
        <div>
          <p className="subtitle-two">View</p>
          {userSelect}
        </div>
        <div>
          <p className="subtitle-two">Client Type</p>
          {clientTypeSelect}
        </div>
      </div>
    );
  }
}

export default Presenter;
