import * as React from 'react';

const ReviewContext = React.createContext({
  actions: {
    handleReviewUpdate: () => {
      console.log('Now');
    },
  },
  props: {
    currentUser: '',
    review: null,
  },
  state: {},
});

export default ReviewContext;
