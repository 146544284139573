import * as React from 'react';

import { ReportTemplatesReportTemplateSection } from 'javascript/models';
import Context from '../../../Context';

import LegendKeys from './shared/LegendKeys';

export default function betaComparison({ attributes, handleChange, handleManualChange, isRepeating }) {
  const context = React.useContext(Context);
  const { reportTemplate } = context.state;

  const isConsolidated = reportTemplate.consolidated;

  const defaultKey = (attributes.barChartDetails && attributes.barChartDetails.name_type) || 'enhance_reference';

  const [selectedSeries, setSelectedSeries] = React.useState(attributes.barChartSeries || []);
  const [selectedLegend, setSelectedLegend] = React.useState(attributes.legendPosition);
  const [selectedKey, setSelectedKey] = React.useState(defaultKey);

  function updateAttributes() {
    const value = { series: selectedSeries, name_type: selectedKey };
    handleManualChange(value, 'barChartDetails');
  }

  React.useEffect(() => {
    updateAttributes();
  }, [selectedSeries, selectedKey]);

  function handleSeriesChange() {
    const updatedSeries = selectedSeries.toggle(this);
    setSelectedSeries(updatedSeries);
  }

  function handleLegendChange() {
    setSelectedLegend(this);
    handleManualChange(this, 'legendPosition');
  }

  function handleKeyChange() {
    setSelectedKey(this);
  }

  let scopedSeriesOptions = ['Portfolio'];

  if (isConsolidated) {
    scopedSeriesOptions = ['Consolidated Portfolio', 'Constituent Portfolios'];
  }

  if (isRepeating) {
    scopedSeriesOptions = ['Constituent Portfolio'];
  }

  const seriesOptions = scopedSeriesOptions.map((option) => {
    return (
      <div className="col-tn-1-3" key={option}>
        <div className="pretty p-icon">
          <input
            type="checkbox"
            value={option}
            id="series"
            onChange={handleSeriesChange.bind(option)}
            checked={selectedSeries.includes(option)}
          />
          <div className="state p-blue">
            <i className="icon-tick icon icon-push-down-small" />
            <label>{option}</label>
          </div>
        </div>
      </div>
    );
  });

  let scopedTypeOptions = [{ value: 'return', label: 'Return' }];

  if (isConsolidated) {
    scopedTypeOptions = [
      { value: 'beta', label: 'Beta' },
      { value: 'return', label: 'Return' },
    ];
  }

  const legendOptions = ReportTemplatesReportTemplateSection.legendOptions.map((legend) => {
    return (
      <div className="col-tn-1-3" key={legend.value}>
        <div className="pretty pretty--large pretty--with-label p-round">
          <input
            type="radio"
            value={legend.value}
            id="timePeriod"
            onChange={handleLegendChange.bind(legend.value)}
            checked={selectedLegend === legend.value}
          />
          <div className="state p-blue-circle">
            <label>{legend.label}</label>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="frow frow--gutters">
      <div className="col-mc-1-1">
        <label className="form__label" htmlFor="">
          Series
        </label>
        <div className="frow">{seriesOptions}</div>
      </div>
      <div className="col-mc-1-1">
        <label className="form__label" htmlFor="">
          Legend Options
        </label>
        <div className="frow ">{legendOptions}</div>
      </div>
      <LegendKeys {...{ handleKeyChange, selectedKey }} />
    </div>
  );
}
