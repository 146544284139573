import * as React from 'react';

import RangeSlider from 'components/shared/forms/RangeSlider';

import { Mandate } from 'javascript/models';
import { Flag } from 'javascript/models';
import Loading from 'components/shared/Loading';

export default function mandateFlags({ mandate, setMandate, mandateId }) {
  const [isLoading, setIsLoading] = React.useState(!mandate);
  const [mandateFlags, setMandateFlags] = React.useState([]);

  React.useEffect(() => {
    {
      getData();
    }
  }, []);

  async function getData() {
    if (!mandateId) return;

    const { data } = await Flag.where({ flagable_id: mandateId, flagable_type: 'Mandate' }).includes('criterion').all();

    setIsLoading(false);
    setMandateFlags(data);
  }

  const noMandate = (
    <div className="platform-content platform-content--padding-all">
      <p className="text-white text-small mar-v-0">This portfolio has no mandate information set.</p>
    </div>
  );

  function flags() {
    const colorsArray: string[] = ['#3990a8', '#5d5f6b', '#CFB538', '#78ae6e', '#b5b6bd'];

    return mandateFlags.map((flag: Flag, index: number) => {
      const marks = {
        [`${flag.minimum}`]: { style: { color: 'white' }, label: `${flag.minimum}%` },
        [`${flag.maximum}`]: { style: { color: 'white' }, label: `${flag.maximum}%` },
      };
      return (
        <div className="col-mc-1-1" key={flag.id}>
          <div className="frow frow--gutters">
            <div className="col-mc-3-8">
              <p className="subtitle-two">{`${flag.flagType} / ${flag.criterion.name} ${flag.aggregate ? '*' : ''}`}</p>
            </div>
            <div className="col-mc-4-7">
              <RangeSlider
                backgroundColor={colorsArray[index % colorsArray.length]}
                marks={marks}
                disabled
                defaultValue={[flag.minimum, flag.maximum]}
              />
            </div>
          </div>
        </div>
      );
    });
  }

  const noFlags = <p className="text-small text-white mar-b-0">There are no mandate flags set for this portfolio</p>;

  function renderMandateInformation() {
    return (
      <div className="col-md-1-1 frow">
        <div className="col-md-1-1">
          <div
            className="platform-content platform-content--padding-all  h-100
             platform-content--border-right-md col-md-2-3"
          >
            <div className="frow frow--gutters frow--items-center mar-b-1">
              <h4 className="subtitle-two text-white mar-b-0">Mandate Flags</h4>
              <span className="helper-text text-white">
                * denotes that a flag is for the portfolio's aggregate value across all of its positions
              </span>
            </div>
            {mandateFlags.length > 0 ? flags() : noFlags}
          </div>
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className="platform-content h-100 col-md-1-1">
        {!mandateId ? noMandate : isLoading ? <Loading /> : renderMandateInformation()}
      </div>
    </React.Fragment>
  );
}
