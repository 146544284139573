import * as React from 'react';

import Context from './Context';

const classNames = require('classnames');

export default function nav() {
  const context = React.useContext(Context);
  const { selectedTab, tabs } = context.state;
  const { action } = context.props;
  const { handleTabChange } = context.actions;

  const formattedTabs = tabs.map((tab, index) => {
    function handleTabClick() {
      if (tab.name !== 'Information' && action === 'create') return;

      handleTabChange(index);
    }
    const tabClasses = classNames(
      'tabbed-nav__tab',
      'col-flex-grow-1',
      { 'tabbed-nav__tab--right-bordered': index !== tabs.length - 1 },
      { 'tabbed-nav__tab--active': tab.name === selectedTab },
      { 'tabbed-nav__tab--disabled': tab.name !== 'Information' && action === 'create' },
    );
    const statusClasses = classNames('status-indicator', 'status-indicator--no-text', {
      'status-indicator--tick': tab.complete,
    });
    return (
      <div key={tab.name} className={tabClasses} onClick={handleTabClick}>
        <span className={statusClasses} />
        <span className="text-bold text-left letter-spacing-1">{tab.name}</span>
      </div>
    );
  });

  return (
    <div>
      <div className="tabbed-nav">{formattedTabs}</div>
    </div>
  );
}
