import * as React from 'react';

import { Collapse } from '@blueprintjs/core';
import classNames from 'classnames';

import { Client, User } from 'javascript/models';

import useVisibility from 'components/shared/customHooks/useVisibility';
import { SubtitleOne } from 'components/shared/Typography';
import ActionPoints from 'components/portal/shared/ActionPoints';
import CurrentUserContext from 'components/shared/context/CurrentUserContext';

import AssetAllocation from './AssetAllocation';
import CurrencyAllocation from './CurrencyAllocation';
import Drawdown from './Drawdown';
import InfoBar from './InfoBar';
import ClientPerformanceChart from './ClientPerformanceChart';
import PortfolioInformation from './PortfolioInformation';
import Returns from './Returns';
import Reviews from './Reviews';
import Risk from './Risk';
import RiskReturn from './RiskReturn';
import Structure from './Structure';
import Value from './Value';
import ComplianceTable from './ComplianceTable';

const presenter = ({
  client,
  currentReviewPeriod,
  layout,
  layoutDisplay,
  portfolioObject,
  reviewPeriods,
  reviewID,
  setCurrentReviewPeriod,
  setItem,
  setLayoutDisplay,
}) => {
  const { showStructure, id: portfolioId } = portfolioObject;

  const { isOpen: isValueOpen, toggleOpen: toggleValueOpen } = useVisibility(true);
  const { isOpen: isReviewsAndActionPointsOpen, toggleOpen: toggleReviewsAndActionPointsOpen } = useVisibility(true);
  const { isOpen: isRiskReturnOpen, toggleOpen: toggleRiskReturn } = useVisibility(true);
  const { isOpen: isHoldingsOpen, toggleOpen: toggleHoldings } = useVisibility(true);
  const { isOpen: isStructureOpen, toggleOpen: toggleStructure } = useVisibility(true);

  const currentUser = React.useContext(CurrentUserContext) as User;

  const order = (componentTitle) => {
    const layoutComponent = layout.lg.find((item) => item.i === componentTitle);
    const { x, y } = layoutComponent;
    const orderNumber = y * 2 + x + 1;
    return `col-order-${orderNumber + 1}`;
  };

  const showMore = (currentlyOpen, clickHandler) => {
    const iconClasses = classNames('icon-arrow-down', 'icon-animated', 'icon-fw', 'icon-push-down-2', 'icon-1-3x', {
      'icon-flipped': currentlyOpen,
    });
    return (
      <div className="frow frow--items-center frow--justify-center">
        <a className="link" onClick={clickHandler}>
          <span className="mar-r-1">Show {currentlyOpen ? 'Less' : 'More'}</span>
          <i className={iconClasses} />
        </a>
      </div>
    );
  };

  const preIrReview = currentReviewPeriod < '2024-01-01';

  const renderData = () => (
    <>
      {layoutDisplay.Structure && showStructure && (
        <div className={`col-md-1-1 ${order('Structure')}`}>
          <div className="portfolio-structure">
            <div className="platform-content platform-content--padding-horizontal">
              <div className="frow frow--justify-between frow--items-center">
                <SubtitleOne text="Portfolio Structure" additionalClasses="text-white" />
                {showMore(isStructureOpen, toggleStructure)}
              </div>
            </div>
            <Collapse isOpen={isStructureOpen} keepChildrenMounted>
              <div className="frow frow--gutters mar-t-1">
                <div className="col-md-1-1">
                  <div className="card card--rounded card--dark card--full-height pad-r-5">
                    <div className="platform-content platform-content--padding-all h-100" style={{ overflowX: 'auto' }}>
                      <Structure {...{ currentReviewPeriod, portfolioObject }} />
                    </div>
                  </div>
                </div>
              </div>
            </Collapse>
          </div>
        </div>
      )}
      {layoutDisplay['Value and Performance'] && (
        <div className={`col-md-1-1 ${order('Value and Performance')}`}>
          <div className="platform-content platform-content--padding-horizontal">
            <div className="frow frow--justify-between frow--items-center frow--nowrap">
              <SubtitleOne text="Portfolio Value & Performance" additionalClasses="text-white" />
              {showMore(isValueOpen, toggleValueOpen)}
            </div>
          </div>
          <Collapse isOpen={isValueOpen} keepChildrenMounted>
            <div className="frow frow--gutters mar-t-1">
              <Value {...{ currentReviewPeriod, portfolioObject }} />
              <ClientPerformanceChart {...{ currentReviewPeriod, portfolioObject }} />
            </div>
          </Collapse>
        </div>
      )}

      {layoutDisplay['Reviews and Action Points'] && (
        <div className={`col-md-1-1 ${order('Reviews and Action Points')}`}>
          <div className="platform-content platform-content--padding-horizontal">
            <div className="frow frow--justify-between frow--items-center frow--nowrap">
              <SubtitleOne text="Mandate compliance" additionalClasses="text-white" />
              {showMore(isReviewsAndActionPointsOpen, toggleReviewsAndActionPointsOpen)}
            </div>
          </div>
          <Collapse isOpen={isReviewsAndActionPointsOpen} keepChildrenMounted>
            <div className="frow frow--gutters mar-t-1">
              <Reviews {...{ portfolioObject }} />
              {portfolioObject && (
                <div className="col-md-1-2">
                  <ActionPoints
                    {...{
                      client,
                      currentUser,
                      portfolioId,
                      selectedDate: currentReviewPeriod,
                      titleControlClass: 'col-md-2-3',
                    }}
                  />
                </div>
              )}
              {!portfolioObject.quant_only && !preIrReview && (
                <ComplianceTable {...{ reviewID, currentReviewPeriod }} />
              )}
            </div>
          </Collapse>
        </div>
      )}

      {layoutDisplay['Return and Risk'] && (
        <div className={`col-md-1-1 ${order('Return and Risk')}`}>
          <div className="platform-content platform-content--padding-horizontal">
            <div className="frow frow--justify-between frow--items-center frow--nowrap">
              <SubtitleOne text="Return & Risk Analytics" additionalClasses="text-white" />
              {showMore(isRiskReturnOpen, toggleRiskReturn)}
            </div>
          </div>
          <Collapse isOpen={isRiskReturnOpen} keepChildrenMounted>
            <div className="frow frow--gutters mar-t-1">
              <Returns {...{ currentReviewPeriod, portfolioObject }} />
              <Risk {...{ currentReviewPeriod, portfolioObject }} />
              <RiskReturn {...{ currentReviewPeriod, portfolioObject }} />
              <Drawdown {...{ currentReviewPeriod, portfolioObject }} />
            </div>
          </Collapse>
        </div>
      )}

      {layoutDisplay['Holding Analytics'] && (
        <div className={`col-md-1-1 ${order('Holding Analytics')}`}>
          <div className="platform-content platform-content--padding-horizontal">
            <div className="frow frow--justify-between frow--items-center frow--nowrap">
              <SubtitleOne text="Holding Analytics" additionalClasses="text-white" />
              {showMore(isHoldingsOpen, toggleHoldings)}
            </div>
          </div>
          <Collapse isOpen={isHoldingsOpen} keepChildrenMounted>
            <div className="frow frow--gutters mar-t-1">
              <AssetAllocation {...{ currentReviewPeriod, portfolioObject }} />
              <CurrencyAllocation {...{ currentReviewPeriod, portfolioObject }} />
            </div>
          </Collapse>
        </div>
      )}
    </>
  );

  const noReviews = (
    <div className="col-mc-1-1">
      <div className="card card--rounded card--dark card--full-height">
        <div className="platform-content platform-content--padding-all h-100">
          <SubtitleOne
            text="This portfolio doesn't have any completed reviews yet, please check back once you have received the
            first review from us"
            additionalClasses="text-white"
          />
        </div>
      </div>
    </div>
  );

  return (
    <React.Fragment>
      <section style={{ marginBottom: '15px' }}>
        <InfoBar
          {...{
            currentReviewPeriod,
            layout,
            layoutDisplay,
            portfolioObject,
            reviewPeriods,
            setCurrentReviewPeriod,
            setItem,
            setLayoutDisplay,
          }}
        />
      </section>
      <div className="frow frow--gutters">
        <PortfolioInformation {...{ portfolioObject }} />

        {reviewPeriods.length > 0 ? renderData() : noReviews}
      </div>
    </React.Fragment>
  );
};

export default presenter;
