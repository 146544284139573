import * as React from 'react';

import { Note } from 'javascript/models';
import { simpleFormat } from 'components/shared/Utils';
import { Column, Frow } from 'components/frow';
import { sanitize } from 'dompurify';

export interface ICommentProps {
  note: Note;
  theme?: string;
}

const View = (props: ICommentProps) => {
  const { note } = props;

  const display = {
    additionalInfo: note.additionalInfo,
    body: simpleFormat(note.body),
  };

  return (
    <Frow direction="column">
      <Column>
        <div className="text-wrap text-wrap--small" dangerouslySetInnerHTML={{ __html: sanitize(display.body) }} />
      </Column>
      <Column>
        <p className="text-tiny mar-b-0">
          <em>{display.additionalInfo}</em>
        </p>
      </Column>
    </Frow>
  );
};

export default View;
