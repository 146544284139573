import * as React from 'react';

import { TodoItem } from 'javascript/models';

interface IProps {
  todoItem: TodoItem;
  handleUpdateItems(): void;
}

export default function item(props: IProps) {
  const { handleUpdateItems, todoItem } = props;

  async function handleChange() {
    todoItem.completed = !todoItem.completed;
    const success = await todoItem.save();

    if (success) {
      handleUpdateItems();
    }
  }

  return (
    <div className="todo-list__item">
      <div className="pretty p-icon">
        <input checked={todoItem.completed} name="action_point[completed]" type="checkbox" onChange={handleChange} />
        <div className="state p-blue">
          <i className="icon-tick icon text-white icon-push-down-small" />
          <label htmlFor="body" className={`text-white ${todoItem.completed ? 'text-strikethrough' : ''}`}>
            {todoItem.body}
          </label>
        </div>
      </div>
    </div>
  );
}
