import * as React from 'react';

const Context = React.createContext({
  props: {
    canCreate: false,
    canDestroy: false,
    canEdit: false,
    currentUserId: null,
  },
});

export default Context;
