import * as React from 'react';

import { compose, prop, pluck } from 'ramda';

import { Holding, Portfolio } from 'javascript/models';

import Table from 'components/shared/reactTable/Table';
import ReactSelect, { optionsGenerator, optionGenerator } from 'components/shared/forms/ReactSelect';
import { useSpraypaintQueryFirst, useSpraypaintQueryAll } from 'components/shared/customHooks/useSpraypaintQuery';

import Context from '../../Context';
import columns from './HoldingsColumns';

const noDataComponent = () => (
  <div className="frow frow--justify-center frow--items-center mar-b-2">
    <p className="subtitle-one text-white">We have not received holdings level data for this portfolio</p>
  </div>
);

const Holdings = () => {
  const context = React.useContext(Context);

  const { id } = context.props.portfolio.attributes;
  const { mostRecentHoldingsDate } = context.props;

  const [selectedDate, setSelectedDate] = React.useState(mostRecentHoldingsDate);

  const holdingsScope = React.useMemo(
    () =>
      Holding.noLimit()
        .selectExtra(['useLookthroughAssetClass', 'activeAssetClasses', 'headlineAssetClass'])
        .includes(['security'])
        .where({ holdable_type: 'Portfolio', holdable_id: id, value_date: selectedDate }),
    [id, selectedDate],
  );

  const {
    data: holdings,
    meta: { isLoading },
  } = useSpraypaintQueryAll(holdingsScope);

  const holdingDatesScope = React.useMemo(
    () =>
      Holding.select(['valueDate'])
        .where({ groupBy: 'value_date', holdableId: id, holdableType: 'Portfolio' })
        .order({ valueDate: 'desc' }),
    [],
  );
  const { data: holdingDates } = useSpraypaintQueryAll(holdingDatesScope);

  const holdingsDatesOptions = React.useMemo(
    () => holdingDates && optionsGenerator(pluck('valueDate', holdingDates) || []),
    [holdingDates],
  );

  const selectedDateOption = optionGenerator(selectedDate);

  return (
    <>
      <ReactSelect
        isDisabled={isLoading}
        theme="dark"
        value={selectedDateOption}
        options={holdingsDatesOptions}
        handleChange={compose(setSelectedDate, prop('value'))}
      />
      <Table
        className="-sticky-header-large"
        columns={columns()}
        data={holdings || []}
        hasColumnSelector={false}
        hasPagination={false}
        isLoading={isLoading}
        manual={false}
        meta={{ count: holdings && holdings.length }}
        noDataComponent={noDataComponent}
      />
    </>
  );
};

export default Holdings;
