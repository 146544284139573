import * as React from 'react';

const classNames = require('classnames');

interface IProps {
  isUserSelectOpen: boolean;
  selectableAdmins: { display: string; value: number }[];
  selectedUser: number | string;
  handleUserSelect(value: number | string);
  handleUserSelectOpen(): void;
}

class UserSelect extends React.Component<IProps, {}> {
  public render() {
    const { handleUserSelectOpen, isUserSelectOpen } = this.props;
    const dropdownClasses = classNames('dropdown dropdown--sw', { 'dropdown--active': isUserSelectOpen });
    return (
      <div className={dropdownClasses}>
        <a className="dropdown__toggle dropdown__toggle--input" onClick={handleUserSelectOpen}>
          <div className="frow frow--items-center">
            <i className="icon-users icon-fw mar-r-1" />
            <span style={{ width: '140px' }} className="text-normal">
              {this.selectedUserName()}
            </span>
            <i className={`button__icon fa fa-caret-down fa-animated ${isUserSelectOpen ? 'fa-flipped' : ''}`} />
          </div>
        </a>
        <div className="dropdown__content">
          <div className="dropdown__content-inner">{this.renderDropdownOptions()}</div>
        </div>
      </div>
    );
  }

  private renderDropdownOptions() {
    const { handleUserSelect, selectableAdmins } = this.props;
    const admins = selectableAdmins.map((admin, index) => {
      return (
        <div className="dropdown__nav-link" key={index} onClick={() => handleUserSelect(admin.value)}>
          {admin.display}
        </div>
      );
    });
    admins.unshift(
      <div key={Math.random()} className="dropdown__nav-link" onClick={() => handleUserSelect('*')}>
        All Users
      </div>,
    );
    return admins;
  }

  private selectedUserName() {
    const { selectableAdmins, selectedUser } = this.props;
    const admin = selectableAdmins.find((admin) => admin.value == selectedUser);
    if (admin) {
      return admin.display;
    }
    return 'All Users';
  }
}

export default UserSelect;
