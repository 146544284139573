import * as React from 'react';

/* This function allows for state to be created and for `useState` like
 *   interfaces to be defined for subsets of the parent state.
 *
 * This allows global state to be maintained in one component and then for
 *   other components to have an isolated view of that state which maintains the
 *   interface expected by the use of `useState`.
 *
 *
 * @param initialValue - This is used for the initial value of the sharedState and can be used to
 *   set the initial values for all the derived subStates
 *
 * @param callback - enable or disable a stable interface for the subState setter function. By default it's true
 *   The main use for this is if the function is being called in place where React hooks can't be invoked from or if
 *   the implementation intends on wrapping the function or intends on ensuring stability on their own terms.
 *
 * @note This function if used correctly should only cause a state update in
 *   the component that called the specificStateSetter and the component which
 *   called `useSharedState`.
 *   If `callback` is false this could cause a re-render in all components
 *   using state derived from `useSharedState`
 *
 * @usage
 *   const [subStateFactory, globalState] = useSharedState();
 *   const [subState, setSubState] = subStateFactory('subState');
 *
 *   @note setSubState doesn't have identical behaviour to `useState`'s setter
 *     function but it does accept a function callback as it's argument
 */
const useSharedState = <T, >(initialValue: T = undefined, callback = true) => {
  const sharedState = React.useState<T>(initialValue || (({} as unknown) as T));
  const [getSharedState, setSharedState] = sharedState;

  /*
   * This function is the setter function for the subset of sharedState
   *
   * @param sharedStateKey - The key this subState is tied to in the sharedState
   *
   * @param subStateInitialValue - A value to initialise the subState too.
   */
  const subStateFactory = <U extends keyof T>(sharedStateKey: U, subStateInitialValue: T[U] = undefined) => {
    const subStateSetter = (newValue) =>
      setSharedState((previous: T) => ({
        ...previous,
        [sharedStateKey]: typeof newValue == 'function' ? (newValue as (prev: T[U]) => T[U])(previous[sharedStateKey]) : newValue,
      }));

    /* Ensure that `subStateSetter` only gets called once when the state is created if `subStateInitialValue`
     *   is provided.
     *
     * We use `subStateSetter` to set the value so that a state update event occurs.
     */
    if (typeof subStateInitialValue != 'undefined' && !getSharedState.hasOwnProperty(sharedStateKey)) {
      subStateSetter(subStateInitialValue);
    }

    return [
      getSharedState[sharedStateKey],
      callback ? React.useCallback(subStateSetter, [sharedStateKey]) : subStateSetter,
    ] as [T[U], React.Dispatch<React.SetStateAction<T[U]>>];
  };

  return [subStateFactory, sharedState] as [typeof subStateFactory, typeof sharedState];
};

export default useSharedState;
