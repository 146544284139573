import * as React from 'react';

import { addQuarters, endOfQuarter, format, getQuarter, getYear, parse } from 'date-fns';
import QuarterIndicator from 'components/shared/QuarterIndicator';
import { ISelectProps } from './Select';
import ReactSelect from './ReactSelect';

type TCopySelectProps = 'defaultValue' | 'id' | 'label' | 'name' | 'readOnly' | 'theme';

export interface IQuarterSelectProps extends Pick<ISelectProps, TCopySelectProps> {
  handleChange?: ISelectProps['handleChange'];
  optionCount?: number;
  showQuarterIndicator?: boolean;
  startDate: string;
  reverseDates?: boolean;
  width?: string;
}

const convertDateToSelectOption = (date: string | Date) => {
  let localDate = date;
  if (!(typeof localDate === 'string')) localDate = format(localDate, 'YYYY-MM-DD');

  return {
    label: `Q${getQuarter(parse(date))} ${getYear(parse(date))}`,
    value: localDate,
  };
};

const QuarterPicker = (props: IQuarterSelectProps) => {
  const { defaultValue, id, name, reverseDates, startDate, showQuarterIndicator } = props;

  const handleChange: ISelectProps['handleChange'] = React.useCallback(
    (selectedOptionValue) => {
      if (props.handleChange) {
        props.handleChange(selectedOptionValue.value);
      }
    },
    [props.handleChange],
  );

  const options = Array(props.optionCount)
    .fill(1)
    .map((_, index) => {
      const date = endOfQuarter(addQuarters(parse(startDate), index + 1));
      return convertDateToSelectOption(date);
    });

  if (reverseDates) {
    options.reverse();
  }

  const defaultValueExistsAndIsNotUnixEpoch = defaultValue && defaultValue !== '1970-01-01';
  const defaultValueInOptions = Boolean(options.find((option) => option.value === defaultValue));

  if (defaultValueExistsAndIsNotUnixEpoch && !defaultValueInOptions) {
    options.unshift(convertDateToSelectOption(defaultValue));
  }

  const customSelectStyles = {
    dropdownIndicator: (provided) => ({
      ...provided,
      marginRight: '10px',
    }),
    singleValue: () => ({
      height: '20px !important',
    }),
  };

  const optionWithQuarterIndicator = ({ label, value }) => {
    const quarter = getQuarter(parse(value));
    return (
      <div className="frow frow--direction-row frow--justify-start frow--items-center frow--full-width">
        <QuarterIndicator currentQuarter={quarter} />
        <span>{label}</span>
      </div>
    );
  };

  const formatOptionLabel = showQuarterIndicator ? optionWithQuarterIndicator : null;

  return (
    <ReactSelect
      formatOptionLabel={formatOptionLabel}
      id={id}
      name={name}
      options={options}
      theme={props.theme}
      handleChange={handleChange}
      defaultValue={defaultValueExistsAndIsNotUnixEpoch ? convertDateToSelectOption(defaultValue) : options[0]}
      customSelectStyles={customSelectStyles}
    />
  );
};

QuarterPicker.defaultProps = {
  optionCount: 16,
};

export default QuarterPicker;
