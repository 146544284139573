import * as React from 'react';

import { Portfolio } from 'javascript/models/portal/Portfolio';
import ReactLineChart from 'components/shared/charting/recharts/ReactLineChart';
import Loading from 'components/shared/Loading';
import CustomModal from 'components/shared/CustomModal';

import { purple } from 'components/shared/colours';

export default function chart({ currentReviewPeriod, isOpen, handleClose, portfolioObject }) {
  const { id } = portfolioObject;
  const [isLoading, setIsLoading] = React.useState(true);
  const [portfolio, setPortfolio] = React.useState<Portfolio>();

  React.useEffect(() => {
    getPortfolio().then(() => setIsLoading(false));
  }, [currentReviewPeriod]);

  async function getPortfolio() {
    const { data } = await Portfolio.extraParams({ review_date: currentReviewPeriod })
      .selectExtra(['value_series_chart_data'])
      .find(id);

    setPortfolio(data);
  }

  if (isLoading) return <Loading />;

  if (portfolio.valueSeriesChartData.data.length < 1) {
    return <p className="text-white helper-text mar-t-3">No data available currently.</p>;
  }

  let primaryColor = purple;

  return (
    <React.Fragment>
      <ReactLineChart
        data={portfolio.valueSeriesChartData.data}
        lines={['Portfolio']}
        lineStyles={{ Portfolio: { stroke: primaryColor }}}
        ticks={portfolio.valueSeriesChartData.ticks}
        legend={false}
        height={225}
        showYAxis={false}
        showXAxis={false}
        minData={0}
        maxData={100}
        percentageValues={false}
      />
      <CustomModal {...{ isOpen, handleClose }} title="Portfolio Value" modifiers={['dark', 'large', 'rounded']}>
        <div className="modal__content">
          <ReactLineChart
            data={portfolio.valueSeriesChartData.data}
            lines={['Portfolio']}
            lineStyles={{ Portfolio: { stroke: primaryColor }}}
            ticks={portfolio.valueSeriesChartData.ticks}
            legend
            height={500}
            minData={0}
            maxData={100}
            percentageValues={false}
            leftMargin={45}
            rightMargin={30}
          />
        </div>
      </CustomModal>
    </React.Fragment>
  );
}
