import * as React from 'react';

export interface IOption {
  value: any;
  label: string;
}

interface IRadioButtonGroupProps {
  options: IOption[];
  name: string;
  selectedValue: string | null;
  defaultValue?: string | null;
  onChange: (value: string) => void;
}
const RadioButtonGroup = ({ options, selectedValue, onChange, name }: IRadioButtonGroupProps) => {
  const [currentValue, setCurrentValue] = React.useState<string | null>(selectedValue);

  React.useEffect(() => {
    setCurrentValue(selectedValue || null);
  }, [selectedValue]);

  const handleOptionChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.target.value) {
        return;
      }
      onChange(e.target.value);
    },
    [onChange],
  );

  return (
    <>
      {options.map((option) => (
        <div key={option.value} className="pretty pretty--large pretty--with-label p-round">
          <input
            type="radio"
            name={name}
            value={option.value}
            onChange={handleOptionChange}
            checked={currentValue == option.value}
          />
          <div className="state p-blue-circle">
            <label className="text-white">{option.label}</label>
          </div>
        </div>
      ))}
    </>
  );
};

export default RadioButtonGroup;
