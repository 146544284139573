import * as React from 'react';

const classNames = require('classnames');
const routes = require('../../../../javascript/application/routes');

interface IProps {
  entity: any;
  entityId: number;
}

export default function entity(props: IProps) {
  const { entity, entityId } = props;

  const cardClasses = classNames('card', 'card--clickable', 'card--clickable-top', {
    'card--highlighted': entity.entity.id === entityId,
  });

  return (
    <div key={entity.entity.id}>
      <a
        className={cardClasses}
        style={{ width: '180px', height: '140px' }}
        href={routes.platform_entity_path(entity.entity.id)}
      >
        <div className="frow frow--full-height frow--direction-column frow--justify-center frow--items-center">
          <h4 className="card__title card__title--small">{entity.entity.name}</h4>
        </div>
      </a>
    </div>
  );
}
