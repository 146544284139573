import * as React from 'react';

import Allocation from './shortCodes/Allocation';
import Performance from './shortCodes/Performance';
import Rating from './shortCodes/Rating';

interface IProps {
  shortCodes: string;
}

export default function shortCode(props: IProps) {
  const [isOpen, setIsOpen] = React.useState(false);

  function handleClick() {
    setIsOpen(!isOpen);
  }

  let shortCodes = [];
  if (props.shortCodes === 'Performance') {
    shortCodes = Performance;
  }
  if (props.shortCodes === 'Allocation') {
    shortCodes = Allocation;
  }
  if (props.shortCodes === 'Rating') {
    shortCodes = Rating;
  }

  function renderShortCodes() {
    if (!isOpen) return null;

    return formattedShortCodes;
  }

  const formattedShortCodes = shortCodes.map((code, index) => {
    return (
      <dl key={index} className="definition-list definition-list--ruled">
        <dt className="text-lowercase">{code.code}</dt>
        <dd className="text-brand-light-grey">{code.explanation}</dd>
      </dl>
    );
  });

  if (!shortCodes.length) return null;

  return (
    <div className="col-sm-1-1">
      <div className="mar-b-2">
        <a className="link link--small link--blue" onClick={handleClick}>
          {isOpen ? 'Hide available short codes' : 'Show available short codes'}
        </a>
      </div>
      {renderShortCodes()}
    </div>
  );
}
