import * as React from 'react';

import Information from './form/Information';

import { Client } from 'javascript/models';
import ISelectOption from 'components/interfaces/ISelectOption';

interface IProps {
  adminOptions: ISelectOption[];
  client: any;
  currencyOptions: ISelectOption[];
  clientTypes: ISelectOption[];
  jurisdictionOptions: ISelectOption[];
  regionOptions: ISelectOption[];
  rmAdminOptions: ISelectOption[];
}

export default function form(props: IProps) {
  const {
    adminOptions,
    client,
    currencyOptions,
    clientTypes,
    jurisdictionOptions,
    regionOptions,
    rmAdminOptions,
  } = props;

  const clientResource = new Client(client);
  const clientType = clientResource.clientType;

  const [selectedClientType, setSelectedClientType] = React.useState(clientType);

  return (
    <React.Fragment>
      <Information
        {...{
          adminOptions,
          clientTypes,
          currencyOptions,
          jurisdictionOptions,
          regionOptions,
          rmAdminOptions,
          setSelectedClientType,
        }}
        client={clientResource}
        clientType={selectedClientType}
      />
    </React.Fragment>
  );
}
