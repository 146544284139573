import * as React from 'react';

import CustomModal from 'components/shared/CustomModal';
import Reorder from './Reorder';
import ClientRoles from 'components/platform/clients/roles/ClientRoles';
import CurrentUserContext from 'components/shared/context/CurrentUserContext';
import { User } from 'javascript/models';
import Loading from 'components/shared/Loading';

type TPermissions = {
  [key: string]: { description: string; value: number };
};

type TPermissionGroups = {
  [key: string]: TPermissions;
};

export default function settings(props) {
  const [selectedTab, setSelectedTab] = React.useState<'reorder' | 'roles'>('reorder');
  const [currentUser, setCurrentUser] = React.useState<User>(undefined);
  const [isLoading, setIsLoading] = React.useState(true);
  const [tabs, setTabs] = React.useState(['reorder']);
  const { isOpen, handleClose, layout, setItem, layoutDisplay, setLayoutDisplay, clientId } = props;

  const context = React.useContext(CurrentUserContext);

  React.useEffect(() => {
    const user = context as User;
    setCurrentUser(user);
    if (user && user.hasPermission('user_portal_manager', clientId)) {
      setTabs([...tabs, 'roles']);
    }
  }, [context]);

  React.useEffect(() => {
    setIsLoading(false);
  }, [tabs]);

  const tabMapper = {
    reorder: (
      <Reorder isOpen={isOpen} handleClose={handleClose} {...{ layout, setItem, layoutDisplay, setLayoutDisplay }} />
    ),
    roles: <ClientRoles {...{ clientId }} />,
  };

  const renderTable = (): JSX.Element => tabMapper[selectedTab];

  function handleTabSelect() {
    setSelectedTab(this);
  }

  const classes = 'tabbed-nav__tab col-flex-grow-1 tabbed-nav__tab--right-bordered';

  const tab = (tabType: string) => (
    <div
      className={`${classes} ${selectedTab === tabType ? 'tabbed-nav__tab--active' : ''}`}
      onClick={handleTabSelect.bind(tabType)}
    >
      <span className="text-bold letter-spacing-1">{tabType}</span>
    </div>
  );

  return (
    <CustomModal isOpen={isOpen} title="Customize layout" handleClose={handleClose} modifiers={['dark', 'huge']}>
      {isLoading ? (
        <Loading />
      ) : (
        <div>
          <div className="tabbed-nav" style={{ padding: 0 }}>
            {tabs.map((tabType) => tab(tabType))}
          </div>
          <div className="platform-content platform-content--padding-all">{renderTable()}</div>
        </div>
      )}
    </CustomModal>
  );
}
