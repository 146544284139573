import * as React from 'react';

import lodashUniq from 'lodash.uniq';
import ReactSelect from 'components/shared/forms/ReactSelect';

import { ExtraSettingsType } from 'javascript/models';

export default function extraSettings({ attributes, handleChange, handleManualChange, isRepeating }) {
  const [options, setOptions] = React.useState([]);

  React.useEffect(() => {
    getOptions();
  }, []);

  function handleSelectChange(selection) {
    handleManualChange(selection.value, 'text');
  }

  async function getOptions() {
    const { data } = await ExtraSettingsType.select(['setting_type']).per(999).all();

    const settingTypes = data.map((setting) => setting.settingType);
    const settingOptions = lodashUniq(settingTypes).map((setting) => ({ label: setting, value: setting }));
    setOptions(settingOptions);
  }

  return (
    <div className="frow frow--gutters">
      <div className="col-mc-1-1">
        <label className="form__label" htmlFor="">
          Extra Setting
        </label>
        <div className="frow ">
          <ReactSelect
            defaultValue={attributes.text}
            isClearable
            name="text"
            options={options}
            theme="light"
            id="extra_setting_text"
            handleChange={handleSelectChange}
          />
        </div>
      </div>
    </div>
  );
}
