import * as React from 'react';

import { InvestmentRiskAssessment } from 'javascript/models';
import Loading from 'components/shared/Loading';
import { SubtitleOne } from 'components/shared/Typography';
import { format, parse } from 'date-fns';
import { Alerter } from 'components/shared/Alerter';
import { Intent } from '@blueprintjs/core';
import { createAlert } from 'components/shared/Utils';

export default function downloads({ userId }) {
  const [loading, setLoading] = React.useState(true);
  const [mirs, setMirs] = React.useState([]);

  React.useEffect(() => {
    getMirs().then(() => setLoading(false));
  }, []);

  async function getMirs() {
    const { data } = await InvestmentRiskAssessment.where({ ownable_type: 'User', ownable_id: userId })
      .order({ created_at: 'desc' })
      .all();

    setMirs(data);
  }

  const pdf = <i className="far fa-file-pdf mar-r-1" />;

  const excel = <i className="far fa-file-excel mar-r-1" />;

  function renderData() {
    if (mirs.length < 1) {
      return (
        <div className="platform-content platform-content--padding-all">
          <p className="text-small text-white mar-v-0">You have no documents to download.</p>
        </div>
      );
    }

    return mirs.map((mir) => {
      const handleDelete = () => {
        Alerter.create({
          cancelButtonText: 'Cancel',
          confirmButtonText: 'Yes, Delete',
          intent: Intent.DANGER,
          onConfirm: () => {
            mir.destroy().then(() => {
              createAlert('success', 'Successfully deleted MIR', 1500);
              getMirs();
            });
          },
          text: 'Are you sure you want to delete this attachment?',
        });
      };

      return (
        <div key={mir.id} className="dropdown-nav-item dropdown-nav-item--bordered">
          <div className="frow frow--justify-between">
            <div>
              <a href={mir.downloadPath} className="text-white" target="_blank">
                <div className="frow">
                  <div className="col-mc-1-1">
                    {mir.documentType === 'pdf' ? pdf : excel}
                    {mir.fileName} - {mir.documentType.toUpperCase()}
                  </div>
                </div>
              </a>
            </div>
            <div>
              <div style={{ cursor: 'pointer' }} onClick={handleDelete}>
                <i className="icon-bin icon-fw icon-hoverable" />
              </div>
            </div>
          </div>
          <div className="subtitle-two text-grey mar-t-1 mar-b-0">
            {format(parse(mir.createdAt), 'DD MMM YYYY [at] HH:mm')}
          </div>
        </div>
      );
    });
  }

  return (
    <div className="platform-panel card card--rounded card--dark">
      <header className="platform-panel__header">
        <SubtitleOne text="Downloads" additionalClasses="text-white" />
      </header>
      {loading ? <Loading /> : renderData()}
    </div>
  );
}
