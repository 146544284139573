import * as React from 'react';

import Loading from 'components/shared/Loading';
import Form from './Form';
import ReportTemplateBucketShow from './ReportTemplateBucket';

import { ReportTemplateBucket } from 'javascript/models';

import {
  platformReportTemplateBucketsPath,
  platformReportTemplatesPath,
} from 'javascript/application/ts_routes';
import useVisibility from 'components/shared/customHooks/useVisibility';

export default function presenter({ canCreate, fetchData, isLoading, reportTemplateBuckets }) {
  const { isOpen, handleOpen, handleClose } = useVisibility(false);

  function renderBuckets() {
    if (isLoading) return <Loading />;

    if (reportTemplateBuckets.length < 1) {
      return (
        <div className="col-mc-1-1">
          <div className="frow frow--centered-column">
            <div>
              <p className="text-white text-small">There are currently no buckets</p>
            </div>
          </div>
        </div>
      );
    }

    return reportTemplateBuckets.map((bucket) => {
      return <ReportTemplateBucketShow key={bucket.id} {...{ bucket, canCreate, fetchData }} />;
    });
  }

  function renderForm() {
    if (!canCreate) return;

    return (
      <div className="frow frow--row-end mar-t-2">
        <div>
          <button className="button button--highlight button--compact" onClick={handleOpen}>
            New Bucket
          </button>
          <Form {...{ handleClose, isOpen, fetchData }} reportTemplateBucket={new ReportTemplateBucket()} />
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <section className="platform-panel platform-panel--no-background">
        <div className="frow frow--gutters frow--justify-between frow--items-center">
          <div>
            <div className="frow frow--items-center frow--gutters">
              <div>
                <p className="subtitle-two">{reportTemplateBuckets.length} buckets</p>
                <h2 className="heading-one text-lh-1">Report Template Buckets</h2>
              </div>
              <div>
                <span className="horizontal-divider hide-tn-inline" />
              </div>
              <div>
                <p className="subtitle-two">Switch Views</p>
                <div className="button-group">
                  <a
                    href={platformReportTemplateBucketsPath()}
                    className="button-group__button button-group__button--active"
                  >
                    Buckets
                  </a>
                  <a href={platformReportTemplatesPath()} className="button-group__button" style={{ width: '170px' }}>
                    All Templates
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div>{renderForm()}</div>
        </div>
      </section>
      <section className="platform-panel">
        <div className="platform-content platform-content--padding-all">
          <div className="frow frow--gutters">{renderBuckets()}</div>
        </div>
      </section>
    </React.Fragment>
  );
}
