import * as React from 'react';

import ReactFlagsSelect from 'react-flags-select';

interface IProps {
  country?: string;
  name: string;
}

export default function countrySelect(props: IProps) {
  const { country, name } = props;

  const [selectedCountry, setSelectedCountry] = React.useState(country || '');

  function handleCountrySelect(countryCode) {
    setSelectedCountry(countryCode);
  }

  return (
    <div className="flag-select-wrapper">
      <ReactFlagsSelect
        defaultCountry={country}
        searchable
        id="country"
        name="entity[country]"
        onSelect={handleCountrySelect}
      />
      <input type="hidden" name={name} value={selectedCountry} />
    </div>
  );
}
