import * as React from 'react';

import lodashStartcase from 'lodash.startcase';

import EntityCreate from './entity/EntityCreate';
import EntitySelect from './entity/EntitySelect';

import Context from '../Context';
import FormRow from 'components/shared/forms/FormRow';
import ReactSelect from 'components/shared/forms/ReactSelect';
import Combined from './entity/Combined';
import Consolidated from './entity/Consolidated';

export default function entity() {
  const context = React.useContext(Context);

  const { canCreateEntities, clientTypeOptions, companyId, entityFormOptions, portfolioStatusOptions } = context.props;
  const { portfolio } = context.state;
  const { clientType, liveStatus } = portfolio;

  const [combined, setCombined] = React.useState<boolean>(portfolio.combined);
  const [consolidated, setConsolidated] = React.useState<boolean>(portfolio.consolidated);

  function handleConsolidatedChange(value: boolean) {
    if (value) {
      setCombined(false);
      setConsolidated(true);
    } else {
      setConsolidated(false);
    }
  }

  function handleCombinedChange(value: boolean) {
    if (value) {
      setConsolidated(false);
      setCombined(true);
    } else {
      setCombined(false);
    }
  }

  let entityId = null;
  let defaultValue = null;
  if (portfolio && portfolio.entity) {
    entityId = portfolio.entity.id;
    defaultValue = { label: portfolio.entity.name, value: portfolio.entity.id };
  }

  return (
    <fieldset className="form__fieldset">
      <div className="frow frow--items-center frow--justify-between">
        <div>
          <h4 className="form__fieldset-label">Entity Information</h4>
        </div>
        <div>{canCreateEntities && <EntityCreate />}</div>
      </div>
      <div className="frow frow--gutters">
        <FormRow note="" className="col-mc-1-1">
          <EntitySelect {...{ companyId, defaultValue, entityId }} />
        </FormRow>
        <FormRow>
          <label htmlFor="client_type" className="form__label">
            Client Type
          </label>
          <ReactSelect
            theme="dark"
            name="portfolio[client_type]"
            id="client_type"
            isClearable
            options={clientTypeOptions}
            defaultValue={{ label: lodashStartcase(clientType), value: clientType }}
          />
        </FormRow>
        <FormRow
          note="The portfolio will only move to this status once it is no longer in draft"
          className="col-mc-1-1"
        >
          <label htmlFor="live_status" className="form__label">
            Portfolio Status
          </label>
          <ReactSelect
            isClearable
            theme="dark"
            name="portfolio[live_status]"
            id="live_status"
            options={portfolioStatusOptions}
            defaultValue={{ label: lodashStartcase(liveStatus), value: liveStatus }}
          />
        </FormRow>
        <FormRow>
          <label htmlFor="name" className="form__label">
            Portfolio Name
          </label>
          <input type="text" name="portfolio[name]" defaultValue={(portfolio && portfolio.name) || null} />
        </FormRow>
        <Combined {...{ combined, handleCombinedChange }} />
        <Consolidated {...{ consolidated, handleConsolidatedChange }} />
      </div>
    </fieldset>
  );
}
