import { ReportTemplate } from 'javascript/models';

import * as Color from 'color';

export function chartColors(
  reportTemplate: ReportTemplate,
  defaultColors: string[],
  labelName: string,
  index,
  preview = false,
  chartType = 'pie',
) {
  if (!reportTemplate) {
    return defaultColors[index];
  }

  const theme = reportTemplate.reportTemplateTheme;

  const {
    primaryColor,
    secondaryColor,
    tertiaryColor,
    cashColor,
    fixedIncomeColor,
    equityColor,
    hedgeFundColor,
    otherColor,
    secondaryColorOne,
    secondaryColorTwo,
    secondaryColorThree,
    secondaryColorFour,
    secondaryColorFive,
    secondaryColorSix,
    secondaryColorSeven,
    secondaryColorEight,
    secondaryColorNine,
    secondaryColorTen,
  } = theme;

  const themeColors = [
    secondaryColorOne,
    secondaryColorTwo,
    secondaryColorThree,
    secondaryColorFour,
    secondaryColorFive,
    secondaryColorSix,
    secondaryColorSeven,
    secondaryColorEight,
    secondaryColorNine,
    secondaryColorTen,
  ];

  const currencyOptions = [
    'gbp',
    'usd',
    'eur',
    'jpy',
    'hkd',
    'chf',
    'sgd',
    'zar',
    'cad',
    'aud',
    'nzd',
    'sek',
    'dkk',
    'tbc',
    'oth',
  ];

  if (currencyOptions.includes(labelName.toLowerCase())) {
    return theme[`${labelName.toLowerCase()}Color`];
  }

  const previewColors = [
    primaryColor,
    secondaryColor,
    tertiaryColor,
    cashColor,
    fixedIncomeColor,
    equityColor,
    hedgeFundColor,
    otherColor,
  ];

  if (preview) {
    return previewColors[index];
  }

  switch (labelName) {
    case 'Cash':
      return cashColor;
    case 'Equity':
      if (chartType !== 'pie') {
        return '#5b363f';
      }
      return equityColor;
    case 'Fixed Income':
      return fixedIncomeColor;
    case 'Hedge Fund':
      return hedgeFundColor;
    case 'Other':
      return otherColor;
    case 'Portfolio':
    case 'Consolidated Portfolio':
    case 'Consolidated portfolio':
      return primaryColor;
    case 'Benchmark 1':
      return secondaryColor;
    case 'Benchmark 2':
      return tertiaryColor;
    case 'Peer Group':
      return secondaryColorOne;
    case 'Peer Group Low':
      return secondaryColorTwo;
    case 'Peer Group Med.':
      return secondaryColorThree;
    case 'Peer Group High':
      return secondaryColorFour;
    default:
      return themeColors[index];
  }
}

const modulateColour = (colour: string, phase: number) => {
  const lighten = phase % 2 === 0;
  const amount = 1 - 2 ** -Math.floor(phase / 2 + 0.5);

  const newColour = Color(colour);

  const operation = lighten ? 'lighten' : 'darken';

  return newColour[operation](amount).hex();
};

export function chartColorsV2() {
  const chartColourCache = {};

  return (
    reportTemplate: ReportTemplate,
    defaultColors: string[],
    labelName: string,
    index,
    preview = false,
    chartType = 'pie',
  ) => {
    let returnVal = chartColors(reportTemplate, defaultColors, labelName, index, preview, chartType);

    if (chartColourCache.hasOwnProperty(returnVal)) {
      const thisPhase = chartColourCache[returnVal]++;
      returnVal = modulateColour(returnVal, thisPhase);
    } else {
      chartColourCache[returnVal] = 1;
    }

    return returnVal;
  };
}
