import * as React from 'react';

interface IProps {
  defaultChecked: number;
}

export default function dataFrequencyPicker(props: IProps) {
  const frequencies = [
    { label: 'Monthly', value: 1 },
    { label: 'Quarterly', value: 3 },
    { label: 'Half Yearly', value: 6 },
    { label: 'Annually', value: 12 },
  ];

  const frequency = frequencies.map((frequency) => {
    return (
      <div className="col-mc-1-1" key={frequency.value}>
        <div className="pretty p-icon">
          <input
            type="radio"
            name="portfolio[data_frequency_months]"
            defaultChecked={frequency.value === props.defaultChecked}
            value={frequency.value}
          />
          <div className="state p-blue">
            <i className="icon-tick icon icon-push-down-small" />
            <label className="text-white">{frequency.label}</label>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="col-mc-1-1">
      <label htmlFor="" className="form__label">
        Data Frequency
      </label>
      <div className="frow frow--gutters">{frequency}</div>
    </div>
  );
}
