import * as React from 'react';

export default function noRatingPortfolioTable({ large, showHeaders }) {
  const headers = (
    <thead>
      <tr>
        <th style={{ verticalAlign: 'top' }}>Reference</th>
        <th style={{ verticalAlign: 'top' }}>Investment Manager</th>
        <th style={{ verticalAlign: 'top' }}>CCY</th>
        <th style={{ verticalAlign: 'top' }}>Prev Year End*</th>
        <th style={{ verticalAlign: 'top' }}>Prev Qtr End*</th>
        <th style={{ verticalAlign: 'top' }}>Qtr Cashflow*</th>
        <th style={{ verticalAlign: 'top' }}>Current Value*</th>
        <th style={{ verticalAlign: 'top' }}>Last Qtr**</th>
        <th style={{ verticalAlign: 'top' }}>YTD</th>
        <th style={{ verticalAlign: 'top' }}>Risk</th>
      </tr>
    </thead>
  );

  return (
    <table className={`pdf-table pdf-table--striped ${large && 'pdf-table--large'}`}>
      {showHeaders && headers}
      <tbody>
        <tr>
          <td>Value</td>
          <td>Value</td>
          <td>Value</td>
          <td>Value</td>
          <td>Value</td>
          <td>Value</td>
          <td>Value</td>
          <td>Value</td>
          <td>Value</td>
          <td>Value</td>
        </tr>
        <tr>
          <td>Value</td>
          <td>Value</td>
          <td>Value</td>
          <td>Value</td>
          <td>Value</td>
          <td>Value</td>
          <td>Value</td>
          <td>Value</td>
          <td>Value</td>
          <td>Value</td>
        </tr>
      </tbody>
    </table>
  );
}
