import * as React from 'react';

const useStateSetAttr = (objects, attr, state) =>
  React.useEffect(() => {
    objects.map((obj) => {
      obj[attr] = state;
    });
  }, [objects, attr, state]);

export default useStateSetAttr;
