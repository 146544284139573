import * as React from 'react';

import { DataNote } from './../../../../../../javascript/models/DataNote';

export default function dataNotes({ portfolio }) {
  const [dataNotes, setDataNotes] = React.useState<DataNote[]>([]);
  const [noteInput, setNoteInput] = React.useState('');

  async function getDataNotes() {
    const { data } = await DataNote.where({ portfolio_id: portfolio.id }).includes(['author']).all();

    setDataNotes(data);
  }

  React.useEffect(() => {
    getDataNotes();
  }, [portfolio]);

  const changeHandler = (value) => setNoteInput(value);

  async function handleNoteSave() {
    const newDataNotes = [...dataNotes];
    const savedNote = new DataNote({ body: noteInput, portfolioId: portfolio.id });
    let success = await savedNote.save();
    if (success) {
      newDataNotes.push(savedNote);
      setDataNotes(newDataNotes);
      setNoteInput('');
    }
  }

  async function handleDelete(dataNote, index) {
    let success = dataNote.destroy();
    if (success) {
      const newDataNotes = [...dataNotes];
      newDataNotes.splice(index, 1);
      setDataNotes(newDataNotes);
    }
  }

  const renderNotes = () => {
    return dataNotes.map((dataNote, index) => {
      return (
        <div className="card--light pad-all-2 mar-b-1" key={index}>
          <div className="frow frow--justify-between">
            <div className="col-md-7-8">
              <div className="mar-b-1">{dataNote.body}</div>
              <div className="">{new Date(dataNote.createdAt).toLocaleDateString()}</div>
            </div>
            <div className="col-1-8">
              <div
                className="button button--danger button--icon"
                onClick={() => handleDelete(dataNote, index)}
                data-tip="Delete Note"
                style={{ borderRadius: '0.7rem' }}
              >
                <i className="icon-bin icon-fw" />
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleNoteSave();
    }
  };

  return (
    <div>
      <h3 className="mar-t-0 mar-b-1">Data Notes</h3>
      {renderNotes()}
      <input
        type="text"
        value={noteInput}
        onChange={(event) => changeHandler(event.target.value)}
        className="card--light card--light-input"
        onKeyDown={handleKeyDown}
        placeholder={'Add a new note.'}
      />
    </div>
  );
}
