import { Attr, HasMany, Model, HasOne } from 'spraypaint';
import { ApplicationRecord } from './ApplicationRecord';

import { SecurityState } from './securities/State';

import { Cluster } from './Cluster';
import { Holding } from './Holding';

@Model()
export class Security extends ApplicationRecord {
  public static jsonapiType = 'securities';
  @Attr() public isin: string;
  @Attr() public name: string;
  @Attr() public shortName: string;
  @Attr() public classified: boolean;
  @Attr() public securityType: string;
  @HasMany(SecurityState) public states: SecurityState[];
  @HasOne(SecurityState) public currentState: SecurityState;
  @Attr() public overwriteClassifications: boolean;
  @HasOne(Cluster) public cluster: Cluster;
  @HasMany(Holding) public holdings: Holding[];
}
