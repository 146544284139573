import * as React from 'react';

const useRecurringRerender = (frequency: number, condition: () => boolean = () => true) => {
  const [rerender, setRerender] = React.useState(false);

  React.useEffect(() => {
    if (!condition()) return;

    const timeoutId = setTimeout(() => setRerender((prev) => !prev), frequency - (Date.now() % frequency));
    return () => clearTimeout(timeoutId);
  }, [rerender]);
};

export default useRecurringRerender;
