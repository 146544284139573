import FormRow from 'components/shared/forms/FormRow';
import React from 'react';

const PersonalDetails = ({ resource, handleChange }) => {
  if (resource.groupContact) {
    return (
      <React.Fragment>
        <FormRow>
          <label htmlFor="firstName" className="form__label">
            Name
          </label>
          <input type="text" value={resource.firstName} onChange={handleChange} id="firstName" name="first_name" />
        </FormRow>
        <FormRow>
          <label htmlFor="firstName" className="form__label">
            Email
          </label>
          <input type="email" value={resource.email} onChange={handleChange} id="email" name="email" />
        </FormRow>
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <FormRow>
        <label htmlFor="firstName" className="form__label">
          First Name
        </label>
        <input type="text" value={resource.firstName} onChange={handleChange} id="firstName" name="first_name" />
      </FormRow>
      <FormRow>
        <label htmlFor="firstName" className="form__label">
          Last Name
        </label>
        <input type="text" value={resource.lastName} onChange={handleChange} id="lastName" name="last_name" />
      </FormRow>
      <FormRow>
        <label htmlFor="email" className="form__label">
          Email
        </label>
        <input type="email" value={resource.email} onChange={handleChange} id="email" name="email" />
      </FormRow>
      <FormRow>
        <label htmlFor="phoneNumber" className="form__label">
          Phone Number
        </label>
        <input type="text" value={resource.phoneNumber} onChange={handleChange} id="phoneNumber" name="phone_number" />
      </FormRow>
    </React.Fragment>
  );
};

export default PersonalDetails;
