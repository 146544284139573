import * as React from 'react';

import GraphitiTableWrapper from 'components/shared/GraphitiTableWrapper';

import { PortfolioBenchmark } from 'javascript/models';

import SelectionCounter from 'components/shared/SelectionCounter';
import columns from './table/columns';

interface IProps {
  benchmarkTypes: string[];
  currencies: string[];
  isExportable: boolean;
  newPath: string;
  riskOptions: string[];
}

const table = (props: IProps) => {
  const { benchmarkTypes, currencies, isExportable, newPath, riskOptions } = props;
  const [selection, setSelection] = React.useState([]);
  const scope = React.useMemo(() => PortfolioBenchmark.scope(), []);
  const compositeOptions = [true, false];

  const formatting = {
    benchmarkType: 'humanize',
    currency: 'upcase',
    risk: 'humanize',
  };

  function handleSelection(item) {
    setSelection(item);
  }

  const path = selection.length > 0 ? `${newPath}?benchmarks_id=${selection}`.toString() : newPath;

  return (
    <>
      <SelectionCounter selection={selection} itemType={'benchmark'} pluraliseItem />
      <GraphitiTableWrapper
        formatting={formatting}
        scope={scope}
        columns={columns(benchmarkTypes, currencies, riskOptions, compositeOptions)}
        isExportable={isExportable}
        isSearchable
        newPath={path}
        newText={selection.length > 1 ? 'Create Composite Benchmark' : 'Create Benchmark'}
        hasHeader
        title="Benchmarks"
        identifier="benchmarks:index"
        selectable
        handleUpdatedSelection={handleSelection}
      />
    </>
  );
};

export default table;
