import * as React from 'react';

import Presenter from './overview/Presenter';
import { Review } from 'javascript/models';

interface IProps {
  initialDate: string;
  managerId: number;
}

export default function overview(props: IProps) {
  const { initialDate, managerId } = props;

  const [selectedDate, setSelectedDate] = React.useState(initialDate);
  const [meta, setMeta] = React.useState();
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    document.addEventListener('managerPortal:Filters:Change', handleFilterChange);
    return () => {
      document.removeEventListener('managerPortal:Filters:Change', handleFilterChange);
    };
  }, []);

  function handleFilterChange(event) {
    setSelectedDate(event.detail.selectedDate);
  }

  React.useEffect(() => {
    getReviews();
  }, [selectedDate]);

  async function getReviews() {
    const { data, meta } = await Review.select(['id'])
      .where({ manager_id: managerId, end_date: selectedDate })
      .stats({ total: ['data_received_count', 'awaiting_data_count', 'error_count', 'count'] })
      .all();

    setMeta(meta);
  }

  React.useEffect(() => {
    if (meta) {
      setIsLoading(false);
    }
  }, [meta]);

  return <Presenter {...{ meta, isLoading, selectedDate }} />;
}
