import * as React from 'react';

import { format, parse } from 'date-fns';
const numeral = require('numeral');

export const CustomTooltip = ({ active = false, payload = [], label = '' }) => {
  if (active) {
    const wrapperStyles = {
      background: '#272a31',
      padding: '12px 0',
      width: '140px',
    };

    const valueStyles = {
      color: 'white',
      fontSize: '18px',
      margin: 0,
      textAlign: 'center' as any,
    };

    const labelStyles = {
      color: '#777881',
      textAlign: 'center' as any,
    };

    return (
      <div style={wrapperStyles}>
        <p style={valueStyles}>{numeral(payload[0].value).format(0, 0.0)}</p>
        <p style={labelStyles} className="small-uppercase mar-v-0">
          {format(parse(label), 'DD MMM YYYY')}
        </p>
      </div>
    );
  }

  return null;
};
