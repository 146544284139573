import * as React from 'react';

import Context from '../Context';

export default function longformCommentary({ templateSection }) {
  const context = React.useContext(Context);
  const { reportTemplate } = context.state;

  const isLandscape = reportTemplate && reportTemplate.orientation === 'landscape';
  const preview = !templateSection;
  const pageStyles = {
    height: '100%',
    width: '100%',
  } as any;

  if (preview) {
    pageStyles.height = isLandscape ? '320px' : '500px';
  } else {
    pageStyles.height = isLandscape ? '480px' : '701px';
    pageStyles.margin = '0';
  }

  return (
    <div className={`pdf-page ${isLandscape ? 'pdf-page--landscape' : ''}`} style={pageStyles}>
      <h3 className="pdf-small-subtitle mar-b-2">Longform Commentary</h3>
      <div className="pdf-body-copy-wrap">
        <p className="pdf-body-copy">
          Longform commentary written for this review will go here. Longform commentary is different to the standard
          review commentary in that it is expected to be much longer and more detailed. You also have more control over
          the style of longform commentary, and it is always at least 1 full page of the generated report.
        </p>
      </div>
    </div>
  );
}
