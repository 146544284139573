import * as React from 'react';

import FormRow from 'components/shared/forms/FormRow';

import IPortfolioBenchmark from 'components/interfaces/IPortfolioBenchmark';

interface IProps {
  portfolioBenchmark: { data: IPortfolioBenchmark };
  handleCanBeSurfacedChange(checked: boolean): void;
}

export default function surfaceable(props: IProps) {
  const { canBeSurfaced } = props.portfolioBenchmark.data.attributes;
  const [isSurfaceable, setIsSurfaceable] = React.useState(canBeSurfaced);

  function handleCheck() {
    setIsSurfaceable(!isSurfaceable);
  }

  React.useEffect(() => {
    props.handleCanBeSurfacedChange(isSurfaceable);
  }, [isSurfaceable]);

  return (
    <React.Fragment>
      <input type="hidden" name="portfolio_benchmark[can_be_surfaced]" value="0" />
      <FormRow note="" className="col-mc-1-1">
        <label htmlFor="currency" className="form__label">
          Can be Surfaced
        </label>
        <div className="pretty p-icon">
          <input
            type="checkbox"
            name="portfolio_benchmark[can_be_surfaced]"
            value="1"
            defaultChecked={isSurfaceable}
            onChange={handleCheck}
          />
          <div className="state p-blue">
            <i className="icon-tick icon icon-push-down-small" />
            <label className="text-white">Surfaced Benchmark</label>
          </div>
        </div>
      </FormRow>
    </React.Fragment>
  );
}
