import * as React from 'react';

import { Droppable } from 'react-beautiful-dnd';

const classNames = require('classnames');

import ColorSelect from './ColorSelect';

import Header from './additionalPages/Header';

import ConsultingFooter from './footers/ConsultingFooter';
import CustomFooter from './footers/CustomFooter';
import GroupFooter from './footers/GroupFooter';
import MonitoringFooter from './footers/MonitoringFooter';
import PortfolioFooter from './footers/PortfolioFooter';

import Context from '../Context';
import Rows from '../report/Rows';

export default function additionalPage({ dummyContainerRef, index, startAt, templateRows }) {
  const context = React.useContext(Context);
  const { canEdit } = context.props;
  const { focus, pageColors, reportTemplate } = context.state;

  function renderFooter() {
    switch (reportTemplate.theme) {
      case 'consultancy':
        return <ConsultingFooter {...{ index }} />;
      case 'group':
        return <GroupFooter {...{ index }} />;
      case 'monitoring':
        return <MonitoringFooter {...{ index }} />;
      case 'portfolio':
        return <PortfolioFooter {...{ index }} />;
      case 'custom':
        return <CustomFooter {...{ index }} />;
    }
  }

  const pageClasses = classNames(
    'pdf-page',
    { 'pdf-page--default': (pageColors[index] && pageColors[index] === 'white') || !pageColors[index] },
    { 'pdf-page--grey': pageColors[index] && pageColors[index] === 'grey' },
  );

  return (
    <div className={pageClasses}>
      <Header
        color={reportTemplate.reportTemplateTheme.headerBorderColor}
        logo={reportTemplate.reportTemplateTheme.logo}
        theme={reportTemplate.theme}
      />
      <div className="pdf-main" style={{ height: '85.63%' }}>
        <ColorSelect {...{ index }} />
        <Droppable droppableId={`droppable-report-${startAt}`} isDropDisabled={focus !== 'report'}>
          {(provided, snapshot) => {
            const styles = {
              background: snapshot.isDraggingOver ? '#FAFAFA' : '',
              height: '100%',
              ...provided.droppableProps.style,
            };
            return (
              <div ref={provided.innerRef} {...provided.droppableProps} style={styles}>
                <Rows {...{ canEdit, dummyContainerRef, templateRows }} />
                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
      </div>
      {renderFooter()}
    </div>
  );
}
