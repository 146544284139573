import * as React from 'react';

import Context from '../../Context';

export default function customFooter({ index }) {
  const context = React.useContext(Context);
  const { reportTemplate } = context.state;
  const { reportTemplateTheme } = reportTemplate;

  const footerStyles = {
    backgroundColor: reportTemplateTheme.footerColor,
    backgroundImage: `url('${reportTemplateTheme.footerBackground}')`,
  };

  const fontColor = {
    color: reportTemplateTheme.footerFontColor,
  };

  let borderCss = { __html: '' };
  if (!reportTemplateTheme.footerBackground) {
    borderCss = {
      __html: `.pdf-footer--fixed:after { background-color: ${reportTemplateTheme.footerBorderColor} }`,
    };
  }

  return (
    <footer className="pdf-footer pdf-footer--fixed" style={footerStyles}>
      <style dangerouslySetInnerHTML={borderCss} />
      <table className="pdf-hidden-table" style={{ height: '100%' }}>
        <tbody>
          <tr>
            <td style={{ width: '70%' }}>
              <div className="pdf-footer__small-print" style={fontColor}>
                {reportTemplateTheme.name}
              </div>
              <div className="pdf-footer__small-print" style={fontColor}>
                {reportTemplateTheme.address}
              </div>
            </td>
            <td>
              <div className="pdf-footer__small-print" style={fontColor}>
                {reportTemplateTheme.website}
              </div>
              <div className="pdf-footer__small-print" style={fontColor}>
                {reportTemplateTheme.phone}
              </div>
            </td>
            <td className="pdf-footer__page-number" style={fontColor}>
              {index + 1}
            </td>
          </tr>
        </tbody>
      </table>
    </footer>
  );
}
