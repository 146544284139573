import * as React from 'react';

import { endOfQuarter, format, subQuarters } from 'date-fns';

import Presenter from './tracker/Presenter';

import { TrackedReview } from 'javascript/models';
import { Tracker } from 'javascript/models';

interface IProps {
  isTrackingManager: boolean;
  team: string;
  dataTeamMembers: { id: number; name: string }[];
  verificationTeamMembers: { id: number; name: string }[];
  writingTeamMembers: { id: number; name: string }[];
}

export default function tracker(props: IProps) {
  const { dataTeamMembers, isTrackingManager, verificationTeamMembers, writingTeamMembers } = props;

  const [frequency, setFrequency] = React.useState('Weekly');
  const [isLoading, setIsLoading] = React.useState(true);
  const [meta, setMeta] = React.useState();
  const [selectedDate, setSelectedDate] = React.useState(endOfQuarter(subQuarters(new Date(), 1)));
  const [tracker, setTracker] = React.useState();
  const [view, setView] = React.useState('overall');
  const [team, setTeam] = React.useState(props.team || 'data');

  React.useEffect(() => {
    if (selectedDate) {
      setIsLoading(true);
      getRecords().then(() => {
        getTracker().then(() => setIsLoading(false));
      });
    }
  }, [selectedDate]);

  React.useEffect(() => {
    getTracker();
  }, [team]);

  async function getRecords() {
    const groupedByStats = [
      'data_complete_week',
      'data_complete_week_admin',
      'data_complete_day',
      'data_complete_day_admin',
      'writing_complete_week',
      'writing_complete_week_admin',
      'writing_complete_day',
      'writing_complete_day_admin',
      'verification_complete_week',
      'verification_complete_week_admin',
      'verification_complete_day',
      'verification_complete_day_admin',
    ];

    const totalStats = [
      'for_data',
      'data_completed',
      'for_writing',
      'writing_completed',
      'for_verification',
      'verification_completed',
    ];

    const { meta } = await TrackedReview.where({ end_date: format(selectedDate, 'YYYY-MM-DD') })
      .select([''])
      .stats({ total: totalStats })
      .stats({ grouped_by: groupedByStats })
      .all();

    setMeta(meta);
  }

  async function getTracker() {
    const trackers = await Tracker.where({ tracker_date: format(selectedDate, 'YYYY-MM-DD'), team }).all();
    let thisTracker;

    if (trackers.data.length > 0) {
      thisTracker = trackers.data[0];
    } else {
      const newTracker = new Tracker({ tracker_date: format(selectedDate, 'YYYY-MM-DD'), team });
      await newTracker.save();
      thisTracker = newTracker;
    }

    const { data } = await Tracker.selectExtra(['overall_targets', 'overall_targets_daily']).find(thisTracker.id);

    setTracker(data);
  }

  return (
    <Presenter
      {...{
        dataTeamMembers,
        frequency,
        isLoading,
        isTrackingManager,
        meta,
        selectedDate,
        setFrequency,
        setSelectedDate,
        setTeam,
        setView,
        team,
        tracker,
        verificationTeamMembers,
        view,
        writingTeamMembers,
      }}
    />
  );
}
