import * as React from 'react';

const Context = React.createContext({
  actions: {
    handleClientSelect: (clientId: string) => {},
    handleEntityCreation: (entity) => {},
    handleManagerCreation: (manager) => {},
    handleTabChange: (index: number) => {},
  },
  props: {
    action: 'create',
    adminSystemOptions: [],
    canCreateClientUsers: false,
    canCreateEntities: false,
    canCreateManagers: false,
    clientTypeOptions: [],
    companyId: null,
    contactTypeOptions: [],
    currencyOptions: [],
    dataChaseTypeOptions: [],
    entityFormOptions: {},
    feeType: null,
    feeTypeOptions: {},
    managerFormOptions: {
      parentManagerOptions: [],
    },
    mandateDocTypeOptions: [],
    portfolioId: null,
    portfolioStatusOptions: [],
    relationshipManagerOptions: [],
    reviewCommentaryTypeOptions: [],
    reviewFrequencyOptions: [],
    riskOptions: [],
    serviceTypeOptions: [],
    latestLetterOfEngagement: null,
    latestLetterOfSuitability: null,
    latestInvestmentObjectiveEvaluation: null,
  },
  state: {
    client: null,
    clients: [],
    portfolio: null,
    selectedEntity: null,
    selectedManager: null,
    selectedManagerUser: null,
    selectedTab: 'Portfolio',
    tabs: [],
  },
});

export default Context;
