import React, { useContext, useMemo } from 'react';
import Checkbox from 'components/shared/Checkbox';
import { uniqueId } from 'lodash';
import { Flag } from 'javascript/models';
import { InvestmentRestrictionsContext } from '../context';
import { Frow } from 'components/frow';
import ConfigurationSettings from './ConfigurationSettings';
import RadioButtonGroup from 'components/shared/forms/RadioButtonGroup';

type Props = {
  flagType: string;
  namePrefix: string;
  label: string;
  options: { value: string; label: string }[];
};

const RadioFlag = (props: Props) => {
  const { flagType, namePrefix, label, options } = props;

  const { investmentRestrictionsSetting } = useContext(InvestmentRestrictionsContext);
  const { flags } = investmentRestrictionsSetting;
  const existingFlag = useMemo(() => flags.filter((flag) => flag.flagType === flagType), [flags, flagType]);
  const flag = existingFlag[0] || new Flag({ generateActionPoint: true });

  const [selectedValue, setSelectedValue] = React.useState<string>(flag.value ? `${flag.value}` : '0');

  const name = `${namePrefix}[${uniqueId()}]`;

  return (
    <fieldset className="form__fieldset pad-v-3">
      <h4 className="form__fieldset-label">{label}</h4>

      <div className="pad-b-3">
        <RadioButtonGroup
          options={options}
          name={`${name}[value]`}
          selectedValue={`${selectedValue}`}
          onChange={(value) => setSelectedValue(value)}
        />
      </div>

      <Frow>
        <div className="col-md-3-5">
          <ConfigurationSettings name={name} flag={flag} />
        </div>
      </Frow>

      <input type="hidden" name={`${name}[id]`} value={flag.id} />
      <input type="hidden" name={`${name}[flag_type]`} value={flagType} />
      <input type="hidden" name={`${name}[value]`} value={parseInt(selectedValue)} />
    </fieldset>
  );
};

export default RadioFlag;
