import * as React from 'react';

export default function subtitleSection({ attributes, handleChange }) {
  return (
    <div className="frow frow--gutters">
      <div className="col-mc-1-1">
        <label className="form__label" htmlFor="">
          Subtitle Text
        </label>
        <input type="text" name="text" value={attributes.text || ''} onChange={handleChange} />
      </div>
    </div>
  );
}
