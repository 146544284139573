import * as React from 'react';
import * as $ from 'jquery';

import * as ReactTooltip from 'react-tooltip';

import lodashStartcase from 'lodash.startcase';
import { CSVLink } from 'react-csv';

import { format, parse } from 'date-fns';
import Button from '../forms/Button';

interface IProps {
  columns: any;
  filters: any;
  formatting?: any;
  meta?: any;
  path: string;
  searchTerm: string;
  sorting: any;
}

export default function oldCsvExport(props: IProps) {
  const { columns, filters, searchTerm, sorting } = props;

  const [data, setData] = React.useState([]);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const csvDownloadButton = React.useRef(null);

  function getRecords() {
    $.ajax({
      beforeSend: () => setIsSubmitting(true),
      cache: false,
      data: { filters, sorting, search: searchTerm, page_size: 100000, page: 1 },
      dataType: 'json',
      success: (response) => setData(response.data),
      type: 'GET',
      url: props.path,
    });
  }

  const headerRows = columns.map((column) => column.accessor).filter(Boolean);

  const csvData = data.map((record) => {
    return headerRows.map((header) => {
      return eval(`record.${header}`);
    });
  });

  const headers = headerRows.map((header) => lodashStartcase(header.split('attributes')[1]));

  React.useEffect(() => {
    if (data.length > 0) {
      csvDownloadButton.current.link.click();
    }
    setIsSubmitting(false);
  }, [data]);

  function handleClick() {
    setIsSubmitting(true);
    getRecords();
  }

  const tooltip = props.meta && props.meta.total > 5000;

  return (
    <React.Fragment>
      <div className="hidden">
        <CSVLink
          ref={csvDownloadButton}
          data={csvData}
          headers={headers}
          filename={`mosaic_table_export_${format(parse(new Date()), 'DD-MM-YYYY')}.csv`}
        />
      </div>
      <div className="pad-a-2" data-tip={tooltip && "You can't export more than 5000 rows at a time"}>
        <Button
          buttonClasses="button--compact button--full-width"
          handleClick={handleClick}
          isDisabled={tooltip}
          text="Export Table"
          submittingText="Exporting"
          isSubmitting={isSubmitting}
        />
        <ReactTooltip effect="solid" visible={tooltip} />
      </div>
    </React.Fragment>
  );
}
