import * as React from 'react';
import { DirectUpload } from 'activestorage';

import useVisibility from 'components/shared/customHooks/useVisibility';
import CustomModal from 'components/shared/CustomModal';
import Dropzone from 'components/shared/forms/Dropzone';
import Alert from 'components/shared/Alert';
import { PortfolioBenchmarkUpload } from 'javascript/models';
import { createAlert } from 'components/shared/Utils';

export default function upload({ uploadTypes }) {
  const { isOpen, handleOpen, handleClose } = useVisibility(false);

  const [alert, setAlert] = React.useState(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [uploadType, setUploadType] = React.useState('');

  async function handleUploadedFiles(files) {
    if (files.length < 1) return;

    setIsSubmitting(true);

    files.forEach((file, index) => {
      const upload = new DirectUpload(file, '/rails/active_storage/direct_uploads');
      upload.create((error, blob) => {
        if (error) {
          setIsSubmitting(false);
          setAlert({ message: 'There was an error uploading this file', type: 'error', title: 'Upload Error' });
          handleClose();
        } else {
          const signedId = blob.signed_id;
          createUpload(signedId).then(() => {
            if (index === files.length - 1) {
              setIsSubmitting(false);
              const customEvent = new CustomEvent('trackedJob:new');
              document.dispatchEvent(customEvent);
              handleClose();
            }
          });
        }
      });
    });
  }

  function handleUploadType(event) {
    setUploadType(event.target.value);
  }

  async function createUpload(signedId) {
    const portfolioBenchmarkUpload = new PortfolioBenchmarkUpload({ import: signedId, upload_type: uploadType });

    const success = await portfolioBenchmarkUpload.save();
    if (success) {
      createAlert('success', 'File Uploaded', 1500);
    }
  }

  function renderAlert() {
    if (!alert) return;

    return <Alert isCloseable modifier={'full-width'} {...alert} />;
  }

  function renderUpload() {
    if (!uploadType) return;

    return (
      <div className="h-100">
        <Dropzone
          acceptedFiles=".csv, .xls, .xlt, .xlm, .xlsx, .xlsm, .xltx, .xltm, .xlsb, .xla, .xlam, .xll, .xlw"
          shouldDiscardFiles
          isLarge
          isSubmitting={isSubmitting}
          onFileUpload={handleUploadedFiles}
          resetFiles
          shouldShowFiles={false}
        />
      </div>
    );
  }

  const uploadTypeOptions = uploadTypes.map((uploadType) => {
    return (
      <option key={uploadType} value={uploadType}>
        {uploadType.toUpperCase()}
      </option>
    );
  });

  return (
    <React.Fragment>
      <button className="button button--secondary" onClick={handleOpen}>
        Upload Benchmarks
      </button>
      <CustomModal isOpen={isOpen} handleClose={handleClose} title="Upload Benchmark File">
        <div className="modal__content">
          {renderAlert()}
          <div className="form form--light mar-b-1">
            <div className="frow frow--gutters">
              <div className="col-mc-1-1">
                <label htmlFor="upload_type" className="form__label">
                  Upload Type
                </label>
                <select name="upload_type" id="upload_type" onChange={handleUploadType} value={uploadType}>
                  <option value="">Please Select</option>
                  {uploadTypeOptions}
                </select>
              </div>
            </div>
          </div>
          {renderUpload()}
        </div>
      </CustomModal>
    </React.Fragment>
  );
}
