import * as React from 'react';

import { format, parse } from 'date-fns';
import { uniq, snakeCase } from 'lodash';

import ReactDataSheet from 'react-datasheet';
import { Holding } from 'javascript/models';

export default function presenter({ assetHoldings, balances, currencyHoldings, handleCellChange, portfolioRef }) {
  const currencies = ['GBP', 'USD', 'EUR', 'JPY', 'HKD', 'CHF', 'SGD', 'ZAR', 'CAD', 'AUD', 'NZD', 'Other'];
  const [assetClasses, setAssetClasses] = React.useState();

  const getAssetClasses = async () => setAssetClasses(await Holding.allAvailableAssetClasses);
  React.useEffect(() => (getAssetClasses(), undefined), []);

  const headers = ['Date', 'Value', ...assetClasses, ...currencies];

  const attributes = [...assetHoldings, ...balances, ...currencyHoldings];
  const dates = attributes
    .map((thing) => (thing.valueDate ? thing.valueDate : thing.value_date))
    .sort((a, b) => +new Date(a) - +new Date(b));

  const rows = uniq(dates).map((date) => {
    const portfolioValue = balances.find((balance) => balance.valueDate === date);
    const assetHolding = assetHoldings.find((holding) => holding.value_date === date);
    const currencyHolding = currencyHoldings.find((holding) => holding.value_date === date);

    return [
      { value: format(parse(date), 'DD/MM/YYYY'), readOnly: true },
      { value: portfolioValue ? portfolioValue.amount : '' },
      ...assetClasses.map((assetClass) => ({ value: assetHolding ? assetHolding[snakeCase(assetClass)] : '' })),
      ...currencies.map((currency) => ({
        value: currencyHoldings ? currencyHolding[snakeCase(currency.substring(0, 3))] : '',
      })),
    ];
  });

  const grid = [headers.map((header) => ({ value: header, readOnly: true })), ...rows] as any;

  const valueRender = (cell) => cell.value;

  return <ReactDataSheet data={grid} valueRenderer={valueRender} onCellsChanged={handleCellChange} />;
}
