import * as React from 'react';

import ReactSelect from 'components/shared/forms/ReactSelect';

export default function userSelect({ client, handleParamsChange, userOptions, userObject }) {
  let defaultValue = { label: 'Create New User', value: 0 };
  if (userObject.user) {
    defaultValue = userOptions.find((userOption) => userObject.user.id == userOption.value);
  }

  const newUser = userObject.new;
  const belongsToOtherClient = !userObject.clientIds.includes(client);

  function sheetName() {
    if (newUser) {
      return (
        <div className="table-icon table-icon--warning">
          {userObject.sheet} <span className="text-brand-orange text-bold">NEW</span>
        </div>
      );
    }

    if (belongsToOtherClient) {
      return (
        <div className="table-icon table-icon--warning">
          {userObject.sheet} <span className="text-brand-orange text-bold">BELONGS TO ANOTHER CLIENT</span>
        </div>
      );
    }

    return userObject.sheet;
  }

  function handleSelect(selectOption) {
    handleParamsChange(userObject.uniqueId, selectOption.value);
  }

  return (
    <tr>
      <td>{sheetName()}</td>
      <td className="text-left">
        <ReactSelect
          theme="dark"
          name="bulk_import[user_id]"
          id="client"
          options={userOptions}
          defaultValue={defaultValue}
          placeholder={newUser ? 'Create new user' : belongsToOtherClient ? 'Link to client' : 'Please select'}
          handleChange={handleSelect}
        />
      </td>
    </tr>
  );
}
