import * as React from 'react';
import Dropzone from 'components/shared/forms/Dropzone';
import { Frow } from 'components/frow';
import ReactSelect from 'components/shared/forms/ReactSelect';

interface IProps {
  tableListOptions: any;
  handleFileUpload: any;
  isSubmitting: boolean;
  responseData: any;
  handleTableSelect: any;
  handleActionSelect: any;
}

const Presenter = (props: IProps) => {
  const { handleFileUpload, tableListOptions, handleActionSelect, handleTableSelect } = props;

  const noDataText = (
    <div className="platform-panel__inner">
      <p className="text-white helper-text mar-v-0">Upload a sheet to see a preview here</p>
    </div>
  );

  const actionList = [
    { value: 'update', label: 'Update' },
    { value: 'insert', label: 'Create' },
    { value: 'delete', label: 'Delete (Danger)' },
  ];

  function actions() {
    return (
      <ReactSelect
        createable
        options={actionList}
        theme="dark"
        id="action_list"
        name="action_list[]"
        handleChange={handleActionSelect}
      />
    );
  }

  function tables() {
    return (
      <ReactSelect
        createable
        options={tableListOptions}
        theme="dark"
        id="table_list"
        name="tables_list[]"
        handleChange={handleTableSelect}
      />
    );
  }

  return (
    <React.Fragment>
      <div className="frow">
        <div className="col-md-3-3">
          <section className="platform-panel">
            <div className="frow row-start" style={{ padding: '10px' }}>
              <Frow justifyContent="around" itemAlignment="baseline">
                <label htmlFor="table_select" className="small-print text-white dropdown__toggle--input">
                  SELECT TABLE TO APPLY ACTION
                </label>
                <div style={{ margin: '10px', minWidth: '100%' }}>{tables()}</div>
              </Frow>
            </div>
            <div className="frow row-start" style={{ padding: '10px' }}>
              <Frow justifyContent="around" itemAlignment="baseline">
                <label htmlFor="action_select" className="small-print text-white dropdown__toggle--input">
                  SELECT ACTION
                </label>
                <div style={{ margin: '10px', minWidth: '100%' }}>{actions()}</div>
              </Frow>
            </div>

            <div className="platform-panel__inner">
              <div className="frow frow--gutters">
                <div className="col-mc-1-1">
                  <div className="form">
                    <label className="form__label">Upload CSV</label>
                    <Dropzone acceptedFiles=".csv" theme="dark" multiple={false} onFileUpload={handleFileUpload} />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Presenter;
