import * as React from 'react';

export interface IProps {
  handleUpdateSelection(value: string): void;
  row: { original: { id: string } };
}

export default function checkbox(props: IProps) {
  const [isChecked, setIsChecked] = React.useState(false);

  React.useEffect(() => {
    document.addEventListener('checkbox:headerChecked', handleHeaderChecked);
    return () => {
      document.removeEventListener('checkbox:headerChecked', handleHeaderChecked);
    };
  }, [props.row]);

  function handleHeaderChecked(event) {
    const { checked } = event.detail;
    setIsChecked(checked);
  }

  return (
    <span className="pretty pretty--table p-icon p-smooth mar-r-0">
      <input
        type="checkbox"
        onChange={() => {
          setIsChecked((previous) => !previous);
          props.handleUpdateSelection(props.row.original.id);
        }}
        checked={isChecked}
      />
      <span className="state p-primary">
        <i className="icon fa fa-check" />
        <label />
      </span>
    </span>
  );
}
