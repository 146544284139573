import * as React from 'react';

import Select from 'react-select';

import ManagerCreate from './manager/ManagerCreate';

import FormRow from 'components/shared/forms/FormRow';
import ReactSelect from 'components/shared/forms/ReactSelect';

import useDebounce from 'components/shared/customHooks/useDebounce';

import { Manager } from 'javascript/models';
import Context from '../Context';
import { ManagerUser } from 'javascript/models';

export default function manager() {
  const context = React.useContext(Context);

  const { canCreateManagers, companyId } = context.props;
  const { state } = context;
  const { portfolio, selectedManager } = state;

  const { additionalReference, investmentManagerReference } = portfolio;
  let managerName;
  let managerId;

  if (portfolio && portfolio.manager) {
    managerName = portfolio.manager.name;
    managerId = portfolio.manager.id;
  }

  let managerUserName;
  let managerUserId;
  if (portfolio && portfolio.managerUser) {
    managerUserName = portfolio.managerUser.fullName;
    managerUserId = portfolio.managerUser.id;
  }

  const [isLoading, setIsLoading] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const [selectedValue, setSelectedValue] = React.useState(
    managerName && managerId ? { label: managerName, value: managerId } : null,
  );
  const [selectedManagerUser, setSelectedManagerUser] = React.useState(
    managerUserName && managerUserId ? { label: managerUserName, value: managerUserId } : null,
  );
  const [managerUserOptions, setManagerUserOptions] = React.useState([]);

  React.useEffect(() => {
    if (selectedManager) {
      setSelectedValue(selectedManager);
      const newOptions = [...options];
      newOptions.push(selectedManager);
      setOptions(newOptions);
    }
  }, [selectedManager]);

  React.useEffect(() => {
    if (state.selectedManagerUser) {
      setSelectedManagerUser(state.selectedManagerUser);
      const newOptions = [...managerUserOptions];
      newOptions.push(state.selectedManagerUser);
      setManagerUserOptions(newOptions);
    }
  }, [state.selectedManagerUser]);

  async function handleManagerUserSelect(option) {
    if (!option || !option.value) {
      setManagerUserOptions([]);
      return;
    }
    const { data } = await ManagerUser.where({ manager_id: option.value }).per(1000).all();

    const options = data.map((user) => ({ label: user.fullName, value: user.id }));
    setManagerUserOptions(options);
  }

  React.useEffect(() => {
    if (managerId) {
      handleManagerUserSelect({ value: managerId });
    }
  }, [managerId]);

  const debouncedInputValue = useDebounce(inputValue, 600);

  React.useEffect(() => {
    setIsLoading(false);
  }, [options]);

  function handleInputChange(input) {
    setInputValue(input);
  }

  function handleChange(selectedValue) {
    setSelectedValue(selectedValue);
    setSelectedManagerUser(null);
    handleManagerUserSelect(selectedValue);
  }

  React.useEffect(() => {
    if (debouncedInputValue && debouncedInputValue.length > 2) {
      getManagers();
    }
  }, [debouncedInputValue]);

  async function getManagers() {
    const { data } = await Manager.where({ company_id: companyId })
      .where({ selectable: true })
      .where({ search: { match: inputValue } })
      .all();

    const managerOptions = data.map((manager) => ({ value: manager.id, label: manager.name }));
    setOptions(managerOptions);
  }

  function handleManagerUserChange(selectedValue) {
    setSelectedManagerUser(selectedValue);
  }

  let managerUsers = [];
  if (managerUserOptions) {
    managerUsers = managerUserOptions.filter((user) => portfolio.managerUsersIds.includes(user.value));
  }

  function renderSelect(defaultValues, name) {
    if (managerUserOptions.length > 0) {
      return (
        <ReactSelect
          theme="dark"
          name={name}
          id={name}
          isMulti
          isClearable
          options={managerUserOptions}
          defaultValues={defaultValues}
        />
      );
    }

    return (
      <div>
        <p className="helper-text text-white">No Manager users found</p>
      </div>
    );
  }

  return (
    <fieldset className="form__fieldset">
      <div className="frow frow--items-center frow--justify-between">
        <div>
          <h4 className="form__fieldset-label">Manager Information</h4>
        </div>
        <div>{canCreateManagers && <ManagerCreate />}</div>
      </div>
      <div className="frow frow--gutters">
        <FormRow note="" className="col-mc-1-1">
          <label htmlFor="manager" className="form__label">
            Manager
          </label>
          <Select
            className="react-select-dark"
            classNamePrefix="react-select-dark"
            value={selectedValue}
            isClearable
            isSearchable
            isLoading={isLoading}
            id="manager_id"
            name="portfolio[manager_id]"
            options={options}
            placeholder="Search for a Manager to select"
            inputValue={inputValue}
            onInputChange={handleInputChange}
            onChange={handleChange}
          />
        </FormRow>
        <FormRow note="" className="col-mc-1-1">
          <label htmlFor="manager_user" className="form__label">
            Primary Manager
          </label>
          <ReactSelect
            theme="dark"
            name="portfolio[manager_user_id]"
            id="manager_user"
            options={managerUserOptions}
            value={selectedManagerUser}
            handleChange={handleManagerUserChange}
          />
        </FormRow>
        <FormRow note="" className="col-mc-1-1">
          <label htmlFor="primary_user" className="form__label">
            Data Contacts
          </label>
          {renderSelect(managerUsers, 'portfolio[manager_user_ids][]')}
        </FormRow>
        <FormRow note="" className="col-mc-1-1">
          <label htmlFor="manager_reference" className="form__label">
            Manager Reference
          </label>
          <input
            type="text"
            placeholder="e.g. 110101011..."
            name="portfolio[investment_manager_reference]"
            defaultValue={investmentManagerReference}
          />
        </FormRow>
        <FormRow note="An additional reference that the manager may use. Used for data mapping" className="col-mc-1-1">
          <label htmlFor="manager_reference" className="form__label">
            Additional Manager Reference
          </label>
          <input
            type="text"
            placeholder="e.g. abcdefg..."
            name="portfolio[additional_reference]"
            defaultValue={additionalReference}
          />
        </FormRow>
      </div>
    </fieldset>
  );
}
