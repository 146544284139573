import * as React from 'react';

import columns from './table/columns';

import { Valuation } from 'javascript/models';
import GraphitiTableWrapper from 'components/shared/GraphitiTableWrapper';

export default function table({ portfolioId }) {
  const [shouldUpdateTable, setShouldUpdateTable] = React.useState(false);

  React.useEffect(() => {
    if (shouldUpdateTable) {
      setShouldUpdateTable(false);
    }
  }, [shouldUpdateTable]);

  function handleValuationUpload() {
    setShouldUpdateTable(true);
  }

  function handleValuationUpdate() {
    setShouldUpdateTable(true);
  }

  React.useEffect(() => {
    document.addEventListener('valuation:uploaded', handleValuationUpload);
    return () => {
      document.removeEventListener('valuation:uploaded', handleValuationUpload);
    };
  }, []);

  const scope = Valuation.where({ portfolio_id: portfolioId }).order({ value_date: 'desc' });

  return (
    <GraphitiTableWrapper
      scope={scope}
      columns={columns(handleValuationUpdate)}
      shouldUpdateTable={shouldUpdateTable}
      identifier="valuations:index"
    />
  );
}
