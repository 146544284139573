import cable from './cable';

let callback; // declaring a variable that will hold a function later

const notification = cable('NotificationChannel', {
  received(response) {
    if (callback) callback.call(null, response);
  },
});

function markAllAsRead() {
  notification.perform('mark_all_as_read');
}

// Getting a message: this callback will be invoked once we receive
// something over NotificationChannel
function setCallback(fn) {
  callback = fn;
}

export { markAllAsRead, setCallback };
