import * as React from 'react';

import DatePicker from 'react-datepicker';

import lodashStartcase from 'lodash.startcase';

import Address from './Address';

import BooleanOption from 'components/shared/forms/BooleanOption';
import FormRow from 'components/shared/forms/FormRow';
import ReactSelect from 'components/shared/forms/ReactSelect';

import ISelectOption from 'components/interfaces/ISelectOption';
import IEntity from 'components/interfaces/IEntity';
import CountrySelect from 'components/shared/forms/CountrySelect';

interface IProps {
  entity: { data: IEntity };
  genders: ISelectOption[];
  occupations: ISelectOption[];
  shortForm: boolean;
  titles: ISelectOption[];
}

export default function natural(props: IProps) {
  const { entity, genders, occupations, shortForm, titles } = props;

  const {
    emailAddress,
    firstName,
    gender,
    governmentPin,
    lastName,
    middleName,
    occupation,
    phoneNumber,
    previousName,
    taxResidence,
    title,
  } = entity.data.attributes;

  const [dob, setDob] = React.useState(new Date(props.entity.data.attributes.dob));
  const [dateOfDeath, setDateOfDeath] = React.useState(new Date(props.entity.data.attributes.dateOfDeath));

  const [usConnection, setUsConnection] = React.useState(props.entity.data.attributes.usConnection);

  function handleUsConnectionChange() {
    setUsConnection(!usConnection);
  }

  function handleDobChange(date) {
    setDob(date);
  }

  function handleDeathDateChange(date) {
    setDateOfDeath(date);
  }

  const middleNameInput = (
    <FormRow>
      <label htmlFor="middle_name" className="form__label">
        Middle Name
      </label>
      <input type="text" name="entity[middle_name]" id="middle_name" defaultValue={middleName} />
    </FormRow>
  );

  const previousNameInput = (
    <FormRow>
      <label htmlFor="previous_name" className="form__label">
        Previous Name
      </label>
      <input type="text" name="entity[previous_name]" id="previous_name" defaultValue={previousName} />
    </FormRow>
  );

  const longFormAttributes = (
    <React.Fragment>
      <FormRow>
        <label htmlFor="dob" className="form__label">
          Date of Birth
        </label>
        <DatePicker name="entity[dob]" id="dob" selected={dob} onChange={handleDobChange} />
      </FormRow>
      <FormRow>
        <label htmlFor="date_of_death" className="form__label">
          Date of Death
        </label>
        <DatePicker
          name="entity[date_of_death]"
          id="date_of_death"
          selected={dateOfDeath}
          onChange={handleDeathDateChange}
        />
      </FormRow>
      <FormRow>
        <label htmlFor="gender" className="form__label">
          Gender
        </label>
        <ReactSelect
          name="entity[gender]"
          theme="dark"
          options={genders}
          id="gender"
          defaultValue={{ value: gender, label: lodashStartcase(gender) }}
        />
      </FormRow>
      <FormRow>
        <label htmlFor="occupation" className="form__label">
          Occupations
        </label>
        <ReactSelect
          name="entity[occupation]"
          theme="dark"
          options={occupations}
          id="occupation"
          defaultValue={{ value: occupation, label: lodashStartcase(occupation) }}
        />
      </FormRow>
      <FormRow>
        <label htmlFor="tax_residence" className="form__label">
          Tax Residence
        </label>
        <CountrySelect name="entity[tax_residence]" country={taxResidence} />
      </FormRow>
      <FormRow>
        <label htmlFor="lei" className="form__label">
          US Connection
        </label>
        <BooleanOption
          name="entity[us_connection]"
          changeHandler={handleUsConnectionChange}
          defaultValue={usConnection}
        />
      </FormRow>
      <FormRow>
        <label htmlFor="government_pin" className="form__label">
          Government Pin
        </label>
        <input type="text" name="entity[government_pin]" id="government_pin" defaultValue={governmentPin} />
      </FormRow>
      <FormRow>
        <label htmlFor="email_address" className="form__label">
          Email Address
        </label>
        <input type="email" name="entity[email_address]" id="email_address" defaultValue={emailAddress} />
      </FormRow>
      <FormRow>
        <label htmlFor="phone_number" className="form__label">
          Phone Number
        </label>
        <input type="text" name="entity[phone_number]" id="phone_number" defaultValue={phoneNumber} />
      </FormRow>
    </React.Fragment>
  );

  return (
    <fieldset className="form__fieldset">
      <h4 className="form__fieldset-label">Natural Person Details</h4>
      <div className="frow frow--gutters">
        <FormRow>
          <label htmlFor="title" className="form__label">
            Title
          </label>
          <ReactSelect
            name="entity[title]"
            theme="dark"
            options={titles}
            id="title"
            defaultValue={{ value: title, label: lodashStartcase(title) }}
          />
        </FormRow>
        <FormRow>
          <label htmlFor="first_name" className="form__label">
            First Name
          </label>
          <input type="text" name="entity[first_name]" id="first_name" defaultValue={firstName} />
        </FormRow>
        {!shortForm && middleNameInput}
        <FormRow>
          <label htmlFor="last_name" className="form__label">
            Last Name
          </label>
          <input type="text" name="entity[last_name]" id="last_name" defaultValue={lastName} />
        </FormRow>
        {!shortForm && previousNameInput}
        <Address {...{ entity, shortForm }} />
        {!shortForm && longFormAttributes}
      </div>
    </fieldset>
  );
}
