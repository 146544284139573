import * as React from 'react';

import { format, parse } from 'date-fns';
import ReactTooltip from 'react-tooltip';

import lodashStartcase from 'lodash.startcase';
import CompositeIndicator from './StatusIndicator';

const columns = (benchmarkTypes: any[], currencies: any[], riskOptions: any[], compositeOptions: boolean[]) => [
  {
    Header: 'Reference',
    accessor: 'reference',
    columnName: 'Reference',
    id: 'reference',
  },
  {
    Cell: (row) => <div className="text-uppercase">{row.original.currency}</div>,
    Header: 'Currency',
    accessor: 'currency',
    columnName: 'Currency',
    filterOptions: {
      key: 'currency',
      options: currencies.map((type: string) => ({ display: type.toUpperCase(), value: type })),
    },
    id: 'currency',
  },
  {
    Cell: (row) => (
      // The random line height is to make the rows the same height as the portfolio and review tables (which each
      // have a 26px tall logo in one of the rows
      <div style={{ lineHeight: '26px' }}>{lodashStartcase(row.original.risk)}</div>
    ),
    Header: 'Risk Profile',
    accessor: 'risk',
    columnName: 'Risk',
    filterOptions: {
      key: 'risk',
      options: riskOptions.map((type: string) => ({ display: lodashStartcase(type), value: type })),
    },
    id: 'risk',
  },
  {
    Cell: (row) => {
      if (!row.original.lastReturnDate) return <div>Never</div>;

      return <div>{format(parse(row.original.lastReturnDate), 'DD-MM-YYYY')}</div>;
    },
    Header: 'Last Return Date',
    accessor: 'lastReturnDate',
    columnName: 'Last Return Date',
    id: 'last_return_date',
  },
  {
    Cell: (row) => <div>{lodashStartcase(row.original.benchmarkType)}</div>,
    Header: 'Benchmark Type',
    accessor: 'benchmarkType',
    columnName: 'Benchmark Type',
    filterOptions: {
      key: 'benchmark_type',
      options: benchmarkTypes.map((type: string) => ({ display: lodashStartcase(type), value: type })),
    },
    id: 'benchmark_type',
  },
  {
    Cell: (row) => <CompositeIndicator status={row.original.composite} />,
    Header: 'Composite Index',
    accessor: 'composite',
    columnName: 'Composite Index',
    filterOptions: {
      key: 'composite',
      options: compositeOptions.map((isComposite: boolean) => ({
        display: isComposite ? 'Yes' : 'No',
        value: isComposite.toString(),
      })),
    },
    id: 'composite',
  },
  {
    Cell: (row) => (
      <div data-tip={row.original.description}>
        {row.original.description}
        <ReactTooltip effect="solid" clickable />
      </div>
    ),
    Header: 'Description',
    accessor: 'description',
    columnName: 'Description',
    id: 'description',
  },
  {
    Header: 'Provider ID',
    accessor: 'providerReferenceId',
    columnName: 'Provider ID',
    id: 'provider_reference_id',
  },
  {
    Header: 'Ticker',
    accessor: 'systemInformation',
    columnName: 'Ticker',
    id: 'system_information',
  },
  {
    Cell: (row) => (
      <a href={row.original.showPath} style={{ display: 'block' }}>
        <div className="rt-tr-clickable-icon">
          <i className="icon-arrow-right-top icon-0-8x" />
        </div>
      </a>
    ),
    Header: '',
    sortable: false,
  },
];
export default columns;
