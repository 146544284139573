import { Attr, Model } from 'spraypaint';

import { ApplicationRecord } from './ApplicationRecord';

@Model()
export class Notification extends ApplicationRecord {
  public static jsonapiType = 'notifications';
  @Attr() public body: string;
  @Attr() public createdAt: string;
  @Attr() public notifyOn: string;
  @Attr() public viewed: string;
}
