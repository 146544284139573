import * as React from 'react';

import GraphitiTableWrapper from 'components/shared/GraphitiTableWrapper';

import columns from './table/columns';

import { User } from 'javascript/models';
import { Scope } from 'spraypaint';

interface IProps {
  newPath?: string;
  scope?: Scope<User>;
}

export default function table(props: IProps) {
  const { newPath, scope: localScope } = props;

  let scope = localScope || User.scope();

  scope = scope.selectExtra(['primary_count', 'secondary_count', 'emap_count', 'clients']);

  return (
    <GraphitiTableWrapper
      scope={scope}
      columns={columns()}
      hasHeader
      initialSort={{ first_name: 'asc' }}
      isSearchable
      newPath={newPath}
      newText="Create User"
      searchPlaceholder="Search by name or email"
      title="Users"
      identifier="users:index"
    />
  );
}
