import * as React from 'react';

import { isLoading } from 'components/shared/HigherOrderComponents';

import Table, { ITableProps } from './messages/Table';

import { DataUpload, Note } from 'javascript/models';
import { DataUploadError } from 'javascript/models';

export interface IPartialMessagesProps {
  dataUpload: DataUpload;
}

const messages = <Model extends Note | DataUploadError>(props: IPartialMessagesProps & ITableProps<Model>) => {
  const { dataUpload } = props;

  if (dataUpload.processing) {
    return (
      <h3 className="subtitle-one text-grey text-normal">
        <i className="fas fa-circle-notch fa-spin text-brand-windows-blue mar-r-2" style={{ margin: 'auto' }} />
        <span className="text-white">Processing...</span>
      </h3>
    );
  }

  return <Table {...props} />;
};

export default isLoading()(messages);
