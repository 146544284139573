import * as React from 'react';
import lodashTruncate from 'lodash.truncate';

import { format, parse } from 'date-fns';
import { sanitize } from 'dompurify';
import Form from './Form';
import { simpleFormat } from 'components/shared/Utils';

import { Note } from 'javascript/models';
import { Intent, Menu, MenuItem, Popover, Position } from '@blueprintjs/core';
import useVisibility from 'components/shared/customHooks/useVisibility';
import CustomModal from 'components/shared/CustomModal';

interface IProps {
  note: Note;
  theme?: string;
  handleDelete?(noteId: string, destroy: boolean): void;
  handleUpdate?(comment: string, noteId: string);
}

export default function comment(props: IProps) {
  const { handleDelete, handleUpdate, note, theme } = props;

  const { isOpen, handleClose, handleOpen, setIsOpen } = useVisibility(false);

  function handleDeleteClick() {
    handleDelete(note.id, true);
  }

  function handleFormUpdate(comment: string) {
    setIsOpen(false);
    handleUpdate(comment, note.id);
  }

  function editDropdown() {
    if (!note.editable || (!handleDelete && !handleUpdate)) return;

    return (
      <div className="col-sm-1-12 text-right">
        <Popover usePortal={false} position={Position.BOTTOM}>
          <div className="mar-r-1">
            <i className="icon-overflow-horizontal-white icon text-white" />
          </div>
          <Menu>
            {handleUpdate && <MenuItem text="Edit" onClick={handleOpen} />}
            {handleDelete && <MenuItem text="Delete" intent={Intent.DANGER} onClick={handleDeleteClick} />}
          </Menu>
        </Popover>
        <CustomModal isOpen={isOpen} handleClose={handleClose} title="Review Comment">
          <Form note={note} handleFormSubmit={handleFormUpdate} />
        </CustomModal>
      </div>
    );
  }

  const commentClasses = ['comment'];
  if (theme) {
    commentClasses.push(`comment--${theme}-themed`);
  }

  return (
    <div className={commentClasses.join(' ')}>
      <div className="frow frow--gutters">
        <div className="col-sm-2-12">
          <div className="frow frow--gutters frow--items-center">
            <div>
              <div className="user-icon mar-h-0" data-letter={note.author.firstName.charAt(0)} />
            </div>
            <div className="text-small">{lodashTruncate(note.author.fullName, { length: 12 })}</div>
          </div>
          <p className="text-tiny mar-b-0">{format(parse(note.createdAt), 'DD MMM YYYY [at] HH:mm')}</p>
        </div>
        <div className="col-sm-9-12">
          <div
            className="text-wrap text-wrap--small"
            dangerouslySetInnerHTML={{ __html: simpleFormat(sanitize(note.body)) }}
          />
          <p className="text-tiny mar-b-0">
            <em>{note.additionalInfo}</em>
          </p>
        </div>
        {editDropdown()}
      </div>
    </div>
  );
}
