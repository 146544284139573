import * as React from 'react';
import * as $ from 'jquery';
import turbolinks from 'turbolinks';

const routes = require('../../../javascript/application/routes');

import Button from 'components/shared/forms/Button';
import CustomModal from 'components/shared/CustomModal';

import useVisibility from 'components/shared/customHooks/useVisibility';
import { ajaxErrorString, createAlert } from 'components/shared/Utils';
import CsrfToken from 'components/shared/CsrfToken';

export default function duplicate({ reportTemplateId }) {
  const { isOpen, handleClose, handleOpen } = useVisibility(false);

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [name, setName] = React.useState('');

  function handleChange(event) {
    setName(event.target.value);
  }

  function handleSubmit(event) {
    event.preventDefault();
    $.ajax({
      beforeSend: () => {
        setIsSubmitting(true);
      },
      data: { report_template: { name }, authenticity_token: CsrfToken() },
      dataType: 'json',
      error: (jqXhr) => {
        createAlert('error', ajaxErrorString(jqXhr), 1500);
        setIsSubmitting(false);
      },
      success: (response) => {
        setIsSubmitting(false);
        turbolinks.visit(routes.platform_report_template_path(response.id));
      },
      type: 'post',
      url: routes.duplicate_platform_report_template_path(reportTemplateId),
    });
  }

  return (
    <React.Fragment>
      <div className="dropdown__nav-link" onClick={handleOpen}>
        Duplicate
      </div>
      <CustomModal handleClose={handleClose} isOpen={isOpen} title="Duplicate Template">
        <div className="modal__content">
          <form className="form form--light" onSubmit={handleSubmit}>
            <div className="frow frow--gutters">
              <div className="col-mc-1-1">
                <label htmlFor="name" className="form__label">
                  Report Name
                </label>
                <input type="text" value={name} onChange={handleChange} />
              </div>
              <div className="col-mc-1-1">
                <Button
                  buttonClasses="button--light-themed"
                  text="Duplicate"
                  isSubmitting={isSubmitting}
                  isDisabled={!name}
                />
              </div>
            </div>
          </form>
        </div>
      </CustomModal>
    </React.Fragment>
  );
}
