import * as React from 'react';

import { endOfQuarter, format, getQuarter, getYear, parse, subQuarters } from 'date-fns';

import { editPlatformTrackersPath } from 'javascript/application/ts_routes';

import DataTargets from './DataTargets';
import QuarterPicker from 'components/shared/forms/QuarterPicker';
import VerificationTargets from './VerificationTargets';
import WritingTargets from './WritingTargets';
import { Tracker } from 'javascript/models';

interface IProps {
  dataTeamMembers: { id: number; name: string }[];
  frequency: string;
  isLoading: boolean;
  isTrackingManager: boolean;
  meta: any;
  selectedDate: Date;
  team: string;
  tracker: Tracker;
  view: string;
  verificationTeamMembers: { id: number; name: string }[];
  writingTeamMembers: { id: number; name: string }[];
  setFrequency(value): void;
  setSelectedDate(value): void;
  setTeam(value): void;
  setView(value): void;
}

export default function presenter(props: IProps) {
  const {
    dataTeamMembers,
    frequency,
    isLoading,
    isTrackingManager,
    meta,
    setFrequency,
    selectedDate,
    setSelectedDate,
    setTeam,
    setView,
    team,
    tracker,
    view,
    verificationTeamMembers,
    writingTeamMembers,
  } = props;

  function renderUpdateTargets() {
    if (!isTrackingManager || isLoading) return;

    return (
      <a
        className="button button--secondary"
        href={editPlatformTrackersPath({ date: format(selectedDate, 'YYYY-MM-DD'), type: team })}
      >
        Update Targets
      </a>
    );
  }

  function renderTargets() {
    if (team === 'data') {
      return <DataTargets {...{ dataTeamMembers, frequency, isLoading, meta, selectedDate, tracker, view }} />;
    }
    if (team === 'writing') {
      return <WritingTargets {...{ frequency, isLoading, meta, selectedDate, tracker, view, writingTeamMembers }} />;
    }
    return (
      <VerificationTargets {...{ frequency, isLoading, meta, selectedDate, tracker, view, verificationTeamMembers }} />
    );
  }

  return (
    <div className="frow">
      <div className="col-mc-1-1">
        <section className="platform-panel platform-panel--no-background">
          <div className="frow frow--justify-between frow--items-center">
            <div className="frow frow--items-center">
              <div>
                <p className="subtitle-two">{format(new Date(), 'dddd[,] D MMMM YYYY')}</p>
                <h2 className="heading-one text-lh-1">
                  Q{getQuarter(selectedDate)} {getYear(selectedDate)} Tracker
                </h2>
              </div>
              <span className="horizontal-divider hide-tn-inline" />
              <div className="frow frow--direction-column" style={{ minWidth: '150px' }}>
                <p className="subtitle-two">Switch Quarter</p>
                <QuarterPicker
                  defaultValue={format(parse(selectedDate), 'YYYY-MM-DD')}
                  handleChange={setSelectedDate}
                  id="tracker_date"
                  name="tracker_date"
                  reverseDates
                  startDate={format(endOfQuarter(subQuarters(new Date(), 16)), 'YYYY-MM-DD')}
                  theme="dark"
                />
              </div>
              <span className="horizontal-divider hide-tn-inline" />
              <div className="frow frow--direction-column">
                <p className="subtitle-two">Switch Team</p>
                <div className="button-group">
                  <div
                    className={`button-group__button ${team === 'data' && 'button-group__button--active'}`}
                    onClick={() => setTeam('data')}
                  >
                    Data
                  </div>
                  <div
                    className={`button-group__button ${team === 'writing' && 'button-group__button--active'}`}
                    onClick={() => setTeam('writing')}
                  >
                    Writing
                  </div>
                  <div
                    className={`button-group__button ${team === 'verification' && 'button-group__button--active'}`}
                    onClick={() => setTeam('verification')}
                  >
                    Verification
                  </div>
                </div>
              </div>
            </div>
            <div>{renderUpdateTargets()}</div>
          </div>
          <div className="mar-t-2">
            <div className="frow frow--gutters">
              <div>
                <div className="button-group">
                  <div
                    className={`button-group__button ${frequency === 'Weekly' && 'button-group__button--active'}`}
                    onClick={() => setFrequency('Weekly')}
                  >
                    Weekly
                  </div>
                  <div
                    className={`button-group__button ${frequency === 'Daily' && 'button-group__button--active'}`}
                    onClick={() => setFrequency('Daily')}
                  >
                    Daily
                  </div>
                </div>
              </div>
              <div>
                <div className="button-group">
                  <div
                    className={`button-group__button ${view === 'overall' && 'button-group__button--active'}`}
                    onClick={() => setView('overall')}
                  >
                    Overall
                  </div>
                  <div
                    className={`button-group__button ${view === 'individual' && 'button-group__button--active'}`}
                    onClick={() => setView('individual')}
                  >
                    Individual
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {renderTargets()}
      </div>
    </div>
  );
}
