import * as React from 'react';

const numeral = require('numeral');

import Loading from 'components/shared/Loading';

interface IProps {
  attributes: number[];
  format: string;
  isLoading: boolean;
  name: string;
  suffix?: string;
  valueKey: string;
  changeFromPrevious(current: number, previous: number, format?: string, suffix?: string): void;
}

export default function metric(props: IProps) {
  const { attributes, changeFromPrevious, format, isLoading, name, suffix, valueKey } = props;

  const lastMetric = attributes[0];
  const lastButOneMetric = attributes[1];

  const formattedSuffix = attributes.length > 0 ? suffix : '';

  function renderChange() {
    if (attributes.length < 2) {
      return;
    }

    return changeFromPrevious(lastMetric[valueKey], lastButOneMetric[valueKey], format, formattedSuffix);
  }

  function renderMetric() {
    if (isLoading) return <Loading />;

    return (
      <React.Fragment>
        <p className="metric__value">
          {attributes.length > 0 ? numeral(lastMetric[valueKey]).format(format) : '-'}
          {formattedSuffix}
        </p>
        {renderChange()}
      </React.Fragment>
    );
  }

  return (
    <div className="metric">
      <p className="metric__title">{name}</p>
      {renderMetric()}
    </div>
  );
}
