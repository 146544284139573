import * as React from 'react';

import CustomModal from 'components/shared/CustomModal';
import Form from '../managers/dataUploads/show/notes/Form';

import { Client } from 'javascript/models';
import useVisibility from 'components/shared/customHooks/useVisibility';
import Comment from '../portfolios/show/portfolioData/reviews/show/comments/Comment';
import { Note } from 'javascript/models';
import { NotesNotable } from 'javascript/models';

export default function notes({ clientId, currentUserId }) {
  const { isOpen, handleOpen, handleClose } = useVisibility(false);
  const [notes, setNotes] = React.useState([]);

  React.useEffect(() => {
    getClient();
  }, []);

  async function getClient() {
    const { data } = await Client.select([]).includes({ notes: 'author' }).find(clientId);

    setNotes(data.notes);
  }

  async function handleFormSubmit(comment: string) {
    const newNote = new Note({
      body: comment,
      author_id: currentUserId,
      note_type: 'general',
      note_category: 'note',
      status: 'normal',
    });

    const success = await newNote.save();
    if (success) {
      const notesNotable = new NotesNotable({ note_id: newNote.id, notable_type: 'Client', notable_id: clientId });

      const notableSuccess = await notesNotable.save();
      if (notableSuccess) {
        handleClose();
        getClient();
      }
    }
  }

  async function handleUpdate(comment: string, noteId: string) {
    const updatedNote = new Note({ id: noteId });
    updatedNote.isPersisted = true;
    updatedNote.body = comment;
    const success = await updatedNote.save();
    if (success) {
      getClient();
    }
  }

  async function handleDelete(noteId: string, destroy: boolean) {
    const updatedNote = new Note({ id: noteId });
    const success = await updatedNote.destroy();
    if (success) {
      getClient();
    }
  }

  const formattedNotes = notes.reverse().map((note) => {
    return (
      <div key={note.id} style={{ width: '100%' }}>
        <Comment {...{ handleDelete, handleUpdate, note }} />
      </div>
    );
  });

  function renderNotes() {
    if (notes.length > 0) {
      return formattedNotes;
    }

    return <p className="mar-v-0 text-small">This client doesn't have any notes yet.</p>;
  }

  return (
    <React.Fragment>
      <header className="platform-panel__header">
        <div className="frow frow--justify-between frow--items-center">
          <h2 className="subtitle-one text-white">Notes</h2>
          <button className="button button--secondary" onClick={handleOpen}>
            Add Note <i className="icon-plus icon-fw mar-l-1 icon-push-down-1" />
          </button>
        </div>
        <CustomModal isOpen={isOpen} title="Create Note" handleClose={handleClose}>
          <Form {...{ handleFormSubmit }} />
        </CustomModal>
      </header>
      <div className="platform-content platform-content--padding-all">
        <div className="frow frow--gutters">{renderNotes()}</div>
      </div>
    </React.Fragment>
  );
}
