import { Attr, Model } from 'spraypaint';
import { ApplicationRecord } from './ApplicationRecord';

@Model()
export class EntityRelationshipRole extends ApplicationRecord {
  public static jsonapiType = 'entity_relationship_roles';
  @Attr() public name: string;
  @Attr() public connectionDirection: string;
  @Attr() public showPath: string;
}
