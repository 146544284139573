import * as React from 'react';

import Presenter from './reportTemplateBuckets/Presenter';
import { ReportTemplateBucket } from 'javascript/models';

export default function reportTemplateBuckets({ canCreate }) {
  const [isLoading, setIsLoading] = React.useState(true);
  const [reportTemplateBuckets, setReportTemplateBuckets] = React.useState<ReportTemplateBucket[]>([]);

  React.useEffect(() => {
    fetchData().then(() => setIsLoading(false));
  }, []);

  async function fetchData() {
    const { data } = await ReportTemplateBucket.selectExtra(['template_count']).per(999).all();

    setReportTemplateBuckets(data);
  }

  return <Presenter {...{ canCreate, fetchData, isLoading, reportTemplateBuckets }} />;
}
