import * as React from 'react';

import columns from '../securityClassificationsForm/classificationUploads/table/columns';

import { ClassificationUpload } from 'javascript/models';
import GraphitiTableWrapper from 'components/shared/GraphitiTableWrapper';

interface IProps {
  securityClassificationStateId: ClassificationUpload;
}

export default function uploadsTable(props: IProps) {
  const [shouldUpdateTable, setShouldUpdateTable] = React.useState(false);

  const { securityClassificationStateId } = props;

  React.useEffect(() => {
    if (shouldUpdateTable) {
      setShouldUpdateTable(false);
    }
  }, [shouldUpdateTable]);

  function handleClassificationUpload() {
    setShouldUpdateTable(true);
  }

  function handleClassificationUpdate() {
    setShouldUpdateTable(true);
  }

  React.useEffect(() => {
    document.addEventListener('classification_upload:uploaded', handleClassificationUpload);
    return () => {
      document.removeEventListener('classification_upload:uploaded', handleClassificationUpload);
    };
  }, []);

  const scope = ClassificationUpload.where({
    security_classification_state_id: securityClassificationStateId,
  }).order({ updated_at: 'desc' });

  return (
    <GraphitiTableWrapper
      scope={scope}
      columns={columns(handleClassificationUpdate)}
      shouldUpdateTable={shouldUpdateTable}
      identifier="classification_uploads:index"
    />
  );
}
