import * as React from 'react';
import * as $ from 'jquery';

import * as ReactTooltip from 'react-tooltip';

import PortfolioRow from './clientRow/PortfolioRow';

import CsrfToken from 'components/shared/CsrfToken';
import { ajaxErrorString, createAlert } from 'components/shared/Utils';
import Loading from 'components/shared/Loading';
import useVisibility from 'components/shared/customHooks/useVisibility';

import { Client } from 'javascript/models/Client';
import { Portfolio } from 'javascript/models/Portfolio';

import { platformClientXeroPostingsPath } from 'javascript/application/ts_routes';

export default function clientRow({ client, getClients }: { client: Client; getClients(): void }) {
  const {
    feeCurrency,
    id,
    monitoringInvoiceableReviewCount,
    monitoringInvoiceableReviewTotalCount,
    name,
    reference,
    xeroContactId,
    xeroTenantId,
  } = client;

  const { isOpen, toggleOpen } = useVisibility(false);

  const [isLoading, setIsLoading] = React.useState(false);
  const [isPosting, setIsPosting] = React.useState(false);
  const [portfolios, setPortfolios] = React.useState([]);

  React.useEffect(() => {
    if (isOpen && portfolios.length < 1) {
      setIsLoading(true);
      getPortfolios();
    }
  }, [isOpen]);

  async function getPortfolios() {
    const { data } = await Portfolio.where({ client_id: id, client_type: 'monitoring' })
      .where({ monitoring_invoicaeble_reviews: true })
      .selectExtra(['monitoring_invoiceable_review_count', 'monitoring_invoiceable_total_review_fee'])
      .all();

    setPortfolios(data);
    setIsLoading(false);
  }

  function handlePost(event) {
    event.stopPropagation();
    $.ajax({
      beforeSend: () => setIsPosting(true),
      cache: false,
      complete: () => setIsPosting(false),
      data: { authenticity_token: CsrfToken() },
      dataType: 'json',
      error: (jqXhr) => {
        createAlert('error', ajaxErrorString(jqXhr), false);
      },
      success: (response) => {
        createAlert('success', 'Invoice successfully posted to Xero', 1500);
        getClients();
      },
      type: 'POST',
      url: platformClientXeroPostingsPath(client.id),
    });
  }

  function renderPost() {
    if (xeroContactId && xeroTenantId) {
      return (
        <div className="link link--right-arrow" onClick={handlePost}>
          Post to Xero
        </div>
      );
    }

    return (
      <div data-tip="Client must have a xero tenant and contact id in order to post to Xero">
        <div className="link link--right-arrow link--disabled">Post to Xero</div>
        <ReactTooltip effect="solid" />
      </div>
    );
  }

  function renderPortfolios() {
    if (!isOpen) return;

    const clientPortfolios = portfolios.map((portfolio) => {
      return <PortfolioRow key={portfolio.id} {...{ portfolio }} />;
    });

    return (
      <div className="platform-content platform-content--spacing-top platform-content--border-top-mc">
        <div className="frow">{isLoading ? <Loading flexDirection="column" /> : clientPortfolios}</div>
      </div>
    );
  }

  function renderPostingOverlay() {
    if (isPosting) {
      const overlayStyles = {
        backgroundColor: '#5d5f6b',
        bottom: 0,
        left: 0,
        opacity: 0.7,
        position: 'absolute' as any,
        right: 0,
        top: 0,
      };
      return (
        <div style={overlayStyles}>
          <Loading flexDirection="column" height="100%" />
        </div>
      );
    }
  }

  return (
    <div className="col-mc-1-1" style={{ position: 'relative' }}>
      <div
        className="platform-content platform-content--padding-all platform-content--border-bottom-mc"
        onClick={toggleOpen}
        style={{ cursor: 'pointer' }}
      >
        <div className="frow frow--gutters">
          <div className="col-sm-1-12">
            <i className={`icon-add icon-animated icon-clickable icon-2x text-white ${isOpen && 'icon-rotate'}`} />
          </div>
          <div className="col-sm-3-12">
            <span className="small-uppercase">
              <span className="text-white">
                {name} ({reference})
              </span>
            </span>
          </div>
          <div className="col-sm-2-12">
            <span className="small-uppercase">
              Currency: <span className="text-white">{feeCurrency}</span>
            </span>
          </div>
          <div className="col-sm-2-12">
            <span className="small-uppercase">
              Invoiceable Reviews: <span className="text-white">{monitoringInvoiceableReviewCount}</span>
            </span>
          </div>
          <div className="col-sm-2-12">
            <span className="small-uppercase">
              Uninvoiced Reviews: <span className="text-white">{monitoringInvoiceableReviewTotalCount}</span>
            </span>
          </div>
          <div className="col-sm-2-12">{renderPost()}</div>
          {/*<div className="col-sm-2-12">*/}
          {/*  <span className="small-uppercase">*/}
          {/*    GBP Amount: <span className="text-white">TODO</span>*/}
          {/*  </span>*/}
          {/*</div>*/}
          {/*<div className="col-sm-2-12">*/}
          {/*  <span className="small-uppercase">*/}
          {/*    Client CCY Amount: <span className="text-white">TODO</span>*/}
          {/*  </span>*/}
          {/*</div>*/}
        </div>
        {renderPortfolios()}
      </div>
      {renderPostingOverlay()}
    </div>
  );
}
