import * as React from 'react';

import ClientTypeSplit from './clientServiceType/ClientTypeSplit';
import ServiceType from './clientServiceType/ServiceType';
import useRadioSelect from '../../../shared/customHooks/useRadioSelect';
import ClientPortalCard from '../../shared/ClientPortalCard';

export default function clientServiceType({ clientId, selectedDate, filterState }) {
  const [selectedChart, setSelectedChart] = React.useState<string>('client_type');

  const typeOptions = [
    { label: 'Client Type', value: 'client_type' },
    { label: 'Service Type', value: 'service_type' },
  ];

  const [radioToggle, selectedRadioRef] = useRadioSelect(typeOptions, 0);
  React.useEffect(() => setSelectedChart(typeOptions[selectedRadioRef.current].value), [selectedRadioRef.current]);

  const titleControls = <>{radioToggle}</>;

  return (
    <ClientPortalCard title="Client / Service Type Split" titleControls={titleControls}>
      <>
        <div style={{ display: selectedChart === 'client_type' ? 'block' : 'none' }}>
          <ClientTypeSplit {...{ clientId, selectedDate, filterState: filterState('client_type') }} />
        </div>
        <div style={{ display: selectedChart === 'service_type' ? 'block' : 'none' }}>
          <ServiceType {...{ clientId, selectedDate, filterState: filterState('service_type') }} />
        </div>
      </>
    </ClientPortalCard>
  );
}
