import * as React from 'react';

import useVisibility from 'components/shared/customHooks/useVisibility';
import Alert from 'components/shared/Alert';
import Button from 'components/shared/forms/Button';
import CustomModal from 'components/shared/CustomModal';
import FormRow from 'components/shared/forms/FormRow';
import { createAlert, graphitiErrorString } from 'components/shared/Utils';

import { User } from 'javascript/models';
import Context from '../../Context';

export default function contactCreate() {
  const context = React.useContext(Context);

  const { client } = context.state;
  const { handleClientSelect } = context.actions;
  const { handleOpen, handleClose, isOpen } = useVisibility(false);

  const [errorMessage, setErrorMessage] = React.useState();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [firstName, setFirstname] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');

  function handleChange(event) {
    switch (event.target.name) {
      case 'first_name':
        setFirstname(event.target.value);
        return;
      case 'last_name':
        setLastName(event.target.value);
        return;
      case 'email':
        setEmail(event.target.value);
        return;
      default:
        return;
    }
  }

  const { id } = client;

  async function handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    const user = new User({ firstName, lastName, email, clientIds: [id] });
    const success = await user.save();
    if (success) {
      createAlert('success', 'New User Created', 1500);
      handleClose();
      setFirstname('');
      setLastName('');
      setEmail('');
      handleClientSelect(id);
    } else {
      setErrorMessage(graphitiErrorString(user));
      setIsSubmitting(false);
    }
  }

  return (
    <React.Fragment>
      <button className="button button--secondary button--compact" type="button" onClick={handleOpen}>
        Create New User
      </button>
      <CustomModal isOpen={isOpen} handleClose={handleClose} title="New User" modifiers={['dark']}>
        <div className="modal__content">
          <Alert type="error" title="Error on submission" message={errorMessage} />
          <form action="" className="form" onSubmit={handleSubmit}>
            <fieldset className="form__fieldset">
              <h4 className="form__fieldset-label">User Details</h4>
              <div className="frow frow--gutters">
                <FormRow>
                  <label htmlFor="first_name" className="form__label">
                    First Name
                  </label>
                  <input type="text" name="first_name" id="first_name" onChange={handleChange} value={firstName} />
                </FormRow>
                <FormRow>
                  <label htmlFor="last_name" className="form__label">
                    Last Name
                  </label>
                  <input type="text" name="last_name" id="last_name" onChange={handleChange} value={lastName} />
                </FormRow>
                <FormRow>
                  <label htmlFor="email" className="form__label">
                    Email
                  </label>
                  <input type="text" name="email" id="email" onChange={handleChange} value={email} />
                </FormRow>
              </div>
            </fieldset>
            <Button
              isDisabled={!firstName || !lastName || !email}
              isSubmitting={isSubmitting}
              submittingText="Creating"
              text="Create"
            />
          </form>
        </div>
      </CustomModal>
    </React.Fragment>
  );
}
