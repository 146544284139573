import * as React from 'react';

import ColorPicker from '../reportTemplates/template/theme/ColorPicker';

import CustomModal from 'components/shared/CustomModal';
import Button from 'components/shared/forms/Button';

import { graphitiErrorString } from 'components/shared/Utils';
import Alert from 'components/shared/Alert';

export default function newModal({ isOpen, handleClose, fetchData, reportTemplateBucket }) {
  const [color, setColor] = React.useState<{ hex: string }>({ hex: reportTemplateBucket.color });
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  function handleSubmit(event) {
    event.preventDefault();
    setErrorMessage('');
    setIsSubmitting(true);
    const formData = new FormData(event.target);
    handleCreate(formData).then((returnObject) => {
      setIsSubmitting(false);
      if (returnObject.success) {
        handleClose();
        fetchData();
      } else {
        setErrorMessage(graphitiErrorString(returnObject.reportTemplateBucket));
      }
    });
  }

  async function handleCreate(formData) {
    reportTemplateBucket.name = formData.get('report_template_bucket[name]');
    reportTemplateBucket.color = color.hex;
    const success = await reportTemplateBucket.save();
    return { success, reportTemplateBucket };
  }

  function handleColorChange(attribute, color) {
    setColor(color);
  }

  return (
    <div>
      <CustomModal handleClose={handleClose} isOpen={isOpen} title="New Report Template">
        <div className="modal__content">
          <Alert message={errorMessage} modifier="dark" title="Missing Fields" type="error" />
          <form className="form form--light" onSubmit={handleSubmit}>
            <div className="frow frow--gutters-2x">
              <div className="col-sm-1-1">
                <div className="frow frow--gutters">
                  <div className="col-mc-1-1">
                    <label htmlFor="name" className="form__label">
                      Name
                    </label>
                    <input
                      type="text"
                      name="report_template_bucket[name]"
                      id="report_template_bucket_name"
                      defaultValue={reportTemplateBucket.name}
                    />
                  </div>
                  <div className="col-mc-1-1">
                    <ColorPicker
                      disabled={false}
                      color={color}
                      label="Color"
                      attribute="color"
                      handleColorChange={handleColorChange}
                    />
                    <input
                      type="hidden"
                      name="report_template_bucket[color]"
                      id="report_template_bucket_color"
                      value={color ? color.hex : ''}
                    />
                  </div>
                </div>
              </div>
              <div className="col-mc-1-1">
                <Button
                  buttonClasses="button--full-width button--light-themed"
                  style={{ minWidth: 'unset' }}
                  text="Create"
                  isSubmitting={isSubmitting}
                  submittingText="Creating"
                />
              </div>
            </div>
          </form>
        </div>
      </CustomModal>
    </div>
  );
}
