import React from 'react';

import { Client, User } from 'javascript/models';

import FormRow from 'components/shared/forms/FormRow';
import ReactSelect from 'components/shared/forms/ReactSelect';
import Loading from 'components/shared/Loading';

interface IClientSelection {
  user: User;
}

const ClientSelection = ({ user }: IClientSelection) => {
  const [clients, setClients] = React.useState<Client[]>();

  const getClients = async () => {
    const { data } = await Client.all();
    setClients(data);
  };

  React.useEffect(() => {
    getClients();
  }, []);

  const handleSelectChange = async (selectedOptions) => {
    if (!selectedOptions) return;

    const clientIds = selectedOptions.map((option) => option.value);
    user.clientIds = clientIds;
    await user.save({ displayQueryResult: true });
  };

  if (!clients) return <Loading />;

  const clientOptions =
    clients &&
    clients.map((client) => ({
      label: client.name,
      value: client.id,
    }));

  const defaultClientSelections =
    user.clientIds && clientOptions.filter((clientOption) => user.clientIds.includes(clientOption.value));

  return (
    <div className="mar-b-3">
      <h4 className="form__fieldset-label">Clients</h4>
      <FormRow
        className="col-mc-1-1"
        note="Selecting more than one client will automatically mark this user as a counterparty and they can be
            selected as a contact for all of the clients specified."
      >
        <ReactSelect
          options={clientOptions}
          theme="dark"
          id="client_ids"
          name="client_ids"
          handleChange={handleSelectChange}
          isMulti
          defaultValues={defaultClientSelections}
        />
      </FormRow>
    </div>
  );
};

export default ClientSelection;
