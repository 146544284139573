import * as React from 'react';

import { differenceInCalendarDays, differenceInHours, differenceInMinutes, parse } from 'date-fns';
import pluralize from 'pluralize';
import { IAlertArgs } from './Toast';

const classNames = require('classnames');
const numeral = require('numeral');

export function ajaxErrorString(jqXhr) {
  if (jqXhr.status === 0) {
    return 'The request was blocked by the browser. Are you using an ad blocker?';
  }
  if (jqXhr.status === 500) {
    return 'Unknown server error';
  }
  if (jqXhr.responseJSON && jqXhr.responseJSON.errors) {
    return jqXhr.responseJSON.errors.map((e) => e.title).join(', ');
  }
  if (jqXhr.status === 401) {
    return jqXhr.responseJSON.message;
  }
  return 'Unknown server error';
}

// Taken from:
// https://gist.github.com/kares/740162
export function simpleFormat(str) {
  const simpleFormatRE1 = /\r\n?/g;
  const simpleFormatRE2 = /\n\n+/g;
  const simpleFormatRE3 = /([^\n]\n)(?=[^\n])/g;
  let fstr = str;
  fstr = fstr.replace(simpleFormatRE1, '\n'); // \r\n and \r -> \n
  fstr = fstr.replace(simpleFormatRE2, '</p>\n\n<p>'); // 2+ newline  -> paragraph
  fstr = fstr.replace(simpleFormatRE3, '$1<br/>'); // 1 newline   -> br
  fstr = `<p>${fstr}</p>`;
  return fstr;
}

export function graphitiErrorString(resource) {
  const messageArray = Object.keys(resource.errors).map((key) => resource.errors[key].fullMessage);

  return messageArray
    .map((message, index) => {
      if (index === 0) {
        return message;
      }
      if (messageArray.length > 1 && index === messageArray.length - 1) {
        return ` and ${message.toLowerCase()}`;
      }
      return `, ${message.toLowerCase()}`;
    })
    .join('');
}

export function changeFromPrevious(current, previous, format = '0,0.0000', suffix = '%') {
  const change = current - previous;
  const metricClasses = classNames(
    'metric__change',
    { 'metric__change--positive': change > 0 },
    { 'metric__change--negative': change < 0 },
  );

  if (change > 0) {
    return (
      <div className={metricClasses}>
        <i className="icon-chevron-up icon-fw icon-0-5x mar-r-1" />
        {numeral(change).format(format)}
        {suffix}
      </div>
    );
  }
  return (
    <div className={metricClasses}>
      <i className="icon-chevron-down icon-fw icon-0-5x mar-r-1" />
      {numeral(Math.abs(change)).format(format)}
      {suffix}
    </div>
  );
}

export function createAlert(
  type: IAlertArgs['type'],
  content: IAlertArgs['content'],
  autoClose: IAlertArgs['autoClose'],
) {
  const alertArgs: IAlertArgs = { content, type, autoClose };
  const customEvent = new CustomEvent('alerts:new', { detail: alertArgs });
  document.dispatchEvent(customEvent);
}

export function daysAgoInWords(date) {
  const difference = differenceInCalendarDays(new Date(), parse(date));

  if (difference < 1) {
    const differenceHours = differenceInHours(new Date(), parse(date));
    if (differenceHours < 1) {
      const differenceMinutes = differenceInMinutes(new Date(), parse(date));
      if (differenceMinutes === 0) {
        return 'Just Now';
      }
      return `${pluralize('minute', differenceMinutes, true)} ago`;
    }
    return `${pluralize('hour', differenceHours, true)} ago`;
  }
  return `${pluralize('day', difference, true)} ago`;
}

export function round_to_precision(x, precision) {
  const y = +x + (precision === undefined ? 0.5 : precision / 2);
  return y - (y % (precision === undefined ? 1 : +precision));
}

// We only need to go to 5 because this is used for the ratings
export const humanizeNumber = (numberToHumanize) => {
  const roundedNumber = Math.round(numberToHumanize);
  switch (roundedNumber) {
    case 1:
      return 'one';
    case 2:
      return 'two';
    case 3:
      return 'three';
    case 4:
      return 'four';
    case 5:
      return 'five';
    default:
      return roundedNumber;
  }
};
