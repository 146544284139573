import * as React from 'react';

import { ReportTemplatesReportTemplateSection } from 'javascript/models';

export default function comparison({ large, showHeaders, returnOptions }) {
  const allOptions = ReportTemplatesReportTemplateSection.barChartSubOptions;

  const headerReturnOptions = returnOptions.map((returnOption) => {
    const humanizedOption = allOptions.find((option) => option.value === returnOption);
    return <th key={returnOption}>{humanizedOption.label}</th>;
  });

  const headers = (
    <thead>
      <tr>
        <th />
        {headerReturnOptions}
      </tr>
    </thead>
  );

  return (
    <table className={`pdf-table pdf-table--striped ${large && 'pdf-table--large'}`}>
      {showHeaders && headers}
      <tbody>
        <tr>
          <td>Portfolio</td>
          {returnOptions.map((option, index) => (
            <td key={index}>value</td>
          ))}
        </tr>
        <tr>
          <td>Benchmark</td>
          {returnOptions.map((option, index) => (
            <td key={index}>value</td>
          ))}
        </tr>
        <tr>
          <td>Value Added</td>
          {returnOptions.map((option, index) => (
            <td key={index}>value</td>
          ))}
        </tr>
      </tbody>
    </table>
  );
}
