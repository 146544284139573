import { Attr, BelongsTo, Model } from 'spraypaint';
import { ApplicationRecord } from './ApplicationRecord';

import { Client } from './Client';

@Model()
export class ClientLetterOfEngagement extends ApplicationRecord {
  public static jsonapiType = 'client_letter_of_engagements';
  @Attr() public signatureDate: Date;
  @Attr() public clientId: string;
  @Attr() public filename: string;
  @Attr() public createdAt: Date;

  @BelongsTo() public client: Client;
}
