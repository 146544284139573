import * as React from 'react';
import * as $ from 'jquery';

import columns from './table/columns';
import Table from 'components/shared/reactTable/Table';

interface IProps {
  includes: string[];
  path: string;
  searches: string[];
}

export default function table(props: IProps) {
  const { includes, path, searches } = props;
  const [isLoading, setIsLoading] = React.useState();
  const [data, setData] = React.useState([]);
  const [meta, setMeta] = React.useState(null);
  const [pageSize, setPageSize] = React.useState<number>(20);
  const [page, setPage] = React.useState<number>(1);

  React.useEffect(() => {
    $.ajax({
      beforeSend: () => setIsLoading(true),
      cache: false,
      data: { searches, includes, page, pageSize },
      dataType: 'json',
      success: (response: any) => {
        setMeta(response.meta);
        setData(response.data);
        setIsLoading(false);
      },
      type: 'GET',
      url: path,
    });
  }, [page, pageSize]);

  function handlePageSizeChange(pageSize: number) {
    setPageSize(pageSize);
  }

  function handlePageChange(page: number) {
    setPage(page);
  }

  return (
    <React.Fragment>
      <Table
        columns={columns()}
        data={data}
        handlePageChange={handlePageChange}
        handlePageSizeChange={handlePageSizeChange}
        // handleSort={handleSort}
        manual
        meta={meta}
        isLoading={isLoading}
      />
    </React.Fragment>
  );
}
