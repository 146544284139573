import * as React from 'react';
import * as $ from 'jquery';

import lodashReject from 'lodash.reject';

import {
  investmentPolicyStatementPlatformPortfolioPath,
  platformPortfolioPath,
} from 'javascript/application/ts_routes';

import Alert from 'components/shared/Alert';
import CsrfToken from 'components/shared/CsrfToken';
import FormFilesDropzone, { IDropzoneFile } from 'components/shared/forms/FormFilesDropzone';
import CustomModal from 'components/shared/CustomModal';
import { ajaxErrorString, createAlert } from 'components/shared/Utils';

import useVisibility from 'components/shared/customHooks/useVisibility';

import Submitting from '../form/Submitting';

import Button from 'components/shared/forms/Button';

interface IUseInvestmentPolicyStatementsModalProps {
  portfolio: any;
  hideSave: boolean;
}

const useInvestmentPolicyStatementsModal = (props: IUseInvestmentPolicyStatementsModalProps) => {
  const { portfolio, hideSave } = props;
  const [investmentPolicyStatementsState, setInvestmentPolicyStatementsState] = React.useState<IDropzoneFile[]>(
    portfolio.attributes.investmentPolicyStatements || [],
  );

  const { isOpen, handleOpen, handleClose } = useVisibility(false);

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const csrfToken = CsrfToken();

  const handleFormSubmission = (event) => {
    event.preventDefault();
    const form = event.target;

    const formData = new FormData(form);
    formData.append('authenticity_token', csrfToken);

    $.ajax({
      contentType: false,
      data: formData,
      dataType: 'json',
      processData: false,
      type: form.method,
      url: form.action,
      // I haven't fixed this linter error, as I think this order helps readability.
      beforeSend: () => setIsSubmitting(true),
      complete: () => setIsSubmitting(false),
      error: (jqXhr) => {
        setErrorMessage(ajaxErrorString(jqXhr));
        $('.modal__content').animate({ scrollTop: 0 }, 'fast');
      },
      success: (response) => {
        createAlert('success', 'Attachments saved.', 1500);
        window.location.reload();
      },
    });
  };

  const handleIPSDelete = (investmentPolicyStatementFile) => {
    const data: FormData = new FormData();
    data.append('authenticity_token', csrfToken);
    // if there are no files saved to the DB or the chosen file is not in th DB,
    // then only remove the selected file from state.
    if (
      !portfolio.attributes.investmentPolicyStatements ||
      !portfolio.attributes.investmentPolicyStatements.includes(investmentPolicyStatementFile)
    ) {
      const newIpsFiles = investmentPolicyStatementsState.filter((file) => file !== investmentPolicyStatementFile);
      setInvestmentPolicyStatementsState(newIpsFiles);
      return;
    }

    $.ajax({
      contentType: false,
      data,
      dataType: 'json',
      method: 'DELETE',
      processData: false,
      url: investmentPolicyStatementPlatformPortfolioPath(portfolio.id, investmentPolicyStatementFile.id),
      // I haven't fixed this linter error, as I think this order helps readability.
      beforeSend: () => setIsSubmitting(true),
      complete: () => setIsSubmitting(false),
      error: (jqXhr) => {
        setErrorMessage(ajaxErrorString(jqXhr));
        $('.modal__content').animate({ scrollTop: 0 }, 'fast');
      },
      success: (response) => {
        setInvestmentPolicyStatementsState((previousState) =>
          lodashReject(previousState, { id: investmentPolicyStatementFile.id }),
        );
        createAlert('success', response.successs, 1500);
      },
    });
  };

  const DisplaySave = () => {
    if (hideSave) return;

    return (
      <div className="frow">
        <Button
          inputType="submit"
          isSubmitting={isSubmitting}
          submittingText="Saving"
          text="Save"
          isDisabled={isSubmitting || investmentPolicyStatementsState.length < 1}
        />
      </div>
    );
  };

  const handleStoreFiles = (files) => {
    setInvestmentPolicyStatementsState((prev) => [...prev, ...files]);
  };

  const jsx = (
    <CustomModal isOpen={isOpen} handleClose={handleClose} title="Investment Policy Statements">
      <div className="modal__content">
        <div className="frow">
          <Alert type="error" title="Error on submission" message={errorMessage} />
        </div>
        <form method="POST" action={platformPortfolioPath(portfolio.attributes.id)} onSubmit={handleFormSubmission}>
          <input type="hidden" name="_method" value="patch" />
          <div className="frow">
            <FormFilesDropzone
              inputName="portfolio[investment_policy_statements][]"
              inputLabel="Investment Policy Statements"
              storedFiles={investmentPolicyStatementsState}
              onDetatchFile={handleIPSDelete}
              updateStoredFiles={handleStoreFiles}
            >
              <DisplaySave />
            </FormFilesDropzone>
          </div>
        </form>
      </div>
      <Submitting {...{ isSubmitting }} />
    </CustomModal>
  );

  return [handleOpen, jsx];
};

export default useInvestmentPolicyStatementsModal;
