import * as React from 'react';
import { SpraypaintBase, Scope } from 'spraypaint';

import { identity } from 'ramda';

import { UnPromisify } from 'javascript/helpers/customUtilityTypes';

type TSpraypaintQueryResult<
  Model extends SpraypaintBase,
  LocalScope extends Scope<Model>,
  Method extends 'first' | 'all'
> = UnPromisify<ReturnType<LocalScope[Method]>>;

type TQueryResult<
  Model extends SpraypaintBase,
  LocalScope extends Scope<Model>,
  Method extends 'first' | 'all'
> = TSpraypaintQueryResult<Model, LocalScope, Method> & { meta: { isLoading: boolean } };

const useSpraypaintQueryCore = <
  Model extends SpraypaintBase,
  LocalScope extends Scope<Model>,
  Method extends 'first' | 'all'
>(
  scope: LocalScope,
  method: Method,
  defaultValue: TSpraypaintQueryResult<Model, LocalScope, Method> = {
    data: undefined,
    meta: { stats: undefined },
  } as any,
  wrapResult: (response: typeof defaultValue) => typeof defaultValue = identity,
): TQueryResult<Model, LocalScope, Method> => {
  const defaultWithMeta = { ...defaultValue } as any;
  defaultWithMeta.meta = defaultWithMeta.meta || {};

  const [isLoading, setIsLoading] = React.useState(true);
  const [queryResult, setQueryResult] = React.useState<TQueryResult<Model, LocalScope, Method>>(
    wrapResult(defaultWithMeta) as any,
  );

  const resolveQuery = async () => {
    setIsLoading(true);

    const { data, meta, raw, _raw_json, _collection }: any = await scope[method]();
    const result = { data, meta, raw, _raw_json, _collection } as any;
    setQueryResult(wrapResult(result) as any);

    setIsLoading(false);
  };

  React.useEffect(() => {
    resolveQuery();
  }, [scope, method]);

  queryResult.meta.isLoading = isLoading;

  return queryResult;
};

const useSpraypaintQueryFirst = <Model extends SpraypaintBase>(scope: Scope<Model>, defaultValue?, wrapResult?) =>
  useSpraypaintQueryCore(scope, 'first', defaultValue, wrapResult);

const useSpraypaintQueryAll = <Model extends SpraypaintBase>(scope: Scope<Model>, defaultValue?, wrapResult?) =>
  useSpraypaintQueryCore(scope, 'all', defaultValue, wrapResult);

export { useSpraypaintQueryFirst, useSpraypaintQueryAll };
