import { useEffect, useState } from 'react';
import { SpraypaintBase } from 'spraypaint';

import useForm from './useForm';

const useGraphitiForm = <TResource extends SpraypaintBase>(defaultResource: TResource, callback) => {
  const { values, handleChange, handleManualChange, handleSubmit, resetValues } = useForm(callback);
  const [resource, setResource] = useState(defaultResource);

  useEffect(() => {
    const newResource = resource.dup();
    Object.keys(values).forEach((key) => (newResource[key] = values[key]));
    setResource(newResource);
  }, [values]);

  const resetResource = () => {
    setResource(defaultResource);
    resetValues();
  };

  return {
    handleChange,
    handleManualChange,
    handleSubmit,
    resetResource,
    resource,
  };
};

export default useGraphitiForm;
