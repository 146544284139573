import { Attr, Model } from 'spraypaint';
import { ApplicationRecord } from './ApplicationRecord';

@Model()
export class ExtraSetting extends ApplicationRecord {
  public static jsonapiType = 'extra_settings';
  @Attr() public description: string;
  @Attr() public extraSettingsType: string;
  @Attr() public extraSettingsTypeId: string;
  @Attr() public portfolioId: string;
}
