import * as React from 'react';

import RoleSelector from 'components/platform/users/form/RoleSelector';

const columns = ({ roleable }) => [
  {
    Header: 'Name',
    Cell: (row) => <div>{row.original.fullName}</div>,
  },
  {
    Header: 'Role Name',
    Cell: (row) => <RoleSelector {...{ roleable, user: row.original }} />,
  },
];

export default columns;
