import * as React from 'react';

import lodashStartcase from 'lodash.startcase';

import Table from 'components/shared/reactTable/Table';

import { platformClientNoPath } from 'javascript/application/ts_routes';

export default function table({ data }) {
  const [tableData, setTableData] = React.useState(data);

  const columns = Object.keys(tableData[0]).map((attribute) => {
    return {
      Header: lodashStartcase(attribute),
      accessor: attribute,
      sortable: false,
    };
  });

  columns.push({
    Cell: (row) => {
      return (
        <a href={platformClientNoPath(row.original.Client_No)} style={{ display: 'block' }}>
          <div className="rt-tr-clickable-icon">
            <i className="icon-arrow-right-top icon-0-8x" />
          </div>
        </a>
      );
    },
    Header: '',
    sortable: false,
  } as any);

  return <Table columns={columns} data={data} isLoading={false} hasPagination={false} hasColumnSelector={false} />;
}
