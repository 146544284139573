import * as React from 'react';

import { PersistedSpraypaintRecord } from 'spraypaint';

import GraphitiTableWrapper from 'components/shared/GraphitiTableWrapper';

import columns from '../../portfolios/table/columns';
import { IRevisedColumn } from 'components/shared/reactTable/utilities/processColumns';

import { Portfolio } from 'javascript/models';

interface IProps {
  clientTypes: string[];
  currencies: string[];
  riskOptions: string[];
  statuses: string[];
  reportTemplateId: string;
}

export default function table(props: IProps) {
  const { clientTypes, currencies, riskOptions, reportTemplateId, statuses } = props;

  const scope = Portfolio.includes(['manager', 'entity', 'client']).where({
    report_template_id: { eq: reportTemplateId },
  });

  const formatting = {
    clientName: 'eval',
    clientType: 'humanize',
    currency: 'upcase',
    entity: 'eval',
    managerName: 'eval',
    risk: 'humanize',
    status: 'humanize',
  };

  const newColumns = columns(currencies, clientTypes, riskOptions, statuses) as IRevisedColumn<
    PersistedSpraypaintRecord<Portfolio>
  >[];

  return (
    <GraphitiTableWrapper
      scope={scope}
      columns={newColumns}
      defaultFilters={{ status: statuses.filter((status) => status !== 'terminated') }}
      formatting={formatting}
      hasHeader
      isSearchable
      title="Linked Portfolios"
      identifier="reportTemplates:portfolios:index"
    />
  );
}
