import React from 'react';

import { and, compose, groupBy, isEmpty, not, prop, reduce, toPairs } from 'ramda';

import { Button, Classes, PopoverPosition, Popover } from '@blueprintjs/core';

import { User, Client } from 'javascript/models';

import { useSpraypaintQueryFirst } from 'components/shared/customHooks/useSpraypaintQuery';
import { Frow, Column } from 'components/frow';
import Loading from 'components/shared/Loading';
import { objOptionsGenerator } from 'components/shared/forms/ReactSelect';

import useStateSetAttr from 'components/shared/customHooks/useStateSetAttr';
import useReactSelect from 'components/shared/customHooks/useReactSelect';

const ChangePortfoliosContacts = (props) => {
  const { clientId, excludedUserId, contactAttribute, portfolios } = props;

  const scope: any = Client.includes(['users']).where({
    id: clientId,
    users: { id: { not_eq: excludedUserId }, discarded: false },
  });

  const {
    data: client,
    meta: { isLoading },
  } = useSpraypaintQueryFirst(scope.memo(clientId)());

  const options = isLoading ? [] : objOptionsGenerator('fullName')(client.users);

  const [selectedOptions, selectJSX] = useReactSelect({ theme: 'dark', options });

  useStateSetAttr(portfolios, contactAttribute, selectedOptions);

  if (isLoading) return <Loading />;

  return (
    <Frow itemAlignment="baseline" style={{ marginBottom: '5px' }}>
      <Column columnSpan={1}>{client.name}</Column>
      <Column columnSpan={2}>{selectJSX}</Column>
    </Frow>
  );
};

const UserDeactivationContents = ({ userId }) => {
  const userScope: any = User.includes(['emapPortfolios', 'primaryContactPortfolios']).where({
    id: userId,
  });

  const {
    data: user,
    meta: { isLoading },
  } = useSpraypaintQueryFirst(userScope.memo(userId)());

  if (isLoading) return <Loading />;

  const groupByClient = compose(toPairs, groupBy(prop('clientId')));

  const [groupedPrimaryPortfolios, groupedEmapPortfolios] = [user.primaryContactPortfolios, user.emapPortfolios].map(
    groupByClient,
  );

  const changeContactsJSXGen = (groups, label, props) =>
    groups.map(([clientId, group]) => (
      <Column key={clientId} style={{ marginBottom: '10px' }}>
        <Frow contentAlignment="around">
          <Column>
            <b>{label}</b>
          </Column>
          <Column>
            <ChangePortfoliosContacts {...{ clientId, ...props, portfolios: group }} />
          </Column>
        </Frow>
      </Column>
    ));

  const primaryContactsJSX = changeContactsJSXGen(groupedPrimaryPortfolios, 'Primary Contact', {
    contactAttribute: 'tempPrimaryContacts',
    excludedUserId: user.id,
  });

  const emapContactsJSX = changeContactsJSXGen(groupedEmapPortfolios, 'EMAP Contacts', {
    contactAttribute: 'tempEmapUsers',
    excludedUserId: user.id,
  });

  const notEmpty = compose(not, isEmpty);

  const saveUser = async () => {
    user.emapPortfolios.map(
      (portfolio) => (portfolio.emapUsers = [...portfolio.emapUsers, ...portfolio.tempEmapUsers]),
    );
    user.primaryContactPortfolios.map((portfolio) => (portfolio.primaryContact = portfolio.tempPrimaryContacts[0]));

    const saveNewContacts = (portfolios, associations) =>
      portfolios.map((portfolio) => portfolio.save({ with: associations, displayQueryResult: 'onError' }));

    const userChanges = await Promise.all([
      ...saveNewContacts(user.primaryContactPortfolios, 'primaryContact.id'),
      ...saveNewContacts(user.emapPortfolios, 'emapUsers.id'),
    ]);

    if (reduce(and, true, userChanges)) user.destroy(true);
  };

  return (
    <Frow contentAlignment="between">
      {notEmpty(groupedPrimaryPortfolios) && primaryContactsJSX}
      {notEmpty(groupedEmapPortfolios) && emapContactsJSX}
      <Column>
        <Frow justifyContent="around">
          <Button className={Classes.POPOVER_DISMISS} text="Cancel" />
          <Button className={Classes.POPOVER_DISMISS} onClick={saveUser} text="Confirm" />
        </Frow>
      </Column>
    </Frow>
  );
};

const DeactivateUserButton = ({ userId }) => (
  <Popover
    interactionKind="click"
    boundary="viewport"
    position={PopoverPosition.AUTO_END}
    popoverClassName="bp3-dark"
    portalContainer={document.body} //Required to prevent a bug where the popper v1.16.0 tries to access positional properties before the element exists on the page
    modifiers={{ flip: { enabled: true }, preventOverflow: { enabled: true } }}
  >
    <Button intent="primary" className="button button--secondary button--compact" text="Deactivate" />
    <div style={{ padding: '15px' }}>
      <UserDeactivationContents userId={userId} />
    </div>
  </Popover>
);

export default DeactivateUserButton;
