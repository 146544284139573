import { Attr, BelongsTo, Model } from 'spraypaint';
import { ApplicationRecord } from './ApplicationRecord';
import { Portfolio } from './Portfolio';

@Model()
export class PortfolioDocument extends ApplicationRecord {
  public static jsonapiType = 'portfolio_documents';
  @Attr() public signatureDate: Date;
  @Attr() public portfolioId: string;
  @Attr() public filename: string;
  @Attr() public createdAt: Date;

  @BelongsTo() public portfolio: Portfolio;
}
