import * as React from 'react';

import Table from 'components/shared/reactTable/Table';
import columns from './columns';

export interface ITable {
  data: any;
  enlargened?: boolean;
}

export default function table({ data, enlargened }) {
  const modalTableModifiers = {
    className: '--sticky-header --sticky-header-large',
    data: data.reverse(),
  };

  const defaultTableProps = {
    className: '-sticky-header -sticky-header-small',
    columns: columns(),
    data: data,
    hasColumnSelector: false,
    hasPagination: false,
    manual: false,
    meta: { count: data.length },
    pageSize: data.length,
  };

  const tableProps = enlargened ? { ...defaultTableProps, ...modalTableModifiers } : defaultTableProps;

  return <Table {...tableProps} />;
}

table.defaultProps = {
  enlargened: false,
};
