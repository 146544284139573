import { Attr, Model, BelongsTo } from 'spraypaint';
import { ApplicationRecord } from './ApplicationRecord';
import { Portfolio } from './Portfolio';
import { Administrator } from './Administrator';

@Model()
export class DataNote extends ApplicationRecord {
  public static jsonapiType = 'data_notes';
  @Attr() public body: string;
  @Attr() public portfolioId: number;
  @Attr() public authorId: number;
  @Attr() public createdAt: Date;

  @BelongsTo() public portfolio: Portfolio;
  @BelongsTo(Administrator) public author: Administrator;
}
