import * as React from 'react';

export default function ratingInfo() {
  return (
    <ul className="pdf-list pdf-list--content">
      <li className="pdf-list__item">
        <table className="pdf-hidden-table">
          <tbody>
            <tr>
              <td className="text-uppercase pdf-list__item-key" style={{ display: 'inline-block', width: '35%' }}>
                5
              </td>
              <td className="pdf-list__item-value" style={{ width: '65%' }}>
                The investment process has significantly enhanced the risk adjusted performance of the portfolio.
              </td>
            </tr>
          </tbody>
        </table>
      </li>
      <li className="pdf-list__item">
        <table className="pdf-hidden-table">
          <tbody>
            <tr>
              <td className="text-uppercase pdf-list__item-key" style={{ display: 'inline-block', width: '35%' }}>
                4
              </td>
              <td className="pdf-list__item-value" style={{ width: '65%' }}>
                The investment process has enhanced the risk adjusted performance of the portfolio.
              </td>
            </tr>
          </tbody>
        </table>
      </li>
      <li className="pdf-list__item">
        <table className="pdf-hidden-table">
          <tbody>
            <tr>
              <td className="text-uppercase pdf-list__item-key" style={{ display: 'inline-block', width: '35%' }}>
                3
              </td>
              <td className="pdf-list__item-value" style={{ width: '65%' }}>
                The investment process has produced reasonable risk adjusted performance.
              </td>
            </tr>
          </tbody>
        </table>
      </li>
      <li className="pdf-list__item">
        <table className="pdf-hidden-table">
          <tbody>
            <tr>
              <td className="text-uppercase pdf-list__item-key" style={{ display: 'inline-block', width: '35%' }}>
                2
              </td>
              <td className="pdf-list__item-value" style={{ width: '65%' }}>
                The investment process has produced poor risk adjusted performance.
              </td>
            </tr>
          </tbody>
        </table>
      </li>
      <li className="pdf-list__item">
        <table className="pdf-hidden-table">
          <tbody>
            <tr>
              <td className="text-uppercase pdf-list__item-key" style={{ display: 'inline-block', width: '35%' }}>
                1
              </td>
              <td className="pdf-list__item-value" style={{ width: '65%' }}>
                The investment process has produced very poor risk adjusted performance.
              </td>
            </tr>
          </tbody>
        </table>
      </li>
      <li className="pdf-list__item">
        <table className="pdf-hidden-table">
          <tbody>
            <tr>
              <td className="text-uppercase pdf-list__item-key" style={{ display: 'inline-block', width: '35%' }}>
                Suspended
              </td>
              <td className="pdf-list__item-value" style={{ width: '65%' }}>
                The rating is suspended.
              </td>
            </tr>
          </tbody>
        </table>
      </li>
      <li className="pdf-list__item">
        <table className="pdf-hidden-table">
          <tbody>
            <tr>
              <td className="text-uppercase pdf-list__item-key" style={{ display: 'inline-block', width: '35%' }}>
                N/A
              </td>
              <td className="pdf-list__item-value" style={{ width: '65%' }}>
                It is not possible to provide a rating based on risk adjusted performance as there is insufficient
                historic data.
              </td>
            </tr>
          </tbody>
        </table>
      </li>
      <li className="pdf-list__item">
        <table className="pdf-hidden-table">
          <tbody>
            <tr>
              <td className="text-uppercase pdf-list__item-key" style={{ display: 'inline-block' }}>
                <span>Benchmark Used</span>
              </td>
              <td className="pdf-list__item-value" style={{ width: '65%' }}>
                Benchmark used to generate the rating
              </td>
            </tr>
          </tbody>
        </table>
      </li>
    </ul>
  );
}
