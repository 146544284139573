import * as React from 'react';
import Context from '../Context';

export default function divider({ templateSection }) {
  const width = templateSection ? templateSection.width : 100;
  const context = React.useContext(Context);
  const { reportTemplate } = context.state;

  let color = '#e4e4e8';
  if (reportTemplate && reportTemplate.reportTemplateTheme && templateSection) {
    if (templateSection.color === 'primary') {
      color = reportTemplate.reportTemplateTheme.primaryColor;
    } else if (templateSection.color === 'secondary') {
      color = reportTemplate.reportTemplateTheme.secondaryColor;
    } else if (templateSection.color === 'tertiary') {
      color = reportTemplate.reportTemplateTheme.tertiaryColor;
    }
  }

  return <div className="pdf-divider pdf-divider--section" style={{ borderColor: color, width: `${width}%` }} />;
}
