import * as React from 'react';

import { useDraggable } from 'components/shared/customHooks/useDraggable';
import TrackedJob from './TrackedJob';

export default function presenter({ handleClick, isShowing, outstandingJobs, trackedJobs }) {
  const { position, isDragging, handleMouseDown } = useDraggable();

  const trackedJobsItems = trackedJobs.map((job) => <TrackedJob key={job.id} {...{ job }} />);

  const trackerStyles = {
    bottom: isShowing ? '20px' : 0,
    cursor: isDragging ? 'grabbing' : 'grab',
    transform: `translate(${position.x}px, ${position.y}px)`,
  };

  return (
    <div className="job-tracker" style={trackerStyles} onMouseDown={handleMouseDown}>
      <div className="job-tracker__header">
        <div className="frow frow--justify-between">
          <p className="subtitle-one mar-v-0">Running Jobs</p>
          <span className="text-small text-capitalize text-grey" style={{ cursor: 'pointer' }} onClick={handleClick}>
            {isShowing ? 'Hide' : 'Show'}
          </span>
        </div>
      </div>
      <table className={`job-tracker__table ${!isShowing && 'hidden'}`}>
        <thead>
          <tr>
            <th />
            <th>Name</th>
            <th>Started At</th>
          </tr>
        </thead>
        <tbody>{trackedJobsItems}</tbody>
      </table>
      <div className="text-white text-small pad-t-1 pad-b-2 pad-h-2">
        Total Outstanding tracked jobs: {outstandingJobs}
      </div>
    </div>
  );
}
