import * as React from 'react';
import lodashTruncate from 'lodash.truncate';
import * as ReactTooltip from 'react-tooltip';
import Button from 'components/shared/forms/Button';
import { simpleFormat } from 'components/shared/Utils';
import { sanitize } from 'dompurify';
import Context from '../../../Context';
import ReviewContext from './ReviewContext';
import Regenerate from './shared/Regenerate';

const classNames = require('classnames');

export default function Commentary() {
  const context = React.useContext(Context);
  const reviewContext = React.useContext(ReviewContext);
  const { portfolio } = context.props;
  const { review } = reviewContext.props;
  const { handleReviewUpdate } = reviewContext.actions;
  const {
    canEdit,
    commentary,
    commentaryAuthor,
    investmentRestrictionsCommentary,
    investmentRestrictionsCommentaryAuthor,
  } = review.attributes;
  const { commentariesEditPath } = review.links;

  const { reviewCommentaryType } = portfolio.attributes;

  const [isOpen, setIsOpen] = React.useState(false);

  const isDisabled = !canEdit;

  function handleClick() {
    setIsOpen(!isOpen);
  }

  function handleEditClick() {
    const newWindow = window.open(commentariesEditPath, '_blank');
    newWindow.onbeforeunload = () => {
      handleReviewUpdate();
    };
  }

  function renderCommentary() {
    if (!commentary) {
      return <p>No commentary has been generated</p>;
    }

    const truncated = simpleFormat(commentary).length > 800;

    let html = simpleFormat(commentary);
    if (truncated) {
      if (isOpen) {
        html = simpleFormat(commentary);
      } else {
        html = lodashTruncate(simpleFormat(commentary), { length: 800 });
      }
    }

    const readMore = () => {
      if (!truncated) return;

      const iconClasses = classNames('icon-arrow-down', 'icon-animated', 'icon-fw', 'icon-push-down-2', 'icon-1-3x', {
        'icon-flipped': isOpen,
      });

      return (
        <a className="link link--small link--bold" onClick={handleClick}>
          <span className="mar-r-1">{isOpen ? 'Read less' : 'Read more'}</span>
          <i className={iconClasses} />
        </a>
      );
    };

    return (
      <React.Fragment>
        <div className="frow frow--justify-between mar-v-2">
          <div className="col-md-3-5">
            <div
              className="text-wrap text-white"
              dangerouslySetInnerHTML={{
                __html: reviewCommentaryType === 'longform' ? sanitize(commentary) : sanitize(html),
              }}
            />
          </div>
          <div className="md-1-5" />
          <div className="col-md-1-5">
            <p className="mar-t-0 text-small">
              <span className="small-uppercase">Author</span>
              <br />
              <span className="text-white">{commentaryAuthor}</span>
            </p>
          </div>
        </div>
        {reviewCommentaryType !== 'longform' && readMore()}
      </React.Fragment>
    );
  }

  function renderInvestmentRestrictionsCommentary() {
    if (!investmentRestrictionsCommentary) {
      return <p className="text-wrap text-white">No investment restrictions commentary has been generated</p>;
    }

    return (
      <React.Fragment>
        <div className="frow frow--justify-between">
          <div className="col-md-3-5">
            <p className="text-wrap text-white">{investmentRestrictionsCommentary}</p>
          </div>
          <div className="md-1-5" />
          <div className="col-md-1-5">
            <p className="mar-t-0 text-small">
              <span className="small-uppercase">Author</span>
              <br />
              <span className="text-white">{investmentRestrictionsCommentaryAuthor}</span>
            </p>
          </div>
        </div>
      </React.Fragment>
    );
  }

  const tooltipTitle = isDisabled ? 'You cannot edit commentary at this stage' : '';

  return (
    <>
      <div className="platform-content platform-content--border-bottom platform-content--spacing-horizontal platform-content--padding-vertical">
        <div className="frow frow--items-center">
          <div className="frow frow--justify-between col-md-3-5 col-lg-4-5">
            <p className="platform-panel__title">Commentary</p>
            <Regenerate
              reviewId={review.attributes.id}
              reviewStatus={review.attributes.status}
              additionalClasses="button--icon-no-background"
            />
          </div>
          <div className="col-md-2-5 col-lg-1-5 text-right">
            <div data-tip={tooltipTitle}>
              <Button
                buttonClasses="button--secondary"
                hasIcon
                icon="arrow-right-top"
                isDisabled={isDisabled}
                text="Edit"
                handleClick={handleEditClick}
              />
              <ReactTooltip effect="solid" />
            </div>
          </div>
        </div>
        {renderCommentary()}
      </div>

      <div className="platform-content platform-content--border-bottom platform-content--spacing-horizontal platform-content--padding-vertical">
        <div className="frow frow--items-center">
          <div className="frow frow--justify-between col-md-3-5 col-lg-4-5">
            <p className="platform-panel__title">Investment Restrictions Commentary</p>
            <Regenerate
              reviewId={review.attributes.id}
              generateInvestmentRestrictionsCommentaryOnly
              reviewStatus={review.attributes.status}
              additionalClasses="button--icon-no-background"
            />
          </div>
          <div className="col-md-2-5 col-lg-1-5 text-right">
            <div data-tip={tooltipTitle}>
              <Button
                buttonClasses="button--secondary"
                hasIcon
                icon="arrow-right-top"
                isDisabled={isDisabled}
                text="Edit"
                handleClick={handleEditClick}
              />
              <ReactTooltip effect="solid" />
            </div>
          </div>
        </div>
        {renderInvestmentRestrictionsCommentary()}
      </div>
    </>
  );
}
