// Taken from:
// https://gist.github.com/kares/740162

const simpleFormatRE1 = /\r\n?/g;
const simpleFormatRE2 = /\n\n+/g;
const simpleFormatRE3 = /([^\n]\n)(?=[^\n])/g;
function simpleFormat(str, klass, style = null) {
  let fstr = str;
  fstr = fstr.replace(simpleFormatRE1, '\n'); // \r\n and \r -> \n
  fstr = fstr.replace(simpleFormatRE2, `</p>\n\n<p class='${klass}'>`); // 2+ newline  -> paragraph
  fstr = fstr.replace(simpleFormatRE3, '$1<br/>'); // 1 newline   -> br
  fstr = `<p class="${klass}" style="${style}">` + fstr + '</p>';
  return fstr;
}

export default simpleFormat;
