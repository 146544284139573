import * as React from 'react';

import { SubtitleOne, SubtitleTwo } from 'components/shared/Typography';
import Frow from '../../frow/Frow';

export interface IClientPortalCard {
  children: JSX.Element;
  title?: string;
  subTitle?: string;
  titleControls?: JSX.Element | (() => JSX.Element);
  titleControlsClassName?: string;
}

export default function clientPortalCard({
  title,
  subTitle,
  titleControls,
  titleControlsClassName = '',
  children,
}: IClientPortalCard) {
  return (
    <div className="card card--rounded card--dark card--full-height">
      <div className="platform-content platform-content--padding-all" style={{ borderBottom: '0.2rem solid black' }}>
        <Frow justifyContent="between" itemAlignment="center" gutterSize={1}>
          <SubtitleOne text={title} additionalClasses="text-white text-weight-500" style={{ margin: '2.5px 0' }} />
          <div className={titleControlsClassName}>
            {typeof titleControls === 'function' ? titleControls() : titleControls}
          </div>
        </Frow>
        <SubtitleTwo text={subTitle} additionalClasses="text-weight-500 mar-b-0" style={{ marginTop: '-1.1rem' }} />
      </div>
      <div className="platform-content platform-content--padding-all">{children}</div>
    </div>
  );
}
