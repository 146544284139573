import * as React from 'react';

const routes = require('../../../../../../javascript/application/routes.js');

import useAjaxRequest from 'components/shared/customHooks/useAjaxRequest';

import columns from './CurrencyColumns';
import Table from 'components/shared/reactTable/Table';

import Context from '../../Context';

export default function assets() {
  const context = React.useContext(Context);

  const { id } = context.props.portfolio.attributes;

  const [holdings, setHoldings] = React.useState([]);

  React.useEffect(() => {
    getHoldings();
  }, [id]);

  const [getHoldings, _isError, isLoading] = useAjaxRequest({
    cache: false,
    method: 'GET',
    path: routes.platform_portfolio_currency_holdings_path(id),
    stateSetter: setHoldings,
  });

  return (
    <div>
      <Table
        className="-sticky-header-large"
        columns={columns()}
        data={holdings}
        hasColumnSelector={false}
        hasPagination={false}
        isLoading={isLoading}
        manual={false}
        meta={{ count: holdings.length }}
      />
    </div>
  );
}
