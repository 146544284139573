import * as React from 'react';

import { snakeCase, startCase } from 'lodash';
import { format } from 'date-fns';
import PieChartTableLegend from '../PieChartTableLegend';
import ReactPieChart from '../../../../shared/charting/recharts/ReactPieChart';
import Loading from '../../../../shared/Loading';
import { Portfolio } from 'javascript/models/portal/Portfolio';
import IPieChartData from '../../../../shared/charting/recharts/interfaces/IPieChartData';
import stateToggle from '../shared/stateToggle';
import useChartFilter from '../../../../shared/customHooks/useChartFilter';

import { useClientPortalTheme } from 'components/shared/colours/themes/clientPortal';

const clientTypeSplit = ({ clientId, selectedDate, filterState }) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [meta, setMeta] = React.useState();
  const [selectedSegment, setSelectedSegment] = filterState;
  const [data, setData] = React.useState<IPieChartData[]>([]);

  useChartFilter(data, setData, 'name', selectedSegment);

  React.useEffect(() => {
    getPortfolios().then(() => setIsLoading(false));
  }, [selectedDate]);

  React.useEffect(() => {
    if (meta) getData();
  }, [meta]);

  React.useEffect(() => {
    if (data) setIsLoading(false);
  }, [data]);

  const getPortfolios = async () => {
    const formattedDate = format(selectedDate, 'YYYY-MM-DD');
    const scope = Portfolio.per(0)
      .where({
        review_date: formattedDate,
        status: 'live',
        client_id: clientId,
      })
      .extraParams({ selected_date: formattedDate })
      .stats({ count: 'total', percentage: ['monitoring', 'portfolio', 'consultancy'] });
    const { meta } = await scope.all();

    setMeta(meta);
  };

  const colourGen = useClientPortalTheme('clientType');

  const getData = () => {
    const serviceTypePercentages = meta.stats.percentage;
    const defaultData = ['monitoring', 'portfolio', 'consultancy'].map((type) => ({
      color: colourGen(type),
      name: startCase(type),
      value: serviceTypePercentages[type],
    }));
    setData(defaultData);
  };

  const handleSelect = React.useCallback(
    (slice) => {
      stateToggle(setSelectedSegment, false)(snakeCase(slice));
    },
    [selectedSegment],
  );

  const renderChart = () => {
    const legendProps = {
      data,
      description: 'Split of portfolios by client type with a review in the selected quarter.',
      headerTitle: 'Client Type',
    };

    const count = meta.stats.count.total;

    return (
      <div className="frow">
        <div className="col-lg-1-2">
          <ReactPieChart
            activeShape
            animationBegin={50}
            animationDuration={400}
            data={data}
            height={320}
            labelText={count}
            noData={count < 1}
            displayLegend={false}
            setSelectedSlice={handleSelect}
          />
        </div>
        <PieChartTableLegend {...legendProps} />
      </div>
    );
  };

  return isLoading || !meta ? <Loading /> : renderChart();
};

export default clientTypeSplit;
