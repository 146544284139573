import * as React from 'react';

import useVisibility from 'components/shared/customHooks/useVisibility';

const classNames = require('classnames');

interface IProps {
  closeDropdown: boolean;
  openDropdown: boolean;
  selectedModels: any;
  selectableModels: string[];
  handleSelectedModel(model: string);
}

export default function inclusionDropdown(props: IProps) {
  const { closeDropdown, handleSelectedModel, openDropdown, selectableModels, selectedModels } = props;

  React.useEffect(() => {
    if (openDropdown) {
      handleOpen();
    }
  }, [openDropdown]);

  React.useEffect(() => {
    handleClose();
  }, [closeDropdown]);

  const { handleClose, handleOpen, isOpen, toggleOpen } = useVisibility(false);

  const dropdownClasses = classNames('dropdown dropdown--floating', { 'dropdown--active': isOpen });

  const inclusionOptions = selectableModels.map((selection, index) => {
    function handleSelection() {
      handleSelectedModel(selection);
    }

    return (
      <div className="dropdown__nav-link" key={index}>
        <div className="pretty p-icon">
          <input type="checkbox" checked={selectedModels.includes(selection)} onChange={handleSelection} />
          <div className="state p-blue">
            <i className="icon-tick icon" />
            <label>{selection}</label>
          </div>
        </div>
      </div>
    );
  });

  const chevronClasses = classNames('icon-chevron-down', 'icon-fw', 'icon-animated', 'icon-tiny', {
    'icon-flipped': isOpen,
  });

  return (
    <div className={dropdownClasses}>
      <div className="dropdown__toggle" onClick={toggleOpen}>
        <div className="frow frow--items-center">
          <i className="icon-search icon-2x icon-fw mar-r-1" />
          <i className={chevronClasses} />
        </div>
      </div>
      <div className="dropdown__content dropdown__content--with-arrow pad-b-1" style={{ width: '260px' }}>
        <div className="dropdown__content-inner">
          <p className="subtitle-two mar-a-2">Include:</p>
          {inclusionOptions}
        </div>
      </div>
    </div>
  );
}
