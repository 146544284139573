import * as React from 'react';
import * as $ from 'jquery';

import Button from 'components/shared/forms/Button';
import CsrfToken from 'components/shared/CsrfToken';
import ReviewContext from '../ReviewContext';

export default function terminated() {
  const reviewContext = React.useContext(ReviewContext);
  const { updateStatusPath } = reviewContext.props.review.links;
  const { handleReviewUpdate } = reviewContext.actions;
  const event = 'back_from_terminated';

  function handleSubmit() {
    const reviewObject = { event };
    $.ajax({
      data: { review: reviewObject, authenticity_token: CsrfToken() },
      dataType: 'json',
      success: () => {
        handleReviewUpdate();
      },
      type: 'PATCH',
      url: updateStatusPath,
    });
  }

  return (
    <div>
      <Button
        buttonClasses="button--compact mar-r-1"
        handleClick={handleSubmit}
        style={{ minWidth: '120px' }}
        text="Re-enable"
      />
    </div>
  );
}
