import { Attr, Model } from 'spraypaint';
import { ApplicationRecord } from './ApplicationRecord';

@Model()
export class ReportTemplateBucket extends ApplicationRecord {
  public static jsonapiType = 'report_template_buckets';
  @Attr() public color: string;
  @Attr() public companyId: string;
  @Attr() public name: string;
  @Attr() public templateCount: number;
}
