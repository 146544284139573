import * as React from 'react';

export default function constituentValues({ large, showHeaders }) {
  const headers = (
    <thead>
      <tr>
        <th />
        <th>SAA</th>
        <th>TAA Min</th>
        <th>TAA Max</th>
        <th>Current</th>
        <th>Difference</th>
      </tr>
    </thead>
  );

  return (
    <table className={`pdf-table pdf-table--striped ${large && 'pdf-table--large'}`}>
      {showHeaders && headers}
      <tbody>
        <tr>
          <td className="pdf-table__row-label">Cash</td>
          <td>Value</td>
          <td>Value</td>
          <td>Value</td>
          <td>Value</td>
          <td>Value</td>
        </tr>
        <tr>
          <td className="pdf-table__row-label">Fixed Income</td>
          <td>Value</td>
          <td>Value</td>
          <td>Value</td>
          <td>Value</td>
          <td>Value</td>
        </tr>
        <tr>
          <td className="pdf-table__row-label">Equity</td>
          <td>Value</td>
          <td>Value</td>
          <td>Value</td>
          <td>Value</td>
          <td>Value</td>
        </tr>
        <tr>
          <td className="pdf-table__row-label">Hedge Fund</td>
          <td>Value</td>
          <td>Value</td>
          <td>Value</td>
          <td>Value</td>
          <td>Value</td>
        </tr>
        <tr>
          <td className="pdf-table__row-label">Other</td>
          <td>Value</td>
          <td>Value</td>
          <td>Value</td>
          <td>Value</td>
          <td>Value</td>
        </tr>
      </tbody>
    </table>
  );
}
