import * as React from 'react';

import classNames from 'classnames';
import { FileWithPath, useDropzone } from 'react-dropzone';
import Alert from '../Alert';
import uploadTextLarge from './dropzone/uploadTextLarge';
import uploading from './dropzone/uploading';
import uploadingLarge from './dropzone/uploadingLarge';
import uploadText from './dropzone/uploadText';

export interface IDropzoneProps {
  acceptedFiles?: string;
  alert?: { type: 'info' | 'success' | 'error' | 'warning'; message: string; title: string };
  disabled?: boolean;
  multiple?: boolean;
  fullHeight?: boolean;
  isLarge?: boolean;
  isSubmitting?: boolean;
  resetFiles?: boolean;
  shouldDiscardFiles?: boolean;
  shouldShowFiles?: boolean;
  text?: any;
  theme?: 'light' | 'dark';
  handleRemoveFile?(index: number): void;
  onFileUpload?(files: File[]): void;
}

const dropzone = (props: IDropzoneProps) => {
  const { acceptedFiles, disabled, isSubmitting, multiple, shouldDiscardFiles, shouldShowFiles } = props;
  const [files, setFiles] = React.useState([]);

  React.useEffect(() => {
    props.onFileUpload(files);
  }, [files]);

  React.useEffect(() => {
    if (props.resetFiles) {
      setFiles([]);
    }
  }, [props.resetFiles]);

  const onDrop = React.useCallback(
    (droppedAcceptedFiles: FileWithPath[]) => {
      let filesToSet = droppedAcceptedFiles;
      if (multiple && !shouldDiscardFiles) {
        filesToSet = [...files, ...droppedAcceptedFiles];
      }
      setFiles(filesToSet);
    },
    [files],
  );

  const renderAlert = () => {
    if (!props.alert) return;

    return <Alert isCloseable modifier={'full-width'} {...props.alert} />;
  };

  function handleRemoveFile() {
    const updatedFiles = [...files];
    updatedFiles.splice(this, 1);
    if (props.handleRemoveFile) {
      props.handleRemoveFile(this);
    }
    setFiles(updatedFiles);
  }

  const renderUploadText = () => {
    if (isSubmitting) {
      if (props.isLarge) {
        return uploadingLarge(files.length, props.theme);
      }
      return uploading(files.length, props.theme);
    }
    if (props.isLarge) {
      return uploadTextLarge(isDragActive);
    }
    return uploadText();
  };

  const renderFiles = () => {
    if (!shouldShowFiles) return;

    const formattedFiles = files.map((file, index) => (
      <div key={index} className="text-small">
        {file.name} (
        <a className="link link--blue" onClick={handleRemoveFile.bind(index)}>
          remove
        </a>
        )
      </div>
    ));

    return <div className="frow frow--gutters mar-t-1">{formattedFiles}</div>;
  };

  const options = { onDrop, multiple, disabled, disabledClick: disabled };

  if (acceptedFiles) {
    options['accept'] = acceptedFiles;
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone(options);

  const dropzoneClasses = classNames(
    'dropzone',
    'h-100',
    { 'dropzone--dark': props.theme === 'dark' },
    { 'dropzone--dragging': isDragActive },
  );

  return (
    <div className={`${props.isLarge || props.fullHeight ? 'h-100' : ''}`}>
      <div {...getRootProps({ className: dropzoneClasses })}>
        <input {...getInputProps()} />
        {renderAlert()}
        {renderUploadText()}
      </div>
      {renderFiles()}
    </div>
  );
};

export default dropzone;

dropzone.defaultProps = {
  disabled: false,
  fullHeight: false,
  isSubmitting: false,
  multiple: true,
  resetFiles: false,
  shouldShowFiles: true,
  theme: 'light',
};
