const statisticsTimeOptions = [
  'Last Qtr',
  'Three Mths',
  'Prev Qtr',
  'Six Mths',
  'YTD',
  '12 Mths',
  '2 Years',
  '3 Years',
  '4 Years',
  '5 Years',
];

const addStatisticsTimeOptions = (option: string) => {
  return {
    name: option,
    options: statisticsTimeOptions,
  };
};

export const columnOptions = [
  'Last Qtr',
  'Prev Qtr',
  'Six Mths',
  'YTD',
  '12 Mths',
  'Last year',
  '2 Yrs ago',
  '3 Yrs ago',
  '4 Yrs ago',
  '5 Yrs ago',
  'Ann.',
  'Ann. (3 Years)',
  'Ann. (5 Years)',
  'Ann. (inception)',
  'Overall',
  'Overall (3 Years)',
  'Overall (5 Years)',
  'Overall (inception)',
  'Std. Dev.',
  'Std. Dev. (3 Years)',
  'Std. Dev. (5 Years)',
  'Std. Dev. (inception)',
  'Short Term Std. Dev.',
  'Downside Std. Dev.',
  'Downside Std. Dev. (3 Years)',
  'Downside Std. Dev. (5 Years)',
  'Downside Std. Dev. (inception)',
  'Positive Periods',
  'Negative Periods',
  'Positive Periods %',
  'Max Drawdown',
  'Max Drawdown (3 Years)',
  'Max Drawdown (5 Years)',
  'Excess Return',
  ...[
    'Positive Periods',
    'Negative Periods',
    'Positive Periods %',
    'Max Drawdown',
    'Information Ratio',
    'R²',
    'Volatility',
    'Alpha',
    'Sortino',
    'Sharpe',
    'Up Capture Ratio',
    'Down Capture Ratio',
  ].map(addStatisticsTimeOptions),
];

export const groupedColumnOptions = [
  {
    name: 'Returns',
    options: [
      'Last Qtr',
      'Three Mths',
      'Prev Qtr',
      'Six Mths',
      'YTD',
      '12 Mths',
      'Last year',
      '2 Yrs ago',
      '3 Yrs ago',
      '4 Yrs ago',
      '5 Yrs ago',
      'Ann.',
      'Ann. (3 Years)',
      'Ann. (5 Years)',
      'Ann. (inception)',
      'Overall',
      'Overall (3 Years)',
      'Overall (5 Years)',
      'Overall (inception)',
      'Excess Return',
    ],
  },
  {
    name: 'Risk',
    options: [
      'Std. Dev.',
      'Std. Dev. (3 Years)',
      'Std. Dev. (5 Years)',
      'Std. Dev. (inception)',
      'Short Term Std. Dev.',
      'Downside Std. Dev.',
      'Downside Std. Dev. (3 Years)',
      'Downside Std. Dev. (5 Years)',
      'Downside Std. Dev. (inception)',
    ],
  },
  {
    name: 'Statistics',
    options: [
      'Positive Periods',
      'Negative Periods',
      'Positive Periods %',
      'Max Drawdown',
      'Information Ratio',
      'R²',
      'Volatility',
      'Alpha',
      'Sortino',
      'Sharpe',
      'Up Capture Ratio',
      'Down Capture Ratio',
    ].map(addStatisticsTimeOptions),
  },
];
