import * as React from 'react';
import Context from '../Context';

const classNames = require('classnames');

const defaultLogo = require('../../../../../images/logos/plus-divide-white.svg').default;

export default function themePage({ templateSection }) {
  const context = React.useContext(Context);
  const { reportTemplate } = context.state;

  const text = templateSection && templateSection.text ? templateSection.text : 'Section Name';

  const preview = !templateSection;

  const custom = reportTemplate && reportTemplate.theme === 'custom';

  const logo = custom ? reportTemplate.reportTemplateTheme.logo : defaultLogo;

  let theme = 'group';
  if (reportTemplate) {
    theme = reportTemplate.theme;
  }

  const headerClasses = classNames(
    'pdf-header',
    'pdf-header--landscape',
    { 'pdf-header--grey': theme === 'group' },
    { 'pdf-header--blue': theme === 'monitoring' },
    { 'pdf-header--orange': theme === 'consultancy' },
    { 'pdf-header--green': theme === 'portfolio' },
  );

  let headerStyles = {} as any;

  if (custom) {
    headerStyles = {
      backgroundColor: reportTemplate.reportTemplateTheme.headerColor,
      backgroundImage: `url(${reportTemplate.reportTemplateTheme.headerBackground})`,
    };
  }

  if (!preview) {
    headerStyles.height = '518px';
    headerStyles.margin = '-22px 0 -22px -40px';
    headerStyles.width = '842px';
  }

  let color = 'white';
  if (custom) {
    color = reportTemplate.reportTemplateTheme.headerFontColor;
  }

  function pageStyles() {
    if (preview) {
      return { width: '100%', height: '320px', marginBottom: 0 };
    }
  }

  function titleWrapperStyles() {
    if (preview) {
      return { top: '140px' };
    }
  }

  const titlePage = (
    <header className={headerClasses} style={headerStyles}>
      <div className="pdf-header__logo-wrap" style={{ bottom: '30px' }}>
        <img src={logo} className="pdf-header__logo" alt="logo" style={{ width: custom ? '65px' : '25px' }} />
        {!custom && <span className="pdf-header__logo-text">Enhance</span>}
      </div>
      <div className="pdf-header__title-wrap" style={titleWrapperStyles()}>
        <div>
          <h2 className="pdf-title" style={{ color }}>
            {text}
          </h2>
        </div>
      </div>
    </header>
  );

  if (preview) {
    return (
      <div className="pdf-page pdf-page--landscape" style={pageStyles()}>
        {titlePage}
      </div>
    );
  }

  return <div style={{ height: '100%', width: '100%' }}>{titlePage}</div>;
}
