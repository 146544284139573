import * as React from 'react';

import lodashStartcase from 'lodash.startcase';
import * as numeral from 'numeral';

import { Area, AreaChart, CartesianGrid, Label, Legend, ReferenceLine, XAxis, YAxis } from 'recharts';
import { format, parse } from 'date-fns';
import getTicks from 'components/shared/utilities/getTicks';
import { precisionTickFormatter } from 'components/shared/utilities/precisionFunctions';

const CustomYAxisPrint = (props) => {
  const name = props.name || 'Axis Name';
  const height = props.height;
  const { x, y } = props.viewBox;
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={-(height / 2)}
        y={10}
        textAnchor="start"
        fill="#333"
        transform="rotate(-90)"
        fontWeight="bold"
        fontSize="10.8px"
      >
        {name.toLocaleUpperCase()}
      </text>
    </g>
  );
};

const CustomLegend = (props) => {
  const { payload } = props;

  const legends = payload.map((payloadItem, index) => {
    const lineStyles = {
      borderColor: payloadItem.color,
      borderStyle: 'solid',
      borderWidth: '1px',
      display: 'inline-block',
      marginBottom: '2px',
      marginRight: '10px',
      width: '25px',
    };

    if (payloadItem.payload.strokeDasharray) {
      lineStyles.borderStyle = 'dashed';
    }

    return (
      <span key={index} style={{ display: 'inline-block', breakInside: 'avoid', whiteSpace: 'nowrap' }}>
        <span style={lineStyles} className="mar-r-1" />
        <span className="mar-r-2" style={{ fontSize: props.print ? '1.26rem' : '0.7rem', breakInside: 'avoid' }}>
          {lodashStartcase(payloadItem.dataKey)}
        </span>
      </span>
    );
  });

  return (
    <div
      style={{ border: '1px solid #e4e4e8', padding: props.print ? '3px 0 9px' : '0 0 5px', textAlign: 'center' }}
      className="mar-t-1"
    >
      {legends}
    </div>
  );
};

const CustomHorizontalLegend = (props) => {
  const { payload } = props;

  const legends = payload.map((payloadItem, index) => {
    const lineStyles = {
      borderColor: payloadItem.color,
      borderStyle: 'solid',
      borderWidth: '1px',
      display: 'inline-block',
      marginBottom: '1px',
      marginRight: '10px',
      width: '25px',
    };

    if (payloadItem.payload.strokeDasharray) {
      lineStyles.borderStyle = 'dashed';
    }

    return (
      <div key={index} style={{ whiteSpace: 'nowrap' }}>
        <span style={lineStyles} />
        <span className="mar-r-2" style={{ fontSize: props.print ? '1.26rem' : '0.7rem' }}>
          {lodashStartcase(payloadItem.dataKey)}
        </span>
      </div>
    );
  });

  return (
    <div className="mar-l-4" style={{ border: '1px solid #e4e4e8', padding: props.print ? '3px 5px 9px' : '0 0 5px' }}>
      {legends}
    </div>
  );
};

interface IProps {
  benchmarkTypes: any;
  templateSection: any;
  width: number;
  height: number;
  tableType?: string;
  theme: any;
  type: 'percentage' | 'number';
  startAt: number;
  verticalLegend: boolean;
  cartesianXAxis: boolean;
}

export default function shadedLineChart(props: IProps) {
  const { templateSection, theme, width, height, type, startAt, verticalLegend, cartesianXAxis } = props;
  const lines = templateSection.data[0].series.map((data) => data.label);

  const numbers = [];

  const data = templateSection.data.map((dataPoint) => {
    const dataObject = { name: dataPoint.time_code };
    dataPoint.series.forEach((data) => {
      numbers.push(data.value);
      dataObject[data.label] = parseFloat(data.value).toFixed(2);
    });
    return dataObject;
  });

  const maxData = Math.ceil(Math.max(...numbers));
  const minData = startAt ? startAt : Math.floor(Math.min(...numbers));
  const tickPoint = (maxData - minData) / 5;

  const ticks = getTicks({ max: maxData, min: minData, tickGap: tickPoint });

  const tickFormatter = precisionTickFormatter(ticks);

  const xAxisTicks = templateSection.x_axis_ticks;
  const dateTickFormatter = (tick: number) => format(parse(tick * 1000), 'MMM YY');

  const chartAreas = lines.map((dataPoint, index) => {
    return (
      <Area
        key={dataPoint}
        strokeWidth="2"
        type="linear"
        dataKey={dataPoint}
        stroke={theme.primary_color}
        dot={false}
        isAnimationActive={false}
        strokeDasharray={index > 3 ? '3 3' : ''}
        fill="url(#splitColor)"
      />
    );
  });

  const xAxesLabelCount = data.length;

  function renderLegend() {
    if (verticalLegend) {
      return (
        <Legend
          content={<CustomHorizontalLegend print={print} height={height} />}
          iconType="line"
          layout="vertical"
          verticalAlign="top"
          align="right"
        />
      );
    }
    return (
      <Legend
        content={<CustomLegend print={print} height={height} />}
        iconType="line"
        wrapperStyle={{ bottom: '-20px' }}
      />
    );
  }

  return (
    <AreaChart width={width} height={height} data={data} margin={{ top: 5, right: 20, left: 0, bottom: 0 }}>
      <CartesianGrid stroke="#d7d7dd" vertical={cartesianXAxis} />
      <XAxis
        dataKey="name"
        interval={xAxesLabelCount < 17 ? 0 : 'preserveEnd'}
        tick={{ fill: '#b5b6bd', fontSize: '12px', fontWeight: 'bold' }}
        ticks={xAxisTicks}
        tickSize={0}
        tickMargin={8}
        tickFormatter={dateTickFormatter}
      />
      <YAxis
        interval={0}
        tick={{ fill: '#b5b6bd', fontSize: '12px', fontWeight: 'bold' }}
        ticks={ticks}
        tickSize={0}
        tickMargin={5}
        tickFormatter={tickFormatter}
        domain={[!startAt ? startAt : minData - Math.abs(minData) * 0.1, maxData + Math.abs(maxData) * 0.1]}
        type="number"
        unit={type === 'percentage' ? '%' : null}
        width={type === 'percentage' ? 60 : 80}
      >
        <Label content={<CustomYAxisPrint name={templateSection.y_axis} height={height} />} />
      </YAxis>
      <defs>
        <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
          <stop offset="0%" stopColor={theme.primary_color} stopOpacity={1} />
          <stop offset={0} stopColor="#FFF" stopOpacity={1} />
          <stop offset="100%" stopColor={theme.primary_color} stopOpacity={1} />
        </linearGradient>
      </defs>
      <ReferenceLine y={0} stroke="#b0b0b5" />
      {chartAreas}
      {renderLegend()}
    </AreaChart>
  );
}

shadedLineChart.defaultProps = {
  height: 270,
  startAt: null,
  type: 'percentage',
};
