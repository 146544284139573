import * as React from 'react';
import { dissoc } from 'ramda';
import { Model, SpraypaintBase, Scope } from 'spraypaint';
import { SaveOptions } from 'spraypaint/lib-esm/model';

import humanizeString from 'humanize-string';
import Pluralize from 'pluralize';

import { createAlert } from 'components/shared/Utils';

export type TDisplayQueryResult = boolean | 'onError';
export interface INotifySaveOptions<I extends SpraypaintBase> extends SaveOptions<I> {
  displayQueryResult?: TDisplayQueryResult;
}

@Model()
export class ApplicationRecord extends SpraypaintBase {
  public static apiNamespace = '/api/v1';
  public static baseUrl = '';

  public static noLimit() {
    return this.per(-1);
  }

  public static scope() {
    const handler = {
      get: (target, prop, receiver) => {
        const value = prop in target ? target[prop] : this[prop];

        if (value instanceof Function) {
          return function (...args) {
            const result = value.apply(this === receiver ? target : this, args);
            return result instanceof Scope ? new Proxy(result, handler) : result;
          };
        }
        return value;
      },
    };

    return new Proxy(super.scope(), handler);
  }

  public async save<I extends SpraypaintBase>(options?: SaveOptions<I>);
  public async save<I extends SpraypaintBase>(options?: INotifySaveOptions<I>);
  public async save<I extends SpraypaintBase>(options: INotifySaveOptions<I> | SaveOptions<I> = {}): Promise<boolean> {
    const { displayQueryResult = true } = options as INotifySaveOptions<I>;

    try {
      const result = await super.save(dissoc('displayQueryResult', options));

      if (displayQueryResult) this.displayQueryResult('Save', displayQueryResult);

      return result;
    } catch {
      if (displayQueryResult) this.displayOtherError();
    }
  }

  public async destroy(displayQueryResult: TDisplayQueryResult = true): Promise<boolean> {
    try {
      const result = await super.destroy();

      if (displayQueryResult) this.displayQueryResult('Destroy', displayQueryResult);

      return result;
    } catch {
      if (displayQueryResult) this.displayOtherError();
    }
  }

  public displayQueryResult(operation, displayQueryResult: TDisplayQueryResult = false) {
    if (this.hasError) {
      this.displayResponseErrors();
    } else if (displayQueryResult === true) {
      this.displaySuccessAlert(operation);
    }
  }

  public displayOtherError() {
    this.diplayErrorAlert('Communication Error', 'There was a client, network, or server error.');
  }

  public displayResponseErrors() {
    Object.keys(this.errors).forEach((error: string) => {
      const currentError = this.errors[error];
      this.diplayErrorAlert(currentError.title, currentError.fullMessage);
    });
  }

  public diplayErrorAlert(title, body) {
    this.createAlert('error', title, body, 2500);
  }

  public displaySuccessAlert(operation) {
    this.createAlert('success', `${operation} Successful`, this.successMessage(operation), 2000);
  }

  public createAlert(msgType, title, body, displayTime) {
    createAlert(
      msgType,
      <>
        <div className="text-bold mar-b-1">{title}</div>
        <span>{body}</span>
      </>,
      2000,
    );
  }

  public successMessage(operation): React.ReactNode {
    return `${operation} on ${this.humanizedName} completed successfully`;
  }

  public get humanizedName(): string {
    const constructor: any = this.constructor;
    const jsonAPITypeParts = constructor.jsonapiType.split('/');
    const lastPart = jsonAPITypeParts[jsonAPITypeParts.length - 1];

    return humanizeString(Pluralize.singular(lastPart));
  }

  public static memo(...conditions) {
    // React doesn't act as expected when useMemo is invoked by a method on an object
    return () => React.useMemo(() => this, conditions);
  }
}
