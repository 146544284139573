import * as React from 'react';

import lodashStartcase from 'lodash.startcase';

const numeral = require('numeral');

export default function returnColumns(columnHeaders) {
  const formattedRow = (row, attribute) => {
    if (!row.original[attribute]) {
      return <div>-</div>;
    }

    return <div className="frow frow--items-center">{numeral(row.original[attribute]).format('0,0.00')}%</div>;
  };

  const columns = columnHeaders.map((header, index) => {
    return {
      Cell: (row) => formattedRow(row, header),
      Header: lodashStartcase(header),
      accessor: header,
      id: header,
    };
  });

  columns.unshift({
    Header: 'Returns',
    accessor: 'name',
    id: 'name',
  });

  return columns;
}
