import * as React from 'react';
import { sanitize } from 'dompurify';
import Context from './template/Context';
import Presenter from './template/Presenter';

import { createAlert } from 'components/shared/Utils';
import { ReportTemplate } from 'javascript/models';
import { ReportTemplatesReportTemplateSection } from 'javascript/models';
import { ReportTemplateRow } from 'javascript/models';

interface IProps {
  canEdit: boolean;
  reportTemplateId: number;
  selectedTab: string;
}

export default function template(props: IProps) {
  const { canEdit, reportTemplateId, selectedTab } = props;

  const [reportTemplate, setReportTemplate] = React.useState();
  const [pageColors, setPageColors] = React.useState([]);
  // Focus can either be the report or one of the repeating rows (possibly we might add the normal rows into the focus?
  // to stop people having to use the plus when messing around within a row)
  const [focus, setFocus] = React.useState('report');
  // We need an array of all the rows so that I can get nested rows when they need to be updated
  const [allRows, setAllRows] = React.useState([]);

  async function handleColorChange(index, color) {
    // Because we might edit the 4th page first we need to create an array to the length of the index given
    const arrayLength = index > pageColors.length ? index : pageColors.length;
    const emptyArray = Array.from({ length: arrayLength }, (v, k) => k);
    // Set the values of that array to be whatever they have been set to otherwise white
    const newColors = emptyArray.map((num) => {
      if (pageColors[num]) return pageColors[num];

      return 'white';
    });
    // Add the new color into this array removing the old one
    newColors.splice(index, 1, color);
    setPageColors(newColors);
    reportTemplate.pageColors = newColors;
    await reportTemplate.save();
  }

  React.useEffect(() => {
    getData();
  }, []);

  async function getData() {
    const { data } = await ReportTemplate.includes({
      report_template_rows: {
        child_rows: { report_templates_report_template_sections: 'report_template_section' },
        report_templates_report_template_sections: 'report_template_section',
      },
    })
      .includes(['report_template_theme', 'report_template_contents'])
      .order({ 'report_template_contents.position': 'asc' })
      .find(reportTemplateId);

    const colors = data.pageColors || ['white'];
    setPageColors(colors);
    setReportTemplate(data);
  }

  async function handleDragEnd(result) {
    const { destination, draggableId, source } = result;

    // dropped outside the list
    if (!destination) return;

    // If the source is 'droppable-section-selector and the destination is 'droppable-report' then we need to create
    // a new join for reports and sections using the destination index as the position
    if (source.droppableId === 'droppable-section-selector' && destination.droppableId.includes('droppable-report')) {
      const reportNumber = parseInt(destination.droppableId.split('-')[2], 10);
      let position = destination.index + 1;
      if (reportNumber > 0) {
        position += reportNumber;
      }

      const reportTemplateRow = new ReportTemplateRow({ position, report_template_id: reportTemplateId });
      const success = await reportTemplateRow.save();

      if (success) {
        const joinObject = new ReportTemplatesReportTemplateSection({
          position: 1,
          report_template_row_id: reportTemplateRow.id,
          report_template_section_id: draggableId,
        });
        const joinObjectSuccess = await joinObject.save();
        if (joinObjectSuccess) {
          getData();
        }
      }
    }

    // If the source is and destination are 'droppable-report' then we need to update the rows position based on the
    // index. We can't just use the id as the draggable id since we have two
    // different objects which might have the same id so we are prefaces it with row_
    if (source.droppableId.includes('droppable-report') && destination.droppableId.includes('droppable-report')) {
      const id = draggableId.split('_')[1];
      const templateRow = reportTemplate.reportTemplateRows.find((row) => row.id === id);

      const reportNumber = parseInt(destination.droppableId.split('-')[2], 10);

      let position = destination.index + 1;
      if (reportNumber > 0) {
        position += reportNumber;
      }

      templateRow.position = position;

      const success = await templateRow.save();

      if (success) {
        getData();
      }
    }

    // If the destination is the repeating-row we need to ensure we save the report row on there
    if (source.droppableId === 'droppable-section-selector' && destination.droppableId.includes('repeating-row')) {
      // If the draggable is a repeating section we just want to return (and maybe alert the user
      // NOTE(BR): Need to be careful that the repeating section is always id 27
      if (draggableId === '27') {
        createAlert('error', "You can't add a repeating row inside another repeating row", 1500);
        return;
      }

      // Getting the row number out of the droppable id
      const rowNumber = parseInt(destination.droppableId.split('-')[2], 10);

      // Getting the position from the index (this needs to change to handle multi-page repeating rows)
      const reportNumber = parseInt(destination.droppableId.split('-')[3], 10);

      let position = destination.index + 1;
      if (reportNumber > 0) {
        position += reportNumber;
      }
      // Create the new row
      const reportTemplateRow = new ReportTemplateRow({
        position,
        parent_report_template_row: rowNumber,
        report_template_id: reportTemplateId,
      });
      // Save the new row
      const success = await reportTemplateRow.save();

      if (success) {
        // Create and save the section join inside the row
        const joinObject = new ReportTemplatesReportTemplateSection({
          position: 1,
          report_template_row_id: reportTemplateRow.id,
          report_template_section_id: draggableId,
        });
        const joinObjectSuccess = await joinObject.save();
        if (joinObjectSuccess) {
          getData();
        }
      }
    }

    // If the destination and target are both the repeating row we need to change the position
    if (source.droppableId.includes('repeating-row') && destination.droppableId.includes('repeating-row')) {
      const id = draggableId.split('_')[1];
      // We are creating a 'new' one, but we know it already exists in the database so we set persisted to true so
      // graphiti will perform an patch instead of a post
      const templateRow = new ReportTemplateRow({ id });
      templateRow.isPersisted = true;
      const reportNumber = parseInt(destination.droppableId.split('-')[3], 10);

      let position = destination.index + 1;
      if (reportNumber > 0) {
        position += reportNumber;
      }
      templateRow.position = position;
      const success = await templateRow.save();

      if (success) {
        getData();
      }
    }
  }

  async function handleDestroy(reportTemplate: ReportTemplatesReportTemplateSection) {
    const success = await reportTemplate.destroy();

    if (success) {
      getData();
    }
  }

  const contextValues = {
    actions: { getData, handleColorChange, handleDestroy, handleDragEnd, setFocus },
    props: { canEdit },
    state: { focus, pageColors, reportTemplate, selectedTab },
  };

  function renderCustomTypography() {
    if (!reportTemplate || reportTemplate.theme !== 'custom' || !reportTemplate.reportTemplateTheme.fontSource) return;

    const styles = `.pdf-page { font-family: ${reportTemplate.reportTemplateTheme.fontName} }`;
    return (
      <React.Fragment>
        <link href={reportTemplate.reportTemplateTheme.fontSource} rel="stylesheet" />
        <style dangerouslySetInnerHTML={{ __html: sanitize(styles) }} />
      </React.Fragment>
    );
  }

  return (
    <Context.Provider value={contextValues}>
      {renderCustomTypography()}
      <Presenter />
    </Context.Provider>
  );
}
