import * as React from 'react';
import * as $ from 'jquery';

import Button from 'components/shared/forms/Button';
import { ajaxErrorString, createAlert } from 'components/shared/Utils';

import CsrfToken from 'components/shared/CsrfToken';

import ReviewContext from '../ReviewContext';
import AssignModal from '../../../../../../reviews/AssignModal';
import Context from '../../../../Context';
import useVisibility from 'components/shared/customHooks/useVisibility';

const routes = require('../../../../../../../../javascript/application/routes');

export default function importData() {
  const context = React.useContext(Context);
  const reviewContext = React.useContext(ReviewContext);
  const { review } = reviewContext.props;
  const { id } = review;
  const { owner } = review.attributes;
  const { handleReviewUpdate } = reviewContext.actions;
  const { administrators, companyId } = context.props;

  const { isOpen, handleOpen, handleClose } = useVisibility(false);

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  function handleClick() {
    if (owner || companyId === 2) {
      handleSubmit();
    } else {
      handleOpen();
    }
  }

  function handleSubmit() {
    setIsSubmitting(true);
    $.ajax({
      cache: false,
      complete: () => {
        setIsSubmitting(false);
      },
      data: { authenticity_token: CsrfToken() },
      dataType: 'json',
      error: (jqXhr) => {
        createAlert('error', ajaxErrorString(jqXhr), false);
      },
      success: () => {
        handleReviewUpdate();
      },
      type: 'POST',
      url: routes.platform_review_transitions_path(review.id),
    });
  }

  return (
    <React.Fragment>
      <Button
        buttonClasses="button--compact mar-r-1"
        isSubmitting={isSubmitting}
        handleClick={handleClick}
        submittingText="Moving Stage"
        style={{ minWidth: '120px' }}
        text="Import Data"
      />
      <AssignModal
        administrators={administrators}
        companyId={companyId}
        handleClose={handleClose}
        handleReviewsAssigned={handleSubmit}
        isOpen={isOpen}
        selection={[id]}
      />
    </React.Fragment>
  );
}
