import * as React from 'react';
import * as $ from 'jquery';

import Alert from 'components/shared/Alert';
import Button from 'components/shared/forms/Button';
import CsrfToken from 'components/shared/CsrfToken';
import CustomModal from 'components/shared/CustomModal';
import useVisibility from 'components/shared/customHooks/useVisibility';
import { ajaxErrorString, createAlert } from 'components/shared/Utils';

import { platformEntitiesPath } from 'javascript/application/ts_routes';
import Context from '../../Context';
import FormRow from 'components/shared/forms/FormRow';

export default function entityCreate() {
  const context = React.useContext(Context);

  const { handleEntityCreation } = context.actions;

  const { isOpen, handleOpen, handleClose } = useVisibility(false);

  const [errorMessage, setErrorMessage] = React.useState();
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  function handleSubmit(event) {
    setIsSubmitting(true);
    event.preventDefault();
    event.stopPropagation();
    const form = event.target;
    const formData = new FormData(form);
    $.ajax({
      complete: () => setIsSubmitting(false),
      contentType: false,
      data: formData,
      dataType: 'json',
      error: (jqXhr) => {
        setErrorMessage(ajaxErrorString(jqXhr));
      },
      processData: false,
      success: (response) => {
        handleEntityCreation(response);
        createAlert('success', 'New Entity Created', 1500);
        handleClose();
      },
      type: 'POST',
      url: platformEntitiesPath(),
    });
  }

  return (
    <React.Fragment>
      <button className="button button--secondary button--compact" type="button" onClick={handleOpen}>
        Create New Entity
      </button>
      <CustomModal isOpen={isOpen} handleClose={handleClose} title="New Entity" modifiers={['dark']}>
        <div className="modal__content">
          <Alert type="error" title="Error on submission" message={errorMessage} />
          <form action="" className="form" onSubmit={handleSubmit}>
            <input type="hidden" name="authenticity_token" value={CsrfToken()} />
            <input type="hidden" name="entity[entity_type]" value="legal_arrangement" />
            <input type="hidden" name="entity[status]" value="live" />
            <fieldset className="form__fieldset">
              <h4 className="form__fieldset-label">Entity Information</h4>
              <div className="frow frow--gutters">
                <FormRow>
                  <label htmlFor="name" className="form__label">
                    Name
                  </label>
                  <input type="text" name="entity[name]" id="name" />
                </FormRow>
                <FormRow>
                  <label className="form__label">Alias</label>
                  <input type="text" name="entity[alias]" />
                </FormRow>
              </div>
            </fieldset>
            <Button text="Create" isSubmitting={isSubmitting} submittingText="Creating" />
          </form>
        </div>
      </CustomModal>
    </React.Fragment>
  );
}
