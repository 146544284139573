import * as React from 'react';

import Conditions from './matchColumn/Conditions';
import ReactSelect from 'components/shared/forms/ReactSelect';

import Context from '../../Context';
import { UploadTemplateCondition } from 'javascript/models';

interface IProps {
  uploadTemplateCondition: UploadTemplateCondition;
  handleAttributeChange(attribute: string, value: string): void;
}

export default function matchColumn(props: IProps) {
  const { handleAttributeChange, uploadTemplateCondition } = props;

  const context = React.useContext(Context);
  const { attributeOptions } = context.props;

  function handleSelectChange(selectedOption) {
    const event = { target: { name: 'row', value: selectedOption.value } };
    handleChange(event);
  }

  function handleConditionChange(conditions) {
    handleAttributeChange('conditions', conditions);
  }

  function handleChange(event) {
    handleAttributeChange(event.target.name, event.target.value);
  }

  let selectedLabel = '';
  let selectedOption = null;
  if (uploadTemplateCondition.row) {
    selectedLabel = attributeOptions.find((option) => option.value === uploadTemplateCondition.row).label;
    selectedOption = { value: uploadTemplateCondition.row, label: selectedLabel };
  }

  return (
    <div
      className="platform-content platform-content--padding-bottom platform-content--border-dashed-bottom-grey
        platform-content--spacing-bottom"
    >
      <div className="frow frow--centered-column frow--gutters">
        <div className="col-mc-1-1">
          <div className="mar-v-1">
            <label className="form__label">Column to Test</label>
            <ReactSelect
              id="row"
              name="row"
              maxMenuHeight={200}
              options={attributeOptions}
              handleChange={handleSelectChange}
              theme="light"
              defaultValue={selectedOption}
            />
          </div>
        </div>
        <div className="col-mc-1-1">
          <label htmlFor="match_type" className="form__label">
            Match Type
          </label>
          <div className="frow">
            <div className="pretty pretty--large pretty--with-label p-round">
              <input
                type="radio"
                name="matchType"
                id="match_type"
                value="exact"
                onChange={handleChange}
                defaultChecked={uploadTemplateCondition.matchType === 'exact'}
              />
              <div className="state p-blue-circle">
                <label>Exact Match</label>
              </div>
            </div>
            <div className="pretty pretty--large pretty--with-label p-round">
              <input
                type="radio"
                name="matchType"
                id="match_type"
                value="fuzzy"
                onChange={handleChange}
                defaultChecked={uploadTemplateCondition.matchType === 'fuzzy'}
              />
              <div className="state p-blue-circle">
                <label>Fuzzy Match</label>
              </div>
            </div>
            <div className="pretty pretty--large pretty--with-label p-icon">
              <input
                type="checkbox"
                name="matchOption"
                id="match_option"
                value="exclude"
                onChange={handleChange}
                defaultChecked={uploadTemplateCondition.matchOption === 'exclude'}
              />

              <div className="state p-blue">
                <i className="icon-tick icon icon-push-down-small" />
                <label>Exclude When Condition Met</label>
              </div>
            </div>
          </div>
        </div>
        <Conditions {...{ handleConditionChange, uploadTemplateCondition }} />
      </div>
    </div>
  );
}
