import * as React from 'react';

import GraphitiTableWrapper from 'components/shared/GraphitiTableWrapper';

import columns from './table/columns';

import { Entity } from 'javascript/models';

interface IProps {
  hasHeader?: boolean;
  newPath?: string;
  scope?: { [key: string]: string | { [key: string]: string } };
}

export default function table(props: IProps) {
  const { hasHeader, newPath } = props;

  let scope = Entity.includes(['portfolios']);

  if (props.scope) {
    scope = scope.where(props.scope);
  }

  return (
    <GraphitiTableWrapper
      scope={scope}
      columns={columns()}
      hasHeader
      isSearchable
      newPath={newPath}
      newText="Add Entity"
      searchPlaceholder="Search by name or alias"
      title="Entities"
      identifier="entities:index"
    />
  );
}
