import * as React from 'react';
import * as $ from 'jquery';

import lodashDebounce from 'lodash.debounce';

import CsrfToken from 'components/shared/CsrfToken';

import Presenter from './editModel/Presenter';

import IAttribute from './editModel/interfaces/IAttribute';
import { createAlert } from './Utils';

interface IProps {
  attributes: IAttribute[];
  editPath: string;
  modelName: string;
}

interface IState {
  isLoading: boolean;
}

class EditModel extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      isLoading: false,
    };
    this.updateModel = lodashDebounce(this.updateModel.bind(this), 700);
  }

  public render() {
    const { attributes, editPath } = this.props;
    return <Presenter {...{ attributes, editPath }} updateModel={this.updateModel} />;
  }

  private updateModel(event: React.SyntheticEvent) {
    const inputEvent = event.target as HTMLInputElement;
    const { name, value } = inputEvent;
    $.ajax({
      beforeSend: () => this.setState({ isLoading: true }),
      complete: () => this.setState({ isLoading: false }),
      data: { [this.props.modelName]: { [name]: value }, authenticity_token: CsrfToken() },
      dataType: 'json',
      success: () => createAlert('success', `Successfully updated ${name.replace('_', ' ')}`, 1500),
      type: 'PATCH',
      url: this.props.editPath,
    });
  }
}

export default EditModel;
