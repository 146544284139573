import { Attr, BelongsTo, Model } from 'spraypaint';
import { ApplicationRecord } from './ApplicationRecord';
import { ConsultancyClient } from './ConsultancyClient';

@Model()
export class ConsultancyClientLetterOfEngagement extends ApplicationRecord {
  public static jsonapiType = 'consultancy_client_letter_of_engagements';

  @Attr() public signatureDate: string;
  @Attr() public filename: string;
  @Attr() public serviceUrl: string;
  @Attr() public createdAt: string;
  @Attr() public documentUrl: string;
}
