import * as React from 'react';

export default function customHeader({ reportTemplate }) {
  const { reportTemplateTheme, reportType } = reportTemplate;

  const { headerLogoHeight, titleFirstLine, titleLetterSpacing, titleSecondLine, titleThirdLine } = reportTemplateTheme;

  const headerStyles = {
    backgroundColor: reportTemplateTheme.headerColor,
    backgroundImage: `url(${reportTemplateTheme.headerBackground})`,
  };

  const color = reportTemplateTheme.headerFontColor;

  const headerLogo = reportTemplateTheme.headerLogo ? reportTemplateTheme.headerLogo : reportTemplateTheme.logo;

  const logoStyles: React.CSSProperties = {
    height: `${headerLogoHeight}px`,
    width: 'auto',
  };

  return (
    <header className="pdf-header pdf-header--landscape" style={headerStyles}>
      <div className="pdf-header__logo-wrap">
        <img src={headerLogo} alt="" className="pdf-header__logo" style={logoStyles} />
      </div>
      <div className="pdf-header__title-wrap">
        <div>
          <h3 className="pdf-subtitle text-white text-bold" style={{ fontSize: '2rem', color }}>
            {titleFirstLine}
          </h3>
          <h1 className="pdf-title text-white" style={{ fontSize: '3rem', color }}>
            {titleSecondLine}
          </h1>
          <h2 className="pdf-subtitle text-white text-bold" style={{ fontSize: '2rem', color }}>
            {titleThirdLine}
          </h2>
        </div>
      </div>
    </header>
  );
}
