import * as React from 'react';

import { Alert, Intent, Menu, MenuItem, Popover } from '@blueprintjs/core';
import { Draggable } from 'react-beautiful-dnd';

import EmptySection from './EmptySection';
import Section from './Section';

import useVisibility from 'components/shared/customHooks/useVisibility';
import Context from '../Context';
import Edit from './row/Edit';

export default function row({ canEdit, dummyContainerRef, row, index }) {
  const context = React.useContext(Context);
  const { getData } = context.actions;
  const { isOpen, handleClose, handleOpen } = useVisibility(false);

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [popoverOpen, setPopoverOpen] = React.useState(false);

  async function confirmDelete() {
    handlePortalClose();
    const success = await row.destroy();

    if (success) {
      getData();
    }
  }

  function columns() {
    switch (row.width) {
      case 'halfs':
        return ['col-mc-1-2', 'col-mc-1-2'];
      case 'thirds':
        return ['col-mc-1-3', 'col-mc-1-3', 'col-mc-1-3'];
      case 'one_two':
        return ['col-mc-1-3', 'col-mc-2-3'];
      case 'two_one':
        return ['col-mc-2-3', 'col-mc-1-3'];
      default:
        return ['col-mc-1-1'];
    }
  }

  function handleAlertOpen(event) {
    event.stopPropagation();
    handlePortalClose();
    handleOpen();
  }

  function handlePopOverToggle(event) {
    event.stopPropagation();
    setPopoverOpen(!popoverOpen);
  }

  function handleModalOpen(event) {
    event.stopPropagation();
    handlePortalClose();
    setIsModalOpen(true);
  }

  function handleModalClose(event) {
    if (event) {
      event.stopPropagation();
    }
    setIsModalOpen(false);
  }

  function handlePortalClose() {
    setPopoverOpen(false);
  }

  const sections = columns().map((column, sectionIndex) => {
    const templateSection = row.reportTemplatesReportTemplateSections[sectionIndex];

    if (templateSection) {
      return (
        <div className={`${column} pad-v-0`} key={`row_section_${index}_${sectionIndex}`}>
          <Section {...{ templateSection, canEdit, dummyContainerRef, row }} />
        </div>
      );
    }

    return (
      <div className={`${column} pad-v-0`} key={`row_section_${index}_${sectionIndex}`}>
        <EmptySection {...{ canEdit, sectionIndex }} rowId={row.id} />
      </div>
    );
  });

  function editRow() {
    if (!canEdit) return;

    return (
      <div
        style={{ position: 'absolute', right: '-10px', cursor: 'pointer', top: '20%' }}
        onClick={handlePopOverToggle}
      >
        <Popover usePortal={false} isOpen={popoverOpen}>
          <i className="icon-overflow-vertical-white" />
          <Menu>
            {row.height != 100.0 && <MenuItem text="Edit" onClick={handleModalOpen} />}
            <MenuItem text="Delete" intent={Intent.DANGER} onClick={handleAlertOpen} />
          </Menu>
        </Popover>
      </div>
    );
  }

  return (
    <div>
      <div style={{ position: 'relative' }}>
        {editRow()}
        <Draggable draggableId={`row_${row.id}`} index={0} isDragDisabled={!canEdit}>
          {(provided, _) => {
            return (
              <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                <div className={`frow ${row.width === 'full' ? '' : 'frow--gutters-horizontal'}`}>{sections}</div>
              </div>
            );
          }}
        </Draggable>
      </div>
      <Alert
        isOpen={isOpen}
        onClose={handleClose}
        canEscapeKeyCancel
        canOutsideClickCancel
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        intent={Intent.DANGER}
        onCancel={handleClose}
        onConfirm={confirmDelete}
        portalContainer={document.body}
      >
        <p className="mar-t-0">Are you sure you want to delete this row?</p>
      </Alert>
      <Edit {...{ handleModalClose, isModalOpen, row }} />
    </div>
  );
}
