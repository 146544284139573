import * as React from 'react';

import turbolinks from 'turbolinks';

import ICompany from 'components/interfaces/ICompany';

import Table from 'components/shared/reactTable/Table';

interface IProps {
  data: ICompany[];
  isLoading: boolean;
  meta: any;
}

class Presenter extends React.Component<IProps> {
  public render() {
    return (
      <Table
        columns={this.columns()}
        data={this.props.data}
        getTrProps={(state, rowInfo, column) => {
          return {
            className: 'rt-tr-clickable',
            onClick: () => turbolinks.visit(rowInfo.original.links.show_path),
          };
        }}
        identifier="administrators-table"
        isLoading={this.props.isLoading}
        meta={this.props.meta}
      />
    );
  }

  private columns() {
    return [
      {
        Header: 'Name',
        accessor: 'attributes.name',
        columnName: 'Name',
      },
      {
        Header: 'Number of Admins',
        accessor: 'attributes.number_of_admins',
        columnName: 'Number of Admins',
      },
      {
        Cell: (row) => {
          return (
            <a href={row.original.links.edit_path}>
              <div className="rt-tr-clickable-icon">
                <i className="icon-arrow-right-top icon-0-8x" />
              </div>
            </a>
          );
        },
        Header: '',
      },
    ];
  }
}

export default Presenter;
