import * as React from 'react';
import * as $ from 'jquery';
import * as classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import startCase from 'lodash.startcase';
import * as colorFunctions from 'color';

import { portalClientPortfolioSubAssetAllocationsPath } from 'javascript/application/ts_routes';

import Loading from 'components/shared/Loading';
import ReactPieChart from 'components/shared/charting/recharts/ReactPieChart';
import PieChartTableLegend from '../../../show/PieChartTableLegend';

export default function subChart({ activeDate, colors, portfolioObject, selectedSlice }) {
  const [isLoading, setIsLoading] = React.useState(true);
  const { client_id, id } = portfolioObject;
  const [subOptions, setSubOptions] = React.useState();
  const [selectedSubOption, setSelectedSubOption] = React.useState();
  const [chartData, setChartData] = React.useState();

  React.useEffect(() => {
    if (selectedSlice) {
      getSubAssetData();
    }
  }, [selectedSlice]);

  function getSubAssetData() {
    $.ajax({
      beforeSend: () => setIsLoading(true),
      cache: false,
      data: { asset: selectedSlice, date: `01 ${activeDate}` },
      dataType: 'json',
      success: (response) => {
        setChartData(response);
        const filteredOptions = { ...response };
        for (const [key, value] of Object.entries(filteredOptions)) {
          if (
            Object.keys(filteredOptions[key]).length === 1 &&
            Object.keys(filteredOptions[key][0] === 'Unclassified')
          ) {
            delete filteredOptions[key];
          }
        }
        const options = Object.keys(filteredOptions);
        setSubOptions(options);
        setSelectedSubOption(options ? options[0] : null);
        setIsLoading(false);
      },
      type: 'GET',
      url: portalClientPortfolioSubAssetAllocationsPath(client_id, id),
    });
  }

  if (isLoading) return <Loading />;

  const formattedPieData = [];
  if (selectedSubOption && !!chartData[selectedSubOption]) {
    for (const [key, value] of Object.entries(chartData[selectedSubOption])) {
      const index = formattedPieData.length;
      let color = colors[index];
      if (index > 5) {
        const baseColor = colors[index % 6];
        color = colorFunctions(baseColor)
          .rotate(15 * index)
          .string();
      }
      formattedPieData.push({
        color,
        name: key,
        value: parseFloat(value as string),
      });
    }
  }

  function optionIcon(subOption) {
    switch (subOption) {
      case 'instrument':
        return 'fas fa-chart-line';
      case 'currency':
        return 'fas fa-pound-sign';
      case 'industry':
        return 'fas fa-industry';
      case 'country_of_risk':
        return 'fas fa-globe-europe';
      case 'credit_rating':
        return 'far fa-credit-card';
      case 'sub_instrument':
        return 'fas fa-layer-group';
      case 'dividend_distribution_policy':
        return 'far fa-money-bill-alt';
      default:
        return '';
    }
  }

  function renderOptions() {
    if (subOptions.legnth < 1) return <div />;
    const optionButtons = subOptions.map((subOption) => {
      const buttonClass = classNames('button-group__button', {
        'button-group__button--active': subOption === selectedSubOption,
      });

      function handleSelect() {
        setSelectedSubOption(subOption);
      }

      return (
        <div
          className={buttonClass}
          key={subOption}
          data-tip={startCase(subOption)}
          data-for="icon-button"
          data-place="top"
          onClick={handleSelect}
        >
          <i className={optionIcon(subOption)} />
        </div>
      );
    });

    return (
      <div className="button-group">
        {optionButtons}
        <ReactTooltip effect="solid" id="icon-button" />
      </div>
    );
  }

  return (
    <div className="frow frow--gutters">
      <div className="col-md-1-2">
        <ReactPieChart
          animationDuration={500}
          animationBegin={200}
          activeShape
          data={formattedPieData}
          height={245}
          labelText={activeDate}
          labelSize="3rem"
          noData={formattedPieData.length < 1}
          displayLegend={false}
          noDataText="Insufficient Data"
        />
      </div>
      <PieChartTableLegend headerTitle="" data={formattedPieData} />
      <div className="col-mc-1-1">{renderOptions()}</div>
    </div>
  );
}
