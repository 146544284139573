import * as React from 'react';
import classNames from 'classnames';
import QuarterIndiciator from 'components/shared/QuarterIndicator';

interface IProps {
  isQuarterSelectOpen: boolean;
  selectedQuarter: { quarter: number; year: number };
  selectableQuarters: { display: string; value: string }[];
  handleQuarterSelect(quarter: number, year: number, value: string): void;
  handleQuarterSelectOpen(): void;
}

const quarterSelect = (props: IProps) => {
  const {
    selectedQuarter,
    handleQuarterSelectOpen,
    isQuarterSelectOpen,
    selectableQuarters,
    handleQuarterSelect,
  } = props;

  const dropdownClasses = classNames('dropdown dropdown--sw', { 'dropdown--active': isQuarterSelectOpen });

  const renderDropdownOptions = selectableQuarters.map((quarter, index) => {
    const quarterNumber = parseInt(quarter.display.split(' ')[0][1], 10);
    const yearNumber = parseInt(quarter.display.split(' ')[1], 10);

    const handleQuarterChange = React.useCallback(() => {
      handleQuarterSelect(quarterNumber, yearNumber, quarter.value);
    }, [quarterNumber, yearNumber, quarter.value]);

    return (
      <div key={index} className="dropdown__nav-link" onClick={handleQuarterChange}>
        <div className="frow frow--items-center">
          <QuarterIndiciator currentQuarter={quarterNumber} currentYear={yearNumber} showDateText />
        </div>
      </div>
    );
  });

  return (
    <div className={dropdownClasses}>
      <a className="dropdown__toggle dropdown__toggle--input" onClick={handleQuarterSelectOpen}>
        <div className="frow frow--items-center">
          <QuarterIndiciator currentQuarter={selectedQuarter.quarter} currentYear={selectedQuarter.year} />
          <span style={{ marginRight: '25px' }} className="text-normal">
            Q{selectedQuarter.quarter} {selectedQuarter.year}
          </span>
          <i className={`button__icon fa fa-caret-down fa-animated ${isQuarterSelectOpen ? 'fa-flipped' : ''}`} />
        </div>
      </a>
      <div className="dropdown__content">
        <div className="dropdown__content-inner">{renderDropdownOptions}</div>
      </div>
    </div>
  );
};

export default quarterSelect;
