export default function InformationalMessagesColumns(handleTableUpdate) {
  return [
    {
      Header: 'Message',
      accessor: 'body',
      columnName: 'Message',
      id: 'body',
      style: { whiteSpace: 'unset' },
    },
  ];
}
