import * as React from 'react';

import Modal from 'react-modal';

export default function customModal(props) {
  function handleClose(event) {
    props.handleClose(event);
  }

  function renderClose() {
    if (!props.handleClose) return;

    return <span className="modal__close" onClick={handleClose} />;
  }

  const modelClasses = props.modifiers.map((modifier) => {
    return `modal--${modifier}`;
  });

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.handleClose}
      ariaHideApp={false}
      className="react-modal-modal"
      overlayClassName="react-modal-overlay"
    >
      <div className={`modal ${modelClasses.join(' ')}`}>
        <header className="modal__header">
          <h3 className="modal__title">{props.title}</h3>
          {renderClose()}
        </header>
        {props.children}
      </div>
    </Modal>
  );
}

customModal.defaultProps = {
  modifiers: [],
};
