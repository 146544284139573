import * as React from 'react';

interface IWrapperProps {
  additionalClasses?: string;
  style?: React.CSSProperties;
  text: any;
}

const wrapper = (Tag, className) => (props: IWrapperProps) => {
  const { additionalClasses, style, text } = props;
  return (
    <Tag className={`${className} ${additionalClasses}`} style={style}>
      {text}
    </Tag>
  );
};

export const HeadingOne = wrapper('h2', 'heading-one');
export const HeadingTwo = wrapper('h2', 'heading-two');
export const HeadingThree = wrapper('h3', 'heading-three');
export const HeadingFour = wrapper('h3', 'heading-four');
export const SubtitleOne = wrapper('p', 'subtitle-one');
export const SubtitleTwo = wrapper('p', 'subtitle-two');
