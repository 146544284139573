import { Attr, BelongsTo, HasMany, HasOne, Model } from 'spraypaint';
import { ApplicationRecord } from './ApplicationRecord';
import { Portfolio } from './Portfolio';
import { Balance } from './Balance';

@Model()
export class PrivateAsset extends ApplicationRecord {
  public static jsonapiType = 'private_assets';
  @Attr() public holdableType: string;
  @Attr() public category: string;
  @Attr() public subCategory: string;
  @Attr() public portfolioId: string;
  @Attr() public description: string;
  @Attr() public lastReturnDate: Date;
  @HasMany(Balance) public balances: Balance[];
  @BelongsTo(Portfolio) public portfolio: Portfolio;
  @HasOne(Balance) public currentValue: Balance;
}
