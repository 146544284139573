import * as React from 'react';
import { format, parse } from 'date-fns';
import { sanitize } from 'dompurify';
import nl2br from 'react-newline-to-break';

export default function auditDisplay({ audit }) {
  const { username, log, note, createdAt } = audit;

  return (
    <div className="comment">
      <div className="frow frow--gutters frow--nowrap">
        <div className="col-sm-2-12">
          <div className="frow frow--gutters frow--items-center frow--nowrap">
            <div>
              <div className="user-icon mar-h-0" data-letter={username.charAt(0)} />
            </div>
            <div className="text-small">{username}</div>
          </div>
          <p className="text-tiny mar-b-0">{format(parse(createdAt), 'DD MMM YYYY [at] HH:mm')}</p>
        </div>
        <div className="col-sm-10-12">
          <div className="text-wrap text-wrap--small" dangerouslySetInnerHTML={{ __html: sanitize(log) }} />
          <p className="text-tiny mar-b-0">
            <em>{nl2br(note)}</em>
          </p>
        </div>
      </div>
    </div>
  );
}
