import * as React from 'react';

export default function changeDate({ handleAttributeChange, uploadTemplateCondition }) {
  const [dateChangeValue, setDateChangeValue] = React.useState(uploadTemplateCondition.dateChangeType);

  const dateChangeOptions = [
    { label: 'Start of month', value: 'start_of_month' },
    { label: 'End of month', value: 'end_of_month' },
    { label: 'Start of quarter', value: 'start_of_quarter' },
    { label: 'End of quarter', value: 'end_of_quarter' },
  ];

  function handleChange(event) {
    const attribute = event.target.id;
    const value = event.target.value;
    if (attribute === 'dateChangeType') {
      setDateChangeValue(value);
    }
    handleAttributeChange(attribute, value);
  }

  const options = dateChangeOptions.map((option) => {
    return (
      <div key={option.value} className="pretty pretty--large pretty--with-label p-round">
        <input
          type="radio"
          id="dateChangeType"
          name="dateChange"
          value={option.value}
          onChange={handleChange}
          defaultChecked={uploadTemplateCondition.dateChangeType === option.value}
        />
        <div className="state p-blue-circle">
          <label>{option.label}</label>
        </div>
      </div>
    );
  });

  return (
    <div
      className="platform-content platform-content--padding-bottom platform-content--border-dashed-bottom-grey
        platform-content--spacing-bottom"
    >
      <p className="text-small">Move the date to:</p>
      <div className="frow frow--centered-column frow--gutters">
        <div className="col-mc-1-1">
          <div className="col-mc-1-1">{options}</div>
        </div>
      </div>
    </div>
  );
}
