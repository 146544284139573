import * as React from 'react';

import { ClientTile } from 'javascript/models';

import Form from './Form';

import useGraphitiForm from 'components/shared/customHooks/useGraphitiForm';

import CustomModal from 'components/shared/CustomModal';
import Alert from 'components/shared/Alert';
import { graphitiErrorString } from 'components/shared/Utils';

export default function newForm({ handleSuccessfulFormSubmission, resourceTypes }) {
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const { resource, handleChange, handleManualChange, handleSubmit, resetResource } = useGraphitiForm(
    new ClientTile(),
    handleFormSubmission,
  );

  function handleClose() {
    setIsOpen(false);
    setErrorMessage(null);
  }

  function handleOpen() {
    setIsOpen(true);
  }

  async function handleFormSubmission(event) {
    const success = await resource.save();
    if (success) {
      resetResource();
      handleClose();
      handleSuccessfulFormSubmission();
    } else {
      setErrorMessage(graphitiErrorString(resource));
    }
    setIsSubmitting(false);
  }

  function handleFormSubmit(event) {
    setIsSubmitting(true);
    handleSubmit(event);
  }

  return (
    <div className="col-sm-1-3 col-md-1-5">
      <div className="card card--clickable card--fixed-height" onClick={handleOpen}>
        <div className="card__inner" style={{ height: '200px' }}>
          <div className="frow frow--direction-column frow--full-height">
            <div>
              <p className="status-indicator mar-v-0 text-white small-uppercase" />
            </div>
            <div className="" style={{ marginTop: 'auto' }}>
              <p className="card__subtitle">
                Create New
                <i className="icon-plus icon-fw icon-2x mar-l-2" />
              </p>
            </div>
          </div>
        </div>
      </div>
      <CustomModal isOpen={isOpen} title="New Resource" handleClose={handleClose} modifiers={['visible-overflow']}>
        <div className="modal__content">
          <Alert message={errorMessage} modifier="dark" title="Missing Fields" type="error" />
          <form className="form form--light" onSubmit={handleFormSubmit}>
            <Form {...{ resource, handleChange, handleManualChange, isSubmitting, resourceTypes }} />
          </form>
        </div>
      </CustomModal>
    </div>
  );
}
