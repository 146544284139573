import * as React from 'react';
import Loading from '../Loading';

import { Review } from 'javascript/models';
import Table from 'components/shared/reactTable/Table';
import columns from './columns';

const InvestmentRestrictionsTable = ({ reviewId }) => {
  const [loading, setLoading] = React.useState(true);
  const [checkList, setCheckList] = React.useState([]);
  const [tableColumns, setTableColumns] = React.useState([]);

  const getData = async () => {
    const { data } = await Review.selectExtra(['investment_restrictions']).find(reviewId);
    const review = data;
    const checkList = review.investmentRestrictions;

    setCheckList(checkList);
    setTableColumns(columns({ review, checkList }));
    setLoading(false);
  };

  React.useEffect(() => {
    getData();
  }, []);

  if (loading) return <Loading />;

  return <Table columns={tableColumns} data={checkList} manual hasPagination={false} isLoading={loading} />;
};

export default InvestmentRestrictionsTable;
