import * as React from 'react';
import * as $ from 'jquery';

import { Alert, Intent } from '@blueprintjs/core';

import CsrfToken from 'components/shared/CsrfToken';
import ReviewContext from '../ReviewContext';
import useVisibility from 'components/shared/customHooks/useVisibility';

interface IProps {
  buttonText: string;
  transition: string;
}

export default function statusButton(props: IProps) {
  const reviewContext = React.useContext(ReviewContext);
  const { updateStatusPath } = reviewContext.props.review.links;
  const { handleReviewUpdate } = reviewContext.actions;
  const { buttonText, transition } = props;

  const { isOpen, handleClose, handleOpen } = useVisibility(false);

  function handleSubmit() {
    const reviewObject = { event: transition };
    $.ajax({
      data: { review: reviewObject, authenticity_token: CsrfToken() },
      dataType: 'json',
      success: () => {
        handleReviewUpdate();
      },
      type: 'PATCH',
      url: updateStatusPath,
    });
  }

  return (
    <div className="mar-r-1">
      <button className="button button--compact button--outlined button--no-min-width" onClick={handleOpen}>
        {buttonText}
      </button>
      <Alert
        isOpen={isOpen}
        onClose={handleClose}
        canEscapeKeyCancel
        canOutsideClickCancel
        cancelButtonText="Cancel"
        confirmButtonText="Confirm"
        intent={Intent.DANGER}
        onCancel={handleClose}
        onConfirm={handleSubmit}
        portalContainer={document.body}
      >
        <p className="mar-t-0">Are you sure?</p>
      </Alert>
    </div>
  );
}
