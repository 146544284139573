import * as React from 'react';
import { ReportTemplatesReportTemplateSection } from 'javascript/models';

interface IProps {
  labelName?: string;
  selectedKey: string;
  handleKeyChange: () => void;
}

export default function legendKeys(props: IProps) {
  const { handleKeyChange, labelName, selectedKey } = props;

  const legendKeys = ReportTemplatesReportTemplateSection.legendKeys.map((key) => {
    return (
      <div className="col-tn-1-3" key={key.value}>
        <div className="pretty pretty--large pretty--with-label p-round">
          <input
            type="radio"
            value={key.value}
            id="legendKey"
            onChange={handleKeyChange.bind(key.value)}
            checked={selectedKey === key.value}
          />
          <div className="state p-blue-circle">
            <label>{key.label}</label>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="col-mc-1-1">
      <label className="form__label" htmlFor="">
        {labelName}
      </label>
      <div className="frow ">{legendKeys}</div>
    </div>
  );
}

legendKeys.defaultProps = {
  labelName: 'Legend Keys',
};
