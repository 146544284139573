import * as React from 'react';
import { sanitize } from 'dompurify';
export default function relationshipManager({ avatar, manager, jobTitle, email, switchboard }) {
  const humanizeSwitchboard = (switchboard) => {
    let humanizedSwitchboard = switchboard.slice(0, 3);
    humanizedSwitchboard += ' (' + switchboard.slice(3, 4) + ')';
    humanizedSwitchboard += ' ' + switchboard.slice(4, 8) + ' ';
    humanizedSwitchboard += switchboard.slice(8);
    return humanizedSwitchboard;
  };

  const avatarImage = (
    <img className="" src={avatar} style={{ maxHeight: '90px', height: 'auto', width: '100%', maxWidth: '135px' }} />
  );

  const managerName = <p className="pdf-body-copy mar-b-0" dangerouslySetInnerHTML={{ __html: sanitize(manager) }} />;

  const managerTitle = <p className="pdf-body-copy" dangerouslySetInnerHTML={{ __html: sanitize(jobTitle) }} />;

  const managerEmail = (
    <p className="pdf-body-copy pdf-body-copy--bold mar-b-0" dangerouslySetInnerHTML={{ __html: sanitize(email) }} />
  );

  const managerPhone = (
    <p
      className="pdf-body-copy pdf-body-copy--bold"
      dangerouslySetInnerHTML={{ __html: humanizeSwitchboard(sanitize(switchboard)) }}
    />
  );

  return (
    <div className="frow frow--gutters frow--nowrap" style={{ height: '110px' }}>
      <div className="">{avatar && avatarImage}</div>
      <div className="">
        <div className="pdf-body-copy-wrap">
          {manager && managerName}
          {jobTitle && managerTitle}
          {email && managerEmail}
          {switchboard && managerPhone}
        </div>
      </div>
    </div>
  );
}

relationshipManager.defaultProps = {
  avatar: 'https://res.cloudinary.com/infuse-group/image/upload/v1547807251/Enhance/Tom-W-min.jpg',
  email: 'twiseman@enhance.group',
  jobTitle: 'CEO',
  manager: 'Tom Wiseman',
  switchboard: '+4401534761522',
};
