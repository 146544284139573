import * as React from 'react';
import { renderToString } from 'react-dom/server';
import * as $ from 'jquery';

import lodashTimes from 'lodash.times';

import { sectionEdit } from './sectionEdit';
import { sectionDisplay } from '../../sectionDisplay';

import Button from 'components/shared/forms/Button';
import CustomModal from 'components/shared/CustomModal';

import Context from '../../Context';

import useVisibility from 'components/shared/customHooks/useVisibility';

export default function edit({ dummyContainerRef, row, templateSection }) {
  const context = React.useContext(Context);
  const { getData } = context.actions;
  const { reportTemplate } = context.state;

  const isLandscape = reportTemplate && reportTemplate.orientation === 'landscape';

  const isRepeating = !!row.parentReportTemplateRow;

  const [attributes, setAttributes] = React.useState({});
  const [isSaving, setIsSaving] = React.useState<boolean>(false);

  React.useEffect(() => {
    setAttributes(templateSection.attributes);
  }, [templateSection]);

  const { isOpen, handleClose, handleOpen } = useVisibility(false);

  const handleManualChange = (value, attribute) => setAttributes((previous) => ({ ...previous, [attribute]: value }));

  const handleChange = (event) => handleManualChange(event.target.value, event.target.name);

  const widths = {
    full: { columnCount: 1, widths: ['100%'] },
    halfs: { columnCount: 2, widths: ['50%', '50%'] },
    one_two: { columnCount: 2, widths: ['34%', '66%'] },
    thirds: { columnCount: 3, widths: ['33%', '33%', '33%'] },
    two_one: { columnCount: 2, widths: ['66%', '34%'] },
  };

  function elementHeight(templateSection) {
    // We render the relevant react component to a string
    const component = sectionDisplay(templateSection.reportTemplateSection.sectionType, templateSection);

    const sectionIndex = row.reportTemplatesReportTemplateSections
      .map((section) => section.id)
      .indexOf(templateSection.id);

    const componentData = lodashTimes(widths[row.width].columnCount).map((index) => {
      return (
        <div key={index} style={{ width: widths[row.width].widths[index] }}>
          {sectionIndex === index ? component : ''}
        </div>
      );
    });

    const componentTable = `<div class="pdf-main pad-v-0">
        <div class="frow">
          ${renderToString(componentData)}
        </div>
      </div>`;

    // Add that HTML to the dummy container ref that is on the cover page with 0 opacity
    $(dummyContainerRef.current).html(componentTable);
    // Measure the height of that dummy div
    const height = $(dummyContainerRef.current).outerHeight();
    // Remove all the HTML we just added so we can remove the div
    $(dummyContainerRef.current).empty();
    // Height comes back in pixels so we need to mess around with it to get a percentage height in relation to the page.
    // Portrait height is 842 and landscape height 600
    // Designs for the page have it at 842px so we need to do (height / 842) * 100 to get the percentage
    // TODO(BR): This might need rounding up at some point
    const pageHeight = isLandscape ? 600 : 842;
    return (height / pageHeight) * 100;
  }

  async function handleSave() {
    setIsSaving(true);
    for (const property in attributes) {
      if (attributes.hasOwnProperty(property)) {
        templateSection[property] = attributes[property];
      }
    }

    const changedAttributes = Object.keys(templateSection.changes());

    if (!changedAttributes.includes('height')) {
      templateSection.height = elementHeight(templateSection);
    }

    const success = await templateSection.save();

    if (success) {
      setIsSaving(false);
      handleClose();
      getData();
    }
  }

  const spacing = (
    <div className="col-mc-1-1">
      <label className="form__label" htmlFor="">
        Spacing
      </label>
      <div className="frow frow--gutters">
        <div className="col-mc-1-2">
          <div className="pretty p-icon">
            <input
              type="checkbox"
              name="top_spacing"
              value="1"
              defaultChecked={attributes['topSpacing']}
              onChange={() => handleManualChange(!attributes['topSpacing'], 'topSpacing')}
            />
            <div className="state p-blue">
              <i className="icon-tick icon icon-push-down-small" />
              <label>Top Spacing</label>
            </div>
          </div>
        </div>
        <div className="col-mc-1-2">
          <div className="pretty p-icon">
            <input
              type="checkbox"
              name="top_spacing"
              value="1"
              defaultChecked={attributes['bottomSpacing']}
              onChange={() => handleManualChange(!attributes['bottomSpacing'], 'bottomSpacing')}
            />
            <div className="state p-blue">
              <i className="icon-tick icon icon-push-down-small" />
              <label>Bottom Spacing</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <React.Fragment>
      <div className="button button--secondary button--light-themed button--icon mar-r-1" onClick={handleOpen}>
        <i className="icon-edit icon-fw" />
      </div>
      <CustomModal isOpen={isOpen} handleClose={handleClose} title="Edit Section" modifiers={['overflow-visible']}>
        <div className="modal__content">
          <div className="form form--light">
            <div className="frow frow--gutters">
              <div className="col-mc-1-1">
                {sectionEdit(
                  templateSection.reportTemplateSection.sectionType,
                  attributes,
                  handleChange,
                  handleManualChange,
                  isRepeating,
                )}
              </div>
              {!['theme_page', 'component_section'].includes(templateSection.reportTemplateSection.sectionType) &&
                spacing}
              <div className="col-mc-1-1">
                <Button
                  buttonClasses="button button--light-themed"
                  handleClick={handleSave}
                  text="Save"
                  isSubmitting={isSaving}
                  submittingText="Updating Report"
                />
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </React.Fragment>
  );
}
