import * as React from 'react';

import AutoResizeTextArea from 'components/shared/forms/AutoResizeTextArea';

export default function textMedium({ attributes, handleChange }) {
  return (
    <div className="frow frow--gutters">
      <div className="col-mc-1-1">
        <label className="form__label" htmlFor="">
          Introduction Copy
        </label>
        <AutoResizeTextArea name="text" id="text" rows={3} value={attributes.text} handleChange={handleChange} />
      </div>
    </div>
  );
}
