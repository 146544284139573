import * as React from 'react';

import Dropdown from './shared/Dropdown';
import { IPropsDDO } from './shared/DropdownOption';

export { IPropsDDO };

interface IProps {
  options: IPropsDDO[];
  selectedOption: IPropsDDO;
  handleSelect(option: IPropsDDO): void;
}

export default function managerSelect(props: IProps) {
  const { options, selectedOption, handleSelect } = props;

  return (
    <Dropdown
      name="Managers"
      dropdownOptions={options}
      selectedOption={selectedOption}
      handleSelect={handleSelect}
      iconClass="icon-users"
    />
  );
}
