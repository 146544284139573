import * as React from 'react';

import { Dialog } from '@blueprintjs/core';
import Loading from 'components/shared/Loading';

export default function submitting({ isSubmitting }) {
  return (
    <Dialog
      isOpen={isSubmitting}
      canEscapeKeyClose={false}
      portalContainer={document.body}
      canOutsideClickClose={false}
      isCloseButtonShown={false}
      title="Submitting"
    >
      <Loading />
    </Dialog>
  );
}
