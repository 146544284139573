import React, { useContext, useMemo } from 'react';
import { uniqueId } from 'lodash';
import { InvestmentRestrictionsContext } from '../context';
import useFlag from './useFlag';
import Slider from 'components/shared/Slider';
import ConfigurationSettings from './ConfigurationSettings';
import { Frow } from 'components/frow';

type Props = {
  flagType: string;
  namePrefix: string;
  label: string;
  reverse?: boolean;
  negative?: boolean;
  dotSlider?: boolean;
  min?: number;
  max?: number;
};

const SliderFlag = (props: Props) => {
  const { flagType, namePrefix, label, reverse, negative, dotSlider, min, max } = props;
  const { investmentRestrictionsSetting } = useContext(InvestmentRestrictionsContext);
  const [flag] = useFlag(investmentRestrictionsSetting, flagType);

  const name = `${namePrefix}[${uniqueId()}]`;

  if (!flag) return null;

  return (
    <Frow>
      <div className="col-md-4-10">
        <input type="hidden" name={`${name}[id]`} value={flag.id} />
        <input type="hidden" name={`${name}[flag_type]`} value={flagType} />
        <input type="hidden" name={`${name}[use_defaults]`} value={flag.useDefaults ? 1 : 0} />
        <Slider
          value={flag.value}
          label={label}
          name={`${name}[value]`}
          reverse={reverse}
          negative={negative}
          dotSlider={dotSlider}
          min={min}
          max={max}
        />
      </div>
      <div className="col-md-1-10" />
      <div className="col-md-5-10 pad-t-3">
        <ConfigurationSettings name={name} flag={flag} />
      </div>
    </Frow>
  );
};

export default SliderFlag;
