import * as React from 'react';

import { add, compose, equals, filter, pluck, prop, reduce, subtract } from 'ramda';

import Slider from 'rc-slider';

import { SecurityState, SecurityStateClassificationWeighting, SecurityClassification } from 'javascript/models';

import { useDefaultTheme } from 'components/shared/colours';
import ISelectOption from 'components/interfaces/ISelectOption';
import ReactSelect, {
  IReactSelectProps,
  objOptionGenerator,
  objOptionsGenerator,
} from 'components/shared/forms/ReactSelect';

import Context from '../../../../Context';
import useRerender from 'components/shared/customHooks/useRerender';
import { Frow, Column } from 'components/frow';

interface IProps {
  key: number;
  securityValue: SecurityStateClassificationWeighting;
  classifications: SecurityClassification[];
  classificationType: string;
  selectedClassificationState: SecurityState;
}

const classificationSelect = (props: IProps) => {
  const context = React.useContext(Context);

  const colourGen = useDefaultTheme('all');
  const rerender = useRerender();

  const { securityValue, classifications, classificationType, selectedClassificationState } = props;

  const accumulateWeightings = compose(reduce(add, 0), pluck('weighting'));
  const selectSameTypeWeightingsFromState = filter(compose(equals(classificationType), prop('valueType')));
  const availableWeight = compose(
    subtract(1),
    accumulateWeightings,
    selectSameTypeWeightingsFromState,
  )(selectedClassificationState.classificationWeightings);

  const classificationOptions = React.useMemo(() => objOptionsGenerator('name')(classifications), [classifications]);
  const setOverwriteClassifications = context.actions.setOverwriteClassifications;

  const handleDelete = () => {
    securityValue.isMarkedForDestruction = true;
  };

  const handleClassificationOverwrite = () => {
    if (securityValue.classificationSource === 'fin_mason') {
      setOverwriteClassifications(true);
      securityValue.classificationSource = 'enhance';
    }
  };

  const handleClassificationChange = (selection: ISelectOption) => {
    securityValue.classification = selection.value;
    handleClassificationOverwrite();
    rerender();
  };

  const handleSlideChange = (value: number) => {
    if (selectedClassificationState.status !== 'draft') return;

    securityValue.weighting = Math.min(parseFloat(value.toFixed(0)) / 100, securityValue.weighting + availableWeight);
    handleClassificationOverwrite();
    rerender();
  };

  const sliderProps = {
    onChange: handleSlideChange,
    trackStyle: [{ backgroundColor: colourGen(securityValue.classification && securityValue.classification.name) }],
    value: securityValue.weighting * 100,
  };

  const selectProps: IReactSelectProps = {
    handleChange: handleClassificationChange,
    id: 'classification',
    isDisabled: selectedClassificationState.status !== 'draft',
    options: classificationOptions,
    theme: 'dark',
    name: '',
    value: objOptionGenerator('name')(securityValue.classification),
  };

  return (
    <Column>
      <Frow justifyContent="between" itemAlignment="center" columnContext={{ maxColumns: 12 }}>
        <Column columnSpan={3}>
          <ReactSelect {...selectProps} />
        </Column>
        <Column columnSpan={6}>
          <Slider {...sliderProps} />
        </Column>
        <Column columnSpan={1}>
          <div className="text-white text-right">{Math.round(securityValue.weighting * 100)}</div>
        </Column>
        <div>
          <button
            className="button button--secondary button--icon button--danger"
            disabled={selectedClassificationState.status !== 'draft'}
            onClick={handleDelete}
          >
            <i className="icon-bin icon-fw" />
          </button>
        </div>
      </Frow>
    </Column>
  );
};

export default classificationSelect;
