import * as React from 'react';

import { Alert, Intent } from '@blueprintjs/core';

import useVisibility from 'components/shared/customHooks/useVisibility';
import { createAlert, graphitiErrorString } from 'components/shared/Utils';

export default function destroy({ investmentRiskAssessment, updateTable }) {
  const { handleClose, handleOpen, isOpen } = useVisibility(false);

  async function confirmDestroy() {
    let success = await investmentRiskAssessment.destroy();
    handleClose();
    if (success) {
      createAlert('success', 'MIR successfully deleted', 2500);
      updateTable();
    } else {
      createAlert('error', graphitiErrorString(investmentRiskAssessment), 2500);
    }
  }

  return (
    <React.Fragment>
      <div className="button button--secondary button--danger button--icon" onClick={handleOpen}>
        <i className="icon-bin icon-fw" />
      </div>
      <Alert
        isOpen={isOpen}
        onClose={handleClose}
        canEscapeKeyCancel
        canOutsideClickCancel
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        intent={Intent.DANGER}
        onCancel={handleClose}
        onConfirm={confirmDestroy}
        portalContainer={document.body}
      >
        <p className="mar-t-0">Are you sure you want to delete this MIR?</p>
      </Alert>
    </React.Fragment>
  );
}
