import * as React from 'react';

interface IProps {
  name: string;
  defaultValue?: boolean;
  changeHandler?(isChecked?: boolean): void;
}

export default function booleanQuestion(props: IProps) {
  const { changeHandler, defaultValue, name } = props;

  const [isChecked, setIsChecked] = React.useState(defaultValue);

  function handleChange() {
    if (changeHandler) {
      changeHandler(!isChecked);
    }
    setIsChecked(!isChecked);
  }

  return (
    <React.Fragment>
      <div className="pretty pretty--large pretty--with-label p-round">
        <input type="radio" name={name} value="1" onChange={handleChange} checked={isChecked} />
        <div className="state p-blue-circle">
          <label className="text-white">Yes</label>
        </div>
      </div>
      <div className="pretty pretty--large pretty--with-label p-round">
        <input type="radio" name={name} value="0" onChange={handleChange} checked={!isChecked} />
        <div className="state p-blue-circle">
          <label className="text-white">No</label>
        </div>
      </div>
    </React.Fragment>
  );
}

booleanQuestion.defaultProps = {
  defaultValue: false,
};
