import * as React from 'react';

import startcase from 'lodash.startcase';

import CriterionHumanizer from 'components/shared/CriterionHumanizer';
import RangeSlider from 'components/shared/forms/RangeSlider';
import { SubtitleOne } from 'components/shared/Typography';
import { Portfolio } from 'javascript/models/portal/Portfolio';
import Loading from 'components/shared/Loading';

export default function mandateFlags({ colors, portfolioObject }) {
  const { id } = portfolioObject;

  const [flags, setFlags] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    getPortfolio().then(() => setIsLoading(false));
  }, []);

  async function getPortfolio() {
    const { data } = await Portfolio.includes({ portfolio_mandate: { flags: 'criterion' } }).find(id);

    setFlags(data.portfolioMandate.flags);
  }

  const noFlags = (
    <tr>
      <td colSpan={6} className="text-small text-white mar-b-0">
        This portfolio has no flags set
      </td>
    </tr>
  );

  const renderFlags = () => {
    return flags.map((flag, index) => {
      const flagType = flag.flagType;
      const humanizedCriterion = CriterionHumanizer(flag.criterion.name, flagType);
      return (
        <React.Fragment key={index}>
          <tr>
            <td>{startcase(flagType)}</td>
            <td>{humanizedCriterion}</td>
            <td>{flag.aggregate ? 'Total Investment' : 'Any one Investment'}</td>
            <td style={{ textAlign: 'center' }}>{flag.minimum}</td>
            <td>
              <RangeSlider
                backgroundColor={colors[index % colors.length]}
                marks={{}}
                disabled
                defaultValue={[flag.minimum, flag.maximum]}
              />
            </td>
            <td style={{ textAlign: 'center' }}>{flag.maximum}</td>
          </tr>
        </React.Fragment>
      );
    });
  };

  function renderTable() {
    return (
      <div style={{ overflowX: 'auto' }}>
        <table className="styled-table styled-table--alt mar-t-1" style={{ width: '100%' }}>
          <thead>
            <tr>
              <th style={{ width: '17%' }}>Restriction Class</th>
              <th style={{ width: '15%' }}>Restriction</th>
              <th style={{ width: '23%' }}>Scope</th>
              <th style={{ width: '5%', textAlign: 'center' }} />
              <th style={{ width: '35%', textAlign: 'center' }}>Range</th>
              <th style={{ width: '5%', textAlign: 'center' }} />
            </tr>
          </thead>
          <tbody className="mar-t-1">{flags.length > 0 ? renderFlags() : noFlags}</tbody>
        </table>
      </div>
    );
  }

  return (
    <div className="col-md-1-1">
      <div className="card card--rounded card--dark card--full-height">
        <div className="platform-content platform-content--padding-all h-100">
          <SubtitleOne text="Investment Restrictions" additionalClasses="text-white" />
          {isLoading ? <Loading /> : renderTable()}
        </div>
      </div>
    </div>
  );
}
