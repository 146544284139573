import * as React from 'react';

import ReviewContext from '../ReviewContext';
import Table from 'components/shared/reactTable/Table';
import riskColumns from './columns/riskColumns';

export default function riskTable() {
  const context = React.useContext(ReviewContext);
  const { review } = context.props;
  const { risks } = review.attributes.metrics;

  let columnHeaders = [
    'portfolio',
    'enhance_benchmark',
    'cash_benchmark',
    'managers_benchmark',
    'peer_group',
    'target_return',
  ];

  if (risks.length) {
    // The slice gets rid of the 'Name' key (which describes the period we are talking about)
    columnHeaders = Object.keys(risks[0]).slice(1);
  }

  return (
    <Table
      columns={riskColumns(columnHeaders)}
      data={risks}
      hasColumnSelector={false}
      hasPagination={false}
      isLoading={false}
      manual={false}
      meta={{ count: risks.length }}
    />
  );
}
