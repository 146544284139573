import { Attr, HasMany, Model } from 'spraypaint';
import { ApplicationRecord } from './ApplicationRecord';
import { ConsultancyClientLetterOfEngagement } from './ConsultancyClientLetterOfEngagement';

@Model()
export class ConsultancyClient extends ApplicationRecord {
  public static jsonapiType = 'consultancy_clients';

  @Attr() public clientGrouping: string;
  @Attr() public entity: string;
  @Attr() public comments: string;
  @Attr() public editPath: string;

  @HasMany(ConsultancyClientLetterOfEngagement) lettersOfEngagement: ConsultancyClientLetterOfEngagement[];
}
