import * as React from 'react';

import { Scope, SpraypaintBase } from 'spraypaint';

import GraphitiTableWrapper from 'components/shared/GraphitiTableWrapper';

export interface ITableProps<Model extends SpraypaintBase> {
  columns: (updateColumns: any) => any;
  scope: Scope<Model>;
}

const table = <Model extends SpraypaintBase>(props: ITableProps<Model>) => {
  const { columns, scope } = props;
  const [shouldUpdateTable, setShouldUpdateTable] = React.useState(false);

  const handleTableUpdate = () => setShouldUpdateTable(true);

  React.useEffect(() => {
    if (shouldUpdateTable) {
      setShouldUpdateTable(false);
    }
  }, [shouldUpdateTable]);

  return (
    <GraphitiTableWrapper
      columns={columns(handleTableUpdate)}
      hasColumnSelector={false}
      scope={scope}
      shouldUpdateTable={shouldUpdateTable}
    />
  );
};

export default table;
