import * as React from 'react';

import ActionPoints from '../ActionPoints';

export default function consolidated({ colors, templateSection }) {
  const detailsPresent = templateSection && templateSection.tableDetails;
  const selectedOption = detailsPresent ? templateSection.tableDetails['option'] : 'all';

  const consolidatedCommentary = (
    <div>
      <h3 className="pdf-small-subtitle mar-b-2">EIDEMO0003 (Consolidated Portfolio)</h3>
      <div className="pdf-body-copy-wrap">
        <p className="pdf-body-copy">
          Review commentary goes here. E.g. In terms of asset allocation the portfolio has 4.2% in cash, 43.09% in
          bonds...
        </p>
      </div>
    </div>
  );

  const consolidatedActionPoints = (
    <div className="mar-v-2">
      <ActionPoints
        actionPoints={[
          {
            body:
              "Please forward a copy of the 'investment management agreement' with details about the investment " +
              'objectives and risk profile.',
            new: true,
            type: 'administration',
          },
        ]}
      />
    </div>
  );

  const divider = (
    <div className="mar-v-2">
      <div className="pdf-divider pdf-divider--section" style={{ borderColor: colors[0] }} />
    </div>
  );

  const constituentCommentsAndActionPoints = (
    <div>
      <div className="mar-v-2">
        <h3 className="pdf-small-subtitle mar-b-2">EIDEMO0001 (Sample Portfolio 1 - Investment Manager 1 - USD)</h3>
        <div className="pdf-body-copy-wrap">
          <p className="pdf-body-copy">
            Review commentary goes here. E.g We have not received a copy of the investment management agreement in
            place, so cannot fully...
          </p>
        </div>
      </div>
      <div className="mar-v-2">
        <ActionPoints
          actionPoints={[
            {
              body:
                "Please forward a copy of the 'investment management agreement' with details about the investment " +
                'objectives and risk profile.',
              new: true,
              type: 'administration',
            },
          ]}
        />
      </div>
      <div className="mar-v-2">
        <div className="pdf-divider pdf-divider--section" style={{ borderColor: colors[0] }} />
      </div>
      <div className="mar-v-2">
        <h3 className="pdf-small-subtitle mar-b-2">EIDEMO0002 (Sample Portfolio 2 - Investment Manager 2 - USD)</h3>
        <div className="pdf-body-copy-wrap">
          <p className="pdf-body-copy">
            Review commentary goes here. E.g During the fourth quarter of 2018 the portfolio fell by -10.83%, compared
            with the Enhance USD Balanced benchmark which fell by -5.52% and the Managers benchmark...
          </p>
        </div>
      </div>
      <div className="mar-t-2">
        <ActionPoints
          actionPoints={[
            {
              body:
                "Please forward a copy of the 'investment management agreement' with details about the investment " +
                'objectives and risk profile.',
              new: true,
              type: 'administration',
            },
          ]}
        />
      </div>
    </div>
  );

  if (selectedOption === 'consolidated_commentary') {
    return <div>{consolidatedCommentary}</div>;
  }

  if (selectedOption === 'consolidated_commentary_ap') {
    return (
      <div>
        {consolidatedCommentary}
        {consolidatedActionPoints}
      </div>
    );
  }

  return (
    <div>
      {consolidatedCommentary}
      {consolidatedActionPoints}
      {divider}
      {constituentCommentsAndActionPoints}
    </div>
  );
}
