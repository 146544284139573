import * as React from 'react';

import * as ReactTooltip from 'react-tooltip';

import { format, parse } from 'date-fns';
import lodashStartcase from 'lodash.startcase';
import { platformSecurityPath } from 'javascript/application/ts_routes';
const numeral = require('numeral');

const columns = () => [
  {
    Cell: (row) => <div>{format(parse(row.original.valueDate), 'DD MMM YYYY')}</div>,
    Header: 'Date',
    accessor: 'value_date',
    id: 'value_date',
  },
  {
    Cell: (row) => {
      let { description } = row.original;
      return (
        <div>
          <span data-tip={description}>{description || '-'}</span>
          <ReactTooltip effect="solid" delay={300} />
        </div>
      );
    },
    Header: 'Description',
    accessor: 'description',
    id: 'description',
  },
  {
    Cell: (row) => (
      <div>{row.original.activeAssetClasses ? lodashStartcase(row.original.activeAssetClasses) : '-'}</div>
    ),
    Header: 'Active Asset Classes',
    accessor: 'headlineAssetClasses',
    id: 'headlineAssetClasses',
  },
  {
    Cell: (row) => <div>{row.original.useLookthroughAssetClass ? 'True' : 'False'}</div>,
    Header: 'Use Lookthrough ACs',
    accessor: 'useLookthroughOverHeadline',
    id: 'useLookthroughOverHeadline',
  },
  {
    Cell: (row) => (
      <div>{row.original.headlineAssetClass ? lodashStartcase(row.original.headlineAssetClass) : '-'}</div>
    ),
    Header: 'Headline Asset Class',
    accessor: 'headlineAssetClass',
    id: 'headlineAssetClass',
  },
  {
    Cell: (row) => <div>{row.original.isin ? row.original.isin : '-'}</div>,
    Header: 'ISIN',
    accessor: 'isin',
    id: 'isin',
  },
  {
    Cell: (row) => {
      let securityName = '';
      const { security } = row.original;
      if (security) securityName = security.name;

      return (
        <div>
          <span data-tip={securityName}>{securityName || '-'}</span>
          <ReactTooltip effect="solid" delay={300} />
        </div>
      );
    },
    Header: 'Security Name',
    accessor: 'security_name',
    id: 'security_name',
  },
  {
    Cell: (row) => <div style={{ textAlign: 'right' }}>{row.original.quantity ? row.original.quantity : '-'}</div>,
    Header: 'Quantity',
    accessor: 'quantity',
    id: 'quantity',
  },
  {
    Cell: (row) => <div>{row.original.currency ? row.original.currency.toUpperCase() : '-'}</div>,
    Header: 'Currency',
    accessor: 'currency',
    id: 'currency',
  },
  {
    Cell: (row) => (
      <div style={{ textAlign: 'right' }} className="frow frow--items-center">
        {numeral(row.original.percentage).format('0,0.00')}%
      </div>
    ),
    Header: 'Percentage',
    accessor: 'percentage',
    id: 'percentage',
  },
  {
    Cell: (row) => {
      if (!row.original.security) return null;

      return (
        <a href={platformSecurityPath(row.original.security.id)} style={{ display: 'block' }}>
          <div className="rt-tr-clickable-icon">
            <i className="icon-arrow-right-top icon-0-8x" />
          </div>
        </a>
      );
    },
  },
];

export default columns;
