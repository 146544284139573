import { Attr, HasMany, Model } from 'spraypaint';
import { ApplicationRecord } from './ApplicationRecord';
import { TrackedJob } from './TrackedJob';
import { AdministratorPermission } from './permissions/Administrator';
import generateRecordWithPermission from './permissions/RecordWithPermissionFactory';

@Model()
export class Administrator extends generateRecordWithPermission(AdministratorPermission)(ApplicationRecord) {
  public static jsonapiType = 'administrators';
  @Attr() public fullName: string;
  @Attr() public firstName: string;
  @Attr() public lastName: string;
  @Attr() public email: string;
  @Attr() public title: string;
  @Attr() public photo: string;
  @Attr() public reportPhoto: string;
  @Attr() public quote: string;
  @Attr() public qualifications: string;
  @Attr() public switchboard: string;
  @Attr() public directDial: string;
  @Attr() public mobile: string;
  @Attr() public color: string;
  @Attr() public jobTitle: string;
  @Attr() public team: string;
  @Attr() public bio: string;
  @Attr() public signInCount: number;
  @Attr() public lastSignInAt: string;
  @Attr() public showPath: string;
  @Attr() public outstandingTrackedJobs: number;
  @HasMany() public permissions: AdministratorPermission[];
  @HasMany(TrackedJob) public trackedJobs: TrackedJob[];
}
