import * as React from 'react';

import BarChart from './sectionEdit/BarChart';
import BetaComparison from './sectionEdit/BetaComparison';
import Blank from './sectionEdit/Blank';
import Contents from './sectionEdit/Contents';
import CorrelationMatrix from './sectionEdit/CorrelationMatrix';
import DataTable from './sectionEdit/DataTable';
import Divider from './sectionEdit/Divider';
import ExtraSettings from './sectionEdit/ExtraSettings';
import HorizontalBarChart from './sectionEdit/HorizontalBarChart';
import LineChart from './sectionEdit/LineChart';
import List from './sectionEdit/List';
import MapVisual from './sectionEdit/MapVisual';
import MultiLayerPieChart from './sectionEdit/MultiLayerPieChart';
import PieChart from './sectionEdit/PieChart';
import PortfolioInformation from './sectionEdit/PortfolioInformation';
import Quote from './sectionEdit/Quote';
import Rating from './sectionEdit/Rating';
import RatingOverTime from './sectionEdit/RatingOverTime';
import ReviewComments from './sectionEdit/ReviewComments';
import ScatterChart from './sectionEdit/ScatterChart';
import Subheading from './sectionEdit/Subheading';
import SubtitleSection from './sectionEdit/SubtitleSection';
import StackedAreaChart from './sectionEdit/StackedAreaChart';
import TextMedium from './sectionEdit/TextMedium';
import TextSmall from './sectionEdit/TextSmall';
import Title from './sectionEdit/Title';
import ComponentSelect from './sectionEdit/ComponentSelect';

export function sectionEdit(type, attributes, handleChange, handleManualChange = null, isRepeating = null) {
  switch (type) {
    case 'area_chart':
      return <StackedAreaChart {...{ attributes, handleChange, handleManualChange, isRepeating }} />;
    case 'bar_chart':
      return <BarChart {...{ attributes, handleChange, handleManualChange, isRepeating }} />;
    case 'beta_comparison':
      return <BetaComparison {...{ attributes, handleChange, handleManualChange, isRepeating }} />;
    case 'blank_component':
      return <Blank {...{ attributes, handleChange }} />;
    case 'component_section':
      return <ComponentSelect {...{ attributes, handleChange, handleManualChange }} />;
    case 'contents':
      return <Contents />;
    case 'correlation_matrix':
      return <CorrelationMatrix {...{ attributes, handleChange, handleManualChange, isRepeating }} />;
    case 'data_table':
      return <DataTable {...{ attributes, handleChange, handleManualChange, isRepeating }} />;
    case 'divider':
      return <Divider {...{ attributes, handleChange, handleManualChange, isRepeating }} />;
    case 'extra_settings':
      return <ExtraSettings {...{ attributes, handleChange, handleManualChange, isRepeating }} />;
    case 'horizontal_bar_chart':
      return <HorizontalBarChart {...{ attributes, handleChange, handleManualChange, isRepeating }} />;
    case 'line_chart':
      return <LineChart {...{ attributes, handleChange, handleManualChange, isRepeating }} />;
    case 'list':
      return <List {...{ attributes, handleChange, handleManualChange }} />;
    case 'map_visual':
      return <MapVisual {...{ attributes, handleChange, handleManualChange }} />;
    case 'multi_layer_pie_chart':
      return <MultiLayerPieChart {...{ attributes, handleChange, handleManualChange, isRepeating }} />;
    case 'pie_chart':
      return <PieChart {...{ attributes, handleChange, handleManualChange, isRepeating }} />;
    case 'portfolio_information':
      return <PortfolioInformation {...{ attributes, handleChange, handleManualChange }} />;
    case 'quote':
      return <Quote {...{ attributes, handleChange }} />;
    case 'rating':
      return <Rating {...{ attributes, handleChange, handleManualChange, isRepeating }} />;
    case 'rating_over_time':
      return <RatingOverTime {...{ attributes, handleChange, handleManualChange }} />;
    case 'review_comments':
      return <ReviewComments {...{ attributes, handleChange, handleManualChange }} />;
    case 'scatter_chart':
      return <ScatterChart {...{ attributes, handleChange, handleManualChange, isRepeating }} />;
    case 'subheading':
      return <Subheading {...{ attributes, handleChange }} />;
    case 'subtitle':
      return <SubtitleSection {...{ attributes, handleChange }} />;
    case 'text_medium':
      return <TextMedium {...{ attributes, handleChange }} />;
    case 'text_small':
      return <TextSmall {...{ attributes, handleChange }} />;
    case 'theme_page':
      return <Title {...{ attributes, handleChange }} />;
    case 'title':
      return <Title {...{ attributes, handleChange }} />;
    default:
      return null;
  }
}
