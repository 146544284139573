import * as React from 'react';

import FormRow from 'components/shared/forms/FormRow';

interface IProps {
  referencePrefix: string;
  isComposite: boolean;
}

export default function reference(props: IProps) {
  const { isComposite, referencePrefix } = props;
  const [selectedPrefix, setSelectedPrefix] = React.useState(referencePrefix);

  function handlePrefixChange(event) {
    const prefix = event.target.value;
    if (prefix === 'EICBM') return;

    setSelectedPrefix(prefix);
  }

  const options = ['EICBM', 'EISBM', 'EIBMK'];

  const prefixOptions = options.map((option) => {
    const checked = (option === 'EICBM' && isComposite) || (selectedPrefix === option && !isComposite);
    const readOnly = option === 'EICBM' || (['EISBM', 'EIBMK'].includes(option) && isComposite);
    return (
      <div key={option} className="pretty pretty--large pretty--with-label p-round">
        <input
          type="radio"
          name="portfolio_benchmark[reference_prefix]"
          id="reference_prefix"
          value={option}
          checked={checked}
          onChange={handlePrefixChange}
          readOnly={readOnly}
        />
        <div className="state p-blue-circle">
          <label className="text-white">{option}</label>
        </div>
      </div>
    );
  });

  return (
    <FormRow note="" className="col-mc-1-1">
      <label htmlFor="reference_prefix" className="form__label">
        Reference Prefix
      </label>
      <div>{prefixOptions}</div>
    </FormRow>
  );
}
