import * as React from 'react';

import { Client } from 'javascript/models';

import { default as ModelModal, IModelModal } from 'components/modals/ModelModal';
import { IModalWithTriggerProps } from 'components/modals/ModalWithTrigger';
import useVisibility from 'components/shared/customHooks/useVisibility';
import Form from 'components/platform/clients/roles/Form';
import { TPermissionGroups } from 'components/platform/clients/Show';

export interface IRoleModalProps {
  addOrEdit: IModelModal['addOrEdit'];
  availablePerms: TPermissionGroups;
  roleable: Client;
  modalWithTriggerProps?: Partial<IModalWithTriggerProps>;
  formContent?: (props) => JSX.Element;
}

const ClientRoleModal = (props: IRoleModalProps) => {
  const { addOrEdit, availablePerms, roleable, modalWithTriggerProps, formContent } = props;
  const actionPointModalVisibility = modalWithTriggerProps.externalUseVisibility || useVisibility(false);

  const newModalWithTriggerProps = { ...modalWithTriggerProps, externalUseVisibility: actionPointModalVisibility };
  const params = {
    roleable,
    groupedPermissions: availablePerms,
    canEditPermissions: true,
    formContent,
    addOrEdit: addOrEdit,
  };

  return (
    <>
      <ModelModal {...{ addOrEdit }} modelName="client role" {...{ modalWithTriggerProps: newModalWithTriggerProps }}>
        <Form {...params} />
      </ModelModal>
    </>
  );
};

export default ClientRoleModal;
