import { Attr, Model } from 'spraypaint';
import { ApplicationRecord } from './ApplicationRecord';

@Model()
export class ClientTile extends ApplicationRecord {
  public static jsonapiType = 'client_tiles';
  @Attr() public description: string;
  @Attr() public editable: boolean;
  @Attr() public faviconSource: string;
  @Attr() public imageSource: string;
  @Attr() public image: any;
  @Attr() public name: string;
  @Attr() public resourceType: string;
  @Attr() public url: string;
}
