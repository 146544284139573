import * as React from 'react';
import ReactLineChart, { IReactLineChartProps } from '../recharts/ReactLineChart';
import Frow from '../../../frow/Frow';
import ModalGraph from '../../../modals/ModalGraph';
import useCheckbox from '../../customHooks/useCheckbox';

export interface ILineChartWithModal {
  isLoading: boolean;
  chartData: any;
  lineStyles: { [key: string]: { stroke: string; strokeDasharray?: string }};
  benchmarkOptions?: { initial: boolean; showToggle: boolean };
  lineChartModifiers?: Partial<IReactLineChartProps>;
  modalChartModifiers?: Partial<IReactLineChartProps>;
  radioToggle?: JSX.Element;
}

const LineChartWithModal = (lineChartWithModalProps: ILineChartWithModal) => {
  const {
    isLoading,
    chartData,
    lineChartModifiers,
    modalChartModifiers,
    radioToggle,
    lineStyles,
  } = lineChartWithModalProps;

  const { initial: benchmarkInitial, showToggle } = lineChartWithModalProps.benchmarkOptions;

  const [showBenchmarks, benchmarkToggle] = useCheckbox('Show Benchmarks', benchmarkInitial);

  const determineLines = (showBenchmarks: boolean, dataSeries: { label: string }[]) => {
    if (!dataSeries) return [];
    if (showBenchmarks) return dataSeries.map((dataObj) => dataObj.label);

    return ['portfolio'];
  };

  const lines = determineLines(showBenchmarks, chartData && chartData.length > 0 && chartData[0].series);

  const chartProps: IReactLineChartProps = {
    data: chartData,
    height: 225,
    leftMargin: 0,
    legend: false,
    lineStyles,
    lines,
    loadingData: isLoading,
    percentageValues: true,
    showXAxis: false,
    showYAxis: false,
    ...lineChartModifiers,
  };

  const modalModifiers: Partial<IReactLineChartProps> = {
    height: 500,
    legend: true,
    lines,
    showXAxis: true,
    showYAxis: true,
    ...modalChartModifiers,
  };

  if (chartData && chartData.length === 0)
    return <p className="helper-text text-white mar-t-3">No performance data to show</p>;

  return (
    <>
      <ReactLineChart {...chartProps} />
      <Frow {...{ justifyContent: 'between', additionalClassNames: 'mar-t-2' }}>
        <ModalGraph title="Performance">
          <>
            <div className="mar-b-2">{radioToggle}</div>
            <ReactLineChart {...{ ...chartProps, ...modalModifiers }} />
            <div className="mar-t-2">{showToggle && benchmarkToggle}</div>
          </>
        </ModalGraph>
        {showToggle && benchmarkToggle}
      </Frow>
    </>
  );
};

LineChartWithModal.defaultProps = { benchmarkOptions: { initial: true, showToggle: true } };

export default LineChartWithModal;
