import * as React from 'react';

import lodashStartcase from 'lodash.startcase';
const numeral = require('numeral');

export default function statisticsColumns(columnHeaders) {
  const formattedRow = (row, attribute) => {
    if (attribute === 'target_return' && row.original.name !== 'Correlation') return <div>N/A</div>;

    if (!row.original[attribute]) {
      return <div>-</div>;
    }

    if (row.original.name === 'Alpha (vs. Portfolio)') {
      return <div className="frow frow--items-center">{numeral(row.original[attribute]).format('0,0.00')}%</div>;
    }

    return <div className="frow frow--items-center">{numeral(row.original[attribute]).format('0,0.0000')}</div>;
  };

  const columns = columnHeaders.map((header, index) => {
    return {
      Cell: (row) => formattedRow(row, header),
      Header: lodashStartcase(header),
      accessor: header,
      id: header,
    };
  });

  columns.unshift({
    Header: 'Statistics',
    accessor: 'name',
    id: 'name',
  });

  return columns;
}
