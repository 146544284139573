import * as React from 'react';

import IReviewCommentaries from 'components/interfaces/IReviewCommentaries';
import FormTag from 'components/shared/forms/FormTag';
import Button from 'components/shared/forms/Button';
import TinyMce from 'components/shared/forms/TinyMce';

interface IProps {
  reviewCommentaries: IReviewCommentaries;
  updatePath: string;
  userId: string;
  handleClose(): void;
  handleSubmit(event: React.SyntheticEvent): void;
}

export default function longform(props: IProps) {
  const { userId } = props;

  const longformCommentary = props.reviewCommentaries.data.find((commentary) => {
    return commentary.attributes.commentaryType === 'longform';
  });
  const initialCommentary = longformCommentary ? longformCommentary.attributes.commentary : '';
  const [commentaryText, setCommentaryText] = React.useState(initialCommentary);

  function handleTextChange(textValue) {
    setCommentaryText(textValue);
  }

  const id = longformCommentary ? longformCommentary.id : '';

  return (
    <div className="modal__content">
      <FormTag className="form form--light" method="patch" url={props.updatePath} onSubmit={props.handleSubmit}>
        <div className="frow frow--gutters">
          <div className="col-mc-1-1">
            <label className="form__label" htmlFor="">
              Commentary
            </label>
            <input type="hidden" name="review[review_commentaries_attributes][0][id]" value={id} />
            <input type="hidden" name="review[review_commentaries_attributes][0][author_id]" value={userId} />
            <input type="hidden" name="review[review_commentaries_attributes][0][commentary_type]" value="longform" />
            <input type="hidden" name="review[review_commentaries_attributes][0][commentary]" value={commentaryText} />
            <TinyMce initialValue={commentaryText} handleChange={handleTextChange} height={500} />
          </div>
          <div className="col-md-2-3">
            <Button text="Save and Close" buttonClasses="button--full-width button--light-themed" />
          </div>
          <div className="col-md-1-3">
            <Button
              inputType="button"
              text="Cancel"
              buttonClasses="button--full-width button--light-themed"
              handleClick={props.handleClose}
            />
          </div>
        </div>
      </FormTag>
    </div>
  );
}
