import * as React from 'react';

import { format, subISOYears } from 'date-fns';
import { SecurityState } from 'javascript/models';

import ReactSelect from 'components/shared/forms/ReactSelect';
import ISelectOption, { ISelectProps } from 'components/interfaces/ISelectOption';
import { monthDateRange } from 'components/shared/utilities/dateRange';

interface IProps {
  selectedState: SecurityState;
  handleCreateDraftClassificationState: (referenceDate: string, useCurrentState: boolean) => void;
}

export default function draftClassificationOptions(props: IProps) {
  const { selectedState, handleCreateDraftClassificationState } = props;

  const currentStateDateOption: ISelectOption = {
    label: format(selectedState.referenceDate, 'MMM YYYY'),
    value: selectedState.referenceDate.toString(),
  };

  const [draftReferenceDateOption, setDraftReferenceDateOption] = React.useState(currentStateDateOption);
  const [useCurrentState, setUseCurrentState] = React.useState(true);

  const handleUseCurrentStateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUseCurrentState(event.target.checked);
  };

  const handleReferenceDateChange = (option: ISelectOption) => {
    setDraftReferenceDateOption(option);
  };

  const dateOptions: ISelectOption[] = monthDateRange(subISOYears(Date.now(), 1), new Date()).map((date) => ({
    label: format(date, 'MMM YYYY'),
    value: date,
  }));

  const selectProps: ISelectProps = {
    handleChange: handleReferenceDateChange,
    id: 'draft_classification_state',
    name: 'current_state',
    options: dateOptions.reverse(),
    theme: 'light',
    value: draftReferenceDateOption,
  };

  const handleSave = () => {
    handleCreateDraftClassificationState(draftReferenceDateOption.value, useCurrentState);
  };

  return (
    <React.Fragment>
      <div className="frow frow--justify-between">
        <h4 className="mar-t-1">Create Draft Classification State</h4>
        <div className="frow col-sm-1-2 pad-l-2">
          <ReactSelect {...selectProps} />
        </div>
      </div>
      <div className="col-mc-1-2 frow">
        <div className="pretty p-icon">
          <input
            type="checkbox"
            name="useCurrentClassificationState"
            checked={useCurrentState}
            onChange={handleUseCurrentStateChange}
          />
          <div className="state p-blue">
            <i className="icon-tick icon icon-push-down-small" />
            <label className="text-black">Use Current Classification State Values</label>
          </div>
        </div>
        <button className="button button-secondary button--compact mar-t-9" onClick={handleSave}>
          Create
        </button>
      </div>
    </React.Fragment>
  );
}
