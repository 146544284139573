import * as React from 'react';

import { format, parse } from 'date-fns';

import Button from 'components/shared/forms/Button';
import lodashStartcase from 'lodash.startcase';

import { createAlert } from 'components/shared/Utils';

export default function template({ dataTemplate }) {
  const { body, emailType, subject, updatedAt } = dataTemplate;
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  async function handleSubmit(event) {
    event.preventDefault();
    const form = new FormData(event.target);
    setIsSubmitting(true);
    dataTemplate.subject = form.get('subject');
    dataTemplate.body = form.get('body');
    const success = await dataTemplate.save();
    if (success) {
      createAlert('success', 'Data Chase Template Updated', 1500);
    }
    setIsSubmitting(false);
  }

  return (
    <div className="col-md-1-2">
      <div className="card card--no-hover">
        <div className="card__inner">
          <p className="card__title">{lodashStartcase(emailType)}</p>
          <form className="form mar-t-1" onSubmit={handleSubmit}>
            <div className="frow frow--gutters">
              <div className="col-mc-1-1">
                <label htmlFor="subject" className="form__label">
                  Subject
                </label>
                <input type="text" name="subject" defaultValue={subject} />
              </div>
              <div className="col-mc-1-1">
                <label htmlFor="subject" className="form__label">
                  Body
                </label>
                <textarea rows={20} style={{ overflow: 'scroll' }} name="body" defaultValue={body} />
              </div>
              <div className="col-mc-1-1">
                <Button text="Update" inputType="submit" isSubmitting={isSubmitting} submittingText="Updating" />
              </div>
            </div>
          </form>
        </div>
        <footer className="card__footer">
          <span className="text-white helper-text">
            Last Updated {format(parse(updatedAt), 'DD MMM YYYY [at] HH:mm')}
          </span>
        </footer>
      </div>
    </div>
  );
}
