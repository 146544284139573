import * as React from 'react';
import CustomModal from 'components/shared/CustomModal';
import useVisibility from 'components/shared/customHooks/useVisibility';
import FormFilesDropzone from 'components/shared/forms/FormFilesDropzone';

interface IProps {
  isSubmitting: boolean;
  handleUploadedFiles: (overwrite: boolean) => (files: File[]) => void;
}

const presenter = (props: IProps) => {
  const { handleUploadedFiles, isSubmitting } = props;
  const { handleClose, handleOpen, isOpen } = useVisibility(false);

  const [overwrite, setOverwrite] = React.useState(false);

  const updateOverwrite = (event) => {
    setOverwrite(event.target.checked);
  };

  const formFilesDropzoneProps = {
    disableBottomMargin: true,
    displayFiles: false,
    dropzoneProps: {
      acceptedFiles: '.csv, .xls, .xlt, .xlm, .xlsx, .xlsm, .xltx, .xltm, .xlsb, .xla, .xlam, .xll, .xlw',
      isLarge: true,
      isSubmitting,
    },
    updateStoredFiles: handleUploadedFiles(overwrite),
    storedFiles: [],
  };

  return (
    <div>
      <button className="button button--secondary button--compact" onClick={handleOpen}>
        Upload Files
      </button>
      <CustomModal isOpen={isOpen} handleClose={handleClose} title="Upload Files">
        <div className="form--light modal__content">
          <form className="frow frow--gutters">
            <div className="col-mc-1-1">
              <div className="pretty p-icon">
                <input type="checkbox" checked={overwrite} onChange={updateOverwrite} />

                <div className="state p-blue">
                  <i className="icon-tick icon text-white icon-push-down-small" />
                  <label htmlFor="overwrite" className="form__label">
                    overwrite existing data
                  </label>
                </div>
              </div>
            </div>
            <div>
              <FormFilesDropzone {...formFilesDropzoneProps} />
            </div>
          </form>
        </div>
      </CustomModal>
    </div>
  );
};

export default presenter;
