import * as React from 'react';

import { Bar, BarChart, CartesianGrid, Legend, ReferenceLine, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { chartColorsV2 } from './chartColors';
import Context from '../Context';

export const CustomLegend = (props) => {
  const { payload } = props;

  const legends = payload.map((payloadItem, index) => {
    const lineStyles = {
      background: payloadItem.payload.fill,
      borderRadius: '50%',
      display: 'inline-block',
      height: '10px',
      marginBottom: '-2px',
      width: '10px',
    };

    return (
      <span key={index} className="">
        <span style={lineStyles} className="mar-r-1" />
        <span className="mar-r-2" style={{ fontSize: '0.7rem', whiteSpace: 'nowrap' }}>
          {payloadItem.dataKey}
        </span>
      </span>
    );
  });

  return (
    <div style={{ border: '1px solid #e4e4e8', padding: '0 0 5px', textAlign: 'center' }} className="mar-t-2">
      {legends}
    </div>
  );
};

export const CustomHorizontalLegend = (props) => {
  const { payload } = props;

  const legends = payload.map((payloadItem, index) => {
    const lineStyles = {
      background: payloadItem.payload.fill,
      border: `2px solid ${payloadItem.payload.stroke}`,
      borderRadius: '50%',
      display: 'inline-block',
      height: '10px',
      width: '10px',
    };

    return (
      <div
        key={index}
        className="col-mc-1-1"
        style={{ marginBottom: index + 1 !== payload.length ? '8px' : 0, fontSize: '0.8rem' }}
      >
        <div
          className={`platform-content ${index + 1 !== payload.length && 'platform-content--border-bottom-light-grey'}`}
          style={{ paddingBottom: index + 1 !== payload.length ? '5px' : '0px' }}
        >
          <table className="pdf-hidden-table">
            <tbody>
              <tr className="pad-a-1">
                <td style={{ fontSize: '8px', paddingRight: '5px', width: '10%' }}>
                  <span style={lineStyles} />
                </td>
                <td style={{ fontSize: '8px' }}>{payloadItem.dataKey}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  });

  return (
    <div style={{ border: '1px solid #e4e4e8' }} className="mar-l-2 pad-a-1">
      {legends}
    </div>
  );
};

export default function betaComparison({ colors, templateSection }) {
  const context = React.useContext(Context);
  const { reportTemplate } = context.state;
  const legendBottom = templateSection && templateSection.legendPosition === 'bottom';
  const chartColors = chartColorsV2();

  const defaultSeries = ['Portfolio'];

  const series = templateSection && templateSection.barChartSeries ? templateSection.barChartSeries : defaultSeries;

  const data = [
    { name: 'Benchmark', Portfolio: 1.2, 'Consolidated Portfolio': 1.2, 'Constituent Portfolios': 1 },
    { name: 'Fixed Income', Portfolio: 0.1, 'Consolidated Portfolio': 1.2, 'Constituent Portfolios': 1 },
    { name: 'Equity', Portfolio: 0.7, 'Consolidated Portfolio': 1.2, 'Constituent Portfolios': 1 },
    { name: 'Hedge', Portfolio: 0.5, 'Consolidated Portfolio': 1.2, 'Constituent Portfolios': 1 },
  ];

  const bars = series.map((dataPoint, index) => {
    return (
      <Bar
        key={dataPoint}
        isAnimationActive={false}
        barSize={10}
        dataKey={dataPoint}
        fill={chartColors(reportTemplate, colors, dataPoint, index, !templateSection)}
      />
    );
  });

  function renderLegend() {
    if (legendBottom) {
      return <Legend content={<CustomLegend />} iconType="line" />;
    }
    return <Legend layout="vertical" verticalAlign="top" align="right" content={<CustomHorizontalLegend />} />;
  }

  function renderBarChart() {
    return (
      <ResponsiveContainer width="100%" height={legendBottom ? 200 : 140}>
        <BarChart
          width={500}
          height={legendBottom ? 200 : 140}
          data={data}
          margin={{ top: 5, right: 15, left: 0, bottom: 0 }}
        >
          <CartesianGrid stroke="#e4e4e8" vertical={false} />
          <XAxis
            dataKey="name"
            interval={0}
            tick={{ fill: '#b5b6bd', fontSize: '8px', fontWeight: 'bold' }}
            tickSize={0}
            tickMargin={10}
          />
          <YAxis
            interval={0}
            tick={{ fill: '#b5b6bd', fontSize: '8px', fontWeight: 'bold' }}
            tickSize={0}
            tickMargin={5}
            tickFormatter={() => 'Label'}
          />
          <ReferenceLine y={0} stroke="#000" />
          {bars}
          {renderLegend()}
        </BarChart>
      </ResponsiveContainer>
    );
  }

  return <div>{renderBarChart()}</div>;
}
