import * as React from 'react';

import { format, parse, subYears } from 'date-fns';

import ReactAreaChart from 'components/shared/charting/recharts/ReactAreaChart';

import { Balance } from 'javascript/models';
import Context from '../../Context';

export default function chart({ selectedYear }) {
  const context = React.useContext(Context);
  const { id, lastQuarterlyBalanceDate } = context.props.portfolio.attributes;

  const [data, setData] = React.useState([]);
  const [meta, setMeta] = React.useState({ maxData: 0, minData: 0 });
  const [isLoading, setIsLoading] = React.useState(true);

  let isMounted = true;

  React.useEffect(() => {
    return () => (isMounted = false);
  }, []);

  React.useEffect(() => {
    getBalances();
  }, [selectedYear]);

  async function getBalances() {
    let scope = Balance.where({ balanceable_id: id, balanceable_type: 'Portfolio' })
      .order({ value_date: 'asc' })
      .stats({ data: ['max', 'min'] })
      .per(1000);

    if (selectedYear !== 0) {
      scope = scope.where({
        value_date: {
          gt: format(subYears(parse(new Date(lastQuarterlyBalanceDate)), selectedYear), 'YYYY-MM-DD'),
          lte: format(parse(lastQuarterlyBalanceDate)),
        },
      });
    }

    const { data, meta } = await scope.all();
    if (!isMounted) return;

    setMeta({ maxData: meta.stats.data.max, minData: meta.stats.data.min });
    setData(data);
    setIsLoading(false);
  }

  const chartData = data.map((balance) => {
    return { name: balance.attributes.valueDate, amount: balance.attributes.amount };
  });

  return (
    <React.Fragment>
      <ReactAreaChart data={chartData} isLoading={isLoading} maxData={meta.maxData} minData={meta.minData} />
    </React.Fragment>
  );
}
