import * as React from 'react';

import { SortScope } from 'spraypaint';

import GraphitiTableWrapper from 'components/shared/GraphitiTableWrapper';

import columns from './table/columns';

import { Manager } from 'javascript/models';

export default function graphitiTable() {
  const selectedAttributes = [
    'name',
    'upload_templates_show_path',
    'manager_user_count',
    'portfolio_count',
    'active_upload_template',
    'last_data_uploaded_on',
  ];

  const scope = Manager.select(selectedAttributes);

  const initialSort: SortScope = { active_upload_template: 'desc' };

  return (
    <GraphitiTableWrapper
      scope={scope}
      columns={columns()}
      hasHeader
      initialSort={initialSort}
      isSearchable
      searchPlaceholder="Search by name"
      title="Manager Uploads"
      identifier="managerUpload:index"
    />
  );
}
