import * as React from 'react';

import { format, lastDayOfQuarter, subQuarters } from 'date-fns';

import ReactSelect from 'components/shared/forms/ReactSelect';
import FormRow from 'components/shared/forms/FormRow';
import QuarterPicker from 'components/shared/forms/QuarterPicker';
import ISelectOption from 'components/interfaces/ISelectOption';
import { Manager } from 'javascript/models';
import useDebounce from 'components/shared/customHooks/useDebounce';

interface IProps {
  clientOptions: ISelectOption[];
  companyId: string;
  currencyOptions: ISelectOption[];
  riskOptions: ISelectOption[];
  serviceTypeOptions: ISelectOption[];
  startQuarter: string;
  handleOptionChange(value): void;
}

export default function sliceAndDice(props: IProps) {
  const {
    clientOptions,
    companyId,
    currencyOptions,
    handleOptionChange,
    riskOptions,
    serviceTypeOptions,
    startQuarter,
  } = props;

  const lastQuarter = format(lastDayOfQuarter(subQuarters(new Date(), 1)), 'YYYY-MM-DD');

  const [inputValue, setInputValue] = React.useState('');
  const [managerOptions, setManagerOptions] = React.useState([]);
  const [options, setOptions] = React.useState({ review_date: lastQuarter });

  function handleOptionsChange(selectedOption) {
    const updatedOptions = { ...options };
    updatedOptions[this] = selectedOption.value;
    setOptions(updatedOptions);
  }

  function handleReviewPeriodChange(selectedDate) {
    const updatedOptions = { ...options };
    updatedOptions['review_date'] = selectedDate;
    setOptions(updatedOptions);
  }

  React.useEffect(() => {
    handleOptionChange({ value: options });
  }, [options]);

  const debouncedInputValue = useDebounce(inputValue, 600);

  function handleInputChange(input) {
    setInputValue(input);
  }

  React.useEffect(() => {
    if (debouncedInputValue && debouncedInputValue.length > 2) {
      getManagers();
    }
  }, [debouncedInputValue]);

  async function getManagers() {
    const { data } = await Manager.where({ company_id: companyId })
      .where({ selectable: true })
      .where({ search: { match: inputValue } })
      .all();

    const managerOptions = data.map((manager) => ({ value: manager.id, label: manager.name }));
    setManagerOptions(managerOptions);
  }

  const frequencyOptions = [
    { label: 'Monthly', value: '1' },
    { label: 'Quarterly', value: '3' },
    { label: 'Half Yearly', value: '6' },
    { label: 'Annually', value: '12' },
  ];

  return (
    <React.Fragment>
      <FormRow>
        <label htmlFor="extract_options" className="form__label">
          Client
        </label>
        <ReactSelect
          options={clientOptions}
          id="client_option"
          name="client_id"
          theme="light"
          handleChange={handleOptionsChange.bind('client_id')}
          isClearable
        />
      </FormRow>
      {/*<FormRow>*/}
      {/*  <label htmlFor="extract_options" className="form__label">Manager</label>*/}
      {/*  <ReactSelect*/}
      {/*    options={managerOptions}*/}
      {/*    id="manager_option"*/}
      {/*    name="manager"*/}
      {/*    theme="light"*/}
      {/*    handleChange={handleOptionsChange.bind('manager')}*/}
      {/*    isClearable*/}
      {/*    placeholder="Search for a Manager to select"*/}
      {/*    // inputValue={inputValue}*/}
      {/*    handleInputChange={handleInputChange}*/}
      {/*  />*/}
      {/*</FormRow>*/}
      {/*<FormRow>*/}
      {/*  <label htmlFor="extract_options" className="form__label">Risk profile</label>*/}
      {/*  <ReactSelect*/}
      {/*    options={riskOptions}*/}
      {/*    id="risk_option"*/}
      {/*    name="risk"*/}
      {/*    theme="light"*/}
      {/*    handleChange={handleOptionsChange.bind('risk_profile')}*/}
      {/*    isClearable*/}
      {/*  />*/}
      {/*</FormRow>*/}
      {/*<FormRow>*/}
      {/*  <label htmlFor="extract_options" className="form__label">Currency</label>*/}
      {/*  <ReactSelect*/}
      {/*    options={currencyOptions}*/}
      {/*    id="currency_option"*/}
      {/*    name="currency"*/}
      {/*    theme="light"*/}
      {/*    handleChange={handleOptionsChange.bind('currency')}*/}
      {/*    isClearable*/}
      {/*  />*/}
      {/*</FormRow>*/}
      <FormRow>
        <label htmlFor="extract_options" className="form__label">
          Service Type
        </label>
        <ReactSelect
          options={serviceTypeOptions}
          id="service_type_option"
          name="service_type"
          theme="light"
          handleChange={handleOptionsChange.bind('service_type')}
          isClearable
        />
      </FormRow>
      {/*<FormRow>*/}
      {/*  <label htmlFor="extract_options" className="form__label">Data Frequency</label>*/}
      {/*  <ReactSelect*/}
      {/*    options={frequencyOptions}*/}
      {/*    id="data_frequency_option"*/}
      {/*    name="data_frequency"*/}
      {/*    theme="light"*/}
      {/*    handleChange={handleOptionsChange.bind('data_frequency')}*/}
      {/*    isClearable*/}
      {/*  />*/}
      {/*</FormRow>*/}
      <FormRow>
        <QuarterPicker
          id="review_date_option"
          name="review_date"
          theme="light"
          label="Review Period"
          startDate={startQuarter}
          defaultValue={lastQuarter}
          handleChange={handleReviewPeriodChange}
          reverseDates
        />
      </FormRow>
    </React.Fragment>
  );
}
