import * as React from 'react';

import { TodoItem } from 'javascript/models';

interface IProps {
  handleUpdateItems(): void;
}

export default function newItem(props: IProps) {
  const { handleUpdateItems } = props;

  const [body, setBody] = React.useState('');

  function handleChange(event) {
    setBody(event.target.value);
  }

  async function handleSubmit(event) {
    event.preventDefault();
    if (!body) return;

    const todoItem = new TodoItem({ body });

    const success = await todoItem.save();

    if (success) {
      setBody('');
      handleUpdateItems();
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="todo-list__form">
        <div className="frow frow--nowrap frow--items-center">
          <i className="icon-plus icon-clickable mar-r-2" onClick={handleSubmit} />
          <div className="col-flex-grow-1">
            <input
              className="todo-list__input"
              placeholder="Add new to-do..."
              type="text"
              onChange={handleChange}
              value={body}
            />
          </div>
        </div>
      </div>
    </form>
  );
}
