import * as React from 'react';
import * as $ from 'jquery';

import Countdown from 'react-countdown';
import { SubtitleTwo } from 'components/shared/Typography';
import QuarterPicker from 'components/shared/forms/QuarterPicker';
import Button from 'components/shared/forms/Button';
import CustomModal from 'components/shared/CustomModal';
import useVisibility from 'components/shared/customHooks/useVisibility';
import { endOfQuarter, format, parse } from 'date-fns';
import { createAlert } from 'components/shared/Utils';
import { bulkCreatePlatformDashboardReviewsPath } from 'javascript/application/ts_routes';

// tslint:disable-next-line:function-name
export default function NextReviewQuarter() {
  const { isOpen, handleClose, handleOpen } = useVisibility(false);

  function handleSubmit() {
    $.ajax({
      beforeSend: () => setIsSubmitting(true),
      complete: () => setIsSubmitting(false),
      contentType: false,
      data: { review_date: quarter },
      dataType: 'json',
      error: () => {
        createAlert('error', 'There was a problem creating the reviews', 1500);
      },
      success: () => {
        handleClose();
        const trackedJobEvent = new CustomEvent('trackedJob:new');
        document.dispatchEvent(trackedJobEvent);
        createAlert('success', 'Successfully queue review creation. This can take a minute', 1500);
      },
      type: 'GET',
      url: bulkCreatePlatformDashboardReviewsPath(),
    });
  }

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [quarter, setQuarter] = React.useState();

  function handleChange(selectedQuarter) {
    setQuarter(selectedQuarter);
  }

  const startDate = format(parse(new Date(new Date().setFullYear(new Date().getFullYear() - 4))), 'YYYY-MM-DD');

  return (
    <div className="frow frow--gutters-2x frow--direction-column">
      <div>
        <SubtitleTwo text="Next review period starts in" additionalClasses="text-white mar-b-0" />
        <Countdown className="text-big text-white text-center" date={endOfQuarter(new Date())} />
      </div>
      <div>
        <button className="button button--secondary" onClick={handleOpen}>
          Create new reviews
        </button>
      </div>
      <CustomModal {...{ isOpen, handleClose }} title="Create review" modifiers={['visible-overflow']}>
        <div className="modal__content">
          <div className="form form--light">
            <div className="frow frow--gutters-2x">
              <div className="col-mc-1-1">
                <QuarterPicker
                  {...{ handleChange, startDate }}
                  label="Review end date"
                  theme="light"
                  name="review[end_date]"
                  id="review_end_date"
                  optionCount={17}
                  reverseDates
                />
              </div>
              <div className="col-mc-1-1">
                <Button
                  buttonClasses="button--light-themed"
                  text="Create"
                  isSubmitting={isSubmitting}
                  submittingText="Creating"
                  isDisabled={!quarter}
                  handleClick={handleSubmit}
                />
              </div>
              <div className="col-mc-1-1">
                <p className="helper-text">
                  The start date for each review will be determined by the review time period set on the portfolio, or
                  the date from which we have performance information for the portfolio whichever is latest.
                </p>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );
}
