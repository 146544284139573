import * as React from 'react';

import AutoResizeTextArea from 'components/shared/forms/AutoResizeTextArea';
import Button from 'components/shared/forms/Button';

interface IProps {
  isSubmitting?: boolean;
  resource?: any;
  handleChange?(event: any): void;
}

export default function form(props: IProps) {
  const { resource } = props;

  return (
    <div className="frow frow--gutters-2x">
      <div className="col-sm-1-1">
        <div className="frow frow--gutters">
          <div className="col-mc-1-1">
            <label htmlFor="title" className="form__label">
              Title
            </label>
            <input
              type="text"
              name="commentary[title]"
              id="title"
              onChange={props.handleChange}
              defaultValue={resource.title}
            />
          </div>
          <div className="col-mc-1-1">
            <AutoResizeTextArea
              id="body"
              label="Commentary"
              maxLength={4000}
              name="commentary[body]"
              rows={10}
              value={resource.body}
              handleChange={props.handleChange}
            />
          </div>
        </div>
      </div>
      <div className="col-mc-1-1">
        <Button
          buttonClasses="button--full-width button--light-themed"
          style={{ minWidth: 'unset' }}
          text="Save"
          isSubmitting={props.isSubmitting}
          submittingText="Saving"
        />
      </div>
    </div>
  );
}
