import * as React from 'react';

import { format } from 'date-fns';
import { apply, concat, flip, map, mapObjIndexed, props, subtract, sum, zip } from 'ramda';
import ReactPieChart from '../../../shared/charting/recharts/ReactPieChart';
import Loading from '../../../shared/Loading';
import { Portfolio } from 'javascript/models/portal/Portfolio';
import ClientPortalCard from '../../shared/ClientPortalCard';
import IPieChartData from '../../../shared/charting/recharts/interfaces/IPieChartData';
import useChartFilter from '../../../shared/customHooks/useChartFilter';
import PieChartTableLegend from './PieChartTableLegend';
import stateToggle from './shared/stateToggle';
import EmapCparRadioToggles, { PortfolioType } from './EmapCparRadioToggles';
import { useClientPortalTheme } from 'components/shared/colours/themes/clientPortal';
import { Frow, Column } from 'components/frow';

const reviewStatusGroups = {
  'Awaiting Data': ['awaiting_data'],
  Complete: 'sent',
  'In Progress': ['in_progress', 'in_review', 'verification', 'ready_to_send'],
  null: null,
};

const groupedReviewStats = (reviewCountStats) => {
  const statuses = ['awaiting_data', 'in_progress', 'complete'];
  const cparStatuses = map(flip(concat)('_cpars'), statuses);

  const countProps = flip(props)(reviewCountStats);

  const all = countProps(statuses);
  const cpar = countProps(cparStatuses);

  const breakdownSum = (values) => ({ breakdown: values, sum: sum(values) });
  const dataObj = mapObjIndexed(breakdownSum)({
    all,
    cpar,
    emap: map(apply(subtract), zip(all, cpar)),
  });

  return dataObj;
};

const reviewBreakdown = ({ clientId, selectedDate, filterStateFunc }) => {
  const [data, setData] = React.useState<IPieChartData[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [reviewStats, setReviewStats] = React.useState<any>();
  const [selectedSlice, setSelectedSlice] = filterStateFunc('reviewStatusAtSelectedDate');
  const [portfolioType, setPortfolioType] = React.useState<PortfolioType>('all');

  const handleSelect = React.useCallback(
    (slice) => {
      const selectedGroup = reviewStatusGroups[slice];
      if (!selectedGroup) return;
      stateToggle(setSelectedSlice, false)(selectedGroup);
    },
    [selectedSlice],
  );

  useChartFilter(data, setData, 'filterKey', selectedSlice);

  const filteredReviewStats = reviewStats && reviewStats[portfolioType];

  React.useEffect(() => {
    setIsLoading(true);
    getPortfolios().then(() => setIsLoading(false));
  }, [selectedDate]);

  React.useEffect(() => {
    if (!filteredReviewStats) return;
    setData(generateData(filteredReviewStats));
  }, [filteredReviewStats]);

  const calculatePercentage = (portion: number, whole: number): number => {
    if (whole === 0) return 0;
    return (portion * 100) / whole;
  };

  const colourGen = useClientPortalTheme('reviewBreakdown');

  const getPortfolios = async () => {
    if (!selectedDate) return null;
    const formattedDate = format(selectedDate, 'YYYY-MM-DD');

    const scope = Portfolio.per(0)
      .where({ review_date: formattedDate, status: 'live', client_id: clientId })
      .extraParams({ selected_date: formattedDate })
      .stats({
        count: [
          'awaiting_data',
          'awaiting_data_cpars',
          'in_progress',
          'in_progress_cpars',
          'complete',
          'complete_cpars',
          'total',
        ],
      });
    const { meta } = await scope.all();
    setReviewStats(groupedReviewStats(meta.stats.count));
  };

  const awaitingColour = colourGen('awaitingData');
  const inProgressColour = colourGen('inProgress');
  const completeColour = colourGen('complete');

  const generateData = (selectedReviewData) => {
    const filteredTotal = selectedReviewData.sum;

    return [
      {
        color: awaitingColour,
        filterKey: 'awaiting_data',
        name: 'Awaiting Data',
        opacity: data.length > 0 ? data[0].opacity : '1',
        value: calculatePercentage(selectedReviewData.breakdown[0], filteredTotal),
      },
      {
        color: inProgressColour,
        filterKey: ['in_progress', 'in_review', 'verification', 'ready_to_send'],
        name: 'In Progress',
        opacity: data.length > 0 ? data[1].opacity : '1',
        value: calculatePercentage(selectedReviewData.breakdown[1], filteredTotal),
      },
      {
        color: completeColour,
        filterKey: 'sent',
        name: 'Complete',
        opacity: data.length > 0 ? data[2].opacity : '1',
        value: calculatePercentage(selectedReviewData.breakdown[2], filteredTotal),
      },
    ];
  };

  const titleControls = () => {
    if (!reviewStats) return <Loading height="auto" />;
    const {
      emap: { sum: emapReviews },
      cpar: { sum: cparReviews },
      all: { sum: total },
    } = reviewStats;

    return (
      <EmapCparRadioToggles
        filterState={filterStateFunc('portfolio_type')}
        emapReviews={emapReviews}
        cparReviews={cparReviews}
        totalReviews={total}
        portfolioType={portfolioType}
        setPortfolioType={setPortfolioType}
      />
    );
  };

  const renderData = () => {
    const filteredTotal = filteredReviewStats.sum;

    return (
      <Frow columnContext={{ columnSpan: 1, maxColumns: 2 }}>
        <Column>
          <ReactPieChart
            activeShape
            animationBegin={50}
            animationDuration={600}
            data={data}
            height={320}
            labelText={filteredTotal}
            noData={filteredTotal < 1}
            displayLegend={false}
            setSelectedSlice={handleSelect}
          />
        </Column>
        <PieChartTableLegend
          headerTitle="Risk Profile"
          {...{ data }}
          description="Breakdown of the progress of portfolios with a review in the selected quarter."
        />
      </Frow>
    );
  };

  return (
    <ClientPortalCard title="Review Progress" titleControls={titleControls}>
      {isLoading || !reviewStats ? <Loading /> : renderData()}
    </ClientPortalCard>
  );
};

export default reviewBreakdown;
