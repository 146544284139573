import * as React from 'react';
import CustomModal from 'components/shared/CustomModal';
import useVisibility from 'components/shared/customHooks/useVisibility';
import Button from 'components/shared/forms/Button';
import { Portfolio, PortfoliosBenchmark } from 'javascript/models';

import useDebounce from 'components/shared/customHooks/useDebounce';
import ReactSelect from 'components/shared/forms/ReactSelect';

const AssignBenchmarkToPortfolio = (props) => {
  const { isOpen, handleOpen, handleClose } = useVisibility(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const [options, setOptions] = React.useState([]);
  const [selectedPortfolio, setSelectedPortfolio] = React.useState(null);
  const [selectedBenchmarkPriority, setSelectedBenchmarkPriority] = React.useState(null);

  const [inputValue, setInputValue] = React.useState('');
  const debouncedInputValue = useDebounce(inputValue, 300);

  const portfolioBenchmarkId = props.id;
  const { benchmarkType } = props;
  const benchmarkPriorityOptions = [
    { label: 'Primary Benchmark', value: { primaryBenchmark: 1 } },
    { label: 'Secondary Benchmark', value: { secondaryBenchmark: 1 } },
    { label: 'Tertiary Benchmark', value: { tertiaryBenchmark: 1 } },
  ];

  React.useEffect(() => {
    if (!debouncedInputValue) {
      return;
    }
    setIsLoading(true);
    const loadOptions = async () => {
      const { data } = await Portfolio.where({ search: { match: debouncedInputValue } }).all();
      setOptions(data.map((portfolio) => ({ label: portfolio.reference, value: portfolio.id })));
      setIsLoading(false);
    };
    loadOptions();
  }, [debouncedInputValue]);

  const handleSubmit = React.useCallback(async (portfolioId, benchmarkPriority) => {
    setIsSubmitting(true);
    const benchmark = new PortfoliosBenchmark({
      benchmarkType,
      portfolioBenchmarkId,
      portfolioId,
      primaryBenchmark: benchmarkPriority && Object.keys(benchmarkPriority)[0].includes('primaryBenchmark') ? 1 : 0,
      secondaryBenchmark: benchmarkPriority && Object.keys(benchmarkPriority)[0].includes('secondaryBenchmark') ? 1 : 0,
      tertiaryBenchmark: benchmarkPriority && Object.keys(benchmarkPriority)[0].includes('tertiaryBenchmark') ? 1 : 0,
    });

    const response: boolean = await benchmark.save();
    setIsSubmitting(false);
    if (response) {
      handleClose();
    }
  }, []);
  return (
    <>
      <div onClick={handleOpen}>Assign To Portfolio</div>
      <CustomModal {...{ isOpen, handleClose }} title="Assign Benchmark To Portfolio" modifiers={['visible-overflow']}>
        <div className="modal__content">
          <div className="form form--light">
            <div className="frow frow--gutters-2x">
              <div className="col-mc-1-1">
                <label className="form__label">Select Portfolio</label>
                <div>
                  <ReactSelect
                    options={options}
                    placeholder="Search By Reference"
                    theme="light"
                    isLoading={isLoading}
                    handleInputChange={(e) => setInputValue(e)}
                    onChange={(selection) => setSelectedPortfolio(selection.value)}
                  />
                </div>
              </div>
              <div className="col-mc-1-1">
                <label className="form__label">Benchmark Priority</label>
                <div>
                  <ReactSelect
                    options={benchmarkPriorityOptions}
                    placeholder="Select Benchmark Priority"
                    theme="light"
                    isClearable
                    onChange={(selection) => setSelectedBenchmarkPriority(selection ? selection.value : null)}
                  />
                </div>
              </div>
              <div className="col-mc-1-1">
                <Button
                  buttonClasses="button--light-themed"
                  text="Submit"
                  isSubmitting={isSubmitting}
                  submittingText="Assigning Benchmark"
                  isDisabled={false}
                  handleClick={() => handleSubmit(selectedPortfolio, selectedBenchmarkPriority)}
                />
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default AssignBenchmarkToPortfolio;
