import * as React from 'react';

import { CartesianGrid, Label, Legend, Line, LineChart, XAxis, YAxis } from 'recharts';

const CustomYAxisPrint = (props) => {
  const height = props.height;
  const { x, y } = props.viewBox;
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={-(height / 2)}
        y={20}
        textAnchor="start"
        fill="#333"
        transform="rotate(-90)"
        fontWeight="bold"
        fontSize="16px"
      >
        RATING
      </text>
    </g>
  );
};

function CustomizedDot(props) {
  const { cx, cy, payload } = props;
  const colors = ['#b81c1c', '#CF7838', '#CFB538', '#B7C14F', '#78AE6E'];

  const ratingScore = payload.Rating[0];
  const roundedRating = Math.round(ratingScore);
  const color = roundedRating === 0 ? '#777881' : colors[roundedRating - 1];

  return <circle cx={cx} cy={cy} r={8} fill={color} />;
}

export default function ratingOverTime({ data, height, width }) {
  return (
    <LineChart width={width} height={height} data={data} margin={{ top: 10, right: 20, left: 0, bottom: 0 }}>
      <CartesianGrid stroke="#e4e4e8" />
      <XAxis
        dataKey="name"
        interval={0}
        tick={{ fill: '#b5b6bd', fontSize: '12px', fontWeight: 'bold' }}
        tickSize={0}
        tickMargin={8}
      />
      <YAxis
        interval={0}
        tick={{ fill: '#b5b6bd', fontSize: '16px', fontWeight: 'bold' }}
        ticks={[1, 2, 3, 4, 5]}
        domain={[0, 5]}
      >
        <Label content={<CustomYAxisPrint height={height} />} />
      </YAxis>
      <Line
        strokeWidth="1"
        type="linear"
        dataKey={'Rating'}
        stroke="#AAA"
        strokeDasharray="6 6"
        dot={<CustomizedDot />}
        isAnimationActive={false}
      />
    </LineChart>
  );
}
