import * as React from 'react';
import { format, parse } from 'date-fns';
import BreachIndicator from './BreachIndicator';
import { Frow } from 'components/frow';
import ActionPointCell from './ActionPointCell';
import ResultCell from './ResultCell';
import { indicators } from './BreachIndicator';

const ratingOptions = {
  1: 'one',
  2: 'two',
  3: 'three',
  4: 'four',
  5: 'five',
};

const ratingStyle = (ratingValue) => ratingOptions[ratingValue] || '';

const RatingColumn = ({ values }) => {
  const containerStyles = { paddingRight: '5px', width: '31px', display: 'inline-block' };
  const ratings = values.reverse().map((rating, index) => {
    if (!rating) return <span style={containerStyles} />;

    const roundedRating = Math.round(rating);

    if (rating === 'N/A') {
      return (
        <span key={index} style={containerStyles}>
          <span className="no-rating-box" style={{ padding: '0.6rem 1.25rem' }} />
        </span>
      );
    }
    return (
      <span key={index} style={containerStyles}>
        <span
          className={`rating-box rating-box--${ratingStyle(roundedRating)}`}
          style={{
            border: '1px solid',
            fontSize: '0.9rem',
            padding: '0.6rem 0.6rem',
          }}
        >
          {rating}
        </span>
      </span>
    );
  });
  return <div>{ratings}</div>;
};

const showActionPointColumn = (checkList) => {
  return checkList.some((row) => row.suggested_action_text);
};

const actionPointColumn = (review, checkList) => {
  if (showActionPointColumn(checkList)) {
    return {
      Cell: (row) => (
        <ActionPointCell
          suggestedActionText={(row.original && row.original.suggested_action_text) || ''}
          actionPointType={(row.original && row.original.action_point_type) || ''}
          review={review}
        />
      ),
      Header: 'Action Points',
      accessor: 'actionPoints',
      columnName: 'Action Points',
      id: 'actionPoints',
      sortable: false,
      show: true,
    };
  } else {
    return null;
  }
};

const DescriptionColumn = ({ row }) => {
  const statusColor = indicators(row.original.status).color;
  return (
    <Frow itemAlignment="center">
      <div style={{ backgroundColor: statusColor, borderRadius: '50%', height: 10, width: 10, marginRight: 7 }}></div>
      <div>{row.original.test}</div>
    </Frow>
  );
};

const columns = ({ review, checkList }) =>
  [
    {
      Cell: (row) => <DescriptionColumn {...{ row }} />,
      Header: 'Description',
      accessor: 'description',
      columnName: 'Description',
      id: 'description',
      sortable: false,
      width: 250,
    },
    {
      Cell: (row) => <div>{row.original.criteria}</div>,
      Header: 'Test Performed',
      accessor: 'uploadedAt',
      columnName: 'Test Performed',
      id: 'testPerformed',
      sortable: false,
      width: 300,
    },
    {
      Cell: (row) => {
        {
          switch (row.original.test) {
            case 'Low consecutive ratings':
              return <RatingColumn values={row.original.plain_text_values} />;
            default:
              return (
                <ResultCell values={row.original.plain_text_values} breachCountText={row.original.breach_count_text} />
              );
          }
        }
      },
      Header: 'Test Result',
      accessor: 'testResult',
      columnName: 'Test Result',
      headerClassName: 'hide-md-flex',
      id: 'testResult',
    },
    {
      Cell: (row) => <BreachIndicator status={row.original.status} reason={''} />,
      Header: 'Breach Comments',
      accessor: 'BreachComments',
      columnName: 'Breach Comments',
      id: 'BreachComments',
      sortable: false,
      width: 210,
    },
    { ...actionPointColumn(review, checkList) },
    {
      Header: '',
      sortable: false,
    },
  ].filter((n) => n); // Remove null values

export default columns;
