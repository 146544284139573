import * as React from 'react';

const classNames = require('classnames');

interface IProps {
  handleSectionChange(section: string): void;
}

export default function portfolioSectionSelect(props: IProps) {
  const tabTitles = ['Review & Notes', 'Balances', 'Allocations'];
  const [selectedTab, setSelectedTab] = React.useState('Review & Notes');

  React.useEffect(() => {
    props.handleSectionChange(selectedTab);
  }, [selectedTab]);

  function renderTabs() {
    return tabTitles.map((tab, index) => {
      const tabClass = classNames('tabbed-nav__tab', 'col-flex-grow-1', 'tabbed-nav__tab--right-bordered', {
        'tabbed-nav__tab--active': selectedTab === tab,
      });
      return (
        <div key={index} className={tabClass} onClick={() => setSelectedTab(tab)}>
          <span className="text-bold letter-spacing-1">{tab}</span>
        </div>
      );
    });
  }

  return <div className="tabbed-nav">{renderTabs()}</div>;
}
