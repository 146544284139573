import * as React from 'react';

import Information from './service/Information';

import Context from './Context';
import { ReportTemplate } from 'javascript/models';

export default function service() {
  const context = React.useContext(Context);
  const { portfolio } = context.state;

  const [reportTemplates, setReportTemplates] = React.useState([]);

  React.useEffect(() => {
    getReportTemplates();
  }, []);

  async function getReportTemplates() {
    const { data } = await ReportTemplate.per(999)
      .select(['id', 'name'])
      .where({ consolidated: portfolio.consolidated })
      .all();

    const formattedTemplates = data.map((template) => ({ value: template.id, label: template.name }));
    setReportTemplates(formattedTemplates);
  }

  return <Information {...{ reportTemplates }} />;
}
