import * as React from 'react';

import DatePicker from 'react-datepicker';
import lodashStartcase from 'lodash.startcase';

import FormRow from 'components/shared/forms/FormRow';

import ReactSelect from 'components/shared/forms/ReactSelect';
import BooleanOption from 'components/shared/forms/BooleanOption';

import IEntity from 'components/interfaces/IEntity';
import ISelectOption from 'components/interfaces/ISelectOption';

interface IProps {
  amlRiskRatings: ISelectOption[];
  entity: { data: IEntity };
}

export default function aml(props: IProps) {
  const { amlRiskRatings, entity } = props;

  const {
    amlNote,
    amlRiskRating,
    amlStatus,
    batchScreen,
    bearShares,
    dateLastReview,
    dateNextReview,
    edd,
    eddNote,
    pepLinked,
    sdd,
    sddNote,
    sourceOfFunds,
    sourceOfWealth,
  } = entity.data.attributes;

  const [nextReviewDate, setNextReviewDate] = React.useState(new Date(dateNextReview));
  const [lastReviewDate, setLastReviewDate] = React.useState(new Date(dateLastReview));

  function handleNextReviewDateChange(date) {
    setNextReviewDate(date);
  }

  function handleLastReviewDateChange(date) {
    setLastReviewDate(date);
  }

  return (
    <fieldset className="form__fieldset">
      <h4 className="form__fieldset-label">AML Details</h4>
      <div className="frow frow--gutters">
        <FormRow>
          <label htmlFor="source_of_wealth" className="form__label">
            Source of Wealth
          </label>
          <textarea name="entity[source_of_wealth]" id="source_of_wealth" defaultValue={sourceOfWealth} />
        </FormRow>
        <FormRow>
          <label htmlFor="source_of_funds" className="form__label">
            Source of Funds
          </label>
          <textarea name="entity[source_of_funds]" id="source_of_funds" defaultValue={sourceOfFunds} />
        </FormRow>
        <FormRow>
          <label htmlFor="aml_risk_rating" className="form__label">
            AML Risk Rating
          </label>
          <ReactSelect
            name="entity[aml_risk_rating]"
            theme="dark"
            options={amlRiskRatings}
            id="aml_risk_rating"
            defaultValue={{ value: amlRiskRating, label: lodashStartcase(amlRiskRating) }}
          />
        </FormRow>
        <FormRow>
          <label htmlFor="pep_linked" className="form__label">
            Pep Linked
          </label>
          <BooleanOption name="entity[pep_linked]" defaultValue={pepLinked} />
        </FormRow>
        <FormRow>
          <label htmlFor="bear_shares" className="form__label">
            Bear Shares
          </label>
          <BooleanOption name="entity[bear_shares]" defaultValue={bearShares} />
        </FormRow>
        <FormRow>
          <label htmlFor="bear_shares" className="form__label">
            AML Status
          </label>
          <BooleanOption name="entity[aml_status]" defaultValue={amlStatus} />
        </FormRow>
        <FormRow>
          <label htmlFor="sdd" className="form__label">
            SDD
          </label>
          <BooleanOption name="entity[sdd]" defaultValue={sdd} />
        </FormRow>
        <FormRow>
          <label htmlFor="edd" className="form__label">
            EDD
          </label>
          <BooleanOption name="entity[edd]" defaultValue={edd} />
        </FormRow>
        <FormRow>
          <label htmlFor="aml_note" className="form__label">
            AML Note
          </label>
          <textarea name="entity[aml_note]" id="aml_note" defaultValue={amlNote} />
        </FormRow>
        <FormRow>
          <label htmlFor="sdd_note" className="form__label">
            SDD Note
          </label>
          <textarea name="entity[sdd_note]" id="sdd_note" defaultValue={sddNote} />
        </FormRow>
        <FormRow>
          <label htmlFor="edd_note" className="form__label">
            EDD Note
          </label>
          <textarea name="entity[edd_note]" id="edd_note" defaultValue={eddNote} />
        </FormRow>
        <FormRow>
          <label htmlFor="date_next_review" className="form__label">
            Next Review Date
          </label>
          <DatePicker
            name="entity[date_next_review]"
            id="date_next_review"
            selected={nextReviewDate}
            onChange={handleNextReviewDateChange}
          />
        </FormRow>
        <FormRow>
          <label htmlFor="date_last_review" className="form__label">
            Last Review Date
          </label>
          <DatePicker
            name="entity[date_last_review]"
            id="date_last_review"
            selected={lastReviewDate}
            onChange={handleLastReviewDateChange}
          />
        </FormRow>
        <FormRow>
          <label htmlFor="edd" className="form__label">
            Batch Screen
          </label>
          <BooleanOption name="entity[batch_screen]" defaultValue={batchScreen} />
        </FormRow>
      </div>
    </fieldset>
  );
}
