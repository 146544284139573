import * as React from 'react';

import DatePicker from 'react-datepicker';

import Address from './Address';
import FormRow from 'components/shared/forms/FormRow';
import BooleanOption from 'components/shared/forms/BooleanOption';
import ReactSelect from 'components/shared/forms/ReactSelect';

import ISelectOption from 'components/interfaces/ISelectOption';
import IEntity from 'components/interfaces/IEntity';
import CountrySelect from 'components/shared/forms/CountrySelect';

interface IProps {
  entity: { data: IEntity };
  regulators: ISelectOption[];
  shortForm: boolean;
}

export default function legal(props: IProps) {
  const { entity, regulators, shortForm } = props;

  const {
    countryEstablishment,
    dateEstablishment,
    fullName,
    emailAddress,
    lei,
    name,
    nameRegulator,
    phoneNumber,
    registrationCode,
    taxResidence,
  } = entity.data.attributes;

  const [establishmentDate, setEstablishmentDate] = React.useState(new Date(dateEstablishment));
  const [usConnection, setUsConnection] = React.useState(entity.data.attributes.usConnection);

  function handleDateChange(date) {
    setEstablishmentDate(date);
  }

  function handleUsConnectionChange() {
    setUsConnection(!usConnection);
  }

  const longFormAttributes = (
    <React.Fragment>
      <FormRow>
        <label htmlFor="establishment_date" className="form__label">
          Date Establishment
        </label>
        <DatePicker
          name="entity[establishment_date]"
          id="establishment_date"
          selected={establishmentDate}
          onChange={handleDateChange}
        />
      </FormRow>
      <FormRow>
        <label htmlFor="country_establishment" className="form__label">
          Country Establishment
        </label>
        <CountrySelect name="entity[country_establishment]" country={countryEstablishment} />
      </FormRow>
      <FormRow>
        <label htmlFor="registration_code" className="form__label">
          Registration Code
        </label>
        <input type="text" name="entity[registration_code]" id="registration_code" defaultValue={registrationCode} />
      </FormRow>
      <FormRow>
        <label htmlFor="lei" className="form__label">
          LEI
        </label>
        <input type="text" name="entity[lei]" id="lei" defaultValue={lei} />
      </FormRow>
      <FormRow>
        <label htmlFor="lei" className="form__label">
          US Connection
        </label>
        <BooleanOption
          name="entity[us_connection]"
          changeHandler={handleUsConnectionChange}
          defaultValue={usConnection}
        />
      </FormRow>
      <FormRow>
        <label htmlFor="email_address" className="form__label">
          Email Address
        </label>
        <input type="email" name="entity[email_address]" id="email_address" defaultValue={emailAddress} />
      </FormRow>
      <FormRow>
        <label htmlFor="phone_number" className="form__label">
          Phone Number
        </label>
        <input type="text" name="entity[phone_number]" id="phone_number" defaultValue={phoneNumber} />
      </FormRow>
      <FormRow>
        <label htmlFor="tax_residence" className="form__label">
          Tax Residence
        </label>
        <CountrySelect name="entity[tax_residence]" country={taxResidence} />
      </FormRow>
      <FormRow>
        <label htmlFor="name_regulator" className="form__label">
          Regulator
        </label>
        <ReactSelect
          name="entity[name_regulator]"
          theme="dark"
          options={regulators}
          id="name_regulator"
          defaultValue={{ value: nameRegulator, label: nameRegulator ? nameRegulator.toUpperCase() : '' }}
        />
      </FormRow>
    </React.Fragment>
  );

  return (
    <fieldset className="form__fieldset">
      <h4 className="form__fieldset-label">Legal Person or Arrangement Details</h4>
      <div className="frow frow--gutters">
        <FormRow>
          <label htmlFor="full_name" className="form__label">
            Name
          </label>
          <input type="text" name="entity[name]" defaultValue={name} />
        </FormRow>
        <Address {...{ entity, shortForm }} />
        {!shortForm && longFormAttributes}
      </div>
    </fieldset>
  );
}
