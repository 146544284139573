import * as React from 'react';

export default function captureRatioTable({ large, showHeaders, templateSection }) {
  const comparison =
    templateSection && templateSection.tableComparison ? templateSection.tableComparison : 'Benchmark 1';

  const headers = (
    <thead>
      <tr>
        <th style={{ width: '34%', verticalAlign: 'top' }} className="text-right" />
        <th style={{ width: '16.5%', verticalAlign: 'top' }} className="text-right" />
        <th style={{ width: '16.5%', verticalAlign: 'top' }} className="text-right">
          Up Capture Ratio
        </th>
        <th style={{ width: '16.5%', verticalAlign: 'top' }} className="text-right">
          Down Capture Ratio
        </th>
        <th style={{ width: '16.5%', verticalAlign: 'top' }} className="text-right">
          Overall Capture Ratio
        </th>
      </tr>
    </thead>
  );

  return (
    <table className={`pdf-table pdf-table--striped ${large && 'pdf-table--large'}`}>
      {showHeaders && headers}
      <tbody>
        <tr>
          <td className="pdf-table__row-label">Portfolio (vs.) {comparison}</td>
          <td className="pdf-table__negative-value text-right" />
          <td className="text-right">Value</td>
          <td className="text-right">Value</td>
          <td className="text-right">Value</td>
        </tr>
      </tbody>
    </table>
  );
}
