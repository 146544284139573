import { Attr, Model } from 'spraypaint';
import { ApplicationRecord } from './ApplicationRecord';

@Model()
export class NotesNotable extends ApplicationRecord {
  public static jsonapiType = 'notes_notables';
  @Attr() public notableId: string;
  @Attr() public notableType: string;
  @Attr() public noteId: string;
}
