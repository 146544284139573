import * as React from 'react';

import Presenter from './search/Presenter';

interface IProps {
  autoFocus?: boolean;
  includes: string[];
  searchPath: string;
  searches: string[];
}

export default function search(props: IProps) {
  const selectableModels = [
    'All',
    'Portfolios',
    'Clients',
    'Managers',
    'Individuals',
    'Entities',
    'Admins',
    'Benchmarks',
  ];

  const { autoFocus, searchPath } = props;
  const formRef = React.useRef(null);

  const [selectedModels, setSelectedModels] = React.useState(props.includes.length ? props.includes : selectableModels);
  const [hasSearchChanged, setHasSearchChanged] = React.useState(false);

  const formElement = React.useRef(null);

  function handleSelectedModel(model: string) {
    if (model === 'All' && selectedModels.includes(model)) {
      setSelectedModels([]);
    } else if (model === 'All' && !selectedModels.includes(model)) {
      setSelectedModels(selectableModels);
    } else {
      setSelectedModels(selectedModels.toggle(model));
    }
  }

  // When we remove an element this is set to true which submits the form
  // Done this way because its essentially just a normal HTML form that we use React to change the input values on
  React.useEffect(() => {
    if (hasSearchChanged) {
      formRef.current.click();
    }
  }, [hasSearchChanged]);

  return (
    <Presenter
      {...{
        autoFocus,
        formElement,
        formRef,
        handleSelectedModel,
        searchPath,
        selectableModels,
        selectedModels,
      }}
      searches={props.searches}
    />
  );
}
