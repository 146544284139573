import React from 'react';
import RoleSelector from './RoleSelector';

const columns = ({ user }) => {
  return [
    {
      Header: 'Client',
      accessor: 'name',
      id: 'client',
    },
    {
      Header: 'Role',
      accessor: 'role',
      id: 'role',
      Cell: (row) => <RoleSelector {...{ user, roleable: row.original }} />,
    },
  ];
};

export default columns;
