/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb.

/* --------------------------------------------------
  Prototype extensions
-------------------------------------------------- */
import '../javascript/extensions';

/* --------------------------------------------------
  Polyfill
-------------------------------------------------- */
import 'core-js';
import 'isomorphic-fetch';

// Polyfill for custom events in IE
// https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent#Polyfill
(function () {
  if (typeof window.CustomEvent === 'function') return false;

  function CustomEvent(event, params) {
    params = params || { bubbles: false, cancelable: false, detail: null };
    var evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
    return evt;
  }

  window.CustomEvent = CustomEvent;
})();

/* --------------------------------------------------
  Turbolinks
-------------------------------------------------- */
import Turbolinks from 'turbolinks';
Turbolinks.start();

/* --------------------------------------------------
  Rails UJS (replaces jquery-ujs)
-------------------------------------------------- */
import Rails from 'rails-ujs';
Rails.start();

/* --------------------------------------------------
  Custom Javacript
-------------------------------------------------- */
import '../javascript';

/* --------------------------------------------------
  Styles
-------------------------------------------------- */
import '../stylesheets/application';

/* --------------------------------------------------
  Images - so we're adding them to the pack manifest
-------------------------------------------------- */
require.context('../images/', true, /\.(gif|jpg|png|svg)$/i);

// Support component names relative to this directory:
var componentRequireContext = require.context('components', true);
var ReactRailsUJS = require('react_ujs');
ReactRailsUJS.useContext(componentRequireContext);

/* --------------------------------------------------
  Register our service worker so we can claim this is a progressive web app
-------------------------------------------------- */
// if (navigator.serviceWorker) {
//   navigator.serviceWorker.register('/service-worker.js', { scope: './' })
//     .then(function(reg) {
//     });
// }

// Adding this line to force a recompilation of the assets
