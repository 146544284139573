import * as React from 'react';

import { CartesianGrid, Label, Legend, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { CustomYAxis } from './CustomComponents';

function CustomizedDot(props) {
  const { cx, cy, payload } = props;
  const colors = ['#b81c1c', '#CF7838', '#CFB538', '#B7C14F', '#78AE6E'];

  const ratingScore = payload.Rating[0];

  return <circle cx={cx} cy={cy} r={5} fill={colors[ratingScore - 1]} />;
}

export default function ratingOverTime({ colors, templateSection }) {
  let height = 150;
  if (templateSection && templateSection.size === 'medium') height = 300;
  if (templateSection && templateSection.size === 'large') height = 450;

  const data = [
    { name: 'Mar 17', Rating: [2] },
    { name: 'Jun 17', Rating: [1] },
    { name: 'Sep 17', Rating: [2] },
    { name: 'Dec 17', Rating: [3] },
    { name: 'Mar 18', Rating: [4] },
    { name: 'Jun 18', Rating: [3] },
    { name: 'Sep 18', Rating: [3] },
    { name: 'Dec 18', Rating: [4] },
    { name: 'Mar 19', Rating: [5] },
    { name: 'Jun 19', Rating: [4] },
    { name: 'Sep 19', Rating: [3] },
    { name: 'Dec 19', Rating: [3] },
  ];

  return (
    <ResponsiveContainer width="100%" height={height}>
      <LineChart width={450} height={height} data={data} margin={{ top: 5, right: 15, left: 0, bottom: 0 }}>
        <CartesianGrid stroke="#e4e4e8" />
        <XAxis
          dataKey="name"
          interval={0}
          tick={{ fill: '#b5b6bd', fontSize: '8px', fontWeight: 'bold' }}
          tickSize={0}
          tickMargin={8}
        />
        <YAxis
          interval={0}
          tick={{ fill: '#b5b6bd', fontSize: '8px', fontWeight: 'bold' }}
          ticks={[1, 2, 3, 4, 5]}
          domain={[0, 5]}
        >
          <Label content={<CustomYAxis name="Rating" height={height} />} />
        </YAxis>
        <Line
          strokeWidth="1"
          type="linear"
          dataKey={'Rating'}
          stroke="#AAA"
          strokeDasharray="3 3"
          dot={<CustomizedDot />}
          isAnimationActive={false}
        />
      </LineChart>
    </ResponsiveContainer>
  );
}
