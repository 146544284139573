import React from 'react';
import { upperCase } from 'lodash';

import { Administrator, ClientUserRole } from 'javascript/models';

import { TPermissionGroups } from './Permissions';
import PermissionCheckbox from './PermissionCheckbox';

interface IPermissionBreakdown {
  permissionable: (Administrator | ClientUserRole);
  groupedPermissions: TPermissionGroups;
  canEditPermissions: boolean;
}

const PermissionBreakdown = ({ permissionable, groupedPermissions, canEditPermissions }: IPermissionBreakdown) => {
  const PermissionList = ({ permissionGroup }) => {
    const jsx = Object.keys(permissionGroup).map((permissionType) => (
      <PermissionCheckbox
        {...{ permissionable, canEditPermissions, permissionType, permissionGroup }}
        key={permissionGroup[permissionType].value}
      />
    ));
    return <>{jsx}</>;
  };

  const jsx = Object.keys(groupedPermissions).map((permissionGroupName) => (
    <div className="col-md-1-1" key={permissionGroupName}>
      <h4 className="subtitle-two text-white">{upperCase(permissionGroupName)}</h4>
      <div className="frow frow--gutters">
        <PermissionList {...{ canEditPermissions, permissionGroup: groupedPermissions[permissionGroupName] }} />
      </div>
    </div>
  ));
  return <>{jsx}</>;
};

export default PermissionBreakdown;
