import { Attr, HasMany, Model } from 'spraypaint';
import { ApplicationRecord } from './ApplicationRecord';
import { ReportTemplatesReportTemplateSection } from './ReportTemplateSection';

@Model()
export class ReportTemplateRow extends ApplicationRecord {
  public static jsonapiType = 'report_template_rows';

  @Attr() public height: string;
  @Attr() public parentReportTemplateRow: string;
  @Attr() public position: number;
  @Attr() public repeating: boolean;
  @Attr() public reportTemplateId: string;
  @Attr() public width: string;
  @Attr() public conditions: string[];
  @HasMany() public reportTemplatesReportTemplateSections: ReportTemplatesReportTemplateSection[];
  @HasMany() public childRows: ReportTemplateRow[];
}
