import * as React from 'react';
import * as $ from 'jquery';
import turbolinks from 'turbolinks';

import { Alert, Intent } from '@blueprintjs/core';

import { platformPortfolioPath, platformPortfoliosPath } from 'javascript/application/ts_routes';

import CsrfToken from 'components/shared/CsrfToken';
import useVisibility from 'components/shared/customHooks/useVisibility';

export default function destroy({ portfolio }) {
  const { isOpen, handleOpen, handleClose } = useVisibility(false);

  function handleConfirmDelete() {
    $.ajax({
      data: { authenticity_token: CsrfToken() },
      method: 'DELETE',
      success: () => turbolinks.visit(platformPortfoliosPath()),
      url: platformPortfolioPath(portfolio.attributes.id),
    });
  }

  return (
    <React.Fragment>
      <div
        className="button button--secondary button--danger button--icon"
        onClick={handleOpen}
        data-tip="Delete Portfolio"
        data-for="portfolio_controls"
      >
        <i className="icon-bin icon-fw" />
      </div>
      <Alert
        isOpen={isOpen}
        onClose={handleClose}
        canEscapeKeyCancel
        canOutsideClickCancel
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        intent={Intent.DANGER}
        onCancel={handleClose}
        onConfirm={handleConfirmDelete}
        portalContainer={document.body}
      >
        <p className="mar-t-0">Are you sure you want to delete this portfolio?</p>
      </Alert>
    </React.Fragment>
  );
}
