import * as React from 'react';

import FormRow from 'components/shared/forms/FormRow';
import Context from '../../Context';
import useVisibility from 'components/shared/customHooks/useVisibility';
import ChildPortfolioSelect from './shared/ChildPortfolioSelect';

export default function combined({ combined, handleCombinedChange }) {
  const context = React.useContext(Context);

  const { client, portfolio } = context.state;

  const { isOpen, setIsOpen } = useVisibility(combined);

  React.useEffect(() => {
    setIsOpen(combined);
  }, [combined]);

  function handleClick() {
    handleCombinedChange(!isOpen);
  }

  return (
    <React.Fragment>
      <FormRow
        note="Combined portfolios are made up of 'Component Part' portfolios and get a normal E-MAP review at the
        parent level. The component parts themselves do not get an E-MAP"
        className="col-mc-1-1"
      >
        <label htmlFor="currency" className="form__label">
          Combined
        </label>
        <div className="pretty p-icon">
          <input type="hidden" value="0" name="portfolio[combined]" />
          <input type="checkbox" name="portfolio[combined]" value="1" checked={combined} onChange={handleClick} />
          <div className="state p-blue">
            <i className="icon-tick icon icon-push-down-small" />
            <label className="text-white">Combined Portfolio</label>
          </div>
        </div>
      </FormRow>
      <ChildPortfolioSelect {...{ isOpen, client, portfolio }} />
    </React.Fragment>
  );
}
