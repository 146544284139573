import * as React from 'react';

export const useCheckbox = (name: string, initial: boolean): [boolean, JSX.Element] => {
  const [toggleState, setToggleState] = React.useState(initial);
  const handleToggle = () => setToggleState((prev) => !prev);

  const toggleComponent = (
    <div className="pretty p-icon">
      <input checked={toggleState} type="checkbox" name={name} onChange={handleToggle} />
      <div className="state p-purple">
        <i className="icon-tick icon icon-push-down-small" />
        <label htmlFor="" className="text-white">
          {name}
        </label>
      </div>
    </div>
  );
  return [toggleState, toggleComponent];
};

export default useCheckbox;
