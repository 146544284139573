import * as React from 'react';

import { Droppable } from 'react-beautiful-dnd';

import Context from '../../Context';
import Footer from './Footer';
import ColorSelect from '../ColorSelect';
import Rows from '../../report/Rows';

const classNames = require('classnames');

export default function additionalPages({ dummyContainerRef, index, startAt, templateRows }) {
  const context = React.useContext(Context);
  const { canEdit } = context.props;
  const { focus, pageColors, reportTemplate } = context.state;
  const color = reportTemplate.reportTemplateTheme.headerColor;

  const pageClasses = classNames(
    'pdf-page',
    'pdf-page--landscape',
    { 'pdf-page--default': (pageColors[index] && pageColors[index] === 'white') || !pageColors[index] },
    { 'pdf-page--grey': pageColors[index] && pageColors[index] === 'grey' },
  );

  return (
    <div className={pageClasses}>
      <header
        className="pdf-footer pdf-footer--landscape pdf-footer--landscape-compact"
        style={{ background: color }}
      />
      <div className="pdf-main" style={{ height: '85.93%' }}>
        <ColorSelect color="white" top={10} {...{ index }} />
        <Droppable droppableId={`droppable-report-${startAt}`} isDropDisabled={focus !== 'report'}>
          {(provided, snapshot) => {
            const styles = {
              background: snapshot.isDraggingOver ? '#FAFAFA' : '',
              height: '100%',
              ...provided.droppableProps.style,
            };
            return (
              <div ref={provided.innerRef} {...provided.droppableProps} style={styles}>
                <Rows {...{ dummyContainerRef, templateRows }} canEdit={canEdit && focus === 'report'} />
                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
      </div>
      <Footer {...{ index }} />
    </div>
  );
}
