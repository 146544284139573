import * as React from 'react';

import AdditionalPages from './AdditionalPages';
import RepeatingPages from './RepeatingPages';

export default function additionalPagesWrapper({ dummyContainerRef, index, startAt, templateRows }) {
  // If there is one row, and that row is repeating we need to treat it differently
  if (templateRows.length === 1 && templateRows[0].repeating) {
    return <RepeatingPages {...{ dummyContainerRef, startAt, templateRows }} startIndex={index} />;
  }

  return <AdditionalPages key={index} {...{ dummyContainerRef, index, startAt, templateRows }} />;
}
