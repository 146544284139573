import * as React from 'react';

import Longform from './LongForm';
import Standard from './Standard';

import IReviewCommentaries from 'components/interfaces/IReviewCommentaries';
import ICommentaries from 'components/interfaces/ICommentaries';

interface IProps {
  commentaries: ICommentaries;
  reviewCommentaries: IReviewCommentaries;
  reviewCommentaryType: string;
  updatePath: string;
  userId: string;
  handleClose(): void;
  handleSubmit(event: React.SyntheticEvent): void;
}

export default function presenter(props: IProps) {
  const { handleClose, handleSubmit, reviewCommentaries, reviewCommentaryType, updatePath, userId } = props;

  if (reviewCommentaryType === 'longform') {
    return <Longform {...{ handleClose, handleSubmit, reviewCommentaries, updatePath, userId }} />;
  }

  return <Standard {...props} />;
}
