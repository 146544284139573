import * as React from 'react';

import ReactTable, { SortingRule } from 'react-table';

import processColumns, { IRevisedColumnsOptions } from './utilities/processColumns';

import HiddenNoDataComponent from './components/HiddenNoDataComponent';
import HiddenPaginationComponent from './components/HiddenPaginationComponent';
import LoadingComponent from './components/LoadingComponent';
import NoDataComponent from './components/NoDataComponent';
import paginationComponentFactory from './components/PaginationComponent';

export interface ITableProps<Data> {
  csvExport?: any;
  getTrProps?: any;
  hasColumnSelector?: boolean;
  hasPagination: boolean;
  identifier?: string;
  isLoading?: boolean;
  loadingComponent?: React.ComponentType;
  page?: number;
  pageSize?: number;
  showPagination?: boolean;
  selectable?: boolean;
  manual?: boolean;
  meta?: any;
  noDataComponent?: React.ReactType;
  className?: string;
  columns: IRevisedColumnsOptions<Data>['columns'];
  data: Data[];
  handleSort?: (sorting: SortingRule[]) => void;
  handleApplyFilters?(key: string, values: string[]): void;
  handlePageChange?(page: number): void;
  handlePageSizeChange?(pageSize: number): void;
  handleUpdateSelection?(value: string | string[]): void;
}

const table = <Data,>(props: ITableProps<Data>) => {
  const {
    csvExport,
    getTrProps,
    hasColumnSelector,
    hasPagination,
    identifier,
    isLoading,
    loadingComponent,
    page,
    pageSize: pageSizeProp,
    showPagination,
    selectable,
    manual,
    meta,
    noDataComponent,
    className,
    columns,
    data,
    handleApplyFilters,
    handlePageChange,
    handlePageSizeChange,
    handleSort,
    handleUpdateSelection,
  } = props;

  const [pageNumber, setPageNumber] = React.useState<number>(page || 1);

  React.useEffect(() => {
    if (!handlePageChange) return;

    handlePageChange(pageNumber);
  }, [pageNumber]);

  const [pageSize, setPageSize] = React.useState<number>(pageSizeProp || 20);

  React.useEffect(() => pageSizeProp && setPageSize(pageSizeProp), [pageSizeProp]);

  React.useEffect(() => {
    if (!handlePageSizeChange || !pageSize) return;
    handlePageSizeChange(pageSize);
  }, [pageSize]);

  const localHandleApplyFilters = React.useCallback(
    (key, values) => {
      setPageNumber(1);
      if (handleApplyFilters) handleApplyFilters(key, values);
    },
    [handleApplyFilters],
  );

  const columnOptions = {
    columns,
    csvExport,
    data,
    handleApplyFilters: localHandleApplyFilters,
    handleUpdateSelection,
    hasColumnSelector,
    identifier,
    selectable,
  };

  const processedColumns = processColumns(columnOptions);

  const onPageSizeChange = React.useCallback((newPageSize: number) => {
    setPageNumber(1);
    setPageSize(newPageSize);
  }, []);

  const noData = isLoading ? HiddenNoDataComponent : noDataComponent ? noDataComponent : NoDataComponent;

  const pagination =
    data.length < 1 || !hasPagination ? HiddenPaginationComponent : paginationComponentFactory(meta, pageNumber);

  const loading = loadingComponent || LoadingComponent;

  const localHandleSort = React.useCallback(
    (sorting: SortingRule[]) => handleSort && handleSort(sorting),
    [handleSort],
  );

  const pages = meta ? meta.pages : 0;

  const realPageSize = hasPagination ? pageSize : data.length;

  return (
    <ReactTable
      className={className}
      columns={processedColumns}
      data={data}
      getTrProps={getTrProps}
      loading={isLoading}
      LoadingComponent={loading}
      manual={manual}
      minRows={0}
      pageSize={realPageSize}
      pages={pages}
      NoDataComponent={noData}
      onPageChange={setPageNumber}
      onPageSizeChange={onPageSizeChange}
      onSortedChange={localHandleSort}
      PaginationComponent={pagination}
      showPagination={showPagination}
    />
  );
};

table.defaultProps = {
  hasColumnSelector: true,
  hasPagination: true,
  identifier: Math.random().toString(),
  manual: true,
};

export default table;
