import * as React from 'react';

import { HeadingThree, SubtitleOne } from '../Typography';

export default function headlineBox({ headline, leadText }) {
  return (
    <div className="card card--dark">
      <div className="platform-content platform-content--padding-all">
        <SubtitleOne text={leadText} additionalClasses="text-white" />
        <HeadingThree text={headline} />
      </div>
    </div>
  );
}
