import React from 'react';
import FormRow from 'components/shared/forms/FormRow';
import { User } from 'javascript/models';

interface IClientPortalManagement {
  handleLoginToggle: (attribute: any, value: any) => void;
  resource: User;
}
const ClientPortalManagement = ({ handleLoginToggle, resource }: IClientPortalManagement) => {
  return (
    <fieldset className="form__fieldset">
      <h4 className="form__fieldset-label">Client User Interface</h4>
      <FormRow>
        <label htmlFor="ui_login" className="form__label">
          Can login to the client user interface
        </label>
        <div className="frow frow--gutters">
          <div>
            <div className="pretty pretty--large pretty--with-label p-round">
              <input
                type="radio"
                value="1"
                id="uiLogin"
                onChange={() => handleLoginToggle('uiLogin', true)}
                checked={resource.uiLogin}
              />
              <div className="state p-purple-circle">
                <label className="text-white">Yes</label>
              </div>
            </div>
          </div>
          <div>
            <div className="pretty pretty--large pretty--with-label p-round">
              <input
                type="radio"
                value="0"
                id="uiLogin"
                onChange={() => handleLoginToggle('uiLogin', false)}
                checked={!resource.uiLogin}
              />
              <div className="state p-blue-circle">
                <label className="text-white">No</label>
              </div>
            </div>
          </div>
        </div>
      </FormRow>
    </fieldset>
  );
};

export default ClientPortalManagement;
