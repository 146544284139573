import * as React from 'react';

import { debounce } from 'lodash';

import { SortingRule } from 'react-table';
import { Scope, SortScope, SpraypaintBase, WhereClause, PersistedSpraypaintRecord } from 'spraypaint';

import CsvExport from './reactTable/CsvExport';
import Table from './reactTable/Table';
import useDebounce from './customHooks/useDebounce';
import Search from './Search';
import { createAlert } from './Utils';

import Header from './graphitiTable/components/Header';
import Button from './graphitiTable/components/header/Button';
import NewLink from './graphitiTable/components/header/NewLink';

import { IRevisedColumnsOptions } from './reactTable/utilities/processColumns';

interface IGraphitiTableWrapperProps<Model extends SpraypaintBase> {
  additionalExtraParams?: any;
  additionalFilters?: WhereClause;
  buttonComponent?: typeof Button;
  buttonText?: string;
  columns: IRevisedColumnsOptions<PersistedSpraypaintRecord<Model>>['columns'];
  className?: string;
  customMeta?: any;
  defaultFilters?: WhereClause;
  formatting?: any;
  getTrProps?: any;
  hasColumnSelector?: boolean;
  hasHeader?: boolean;
  handleFilterChange?: (newFilters: WhereClause) => WhereClause | void;
  headerComponent?: typeof Header;
  identifier?: string;
  isExportable?: boolean;
  isSearchable?: boolean;
  initialSort?: SortScope;
  newLinkComponent?: typeof NewLink;
  newPath?: string;
  newText?: string;
  resetSelected?: boolean;
  searchComponent?: typeof Search;
  searchPlaceholder?: string;
  selectable?: boolean;
  shouldUpdateTable?: boolean;
  scope: Scope<Model>;
  title?: string;
  handleButtonClick?(): void;
  handleUpdatedSelection?(selection): void;
}

const reducer = (state, action) => {
  if (action.id) {
    return { selection: [...state.selection.toggle(action.id)] };
  }
  if (action.ids) {
    return { selection: [...action.ids] };
  }
};

const graphitiTableWrapper = <Model extends SpraypaintBase>(props: IGraphitiTableWrapperProps<Model>) => {
  const {
    additionalFilters,
    additionalExtraParams,
    buttonComponent,
    buttonText,
    columns,
    className = 'platform-panel platform-panel--no-background',
    customMeta,
    defaultFilters,
    formatting,
    handleButtonClick,
    handleFilterChange,
    handleUpdatedSelection,
    hasColumnSelector = true,
    hasHeader,
    headerComponent,
    identifier,
    initialSort,
    isExportable,
    isSearchable,
    newLinkComponent,
    newPath,
    newText,
    searchComponent,
    searchPlaceholder = 'Search by reference',
    selectable = false,
    scope,
    title,
  } = props;

  const [isLoading, setIsLoading] = React.useState(true);
  const [isSearching, setIsSearching] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [meta, setMeta] = React.useState(customMeta);
  const [pageSize, setPageSize] = React.useState<number>(20);
  const [page, setPage] = React.useState<number>(1);
  const [sorting, setSorting] = React.useState<SortScope>(initialSort || { id: 'asc' });
  const [filters, setFilters] = React.useState<WhereClause>(defaultFilters || {});
  const [searchTerm, setSearchTerm] = React.useState('');

  const [state, dispatch] = React.useReducer(reducer, { selection: [] });

  React.useEffect(() => {
    if (props.resetSelected) {
      dispatch({ ids: [] });
    }
  }, [props.resetSelected]);

  const debouncedSearchTerm = useDebounce(searchTerm, 100);

  // The function below Takes some sorting/filterting parameters,
  // then gets records using spraypaint and then sets the Data and Meta states.
  const getRecords = async (
    localAdditionalExtraParams: typeof additionalExtraParams,
    localAdditionalFilters: typeof additionalFilters,
    localSorting: typeof sorting,
    localFilters: typeof filters,
    localPage: typeof page,
    localPageSize: typeof pageSize,
    localDebouncedSearchTerm: typeof debouncedSearchTerm,
  ) => {
    let scopeFilters = localFilters;
    if (localAdditionalFilters) scopeFilters = { ...scopeFilters, ...localAdditionalFilters };

    let localScope = scope.where(scopeFilters).page(localPage).per(localPageSize);

    if (!customMeta) localScope = localScope.stats({ total: 'count' });

    if (localAdditionalExtraParams) {
      localScope = localScope.extraParams(localAdditionalExtraParams);
    }

    localScope = Object.entries(localSorting).reduce(
      (previous, [key, value]) => previous.order({ [key]: value }),
      localScope,
    );

    if (isSearchable && localDebouncedSearchTerm) {
      localScope = localScope.where({ search: { match: localDebouncedSearchTerm } });
    }

    const { data: localData, meta: localMeta } = await localScope.all();

    if (!customMeta) {
      const totalRecords = localMeta.stats.total.count;

      setMeta({ total: totalRecords, pages: Math.ceil(totalRecords / localPageSize) });
    }

    setData(localData);
    setIsLoading(false);
    setIsSearching(false);
  };

  const debouncedGetRecords = React.useCallback(debounce(getRecords, 100), [scope]);

  React.useEffect(() => {
    if (filters) {
      if ((Object.keys(filters).length === 0 && filters.constructor === Object) || filters === defaultFilters) return;

      createAlert('success', 'Filters applied', 1000);
    }
  }, [filters]);

  React.useEffect(() => {
    setIsLoading(true);
    debouncedGetRecords(
      additionalExtraParams,
      additionalFilters,
      sorting,
      filters,
      page,
      pageSize,
      debouncedSearchTerm,
    );
  }, [page, pageSize, sorting, filters, additionalFilters, additionalExtraParams, debouncedGetRecords]);

  React.useEffect(() => {
    if (!props.shouldUpdateTable) return;
    setIsLoading(true);

    debouncedGetRecords(
      additionalExtraParams,
      additionalFilters,
      sorting,
      filters,
      page,
      pageSize,
      debouncedSearchTerm,
    );
  }, [props.shouldUpdateTable]);

  React.useEffect(() => {
    if (debouncedSearchTerm) setIsSearching(true);

    debouncedGetRecords(
      additionalExtraParams,
      additionalFilters,
      sorting,
      filters,
      page,
      pageSize,
      debouncedSearchTerm,
    );
  }, [debouncedSearchTerm]);

  const handlePageSizeChange = React.useCallback(
    (localPageSize: number) => localPageSize && setPageSize(localPageSize),
    [],
  );

  const handleSort = React.useCallback(
    (localSorting: SortingRule[]) => setSorting({ [localSorting[0].id]: localSorting[0].desc ? 'desc' : 'asc' }),
    [],
  );

  const handleApplyFilters = React.useCallback(
    (key: string, values: string[]) =>
      setFilters((prevFilters: WhereClause) => {
        let newFilters = { ...prevFilters, [key]: values };
        if (handleFilterChange) newFilters = handleFilterChange(newFilters) || newFilters;
        return newFilters;
      }),
    [],
  );

  const ButtonComponent = buttonComponent || Button;
  const NewLinkComponent = newLinkComponent || NewLink;
  const SearchComponent = searchComponent || Search;
  const HeaderComponent = headerComponent || Header;

  let buttonProps = {
    buttonText,
    handleButtonClick,
  };
  buttonProps = React.useMemo(() => buttonProps, Object.values(buttonProps));

  let newLinkProps = {
    newPath,
    newText,
  };
  newLinkProps = React.useMemo(() => newLinkProps, Object.values(newLinkProps));

  let searchProps = {
    handleChange: setSearchTerm,
    isSearching,
    placeholder: searchPlaceholder,
    width: '240px',
  };
  searchProps = React.useMemo(() => searchProps, Object.values(searchProps));

  let headerComponentProps = {
    Button: ButtonComponent,
    buttonProps,
    NewLink: NewLinkComponent,
    newLinkProps,
    Search: isSearchable && SearchComponent,
    searchProps,
    title,
  };
  headerComponentProps = React.useMemo(() => headerComponentProps, Object.values(headerComponentProps));

  const header = hasHeader && <HeaderComponent {...headerComponentProps} />;

  const csvExport = React.useCallback(
    () => isExportable && <CsvExport {...{ columns, formatting, searchTerm, filters, scope, sorting }} />,
    [isExportable, columns, formatting, searchTerm, filters, scope, sorting],
  );

  React.useEffect(() => handleUpdatedSelection && handleUpdatedSelection(state.selection), [state]);

  const handleUpdateSelection = React.useCallback(
    (value: string) => dispatch(typeof value === 'string' ? { id: value } : { ids: value }),
    [],
  );

  return (
    <React.Fragment>
      {header}
      <div className={className}>
        <Table
          csvExport={csvExport()}
          columns={columns}
          data={data}
          getTrProps={props.getTrProps}
          handleApplyFilters={handleApplyFilters}
          handlePageChange={setPage}
          handlePageSizeChange={handlePageSizeChange}
          hasColumnSelector={hasColumnSelector}
          hasPagination
          handleSort={handleSort}
          isLoading={isLoading}
          selectable={selectable}
          handleUpdateSelection={handleUpdateSelection}
          meta={meta}
          identifier={identifier}
        />
      </div>
    </React.Fragment>
  );
};

export default graphitiTableWrapper;
