import * as React from 'react';

import Presenter from './xeroPostings/Presenter';

import { Client } from 'javascript/models';

export default function xeroPostings({ companyId }: { companyId: string }) {
  const [isLoading, setIsLoading] = React.useState(true);
  const [clients, setClients] = React.useState([]);

  React.useEffect(() => {
    getClients();
  }, []);

  async function getClients() {
    const { data } = await Client.where({ company_id: companyId })
      .where({ monitoring_invoicaeble_reviews: true })
      .selectExtra(['monitoring_invoiceable_review_count', 'monitoring_invoiceable_review_total_count'])
      .order({ reference: 'asc' })
      .all();

    setClients(data);
    setIsLoading(false);
  }

  return <Presenter {...{ clients, getClients, isLoading }} />;
}
