export default [
  { code: '{{review_period}}', explanation: 'The period of the review' },
  {
    code: '{{half_year_increased_fell}}',
    explanation: 'Whether the portfolio increased or fell over the last half year',
  },
  { code: '{{half_year}}', explanation: 'Portfolio return over the last 12 months' },
  { code: '{{benchmark_name}}', explanation: 'The name of the primary benchmark' },
  {
    code: '{{benchmark_half_year_increased_fell}}',
    explanation: 'Whether the primary benchmark increased or fell over the last half year',
  },
  { code: '{{benchmark_half_year}}', explanation: 'Benchmark return over the last 12 months' },
  { code: '{{quarter}}', explanation: 'Number of the quarter under review (i.e. 1, 2, 3 or 4)' },
  { code: '{{year}}', explanation: 'The year under review' },
  {
    code: '{{last_quarter_increased_fell}}',
    explanation: 'Whether the portfolio increased or fell over the quarter',
  },
  { code: '{{last_quarter}}', explanation: 'Portfolio return over the quarter' },
  {
    code: '{{benchmark_last_quarter_increased_fell}}',
    explanation: 'Whether the primary benchmark increased or fell over the quarter',
  },
  { code: '{{benchmark_last_quarter}}', explanation: 'Benchmark return over the quarter' },
  { code: '{{twelve_months_up_down}}', explanation: 'Whether the portfolio is up or down over the last 12 months' },
  { code: '{{twelve_months}}', explanation: 'Portfolio return over the last 12 months' },
  {
    code: '{{benchmark_12_months_increased_fell}}',
    explanation: 'Whether the primary benchmark increased or fell over the last 12 months',
  },
  { code: '{{benchmark_12_months}}', explanation: 'Benchmark returns over the last 12 months' },
  { code: '{{review_months}}', explanation: 'Number of months the review covers' },
  {
    code: '{{portfolio_overall_increased_fell}}',
    explanation: 'Whether the portfolio increased or fell over period under review',
  },
  { code: '{{overall}}', explanation: 'Portfolio return over the period under review' },
  {
    code: '{{benchmark_overall_increased_fell}}',
    explanation: 'Whether the benchmark increased or fell over period under review',
  },
  { code: '{{benchmark_overall}}', explanation: 'Benchmark returns over the period under review' },
];
