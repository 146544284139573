import * as React from 'react';

const numeral = require('numeral');

import { format, parse } from 'date-fns';

function balanceMetric({ changeFromPrevious, data, isLoading }) {
  if (isLoading) {
    return (
      <div className="metric">
        <p className="metric__title">Balance</p>
        <p className="metric__value" />
      </div>
    );
  }

  const currentBalance = data[0];
  const previousBalance = data[1];

  function change() {
    if (!currentBalance || !previousBalance) {
      return '-';
    }

    return changeFromPrevious(currentBalance.value, previousBalance.value, '0,0.00', '');
  }

  return (
    <div className="metric">
      <p className="metric__title">
        Balance {currentBalance && `(as at ${format(parse(currentBalance.date), 'DD MMM YYYY')})`}
      </p>
      <p className="metric__value">{currentBalance && numeral(currentBalance.value).format('0,0.00')}</p>
      {change()}
    </div>
  );
}

export default balanceMetric;
