import * as React from 'react';

import * as ReactTooltip from 'react-tooltip';

import { format, parse } from 'date-fns';

export default function trackedJob({ job }) {
  function icon() {
    switch (job.status) {
      case 'queued':
        return (
          <div data-tip="Queued">
            <i className="icon-history text-brand-windows-blue" />
          </div>
        );
      case 'processing':
        return (
          <div data-tip="Processing">
            <i className="fas fa-circle-notch fa-spin text-brand-windows-blue" />
          </div>
        );
      case 'error':
        return (
          <div data-tip="Error">
            <i className="fas fa-times text-brand-pale-red" />
          </div>
        );
      default:
        return (
          <div data-tip="Complete">
            <i className="icon-tick text-brand-sage" />
          </div>
        );
    }
  }

  function jobName() {
    switch (job.jobName) {
      case 'DataUploads::ProcessJob':
        return 'Manager Data Upload';
      case 'PortfolioBenchmarks::ProcessJob':
        return 'Benchmark Upload';
      default:
        return job.jobName;
    }
  }

  return (
    <tr>
      <td>{icon()}</td>
      <td>{jobName()}</td>
      <td>
        {format(parse(job.createdAt), 'DD MMM YYYY [at] HH:mm')}
        <ReactTooltip effect="solid" />
      </td>
    </tr>
  );
}
