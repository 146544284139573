import * as React from 'react';

import FinancialClock from './FinancialClock';

export default function financialClocks() {
  const availableClocks = [
    { country: 'United Kingdom', city: 'London', timeZone: 'Europe/London', colour: 'aqua' },
    { country: 'Switzerland', city: 'Zurich', timeZone: 'Europe/Zurich', colour: 'orange' },
    { country: 'United States', city: 'New York', timeZone: 'America/New_York', colour: 'green' },
    { country: 'China', city: 'Shanghai', timeZone: 'Asia/Shanghai', colour: 'magenta' },
  ];

  const clocks = availableClocks.map((clock, index) => {
    return (
      <div key={clock.city} className="col-md-1-4">
        <FinancialClock {...{ clock, color: clock.colour }} />
      </div>
    );
  });

  return (
    <div className="card card--rounded card--dark mar-b-4">
      <div className="platform-content platform-content--padding-all">
        <div className="frow">{clocks}</div>
      </div>
    </div>
  );
}
