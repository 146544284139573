import * as React from 'react';

export default function subtitle({ handleChange, value, optional = true }) {
  return (
    <React.Fragment>
      <label htmlFor="" className="form__label">
        Subtitle {optional && '(optional)'}
      </label>
      <input type="text" name="subtitle" value={value || ''} onChange={handleChange} />
    </React.Fragment>
  );
}
