import * as React from 'react';

export default function () {
  return (
    <div className="frow frow--centered-column" style={{ height: '100%' }}>
      <p className="text-uppercase text-new-brand-grey-40 mar-v-0 text-center text-tiny text-rubik">
        Drag and drop, or click to select attachments
      </p>
    </div>
  );
}
