import * as React from 'react';

import { ReportTemplatesReportTemplateSection } from 'javascript/models';
import Context from '../../../Context';

import { legendOptions } from './shared/options';
import useSectionSize from './useSectionSize';

export default function lineChart({ attributes, handleChange, handleManualChange, isRepeating }) {
  const context = React.useContext(Context);
  const { reportTemplate } = context.state;

  const isConsolidated = reportTemplate.consolidated;

  const [comparisonMeasure, setComparisonMeasure] = React.useState(attributes.lineChartComparisonMeasure);
  const [selectedSeries, setSelectedSeries] = React.useState(attributes.lineChartSeries || []);
  const [selectedTimePeriod, setTimePeriod] = React.useState(attributes.lineChartTimePeriod);
  const [selectedType, setSelectedType] = React.useState(attributes.lineChartType);
  const [selectedLegend, setSelectedLegend] = React.useState(attributes.legendPosition);

  const { selectedSize, handleSizeChange } = useSectionSize(attributes.size, handleManualChange);

  function updateAttributes() {
    const value = {
      comparison_measure: comparisonMeasure,
      series: selectedSeries,
      time_period: selectedTimePeriod,
      type: selectedType,
    };
    handleManualChange(value, 'lineChartDetails');
  }

  React.useEffect(() => {
    updateAttributes();
  }, [comparisonMeasure, selectedSeries, selectedTimePeriod, selectedType]);

  function handleSeriesChange() {
    const updatedSeries = selectedSeries.toggle(this);
    setSelectedSeries(updatedSeries);
  }

  function handleTypeChange() {
    setSelectedType(this);
    setSelectedSeries([]);
  }

  function handlePeriodChange() {
    setTimePeriod(this);
  }
  function handleComparisonChange() {
    setComparisonMeasure(this);
  }

  function handleLegendChange(selectedOption) {
    setSelectedLegend(selectedOption);
    handleManualChange(selectedOption, 'legendPosition');
  }

  let scopedSeriesOptions = ReportTemplatesReportTemplateSection.seriesOptions;
  if (isConsolidated) {
    scopedSeriesOptions = [
      'Consolidated Portfolio',
      'Constituent Portfolios',
      ...ReportTemplatesReportTemplateSection.seriesOptions.filter((option) => option !== 'Portfolio'),
    ];
  }

  if (isRepeating) {
    scopedSeriesOptions = [
      'Constituent Portfolio',
      ...ReportTemplatesReportTemplateSection.seriesOptions.filter((option) => option !== 'Portfolio'),
    ];
  }

  if (selectedType === 'net_gross') {
    scopedSeriesOptions = ['Net', 'Gross', 'Loan'];
  }

  const typeOptions = ReportTemplatesReportTemplateSection.typeOptions.map((type) => {
    return (
      <div className="col-tn-1-3" key={type.value}>
        <div className="pretty pretty--large pretty--with-label p-round">
          <input
            type="radio"
            value={type.value}
            id="type"
            onChange={handleTypeChange.bind(type.value)}
            checked={selectedType === type.value}
          />
          <div className="state p-blue-circle">
            <label>{type.label}</label>
          </div>
        </div>
      </div>
    );
  });

  const seriesOptions = scopedSeriesOptions.map((option) => {
    return (
      <div className="col-tn-1-3" key={option}>
        <div className="pretty p-icon">
          <input
            type="checkbox"
            value={option}
            id="series"
            onChange={handleSeriesChange.bind(option)}
            checked={selectedSeries.includes(option)}
          />
          <div className="state p-blue">
            <i className="icon-tick icon icon-push-down-small" />
            <label>{option}</label>
          </div>
        </div>
      </div>
    );
  });

  function renderSeries() {
    if (selectedType === 'excess_return') return;

    return (
      <div className="col-mc-1-1">
        <label className="form__label" htmlFor="">
          Series
        </label>
        <div className="frow">{seriesOptions}</div>
      </div>
    );
  }

  const timePeriodOptions = ReportTemplatesReportTemplateSection.timePeriodOptions.map((timePeriod) => {
    return (
      <div className="col-tn-1-3" key={timePeriod.value}>
        <div className="pretty pretty--large pretty--with-label p-round">
          <input
            type="radio"
            value={timePeriod.value}
            id="timePeriod"
            onChange={handlePeriodChange.bind(timePeriod.value)}
            checked={selectedTimePeriod === timePeriod.value}
          />
          <div className="state p-blue-circle">
            <label>{timePeriod.label}</label>
          </div>
        </div>
      </div>
    );
  });

  function renderComparisonOptions() {
    if (selectedType !== 'excess_return') return;

    const scopedOptions = ReportTemplatesReportTemplateSection.seriesOptions.filter((option) => option !== 'Portfolio');
    const comparisonOptions = scopedOptions.map((option) => {
      return (
        <div className="col-tn-1-3" key={option}>
          <div className="pretty pretty--large pretty--with-label p-round">
            <input
              type="radio"
              value={option}
              id="timePeriod"
              onChange={handleComparisonChange.bind(option)}
              checked={comparisonMeasure === option}
            />
            <div className="state p-blue-circle">
              <label>{option}</label>
            </div>
          </div>
        </div>
      );
    });

    return (
      <div className="col-mc-1-1">
        <label className="form__label" htmlFor="">
          Comparison
        </label>
        <div className="frow ">{comparisonOptions}</div>
      </div>
    );
  }

  const sizeOptions = ReportTemplatesReportTemplateSection.sizeOptions.map((size) => {
    function handleChange() {
      handleSizeChange(size.value);
    }
    return (
      <div className="col-tn-1-3" key={size.value}>
        <div className="pretty pretty--large pretty--with-label p-round">
          <input
            type="radio"
            value={size.value}
            id="size"
            onChange={handleChange}
            checked={selectedSize === size.value}
          />
          <div className="state p-blue-circle">
            <label>{size.label}</label>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="frow frow--gutters">
      <div className="col-mc-1-1">
        <label className="form__label" htmlFor="">
          Chart Type
        </label>
        <div className="frow ">{typeOptions}</div>
      </div>
      {renderSeries()}
      {renderComparisonOptions()}
      <div className="col-mc-1-1">
        <label className="form__label" htmlFor="">
          Time Period
        </label>
        <div className="frow ">{timePeriodOptions}</div>
      </div>
      <div className="col-mc-1-1">
        <label className="form__label" htmlFor="">
          Size
        </label>
        <div className="frow ">{sizeOptions}</div>
      </div>
      {legendOptions(selectedLegend, handleLegendChange)}
    </div>
  );
}
