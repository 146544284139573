import * as React from 'react';

import lodashStartcase from 'lodash.startcase';

import AutoResizeTextArea from 'components/shared/forms/AutoResizeTextArea';

import IReviewCommentary from 'components/interfaces/IReviewCommentary';

interface IProps {
  index: number;
  reviewCommentary: IReviewCommentary;
  type: string;
  userId: string;
  handleCommentaryChange(commentary, commentaryType): void;
}

export default function presenter(props: IProps) {
  const { handleCommentaryChange, index, reviewCommentary, type, userId } = props;
  const commentary = reviewCommentary ? reviewCommentary.attributes.commentary : '';
  const display = reviewCommentary ? reviewCommentary.attributes.display : false;

  function handleTextChange(commentary) {
    handleCommentaryChange(commentary, type);
  }

  const [isActive, setIsActive] = React.useState(display);

  function handleChange() {
    setIsActive(!isActive);
  }

  const id = reviewCommentary ? reviewCommentary.id : '';

  return (
    <div>
      <input type="hidden" name={`review[review_commentaries_attributes][${index}][id]`} value={id} />
      <div className="pretty p-icon">
        <input checked={isActive} name="action_point[completed]" type="checkbox" onChange={handleChange} />
        <div className="state p-blue">
          <i className="icon-tick icon text-white icon-push-down-small" />
          <label htmlFor="body" className="text-black">
            {lodashStartcase(type)}
          </label>
        </div>
      </div>
      <input
        type="hidden"
        name={`review[review_commentaries_attributes][${index}][display]`}
        value={isActive ? 1 : 0}
      />
      <input type="hidden" name={`review[review_commentaries_attributes][${index}][author_id]`} value={userId} />
      <input type="hidden" name={`review[review_commentaries_attributes][${index}][commentary_type]`} value={type} />
      <div className="platform-content platform-content--padding-left platform-content--padding-top">
        <AutoResizeTextArea
          id={`${type}_commentary`}
          label="Commentary"
          name={`review[review_commentaries_attributes[${index}][commentary]`}
          value={commentary}
          // maxLength={400}
          rows={10}
          style={{ maxHeight: 130 }}
          disabled={!isActive}
          onChange={handleTextChange}
        />
      </div>
    </div>
  );
}
