import consumer from './consumer';

let portalNotificationSubscription = null;

const createPortalNotificationSubscription = (callback) => {
  if (!portalNotificationSubscription) {
    portalNotificationSubscription = consumer.subscriptions.create(
      { channel: 'PortalNotificationChannel' },
      {
        disconnected: () => {
          portalNotificationSubscription = null;
        },
        received: (data) => {
          if (callback) {
            callback(data);
          }
        },
      },
    );
  }

  return portalNotificationSubscription;
};

export default createPortalNotificationSubscription;
