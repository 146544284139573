import * as React from 'react';

import { Note } from 'javascript/models';

import NotesPresenter from 'components/shared/notes/Presenter';
import Loading from 'components/shared/Loading';
import CurrentUserContext from 'components/shared/context/CurrentUserContext';

import { ActionPointContext } from '../Show';
import useRerender from 'components/shared/customHooks/useRerender';

const Notes = () => {
  const { actionPoint, currentUser, isLoading } = React.useContext(ActionPointContext);

  const rerender = useRerender();

  const newNoteAttributes = {
    actionPoints: [actionPoint],
    note_category: 'note',
    note_type: 'action_point_response',
    status: 'normal',
  };

  const afterCreate = (note: Note) => {
    actionPoint.notes.push(note);
    rerender();
  };

  const notesJSX =
    actionPoint && !isLoading ? (
      <div className="platform-content platform-content--padding-all">
        {<NotesPresenter {...{ afterCreate, notes: actionPoint.notes, newNoteAttributes }} />}
      </div>
    ) : (
      <Loading />
    );

  return (
    <section className="platform-panel">
      <header className="platform-panel__header">
        <div className="frow frow--justify-between frow--items-center">
          <h2 className="subtitle-one text-white">Notes</h2>
        </div>
      </header>
      <CurrentUserContext.Provider value={currentUser}>{currentUser && notesJSX}</CurrentUserContext.Provider>
    </section>
  );
};

export default Notes;
