import * as React from 'react';

import { Administrator } from 'javascript/models';

import Context from './show/Context';
import Presenter from './show/Presenter';
import CompanyContext from 'components/shared/context/CompanyContext';
import CurrentUserContext from 'components/shared/context/CurrentUserContext';

import ISelectOption from 'components/interfaces/ISelectOption';

interface IProps {
  administrators: { display: string; value: number }[];
  canManageReviews: boolean;
  companyId: number;
  currencyOptions: ISelectOption[];
  currentUser: string;
  currentUserId: number;
  defaultReviewId: number;
  portfolio: any;
  terminationReasons: string[];
  mostRecentHoldingsDate: Date;
}

export default function show(props: IProps) {
  const portfolio = props.portfolio.data;

  const contextValues = {
    props: { ...props, portfolio },
    state: {},
  };

  const { companyId, currentUserId } = props;

  const [currentUserModel, setCurrentUserModel] = React.useState(null);

  const getCurrentUser = async () => {
    const currentUser = await Administrator.find(currentUserId);
    setCurrentUserModel(currentUser.data);
  };

  React.useEffect(() => {
    getCurrentUser();
  }, [currentUserId]);

  return (
    <Context.Provider value={contextValues}>
      <CompanyContext.Provider value={{ id: companyId }}>
        <CurrentUserContext.Provider value={currentUserModel}>
          <Presenter />
        </CurrentUserContext.Provider>
      </CompanyContext.Provider>
    </Context.Provider>
  );
}
