import * as React from 'react';

import ReturnsTable from './tables/ReturnsTable';
import RiskTable from './tables/RiskTable';
import StatisticsTable from './tables/StatisticsTable';

export default function tables() {
  return (
    <div>
      <ReturnsTable />
      <RiskTable />
      <StatisticsTable />
    </div>
  );
}
