/* eslint-disable prefer-arrow/prefer-arrow-functions */
// Functions for converting numbers to different precisions
import * as numeral from 'numeral';

// Function that take in a number and precision and returns a number rounded to that precision (sig figs)
//    Needed because Number.toPrecision() in JS returns a string
export function convertToPrecision(num: number, precision: number) {
  if (num === 0) {
    return 0;
  }
  const exponent = getOrder(num);
  const normalizedSignificand = num / Math.pow(10, exponent);
  const roundedNum = Math.round(normalizedSignificand * Math.pow(10, precision - 1)) / Math.pow(10, precision - 1);
  return roundedNum * Math.pow(10, exponent);
}

export function getOrder(num: number) {
  return Math.floor(Math.log10(Math.abs(num)));
}

// Function that returns a tick formatting function to be used with as the tickFormatting prop in Recharts axes where
//    The breakpoint is the upper limit where we wish to use 2 d.p.
export function precisionTickFormatter(ticks: number[], breakpoint = 20) {
  if (!ticks) return (tick: number) => numeral(tick).format('0,0');
  const largestValue = Math.max(Math.max(...ticks), Math.abs(Math.min(...ticks)));

  if (largestValue <= breakpoint) {
    return (tick: number) => numeral(tick).format('0,0.0');
  }
  return (tick: number) => numeral(tick).format('0,0');
}
