import React from 'react';

import Slider from 'rc-slider';
import useFlag from '../InvestmentRestrictions/Flags/useFlag';
import { uniqueId } from 'lodash';

import { Frow, Column } from 'components/frow';
import { InvestmentRestrictionsContext } from '../InvestmentRestrictions/context';
import { Flag } from 'javascript/models';

export interface IAssetProps {
  availableSpace: number;
  color: string;
  allocation?: number;
  setAllocation: (value: number) => void;
  label: string;
  flag?: Flag;
  namePrefix?: string;
}

const AssetAllocationSlider = (props: IAssetProps) => {
  const { availableSpace, color, allocation, setAllocation, label, flag, namePrefix } = props;

  const { value, flagType, id, useDefaults } = flag || new Flag({ value: 0 });

  const name = `${namePrefix}[${uniqueId()}]`;

  const allocationValue = allocation || value;

  const handleValueUpdate = React.useCallback(
    (newValue) => {
      setAllocation(Math.min(allocationValue + availableSpace, newValue));
    },
    [availableSpace],
  );

  return (
    <Column>
      <label htmlFor="" className="form__label">
        {label}
      </label>
      <Frow justifyContent="between" itemAlignment="center">
        <Column columnSpan={10} maxColumns={11}>
          <Slider trackStyle={[{ backgroundColor: color }]} onChange={handleValueUpdate} value={allocationValue} />
        </Column>
        <Column columnSpan={1} maxColumns={11}>
          <div className="text-white text-right">{allocationValue}</div>
        </Column>
      </Frow>

      {flag && (
        <>
          <input type="hidden" name={`${name}[value]`} value={value} />
          <input type="hidden" name={`${name}[id]`} value={id} />
          <input type="hidden" name={`${name}[flag_type]`} value={flagType} />
          <input type="hidden" name={`${name}[use_defaults]`} value={useDefaults ? 1 : 0} />
        </>
      )}
    </Column>
  );
};

export default AssetAllocationSlider;
