import React, { useEffect } from 'react';

import { Range } from 'rc-slider';

interface IProps {
  backgroundColor: string;
  defaultValue: number[];
  disabled?: boolean;
  marks?: any;
  max?: number;
  min?: number;
  pushable?: number | boolean;
  showValues?: boolean;
  handleSlideEnd?: (values) => void;
}

export default function rangeSlider(props: IProps) {
  const { backgroundColor, defaultValue, disabled, handleSlideEnd, marks, max, min, pushable, showValues } = props;
  const [values, setValues] = React.useState(defaultValue);

  useEffect(() => {
    setValues(defaultValue);
  }, [defaultValue]);

  function handleMinChange(event) {
    if (!event.target.value) return;
    const numberValue = parseInt(event.target.value, 10);
    if (numberValue >= values[1]) return;

    const newValues = [...values];
    newValues[0] = numberValue;
    setValues(newValues);
    handleSlideEnd([numberValue, values[1]]);
  }

  function handleMaxChange(event) {
    if (!event.target.value) return;
    const numberValue = parseInt(event.target.value, 10);
    if (numberValue <= values[0]) return;

    const newValues = [...values];
    newValues[1] = numberValue;
    setValues(newValues);
    handleSlideEnd([values[0], numberValue]);
  }

  function onAfterChange(values) {
    if (disabled) return;

    setValues(values);
    if (handleSlideEnd) {
      handleSlideEnd(values);
    }
  }

  function onChange(values) {
    if (disabled) return;

    setValues(values);
  }

  function renderValues() {
    if (showValues) {
      const inputStyles = {
        padding: '8px',
        textAlign: 'center' as any,
      };

      return (
        <div className="frow frow--justify-between mar-b-2">
          <div>
            <label className="form__label">Min</label>
            <input style={inputStyles} type="number" value={values[0]} onChange={handleMinChange} min={min} max={max} />
          </div>
          <div>
            <label className="form__label">Max</label>
            <input type="number" style={inputStyles} value={values[1]} onChange={handleMaxChange} min={min} max={max} />
          </div>
        </div>
      );
    }
  }

  const trackStyle = [{ backgroundColor }];

  return (
    <div>
      {renderValues()}
      <Range {...{ marks, max, min, onAfterChange, onChange, pushable, trackStyle }} value={values} />
    </div>
  );
}

rangeSlider.defaultProps = {
  backgroundColor: '#3990A8',
  disabled: false,
  marks: {
    25: { style: { color: 'white' }, label: '25%' },
    50: { style: { color: 'white' }, label: '50%' },
    75: { style: { color: 'white' }, label: '75%' },
  },
  max: 100,
  min: 0,
  pushable: false,
};
