import * as React from 'react';

import Context from '../Context';
import FormRow from 'components/shared/forms/FormRow';
import ReactSelect from 'components/shared/forms/ReactSelect';

export default function adminInfo() {
  const context = React.useContext(Context);

  const { portfolio } = context.state;
  const { adminSystemOptions } = context.props;

  const { adminSystem, adminSystemReference, custodian, custodianReference, jobstreamPortfolioNumber } = portfolio;

  const defaultAdminSystem = adminSystemOptions.find((option) => option.value === adminSystem);

  return (
    <fieldset className="form__fieldset">
      <h4 className="form__fieldset-label">Admin Information</h4>
      <div className="frow frow--gutters">
        <FormRow note="" className="col-mc-1-1">
          <label htmlFor="admin_system" className="form__label">
            Admin System
          </label>
          <ReactSelect
            defaultValue={defaultAdminSystem}
            name="portfolio[admin_system]"
            id="portfolio_admin_system"
            isClearable
            options={adminSystemOptions}
            theme="dark"
          />
        </FormRow>
        <FormRow note="" className="col-mc-1-1">
          <label htmlFor="currency" className="form__label">
            Portfolio Admin Reference
          </label>
          <input type="text" name="portfolio[admin_system_reference]" defaultValue={adminSystemReference} />
        </FormRow>
        <FormRow note="" className="col-mc-1-1">
          <label htmlFor="currency" className="form__label">
            Jobstream Portfolio Number
          </label>
          <input type="text" name="portfolio[jobstream_portfolio_number]" defaultValue={jobstreamPortfolioNumber} />
        </FormRow>
        <FormRow note="" className="col-mc-1-1">
          <label htmlFor="currency" className="form__label">
            Custodian
          </label>
          <input type="text" name="portfolio[custodian]" defaultValue={custodian} />
        </FormRow>
        <FormRow note="" className="col-mc-1-1">
          <label htmlFor="currency" className="form__label">
            Custodian Reference
          </label>
          <input type="text" name="portfolio[custodian_reference]" defaultValue={custodianReference} />
        </FormRow>
      </div>
    </fieldset>
  );
}
