import * as React from 'react';

export default function footer({ index, reportTemplate }) {
  const { reportTemplateTheme } = reportTemplate;

  const footerStyles = {
    backgroundColor: reportTemplateTheme.footerColor,
    backgroundImage: `url('${reportTemplateTheme.footerBackground}')`,
  };

  const fontColor = {
    color: reportTemplateTheme.footerFontColor,
  };

  let borderCss = { __html: '' };
  if (!reportTemplateTheme.footerBackground) {
    borderCss = {
      __html: `.pdf-footer--fixed:after { background-color: ${reportTemplateTheme.footerBorderColor} }`,
    };
  }

  return (
    <footer className="pdf-footer pdf-footer--landscape pdf-footer--fixed" style={footerStyles}>
      <style dangerouslySetInnerHTML={borderCss} />
      <table className="pdf-hidden-table" style={{ height: '100%' }}>
        <tbody>
          <tr className="text-bold">
            <td style={{ width: '80%', fontSize: '1.3rem', verticalAlign: 'middle' }}>
              <span style={fontColor}>Report Name. Date</span>
            </td>
            <td style={{ fontSize: '1.3rem', textAlign: 'right', verticalAlign: 'middle' }}>
              <span style={fontColor}>{index + 1}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </footer>
  );
}
