import { TTheme, useThemeFactory } from '../functions';

/* New Brand Colours */

export const aqua = '#01feff';
export const lightAqua = '#adeded';
export const midAqua = '#58d1d2';
export const darkAqua = '#009898';

export const green = '#2eff01';
export const lightGreen = '#aef29f';
export const midGreen = '#6bd055';
export const darkGreen = '#27a10c';

export const purple = '#b517ff';
export const lightPurple = '#d6a1ee';
export const midPurple = '#b33ee9';
export const darkPurple = '#660b91';

export const lightLime = '#c5dc16';
export const midLime = '#98a723';
export const darkLime = '#98a723';

export const lightYellow = '#f5e289';
export const midYellow = '#e5c217';
export const darkYellow = '#bc9c00';

export const lightOrange = '#ffca7e';
export const midOrange = '#ff9803';
export const darkOrange = '#cd7d07';

export const lightRed = '#f89696';
export const midRed = '#ff5151';
export const darkRed = '#cd2525';

export const lightBlue = '#a3a3f5';
export const midBlue = '#5353e1';
export const darkBlue = '#121293';

export const lightMagenta = '#f9abf9';
export const midMagenta = '#ec5dec';
export const darkMagenta = '#a339a3';

export const grey90 = '#191919';
export const grey80 = '#333333';
export const grey70 = '#4c4c4c';
export const grey60 = '#666666';
export const grey50 = '#7f7f7f';
export const grey40 = '#999999';
export const grey30 = '#b2b2b2';
export const grey20 = '#cccccc';
export const grey10 = '#e5e5e5';
export const grey05 = '#f4f4f4';
export const inputGrey = '#1A1A1A';

export const white = '#ffffff';

/* eslint-disable object-literal-sort-keys */

const benchmark = {
  cash: midOrange,
  equity: grey60,
  enhance: midAqua,
  peerGroup: darkAqua,
  peerGroupLow: lightLime,
  peerGroupMed: midLime,
  peerGroupHig: darkLime,
  managers: midYellow,
  targetReturn: darkMagenta,
};

const assetClass = {
  cash: midOrange,
  fixedIncome: lightLime,
  equity: grey60,
  hedge_fund: midAqua,
  other: darkAqua,
  commodities: midYellow,
  realEstate: darkMagenta,
  privateMarkets: grey40,
};

const reviewBreakdown = {
  awaitingData: grey60,
  inProgress: midOrange,
  complete: lightLime,
};

const clientType = {
  consultancy: midOrange,
  monitoring: aqua,
  portfolio: green,
};

const currency = {
  gbp: midOrange,
  usd: lightLime,
  eur: grey60,
  chf: midAqua,
  other: darkAqua,
};

const riskProfile = {
  cash: midOrange,
  fixedIncome: lightLime,
  equity: grey60,
  hedge: midAqua,
  growth: darkAqua,
  cautious: midYellow,
  balanced: darkMagenta,
};

const serviceType = {
  advisory: midOrange,
  consolidated: lightLime,
  custodyOnly: grey60,
  dealingWithAdvice: midAqua,
  discretionary: darkAqua,
  enhanceMps: midYellow,
  enhancePortfolio: darkMagenta,
  executionOnly: grey40,
  familyOffice: green,
  fund: midOrange,
  internationalLifeInsuranceBond: lightMagenta,
  reservedPowers: midLime,
  tbc: midBlue,
  tmpi: midRed,
};

const shared = {
  portfolio: purple,
  tbc: grey30,
  unclassified: grey50,
};

/*
 * A set of colours which can be used for any undefined values which might be observed during the use of useThemeColours
 */
const general = {
  // aqua, -- Removed as client type colour
  lightAqua,
  midAqua,
  darkAqua,
  green,
  lightGreen,
  // midGreen, -- Removed as client type colour
  darkGreen,
  lightLime,
  midLime,
  darkLime,
  lightYellow,
  midYellow,
  darkYellow,
  lightOrange,
  // midOrange, -- Removed as client type colour
  darkOrange,
  lightRed,
  midRed,
  darkRed,
  //purple, -- Removed as default portfolio colour
  lightPurple,
  midPurple,
  darkPurple,
  lightBlue,
  midBlue,
  darkBlue,
  lightMagenta,
  midMagenta,
  darkMagenta,
};

const localTheme = {
  assetClass,
  benchmark,
  clientType,
  currency,
  general,
  reviewBreakdown,
  riskProfile,
  serviceType,
  shared,
};
/* eslint-enable object-literal-sort-keys */

export const theme: Readonly<typeof localTheme> = localTheme;

export const useClientPortalTheme = useThemeFactory<typeof localTheme>(theme);
