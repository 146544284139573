import * as React from 'react';

import { format, parse } from 'date-fns';
import lodashStartcase from 'lodash.startcase';

import NewReview from './NewReview';
import Context from '../../Context';

const classNames = require('classnames');

export default function reviewSelector({ handleReviewSelect, getReviews, isOpen, totalReviews, reviews, setIsOpen }) {
  const context = React.useContext(Context);
  const { canManageReviews } = context.props;
  function ratingBoxes(rating, ratingStatus) {
    if (ratingStatus === 'insufficient_data') {
      return (
        <div>
          <div className="rating-box rating-box--grey">Insufficient Data</div>
        </div>
      );
    }
    return [1, 2, 3, 4, 5].map((num) => {
      const roundedRating = Math.round(rating);
      const ratingClasses = classNames(
        'rating-box',
        { 'rating-box--one': roundedRating === num && num === 1 },
        { 'rating-box--two': roundedRating === num && num === 2 },
        { 'rating-box--three': roundedRating === num && num === 3 },
        { 'rating-box--four': roundedRating === num && num === 4 },
        { 'rating-box--five': roundedRating === num && num === 5 },
      );
      return (
        <div key={num}>
          <div className={ratingClasses}>{num}</div>
        </div>
      );
    });
  }

  function renderReviews() {
    return reviews.map((review, index) => {
      const {
        displayStatus,
        endDate,
        invoicedAt,
        periodMonths,
        ratingStatus,
        ratingValue,
        realStartDate,
        showInvoiceData,
        status,
        timePeriod,
      } = review;
      function selectReview() {
        handleReviewSelect(review.id);
      }
      function displayInvoiceInfo() {
        if (!showInvoiceData || status !== 'sent') return;

        if (invoicedAt) {
          return <span className="helper-text text-white">- Invoiced {format(parse(invoicedAt), 'DD MMM YYYY')}</span>;
        }
      }
      return (
        <div className="col-sm-1-3 col-lg-1-5" key={index}>
          <div className="card card--clickable card--clickable-top" onClick={selectReview}>
            <div className="card__inner">
              <div className="frow frow--direction-column frow--full-height">
                <div>
                  <p
                    className={`status-indicator status-indicator--${displayStatus.color}
                        mar-v-0 text-white small-uppercase`}
                    data-number={displayStatus.number}
                  >
                    {lodashStartcase(status)} {displayInvoiceInfo()}
                  </p>
                </div>
                <div className="mar-t-7">
                  <p className="card__title">{timePeriod}</p>
                  <div className="mar-t-1 text-white text-small text-uppercase">{periodMonths} months</div>
                  <p className="card__subtitle">
                    {format(parse(realStartDate), 'MMM YY')} &rarr; {format(parse(endDate), 'MMM YY')}
                  </p>
                </div>
              </div>
            </div>
            <div style={{ marginTop: 'auto' }}>
              <div className="card__footer">
                <div className="frow frow--gutters-half">{ratingBoxes(ratingValue, ratingStatus)}</div>
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  const iconClasses = classNames('icon-arrow-down', 'icon-animated', 'icon-fw', 'icon-push-down-2', 'icon-1-3x', {
    'icon-flipped': isOpen,
  });

  function toggleOpen() {
    setIsOpen(!isOpen);
  }

  function renderShowMore() {
    if (totalReviews <= 5) {
      return;
    }

    return (
      <div className="platform-panel__inner mar-t-3 mar-b-0">
        <div className="frow frow--items-center frow--justify-center">
          <a className="link link--small link--bold" onClick={toggleOpen}>
            <span className="mar-r-1">Show {isOpen ? 'Less' : 'More'}</span>
            <i className={iconClasses} />
          </a>
        </div>
      </div>
    );
  }

  return (
    <div className="platform-panel__inner">
      <div className="frow frow--items-center frow--justify-between">
        <div>
          <h2 className="heading-two mar-b-2">{totalReviews} Reviews</h2>
        </div>
        {canManageReviews && <NewReview {...{ getReviews }} />}
      </div>
      <div className="frow frow--gutters">{renderReviews()}</div>
      {renderShowMore()}
    </div>
  );
}
