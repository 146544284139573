import * as React from 'react';

import lodashStartcase from 'lodash.startcase';

import Context from '../Context';
import ReactSelect from 'components/shared/forms/ReactSelect';
import FormRow from 'components/shared/forms/FormRow';

export default function dataChase() {
  const context = React.useContext(Context);
  const { portfolio } = context.state;
  const { dataChaseTypeOptions } = context.props;
  const { dataChaseType } = portfolio;

  let defaultValue = null;
  if (portfolio && dataChaseType) {
    defaultValue = { label: lodashStartcase(dataChaseType), value: dataChaseType };
  }
  return (
    <fieldset className="form__fieldset">
      <h4 className="form__fieldset-label">Data Chase Type</h4>
      <div className="frow frow--gutters">
        <FormRow note="" className="col-mc-1-1">
          <ReactSelect
            theme="dark"
            name="portfolio[data_chase_type]"
            id="data_chase_type"
            isClearable
            options={dataChaseTypeOptions}
            defaultValue={defaultValue}
          />
        </FormRow>
      </div>
    </fieldset>
  );
}
