/* eslint-disable  */
import { Attr, BelongsTo, HasMany, HasOne, Model } from 'spraypaint';

import { compose, equals, not } from 'ramda';

import { ApplicationRecord } from './ApplicationRecord';
import { ActionPoint } from './ActionPoint';
import { ActionPointsReview } from './ActionPointsReview';
import { Administrator } from './Administrator';
import { Portfolio } from './Portfolio';
import { THasManyAttached } from './shared/types';
import { ReviewTransition } from './ReviewTransition';
import { NotesNotable } from './NotesNotable';
import { Manager } from './Manager';

@Model()
export class DataUpload extends ApplicationRecord {
  public static jsonapiType = 'data_uploads';
  @Attr() public attachment: string;
  @Attr() public managerId: number;
  @Attr() public fileName: string;
  @Attr() public createdAt: string;
  @Attr() public updatedAt: string;
  @Attr() public processed: boolean;
  @Attr() public processing: boolean;
  @Attr() public hasErrorMessages: boolean;
  @Attr() public downloadPath: string;
  @Attr() public showPath: string;
  @Attr() public overwrite: boolean;
  @HasMany() public notes: Note[];
}

@Model()
export class Note extends ApplicationRecord {
  public static jsonapiType = 'notes';
  @Attr() public additionalInfo: string;
  @Attr() public body: string;
  @Attr() public authorId: number;
  @Attr() public authorType: string;
  @Attr() public editable: boolean;
  @Attr() public noteType: string;
  @Attr() public notableType: string;
  @Attr() public noteCategory: string;
  @Attr() public status: string;
  @Attr() public tagList: string[];
  @Attr() public createdAt: string;
  @Attr() public updatedAt: string;
  @Attr({ dirtyChecker: compose(not, equals) }) public attachments: THasManyAttached;

  @BelongsTo() public author: Administrator;

  @HasMany() public portfolios: Portfolio[];
  @HasMany() public actionPoints: ActionPoint[];
  @HasMany() public managers: Manager[];

  public get hasBeenUpdated() {
    return this.createdAt !== this.updatedAt;
  }
}

@Model()
export class Review extends ApplicationRecord {
  public static jsonapiType = 'reviews';
  @Attr() public clientName: string;
  @Attr() public fee: number;
  @Attr() public dataReceivedOn: string;
  @Attr() public dueDate: string;
  @Attr() public endDate: string;
  @Attr() public enrichedHoldings: string;
  @Attr() public invoicedAt: string;
  @Attr() public previousOwnerId: string;
  @Attr() public reviewableId: string;
  @Attr() public reviewableType: string;
  @Attr() public startDate: string;
  @Attr() public status: string;
  @Attr() public uploadErrors: string;
  @Attr() public notesAttributes: { body: string }[];
  @Attr() public portfolioReference: string;
  @Attr() public showInvoiceData: boolean;
  // Extra attributes
  @Attr() public client: string;
  @Attr() public clientTypeIcon: string;
  @Attr() public currency: string;
  @Attr() public displayStatus: { color: string; number: string };

  @Attr() public investmentRestrictions: [];
  @Attr() public investmentRestrictionsCommentary: string;
  @Attr() public tacticalAssetAllocation: string;
  @Attr() public foreignCurrencyExposure: string;
  @Attr() public singleDirectBondConcentration: string;
  @Attr() public singleStockConcentration: string;
  @Attr() public singleFundConcentration: string;
  @Attr() public nonInvestmentGradeBonds: string;
  @Attr() public countryOfDomicile: string;
  @Attr() public partOfAWiderStructure: string;
  @Attr() public priorActionPointStatus: string;
  @Attr() public lowConsecutiveRatings: string;

  @Attr() public entityName: string;
  @Attr() public flagList: string[];
  @Attr() public managerReference: string;
  @Attr() public managerName: string;
  @Attr() public periodMonths: string;
  @Attr() public portfolioId: string;
  @Attr() public ratingStatus: string;
  @Attr() public ratingValue: number;
  @Attr() public realStartDate: string;
  @Attr() public reference: string;
  @Attr() public risk: string;
  @BelongsTo() public currentOwner: Administrator;
  @BelongsTo() public previousOwner: Administrator;
  @Attr() public timePeriod: string;
  @BelongsTo() public portfolio: Portfolio;
  @HasMany() public notes: Note[];
  @HasMany(ActionPointsReview) public actionPointsReviews: ActionPointsReview[];
  @HasMany(ActionPoint) public actionPoints: ActionPoint[];
  @BelongsTo(Portfolio) public reviewable: Portfolio;
  @HasMany(ReviewTransition) public reviewTransitions: ReviewTransition[];
  @HasOne(ReviewTransition) public lastReviewTransition: ReviewTransition;
}
