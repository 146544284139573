import * as React from 'react';
import { ColumnContext, defaultColumnContextValue } from './ColumnContext';

export type TColumnWidths = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
export type TBreakPointSizes = 'mc' | 'tn' | 'sm' | 'md' | 'lg' | 'gt' | 'hg';

export interface IColumnProps<TagType> extends React.DetailedHTMLProps<React.HTMLAttributes<TagType>, TagType> {
  additionalClassNames?: any;
  breakpointSize?: TBreakPointSizes;
  children?: React.ReactNode;
  columnSpan?: TColumnWidths;
  maxColumns?: TColumnWidths;
  tag?: string;
}

interface IColumnTag extends Element, HTMLOrSVGElement {}

const Column = <TagType extends IColumnTag>(props: IColumnProps<TagType>) => {
  const columnContext = React.useContext(ColumnContext);
  const { additionalClassNames, breakpointSize, columnSpan, maxColumns, tag } = {
    ...columnContext,
    ...props,
  };

  const tempTagProps = { ...props };

  ['additionalClassNames', 'breakpointSize', 'children', 'columnSpan', 'maxColumns', 'tag'].forEach(
    (prop) => delete tempTagProps[prop],
  );

  const tagProps: React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> = {
    ...tempTagProps,
  } as unknown;

  let newClassName = `col-${breakpointSize}-${columnSpan}-${maxColumns}`;
  if (additionalClassNames) newClassName += ` ${additionalClassNames}`;

  tagProps.className = `${newClassName} ${tagProps.className || ''}`;

  const CustomTag = tag;

  return (
    <ColumnContext.Provider value={defaultColumnContextValue}>
      <CustomTag {...tagProps}>{props.children}</CustomTag>
    </ColumnContext.Provider>
  );
};

export { Column };
export default Column;
