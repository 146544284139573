import * as React from 'react';

import Presenter from './comments/Presenter';

import Context from '../../../Context';
import ReviewContext from './ReviewContext';
import { Review } from 'javascript/models';

export default function comments() {
  const reviewContext = React.useContext(ReviewContext);
  const context = React.useContext(Context);
  const { currentUserId } = context.props;

  const [review, setReview] = React.useState();
  const [notes, setNotes] = React.useState([]);

  React.useEffect(() => {
    getComments();
  }, [reviewContext.props.review]);

  async function getComments() {
    const { data } = await Review.includes({ notes: 'author' })
      .order({ 'notes.created_at': 'desc' })
      .find(reviewContext.props.review.id);

    setReview(data);
    setNotes(data.notes);
  }

  async function handleFormSubmit(comment: string) {
    review.notesAttributes = [
      {
        author_id: currentUserId,
        author_type: 'Administrator',
        body: comment,
        note_category: 'note',
        note_type: 'general',
        status: 'normal',
      },
    ];
    const success = await review.save();
    if (success) {
      getComments();
    }
  }

  async function handleUpdate(comment: string, noteId: string) {
    review.notesAttributes = [{ id: noteId, body: comment }];
    const success = await review.save();
    if (success) {
      getComments();
    }
  }

  async function handleDelete(noteId: string, destroy: boolean) {
    review.notesAttributes = [{ id: noteId, _destroy: destroy }];
    const success = await review.save();
    if (success) {
      getComments();
    }
  }

  return <Presenter {...{ handleDelete, handleUpdate, handleFormSubmit, notes }} />;
}
