import * as React from 'react';

import ReviewBox from './ReviewBox';
import Loading from 'components/shared/Loading';

import { Review } from 'javascript/models';

interface IProps {
  dashboardPath: string;
  data: Review[];
  isLoading: boolean;
}

export default function presenter(props: IProps) {
  const { dashboardPath, data, isLoading } = props;

  function reviews() {
    if (data.length) {
      return data.map((review) => {
        return <ReviewBox key={review.id} review={review} />;
      });
    }
    return (
      <div className="col-mc-1-1">
        <div className="frow frow--justify-center frow--items-center">
          <p className="heading-one text-grey">No results</p>
        </div>
      </div>
    );
  }

  return (
    <div className="platform-panel">
      <header className="platform-panel__header">
        <a href={dashboardPath} className="link link--left-arrow">
          Back
        </a>
      </header>
      <div className="platform-content platform-content--padding-all">
        <div className="frow frow--gutters">{isLoading ? <Loading /> : reviews()}</div>
      </div>
    </div>
  );
}
