import * as React from 'react';

import lodashStartcase from 'lodash.startcase';

export default function columns() {
  return [
    {
      Header: 'Asset Name',
      accessor: 'assetName',
      columnName: 'Asset Name',
      id: 'asset_name',
    },
    {
      Cell: (row) => {
        return <div>{lodashStartcase(row.original.assetClass)}</div>;
      },
      Header: 'Asset Class',
      accessor: 'assetClass',
      columnName: 'Asset Class',
      id: 'asset_class',
    },
    {
      Header: 'ISIN',
      accessor: 'isin',
      columnName: 'ISIN',
      id: 'isin',
    },
    {
      Cell: (row) => {
        return (
          <a href={row.original.editPath} style={{ display: 'block' }}>
            <div className="rt-tr-clickable-icon">
              <i className="icon-arrow-right-top icon-0-8x" />
            </div>
          </a>
        );
      },
      Header: '',
      sortable: false,
    },
  ];
}
