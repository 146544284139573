import * as React from 'react';

import Select from './Select';

import FormRow from 'components/shared/forms/FormRow';

interface IProps {
  benchmarkType: string;
  index: number;
  portfoliosBenchmark: any;
  primaryIndex: number;
  secondaryIndex: number;
  tertiaryIndex: number;
  changePrimaryIndex(index: number): void;
  changeSecondaryIndex(index: number): void;
  changeTertiaryIndex(index: number): void;
}

export default function row(props: IProps) {
  const {
    benchmarkType,
    changePrimaryIndex,
    changeSecondaryIndex,
    changeTertiaryIndex,
    index,
    portfoliosBenchmark,
    primaryIndex,
    secondaryIndex,
    tertiaryIndex,
  } = props;

  const [isDeleted, setIsDeleted] = React.useState(false);
  const { id, benchmark_id, benchmark_name, primary, secondary, tertiary } = portfoliosBenchmark;

  React.useEffect(() => {
    if (primary && primaryIndex !== index) {
      changePrimaryIndex(index);
    }
    if (secondary && secondaryIndex !== index) {
      changeSecondaryIndex(index);
    }
    if (tertiary && tertiaryIndex !== index) {
      changeTertiaryIndex(index);
    }
  }, []);

  function handleDelete() {
    setIsDeleted(true);
  }

  function renderHiddenField() {
    if (isDeleted) {
      return (
        <input type="hidden" value="true" name={`portfolio[portfolios_benchmarks_attributes][${index}][_destroy]`} />
      );
    }
  }

  const primaryInput = (
    <input
      type="hidden"
      name={`portfolio[portfolios_benchmarks_attributes][${index}][primary_benchmark]`}
      value={primaryIndex === index ? '1' : '0'}
    />
  );

  const secondaryInput = (
    <input
      type="hidden"
      name={`portfolio[portfolios_benchmarks_attributes][${index}][secondary_benchmark]`}
      value={secondaryIndex === index ? '1' : '0'}
    />
  );

  const idInput = <input type="hidden" value={id} name={`portfolio[portfolios_benchmarks_attributes][${index}][id]`} />;

  const benchmarkTypeInput = (
    <input
      type="hidden"
      name={`portfolio[portfolios_benchmarks_attributes][${index}][benchmark_type]`}
      value={benchmarkType}
    />
  );

  const newBenchmark = (
    <FormRow note="" className="col-mc-1-1">
      <div className="frow frow--gutters">
        <div className="col-mc-5-6">
          <Select
            benchmarkType={benchmarkType}
            defaultValue={{ label: null, value: null }}
            index={index * 5}
            name="benchmark"
            type={benchmarkType}
            primaryChange={() => changePrimaryIndex(index * 5)}
            {...{ changeSecondaryIndex, changeTertiaryIndex, primaryIndex, secondaryIndex, tertiaryIndex }}
          />
        </div>
      </div>
    </FormRow>
  );

  const deleteButton = (
    <div
      className="button button--secondary button--danger button--icon"
      onClick={handleDelete}
      style={{ margin: '6px 0' }}
    >
      <i className="icon-bin icon-fw" />
    </div>
  );

  return (
    <div className="frow">
      <FormRow note="" className={`col-mc-1-1 ${isDeleted && 'hidden'}`}>
        <div className="frow frow--gutters">
          <div className="col-mc-5-6">
            <Select
              benchmarkType={benchmarkType}
              defaultValue={{ label: benchmark_name, value: benchmark_id }}
              name="benchmark"
              type={benchmarkType}
              primaryChange={() => changePrimaryIndex(index)}
              {...{ changeSecondaryIndex, changeTertiaryIndex, index, primaryIndex, secondaryIndex, tertiaryIndex }}
            />
          </div>
          <div className="col-mc-1-6">{benchmarkType !== 'enhance_benchmark' && deleteButton}</div>
        </div>
      </FormRow>
      {renderHiddenField()}
      {benchmarkTypeInput}
      {id && idInput}
      {id && primaryInput}
      {id && secondaryInput}
      {isDeleted && newBenchmark}
    </div>
  );
}
