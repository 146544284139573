import * as React from 'react';

import TemplateSheetColumn from './columns/TemplateSheetColumn';

import { UploadTemplateSheet, UploadTemplateSheetColumn } from 'javascript/models';

interface IProps {
  uploadTemplateSheet: UploadTemplateSheet;
}

export default function columns(props: IProps) {
  const { uploadTemplateSheet } = props;

  const [uploadTemplateSheetColumns, setUploadTemplateSheetColumns] = React.useState(
    uploadTemplateSheet.uploadTemplateSheetColumns,
  );

  React.useEffect(() => {
    setUploadTemplateSheetColumns(uploadTemplateSheet.uploadTemplateSheetColumns);
  }, [uploadTemplateSheet]);

  async function handleNewColumn() {
    const newColumn = new UploadTemplateSheetColumn({ uploadTemplateSheetId: uploadTemplateSheet.id });
    const success = await newColumn.save();
    const allColumns = [...uploadTemplateSheetColumns];
    allColumns.push(newColumn);
    setUploadTemplateSheetColumns(allColumns);
  }

  async function handleDestroyColumn(columnId) {
    const column = uploadTemplateSheetColumns.find((column) => column.id === columnId);
    const success = await column.destroy();
    if (success) {
      const newColumns = [...uploadTemplateSheetColumns];
      const index = newColumns.indexOf(column);
      newColumns.splice(index, 1);
      setUploadTemplateSheetColumns(newColumns);
    }
  }

  const columns = uploadTemplateSheetColumns.map((uploadTemplateSheetColumn, index) => {
    return (
      <TemplateSheetColumn
        key={uploadTemplateSheetColumn.id}
        {...{ handleDestroyColumn, index, uploadTemplateSheetColumn, uploadTemplateSheetColumns }}
      />
    );
  });

  return (
    <div>
      <label htmlFor="" className="form__label">
        Columns
      </label>
      <div style={{ width: '100%', overflowX: 'scroll', paddingBottom: '20px' }}>
        <div className="frow frow--gutters frow--nowrap">
          {columns}
          <div>
            <div
              className="card frow frow--centered-column frow--items-center fa-clickable"
              style={{ width: '250px', minHeight: '300px' }}
              onClick={handleNewColumn}
            >
              <h4
                className="card__title card__title--small"
                style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
              >
                <i className="icon-plus icon-fw mar-r-1 icon-push-down-1" /> Add Column
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
