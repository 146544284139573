import * as React from 'react';

import * as ReactTooltip from 'react-tooltip';

import { downloadTemplatePlatformBulkImportsContactsPath } from 'javascript/application/ts_routes';
import Contacts from './Contacts';
import Dropzone from 'components/shared/forms/Dropzone';
import Loading from 'components/shared/Loading';
import Button from 'components/shared/forms/Button';
import FormRow from 'components/shared/forms/FormRow';
import ReactSelect from 'components/shared/forms/ReactSelect';

import { BulkImport } from 'javascript/models';
import ISelectOption from 'components/interfaces/ISelectOption';
import Alert from 'components/shared/Alert';
import { Frow } from 'components/frow';

interface IProps {
  bulkImport: BulkImport;
  client: string;
  clientOptions: ISelectOption[];
  displayErrors: boolean;
  isProcessing: boolean;
  isSubmitting: boolean;
  users: any;
  handleClientSelect(event): void;
  handleFileUpload(files): void;
  handleParamsChange(uniqueId, user): void;
  handleSubmit(): void;
}

export default function presenter(props: IProps) {
  const {
    bulkImport,
    client,
    clientOptions,
    displayErrors,
    handleClientSelect,
    handleFileUpload,
    handleParamsChange,
    handleSubmit,
    isProcessing,
    isSubmitting,
    users,
  } = props;

  const previewText = (
    <div className="platform-panel__inner">
      <p className="text-white helper-text mar-v-0">Upload a sheet to see a preview here</p>
    </div>
  );

  function submitButton() {
    return (
      <div className="mar-v-2">
        <Button
          text="Import Sheet"
          isSubmitting={isSubmitting}
          submittingText="Importing Sheet"
          handleClick={handleSubmit}
          disabledText="Unable to submit with errors"
        />
      </div>
    );
  }

  const result = (
    <div className="platform-panel__inner">
      <Contacts {...{ bulkImport, client, handleParamsChange, users }} />
      {bulkImport && submitButton()}
      <ReactTooltip effect="solid" />
    </div>
  );

  const dropzone = (
    <div className="col-mc-1-1">
      <div className="form">
        <label className="form__label">Upload Sheet</label>
        <Dropzone
          acceptedFiles=".csv, .xls, .xml, .xlsx, .xlsm"
          theme="dark"
          multiple={false}
          onFileUpload={handleFileUpload}
        />
      </div>
    </div>
  );

  const errorMessage = (
    <div className="platform-panel__inner">
      <Alert
        message="Please check the formatting of the sheet and the column headers"
        title="Unable to parse"
        type="error"
      />
    </div>
  );

  return (
    <React.Fragment>
      <div className="frow">
        <div className="col-md-1-1">
          <section className="platform-panel">
            <header className="platform-panel__header">
              <Frow justifyContent="between" itemAlignment="baseline">
                <h3 className="platform-panel__title">Upload</h3>
                <Button
                  buttonClasses="button--secondary button--no-min-width"
                  handleClick={() => (window.location.href = downloadTemplatePlatformBulkImportsContactsPath())}
                  hasIcon
                  icon="document"
                  text="Download Template"
                />
              </Frow>
            </header>
            <div className="platform-panel__inner">
              <div className="frow frow--gutters">
                <FormRow>
                  <label htmlFor="client_id" className="form__label">
                    Client
                  </label>
                  <ReactSelect
                    theme="dark"
                    name="portfolio[client_id]"
                    handleChange={handleClientSelect}
                    id="client"
                    options={clientOptions}
                  />
                </FormRow>
                {client && dropzone}
              </div>
            </div>
          </section>
        </div>
      </div>
      <div className="frow">
        <div className="col-md-1-1">
          <section className="platform-panel">
            <header className="platform-panel__header">
              <h3 className="platform-panel__title">Preview</h3>
            </header>
            {displayErrors && errorMessage}
            {isProcessing ? <Loading /> : bulkImport && bulkImport.preview.data.length > 0 ? result : previewText}
          </section>
        </div>
      </div>
    </React.Fragment>
  );
}
