import * as React from 'react';

import { Droppable } from 'react-beautiful-dnd';

import Context from '../../Context';
// import ColorSelect from '../ColorSelect';
import Rows from '../../report/Rows';
import Footer from './Footer';
import { Alert, Intent } from '@blueprintjs/core';
import useVisibility from 'components/shared/customHooks/useVisibility';

export default function repeatingPages({ dummyContainerRef, startAt, startIndex, templateRows }) {
  const context = React.useContext(Context);
  const { canEdit } = context.props;
  const { focus, reportTemplate } = context.state;
  const { getData, setFocus } = context.actions;
  const color = reportTemplate.reportTemplateTheme.headerColor;
  const { handleClose, handleOpen, isOpen } = useVisibility(false);

  async function confirmDelete() {
    const success = await row.destroy();

    if (success) {
      getData();
    }
  }

  function toggleFocus() {
    if (isFocused) {
      setFocus('report');
    } else {
      setFocus(row.id);
    }
  }

  const row = templateRows[0];
  const isFocused = focus === row.id;

  let total = 0;
  const overallTemplateSections = [];
  let localTemplateSections = [];
  templateRows[0].childRows.forEach((row, index) => {
    const rowHeight = row.height || 4.1;

    // The blank page filler has a height of 100 or 140 for landscape, we don't want this to actually take a whole
    // page. Just push the rest of the stuff onto a new page
    if (parseFloat(rowHeight) == 100 || parseFloat(rowHeight) == 140) {
      // 26 is the section id for theme pages these are treated differently since they are a single page and only
      // available on the landscape report. Double instead of triple equals because it comes through as a sting not
      // a number
      if (row.reportTemplatesReportTemplateSections[0].reportTemplateSectionId == 26) {
        overallTemplateSections.push(localTemplateSections);
        localTemplateSections = [];
        localTemplateSections.push(row);
        overallTemplateSections.push(localTemplateSections);
      } else {
        localTemplateSections.push(row);
        overallTemplateSections.push(localTemplateSections);
      }
      localTemplateSections = [];
      total = 0;
      return;
    }

    // Landscape height is 82.5% - 600 - 35 (header) - 50 (footer) - 20 (vertical padding) = 495 = 495 / 600 = 0.825
    const percentageAvailable = 82.5;

    if (total + parseFloat(rowHeight) > percentageAvailable) {
      overallTemplateSections.push(localTemplateSections);
      localTemplateSections = [row];
      total = parseFloat(rowHeight);
      // If this one happens to be the last one we need to add the local array to the overall array
      if (index + 1 === templateRows[0].childRows.length) {
        overallTemplateSections.push(localTemplateSections);
      }
      // If this is the last one (but doesn't push us over the 100 marks we add the section into the local array and
      // add the local array into the overall array
    } else if (index + 1 === templateRows[0].childRows.length) {
      localTemplateSections.push(row);
      overallTemplateSections.push(localTemplateSections);
      // If we are not over 100 and this isn't the last section we just add the section into the local array and
      // increase the total by the height of the section
    } else {
      localTemplateSections.push(row);
      total += parseFloat(rowHeight);
    }
  });
  const totalPages = overallTemplateSections.length < 1 ? 1 : overallTemplateSections.length;

  const pages = Array.apply(null, Array(totalPages))
    .map((x, i) => i)
    .map((pageNumber, index) => {
      let innerStartAt = 0;
      for (let i = 0; i < index; i++) {
        innerStartAt += overallTemplateSections[i].length;
      }

      const isFocused = focus === row.id;

      return (
        <Droppable droppableId={`droppable-report-${startAt + 1}`} isDropDisabled={focus !== 'report'} key={index}>
          {(provided, snapshot) => {
            const styles = {
              background: snapshot.isDraggingOver ? '#FAFAFA' : '',
              height: '100%',
              ...provided.droppableProps.style,
            };
            return (
              <div ref={provided.innerRef} {...provided.droppableProps} style={styles}>
                <div
                  className="pdf-page pdf-page--landscape"
                  style={{ border: '1px dashed #CF7838', borderColor: isFocused ? '#78AE6E' : '#CF7838' }}
                >
                  <header
                    className="pdf-footer pdf-footer--landscape pdf-footer--landscape-compact"
                    style={{ background: color }}
                  />
                  <div className="pdf-main" style={{ height: '85.93%' }}>
                    {/*<ColorSelect color="white" top={10} {...{ index }} />*/}
                    <Droppable droppableId={`repeating-row-${row.id}-${innerStartAt}`} isDropDisabled={!isFocused}>
                      {(provided, snapshot) => {
                        const styles = {
                          background: snapshot.isDraggingOver ? '#FAFAFA' : '',
                          height: '100%',
                          ...provided.droppableProps.style,
                        };
                        return (
                          <div ref={provided.innerRef} {...provided.droppableProps} style={styles}>
                            <Rows
                              {...{ dummyContainerRef }}
                              canEdit={canEdit && focus === row.id}
                              templateRows={overallTemplateSections[index]}
                            />
                            {provided.placeholder}
                          </div>
                        );
                      }}
                    </Droppable>
                  </div>
                  <Footer index={startIndex + index} />
                </div>
                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
      );
    });

  const popout = (
    <div className="editable-row__popout" style={{ top: '-49px' }}>
      <div className="button button--secondary button--light-themed button--icon mar-r-1" onClick={toggleFocus}>
        <i className={`${isFocused ? 'icon-tick' : 'icon-edit'} icon-fw`} />
      </div>
      <div className="button button--secondary button--danger button--icon" onClick={handleOpen}>
        <i className="icon-bin icon-fw" />
      </div>
    </div>
  );

  return (
    <div className="editable-row">
      {canEdit && popout}
      {pages}
      <Alert
        isOpen={isOpen}
        onClose={handleClose}
        canEscapeKeyCancel
        canOutsideClickCancel
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        intent={Intent.DANGER}
        onCancel={handleClose}
        onConfirm={confirmDelete}
        portalContainer={document.body}
      >
        <p className="mar-t-0">Are you sure you want to delete this repeating page?</p>
      </Alert>
    </div>
  );
}
