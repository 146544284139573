import * as React from 'react';
import * as Mathjs from 'mathjs';
import ReactDataSheet from 'react-datasheet';
import { ICell, IScope, ISheetDatum } from 'components/interfaces/DataTableTypes';
import lodashStartcase from 'lodash.startcase';
import lodashFilter from 'lodash.filter';
import lodashMapkeys from 'lodash.mapkeys';
import lodashMapvalues from 'lodash.mapvalues';
import { format, addMonths, endOfMonth } from 'date-fns';
import NewRow from './NewRow';

const dataTable = ({
  sheetData,
  columnValues,
  amendedData,
  tab,
  showTotals,
  calculationScope,
  selectedDateAutoFillValue = 'quarterly',
}) => {
  interface IChange {
    cell: ICell;
    row: number;
    col: number;
    value: any;
  }

  type Row = ICell[];

  type Grid = Row[];

  const cellValues = (scope: IScope) => {
    const sheetValues = Object.fromEntries(Object.keys(scope).map((key) => [key.toLowerCase(), scope[key].value]));
    return { ...sheetValues, ...calculationScope };
  };

  const defaultColumns = () => {
    const columns = ['function', 'date', ...columnValues];
    showTotals && columns.push('total');
    return columns;
  };

  const evaluateExpr = (expr: string, scope: any) => {
    let value: number | null = null;
    if (expr.charAt(0) !== '=') {
      return { value: expr, expr };
    }
    try {
      // format result with precision set as just less than JS number precision to hide round off errors
      value = Mathjs.format(Mathjs.evaluate(expr.substring(1).toLowerCase(), cellValues(scope)), { precision: 14 });
    } catch (e) {
      value = null;
    }
    if (value !== null) {
      return { value, expr };
    }
    return { value: 'error', expr };
  };

  const evaluateScope = (scope: IScope) => {
    Object.entries(scope).forEach(([key, cell]) => (scope[key] = { ...cell, ...evaluateExpr(cell.expr, scope) }));
  };

  const defaultScope = (sheetData: ISheetDatum[]) => {
    const scope: IScope = {};
    rows.forEach((row) => {
      columns.forEach((col, colIndex) => {
        let valueObj: ICell = { value: '', expr: '', changed: false, row, col: colIndex };
        if (row === 0) {
          valueObj = { ...valueObj, value: col, expr: col, readOnly: true };
        } else if (colIndex === 0) {
          valueObj = { ...valueObj, readOnly: true, value: `r${row}`, expr: `r${row}`, changed: false };
        } else if (col === 'total') {
          const expr = `=sum(r${row}c${range(colIndex).slice(2).join(`, r${row}c`)})`;
          const value = Mathjs.evaluate(expr.substring(1).toLowerCase(), cellValues(scope));
          valueObj = { ...valueObj, expr, readOnly: true, value };
        } else if (col === 'date' || col === 'currency') {
          const value = sheetData[row - 1][col];
          valueObj = { ...valueObj, value, expr: value };
        } else if (tab === 'Balances') {
          const value = sheetData[row - 1][col];
          valueObj = {
            ...valueObj,
            date: sheetData[row - 1][col],
            expr: value.toString(),
            type: col,
            value,
          };
        } else {
          const value = sheetData[row - 1][col];
          valueObj = {
            ...valueObj,
            value: value || 0,
            expr: (value && value.toString()) || '0',
            date: sheetData[row - 1].date,
            type: col,
          };
        }
        scope[`r${row}c${colIndex}`] = valueObj;
      });
    });
    evaluateScope(scope);
    return scope;
  };

  const range = (n: number) => Array.from(Array(n).keys());
  const [columns, setColumns] = React.useState(defaultColumns());
  const [rows, setRows] = React.useState(range(sheetData.length + 1));
  const [scope, setScope] = React.useState({});

  const addNewRow = (currentScope: IScope, rowNum: number) => {
    let alteredRows = lodashFilter(scope, (cell: ICell) => cell.row >= rowNum);
    alteredRows = lodashMapvalues(alteredRows, (cell: ICell) => ({ ...cell, row: cell.row + 1 }));
    alteredRows = lodashMapkeys(alteredRows, (cell: ICell) => `r${cell.row}c${cell.col}`);
    const selectedDateRowAndColumn = `r${rowNum + 1}c1`;
    const selectedDateValue = alteredRows[selectedDateRowAndColumn].value;

    const newDate = generateDate(selectedDateValue);
    const newRow = formatNewScopeRow(rowNum, newDate);

    const newDateRowCells = lodashMapkeys(newRow, (cell: ICell) => `r${cell.row}c${cell.col}`);
    const newScope = { ...currentScope, ...alteredRows, ...newDateRowCells };
    setScope(newScope);
    setRows(range(rows.length + 1));
  };

  const formatNewScopeRow = (rowNum: number, newScopeDate: string) => {
    const tabsAndTheirNumberOfColumns = {
      'Asset Allocations': 7,
      'Balances & Cash Flows': 2,
      'Cash Flows': 4,
      'Currency Allocations': 15,
    };
    const numberOfColumns = tabsAndTheirNumberOfColumns[tab];
    const commonValues = {
      0: {
        value: '',
        expr: '',
        changed: true,
        row: rowNum,
        col: 0,
      },
      1: {
        value: newScopeDate,
        expr: newScopeDate,
        changed: true,
        row: rowNum,
        col: 1,
      },
      2: {
        value: '',
        expr: '',
        changed: true,
        row: rowNum,
        col: 2,
      },
    };

    const colValues = {};
    for (let i = 3; i < numberOfColumns; i++) {
      colValues[i] = {
        value: '',
        expr: '',
        changed: true,
        row: rowNum,
        col: i,
      };
    }
    return { ...commonValues, ...colValues };
  };

  const generateDate = (dateValue: string) => {
    const monthsToAddToDate = selectedDateAutoFillValue === 'monthly' ? 1 : 3;
    const date = addMonths(dateValue, monthsToAddToDate);
    const formatedDateValue = format(endOfMonth(date), 'YYYY-MM-DD');
    return formatedDateValue;
  };

  const addBottomRow = (scope: IScope) => {
    const newScope = { ...scope };
    const finalRowNum = rows.length;
    const blankCell = { value: ' ', expr: ' ', changed: false, row: finalRowNum, col: 0 };
    columns.map((columnName, col) => {
      let value = '';
      let expr = '';
      let readOnly = false;
      if (columnName === 'total') {
        expr = `=sum(r${finalRowNum}c${range(columns.length).slice(2, -1).join(`, r${finalRowNum}c`)})`;
        value = Mathjs.evaluate(expr.substring(1).toLowerCase(), cellValues(newScope));
        readOnly = true;
      }
      newScope[`r${finalRowNum}c${col}`] = { ...blankCell, expr, value, col, readOnly };
    });
    setRows(range(finalRowNum + 1));
    return newScope;
  };

  const addNewColumn = (colNum: number) => null;

  const newRowColumnHandler = (id: number, column: boolean) => (column ? addNewColumn(id) : addNewRow(scope, id));

  const generateGrid = (scope: IScope): Grid =>
    rows.map((row) =>
      columns.map((col, colIndex) => {
        const valuesObj = { ...scope[`r${row}c${colIndex}`] };
        if (row === 0 && tab === 'Currency Allocations' && !['total', 'date', 'other', 'function'].includes(col)) {
          return { readOnly: true, ...valuesObj, value: col.toUpperCase() };
        }
        if (row === 0 && colIndex === 0) {
          return { readOnly: true, value: '', expr: '', changed: false, row, col: colIndex, width: 40 };
        }
        if (row === 0 && col === 'date') {
          return { readOnly: true, ...valuesObj, value: lodashStartcase(col), width: 80 };
        }
        if (row === 0) {
          return { readOnly: true, ...valuesObj, value: lodashStartcase(col) };
        }
        if (colIndex === 0) {
          return {
            ...valuesObj,
            component: <NewRow {...{ newRowColumnHandler, id: row, column: false }} />,
            forceComponent: true,
            readOnly: true,
            width: 40,
          };
        }
        if (col === 'date') {
          return { ...valuesObj, width: 80 };
        }
        return { ...valuesObj };
      }),
    );

  const cellsChanged = (changes: IChange[]) => {
    const newScope = { ...scope };
    changes.forEach(({ value, row, col }) => {
      const key = `r${[row]}c${[col]}`;
      newScope[key] = { ...newScope[key], ...evaluateExpr(value, newScope), changed: true };
    });
    evaluateScope(newScope);
    setScope(newScope);
  };

  const valueRenderer = (cell: ICell, i: number, j: number) => {
    if (['balance', 'capitalIn', 'capitalOut'].includes(columns[j]) && i !== 0 && j !== 0) {
      if (cell.value) {
        return parseFloat(cell.value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      }
      return;
    }
    if (columns[j] === 'date' && i !== 0) {
      if (cell.value) {
        return Date.parse(cell.value) ? cell.value : 'Invalid date';
      }
      return;
    }
    return cell.value;
  };

  const dataRenderer = (cell: ICell) => cell.expr;

  React.useEffect(() => {
    let newScope = defaultScope(sheetData);
    newScope = addBottomRow(newScope);
    setScope(newScope);
  }, []);

  React.useEffect(() => {
    amendedData(scope, tab, columns);
  }, [scope]);

  return (
    <ReactDataSheet
      data={generateGrid(scope)}
      valueRenderer={valueRenderer}
      dataRenderer={dataRenderer}
      onCellsChanged={cellsChanged}
    />
  );
};

export default dataTable;
