import * as React from 'react';

import lodashStartcase from 'lodash.startcase';

import { format, parse } from 'date-fns';
const numeral = require('numeral');

export const CustomTooltip = (props) => {
  const { active, label, payload, transformLabel, percentage } = props;

  function compare(a, b) {
    if (parseFloat(a.value) > parseFloat(b.value)) {
      return -1;
    }
    if (parseFloat(a.value) < parseFloat(b.value)) {
      return 1;
    }
    return 0;
  }

  if (active) {
    const wrapperStyles = {
      background: 'rgba(39,42,49, 0.3)',
      padding: '12px 0',
      width: '300px',
    };

    const valueStyles = {
      color: 'white',
      fontSize: '13px',
      fontWeight: 'bold' as 'bold',
      margin: 0,
      textAlign: 'center' as any,
    };

    const labelStyles = {
      textAlign: 'center' as any,
    };

    const tooltips = payload.sort(compare).map((payloadItem, index) => {
      const lineStyles = {
        borderBottom: `1px solid ${payloadItem.color}`,
        display: 'inline-block',
        marginRight: '15px',
        width: '20px',
      };

      if (payloadItem.strokeDasharray) {
        lineStyles.borderBottom = `1px dashed ${payloadItem.color}`;
      }

      return (
        <div key={index} style={valueStyles} className="frow frow--items-center frow--gutters frow--nowrap">
          <div className="col-mc-1-5 col-md-1-8">
            <span style={lineStyles} />
          </div>
          <div className="col-mc-1-5 col-md-2-8">
            {payloadItem.value === 0 ? '0%' : numeral(payloadItem.value).format('0,0.00')}
            {percentage && '%'}
          </div>
          <div className={`col-mc-3-5 col-md-6-8 ${percentage && 'text-left'}`}>
            <span className="text-normal">{lodashStartcase(payloadItem.dataKey)}</span>
          </div>
        </div>
      );
    });

    return (
      <div style={wrapperStyles}>
        <p style={labelStyles} className="small-uppercase mar-t-0 text-white">
          {transformLabel ? format(parse(label), 'DD MMM YYYY') : label}
        </p>
        <div>{tooltips}</div>
      </div>
    );
  }

  return null;
};
