import * as React from 'react';

import { UploadTemplate } from 'javascript/models';

const Context = React.createContext({
  actions: {
    getTemplates: () => {},
    handleDestroyTemplate: (templateId) => {},
    handleNewTemplate: () => {},
  },
  props: {
    managerId: '',
    attributeOptions: [{ value: '', label: '', type: '' }],
    dataFormatOptions: [{ value: '', label: '' }],
  },
  state: {
    uploadTemplates: [],
  },
});

export default Context;
