// TODO(JLB): Create JS preview of mandate objectives table
import * as React from 'react';

import { Intent, Menu, MenuDivider, MenuItem, Popover, Position } from '@blueprintjs/core';

import AssetMovements from '../../../sectionTypes/dataTable/AssetMovements';
import Beta from '../../../sectionTypes/dataTable/Beta';
import Comparison from '../../../sectionTypes/dataTable/Comparison';
import ConsolidatedPortfolio from '../../../sectionTypes/dataTable/ConsolidatedPortfolio';
import ConstituentMandates from '../../../sectionTypes/dataTable/ConstituentMandates';
import ConstituentValues from '../../../sectionTypes/dataTable/ConstituentValues';
import ExcludeRatingConstituentTable from '../../../sectionTypes/dataTable/ExcludeRatingConstituentTable';
import GenericPortfolioTable from '../../../sectionTypes/dataTable/GenericPortfolioTable';
import MandatePositioning from '../../../sectionTypes/dataTable/MandatePositioning';
import SharpeRatio from '../../../sectionTypes/dataTable/SharpeRatio';
import ConsolidatedSharpeRatio from '../../../sectionTypes/dataTable/ConsolidatedSharpeRatio';
import ConsolidatedSortinoRatio from '../../../sectionTypes/dataTable/ConsolidatedSortinoRatio';
import CaptureRatioTable from '../../../sectionTypes/dataTable/CaptureRatioTable';

import { groupedColumnOptions } from './dataTable/columnOptions';
import { rowOptions } from './rowOptions';
import { ReportTemplatesReportTemplateSection } from 'javascript/models';
import Context from '../../../Context';
import LegendKeys from './shared/LegendKeys';

export default function dataTable({ attributes, handleChange, handleManualChange, isRepeating }) {
  const context = React.useContext(Context);
  const { reportTemplate } = context.state;

  const isConsolidated = reportTemplate.consolidated;

  const [columns, setColumns] = React.useState(attributes.tableColumns || ['']);
  const [selectedTimePeriod, setTimePeriod] = React.useState(attributes.tableTimePeriod);
  const [rows, setRows] = React.useState(attributes.tableRows || []);
  const [type, setType] = React.useState(attributes.tableType);
  const [comparison, setComparison] = React.useState(attributes.tableComparison);
  const [comparisonReturnOptions, setComparisonReturnOptions] = React.useState(
    attributes.tableComparisonReturnOptions || [],
  );
  const [series, setSeries] = React.useState(attributes.tableSeries || []);
  const [headerDisplay, setHeaderDisplay] = React.useState(attributes.tableHeaderDisplay);
  const [selectedKey, setSelectedKey] = React.useState(attributes.tableColumnNameFormat || 'enhance_reference');

  function handlePeriodChange() {
    setTimePeriod(this);
  }

  function updateAttributes() {
    const value = {
      columns,
      comparison,
      comparison_return_options: comparisonReturnOptions,
      header_display: headerDisplay,
      rows,
      series,
      table_column_name_format: selectedKey,
      time_period: selectedTimePeriod,
      type,
    };
    handleManualChange(value, 'tableDetails');
  }

  React.useEffect(() => {
    updateAttributes();
  }, [
    comparison,
    comparisonReturnOptions,
    rows,
    columns,
    type,
    selectedTimePeriod,
    headerDisplay,
    selectedKey,
    series,
  ]);

  function handleTypeChange() {
    setType(this);
  }
  function handleHeaderDisplayChange() {
    setHeaderDisplay(this);
  }
  function handleComparisonChange() {
    setComparison(this);
  }
  function handleKeyChange() {
    setSelectedKey(this);
  }
  function handleSeriesChange() {
    const updatedSeries = series.toggle(this);
    setSeries(updatedSeries);
  }

  function handleComparisonReturnOptionsChange() {
    const updatedReturnOptions = comparisonReturnOptions.toggle(this);
    setComparisonReturnOptions(updatedReturnOptions);
  }

  function addRow() {
    const newRows = [...rows];
    newRows.push('');
    setRows(newRows);
  }

  function addColumns() {
    const newColumns = [...columns];
    newColumns.push('');
    setColumns(newColumns);
  }

  function updateColumn(value, index) {
    const newColumns = [...columns];

    const valueSections: string[] = Array.from(value.match(/\/[^\/]+/g));
    const valueSectionsLength = valueSections.length;
    let newValue = valueSections[valueSectionsLength - 1].slice(1);

    if (valueSectionsLength > 2) newValue = `${valueSections[valueSectionsLength - 2].slice(1)} (${newValue})`;

    newColumns.splice(index, 1, newValue);
    setColumns(newColumns);
  }

  function removeColumn(index) {
    const newColumns = [...columns];
    newColumns.splice(index, 1);
    setColumns(newColumns);
  }

  function removeRow(index) {
    const newRows = [...rows];
    newRows.splice(index, 1);
    setRows(newRows);
  }

  const menuGenerator = (clickHandler: (nodePath, menuID) => void, menuID: number, path = '/') => (nodes) =>
    nodes.map((node) => {
      const nodePath = node.hasOwnProperty('name') ? `${path}/${node.name}` : `${path}/${node}`;
      let subOptions = null;

      if (node.hasOwnProperty('options')) subOptions = menuGenerator(clickHandler, menuID, nodePath)(node.options);

      const onClick = () => clickHandler(nodePath, menuID);

      if (!subOptions) return <MenuItem key={nodePath} text={node} onClick={onClick} />;

      return (
        <MenuItem key={nodePath} text={node.name}>
          {subOptions}
        </MenuItem>
      );
    });

  const columnItems = (index) => menuGenerator(updateColumn, index)(groupedColumnOptions);

  function updateRow(value, index) {
    const newRows = [...rows];
    newRows.splice(index, 1, value);
    setRows(newRows);
  }

  let scopedRowOptions = rowOptions;
  if (isConsolidated) {
    scopedRowOptions = ['Consolidated Portfolio', ...rowOptions.filter((option) => option !== 'Portfolio')];
  }

  if (isRepeating) {
    scopedRowOptions = ['Constituent Portfolio', ...rowOptions.filter((option) => option !== 'Portfolio')];
  }

  const rowItems = (index) =>
    scopedRowOptions.map((option) => {
      return <MenuItem key={option} text={option} onClick={() => updateRow(option, index)} />;
    });

  let scopedTableOptions = ReportTemplatesReportTemplateSection.tableOptions as any;
  if (isConsolidated) {
    scopedTableOptions = [
      ...ReportTemplatesReportTemplateSection.tableOptions,
      { value: 'constituent_portfolio_information', label: 'Constituent Information' },
      { value: 'exclude_rating_constituent_table', label: 'Constituent Information (w/o rating)' },
      { value: 'constituent_portfolio_mandates', label: 'Constituent Mandates' },
      { value: 'constituent_portfolio_values', label: 'Constituent Values' },
      { value: 'consolidated_portfolio', label: 'Consolidated Portfolio' },
      { value: 'constituent_portfolio_mandates_objectives', label: 'Constituent Objectives' },
    ];
  }

  const tableOptions = scopedTableOptions.map((option) => {
    return (
      <div className="col-tn-1-3" key={option.value}>
        <div className="pretty pretty--large pretty--with-label p-round">
          <input
            type="radio"
            value={option.value}
            id="series"
            onChange={handleTypeChange.bind(option.value)}
            checked={type === option.value}
          />
          <div className="state p-blue-circle">
            <label>{option.label}</label>
          </div>
        </div>
      </div>
    );
  });

  const tableColumns = columns.map((column, index) => {
    if (index === 0) {
      return <th style={{ width: '20%' }} key={`${column}-column-${index}`} />;
    }

    return (
      <th key={`${column}-column-${index}`} style={{ width: `${70 / (columns.length - 1)}%` }}>
        <Popover usePortal={false} autoFocus={false} position={Position.TOP}>
          <span>{column ? column : 'Select'}</span>
          <Menu>
            {columnItems(index)}
            <MenuDivider />
            <MenuItem text="Remove Column" intent={Intent.DANGER} onClick={() => removeColumn(index)} />
          </Menu>
        </Popover>
      </th>
    );
  });

  const tableRows = rows.map((row, index) => {
    const dataCells = columns.map((column, innerIndex) => {
      if (innerIndex === 0) {
        return (
          <td className="pdf-table__row-label" key={`${column}-column-row-${innerIndex}`}>
            <Popover usePortal={false} position={Position.RIGHT}>
              <span>{row ? row : 'Select'}</span>
              <Menu>
                {rowItems(index)}
                <MenuItem text="Remove Row" intent={Intent.DANGER} onClick={() => removeRow(index)} />
              </Menu>
            </Popover>
          </td>
        );
      }

      return (
        <td key={`${column}-column-row-${innerIndex}`}>
          <span className={Math.random() * 10 > 5 ? 'pdf-table__negative-value' : ''}>Value</span>
        </td>
      );
    });

    return (
      <tr key={`${row}-row-${index}`}>
        {dataCells}
        {/* Intentionally blank to match the extra column for adding /*/}
        <td />
      </tr>
    );
  });

  function renderTimePeriodOptions() {
    if (type === 'custom' || type === 'comparison' || type === 'mandate_positioning') return;

    let scopedOptions = ReportTemplatesReportTemplateSection.longTimePeriodOptions;
    if (['asset_movements', 'net_gross_movements'].includes(type)) {
      scopedOptions = scopedOptions.filter((option) => option.value !== 'inception');
    }

    const timePeriodOptions = scopedOptions.map((timePeriod) => {
      return (
        <div className="col-tn-1-3" key={timePeriod.value}>
          <div className="pretty pretty--large pretty--with-label p-round">
            <input
              type="radio"
              value={timePeriod.value}
              id="timePeriod"
              onChange={handlePeriodChange.bind(timePeriod.value)}
              checked={selectedTimePeriod === timePeriod.value}
            />
            <div className="state p-blue-circle">
              <label>{timePeriod.label}</label>
            </div>
          </div>
        </div>
      );
    });

    return (
      <div className="col-mc-1-1">
        <label className="form__label" htmlFor="">
          Time Period
        </label>
        <div className="frow ">{timePeriodOptions}</div>
      </div>
    );
  }

  function renderComparisonOptions() {
    if (type !== 'comparison' && type !== 'capture_ratio') return;

    const scopedOptions = ReportTemplatesReportTemplateSection.seriesOptions.filter((option) => option !== 'Portfolio');
    const comparisonOptions = scopedOptions.map((option) => {
      return (
        <div className="col-tn-1-3" key={option}>
          <div className="pretty pretty--large pretty--with-label p-round">
            <input
              type="radio"
              value={option}
              id="timePeriod"
              onChange={handleComparisonChange.bind(option)}
              checked={comparison === option}
            />
            <div className="state p-blue-circle">
              <label>{option}</label>
            </div>
          </div>
        </div>
      );
    });

    return (
      <div className="col-mc-1-1">
        <label className="form__label" htmlFor="">
          Comparison
        </label>
        <div className="frow ">{comparisonOptions}</div>
      </div>
    );
  }

  const seriesTables = ['sharpe', 'beta', 'alpha', 'sortino', 'correlation'];

  function renderSeriesOptions() {
    if (!seriesTables.includes(type)) return;

    let scopedOptions = ReportTemplatesReportTemplateSection.seriesOptions;
    if (type !== 'sharpe' && type !== 'sortino') {
      scopedOptions = scopedOptions.filter((option) => option !== 'Portfolio');
    }

    const seriesOptions = scopedOptions.map((option) => {
      return (
        <div className="col-tn-1-3" key={option}>
          <div className="pretty p-icon">
            <input
              type="checkbox"
              value={option}
              id="series"
              onChange={handleSeriesChange.bind(option)}
              checked={series.includes(option)}
            />
            <div className="state p-blue">
              <i className="icon-tick icon icon-push-down-small" />
              <label>{option}</label>
            </div>
          </div>
        </div>
      );
    });

    return (
      <div className="col-mc-1-1">
        <label className="form__label" htmlFor="">
          Series
        </label>
        <div className="frow ">{seriesOptions}</div>
      </div>
    );
  }

  function renderReturnOptions() {
    if (type !== 'comparison') return;

    const comparisonOptions = ReportTemplatesReportTemplateSection.barChartSubOptions.map((option) => {
      return (
        <div className="col-tn-1-3" key={option.value}>
          <div className="pretty pretty--large pretty--with-label p-round">
            <input
              type="checkbox"
              value={option.value}
              id="timePeriod"
              onChange={handleComparisonReturnOptionsChange.bind(option.value)}
              checked={comparisonReturnOptions.includes(option.value)}
            />
            <div className="state p-blue-circle">
              <label>{option.label}</label>
            </div>
          </div>
        </div>
      );
    });

    return (
      <div className="col-mc-1-1">
        <label className="form__label" htmlFor="">
          Comparison Return Options
        </label>
        <div className="frow ">{comparisonOptions}</div>
      </div>
    );
  }

  function customTableHeader() {
    if (headerDisplay === 'false') return;

    return (
      <thead>
        <tr>
          {tableColumns}
          <th onClick={addColumns} style={{ cursor: 'pointer', width: '10%' }}>
            Add Column
            <i className="icon-plus icon-fw icon-0-5x" style={{ marginLeft: '4px' }} />
          </th>
        </tr>
      </thead>
    );
  }

  const customTable = (
    <table className="pdf-table pdf-table--striped pdf-table--large">
      {customTableHeader()}
      <tbody>
        {tableRows}
        <tr onClick={addRow}>
          <td colSpan={columns.length + 1} style={{ cursor: 'pointer' }}>
            Add Row
            <i className="icon-plus icon-fw icon-0-5x" style={{ marginLeft: '4px' }} />
          </td>
        </tr>
      </tbody>
    </table>
  );

  const showHeaders = headerDisplay !== 'false';

  function renderTable() {
    switch (type) {
      case 'custom':
        return customTable;
      case 'comparison':
        return <Comparison large {...{ showHeaders }} returnOptions={comparisonReturnOptions} />;
      case 'consolidated_portfolio':
        return <ConsolidatedPortfolio large {...{ showHeaders }} />;
      case 'constituent_portfolio_information':
        return <ExcludeRatingConstituentTable large {...{ showHeaders }} />;
      case 'constituent_portfolio_values':
        return <ConstituentValues large {...{ showHeaders }} />;
      case 'constituent_portfolio_mandates':
        return <ConstituentMandates large {...{ showHeaders }} />;
      case 'asset_movements':
        return <AssetMovements large templateSection={attributes} {...{ showHeaders }} />;
      case 'alpha':
      case 'beta':
        if (isConsolidated) {
          return <Beta large {...{ showHeaders }} />;
        }
        return <GenericPortfolioTable large {...{ showHeaders }} templateSection={attributes} />;
      case 'correlation':
      case 'sortino':
        if (isConsolidated) {
          return <ConsolidatedSortinoRatio large {...{ showHeaders }} />;
        }
        return <GenericPortfolioTable large {...{ showHeaders }} templateSection={attributes} />;
      case 'sharpe':
        if (isConsolidated) {
          return <ConsolidatedSharpeRatio large {...{ showHeaders }} />;
        }
        return <SharpeRatio large {...{ showHeaders }} templateSection={attributes} />;
      case 'capture_ratio':
        return <CaptureRatioTable large {...{ showHeaders }} templateSection={attributes} />;
      case 'mandate_positioning':
        return <MandatePositioning large {...{ showHeaders }} />;
      default:
        return (
          <div className="placeholder mar-t-1" style={{ height: '100px' }}>
            Table Preview will display here
          </div>
        );
    }
  }

  function renderLegendKeys() {
    if (type !== 'constituent_portfolio_values') return;

    return <LegendKeys {...{ handleKeyChange, selectedKey }} labelName="Column Names" />;
  }

  const tableHeaderOptions = ['true', 'false'].map((option) => {
    return (
      <div className="col-tn-1-3" key={option}>
        <div className="pretty pretty--large pretty--with-label p-round">
          <input
            type="radio"
            value={option}
            id="series"
            onChange={handleHeaderDisplayChange.bind(option)}
            checked={headerDisplay === option}
          />
          <div className="state p-blue-circle">
            <label>{option === 'true' ? 'Yes' : 'No'}</label>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="frow frow--gutters">
      <div className="col-mc-1-1">
        <label className="form__label" htmlFor="">
          Table Type
        </label>
        <div className="frow">{tableOptions}</div>
      </div>
      {renderTimePeriodOptions()}
      {renderComparisonOptions()}
      {renderReturnOptions()}
      {renderSeriesOptions()}
      {renderLegendKeys()}
      <div className="col-mc-1-1">
        <label className="form__label" htmlFor="">
          Display Table Header
        </label>
        <div className="frow">{tableHeaderOptions}</div>
      </div>
      <div className="col-mc-1-1">{renderTable()}</div>
    </div>
  );
}
