import * as React from 'react';
import { DirectUpload } from 'activestorage';
import { Alert, Intent } from '@blueprintjs/core';

import Dropzone from 'components/shared/forms/Dropzone';
import FormRow from 'components/shared/forms/FormRow';
import Sheets from './Sheets';

import { UploadTemplate } from 'javascript/models';
import Loading from 'components/shared/Loading';
import { createAlert } from 'components/shared/Utils';
import Context from './Context';
import useVisibility from 'components/shared/customHooks/useVisibility';

interface IProps {
  uploadTemplate: UploadTemplate;
}

export default function template(props: IProps) {
  const context = React.useContext(Context);
  const { handleDestroyTemplate, getTemplates } = context.actions;
  const [isParsingUpload, setIsParsingUpload] = React.useState(false);
  const [uploadTemplate, setUploadTemplate] = React.useState(props.uploadTemplate);

  const { isOpen, handleClose, handleOpen } = useVisibility(false);

  React.useEffect(() => {
    setUploadTemplate(props.uploadTemplate);
  }, [props.uploadTemplate]);

  function handleFileUpload(files) {
    Array.from(files).forEach((file) => uploadFile(file));
  }

  function uploadFile(file) {
    const upload = new DirectUpload(file, '/rails/active_storage/direct_uploads');

    upload.create((error, blob) => {
      if (error) {
        createAlert('error', 'Something went wrong while uploading the file. Check your internet connection', 5000);
      } else {
        setIsParsingUpload(true);
        uploadTemplate.exampleFile = blob.signed_id;
        uploadTemplate.exampleFileChanged = true;
        updateTemplate().catch((response) => {
          createAlert('error', 'Something went wrong while parsing the file. Please contact support', 5000);
          setIsParsingUpload(false);
        });
      }
    });
  }

  function handleChange(event) {
    const id = event.target.id;
    if (id === 'all_sheets_same') {
      uploadTemplate.allSheetsSame = !uploadTemplate.allSheetsSame;
    }
    if (id === 'file_name') {
      uploadTemplate.name = event.target.value;
    }
    const updatedTemplate = uploadTemplate.dup();
    setUploadTemplate(updatedTemplate);
    updateTemplate();
  }

  async function updateTemplate() {
    const success = await uploadTemplate.save();

    if (success) {
      getTemplates();
    } else {
      createAlert('error', 'Something went wrong while uploading the file. Please contact support', 5000);
    }

    setIsParsingUpload(false);
  }

  if (isParsingUpload) {
    return <Loading flexDirection="column" text="Processing Uploaded File" />;
  }

  function confirmDelete() {
    handleClose();
    handleDestroyTemplate(uploadTemplate.id);
  }

  const fileLink = (
    <div className="mar-t-1 ">
      <a className="link link--right-arrow mar-t-2" target="_blank" href={uploadTemplate.exampleFile}>
        View Example File
      </a>
    </div>
  );

  return (
    <div
      className="platform-content platform-content--padding-bottom platform-content--border-bottom
        platform-content--spacing-bottom"
    >
      <div className="platform-content platform-content--padding-bottom platform-content--border-dashed-bottom">
        <div className="frow frow--gutters-2x">
          <FormRow note="Upload a file to use as an example">
            <label htmlFor="" className="form__label">
              Example File
            </label>
            <Dropzone
              acceptedFiles=".csv, .xls, .xml, .xlsx, .xlsm"
              multiple={false}
              onFileUpload={handleFileUpload}
              theme="dark"
            />
            <div>{uploadTemplate.exampleFile && fileLink}</div>
          </FormRow>
          <FormRow
            className="col-mc-1-1"
            note="If all the sheets are the same format and should be treated the same tick this box and then only
            complete the template for one sheet"
          >
            <label htmlFor="" className="form__label">
              Template Info
            </label>
            <div className="pretty p-icon">
              <input
                checked={uploadTemplate.allSheetsSame}
                type="checkbox"
                onChange={handleChange}
                id="all_sheets_same"
              />
              <div className="state p-blue">
                <i className="icon-tick icon text-white icon-push-down-small" />
                <label className="text-white">All Sheets same</label>
              </div>
            </div>
          </FormRow>
          <FormRow
            className="col-mc-1-1"
            note="This should identify the file if there is more than one file uploaded as part of this
                template. We use regular expression matching on the value of the filename so don't include any values
                that will change from one upload to the next (i.e. don't include dates). This will be automatically
                updated by the example file."
          >
            <label htmlFor="file_name" className="form__label">
              File name
            </label>
            <input
              type="text"
              defaultValue={uploadTemplate.name}
              onBlur={handleChange}
              id="file_name"
              name="file_name"
            />
          </FormRow>
        </div>
      </div>
      <Sheets {...{ uploadTemplate }} />
      <div className="mar-v-2">
        <button className="button button--danger" onClick={handleOpen}>
          Delete Template
        </button>
        <Alert
          isOpen={isOpen}
          onClose={handleClose}
          canEscapeKeyCancel
          canOutsideClickCancel
          cancelButtonText="Cancel"
          confirmButtonText="Delete"
          intent={Intent.DANGER}
          onCancel={handleClose}
          onConfirm={confirmDelete}
          portalContainer={document.body}
        >
          <p className="mar-t-0">
            Are you sure you want to delete this template? All columns and conditions will also be deleted.
          </p>
        </Alert>
      </div>
    </div>
  );
}
