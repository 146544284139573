import * as React from 'react';
import * as moment from 'moment-timezone';

import Clock from 'react-clock';
import { format } from 'date-fns';
import { HeadingTwo, HeadingThree, SubtitleOne } from 'components/shared/Typography';

export default function financialClock({ clock, color }) {
  function getTimeInZone() {
    const timeInZone = moment.utc(new Date()).tz(clock.timeZone);
    const year = parseInt(timeInZone.format('YYYY'), 10);
    const month = parseInt(timeInZone.format('MM'), 10);
    const day = parseInt(timeInZone.format('DD'), 10);
    const hour = parseInt(timeInZone.format('HH'), 10);
    const minute = parseInt(timeInZone.format('mm'), 10);
    const second = parseInt(timeInZone.format('ss'), 10);

    return new Date(year, month, day, hour, minute, second);
  }

  const [value, setValue] = React.useState(getTimeInZone());

  React.useEffect(() => {
    const interval = setInterval(() => setValue(getTimeInZone()), 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="platform-content platform-content--padding-all">
      <div className="frow frow--centered-column">
        <div className="mar-b-3">
          <Clock
            className={['react-clock--financial-clock', `react-clock--${color}`]}
            hourHandLength={40}
            hourHandOppositeLength={5}
            hourHandWidth={3}
            hourMarksLength={8}
            hourMarksWidth={2}
            minuteHandLength={70}
            minuteHandOppositeLength={5}
            minuteHandWidth={3}
            size={164}
            value={value}
            renderMinuteMarks={false}
            renderSecondHand={false}
          />
        </div>
        <HeadingTwo
          text={format(value, 'hh:mm A')}
          additionalClasses="zeplin__app_04_h2 zeplin__app_04_h2--white zeplin__app_04_h2--left mar-b-1"
        />
        <HeadingTwo
          text={clock.city}
          additionalClasses="zeplin__app_04_h2 zeplin__app_04_h2--white zeplin__app_04_h2--left mar-b-1"
          style={{ fontSize: 24, lineHeight: '28px' }}
        />
        <SubtitleOne
          text={clock.country}
          additionalClasses="zeplin__app_07_subtitle_1 zeplin__app_07_subtitle_1--grey-40 zeplin__app_07_subtitle_1--left"
        />
      </div>
    </div>
  );
}
