import { Attr, Model } from 'spraypaint';
import { ApplicationRecord } from './ApplicationRecord';

@Model()
export class BulkImport extends ApplicationRecord {
  public static jsonapiType = 'bulk_imports';
  @Attr() public clientId: string;
  @Attr() public import: string;
  @Attr() public processed: boolean;
  @Attr() public importType: string;
  @Attr() public status: string;
  @Attr() public importParams: any;
  @Attr({ persist: false }) public preview: any;
}
