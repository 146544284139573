import * as React from 'react';
import Context from '../Context';

export default function subHeading({ templateSection }) {
  const context = React.useContext(Context);
  const { reportTemplate } = context.state;

  const text = templateSection && templateSection.text ? templateSection.text : 'Subheading';

  let color = '#3990a8';
  if (reportTemplate && reportTemplate.reportTemplateTheme) {
    color = reportTemplate.reportTemplateTheme.primaryColor;
  }

  return (
    <p className="pdf-subheading" style={{ color }}>
      {text}
    </p>
  );
}
