import * as React from 'react';

import { keys, pipe, reduce } from 'ramda';
import { TColumnContext, ColumnContext } from './ColumnContext';

type TCommonAlignment = 'start' | 'end' | 'center';

export type TFrowContentAlignment = TCommonAlignment | 'between' | 'around' | 'stretch';
export type TFrowItemAlignment = TCommonAlignment | 'baseline';
export type TFrowJustifyContent = TCommonAlignment | 'between' | 'around' | 'sm-center';
export type TFrowWrap = true | false | 'reverse';
export type TFrowDirection = 'row' | 'column' | 'row-reverse' | 'column-reverse';
export type TFrowGutterSize = 'half' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;

export interface IFrowProps<TagType> extends React.DetailedHTMLProps<React.HTMLAttributes<TagType>, TagType> {
  additionalClassNames?: string;
  children: React.ReactNode;
  contentAlignment?: TFrowContentAlignment;
  direction?: TFrowDirection;
  fullWidth?: boolean;
  gutterSize?: TFrowGutterSize;
  itemAlignment?: TFrowItemAlignment;
  justifyContent?: TFrowJustifyContent;
  style?: React.CSSProperties;
  tag?: string;
  wrap?: TFrowWrap;
  columnContext?: TColumnContext;
}

interface IFrowTag extends Element, HTMLOrSVGElement {}

const Frow = <TagType extends IFrowTag>(props: IFrowProps<TagType>) => {
  const options = {
    fullWidth: false,
    ...props,
  };

  const {
    additionalClassNames,
    contentAlignment,
    direction,
    gutterSize,
    itemAlignment,
    justifyContent,
    tag,
    wrap,
  } = options;

  const tagProps = { ...props };

  delete tagProps.children;

  const { columnContext } = props;

  const currentColumnContext = React.useContext(ColumnContext);
  const completeColumnContext = { ...currentColumnContext, ...columnContext };

  delete tagProps.columnContext;

  const classNames = {
    additionalClassNames,
    contentAlignment: `frow--content-${contentAlignment}`,
    direction: `frow--direction-${direction}`,
    fullWidth: 'frow--full-width',
    gutterSize: 'frow--gutters',
    itemAlignment: `frow--items-${itemAlignment}`,
    justifyContent: `frow--justify-${justifyContent}`,
    wrap: `frow--wrap-${wrap}`,
  };

  Object.getOwnPropertyNames(classNames).forEach((className) => delete tagProps[className]);

  if (typeof gutterSize === 'number') {
    if (gutterSize > 1) {
      classNames.gutterSize += `-${gutterSize}x`;
    }
  } else {
    classNames.gutterSize += `-${gutterSize}`;
  }

  const aggregateClasses = (className: string, property: keyof typeof classNames) =>
    (options[property] && `${className} ${classNames[property]}`) || className;

  const newClassName = pipe(keys, reduce(aggregateClasses, 'frow'))(classNames);

  tagProps.className = `${newClassName} ${tagProps.className || ''}`;

  const CustomTag = tag || 'div';

  return (
    <ColumnContext.Provider value={completeColumnContext}>
      <CustomTag {...tagProps}>{props.children}</CustomTag>
    </ColumnContext.Provider>
  );
};

export { Frow };
export default Frow;
