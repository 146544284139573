import * as React from 'react';

import { reject } from 'ramda';

interface IContentsProps {
  color: string;
  isLandscape: boolean;
}

const Contents = ({ color, isLandscape }: IContentsProps) => {
  const hasClass = (className: string) => (element: HTMLElement) => element.className.split(' ').includes(className);
  const elementsByClass = (className: string, base: HTMLElement | Document = document) =>
    Array.from(base.getElementsByClassName(className)) as HTMLElement[];

  const pageWrappers: HTMLElement[] = reject(hasClass('page-wrapper--exclude-contents'), [
    ...elementsByClass('page-wrapper'),
    ...elementsByClass('page-wrapper--landscape'),
  ]);

  let pageOffset = isLandscape ? 2 : 1;

  const extractContentsEntries = (pageWrapper: HTMLElement, index: number) => {
    const contentsEntryElements = elementsByClass('contents-entry', pageWrapper);

    return contentsEntryElements.map((contentsEntry) => {
      const data = contentsEntry.dataset;
      const contentsEntryText = data.contentsEntryText;
      const pseudoPageCount = +data.pseudoPageCount;
      const pageNumber = pageOffset + index;

      if (pseudoPageCount) pageOffset += pseudoPageCount - 1;
      if (contentsEntryText) return [pageNumber, contentsEntryText];
    });
  };

  const contentsEntries = pageWrappers
    .map(extractContentsEntries)
    .filter((contentsEntry) => contentsEntry.length)
    .flat();

  if (!contentsEntries) return null;

  const contentsEntryList = contentsEntries.map(([pageNumber, text], index) => (
    <li key={index} className="pdf-list__item">
      <span className="pdf-list__number" style={{ color }}>
        {index + 1}
      </span>{' '}
      {text}
      <span className="pdf-list__page-number">{pageNumber}</span>
    </li>
  ));

  return <ul className="pdf-list pdf-list--content pdf-list--content-print">{contentsEntryList}</ul>;
};

export default Contents;
