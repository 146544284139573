import * as React from 'react';

export interface INewLinkProps {
  newPath: string;
  newText: React.ReactNode;
}

const newLink = (props: INewLinkProps) => {
  const { newPath, newText } = props;

  if (!newPath) return <></>;

  return (
    <a href={newPath} className="button button--secondary">
      {newText} <i className="fas fa-plus mar-l-1" />
    </a>
  );
};

export default newLink;
