import * as React from 'react';

import { Review } from 'javascript/models';
import { Button, Card, Elevation } from '@blueprintjs/core';
import InvestmentRestrictionsTable from 'components/shared/investmentRestrictionsTable/InvestmentRestrictionsTable';

const table = ({ reviewId, reference, endDate }) => {
  const [review, setReview] = React.useState<Review>();
  const [loading, setLoading] = React.useState(true);

  const getData = async () => {
    const { data } = await Review.selectExtra(['investment_restrictions', 'investment_restrictions_commentary']).find(
      reviewId,
    );

    setReview(data);
    setLoading(false);
  };

  const investmentRestrictionsCommentary = () => {
    if (loading) {
      return;
    }

    return (
      <Card interactive elevation={Elevation.TWO} className="card--dark card--rounded text--white">
        <h5 style={{ color: '#ffffff' }}>AI Generated Summary For Investment Restrictions</h5>
        <p style={{ color: '#e1d1d1' }}>{review.investmentRestrictionsCommentary}</p>
        <Button>Rate Generation</Button>
      </Card>
    );
  };

  React.useEffect(() => {
    getData();
  }, []);

  const title = `Checklist Results For: ${reference} - ${endDate}`;

  return (
    <>
      <h2 className="platform-panel__title mar-r-3">{title}</h2>
      <InvestmentRestrictionsTable reviewId={reviewId} />

      {investmentRestrictionsCommentary()}
    </>
  );
};

export default table;
