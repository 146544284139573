import * as React from 'react';

import EntityLevels from './EntityLevels';
import Input from 'components/shared/editModel/Input';

interface IProps {
  entityGrouping: any;
  entityId: number;
  isLoading: boolean;
  structure: any;
  updateGrouping(event): void;
}

export default function presenter(props: IProps) {
  const { entityGrouping, entityId, isLoading, structure, updateGrouping } = props;

  const attribute = {
    defaultValue: entityGrouping.name,
    icon: '',
    id: 'entity_grouping',
    isUpdateable: true,
    label: 'Structure Name',
    name: 'entity_grouping',
  };

  return (
    <section className="platform-panel">
      <header className="platform-panel__header">
        <div className="platform-content platform-content--border-bottom pad-b-2">
          <div className="frow frow--justify-between frow--items-center">
            <div className="frow frow--items-center">
              <h2 className="platform-panel__title mar-r-3">Structure</h2>
            </div>
          </div>
        </div>
        <form className="form mar-t-2">
          <div className="frow frow--gutters">
            <Input attribute={attribute} updateModel={updateGrouping} />
          </div>
        </form>
      </header>
      <div className="platform-content platform-content--padding-horizontal">
        <EntityLevels {...{ entityId, isLoading, structure }} />
      </div>
    </section>
  );
}
