import * as React from 'react';

import { compose, prop } from 'ramda';

import FormRow from 'components/shared/forms/FormRow';
import ReactSelect from 'components/shared/forms/ReactSelect';

import Loading from 'components/shared/Loading';
import { Frow } from 'components/frow';

import Context from '../Context';

const Client = () => {
  const context = React.useContext(Context);

  const { handleClientSelect } = context.actions;
  const { client, clients, portfolio } = context.state;
  const { clientId, slaDays } = portfolio;

  const formattedClients = clients.map((client) => ({ value: client.id, label: client.name }));
  const defaultValue = formattedClients.find((client) => client.value === clientId);

  const handleSelect = compose(handleClientSelect, prop('value'));

  const clientSelect = (
    <ReactSelect
      theme="dark"
      name="portfolio[client_id]"
      handleChange={handleSelect}
      id="client"
      options={formattedClients}
      defaultValue={defaultValue}
    />
  );

  return (
    <fieldset className="form__fieldset">
      <h4 className="form__fieldset-label">Client Information</h4>
      <Frow gutterSize={1}>
        <FormRow>
          <label htmlFor="client_id" className="form__label">
            Client
          </label>
          {clients.length > 0 ? clientSelect : <Loading />}
        </FormRow>
        <FormRow>
          <label htmlFor="client_sla" className="form__label">
            Sla (days after data is received)
          </label>
          <input id="client_sla" type="number" name="portfolio[sla_days]" defaultValue={slaDays} />
        </FormRow>
      </Frow>
    </fieldset>
  );
};

export default Client;
