import * as React from 'react';

export interface IUseVisibility {
  handleClose: () => void;
  handleOpen: () => void;
  isOpen: boolean;
  setIsOpen: ReturnType<typeof React.useState>[1];
  toggleOpen: () => void;
}

const useVisibility = (initialState: boolean): IUseVisibility => {
  const [isOpen, setIsOpen] = React.useState(initialState);

  const handleOpen = React.useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleClose = React.useCallback(() => {
    setIsOpen(false);
  }, []);

  const toggleOpen = React.useCallback(() => {
    setIsOpen((prevState) => !prevState);
  }, []);

  return {
    handleClose,
    handleOpen,
    isOpen,
    setIsOpen,
    toggleOpen,
  };
};

export default useVisibility;
