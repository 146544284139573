import * as React from 'react';
/* eslint-disable  */

import { equals, empty } from 'ramda';

import AssetMovements from './dataTable/AssetMovements';
import Beta from './dataTable/Beta';
import ConsolidatedPortfolio from './dataTable/ConsolidatedPortfolio';
import ConstituentMandates from './dataTable/ConstituentMandates';
import ConsolidatedSharpeRatio from './dataTable/ConsolidatedSharpeRatio';
import ConsolidatedSortinoRatio from './dataTable/ConsolidatedSortinoRatio';
import ConstituentTable from './dataTable/ConstituentTable';
import ConstituentValues from './dataTable/ConstituentValues';
import GenericPortfolioTable from './dataTable/GenericPortfolioTable';
import MandatePositioning from './dataTable/MandatePositioning';
import SharpeRatio from './dataTable/SharpeRatio';
import SortinoRatio from './dataTable/SortinoRatio';
import CaptureRatioTable from './dataTable/CaptureRatioTable';
import Context from '../Context';
import Comparison from './dataTable/Comparison';

const exampleTables = {
  private_assets: {
    columns: [
      'category',
      'sub-category',
      'description',
      'latest valuation date',
      'latest valuation',
      'latest valuation (base CCY)',
      '% Total',
    ],
    rows: ['Private Ownership', 'Real Estate', 'Collectibles', 'Intellectual Property', 'Digital Assets'],
  },
  traditional_vs_private_summary: {
    columns: ['category', 'latest valuation (base CCY)', '% Total'],
    rows: ['Traditional Assets', 'Private Assets'],
  },
};

export default function dataTable({ templateSection }) {
  const context = React.useContext(Context);
  const { reportTemplate } = context.state;
  const isConsolidated = reportTemplate && reportTemplate.consolidated;
  const showHeaders = !(templateSection && templateSection.tableHeaderDisplay === 'false');

  let columns = ['', 'LABEL', 'LABEL', 'LABEL', 'LABEL', 'LABEL', 'LABEL'];

  let rows = ['Row Label', 'Row Label', 'Row Label', 'Row Label', 'Row Label'];

  if (templateSection) {
    const templateExample = exampleTables[templateSection.tableType];

    const hasOwnColumns = !equals(templateSection.tableColumns, ['']);
    const hasOwnRows = !empty(templateSection.tableRows);

    columns =
      (templateExample && templateExample.columns) || (hasOwnColumns && templateSection.tableColumns) || columns;
    rows = (templateExample && templateExample.rows) || (hasOwnRows && templateSection.tableRows) || rows;
  }

  const tableHeaders = columns.map((column, index) => (
    <th className="text-right" style={{ verticalAlign: 'top' }} key={`${column}-${index}`}>
      {column}
    </th>
  ));

  const tableRows = rows.map((row, index) => {
    const tableData = columns.map((column, innerIndex) => {
      if (innerIndex === 0) {
        return (
          <td key={`${row}-table-data-${innerIndex}`} style={{ width: '34%' }}>
            <span className="pdf-table__row-label">{row}</span>
          </td>
        );
      }

      return (
        <td
          key={`${row}-table-data-${innerIndex}`}
          style={{ width: `${66 / (columns.length - 1)}%` }}
          className="text-right"
        >
          <span className={Math.random() * 10 > 5 ? 'pdf-table__negative-value' : ''}>Value</span>
        </td>
      );
    });

    return <tr key={`${row}-table-row-${index}`}>{tableData}</tr>;
  });

  const defaultTable = (
    <table className="pdf-table pdf-table--striped">
      <thead>
        <tr>{tableHeaders}</tr>
      </thead>
      <tbody>{tableRows}</tbody>
    </table>
  );

  let returnOptions = ['qtd', '1_year', '3_years', '5_years'];
  if (templateSection && templateSection.tableComparisonReturnOptions) {
    returnOptions = templateSection.tableComparisonReturnOptions;
  }

  switch (templateSection && templateSection.tableType) {
    case 'comparison':
      return <Comparison large={false} {...{ returnOptions, showHeaders }} />;
    case 'consolidated_portfolio':
      return <ConsolidatedPortfolio large={false} {...{ showHeaders }} />;
    case 'constituent_portfolio_values':
      return <ConstituentValues large={false} {...{ showHeaders }} />;
    case 'constituent_portfolio_information':
      return <ConstituentTable large={false} {...{ showHeaders }} />;
    case 'constituent_portfolio_mandates':
      return <ConstituentMandates large={false} {...{ showHeaders }} />;
    case 'asset_movements':
      return <AssetMovements large={false} {...{ templateSection, showHeaders }} />;
    case 'alpha':
    case 'beta':
      if (isConsolidated) {
        return <Beta large={false} {...{ showHeaders }} />;
      }
      return <GenericPortfolioTable large={false} {...{ showHeaders, templateSection }} />;
    case 'correlation':
      return <GenericPortfolioTable large={false} {...{ showHeaders, templateSection }} />;
    case 'sharpe':
      if (isConsolidated) {
        return <ConsolidatedSharpeRatio {...{ showHeaders }} />;
      }
      return <SharpeRatio large={false} {...{ showHeaders, templateSection }} />;
    case 'sortino':
      if (isConsolidated) {
        return <ConsolidatedSortinoRatio {...{ showHeaders }} />;
      }
      return <SortinoRatio large={false} {...{ showHeaders, templateSection }} />;
    case 'capture_ratio':
      return <CaptureRatioTable large={false} {...{ showHeaders, templateSection }} />;
    case 'mandate_positioning':
      return <MandatePositioning large={false} {...{ showHeaders }} />;
    default:
      return defaultTable;
  }
}
