import { Attr, Model, HasMany } from 'spraypaint';
import { ApplicationRecord } from './ApplicationRecord';
import { Note } from './Models';

@Model()
export class Manager extends ApplicationRecord {
  public static jsonapiType = 'managers';
  @Attr() public activeUploadTemplate: boolean;
  @Attr() public lastDataUploadedOn: string;
  @Attr() public managerUserCount: number;
  @Attr() public name: string;
  @Attr() public portfolioCount: number;
  @Attr() public showPath: string;
  @Attr() public uploadTemplatesPath: string;
  @Attr() public uploadTemplatesShowPath: string;

  @HasMany() public notes: Note[];

  get fullName() {
    return this.name;
  }
}
