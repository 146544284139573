import React from 'react';

import { Frow, Column } from 'components/frow';

import { ActionPointContext } from '../../Show';

import InReviewActions from './inReview/InReviewActions';
import InProgressActions from './inProgress/InProgressActions';
import Assigner from './ActionPointAssigner';

const Actions = () => {
  const { actionPoint, client } = React.useContext(ActionPointContext);
  if (!client.managesActionPointsInPortal) return null;

  const releasedMessage = (
    <h4 className="text-white text-weight-500">This action point has been released and is now with Enhance.</h4>
  );
  const actionsContent = {
    inProgress: <InProgressActions />,
    inReview: <InReviewActions />,
    released: releasedMessage,
  }[actionPoint.status];

  return (
    <Frow justifyContent="between" itemAlignment="center">
      {actionsContent}
      <Assigner />
    </Frow>
  );
};

export default Actions;
