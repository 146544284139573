import React from 'react';

import { User } from 'javascript/models';

import Table from 'components/shared/reactTable/Table';
import { Column, Frow } from 'components/frow';

import columns from './Columns';

interface IRoleSelection {
  user: User;
}

const RoleSelection = ({ user }: IRoleSelection) => (
  <Frow justifyContent="between" itemAlignment="start" direction="column">
    <Column>
      <Frow justifyContent="between" itemAlignment="start">
        <h4 className="form__fieldset-label">Roles</h4>
      </Frow>
    </Column>
    <Column>
      <Table
        columns={columns({ user })}
        data={user.clients}
        hasColumnSelector={false}
        hasPagination={false}
        manual={false}
      />
    </Column>
  </Frow>
);

export default RoleSelection;
