import * as React from 'react';

import { format, parse } from 'date-fns';

import { portalClientPortfolioCurrencyAllocationsPath } from 'javascript/application/ts_routes';
import ReactStackedAreaChart from '../../../../shared/charting/recharts/ReactStackedAreaChart';
import Loading from '../../../../shared/Loading';
import ReactPieChart from '../../../../shared/charting/recharts/ReactPieChart';
import PieChartTableLegend from '../../show/PieChartTableLegend';
import ClientPortalCard from '../../../shared/ClientPortalCard';

import useAjaxRequest from '../../../../shared/customHooks/useAjaxRequest';
import { useClientPortalTheme } from '../../../../shared/colours';

interface ICurrencyAllocation {
  currentReviewPeriod: string;
  portfolioObject: {
    client_id: number;
    id: number;
  };
}

const CurrencyAllocation = ({ currentReviewPeriod, portfolioObject }: ICurrencyAllocation) => {
  const { client_id, id } = portfolioObject;
  const [currencyData, setCurrencyData] = React.useState();
  const [activeDate, setActiveDate] = React.useState<string>(format(parse(currentReviewPeriod), 'MMM YY'));

  React.useEffect(() => {
    getCurrencyData({ requestParams: null });
  }, []);

  React.useEffect(() => {
    setActiveDate(format(parse(currentReviewPeriod), 'MMM YY'));
  }, [currentReviewPeriod]);

  const handleActiveLabelChange = React.useCallback((activeLabel: string) => setActiveDate(activeLabel), []);

  const path = portalClientPortfolioCurrencyAllocationsPath(client_id, id);
  const [getCurrencyData, , isLoading] = useAjaxRequest<{ requestParams: null }, any>({
    method: 'GET',
    path,
    stateSetter: setCurrencyData,
  });

  const getColour = useClientPortalTheme('currency');

  const RenderCharts = () => {
    const colourMap = {};
    const pieData = currencyData.data.find((data) => data.name === activeDate);

    if (!pieData) {
      return <p className="text-white helper-text mar-t-3">No data available currently.</p>;
    }

    const formattedPieData = pieData.series.map((dataItem) => {
      const colour = getColour(dataItem.name);
      colourMap[dataItem.name] = colour;
      dataItem.color = colour;
      dataItem.value = parseFloat(dataItem.value);
      return dataItem;
    });

    return (
      <div className="frow frow-gutters">
        <div className="col-md-1-2">
          <ReactStackedAreaChart
            data={currencyData.data}
            height={300}
            showYAxis={false}
            showXAxis={false}
            showTooltip
            handleActiveLabelChange={handleActiveLabelChange}
            colourMap={colourMap}
          />
          <p className="helper-text text-white mar-t-2">
            Click a time period on the area chart to see it reflected in the pie chart
          </p>
        </div>
        <div className="col-md-1-2">
          <div className="frow frow--gutters-2x">
            <div className="col-md-1-2">
              <ReactPieChart
                animationDuration={500}
                animationBegin={200}
                activeShape
                cursor="default"
                data={formattedPieData}
                height={300}
                labelText={activeDate}
                labelSize="3rem"
                noData={formattedPieData.length < 1}
                displayLegend={false}
              />
            </div>
            <PieChartTableLegend headerTitle="Currency" data={formattedPieData} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="col-md-1-1">
      <ClientPortalCard title="Currency Allocation">{isLoading ? <Loading /> : RenderCharts()}</ClientPortalCard>
    </div>
  );
};

export default CurrencyAllocation;
