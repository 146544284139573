import * as React from 'react';

export default function mandate() {
  return (
    <div>
      <ul className="pdf-list pdf-list--content">
        <li className="pdf-list__item">
          <span className="text-uppercase pdf-list__item-key" style={{ display: 'inline-block', width: '40%' }}>
            Entity
          </span>
          <span className="pdf-list__item-value">Value</span>
        </li>
        <li className="pdf-list__item">
          <span className="text-uppercase pdf-list__item-key" style={{ display: 'inline-block', width: '40%' }}>
            Manager
          </span>
          <span className="pdf-list__item-value">Value</span>
        </li>
        <li className="pdf-list__item">
          <span className="text-uppercase pdf-list__item-key" style={{ display: 'inline-block', width: '40%' }}>
            Investment Objective
          </span>
          <span className="pdf-list__item-value">Value</span>
        </li>
        <li className="pdf-list__item">
          <span className="text-uppercase pdf-list__item-key" style={{ display: 'inline-block', width: '40%' }}>
            Return Objective
          </span>
          <span className="pdf-list__item-value">Value</span>
        </li>
        <li className="pdf-list__item">
          <span className="text-uppercase pdf-list__item-key" style={{ display: 'inline-block', width: '40%' }}>
            Reference Currency
          </span>
          <span className="pdf-list__item-value">Value</span>
        </li>
        <li className="pdf-list__item">
          <span className="text-uppercase pdf-list__item-key" style={{ display: 'inline-block', width: '40%' }}>
            Time Horizon
          </span>
          <span className="pdf-list__item-value">Value</span>
        </li>
        <li className="pdf-list__item">
          <span className="text-uppercase pdf-list__item-key" style={{ display: 'inline-block', width: '40%' }}>
            Risk Objective
          </span>
          <span className="pdf-list__item-value">Value</span>
        </li>
        <li className="pdf-list__item">
          <span className="text-uppercase pdf-list__item-key" style={{ display: 'inline-block', width: '40%' }}>
            Income Requirement
          </span>
          <span className="pdf-list__item-value">Value</span>
        </li>
        <li className="pdf-list__item">
          <span className="text-uppercase pdf-list__item-key" style={{ display: 'inline-block', width: '40%' }}>
            Restrictions
          </span>
          <span className="pdf-list__item-value">Value</span>
        </li>
      </ul>
    </div>
  );
}
