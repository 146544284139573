import * as React from 'react';
import { ReportTemplatesReportTemplateSection } from 'javascript/models';

export default function rating({ attributes, handleChange, handleManualChange, isRepeating }) {
  const [selectedSize, setSelectedSize] = React.useState(attributes.size);

  function handleSizeChange() {
    setSelectedSize(this);
    handleManualChange(this, 'size');
  }

  const scopedSizeOptions = [
    ...ReportTemplatesReportTemplateSection.sizeOptions.filter((option) => option.value !== 'medium'),
  ];

  const sizeOptions = scopedSizeOptions.map((size) => {
    return (
      <div className="col-tn-1-3" key={size.value}>
        <div className="pretty pretty--large pretty--with-label p-round">
          <input
            type="radio"
            value={size.value}
            id="size"
            onChange={handleSizeChange.bind(size.value)}
            checked={selectedSize === size.value}
          />
          <div className="state p-blue-circle">
            <label>{size.label}</label>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="frow frow--gutters">
      <div className="col-mc-1-1">
        <label className="form__label" htmlFor="">
          Size
        </label>
        <div className="frow ">{sizeOptions}</div>
      </div>
    </div>
  );
}
