import * as React from 'react';

import lastDateInQuarter from '../../../shared/lastDateInQuarter';

const routes = require('../../../../javascript/application/routes.js');
const numeral = require('numeral');

import { IFilterOptions } from '../shared/IFilterOptions';
import IPieChartData from '../../../shared/charting/recharts/interfaces/IPieChartData';

interface IProps {
  admin: number | string;
  client_type: number | string;
  companyId: number;
  currencySymbol?: string;
  dataObject: IPieChartData;
  filterOptions?: IFilterOptions;
  isCurrency?: boolean;
  isLoading: boolean;
  totalCount: number;
  quarter: number;
  year: number;
}

export default function infoBox(props: IProps) {
  const {
    admin,
    client_type,
    currencySymbol,
    dataObject,
    filterOptions,
    isCurrency,
    isLoading,
    totalCount,
    quarter,
    year,
  } = props;

  if (isLoading) {
    return (
      <div className="info-box">
        <p className="info-box__title status-indicator bp3-skeleton">Placeholder</p>
        <div className="info-box__content">
          <p className="info-box__main-text bp3-skeleton">Value</p>
          <p className="info-box__sub-text bp3-skeleton mar-t-1">%</p>
        </div>
        <div className="info-box__footer">
          <a className="button button--secondary button--compact bp3-skeleton">
            Button <i className="icon-arrow-right-top icon-fw icon-0-8x" />
          </a>
        </div>
      </div>
    );
  }

  const percentage = Math.round((dataObject.value / totalCount) * 100 * 100) / 100;
  const route = routes.platform_reviews_path({
    filters: {
      current_owner_id: [admin],
      end_date: [lastDateInQuarter(quarter, year)],
      client_type: [client_type],
      status: dataObject.statuses,
      ...filterOptions,
    },
  });

  return (
    <div className="info-box">
      <p className={`info-box__title status-indicator status-indicator--${dataObject.color_name}`}>{dataObject.name}</p>
      <div className="info-box__content">
        <p className={`info-box__main-text ${isCurrency && 'info-box__main-text--small'}`}>
          {isCurrency ? `${currencySymbol}${numeral(dataObject.value).format('0,0')}` : dataObject.value}
        </p>
        <p className="info-box__sub-text">{isNaN(percentage) ? 'n/a' : `${percentage}%`}</p>
      </div>
      <div className="info-box__footer">
        <a href={route} className="button button--secondary button--compact">
          View Items <i className="icon-arrow-right-top icon-fw icon-0-8x" />
        </a>
      </div>
    </div>
  );
}
