import * as React from 'react';

export interface IButtonProps {
  buttonText: React.ReactNode;
  handleButtonClick: React.DOMAttributes<any>['onClick'];
}

const button = (props: IButtonProps) => {
  const { buttonText, handleButtonClick } = props;

  if (!buttonText || !handleButtonClick) return <></>;

  return (
    <div className="button button--secondary" onClick={handleButtonClick}>
      {buttonText}
    </div>
  );
};

export default button;
