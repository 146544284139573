import * as React from 'react';

import { ReportTemplatesReportTemplateSection } from 'javascript/models';

export default function mapVisual({ attributes, handleChange, handleManualChange }) {
  const [selectedType, setSelectedType] = React.useState(attributes.barChartType);

  function updateAttributes() {
    const value = {
      type: selectedType,
    };
    handleManualChange(value, 'barChartDetails');
  }

  React.useEffect(() => {
    updateAttributes();
  }, [selectedType]);

  function handleTypeChange() {
    setSelectedType(this);
  }

  const typeOptions = ReportTemplatesReportTemplateSection.mapOptions.map((type) => {
    return (
      <div className="col-tn-1-3" key={type.value}>
        <div className="pretty pretty--large pretty--with-label p-round">
          <input
            type="radio"
            value={type.value}
            id="type"
            onChange={handleTypeChange.bind(type.value)}
            checked={selectedType === type.value}
          />
          <div className="state p-blue-circle">
            <label>{type.label}</label>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="frow frow--gutters">
      <div className="col-mc-1-1">
        <label className="form__label" htmlFor="">
          Chart Type
        </label>
        <div className="frow ">{typeOptions}</div>
      </div>
    </div>
  );
}
