import * as React from 'react';

import { ReportTemplate } from 'javascript/models';

const Context = React.createContext({
  actions: {
    getData: () => {},
    handleColorChange: (index, color) => {},
    handleDestroy: (reportTemplate) => {},
    handleDragEnd: (result) => {},
    setFocus: (result) => {},
  },
  props: {
    canEdit: false,
  },
  state: {
    focus: 'report',
    pageColors: [],
    reportTemplate: null,
    selectedTab: 'report',
  },
});

export default Context;
