import * as React from 'react';

import { format, getQuarter, getYear, parse } from 'date-fns';
import { startCase, reduce } from 'lodash';
import { complement, filter, head, includes, isNil, partialRight, pipe, prop, propSatisfies, map } from 'ramda';

import * as ReactTooltip from 'react-tooltip';
import { ProgressBar } from '@blueprintjs/core';

import { platformPortfolioPath } from 'javascript/application/ts_routes';

import Frow from '../../../frow/Frow';
import Column from '../../../frow/Column';
import { Review } from 'javascript/models';

import AssignOwner from '../AssignOwner';

const columns = (
  administrators,
  clientTypes,
  companyId,
  filters,
  handleReviewsAssigned,
  quarters,
  reviewFlags,
  risks,
  reviewTransitions,
  selectedQueue,
) => {
  const reviewTransitionFilters = reviewTransitions.map((value) => ({ display: `${startCase(value)} Error`, value }));
  reviewTransitionFilters.push({ display: 'None', value: 'null' });

  const tableColumns = [
    {
      Cell: (row) => {
        return (
          <div className="frow frow--items-center">
            <a
              href={platformPortfolioPath(row.original.reviewableId, { review: row.original.id })}
              className="link link--secondary"
            >
              {row.original.reference}
            </a>
          </div>
        );
      },
      Header: 'Portfolio',
      accessor: 'reference',
      columnName: 'Portfolio',
      id: 'p_ref',
    },
    {
      Cell: (row) => {
        return (
          <div>
            Q{getQuarter(row.original.endDate)} {getYear(row.original.endDate)}
          </div>
        );
      },
      Header: 'Quarter',
      accessor: 'endDate',
      columnName: 'Quarter',
      filterOptions: {
        key: 'end_date',
        options: quarters,
        selectedOptions: filters.end_date,
      },
      id: 'end_date',
      maxWidth: 100,
    },
    {
      Cell: (row) => {
        return (
          <div className="frow frow--items-center">
            <img src={row.original.clientTypeIcon} alt="Client Type" className="mar-r-1 client-type-table-icon" />
          </div>
        );
      },
      Header: 'Service',
      accessor: 'clientType',
      className: 'hide-md-flex',
      columnName: 'Service',
      filterOptions: {
        key: 'client_type',
        options: clientTypes.map((type) => ({ display: type, value: type })),
        selectedOptions: filters.client_type,
      },
      headerClassName: 'hide-md-block',
      id: 'client_type_icon',
      maxWidth: 100,
    },
    {
      Cell: (row) => {
        const textColor = row.original.attributes.enrichedHoldings ? 'gold' : 'light-grey';
        let tooltip = 'We do not have holdings level data for this review';
        if (row.original.enrichedHoldings) {
          tooltip = 'We have holdings level data for this review';
        }
        return (
          <div data-tip={tooltip}>
            <i className={`fas fa-2x text-brand-${textColor} fa-medal`} />
          </div>
        );
      },
      Header: 'Data',
      accessor: 'enrichedHoldings',
      className: 'hide-md-flex',
      columnName: 'Data',
      headerClassName: 'hide-md-block',
      id: 'enriched_holdings',
      maxWidth: 55,
    },
    {
      Cell: (row) => {
        return <div data-tip={row.original.client}>{row.original.client}</div>;
      },
      Header: 'Client',
      accessor: 'client',
      className: 'hide-lg-flex',
      columnName: 'Client',
      headerClassName: 'hide-lg-block',
      id: 'c_name',
    },
    {
      Cell: (row) => {
        return <div data-tip={row.original.entityName}>{row.original.entityName}</div>;
      },
      Header: 'Entity',
      accessor: 'entityName',
      columnName: 'Entity',
      id: 'e_name',
    },
    {
      Cell: (row) => {
        return (
          <div data-tip={row.original.managerName}>
            {row.original.managerName}
            <ReactTooltip effect="solid" delay={300} />
          </div>
        );
      },
      Header: 'Manager',
      accessor: 'managerName',
      className: 'hide-lg-flex',
      columnName: 'Manager',
      headerClassName: 'hide-lg-block',
      id: 'm_name',
    },
    {
      Cell: (row) => {
        return <div>{row.original.currency.toUpperCase()}</div>;
      },
      Header: 'CCY',
      accessor: 'currency',
      className: 'hide-md-flex',
      columnName: 'CCY',
      headerClassName: 'hide-md-block',
      id: 'currency',
      maxWidth: 60,
    },
    {
      Cell: (row) => {
        return <div>{startCase(row.original.risk)}</div>;
      },
      accessor: 'risk',
      className: 'hide-md-flex',
      columnName: 'Risk',
      filterOptions: {
        key: 'portfolio_risk',
        options: risks.map((type) => ({ display: startCase(type), value: type })),
      },
      headerClassName: 'hide-md-block',
      id: 'risk',
    },
    {
      Cell: (row) => {
        if (row.original.previousOwner) {
          return <div>{row.original.previousOwner.fullName}</div>;
        }
        return <div>Unassigned</div>;
      },
      Header: 'Last Owner',
      accessor: 'previousOwner.fullName',
      columnName: 'Last Owner',
      filterOptions: {
        key: 'previous_owner_id',
        options: administrators,
        selectedOptions: filters.previous_owner_id,
      },
      id: 'previous_owner',
    },
    {
      Cell: (row) => {
        if (row.original.currentOwner) {
          return (
            <div>
              {row.original.currentOwner.fullName}
              <AssignOwner
                administrators={administrators}
                companyId={companyId}
                handleReviewsAssigned={handleReviewsAssigned}
                iconButton
                id={row.original.id}
              />
            </div>
          );
        }
        return (
          <AssignOwner
            administrators={administrators}
            companyId={companyId}
            handleReviewsAssigned={handleReviewsAssigned}
            id={row.original.id}
          />
        );
      },
      Header: 'Owner',
      accessor: 'currentOwner.fullName',
      columnName: 'Owner',
      filterOptions: {
        key: 'current_owner_id',
        options: administrators,
        selectedOptions: filters.current_owner_id,
      },
      id: 'current_owner_id',
    },
    {
      Cell: (row) => {
        if (!row.original.dueDate) {
          return <div>N/A</div>;
        }
        return <div>{format(parse(row.original.dueDate), 'DD MMM YYYY')}</div>;
      },
      Header: 'Due Date',
      accessor: 'dueDate',
      columnName: 'Due Date',
      id: 'due_date',
    },
    {
      Cell: (row) => {
        const { color, number } = row.original.attributes.displayStatus;
        return (
          <div className="small-uppercase" data-tip={startCase(row.original.attributes.status)}>
            <div className={`status-indicator status-indicator--${color}`} data-number={number}>
              <span className="hide-lg-inline">{startCase(row.original.attributes.status)}</span>
            </div>
          </div>
        );
      },
      Header: 'Status',
      columnName: 'Status',
      id: 'status',
    },
    {
      Cell: (row) => {
        const lastReviewTransition = row.original.lastReviewTransition;
        if (!lastReviewTransition) return null;

        if (!lastReviewTransition.errorSubCategory) return null;

        return (
          <div className="frow frow--direction-column" style={{ margin: '-5px 0' }}>
            {startCase(lastReviewTransition.errorSubCategory)} Error
          </div>
        );
      },
      Header: 'Current Error',
      accessor: 'lastReviewTransitionError',
      className: 'hide-md-flex',
      columnName: 'Current Error',
      customFilterKey: 'last_review_transition_error_sub_category',
      customFilterOptions: reviewTransitionFilters,
      customFilterable: true,
      id: 'last_review_transition_error',
    },
    {
      Cell: (row) => {
        const review: Review = row.original;
        const childPortfolios = review.reviewable.childPortfolios;

        if (!childPortfolios) return null;

        const childReviewStatusSelector = pipe(
          prop('reviews'),
          filter(propSatisfies((endDate) => endDate === review.endDate, 'endDate')),
          head,
          prop('status'),
          (x) => x && x.toUpperCase(),
        );

        const childReviewStatuses = pipe(
          map(childReviewStatusSelector),
          filter(complement(partialRight(includes, [['TERMINATED']]))),
          filter(complement(isNil)),
        )(childPortfolios);

        const childReviewCount = childReviewStatuses.length;

        if (!childReviewCount) return null;

        const verifiedReviewStatuses = filter(partialRight(includes, [['READY_TO_SEND', 'SENT', 'NO_REVIEW']]))(
          childReviewStatuses,
        );

        const atLeastVerifiedCount = verifiedReviewStatuses.length;

        return (
          <Frow direction="column" itemAlignment="center">
            <Column breakpointSize="mc" columnSpan={1} maxColumns={1} style={{ textAlign: 'center' }}>
              {atLeastVerifiedCount} / {childReviewCount}
            </Column>
            <ProgressBar
              animate={false}
              stripes={false}
              value={atLeastVerifiedCount / childReviewCount}
              intent="primary"
            />
          </Frow>
        );
      },
      Header: 'Ready Child Reviews',
      sortable: false,
    },
    {
      Cell: (row) => {
        return (
          <a
            href={platformPortfolioPath(row.original.reviewableId, { review: row.original.id })}
            style={{ display: 'block' }}
          >
            <div className="rt-tr-clickable-icon">
              <i className="icon-arrow-right-top icon-0-8x" />
            </div>
          </a>
        );
      },
      Header: '',
      sortable: false,
    },
  ] as any;

  if (selectedQueue === 'in_progress') {
    const noteColumn = {
      Cell: (row) => {
        const length = row.original.flagList.length;
        const flags = row.original.flagList.map((flag, index) => {
          return (
            <div className="col-mc-1-1" key={index} style={{ marginBottom: length === index + 1 ? '0' : '5px' }}>
              {flag}
            </div>
          );
        });

        return (
          <div className="frow frow--direction-column" style={{ margin: '-5px 0' }}>
            {flags}
          </div>
        );
      },
      Header: 'Notes',
      accessor: 'flagList',
      columnName: 'Notes',
      filterOptions: {
        key: 'notes',
        options: reviewFlags.map((flag) => ({ display: flag, value: flag })),
      },
      id: 'flag_list',
      show: false,
      sortable: false,
    };

    tableColumns.splice(13, 0, noteColumn);
  }

  return React.useMemo(() => tableColumns, [
    administrators,
    clientTypes,
    companyId,
    filters,
    handleReviewsAssigned,
    quarters,
    reviewFlags,
    risks,
    reviewTransitions,
    selectedQueue,
  ]);
};

export default columns;
