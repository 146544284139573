import * as React from 'react';
import { Column, Frow } from 'components/frow';

const View = (props) => {
  const [displayEditIcon, setDisplayEditIcon] = React.useState(false);

  const showEditIcon = React.useCallback(() => setDisplayEditIcon(true), []);
  const hideEditIcon = React.useCallback(() => setDisplayEditIcon(false), []);

  return (
    <Frow onMouseEnter={showEditIcon} onMouseLeave={hideEditIcon}>
      {displayEditIcon && <span>Hello</span>}
      <Column {...props}>note.body</Column>
    </Frow>
  );
};

export default View;
