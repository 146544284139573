import * as React from 'react';

import { snakeCase, startCase } from 'lodash';
import { format } from 'date-fns';

import { Portfolio } from 'javascript/models/portal/Portfolio';

import Loading from '../../../shared/Loading';
import ReactPieChart from '../../../shared/charting/recharts/ReactPieChart';

import ClientPortalCard from '../../shared/ClientPortalCard';

import stateToggle from './shared/stateToggle';

import PieChartTableLegend from './PieChartTableLegend';
import IPieChartData from '../../../shared/charting/recharts/interfaces/IPieChartData';
import useChartFilter from '../../../shared/customHooks/useChartFilter';
import { useClientPortalTheme } from '../../../shared/colours';

const riskBreakdown = ({ clientId, selectedDate, filterState }) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [meta, setMeta] = React.useState();
  const [selectedSegment, setSelectedSegment] = filterState;
  const [data, setData] = React.useState<IPieChartData[]>([]);

  React.useEffect(() => {
    setIsLoading(true);
    getPortfolios().then(() => setIsLoading(false));
  }, [selectedDate]);

  const handleSelect = React.useCallback(
    (slice) => {
      stateToggle(setSelectedSegment, false)(snakeCase(slice));
    },
    [selectedSegment],
  );

  useChartFilter(data, setData, 'name', selectedSegment);

  const getColour = useClientPortalTheme('riskProfile');

  const getPortfolios = async () => {
    const formattedDate = format(selectedDate, 'YYYY-MM-DD');
    const scope = Portfolio.per(0)
      .where({
        review_date: formattedDate,
        status: 'live',
        client_id: clientId,
      })
      .extraParams({ selected_date: formattedDate })
      .stats({ count: 'total', breakdown: 'risk' });

    const { meta } = await scope.all();
    const dataArr = meta.stats.breakdown.risk.map((type) => {
      type.color = getColour(type.name);
      type.name = startCase(type.name);
      return type;
    });

    setMeta(meta);
    setData(dataArr);
  };

  const renderChart = () => {
    const count = meta.stats.count.total;

    return (
      <div className="frow">
        <div className="col-md-1-2">
          <ReactPieChart
            activeShape
            animationBegin={50}
            animationDuration={400}
            data={data}
            height={320}
            labelText={count}
            noData={count < 1}
            displayLegend={false}
            setSelectedSlice={handleSelect}
          />
        </div>
        <PieChartTableLegend
          headerTitle="Risk Profile"
          description="Split of portfolios by risk with a review in the selected quarter."
          {...{ data }}
        />
      </div>
    );
  };

  return <ClientPortalCard title="Risk Profile Split">{isLoading ? <Loading /> : renderChart()}</ClientPortalCard>;
};

export default riskBreakdown;
