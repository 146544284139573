import * as React from 'react';

export default function tab({ handleTabChange, tabName, id, styling }) {
  const handleClick = () => {
    handleTabChange(tabName, id);
  };

  return (
    <div className={styling} onClick={handleClick}>
      {tabName}
    </div>
  );
}
