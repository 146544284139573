import * as React from 'react';

import { PrivateAsset } from 'javascript/models';
import { Balance } from 'javascript/models';
import ReactSelect from 'components/shared/forms/ReactSelect';
import ISelectOption, { ISelectProps } from 'components/interfaces/ISelectOption';
import { createAlert, graphitiErrorString } from 'components/shared/Utils';
import BalanceInput, { IBalanceInputProps } from './privateAssetForm/BalanceInput';
import CustomModal from 'components/shared/CustomModal';
import useVisibility from 'components/shared/customHooks/useVisibility';
import Table from 'components/shared/reactTable/Table';
import Columns from './privateAssetForm/table/Columns';
import { ICategoryOptions, ISubCategoryOptions } from '../PrivateAssets';

import lodashStartcase from 'lodash.startcase';
import lodashSortby from 'lodash.sortby';
import lodashFilter from 'lodash.filter';

export interface IPrivateAssetFormProps {
  privateAsset?: PrivateAsset;
  categoryOptions: ICategoryOptions;
  portfolioId: string;
  triggerTableUpdate: () => void;
  modalTitle: string;
  buttonContent: string | JSX.Element;
  buttonIcon?: boolean;
}

interface IModalProps {
  isOpen: boolean;
  handleOpen: () => void;
  handleClose: () => void;
  title: string;
  modifiers: string[];
}

export default function privateAssetForm(props: IPrivateAssetFormProps) {
  const [privateAsset, setPrivateAsset] = React.useState<PrivateAsset>(
    props.privateAsset || new PrivateAsset({ portfolioId: props.portfolioId }),
  );

  const mapCategoryToSelectOption = (value: string) => ({ label: lodashStartcase(value), value });

  const defaultCategoryOption = mapCategoryToSelectOption(privateAsset.category || '');
  const defaultSubCategoryOption = mapCategoryToSelectOption(privateAsset.subCategory || '');

  const { isOpen, handleOpen, handleClose } = useVisibility(false);

  const buildCategoryOptions = () => {
    if (props.categoryOptions) {
      return Object.keys(props.categoryOptions).map(mapCategoryToSelectOption);
    }
  };

  const [categoryOption, setCategoryOption] = React.useState(defaultCategoryOption);
  const [subCategoryOption, setSubCategoryOption] = React.useState(defaultSubCategoryOption);
  const [category, setCategory] = React.useState(privateAsset.category || '');
  const [description, setDescription] = React.useState(privateAsset.description || '');
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [balances, setBalances] = React.useState(privateAsset.balances);
  const [displayedBalances, setDisplayedBalances] = React.useState<Balance[]>();
  const [categoryOptions, setCategoryOptions] = React.useState<ISelectOption[]>(buildCategoryOptions);
  const [subCategoryOptions, setSubCategoryOptions] = React.useState<ISelectOption[]>();

  React.useEffect(() => setCategoryOptions(buildCategoryOptions), [props.categoryOptions]);

  React.useEffect(() => {
    if (props.categoryOptions && props.categoryOptions[category]) {
      const newSubCategoryOptions = Object.keys(props.categoryOptions[category]).map(mapCategoryToSelectOption);
      newSubCategoryOptions.push(mapCategoryToSelectOption('other'));
      setSubCategoryOptions(newSubCategoryOptions);
    }
  }, [props.categoryOptions, category]);

  const updateDisplayedBalances = () => {
    const newDisplayBalances: Balance[] = lodashFilter(balances, (balance: Balance) => !balance.isMarkedForDestruction);
    setDisplayedBalances(lodashSortby(newDisplayBalances, (balance: Balance) => new Date(balance.valueDate)).reverse());
  };

  React.useEffect(updateDisplayedBalances, [balances]);

  const handleCategoryChange = (option: ISelectOption) => {
    setCategoryOption(option);
    setCategory(option.value);
    setSubCategoryOption(null);
    privateAsset.category = option.value;
    privateAsset.subCategory = null;
  };

  const handleSubCategoryChange = (option: ISelectOption) => {
    setSubCategoryOption(option);
    privateAsset.subCategory = option.value;
  };

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
    privateAsset.description = event.target.value;
  };

  const selectProps: ISelectProps = {
    handleChange: handleCategoryChange,
    id: 'private_asset_category_select',
    name: 'private_asset_category',
    options: categoryOptions,
    theme: 'dark',
    value: categoryOption,
  };

  const subCategorySelectProps: ISelectProps = {
    handleChange: handleSubCategoryChange,
    id: 'private_asset_sub_category_select',
    name: 'private_asset_sub_category',
    options: subCategoryOptions,
    theme: 'dark',
    value: subCategoryOption,
  };

  const buttonClasses = `button button--secondary ${props.buttonIcon ? 'button--icon' : 'button--no-min-width'}`;

  const handleAddBalance = (newBalance: Balance) => {
    const newBalances = [...balances];
    newBalances.push(newBalance);
    setBalances(newBalances);
    privateAsset.balances = newBalances;
  };

  const handleDeleteBalance = (balance: Balance) => {
    balance.isMarkedForDestruction = true;
    updateDisplayedBalances();
  };

  const clearState = () => {
    setPrivateAsset(new PrivateAsset({ portfolioId: props.portfolioId }));
    setCategoryOption({ label: '', value: '' });
    setSubCategoryOption({ label: '', value: '' });
    setDescription('');
    setBalances([]);
  };

  async function handleSave() {
    setIsSubmitting(true);

    const success = await privateAsset.save({ with: 'balances' });
    if (success) {
      createAlert('success', 'Private Asset successfully saved', 1500);
      props.triggerTableUpdate();
      setIsSubmitting(false);
      handleClose();
      if (!props.privateAsset) clearState();
    } else {
      createAlert('error', graphitiErrorString(privateAsset), 2500);
      setIsSubmitting(false);
    }
  }

  const modalProps: IModalProps = {
    handleClose,
    handleOpen,
    isOpen,
    modifiers: ['dark'],
    title: props.modalTitle,
  };

  return (
    <>
      <button type="button" className={buttonClasses} onClick={handleOpen}>
        {props.buttonContent}
      </button>
      <CustomModal {...modalProps}>
        <div className="modal__content">
          <div>
            <div className="frow frow--justify-between frow--centered col-sm-1-1">
              <label htmlFor="private_asset_category" className="form__label col-sm-1-3">
                Category
              </label>
              <div className="col-sm-1-3">
                <ReactSelect {...selectProps} />
              </div>
            </div>
            <div className="frow frow--justify-between frow--centered mar-t-1 col-sm-1-1">
              <label htmlFor="private_asset_sub_category" className="form__label col-sm-1-3">
                Sub-Category
              </label>
              <div className="col-sm-1-3">
                <ReactSelect {...subCategorySelectProps} />
              </div>
            </div>
            <div className="frow col-sm-1-1 mar-t-1 frow--centered">
              <label htmlFor="private_asset_description" className="form__label col-sm-1-3">
                Description
              </label>
              <input
                className="pad-v-0 pad-h-1 col-sm-2-3"
                style={{ minHeight: '38px' }}
                type="text"
                placeholder="e.g. Stradivarius Violin Collection"
                name="description"
                value={description}
                onChange={handleDescriptionChange}
              />
            </div>
          </div>
          <BalanceInput {...{ handleAddBalance }} />
          <h3>Previous Valuations</h3>
          <Table
            data={displayedBalances}
            columns={Columns({ handleDeleteBalance })}
            hasColumnSelector={false}
            hasPagination={false}
            showPagination={false}
          />
          <div className="frow frow--justify-end">
            <button className="button button-secondary button--compact" onClick={handleSave} disabled={isSubmitting}>
              Save
            </button>
          </div>
        </div>
      </CustomModal>
    </>
  );
}
