import * as React from 'react';

import { SortScope } from 'spraypaint';

import GraphitiTableWrapper from 'components/shared/GraphitiTableWrapper';

import columns from './table/columns';

import { Administrator } from 'javascript/models';

interface IProps {
  canInvite?: boolean;
  companyId: number;
  newPath: string;
  title: string;
}

export default function graphitiTable(props: IProps) {
  const { canInvite, companyId, newPath, title } = props;

  const scope = Administrator.where({ company_id: companyId });

  const initialSort: SortScope = { first_name: 'asc' };

  return (
    <GraphitiTableWrapper
      scope={scope}
      columns={columns()}
      hasHeader
      initialSort={initialSort}
      isSearchable
      newPath={canInvite ? newPath : null}
      newText="Invite New Admin"
      title={title}
      identifier="administrator:index"
    />
  );
}
