import * as React from 'react';

import { CSVLink } from 'react-csv';
import { format, parse } from 'date-fns';

import lodashStartcase from 'lodash.startcase';

import Button from '../forms/Button';

interface IProps {
  columns: any;
  filters: any;
  formatting?: any;
  scope: any;
  searchTerm: string;
  sorting: any;
}

export default function csvExport(props: IProps) {
  const { columns, filters, scope, searchTerm, sorting } = props;

  const [data, setData] = React.useState([]);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const csvDownloadButton = React.useRef(null);

  const headerRows = columns.map((column) => column.accessor).filter(Boolean);

  const csvData = data.map((record) => {
    return headerRows.map((header) => {
      if (props.formatting[header] === 'upcase') {
        return record[header].toUpperCase();
      }
      if (props.formatting[header] === 'humanize') {
        return lodashStartcase(record[header]);
      }
      if (props.formatting[header] === 'eval') {
        const evalString = columns.find((column) => column.accessor === header);
        // We are only evaluation strings that I set nobody panic
        return eval(`record.${evalString.eval}`);
      }
      return record[header];
    });
  });

  const headers = headerRows.map((header) => lodashStartcase(header));

  React.useEffect(() => {
    if (data.length > 0) {
      csvDownloadButton.current.link.click();
    }
    setIsSubmitting(false);
  }, [data]);

  function handleClick() {
    setIsSubmitting(true);
    getRecords();
  }

  async function getRecords() {
    let localScope = scope.order(sorting).where(filters);

    if (searchTerm) {
      localScope = localScope.where({ search: { match: searchTerm } });
    }

    const { data } = await localScope.all();

    setData(data);
  }

  return (
    <React.Fragment>
      <div className="hidden">
        <CSVLink
          ref={csvDownloadButton}
          data={csvData}
          headers={headers}
          filename={`mosaic_table_export_${format(parse(new Date()), 'DD-MM-YYYY')}.csv`}
        />
      </div>
      <div className="dropdown__content-footer">
        <Button
          buttonClasses="button--compact button--full-width"
          handleClick={handleClick}
          text="Export Table"
          submittingText="Exporting"
          isSubmitting={isSubmitting}
        />
      </div>
    </React.Fragment>
  );
}

csvExport.defaultProps = {
  formatting: {},
};
