import * as React from 'react';

import Display from './templateActionPoints/Display';
import New from './templateActionPoints/New';

import { TemplateActionPoint } from 'javascript/models';

export default function templateActionPoints() {
  const [templateActionPoints, setTemplateActionPoints] = React.useState([]);

  React.useEffect(() => {
    getActionPoints();
  }, []);

  async function getActionPoints() {
    const { data } = await TemplateActionPoint.per(1000).all();

    setTemplateActionPoints(data);
  }

  async function confirmDelete(templateActionPoint) {
    await templateActionPoint.destroy();
    getActionPoints();
  }

  const actionPoints = templateActionPoints.map((templateActionPoint) => {
    return (
      <Display
        {...{ confirmDelete, handleSuccessfulFormSubmission, templateActionPoint }}
        key={templateActionPoint.id}
      />
    );
  });

  function handleSuccessfulFormSubmission() {
    getActionPoints();
  }

  return (
    <div className="frow frow--gutters">
      {actionPoints}
      <New handleSuccessfulFormSubmission={handleSuccessfulFormSubmission} />
    </div>
  );
}
