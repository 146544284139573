import React, { useState } from 'react';

import { debounce, startCase } from 'lodash';

import { RejectionAction } from 'javascript/models';
import useVisibility from 'components/shared/customHooks/useVisibility';
import CustomModal from 'components/shared/CustomModal';
import { Frow } from 'components/frow';

import { ActionPointContext } from '../../../Show';

type InReviewAction = 'reject' | 'release';

const InReviewButton = ({ action }: { action: InReviewAction }) => {
  const { actionPoint, getActionPoint, isLoading, setIsLoading, currentUser } = React.useContext(ActionPointContext);

  const rejectAction: RejectionAction = actionPoint.completed ? 'rejectCompletion' : 'rejectDeferral';

  const { isOpen, handleOpen, handleClose } = useVisibility(false);
  const [actionNote, setActionNote] = useState('');

  const handleAction = async () => {
    setIsLoading(true);
    if (action === 'reject') {
      await actionPoint.reject(rejectAction, currentUser, actionNote);
    } else {
      await actionPoint.release(currentUser, actionNote);
    }
    getActionPoint().then(() => setIsLoading(false));
  };

  const handleSubmit = debounce(handleAction, 1000, { leading: true, trailing: false });

  const buttonEnabled = !isLoading && actionPoint.assignedToCurrentPortalUser(currentUser);
  const actionButtonEnabled = (actionNote.trim().length > 0 || action === 'release') && buttonEnabled;

  const { title, placeholder, labelText } = {
    reject: {
      labelText: 'Reason for Rejection',
      placeholder: 'Please provide an accompanying note detailing reason for rejection',
      title: `${startCase(rejectAction)} of Action Point}`,
    },
    release: {
      labelText: 'Release Note',
      placeholder: 'Please provide an accompanying note for the release of this action point',
      title: 'Release Action Point',
    },
  }[action];

  return (
    <>
      <button
        className={`button button--compact button--${action === 'reject' ? 'danger' : 'primary'}`}
        onClick={handleOpen}
        disabled={!buttonEnabled}
      >
        {startCase(action)}
      </button>
      <CustomModal {...{ isOpen, title, modifers: ['visible-overflow'] }}>
        <div className="modal__content">
          <div className="form form--light mar-b-2">
            <label htmlFor="assignee" className="form__label">
              {labelText}
            </label>
            <textarea
              className="textarea"
              placeholder={placeholder}
              value={actionNote}
              onChange={(e) => setActionNote(e.target.value)}
            />
          </div>
          <Frow justifyContent="end">
            <button className="button mar-r-1" onClick={handleSubmit} disabled={!actionButtonEnabled}>
              {action === 'reject' ? startCase(rejectAction) : 'Release'}
            </button>
            <button className="button button--danger" onClick={handleClose}>
              Cancel
            </button>
          </Frow>
        </div>
      </CustomModal>
    </>
  );
};

export default InReviewButton;
