import * as React from 'react';

import { clone } from 'ramda';

import ReactHorizontalBarChart from 'components/shared/charting/recharts/ReactHorizontalBarChart';
import ReactFloatingBarChart from 'components/shared/charting/recharts/ReactFloatingBarChart';

import { chartColorsV2 } from './chartColors';

export default function horizontalBarChart({ chartType, height, templateSection, theme, width }) {
  const chartColors = chartColorsV2();

  const formattedData = clone(templateSection.data);
  formattedData.forEach((dataItem) => dataItem.color = chartColors(dataItem.name, theme));

  if (chartType === 'taa_ranges') {
    formattedData.forEach((dataItem) => dataItem.currentValue = parseInt(dataItem.currentValue, 10));

    return (
      <ReactFloatingBarChart
        data={formattedData}
        barSize={30}
        isResponsive={false}
        labelSize={12}
        lineStroke=""
        lineWidth={0.8}
        percentage
        {...{ height, width }}
      />
    );
  }

  return (
    <ReactHorizontalBarChart
      data={formattedData}
      barSize={30}
      isResponsive={false}
      labelSize={12}
      percentage
      {...{ height, width }}
    />
  );
}
