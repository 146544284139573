import * as React from 'react';

import * as fetch from 'lodash.get';
import { format } from 'date-fns';

import { Portfolio } from 'javascript/models/portal/Portfolio';
import Loading from '../../../shared/Loading';

import ClientPortalCard from '../../shared/ClientPortalCard';

import RatingBreakdownBarChart from './RatingBreakdownBarChart';

const ratingBreakdown = ({ clientId, selectedDate, filterState }) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [meta, setMeta] = React.useState<any>();

  React.useEffect(() => {
    setIsLoading(true);
    getPortfolios().then(() => setIsLoading(false));
  }, [selectedDate]);

  const getPortfolios = async () => {
    if (!selectedDate) return null;
    const formattedDate = format(selectedDate, 'YYYY-MM-DD');

    const scope = Portfolio.per(0)
      .where({
        client_id: clientId,
        review_completed: true,
        review_date: formattedDate,
        status: 'live',
      })
      .extraParams({ selected_date: formattedDate })
      .stats({ count: ['no_rating', 'grouped_rating'] });

    const { meta } = await scope.all();

    setMeta(meta);
  };

  const renderData = () => {
    const { no_rating, grouped_rating } = meta.stats.count;
    const fiveRating = fetch(
      grouped_rating.find((ratingObject) => ratingObject.rating === 5),
      'count',
      0,
    );
    const fourRating = fetch(
      grouped_rating.find((ratingObject) => ratingObject.rating === 4),
      'count',
      0,
    );
    const threeRating = fetch(
      grouped_rating.find((ratingObject) => ratingObject.rating === 3),
      'count',
      0,
    );
    const twoRating = fetch(
      grouped_rating.find((ratingObject) => ratingObject.rating === 2),
      'count',
      0,
    );
    const oneRating = fetch(
      grouped_rating.find((ratingObject) => ratingObject.rating === 1),
      'count',
      0,
    );
    const unRated =
      no_rating +
      fetch(
        grouped_rating.find((ratingObject) => ratingObject.rating === null),
        'count',
        0,
      );

    const dataForBarChart = [
      { name: '5', value: fiveRating },
      { name: '4', value: fourRating },
      { name: '3', value: threeRating },
      { name: '2', value: twoRating },
      { name: '1', value: oneRating },
      { name: 'null', value: unRated },
    ];

    return (
      <div className="mar-t-1 mar-b-1 pad-r-1 frow frow--direction-column frow--items-end">
        <p className="text-small text-grey-40" style={{ width: '90%' }}>
          Distribution of ratings across the portfolios with a completed review in the selected quarter.
        </p>
        <RatingBreakdownBarChart data={dataForBarChart} filterState={filterState} />
        <p className="text-small text-grey-40" style={{ width: '90%' }}>
          The E-MAP rating is a quantitative score that considers the performance and risk (volatility) of a portfolio
          relative to the primary benchmark (per portfolio) over various time-frames.
        </p>
        <p className="text-small text-grey-40" style={{ width: '90%' }}>
          Note: Ratings are provided for portfolios captured by the Enhance Monitoring and Enhance Portfolio service
          lines, where relevant.
        </p>
      </div>
    );
  };

  return <ClientPortalCard title="EMAP Ratings">{isLoading ? <Loading /> : renderData()}</ClientPortalCard>;
};

export default ratingBreakdown;
