const seriesData = [
  [0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, -0.5, 0, -3.4, -1.6, -4, 0, 0, 0],
  [-1.2, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0],
  [-0.75, -1, -0.4, 0, -2, -1, 0, -4, -0.45],
  [-1.1, -0.6, -1, 0, 0, 0, -0.4, 0, 0],
  [0, -2.3, 0, -3.3, -0.67, 0, 0, 0, 0],
  [-6, -0.55, -2, -3.5, 0, -2.4, 0, 0, 0],
  [-3.4, -0.34, -1, 0, 0, -0.3, 0, -4, 0],
  [0, -0.55, 0, 0, -1, -2, -1.4, -0.1, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, -0.34],
  [0, -1, -0.5, -4, -2, 0, 0, 0, 0],
  [-2.4, -1.5, 0, -3, 0, -1.8, -0.6, -0.1, 0],
];

export function drawdownData(series) {
  const returnArray = [];
  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((num, index) => {
    const dataPointHash = { name: 'LABEL' };
    series.map((dataPoint, index) => {
      dataPointHash[dataPoint] = seriesData[num - 1][index];
    });
    returnArray.push(dataPointHash);
  });
  return returnArray;
}
