import * as React from 'react';
import * as $ from 'jquery';

import { Alert, Intent } from '@blueprintjs/core';
import CsrfToken from 'components/shared/CsrfToken';
import { createAlert } from 'components/shared/Utils';
import useVisibility from 'components/shared/customHooks/useVisibility';

import { platformPortfolioBenchmarkPortfolioBenchmarksCompositeReturnsPath } from 'javascript/application/ts_routes';

export default function regenerate({ portfolioBenchmarkId }) {
  const { isOpen, handleOpen, handleClose } = useVisibility(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  function handleConfirm() {
    $.ajax({
      beforeSend: () => setIsSubmitting(true),
      cache: false,
      data: { authenticity_token: CsrfToken() },
      dataType: 'json',
      success: () => {
        setTimeout(() => {
          const trackedJobEvent = new CustomEvent('trackedJob:new');
          document.dispatchEvent(trackedJobEvent);
          createAlert(
            'success',
            'Regenerating returns, this can take a minute. Once the job has run you will need to refresh ' +
              'the page to see the regenerate returns',
            3000,
          );
        }, 500);
      },
      type: 'POST',
      url: platformPortfolioBenchmarkPortfolioBenchmarksCompositeReturnsPath(portfolioBenchmarkId),
    });
  }

  return (
    <div>
      <button
        className="button button--secondary button--icon"
        onClick={handleOpen}
        disabled={isSubmitting}
        data-tip="Regenerate composite returns"
      >
        <i className="icon-refresh icon-fw" />
      </button>
      <Alert
        isOpen={isOpen}
        onClose={handleClose}
        canEscapeKeyCancel
        canOutsideClickCancel
        cancelButtonText="Cancel"
        confirmButtonText="Regenerate"
        intent={Intent.PRIMARY}
        onCancel={handleClose}
        onConfirm={handleConfirm}
        portalContainer={document.body}
      >
        <p className="mar-t-0">
          Are you sure you want to regenerate composite returns for this benchmark? Returns will be created from the
          first date we have performance data for all the component benchmarks to the latest date we have performance
          data for all of the component benchmarks
        </p>
      </Alert>
    </div>
  );
}
