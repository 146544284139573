import * as React from 'react';

import { PersistedSpraypaintRecord } from 'spraypaint';

import GraphitiTableWrapper from 'components/shared/GraphitiTableWrapper';

import { Portfolio } from 'javascript/models';
import { IRevisedColumn } from 'components/shared/reactTable/utilities/processColumns';

import columns from './table/columns';

interface IProps {
  clientTypes: string[];
  currencies: string[];
  hasHeader?: boolean;
  isExportable?: boolean;
  newPath?: string;
  riskOptions: string[];
  scope?: { [key: string]: string | { [key: string]: string } };
  statuses: string[];
}

export default function table(props: IProps) {
  const { clientTypes, currencies, hasHeader, isExportable, newPath, riskOptions, statuses } = props;

  let scope = Portfolio.includes(['manager', 'entity', 'client']);

  if (props.scope) {
    scope = scope.where(props.scope);
  }

  const formatting = {
    clientName: 'eval',
    clientType: 'humanize',
    currency: 'upcase',
    entity: 'eval',
    managerName: 'eval',
    risk: 'humanize',
    status: 'humanize',
  };

  const newColumns = columns(currencies, clientTypes, riskOptions, statuses) as IRevisedColumn<
    PersistedSpraypaintRecord<Portfolio>
  >[];

  return (
    <GraphitiTableWrapper
      scope={scope}
      columns={newColumns}
      // defaultFilters={{ status: statuses.filter(status => status !== 'terminated') }}
      formatting={formatting}
      hasHeader={hasHeader}
      isExportable={isExportable}
      isSearchable
      newPath={newPath}
      newText="Create Portfolio"
      title="Portfolios"
      identifier="portfolios:index"
    />
  );
}

table.defaultProps = {
  hasHeader: true,
};
