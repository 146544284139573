import * as React from 'react';

import * as ReactTooltip from 'react-tooltip';

import Button from 'components/shared/forms/Button';
import Dropzone from 'components/shared/forms/Dropzone';
import Loading from 'components/shared/Loading';
import Summary from './Summary';
import Table from './Table';
import { downloadTemplatePlatformBulkPortfolioImportsPath } from 'javascript/application/ts_routes';
import { Frow } from 'components/frow';

export default function presenter({ handleFileUpload, handleSubmit, isProcessing, isSubmitting, responseData }) {
  const { errors, portfolios } = responseData;

  const noDataText = (
    <div className="platform-panel__inner">
      <p className="text-white helper-text mar-v-0">Upload a sheet to see a preview here</p>
    </div>
  );

  const submitButton = (
    <div className="mar-v-2">
      <Button
        text="Import Sheet"
        isSubmitting={isSubmitting}
        submittingText="Importing Sheet"
        handleClick={handleSubmit}
      />
    </div>
  );

  const result = (
    <div className="platform-panel__inner">
      {!responseData.noData && <Summary {...{ responseData }} />}
      {portfolios.length > 0 && <Table {...{ portfolios }} />}
      {errors && errors.length < 1 && submitButton}
      <ReactTooltip effect="solid" />
    </div>
  );

  return (
    <React.Fragment>
      <div className="frow">
        <div className="col-md-3-3">
          <section className="platform-panel">
            <header className="platform-panel__header">
              <Frow justifyContent="between" itemAlignment="baseline">
                <h3 className="platform-panel__title">Upload</h3>
                <Button
                  hasIcon
                  icon="document"
                  buttonClasses="button--secondary button--no-min-width"
                  text="Download Template"
                  handleClick={() => (window.location.href = downloadTemplatePlatformBulkPortfolioImportsPath())}
                />
              </Frow>
            </header>
            <div className="platform-panel__inner">
              <div className="frow frow--gutters">
                <div className="col-mc-1-1">
                  <div className="form">
                    <label className="form__label">Upload Sheet</label>
                    <Dropzone
                      acceptedFiles=".csv, .xls, .xml, .xlsx, .xlsm"
                      theme="dark"
                      multiple={false}
                      onFileUpload={handleFileUpload}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div className="frow">
        <div className="col-md-1-1">
          <section className="platform-panel">
            <header className="platform-panel__header">
              <h3 className="platform-panel__title">Preview</h3>
            </header>
            {isProcessing ? <Loading /> : responseData.noData ? noDataText : result}
          </section>
        </div>
      </div>
    </React.Fragment>
  );
}
