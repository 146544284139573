import * as React from 'react';

import { Client } from 'javascript/models';

import { Frow, Column } from 'components/frow';
import DatePicker from 'react-datepicker';
import Dropzone from 'components/shared/forms/Dropzone';
import { DirectUpload } from 'activestorage';

type Props = {
  client: Pick<Client, 'documents'>;
};

const Documents = ({ client: { documents } }: Props) => {
  const [signatureDate, setSignatureDate] = React.useState<Date | null>(null);
  const [blobSignedId, setBlobSignedId] = React.useState<string>('');
  const [isProcessing, setIsProcessing] = React.useState<boolean>(false);
  const latestDocument = documents.length >= 1 && documents[0].id ? documents[0] : null;

  function handleFileUpload(files) {
    if (files.length < 1) return;

    setIsProcessing(true);
    const upload = new DirectUpload(files[0], '/rails/active_storage/direct_uploads');

    upload.create((error, blob) => {
      if (!error) {
        setBlobSignedId(blob.signed_id);
      }
      setIsProcessing(false);
    });
  }

  return (
    <>
      <h4 className="form__fieldset-label">Letter of Engagement</h4>
      <Frow gutterSize={1}>
        <Column>
          {latestDocument && (
            <Frow gutterSize={1} itemAlignment="center" columnContext={{ maxColumns: 5 }}>
              <Column columnSpan={2}>
                <label className="form__label">Latest document</label>
                <span className="text-white text-small">{latestDocument.signatureDate}</span>
              </Column>
            </Frow>
          )}

          <Frow gutterSize={1} itemAlignment="center" columnContext={{ maxColumns: 5 }}>
            <Column columnSpan={2}>
              <Dropzone
                handleRemoveFile={() => setBlobSignedId('')}
                onFileUpload={handleFileUpload}
                multiple={false}
                isSubmitting={isProcessing}
                shouldShowFiles={true}
              />
              <input type="hidden" name="client[documents_attributes][0][document]" value={blobSignedId} />
            </Column>
          </Frow>

          <Frow gutterSize={1} itemAlignment="center" columnContext={{ maxColumns: 5 }}>
            <Column columnSpan={2}>
              <label className="form__label">Signature date</label>
              <DatePicker
                name="client[documents_attributes][0][signature_date]"
                id="signature_date"
                selected={signatureDate}
                onChange={(date) => setSignatureDate(date)}
                dateFormat="dd/MM/yyyy"
              />
            </Column>

            <Column columnSpan={3} />
          </Frow>
        </Column>
      </Frow>
    </>
  );
};

export default Documents;
