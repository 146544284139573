import * as React from 'react';

import returnColumns from './columns/returnColumns';
import ReviewContext from '../ReviewContext';
import Table from 'components/shared/reactTable/Table';

export default function returnsTable() {
  const context = React.useContext(ReviewContext);
  const { review } = context.props;
  const { returns } = review.attributes.metrics;

  let columnHeaders = [
    'portfolio',
    'enhance_benchmark',
    'cash_benchmark',
    'managers_benchmark',
    'peer_group',
    'target_return',
  ];

  if (returns.length) {
    // The slice gets rid of the 'Name' key (which describes the period we are talking about)
    columnHeaders = Object.keys(returns[0]).slice(1);
  }

  const data = [];
  return (
    <Table
      columns={returnColumns(columnHeaders)}
      data={returns}
      hasColumnSelector={false}
      hasPagination={false}
      isLoading={false}
      manual={false}
      meta={{ count: data.length }}
    />
  );
}
