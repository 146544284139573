/* eslint-disable  */
import * as React from 'react';
import * as $ from 'jquery';
import groupBy from 'lodash.groupby';
import mapValues from 'lodash.mapvalues';
import { IAllocation, IBalance, ICashFlow, ICell, IScope, ICcyAllocation } from 'components/interfaces/DataTableTypes';

import { platformPortfolioPortfolioReturnsPath } from 'javascript/application/ts_routes';
import CsrfToken from 'components/shared/CsrfToken';

import CustomModal from 'components/shared/CustomModal';
import DataTable from './manualEntry/DataTable';
import DataNotes from './manualEntry/DataNotes';
import { Portfolio } from './../../../../../javascript/models/Portfolio';
import { Holding } from './../../../../../javascript/models/Holding';
import { Balance } from './../../../../../javascript/models/Balance';
import { CashFlow } from './../../../../../javascript/models/CashFlow';
import Tab from './manualEntry/tab';
import ScratchPad from './manualEntry/ScratchPad';
import { Frow } from 'components/frow';

import useVisibility from 'components/shared/customHooks/useVisibility';
import { createAlert, graphitiErrorString } from 'components/shared/Utils';
import lodashFilter from 'lodash.filter';
import { differenceInCalendarMonths } from 'date-fns';
import lodashSortBy from 'lodash.sortby';
import { Checkbox } from '@blueprintjs/core';
import { Radio, RadioGroup } from '@blueprintjs/core';

export default function manualEntry({ disabled, portfolio }) {
  const defaultAllocations: IAllocation = {
    date: '',
    equity: 0,
    cash: 0,
    fixed_income: 0,
    hedge_fund: 0,
    other: 0,
  };

  const defaultCcyAllocations: ICcyAllocation = {
    date: '',
    gbp: 0,
    usd: 0,
    eur: 0,
    jpy: 0,
    hkd: 0,
    chf: 0,
    sgd: 0,
    zar: 0,
    cad: 0,
    aud: 0,
    nzd: 0,
    sek: 0,
    oth: 0,
  };

  const [discarded, setDiscarded] = React.useState(false);
  const { isOpen, handleOpen, handleClose } = useVisibility(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const { combined, consolidated } = portfolio.attributes;
  const [apiPortfolio, setApiPortfolio] = React.useState();
  const [assetClasses, setAssetClasses] = React.useState([]);
  const [holdings, setHoldings] = React.useState([]);
  const [holdingsCurrency, setHoldingsCurrency] = React.useState([]);
  const [balances, setBalances] = React.useState([]);
  const [cashFlows, setCashFlows] = React.useState([]);
  const [allocations, setAllocations] = React.useState<IAllocation[]>([defaultAllocations]);
  const [ccyAllocations, setCcyAllocations] = React.useState<ICcyAllocation[]>([defaultCcyAllocations]);

  React.useEffect(() => {
    getAssetClasses();
  }, []);

  const groupAllocations = (holdings) =>
    holdings.map((holding) => ({
      date: holding.value_date,
      ...mapValues(holding, (value) => (isNaN(Number(value)) ? value : Number(value).toFixed(2))),
    }));

  React.useEffect(() => {
    setAllocations(lodashSortBy(groupAllocations(holdings), (holding) => holding.date).reverse());
    setCcyAllocations(lodashSortBy(groupAllocations(holdingsCurrency), (currency) => currency.date).reverse());
  }, [holdings, holdingsCurrency, assetClasses]);

  const groupCashFlows = (cashFlows) => {
    const cashFlowCells = cashFlows.map((cashFlow) => {
      return {
        date: cashFlow.valueDate,
        capitalIn: Math.max(0, cashFlow.amount),
        capitalOut: -Math.min(0, cashFlow.amount),
      };
    });
    const dateGroupedCashFlows = groupBy(cashFlowCells, 'date');
    return Object.values(
      mapValues(dateGroupedCashFlows, (dateCashFlows) => {
        return dateCashFlows.reduce((cashFlowObj, cashFlow) => {
          return {
            date: cashFlow.date,
            capitalIn: cashFlowObj.capitalIn + cashFlow.capitalIn,
            capitalOut: cashFlowObj.capitalOut + cashFlow.capitalOut,
          };
        });
      }),
    );
  };

  const getAssetClasses = async () => {
    setAssetClasses(await Holding.allAvailableAssetClasses);
  };

  async function getPortfolio() {
    setIsLoading(true);
    let scope = Portfolio.selectExtra(['enriched_holding', 'enriched_holding_currency']).includes([
      'holdings',
      'balances',
      'cash_flows',
    ]);

    scope = discarded ? scope.where({ balances: { withDiscarded: true }, cashFlows: { withDiscarded: true } }) : scope;

    const { data } = await scope.find(portfolio.id);

    setApiPortfolio(data);
    setHoldings(data.enrichedHolding);
    setHoldingsCurrency(data.enrichedHoldingCurrency);

    const balances: IBalance[] = data.balances.map((balance) => ({
      currency: balance.currency,
      discardedAt: balance.discardedAt,
      date: balance.valueDate,
      balance: balance.amount,
      dbObject: balance,
    }));
    setBalances(lodashSortBy(balances, (balance) => balance.date).reverse());
    setCashFlows(lodashSortBy(groupCashFlows(data.cashFlows), (cashFlow) => cashFlow.date).reverse());

    setIsLoading(false);
  }
  const Thing = () => {
    interface ITab {
      id: number;
      name: string;
    }

    const tabNames = ['Balances & Cash Flows', 'Asset Allocations', 'Currency Allocations'];
    const cashFlowColumns = ['capitalIn', 'capitalOut'];
    const currencies = ['gbp', 'usd', 'eur', 'jpy', 'hkd', 'chf', 'sgd', 'zar', 'cad', 'aud', 'nzd', 'sek', 'oth'];
    const balanceColumns = ['balance'];
    if (discarded) balanceColumns.push('discardedAt');
    const [updatedAssetAllocations, setUpdatedAssetAllocations] = React.useState([]);

    const [updatedCcyAllocations, setUpdatedCcyAllocations] = React.useState([]);
    const [updatedBalances, setUpdatedBalances] = React.useState([]);
    const [updatedCashFlows, setUpdatedCashFlows] = React.useState([]);
    const [currentTab, setCurrentTab] = React.useState<ITab>({ name: 'Balances & Cash Flows', id: 0 });
    const [currentScope, setCurrentScope] = React.useState(defaultScope());
    const [canSubmitAssets, setCanSubmitAssets] = React.useState(true);
    const [canSubmitCcy, setCanSubmitCcy] = React.useState(true);
    const [isSubmittingData, setIsSubmittingData] = React.useState(false);
    const [allDatesValid, setAllDatesValid] = React.useState(true);
    const dataFrequencyMonths = apiPortfolio && apiPortfolio.dataFrequencyMonths === 3 ? 'quarterly' : 'monthly';
    const [selectedDateAutoFillValue, setselectedDateAutoFillValue] = React.useState(dataFrequencyMonths);
    function defaultScope() {
      return {
        help: `Welcome to the calculations scratch pad. Type 'help' to see this message again.
        Type 'clear' to clear the output.
        Type 'basics' to get additional information on basic functionality.
        Type 'functions' for more information on functions`,
        basics: `You can assign values to variable names by using '=', e.g. 'total = 10 + 20.
        You can use '_' to access the last value output by the scratchpad.
        You can access values that are in cells with their cell reference, e.g. r2c5.
        You can also assign values from variables in here to cells, e.g. '=total'.
        Be careful with naming as the variables are scoped to all the tables.`,
        functions: `Type 'functions_list' for a list of functions pre-programmed into the scratchpad.
        Type 'creating_functions' for information on creating your own functions.`,
        functions_list: `Functions currently available in scratchpad: combine(family of functions), percentage.
        Type the function name followed by '_help' for information on the individual functions.`,
        creating_functions: `You can define your own functions in much the same way as you define a variable's name.
        When defining a function, type the name of the function you wish to create followed by parenthesis and any
        number of function arguments you wish to use. Then set this equal to the calculation the function should make.
        For example, a simple function that adds two numbers together would be: 'add(x, y) = x + y'.`,
        combine_help: `The combine family of functions allow you to combine multiple portfolio allocations together to calculate
        the overall allocation. The available functions are: combine2, combine3, and combine4.
        When using these functions, you must have defined variables p1, p2, p3, etc. which hold the total value of
        the portfolios to be combined. The general syntax for the combine functions is combineN(percentage_p1
        , percentage_p2, ...percentage_pN)`,
        percentage_help: `The percentage function takes two arguments (the value you want to know as a percentage and the total) and
        returns the percentage.`,
      };
    }

    React.useEffect(() => {
      const difference = balances.length > 1 && differenceInCalendarMonths(balances[0].date, balances[1].date);
      const newDateAutoFillValue = difference > 1 ? 'quarterly' : 'monthly';
      setselectedDateAutoFillValue(newDateAutoFillValue);
    }, []);

    const handleSaveClick = () => {
      setIsSubmittingData(true);
      const submitPromise = new Promise((resolve) => setTimeout(() => resolve(), 2000));

      if (Promise.resolve(submitPromise)) {
        return submitPromise
          .then(() => {
            submitUpdatesHandler();
          })
          .catch(() => setIsSubmittingData(false));
      }
    };

    const validateDates = () => {
      const updatedValues = [
        ...updatedAssetAllocations,
        ...updatedCcyAllocations,
        ...updatedBalances,
        ...updatedCashFlows,
      ];
      setAllDatesValid(
        updatedValues.every((valueObj) => {
          const valueObjDate = new Date(valueObj.date);
          return valueObjDate.getTime() === valueObjDate.getTime();
        }),
      );
    };

    React.useEffect(validateDates, [updatedBalances, updatedAssetAllocations, updatedCashFlows, updatedCcyAllocations]);

    async function submitUpdatesHandler() {
      if (canSubmitAssets && canSubmitCcy && allDatesValid) {
        setIsSubmittingData(true);

        markDiscardedHoldings();
        markDiscardedCcyHoldings();
        markDiscardedBalances();
        markDiscardedCashFlows();

        const updatedHoldings = lodashFilter(updatedAssetAllocations, (allocation) => allocation.value != '').map(
          (allocation) => {
            return new Holding({
              holdableType: 'Portfolio',
              holdableId: portfolio.id,
              assetClass: allocation.type,
              percentage: parseFloat(allocation.value),
              valueDate: allocation.date,
            });
          },
        );

        const updatedCcyHoldings = lodashFilter(updatedCcyAllocations, (allocation) => allocation.value != '').map(
          (allocation) => {
            return new Holding({
              holdableType: 'Portfolio',
              holdableId: portfolio.id,
              assetClass: 'portfolio',
              percentage: parseFloat(allocation.value),
              valueDate: allocation.date,
              currency: allocation.type,
            });
          },
        );

        const balancesToSubmit = lodashFilter(updatedBalances, (balance) => balance.balance != '').map(
          (balanceCell) => {
            return new Balance({
              balanceableId: apiPortfolio.id,
              balanceableType: 'Portfolio',
              amount: balanceCell.balance,
              valueDate: balanceCell.date,
              currency: apiPortfolio.currency,
            });
          },
        );

        const cashFlowsToSubmit = lodashFilter(updatedCashFlows, (cashFlow) => cashFlow.value != '').map(
          (cashFlowCell) => {
            return new CashFlow({
              cashFlowableType: 'Portfolio',
              cashFlowableId: apiPortfolio.id,
              amount: (cashFlowCell.type === 'capitalIn' ? 1 : -1) * cashFlowCell.value,
              valueDate: cashFlowCell.date,
            });
          },
        );

        apiPortfolio.holdings.push(...updatedHoldings, ...updatedCcyHoldings);
        apiPortfolio.balances.push(...balancesToSubmit);
        apiPortfolio.cashFlows.push(...cashFlowsToSubmit);

        await payloadSubmit(apiPortfolio);
      } else {
        if (!canSubmitAssets) createAlert('error', 'Asset allocations do not sum to approximately 100%', 5000);
        if (!canSubmitCcy) createAlert('error', 'Currency allocations do not sum to approximately 100%', 5000);
        if (!allDatesValid) createAlert('error', 'Attempted to save an invalid date', 5000);
      }
      setIsSubmittingData(false);
    }

    const [isSubmitting, setIsSubmitting] = React.useState(false);

    function handleRegenerate() {
      $.ajax({
        beforeSend: () => setIsSubmitting(true),
        data: { authenticity_token: CsrfToken() },
        dataType: 'json',
        error: (jqXhr) => {
          createAlert('error', 'There was a problem recreating returns for this portfolio', 5000);
          setIsSubmitting(false);
        },
        success: () => {
          createAlert('success', 'Returns successfully regenerated', 1500);
          const customEvent = new CustomEvent('portfolio:returns:update');
          document.dispatchEvent(customEvent);
          setIsSubmitting(false);
        },
        type: 'PATCH',
        url: platformPortfolioPortfolioReturnsPath(apiPortfolio.id),
      });
    }

    async function payloadSubmit(payload) {
      try {
        let success = await payload.save({ with: ['holdings', 'balances', 'cashFlows'] });
        if (success) {
          createAlert('success', 'Data successfully saved.', 1500);
          handleClose();
          handleRegenerate();
          getPortfolio();
        } else {
          createAlert('error', graphitiErrorString(payload), 2500);
        }
      } catch (e) {
        createAlert('error', e.toString(), 2500);
      }
    }

    function markDiscardedHoldings() {
      // Discard overwritten holdings
      updatedAssetAllocations.forEach((protoHolding) => {
        const holdingsToDiscard = apiPortfolio.holdings.filter((holding) => {
          return holding.assetClass === protoHolding.type && holding.valueDate === protoHolding.date;
        });
        holdingsToDiscard.forEach((holding) => (holding.discardedAt = new Date(Date.now())));
      });
      // Discard removed holdings (by value_date)
      const removedHoldings = apiPortfolio.holdings.filter((holding) => {
        return !allocationDates.includes(holding.valueDate) && holding.assetClass !== 'portfolio';
      });
      removedHoldings.map((holding) => (holding.discardedAt = new Date(Date.now())));
    }

    function markDiscardedCcyHoldings() {
      updatedCcyAllocations.forEach((allocation) => {
        const holdingsToDiscard = apiPortfolio.holdings.filter((holding) => {
          return holding.valueDate === allocation.date && holding.currency === allocation.type;
        });
        holdingsToDiscard.forEach((holding) => (holding.discardedAt = new Date(Date.now())));
      });
      const removedCcyHoldings = apiPortfolio.holdings.filter((holding) => {
        return !ccyAllocationDates.includes(holding.valueDate) && holding.currency;
      });
      removedCcyHoldings.map((holding) => (holding.discardedAt = new Date(Date.now())));
    }

    function markDiscardedBalances() {
      updatedBalances.map((newBalance) => {
        const balancesToDiscard = apiPortfolio.balances.filter(
          (balance) => balance.valueDate === newBalance.date && balance.discardedAt == null,
        );
        balancesToDiscard.map((balance) => (balance.discardedAt = new Date(Date.now())));
      });
      const removedBalances = apiPortfolio.balances.filter((balance) => !balanceDates.includes(balance.valueDate));
      removedBalances.map((balance) => (balance.discardedAt = new Date(Date.now())));
    }

    function markDiscardedCashFlows() {
      updatedCashFlows.map((newCashFlow) => {
        const cashFlowsToDiscard = apiPortfolio.cashFlows.filter(
          (cashFlow) => cashFlow.valueDate === newCashFlow.date && cashFlow.discardedAt == null,
        );
        cashFlowsToDiscard.map((cashFlow) => (cashFlow.discardedAt = new Date(Date.now())));
      });
      const removedCashFlows = apiPortfolio.cashFlows.filter((cashFlow) => !cashFlowDates.includes(cashFlow.valueDate));
      removedCashFlows.map((cashFlow) => (cashFlow.discardedAt = new Date(Date.now())));
    }

    const splitCells = (alteredCells: any[], tab: string) => {
      const splitCellsArr = [];
      alteredCells.map((cell) => {
        Object.entries(cell).map(([key, value]) => {
          !['date', 'function', 'total'].includes(key) && splitCellsArr.push({ value, date: cell.date, type: key });
        });
      });
      return splitCellsArr;
    };

    const amendedData = (scope: IScope, tab: string, columns: string[]) => {
      const updatedRows = Object.entries(scope)
        .filter(([key, cell]) => cell.changed)
        .map(([key, cell]) => cell.row);
      const filteredScope = groupBy(
        lodashFilter(scope, (scopeValue) => updatedRows.includes(scopeValue.row)),
        'row',
      );
      let alteredCells = [];
      mapValues(filteredScope, (row: ICell[]) => {
        const newCell = {};
        mapValues(row, (cell: ICell) => {
          newCell[columns[cell.col]] = cell.value;
        });
        if (newCell['date'] !== '') {
          alteredCells.push(newCell);
        }
      });

      if (['Asset Allocations', 'Currency Allocations'].includes(tab)) {
        let totals: number[];
        totals = alteredCells.map((cell) => cell.total);
        alteredCells = lodashFilter(splitCells(alteredCells, tab), (cell) => cell.value != '0.00');
        if (totals.every((total) => total >= 99) && totals.every((total) => total <= 101)) {
          tab === 'Asset Allocations' ? setCanSubmitAssets(true) : setCanSubmitCcy(true);
        } else {
          tab === 'Asset Allocations' ? setCanSubmitAssets(false) : setCanSubmitCcy(false);
        }
      }

      if (tab === 'Cash Flows') {
        alteredCells = lodashFilter(splitCells(alteredCells, tab), (cell) => cell.value != null);
      }

      const includedDates = lodashFilter(Object.entries(scope), ([key, cell]) => cell.col === 1).map(([key, cell]) => {
        return cell.value;
      });

      if (tab === 'Asset Allocations') {
        setUpdatedAssetAllocations(alteredCells);
        setAllocationDates(includedDates);
      } else if (tab === 'Currency Allocations') {
        setUpdatedCcyAllocations(alteredCells);
        setCcyAllocationDates(includedDates);
      } else if (tab === 'Balances & Cash Flows') {
        setUpdatedBalances(alteredCells);
        setBalanceDates(includedDates);
      } else if (tab === 'Cash Flows') {
        setUpdatedCashFlows(alteredCells);
        setCashFlowDates(includedDates);
      }
    };

    const [balanceDates, setBalanceDates] = React.useState<string[]>([]);
    const [allocationDates, setAllocationDates] = React.useState<string[]>([]);
    const [ccyAllocationDates, setCcyAllocationDates] = React.useState<string[]>([]);
    const [cashFlowDates, setCashFlowDates] = React.useState<string[]>([]);

    const handleTabChange = (tabName: string, id: number) => {
      setCurrentTab({ id, name: tabName });
    };

    const toggleDiscarded = (e) => {
      setDiscarded((prev) => !prev);
    };

    const renderTabs = () => {
      return tabNames.map((tabName, index) => {
        let styling = 'tabbed-datasheet__tab';
        if (tabName === currentTab.name) {
          styling += ' tabbed-datasheet__tab--active';
        }
        return <Tab {...{ tabName, id: index, key: index, handleTabChange, styling }} />;
      });
    };

    const renderTables = () => {
      return tabNames.map((tab, index) => {
        if (tab === 'Asset Allocations') {
          return (
            <div className={currentTab.name !== tab ? 'hidden' : ''} key={index}>
              <div>
                <DataTable
                  {...{
                    sheetData: allocations,
                    columnValues: assetClasses,
                    amendedData,
                    tab,
                    showTotals: true,
                    calculationScope: currentScope,
                    setCurrentScope,
                  }}
                />
              </div>
            </div>
          );
        } else if (tab === 'Currency Allocations') {
          return (
            <div className={currentTab.name !== tab ? 'hidden' : ''} key={index}>
              <div>
                <DataTable
                  {...{
                    sheetData: ccyAllocations,
                    columnValues: currencies,
                    amendedData,
                    tab,
                    showTotals: true,
                    calculationScope: currentScope,
                    setCurrentScope,
                  }}
                />
              </div>
            </div>
          );
        } else if (tab === 'Balances & Cash Flows') {
          return (
            <div className={currentTab.name !== tab ? 'hidden' : ''} key={index}>
              <div className={'frow'}>
                <div className="col-md-5-11 mar-r-7">
                  <DataTable
                    {...{
                      sheetData: balances,
                      columnValues: balanceColumns,
                      amendedData,
                      tab,
                      showTotals: false,
                      calculationScope: currentScope,
                      setCurrentScope,
                      selectedDateAutoFillValue,
                    }}
                  />
                </div>
                <div className="col-md-5-11">
                  <DataTable
                    {...{
                      sheetData: cashFlows,
                      columnValues: cashFlowColumns,
                      amendedData,
                      tab: 'Cash Flows',
                      showTotals: false,
                      calculationScope: currentScope,
                      setCurrentScope,
                      selectedDateAutoFillValue,
                    }}
                  />
                </div>
              </div>
            </div>
          );
        }
      });
    };

    return (
      <>
        <Frow gutterSize={'half'}>
          <Checkbox checked={discarded} onChange={toggleDiscarded}>
            Include Discarded
          </Checkbox>

          <RadioGroup
            onChange={(event) => setselectedDateAutoFillValue(event.currentTarget.value)}
            inline
            selectedValue={currentTab.name == 'Balances & Cash Flows' ? selectedDateAutoFillValue : 'quarterly'}
            disabled={currentTab.name !== 'Balances & Cash Flows'}
          >
            <Radio label="Auto-fill date monthly" value="monthly" />
            <Radio label="Auto-fill date quarterly" value="quarterly" />
          </RadioGroup>
        </Frow>
        <div>
          <div className="tabbed-datasheet">
            {renderTabs()}
            <button
              className="button button--icon button--datasheet-icon pad-1"
              onClick={handleSaveClick}
              data-tip="Save Changes and Regenerate Returns"
              disabled={isSubmittingData}
            >
              <i className="far fa-save" />
            </button>
          </div>
          <div className="frow">
            <div className="col-md-6-9">{renderTables()}</div>
            <div className="col-md-3-9 pad-r-1 pad-l-3">
              <ScratchPad {...{ currentScope, setCurrentScope }} />
              <DataNotes {...{ portfolio: apiPortfolio }} />
            </div>
          </div>
        </div>
      </>
    );
  };

  React.useEffect(() => {
    getPortfolio();
  }, [discarded]);

  function hideManualEntryButton() {
    if (!combined && !consolidated) {
      return (
        <button
          className="button button--secondary button--icon mar-r-1"
          onClick={handleOpen}
          data-tip="Manually Enter Portfolio Data"
          data-for="portfolio_controls"
          disabled={disabled || isLoading}
        >
          <i className="icon-menu-templates icon-fw" />
        </button>
      );
    }
  }

  return (
    <>
      {hideManualEntryButton()}
      <CustomModal
        isOpen={isOpen}
        handleClose={handleClose}
        modifiers={['huge']}
        title="Manually Enter Allocations, Balances, and Cash Flows"
      >
        <div className="modal__content">
          <div className="frow">
            <Thing />{' '}
          </div>
        </div>
      </CustomModal>
    </>
  );
}
