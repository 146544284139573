import * as React from 'react';

import { startCase } from 'lodash';

import ReactLineChart from '../../../../shared/charting/recharts/ReactLineChart';
import { purple } from '../../../../shared/colours';

import useRadioSelect from '../../../../shared/customHooks/useRadioSelect';
import Loading from '../../../../shared/Loading';

import ClientPortalCard from '../../../shared/ClientPortalCard';
import ModalGraph from '../../../../modals/ModalGraph';

import { Portfolio } from 'javascript/models/portal/Portfolio';

import Table from './value/Table';

export default function value({ currentReviewPeriod, portfolioObject }) {
  const [isLoading, setIsLoading] = React.useState(true);
  const [portfolio, setPortfolio] = React.useState<Portfolio>();

  const getPortfolio = async () => {
    const { data } = await Portfolio.extraParams({ review_date: currentReviewPeriod })
      .selectExtra(['value_series_chart_data', 'asset_movement_table_data'])
      .find(portfolioObject.id);

    setPortfolio(data);
  };

  React.useEffect(() => {
    getPortfolio().then(() => setIsLoading(false));
  }, [currentReviewPeriod]);

  const [selectedType, setSelectedType] = React.useState('chart');

  const toggles = ['chart', 'table'].map((value) => ({ label: startCase(value), value }));
  const [radioToggle, selectedRadioRef] = useRadioSelect(toggles, 0);

  React.useEffect(() => setSelectedType(toggles[selectedRadioRef.current].value), [selectedRadioRef.current]);

  function RenderData({ enlargened }: { enlargened: boolean }) {
    let modalChartModifiers = {};
    if (enlargened)
      modalChartModifiers = {
        legend: true,
        height: 500,
        showXAxis: true,
        showYAxis: true,
        leftMargin: 45,
        rightMargin: 30,
      };

    const chartProps = {
      data: portfolio.valueSeriesChartData.data,
      legend: false,
      lines: ['Portfolio'],
      lineStyles: { 'Portfolio': { stroke: purple }},
      height: 225,
      percentageValues: false,
      showXAxis: false,
      showYAxis: false,
      leftMargin: 0,
      ...modalChartModifiers,
    };
    if (selectedType === 'chart') {
      return <ReactLineChart {...chartProps} />;
    }
    return <Table {...{ data: portfolio.assetMovementTableData.data, enlargened }} />;
  }

  const cardProps = {
    title: 'Value',
    subTitle: 'All Time',
    titleControls: radioToggle,
  };

  return (
    <div className="col-md-1-2">
      <ClientPortalCard {...cardProps}>
        <>
          {isLoading ? <Loading /> : <RenderData enlargened={false} />}
          <div className="mar-t-2">
            <ModalGraph title="Portfolio Value">
              <RenderData enlargened />
            </ModalGraph>
          </div>
        </>
      </ClientPortalCard>
    </div>
  );
}
