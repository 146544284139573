import * as React from 'react';

import * as ReactTooltip from 'react-tooltip';

import ReviewContext from '../ReviewContext';
import { regeneratePlatformReviewPath } from 'javascript/application/ts_routes';
import ajaxRequest from 'components/shared/utilities/ajaxRequest';
import { createAlert } from 'components/shared/Utils';

export interface IRegenerate {
  reviewId: string;
  additionalClasses?: string;
  reviewStatus: string;
  generateInvestmentRestrictionsCommentaryOnly?: boolean;
}

const Regenerate = ({
  reviewId,
  reviewStatus,
  additionalClasses,
  generateInvestmentRestrictionsCommentaryOnly,
}: IRegenerate) => {
  const allowedStages = ['awaiting_data', 'in_progress', 'in_review', 'verification'];
  if (!allowedStages.includes(reviewStatus)) return <></>;

  const reviewContext = React.useContext(ReviewContext);
  const { handleReviewUpdate } = reviewContext.actions;
  const path = regeneratePlatformReviewPath(reviewId);

  const handleRegenerate = React.useCallback(async () => {
    try {
      const requestData = {
        generate_investment_restrictions_only: generateInvestmentRestrictionsCommentaryOnly,
      };

      createAlert('warning', 'Regeneration in progress', 2000);

      await ajaxRequest({
        method: 'POST',
        path,
        params: requestData,
      });

      if (generateInvestmentRestrictionsCommentaryOnly) {
        createAlert('success', 'Successfully regenerated investment restrictions commentary', 2000);
      } else {
        createAlert('success', 'Successfully regenerated action points and commentary', 2000);
      }
      handleReviewUpdate();
    } catch (_) {
      createAlert('error', 'Something went wrong', 5000);
    }
  }, [reviewId]);

  return (
    <>
      <div
        className={`button button--icon mar-r-1 ${additionalClasses}`}
        data-tip={
          generateInvestmentRestrictionsCommentaryOnly
            ? 'Regenerate investment restrictions commentary'
            : 'Regenerate commentary and action points'
        }
        onClick={handleRegenerate}
        data-for="review_controls"
      >
        <i className="icon-refresh icon-fw" />
      </div>
      <ReactTooltip effect="solid" id="review_controls" />
    </>
  );
};

export default Regenerate;
