import * as React from 'react';

import { format, parse } from 'date-fns';
const numeral = require('numeral');

export default function columns() {
  return [
    {
      Cell: (row) => {
        return <div>{format(parse(row.original.date), 'DD MMM YYYY')}</div>;
      },
      Header: 'Date',
      accessor: 'date',
      id: 'date',
    },
    {
      Cell: (row) => {
        return <div>{numeral(row.original.value).format('0,0.00')}</div>;
      },
      Header: 'Value',
      accessor: 'value',
      id: 'value',
    },
    {
      Cell: (row) => {
        return <div>{numeral(row.original.value_in).format('0,0.00')}</div>;
      },
      Header: 'Value In',
      accessor: 'value_in',
      id: 'value_in',
    },
    {
      Cell: (row) => {
        return <div>{numeral(Math.abs(row.original.value_out)).format('0,0.00')}</div>;
      },
      Header: 'Value Out',
      accessor: 'value_out',
      id: 'value_out',
    },
    {
      Cell: (row) => {
        return <div>{numeral(row.original.month_return).format('0,0.00')}%</div>;
      },
      Header: 'Return',
      accessor: 'month_return',
      id: 'month_return',
    },
    {
      Cell: (row) => {
        return <div>{numeral(row.original.overall).format('0,0.00')}%</div>;
      },
      Header: 'Overall',
      accessor: 'overall',
      id: 'overall',
    },
  ];
}
