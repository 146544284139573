import * as React from 'react';

import GraphitiTableWrapper from 'components/shared/GraphitiTableWrapper';

import columns from './table/columns';

import { MarketCommentary } from 'javascript/models';

interface IProps {
  hasHeader?: boolean;
  isExportable: boolean;
  newPath?: string;
  scope?: { [key: string]: string | { [key: string]: string } };
  title: string;
}

export default function graphitiTable(props: IProps) {
  const { isExportable, newPath, title } = props;

  let scope = MarketCommentary.includes([]);

  if (props.scope) {
    scope = scope.where(props.scope);
  }

  return (
    <GraphitiTableWrapper
      scope={scope}
      columns={columns()}
      hasHeader
      isExportable={isExportable}
      newPath={newPath}
      newText="Add Market Commentary"
      title={title}
      identifier="marketCommentaries:index"
    />
  );
}

graphitiTable.defaultProps = {
  hasHeader: true,
};
