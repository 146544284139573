import { Attr, HasMany, Model, BelongsTo } from 'spraypaint';

import { Client } from '../Client';
import { User } from '../User';
import { ClientUserPermission } from '../permissions/ClientUser';

import { Role } from '../Role';

import generateRecordWithPermission from '../permissions/RecordWithPermissionFactory';

class RoleBase extends Role<Client> {}

@Model()
export class ClientUserRole extends generateRecordWithPermission(ClientUserPermission)(RoleBase) {
  public static jsonapiType = 'roles/client_users';

  @Attr() public id: string;
  @Attr() public name: string;
  @Attr() public lockedForEditing: boolean;

  @BelongsTo(Client) public client: Client;
  @HasMany(User) public users: User[];
  @HasMany(ClientUserPermission) public permissions: ClientUserPermission[];
}
