import * as React from 'react';
import lodashStartcase from 'lodash.startcase';

import { DirectUpload } from 'activestorage';
import { platformTableDataModifierIndexPath } from 'javascript/application/ts_routes';

import CsrfToken from 'components/shared/CsrfToken';
import { createAlert } from 'components/shared/Utils';
import Presenter from './tableDataModifier/Presenter';

interface IProps {
  tableList: string[];
}

const tableDataModifierImport = (props: IProps) => {
  const [responseData, setResponseData] = React.useState({
    bulk_portfolio_import_id: null,
    noData: true,
    portfolios: [],
  });

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [selectedTable, setSelectedTable] = React.useState();
  const [selectedAction, setSelectedAction] = React.useState();

  const { tableList } = props;

  const tableListOptions = tableList.map((table) => ({ value: table, label: table }));

  const options = {
    method: 'POST',
  };

  const handleActionSelect = (action) => {
    setSelectedAction(action.value);
  };

  const handleTableSelect = (table) => {
    setSelectedTable(table.value);
  };

  const handleFileUpload = (files) => {
    if (files.length < 1) return;

    const upload = new DirectUpload(files[0], '/rails/active_storage/direct_uploads');

    upload.create((error, blob) => {
      if (error) {
        // Dump out
      } else {
        const signedId = blob.signed_id;
        createCsvImport(signedId);
      }
    });
  };

  function createCsvImport(signedId) {
    const options = {
      method: 'POST',
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
      body: JSON.stringify({
        authenticity_token: CsrfToken(),
        table_data_modifier: { import: signedId },
        table: selectedTable,
        table_action: selectedAction,
        dataType: 'json',
      }),
    };

    fetch(platformTableDataModifierIndexPath(), options)
      .then((response) => response.json())
      .then((data) => setResponseData(data))
      .catch((error) => createAlert('error', error, false));
  }

  return (
    <Presenter
      {...{ handleFileUpload, handleTableSelect, handleActionSelect, isSubmitting, responseData, tableListOptions }}
    />
  );
};

export default tableDataModifierImport;
