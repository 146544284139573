import * as React from 'react';

import { daysAgoInWords } from 'components/shared/Utils';
import { sanitize } from 'dompurify';

export default function notification({ toggleReadStatus, notification }) {
  const { body, id, notifyOn, viewed } = notification;

  function handleReadToggle() {
    toggleReadStatus(id);
  }

  function handleNotificationClick(event) {
    event.preventDefault();
    const linkPath = event.target.href;
    if (linkPath) {
      if (!viewed) toggleReadStatus(id);

      window.location = linkPath;
    }
  }

  const styles = {
    background: viewed ? '#3B4050' : '#4eacd0',
    borderRadius: '50%',
    cursor: 'pointer',
    height: '10px',
    width: '10px',
  };

  return (
    <div className="dropdown-nav-item dropdown-nav-item--bordered">
      <div className="frow">
        <div className="col-mc-1-12">
          <div style={styles} onClick={handleReadToggle} />
        </div>
        <div className="col-mc-11-12">
          <div className="frow frow--direction-column frow--gutters">
            <div
              dangerouslySetInnerHTML={{ __html: sanitize(body) }}
              className="text-white"
              onClick={handleNotificationClick}
            />
            <div className="subtitle-two text-grey mar-v-0">{daysAgoInWords(notifyOn)}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
