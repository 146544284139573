import React from 'react';
import { midPurple, white } from 'components/shared/colours';
import { portalMarketResourcesPath, portalRootPath } from 'javascript/application/ts_routes';

const navItem = () => {
  const currentUrl = window.location.pathname;
  const urlSegment = currentUrl.split('/')[2] || 'none';

  const routeObj = {
    clients: 'dashboard',
    market_resources: 'resources',
    none: 'dashboard',
  };

  const underlineStyles = {
    borderBottom: `2px solid ${midPurple}`,
    color: white,
  };

  return (
    <div className="platform-header__nav-wrap">
      <a
        className="platform-header__nav-item"
        href={portalRootPath()}
        style={routeObj[urlSegment] === 'dashboard' ? underlineStyles : null}
      >
        Dashboard
      </a>
      <a
        className="platform-header__nav-item"
        href={portalMarketResourcesPath()}
        style={routeObj[urlSegment] === 'resources' ? underlineStyles : null}
      >
        Resources
      </a>
    </div>
  );
};

export default navItem;
