import * as React from 'react';

import ReactSelect from 'components/shared/forms/ReactSelect';
import FormRow from 'components/shared/forms/FormRow';
import { Portfolio, Client } from 'javascript/models';

export interface IChildPortfolioSelect {
  isOpen: boolean;
  client: Client;
  portfolio: Portfolio;
}

const ChildPortfolioSelect = ({ isOpen, client, portfolio }: IChildPortfolioSelect) => {
  const [options, setOptions] = React.useState([]);

  const mapChildrenToSelect = (childPortfolio: Portfolio) => ({
    label: childPortfolio.reference,
    value: childPortfolio.id,
  });

  const getPortfolios = async () => {
    const { data } = await Portfolio.where({
      status: ['live', 'component_part', 'loan'],
      client_id: [client.id, ...client.childClientIds],
    })
      .select(['id', 'reference'])
      .per(-1)
      .all();

    const portfolioOptions = data.map(mapChildrenToSelect);
    setOptions(portfolioOptions);
  };

  React.useEffect(() => {
    if (portfolio && client) getPortfolios();
  }, [portfolio, client]);

  if (!isOpen) return <></>;

  const select = (
    <ReactSelect
      theme="dark"
      name="portfolio[child_portfolio_ids][]"
      id="child_portfolios"
      isMulti
      options={options}
      defaultValues={portfolio.childPortfolios.map(mapChildrenToSelect)}
    />
  );

  const noClient = (
    <div>
      <p className="helper-text text-white">A client must be selected before you can assign constituent portfolios</p>
    </div>
  );

  const noOptions = (
    <div>
      <p className="helper-text text-white">There are no component part portfolios for this client</p>
    </div>
  );

  return (
    <FormRow note="" className="col-mc-1-1">
      <label htmlFor="child_portfolios" className="form__label">
        Child Portfolios
        <span>You can only select portfolios marked as component parts that belong to this client</span>
      </label>
      {!client ? noClient : options ? select : noOptions}
    </FormRow>
  );
};

export default ChildPortfolioSelect;
