import * as React from 'react';

import ReactSelect from 'components/shared/forms/ReactSelect';

export default function filename({ currentFilename, extraSettingOptions = [] }) {
  const [inputValue, setInputValue] = React.useState(currentFilename);
  const [cursorPosition, setCursorPosition] = React.useState();

  function handleSelect(selectedOption) {
    const textToInsert = selectedOption.value;
    const textBefore = inputValue.substring(0, cursorPosition);
    const textAfter = inputValue.substring(cursorPosition, inputValue.length);
    const newInputValue = `${textBefore}{{ ${textToInsert} }}${textAfter}`;
    setInputValue(newInputValue);
  }

  function handleInputChange(event) {
    setCursorPosition(event.target.selectionStart);
    setInputValue(event.target.value);
  }

  function handleCursorChange(event) {
    setCursorPosition(event.target.selectionStart);
  }

  const selectableOptions = [
    'Client Name',
    'Enhance Reference',
    'IM Reference',
    'Date',
    'Report Name',
    'Entity Name',
    'Entity Alias',
    'Investment Manager',
    'Primary Contact',
    'Review Period',
    'Review Rating',
    'Date and Time Generated',
    'Review Period Date',
    'Portfolio Admin Reference',
    ...extraSettingOptions,
  ];

  const selectOptions = selectableOptions.filter(Boolean).map((option) => {
    return { value: option, label: option };
  });

  return (
    <React.Fragment>
      <label className="form__label">Review Filename</label>
      <div className="frow frow--gutters frow--items-center">
        <div className="col-md-2-5">
          <div className="frow frow--gutters frow--items-center mar-b-2">
            <span className="text-white text-small">Custom attributes:</span>
            <div className="col-flex-grow-1">
              <div className="form__select-wrapper">
                <ReactSelect
                  id="custom_attribute"
                  name="client_custom_attribute"
                  handleChange={handleSelect}
                  options={selectOptions}
                  theme="dark"
                  placeholder="Please select"
                />
              </div>
            </div>
          </div>
          <textarea
            name="client[review_filename_format]"
            value={inputValue}
            onChange={handleInputChange}
            onClick={handleCursorChange}
            onKeyDown={handleCursorChange}
          />
        </div>
        <div className="col-md-3-5">
          <p className="text-white text-small mar-v-0">
            This will be the filename format of any reviews generated for this client.
          </p>
        </div>
      </div>
    </React.Fragment>
  );
}
