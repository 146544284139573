import * as React from 'react';
import { DirectUpload, Blob as ASBlob } from 'activestorage';
import { FileWithPath } from 'react-dropzone';
import { toFileWithPath } from 'file-selector/dist/file';
import * as _ from 'lodash';
import ReactTooltip from 'react-tooltip';
import { createAlert } from 'components/shared/Utils';
import Frow from '../../frow/Frow';
import Dropzone, { IDropzoneProps } from './Dropzone';

export interface IDropzoneFile extends FileWithPath {
  blob: ASBlob;
}

export interface IFormFilesDropzoneProps {
  children?: JSX.Element;
  dropzoneProps?: IDropzoneProps;
  disableBottomMargin?: boolean;
  displayFiles?: boolean;
  fileDisplayWidth?: string;
  fileDisplayHeight?: string;
  inputLabel?: string;
  inputName?: string;
  updateStateOnDetach?: boolean;
  showFilePreview?: boolean;
  storedFiles?: IDropzoneFile[];
  // Second type below accounts for the function, 'handleUploadedFiles' which is passed
  // into the following file as a prop:
  // app/webpacker/components/platform/managers/uploadTemplates/upload/Presenter.tsx
  updateStoredFiles?: (files: IDropzoneFile[]) => void;
  onDetatchFile?(file?: IDropzoneFile): void;
}

const formFilesDropzone = (props: IFormFilesDropzoneProps) => {
  const {
    dropzoneProps,
    disableBottomMargin,
    displayFiles,
    fileDisplayWidth,
    fileDisplayHeight,
    inputLabel,
    inputName,
    showFilePreview,
    storedFiles,
    updateStoredFiles,
    onDetatchFile,
    updateStateOnDetach,
  } = props;

  const dzSave = async (files: FileWithPath[]) => {
    if (files.length < 1) return;

    const directUploadPath = '/rails/active_storage/direct_uploads';
    const uploadPromises = files.map(
      (file) =>
        new Promise((resolve, reject) => {
          new DirectUpload(file, directUploadPath).create((error, blob) => {
            if (error) {
              createAlert('error', 'File not uploaded', 1500);
              reject(error);
              return;
            }

            const tempFile: FileWithPath = new File([file], blob.filename);
            const filePath = `/rails/active_storage/blobs/${blob.signed_id}/${blob.filename}`;
            const newFile = toFileWithPath(tempFile, filePath) as IDropzoneFile;

            newFile.blob = blob;

            resolve(newFile);
          });
        }),
    );
    // to avoid (Promise as any) we need to update typescript
    const allPromises = await (Promise as any).allSettled(uploadPromises);
    const uploadedFiles = _.filter(allPromises, ['status', 'fulfilled']).map((promise) => promise.value);
    updateStoredFiles(uploadedFiles);
  };

  const localOnDetatchFile = (file: IDropzoneFile) => {
    if (!file) return;

    if (onDetatchFile) onDetatchFile(file);

    if (updateStateOnDetach) {
      const newFiles = _.reject(storedFiles, file);
      updateStoredFiles(newFiles);
    }
  };

  const DisplayFile = ({ file }: { file: IDropzoneFile }) => (
    <>
      <a
        className="link link--blue"
        target="_blank"
        rel="noreferrer"
        href={file.path}
        data-tip={file.name}
        data-for="file-full-name"
      >
        {_.truncate(file.name)}
      </a>
      <ReactTooltip effect="solid" id="file-full-name" />
    </>
  );

  const RenderFiles = () => {
    if (!displayFiles) return null;

    return (
      <div className="col-sm-1-2">
        <ul>
          {storedFiles.map((file, index) => (
            <li key={file.blob.signed_id}>
              <div className="frow frow--items-center frow--justify-between frow--nowrap mar-b-1">
                <DisplayFile file={file} />
                {showFilePreview && (
                  <img src={file.path} style={{ width: fileDisplayWidth, height: fileDisplayHeight }} />
                )}
                <input type="hidden" name={inputName} value={file.blob.signed_id} />
                <button onClick={() => localOnDetatchFile(file)} className="button button--danger button--icon">
                  <i className="icon-bin icon-fw" />
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <Frow>
      <div className={displayFiles ? 'col-sm-1-2' : 'col-sm-1-1'}>
        <div className="frow frow--direction-column frow--justify-around">
          <div className={disableBottomMargin ? '' : 'mar-b-10'}>
            <div className="mar-b-1">
              <label className="form__label">{inputLabel}</label>
            </div>
            <Dropzone
              onFileUpload={dzSave}
              isLarge={false}
              theme="light"
              shouldShowFiles={false}
              shouldDiscardFiles
              {...dropzoneProps}
            />
          </div>
          {props.children}
        </div>
      </div>
      <RenderFiles />
    </Frow>
  );
};
export default formFilesDropzone;

formFilesDropzone.defaultProps = {
  disableBottomMargin: false,
  displayFiles: true,
  showFilePreview: false,
};
