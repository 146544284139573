import * as React from 'react';

import ContactCreate from './contacts/ContactCreate';
import FormRow from 'components/shared/forms/FormRow';
import ReactSelect from 'components/shared/forms/ReactSelect';
import Context from '../Context';

import lodashStartcase from 'lodash.startcase';

export default function contacts() {
  const context = React.useContext(Context);

  const { canCreateClientUsers, contactTypeOptions } = context.props;
  const { client, portfolio } = context.state;
  const { primaryContactType, secondaryContactType, tertiaryContactType } = portfolio;

  let userOptions = [];
  if (client) {
    userOptions = client.users.map((user) => {
      return {
        value: user.id,
        label: `${user.fullName} (${user.email ? user.email : 'No email'})`,
        discarded: user.discarded,
      };
    });
  }

  let emapContacts = [];

  const primaryContact = userOptions.find((user) => user.value === portfolio.primaryContactId);
  const secondaryContact = userOptions.find((user) => user.value === portfolio.secondaryContactId);
  const tertiaryContact = userOptions.find((user) => user.value === portfolio.tertiaryContactId);

  if (portfolio.contactUsers) {
    emapContacts = userOptions.filter((user) => portfolio.contactUsers.emap_users.includes(user.value));
  }

  userOptions = userOptions.filter((userOption) => !userOption.discarded);

  function renderSelect(defaultValues, name, defaultValue = null, isMulti = true) {
    if (client && portfolio) {
      return (
        <ReactSelect
          theme="dark"
          name={name}
          id={name}
          isMulti={isMulti}
          isClearable
          options={userOptions}
          defaultValues={defaultValues}
          defaultValue={defaultValue}
        />
      );
    }

    return (
      <div>
        <p className="helper-text text-white">A client must be selected before you can assign contacts</p>
      </div>
    );
  }

  return (
    <fieldset className="form__fieldset">
      <div className="frow frow--items-center frow--justify-between">
        <div>
          <h4 className="form__fieldset-label">Contacts</h4>
        </div>
        <div>{canCreateClientUsers && client && portfolio && <ContactCreate />}</div>
      </div>
      <div className="frow frow--gutters">
        <FormRow note="" className="col-mc-1-1">
          <label htmlFor="primary_contact" className="form__label">
            Primary Contact
          </label>
          {renderSelect(null, 'portfolio[primary_contact_id]', primaryContact, false)}
        </FormRow>
        <FormRow note="" className="col-mc-1-1">
          <label htmlFor="portfolio_primary_contact_type" className="form__label">
            Primary Contact Type
          </label>
          <ReactSelect
            theme="dark"
            name="portfolio[primary_contact_type]"
            id="portfolio_primary_contact_type"
            options={contactTypeOptions}
            defaultValue={{ label: lodashStartcase(primaryContactType), value: primaryContactType }}
          />
        </FormRow>
        <FormRow note="" className="col-mc-1-1">
          <label htmlFor="secondary_contact" className="form__label">
            Secondary Contact
          </label>
          {renderSelect(null, 'portfolio[secondary_contact_id]', secondaryContact, false)}
        </FormRow>
        <FormRow note="" className="col-mc-1-1">
          <label htmlFor="portfolio_primary_contact_type" className="form__label">
            Secondary Contact Type
          </label>
          <ReactSelect
            theme="dark"
            name="portfolio[secondary_contact_type]"
            id="portfolio_secondary_contact_type"
            options={contactTypeOptions}
            defaultValue={{ label: lodashStartcase(secondaryContactType), value: secondaryContactType }}
          />
        </FormRow>
        <FormRow note="" className="col-mc-1-1">
          <label htmlFor="tertiary_contact" className="form__label">
            Tertiary Contact
          </label>
          {renderSelect(null, 'portfolio[tertiary_contact_id]', tertiaryContact, false)}
        </FormRow>
        <FormRow note="" className="col-mc-1-1">
          <label htmlFor="portfolio_tertiary_contact_type" className="form__label">
            Tertiary Contact Type
          </label>
          <ReactSelect
            theme="dark"
            name="portfolio[tertiary_contact_type]"
            id="portfolio_tertiary_contact_type"
            options={contactTypeOptions}
            defaultValue={{ label: lodashStartcase(tertiaryContactType), value: tertiaryContactType }}
          />
        </FormRow>
        <FormRow note="" className="col-mc-1-1">
          <label htmlFor="primary_user" className="form__label">
            EMAP Contacts
            <span>If the client is set to automatically send reviews, these are the people that will receive them</span>
          </label>
          {renderSelect(emapContacts, 'portfolio[emap_user_ids][]')}
        </FormRow>
      </div>
    </fieldset>
  );
}
