import * as React from 'react';

import concatMerge from 'javascript/helpers/concatMerge';

import { ActionPoint, Review } from 'javascript/models';

import { Column, Frow } from 'components/frow';

import CurrentUserContext from 'components/shared/context/CurrentUserContext';
import ThemeContext from 'components/shared/context/ThemeContext';

import useVisibility from 'components/shared/customHooks/useVisibility';
import useAsyncFunctionInjector, { beforeAction } from 'components/shared/customHooks/useAsyncFunctionInjector';

import Button from 'components/shared/forms/Button';

import quarterFormat from 'javascript/helpers/quarterFormat';

import ModalWithTrigger from '../../../../modals/ModalWithTrigger';

import CompletionForm from '../CompletionForm';
import DeferralForm from '../DeferralForm';

import CommonFields from './CommonFields';
import DeferralNotesList from './DeferralNotesList';

export interface IMainProps {
  actionPoint: ActionPoint;
  review: Review;
}

const Main = (props: IMainProps) => {
  const { actionPoint: actionPointProp, review } = props;

  if (!actionPointProp || !review) {
    return null;
  }

  const currentUser = React.useContext(CurrentUserContext);
  const theme = React.useContext(ThemeContext);

  const completeButtonText = actionPointProp.completed ? 'Completed' : 'Save & Complete Action Point';

  const deferralModalVisibility = useVisibility(false);
  const completeModalVisibility = useVisibility(false);

  const { handleClose: closeDeferralModal } = deferralModalVisibility;
  const { handleClose: closeCompleteModal } = completeModalVisibility;

  const save = React.useCallback(
    (action, args, result) => {
      if (action === beforeAction) {
        return [concatMerge(args[0] || {}, { with: ['completeNotes', 'deferralNotes'] })];
      } else if (result) {
        closeCompleteModal();
        closeDeferralModal();
      }
    },
    [closeCompleteModal, closeDeferralModal],
  );

  const actionPoint = useAsyncFunctionInjector(save, 'save', actionPointProp);

  const author = actionPoint.author ? actionPoint.author.fullName : currentUser.fullName;
  const firstIncluded = actionPoint.firstIncludedDate ? quarterFormat(actionPoint.firstIncludedDate) : review.endDate;

  const buttonClassName = `button--full-width button--${theme.classNameModifier}-themed`;

  const completeActionPointModalProps = {
    buttonClassName,
    buttonText: completeButtonText,
    disabled: actionPoint.completed,
    externalUseVisibility: completeModalVisibility,
    title: completeButtonText,
  };

  const deferActionPointModalProps = {
    buttonClassName,
    buttonText: 'Save & Defer Action Point',
    disabled: actionPoint.completed,
    externalUseVisibility: deferralModalVisibility,
    title: 'Save & Defer Action Point',
  };

  const DeferalNotesSection = () =>
    actionPoint.deferralNotes.length === 0 ? null : (
      <div className="mar-t-0 text-small">
        <span className="small-uppercase">Deferral Notes</span>
        <br />
        <span className="text-black">
          <DeferralNotesList actionPoint={actionPoint} />
        </span>
      </div>
    );

  const DeferredUntilSection = () =>
    !actionPoint.deferredUntil ? null : (
      <p className="mar-t-0 text-small">
        <span className="small-uppercase">Deferred Until</span>
        <br />
        <span className="text-black">{quarterFormat(actionPoint.deferredUntil)}</span>
      </p>
    );

  const CompleteButton = () => (
    <ModalWithTrigger {...completeActionPointModalProps}>
      <CompletionForm {...{ actionPoint }} />
    </ModalWithTrigger>
  );

  const unCompleteActionPoint = () => {
    actionPoint.completed = false;
    actionPoint.completedNote = null;

    actionPoint.canPerformAutomaticStateReversion(actionPoint.completeNotes[actionPoint.completeNotes.length - 1]);

    actionPoint.save();
  };

  const UncompleteButton = () => (
    <button className={`button ${buttonClassName}`} onClick={unCompleteActionPoint}>
      Uncomplete
    </button>
  );

  const CompleteOrUncompleteButton = () => (actionPoint.completed ? <UncompleteButton /> : <CompleteButton />);

  return (
    <div className={`form--${theme.classNameModifier}`}>
      <Frow gutterSize={2}>
        <Column columnSpan={2}>
          <CommonFields {...{ actionPoint }} />
        </Column>

        <Column columnSpan={1}>
          <Frow>
            <Column>
              <p className="mar-t-0 text-small">
                <span className="small-uppercase">Author</span>
                <br />
                <span className="text-black">{author}</span>
              </p>
            </Column>
            <Column>
              <p className="mar-t-0 text-small">
                <span className="small-uppercase">First Included</span>
                <br />
                <span className="text-black">{firstIncluded}</span>
              </p>
            </Column>
            <Column>
              <DeferredUntilSection />
            </Column>
            <Column>
              <DeferalNotesSection />
            </Column>
            <Column>{/* <Notes {...{ actionPoint }} /> */}</Column>
          </Frow>
        </Column>
      </Frow>

      <Frow gutterSize={1}>
        <Column>
          <Button
            buttonClasses={buttonClassName}
            style={{ minWidth: 'unset' }}
            text="Save"
            handleClick={() => {
              actionPoint.save();
            }}
          />
        </Column>

        <Column>
          <CompleteOrUncompleteButton />
        </Column>

        <Column>
          <ModalWithTrigger {...deferActionPointModalProps}>
            <DeferralForm {...{ actionPoint, review }} />
          </ModalWithTrigger>
        </Column>
      </Frow>
    </div>
  );
};

export default Main;
