import * as React from 'react';

import OutsideClickHandler from 'react-outside-click-handler';

import lodashMerge from 'lodash.merge';

import * as ReactTooltip from 'react-tooltip';
import { format, getQuarter, getYear, isAfter, parse } from 'date-fns';
import { Alert, Classes, Intent, Menu, MenuItem, Popover, Position, PopoverInteractionKind } from '@blueprintjs/core';

import Info from './Info';
import useVisibility from 'components/shared/customHooks/useVisibility';

import ActionPointModal, { IActionPointModalProps } from '../../../../../../../modals/reviews/ActionPointModal';

export default function card({
  actionPointModalProps,
  actionPointsReview,
  deferredDate,
  handleSuccessfulFormSubmission,
}) {
  const { actionPoint, include } = actionPointsReview;

  const { isOpen: isPopoverOpen, handleClose: popoverClose, handleOpen: popoverOpen } = useVisibility(false);

  const editVisibility = useVisibility(false);
  const { isOpen: isEditOpen, handleClose: handleEditClose, handleOpen: handleEditOpen } = editVisibility;

  const { isOpen: isAlertOpen, handleClose: handleAlertClose, handleOpen: handleAlertOpen } = useVisibility(false);

  const { isOpen: isInfoOpen, handleClose: handleInfoClose, handleOpen: handleInfoOpen } = useVisibility(false);

  React.useEffect(() => {
    handleEditClose();
    handleInfoClose();
  }, [actionPointsReview]);

  const canClosePopover = () => !(isEditOpen || isAlertOpen || isInfoOpen);

  const safeClosePopover = () => {
    if (canClosePopover()) popoverClose();
  };

  React.useEffect(() => {
    safeClosePopover();
  }, [isEditOpen, isAlertOpen, isInfoOpen]);

  const deferred = actionPoint.deferredUntil && isAfter(parse(actionPoint.deferredUntil), parse(deferredDate));
  let status = actionPoint.completed ? 'Complete' : 'Open';
  if (deferred) {
    status = 'Deferred';
  }

  const deferredIcon = (
    <div className="text-small" data-tip="Deferred Date" data-for={`action-point-icon-${actionPoint.id}`}>
      <i className="icon-chevron-right" style={{ marginRight: '5px' }} />
      {format(actionPoint.deferredUntil, 'DD MMM YYYY')}
    </div>
  );

  let includedIcon = 'icon-toggle-visibility-visible text-brand-blue';
  if (!include || deferred || actionPoint.completed) {
    includedIcon = 'icon-toggle-visibility-invisible';
  }

  async function handleIncludeChange() {
    actionPointsReview.include = !include;
    await actionPointsReview.save();
    handleSuccessfulFormSubmission();
    popoverClose();
  }

  const includeMenu = (
    <MenuItem text={include ? 'Do not include' : 'Include'} intent={Intent.PRIMARY} onClick={handleIncludeChange} />
  );

  function handleDelete() {
    handleAlertOpen();
  }

  async function confirmDelete() {
    await actionPoint.destroy();
    handleSuccessfulFormSubmission();
  }

  const EditTrigger = ({ handleOpen }) => {
    const clickHandler = (e) => {
      e.preventDefault();
      e.stopPropagation();
      handleOpen();
    };

    return <MenuItem text="Edit" onClick={clickHandler} />;
  };

  const newEditActionPointModalProps: IActionPointModalProps = {
    actionPoint,
    addOrEdit: 'edit',
    modalWithTriggerProps: {
      externalUseVisibility: editVisibility,
      trigger: EditTrigger,
    } as any,
  };

  const editActionPointModalProps = {
    ...actionPointModalProps,
    modalWithTriggerProps: { ...actionPointModalProps.modalWithTriggerProps },
  };
  lodashMerge(editActionPointModalProps, newEditActionPointModalProps);

  const dropdown = (
    <div className="col-sm-1-12 text-right">
      <OutsideClickHandler onOutsideClick={safeClosePopover}>
        <Popover isOpen={isPopoverOpen} usePortal={false} position={Position.BOTTOM}>
          <div className="mar-r-1" onClick={popoverOpen}>
            <i className="icon-overflow-horizontal-white icon text-white" />
          </div>
          <Menu>
            <ActionPointModal {...editActionPointModalProps} />
            <MenuItem text="More Information" onClick={handleInfoOpen} />
            {!deferred && !actionPoint.completed && includeMenu}
            <MenuItem text="Delete" intent={Intent.DANGER} onClick={handleDelete} />
          </Menu>
        </Popover>
      </OutsideClickHandler>
    </div>
  );

  const firstIncluded = actionPoint.deferredUntil ? actionPoint.deferredUntil : actionPoint.firstIncludedDate;

  const deferredPrefix = () => {
    if (deferred || !actionPoint.deferredUntil) return;

    return `Deferred from Q${getQuarter(actionPoint.firstIncludedDate)} ${getYear(actionPoint.firstIncludedDate)} - `;
  };

  return (
    <div className="col-mc-1-1">
      <div className="comment">
        <div
          className="platform-content platform-content--padding-bottom platform-content--border-bottom
          platform-content--spacing-bottom"
        >
          <div className="frow frow--no-wrap">
            <div className="col-mc-11-12">
              <p className="mar-v-0 text-small" style={{ opacity: deferred || actionPoint.completed ? 0.5 : 1 }}>
                {deferredPrefix()}
                {actionPoint.body}
              </p>
            </div>
            {dropdown}
          </div>
        </div>
        <div className="platform-content" style={{ opacity: deferred || actionPoint.completed ? 0.5 : 1 }}>
          <div className="frow frow--gutters">
            <div
              className="text-small"
              data-tip={!include || deferred || actionPoint.completed ? 'Not included' : 'Included'}
              data-for={`action-point-icon-${actionPoint.id}`}
            >
              <i className={includedIcon} />
            </div>
            <div className="text-small" data-tip="Author" data-for={`action-point-icon-${actionPoint.id}`}>
              <i style={{ marginRight: '5px' }} className="icon-users" /> {actionPoint.author.fullName}
            </div>
            <div className="text-small" data-tip="Status" data-for={`action-point-icon-${actionPoint.id}`}>
              {status}
            </div>
            <div className="text-small" data-tip="Date Added" data-for={`action-point-icon-${actionPoint.id}`}>
              <i style={{ marginRight: '5px' }} className="icon-history" />
              {format(actionPoint.createdAt, 'DD MMM YYYY')}
            </div>
            {actionPoint.deferredUntil && deferredIcon}
            <div className="text-small" data-tip="First Included" data-for={`action-point-icon-${actionPoint.id}`}>
              <i style={{ marginRight: '5px' }} className="icon-add" />Q{getQuarter(firstIncluded)}{' '}
              {getYear(firstIncluded)}
            </div>
            <div className="text-small" data-tip="Comments" data-for={`action-point-icon-${actionPoint.id}`}>
              <i className="far fa-comment" style={{ marginRight: '5px' }} />
              {actionPoint.commentCount}
            </div>
            <div className="text-small" data-tip="Attachments" data-for={`action-point-icon-${actionPoint.id}`}>
              <i className="icon-document" style={{ marginRight: '5px' }} />
              {actionPoint.documentCount}
            </div>
          </div>
        </div>
      </div>
      <Info {...{ actionPoint, handleInfoClose, isInfoOpen }} />
      <Alert
        isOpen={isAlertOpen}
        onClose={handleAlertClose}
        canEscapeKeyCancel
        canOutsideClickCancel
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        intent={Intent.DANGER}
        onCancel={handleAlertClose}
        onConfirm={confirmDelete}
        portalContainer={document.body}
      >
        <p className="mar-t-0">Are you sure you want to delete this action point?</p>
      </Alert>
      <ReactTooltip effect="solid" id={`action-point-icon-${actionPoint.id}`} />
    </div>
  );
}
