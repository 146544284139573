const seriesData = [
  [0, 0, 0, 0, 0, 0, 0, 0, 0],
  [3, 1, 2, 3, 1, 4, 2, 5, 2.2],
  [5, 6, 1, 4, 2.9, 5.4, 1, 3.7, 5],
  [4, 2, 5, 5.8, 5.9, 6, 4.6, 4, 2],
  [6, 1.5, 1.5, 2, 0.7, 2.6, 3, 1.8, 3],
  [5.6, 5, 1, 2, 1, 1.5, 0.4, 4, 1],
  [7, 4, 8, 7, 7.5, 6.1, 5.5, 5.2, 4],
  [6, 7, 1, 2.5, 3.1, 3.5, 2.1, 0.9, 3],
  [7, 8.5, 3, 2.4, 2.9, 3.2, 4, 2.1, 1.5],
  [8, 5, 1.9, 1.5, 2.1, 2.8, 2.4, 3, 0.4],
  [9, 6, 3, 3.5, 2, 5.6, 5.9, 6.1, 3],
  [8.5, 7.2, 1.6, 2, 2, 3.6, 3, 5.6, 1],
  [10, 8, 4.5, 6, 3, 3.4, 2.2, 4, 1],
];

export function data(series) {
  const returnArray = [];
  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((num) => {
    const dataPointHash = { name: 'LABEL' };
    series.map((dataPoint, index) => {
      dataPointHash[dataPoint] = seriesData[num - 1][index];
    });
    returnArray.push(dataPointHash);
  });
  return returnArray;
}
