import * as React from 'react';

import Button from 'components/shared/forms/Button';
import CustomModal from 'components/shared/CustomModal';

import Context from '../../Context';
import { Checkbox } from '@blueprintjs/core';
import { Column, Frow } from 'components/frow';
import useRerender from 'components/shared/customHooks/useRerender';
import { ReportTemplateRow } from 'javascript/models';
import eventSetProperty from 'javascript/helpers/eventSetProperty';

export interface IEditProps<Row> {
  handleModalClose: any;
  isModalOpen: boolean;
  row: Row;
}

const widths = [
  { label: 'Full Width', value: 'full' },
  { label: 'Half & Half', value: 'halfs' },
  { label: 'Thirds', value: 'thirds' },
  { label: 'One third to two thirds', value: 'one_two' },
  { label: 'Two thirds to one third', value: 'two_one' },
];

const conditions = [{ label: 'Has private assets', value: 'has_private_assets' }];

const Edit = <Row extends ReportTemplateRow>({ handleModalClose, isModalOpen, row }: IEditProps<Row>) => {
  const context = React.useContext(Context);
  const { getData } = context.actions;

  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const rerender = useRerender();

  const handleSave = async () => {
    setIsSaving(true);

    try {
      const success = await row.save({ displayQueryResult: true });

      if (!success) return;

      handleModalClose();
      getData();
    } finally {
      setIsSaving(false);
    }
  };

  const widthOptions = widths.map(({ label, value }) => (
    <Column key={value}>
      <div className="pretty pretty--large pretty--with-label p-round">
        <input
          type="radio"
          name="width"
          id="width"
          value={value}
          onChange={eventSetProperty(row, 'width', false)}
          defaultChecked={value === row.width}
        />
        <div className="state p-blue-circle">
          <label>{label}</label>
        </div>
      </div>
    </Column>
  ));

  const toggleProp = (obj, prop, value) => () => ((obj[prop] = obj[prop].toggle(value)), rerender());
  const conditionOptions = conditions.map(({ label, value }) => (
    <Column key={value}>
      <Checkbox
        label={label}
        checked={row.conditions && row.conditions.includes(value)}
        onChange={toggleProp(row, 'conditions', value)}
      />
    </Column>
  ));

  return (
    <React.Fragment>
      <CustomModal isOpen={isModalOpen} handleClose={handleModalClose} title="Edit Row">
        <div className="modal__content">
          <div className="form form--light">
            <Frow gutterSize={1}>
              <Column>
                <label className="form__label">Row width</label>
                <Frow columnContext={{ columnSpan: 1 }} gutterSize={1}>
                  {widthOptions}
                </Frow>
              </Column>
              <Column>
                <label className="form__label">Row conditions</label>
                <Frow columnContext={{ columnSpan: 1 }} gutterSize={1}>
                  {conditionOptions}
                </Frow>
              </Column>
              <Column>
                <Button
                  buttonClasses="button button--light-themed"
                  handleClick={handleSave}
                  text="Save"
                  isSubmitting={isSaving}
                  submittingText="Updating Report"
                />
              </Column>
            </Frow>
          </div>
        </div>
      </CustomModal>
    </React.Fragment>
  );
};

export default Edit;
