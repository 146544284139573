import { Attr, HasMany, HasOne, Model } from 'spraypaint';
import { ApplicationRecord } from './ApplicationRecord';
import { ReportTemplateRow } from './ReportTemplateRow';
import { ReportTemplateTheme } from './ReportTemplateTheme';

@Model()
export class ReportTemplateContent extends ApplicationRecord {
  public static jsonapiType = 'report_template_contents';
  @Attr() public name: string;
  @Attr() public position: string;
  @Attr() public reportTemplateId: string;
  @Attr() public reportTemplatesReportTemplateSectionId: string;
}

@Model()
export class ReportTemplate extends ApplicationRecord {
  public static jsonapiType = 'report_templates';
  @Attr() public consolidated: boolean;
  @Attr() public displayName: string;
  @Attr() public reportType: string;
  @Attr() public name: string;
  @Attr() public orientation: string;
  @Attr() public pageColors: string[];
  @Attr() public reportTemplateBucketColor: string;
  @Attr() public reportTemplateBucketId: string;
  @Attr() public status: string;
  @Attr() public theme: string;
  @Attr() public component: boolean;
  @HasMany() public reportTemplateContents: ReportTemplateContent[];
  @HasMany() public reportTemplateRows: ReportTemplateRow[];
  @HasOne() public reportTemplateTheme: ReportTemplateTheme;
}
