import * as React from 'react';
import * as $ from 'jquery';

import CsrfToken from 'components/shared/CsrfToken';
import { createAlert } from 'components/shared/Utils';

import { platformPortfolioPortfolioReturnsPath } from 'javascript/application/ts_routes';

export default function regenerateReturns({ id }) {
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  function handleRegenerate() {
    $.ajax({
      beforeSend: () => setIsSubmitting(true),
      data: { authenticity_token: CsrfToken() },
      dataType: 'json',
      error: (jqXhr) => {
        createAlert('error', 'There was a problem recreating returns for this portfolio', 5000);
        setIsSubmitting(false);
      },
      success: () => {
        createAlert('success', 'Returns successfully regenerated', 1500);
        const customEvent = new CustomEvent('portfolio:returns:update');
        document.dispatchEvent(customEvent);
        setIsSubmitting(false);
      },
      type: 'PATCH',
      url: platformPortfolioPortfolioReturnsPath(id),
    });
  }

  return (
    <React.Fragment>
      <div
        className="button button--secondary button--icon mar-r-1"
        data-tip="Regenerate returns"
        onClick={handleRegenerate}
        data-for="portfolio_controls"
      >
        <i className="icon-refresh icon-fw" />
      </div>
    </React.Fragment>
  );
}
