import * as React from 'react';

const numeral = require('numeral');

import { Bar, BarChart, CartesianGrid, Legend, ReferenceLine, XAxis, YAxis } from 'recharts';

export const CustomLegend = (props) => {
  const { payload } = props;

  const legends = payload.map((payloadItem, index) => {
    const lineStyles = {
      background: payloadItem.payload.fill,
      borderRadius: '50%',
      display: 'inline-block',
      height: '10px',
      marginBottom: '-2px',
      width: '10px',
    };

    return (
      <span key={index} className="">
        <span style={lineStyles} className="mar-r-1" />
        <span className="mar-r-2" style={{ fontSize: props.print ? '1.26rem' : '0.7rem', whiteSpace: 'nowrap' }}>
          {payloadItem.dataKey}
        </span>
      </span>
    );
  });

  return (
    <div
      style={{ border: '1px solid #e4e4e8', padding: props.print ? '3px 0 9px' : '0 0 5px', textAlign: 'center' }}
      className="mar-t-2"
    >
      {legends}
    </div>
  );
};

const CustomHorizontalLegend = (props) => {
  const { payload } = props;

  const legends = payload.map((payloadItem, index) => {
    const lineStyles = {
      background: payloadItem.payload.fill,
      borderColor: payloadItem.payload.fill,
      borderRadius: '50%',
      borderStyle: 'solid',
      display: 'inline-block',
      height: '10px',
      marginBottom: '-2px',
      width: '10px',
    };

    if (payloadItem.payload.strokeDasharray) {
      lineStyles.borderStyle = 'dashed';
    }

    return (
      <div key={index} style={{ whiteSpace: 'nowrap' }}>
        <span style={lineStyles} className="mar-r-1" />
        <span className="mar-r-2" style={{ fontSize: props.print ? '1.26rem' : '0.7rem' }}>
          {payloadItem.dataKey}
        </span>
      </div>
    );
  });

  return (
    <div className="mar-l-4" style={{ border: '1px solid #e4e4e8', padding: props.print ? '3px 5px 9px' : '0 0 5px' }}>
      {legends}
    </div>
  );
};

interface IProps {
  barSize: number;
  benchmarkTypes: any;
  format: string;
  templateSection: any;
  theme: any;
  width: number;
  height: number;
  interval: number;
  pticks: number[];
  verticalLegend: boolean;
}

import { chartColorsV2 } from './chartColors';

export default function betaComparison(props: IProps) {
  const {
    barSize,
    benchmarkTypes,
    format,
    templateSection,
    theme,
    width,
    height,
    interval,
    pticks,
    verticalLegend,
  } = props;

  const chartColors = chartColorsV2();

  const bars = templateSection.series;

  const numbers = [];
  const data = templateSection.data.map((dataPoint) => {
    const dataObject = { name: dataPoint.name };
    templateSection.series.forEach((seriesName) => {
      numbers.push(dataPoint[seriesName]);
      dataObject[seriesName] = parseFloat(dataPoint[seriesName]).toFixed(2);
    });
    return dataObject;
  });

  const maxData = Math.max(...numbers) + 1;
  const minData = Math.min(...numbers) - 1;
  const tickPoint = Math.floor((maxData - minData) / 5);

  let ticks = [
    Math.ceil(minData),
    Math.ceil(minData + tickPoint),
    Math.ceil(minData + tickPoint * 2),
    Math.ceil(minData + tickPoint * 3),
    Math.ceil(minData + tickPoint * 4),
    Math.ceil(minData + tickPoint * 5),
    '',
  ];

  if (pticks) {
    ticks = pticks;
  }

  const tickFormatter = (tick) => numeral(tick).format(format);

  function renderLegend() {
    if (verticalLegend) {
      return (
        <Legend
          content={<CustomHorizontalLegend print={print} height={height} />}
          iconType="line"
          layout="vertical"
          verticalAlign="top"
          align="right"
        />
      );
    }
    return <Legend content={<CustomLegend print={print} />} iconType="line" wrapperStyle={{ bottom: '-20px' }} />;
  }

  const chartBars = bars.map((dataPoint, index) => {
    return (
      <Bar
        key={dataPoint}
        isAnimationActive={false}
        barSize={barSize}
        dataKey={dataPoint}
        fill={chartColors(dataPoint, theme, benchmarkTypes, index)}
      />
    );
  });

  return (
    <BarChart width={width} height={height} data={data} margin={{ top: 5, right: 15, left: 0, bottom: 0 }}>
      <CartesianGrid stroke="#e4e4e8" vertical={false} />
      <XAxis
        dataKey="name"
        interval={interval}
        tick={{ fill: '#b5b6bd', fontSize: '12px', fontWeight: 'bold' }}
        tickSize={0}
        tickMargin={8}
      />
      <YAxis
        interval={0}
        tick={{ fill: '#b5b6bd', fontSize: '12px', fontWeight: 'bold' }}
        ticks={ticks}
        tickSize={0}
        tickMargin={5}
        tickFormatter={tickFormatter}
        domain={['dataMin', 'dataMax']}
        type="number"
        unit=""
      />
      <ReferenceLine y={0} stroke="#000" />
      {chartBars}
      {renderLegend()}
    </BarChart>
  );
}

betaComparison.defaultProps = {
  barSize: 30,
  format: '0,0',
  height: 270,
  interval: 0,
  verticalLegend: false,
};
