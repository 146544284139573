import * as React from 'react';

export default function correlationMatrix({ templateSection }) {
  const defaultColumns = ['Portfolio', 'Benchmark 1', 'Benchmark 2', 'Cash', 'Equity'];

  const defaultRows = ['Portfolio', 'Benchmark 1', 'Benchmark 2', 'Cash', 'Equity'];

  const correlationDetailsPresent = templateSection && templateSection.correlationDetails;
  let columns = defaultColumns;
  let rows = defaultRows;
  if (correlationDetailsPresent) {
    columns = templateSection.correlationDetails.series;
    rows = templateSection.correlationDetails.series;
    if (templateSection.correlationSeries) {
      columns = templateSection.correlationSeries;
      rows = templateSection.correlationSeries;
    }
  }

  const tableHeaders = ['', ...columns].map((column, index) => {
    return (
      <th style={{ verticalAlign: 'top' }} key={`${column}-${index}`}>
        {column}
      </th>
    );
  });

  const tableRows = rows.map((row, index) => {
    const tableData = ['', ...columns].map((column, innerIndex) => {
      if (innerIndex === 0) {
        return (
          <td key={`${row}-table-data-${innerIndex}`} style={{ width: `${100 / (columns.length + 1)}%` }}>
            <span className="pdf-table__row-label">{row}</span>
          </td>
        );
      }

      return (
        <td key={`${row}-table-data-${innerIndex}`} style={{ width: `${100 / (columns.length + 1)}%` }}>
          <span>{row === column ? <span>1.00</span> : index >= innerIndex ? '' : (Math.random() * 10).toFixed(2)}</span>
        </td>
      );
    });

    return <tr key={`${row}-table-row-${index}`}>{tableData}</tr>;
  });

  return (
    <table className="pdf-table pdf-table--striped">
      <thead>
        <tr>{tableHeaders}</tr>
      </thead>
      <tbody>{tableRows}</tbody>
    </table>
  );
}
