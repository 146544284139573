import * as React from 'react';

import { PersistedSpraypaintRecord } from 'spraypaint';

import columns from '../../portfolios/table/columns';

import GraphitiTableWrapper from 'components/shared/GraphitiTableWrapper';
import { IRevisedColumn } from 'components/shared/reactTable/utilities/processColumns';

import { Portfolio } from 'javascript/models';

export default function table({ portfolioBenchmarkId, clientTypes, currencies, statuses, riskOptions }) {
  const scope = Portfolio.select([
    'reference',
    'client_type',
    'client_type_icon',
    'risk',
    'currency',
    'status',
    'show_path',
  ])
    .where({ portfolio_benchmark_id: { eq: portfolioBenchmarkId } })
    .includes(['client', 'entity', 'manager'])
    .select({ client: ['name', 'id'], entity: ['name', 'id'], manager: ['name', 'id'] });

  const newColumns = columns(currencies, clientTypes, riskOptions, statuses) as IRevisedColumn<
    PersistedSpraypaintRecord<Portfolio>
  >[];

  return (
    <GraphitiTableWrapper
      scope={scope}
      columns={newColumns}
      isSearchable
      hasHeader
      title="Linked Portfolios"
      identifier="benchmarks:portfolios:index"
    />
  );
}
