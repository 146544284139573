import * as React from 'react';

export interface IAlertProps {
  additionalClasses?: string[];
  isCloseable?: boolean;
  message: string;
  modifier?: string;
  title: string;
  type: 'info' | 'success' | 'error' | 'warning';
}

export default function alert(props: IAlertProps) {
  const { additionalClasses = [], isCloseable, message, modifier, title, type } = props;

  const [isHidden, setIsHidden] = React.useState(false);

  React.useEffect(() => {
    setIsHidden(false);
  }, [message]);

  function handleClose(event) {
    event.stopPropagation();
    setIsHidden(true);
  }

  function renderClose() {
    if (isCloseable) {
      return (
        <div className="alert__close" onClick={handleClose}>
          <i className="icon-close icon-fw icon-0-8x icon-clickable" />
        </div>
      );
    }
  }

  if (!message) {
    return null;
  }

  return (
    <div
      className={`alert alert--${modifier} alert--${type} ${isHidden ? 'hidden' : ''} ${additionalClasses.join(' ')}`}
    >
      <p className="alert__title">{title}</p>
      <p className="alert__message">{message}</p>
      {renderClose()}
    </div>
  );
}
