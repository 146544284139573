/* tslint:disable */
/* This is generated by ts_routes-rails */

export type ScalarType = string | number | boolean;

function $buildOptions(options: any, names: string[]): string {
  if (options) {
    let anchor = '';
    const q = [] as string[];
    for (const key of Object.keys(options)) {
      if (names.indexOf(key) !== -1) {
        // the key is already consumed
        continue;
      }

      const value = options[key];

      if (key === 'anchor') {
        anchor = `#${$encode(value)}`;
      } else {
        $buildQuery(q, key, value);
      }
    }
    return (q.length > 0 ? '?' + q.join('&') : '') + anchor;
  } else {
    return '';
  }
}

function $buildQuery(q: string[], key: string, value: any) {
  if ($isScalarType(value)) {
    q.push(`${$encode(key)}=${$encode(value)}`);
  } else if (Array.isArray(value)) {
    for (const v of value) {
      $buildQuery(q, `${key}[]`, v);
    }
  } else if (value != null) {
    // i.e. non-null, non-scalar, non-array type
    for (const k of Object.keys(value)) {
      $buildQuery(q, `${key}[${k}]`, value[k]);
    }
  }
}

function $encode(value: any): string {
  return encodeURIComponent(value);
}

function $isScalarType(value: any): value is ScalarType {
  return typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean';
}

function $isPresent(value: any): boolean {
  return value != null && ('' + value).length > 0;
}

function $hasPresentOwnProperty(options: any, key: string): boolean {
  return options && options.hasOwnProperty(key) && $isPresent(options[key]);
}

/** /rails/info/properties(.:format) */
export function railsInfoPropertiesPath(options?: object): string {
  return (
    '/' +
    'rails' +
    '/' +
    'info' +
    '/' +
    'properties' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /rails/info/routes(.:format) */
export function railsInfoRoutesPath(options?: object): string {
  return (
    '/' +
    'rails' +
    '/' +
    'info' +
    '/' +
    'routes' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /rails/info(.:format) */
export function railsInfoPath(options?: object): string {
  return (
    '/' +
    'rails' +
    '/' +
    'info' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /rails/mailers(.:format) */
export function railsMailersPath(options?: object): string {
  return (
    '/' +
    'rails' +
    '/' +
    'mailers' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** / */
export function rootPath(options?: object): string {
  return '/' + $buildOptions(options, []);
}

/** /styles(.:format) */
export function stylesPath(options?: object): string {
  return (
    '/' +
    'styles' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /data_provision_guidelines(.:format) */
export function dataProvisionGuidelinesPath(options?: object): string {
  return (
    '/' +
    'data_provision_guidelines' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /delivery_options/api(.:format) */
export function deliveryOptionsApiPath(options?: object): string {
  return (
    '/' +
    'delivery_options' +
    '/' +
    'api' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /delivery_options/data_mapping(.:format) */
export function deliveryOptionsDataMappingPath(options?: object): string {
  return (
    '/' +
    'delivery_options' +
    '/' +
    'data_mapping' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /delivery_options/portal_upload(.:format) */
export function deliveryOptionsPortalUploadPath(options?: object): string {
  return (
    '/' +
    'delivery_options' +
    '/' +
    'portal_upload' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /delivery_options/sftp_delivery(.:format) */
export function deliveryOptionsSftpDeliveryPath(options?: object): string {
  return (
    '/' +
    'delivery_options' +
    '/' +
    'sftp_delivery' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /delivery_options/portal_download(.:format) */
export function deliveryOptionsPortalDownloadPath(options?: object): string {
  return (
    '/' +
    'delivery_options' +
    '/' +
    'portal_download' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /delivery_options/example_file(.:format) */
export function deliveryOptionsExampleFilePath(options?: object): string {
  return (
    '/' +
    'delivery_options' +
    '/' +
    'example_file' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /administrators/sign_in(.:format) */
export function newAdministratorSessionPath(options?: object): string {
  return (
    '/' +
    'administrators' +
    '/' +
    'sign_in' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /administrators/sign_in(.:format) */
export function administratorSessionPath(options?: object): string {
  return (
    '/' +
    'administrators' +
    '/' +
    'sign_in' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /administrators/sign_out(.:format) */
export function destroyAdministratorSessionPath(options?: object): string {
  return (
    '/' +
    'administrators' +
    '/' +
    'sign_out' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /administrators/password/new(.:format) */
export function newAdministratorPasswordPath(options?: object): string {
  return (
    '/' +
    'administrators' +
    '/' +
    'password' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /administrators/password/edit(.:format) */
export function editAdministratorPasswordPath(options?: object): string {
  return (
    '/' +
    'administrators' +
    '/' +
    'password' +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /administrators/password(.:format) */
export function administratorPasswordPath(options?: object): string {
  return (
    '/' +
    'administrators' +
    '/' +
    'password' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /administrators/unlock/new(.:format) */
export function newAdministratorUnlockPath(options?: object): string {
  return (
    '/' +
    'administrators' +
    '/' +
    'unlock' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /administrators/unlock(.:format) */
export function administratorUnlockPath(options?: object): string {
  return (
    '/' +
    'administrators' +
    '/' +
    'unlock' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /administrators/invitation/accept(.:format) */
export function acceptAdministratorInvitationPath(options?: object): string {
  return (
    '/' +
    'administrators' +
    '/' +
    'invitation' +
    '/' +
    'accept' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /administrators/invitation/remove(.:format) */
export function removeAdministratorInvitationPath(options?: object): string {
  return (
    '/' +
    'administrators' +
    '/' +
    'invitation' +
    '/' +
    'remove' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /administrators/invitation/new(.:format) */
export function newAdministratorInvitationPath(options?: object): string {
  return (
    '/' +
    'administrators' +
    '/' +
    'invitation' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /administrators/invitation(.:format) */
export function administratorInvitationPath(options?: object): string {
  return (
    '/' +
    'administrators' +
    '/' +
    'invitation' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /auth/xero_oauth2/callback(.:format) */
export function authXeroOauth2CallbackPath(options?: object): string {
  return (
    '/' +
    'auth' +
    '/' +
    'xero_oauth2' +
    '/' +
    'callback' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /auth/azure_oauth2/callback(.:format) */
export function authAzureOauth2CallbackPath(options?: object): string {
  return (
    '/' +
    'auth' +
    '/' +
    'azure_oauth2' +
    '/' +
    'callback' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /rails/active_storage/direct_uploads(.:format) */
export function directUploadsPath(options?: object): string {
  return (
    '/' +
    'rails' +
    '/' +
    'active_storage' +
    '/' +
    'direct_uploads' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/action_points(.:format) */
export function apiV1ActionPointsPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'action_points' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/action_points/:id(.:format) */
export function apiV1ActionPointPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'action_points' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/administrators(.:format) */
export function apiV1AdministratorsPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'administrators' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/administrators/:id(.:format) */
export function apiV1AdministratorPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'administrators' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/clusters(.:format) */
export function apiV1ClustersPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'clusters' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/cluster_mappings(.:format) */
export function apiV1ClusterMappingsPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'cluster_mappings' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/holdings(.:format) */
export function apiV1HoldingsPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'holdings' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/portfolios(.:format) */
export function apiV1PortfoliosPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'portfolios' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/portfolios/:id(.:format) */
export function apiV1PortfolioPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'portfolios' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/securities/states(.:format) */
export function apiV1SecuritiesStatesPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'securities' +
    '/' +
    'states' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/securities/states/:id(.:format) */
export function apiV1SecuritiesStatePath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'securities' +
    '/' +
    'states' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/securities/classifications(.:format) */
export function apiV1SecuritiesClassificationsPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'securities' +
    '/' +
    'classifications' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/securities/classifications/:id(.:format) */
export function apiV1SecuritiesClassificationPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'securities' +
    '/' +
    'classifications' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/securities/state_classification_weightings(.:format) */
export function apiV1SecuritiesStateClassificationWeightingsPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'securities' +
    '/' +
    'state_classification_weightings' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/securities/state_classification_weightings/:id(.:format) */
export function apiV1SecuritiesStateClassificationWeightingPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'securities' +
    '/' +
    'state_classification_weightings' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/securities(.:format) */
export function apiV1SecuritiesPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'securities' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/securities/:id(.:format) */
export function apiV1SecurityPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'securities' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/users(.:format) */
export function apiV1UsersPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'users' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/users/:id(.:format) */
export function apiV1UserPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'users' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/action_points_reviews/:id(.:format) */
export function apiV1ActionPointsReviewPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'action_points_reviews' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/answers(.:format) */
export function apiV1AnswersPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'answers' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/answers/:id(.:format) */
export function apiV1AnswerPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'answers' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/asset_mappings(.:format) */
export function apiV1AssetMappingsPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'asset_mappings' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/attachments/:id(.:format) */
export function apiV1AttachmentPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'attachments' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/audits(.:format) */
export function apiV1AuditsPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'audits' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/audits/:id(.:format) */
export function apiV1AuditPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'audits' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/balances(.:format) */
export function apiV1BalancesPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'balances' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/bulk_imports(.:format) */
export function apiV1BulkImportsPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'bulk_imports' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/bulk_imports/new(.:format) */
export function newApiV1BulkImportPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'bulk_imports' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/bulk_imports/:id/edit(.:format) */
export function editApiV1BulkImportPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'bulk_imports' +
    '/' +
    id +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/bulk_imports/:id(.:format) */
export function apiV1BulkImportPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'bulk_imports' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/cash_flows(.:format) */
export function apiV1CashFlowsPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'cash_flows' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/clients(.:format) */
export function apiV1ClientsPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'clients' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/clients/:id(.:format) */
export function apiV1ClientPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'clients' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/client_tiles(.:format) */
export function apiV1ClientTilesPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'client_tiles' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/client_tiles/new(.:format) */
export function newApiV1ClientTilePath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'client_tiles' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/client_tiles/:id/edit(.:format) */
export function editApiV1ClientTilePath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'client_tiles' +
    '/' +
    id +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/client_tiles/:id(.:format) */
export function apiV1ClientTilePath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'client_tiles' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/commentaries(.:format) */
export function apiV1CommentariesPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'commentaries' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/commentaries/new(.:format) */
export function newApiV1CommentaryPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'commentaries' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/commentaries/:id/edit(.:format) */
export function editApiV1CommentaryPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'commentaries' +
    '/' +
    id +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/commentaries/:id(.:format) */
export function apiV1CommentaryPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'commentaries' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/data_chasers(.:format) */
export function apiV1DataChasersPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'data_chasers' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/data_chasers/:id(.:format) */
export function apiV1DataChaserPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'data_chasers' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/data_chase_templates(.:format) */
export function apiV1DataChaseTemplatesPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'data_chase_templates' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/data_chase_templates/new(.:format) */
export function newApiV1DataChaseTemplatePath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'data_chase_templates' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/data_chase_templates/:id/edit(.:format) */
export function editApiV1DataChaseTemplatePath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'data_chase_templates' +
    '/' +
    id +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/data_chase_templates/:id(.:format) */
export function apiV1DataChaseTemplatePath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'data_chase_templates' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/data_notes(.:format) */
export function apiV1DataNotesPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'data_notes' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/data_notes/:id(.:format) */
export function apiV1DataNotePath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'data_notes' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/data_uploads(.:format) */
export function apiV1DataUploadsPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'data_uploads' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/data_uploads/:id(.:format) */
export function apiV1DataUploadPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'data_uploads' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/data_upload_errors(.:format) */
export function apiV1DataUploadErrorsPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'data_upload_errors' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/data_upload_errors/new(.:format) */
export function newApiV1DataUploadErrorPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'data_upload_errors' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/data_upload_errors/:id/edit(.:format) */
export function editApiV1DataUploadErrorPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'data_upload_errors' +
    '/' +
    id +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/data_upload_errors/:id(.:format) */
export function apiV1DataUploadErrorPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'data_upload_errors' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/entities(.:format) */
export function apiV1EntitiesPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'entities' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/entities/:id(.:format) */
export function apiV1EntityPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'entities' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/entity_groupings(.:format) */
export function apiV1EntityGroupingsPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'entity_groupings' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/entity_groupings/new(.:format) */
export function newApiV1EntityGroupingPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'entity_groupings' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/entity_groupings/:id/edit(.:format) */
export function editApiV1EntityGroupingPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'entity_groupings' +
    '/' +
    id +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/entity_groupings/:id(.:format) */
export function apiV1EntityGroupingPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'entity_groupings' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/entity_relationship_roles(.:format) */
export function apiV1EntityRelationshipRolesPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'entity_relationship_roles' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/entity_relationship_roles/new(.:format) */
export function newApiV1EntityRelationshipRolePath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'entity_relationship_roles' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/entity_relationship_roles/:id/edit(.:format) */
export function editApiV1EntityRelationshipRolePath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'entity_relationship_roles' +
    '/' +
    id +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/entity_relationship_roles/:id(.:format) */
export function apiV1EntityRelationshipRolePath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'entity_relationship_roles' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/extra_settings(.:format) */
export function apiV1ExtraSettingsPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'extra_settings' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/extra_settings_types(.:format) */
export function apiV1ExtraSettingsTypesPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'extra_settings_types' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/extracts(.:format) */
export function apiV1ExtractsPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'extracts' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/extracts/new(.:format) */
export function newApiV1ExtractPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'extracts' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/extracts/:id/edit(.:format) */
export function editApiV1ExtractPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'extracts' +
    '/' +
    id +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/extracts/:id(.:format) */
export function apiV1ExtractPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'extracts' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/flags(.:format) */
export function apiV1FlagsPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'flags' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/investment_risk_assessments(.:format) */
export function apiV1InvestmentRiskAssessmentsPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'investment_risk_assessments' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/investment_risk_assessments/new(.:format) */
export function newApiV1InvestmentRiskAssessmentPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'investment_risk_assessments' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/investment_risk_assessments/:id/edit(.:format) */
export function editApiV1InvestmentRiskAssessmentPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'investment_risk_assessments' +
    '/' +
    id +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/investment_risk_assessments/:id(.:format) */
export function apiV1InvestmentRiskAssessmentPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'investment_risk_assessments' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/managers(.:format) */
export function apiV1ManagersPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'managers' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/managers/:id(.:format) */
export function apiV1ManagerPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'managers' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/manager_users(.:format) */
export function apiV1ManagerUsersPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'manager_users' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/mandates/:id(.:format) */
export function apiV1MandatePath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'mandates' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/market_commentaries(.:format) */
export function apiV1MarketCommentariesPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'market_commentaries' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/notes(.:format) */
export function apiV1NotesPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'notes' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/notes/new(.:format) */
export function newApiV1NotePath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'notes' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/notes/:id/edit(.:format) */
export function editApiV1NotePath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'notes' +
    '/' +
    id +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/notes/:id(.:format) */
export function apiV1NotePath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'notes' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/notes_notables(.:format) */
export function apiV1NotesNotablesPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'notes_notables' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/notes_notables/new(.:format) */
export function newApiV1NotesNotablePath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'notes_notables' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/notes_notables/:id/edit(.:format) */
export function editApiV1NotesNotablePath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'notes_notables' +
    '/' +
    id +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/notes_notables/:id(.:format) */
export function apiV1NotesNotablePath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'notes_notables' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/notifications(.:format) */
export function apiV1NotificationsPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'notifications' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/parent_managers(.:format) */
export function apiV1ParentManagersPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'parent_managers' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/portfolio_benchmarks(.:format) */
export function apiV1PortfolioBenchmarksPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'portfolio_benchmarks' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/portfolio_benchmarks/:id(.:format) */
export function apiV1PortfolioBenchmarkPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'portfolio_benchmarks' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/portfolios_benchmarks(.:format) */
export function apiV1PortfoliosBenchmarksPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'portfolios_benchmarks' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/portfolios_benchmarks/:id(.:format) */
export function apiV1PortfoliosBenchmarkPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'portfolios_benchmarks' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/portfolio_benchmark_uploads(.:format) */
export function apiV1PortfolioBenchmarkUploadsPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'portfolio_benchmark_uploads' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/portfolio_manual_uploads(.:format) */
export function apiV1PortfolioManualUploadsPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'portfolio_manual_uploads' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/portfolio_returns(.:format) */
export function apiV1PortfolioReturnsPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'portfolio_returns' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/private_assets(.:format) */
export function apiV1PrivateAssetsPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'private_assets' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/private_assets/:id(.:format) */
export function apiV1PrivateAssetPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'private_assets' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/report_templates(.:format) */
export function apiV1ReportTemplatesPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'report_templates' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/report_templates/new(.:format) */
export function newApiV1ReportTemplatePath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'report_templates' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/report_templates/:id/edit(.:format) */
export function editApiV1ReportTemplatePath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'report_templates' +
    '/' +
    id +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/report_templates/:id(.:format) */
export function apiV1ReportTemplatePath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'report_templates' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/report_template_buckets(.:format) */
export function apiV1ReportTemplateBucketsPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'report_template_buckets' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/report_template_buckets/new(.:format) */
export function newApiV1ReportTemplateBucketPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'report_template_buckets' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/report_template_buckets/:id/edit(.:format) */
export function editApiV1ReportTemplateBucketPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'report_template_buckets' +
    '/' +
    id +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/report_template_buckets/:id(.:format) */
export function apiV1ReportTemplateBucketPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'report_template_buckets' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/report_template_rows(.:format) */
export function apiV1ReportTemplateRowsPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'report_template_rows' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/report_template_rows/new(.:format) */
export function newApiV1ReportTemplateRowPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'report_template_rows' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/report_template_rows/:id/edit(.:format) */
export function editApiV1ReportTemplateRowPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'report_template_rows' +
    '/' +
    id +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/report_template_rows/:id(.:format) */
export function apiV1ReportTemplateRowPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'report_template_rows' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/report_template_sections(.:format) */
export function apiV1ReportTemplateSectionsPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'report_template_sections' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/report_template_sections/new(.:format) */
export function newApiV1ReportTemplateSectionPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'report_template_sections' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/report_template_sections/:id/edit(.:format) */
export function editApiV1ReportTemplateSectionPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'report_template_sections' +
    '/' +
    id +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/report_template_sections/:id(.:format) */
export function apiV1ReportTemplateSectionPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'report_template_sections' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/report_template_themes/:id(.:format) */
export function apiV1ReportTemplateThemePath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'report_template_themes' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/report_templates_report_template_sections(.:format) */
export function apiV1ReportTemplatesReportTemplateSectionsPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'report_templates_report_template_sections' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/report_templates_report_template_sections/new(.:format) */
export function newApiV1ReportTemplatesReportTemplateSectionPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'report_templates_report_template_sections' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/report_templates_report_template_sections/:id/edit(.:format) */
export function editApiV1ReportTemplatesReportTemplateSectionPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'report_templates_report_template_sections' +
    '/' +
    id +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/report_templates_report_template_sections/:id(.:format) */
export function apiV1ReportTemplatesReportTemplateSectionPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'report_templates_report_template_sections' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/reviews(.:format) */
export function apiV1ReviewsPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'reviews' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/reviews/new(.:format) */
export function newApiV1ReviewPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'reviews' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/reviews/:id/edit(.:format) */
export function editApiV1ReviewPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'reviews' +
    '/' +
    id +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/reviews/:id(.:format) */
export function apiV1ReviewPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'reviews' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/review_reports(.:format) */
export function apiV1ReviewReportsPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'review_reports' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/review_reports/new(.:format) */
export function newApiV1ReviewReportPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'review_reports' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/review_reports/:id/edit(.:format) */
export function editApiV1ReviewReportPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'review_reports' +
    '/' +
    id +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/review_reports/:id(.:format) */
export function apiV1ReviewReportPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'review_reports' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/review_transitions(.:format) */
export function apiV1ReviewTransitionsPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'review_transitions' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/review_transitions/new(.:format) */
export function newApiV1ReviewTransitionPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'review_transitions' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/review_transitions/:id/edit(.:format) */
export function editApiV1ReviewTransitionPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'review_transitions' +
    '/' +
    id +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/review_transitions/:id(.:format) */
export function apiV1ReviewTransitionPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'review_transitions' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/classification_uploads(.:format) */
export function apiV1ClassificationUploadsPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'classification_uploads' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/classification_uploads/:id(.:format) */
export function apiV1ClassificationUploadPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'classification_uploads' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/template_action_points(.:format) */
export function apiV1TemplateActionPointsPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'template_action_points' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/template_action_points/new(.:format) */
export function newApiV1TemplateActionPointPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'template_action_points' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/template_action_points/:id/edit(.:format) */
export function editApiV1TemplateActionPointPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'template_action_points' +
    '/' +
    id +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/template_action_points/:id(.:format) */
export function apiV1TemplateActionPointPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'template_action_points' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/todo_items(.:format) */
export function apiV1TodoItemsPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'todo_items' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/todo_items/new(.:format) */
export function newApiV1TodoItemPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'todo_items' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/todo_items/:id/edit(.:format) */
export function editApiV1TodoItemPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'todo_items' +
    '/' +
    id +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/todo_items/:id(.:format) */
export function apiV1TodoItemPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'todo_items' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/tracked_jobs/:id(.:format) */
export function apiV1TrackedJobPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'tracked_jobs' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/tracked_reviews(.:format) */
export function apiV1TrackedReviewsPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'tracked_reviews' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/tracked_reviews/new(.:format) */
export function newApiV1TrackedReviewPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'tracked_reviews' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/tracked_reviews/:id/edit(.:format) */
export function editApiV1TrackedReviewPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'tracked_reviews' +
    '/' +
    id +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/tracked_reviews/:id(.:format) */
export function apiV1TrackedReviewPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'tracked_reviews' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/trackers(.:format) */
export function apiV1TrackersPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'trackers' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/trackers/new(.:format) */
export function newApiV1TrackerPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'trackers' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/trackers/:id/edit(.:format) */
export function editApiV1TrackerPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'trackers' +
    '/' +
    id +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/trackers/:id(.:format) */
export function apiV1TrackerPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'trackers' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/upload_templates(.:format) */
export function apiV1UploadTemplatesPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'upload_templates' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/upload_templates/new(.:format) */
export function newApiV1UploadTemplatePath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'upload_templates' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/upload_templates/:id/edit(.:format) */
export function editApiV1UploadTemplatePath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'upload_templates' +
    '/' +
    id +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/upload_templates/:id(.:format) */
export function apiV1UploadTemplatePath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'upload_templates' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/upload_template_additional_attributes(.:format) */
export function apiV1UploadTemplateAdditionalAttributesPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'upload_template_additional_attributes' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/upload_template_additional_attributes/new(.:format) */
export function newApiV1UploadTemplateAdditionalAttributePath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'upload_template_additional_attributes' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/upload_template_additional_attributes/:id/edit(.:format) */
export function editApiV1UploadTemplateAdditionalAttributePath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'upload_template_additional_attributes' +
    '/' +
    id +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/upload_template_additional_attributes/:id(.:format) */
export function apiV1UploadTemplateAdditionalAttributePath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'upload_template_additional_attributes' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/upload_template_sheets(.:format) */
export function apiV1UploadTemplateSheetsPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'upload_template_sheets' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/upload_template_sheets/new(.:format) */
export function newApiV1UploadTemplateSheetPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'upload_template_sheets' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/upload_template_sheets/:id/edit(.:format) */
export function editApiV1UploadTemplateSheetPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'upload_template_sheets' +
    '/' +
    id +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/upload_template_sheets/:id(.:format) */
export function apiV1UploadTemplateSheetPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'upload_template_sheets' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/upload_template_sheet_columns(.:format) */
export function apiV1UploadTemplateSheetColumnsPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'upload_template_sheet_columns' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/upload_template_sheet_columns/new(.:format) */
export function newApiV1UploadTemplateSheetColumnPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'upload_template_sheet_columns' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/upload_template_sheet_columns/:id/edit(.:format) */
export function editApiV1UploadTemplateSheetColumnPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'upload_template_sheet_columns' +
    '/' +
    id +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/upload_template_sheet_columns/:id(.:format) */
export function apiV1UploadTemplateSheetColumnPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'upload_template_sheet_columns' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/upload_template_conditions(.:format) */
export function apiV1UploadTemplateConditionsPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'upload_template_conditions' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/upload_template_conditions/new(.:format) */
export function newApiV1UploadTemplateConditionPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'upload_template_conditions' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/upload_template_conditions/:id/edit(.:format) */
export function editApiV1UploadTemplateConditionPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'upload_template_conditions' +
    '/' +
    id +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/upload_template_conditions/:id(.:format) */
export function apiV1UploadTemplateConditionPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'upload_template_conditions' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/valuations(.:format) */
export function apiV1ValuationsPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'valuations' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/valuations/:id(.:format) */
export function apiV1ValuationPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'valuations' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/portal/portfolios(.:format) */
export function apiV1PortalPortfoliosPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'portal' +
    '/' +
    'portfolios' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/portal/portfolios/:id(.:format) */
export function apiV1PortalPortfolioPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'portal' +
    '/' +
    'portfolios' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/portal/reviews(.:format) */
export function apiV1PortalReviewsPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'portal' +
    '/' +
    'reviews' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/permissions/administrators(.:format) */
export function apiV1PermissionsAdministratorsPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'permissions' +
    '/' +
    'administrators' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/permissions/administrators/:id(.:format) */
export function apiV1PermissionsAdministratorPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'permissions' +
    '/' +
    'administrators' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/permissions/client_users(.:format) */
export function apiV1PermissionsClientUsersPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'permissions' +
    '/' +
    'client_users' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/permissions/client_users/new(.:format) */
export function newApiV1PermissionsClientUserPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'permissions' +
    '/' +
    'client_users' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/permissions/client_users/:id/edit(.:format) */
export function editApiV1PermissionsClientUserPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'permissions' +
    '/' +
    'client_users' +
    '/' +
    id +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/permissions/client_users/:id(.:format) */
export function apiV1PermissionsClientUserPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'permissions' +
    '/' +
    'client_users' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/roles/client_users(.:format) */
export function apiV1RolesClientUsersPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'roles' +
    '/' +
    'client_users' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/roles/client_users/new(.:format) */
export function newApiV1RolesClientUserPath(options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'roles' +
    '/' +
    'client_users' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /api/v1/roles/client_users/:id/edit(.:format) */
export function editApiV1RolesClientUserPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'roles' +
    '/' +
    'client_users' +
    '/' +
    id +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /api/v1/roles/client_users/:id(.:format) */
export function apiV1RolesClientUserPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'api' +
    '/' +
    'v1' +
    '/' +
    'roles' +
    '/' +
    'client_users' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /manager_portal/sign_in(.:format) */
export function newManagerUserSessionPath(options?: object): string {
  return (
    '/' +
    'manager_portal' +
    '/' +
    'sign_in' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /manager_portal/sign_in(.:format) */
export function managerUserSessionPath(options?: object): string {
  return (
    '/' +
    'manager_portal' +
    '/' +
    'sign_in' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /manager_portal/sign_out(.:format) */
export function destroyManagerUserSessionPath(options?: object): string {
  return (
    '/' +
    'manager_portal' +
    '/' +
    'sign_out' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /manager_portal/password/new(.:format) */
export function newManagerUserPasswordPath(options?: object): string {
  return (
    '/' +
    'manager_portal' +
    '/' +
    'password' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /manager_portal/password/edit(.:format) */
export function editManagerUserPasswordPath(options?: object): string {
  return (
    '/' +
    'manager_portal' +
    '/' +
    'password' +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /manager_portal/password(.:format) */
export function managerUserPasswordPath(options?: object): string {
  return (
    '/' +
    'manager_portal' +
    '/' +
    'password' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /manager_portal/unlock/new(.:format) */
export function newManagerUserUnlockPath(options?: object): string {
  return (
    '/' +
    'manager_portal' +
    '/' +
    'unlock' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /manager_portal/unlock(.:format) */
export function managerUserUnlockPath(options?: object): string {
  return (
    '/' +
    'manager_portal' +
    '/' +
    'unlock' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /manager_portal(.:format) */
export function managerPortalRootPath(options?: object): string {
  return (
    '/' +
    'manager_portal' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /manager_portal/manager/regenerate_key(.:format) */
export function regenerateKeyManagerPortalManagerPath(options?: object): string {
  return (
    '/' +
    'manager_portal' +
    '/' +
    'manager' +
    '/' +
    'regenerate_key' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /manager_portal/manager(.:format) */
export function managerPortalManagerPath(options?: object): string {
  return (
    '/' +
    'manager_portal' +
    '/' +
    'manager' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /manager_portal/dashboard(.:format) */
export function managerPortalDashboardPath(options?: object): string {
  return (
    '/' +
    'manager_portal' +
    '/' +
    'dashboard' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /manager_portal/manager_users/:id/edit(.:format) */
export function editManagerPortalManagerUserPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'manager_portal' +
    '/' +
    'manager_users' +
    '/' +
    id +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /manager_portal/manager_users/:id(.:format) */
export function managerPortalManagerUserPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'manager_portal' +
    '/' +
    'manager_users' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /manager_portal/data_uploads/bulk_create(.:format) */
export function bulkCreateManagerPortalDataUploadsPath(options?: object): string {
  return (
    '/' +
    'manager_portal' +
    '/' +
    'data_uploads' +
    '/' +
    'bulk_create' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /manager_portal/data_uploads(.:format) */
export function managerPortalDataUploadsPath(options?: object): string {
  return (
    '/' +
    'manager_portal' +
    '/' +
    'data_uploads' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /manager_portal/data_uploads/new(.:format) */
export function newManagerPortalDataUploadPath(options?: object): string {
  return (
    '/' +
    'manager_portal' +
    '/' +
    'data_uploads' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /manager_portal/data_uploads/:id/edit(.:format) */
export function editManagerPortalDataUploadPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'manager_portal' +
    '/' +
    'data_uploads' +
    '/' +
    id +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /manager_portal/data_uploads/:id(.:format) */
export function managerPortalDataUploadPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'manager_portal' +
    '/' +
    'data_uploads' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /manager_portal/reviews(.:format) */
export function managerPortalReviewsPath(options?: object): string {
  return (
    '/' +
    'manager_portal' +
    '/' +
    'reviews' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /manager_portal/reviews/new(.:format) */
export function newManagerPortalReviewPath(options?: object): string {
  return (
    '/' +
    'manager_portal' +
    '/' +
    'reviews' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /manager_portal/reviews/:id/edit(.:format) */
export function editManagerPortalReviewPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'manager_portal' +
    '/' +
    'reviews' +
    '/' +
    id +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /manager_portal/reviews/:id(.:format) */
export function managerPortalReviewPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'manager_portal' +
    '/' +
    'reviews' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /platform(.:format) */
export function platformRootPath(options?: object): string {
  return (
    '/' +
    'platform' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/testing(.:format) */
export function platformTestingPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'testing' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/react/:component(/:args)(.:format) */
export function platformReactPath(component: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'react' +
    '/' +
    component +
    ($hasPresentOwnProperty(options, 'args') ? '/' + (options as any).args : '') +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['component', 'args', 'format'])
  );
}

/** /platform/investment_restrictions/:id(.:format) */
export function platformInvestmentRestrictionPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'investment_restrictions' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /platform/accounting/dashboard(.:format) */
export function platformAccountingDashboardPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'accounting' +
    '/' +
    'dashboard' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/accounting/dashboard/reviews(.:format) */
export function platformAccountingDashboardReviewsPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'accounting' +
    '/' +
    'dashboard' +
    '/' +
    'reviews' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/accounting/monitoring/postings(.:format) */
export function platformAccountingMonitoringPostingsPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'accounting' +
    '/' +
    'monitoring' +
    '/' +
    'postings' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/accounting/monitoring/postings/new(.:format) */
export function newPlatformAccountingMonitoringPostingPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'accounting' +
    '/' +
    'monitoring' +
    '/' +
    'postings' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/accounting/monitoring/postings/:id/edit(.:format) */
export function editPlatformAccountingMonitoringPostingPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'accounting' +
    '/' +
    'monitoring' +
    '/' +
    'postings' +
    '/' +
    id +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /platform/accounting/monitoring/postings/:id(.:format) */
export function platformAccountingMonitoringPostingPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'accounting' +
    '/' +
    'monitoring' +
    '/' +
    'postings' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /platform/administrators_notifications/bulk_update(.:format) */
export function bulkUpdatePlatformAdministratorsNotificationsPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'administrators_notifications' +
    '/' +
    'bulk_update' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/administrators_notifications(.:format) */
export function platformAdministratorsNotificationsPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'administrators_notifications' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/administrators_notifications/new(.:format) */
export function newPlatformAdministratorsNotificationPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'administrators_notifications' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/administrators_notifications/:id/edit(.:format) */
export function editPlatformAdministratorsNotificationPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'administrators_notifications' +
    '/' +
    id +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /platform/administrators_notifications/:id(.:format) */
export function platformAdministratorsNotificationPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'administrators_notifications' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /platform/asset_mappings(.:format) */
export function platformAssetMappingsPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'asset_mappings' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/asset_mappings/new(.:format) */
export function newPlatformAssetMappingPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'asset_mappings' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/asset_mappings/:id/edit(.:format) */
export function editPlatformAssetMappingPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'asset_mappings' +
    '/' +
    id +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /platform/asset_mappings/:id(.:format) */
export function platformAssetMappingPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'asset_mappings' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /platform/bulk_imports/contacts/download_template(.:format) */
export function downloadTemplatePlatformBulkImportsContactsPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'bulk_imports' +
    '/' +
    'contacts' +
    '/' +
    'download_template' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/bulk_imports/contacts/new(.:format) */
export function newPlatformBulkImportsContactsPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'bulk_imports' +
    '/' +
    'contacts' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/table_data_modifier(.:format) */
export function platformTableDataModifierIndexPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'table_data_modifier' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/table_data_modifier/new(.:format) */
export function newPlatformTableDataModifierPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'table_data_modifier' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/table_data_modifier/:id/edit(.:format) */
export function editPlatformTableDataModifierPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'table_data_modifier' +
    '/' +
    id +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /platform/table_data_modifier/:id(.:format) */
export function platformTableDataModifierPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'table_data_modifier' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /platform/bulk_portfolio_imports/download_template(.:format) */
export function downloadTemplatePlatformBulkPortfolioImportsPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'bulk_portfolio_imports' +
    '/' +
    'download_template' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/bulk_portfolio_imports(.:format) */
export function platformBulkPortfolioImportsPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'bulk_portfolio_imports' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/bulk_portfolio_imports/new(.:format) */
export function newPlatformBulkPortfolioImportPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'bulk_portfolio_imports' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/bulk_portfolio_imports/:id/edit(.:format) */
export function editPlatformBulkPortfolioImportPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'bulk_portfolio_imports' +
    '/' +
    id +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /platform/bulk_portfolio_imports/:id(.:format) */
export function platformBulkPortfolioImportPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'bulk_portfolio_imports' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /platform/clients/:client_id/contacts(.:format) */
export function platformClientContactsPath(client_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'clients' +
    '/' +
    client_id +
    '/' +
    'contacts' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['client_id', 'format'])
  );
}

/** /platform/clients/:client_id/extra_settings/new(.:format) */
export function newPlatformClientExtraSettingsPath(client_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'clients' +
    '/' +
    client_id +
    '/' +
    'extra_settings' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['client_id', 'format'])
  );
}

/** /platform/clients/:client_id/extra_settings/edit(.:format) */
export function editPlatformClientExtraSettingsPath(client_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'clients' +
    '/' +
    client_id +
    '/' +
    'extra_settings' +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['client_id', 'format'])
  );
}

/** /platform/clients/:client_id/extra_settings(.:format) */
export function platformClientExtraSettingsPath(client_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'clients' +
    '/' +
    client_id +
    '/' +
    'extra_settings' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['client_id', 'format'])
  );
}

/** /platform/clients/:client_id/factsheets/download(.:format) */
export function downloadPlatformClientFactsheetsPath(client_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'clients' +
    '/' +
    client_id +
    '/' +
    'factsheets' +
    '/' +
    'download' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['client_id', 'format'])
  );
}

/** /platform/clients/:client_id/factsheets(.:format) */
export function platformClientFactsheetsPath(client_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'clients' +
    '/' +
    client_id +
    '/' +
    'factsheets' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['client_id', 'format'])
  );
}

/** /platform/clients/:client_id/investment_risk_assessments(.:format) */
export function platformClientInvestmentRiskAssessmentsPath(client_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'clients' +
    '/' +
    client_id +
    '/' +
    'investment_risk_assessments' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['client_id', 'format'])
  );
}

/** /platform/clients/:client_id/investment_risk_assessments/new(.:format) */
export function newPlatformClientInvestmentRiskAssessmentPath(client_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'clients' +
    '/' +
    client_id +
    '/' +
    'investment_risk_assessments' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['client_id', 'format'])
  );
}

/** /platform/clients/:client_id/investment_risk_assessments/:id/edit(.:format) */
export function editPlatformClientInvestmentRiskAssessmentPath(
  client_id: ScalarType,
  id: ScalarType,
  options?: object,
): string {
  return (
    '/' +
    'platform' +
    '/' +
    'clients' +
    '/' +
    client_id +
    '/' +
    'investment_risk_assessments' +
    '/' +
    id +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['client_id', 'id', 'format'])
  );
}

/** /platform/clients/:client_id/investment_risk_assessments/:id(.:format) */
export function platformClientInvestmentRiskAssessmentPath(
  client_id: ScalarType,
  id: ScalarType,
  options?: object,
): string {
  return (
    '/' +
    'platform' +
    '/' +
    'clients' +
    '/' +
    client_id +
    '/' +
    'investment_risk_assessments' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['client_id', 'id', 'format'])
  );
}

/** /platform/clients/:client_id/sent_reviews(.:format) */
export function platformClientSentReviewsPath(client_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'clients' +
    '/' +
    client_id +
    '/' +
    'sent_reviews' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['client_id', 'format'])
  );
}

/** /platform/clients/:client_id/xero/postings(.:format) */
export function platformClientXeroPostingsPath(client_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'clients' +
    '/' +
    client_id +
    '/' +
    'xero' +
    '/' +
    'postings' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['client_id', 'format'])
  );
}

/** /platform/clients/:client_id/zipped_reviews(.:format) */
export function platformClientZippedReviewsPath(client_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'clients' +
    '/' +
    client_id +
    '/' +
    'zipped_reviews' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['client_id', 'format'])
  );
}

/** /platform/clients(.:format) */
export function platformClientsPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'clients' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/clients/new(.:format) */
export function newPlatformClientPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'clients' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/clients/:id/edit(.:format) */
export function editPlatformClientPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'clients' +
    '/' +
    id +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /platform/clients/:id(.:format) */
export function platformClientPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'clients' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /platform/client_tiles(.:format) */
export function platformClientTilesPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'client_tiles' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/client_tiles/new(.:format) */
export function newPlatformClientTilePath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'client_tiles' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/client_tiles/:id/edit(.:format) */
export function editPlatformClientTilePath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'client_tiles' +
    '/' +
    id +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /platform/client_tiles/:id(.:format) */
export function platformClientTilePath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'client_tiles' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /platform/cluster_mappings(.:format) */
export function platformClusterMappingsPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'cluster_mappings' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/cluster_mappings/new(.:format) */
export function newPlatformClusterMappingPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'cluster_mappings' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/cluster_mappings/:id/edit(.:format) */
export function editPlatformClusterMappingPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'cluster_mappings' +
    '/' +
    id +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /platform/cluster_mappings/:id(.:format) */
export function platformClusterMappingPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'cluster_mappings' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /platform/commentaries(.:format) */
export function platformCommentariesPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'commentaries' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/administrators/companies/:company_id/sign_in(.:format) */
export function newPlatformAdministratorCompanySessionPath(company_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'administrators' +
    '/' +
    'companies' +
    '/' +
    company_id +
    '/' +
    'sign_in' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['company_id', 'format'])
  );
}

/** /platform/administrators/companies/:company_id/sign_in(.:format) */
export function platformAdministratorCompanySessionPath(company_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'administrators' +
    '/' +
    'companies' +
    '/' +
    company_id +
    '/' +
    'sign_in' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['company_id', 'format'])
  );
}

/** /platform/administrators/companies/:company_id/sign_out(.:format) */
export function destroyPlatformAdministratorCompanySessionPath(company_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'administrators' +
    '/' +
    'companies' +
    '/' +
    company_id +
    '/' +
    'sign_out' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['company_id', 'format'])
  );
}

/** /platform/administrators/companies/:company_id/password/new(.:format) */
export function newPlatformAdministratorCompanyPasswordPath(company_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'administrators' +
    '/' +
    'companies' +
    '/' +
    company_id +
    '/' +
    'password' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['company_id', 'format'])
  );
}

/** /platform/administrators/companies/:company_id/password/edit(.:format) */
export function editPlatformAdministratorCompanyPasswordPath(company_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'administrators' +
    '/' +
    'companies' +
    '/' +
    company_id +
    '/' +
    'password' +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['company_id', 'format'])
  );
}

/** /platform/administrators/companies/:company_id/password(.:format) */
export function platformAdministratorCompanyPasswordPath(company_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'administrators' +
    '/' +
    'companies' +
    '/' +
    company_id +
    '/' +
    'password' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['company_id', 'format'])
  );
}

/** /platform/administrators/companies/:company_id/unlock/new(.:format) */
export function newPlatformAdministratorCompanyUnlockPath(company_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'administrators' +
    '/' +
    'companies' +
    '/' +
    company_id +
    '/' +
    'unlock' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['company_id', 'format'])
  );
}

/** /platform/administrators/companies/:company_id/unlock(.:format) */
export function platformAdministratorCompanyUnlockPath(company_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'administrators' +
    '/' +
    'companies' +
    '/' +
    company_id +
    '/' +
    'unlock' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['company_id', 'format'])
  );
}

/** /platform/administrators/companies/:company_id/invitation/accept(.:format) */
export function acceptPlatformAdministratorCompanyInvitationPath(company_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'administrators' +
    '/' +
    'companies' +
    '/' +
    company_id +
    '/' +
    'invitation' +
    '/' +
    'accept' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['company_id', 'format'])
  );
}

/** /platform/administrators/companies/:company_id/invitation/remove(.:format) */
export function removePlatformAdministratorCompanyInvitationPath(company_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'administrators' +
    '/' +
    'companies' +
    '/' +
    company_id +
    '/' +
    'invitation' +
    '/' +
    'remove' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['company_id', 'format'])
  );
}

/** /platform/administrators/companies/:company_id/invitation/new(.:format) */
export function newPlatformAdministratorCompanyInvitationPath(company_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'administrators' +
    '/' +
    'companies' +
    '/' +
    company_id +
    '/' +
    'invitation' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['company_id', 'format'])
  );
}

/** /platform/administrators/companies/:company_id/invitation(.:format) */
export function platformAdministratorCompanyInvitationPath(company_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'administrators' +
    '/' +
    'companies' +
    '/' +
    company_id +
    '/' +
    'invitation' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['company_id', 'format'])
  );
}

/** /platform/companies/:company_id/administrators/:administrator_id/reviews/new(.:format) */
export function newPlatformCompanyAdministratorReviewsPath(
  company_id: ScalarType,
  administrator_id: ScalarType,
  options?: object,
): string {
  return (
    '/' +
    'platform' +
    '/' +
    'companies' +
    '/' +
    company_id +
    '/' +
    'administrators' +
    '/' +
    administrator_id +
    '/' +
    'reviews' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['company_id', 'administrator_id', 'format'])
  );
}

/** /platform/companies/:company_id/administrators/:administrator_id/reviews/edit(.:format) */
export function editPlatformCompanyAdministratorReviewsPath(
  company_id: ScalarType,
  administrator_id: ScalarType,
  options?: object,
): string {
  return (
    '/' +
    'platform' +
    '/' +
    'companies' +
    '/' +
    company_id +
    '/' +
    'administrators' +
    '/' +
    administrator_id +
    '/' +
    'reviews' +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['company_id', 'administrator_id', 'format'])
  );
}

/** /platform/companies/:company_id/administrators/:administrator_id/reviews(.:format) */
export function platformCompanyAdministratorReviewsPath(
  company_id: ScalarType,
  administrator_id: ScalarType,
  options?: object,
): string {
  return (
    '/' +
    'platform' +
    '/' +
    'companies' +
    '/' +
    company_id +
    '/' +
    'administrators' +
    '/' +
    administrator_id +
    '/' +
    'reviews' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['company_id', 'administrator_id', 'format'])
  );
}

/** /platform/companies/:company_id/administrators(.:format) */
export function platformCompanyAdministratorsPath(company_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'companies' +
    '/' +
    company_id +
    '/' +
    'administrators' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['company_id', 'format'])
  );
}

/** /platform/companies/:company_id/administrators/:id(.:format) */
export function platformCompanyAdministratorPath(company_id: ScalarType, id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'companies' +
    '/' +
    company_id +
    '/' +
    'administrators' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['company_id', 'id', 'format'])
  );
}

/** /platform/companies/:company_id/company_switcher(.:format) */
export function platformCompanyCompanySwitcherPath(company_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'companies' +
    '/' +
    company_id +
    '/' +
    'company_switcher' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['company_id', 'format'])
  );
}

/** /platform/companies(.:format) */
export function platformCompaniesPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'companies' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/companies/new(.:format) */
export function newPlatformCompanyPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'companies' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/companies/:id/edit(.:format) */
export function editPlatformCompanyPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'companies' +
    '/' +
    id +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /platform/companies/:id(.:format) */
export function platformCompanyPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'companies' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /platform/data_chase_templates(.:format) */
export function platformDataChaseTemplatesPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'data_chase_templates' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/dashboard(.:format) */
export function platformDashboardPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'dashboard' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/dashboard/reviews/bulk_create(.:format) */
export function bulkCreatePlatformDashboardReviewsPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'dashboard' +
    '/' +
    'reviews' +
    '/' +
    'bulk_create' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/dashboard/reviews(.:format) */
export function platformDashboardReviewsPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'dashboard' +
    '/' +
    'reviews' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/entities/:entity_id/structure_graphs(.:format) */
export function platformEntityStructureGraphsPath(entity_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'entities' +
    '/' +
    entity_id +
    '/' +
    'structure_graphs' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['entity_id', 'format'])
  );
}

/** /platform/entities(.:format) */
export function platformEntitiesPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'entities' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/entities/new(.:format) */
export function newPlatformEntityPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'entities' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/entities/:id/edit(.:format) */
export function editPlatformEntityPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'entities' +
    '/' +
    id +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /platform/entities/:id(.:format) */
export function platformEntityPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'entities' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /platform/entity_relationship_roles(.:format) */
export function platformEntityRelationshipRolesPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'entity_relationship_roles' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/entity_relationship_roles/new(.:format) */
export function newPlatformEntityRelationshipRolePath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'entity_relationship_roles' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/entity_relationship_roles/:id/edit(.:format) */
export function editPlatformEntityRelationshipRolePath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'entity_relationship_roles' +
    '/' +
    id +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /platform/entity_relationship_roles/:id(.:format) */
export function platformEntityRelationshipRolePath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'entity_relationship_roles' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /platform/extracts(.:format) */
export function platformExtractsPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'extracts' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/managers/:manager_id/data_uploads/bulk_create(.:format) */
export function bulkCreatePlatformManagerDataUploadsPath(manager_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'managers' +
    '/' +
    manager_id +
    '/' +
    'data_uploads' +
    '/' +
    'bulk_create' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['manager_id', 'format'])
  );
}

/** /platform/managers/:manager_id/data_uploads/:id(.:format) */
export function platformManagerDataUploadPath(manager_id: ScalarType, id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'managers' +
    '/' +
    manager_id +
    '/' +
    'data_uploads' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['manager_id', 'id', 'format'])
  );
}

/** /platform/managers/:manager_id/manager_users/:manager_user_id/portfolios(.:format) */
export function platformManagerManagerUserPortfoliosPath(
  manager_id: ScalarType,
  manager_user_id: ScalarType,
  options?: object,
): string {
  return (
    '/' +
    'platform' +
    '/' +
    'managers' +
    '/' +
    manager_id +
    '/' +
    'manager_users' +
    '/' +
    manager_user_id +
    '/' +
    'portfolios' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['manager_id', 'manager_user_id', 'format'])
  );
}

/** /platform/managers/:manager_id/manager_users(.:format) */
export function platformManagerManagerUsersPath(manager_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'managers' +
    '/' +
    manager_id +
    '/' +
    'manager_users' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['manager_id', 'format'])
  );
}

/** /platform/managers/:manager_id/manager_users/new(.:format) */
export function newPlatformManagerManagerUserPath(manager_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'managers' +
    '/' +
    manager_id +
    '/' +
    'manager_users' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['manager_id', 'format'])
  );
}

/** /platform/managers/:manager_id/manager_users/:id/edit(.:format) */
export function editPlatformManagerManagerUserPath(manager_id: ScalarType, id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'managers' +
    '/' +
    manager_id +
    '/' +
    'manager_users' +
    '/' +
    id +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['manager_id', 'id', 'format'])
  );
}

/** /platform/managers/:manager_id/manager_users/:id(.:format) */
export function platformManagerManagerUserPath(manager_id: ScalarType, id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'managers' +
    '/' +
    manager_id +
    '/' +
    'manager_users' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['manager_id', 'id', 'format'])
  );
}

/** /platform/managers/:manager_id/portfolios(.:format) */
export function platformManagerPortfoliosPath(manager_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'managers' +
    '/' +
    manager_id +
    '/' +
    'portfolios' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['manager_id', 'format'])
  );
}

/** /platform/managers/:manager_id/upload_templates/parse_file(.:format) */
export function parseFilePlatformManagerUploadTemplatesPath(manager_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'managers' +
    '/' +
    manager_id +
    '/' +
    'upload_templates' +
    '/' +
    'parse_file' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['manager_id', 'format'])
  );
}

/** /platform/managers/:manager_id/upload_templates/new(.:format) */
export function newPlatformManagerUploadTemplatesPath(manager_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'managers' +
    '/' +
    manager_id +
    '/' +
    'upload_templates' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['manager_id', 'format'])
  );
}

/** /platform/managers/:manager_id/upload_templates/edit(.:format) */
export function editPlatformManagerUploadTemplatesPath(manager_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'managers' +
    '/' +
    manager_id +
    '/' +
    'upload_templates' +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['manager_id', 'format'])
  );
}

/** /platform/managers/:manager_id/upload_templates(.:format) */
export function platformManagerUploadTemplatesPath(manager_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'managers' +
    '/' +
    manager_id +
    '/' +
    'upload_templates' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['manager_id', 'format'])
  );
}

/** /platform/managers(.:format) */
export function platformManagersPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'managers' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/managers/new(.:format) */
export function newPlatformManagerPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'managers' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/managers/:id/edit(.:format) */
export function editPlatformManagerPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'managers' +
    '/' +
    id +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /platform/managers/:id(.:format) */
export function platformManagerPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'managers' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /platform/manager_uploads(.:format) */
export function platformManagerUploadsPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'manager_uploads' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/market_commentaries(.:format) */
export function platformMarketCommentariesPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'market_commentaries' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/market_commentaries/new(.:format) */
export function newPlatformMarketCommentaryPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'market_commentaries' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/market_commentaries/:id/edit(.:format) */
export function editPlatformMarketCommentaryPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'market_commentaries' +
    '/' +
    id +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /platform/market_commentaries/:id(.:format) */
export function platformMarketCommentaryPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'market_commentaries' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /platform/notes/:note_id/attachments(.:format) */
export function platformNoteAttachmentsPath(note_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'notes' +
    '/' +
    note_id +
    '/' +
    'attachments' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['note_id', 'format'])
  );
}

/** /platform/notes/:note_id/attachments/new(.:format) */
export function newPlatformNoteAttachmentPath(note_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'notes' +
    '/' +
    note_id +
    '/' +
    'attachments' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['note_id', 'format'])
  );
}

/** /platform/notes/:note_id/attachments/:id/edit(.:format) */
export function editPlatformNoteAttachmentPath(note_id: ScalarType, id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'notes' +
    '/' +
    note_id +
    '/' +
    'attachments' +
    '/' +
    id +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['note_id', 'id', 'format'])
  );
}

/** /platform/notes/:note_id/attachments/:id(.:format) */
export function platformNoteAttachmentPath(note_id: ScalarType, id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'notes' +
    '/' +
    note_id +
    '/' +
    'attachments' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['note_id', 'id', 'format'])
  );
}

/** /platform/notes(.:format) */
export function platformNotesPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'notes' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/notes/:id(.:format) */
export function platformNotePath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'notes' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /platform/nav_one_clients(.:format) */
export function platformNavOneClientsPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'nav_one_clients' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/nav_one_clients/:client_no(.:format) */
export function platformClientNoPath(client_no: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'nav_one_clients' +
    '/' +
    client_no +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['client_no', 'format'])
  );
}

/** /platform/nav_one_clients/:client_no/portfolio_details/:portfolio_no(.:format) */
export function platformPortfolioDetailPath(client_no: ScalarType, portfolio_no: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'nav_one_clients' +
    '/' +
    client_no +
    '/' +
    'portfolio_details' +
    '/' +
    portfolio_no +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['client_no', 'portfolio_no', 'format'])
  );
}

/** /platform/nav_one_clients/:client_no/investment_transactions/:portfolio_no(.:format) */
export function platformInvestmentTransactionPath(
  client_no: ScalarType,
  portfolio_no: ScalarType,
  options?: object,
): string {
  return (
    '/' +
    'platform' +
    '/' +
    'nav_one_clients' +
    '/' +
    client_no +
    '/' +
    'investment_transactions' +
    '/' +
    portfolio_no +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['client_no', 'portfolio_no', 'format'])
  );
}

/** /platform/nav_one_clients/:client_no/bank_account_transactions/:bank_no(.:format) */
export function platformBankAccountTransactionPath(
  client_no: ScalarType,
  bank_no: ScalarType,
  options?: object,
): string {
  return (
    '/' +
    'platform' +
    '/' +
    'nav_one_clients' +
    '/' +
    client_no +
    '/' +
    'bank_account_transactions' +
    '/' +
    bank_no +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['client_no', 'bank_no', 'format'])
  );
}

/** /platform/notifications(.:format) */
export function platformNotificationsPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'notifications' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/parent_managers(.:format) */
export function platformParentManagersPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'parent_managers' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/parent_managers/new(.:format) */
export function newPlatformParentManagerPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'parent_managers' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/parent_managers/:id/edit(.:format) */
export function editPlatformParentManagerPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'parent_managers' +
    '/' +
    id +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /platform/parent_managers/:id(.:format) */
export function platformParentManagerPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'parent_managers' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /platform/portfolios/:portfolio_id/download(.:format) */
export function platformPortfolioDownloadPath(portfolio_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'portfolios' +
    '/' +
    portfolio_id +
    '/' +
    'download' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['portfolio_id', 'format'])
  );
}

/** /platform/portfolios/:id/mandates/:mandate_id(.:format) */
export function mandatePlatformPortfolioPath(id: ScalarType, mandate_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'portfolios' +
    '/' +
    id +
    '/' +
    'mandates' +
    '/' +
    mandate_id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'mandate_id', 'format'])
  );
}

/** /platform/portfolios/:id/investment_policy_statements/:investment_policy_statement_id(.:format) */
export function investmentPolicyStatementPlatformPortfolioPath(
  id: ScalarType,
  investment_policy_statement_id: ScalarType,
  options?: object,
): string {
  return (
    '/' +
    'platform' +
    '/' +
    'portfolios' +
    '/' +
    id +
    '/' +
    'investment_policy_statements' +
    '/' +
    investment_policy_statement_id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'investment_policy_statement_id', 'format'])
  );
}

/** /platform/portfolios/:portfolio_id/asset_holdings(.:format) */
export function platformPortfolioAssetHoldingsPath(portfolio_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'portfolios' +
    '/' +
    portfolio_id +
    '/' +
    'asset_holdings' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['portfolio_id', 'format'])
  );
}

/** /platform/portfolios/:portfolio_id/balances(.:format) */
export function platformPortfolioBalancesPath(portfolio_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'portfolios' +
    '/' +
    portfolio_id +
    '/' +
    'balances' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['portfolio_id', 'format'])
  );
}

/** /platform/portfolios/:portfolio_id/currency_holdings(.:format) */
export function platformPortfolioCurrencyHoldingsPath(portfolio_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'portfolios' +
    '/' +
    portfolio_id +
    '/' +
    'currency_holdings' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['portfolio_id', 'format'])
  );
}

/** /platform/portfolios/:portfolio_id/holdings(.:format) */
export function platformPortfolioHoldingsPath(portfolio_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'portfolios' +
    '/' +
    portfolio_id +
    '/' +
    'holdings' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['portfolio_id', 'format'])
  );
}

/** /platform/portfolios/:portfolio_id/notes(.:format) */
export function platformPortfolioNotesPath(portfolio_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'portfolios' +
    '/' +
    portfolio_id +
    '/' +
    'notes' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['portfolio_id', 'format'])
  );
}

/** /platform/portfolios/:portfolio_id/notes/new(.:format) */
export function newPlatformPortfolioNotePath(portfolio_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'portfolios' +
    '/' +
    portfolio_id +
    '/' +
    'notes' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['portfolio_id', 'format'])
  );
}

/** /platform/portfolios/:portfolio_id/notes/:id/edit(.:format) */
export function editPlatformPortfolioNotePath(portfolio_id: ScalarType, id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'portfolios' +
    '/' +
    portfolio_id +
    '/' +
    'notes' +
    '/' +
    id +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['portfolio_id', 'id', 'format'])
  );
}

/** /platform/portfolios/:portfolio_id/notes/:id(.:format) */
export function platformPortfolioNotePath(portfolio_id: ScalarType, id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'portfolios' +
    '/' +
    portfolio_id +
    '/' +
    'notes' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['portfolio_id', 'id', 'format'])
  );
}

/** /platform/portfolios/:portfolio_id/performance_numbers(.:format) */
export function platformPortfolioPerformanceNumbersPath(portfolio_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'portfolios' +
    '/' +
    portfolio_id +
    '/' +
    'performance_numbers' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['portfolio_id', 'format'])
  );
}

/** /platform/portfolios/:portfolio_id/performance_numbers/new(.:format) */
export function newPlatformPortfolioPerformanceNumberPath(portfolio_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'portfolios' +
    '/' +
    portfolio_id +
    '/' +
    'performance_numbers' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['portfolio_id', 'format'])
  );
}

/** /platform/portfolios/:portfolio_id/performance_numbers/:id/edit(.:format) */
export function editPlatformPortfolioPerformanceNumberPath(
  portfolio_id: ScalarType,
  id: ScalarType,
  options?: object,
): string {
  return (
    '/' +
    'platform' +
    '/' +
    'portfolios' +
    '/' +
    portfolio_id +
    '/' +
    'performance_numbers' +
    '/' +
    id +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['portfolio_id', 'id', 'format'])
  );
}

/** /platform/portfolios/:portfolio_id/performance_numbers/:id(.:format) */
export function platformPortfolioPerformanceNumberPath(
  portfolio_id: ScalarType,
  id: ScalarType,
  options?: object,
): string {
  return (
    '/' +
    'platform' +
    '/' +
    'portfolios' +
    '/' +
    portfolio_id +
    '/' +
    'performance_numbers' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['portfolio_id', 'id', 'format'])
  );
}

/** /platform/portfolios/:portfolio_id/portfolio_returns(.:format) */
export function platformPortfolioPortfolioReturnsPath(portfolio_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'portfolios' +
    '/' +
    portfolio_id +
    '/' +
    'portfolio_returns' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['portfolio_id', 'format'])
  );
}

/** /platform/portfolios/:portfolio_id/return_series(.:format) */
export function platformPortfolioReturnSeriesIndexPath(portfolio_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'portfolios' +
    '/' +
    portfolio_id +
    '/' +
    'return_series' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['portfolio_id', 'format'])
  );
}

/** /platform/portfolios/:portfolio_id/reviews/:review_id/reports/:report_id/preview(.:format) */
export function platformPortfolioReviewReportPreviewPath(
  portfolio_id: ScalarType,
  review_id: ScalarType,
  report_id: ScalarType,
  options?: object,
): string {
  return (
    '/' +
    'platform' +
    '/' +
    'portfolios' +
    '/' +
    portfolio_id +
    '/' +
    'reviews' +
    '/' +
    review_id +
    '/' +
    'reports' +
    '/' +
    report_id +
    '/' +
    'preview' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['portfolio_id', 'review_id', 'report_id', 'format'])
  );
}

/** /platform/portfolios/:portfolio_id/reviews/:review_id/reports(.:format) */
export function platformPortfolioReviewReportsPath(
  portfolio_id: ScalarType,
  review_id: ScalarType,
  options?: object,
): string {
  return (
    '/' +
    'platform' +
    '/' +
    'portfolios' +
    '/' +
    portfolio_id +
    '/' +
    'reviews' +
    '/' +
    review_id +
    '/' +
    'reports' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['portfolio_id', 'review_id', 'format'])
  );
}

/** /platform/portfolios/:portfolio_id/reviews/:review_id/reports/new(.:format) */
export function newPlatformPortfolioReviewReportPath(
  portfolio_id: ScalarType,
  review_id: ScalarType,
  options?: object,
): string {
  return (
    '/' +
    'platform' +
    '/' +
    'portfolios' +
    '/' +
    portfolio_id +
    '/' +
    'reviews' +
    '/' +
    review_id +
    '/' +
    'reports' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['portfolio_id', 'review_id', 'format'])
  );
}

/** /platform/portfolios/:portfolio_id/reviews/:review_id/reports/:id/edit(.:format) */
export function editPlatformPortfolioReviewReportPath(
  portfolio_id: ScalarType,
  review_id: ScalarType,
  id: ScalarType,
  options?: object,
): string {
  return (
    '/' +
    'platform' +
    '/' +
    'portfolios' +
    '/' +
    portfolio_id +
    '/' +
    'reviews' +
    '/' +
    review_id +
    '/' +
    'reports' +
    '/' +
    id +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['portfolio_id', 'review_id', 'id', 'format'])
  );
}

/** /platform/portfolios/:portfolio_id/reviews/:review_id/reports/:id(.:format) */
export function platformPortfolioReviewReportPath(
  portfolio_id: ScalarType,
  review_id: ScalarType,
  id: ScalarType,
  options?: object,
): string {
  return (
    '/' +
    'platform' +
    '/' +
    'portfolios' +
    '/' +
    portfolio_id +
    '/' +
    'reviews' +
    '/' +
    review_id +
    '/' +
    'reports' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['portfolio_id', 'review_id', 'id', 'format'])
  );
}

/** /platform/portfolios/:portfolio_id/reviews(.:format) */
export function platformPortfolioReviewsPath(portfolio_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'portfolios' +
    '/' +
    portfolio_id +
    '/' +
    'reviews' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['portfolio_id', 'format'])
  );
}

/** /platform/portfolios/:portfolio_id/reviews/:id(.:format) */
export function platformPortfolioReviewPath(portfolio_id: ScalarType, id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'portfolios' +
    '/' +
    portfolio_id +
    '/' +
    'reviews' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['portfolio_id', 'id', 'format'])
  );
}

/** /platform/portfolios/:portfolio_id/statuses(.:format) */
export function platformPortfolioStatusesPath(portfolio_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'portfolios' +
    '/' +
    portfolio_id +
    '/' +
    'statuses' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['portfolio_id', 'format'])
  );
}

/** /platform/portfolios/:portfolio_id/valuations(.:format) */
export function platformPortfolioValuationsPath(portfolio_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'portfolios' +
    '/' +
    portfolio_id +
    '/' +
    'valuations' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['portfolio_id', 'format'])
  );
}

/** /platform/portfolios/:portfolio_id/viewed_portfolios(.:format) */
export function platformPortfolioViewedPortfoliosPath(portfolio_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'portfolios' +
    '/' +
    portfolio_id +
    '/' +
    'viewed_portfolios' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['portfolio_id', 'format'])
  );
}

/** /platform/portfolios(.:format) */
export function platformPortfoliosPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'portfolios' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/portfolios/new(.:format) */
export function newPlatformPortfolioPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'portfolios' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/portfolios/:id/edit(.:format) */
export function editPlatformPortfolioPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'portfolios' +
    '/' +
    id +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /platform/portfolios/:id(.:format) */
export function platformPortfolioPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'portfolios' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /platform/portfolio_benchmarks/:portfolio_benchmark_id/portfolio_benchmarks/composite_returns(.:format) */
export function platformPortfolioBenchmarkPortfolioBenchmarksCompositeReturnsPath(
  portfolio_benchmark_id: ScalarType,
  options?: object,
): string {
  return (
    '/' +
    'platform' +
    '/' +
    'portfolio_benchmarks' +
    '/' +
    portfolio_benchmark_id +
    '/' +
    'portfolio_benchmarks' +
    '/' +
    'composite_returns' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['portfolio_benchmark_id', 'format'])
  );
}

/** /platform/portfolio_benchmarks(.:format) */
export function platformPortfolioBenchmarksPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'portfolio_benchmarks' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/portfolio_benchmarks/new(.:format) */
export function newPlatformPortfolioBenchmarkPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'portfolio_benchmarks' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/portfolio_benchmarks/:id/edit(.:format) */
export function editPlatformPortfolioBenchmarkPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'portfolio_benchmarks' +
    '/' +
    id +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /platform/portfolio_benchmarks/:id(.:format) */
export function platformPortfolioBenchmarkPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'portfolio_benchmarks' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /platform/relationship_managers/dashboard(.:format) */
export function platformRelationshipManagersDashboardPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'relationship_managers' +
    '/' +
    'dashboard' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/relationship_managers/dashboard/reviews(.:format) */
export function platformRelationshipManagersDashboardReviewsPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'relationship_managers' +
    '/' +
    'dashboard' +
    '/' +
    'reviews' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/report_designs(.:format) */
export function platformReportDesignsPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'report_designs' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/report_templates/:id/duplicate(.:format) */
export function duplicatePlatformReportTemplatePath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'report_templates' +
    '/' +
    id +
    '/' +
    'duplicate' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /platform/report_templates/:id/publish(.:format) */
export function publishPlatformReportTemplatePath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'report_templates' +
    '/' +
    id +
    '/' +
    'publish' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /platform/report_templates/:id/status_update(.:format) */
export function statusUpdatePlatformReportTemplatePath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'report_templates' +
    '/' +
    id +
    '/' +
    'status_update' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /platform/report_templates(.:format) */
export function platformReportTemplatesPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'report_templates' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/report_templates/new(.:format) */
export function newPlatformReportTemplatePath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'report_templates' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/report_templates/:id/edit(.:format) */
export function editPlatformReportTemplatePath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'report_templates' +
    '/' +
    id +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /platform/report_templates/:id(.:format) */
export function platformReportTemplatePath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'report_templates' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /platform/report_template_buckets(.:format) */
export function platformReportTemplateBucketsPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'report_template_buckets' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/reports/investment_risk_assessments/preview(.:format) */
export function previewPlatformReportsInvestmentRiskAssessmentsPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'reports' +
    '/' +
    'investment_risk_assessments' +
    '/' +
    'preview' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/reviews/move_stage(.:format) */
export function moveStagePlatformReviewsPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'reviews' +
    '/' +
    'move_stage' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/reviews/unassign(.:format) */
export function unassignPlatformReviewsPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'reviews' +
    '/' +
    'unassign' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/reviews/:id/regenerate(.:format) */
export function regeneratePlatformReviewPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'reviews' +
    '/' +
    id +
    '/' +
    'regenerate' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /platform/reviews/:review_id/action_points(.:format) */
export function platformReviewActionPointsPath(review_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'reviews' +
    '/' +
    review_id +
    '/' +
    'action_points' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['review_id', 'format'])
  );
}

/** /platform/reviews/:review_id/action_points/:id(.:format) */
export function platformReviewActionPointPath(review_id: ScalarType, id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'reviews' +
    '/' +
    review_id +
    '/' +
    'action_points' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['review_id', 'id', 'format'])
  );
}

/** /platform/reviews/:review_id/action_points_reviews/:id(.:format) */
export function platformReviewActionPointsReviewPath(review_id: ScalarType, id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'reviews' +
    '/' +
    review_id +
    '/' +
    'action_points_reviews' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['review_id', 'id', 'format'])
  );
}

/** /platform/reviews/:review_id/commentaries/new(.:format) */
export function newPlatformReviewCommentariesPath(review_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'reviews' +
    '/' +
    review_id +
    '/' +
    'commentaries' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['review_id', 'format'])
  );
}

/** /platform/reviews/:review_id/commentaries/edit(.:format) */
export function editPlatformReviewCommentariesPath(review_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'reviews' +
    '/' +
    review_id +
    '/' +
    'commentaries' +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['review_id', 'format'])
  );
}

/** /platform/reviews/:review_id/commentaries(.:format) */
export function platformReviewCommentariesPath(review_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'reviews' +
    '/' +
    review_id +
    '/' +
    'commentaries' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['review_id', 'format'])
  );
}

/** /platform/reviews/:review_id/rating/:id(.:format) */
export function platformReviewRatingPath(review_id: ScalarType, id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'reviews' +
    '/' +
    review_id +
    '/' +
    'rating' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['review_id', 'id', 'format'])
  );
}

/** /platform/reviews/:review_id/return_series(.:format) */
export function platformReviewReturnSeriesIndexPath(review_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'reviews' +
    '/' +
    review_id +
    '/' +
    'return_series' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['review_id', 'format'])
  );
}

/** /platform/reviews/:review_id/report_templates(.:format) */
export function platformReviewReportTemplatesPath(review_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'reviews' +
    '/' +
    review_id +
    '/' +
    'report_templates' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['review_id', 'format'])
  );
}

/** /platform/reviews/:review_id/review_reports(.:format) */
export function platformReviewReviewReportsPath(review_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'reviews' +
    '/' +
    review_id +
    '/' +
    'review_reports' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['review_id', 'format'])
  );
}

/** /platform/reviews/:review_id/statuses/validate(.:format) */
export function validatePlatformReviewStatusesPath(review_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'reviews' +
    '/' +
    review_id +
    '/' +
    'statuses' +
    '/' +
    'validate' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['review_id', 'format'])
  );
}

/** /platform/reviews/:review_id/statuses(.:format) */
export function platformReviewStatusesPath(review_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'reviews' +
    '/' +
    review_id +
    '/' +
    'statuses' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['review_id', 'format'])
  );
}

/** /platform/reviews/:review_id/transitions(.:format) */
export function platformReviewTransitionsPath(review_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'reviews' +
    '/' +
    review_id +
    '/' +
    'transitions' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['review_id', 'format'])
  );
}

/** /platform/reviews(.:format) */
export function platformReviewsPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'reviews' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/reviews/new(.:format) */
export function newPlatformReviewPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'reviews' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/reviews/:id/edit(.:format) */
export function editPlatformReviewPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'reviews' +
    '/' +
    id +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /platform/reviews/:id(.:format) */
export function platformReviewPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'reviews' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /platform/securities/:security_id/download(.:format) */
export function platformSecurityDownloadPath(security_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'securities' +
    '/' +
    security_id +
    '/' +
    'download' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['security_id', 'format'])
  );
}

/** /platform/securities(.:format) */
export function platformSecuritiesPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'securities' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/securities/new(.:format) */
export function newPlatformSecurityPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'securities' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/securities/:id/edit(.:format) */
export function editPlatformSecurityPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'securities' +
    '/' +
    id +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /platform/securities/:id(.:format) */
export function platformSecurityPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'securities' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /platform/security_classification_states/classification_uploads(.:format) */
export function platformSecurityClassificationStatesClassificationUploadsPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'security_classification_states' +
    '/' +
    'classification_uploads' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/security_classification_states/classification_uploads/:id(.:format) */
export function platformSecurityClassificationStatesClassificationUploadPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'security_classification_states' +
    '/' +
    'classification_uploads' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /platform/searches(.:format) */
export function platformSearchesPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'searches' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/template_action_points(.:format) */
export function platformTemplateActionPointsPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'template_action_points' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/trackers/edit(.:format) */
export function editPlatformTrackersPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'trackers' +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/trackers(.:format) */
export function platformTrackersPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'trackers' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/trackers/:id(.:format) */
export function platformTrackerPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'trackers' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /platform/users/:user_id/invite(.:format) */
export function platformUserInvitePath(user_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'users' +
    '/' +
    user_id +
    '/' +
    'invite' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['user_id', 'format'])
  );
}

/** /platform/users/:user_id/login_as(.:format) */
export function platformUserLoginAsPath(user_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'users' +
    '/' +
    user_id +
    '/' +
    'login_as' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['user_id', 'format'])
  );
}

/** /platform/users(.:format) */
export function platformUsersPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'users' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/users/new(.:format) */
export function newPlatformUserPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'users' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/users/:id/edit(.:format) */
export function editPlatformUserPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'users' +
    '/' +
    id +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /platform/users/:id(.:format) */
export function platformUserPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'users' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /platform/xero_tenants/:xero_tenant_id/branding_themes(.:format) */
export function platformXeroTenantBrandingThemesPath(xero_tenant_id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'xero_tenants' +
    '/' +
    xero_tenant_id +
    '/' +
    'branding_themes' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['xero_tenant_id', 'format'])
  );
}

/** /platform/xero_tenants(.:format) */
export function platformXeroTenantsPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'xero_tenants' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/xero_tenants/new(.:format) */
export function newPlatformXeroTenantPath(options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'xero_tenants' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /platform/xero_tenants/:id/edit(.:format) */
export function editPlatformXeroTenantPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'xero_tenants' +
    '/' +
    id +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /platform/xero_tenants/:id(.:format) */
export function platformXeroTenantPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'platform' +
    '/' +
    'xero_tenants' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /portal(.:format) */
export function portalRootPath(options?: object): string {
  return (
    '/' +
    'portal' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /portal/downloads(.:format) */
export function portalDownloadsPath(options?: object): string {
  return (
    '/' +
    'portal' +
    '/' +
    'downloads' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /portal/clients/:client_id/aum_breakdown(.:format) */
export function portalClientAumBreakdownPath(client_id: ScalarType, options?: object): string {
  return (
    '/' +
    'portal' +
    '/' +
    'clients' +
    '/' +
    client_id +
    '/' +
    'aum_breakdown' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['client_id', 'format'])
  );
}

/** /portal/clients/:client_id/manager_breakdowns(.:format) */
export function portalClientManagerBreakdownsPath(client_id: ScalarType, options?: object): string {
  return (
    '/' +
    'portal' +
    '/' +
    'clients' +
    '/' +
    client_id +
    '/' +
    'manager_breakdowns' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['client_id', 'format'])
  );
}

/** /portal/clients/:client_id/portfolios/:id/structure(.:format) */
export function structurePortalClientPortfolioPath(client_id: ScalarType, id: ScalarType, options?: object): string {
  return (
    '/' +
    'portal' +
    '/' +
    'clients' +
    '/' +
    client_id +
    '/' +
    'portfolios' +
    '/' +
    id +
    '/' +
    'structure' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['client_id', 'id', 'format'])
  );
}

/** /portal/clients/:client_id/portfolios/:portfolio_id/action_points/:id(.:format) */
export function portalClientPortfolioActionPointPath(
  client_id: ScalarType,
  portfolio_id: ScalarType,
  id: ScalarType,
  options?: object,
): string {
  return (
    '/' +
    'portal' +
    '/' +
    'clients' +
    '/' +
    client_id +
    '/' +
    'portfolios' +
    '/' +
    portfolio_id +
    '/' +
    'action_points' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['client_id', 'portfolio_id', 'id', 'format'])
  );
}

/** /portal/clients/:client_id/portfolios/:portfolio_id/drawdown_series(.:format) */
export function portalClientPortfolioDrawdownSeriesIndexPath(
  client_id: ScalarType,
  portfolio_id: ScalarType,
  options?: object,
): string {
  return (
    '/' +
    'portal' +
    '/' +
    'clients' +
    '/' +
    client_id +
    '/' +
    'portfolios' +
    '/' +
    portfolio_id +
    '/' +
    'drawdown_series' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['client_id', 'portfolio_id', 'format'])
  );
}

/** /portal/clients/:client_id/portfolios/:portfolio_id/return_series(.:format) */
export function portalClientPortfolioReturnSeriesIndexPath(
  client_id: ScalarType,
  portfolio_id: ScalarType,
  options?: object,
): string {
  return (
    '/' +
    'portal' +
    '/' +
    'clients' +
    '/' +
    client_id +
    '/' +
    'portfolios' +
    '/' +
    portfolio_id +
    '/' +
    'return_series' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['client_id', 'portfolio_id', 'format'])
  );
}

/** /portal/clients/:client_id/portfolios/:portfolio_id/return_metrics(.:format) */
export function portalClientPortfolioReturnMetricsPath(
  client_id: ScalarType,
  portfolio_id: ScalarType,
  options?: object,
): string {
  return (
    '/' +
    'portal' +
    '/' +
    'clients' +
    '/' +
    client_id +
    '/' +
    'portfolios' +
    '/' +
    portfolio_id +
    '/' +
    'return_metrics' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['client_id', 'portfolio_id', 'format'])
  );
}

/** /portal/clients/:client_id/portfolios/:portfolio_id/risk_metrics(.:format) */
export function portalClientPortfolioRiskMetricsPath(
  client_id: ScalarType,
  portfolio_id: ScalarType,
  options?: object,
): string {
  return (
    '/' +
    'portal' +
    '/' +
    'clients' +
    '/' +
    client_id +
    '/' +
    'portfolios' +
    '/' +
    portfolio_id +
    '/' +
    'risk_metrics' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['client_id', 'portfolio_id', 'format'])
  );
}

/** /portal/clients/:client_id/portfolios/:portfolio_id/risk_return_series(.:format) */
export function portalClientPortfolioRiskReturnSeriesIndexPath(
  client_id: ScalarType,
  portfolio_id: ScalarType,
  options?: object,
): string {
  return (
    '/' +
    'portal' +
    '/' +
    'clients' +
    '/' +
    client_id +
    '/' +
    'portfolios' +
    '/' +
    portfolio_id +
    '/' +
    'risk_return_series' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['client_id', 'portfolio_id', 'format'])
  );
}

/** /portal/clients/:client_id/portfolios/:portfolio_id/currency_allocations(.:format) */
export function portalClientPortfolioCurrencyAllocationsPath(
  client_id: ScalarType,
  portfolio_id: ScalarType,
  options?: object,
): string {
  return (
    '/' +
    'portal' +
    '/' +
    'clients' +
    '/' +
    client_id +
    '/' +
    'portfolios' +
    '/' +
    portfolio_id +
    '/' +
    'currency_allocations' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['client_id', 'portfolio_id', 'format'])
  );
}

/** /portal/clients/:client_id/portfolios/:portfolio_id/asset_allocations(.:format) */
export function portalClientPortfolioAssetAllocationsPath(
  client_id: ScalarType,
  portfolio_id: ScalarType,
  options?: object,
): string {
  return (
    '/' +
    'portal' +
    '/' +
    'clients' +
    '/' +
    client_id +
    '/' +
    'portfolios' +
    '/' +
    portfolio_id +
    '/' +
    'asset_allocations' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['client_id', 'portfolio_id', 'format'])
  );
}

/** /portal/clients/:client_id/portfolios/:portfolio_id/strategic_allocations(.:format) */
export function portalClientPortfolioStrategicAllocationsPath(
  client_id: ScalarType,
  portfolio_id: ScalarType,
  options?: object,
): string {
  return (
    '/' +
    'portal' +
    '/' +
    'clients' +
    '/' +
    client_id +
    '/' +
    'portfolios' +
    '/' +
    portfolio_id +
    '/' +
    'strategic_allocations' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['client_id', 'portfolio_id', 'format'])
  );
}

/** /portal/clients/:client_id/portfolios/:portfolio_id/sub_asset_allocations(.:format) */
export function portalClientPortfolioSubAssetAllocationsPath(
  client_id: ScalarType,
  portfolio_id: ScalarType,
  options?: object,
): string {
  return (
    '/' +
    'portal' +
    '/' +
    'clients' +
    '/' +
    client_id +
    '/' +
    'portfolios' +
    '/' +
    portfolio_id +
    '/' +
    'sub_asset_allocations' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['client_id', 'portfolio_id', 'format'])
  );
}

/** /portal/clients/:client_id/portfolios/:id(.:format) */
export function portalClientPortfolioPath(client_id: ScalarType, id: ScalarType, options?: object): string {
  return (
    '/' +
    'portal' +
    '/' +
    'clients' +
    '/' +
    client_id +
    '/' +
    'portfolios' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['client_id', 'id', 'format'])
  );
}

/** /portal/clients/:id(.:format) */
export function portalClientPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'portal' +
    '/' +
    'clients' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /portal/action_points(.:format) */
export function portalActionPointsPath(options?: object): string {
  return (
    '/' +
    'portal' +
    '/' +
    'action_points' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /portal/market_resources(.:format) */
export function portalMarketResourcesPath(options?: object): string {
  return (
    '/' +
    'portal' +
    '/' +
    'market_resources' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /portal/sign_in(.:format) */
export function newUserSessionPath(options?: object): string {
  return (
    '/' +
    'portal' +
    '/' +
    'sign_in' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /portal/sign_in(.:format) */
export function userSessionPath(options?: object): string {
  return (
    '/' +
    'portal' +
    '/' +
    'sign_in' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /portal/sign_out(.:format) */
export function destroyUserSessionPath(options?: object): string {
  return (
    '/' +
    'portal' +
    '/' +
    'sign_out' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /portal/password/new(.:format) */
export function newUserPasswordPath(options?: object): string {
  return (
    '/' +
    'portal' +
    '/' +
    'password' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /portal/password/edit(.:format) */
export function editUserPasswordPath(options?: object): string {
  return (
    '/' +
    'portal' +
    '/' +
    'password' +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /portal/password(.:format) */
export function userPasswordPath(options?: object): string {
  return (
    '/' +
    'portal' +
    '/' +
    'password' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /portal/unlock/new(.:format) */
export function newUserUnlockPath(options?: object): string {
  return (
    '/' +
    'portal' +
    '/' +
    'unlock' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /portal/unlock(.:format) */
export function userUnlockPath(options?: object): string {
  return (
    '/' +
    'portal' +
    '/' +
    'unlock' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /portal/invitation/accept(.:format) */
export function acceptUserInvitationPath(options?: object): string {
  return (
    '/' +
    'portal' +
    '/' +
    'invitation' +
    '/' +
    'accept' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /portal/invitation/remove(.:format) */
export function removeUserInvitationPath(options?: object): string {
  return (
    '/' +
    'portal' +
    '/' +
    'invitation' +
    '/' +
    'remove' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /portal/invitation/new(.:format) */
export function newUserInvitationPath(options?: object): string {
  return (
    '/' +
    'portal' +
    '/' +
    'invitation' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /portal/invitation(.:format) */
export function userInvitationPath(options?: object): string {
  return (
    '/' +
    'portal' +
    '/' +
    'invitation' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /users/token_verifications/new/resend(.:format) */
export function resendNewUsersTokenVerificationPath(options?: object): string {
  return (
    '/' +
    'users' +
    '/' +
    'token_verifications' +
    '/' +
    'new' +
    '/' +
    'resend' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /users/token_verifications(.:format) */
export function usersTokenVerificationsPath(options?: object): string {
  return (
    '/' +
    'users' +
    '/' +
    'token_verifications' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /users/token_verifications/new(.:format) */
export function newUsersTokenVerificationPath(options?: object): string {
  return (
    '/' +
    'users' +
    '/' +
    'token_verifications' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /users/terms_and_conditions(.:format) */
export function usersTermsAndConditionsPath(options?: object): string {
  return (
    '/' +
    'users' +
    '/' +
    'terms_and_conditions' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /users/terms_and_conditions/new(.:format) */
export function newUsersTermsAndConditionPath(options?: object): string {
  return (
    '/' +
    'users' +
    '/' +
    'terms_and_conditions' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /static_assets(.:format) */
export function staticAssetsPath(options?: object): string {
  return (
    '/' +
    'static_assets' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /rails/action_mailbox/postmark/inbound_emails(.:format) */
export function railsPostmarkInboundEmailsPath(options?: object): string {
  return (
    '/' +
    'rails' +
    '/' +
    'action_mailbox' +
    '/' +
    'postmark' +
    '/' +
    'inbound_emails' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /rails/action_mailbox/relay/inbound_emails(.:format) */
export function railsRelayInboundEmailsPath(options?: object): string {
  return (
    '/' +
    'rails' +
    '/' +
    'action_mailbox' +
    '/' +
    'relay' +
    '/' +
    'inbound_emails' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /rails/action_mailbox/sendgrid/inbound_emails(.:format) */
export function railsSendgridInboundEmailsPath(options?: object): string {
  return (
    '/' +
    'rails' +
    '/' +
    'action_mailbox' +
    '/' +
    'sendgrid' +
    '/' +
    'inbound_emails' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /rails/action_mailbox/mandrill/inbound_emails(.:format) */
export function railsMandrillInboundHealthCheckPath(options?: object): string {
  return (
    '/' +
    'rails' +
    '/' +
    'action_mailbox' +
    '/' +
    'mandrill' +
    '/' +
    'inbound_emails' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /rails/action_mailbox/mandrill/inbound_emails(.:format) */
export function railsMandrillInboundEmailsPath(options?: object): string {
  return (
    '/' +
    'rails' +
    '/' +
    'action_mailbox' +
    '/' +
    'mandrill' +
    '/' +
    'inbound_emails' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /rails/action_mailbox/mailgun/inbound_emails/mime(.:format) */
export function railsMailgunInboundEmailsPath(options?: object): string {
  return (
    '/' +
    'rails' +
    '/' +
    'action_mailbox' +
    '/' +
    'mailgun' +
    '/' +
    'inbound_emails' +
    '/' +
    'mime' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /rails/conductor/action_mailbox/inbound_emails(.:format) */
export function railsConductorInboundEmailsPath(options?: object): string {
  return (
    '/' +
    'rails' +
    '/' +
    'conductor' +
    '/' +
    'action_mailbox' +
    '/' +
    'inbound_emails' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /rails/conductor/action_mailbox/inbound_emails/new(.:format) */
export function newRailsConductorInboundEmailPath(options?: object): string {
  return (
    '/' +
    'rails' +
    '/' +
    'conductor' +
    '/' +
    'action_mailbox' +
    '/' +
    'inbound_emails' +
    '/' +
    'new' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}

/** /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format) */
export function editRailsConductorInboundEmailPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'rails' +
    '/' +
    'conductor' +
    '/' +
    'action_mailbox' +
    '/' +
    'inbound_emails' +
    '/' +
    id +
    '/' +
    'edit' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /rails/conductor/action_mailbox/inbound_emails/:id(.:format) */
export function railsConductorInboundEmailPath(id: ScalarType, options?: object): string {
  return (
    '/' +
    'rails' +
    '/' +
    'conductor' +
    '/' +
    'action_mailbox' +
    '/' +
    'inbound_emails' +
    '/' +
    id +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['id', 'format'])
  );
}

/** /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format) */
export function railsConductorInboundEmailReroutePath(inbound_email_id: ScalarType, options?: object): string {
  return (
    '/' +
    'rails' +
    '/' +
    'conductor' +
    '/' +
    'action_mailbox' +
    '/' +
    inbound_email_id +
    '/' +
    'reroute' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['inbound_email_id', 'format'])
  );
}

/** /rails/active_storage/blobs/:signed_id/*filename(.:format) */
export function railsServiceBlobPath(signed_id: ScalarType, filename: ScalarType[], options?: object): string {
  return (
    '/' +
    'rails' +
    '/' +
    'active_storage' +
    '/' +
    'blobs' +
    '/' +
    signed_id +
    '/' +
    filename.map((part) => $encode(part)).join('/') +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['signed_id', 'filename', 'format'])
  );
}

/** /rails/active_storage/representations/:signed_blob_id/:variation_key/*filename(.:format) */
export function railsBlobRepresentationPath(
  signed_blob_id: ScalarType,
  variation_key: ScalarType,
  filename: ScalarType[],
  options?: object,
): string {
  return (
    '/' +
    'rails' +
    '/' +
    'active_storage' +
    '/' +
    'representations' +
    '/' +
    signed_blob_id +
    '/' +
    variation_key +
    '/' +
    filename.map((part) => $encode(part)).join('/') +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['signed_blob_id', 'variation_key', 'filename', 'format'])
  );
}

/** /rails/active_storage/disk/:encoded_key/*filename(.:format) */
export function railsDiskServicePath(encoded_key: ScalarType, filename: ScalarType[], options?: object): string {
  return (
    '/' +
    'rails' +
    '/' +
    'active_storage' +
    '/' +
    'disk' +
    '/' +
    encoded_key +
    '/' +
    filename.map((part) => $encode(part)).join('/') +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['encoded_key', 'filename', 'format'])
  );
}

/** /rails/active_storage/disk/:encoded_token(.:format) */
export function updateRailsDiskServicePath(encoded_token: ScalarType, options?: object): string {
  return (
    '/' +
    'rails' +
    '/' +
    'active_storage' +
    '/' +
    'disk' +
    '/' +
    encoded_token +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['encoded_token', 'format'])
  );
}

/** /rails/active_storage/direct_uploads(.:format) */
export function railsDirectUploadsPath(options?: object): string {
  return (
    '/' +
    'rails' +
    '/' +
    'active_storage' +
    '/' +
    'direct_uploads' +
    ($hasPresentOwnProperty(options, 'format') ? '.' + (options as any).format : '') +
    $buildOptions(options, ['format'])
  );
}
