import * as React from 'react';

import { Alert, Intent } from '@blueprintjs/core';
import Loading from 'components/shared/Loading';

export default function confirm({ alertOpen, handleAlertClose, confirmSubmit, isSubmitting }) {
  const confirmMessage = (
    <div>
      <h4 className="mar-t-0">Are you sure you want to update the portfolio?</h4>
      <p>
        Changes to the portfolio will cause any reviews currently in the 'Verification' or 'Ready to Send' stages to be
        moved back to 'In Review'. It will also regenerate commentary, system generated action points and the rating.
      </p>
    </div>
  );

  return (
    <Alert
      isOpen={alertOpen}
      onClose={handleAlertClose}
      canEscapeKeyCancel
      canOutsideClickCancel
      cancelButtonText="Cancel"
      confirmButtonText="Submit"
      intent={Intent.PRIMARY}
      onCancel={handleAlertClose}
      onConfirm={confirmSubmit}
      portalContainer={document.body}
      icon="updated"
    >
      {isSubmitting ? <Loading /> : confirmMessage}
    </Alert>
  );
}
