import * as React from 'react';

import { useDefaultTheme } from 'components/shared/colours';
import { dataBenchmarkColourGenerator } from 'components/shared/colours/hacks/benchmarkColourGenerator';

import useRadioSelect from 'components/shared/customHooks/useRadioSelect';
import useAjaxRequest from 'components/shared/customHooks/useAjaxRequest';
import Frow from '../../../../frow/Frow';
import LineChartWithModal from 'components/shared/charting/composed/LineChartWithModal';
import { SubtitleTwo } from 'components/shared/Typography';

export interface IPlatformPerformanceChart {
  path: string;
}

const PlatformPerformanceChart = ({ path }: IPlatformPerformanceChart) => {
  const availableTimePeriods = [
    { label: 'Review', value: 'last_review' },
    { label: 'Inception', value: 'inception' },
  ];
  const [chartData, setChartData] = React.useState();
  const [radioToggle, selectedRadioRef] = useRadioSelect(availableTimePeriods, 0);

  interface IRequestParams {
    time_period: string;
  }

  const [getChartData, isError, isLoading] = useAjaxRequest<IRequestParams, any>({
    method: 'GET',
    path,
    stateSetter: setChartData,
  });

  React.useEffect(() => {
    getChartData({ time_period: availableTimePeriods[selectedRadioRef.current].value });
  }, [selectedRadioRef.current]);

  const lineStyles = chartData && dataBenchmarkColourGenerator(useDefaultTheme, chartData.data);

  const chartContentProps = {
    chartData: chartData && chartData.data,
    isLoading,
    lineStyles,
    radioToggle,
  };

  const chartContent = isError ? (
    <p className="text-white helper-text mar-t-3">No data to display</p>
  ) : (
    <LineChartWithModal {...chartContentProps} />
  );

  return (
    <div className="col-md-1-3">
      <div className="platform-content platform-content--padding-all platform-content--border-bottom-at-md h-100 platform-content--border-right-md">
        <Frow justifyContent="between">
          <SubtitleTwo text="Performance" additionalClasses="text-white" />
          <div>{radioToggle}</div>
        </Frow>
        {chartContent}
      </div>
    </div>
  );
};

export default PlatformPerformanceChart;
