import * as React from 'react';

export default function columns() {
  return [
    {
      Header: 'Name',
      accessor: 'fullName',
      columnName: 'Full Name',
      id: 'first_name',
    },
    {
      Header: 'Email',
      accessor: 'email',
      columnName: 'Email',
      id: 'email',
    },
    {
      Header: 'Primary Portfolio Count',
      accessor: 'primaryCount',
      columnName: 'Primary Portfolio Count',
      id: 'primary_count',
    },
    {
      Header: 'Secondary Portfolio Count',
      accessor: 'secondaryCount',
      columnName: 'Secondary Portfolio Count',
      id: 'secondary_count',
    },
    {
      Header: 'EMAP Portfolio Count',
      accessor: 'emapCount',
      columnName: 'EMAP Portfolio Count',
      id: 'emap_count',
    },
    {
      Header: 'Client(s)',
      accessor: 'clients',
      columnName: 'Client(s)',
      id: 'clients',
      sortable: false,
    },
    {
      Header: 'Deactivated',
      accessor: 'discarded',
      columnName: 'Deactivated',
      id: 'deactivated',
      sortable: false,
      Cell: (row) => (row.original.discarded ? 'True' : 'False'),
      filterOptions: {
        key: 'discarded',
        options: [
          { display: 'True', value: 'true' },
          { display: 'False', value: 'false' },
        ],
      },
    },
    {
      Cell: (row) => {
        return (
          <a href={row.original.showPath}>
            <div className="rt-tr-clickable-icon">
              <i className="icon-arrow-right-top icon-0-8x" />
            </div>
          </a>
        );
      },
      Header: '',
      sortable: false,
    },
  ];
}
