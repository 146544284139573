import * as React from 'react';
import * as $ from 'jquery';

import CsrfToken from 'components/shared/CsrfToken';

import {
  bulkUpdatePlatformAdministratorsNotificationsPath,
  platformAdministratorsNotificationPath,
} from 'javascript/application/ts_routes';

import Presenter from './list/Presenter';

import { Notification } from 'javascript/models';

export default function list() {
  const [notifications, setNotifications] = React.useState([]);

  React.useEffect(() => {
    getNotifications();
  }, []);

  async function getNotifications() {
    const { data } = await Notification.order({ notify_on: 'desc' }).order({ id: 'desc' }).per(100).all();

    setNotifications(data);
  }

  function markAllAsRead() {
    $.ajax({
      data: { authenticity_token: CsrfToken() },
      dataType: 'json',
      success: () => {
        const updatedNotifications = [
          ...notifications.map((notification) => {
            notification.viewed = 1;
            return notification;
          }),
        ];
        setNotifications(updatedNotifications);
      },
      type: 'PATCH',
      url: bulkUpdatePlatformAdministratorsNotificationsPath(),
    });
  }

  async function toggleReadStatus(id: string) {
    $.ajax({
      data: { authenticity_token: CsrfToken() },
      dataType: 'json',
      success: () => {
        const updatedNotifications = [
          ...notifications.map((notification) => {
            if (notification.id === id) {
              notification.viewed = !notification.viewed;
            }
            return notification;
          }),
        ];
        setNotifications(updatedNotifications);
      },
      type: 'PATCH',
      url: platformAdministratorsNotificationPath(id),
    });
  }

  return <Presenter {...{ markAllAsRead, notifications, toggleReadStatus }} />;
}
