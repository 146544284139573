import * as React from 'react';

const Context = React.createContext({
  props: {
    administrators: [],
    canManageReviews: false,
    companyId: null,
    currencyOptions: [],
    currentUser: '',
    currentUserId: null,
    defaultReviewId: null,
    mostRecentHoldingsDate: null,
    portfolio: null,
    terminationReasons: [],
  },
  state: {},
});

export default Context;
