import * as React from 'react';
import * as $ from 'jquery';

import Alert from 'components/shared/Alert';
import CustomModal from 'components/shared/CustomModal';
import Form from './Form';
import FormTag from 'components/shared/forms/FormTag';
import { ajaxErrorString } from 'components/shared/Utils';

import { Note } from 'javascript/models';

import { platformNotePath } from 'javascript/application/ts_routes';
import ISelectOption from 'components/interfaces/ISelectOption';

interface IProps {
  note: Note;
  isOpen: boolean;
  tags: ISelectOption[];
  handleClose(): void;
  handleSuccessfulFormSubmission(): void;
}

export default function edit(props: IProps) {
  const { isOpen, handleClose, note, tags } = props;

  const [errorMessage, setErrorMessage] = React.useState(null);
  const [files, setFiles] = React.useState([]);
  const [updateOnClose, setUpdateOnClose] = React.useState();

  function handleUploadedFiles(files) {
    setFiles(files);
  }

  function handleFormSubmission(event) {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);
    files.forEach((file) => {
      formData.append('note[attachments][]', file);
    });
    $.ajax({
      beforeSend: () => setErrorMessage(null),
      contentType: false,
      data: formData,
      dataType: 'json',
      error: (jqXhr) => setErrorMessage(ajaxErrorString(jqXhr)),
      processData: false,
      success: () => {
        handleClose();
        props.handleSuccessfulFormSubmission();
      },
      type: form.method,
      url: form.action,
    });
  }

  return (
    <React.Fragment>
      <CustomModal isOpen={isOpen} title="Note" handleClose={handleClose}>
        <div className="modal__content">
          <Alert message={errorMessage} modifier="dark" title="Missing Fields" type="error" />
          <FormTag
            method="patch"
            url={platformNotePath(note.id)}
            className="form--light"
            onSubmit={handleFormSubmission}
          >
            <Form
              handleUploadedFiles={handleUploadedFiles}
              handleSuccessfulFormSubmission={props.handleSuccessfulFormSubmission}
              setUpdateOnClose={setUpdateOnClose}
              {...{ handleClose, note, tags }}
            />
          </FormTag>
        </div>
      </CustomModal>
    </React.Fragment>
  );
}
