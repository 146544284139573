import * as React from 'react';

import Presenter from './show/Presenter';

import { PortfolioReturn } from 'javascript/models';

interface IProps {
  id: string;
}

export default function show(props: IProps) {
  const { id } = props;

  const [isLoading, setIsLoading] = React.useState(true);
  const [portfolioReturns, setPortfolioReturns] = React.useState([]);

  React.useEffect(() => {
    getPortfolioReturns();
  }, []);

  async function getPortfolioReturns() {
    const { data } = await PortfolioReturn.where({
      portfolio_returnable_type: 'PortfolioBenchmark',
      portfolio_returnable_id: id,
    })
      .order({ value_date: 'desc' })
      .per(2)
      .all();

    setPortfolioReturns(data);
    setIsLoading(false);
  }

  return <Presenter {...{ id, isLoading, portfolioReturns }} />;
}
