import * as React from 'react';
import * as $ from 'jquery';

import Presenter from './table/Presenter';

import ICompanies from 'components/interfaces/ICompanies';

interface IProps {
  path: string;
}

interface IState {
  data: ICompanies['data'];
  isLoading: boolean;
  meta: any;
}

class Table extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      data: [],
      isLoading: true,
      meta: null,
    };
  }

  componentDidMount() {
    $.ajax({
      url: this.props.path,
      type: 'GET',
      dataType: 'json',
      cache: false,
      beforeSend: () => this.setState({ isLoading: true }),
      success: (response: ICompanies) => {
        this.setState({ meta: response.meta, data: response.data });
      },
      complete: () => this.setState({ isLoading: false }),
    });
  }

  render() {
    const { data, isLoading, meta } = this.state;
    return <Presenter {...{ data, isLoading, meta }} />;
  }
}

export default Table;
