/* eslint-disable  */
import * as React from 'react';

import TextareaAutosize from 'react-autosize-textarea';
import Button from 'components/shared/forms/Button';

import { ActionPoint } from 'javascript/models';
import { Note } from 'javascript/models';

import ThemeContext from 'components/shared/context/ThemeContext';

import Frow from '../../../frow/Frow';
import Column from '../../../frow/Column';
import FileUpload from './form/FileUpload';

export interface ICompletionFormProps {
  actionPoint: ActionPoint;
}

const CompletionForm = (props: ICompletionFormProps) => {
  const { actionPoint } = props;

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const theme = React.useContext(ThemeContext);

  const note = React.useMemo(() => new Note({ notable: actionPoint }), []);

  actionPoint.completeNotes = [note];

  const buttonAction = async () => {
    setIsSubmitting(true);

    actionPoint.completed = true;
    actionPoint.completedNote = note.body;
    actionPoint.assigneeId = null;
    actionPoint.assigneeType = null;

    await actionPoint.save();

    setIsSubmitting(false);
  };

  const updateNote = (event) => (note.body = event.target.value);

  return (
    <div className={`form--${theme.classNameModifier}`}>
      <Frow gutterSize={2} columnContext={{ breakpointSize: 'mc', columnSpan: 1, maxColumns: 1 }}>
        <Column>
          <label htmlFor="completed_note" className="form__label">
            Completion Note
          </label>
          <TextareaAutosize
            defaultValue=""
            style={{ minHeight: 110, maxHeight: 300 }}
            name="action_point[completed_note]"
            cols={10}
            id="completed_note"
            onChange={updateNote}
          />
        </Column>
        <Column>
          <label className="form__label">Attachments</label>
          <FileUpload model={note} accessor="attachments" />
        </Column>
      </Frow>
      <Frow gutterSize={1}>
        <Column>
          <Button
            buttonClasses={`button--full-width button--${theme.classNameModifier}-themed`}
            style={{ minWidth: 'unset' }}
            text="Complete"
            isSubmitting={isSubmitting}
            isDisabled={false}
            handleClick={buttonAction}
            submittingText="Completing"
          />
        </Column>
      </Frow>
    </div>
  );
};

export default CompletionForm;
