import * as React from 'react';

import { format } from 'date-fns';

export default function queueSelect({
  handleSubOptionUpdate,
  selectedQueue,
  portfolioStatus,
  queueOptions,
  updateSelectedQueue,
}) {
  const queueSelects = queueOptions.map((option) => {
    const dropdownOptions = (option.subOptions || []).map((subOption) => {
      function handleOptionClick(event) {
        event.stopPropagation();
        handleSubOptionUpdate(subOption.value);
      }

      return (
        <div
          key={subOption.value}
          className={`button-group__button ${portfolioStatus === subOption.value && 'button-group__button--active'}`}
          onClick={handleOptionClick}
        >
          {subOption.label}
        </div>
      );
    });

    const active = option.value === selectedQueue;
    function handleClick() {
      updateSelectedQueue(option.value);
    }
    return (
      <div
        key={option.value}
        className={`button-group__button dropdown dropdown--hover dropdown--sw ${
          active && 'button-group__button--active'
        }`}
        onClick={handleClick}
        style={{ flex: 'inherit' }}
      >
        {option.label}
        {option.subOptions && <i className="mar-l-1 button__icon icon-chevron-down icon-tiny icon-push-up-2" />}
        {option.subOptions && <div className="dropdown__content">{dropdownOptions}</div>}
      </div>
    );
  });

  return (
    <div className="platform-panel platform-panel--no-background">
      <div className="frow frow--items-center">
        <div>
          <p className="subtitle-two">{format(new Date(), 'dddd[,] DD MMMM YYYY')}</p>
          <h2 className="heading-one text-lh-1">Reviews</h2>
        </div>
        <div>
          <span className="horizontal-divider hide-md-inline" />
        </div>
        <div className="frow frow--direction-column">
          <p className="subtitle-two">Switch Queue</p>
          <div className="button-group">{queueSelects}</div>
        </div>
      </div>
    </div>
  );
}
