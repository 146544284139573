import { Attr, Model } from 'spraypaint';

import { ApplicationRecord } from './ApplicationRecord';

@Model()
export class PortfolioManualUpload extends ApplicationRecord {
  public static jsonapiType = 'portfolio_manual_uploads';
  @Attr() public portfolioId: string;
  @Attr() public administratorId: string;
  @Attr() public import: string;
}
