import * as React from 'react';

const classNames = require('classnames');

import { Portfolio } from 'javascript/models';

import Assets from './allocations/Assets';
import Currencies from './allocations/Currencies';
import Holdings from './allocations/Holdings';
import PrivateAssets from './allocations/PrivateAssets';

import Context from './../Context';

export default function allocations() {
  const context = React.useContext(Context);
  const portfolio: Portfolio = context.props.portfolio;
  const [tab, setTab] = React.useState('assets');

  function handleClick() {
    setTab(this);
  }

  const tabOptions = ['assets', 'currencies', 'holdings'];
  if (portfolio.attributes.consolidated) tabOptions.push('private assets');

  const options = tabOptions.map((title, index) => {
    const buttonClasses = classNames('button-group__button', { 'button-group__button--active': tab === title });
    return (
      <div key={index} className={buttonClasses} onClick={handleClick.bind(title)}>
        {title}
      </div>
    );
  });

  return (
    <div>
      <div className="platform-panel__inner">
        <div className="button-group mar-v-2">{options}</div>
        <div style={{ display: tab === 'assets' ? 'block' : 'none' }}>
          <Assets />
        </div>
        <div style={{ display: tab === 'currencies' ? 'block' : 'none' }}>
          <Currencies />
        </div>
        <div style={{ display: tab === 'holdings' ? 'block' : 'none' }}>
          <Holdings />
        </div>
        <div style={{ display: tab === 'private assets' ? 'block' : 'none' }}>
          <PrivateAssets {...{ portfolio }} />
        </div>
      </div>
    </div>
  );
}
