import * as React from 'react';

import { compose, prop, sum, map } from 'ramda';

import { startCase } from 'lodash';

import { useDefaultTheme } from 'components/shared/customHooks/useThemeColours';

import RangeSlider from 'components/shared/forms/RangeSlider';
import ReactPieChart from 'components/shared/charting/recharts/ReactPieChart';
import Loading from 'components/shared/Loading';

import { Mandate } from 'javascript/models';
import { platformPortfolioReturnSeriesIndexPath } from 'javascript/application/ts_routes';

import PlatformPerformanceChart from './PlatformPerformanceChart';
import { Column, Frow } from 'components/frow';
import { SubtitleTwo } from 'components/shared/Typography';

const mandateInformation = ({ mandate, setMandate, mandateId, portfolio }) => {
  const [isLoading, setIsLoading] = React.useState(!mandate);

  const [strategicAllocations, setStrategicAllocations] = React.useState();
  const [tacticalAllocations, setTacticalAllocations] = React.useState();

  React.useEffect(() => {
    if (!mandate) {
      getData();
    }
  }, []);

  async function getData() {
    if (!mandateId) return;

    const { data } = await Mandate.includes(['flags']).find(mandateId);

    setStrategicAllocations(await data.strategicAssetAllocations);
    setTacticalAllocations(await data.tacticalAssetAllocations);

    setMandate(data);
    setIsLoading(false);
  }

  const getColourA = useDefaultTheme('assetClass');
  const getColourB = useDefaultTheme('assetClass');

  const renderMandateInformation = () => {
    const sumAllocations = compose(sum, map(compose(prop(0), prop('values'))));
    const unassignedValue = 100 - sumAllocations(strategicAllocations);

    const strategicChartData = [
      ...strategicAllocations.map(({ assetClass, values }) => ({
        color: getColourA(assetClass),
        name: startCase(assetClass),
        value: values[0],
      })),
      { name: 'Unassigned', value: unassignedValue < 1 ? 0 : unassignedValue, color: '#ffffff' },
    ];

    const tacticalAssetAllocationSliders = tacticalAllocations.map(({ assetClass, values: defaultValue }) => {
      const marks = {};
      defaultValue.forEach((value) => (marks[value] = { style: { color: 'white' }, label: `${value}%` }));
      return (
        <Column key={assetClass}>
          <Frow gutterSize={1}>
            <Column columnSpan={1}>
              <SubtitleTwo text={startCase(assetClass)} />
            </Column>
            <Column columnSpan={2}>
              <RangeSlider backgroundColor={getColourB(assetClass)} marks={marks} disabled defaultValue={defaultValue} />
            </Column>
          </Frow>
        </Column>
      );
    });

    return (
      <React.Fragment>
        <Column breakpointSize="md" columnSpan={1}>
          <div
            className="platform-content platform-content--padding-all platform-content--border-bottom-at-md h-100
             platform-content--border-right-md"
          >
            <SubtitleTwo additionalClasses="text-white" text="Strategic Asset Allocation" />
            <ReactPieChart data={strategicChartData} displayLegend height={200} labelText="" />
          </div>
        </Column>
        <Column breakpointSize="md" columnSpan={1}>
          <div className="platform-content platform-content--padding-all platform-content--border-bottom-at-md h-100">
            <SubtitleTwo additionalClasses="mar-b-3 text-white" text="Asset Allocation Tolerances" />
            <Frow gutterSize={1}>{tacticalAssetAllocationSliders}</Frow>
          </div>
        </Column>
      </React.Fragment>
    );
  };

  return (
    <div className="platform-content platform-content--spacing-horizontal platform-content--border-bottom h-100">
      <div className="frow">
        <PlatformPerformanceChart path={platformPortfolioReturnSeriesIndexPath(portfolio.id)} />
        {isLoading ? <Loading /> : renderMandateInformation()}
      </div>
    </div>
  );
};

export default mandateInformation;
