import * as React from 'react';
import { IRevisedColumn } from '../utilities/processColumns';

export interface IProps<Data> {
  data: IRevisedColumn<Data>[];
  handleUpdateSelection(id: string[]): void;
}

export default function headerCheckbox<Data>(props: IProps<Data>) {
  const [isChecked, setIsChecked] = React.useState(false);
  const [isHUSReady, setIsHUSReady] = React.useState(false);

  React.useEffect(() => {
    setIsChecked(false);
  }, [props.data]);

  React.useEffect(() => {
    if (!isHUSReady) return setIsHUSReady(true);
    if (isChecked) {
      props.handleUpdateSelection(props.data.map((item) => item.id));
    } else {
      props.handleUpdateSelection([]);
    }
    const customEvent = new CustomEvent('checkbox:headerChecked', { detail: { checked: isChecked } });
    document.dispatchEvent(customEvent);
  }, [isChecked]);

  return (
    <span className="pretty pretty--table p-icon p-smooth mar-r-0">
      <input
        type="checkbox"
        onChange={() => {
          setIsChecked((previous) => !previous);
        }}
        checked={isChecked}
      />
      <span className="state p-primary">
        <i className="icon fa fa-check" />
        <label />
      </span>
    </span>
  );
}
