import * as React from 'react';

import CustomModal from 'components/shared/CustomModal';
import { Note } from 'javascript/models';

import Form from './Form';
import Comment from './Comment';

interface IProps {
  notes: Note[];
  handleFormSubmit(comment: string): void;
  handleDelete(noteId: string, destroy: boolean): void;
  handleUpdate?(comment: string, noteId: string);
}

export default function presenter(props: IProps) {
  const { handleDelete, handleUpdate, notes } = props;

  function handleFormSubmit(comment: string) {
    setIsOpen(false);
    props.handleFormSubmit(comment);
  }

  const [isOpen, setIsOpen] = React.useState(false);

  function handleClose() {
    setIsOpen(false);
  }

  function handleOpen() {
    setIsOpen(true);
  }

  const formattedNotes = notes.map((note) => {
    return (
      <div key={note.id} style={{ width: '100%' }}>
        <Comment {...{ handleDelete, handleUpdate, note }} />
      </div>
    );
  });

  function renderNotes() {
    if (notes.length > 0) {
      return formattedNotes;
    }

    return <p className="text-small">This review doesn't have any comments yet.</p>;
  }

  return (
    <div className="platform-content platform-content--padding-vertical">
      <div className="frow frow--items-center frow--justify-between mar-b-2">
        <h4 className="platform-panel__title">Review Comments</h4>
        <button className="button button--compact button--secondary" onClick={handleOpen}>
          Add Comment
          <i className="icon-plus icon-fw icon-push-down-1 mar-l-1" />
        </button>
        <CustomModal isOpen={isOpen} handleClose={handleClose} title="Review Comment">
          <Form handleFormSubmit={handleFormSubmit} />
        </CustomModal>
      </div>
      <div className="frow frow--gutters frow--direction-column">{renderNotes()}</div>
    </div>
  );
}
