import * as React from 'react';

import Context from './Context';
import FormRow from 'components/shared/forms/FormRow';

export default function extraSettings() {
  const context = React.useContext(Context);
  const { portfolio } = context.state;

  const clientSettingTypes = portfolio.client.extraSettingsTypes.map((extraSetting, index) => {
    const existingExtraSetting = portfolio.extraSettings.find((setting) => {
      return setting.extraSettingsTypeId === extraSetting.id;
    });

    function renderExistingId() {
      if (existingExtraSetting) {
        return (
          <input
            type="hidden"
            name={`portfolio[extra_settings_attributes][${index}][id]`}
            value={existingExtraSetting.id}
          />
        );
      }
    }

    return (
      <FormRow key={extraSetting.id}>
        <label htmlFor="" className="form__label">
          {extraSetting.settingType}
        </label>
        <input
          type="hidden"
          name={`portfolio[extra_settings_attributes][${index}][extra_settings_type_id]`}
          value={extraSetting.id}
        />
        <input
          type="text"
          name={`portfolio[extra_settings_attributes][${index}][description]`}
          defaultValue={existingExtraSetting ? existingExtraSetting.description : ''}
        />
        {renderExistingId()}
      </FormRow>
    );
  });

  return (
    <fieldset className="form__fieldset">
      <h4 className="form__fieldset-label">Extra Settings</h4>
      <div className="frow frow--gutters">{clientSettingTypes}</div>
    </fieldset>
  );
}
