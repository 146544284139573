import * as React from 'react';

export type TPossiblePromise<T> = T | Promise<T>;
export type TAsyncCallbackUpdate<T> = (arg: T) => TPossiblePromise<T>;
export type TPossibleCallbackPromise<T> = TPossiblePromise<T> | TAsyncCallbackUpdate<T>;

/*
 * Function that attempts to resolve a complex object `promiseFunction` to a value and store the value in state
 *
 * @remarks If the promise fails to resolve or resolves to undefined then the previous value will be used, if the
 *   previous value isn't available then the fallback value will be used.
 *
 * @param initialValue - A value that can either be a non-promise, a promise, or a function which resolves to
 *   non-promise or a promise.
 * @param fallback - A default value to be used if both promiseFunction and the previous value provided by setState are
 *   undefined.
 *
 * @returns - An array where the first value is the value stored in state and the second value is a setState function
 *
 * @remarks The setState function operates similarly to that of useState except if the value is a promise it'll try and
 *   resolve the promise and store that value instead. Additionally, This state function accepts a fallback value.
 *
 * @remarks The setState function may experience race conditions due to the fact it's called twice for a single change.
 */
const useAsyncState = <T,>(
  initialValue: TPossibleCallbackPromise<T>,
  fallback: T,
): [T, (newState: TPossibleCallbackPromise<T>, localFallback?: T) => void] => {
  const [state, setState] = React.useState<T>();

  const resolveAsyncState = async (previousState?: T, newState?: TPossibleCallbackPromise<T>, localFallback?: T) => {
    let result: T;

    let promise: any = newState;
    if (typeof promise === 'function') promise = promise(previousState);

    try {
      result = await promise;
    } catch (e) {
      // We use the fallback value provided when the promise fails to resolve.
    }

    if (result === undefined) result = previousState;
    if (result === undefined) result = localFallback;
    if (result === undefined) result = fallback;

    setState(result);
  };

  const setAsyncState = (newState: TPossibleCallbackPromise<T>, localFallback?: T) =>
    setState((previousState) => (resolveAsyncState(previousState, newState, localFallback), previousState));

  React.useEffect(() => (resolveAsyncState(undefined, initialValue), undefined), []);

  return [state, React.useCallback(setAsyncState, [])];
};

export default useAsyncState;
