import * as React from 'react';

import FormRow from 'components/shared/forms/FormRow';
import useDebounce from 'components/shared/customHooks/useDebounce';

export default function general({ reportTemplate }) {
  const [name, setName] = React.useState('');
  const [displayName, setDisplayName] = React.useState('');
  const [reportName, setReportName] = React.useState('');

  React.useEffect(() => {
    if (reportTemplate) {
      setName(reportTemplate.name);
      setDisplayName(reportTemplate.displayName);
      setReportName(reportTemplate.reportType);
    }
  }, [reportTemplate]);

  const debouncedNameValue = useDebounce(name, 800);
  const debouncedDisplayNameValue = useDebounce(displayName, 800);
  const debouncedReportNameValue = useDebounce(reportName, 800);

  function handleNameChange(event) {
    setName(event.target.value);
  }

  function handleDisplayNameChange(event) {
    setDisplayName(event.target.value);
  }

  function handleReportNameChange(event) {
    setReportName(event.target.value);
  }

  React.useEffect(() => {
    if (debouncedNameValue) {
      updateTemplate();
    }
  }, [debouncedNameValue, debouncedDisplayNameValue, debouncedReportNameValue]);

  async function updateTemplate() {
    reportTemplate.name = name;
    reportTemplate.displayName = displayName;
    reportTemplate.reportType = reportName;
    reportTemplate.save();
  }

  return (
    <div className="form">
      <fieldset className="form__fieldset">
        <h4 className="form__fieldset-label">General Details</h4>
        <div className="frow frow--gutters">
          <FormRow>
            <label htmlFor="" className="form__label">
              Template Name
            </label>
            <input type="text" onChange={handleNameChange} defaultValue={name} />
          </FormRow>
          <FormRow note="The report name that will appear on the report">
            <label htmlFor="" className="form__label">
              Report Name
            </label>
            <input type="text" onChange={handleReportNameChange} defaultValue={reportName} />
          </FormRow>
          <FormRow note="The name that will appear on the report">
            <label htmlFor="" className="form__label">
              Display Name
            </label>
            <input type="text" onChange={handleDisplayNameChange} defaultValue={displayName} />
          </FormRow>
        </div>
      </fieldset>
    </div>
  );
}
