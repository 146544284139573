import * as React from 'react';

import { Intent, Menu, MenuItem, Popover } from '@blueprintjs/core';

import { compose, fromPairs, map, omit, remove, toPairs, update } from 'ramda';

import { Frow, Column } from 'components/frow';

import { ReportTemplatesReportTemplateSection } from 'javascript/models';

const List = ({ attributes, handleChange, handleManualChange }) => {
  const [listItems, setListItems] = React.useState(attributes.listItems || []);
  const [listItemParameters, setListItemParameters] = React.useState(attributes.listItemParameters || {});

  React.useEffect(() => {
    handleManualChange({ list_items: listItems, list_item_parameters: listItemParameters }, 'listDetails');
  }, [listItems, listItemParameters]);

  const decrementIdxIfGreaterThan = (condition) => ([key, value]) => [key - +(key > condition), value];
  const adjustIndicies = (index: number) => compose(fromPairs, map(decrementIdxIfGreaterThan(index)), toPairs);

  const adjustParameterIndicies = (removedIndex: number) =>
    setListItemParameters((previous) => adjustIndicies(removedIndex)(omit(removedIndex, previous)));

  const addListItem = React.useCallback(() => setListItems((previous) => [...previous, '']), []);
  const removeItem = (index: number) => {
    setListItems(remove(index, 1));
    adjustParameterIndicies(index);
  };
  const updateColumn = (value, index: number) => setListItems(update(index, value));

  const updateParameters = (parameterKey: string | number) => (index: number, parameterValue) =>
    setListItemParameters((previous) => ({
      ...previous,
      [index]: { ...previous[index], [parameterKey]: parameterValue },
    }));

  const listItemsOptions = (index) =>
    ReportTemplatesReportTemplateSection.listOptions.map((option) => (
      <MenuItem key={option.value} text={option.label} onClick={() => updateColumn(option.value, index)} />
    ));

  const itemInList = (value) => {
    const index = ReportTemplatesReportTemplateSection.listOptions.map((option) => option.value).indexOf(value);
    return ReportTemplatesReportTemplateSection.listOptions[index].label;
  };

  const formattedListItems = listItems.map((item, index: number) => {
    const requireFieldInput = item === 'additional_portfolio_attribute';
    let extraColumnValue: React.ReactNode = item ? 'Value' : '';
    let key = `${item}-list-item-${index}-`;

    if (requireFieldInput) {
      const initialParameterValue =
        attributes.listItemParameters[index] && attributes.listItemParameters[index].additionalAttribute;
      key += initialParameterValue;

      extraColumnValue = (
        <input
          type="text"
          defaultValue={initialParameterValue}
          onChange={(event) => updateParameters('additionalAttribute')(index, event.target.value)}
        />
      );
    }

    return (
      <li key={key} className="pdf-list__item">
        <Frow columnContext={{ maxColumns: 2, columnSpan: 1 }}>
          <Column>
            <span className="text-uppercase pdf-list__item-key">
              <Popover usePortal={false} modifiers={{ preventOverflow: { boundariesElement: 'viewport' } }}>
                <span>{item ? itemInList(item) : 'Select'}</span>
                <Menu>
                  {listItemsOptions(index)}
                  <MenuItem text="Remove Item" intent={Intent.DANGER} onClick={() => removeItem(index)} />
                </Menu>
              </Popover>
            </span>
          </Column>
          <Column>
            <span className="pdf-list__item-value">{extraColumnValue}</span>
          </Column>
        </Frow>
      </li>
    );
  });

  return (
    <div className="frow frow--gutters">
      <div className="col-mc-1-1">
        <ul className="pdf-list pdf-list--content pdf-list--large">
          {formattedListItems}
          <li onClick={addListItem} style={{ cursor: 'pointer' }} className="pdf-list__item">
            <span className="pdf-list__item-key">Add Item</span>
            <i className="icon-plus icon-fw icon-0-8x" style={{ marginLeft: '4px' }} />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default List;
