import * as React from 'react';

import { TodoItem } from 'javascript/models';

import Presenter from './todoList/Presenter';

export default function todoList() {
  const [todoItems, setTodoItems] = React.useState([]);

  React.useEffect(() => {
    getTodoItems();
  }, []);

  function handleUpdateItems() {
    getTodoItems();
  }

  async function getTodoItems() {
    const { data } = await TodoItem.all();
    setTodoItems(data);
  }

  return <Presenter {...{ handleUpdateItems, todoItems }} />;
}
