// Stolen from this (almost) excellent package https://github.com/danott/react-rails-form-helpers
// Need to take the code as the actual package is broken

import * as React from 'react';
import * as $ from 'jquery';

interface Props {
  url: string;
  method?: 'get' | 'post' | 'put' | 'patch' | 'delete';
  csrfToken?: string;
  children: any;
  className?: string;
  onSubmit?(event: any): void;
}

class FormTag extends React.Component<Props, {}> {
  public static defaultProps: Partial<Props> = {
    method: 'post',
    className: '',
  };

  whitelistProps(props, ...omit) {
    const alwaysOmit = ['key', 'ref', ...omit];
    const cloned = { ...props };
    alwaysOmit.forEach((key) => delete cloned[key]);
    return cloned;
  }

  handleSubmit(event) {
    if (this.props.onSubmit) {
      this.props.onSubmit(event);
    }
  }

  render() {
    let browserHTTPMethod = 'post';
    let fakedHTTPMethod = null;

    if (this.props.method === 'get') {
      browserHTTPMethod = 'get';
    } else if (this.props.method !== 'post') {
      fakedHTTPMethod = this.props.method;
    }

    const csrfToken = this.props.csrfToken || $('meta[name="csrf-token"]').attr('content');

    return (
      <form
        {...this.whitelistProps(this.props, 'url', 'children', 'csrfToken')}
        acceptCharset="UTF-8"
        action={this.props.url}
        method={browserHTTPMethod}
        className={this.props.className}
        onSubmit={this.handleSubmit.bind(this)}
      >
        {fakedHTTPMethod && <input type="hidden" name="_method" value={fakedHTTPMethod} />}
        {csrfToken && <input type="hidden" name="authenticity_token" value={csrfToken} />}
        <input type="hidden" name="utf8" value="&#x2713;" />
        {this.props.children}
      </form>
    );
  }
}

export default FormTag;
