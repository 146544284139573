import * as React from 'react';

export default function ratingLarge() {
  return (
    <table className="pdf-hidden-table">
      <tbody>
        <tr>
          <td>
            <table>
              <tbody>
                <tr>
                  <td className="pad-r-1">
                    <div className="filled-rating-box filled-rating-box--one">1</div>
                  </td>
                  <td className="pad-r-1">
                    <div className="filled-rating-box filled-rating-box--two">2</div>
                  </td>
                  <td className="pad-r-1">
                    <div className="filled-rating-box filled-rating-box--three">3</div>
                  </td>
                  <td className="pad-r-1">
                    <div className="filled-rating-box filled-rating-box--four">4</div>
                  </td>
                  <td className="pad-r-1">
                    <div className="filled-rating-box filled-rating-box--five">5</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
}
