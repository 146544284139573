import * as React from 'react';
import { Colors, Icon, Tooltip } from '@blueprintjs/core';
import { ActionPoint, ActionPointsReview, Review } from 'javascript/models';
import Button from 'components/shared/forms/Button';

type Props = {
  suggestedActionText: string;
  actionPointType: string;
  review: Review;
};

const ActionPointCell = (props: Props) => {
  const { suggestedActionText, actionPointType, review } = props;
  const [actionPointCreated, setActionPointCreated] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  async function createActionPoint() {
    setLoading(true);
    const action_points_review = new ActionPointsReview({ reviewId: review.id });
    const actionPoint = new ActionPoint({
      firstIncludedDate: review.endDate,
      actionPointType: actionPointType,
      body: suggestedActionText,
      action_points_reviews: [action_points_review],
    });

    const success = await actionPoint.save({ with: 'actionPointsReviews' });

    if (success) {
      setActionPointCreated(true);
    }

    setLoading(false);
  }

  if (suggestedActionText === '') return null;
  if (actionPointCreated) return <div>Created</div>;

  return (
    <td className="table__cell table__cell--action">
      <div className="frow frow--middle frow--center">
        <div
          className="frow frow--full-width frow--direction-row pad-a-1 frow--nowrap frow--justify-between"
          style={{ backgroundColor: Colors.DARK_GRAY1 }}
        >
          <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{suggestedActionText}</div>
          <div>
            <Tooltip content="Create action point">
              <Button
                buttonClasses="button button--add-action-point"
                handleClick={createActionPoint}
                isSubmitting={loading}
              >
                <Icon icon="plus" />
              </Button>
            </Tooltip>
          </div>
        </div>
      </div>
    </td>
  );
};

export default ActionPointCell;
