import * as React from 'react';

import OutsideClickHandler from 'react-outside-click-handler';
const classNames = require('classnames');

interface IProps {
  data: any;
  pages: number;
  canPrevious: boolean;
  canNext: boolean;
  pageSize: number;
  onPageChange(page: any): void;
  onPageSizeChange(number: any): void;
}
const { random, max, min } = Math;

const paginationComponent = (meta: any, pageNumber: number) => (props: IProps) => {
  const { onPageChange, onPageSizeChange, pages, pageSize } = props;

  const [isOpen, setIsOpen] = React.useState(false);
  const [isPageDropdownOpen, setIsPageDropdownOpen] = React.useState<boolean>(false);
  const localMeta = meta || { total: 0, pages: 1 };

  React.useEffect(() => {
    setIsOpen(false);
    setIsPageDropdownOpen(false);
  }, [props.pageSize, pageNumber]);

  function dropdownOptions() {
    return [20, 40, 80, 100]
      .filter((number) => number != pageSize)
      .map((number) => {
        return (
          <div
            key={random()}
            className="dropdown__nav-link dropdown__nav-link--small"
            onClick={() => onPageSizeChange(number)}
          >
            {number}
          </div>
        );
      });
  }

  function pageDropdownOptions() {
    const arraySizeLimit = 10000;
    return Array.from(
      { length: min(arraySizeLimit, localMeta.pages) },
      (v, k) => max(pageNumber - arraySizeLimit / 2, 0) + k + 1,
    )
      .filter((number) => number != pageNumber)
      .map((number) => {
        return (
          <div
            key={number}
            className="dropdown__nav-link dropdown__nav-link--small"
            onClick={() => onPageChange(number)}
          >
            {number}
          </div>
        );
      });
  }

  function handlePageChangeClick(number) {
    if (number < 1 || number > localMeta.pages) {
      return;
    }

    onPageChange(number);
  }

  const dropdownClasses = classNames('dropdown dropdown--floating dropdown--sw mar-r-2', {
    'dropdown--active': isOpen,
  });

  const pageDropdownClasses = classNames('dropdown dropdown--floating dropdown--sw', {
    'dropdown--active': isPageDropdownOpen,
  });

  const rightArrowClasses = classNames(
    'icon-arrow-right mar-l-2',
    { 'icon-disabled': pageNumber >= localMeta.pages },
    { 'icon-clickable': pageNumber < localMeta.pages },
  );

  const leftArrowClasses = classNames(
    'icon-arrow-left mar-r-2',
    { 'icon-disabled': pageNumber === 1 },
    { 'icon-clickable': pageNumber !== 1 },
  );

  const startNumber = pageSize * (pageNumber - 1) + 1;
  const endNumber = min(startNumber - 1 + pageSize, localMeta.total);

  return (
    <div className="frow frow--items-center frow--justify-between">
      <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
        <div>
          <span className="pagination-bottom__text text-white mar-r-2">Items per page</span>
          <div className={dropdownClasses}>
            <div className="dropdown__toggle" onClick={() => setIsOpen(!isOpen)}>
              {pageSize}
              <i className="icon-chevron-down icon-fw icon-tiny button__icon icon-push-up-2 mar-l-1" />
            </div>
            <div className="dropdown__content" style={{ width: '130%' }}>
              <div className="dropdown__content-inner">{dropdownOptions()}</div>
            </div>
          </div>
          <span className="pagination-bottom__text">
            Showing {startNumber} - {endNumber} of {localMeta.total}
          </span>
        </div>
      </OutsideClickHandler>
      <div>
        <OutsideClickHandler onOutsideClick={() => setIsPageDropdownOpen(false)}>
          <span className="pagination-bottom__text mar-r-4">
            Page {pageNumber} of {pages}
          </span>
          <span className="pagination-bottom__text">
            <i className={leftArrowClasses} onClick={() => handlePageChangeClick(pageNumber - 1)} />
            <div className={pageDropdownClasses}>
              <div
                className="dropdown__toggle frow frow--items-center"
                onClick={() => setIsPageDropdownOpen(!isPageDropdownOpen)}
              >
                {pageNumber}
                <i className="icon-chevron-down icon-fw icon-tiny button__icon mar-l-1" />
              </div>
              <div className="dropdown__content" style={{ width: '500%', maxHeight: '80px', overflow: 'scroll' }}>
                <div className="dropdown__content-inner">{pageDropdownOptions()}</div>
              </div>
            </div>
            <i className={rightArrowClasses} onClick={() => handlePageChangeClick(pageNumber + 1)} />
          </span>
        </OutsideClickHandler>
      </div>
    </div>
  );
};

export default paginationComponent;
