import * as React from 'react';

import Notification from '../../layouts/notifications/Notification';

export default function presenter({ markAllAsRead, notifications, toggleReadStatus }) {
  const renderNotifications = notifications.map((notification) => {
    return <Notification key={notification.id} {...{ notification, toggleReadStatus }} />;
  });

  return (
    <div className="platform-panel">
      <header className="platform-panel__header">
        <div className="frow frow--justify-between frow--items-center">
          <div>
            <h3 className="platform-panel__title">Notifications</h3>
          </div>
          <div>
            <div className="link" onClick={markAllAsRead}>
              Mark all as read
            </div>
          </div>
        </div>
      </header>
      {renderNotifications}
    </div>
  );
}
