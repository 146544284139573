import * as React from 'react';

import { Alert, Intent, Menu, MenuItem, Popover, Position } from '@blueprintjs/core';

import pluralize from 'pluralize';

import { platformReportTemplatesPath } from 'javascript/application/ts_routes';
import Form from './Form';
import useVisibility from 'components/shared/customHooks/useVisibility';

export default function reportTemplate({ bucket, canCreate, fetchData }) {
  const { isOpen, handleOpen, handleClose } = useVisibility(false);
  const { isOpen: isAlertOpen, handleOpen: handleDelete, handleClose: handleCloseDelete } = useVisibility(false);

  async function confirmDelete() {
    await bucket.destroy();
    fetchData();
  }

  const { color, id, name, templateCount } = bucket;

  const menu = (
    <React.Fragment>
      <div className="frow frow--justify-between">
        <div />
        <Popover usePortal={false} position={Position.BOTTOM}>
          <div>
            <i className="icon-overflow-horizontal-white icon text-white" />
          </div>
          <Menu>
            <MenuItem text="Edit" onClick={handleOpen} />
            <MenuItem text="Delete" onClick={handleDelete} intent={Intent.DANGER} />
          </Menu>
        </Popover>
      </div>
      <Form {...{ handleClose, isOpen, fetchData }} reportTemplateBucket={bucket} />
      <Alert
        isOpen={isAlertOpen}
        onClose={handleCloseDelete}
        canEscapeKeyCancel
        canOutsideClickCancel
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        intent={Intent.DANGER}
        onCancel={handleCloseDelete}
        onConfirm={confirmDelete}
        portalContainer={document.body}
      >
        <p className="mar-t-0">Are you sure you want to delete this bucket?</p>
      </Alert>
    </React.Fragment>
  );

  return (
    <div className="col-sm-1-3 col-lg-1-5">
      <div className="card card--fixed-height" style={{ height: '100%' }}>
        <div className="card__highlight" style={{ backgroundColor: color }} />
        <div className="card__inner">
          <div className="frow frow--nowrap frow--direction-column frow--full-height">
            {canCreate && menu}
            <a style={{ marginTop: 'auto' }} href={platformReportTemplatesPath({ bucket: id })}>
              <div className="mar-b-1 text-white text-small text-uppercase">
                {pluralize('report template', templateCount, true)}
              </div>
              <p className="card__title">{name}</p>
              <div>
                <div className="frow frow--justify-between">
                  <p className="card__subtitle">Click to View</p>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
