import * as React from 'react';
import * as $ from 'jquery';

import Select from 'react-select';

import useDebounce from 'components/shared/customHooks/useDebounce';

interface IProps {
  defaultOptions: { label: string; value: string }[];
  id: string;
  label: string;
  name: string;
  path: string;
}

export default function entitySelect(props: IProps) {
  const { defaultOptions, id, name, label, path } = props;
  const [isLoading, setIsLoading] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);

  const debouncedInputValue = useDebounce(inputValue, 600);

  React.useEffect(() => {
    setIsLoading(false);
  }, [options]);

  function handleInputChange(input) {
    setInputValue(input);
  }

  React.useEffect(() => {
    if (debouncedInputValue && debouncedInputValue.length > 4) {
      getEntities();
    } else {
      setOptions([]);
    }
  }, [debouncedInputValue]);

  function getEntities() {
    $.ajax({
      beforeSend: () => setIsLoading(true),
      cache: false,
      data: { search: inputValue },
      dataType: 'json',
      success: (response) => setOptions(response),
      type: 'GET',
      url: path,
    });
  }

  return (
    <React.Fragment>
      <label htmlFor={id} className="form__label">
        {label}
      </label>
      <Select
        className="react-select-dark"
        classNamePrefix="react-select-dark"
        defaultValue={defaultOptions}
        isMulti
        isSearchable
        isLoading={isLoading}
        name={name}
        options={options}
        placeholder="Search for an Entity to select"
        inputValue={inputValue}
        onInputChange={handleInputChange}
      />
    </React.Fragment>
  );
}
