import * as React from 'react';

import { ReportTemplatesReportTemplateSection } from 'javascript/models';

export default function horizontalBarChart({ attributes, handleChange, handleManualChange }) {
  const [type, setType] = React.useState(attributes.sectionType);

  function updateAttributes() {
    const value = { type };
    handleManualChange(value, 'sectionDetails');
  }

  React.useEffect(() => {
    updateAttributes();
  }, [type]);

  function handleTypeChange() {
    setType(this);
  }

  const typeOptions = ReportTemplatesReportTemplateSection.portfolioInformationOptions.map((option) => {
    return (
      <div className="col-tn-1-3" key={option.value}>
        <div className="pretty pretty--large pretty--with-label p-round">
          <input
            type="radio"
            value={option.value}
            id="H_bar_type"
            onChange={handleTypeChange.bind(option.value)}
            checked={type === option.value}
          />
          <div className="state p-blue-circle">
            <label>{option.label}</label>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="frow frow--gutters">
      <div className="col-mc-1-1">
        <label className="form__label" htmlFor="">
          Type
        </label>
        <div className="frow">{typeOptions}</div>
      </div>
    </div>
  );
}
