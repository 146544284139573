import * as React from 'react';
import { Frow } from 'components/frow';

interface IBreachIndicatorProps {
  status: 'flag' | 'pass' | 'cannot_test' | 'info' | 'not_applicable' | 'no_set' | 'error';
  reason?: string;
  iconSize?: number;
}

const colors = {
  red: '#ff5151',
  green: '#6bd055',
  grey: '#7f7f7f',
  blue: '#a3a3f5',
  yellow: 'yellow',
};

export const indicators = (status) => {
  let color;
  let text;
  let icon;

  switch (status) {
    case 'flag':
      color = colors.red;
      text = 'Flag';
      icon = (
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      );
      break;
    case 'pass':
      color = colors.green;
      text = 'Pass';
      icon = (
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      );
      break;
    case 'cannot_test':
      color = colors.grey;
      text = 'Cannot Test';
      icon = (
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
        />
      );
      break;
    case 'info':
      color = colors.blue;
      text = 'Info';
      icon = (
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
        />
      );
      break;
    case 'not_applicable':
      color = colors.grey;
      text = 'Not Applicable';
      icon = (
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
        />
      );
      break;
    case 'not_set':
      color = colors.grey;
      text = 'Not set';
      icon = (
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
        />
      );
      break;
    case 'error':
      color = 'yellow';
      text = 'Error';
      icon = (
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
        />
      );
      break;
    default:
      color = 'white';
      text = '';
      icon = '';
      break;
  }

  return { color, text, icon };
};

const BreachIndicator = ({ status, iconSize = 17 }: IBreachIndicatorProps) => {
  const { text, color, icon } = indicators(status);

  return (
    <Frow itemAlignment="center">
      <div style={{ width: 20, height: 20, marginRight: 2 }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="2"
          stroke={color}
          height="20"
          width="20"
        >
          {icon}
        </svg>
      </div>
      {text}
    </Frow>
  );
};

export default BreachIndicator;
