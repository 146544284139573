import * as React from 'react';

import Frow from '../../../../../../../frow/Frow';
import Column from '../../../../../../../frow/Column';

import Loading from 'components/shared/Loading';

import { Portfolio } from 'javascript/models';
import { Review } from 'javascript/models';

import { platformPortfolioPath } from 'javascript/application/ts_routes';

export interface IPortfolioReviewStatuses {
  portfolios: Portfolio[];
  review: Review;
}

export default function portfolioReviewStatuses(props: IPortfolioReviewStatuses) {
  const { portfolios, review } = props;

  if (!portfolios.length) return null;

  const [portfolioReviews, setPortfolioReviews] = React.useState<Portfolio[]>();

  const getPortolioReviews = async () => {
    const reviewPromises = portfolios.map(async (portfolio) => {
      return Portfolio.where({
        id: portfolio.id,
        reviews: {
          endDate: review.attributes.endDate,
        },
      })
        .includes('reviews')
        .first();
    });

    const portfoliosPromise = await Promise.all(reviewPromises);

    const portfoliosData = portfoliosPromise.map((status: any) => status.data);

    setPortfolioReviews(portfoliosData);
  };

  React.useEffect(() => {
    getPortolioReviews();
  }, []);

  if (!portfolioReviews) return <Loading />;

  const reviewsJSX = portfolioReviews.map((portfolioReview) => {
    const review = portfolioReview.reviews[0];
    const hrefOptions = review ? { review: review.id } : undefined;

    return (
      <Frow key={portfolioReview.reference} itemAlignment="baseline" style={{ width: '100%' }}>
        <Column breakpointSize="mc" maxColumns={2} columnSpan={1}>
          <a className="link link--secondary" href={platformPortfolioPath(portfolioReview.id, hrefOptions)}>
            {portfolioReview.reference}
          </a>
        </Column>
        <Column breakpointSize="mc" maxColumns={2} columnSpan={1} style={{ fontSize: '1.3rem' }}>
          {((review && review.status.replace('_', ' ')) || 'n/a').toUpperCase()}
        </Column>
      </Frow>
    );
  });

  return <>{reviewsJSX}</>;
}
