import * as React from 'react';

import { CartesianGrid, Label, Legend, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';

import ShadedLineChart from './ShadedLineChart';

import { CustomYAxis } from './CustomComponents';
import { data } from './LineChartData';
import { drawdownData } from './DrawdownData';
import { chartColorsV2 } from './chartColors';
import Context from '../Context';

import { startCase } from 'lodash';

export const CustomLegend = (props) => {
  const { payload } = props;

  const legends = payload.map((payloadItem, index) => {
    const lineStyles = {
      borderColor: payloadItem.color,
      borderStyle: 'solid',
      borderWidth: '1px',
      display: 'inline-block',
      marginBottom: '1px',
      marginRight: '10px',
      width: '25px',
    };

    if (payloadItem.payload.strokeDasharray) {
      lineStyles.borderStyle = 'dashed';
    }

    return (
      <span key={index} className="">
        <span style={lineStyles} className="mar-r-1" />
        <span className="mar-r-2" style={{ fontSize: '0.7rem', whiteSpace: 'nowrap' }}>
          {payloadItem.dataKey}
        </span>
      </span>
    );
  });

  return <div style={{ border: '1px solid #e4e4e8', padding: '0 0 5px', textAlign: 'center' }}>{legends}</div>;
};

export const CustomHorizontalLegend = (props) => {
  const { payload } = props;

  const legends = payload.map((payloadItem, index) => {
    const lineStyles = {
      borderColor: payloadItem.color,
      borderStyle: 'solid',
      borderWidth: '1px',
      display: 'inline-block',
      marginBottom: '1px',
      marginRight: '10px',
      width: '15px',
    };

    return (
      <div
        key={index}
        className="col-mc-1-1"
        style={{ marginBottom: index + 1 !== payload.length ? '8px' : 0, fontSize: '0.8rem' }}
      >
        <div
          className={`platform-content ${index + 1 !== payload.length && 'platform-content--border-bottom-light-grey'}`}
          style={{ paddingBottom: index + 1 !== payload.length ? '5px' : '0px' }}
        >
          <table className="pdf-hidden-table">
            <tbody>
              <tr className="pad-a-1">
                <td style={{ fontSize: '8px', paddingRight: '5px', width: '7%' }}>
                  <span style={lineStyles} />
                </td>
                <td style={{ fontSize: '8px' }}>{payloadItem.dataKey}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  });

  return (
    <div style={{ border: '1px solid #e4e4e8' }} className="mar-l-2 pad-a-1">
      {legends}
    </div>
  );
};

export default function lineChart({ colors, templateSection }) {
  const legendRight = templateSection && templateSection.legendPosition === 'right';
  const context = React.useContext(Context);
  const { reportTemplate } = context.state;
  const chartColors = chartColorsV2();

  const isLandscape = reportTemplate && reportTemplate.orientation === 'landscape';

  let defaultSeries = ['Series 1', 'Series 2', 'Series 3', 'Series 4', 'Series 5'];

  let seriesExists = templateSection && templateSection.lineChartDetails;

  const chartType = seriesExists && templateSection.lineChartType ? templateSection.lineChartType : 'return';
  // If we are dealing with an excess return chart then we don't want to look at the series (because we dont have one
  if (chartType === 'excess_return') {
    // seriesExists  = false;
    // defaultSeries = ['Portfolio'];
    return <ShadedLineChart {...{ colors, templateSection }} />;
  }
  let series = defaultSeries;
  if (seriesExists) {
    series = templateSection.lineChartDetails.series;
    if (templateSection.lineChartSeries && templateSection.lineChartSeries.length > 0) {
      series = templateSection.lineChartSeries;
    }
  }
  const yAxisName = seriesExists && templateSection.lineChartType ? templateSection.lineChartType : null;
  let height = legendRight ? 125 : isLandscape ? 200 : 150;
  if (templateSection && templateSection.size === 'medium') height = 300;
  if (templateSection && templateSection.size === 'large') height = 425;

  const lines = series.map((dataPoint, index) => {
    return (
      <Line
        key={dataPoint}
        strokeWidth="2"
        type="linear"
        dataKey={dataPoint}
        stroke={chartColors(reportTemplate, colors, dataPoint, index, !templateSection, 'line')}
        dot={false}
        isAnimationActive={false}
        strokeDasharray={index > 3 ? '3 3' : ''}
      />
    );
  });

  function renderLegend() {
    if (legendRight) {
      return <Legend layout="vertical" verticalAlign="top" align="right" content={<CustomHorizontalLegend />} />;
    }
    return <Legend content={<CustomLegend />} iconType="line" />;
  }

  return (
    <ResponsiveContainer width="100%" height={height}>
      <LineChart
        width={450}
        height={height}
        data={chartType === 'drawdown' ? drawdownData(series) : data(series)}
        margin={{ top: 5, right: 15, left: 0, bottom: 0 }}
      >
        <CartesianGrid stroke="#d7d7dd" />
        <XAxis
          dataKey="name"
          interval={0}
          tick={{ fill: '#b5b6bd', fontSize: '8px', fontWeight: 'bold' }}
          tickSize={0}
          tickMargin={8}
        />
        <YAxis
          domain={chartType === 'drawdown' ? ['auto', 0] : ['auto', 'auto']}
          interval={0}
          tick={{ fill: '#b5b6bd', fontSize: '8px', fontWeight: 'bold' }}
          tickSize={0}
          tickMargin={5}
          tickFormatter={() => 'LABEL'}
        >
          <Label content={<CustomYAxis name={startCase(yAxisName)} height={height} />} />
        </YAxis>
        {lines}
        {renderLegend()}
      </LineChart>
    </ResponsiveContainer>
  );
}
