import * as React from 'react';

import * as classNames from 'classnames';

import { prop, pipe } from 'ramda';

import OutsideClickHandler from 'react-outside-click-handler';

import { Popover } from '@blueprintjs/core';

import useAsyncState, { TPossibleCallbackPromise } from 'components/shared/customHooks/useAsyncState';

import Loading from '../../Loading';

import { IRevisedColumn } from '../utilities/processColumns';

export interface IFilterOption {
  display: string | number;
  value: string;
}

type TFilterOptions = TPossibleCallbackPromise<IFilterOption[]>;

export interface IFilterParams {
  key: string;
  options: TFilterOptions;
  selectedOptions?: TPossibleCallbackPromise<IFilterOption['value'][]>;
}

export interface IColumnFilterProps<ColumnType> {
  column: IRevisedColumn<ColumnType>;
  handleApplyFilters(key: string, values: IFilterOption['value'][]): void;
}

/*
 * A function which filters an array and then performs a map to extract an attribute
 *
 * @param array - The values to be filtered and mapped over
 * @param attribute - The value to be selected from the objects when mapping also used during filtering,
 *   in the absence of a filterFunc
 * @param filterFunc - An optional paramter used to filter the array before mapping
 *
 * @remarks in the absence of filterFunc the filtering checks if the attribute evaluates to true
 *
 * @returns Both the filtered list and the mapped list
 */
const filterSelect = <Data,>(array: Data[], attribute: keyof Data, filterFunc?: (item: Data) => boolean) => {
  const filtered = array.filter(filterFunc || ((obj) => obj[attribute] || false));
  const mapped = filtered.map((obj) => obj[attribute]);

  return [filtered, mapped];
};

/*
 * This component is used when a column provided to reactTable/Table has the filterOptions property.
 *   This component replace the Header value in the column to allow filter options to be provided.
 *   This function handles the selection and display of the filters, invoking a `handleApplyFilters` callback
 *   when the value changes
 *
 * @remarks when the value of `options` or `selectedOptions` changes and the value is a function the function will be
 *   recalled.
 *
 * @remarks we also recall the function for `selectedOptions` when the value of `selectableFilterOptions` changes
 *   The reason for this is because it's used as the fallback value this may cause unexpected behaviour, if so
 *   other options can be explored to initially set the value of selectedFilterOptions
 */

const valueProp = prop('value');

const columnFilter = <ColumnType,>(props: IColumnFilterProps<ColumnType>) => {
  const { column, handleApplyFilters } = props;
  const filterParams = column.filterOptions;

  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const [selectableFilterOptions, setSelectableFilterOptions] = useAsyncState<IFilterOption[]>(
    filterParams.options,
    null,
  );

  const [selectedFilterOptions, setSelectedFilterOptions] = useAsyncState<string[]>(
    filterParams.selectedOptions,
    selectableFilterOptions && selectableFilterOptions.map(valueProp),
  );

  const [hasChanged, setHasChanged] = React.useState(false);

  React.useEffect(() => {
    setSelectableFilterOptions(filterParams.options);
  }, [filterParams.options]);

  React.useEffect(() => {
    setSelectedFilterOptions(
      filterParams.selectedOptions,
      selectableFilterOptions && selectableFilterOptions.map(pipe(valueProp, String)),
    );
  }, [filterParams.selectedOptions, selectableFilterOptions]);

  let isAllChecked = true;

  if (selectableFilterOptions && selectedFilterOptions) {
    isAllChecked = selectableFilterOptions
      .map((option) => option.value)
      .every((option) => selectedFilterOptions.includes(option));
  }

  const handleAllClick = React.useCallback(() => {
    setHasChanged(true);
    if (isAllChecked) {
      setSelectedFilterOptions([]);
    } else {
      setSelectedFilterOptions(selectableFilterOptions.map(valueProp));
    }
  }, [isAllChecked, selectableFilterOptions]);

  const handleChange = React.useCallback((event) => {
    setHasChanged(true);
    const value = event.target.dataset.value;
    setSelectedFilterOptions((prev) => prev.toggle(value));
  }, []);

  const stopPropagation = React.useCallback((e) => e.stopPropagation(), []);
  const close = React.useCallback(() => setIsOpen(false), []);
  const toggle = React.useCallback(() => setIsOpen((previous) => !previous), []);

  const localHandleApplyFilters = React.useCallback(() => {
    setHasChanged(false);
    setIsOpen(false);
    handleApplyFilters(filterParams.key, selectedFilterOptions);
  }, [handleApplyFilters, selectedFilterOptions]);

  let filterOptionsJSX = [<Loading key={Math.random()} />];

  if (selectableFilterOptions && selectedFilterOptions) {
    filterOptionsJSX = selectableFilterOptions.map((option) => {
      const checked = selectedFilterOptions.includes(option.value);
      return (
        <div className="dropdown__nav-link" key={String(option.value)}>
          <div className="pretty p-icon">
            <input type="checkbox" checked={checked} onChange={handleChange} data-value={option.value} />
            <div className="state p-blue">
              <i className="icon-tick icon" />
              <label>{option.display}</label>
            </div>
          </div>
        </div>
      );
    });

    filterOptionsJSX.unshift(
      <div className="dropdown__nav-link" key="* --all-- *">
        <div className="pretty p-icon">
          <input type="checkbox" checked={isAllChecked} onChange={handleAllClick} />
          <div className="state p-blue">
            <i className="icon-tick icon" />
            <label>All</label>
          </div>
        </div>
      </div>,
    );
  }

  const dropdownClasses = classNames('dropdown--popover', {
    'dropdown--active': isOpen,
  });

  const iconClasses = classNames('icon-filter-list', 'icon-hoverable', { 'text-white': !isAllChecked });

  const popoverContent = (
    <div className="dropdown__content" style={{ width: '200px', left: '-580%' }}>
      <div className="dropdown__content-inner">{filterOptionsJSX}</div>
      <div className="dropdown__content-footer">
        <button
          className="button button--compact button--full-width"
          onClick={localHandleApplyFilters}
          disabled={!hasChanged}
        >
          Apply
        </button>
      </div>
    </div>
  );

  return (
    <div className="frow frow--items-center frow--nowrap">
      <div onClick={stopPropagation}>
        <Popover
          popoverClassName={dropdownClasses}
          isOpen={isOpen}
          modifiers={{
            flip: { order: 200, boundariesElement: 'viewport' },
            offset: { offset: '35px' },
            preventOverflow: { boundariesElement: 'viewport', escapeWithReference: true },
          }}
          position="bottom"
        >
          <i id="popover-trigger-element" className={iconClasses} onClick={toggle} />
          <OutsideClickHandler onOutsideClick={close}>{popoverContent}</OutsideClickHandler>
        </Popover>
      </div>
      <div className="mar-l-1">{column.columnName}</div>
    </div>
  );
};

export default columnFilter;
export { filterSelect };
