import * as React from 'react';

interface IStatusIndicatorProps {
  status?: boolean;
}

const StatusIndicator = ({ status }: IStatusIndicatorProps) => {
  const iconType = status ? 'success' : 'endorse';
  const iconText = status ? 'Yes' : 'No';
  return <div className={`table-icon table-icon--${iconType}`}>{iconText}</div>;
};

export default StatusIndicator;
