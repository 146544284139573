import * as React from 'react';

import {
  CartesianGrid,
  Label,
  Legend,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Surface,
  Symbols,
  XAxis,
  YAxis,
} from 'recharts';

import { CustomLegend } from './reactBubbleChart/CustomLegend';
import { CustomXAxis, CustomYAxis } from './reactBubbleChart/Axes';

const customisedCircle = (props) => {
  const { cx, cy, fill, payload, stroke } = props;
  const { pct } = payload;
  const baseSize = 15000;

  const textLabel = (
    <text x={cx} y={cy + 4} textAnchor="middle" fill="#fff" fontSize={12}>
      {`${(pct * 100).toFixed(1)}%`}
    </text>
  );

  return (
    <React.Fragment>
      <Symbols cx={cx} cy={cy} type="circle" opacity={0.8} size={baseSize * pct} fill={fill} stroke={stroke} />
      {pct > 0 && textLabel}
    </React.Fragment>
  );
};

export default function reactBubbleChart({ handleSelect = null, height, series }) {
  function handleClick(bubble) {
    if (handleSelect) handleSelect(bubble.name);
  }

  const scatters = series.map((object, index) => {
    return (
      <Scatter
        key={index}
        data={object}
        fill={object[0].fill}
        shape={customisedCircle}
        legendType="circle"
        isAnimationActive
        onClick={handleClick}
      />
    );
  });

  return (
    <div>
      <ResponsiveContainer width="100%" height={height}>
        <ScatterChart margin={{ top: 5, right: 0, left: 0, bottom: 20 }}>
          <XAxis
            dataKey="xAxis"
            tick={false}
            domain={[0, 8]}
            ticks={[1, 2, 3, 4, 5, 6, 7, 8]}
            tickSize={0}
            tickMargin={8}
            type="number"
          >
            <Label content={<CustomXAxis name="Risk" />} />
          </XAxis>
          <YAxis dataKey="y" domain={[0, 8]} tick={false} ticks={[1, 2, 3, 4, 5, 6, 7, 8]} tickSize={0} tickMargin={5}>
            <Label content={<CustomYAxis height={height} name="Return" />} />
          </YAxis>
          <Legend content={<CustomLegend />} />
          {scatters}
        </ScatterChart>
      </ResponsiveContainer>
    </div>
  );
}
