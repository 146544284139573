import * as React from 'react';

import { startCase } from 'lodash';

import Loading from 'components/shared/Loading';
import useRadioSelect from 'components/shared/customHooks/useRadioSelect';
import Column from 'components/frow/Column';

import { ReviewTransition } from 'javascript/models';
import ReactSelect from 'components/shared/forms/ReactSelect';
import AutoResizeTextArea from 'components/shared/forms/AutoResizeTextArea';

export interface IReasonsObject {
  errorCategory: string;
  errorSubCategory: string;
  errorSource: string;
  reason: string;
}

type TReasonStatObject = {
  [Key in keyof Omit<IReasonsObject, 'reason'>]: string[];
};

const useReviewTransitionReasons: () => [JSX.Element, IReasonsObject, () => void] = () => {
  const [selectableReasons, setSelectableReasons] = React.useState<TReasonStatObject>({} as TReasonStatObject);
  const [selectedReason, setSelectedReason] = React.useState<IReasonsObject>({} as IReasonsObject);

  const getSelectOptions = async () => {
    const { meta } = await ReviewTransition.where({ reviewId: 1 }).stats({ staticData: 'error_data' }).all();
    const transitionsObject = meta.stats.static_data.error_data;
    setSelectableReasons(transitionsObject);
  };

  React.useEffect(() => {
    getSelectOptions();
  }, []);

  const reactSelectOptions = (optionValueArray: string[]) =>
    optionValueArray && optionValueArray.map((value) => ({ label: startCase(value), value }));

  const [categoryJSX, selectedCategoryRef, clearCategory] = useRadioSelect(
    reactSelectOptions(selectableReasons.errorCategory),
    null,
    'text-grey',
  );

  const [errorSourceJSX, selectedSourceRef, clearSource] = useRadioSelect(
    reactSelectOptions(selectableReasons.errorSource),
    null,
    'text-grey',
  );

  const clearSelectedReason = () => {
    setSelectedReason({} as IReasonsObject);
    clearCategory();
    clearSource();
  };

  const selectReason = (reasonPart: keyof IReasonsObject) =>
    React.useCallback(
      (option) =>
        setSelectedReason((previous) => ({
          ...previous,
          [reasonPart]: reasonPart === 'reason' ? option : option.value,
        })),
      [],
    );

  const handleCategoryChange = selectReason('errorCategory');
  const handleSourceChange = selectReason('errorSource');

  React.useEffect(
    () =>
      selectableReasons.errorCategory &&
      handleCategoryChange({ value: selectableReasons.errorCategory[selectedCategoryRef.current] }),
    [selectedCategoryRef.current],
  );

  React.useEffect(
    () =>
      selectableReasons.errorSource &&
      handleSourceChange({ value: selectableReasons.errorSource[selectedSourceRef.current] }),
    [selectedSourceRef.current],
  );

  const subCategoryJSX = (
    <ReactSelect
      options={reactSelectOptions(selectableReasons.errorSubCategory)}
      name="error-sub-cateory-select"
      id="error-sub-cateory-select"
      handleChange={selectReason('errorSubCategory')}
    />
  );

  const reasonJSX = (
    <div className="col-mc-1-1">
      <label htmlFor="rejection_reason" className="form__label">
        Reason for Moving back
      </label>
      <AutoResizeTextArea
        name="rejection_reason"
        id="rejection_reason"
        value={selectedReason.reason}
        rows={10}
        placeholder="Please provide a reason for kicking this review back"
        onChange={selectReason('reason')}
      />
    </div>
  );

  const JSX = (
    <Column>
      <label className="form__label">Error Category</label>
      <div className="mar-b-2">{categoryJSX}</div>
      <label className="form__label">Error Source</label>
      <div className="mar-b-2">{errorSourceJSX}</div>
      <label className="form__label">Error Type</label>
      <div className="mar-b-2">{subCategoryJSX}</div>
      <div className="mar-b-1">{reasonJSX}</div>
    </Column>
  );

  return [JSX, selectedReason, clearSelectedReason];
};

export default useReviewTransitionReasons;
