import React, { useState } from 'react';

import { debounce, startCase } from 'lodash';

import { User } from 'javascript/models';

import CustomModal from 'components/shared/CustomModal';
import useVisibility from 'components/shared/customHooks/useVisibility';
import { Frow, Column } from 'components/frow';
import ReactSelect from 'components/shared/forms/ReactSelect';
import QuarterPicker from 'components/shared/forms/QuarterPicker';

import { ActionPointContext } from '../../../Show';

type InProgressAction = 'defer' | 'complete';

export interface IInProgressButtonProps {
  action: InProgressAction;
  releaseAssignees: User[];
}

const InProgressButton = ({ action, releaseAssignees }: IInProgressButtonProps) => {
  const { actionPoint, getActionPoint, isLoading, setIsLoading, currentUser } = React.useContext(ActionPointContext);

  const [actionNote, setActionNote] = useState('');
  const [releaseAssignee, setReleaseAssignee] = useState<User>(null);
  const [deferralDate, setDeferralDate] = useState<Date>(null);

  const { isOpen, handleClose, handleOpen } = useVisibility(false);

  const handleAction = async () => {
    setIsLoading(true);
    if (action === 'defer') {
      await actionPoint.defer(actionNote, deferralDate, currentUser, releaseAssignee);
    } else {
      await actionPoint.complete(currentUser, actionNote, releaseAssignee);
    }
    getActionPoint().then(() => {
      setIsLoading(false);
      handleClose();
    });
  };

  const handleClick = debounce(handleAction, 1000, { leading: true, trailing: false });
  const handleDateChange = (date: Date) => {
    setDeferralDate(date);
  };

  const modalEnabled = !isLoading && actionPoint.assignedToCurrentPortalUser(currentUser);
  const actionButtonEnabled =
    modalEnabled && actionNote.trim().length > 0 && releaseAssignee && (action === 'complete' || deferralDate);

  const deferralDateSelector = (
    <>
      <Column columnSpan={1} maxColumns={3} additionalClassNames="mar-r-1">
        <label htmlFor="assignee" className="form__label">
          Enter Deferral Date
        </label>
        <QuarterPicker
          handleChange={handleDateChange}
          startDate={new Date().toISOString()}
          name="QuarterPicker"
          optionCount={40}
        />
      </Column>
    </>
  );

  const mapUserToOption = (user: User) => (user ? { label: user.name, value: user } : { label: '', value: '' });

  const reactSelectStyles = {
    menuPortal: (provided) => ({
      ...provided,
      zIndex: '99999 !important',
    }),
  };

  return (
    <>
      <button className="button button--compact" onClick={handleOpen} disabled={!modalEnabled}>
        {startCase(action)}
      </button>
      <CustomModal {...{ isOpen, title: `${startCase(action)} Action Point`, modifiers: ['visible-overflow'] }}>
        <div className="modal__content">
          <div className="form form--light mar-b-2">
            <label htmlFor="assignee" className="form__label">
              {`Action Point ${action === 'defer' ? 'Deferral' : 'Completion'} Note`}
            </label>
            <textarea
              className="textarea"
              placeholder="Please provide an accompanying note detailing your rationale"
              value={actionNote}
              onChange={(e) => setActionNote(e.target.value)}
            />
            <Frow justifyContent="start" additionalClassNames="mar-t-2">
              {action === 'defer' && deferralDateSelector}
              <Column columnSpan={1} maxColumns={3}>
                <label htmlFor="assignee" className="form__label mar-r-1">
                  Select Assignee for Review
                </label>
                <ReactSelect
                  options={releaseAssignees.map(mapUserToOption)}
                  value={mapUserToOption(releaseAssignee)}
                  isClearable
                  name="releaseAssignee"
                  id={`${action}Assignee`}
                  handleChange={(option: { label: string; value: User }) => setReleaseAssignee(option && option.value)}
                  customSelectStyles={reactSelectStyles}
                  menuPortalTarget={document.body}
                />
              </Column>
            </Frow>
          </div>
          <Frow justifyContent="end">
            <button className="button mar-r-1" onClick={handleClick} disabled={!actionButtonEnabled}>
              {startCase(action)}
            </button>
            <button className="button button--danger" onClick={handleClose}>
              Cancel
            </button>
          </Frow>
        </div>
      </CustomModal>
    </>
  );
};

export default InProgressButton;
