import * as React from 'react';

import { format, parse } from 'date-fns';
const numeral = require('numeral');

export default function columns() {
  return [
    {
      Cell: (row) => {
        return <div>{format(parse(row.original.valueDate), 'DD MMM YYYY')}</div>;
      },
      Header: 'Date',
      accessor: 'valueDate',
      id: 'value_date',
    },
    {
      Cell: (row) => {
        return <div>N/A</div>;
      },
      Header: 'Value',
      accessor: 'value',
      id: 'value',
    },
    {
      Cell: (row) => {
        return <div>{numeral(row.original.monthReturn).format('0,0.0000')}%</div>;
      },
      Header: 'Return',
      accessor: 'return',
      id: 'month_return',
    },
    {
      Cell: (row) => {
        return <div>{numeral(row.original.overallReturn).format('0,0.0000')}%</div>;
      },
      Header: 'Overall',
      accessor: 'overallReturn',
      id: 'overall_return',
    },
  ];
}
