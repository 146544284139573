import * as React from 'react';
import * as $ from 'jquery';

import * as ReactTooltip from 'react-tooltip';
import lodashDebounce from 'lodash.debounce';

import Button from 'components/shared/forms/Button';
import CsrfToken from 'components/shared/CsrfToken';
import { ajaxErrorString } from 'components/shared/Utils';
import ReviewContext from '../ReviewContext';
import Context from '../../../../Context';
import AssignModal from '../../../../../../reviews/AssignModal';
import useVisibility from 'components/shared/customHooks/useVisibility';

const verifyReview = () => {
  const context = React.useContext(Context);
  const reviewContext = React.useContext(ReviewContext);
  const { review } = reviewContext.props;
  const { id } = review;
  const { owner } = review.attributes;
  const { updateStatusPath, validateStatusPath } = review.links;
  const { handleReviewUpdate } = reviewContext.actions;
  const { administrators, companyId } = context.props;

  const { isOpen, handleOpen, handleClose } = useVisibility(false);

  const [isDisabled, setIsDisabled] = React.useState(true);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  const event = 'to_ready_to_send';

  React.useEffect(() => {
    validateTransition();
    setIsSubmitting(false);
  }, [review]);

  function validateTransition() {
    $.ajax({
      cache: false,
      dataType: 'json',
      error: (jqXhr) => setErrorMessage(ajaxErrorString(jqXhr)),
      success: () => setIsDisabled(false),
      type: 'GET',
      url: `${validateStatusPath}?transition=${event}`,
    });
  }

  function handleClick() {
    if (owner || companyId === 2) {
      handleSubmit();
    } else {
      handleOpen();
    }
  }

  function handleSubmit() {
    setIsDisabled(true);
    const reviewObject = { event };
    $.ajax({
      data: { review: reviewObject, authenticity_token: CsrfToken() },
      dataType: 'json',
      success: () => {
        handleReviewUpdate();
      },
      type: 'PATCH',
      url: updateStatusPath,
    });
  }

  const debouncedHandleClick = lodashDebounce(handleClick, 1000, {leading: true, trailing: false});

  return (
    <div className="frow" data-tip={errorMessage} data-place="left" data-for="verify-review-tooltip">
      <ReactTooltip effect="solid" id="verify-review-tooltip" />
      <Button
        buttonClasses="button--compact mar-r-1"
        isDisabled={isDisabled}
        isSubmitting={isSubmitting}
        handleClick={debouncedHandleClick}
        submittingText="Approved"
        style={{ minWidth: '120px' }}
        text="Approve"
      />
      <AssignModal
        administrators={administrators}
        companyId={companyId}
        handleClose={handleClose}
        handleReviewsAssigned={handleSubmit}
        isOpen={isOpen}
        selection={[id]}
      />
    </div>
  );
};

export default verifyReview;
