import * as React from 'react';
import UserSelect from './UserSelect';

export default function contacts({ bulkImport, client, handleParamsChange, users }) {
  const userOptions = users.map((user) => ({ value: user.id, label: `${user.fullName} (${user.email})` }));

  const contactSelects = bulkImport.preview.data.map((userObject, index) => {
    return <UserSelect key={index} {...{ client, handleParamsChange, userOptions, userObject }} />;
  });
  return (
    <div className="platform-content--padding-all">
      <table className="styled-table">
        <thead>
          <tr>
            <th>User from sheet</th>
            <th>User In System</th>
          </tr>
        </thead>
        <tbody>{contactSelects}</tbody>
      </table>
    </div>
  );
}
