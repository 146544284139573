import * as React from 'react';
import classNames from 'classnames';

import { format, parse } from 'date-fns';

import ClientPortalCard from '../../../shared/ClientPortalCard';
import { portalClientPortfolioAssetAllocationsPath } from 'javascript/application/ts_routes';
import ReactStackedAreaChart from '../../../../shared/charting/recharts/ReactStackedAreaChart';
import Loading from '../../../../shared/Loading';
import ReactPieChart from '../../../../shared/charting/recharts/ReactPieChart';
import useVisibility from '../../../../shared/customHooks/useVisibility';
import PieChartTableLegend from '../../show/PieChartTableLegend';
import useAjaxRequest from '../../../../shared/customHooks/useAjaxRequest';
import SubChart from './assetAllocation/SubChart';

import { useClientPortalTheme } from 'components/shared/colours/themes/clientPortal';
import { Frow, Column } from 'components/frow';
import { ColumnContext, defaultColumnContextValue } from 'components/frow/ColumnContext';

interface IAssetAllocation {
  currentReviewPeriod: string;
  portfolioObject: {
    client_id: number;
    id: number;
  };
}

const AssetAllocation = ({ currentReviewPeriod, portfolioObject }: IAssetAllocation) => {
  const { client_id, id } = portfolioObject;
  const [assetData, setAssetData] = React.useState();
  const [activeDate, setActiveDate] = React.useState<string>(format(parse(currentReviewPeriod), 'MMM YY'));
  const [selectedSlice, setSelectedSlice] = React.useState();
  /*
   * Disabling asset class click-through as per MOS-585
   */
  const { setIsOpen: setDefaultOpen } = useVisibility(true);

  const getColour = useClientPortalTheme('assetClass');

  const defaultOpen = true;

  React.useEffect(() => {
    setActiveDate(format(parse(currentReviewPeriod), 'MMM YY'));
  }, [currentReviewPeriod]);

  React.useEffect(() => {
    getAssetData({ requestParams: null });
  }, []);

  React.useEffect(() => {
    if (selectedSlice) {
      setDefaultOpen(false);
    } else {
      setDefaultOpen(true);
    }
  }, [selectedSlice]);

  const handleActiveLabelChange = React.useCallback((activeLabel: string) => setActiveDate(activeLabel), []);

  const resetSlice = React.useCallback(() => setSelectedSlice(null), []);

  interface IRequestParams {
    requestParams: null;
  }

  const path = portalClientPortfolioAssetAllocationsPath(client_id, id);

  const [getAssetData, isError, isLoading] = useAjaxRequest<IRequestParams, any>({
    method: 'GET',
    path,
    stateSetter: setAssetData,
  });

  const defaultPieWrapper = classNames(
    'animate__animated',
    'animate__fast',
    { animate__fadeOutLeft: !defaultOpen },
    { animate__fadeInLeft: defaultOpen },
  );

  const subChartWrapper = classNames(
    'animate__animated',
    'animate__fast',
    { animate__fadeOutRight: defaultOpen },
    { animate__fadeInRight: !defaultOpen },
  );

  const backToMain = (
    <span onClick={resetSlice} className="link link--left-arrow">
      Back
    </span>
  );

  const RenderCharts = () => {
    const colourMap = {};
    const pieData = assetData.data.find((data) => data.name === activeDate);

    const columnContextValue = React.useMemo(
      () => ({ ...defaultColumnContextValue, maxColumns: 2, columnSpan: 1 }),
      [],
    );

    if (!pieData) {
      return <p className="text-white helper-text mar-t-3">No data available currently.</p>;
    }
    const formattedPieData = pieData.series.map((dataItem) => {
      const colour = getColour(dataItem.asset_class);
      colourMap[dataItem.name] = colour;
      dataItem.color = colour;
      dataItem.value = parseFloat(dataItem.value);
      return dataItem;
    });

    return (
      <Frow gutterSize={1} itemAlignment="center" justifyContent="between">
        <ColumnContext.Provider value={columnContextValue}>
          <Column>
            <ReactStackedAreaChart
              data={assetData.data}
              height={290}
              showYAxis={false}
              showXAxis={false}
              showTooltip
              handleActiveLabelChange={handleActiveLabelChange}
              colourMap={colourMap}
            />
            <p className="helper-text text-white mar-t-2">
              Click a time period on the area chart to see it reflected in the pie chart
            </p>
          </Column>
          <Column>
            <Frow itemAlignment="center" justifyContent="between">
              {defaultOpen ? <span /> : backToMain}
            </Frow>
            <div
              style={{ position: 'relative', height: '100%', overflow: 'hidden' }}
              className="portal-asset-pie-chart"
            >
              <div className={defaultPieWrapper} style={{ top: 0, left: 0, right: 0, bottom: 0 }}>
                <Frow gutterSize={2} itemAlignment="center" justifyContent="between">
                  <ColumnContext.Provider value={columnContextValue}>
                    <Column>
                      <ReactPieChart
                        animationDuration={500}
                        animationBegin={200}
                        activeShape
                        cursor="default"
                        data={formattedPieData}
                        height={300}
                        labelText={activeDate}
                        labelSize="3rem"
                        noData={formattedPieData.length < 1}
                        displayLegend={false}
                        setSelectedSlice={setSelectedSlice}
                      />
                    </Column>
                    <PieChartTableLegend headerTitle="Asset Class" data={formattedPieData} />
                  </ColumnContext.Provider>
                </Frow>
              </div>
              <div
                className={subChartWrapper}
                style={{ position: 'relative', top: 0, left: 0, right: 0, bottom: 0, height: '100%' }}
              ></div>
            </div>
          </Column>
        </ColumnContext.Provider>
      </Frow>
    );
  };

  return (
    <div className="col-md-1-1">
      <ClientPortalCard title="Asset Allocation">
        {isLoading || isError ? <Loading /> : <RenderCharts />}
      </ClientPortalCard>
    </div>
  );
};

export default AssetAllocation;
