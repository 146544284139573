import * as React from 'react';

import ChangeDate from './ChangeDate';
import MatchColumn from './MatchColumn';
import MatchCondition from './MatchCondition';
import PotentiallyBlank from './PotentiallyBlank';

import Alert from 'components/shared/Alert';

import { UploadTemplateCondition } from 'javascript/models';

interface IProps {
  conditionOptions?: { label: string; value: string }[];
  uploadTemplateCondition: UploadTemplateCondition;
  saveConditions: boolean;
  handleClose(): void;
  removeCondition(conditionId: string): void;
}

export default function condition(props: IProps) {
  const { conditionOptions, handleClose, removeCondition, saveConditions } = props;

  const [uploadTemplateCondition, setUploadTemplateCondition] = React.useState(props.uploadTemplateCondition);
  const [condition, setCondition] = React.useState({ ...props.uploadTemplateCondition.attributes });
  const [errors, setErrors] = React.useState('');

  React.useEffect(() => {
    setCondition(props.uploadTemplateCondition);
  }, [props.uploadTemplateCondition]);

  function handleChange(event) {
    const newCondition = { ...condition };
    newCondition.conditionType = event.target.value;
    setCondition(newCondition);
  }

  function handleRemoveCondition() {
    removeCondition(uploadTemplateCondition.id);
  }

  function handleAttributeChange(attribute, value) {
    const newCondition = { ...condition };
    newCondition[attribute] = value;
    setCondition(newCondition);
  }

  async function updateColumn() {
    setErrors('');
    const {
      blankOption,
      blankText,
      conditionType,
      conditions,
      dateChangeType,
      matchOption,
      matchText,
      matchType,
      row,
    } = condition;

    uploadTemplateCondition.blankOption = blankOption;
    uploadTemplateCondition.blankText = blankText;
    uploadTemplateCondition.conditionType = conditionType;
    uploadTemplateCondition.conditions = conditions;
    uploadTemplateCondition.dateChangeType = dateChangeType;
    uploadTemplateCondition.matchOption = matchOption;
    uploadTemplateCondition.matchText = matchText;
    uploadTemplateCondition.matchType = matchType;
    uploadTemplateCondition.row = row;

    const success = await uploadTemplateCondition.save();
    if (success) {
      handleClose();
    } else {
      const errorArray = [];
      const objectErrors = uploadTemplateCondition.errors;
      for (const key in objectErrors) {
        if (objectErrors.hasOwnProperty(key)) {
          errorArray.push(objectErrors[key].fullMessage);
        }
      }
      setErrors(errorArray.join(', '));
    }
  }

  React.useEffect(() => {
    if (saveConditions) {
      updateColumn();
    }
  }, [saveConditions]);

  const formattedConditionOptions = conditionOptions.map((option) => {
    return (
      <div key={option.value} className="pretty pretty--large pretty--with-label p-round">
        <input
          type="radio"
          value={option.value}
          id="column_type"
          onChange={handleChange}
          checked={condition.conditionType === option.value}
        />
        <div className="state p-blue-circle">
          <label>{option.label}</label>
        </div>
      </div>
    );
  });

  React.useEffect(() => {
    setUploadTemplateCondition(props.uploadTemplateCondition);
  }, [props.uploadTemplateCondition]);

  function renderConditionFields() {
    switch (condition.conditionType) {
      case 'match_column':
        return <MatchColumn {...{ handleAttributeChange, uploadTemplateCondition }} />;
      case 'match_condition':
        return <MatchCondition {...{ handleAttributeChange, uploadTemplateCondition }} />;
      case 'potentially_blank':
        return <PotentiallyBlank {...{ handleAttributeChange, uploadTemplateCondition }} />;
      case 'move_date':
        return <ChangeDate {...{ handleAttributeChange, uploadTemplateCondition }} />;
      default:
        return;
    }
  }

  return (
    <div className="form form--light">
      <div className="platform-content platform-content--padding-bottom">
        <Alert type="error" title="Issue saving Condition" message={errors} />
        <label className="form__label">Type of Condition</label>
        <div className="frow frow--gutters">{formattedConditionOptions}</div>
      </div>
      {renderConditionFields()}
      <div className="col-mc-1-1">
        <div className="mar-v-2">
          <div className="frow frow--gutters">
            <div>
              <button className="button button--secondary button--danger" type="button" onClick={handleRemoveCondition}>
                Remove Condition
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

condition.defaultProps = {
  conditionOptions: UploadTemplateCondition.conditionOptions,
};
