import * as React from 'react';

export default function columns() {
  return [
    {
      Header: 'Name',
      accessor: 'name',
      columnName: 'Name',
      id: 'name',
    },
    {
      Header: 'Reference',
      accessor: 'reference',
      columnName: 'reference',
      id: 'reference',
    },
    {
      Header: 'User Count',
      accessor: 'userCount',
      columnName: 'User Count',
      id: 'user_count',
    },
    {
      Header: 'Portfolio Count',
      accessor: 'portfolioCount',
      columnName: 'Portfolio Count',
      id: 'portfolio_count',
    },
    {
      Cell: (row) => {
        return (
          <div className="frow frow--items-center">
            <img alt="Client type icon" src={row.original.clientTypeIcon} className="mar-r-1 client-type-table-icon" />
          </div>
        );
      },
      Header: 'Client Type',
      accessor: 'clientType',
      columnName: 'Client Type',
      id: 'client_type',
      sortable: false,
    },
    {
      Cell: (row) => {
        return (
          <a href={row.original.showPath} style={{ display: 'block' }}>
            <div className="rt-tr-clickable-icon">
              <i className="icon-arrow-right-top icon-0-8x" />
            </div>
          </a>
        );
      },
      Header: '',
      sortable: false,
    },
  ];
}
