import * as React from 'react';

const numeral = require('numeral');

import ReviewRow from './portfolioRow/ReviewRow';

import Loading from 'components/shared/Loading';
import useVisibility from 'components/shared/customHooks/useVisibility';

import { Portfolio, Review } from 'javascript/models';

export default function portfolioRow({ portfolio }: { portfolio: Portfolio }) {
  const { monitoringInvoiceableTotalReviewFee, monitoringInvoiceableReviewCount, id, reference } = portfolio;

  const { isOpen, toggleOpen } = useVisibility(false);

  const [isLoading, setIsLoading] = React.useState(false);
  const [reviews, setReviews] = React.useState([]);

  React.useEffect(() => {
    if (isOpen && reviews.length < 1) {
      setIsLoading(true);
      getReviews();
    }
  }, [isOpen]);

  async function getReviews() {
    const { data } = await Review.where({ reviewable_id: id, reviewable_type: 'Portfolio', status: 'sent' })
      .where({ invoiced_at: { eq: false } })
      .all();

    setReviews(data);
    setIsLoading(false);
  }

  function handleClick(event) {
    event.stopPropagation();
    toggleOpen();
  }

  function renderReviews() {
    if (!isOpen) return;

    const portfolioReviews = reviews.map((review, index) => {
      return <ReviewRow key={`${index}-${review.id}`} {...{ index, review }} reviewCount={reviews.length} />;
    });

    return (
      <div className="platform-content platform-content--spacing-top platform-content--border-top-mc">
        <div className="frow">{isLoading ? <Loading flexDirection="column" /> : portfolioReviews}</div>
      </div>
    );
  }

  return (
    <div className="col-mc-1-1">
      <div
        className="platform-content platform-content--padding-all platform-content--border-bottom-mc"
        onClick={handleClick}
        style={{ cursor: 'pointer' }}
      >
        <div className="frow frow--gutters">
          <div className="col-sm-1-12">
            <i className={`icon-add icon-animated icon-clickable icon-2x text-white ${isOpen && 'icon-rotate'}`} />
          </div>
          <div className="col-sm-2-12">
            <span className="small-uppercase">
              <span className="text-white">{reference}</span>
            </span>
          </div>
          <div className="col-sm-2-12">
            <span className="small-uppercase">
              Total Fee Amount:{' '}
              <span className="text-white">{numeral(monitoringInvoiceableTotalReviewFee).format('0,0.00')}</span>
            </span>
          </div>
          <div className="col-sm-2-12">
            <span className="small-uppercase">
              Invoiceable Reviews: <span className="text-white">{monitoringInvoiceableReviewCount}</span>
            </span>
          </div>
        </div>
        {renderReviews()}
      </div>
    </div>
  );
}
