const dataPoints = [
  [3, 27, 45, 20, 5],
  [5, 20, 41, 22, 12],
  [1, 30, 50, 15, 4],
  [0, 19, 60, 18, 3],
  [10, 40, 30, 10, 10],
  [5, 25, 40, 10, 20],
  [10, 24, 46, 17, 3],
  [4, 36, 30, 25, 5],
  [6, 13, 40, 30, 10],
  [7, 33, 20, 35, 5],
  [1, 39, 40, 5, 15],
  [2, 28, 50, 15, 5],
  [30, 10, 30, 12, 18],
];

export function data(series) {
  return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 13].map((num) => {
    const dataPointHash = {};
    series.forEach((dataPoint, index) => {
      dataPointHash[dataPoint] = dataPoints[num - 1][index];
    });
    return dataPointHash;
  });
}

function randomBetween(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}
