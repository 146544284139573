import * as React from 'react';
import FormRow from 'components/shared/forms/FormRow';
import IPortfolioBenchmark from 'components/interfaces/IPortfolioBenchmark';
import ComponentBenchmarks from './composite/ComponentBenchmarks';

interface IProps {
  componentBenchmarksFromBuilder: [];
  portfolioBenchmark: { data: IPortfolioBenchmark };
  handleCompositeChange(checked: boolean): void;
}

const Composite = (props: IProps) => {
  const { composite, componentBenchmarks } = props.portfolioBenchmark.data.attributes;
  const selectedBenchmarks = props.componentBenchmarksFromBuilder;

  const isOpenInitialValue = composite || props.componentBenchmarksFromBuilder.length > 1;

  const [isOpen, setIsOpen] = React.useState(isOpenInitialValue);

  const handleCheck = React.useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  React.useEffect(() => {
    props.handleCompositeChange(isOpen);
  }, [isOpen, props]);

  return (
    <>
      <input type="hidden" name="portfolio_benchmark[composite]" value="0" />
      <FormRow note="" className="col-mc-1-1">
        <label htmlFor="currency" className="form__label">
          Composite
        </label>
        <div className="pretty p-icon">
          <input
            type="checkbox"
            name="portfolio_benchmark[composite]"
            value="1"
            defaultChecked={isOpenInitialValue}
            onChange={handleCheck}
          />
          <div className="state p-blue">
            <i className="icon-tick icon icon-push-down-small" />
            <label className="text-white">Composite Benchmark</label>
          </div>
        </div>
      </FormRow>
      {isOpen ? <ComponentBenchmarks selectedBenchmarks={selectedBenchmarks} {...{ componentBenchmarks }} /> : null}
    </>
  );
};

export default Composite;
