import { Attr, HasMany, Model } from 'spraypaint';
import { ApplicationRecord } from './ApplicationRecord';
import { Portfolio } from './Portfolio';

@Model()
export class Entity extends ApplicationRecord {
  public static jsonapiType = 'entities';
  @Attr() public alias: string;
  @Attr() public entityGroupingId: string;
  @Attr() public name: string;
  @Attr() public portfoliosCount: number;
  @Attr() public showPath: string;

  @HasMany() public portfolios: Portfolio;
}
