import * as React from 'react';

export interface IPropsDDO {
  key?: string;
  display: string;
  value: string | number;
}

interface IHandleSelect {
  handleSelect?(option: IPropsDDO): void;
}

type IProps = IPropsDDO & IHandleSelect;

export default function dropdownOption(props: IProps) {
  const { display, handleSelect } = props;
  function onClick(e) {
    handleSelect(props);
  }

  return (
    <div className="dropdown__nav-link" onClick={onClick}>
      {display}
    </div>
  );
}
