import { Attr, Model } from 'spraypaint';
import { ApplicationRecord } from './ApplicationRecord';

@Model()
export class DataChaser extends ApplicationRecord {
  public static jsonapiType = 'data_chasers';
  @Attr() public administratorId: string;
  @Attr() public emailType: string;
  @Attr() public status: string;
  @Attr({ persist: false }) public preview: any;
}
