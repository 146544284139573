import * as React from 'react';

import { ReportTemplatesReportTemplateSection } from 'javascript/models';
import Context from '../../../Context';

import { legendOptions } from './shared/options';
import LegendKeys from './shared/LegendKeys';

export default function scatterChart({ attributes, handleChange, handleManualChange, isRepeating }) {
  const context = React.useContext(Context);
  const { reportTemplate } = context.state;

  const isConsolidated = reportTemplate.consolidated;

  const { scatterChartDetails } = attributes;
  const defaultKey = (scatterChartDetails && scatterChartDetails.name_type) || 'enhance_reference';

  const [series, setSeries] = React.useState(attributes.scatterChartSeries || []);
  const [selectedSize, setSelectedSize] = React.useState(attributes.size);
  const [selectedTimePeriod, setTimePeriod] = React.useState(attributes.scatterChartTimePeriod);
  const [type, setType] = React.useState(attributes.scatterChartType);
  const [selectedLegend, setSelectedLegend] = React.useState(attributes.legendPosition);
  const [selectedKey, setSelectedKey] = React.useState(defaultKey);

  function updateAttributes() {
    const value = { series, type, time_period: selectedTimePeriod, name_type: selectedKey };
    handleManualChange(value, 'scatterChartDetails');
  }

  React.useEffect(() => {
    updateAttributes();
  }, [series, type, selectedTimePeriod, selectedKey]);

  function handleSeriesChange() {
    const updatedSeries = series.toggle(this);
    setSeries(updatedSeries);
  }

  function handleTypeChange() {
    setType(this);
  }
  function handlePeriodChange() {
    setTimePeriod(this);
  }

  function handleSizeChange() {
    setSelectedSize(this);
    handleManualChange(this, 'size');
  }

  function handleLegendChange(selectedOption) {
    setSelectedLegend(selectedOption);
    handleManualChange(selectedOption, 'legendPosition');
  }

  function handleKeyChange() {
    setSelectedKey(this);
  }

  let scopedSeriesOptions = ReportTemplatesReportTemplateSection.seriesOptions;
  if (isConsolidated) {
    scopedSeriesOptions = [
      'Consolidated Portfolio',
      'Constituent Portfolios',
      ...ReportTemplatesReportTemplateSection.seriesOptions.filter((option) => option !== 'Portfolio'),
    ];
  }

  if (isRepeating) {
    scopedSeriesOptions = [
      'Constituent Portfolio',
      ...ReportTemplatesReportTemplateSection.seriesOptions.filter((option) => option !== 'Portfolio'),
    ];
  }

  if (type === 'up_down_capture') {
    scopedSeriesOptions = [
      ...ReportTemplatesReportTemplateSection.seriesOptions.filter((option) => option !== 'Portfolio'),
    ];
    if (isConsolidated) {
      scopedSeriesOptions.unshift('Constituent Portfolios');
    }
  }

  const seriesOptions = scopedSeriesOptions.map((option) => {
    return (
      <div className="col-tn-1-3" key={option}>
        <div className="pretty p-icon">
          <input
            type="checkbox"
            value={option}
            id="ScatterSeries"
            onChange={handleSeriesChange.bind(option)}
            checked={series.includes(option)}
          />
          <div className="state p-blue">
            <i className="icon-tick icon icon-push-down-small" />
            <label>{option}</label>
          </div>
        </div>
      </div>
    );
  });

  const typeOptions = ReportTemplatesReportTemplateSection.scatterTypeOptions.map((typeOption) => {
    return (
      <div className="col-tn-1-3" key={typeOption.value}>
        <div className="pretty pretty--large pretty--with-label p-round">
          <input
            type="radio"
            value={typeOption.value}
            id="type"
            onChange={handleTypeChange.bind(typeOption.value)}
            checked={type === typeOption.value}
          />
          <div className="state p-blue-circle">
            <label>{typeOption.label}</label>
          </div>
        </div>
      </div>
    );
  });

  const timePeriodOptions = ReportTemplatesReportTemplateSection.timePeriodOptions.map((timePeriod) => {
    return (
      <div className="col-tn-1-3" key={timePeriod.value}>
        <div className="pretty pretty--large pretty--with-label p-round">
          <input
            type="radio"
            value={timePeriod.value}
            id="ScatterTimePeriod"
            onChange={handlePeriodChange.bind(timePeriod.value)}
            checked={selectedTimePeriod === timePeriod.value}
          />
          <div className="state p-blue-circle">
            <label>{timePeriod.label}</label>
          </div>
        </div>
      </div>
    );
  });

  const scatterSizeOptions = [
    { value: 'small', label: 'Standard' },
    { value: 'large', label: 'Large' },
  ];

  const sizeOptions = scatterSizeOptions.map((size) => {
    return (
      <div className="col-tn-1-3" key={size.value}>
        <div className="pretty pretty--large pretty--with-label p-round">
          <input
            type="radio"
            value={size.value}
            id="size"
            onChange={handleSizeChange.bind(size.value)}
            checked={selectedSize === size.value}
          />
          <div className="state p-blue-circle">
            <label>{size.label}</label>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="frow frow--gutters">
      <div className="col-mc-1-1">
        <label className="form__label" htmlFor="">
          Series
        </label>
        <div className="frow">{seriesOptions}</div>
      </div>
      <div className="col-mc-1-1">
        <label className="form__label" htmlFor="">
          Time Period
        </label>
        <div className="frow ">{timePeriodOptions}</div>
      </div>
      <div className="col-mc-1-1">
        <label className="form__label" htmlFor="">
          Chart Type
        </label>
        <div className="frow ">{typeOptions}</div>
      </div>
      <div className="col-mc-1-1">
        <label className="form__label" htmlFor="">
          Size
        </label>
        <div className="frow ">{sizeOptions}</div>
      </div>
      {legendOptions(selectedLegend, handleLegendChange)}
      <LegendKeys {...{ handleKeyChange, selectedKey }} />
    </div>
  );
}
