import * as $ from 'jquery';

import CsrfToken from 'components/shared/CsrfToken';

export type HttpMethod = 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE' | 'HEAD' | 'CONNECT' | 'OPTIONS' | 'TRACE';

export interface IAjaxRequest<RequestParams> {
  cache?: boolean;
  includeCsrfToken?: boolean;
  path: string;
  method?: HttpMethod;
  params?: RequestParams;
  beforeSend?: () => void;
}

const ajaxRequest = <Response, RequestParams>({
  cache = false,
  includeCsrfToken = true,
  path,
  method = 'GET',
  params,
  beforeSend,
}: IAjaxRequest<RequestParams>) => {
  const data: typeof params & { authenticity_token?: string } = { ...params };
  if (includeCsrfToken) data.authenticity_token = CsrfToken();

  // having a particularly annoying issue with conflicting indent rules so am disabling them for now. The indentation
  //   below was produced by prettier.
  /* eslint-disable indent */
  return new Promise<Response>((resolve, reject) => {
    $.ajax({
      beforeSend,
      cache,
      data,
      dataType: 'json',
      error: (_: unknown, errorString: string) => {
        reject(errorString);
      },
      success: (response: Response) => resolve(response),
      type: method,
      url: path,
    });
  });
};
/* eslint-enable indent */

export default ajaxRequest;
