import * as React from 'react';

import ReviewFilters, { IReviewFiltersProps } from './dashboard/ReviewFilters';
import ReviewOverview, { IReviewOverviewProps } from '../dashboard/ReviewOverview';
import ClientsTable, { IGraphitiTableProps } from '../clients/Table';

import DashSwitcher, { IDashSwitcherProps } from '../dashboard/DashSwitcher';

interface IProps {
  clientsTable: IGraphitiTableProps & { key?: string };
  dashSwitcher: IDashSwitcherProps;
  reviewFilters: IReviewFiltersProps;
  reviewOverview: IReviewOverviewProps;
  dashboard: any;
}

function possessive_proper_noun(noun: string): string {
  if (noun.length < 3) return '';

  const lastChar: string = noun[noun.length - 1];

  let posNoun = `${noun}'`;
  if (lastChar !== 's') {
    posNoun = `${posNoun}s`;
  }

  return posNoun;
}

function reformatFilters(filters) {
  const localFilters = { ...filters };
  if (filters.relationship_manager !== '*') {
    localFilters.relationship_manager_id = filters.relationship_manager;
  }

  delete localFilters.relationship_manager;
  return localFilters;
}

export default function dashboard(props: IProps) {
  const { reviewFilters, reviewOverview, clientsTable, dashSwitcher, dashboard } = props;

  const [relationshipManager, setRelationshipManager] = React.useState({
    value: '*',
    display: 'All Relationship Managers',
  });
  const [filterOptions, setFilterOptions] = React.useState(
    reformatFilters({ quarter: `${reviewFilters.currentQuarter}`, year: `${reviewFilters.currentYear}` }),
  );

  const localClientsTable = { ...clientsTable };
  const localReviewFilters = { ...reviewFilters };

  localClientsTable.scope = filterOptions;
  localClientsTable.title = `${
    relationshipManager.value === '*'
      ? relationshipManager.display
      : possessive_proper_noun(relationshipManager.display)
  } Clients`;

  localReviewFilters.handleRMAdminSelect = setRelationshipManager;
  localReviewFilters.currentUserID = dashboard.adminID;

  const setFilters = (event) => {
    const filters = reformatFilters(event.detail);
    setFilterOptions(filters);
  };

  React.useEffect(() => {
    document.addEventListener('dropdownFilters:change', setFilters);
    return () => {
      document.removeEventListener('dropdownFilters:change', setFilters);
    };
  }, []);

  return (
    <>
      <div className="platform-panel platform-panel--no-background">
        <div className="frow frow--gutters-2x frow--justify-between">
          <div className="frow frow--items-center">
            <div className="frow frow--direction-column">
              <p className="subtitle-two">{dashboard.date}</p>
              <h2 className="heading-one text-lh-1 ">Dashboard</h2>
            </div>
            <span className="horizontal-divider hide-tn-inline" />
            <DashSwitcher {...dashSwitcher} />
          </div>
          <div className="review-filters">
            <ReviewFilters {...localReviewFilters} />
          </div>
        </div>
      </div>
      <ReviewOverview {...reviewOverview} />
      <div className="platform-panel">
        <div className="platform-content platform-content--padding-all">
          <ClientsTable key={filterOptions} {...localClientsTable} />
        </div>
      </div>
    </>
  );
}
