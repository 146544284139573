import * as React from 'react';

import ReactPieChart from 'components/shared/charting/recharts/ReactPieChart';
import { isLoading } from 'components/shared/HigherOrderComponents';

interface IProps {
  isLoading: boolean;
  meta: {
    stats: {
      total: {
        awaiting_data_count: number;
        error_count: number;
        data_received_count: number;
        count: number;
      };
    };
  };
}

function graph(props: IProps) {
  const { meta } = props;

  const data = [
    {
      color: '#3990A8',
      name: 'Awaiting Data',
      value: meta.stats.total.awaiting_data_count,
    },
    {
      color: '#78ae6e',
      name: 'Data Received',
      value: meta.stats.total.data_received_count,
    },
    {
      color: '#B81C1C',
      name: 'Error',
      value: meta.stats.total.error_count,
    },
  ];

  const totalCount = meta.stats.total.count;

  return <ReactPieChart data={data} height={380} labelText={meta.stats.total.count} noData={totalCount < 1} />;
}

export default isLoading()(graph);
