import * as React from 'react';

import Context from '../../../Context';
import Button from 'components/shared/forms/Button';

export default function contents() {
  const context = React.useContext(Context);
  const { reportTemplate } = context.state;
  const { getData } = context.actions;
  const [reportTemplateContents, setReportTemplateContents] = React.useState(reportTemplate.reportTemplateContents);

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  if (reportTemplate.reportTemplateContents < 1) return <div />;

  async function handleSave() {
    setIsSubmitting(true);

    await reportTemplate.save({ with: 'reportTemplateContents' });
    setIsSubmitting(false);
    getData();
  }

  const nonDestroyedContents = reportTemplateContents.filter((content) => !content.isMarkedForDestruction);

  const contents = nonDestroyedContents.map((reportTemplateContent) => {
    function handleDelete() {
      const newContents = [...reportTemplateContents];
      const index = newContents.map((content) => content.id).indexOf(reportTemplateContent.id);
      newContents[index].isMarkedForDestruction = true;
      setReportTemplateContents(newContents);
    }
    return (
      <li className="pdf-list__item" key={reportTemplateContent.id}>
        <div className="frow frow--justify-between frow--items-center">
          <div>{reportTemplateContent.name}</div>
          <div style={{ cursor: 'pointer' }} onClick={handleDelete}>
            <i className="icon-bin icon-fw text-brand-red" />
          </div>
        </div>
      </li>
    );
  });

  return (
    <div className="frow frow--gutters">
      <div className="col-mc-1-1">
        <ul className="pdf-list">{contents}</ul>
      </div>
      <div className="col-mc-1-1">
        <Button
          buttonClasses="button button--light-themed"
          handleClick={handleSave}
          isSubmitting={isSubmitting}
          successText="Updated"
          submittingText="Updating Contents"
          text="Update Contents"
        />
      </div>
    </div>
  );
}
