import * as React from 'react';
import ISelectOption from 'components/interfaces/ISelectOption';
import { Security } from 'javascript/models';

import { Administrator, SecurityState } from 'javascript/models';

import { ICriterionOptions } from 'components/interfaces/ICriterionOptions';

interface IActions {
  setOverwriteClassifications?: (newValue: boolean) => void;
}

interface IProps {
  criterionOptions: ICriterionOptions;
}

interface IState {
  currentUser?: Administrator;
  overwriteClassifications?: boolean;
}

interface IContext {
  props: IProps;
  state: IState;
  actions: IActions;
}

const Context = React.createContext<IContext>({
  actions: {},
  props: {
    criterionOptions: {
      countryOfDomicile: [],
      countryOfRisk: [],
      creditRating: [],
      currency: [],
      dividendDistributionPolicy: [],
      headlineAssetClass: [],
      industry: [],
      instrument: [],
      lookthroughAssetClass: [],
    },
  },
  state: {
    currentUser: null,
    overwriteClassifications: null,
  },
});

export default Context;
