import * as React from 'react';

import { Alert, Intent } from '@blueprintjs/core';

import useVisibility from 'components/shared/customHooks/useVisibility';

export default function destroy({ handleValuationUpdate, valuation }) {
  const { handleClose, handleOpen, isOpen } = useVisibility(false);

  async function confirmDestroy() {
    await valuation.destroy();
    handleClose();
    handleValuationUpdate();
  }

  return (
    <React.Fragment>
      <div className="button button--secondary button--danger button--icon" onClick={handleOpen}>
        <i className="icon-bin icon-fw" />
      </div>
      <Alert
        isOpen={isOpen}
        onClose={handleClose}
        canEscapeKeyCancel
        canOutsideClickCancel
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        intent={Intent.DANGER}
        onCancel={handleClose}
        onConfirm={confirmDestroy}
        portalContainer={document.body}
      >
        <p className="mar-t-0">Are you sure you want to delete this valuation?</p>
      </Alert>
    </React.Fragment>
  );
}
