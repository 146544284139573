import { Attr, BelongsTo, HasMany, Model } from 'spraypaint';

import { createAlert } from 'components/shared/Utils';

import { ApplicationRecord } from './ApplicationRecord';
import { Administrator } from './Administrator';
import { User } from './User';
import { Note, Review } from './Models';
import { THasManyAttached } from './shared/types';
import { ActionPointsReview } from './ActionPointsReview';

export type RejectionAction = 'rejectDeferral' | 'rejectCompletion';

export type ActionPointStatus = 'inProgress' | 'inReview' | 'released';

@Model()
export class ActionPoint extends ApplicationRecord {
  public static jsonapiType = 'action_points';
  @Attr() public actionPointType: string;
  @Attr() public body: string;
  @Attr() public commentCount: number;
  @Attr() public completed: boolean;
  @Attr() public completedNote: string;
  @Attr() public createdAt: string;
  @Attr() public deferredUntil: string;
  @Attr() public deferredNote: string;
  @Attr() public documentCount: number;
  @Attr() public firstIncludedDate: string;
  @Attr() public assigneeId: string;
  @Attr() public assigneeType: string;
  @Attr() public releaserId: string;
  @Attr() public completerId: string;
  @Attr() public completerType: string;
  @Attr() public portfolio: any;
  @Attr() public status: string;
  @Attr() public response: string;
  @BelongsTo() public author: Administrator;
  @BelongsTo() public assignee: User | Administrator;
  @BelongsTo() public releaser: User;
  @Attr() public documents: THasManyAttached;
  @HasMany() public notes: Note[];
  @HasMany(Note) public completeNotes: Note[];
  @HasMany(Note) public deferralNotes: Note[];
  @HasMany() public actionPointsReviews: ActionPointsReview[];
  @HasMany() public reviews: Review[];

  @Attr() public entityName: string;

  public canPerformAutomaticStateReversion = (noteToDelete: Note) => {
    if (this.deferralNotes.length === 0) return true;

    const nextIndex = noteToDelete.noteCategory === 'action_point_deferred' ? 1 : 0;
    const nextNote = this.deferralNotes[nextIndex];

    if (
      noteToDelete.noteCategory === 'action_point_deferred' &&
      noteToDelete !== this.deferralNotes[0] &&
      this.deferralNotes.length > 1
    )
      return true;

    const fail = () =>
      createAlert(
        'warning',
        'Unable to extract deferral information from previous deferral. Please redefer manually.',
        false,
      );

    try {
      const nextNoteAdditionalInfo = JSON.parse(nextNote.additionalInfo);

      if (nextNoteAdditionalInfo.originalNote === null || nextNoteAdditionalInfo.deferredUntil === null) {
        fail();
        return false;
      }

      return true;
    } catch {
      fail();
      return false;
    }
  };

  public release = async (releaser: User, releaseNote: string) => {
    Object.assign(this, {
      assigneeId: null,
      assigneeType: null,
      releaserId: releaser.id,
    });
    if (releaseNote.length) {
      this.notes.push(
        new Note({
          body: releaseNote,
          noteCategory: 'note',
          noteType: 'action_point_response',
          status: 'normal',
        }),
      );
    }
    return this.saveWithNotes();
  };

  public reject = (action: RejectionAction, rejecter: User, rejectionNote: string) => {
    Object.assign(this, {
      assigneeId: this.completerId,
      assigneeType: this.completerType,
    });
    switch (action) {
      case 'rejectDeferral':
        this.rejectDeferral(rejecter);
        break;
      case 'rejectCompletion':
        this.rejectCompletion(rejecter);
    }
    this.notes.push(
      new Note({
        authorId: rejecter.id,
        authorType: 'User',
        body: rejectionNote,
        noteCategory: 'note',
        noteType: 'action_point_response',
        status: 'normal',
      }),
    );
    return this.saveWithNotes();
  };

  public defer = (deferralNote: string, deferredUntil: Date, completer: User, newAssignee: User) => {
    Object.assign(this, {
      assigneeId: newAssignee.id,
      assigneeType: 'User',
      completerId: completer.id,
      completerType: 'User',
      deferredUntil,
    });
    this.deferralNotes.push(
      new Note({
        authorId: completer.id,
        authorType: 'User',
        body: deferralNote,
        noteCategory: 'action_point_deferred',
        noteType: 'general',
      }),
    );
    return this.saveWithNotes();
  };

  public complete = (completer: User, completionNote: string, newAssignee: User) => {
    Object.assign(this, {
      assigneeId: newAssignee.id,
      assigneeType: 'User',
      completed: true,
      completedNote: completionNote,
      completerId: completer.id,
      completerType: 'User',
      deferredUntil: null,
    });
    this.completeNotes.push(
      new Note({
        authorId: completer.id,
        authorType: 'User',
        body: completionNote,
        noteCategory: 'action_point_complete',
        noteType: 'general',
      }),
    );
    return this.saveWithNotes();
  };

  public assignedToCurrentPortalUser = (currentUser: User) => {
    const { assigneeId, assigneeType } = this;

    return assigneeId === currentUser.id && assigneeType === 'User';
  };

  private saveWithNotes = () =>
    this.save({ with: ['notes', 'deferralNotes', 'completeNotes'], displayQueryResult: true });

  private rejectDeferral = async (rejecter: User) => {
    // Getting a 500 error when trying to delete the last deferral note and a Graphiti::RecordNotFound error
    //  parking this for now due to time constraints but we will need to fix this. Work around is to set the
    //  deferredUntil to null.
    // last(this.deferralNotes).isMarkedForDestruction = true;
    Object.assign(this, {
      completerId: null,
      completerType: null,
      deferredUntil: null,
    });
  };

  private rejectCompletion = (rejecter: User) => {
    Object.assign(this, {
      completed: false,
      completedNote: null,
      completerId: null,
      completerType: null,
    });
  };
}
