import * as React from 'react';

import { IMenuItemProps, Menu, MenuItem, Popover, Position } from '@blueprintjs/core';

import { startCase } from 'lodash';
import { Column } from 'components/frow';

export interface IStringMenuItemProps extends Omit<IMenuItemProps, 'text'> {
  text: string;
}

export interface IActionsDropdownProps {
  actions?: IStringMenuItemProps[];
}

const ActionsDropdown = (props: IActionsDropdownProps) => {
  const { actions } = props;
  if (!actions) return null;

  return (
    <Column columnSpan={1}>
      <Popover usePortal={false} position={Position.BOTTOM}>
        <div className="mar-r-1">
          <i className="icon-overflow-vertical-white icon text-white" />
        </div>
        <Menu>
          {actions.map((props) => (
            <MenuItem key={props.text} {...{ ...props, text: startCase(props.text) }} />
          ))}
        </Menu>
      </Popover>
    </Column>
  );
};

export default ActionsDropdown;
