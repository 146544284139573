import * as React from 'react';

import Context from '../../../Context';
import { ReportTemplatesReportTemplateSection } from 'javascript/models';

export default function horizontalBarChart({ attributes, handleChange, handleManualChange, isRepeating }) {
  const context = React.useContext(Context);
  const { reportTemplate } = context.state;

  const isConsolidated = reportTemplate.consolidated;

  const [target, setTarget] = React.useState(attributes.barChartTarget);
  const [type, setType] = React.useState(attributes.barChartType);

  function updateAttributes() {
    const value = { target, type };
    handleManualChange(value, 'barChartDetails');
  }

  React.useEffect(() => {
    updateAttributes();
  }, [target, type]);

  function handleTargetChange() {
    setTarget(this);
  }
  function handleTypeChange() {
    setType(this);
  }

  let scopedTargetOptions = ReportTemplatesReportTemplateSection.targetOptions;
  if (isConsolidated) {
    scopedTargetOptions = ReportTemplatesReportTemplateSection.consolidatedTargetOptions;
  }

  if (isRepeating) {
    scopedTargetOptions = ReportTemplatesReportTemplateSection.constituentTargetOptions;
  }

  const targetOptions = scopedTargetOptions.map((option) => {
    return (
      <div className="col-tn-1-3" key={option.value}>
        <div className="pretty pretty--large pretty--with-label p-round">
          <input
            type="radio"
            value={option.value}
            id="h_bar_target"
            onChange={handleTargetChange.bind(option.value)}
            checked={target === option.value}
          />
          <div className="state p-blue-circle">
            <label>{option.label}</label>
          </div>
        </div>
      </div>
    );
  });

  const typeOptions = ReportTemplatesReportTemplateSection.horizontalBarOptions.map((option) => {
    return (
      <div className="col-tn-1-3" key={option.value}>
        <div className="pretty pretty--large pretty--with-label p-round">
          <input
            type="radio"
            value={option.value}
            id="H_bar_type"
            onChange={handleTypeChange.bind(option.value)}
            checked={type === option.value}
          />
          <div className="state p-blue-circle">
            <label>{option.label}</label>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="frow frow--gutters">
      <div className="col-mc-1-1">
        <label className="form__label" htmlFor="">
          Target
        </label>
        <div className="frow">{targetOptions}</div>
      </div>
      <div className="col-mc-1-1">
        <label className="form__label" htmlFor="">
          Type
        </label>
        <div className="frow">{typeOptions}</div>
      </div>
    </div>
  );
}
