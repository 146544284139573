import * as React from 'react';

import Presenter from './form/Presenter';

import ISelectOption from 'components/interfaces/ISelectOption';
import IEntity from 'components/interfaces/IEntity';

interface IProps {
  administrators: ISelectOption[];
  amlRiskRatings: ISelectOption[];
  clientClassifications: ISelectOption[];
  contractOffices: ISelectOption[];
  entityTypes: ISelectOption[];
  entity: { data: IEntity };
  genders: ISelectOption[];
  occupations: ISelectOption[];
  regulators: ISelectOption[];
  serviceOffices: ISelectOption[];
  shortForm?: boolean;
  statuses: ISelectOption[];
  structureServiceTypes: ISelectOption[];
  subTypes: ISelectOption[];
  titles: ISelectOption[];
}

export default function form(props: IProps) {
  return <Presenter {...props} />;
}

form.defaultProps = {
  shortForm: false,
};
