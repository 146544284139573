import * as React from 'react';

import { isAfter, parse } from 'date-fns';

import Card from './actionPoints/Card';

import Context from '../../../Context';
import ReviewContext from './ReviewContext';

import { Review } from 'javascript/models';
import { ActionPointsReview } from 'javascript/models';
import { ActionPoint } from 'javascript/models';

import Loading from 'components/shared/Loading';
import { SubtitleTwo } from 'components/shared/Typography';

import ActionPointModal, { IActionPointModalProps } from '../../../../../../modals/reviews/ActionPointModal';
import ThemeContext from 'components/shared/context/ThemeContext';
import Regenerate from './shared/Regenerate';

export default function actionPoints() {
  const context = React.useContext(ReviewContext);
  const portfolioContext = React.useContext(Context);
  const { review } = context.props;
  const { handleReviewUpdate } = context.actions;

  const { quantOnly } = portfolioContext.props.portfolio.attributes;

  const [actionPointsReviews, setActionPointsReviews] = React.useState<ActionPointsReview[]>([]);
  const [reviewModel, setReviewModel] = React.useState();

  const [isLoading, setIsLoading] = React.useState(true);

  const { id, endDate } = review.attributes;

  React.useEffect(() => {
    getActionPoints().then(() => setIsLoading(false));
  }, [review]);

  async function getActionPoints() {
    const actionPointScope = ActionPoint.selectExtra(['comment_count', 'document_count']);
    const { data } = await Review.includes({ action_points_reviews: { action_point: ['author', { notes: 'author' }] } })
      .where({ action_points_reviews: { action_point_exists: true } })
      .merge({ actionPoints: actionPointScope })
      .find(id);

    setReviewModel(data);
    setActionPointsReviews(data.actionPointsReviews);
  }

  const handleSuccessfulFormSubmission = React.useCallback(() => handleReviewUpdate(), [handleReviewUpdate]);

  const aroundModalClose = React.useCallback(
    (handleClose) => {
      handleClose();
      handleSuccessfulFormSubmission();
    },
    [handleSuccessfulFormSubmission],
  );

  const actionPointModalProps: Partial<IActionPointModalProps> = {
    modalWithTriggerProps: {
      aroundOnClose: aroundModalClose,
    },
    review: reviewModel,
  };

  const noActionPoints = (type = null) => {
    return <div className="col-mc-1-1 text-white text-small">No {type && `${type} `}Action points</div>;
  };

  const actionPointCards = (scopedActionPointReviews, type = null) => {
    const actionPointCard = scopedActionPointReviews.map((actionPointsReview) => {
      return (
        <Card
          key={actionPointsReview.id}
          {...{ actionPointsReview, handleSuccessfulFormSubmission, actionPointModalProps }}
          deferredDate={endDate}
        />
      );
    });

    return (
      <React.Fragment>
        <SubtitleTwo text={type} additionalClasses="text-white" />
        {actionPointCard.length ? actionPointCard : noActionPoints(type)}
      </React.Fragment>
    );
  };

  function renderActionPoints() {
    if (actionPointsReviews.length < 1) {
      return noActionPoints();
    }

    const completeActionPointReviews = actionPointsReviews.filter((actionPointsReview) => {
      return actionPointsReview.actionPoint.completed;
    });

    const incompleteActionPointReviews = actionPointsReviews.filter((actionPointsReview) => {
      const { actionPoint } = actionPointsReview;
      const deferred = actionPoint.deferredUntil && isAfter(parse(actionPoint.deferredUntil), parse(endDate));
      return !actionPointsReview.actionPoint.completed && !deferred;
    });

    const deferredActionPointReview = actionPointsReviews.filter((actionPointsReview) => {
      const { actionPoint } = actionPointsReview;
      return actionPoint.deferredUntil && isAfter(parse(actionPoint.deferredUntil), parse(endDate));
    });

    return (
      <ThemeContext.Provider value={{ classNameModifier: 'light' }}>
        {actionPointCards(incompleteActionPointReviews, 'Outstanding')}
        {actionPointCards(completeActionPointReviews, 'Complete')}
        {actionPointCards(deferredActionPointReview, 'Deferred')}
      </ThemeContext.Provider>
    );
  }

  const addModal = reviewModel && !quantOnly ? <ActionPointModal addOrEdit="add" {...actionPointModalProps} /> : null;

  return (
    <div className="platform-content platform-content--spacing-horizontal platform-content--padding-top">
      <div className="frow frow--items-center frow--justify-between">
        <div className="frow frow--justify-between col-md-3-5 col-lg-4-5">
          <p className="platform-panel__title">Action Points</p>
          <Regenerate
            reviewId={review.attributes.id}
            reviewStatus={review.attributes.status}
            additionalClasses="button--icon-no-background"
          />
        </div>
        <div className="col-md-2-5 col-lg-1-5 text-right">
          <ThemeContext.Provider value={{ classNameModifier: 'light' }}>{addModal}</ThemeContext.Provider>
        </div>
      </div>
      <div className="mar-t-2">
        <div className="frow frow--gutters">{isLoading ? <Loading /> : renderActionPoints()}</div>
      </div>
    </div>
  );
}
