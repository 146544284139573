import * as React from 'react';

import lodashStartcase from 'lodash.startcase';

import ReactSelect from 'components/shared/forms/ReactSelect';

import ISelectOption from 'components/interfaces/ISelectOption';
import DataFrequencyPicker from 'components/shared/forms/DataFrequencyPicker';
import FormRow from 'components/shared/forms/FormRow';
import AutoResizeTextArea from 'components/shared/forms/AutoResizeTextArea';

import IPortfolioBenchmark from 'components/interfaces/IPortfolioBenchmark';
import Reference from './Reference';
import Surfaceable from './Surfaceable';
import Composite from './Composite';

interface IProps {
  benchmarkTypes: ISelectOption[];
  currencies: ISelectOption[];
  risks: ISelectOption[];
  portfolioBenchmark: { data: IPortfolioBenchmark };
  componentBenchmarks: [];
}

const Presenter = (props: IProps) => {
  const { benchmarkTypes, currencies, portfolioBenchmark, risks, componentBenchmarks } = props;
  const [isComposite, setIsComposite] = React.useState(false);
  const [isSurfaceable, setIsSurfaceable] = React.useState(false);

  const {
    currency,
    description,
    importPath,
    longDescription,
    risk,
    benchmarkType,
    dataFrequencyMonths,
    referencePrefix,
    systemInformation,
  } = portfolioBenchmark.data.attributes;

  function handleCompositeChange(checked: boolean) {
    setIsComposite(checked);
  }

  function handleCanBeSurfacedChange(checked: boolean) {
    setIsSurfaceable(checked);
  }

  function handleCancel() {
    history.back();
  }

  return (
    <div className="frow frow--gutters">
      <FormRow note="" className="col-mc-1-1">
        <label htmlFor="currency" className="form__label">
          Currency
        </label>
        <ReactSelect
          theme="dark"
          name="portfolio_benchmark[currency]"
          id="currency"
          options={currencies}
          defaultValue={{ label: currency ? currency.toUpperCase() : '', value: currency }}
        />
      </FormRow>
      <FormRow note="" className="col-mc-1-1">
        <label htmlFor="risk" className="form__label">
          Risk Profile
        </label>
        <ReactSelect
          theme="dark"
          name="portfolio_benchmark[risk]"
          id="risk"
          options={risks}
          defaultValue={{ label: risk ? lodashStartcase(risk) : '', value: risk }}
        />
      </FormRow>
      <FormRow note="" className="col-mc-1-1">
        <label htmlFor="benchmark_type" className="form__label">
          Type
        </label>
        <ReactSelect
          theme="dark"
          name="portfolio_benchmark[benchmark_type]"
          id="benchmark_type"
          options={benchmarkTypes}
          defaultValue={{ label: benchmarkType ? lodashStartcase(benchmarkType) : '', value: benchmarkType }}
        />
      </FormRow>
      <FormRow note="" className="col-mc-1-1">
        <DataFrequencyPicker name="portfolio_benchmark[data_frequency_months]" defaultChecked={dataFrequencyMonths} />
      </FormRow>
      <FormRow note="" className="col-mc-1-1">
        <label htmlFor="description" className="form__label">
          Short Description
        </label>
        <input
          defaultValue={description}
          id="description"
          type="text"
          placeholder="Description..."
          name="portfolio_benchmark[description]"
        />
      </FormRow>
      <FormRow note="" className="col-mc-1-1">
        <label htmlFor="long_description" className="form__label">
          Long Description
        </label>
        <AutoResizeTextArea
          value={longDescription}
          id="long_description"
          placeholder="Long Description..."
          name="portfolio_benchmark[long_description]"
          style={{ minHeight: '40px' }}
          rows={3}
        />
      </FormRow>
      <FormRow
        note="This is used to ensure we update the correct benchmark from the correct sheet, please do not change unless
          you are sure it is incorrect"
        className="col-mc-1-1"
      >
        <label htmlFor="long_description" className="form__label">
          Import Path
        </label>
        <input
          defaultValue={importPath}
          id="import_path"
          type="text"
          placeholder="Mosaic Arc Benchmarks.xlsx"
          name="portfolio_benchmark[import_path]"
        />
      </FormRow>
      <FormRow note="The pairing between this and the currency must be unique" className="col-mc-1-1">
        <label htmlFor="system_information" className="form__label">
          Ticker
        </label>
        <input
          defaultValue={systemInformation}
          id="system_information"
          name="portfolio_benchmark[system_information]"
          placeholder="E.g. HEDGNAV Index..."
          type="text"
        />
      </FormRow>
      <Composite
        componentBenchmarksFromBuilder={componentBenchmarks}
        {...{ handleCompositeChange, portfolioBenchmark }}
      />
      <Surfaceable {...{ isSurfaceable, handleCanBeSurfacedChange, portfolioBenchmark }} />
      <Reference {...{ isComposite, referencePrefix }} />
      <div className="col-mc-1-1 mar-t-1">
        <button className="button button--full-width">Save</button>
      </div>
      <div className="col-mc-1-1">
        <button
          className="button button--secondary button--outlined button--full-width"
          type="button"
          onClick={handleCancel}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default Presenter;
