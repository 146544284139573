import * as React from 'react';

import Frow from 'components/frow/Frow';
import FormRow from 'components/shared/forms/FormRow';
import { Administrator } from 'javascript/models';
import ReactSelect from 'components/shared/forms/ReactSelect';
import ISelectOption from 'components/interfaces/ISelectOption';
import Loading from 'components/shared/Loading';

import Context from '../Context';

const RelationshipManager = () => {
  const context = React.useContext(Context);
  const { portfolio, client } = context.state;
  const { relationshipManagerOptions } = context.props;
  const { portfolioRelationshipManagerId, clientType } = portfolio;

  const [clientRM, setClientRM] = React.useState<Administrator>();
  const [isLoading, setIsLoading] = React.useState(true);
  const [selectedRM, setSelectedRM] = React.useState<ISelectOption>(
    portfolioRelationshipManagerId &&
      relationshipManagerOptions.find((option) => option.value === +portfolioRelationshipManagerId),
  );

  React.useEffect(() => setIsLoading(!Boolean(client && portfolio)), [client, portfolio]);

  React.useEffect(() => {
    const rmType = clientType in ['portfolio', 'consultancy'] ? 'advisory' : 'monitoring';
    setClientRM(client && client[`${rmType}RelationshipManager`]);
  }, [clientType, client]);

  const formNote = (
    <>
      A relationship manager set against this portfolio will override the relationship manager set for this service line
      at the client level (
      <em>
        <u>{clientRM ? clientRM.fullName : 'not set'}</u>
      </em>{' '}
      for this client and service line).
    </>
  );

  const RelationManagerSelector = () => {
    if (isLoading) return <Loading />;

    return (
      <ReactSelect
        theme="dark"
        name="portfolio[portfolio_relationship_manager_id]"
        id="portfolio_relationship_manager_id"
        isClearable
        value={selectedRM}
        options={relationshipManagerOptions}
        handleChange={setSelectedRM}
      />
    );
  };

  return (
    <fieldset className="form__fieldset">
      <h4 className="form__fieldset-label">Relationship Manager</h4>
      <Frow gutterSize={1}>
        <FormRow note={formNote} className="col-mc-1-1">
          <label htmlFor="" className="form__label">
            Assign Portfolio Specific RM
          </label>
          <RelationManagerSelector />
        </FormRow>
      </Frow>
    </fieldset>
  );
};

export default RelationshipManager;
