import * as React from 'react';
import { ReportTemplate } from 'javascript/models';
import ReactSelect from 'components/shared/forms/ReactSelect';
import ISelectOption from 'components/interfaces/ISelectOption';
import Loading from 'components/shared/Loading';
import lodashFilter from 'lodash.filter';

interface ISelectProps {
  value: ISelectOption;
  id: string;
  name: string;
  options: ISelectOption[];
  theme: 'dark' | 'light';
  handleChange(option: ISelectOption): void;
}

export default function componentSelect({ attributes, handleChange, handleManualChange }) {
  const [componentOptions, setComponentOptions] = React.useState(null);
  const [selectedComponentOption, setSelectedComponentOption] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);

  const convertTemplateToOptions = (componentTemplates: ReportTemplate[]) => {
    return componentTemplates.map((template) => {
      return {
        label: template.name,
        value: template.id,
      };
    });
  };

  async function getComponentTemplates() {
    const { data } = await ReportTemplate.where({ component: true }).all();

    const componentOptionArray: ISelectOption[] = convertTemplateToOptions(data);
    const componentOption = lodashFilter(componentOptionArray, (option: ISelectOption) => {
      return option.value === attributes.componentId;
    })[0];

    setComponentOptions(componentOptionArray);
    setSelectedComponentOption(componentOption);
    setIsLoading(false);
  }

  const handleComponentChange = (selection: ISelectOption) => {
    setSelectedComponentOption(selection);
    handleManualChange(selection.value, 'componentId');
  };

  React.useEffect(() => {
    getComponentTemplates();
  }, []);

  const selectProps: ISelectProps = {
    handleChange: handleComponentChange,
    id: 'component',
    name: 'component',
    options: componentOptions,
    theme: 'light',
    value: selectedComponentOption,
  };

  return (
    <div className="frow frow--gutters">
      <div className="col-mc-1-1">
        <label className="form__label" htmlFor="">
          Title
        </label>
        <input type="text" name="text" value={attributes.text || ''} onChange={handleChange} />
      </div>
      <div className="col-mc-1-1">{isLoading ? <Loading /> : <ReactSelect {...selectProps} />}</div>
    </div>
  );
}
