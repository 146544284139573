import * as React from 'react';

import { Alert, Intent } from '@blueprintjs/core';
import Loading from 'components/shared/Loading';

export default function confirm({ alertOpen, handleAlertClose, confirm, isSubmitting }) {
  const confirmMessage = (
    <div>
      <h4 className="mar-t-0">Are you sure you want to verify?</h4>
      <p>Bulk verification may result in multiple reports being automatically sent.</p>
    </div>
  );

  return (
    <Alert
      isOpen={alertOpen}
      onClose={handleAlertClose}
      canEscapeKeyCancel
      canOutsideClickCancel
      cancelButtonText="Cancel"
      confirmButtonText="Submit"
      intent={Intent.DANGER}
      onCancel={handleAlertClose}
      onConfirm={confirm}
      portalContainer={document.body}
      icon="updated"
    >
      {isSubmitting ? <Loading /> : confirmMessage}
    </Alert>
  );
}
