import * as React from 'react';

import { Answer } from 'javascript/models';
import AnswerDisplay from './answers/AnswerDisplay';
import Loading from 'components/shared/Loading';
import ReviewContext from './ReviewContext';

export default function answers() {
  const context = React.useContext(ReviewContext);
  const { id } = context.props.review;

  const [answers, setAnswers] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    getAnswers().then(() => setIsLoading(false));
  }, []);

  async function getAnswers() {
    const { data } = await Answer.where({ review_id: id }).all();

    setAnswers(data);
  }

  const displayAnswers = (
    <div className="frow frow--gutters frow--direction-column mar-t-2">
      {answers.map((answer, index) => (
        <AnswerDisplay key={answer.id} {...{ answer, index }} />
      ))}
    </div>
  );

  return (
    <div
      className="platform-content platform-content--border-bottom platform-content--spacing-horizontal
        platform-content--padding-vertical"
    >
      <div className="frow frow--items-center">
        <div className="col-md-3-5 col-lg-4-5">
          <p className="platform-panel__title">Question Answers</p>
        </div>
      </div>
      {isLoading ? <Loading /> : displayAnswers}
    </div>
  );
}
