import * as React from 'react';

import lodashStartcase from 'lodash.startcase';

import AutoResizeTextArea from 'components/shared/forms/AutoResizeTextArea';

import IReviewCommentary from 'components/interfaces/IReviewCommentary';
import ICommentaries from 'components/interfaces/ICommentaries';

interface IProps {
  commentaries: ICommentaries;
  index: number;
  reviewCommentary: IReviewCommentary;
  type: string;
  userId: string;
  handleCommentaryChange(commentary, commentaryType): void;
}

export default function presenter(props: IProps) {
  const { commentaries, handleCommentaryChange, index, reviewCommentary, type, userId } = props;

  const startingCommentary = reviewCommentary ? reviewCommentary.attributes.commentary : '';
  const display = reviewCommentary ? reviewCommentary.attributes.display : false;

  const [commentary, setCommentary] = React.useState(startingCommentary || '');
  const [isActive, setIsActive] = React.useState(display);

  function handleChange() {
    setIsActive(!isActive);
  }

  function handleSelect(event) {
    if (commentary) {
      setCommentary(`${commentary}\r\n\r\n${event.target.value}`);
    } else {
      setCommentary(event.target.value);
    }
  }

  function handleTextChange(text) {
    setCommentary(text);
    handleCommentaryChange(commentary, type);
  }

  const id = reviewCommentary ? reviewCommentary.id : '';

  const selectOptions = commentaries.data.map((commentary) => {
    return (
      <option key={commentary.id} value={commentary.attributes.body}>
        {commentary.attributes.title}
      </option>
    );
  });

  return (
    <div>
      <input type="hidden" name={`review[review_commentaries_attributes][${index}][id]`} value={id} />
      <div className="pretty p-icon">
        <input checked={isActive} name="action_point[completed]" type="checkbox" onChange={handleChange} />
        <div className="state p-blue">
          <i className="icon-tick icon text-white icon-push-down-small" />
          <label htmlFor="body" className="text-black">
            {lodashStartcase(type)}
          </label>
        </div>
      </div>
      <input
        type="hidden"
        name={`review[review_commentaries_attributes][${index}][display]`}
        value={isActive ? 1 : 0}
      />
      <input type="hidden" name={`review[review_commentaries_attributes][${index}][author_id]`} value={userId} />
      <input type="hidden" name={`review[review_commentaries_attributes][${index}][commentary_type]`} value={type} />
      <div className="platform-content platform-content--padding-left platform-content--padding-top">
        <div className="form__select-wrapper mar-b-2">
          <select
            disabled={!isActive}
            id="note_category"
            name="action_point[action_point_type]"
            onChange={handleSelect}
            value={''}
          >
            <option value="">Please Select</option>
            {selectOptions}
          </select>
        </div>
        <AutoResizeTextArea
          disabled={!isActive}
          id={`${type}_commentary`}
          label="Commentary"
          // maxLength={1400}
          name={`review[review_commentaries_attributes[${index}][commentary]`}
          rows={10}
          maxRows={20}
          value={commentary}
          onChange={handleTextChange}
        />
      </div>
    </div>
  );
}
