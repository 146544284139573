import * as React from 'react';

import { ReportTemplatesReportTemplateSection } from 'javascript/models';

export default function list({ templateSection }) {
  const defaultListItems = [
    { key: 'List Item', value: 'List description' },
    { key: 'List Item', value: 'List description' },
    { key: 'List Item', value: 'List description' },
  ];

  const haveListItems = templateSection && templateSection.listItems;
  const listItems = haveListItems ? templateSection.listItems : defaultListItems;

  function itemInList(value) {
    const index = ReportTemplatesReportTemplateSection.listOptions.map((option) => option.value).indexOf(value);
    if (index === -1) return <span className="text-error">No item selected</span>;

    return ReportTemplatesReportTemplateSection.listOptions[index].label;
  }

  const formattedListItems = listItems.map((item, index) => {
    return (
      <li key={`${item}_${index}`} className="pdf-list__item">
        <span className="text-uppercase pdf-list__item-key" style={{ display: 'inline-block', width: '50%' }}>
          {haveListItems ? itemInList(item) : item.key}
        </span>
        <span className="pdf-list__item-value">{haveListItems ? itemInList(item) : item.value}</span>
      </li>
    );
  });

  return <ul className="pdf-list pdf-list--content">{formattedListItems}</ul>;
}
