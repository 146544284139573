import * as React from 'react';

export default function ratingBox({ templateSection }) {
  return (
    <table className="pdf-hidden-table">
      <tbody>
        <tr>
          <td>
            <table>
              <tbody>
                <tr>
                  <td className="pad-r-1">
                    <div className="rating-box rating-box-decimal rating-box-decimal--three">3.0</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
}
