import * as React from 'react';

import { default as ModelModal, IModelModal } from '../ModelModal';

import Form from '../../platform/reviews/actionPoints/Form';
import { ActionPoint } from 'javascript/models';
import { Review } from 'javascript/models';

import { IModalWithTriggerProps } from '../ModalWithTrigger';
import useVisibility from 'components/shared/customHooks/useVisibility';

export interface IActionPointModalProps {
  addOrEdit: IModelModal['addOrEdit'];
  actionPoint?: ActionPoint;
  modalWithTriggerProps?: Partial<IModalWithTriggerProps>;
  review?: Review;
}

export class ActionPointModalError extends Error {}

export default function actionPointModal(props: IActionPointModalProps) {
  const { actionPoint, addOrEdit, modalWithTriggerProps, review } = props;

  const actionPointModalVisibility = modalWithTriggerProps.externalUseVisibility || useVisibility(false);

  const newModalWithTriggerProps = { ...modalWithTriggerProps, externalUseVisibility: actionPointModalVisibility };

  let formActionPoint = actionPoint;
  if (review && addOrEdit === 'add') {
    formActionPoint = new ActionPoint();
  }

  if (!formActionPoint) {
    throw new ActionPointModalError(
      'Either a `actionPoint` or a `review` must be provided and `addOrEdit` set approriately.',
    );
  }

  return (
    <>
      <ModelModal {...{ addOrEdit }} modelName="action point" {...{ modalWithTriggerProps: newModalWithTriggerProps }}>
        <Form actionPoint={formActionPoint} review={review} />
      </ModelModal>
    </>
  );
}
