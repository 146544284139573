import * as React from 'react';

export default function columns() {
  return [
    {
      Header: 'Name',
      accessor: 'name',
      columnName: 'Name',
      id: 'name',
    },
    {
      Cell: (row) => {
        return (
          <a href={row.original.showPath} style={{ display: 'block' }}>
            <div className="rt-tr-clickable-icon">
              <i className="icon-arrow-right-top icon-0-8x" />
            </div>
          </a>
        );
      },
      Header: '',
      sortable: false,
    },
  ];
}
