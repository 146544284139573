import * as React from 'react';

import startCase from 'lodash.startcase';

export default function columns(headers) {
  const allColumns = [
    {
      Header: '',
      accessor: 'name',
      columnName: 'Name',
      id: 'name',
      sortable: false,
    },
  ] as any;

  const headerOrder = [
    'three_month',
    'six_month',
    'year_to_date',
    'twelve_months',
    'previous_year',
    'two_years_ago',
    'three_years_ago',
    'four_years_ago',
    'five_years_ago',
    'annualised_3_yr',
    'annualised_5_yr',
    'annualised',
    'overall_3_yr',
    'overall_5_yr',
    'overall',
  ];

  const headerMap = {
    annualised: { name: 'Ann. (Inception)', width: 105 },
    annualised_3_yr: { name: 'Ann. (3 Years)', width: 80 },
    annualised_5_yr: { name: 'Ann (5 Years)', width: 80 },
    five_years_ago: { name: '5 Years Ago', width: 85 },
    four_years_ago: { name: '4 Years Ago', width: 85 },
    overall: { name: 'Overall (Inception)', width: 100 },
    overall_3_yr: { name: 'Overall (3 Years)', width: 105 },
    overall_5_yr: { name: 'Overall (5 Years)', width: 105 },
    previous_year: { name: 'Last Yr', width: 80 },
    six_month: { name: '6 Months', width: 80 },
    three_month: { name: '3 Months', width: 80 },
    three_years_ago: { name: '3 Yrs Ago', width: 85 },
    twelve_months: { name: '12 Months', width: 80 },
    two_years_ago: { name: '2 Years Ago', width: 85 },
    year_to_date: { name: 'Year to Date', width: 70 },
  };

  const sortedHeaders = headers.sort((...compare: string[]) => {
    const [fst, snd] = compare.map((arg) => headerOrder.indexOf(arg));
    return fst - snd;
  });

  sortedHeaders.forEach((header) => {
    allColumns.push({
      Cell: (row) => {
        if (row.original[header]) {
          return <div>{row.original[header]}%</div>;
        }
        return <div />;
      },
      Header: headerMap[header] ? `${headerMap[header].name}` : '',
      accessor: header,
      columnName: `${startCase(header)}`,
      id: header,
      sortable: false,
    });
  });

  return allColumns;
}
