import * as React from 'react';

export default function genericPortfolioTable({ templateSection, large, showHeaders }) {
  const defaultSeries = ['Benchmark 1', 'Benchmark 2', 'Benchmark 3,'];

  const series =
    templateSection && templateSection.tableSeries && templateSection.tableSeries.length
      ? templateSection.tableSeries
      : defaultSeries;

  const tableHeaders = series.map((item) => {
    return (
      <th key={item} style={{ width: `${66 / series.length}%`, verticalAlign: 'top' }} className="text-right">
        {item}
      </th>
    );
  });

  const headers = (
    <thead>
      <tr>
        <th style={{ width: '34%', verticalAlign: 'top' }} className="text-right" />
        {tableHeaders}
      </tr>
    </thead>
  );

  const tableData = series.map((item, index) => {
    return (
      <td key={index} className="text-right">
        Value
      </td>
    );
  });

  return (
    <table className={`pdf-table pdf-table--striped ${large && 'pdf-table--large'}`}>
      {showHeaders && headers}
      <tbody>
        <tr>
          <td className="pdf-table__row-label">Portfolio (vs.)</td>
          {tableData}
        </tr>
      </tbody>
    </table>
  );
}
