import * as React from 'react';

import ClientPortalCard from '../../../shared/ClientPortalCard';

import { format, getMonth } from 'date-fns';

import { portalClientPortfolioReturnSeriesIndexPath } from 'javascript/application/ts_routes';
import useRadioSelect from '../../../../shared/customHooks/useRadioSelect';
import useAjaxRequest from '../../../../shared/customHooks/useAjaxRequest';
import LineChartWithModal from '../../../../shared/charting/composed/LineChartWithModal';
import { TimePeriod, IRequestParams } from './shared/types';
import {useClientPortalTheme} from 'components/shared/colours';

export interface IClientPerformanceChartProps {
  portfolioObject: {
    client_id: number;
    performancePeriodMonths: number;
    id: number;
    timePeriods: TimePeriod[];
  };
  currentReviewPeriod: IRequestParams['review_period'];
}

export default function clientPerformanceChart({ currentReviewPeriod, portfolioObject }: IClientPerformanceChartProps) {
  const { client_id, id, timePeriods } = portfolioObject;

  const [chartData, setChartData] = React.useState();

  const strokeDasharray = '3 3';

  const colourGen = useClientPortalTheme('benchmark');

  const lineStyles = {
    portfolio: { stroke: colourGen('portfolio') },
    'Enhance benchmark': { stroke: colourGen('enhance') },
    'Cash benchmark': { stroke: colourGen('cash') },
    'Peer group': { stroke: colourGen('peerGroup'), strokeDasharray },
    'Managers benchmark': { stroke: colourGen('managers'), strokeDasharray },
    'Target return': { stroke: colourGen('targetReturn') },
  };
  const [radioToggle, selectedRadioRef] = useRadioSelect(timePeriods, 1);

  const [getChartData, isError, isLoading] = useAjaxRequest<IRequestParams, any>({
    method: 'GET',
    path: portalClientPortfolioReturnSeriesIndexPath(client_id, id),
    stateSetter: setChartData,
  });

  React.useEffect(() => {
    getChartData({
      review_period: currentReviewPeriod,
      time_period: timePeriods[selectedRadioRef.current].value,
    });
  }, [currentReviewPeriod, selectedRadioRef.current]);

  const cardProps = {
    title: 'Performance',
    subTitle: format(currentReviewPeriod, "DD MMM 'YY"),
    titleControls: radioToggle,
  };

  const chartContentProps = {
    isLoading,
    chartData: chartData && chartData.data,
    radioToggle,
    lineStyles,
  };

  const cardContent = isError ? (
    <p className="text-white helper-text mar-t-3">No data to display</p>
  ) : (
    <LineChartWithModal {...chartContentProps} />
  );

  return (
    <div className="col-md-1-2 hide-md-block">
      <div>
        <ClientPortalCard {...cardProps}>{cardContent}</ClientPortalCard>
      </div>
    </div>
  );
}
