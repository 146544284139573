import * as React from 'react';
import turbolinks from 'turbolinks';

import { DirectUpload } from 'activestorage';

import Presenter from './contacts/Presenter';

import { BulkImport } from 'javascript/models';
import { Client } from 'javascript/models';
import { createAlert } from 'components/shared/Utils';
import { platformDashboardPath } from 'javascript/application/ts_routes';

export default function bulkContactUpdate({ clientOptions }) {
  const [bulkImport, setBulkImport] = React.useState();
  const [client, setClient] = React.useState();
  const [users, setUsers] = React.useState([]);
  const [importParams, setImportParams] = React.useState([]);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [displayErrors, setDisplayErrors] = React.useState(false);

  function handleClientSelect(client) {
    setClient(client.value);
  }

  React.useEffect(() => {
    if (client) {
      getClient(client);
    }
  }, [client]);

  async function getClient(clientId) {
    const { data } = await Client.select(['id'])
      .includes(['users'])
      .order({ 'users.first_name': 'asc' })
      .find(clientId);

    setUsers(data.users);
  }

  function handleFileUpload(files) {
    if (files.length < 1) return;

    setIsProcessing(true);
    const upload = new DirectUpload(files[0], '/rails/active_storage/direct_uploads');

    upload.create((error, blob) => {
      if (error) {
        // Dump out
      } else {
        const signedId = blob.signed_id;
        createBulkImport(signedId);
      }
    });
  }

  async function createBulkImport(signedId) {
    setIsProcessing(true);
    setDisplayErrors(false);
    const newBulkImport = new BulkImport({ import: signedId, importType: 'contacts', client_id: client });
    const success = await newBulkImport.save();

    if (Object.keys(newBulkImport.preview).length === 0 && newBulkImport.preview.constructor === Object) {
      setDisplayErrors(true);
    } else {
      const newBulkParams = newBulkImport.preview.data.map((userObject) => {
        return { uniqueId: userObject.uniqueId, user: userObject.new ? 'New' : userObject.user.id };
      });
      setImportParams(newBulkParams);
      setBulkImport(newBulkImport);
    }
    setIsProcessing(false);
  }

  function handleParamsChange(uniqueId, user) {
    const updatedParams = importParams.map((param) => {
      if (param.uniqueId === uniqueId) {
        param.user = user;
      }
      return param;
    });
    setImportParams(updatedParams);
  }

  async function handleSubmit() {
    setIsSubmitting(true);
    bulkImport.status = 'submitted';
    bulkImport.importParams = importParams;
    const success = await bulkImport.save();

    if (success) {
      setIsSubmitting(false);
      turbolinks.visit(platformDashboardPath());
    } else {
      setIsSubmitting(false);
      createAlert('error', 'Something went wrong uploading the file, please try again', 2000);
    }
  }

  return (
    <Presenter
      {...{
        bulkImport,
        client,
        clientOptions,
        displayErrors,
        handleClientSelect,
        handleFileUpload,
        handleParamsChange,
        handleSubmit,
        isProcessing,
        isSubmitting,
        users,
      }}
    />
  );
}
