import * as React from 'react';

import CustomModal from 'components/shared/CustomModal';
import FormTag from 'components/shared/forms/FormTag';
import IReportTemplate from 'components/interfaces/IReportTemplate';

const routes = require('../../../../../../../../javascript/application/routes.js');

interface IProps {
  companyId: number;
  isOpen: boolean;
  reviewId: number;
  reviewIds: string[];
  reportTemplates: IReportTemplate[];
  handleClose(): void;
  handleSubmit(event: React.SyntheticEvent): void;
}

export default function assignTemplates(props: IProps) {
  const { handleClose, reviewId, reportTemplates, isOpen } = props;

  const templateOptions = reportTemplates.map((template) => {
    return (
      <div className="col-sm-1-2" key={template.id}>
        <div className="pretty p-icon">
          <input
            defaultChecked={props.reviewIds.includes(template.id as string)}
            type="checkbox"
            name="review[report_template_ids][]"
            value={template.id}
          />
          <div className="state p-blue">
            <i className="icon-tick icon icon-push-down-small" />
            <label htmlFor="" className="text-white">
              {template.attributes.name}
            </label>
          </div>
        </div>
      </div>
    );
  });

  return (
    <CustomModal handleClose={handleClose} isOpen={isOpen} modifiers={['dark']} title="Report Templates">
      <FormTag
        url={routes.platform_review_report_templates_path(reviewId)}
        method="patch"
        onSubmit={props.handleSubmit}
      >
        <div className="platform-content--border-bottom">
          <div className="modal__content">
            <div className="frow frow--gutters">
              <input type="hidden" name="review[report_template_ids][]" value="" />
              {templateOptions}
            </div>
          </div>
        </div>
        <div className="modal__content pad-t-2">
          <div className="frow frow--gutters">
            <div className="col-sm-1-2">
              <div className="pretty pretty--large pretty--with-label p-round">
                <input type="radio" name="review[report_template][assign_to]" value="review" defaultChecked />
                <div className="state p-blue-circle">
                  <label className="text-white">This review only</label>
                </div>
              </div>
            </div>
            <div className="col-sm-1-2">
              <div className="pretty pretty--large pretty--with-label p-round">
                <input type="radio" name="review[report_template][assign_to]" value="portfolio" />
                <div className="state p-blue-circle">
                  <label className="text-white">All reviews</label>
                </div>
              </div>
            </div>
          </div>
          <button className="button button--compact button--full-width mar-t-2">Update</button>
        </div>
      </FormTag>
    </CustomModal>
  );
}
