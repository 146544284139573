import * as React from 'react';

import Large from './rating/Large';
import Small from './rating/Small';

export default function rating({ templateSection }) {
  let size = 'large';

  if (templateSection && templateSection.size === 'small') {
    size = 'small';
  }

  if (size === 'large') {
    return <Large />;
  }
  return <Small />;
}
