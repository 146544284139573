import * as React from 'react';
import * as $ from 'jquery';

import Context from '../Context';

export default function content({ sectionRef, templateSection }) {
  const context = React.useContext(Context);
  const { reportTemplate } = context.state;
  const defaultListItems = [
    { name: 'Introduction', pageNumber: 'x' },
    { name: 'Investment Portfolio Review Summary', pageNumber: 'x' },
    { name: 'Currency and Strategy Overview', pageNumber: 'x' },
  ];
  const [listItems, setListItems] = React.useState(defaultListItems);

  React.useEffect(() => {
    if (reportTemplate) {
      if (reportTemplate.reportTemplateContents.length > 0) {
        setListItems(reportTemplate.reportTemplateContents.map((content) => ({ name: content.name, pageNumber: 'x' })));
      } else {
        setListItems(defaultListItems);
      }
    }
  }, [reportTemplate]);

  React.useEffect(() => {
    if (sectionRef) {
      const height = $(sectionRef.current).outerHeight();
      const percentageHeight = (height / 842) * 100;
      updateSection(percentageHeight);
    }
  }, [listItems]);

  async function updateSection(percentageHeight) {
    templateSection.height = percentageHeight;
    await templateSection.save();
  }

  let color = '#3990a8';
  if (reportTemplate && reportTemplate.reportTemplateTheme) {
    color = reportTemplate.reportTemplateTheme.primaryColor;
  }

  const formattedListItems = listItems.map((item, index) => {
    return (
      <li key={`${item.name}_${index}`} className="pdf-list__item">
        <span className="pdf-list__number" style={{ color }}>
          {index + 1}
        </span>{' '}
        {item.name}
        <span className="pdf-list__page-number">{item.pageNumber}</span>
      </li>
    );
  });

  return <ul className="pdf-list pdf-list--content">{formattedListItems}</ul>;
}
