import * as React from 'react';

import { Cell, Label, Legend, Pie, PieChart } from 'recharts';
const numeral = require('numeral');
import * as colorFunctions from 'color';

export const CustomLegend = (props) => {
  const { payload } = props;
  const { rowWidth } = props;

  const legends = payload.map((payloadItem, index) => {
    const lineStyles = {
      background: payloadItem.payload.fill,
      border: `2px solid ${payloadItem.payload.stroke}`,
      borderRadius: '50%',
      display: 'inline-block',
      height: '10px',
      width: '10px',
    };

    return (
      <div key={index} className="col-mc-1-1" style={{ marginBottom: '8px', fontSize: '0.8rem' }}>
        <div
          className={`platform-content ${index + 1 !== payload.length && 'platform-content--border-bottom-light-grey'}`}
          style={{ paddingBottom: index + 1 !== payload.length ? '5px' : '0px' }}
        >
          <table className="pdf-hidden-table">
            <tbody>
              <tr>
                <td style={{ fontSize: '12px', width: '15%' }}>
                  <span style={lineStyles} />
                </td>
                <td style={{ width: '50%', fontSize: '12px' }}>{props.data[index].name}</td>
                <td style={{ fontSize: '12px', fontWeight: 500 }} className="text-right">
                  {`${numeral(props.data[index].value).format('0,0.00')}%`}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  });

  return (
    <div
      style={{ border: '1px solid #e4e4e8', padding: '15px 5px 10px', width: rowWidth === 'full' ? '360px' : '180px' }}
    >
      <div className="frow frow--direction-column">{legends}</div>
    </div>
  );
};

const EmptyLegend = () => {
  return (
    <div style={{ padding: '15px 5px 10px', width: '180px' }}>
      <div className="frow frow--direction-column" />
    </div>
  );
};

const EmptyChart = ({ height, width }) => {
  const data = [{ name: 'empty', value: 1, color: '#777881' }];

  return (
    <div style={{ opacity: 0.5 }}>
      <PieChart height={height} width={width}>
        <Pie
          data={data}
          nameKey="name"
          dataKey="value"
          innerRadius="55%"
          startAngle={90}
          endAngle={-270}
          isAnimationActive={false}
        >
          {data.map((entry, index) => (
            <Cell key={index} fill={entry.color} stroke="none" />
          ))}
          <Label position="center" fill="#000" fontSize={14} fontWeight="bold">
            No Data
          </Label>
        </Pie>
        <Legend layout="vertical" verticalAlign="middle" align="right" content={<EmptyLegend />} />
      </PieChart>
    </div>
  );
};

import { chartColorsV2 } from './chartColors';

export default function pieChart({
  baseColor,
  benchmarkTypes,
  chartData,
  label,
  theme,
  width,
  rowWidth,
  height = 360,
}) {
  const chartColors = chartColorsV2();
  const data = chartData.map((value) => ({ name: value.name, value: parseFloat(value.value) }));

  if (data.length < 1) {
    return <EmptyChart {...{ height, width }} />;
  }

  const cells = data.map((entry, index) => {
    let color = chartColors(entry.name, theme, benchmarkTypes, index);
    if (baseColor) {
      color = colorFunctions(baseColor)
        .rotate(40 * index)
        .string();
    }
    return <Cell key={index} fill={color} stroke="none" />;
  });

  return (
    <PieChart height={height} width={width}>
      <Pie
        data={data}
        nameKey="name"
        dataKey="value"
        innerRadius="55%"
        startAngle={90}
        endAngle={-270}
        isAnimationActive={false}
      >
        {cells}
        <Label position="center" fill="#000" fontSize={14} fontWeight="bold">
          {label}
        </Label>
      </Pie>
      <Legend
        layout="vertical"
        verticalAlign="middle"
        align="right"
        content={<CustomLegend data={data} rowWidth={rowWidth} />}
      />
    </PieChart>
  );
}
