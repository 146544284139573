import * as React from 'react';

import { getQuarter, getYear, parse, subMonths } from 'date-fns';

import Emails from './Emails';
import Loading from 'components/shared/Loading';
import Template from './Template';

export default function presenter({ dataChaseTemplates, getTemplates, isLoading }) {
  const templates = dataChaseTemplates.map((dataTemplate) => {
    return <Template key={dataTemplate.id} dataTemplate={dataTemplate} />;
  });

  function renderTemplates() {
    return <div className="frow frow--gutters">{templates}</div>;
  }

  const quarter = `Q${getQuarter(parse(subMonths(new Date(), 3)))} ${getYear(parse(subMonths(new Date(), 3)))}`;

  return (
    <React.Fragment>
      <div className="platform-panel">
        <header className="platform-panel__header">
          <h3 className="platform-panel__title">Data Chase Emails for {quarter}</h3>
        </header>
        <div className="platform-content platform-content--padding-all">
          {isLoading ? <Loading /> : <Emails {...{ dataChaseTemplates, getTemplates }} />}
        </div>
      </div>
      <div className="platform-panel">
        <header className="platform-panel__header">
          <h3 className="platform-panel__title">Data Chase Templates</h3>
        </header>
        <div className="platform-content platform-content--padding-all">
          {isLoading ? <Loading /> : renderTemplates()}
        </div>
      </div>
    </React.Fragment>
  );
}
