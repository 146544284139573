import * as React from 'react';

import { getQuarter, format, parse, subMonths, getYear } from 'date-fns';
import lodashStartcase from 'lodash.startcase';
import * as ReactTooltip from 'react-tooltip';

export default function columns() {
  return [
    {
      Cell: (row) => {
        return <div>{lodashStartcase(row.original.extractType)}</div>;
      },
      Header: 'Extract Type',
      accessor: 'extractType',
      columnName: 'Extract Type',
      id: 'extract_type',
    },
    {
      Cell: (row) => {
        const review_date = row.original.options.review_date;
        return <div>{review_date ? `Q${getQuarter(parse(review_date))} ${getYear(parse(review_date))}` : ''}</div>;
      },
      Header: 'Quarter',
      accessor: 'quarter',
      columnName: 'Quarter',
      id: 'quarter',
    },
    {
      Cell: (row) => {
        return <div>{row.original.clientName}</div>;
      },
      Header: 'Client Name',
      accessor: 'clientName',
      columnName: 'Client Name',
      id: 'client',
    },
    {
      Cell: (row) => {
        const generated = row.original.generated;

        if (generated) {
          return (
            <div>
              <i className="icon-tick text-brand-sage" data-tip="Generated" />
            </div>
          );
        }
        return (
          <div>
            <i className="fas fa-circle-notch fa-spin text-brand-windows-blue" data-tip="Generating" />
            <ReactTooltip effect="solid" />
          </div>
        );
      },
      Header: 'Status',
      accessor: 'generated',
      columnName: 'Status',
      id: 'generated',
    },
    {
      Header: 'Created By',
      accessor: 'administrator.fullName',
      columnName: 'Created By',
      id: 'administrator.fullName',
      sortable: false,
    },
    {
      Cell: (row) => {
        return <div>{format(parse(row.original.createdAt), 'DD MMM YYYY [at] HH:mm')}</div>;
      },
      Header: 'Created At',
      accessor: 'createdAt',
      columnName: 'Created At',
      id: 'created_at',
    },
    {
      Cell: (row) => {
        return (
          <a href={row.original.downloadPath} style={{ display: 'block' }}>
            <div className="rt-tr-clickable-icon">
              <i className="icon-arrow-right-top icon-0-8x" />
            </div>
          </a>
        );
      },
      Header: '',
      sortable: false,
    },
  ];
}
