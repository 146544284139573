import * as React from 'react';

import FormRow from 'components/shared/forms/FormRow';
import ReactSelect from 'components/shared/forms/ReactSelect';

import { ReportTemplate } from 'javascript/models';

export default function reportTemplates({ reportTemplateIds, reportTemplateType, name, note }) {
  const [reportTemplates, setReportTemplates] = React.useState();

  React.useEffect(() => {
    getReportTemplates();
  }, []);

  async function getReportTemplates() {
    let scope;
    if (reportTemplateType === 'Consolidated') {
      scope = ReportTemplate.select(['name', 'id']).where({ consolidated: true }).per(999);
    } else {
      scope = ReportTemplate.select(['name', 'id']).where({ consolidated: false }).per(999);
    }
    const { data } = await scope.all();

    const templateOptions = data.map((template) => ({ value: template.id, label: template.name }));
    setReportTemplates(templateOptions);
  }

  let defaultReportTemplates;
  if (reportTemplates) {
    defaultReportTemplates = reportTemplates.filter((template) => reportTemplateIds.includes(template.value));
  }

  const reportTemplateSelect = (
    <ReactSelect
      theme="dark"
      name={name}
      id="report_template_ids"
      isClearable
      isMulti
      options={reportTemplates}
      defaultValues={defaultReportTemplates}
    />
  );

  return (
    <FormRow note={note}>
      <label htmlFor="" className="form__label">
        {reportTemplateType} Report Templates
      </label>
      {reportTemplates && reportTemplateSelect}
    </FormRow>
  );
}
