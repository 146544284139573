import * as React from 'react';

import { format, parse } from 'date-fns';

import Context from '../../../Context';
import AssignOwner from '../../../../../reviews/AssignOwner';
import ReviewContext from './ReviewContext';
import Warnings from './Warnings';

import StatusButton from './info/StatusButton';

const info = () => {
  const context = React.useContext(Context);
  const reviewContext = React.useContext(ReviewContext);

  const { review } = reviewContext.props;
  const { handleReviewUpdate } = reviewContext.actions;
  const { administrators, companyId, portfolio } = context.props;
  const { id } = review;
  const { status, sentAt } = review.attributes;
  const { warnings } = review.attributes.metrics;

  const handleReviewsAssigned = () => {
    handleReviewUpdate();
  };

  const previousOwner = () => {
    if (review.attributes.previousOwner) {
      return <span>{review.attributes.previousOwner}</span>;
    }
    return <span>N/A</span>;
  };

  const owner = () => {
    if (review.attributes.owner) {
      return (
        <span>
          {review.attributes.owner}
          <AssignOwner
            administrators={administrators}
            companyId={companyId}
            iconButton
            id={id}
            handleReviewsAssigned={handleReviewsAssigned}
          />
        </span>
      );
    }
    return (
      <AssignOwner
        administrators={administrators}
        companyId={companyId}
        id={id}
        handleReviewsAssigned={handleReviewsAssigned}
      />
    );
  };

  const renderSentAt = () => {
    if (!sentAt) return;

    return (
      <div className="alert alert--success mar-t-2">
        <p className="alert__message">
          Reports were sent on the {format(parse(sentAt), 'DD MMM YYYY [at] HH:mm')}. They will not be automatically
          sent again. If you make changes to the report it will need to be sent manually.
        </p>
      </div>
    );
  };

  const showButton = status !== 'on_hold' && status !== 'terminated';
  const irlink = `/platform/investment_restrictions/${review.id}?portfolio_reference=${portfolio.attributes.reference}`;
  return (
    <div className="platform-content platform-content--padding-vertical platform-content--border-bottom">
      <div className="frow frow--items-center">
        <div className="col-md-2-12">
          <div className="metric metric--right-bordered">
            <p className="metric__value mar-v-0">{review.attributes.timePeriod}</p>
          </div>
        </div>
        <div className="col-md-2-12">
          <div className="metric metric--right-bordered mar-l-3">
            <p className="metric__title">
              Last Owner {review.attributes.previousStatus && `(${review.attributes.previousStatus})`}
            </p>
            <p className="metric__value metric__value--small">{previousOwner()}</p>
          </div>
        </div>
        <div className="col-md-2-12">
          <div className="metric metric--right-bordered mar-l-3">
            <p className="metric__title">Owner</p>
            <p className="metric__value metric__value--small">{owner()}</p>
          </div>
        </div>
        <div className="col-md-2-12">
          <div className="metric mar-l-3">
            <p className="metric__title">Checked by</p>
            <p className="metric__value metric__value--small">
              {review.attributes.checker ? review.attributes.checker : '-'}
            </p>
          </div>
        </div>
        <div className="col-md-4-12">
          <div className="frow frow--items-center frow--row-end">
            <a href={irlink} target="_blank" rel="noopener noreferrer" className="button button--compact mar-r-1">
              Checklist
            </a>
            {showButton && <StatusButton buttonText="Put on Hold" transition="to_on_hold" />}
            {showButton && <StatusButton buttonText="Terminate Review" transition="to_terminated" />}
          </div>
        </div>
      </div>
      {renderSentAt()}
      {warnings.length > 0 && <Warnings />}
    </div>
  );
};

export default info;
