import * as React from 'react';

const classNames = require('classnames');

import useVisibility from 'components/shared/customHooks/useVisibility';

function Filter({ filterName, filterType, handleFilterChange, minWidth, options }) {
  const { isOpen, handleClose, toggleOpen } = useVisibility(false);
  const [selectedOption, setSelectedOption] = React.useState(`All ${filterName}`);

  function handleSelect() {
    setSelectedOption(this.label);
    handleFilterChange(filterType, this.value);
    handleClose();
  }

  const formattedOptions = options.map((option) => {
    return (
      <div key={option.value} className="dropdown__nav-link" onClick={handleSelect.bind(option)}>
        <div className="frow frow--items-center">{option.label}</div>
      </div>
    );
  });

  const dropdownClasses = classNames('dropdown dropdown--sw', { 'dropdown--active': isOpen });

  return (
    <div className={dropdownClasses}>
      <a
        className="dropdown__toggle dropdown__toggle--input"
        onClick={toggleOpen}
        style={{ minWidth: `${minWidth}px` }}
      >
        <div className="frow frow--items-center">
          <span style={{ marginRight: '25px' }} className="text-normal">
            {selectedOption}
          </span>
          <i className={`button__icon fa fa-caret-down fa-animated ${isOpen ? 'fa-flipped' : ''}`} />
        </div>
      </a>
      <div className="dropdown__content">
        <div className="dropdown__content-inner">{formattedOptions}</div>
      </div>
    </div>
  );
}

export default function filters({ handleFilterChange }) {
  const statusOptions = [
    { label: 'All Statuses', value: 'all', icon: '' },
    { label: 'Draft', value: 'draft', icon: '' },
    { label: 'Live', value: 'live', icon: '' },
  ];

  const themeOptions = [
    { label: 'All Themes', value: 'all', icon: '' },
    { label: 'Monitoring', value: 'monitoring', icon: '' },
    { label: 'Consultancy', value: 'consultancy', icon: '' },
    { label: 'Portfolio', value: 'portfolio', icon: '' },
    { label: 'Group', value: 'group', icon: '' },
    { label: 'Custom', value: 'custom', icon: '' },
  ];

  const orientationOptions = [
    { label: 'All Orientations', value: 'all', icon: '' },
    { label: 'Portrait', value: 'portrait', icon: '' },
    { label: 'Landscape', value: 'landscape', icon: '' },
  ];

  return (
    <div className="frow frow--gutters-2x frow--items-center">
      <div>
        <p className="subtitle-two">Status</p>
        <Filter
          filterName="Statuses"
          filterType="status"
          minWidth={140}
          options={statusOptions}
          {...{ handleFilterChange }}
        />
      </div>
      <div>
        <p className="subtitle-two">Theme</p>
        <Filter
          filterName="Themes"
          filterType="theme"
          minWidth={120}
          options={themeOptions}
          {...{ handleFilterChange }}
        />
      </div>
      <div>
        <p className="subtitle-two">Orientation</p>
        <Filter
          minWidth={170}
          filterType="orientation"
          filterName="Orientations"
          options={orientationOptions}
          {...{ handleFilterChange }}
        />
      </div>
    </div>
  );
}
