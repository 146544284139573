import * as React from 'react';
import * as $ from 'jquery';
import Context from '../Context';

import { platformPortfolioReviewPath } from 'javascript/application/ts_routes';

import Notes from './reviews/Notes';
import ReviewSelector from './reviews/ReviewSelector';
import Show from './reviews/Show';
import { Review } from 'javascript/models';
import { ajaxErrorString } from 'components/shared/Utils';

interface IProps {
  selectedReviewId: number;
  setSelectedReviewId: any;
}

export default function reviews(props: IProps) {
  const { selectedReviewId, setSelectedReviewId } = props;
  const context = React.useContext(Context);
  const { id } = context.props.portfolio.attributes;

  const [errorMessage, setErrorMessage] = React.useState<string>();
  const [isDisplayingReview, setIsDisplayingReview] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [review, setReview] = React.useState(null);
  const [totalReviews, setTotalReviews] = React.useState(0);
  const [reviews, setReviews] = React.useState([]);
  const [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    if (selectedReviewId) {
      getReview();
    }
  }, [selectedReviewId]);

  React.useEffect(() => {
    getReviews();
  }, [isOpen]);

  function getReview() {
    $.ajax({
      beforeSend: () => {
        setIsLoading(true);
      },
      cache: false,
      complete: () => setIsDisplayingReview(true),
      dataType: 'json',
      error: (error) => setErrorMessage(ajaxErrorString(error)),
      success: (response: any) => {
        // Constantly have to add this in, so leaving it here for now
        // console.log(response.data);
        setReview(response.data);
      },
      type: 'GET',
      url: platformPortfolioReviewPath(id, selectedReviewId),
    });
  }

  React.useEffect(() => {
    if (review) {
      setIsLoading(false);
      setErrorMessage(null);
    }
  }, [review]);

  const extraAttributes = [
    'display_status',
    'rating_value',
    'rating_status',
    'time_period',
    'period_months',
    'real_start_date',
  ];

  const getReviews = React.useCallback(async () => {
    const { data, meta } = await Review.where({ reviewable_type: 'Portfolio', reviewable_id: id })
      .order({ end_date: 'desc' })
      .selectExtra(extraAttributes)
      .stats({ total: 'count' })
      .per(isOpen ? 1000 : 5)
      .all();

    setIsLoading(false);
    setTotalReviews(meta.stats.total.count);
    setReviews(data);
  }, [id, extraAttributes, isOpen, setReviews, setTotalReviews]);

  const handleReviewUpdate = React.useCallback(() => {
    getReview();
    getReviews();
  }, [getReview, getReviews]);

  function handleReviewSelect(reviewId: string) {
    setSelectedReviewId(reviewId);
  }

  function handleBackClick() {
    setIsDisplayingReview(false);
    setSelectedReviewId(null);
  }

  const reviewSelector = (
    <div>
      <ReviewSelector {...{ handleReviewSelect, getReviews, isLoading, isOpen, totalReviews, reviews, setIsOpen }} />
      <Notes />
    </div>
  );

  const reviewShow = (
    <Show {...{ errorMessage, handleBackClick, handleReviewSelect, handleReviewUpdate, isLoading, review, reviews }} />
  );

  return (
    <React.Fragment>
      {!isDisplayingReview && reviewSelector}
      {isDisplayingReview && reviewShow}
    </React.Fragment>
  );
}
