import * as React from 'react';

import Tile from './clientTiles/Tile';
import New from './clientTiles/New';

import { ClientTile } from 'javascript/models';

export default function clientTiles({ companyId, resourceTypes }) {
  const [clientTiles, setClientTiles] = React.useState([]);

  function handleSuccessfulFormSubmission() {
    getClientResources();
  }

  React.useEffect(() => {
    getClientResources();
  }, []);

  async function confirmDelete(clientResource) {
    await clientResource.destroy();
    getClientResources();
  }

  async function getClientResources() {
    const { data } = await ClientTile.per(1000).where({ company_id: companyId, default_and_individual: false }).all();

    setClientTiles(data);
  }

  const allResources = clientTiles.map((clientTile) => {
    return (
      <Tile {...{ clientTile, confirmDelete, handleSuccessfulFormSubmission, resourceTypes }} key={clientTile.id} />
    );
  });

  return (
    <div className="frow frow--gutters">
      {allResources}
      <New {...{ resourceTypes }} handleSuccessfulFormSubmission={handleSuccessfulFormSubmission} />
    </div>
  );
}
