import * as React from 'react';

import { Cell, Label, PieChart, Pie, ResponsiveContainer, Tooltip } from 'recharts';

interface IProps {
  animationBegin: number;
  animationDuration: number;
  height: number;
  text?: string;
}

export default function emptyChart(props: IProps) {
  const data = [{ name: 'empty', value: 1, color: '#777881' }];

  return (
    <div style={{ opacity: 0.5 }}>
      <ResponsiveContainer width="100%" height={props.height}>
        <PieChart>
          <Pie
            animationDuration={props.animationDuration}
            animationBegin={props.animationBegin}
            data={data}
            nameKey="name"
            dataKey="value"
            innerRadius="55%"
            startAngle={90}
            endAngle={-270}
          >
            {data.map((entry, index) => (
              <Cell key={index} fill={entry.color} stroke="none" />
            ))}
            <Label position="center" fill="#ffffff">
              {props.text}
            </Label>
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}

emptyChart.defaultProps = {
  text: 'No Data',
};
