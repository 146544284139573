import * as React from 'react';

import { modify } from 'ramda';
import { startCase, groupBy, mapKeys, sortBy } from 'lodash';

import { endOfQuarter, addQuarters } from 'date-fns';

import { Administrator, Cluster, ClusterMapping, Security, SecurityState } from 'javascript/models';

import { useSpraypaintQueryAll } from 'components/shared/customHooks/useSpraypaintQuery';

import Loading from 'components/shared/Loading';

import ISelectOption, { ISelectProps } from 'components/interfaces/ISelectOption';
import { IGroupedClassificationStates } from 'components/interfaces/SecurityInterfaces';
import { ICriterionOptions } from 'components/interfaces/ICriterionOptions';

import SecurityInformation, { ISecurityInformationProps } from './form/SecurityInformation';
import SecurityClassificationsForm from './form/SecurityClassificationsForm';

import Context from './form/Context';

interface IProps {
  criterionOptions: ICriterionOptions;
  securityId: string;
  authorId: string;
  clusterOptions: ISelectOption[];
}

export interface ISecurityAttributes {
  name: string;
  shortName: string;
  isin: string;
}

const Form = (props: IProps) => {
  const { criterionOptions, authorId, securityId } = props;

  const [groupedClassificationStates, setGroupedClassificationStates] = React.useState<IGroupedClassificationStates>(
    {},
  );

  const [isLoading, setIsLoading] = React.useState(true);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [selectedClusterOption, setSelectedClusterOption] = React.useState<ISelectOption>();
  const [overwriteClassifications, setOverwriteClassifications] = React.useState(false);
  const [currentUser, setCurrentUser] = React.useState<Administrator>();
  const [securityType, setSecurityType] = React.useState('');
  const [security, setSecurity] = React.useState<Security>();

  const createClusterOptions = (clusterMappings: ClusterMapping[]) =>
    clusterMappings.map((clusterMapping) => ({
      label: startCase(clusterMapping.cluster),
      value: clusterMapping.cluster,
    }));

  const clusterOptionsScope = React.useMemo(() => ClusterMapping.noLimit(), []);
  const cluserMappingTransformer = React.useCallback(modify('data', createClusterOptions), []);
  const { data: clusterOptions }: any = useSpraypaintQueryAll(
    clusterOptionsScope,
    { data: [] },
    cluserMappingTransformer,
  );

  const stateIncludes = SecurityState.defaultReturnScopeIncludes;

  const securityScope = React.useMemo(
    () =>
      Security.includes(['cluster', { states: stateIncludes }, { current_state: stateIncludes }]).where({
        id: securityId,
      }),
    [securityId],
  );

  React.useEffect(() => {
    (async () => {
      if (securityId) {
        const { data } = await securityScope.first();
        setSecurity(data);
      } else {
        setSecurity(new Security());
      }
    })();
  }, [securityId]);

  React.useEffect(() => (processSecurity(security), undefined), [security]);

  const getAuthor = async () => {
    const { data } = await Administrator.find(authorId);

    setCurrentUser(data);
  };

  const createDraftClassificationState = (referenceDate: Date, baseState?: SecurityState) => {
    const draftClassificationState = new SecurityState({
      author: currentUser,
      classifiedBy: 'enhance',
      referenceDate: referenceDate.toDateString(),
      security,
      status: 'draft',
    });

    draftClassificationState.rootAlias = draftClassificationState;

    if (baseState) {
      draftClassificationState.rootAlias = baseState.rootAlias;
      draftClassificationState.aliasId = baseState.rootAlias.id;
    }

    handleGroupedClassificationsChange(draftClassificationState);

    security.currentState = draftClassificationState;
    security.states.push(draftClassificationState);

    return draftClassificationState;
  };

  const processSecurity = (localSecurity: Security) => {
    if (!localSecurity) return;

    if (!localSecurity.securityType) localSecurity.securityType = 'manual';
    setSecurityType(localSecurity.securityType);

    if (localSecurity.isPersisted && !localSecurity.currentState)
      createDraftClassificationState(endOfQuarter(addQuarters(new Date(), -1)));

    setOverwriteClassifications(localSecurity.overwriteClassifications || true);

    processSecurityStates(localSecurity.states);
    processClusterState(localSecurity.cluster);

    setIsLoading(false);
  };

  const processSecurityStates = (securityClassificationStates: SecurityState[]) => {
    const orderedClassificationStates = sortBy(securityClassificationStates, (classificationState: SecurityState) => {
      const sortMap = {
        draft: 1,
        live: 0,
        superseded: 2,
      };
      return sortMap[classificationState.status];
    });

    const localGroupedClassificationStates: IGroupedClassificationStates = groupBy(
      orderedClassificationStates,
      'referenceDate',
    );

    const groupedStatesByDateObj = mapKeys(localGroupedClassificationStates, (_: any, key: string) =>
      new Date(key).toDateString(),
    );
    setGroupedClassificationStates(groupedStatesByDateObj);
  };

  const processClusterState = (cluster: Cluster) => {
    let clusterOption = null;

    if (cluster)
      clusterOption = {
        label: startCase(cluster.classification),
        value: cluster.classification,
      };

    setSelectedClusterOption(clusterOption);
  };

  React.useEffect(() => {
    if (authorId) getAuthor();
  }, [authorId]);

  const handleInfoSubmit = async () => {
    setIsSubmitting(true);

    const success = await security.save({
      displayQueryResult: true,
      with: ['cluster'],
    });
    setIsSubmitting(false);
    if (success) {
      processSecurity(security);
      processSecurityStates(security.states);
    }
  };

  const handleClusterChange = (selection) => {
    if (!security.cluster) security.cluster = new Cluster({ securityId: security.id });
    security.cluster.classification = selection.value;
    setSelectedClusterOption(selection);
  };

  const handleOverwriteClassificationsChange = (event) => {
    setOverwriteClassifications(event.target.checked);
    security.overwriteClassifications = event.target.checked;
  };

  const handleGroupedClassificationsChange = (classificationState: SecurityState) => {
    const newGroupedClassifications = { ...groupedClassificationStates };
    const date = classificationState.referenceDate;
    const classificationStatesOnDate = newGroupedClassifications[date] || [];
    newGroupedClassifications[date] = [...classificationStatesOnDate, classificationState];
    setGroupedClassificationStates(newGroupedClassifications);
  };

  if (isLoading) return <Loading />;

  const clusterProps: ISelectProps = {
    handleChange: handleClusterChange,
    id: 'security_cluster',
    isDisabled: !security.id,
    name: 'security[cluster]',
    options: clusterOptions,
    placeholder: !security.id && 'Please Save a new Security before assigning a cluster',
    theme: 'dark',
    value: selectedClusterOption,
  };

  const securityInfoProps: ISecurityInformationProps = {
    clusterProps,
    handleInfoSubmit,
    handleOverwriteClassificationsChange,
    isSubmitting,
    overwriteClassifications,
    securityType,
    security,
  };

  const classificationFormProps = {
    createDraftClassificationState,
    groupedClassificationStates,
    handleGroupedClassificationsChange,
    isSubmitting,
    security,
  };

  const contextValues = {
    actions: { setOverwriteClassifications },
    props: { criterionOptions },
    state: { currentUser },
  };

  return (
    <Context.Provider value={contextValues}>
      <div className="platform-content--full-height platform-content--padding-all platform-content--border-bottom">
        <SecurityInformation {...securityInfoProps} />
      </div>
      <div className="platform-content">
        {security.currentState && <SecurityClassificationsForm {...classificationFormProps} />}
      </div>
    </Context.Provider>
  );
};

export default Form;
