import * as React from 'react';

import { Area, AreaChart, CartesianGrid, Legend, ResponsiveContainer, XAxis, YAxis } from 'recharts';

import { data } from './StackedAreaChartData';
import Context from '../Context';
import { chartColorsV2 } from './chartColors';

export const CustomLegend = (props) => {
  const { payload } = props;

  const legends = payload.map((payloadItem, index) => {
    const lineStyles = {
      background: payloadItem.payload.fill,
      border: `2px solid ${payloadItem.payload.stroke}`,
      borderRadius: '50%',
      display: 'inline-block',
      height: props.print ? '15px' : '10px',
      marginBottom: '-2px',
      width: props.print ? '15px' : '10px',
    };

    return (
      <span key={index} className="">
        <span style={lineStyles} className="mar-r-1" />
        <span className="mar-r-2" style={{ fontSize: '0.7rem', whiteSpace: 'nowrap' }}>
          {payloadItem.dataKey}
        </span>
      </span>
    );
  });

  return <div style={{ border: '1px solid #e4e4e8', padding: '0 0 5px', textAlign: 'center' }}>{legends}</div>;
};

export default function stackedAreaChart({ colors, templateSection }) {
  const context = React.useContext(Context);
  const { reportTemplate } = context.state;
  const chartColors = chartColorsV2();

  const defaultSeries = ['Series 1', 'Series 2', 'Series 3', 'Series 4', 'Series 5'];

  const allocationSeries = ['Cash', 'Fixed Income', 'Equity', 'Hedge Fund', 'Other'];

  const currencySeries = ['GBP', 'USD', 'EUR', 'CHF', 'Other'];

  const [series, setSeries] = React.useState<string[]>(defaultSeries);

  const areas = series.map((area, index) => {
    const colour = chartColors(reportTemplate, colors, area, index, !templateSection);
    return (
      <Area
        key={area}
        dataKey={area}
        stackId="1"
        fill={colour}
        isAnimationActive={false}
        fillOpacity={1}
        stroke={colour}
      />
    );
  });

  React.useEffect(() => {
    if (templateSection && templateSection.areaChartType) {
      switch (templateSection.areaChartType) {
        case 'allocation':
          setSeries(allocationSeries);
          return;
        case 'currency':
          setSeries(currencySeries);
          return;
        default:
          setSeries(defaultSeries);
          return;
      }
    }
  }, [templateSection]);

  let height = 175;
  if (templateSection && templateSection.size === 'medium') height = 300;
  if (templateSection && templateSection.size === 'large') height = 450;

  return (
    <div>
      <ResponsiveContainer width="100%" height={height}>
        <AreaChart width={500} height={height} data={data(series)} margin={{ top: 5, right: 15, left: 0, bottom: 0 }}>
          <CartesianGrid stroke="#fff" />
          <XAxis
            dataKey="name"
            interval={0}
            tick={{ fill: '#b5b6bd', fontSize: '8px', fontWeight: 'bold' }}
            tickSize={0}
            tickMargin={10}
          />
          <YAxis
            interval={0}
            domain={[0, 100]}
            tick={{ fill: '#b5b6bd', fontSize: '8px', fontWeight: 'bold' }}
            ticks={[0, 25, 50, 75, 100]}
            allowDataOverflow
            tickSize={0}
            tickMargin={5}
            tickFormatter={(tick) => `${tick}%`}
            type="number"
          />
          {areas}
          <Legend content={<CustomLegend />} iconType="line" />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}
