import * as React from 'react';
import * as $ from 'jquery';

const routes = require('../../../../javascript/application/routes.js');

import Presenter from './edit/Presenter';

import Loading from 'components/shared/Loading';
import { Balance } from 'javascript/models';
import { CashFlow } from 'javascript/models';

export default function edit({ portfolioId, portfolioRef }) {
  const [balances, setBalances] = React.useState(null);
  const [currencyHoldings, setCurrencyHoldings] = React.useState(null);
  const [cashFlows, setCashFlows] = React.useState(null);
  const [assetHoldings, setAssetHoldings] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    getData();
  }, []);

  React.useEffect(() => {
    if (assetHoldings && balances && currencyHoldings) {
      setIsLoading(false);
    }
  }, [assetHoldings, balances, currencyHoldings]);

  async function getData() {
    getBalances();
    getCurrencyHoldings();
    getAssetHoldings();
  }

  async function getBalances() {
    const { data } = await Balance.where({ balanceable_id: portfolioId, balanceable_type: 'Portfolio' })
      .order({ value_date: 'asc' })
      .per(1000)
      .all();

    setBalances(data);
  }

  function getCurrencyHoldings() {
    $.ajax({
      beforeSend: () => setIsLoading(true),
      cache: false,
      dataType: 'json',
      success: (response) => setCurrencyHoldings(response),
      type: 'GET',
      url: routes.platform_portfolio_currency_holdings_path(portfolioId),
    });
  }

  function getAssetHoldings() {
    $.ajax({
      beforeSend: () => setIsLoading(true),
      cache: false,
      dataType: 'json',
      success: (response) => setAssetHoldings(response),
      type: 'GET',
      url: routes.platform_portfolio_asset_holdings_path(portfolioId),
    });
  }

  async function getCashflows() {
    const { data } = await CashFlow.where({ cash_flowable_id: portfolioId, cash_flowable_type: 'Portfolio' })
      .order({ value_date: 'asc' })
      .all();

    setCashFlows(data);
  }

  function handleUpdate() {}

  function handleCellChange(changes) {
    // console.log(changes)
  }

  return (
    <div className="modal" style={{ margin: '40px 20px', width: 'calc(100% - 40px)', overflowX: 'scroll' }}>
      <div style={{ width: '2000px' }}>
        <header className="modal__header">{portfolioRef}</header>
        <div className="modal__content">
          <div className="frow frow--gutters">
            <div className="col-mc-1-1">
              {isLoading ? (
                <Loading />
              ) : (
                <Presenter {...{ assetHoldings, balances, currencyHoldings, handleCellChange, portfolioRef }} />
              )}
            </div>
            <div className="col-mc-1-1">
              <div className="button button--light-themed" onClick={handleUpdate}>
                Update
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
