import * as React from 'react';

import Context from '../../../Context';

export default function reviewComments({ attributes, handleChange, handleManualChange }) {
  const context = React.useContext(Context);
  const { reportTemplate } = context.state;
  const [selectedOption, setSelectedOption] = React.useState(
    attributes.tableDetails ? attributes.tableDetails['option'] : null,
  );

  const isConsolidated = reportTemplate.consolidated;

  if (!isConsolidated) return <div />;

  function updateAttributes() {
    const value = { option: selectedOption };
    handleManualChange(value, 'tableDetails');
  }

  React.useEffect(() => {
    updateAttributes();
  }, [selectedOption]);

  function handleOptionChange() {
    setSelectedOption(this);
  }

  const allOptions = [
    { value: 'consolidated_commentary', label: 'Consolidated commentary only' },
    { value: 'consolidated_commentary_ap', label: 'Consolidated commentary & action points' },
    { value: 'all', label: 'Consolidated & constituent commentary & action points' },
  ];

  const options = allOptions.map((option) => {
    return (
      <div className="col-tn-1-1" key={option.value}>
        <div className="pretty pretty--large pretty--with-label p-round">
          <input
            type="radio"
            value={option.value}
            id="option_type"
            onChange={handleOptionChange.bind(option.value)}
            checked={selectedOption === option.value}
          />
          <div className="state p-blue-circle">
            <label>{option.label}</label>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="frow frow--gutters">
      <div className="col-mc-1-1">
        <label className="form__label" htmlFor="">
          Option
        </label>
        <div className="frow">{options}</div>
      </div>
    </div>
  );
}
