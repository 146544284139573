import { Attr, Model } from 'spraypaint';
import { ApplicationRecord } from './ApplicationRecord';

@Model()
export class TemplateActionPoint extends ApplicationRecord {
  public static jsonapiType = 'template_action_points';
  @Attr() public companyId: string;
  @Attr() public title: string;
  @Attr() public body: string;
}
