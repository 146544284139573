import * as React from 'react';

import { format } from 'date-fns';

import Summary from './emails/Summary';

export default function emails({ dataChaseTemplates, getTemplates }) {
  let firstChaserSent = 'Never';
  let secondChaserSent = 'Never';
  let thirdChaserSent = 'Never';

  const firstChaser = dataChaseTemplates.find((template) => template.emailType === 'data_chase_one');
  if (firstChaser && firstChaser.lastTriggeredAt) {
    firstChaserSent = format(firstChaser.lastTriggeredAt, 'DD MMM YYYY [at] HH:mm');
  }
  const secondChaser = dataChaseTemplates.find((template) => template.emailType === 'data_update_request');

  if (secondChaser && secondChaser.lastTriggeredAt) {
    secondChaserSent = format(secondChaser.lastTriggeredAt, 'DD MMM YYYY [at] HH:mm');
  }
  const thirdChaser = dataChaseTemplates.find((template) => template.emailType === 'second_update_request');
  if (thirdChaser && thirdChaser.lastTriggeredAt) {
    thirdChaserSent = format(thirdChaser.lastTriggeredAt, 'DD MMM YYYY [at] HH:mm');
  }

  return (
    <div className="frow frow--gutters frow--items-center frow--justify-between">
      <div className="col-sm-1-5">
        <Summary label="Send First Chaser" emailType="data_chase_one" {...{ getTemplates }} />
        <p className="text-white helper-text mar-t-1">Last Sent: {firstChaserSent}</p>
      </div>
      <div className="sm-1-5">
        <i className="icon-arrow-right icon-2x text-white" />
      </div>
      <div className="col-sm-1-5">
        <Summary label="Send Second Chaser" emailType="data_update_request" {...{ getTemplates }} />
        <p className="text-white helper-text mar-t-1">Last Sent: {secondChaserSent}</p>
      </div>
      <div className="sm-1-5">
        <i className="icon-arrow-right icon-2x text-white" />
      </div>
      <div className="col-sm-1-5">
        <Summary label="Send Third Chaser" emailType="second_update_request" {...{ getTemplates }} />
        <p className="text-white helper-text mar-t-1">Last Sent: {thirdChaserSent}</p>
      </div>
    </div>
  );
}
