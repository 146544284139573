import * as React from 'react';

export default function blankComponent() {
  return (
    <div className="pdf-body-copy" style={{ height: '10px', opacity: 0.9, fontSize: '0.9rem' }}>
      This component is used to fill the remainder of the page. It will be ignored if the components fill the page
      themselves
    </div>
  );
}
