import * as React from 'react';

export default function constituentValues({ large, showHeaders }) {
  const headers = (
    <thead>
      <tr>
        <th style={{ verticalAlign: 'top', width: '20%' }} />
        <th style={{ verticalAlign: 'top', width: '20%' }}>Constituent 1</th>
        <th style={{ verticalAlign: 'top', width: '20%' }}>Constituent 2</th>
        <th style={{ verticalAlign: 'top', width: '20%' }}>Constituent 3</th>
        <th style={{ verticalAlign: 'top', width: '20%' }}>Total</th>
      </tr>
    </thead>
  );

  return (
    <table className={`pdf-table pdf-table--striped ${large && 'pdf-table--large'}`}>
      {showHeaders && headers}
      <tbody>
        <tr>
          <td style={{ width: '20%' }} className="pdf-table__row-label">
            Beginning Value
          </td>
          <td style={{ width: '20%' }}>100.00</td>
          <td style={{ width: '20%' }}>200.00</td>
          <td style={{ width: '20%' }}>300.00</td>
          <td style={{ width: '20%' }}>600.00</td>
        </tr>
        <tr>
          <td style={{ width: '20%' }} className="pdf-table__row-label">
            Cash Flows
          </td>
          <td style={{ width: '20%' }} className="pdf-table__negative-value">
            -10.00
          </td>
          <td style={{ width: '20%' }}>0.00</td>
          <td style={{ width: '20%' }}>50.00</td>
          <td style={{ width: '20%' }}>40.00</td>
        </tr>
        <tr>
          <td style={{ width: '20%' }} className="pdf-table__row-label">
            Appreciation
          </td>
          <td style={{ width: '20%' }}>20.00</td>
          <td style={{ width: '20%' }}>10.00</td>
          <td style={{ width: '20%' }} className="pdf-table__negative-value">
            -60.00
          </td>
          <td style={{ width: '20%' }} className="pdf-table__negative-value">
            -30.00
          </td>
        </tr>
        <tr>
          <td style={{ width: '20%' }} className="pdf-table__row-label">
            Current Value
          </td>
          <td style={{ width: '20%' }}>110.00</td>
          <td style={{ width: '20%' }}>210.00</td>
          <td style={{ width: '20%' }}>290.00</td>
          <td style={{ width: '20%' }}>610.00</td>
        </tr>
        <tr>
          <td style={{ width: '20%' }} className="pdf-table__row-label">
            Return
          </td>
          <td style={{ width: '20%' }}>10.00%</td>
          <td style={{ width: '20%' }}>5.00%</td>
          <td style={{ width: '20%' }} className="pdf-table__negative-value">
            -12.50%
          </td>
          <td style={{ width: '20%' }}>6.47%</td>
        </tr>
        <tr>
          <td style={{ width: '20%' }} className="pdf-table__row-label">
            Benchmark Return
          </td>
          <td style={{ width: '20%' }}>6.05%</td>
          <td style={{ width: '20%' }}>7.85%</td>
          <td style={{ width: '20%' }} className="pdf-table__negative-value">
            -4.35%
          </td>
          <td style={{ width: '20%' }}>2.45%</td>
        </tr>
        <tr>
          <td style={{ width: '20%' }} className="pdf-table__row-label">
            Added Value
          </td>
          <td style={{ width: '20%' }}>3.95%</td>
          <td style={{ width: '20%' }} className="pdf-table__negative-value">
            -2.85%
          </td>
          <td style={{ width: '20%' }} className="pdf-table__negative-value">
            -8.15%
          </td>
          <td style={{ width: '20%' }}>4.02%</td>
        </tr>
      </tbody>
    </table>
  );
}
