import * as React from 'react';

import { Drawer } from '@blueprintjs/core';
import pluralize from 'pluralize';

import Condition from './conditions/Condition';
import useVisibility from 'components/shared/customHooks/useVisibility';

import {
  UploadTemplateAdditionalAttribute,
  UploadTemplateCondition,
  UploadTemplateSheetColumn,
} from 'javascript/models';

interface IProps {
  conditionable: UploadTemplateSheetColumn | UploadTemplateAdditionalAttribute;
  conditionOptions?: { value: string; label: string }[];
  conditionableType: string;
}

export default function conditions(props: IProps) {
  const { conditionable, conditionOptions, conditionableType } = props;

  const [conditions, setConditions] = React.useState(conditionable.uploadTemplateConditions);
  // Using this to let the children know they should try and save their conditions because all of the unsaved
  // attributes are in the child component instead of here
  const [saveConditions, setSaveConditions] = React.useState(false);

  React.useEffect(() => {
    if (saveConditions) {
      setSaveConditions(false);
    }
  }, [saveConditions]);

  React.useEffect(() => {
    setConditions(conditionable.uploadTemplateConditions);
  }, [conditionable.uploadTemplateConditions]);

  const { handleClose, handleOpen, isOpen } = useVisibility(false);

  async function handleAddCondition() {
    const condition = new UploadTemplateCondition({ conditionableType, conditionableId: conditionable.id });
    const success = await condition.save();
    const allConditions = [...conditions];
    allConditions.push(condition);
    setConditions(allConditions);
  }

  async function removeCondition(conditionId) {
    const condition = conditions.find((condition) => condition.id === conditionId);
    const success = await condition.destroy();
    if (success) {
      const newConditions = [...conditions];
      const index = newConditions.indexOf(condition);
      newConditions.splice(index, 1);
      setConditions(newConditions);
    }
  }

  function handleSave() {
    setSaveConditions(true);
  }

  const allConditions = conditions.map((condition) => {
    return (
      <Condition
        key={condition.id}
        uploadTemplateCondition={condition}
        {...{ conditionOptions, handleClose, removeCondition, saveConditions }}
      />
    );
  });

  return (
    <React.Fragment>
      <span className="link link--secondary" onClick={handleOpen}>
        {conditions.length > 0 ? pluralize('Condition', conditions.length, true) : 'Conditions'}
      </span>
      <Drawer isOpen={isOpen} portalContainer={document.body} title="Conditions" onClose={handleClose} size="70%">
        <div className="platform-content platform-content--padding-all">
          {allConditions}
          <button className="button button--secondary button--secondary-light-themed" onClick={handleAddCondition}>
            Add Condition
          </button>
        </div>
        <div className="platform-content platform-content--padding-all">
          <button className="button button--light-themed button--full-width" onClick={handleSave}>
            Save Conditions
          </button>
        </div>
      </Drawer>
    </React.Fragment>
  );
}
