import * as React from 'react';

import { Area, AreaChart, CartesianGrid, Legend, Pie, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { CustomLegend, CustomTooltip } from './reactStackedAreaChart/ChartComponents';


interface IProps {
  data: any;
  height: number;
  showXAxis: boolean;
  showYAxis: boolean;
  showTooltip: boolean;
  handleActiveLabelChange?(activeLabel: string): void;
  colourMap: { [key: string]: string };
}

export default function reactStackedAreaChart(props: IProps) {
  const { colourMap, data, height, showYAxis, showXAxis, showTooltip, handleActiveLabelChange } = props;
  const [activeLabel, setActiveLabel] = React.useState();

  const series = data[0].series.map((data) => data.name);

  function handleClick(payload) {
    setActiveLabel(payload.activeLabel);
  }

  React.useEffect(() => {
    if (activeLabel && handleActiveLabelChange) {
      handleActiveLabelChange(activeLabel);
    }
  }, [activeLabel]);

  const areas = series.map((area) => {
    return (
      <Area
        key={area}
        dataKey={area}
        stackId="1"
        fill={colourMap[area]}
        isAnimationActive={false}
        fillOpacity={0.9}
        stroke={colourMap[area]}
      />
    );
  });

  const chartData = data.map((dataPoint) => {
    const dataObject = { name: dataPoint.name };
    dataPoint.series.forEach((data) => {
      dataObject[data.name] = parseFloat(data.value).toFixed(2);
    });
    return dataObject;
  });

  const tooltip = <Tooltip content={<CustomTooltip />} offset={0} />;

  return (
    <div>
      <ResponsiveContainer width="100%" height={height}>
        <AreaChart
          width={500}
          height={height}
          data={chartData}
          margin={{ top: 5, right: 15, left: 0, bottom: 0 }}
          onClick={handleClick}
        >
          <CartesianGrid stroke="#fff" />
          <XAxis
            dataKey="name"
            interval={0}
            tick={{ fill: '#b5b6bd', fontSize: '12px', fontWeight: 'bold' }}
            tickSize={0}
            tickMargin={10}
            hide={!showXAxis}
          />
          <YAxis
            interval={0}
            domain={[0, 100]}
            tick={{ fill: '#b5b6bd', fontSize: '12px', fontWeight: 'bold' }}
            ticks={[0, 25, 50, 75, 100]}
            allowDataOverflow
            tickSize={0}
            tickMargin={5}
            tickFormatter={(tick) => `${tick}%`}
            type="number"
            hide={!showYAxis}
          />
          {areas}
          {showTooltip && tooltip}
          <Legend content={<CustomLegend {...{ showXAxis }} />} iconType="line" />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}
