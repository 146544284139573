import * as React from 'react';

import { Cell, Label, Legend, Pie, PieChart, ResponsiveContainer, Sector } from 'recharts';

import EmptyChart from './reactPieChart/EmptyChart';

import IPieChartData from './interfaces/IPieChartData';
import Cursor from 'components/types/Cursor';

interface IProps {
  animationBegin?: number;
  animationDuration?: number;
  activeShape?: boolean;
  data: IPieChartData[];
  displayLegend?: boolean;
  height: number;
  isLoading?: boolean;
  labelText: string | number;
  labelSize?: string;
  noData?: boolean;
  noDataText?: string;
  cursor?: Cursor;
  setSelectedSlice?: any;
}

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 8) * cos;
  const my = cy + (outerRadius + 8) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 15;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g style={{ cursor: props.cursor }}>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      {/*<text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#fff" fontSize={12}>*/}
      {/*  {payload.name}*/}
      {/*</text>*/}
      <text x={ex + (cos >= 0 ? 1 : -1) * 8} y={ey} dy={3} textAnchor={textAnchor} fill="#fff" fontSize={12}>
        {`${(percent * 100).toFixed(2)}%`}
      </text>
    </g>
  );
};

export const CustomLegend = (props) => {
  const { payload } = props;

  const legends = payload.map((payloadItem, index) => {
    const lineStyles = {
      background: payloadItem.payload.fill,
      border: `2px solid ${payloadItem.payload.stroke}`,
      borderRadius: '50%',
      display: 'inline-block',
      height: '10px',
      width: '10px',
    };

    return (
      <div key={index} className="col-mc-1-1" style={{ marginBottom: '8px', fontSize: '0.8rem' }}>
        <div style={{ paddingBottom: index + 1 !== payload.length ? '5px' : '0px' }}>
          <table className="pdf-hidden-table">
            <tbody>
              <tr>
                <td style={{ fontSize: '8px', width: '15%' }}>
                  <span style={lineStyles} />
                </td>
                <td className="text-white" style={{ width: '50%', fontSize: '12px' }}>
                  {props.data[index].name}
                </td>
                <td style={{ fontSize: '12px' }} className="text-right text-white">
                  {`${props.data[index].value}%`}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  });

  return (
    <div style={{ padding: '15px 5px 10px', width: '170px' }}>
      <div className="frow frow--direction-column">{legends}</div>
    </div>
  );
};

export default function reactPieChart(props: IProps) {
  const [activeIndex, setActiveIndex] = React.useState();
  const { activeShape, isLoading, labelSize, setSelectedSlice, cursor = 'pointer' } = props;

  function onPieEnter(data, index) {
    if (!activeShape) return;

    setActiveIndex(index);
  }
  function onPieLeave() {
    setActiveIndex(null);
  }

  function handleClick(selectedSlice) {
    if (setSelectedSlice) {
      setSelectedSlice(selectedSlice.name);
    }
  }

  if (isLoading) {
    return (
      <ResponsiveContainer width="100%" height={props.height} className="text-huge bp3-skeleton">
        <div>Placeholder</div>
      </ResponsiveContainer>
    );
  }

  const { data, noData } = props;

  if (noData) {
    return (
      <EmptyChart
        animationBegin={props.animationBegin}
        animationDuration={props.animationDuration}
        height={props.height}
        text={props.noDataText}
      />
    );
  }

  const legend = (
    <Legend layout="vertical" verticalAlign="middle" align="right" content={<CustomLegend {...{ data }} />} />
  );

  let margin = { top: 0, right: 0, left: 0, bottom: 0 };
  // We need more margin when using the active shape to add in more margin
  if (activeShape) {
    margin = { top: 10, right: 0, left: 0, bottom: 25 };
  }

  return (
    <ResponsiveContainer width="100%" height={props.height} className="text-huge">
      <PieChart margin={margin}>
        <Pie
          animationBegin={props.animationBegin}
          animationDuration={props.animationDuration}
          data={data}
          nameKey="name"
          dataKey="value"
          innerRadius="55%"
          startAngle={90}
          endAngle={-270}
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          onMouseEnter={onPieEnter}
          onMouseLeave={onPieLeave}
          onClick={handleClick}
          cursor={cursor}
        >
          {data.map((entry, index) => (
            <Cell key={index} fill={entry.color} stroke="none" fillOpacity={entry.opacity ? entry.opacity : '1'} />
          ))}
          <Label position="center" fill="#ffffff" fontSize={labelSize}>
            {props.labelText}
          </Label>
        </Pie>
        {props.displayLegend && legend}
      </PieChart>
    </ResponsiveContainer>
  );
}

reactPieChart.defaultProps = {
  animationBegin: 400,
  animationDuration: 1500,
  labelSize: '6rem',
};
