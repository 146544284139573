import * as React from 'react';

import * as ReactTooltip from 'react-tooltip';
import lodashStartcase from 'lodash.startcase';

import {
  editPlatformPortfolioPath,
  platformPortfolioDownloadPath,
  platformPortfolioValuationsPath,
} from 'javascript/application/ts_routes';

const classNames = require('classnames');

import Destroy from './infoBar/Destroy';
import ManualUpload from './infoBar/ManualUpload';
import RegenerateReturns from './infoBar/RegenerateReturns';
import Terminate from './infoBar/Terminate';
import ManualEntry from './infoBar/manualEntry';
import StatusTag from './shared/StatusTag';

const infoBar = ({ portfolio, terminationReasons }) => {
  const { canUpdate, clientTypeIcon, id, reference, status, consolidated, combined, dataChaseType } =
    portfolio.attributes;
  const { mandatePath } = portfolio.links;
  const isDraft = status === 'draft';

  const portfolioName = portfolio.attributes.name;

  const portfolioMandate = () => {
    if (!mandatePath) return;

    return (
      <a
        href={mandatePath}
        target="_blank"
        className="button button--secondary button--no-min-width mar-r-2"
        rel="noreferrer"
      >
        View Mandate
        <i className="icon-arrow-right-top icon-fw icon-0-9x mar-l-1" />
      </a>
    );
  };

  const renderUpdateControls = () => {
    if (!canUpdate) return;

    const destroyButton = <Destroy {...{ portfolio }} />;
    const terminateButton = <Terminate {...{ portfolio, terminationReasons }} />;

    return (
      <React.Fragment>
        <ManualUpload disabled={isDraft} {...{ portfolio }} />
        <ManualEntry disabled={isDraft} {...{ portfolio }} />
        <a
          href={platformPortfolioDownloadPath(id)}
          target="_blank"
          className="button button--secondary button--icon mar-r-1"
          data-tip="Download Portfolio information"
          data-for="portfolio_controls"
          rel="noreferrer"
        >
          <i className="icon-document icon-fw" />
        </a>
        <a
          href={editPlatformPortfolioPath(id)}
          className="button button--secondary button--icon mar-r-1"
          data-tip="Edit"
          data-for="portfolio_controls"
        >
          <i className="icon-edit icon-fw" />
        </a>
        {status === 'draft' ? destroyButton : terminateButton}
        <ReactTooltip effect="solid" id="portfolio_controls" />
      </React.Fragment>
    );
  };

  const portfolioTitle = () => {
    if (portfolio && portfolio.id) {
      return `${portfolioName ? `${portfolioName} - ` : ''}${reference}`;
    }
  };

  const combinedOrConsolidated = () => {
    if (combined) {
      return <span className="tag tag--orange mar-l-2">{lodashStartcase('Combined')}</span>;
    } else if (consolidated) {
      return <span className="tag tag--green mar-l-2">{lodashStartcase('Consolidated')}</span>;
    } else return null;
  };

  return (
    <div className="frow frow--items-center">
      <div className="col-md-6-10 col-lg-7-10">
        <div className="platform-content platform-content--padding-horizontal platform-content--border-right-md pad-v-1">
          <div className="frow frow--items-center frow--justify-between">
            <div className="frow frow--items-center">
              <h2 className={`heading-one ${status === 'terminated' && 'text-brand-pale-red'}`}>{portfolioTitle()}</h2>
              <StatusTag status={status} />
              {combinedOrConsolidated()}
              {dataChaseType === 'data_feed' && <StatusTag status={dataChaseType} />}
            </div>
            <div>
              <RegenerateReturns {...{ id }} />
              {renderUpdateControls()}
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4-10 col-lg-3-10" style={{ position: 'relative' }}>
        <div className="platform-panel__content platform-panel__content--padded pad-v-1">
          {portfolioMandate()}
          <a
            href={platformPortfolioValuationsPath(id)}
            target="_blank"
            className="button button--secondary button--no-min-width"
            rel="noreferrer"
          >
            Valuations
            <i className="icon-arrow-right-top icon-fw icon-0-9x mar-l-1" />
          </a>
        </div>
        <img src={clientTypeIcon} alt="client type logo" className="client-icon" />
      </div>
    </div>
  );
};

export default infoBar;
