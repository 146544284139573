import * as React from 'react';

import ReactSelect from 'components/shared/forms/ReactSelect';

interface ISelectOptions {
  label: string;
  value: string;
}

interface IProps {
  clientCurrency: string;
  currencies: ISelectOptions[];
  contactOptions: ISelectOptions[];
  selectableQuarters: { display: string; value: string }[];
  selectableUsers: ISelectOptions[];
}

export default function form(props: IProps) {
  const { clientCurrency, currencies, contactOptions, selectableQuarters, selectableUsers } = props;

  const [includeAll, setIncludeAll] = React.useState(true);
  const [useRm, setUseRm] = React.useState(true);

  const formattedSelectableQuarters = selectableQuarters.map((quarter) => {
    return { label: quarter.display, value: quarter.value };
  });

  function toggleIncludeAll() {
    setIncludeAll(!includeAll);
  }

  function toggleUseRm() {
    setUseRm(!useRm);
  }

  const documentOptions = [
    { value: 'pdf', label: 'PDF' },
    { value: 'excel', label: 'Excel' },
    { value: 'both', label: 'PDF & Excel' },
  ];

  const formatOptions = documentOptions.map((option, index) => {
    return (
      <div className="col-sm-1-2" key={option.value}>
        <div className="pretty pretty--large pretty--with-label p-round">
          <input
            type="radio"
            value={option.value}
            name="investment_risk_assessment[document_type]"
            id="series"
            defaultChecked={option.value === 'pdf'}
          />
          <div className="state p-blue-circle">
            <label className="text-white">{option.label}</label>
          </div>
        </div>
      </div>
    );
  });

  const userSelect = (
    <div className="col-mc-1-1">
      <label htmlFor="primary_user" className="form__label">
        Users
        <span>Only portfolios belonging to the selected users will be included</span>
      </label>
      <ReactSelect
        theme="dark"
        name="investment_risk_assessment[user_ids][]"
        id="user_ids"
        isMulti
        options={selectableUsers}
      />
    </div>
  );

  const keyContact = (
    <div className="col-mc-1-1">
      <label htmlFor="primary_user" className="form__label">
        Key Contact
        <span>This person will appear on the IRA as the key contact</span>
      </label>
      <ReactSelect
        theme="dark"
        name="investment_risk_assessment[key_contact_id]"
        id="key_contact_id"
        options={contactOptions}
      />
    </div>
  );

  return (
    <div className="frow frow--gutters">
      <div className="col-mc-1-1">
        <label htmlFor="" className="form__label">
          Do you want to include all portfolios for this client?
        </label>
        <div className="pretty p-icon">
          <input
            type="checkbox"
            id="include_all"
            onChange={toggleIncludeAll}
            checked={includeAll}
            name="investment_risk_assessment[include_all]"
            value="1"
          />
          <div className="state p-blue">
            <i className="icon-tick icon icon-push-down-small" />
            <label className="text-white">Include all</label>
          </div>
        </div>
      </div>
      {!includeAll && userSelect}
      <div className="col-mc-1-1">
        <label htmlFor="" className="form__label">
          Do you want to use the RM as the key contact?
        </label>
        <div className="pretty p-icon">
          <input
            type="checkbox"
            id="series"
            onChange={toggleUseRm}
            checked={useRm}
            name="investment_risk_assessment[use_rm]"
            value="1"
          />
          <div className="state p-blue">
            <i className="icon-tick icon icon-push-down-small" />
            <label className="text-white">Yes</label>
          </div>
        </div>
      </div>
      {!useRm && keyContact}
      <div className="col-mc-1-1">
        <label htmlFor="" className="form__label">
          Format
        </label>
        <div className="frow frow--gutters">{formatOptions}</div>
      </div>
      <div className="col-mc-1-1">
        <label htmlFor="" className="form__label">
          Currency
        </label>
        <ReactSelect
          theme="dark"
          name="investment_risk_assessment[currency]"
          id="review_date"
          options={currencies}
          defaultValue={{ value: clientCurrency, label: clientCurrency.toUpperCase() }}
        />
      </div>
      <div className="col-mc-1-1">
        <label htmlFor="" className="form__label">
          Review Date
        </label>
        <ReactSelect
          theme="dark"
          name="investment_risk_assessment[review_date]"
          id="review_date"
          options={formattedSelectableQuarters}
        />
      </div>
    </div>
  );
}
