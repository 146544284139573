import * as React from 'react';

import lodashStartcase from 'lodash.startcase';
import { getOrder, precisionTickFormatter } from './../../shared/utilities/precisionFunctions';
const numeral = require('numeral');
import { format, parse } from 'date-fns';

import { CartesianGrid, Label, Legend, Line, LineChart, ReferenceArea, ReferenceLine, XAxis, YAxis } from 'recharts';

const CustomYAxisPrint = (props) => {
  const name = props.name || 'Axis Name';
  const height = props.height;
  const { x, y } = props.viewBox;
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={-(height / 2 - 20)}
        y={10}
        textAnchor="middle"
        fill="#333"
        transform="rotate(-90)"
        fontWeight="bold"
        fontSize="10.8px"
      >
        {name.toLocaleUpperCase()}
      </text>
    </g>
  );
};

const CustomLegend = (props) => {
  const { payload } = props;

  const legends = payload.map((payloadItem, index) => {
    const lineStyles = {
      borderColor: payloadItem.color,
      borderStyle: 'solid',
      borderWidth: '1px',
      display: 'inline-block',
      marginBottom: '2px',
      marginRight: '10px',
      width: '25px',
    };

    if (payloadItem.payload.strokeDasharray) {
      lineStyles.borderStyle = 'dashed';
    }

    return (
      <span key={index} style={{ display: 'inline-block', breakInside: 'avoid', whiteSpace: 'nowrap' }}>
        <span style={lineStyles} className="mar-r-1" />
        <span className="mar-r-2" style={{ fontSize: '1.26rem' }}>
          {payloadItem.dataKey.startsWith('EI') ? payloadItem.dataKey : lodashStartcase(payloadItem.dataKey)}
        </span>
      </span>
    );
  });

  return <div style={{ border: '1px solid #e4e4e8', padding: '3px 0 9px', textAlign: 'center' }}>{legends}</div>;
};

const CustomizedNoDataLabel = (props) => {
  const { height, width, x, y } = props.viewBox;
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={width / 2} y={height / 2} textAnchor="middle" fill="#000" fontWeight="bold" fontSize="20px">
        Insufficient Data
      </text>
    </g>
  );
};

const CustomHorizontalLegend = (props) => {
  const { payload } = props;

  const legends = payload.map((payloadItem, index) => {
    const lineStyles = {
      borderColor: payloadItem.color,
      borderStyle: 'solid',
      borderWidth: '1px',
      display: 'inline-block',
      marginBottom: '1px',
      marginRight: '10px',
      width: '25px',
    };

    if (payloadItem.payload.strokeDasharray) {
      lineStyles.borderStyle = 'dashed';
    }

    return (
      <div key={index} style={{ whiteSpace: 'nowrap', breakInside: 'avoid' }}>
        <span style={lineStyles} />
        <span className="mar-r-2" style={{ fontSize: '1.26rem' }}>
          {payloadItem.dataKey.startsWith('EI') ? payloadItem.dataKey : lodashStartcase(payloadItem.dataKey)}
        </span>
      </div>
    );
  });

  return (
    <div className="mar-l-4" style={{ border: '1px solid #e4e4e8', padding: '3px 5px 9px' }}>
      {legends}
    </div>
  );
};

import { chartColorsV2 } from './chartColors';
import getTicks from 'components/shared/utilities/getTicks';

interface IProps {
  benchmarkTypes: any;
  templateSection: any;
  width: number;
  height: number;
  showReferenceLine?: boolean;
  tableType?: string;
  theme: any;
  type: 'percentage' | 'number';
  startAt: number;
  pticks: number[];
  verticalLegend: boolean;
  cartesianXAxis: boolean;
  xAxis: number[];
}

export default function lineChart(props: IProps) {
  const {
    benchmarkTypes,
    templateSection,
    theme,
    width,
    height,
    showReferenceLine,
    tableType,
    type,
    startAt,
    pticks,
    verticalLegend,
    cartesianXAxis,
    xAxis,
  } = props;
  const chartColors = chartColorsV2();
  const lines = templateSection.data[0].series.map((data) => data.label);

  const numbers = [];

  const data = templateSection.data.map((dataPoint) => {
    const dataObject = { name: dataPoint.time_code };
    dataPoint.series.forEach((data) => {
      numbers.push(data.value);
      dataObject[data.label] = parseFloat(data.value).toFixed(2);
    });
    return dataObject;
  });

  const maxData = tableType === 'drawdown' ? 0 : Math.max(...numbers);
  const minData = startAt ? startAt : Math.min(...numbers);
  const tickPoint = tableType === 'drawdown' ? minData / 5 : Math.max((maxData - minData) / 5, 0.1);

  let ticks = getTicks({ max: maxData, min: minData, tickGap: tickPoint });

  if (pticks) {
    ticks = pticks;
  }

  if (tableType === 'drawdown') {
    ticks = [
      Math.floor(minData),
      Math.ceil(minData - tickPoint),
      Math.ceil(minData - tickPoint * 2),
      Math.ceil(minData - tickPoint * 3),
      Math.ceil(minData - tickPoint * 4),
      Math.ceil(minData - tickPoint * 5),
      '' as any,
    ];
  }

  const tickFormatter = precisionTickFormatter(ticks);

  const dateTickFormatter = (tick) => format(parse(tick * 1000), 'MMM YY');

  const chartLines = lines.map((dataPoint, index) => {
    let dash = '';
    if (index > 3) dash = '3 3';
    if (dataPoint === 'Loan') dash = '9 5';
    return (
      <Line
        key={dataPoint}
        strokeWidth="2"
        type="linear"
        dataKey={dataPoint}
        stroke={chartColors(dataPoint, theme, benchmarkTypes, index, 'line')}
        dot={false}
        isAnimationActive={false}
        strokeDasharray={dash}
      />
    );
  });

  const noDataOverlay = (
    <ReferenceArea
      fill="#f6f6f8"
      x1={0}
      x2={1}
      y1={0}
      y2={100}
      alwaysShow
      isFront
      label={<CustomizedNoDataLabel />}
    />
  );

  function renderLegend() {
    if (verticalLegend) {
      return (
        <Legend
          content={<CustomHorizontalLegend height={height} />}
          iconType="line"
          layout="vertical"
          verticalAlign="top"
          align="right"
        />
      );
    }
    return <Legend content={<CustomLegend height={height} />} iconType="line" wrapperStyle={{ bottom: '-20px' }} />;
  }

  const xAxesLabelCount = data.length;

  const noData = data.length <= 1;

  return (
    <LineChart
      width={width}
      height={height}
      data={noData ? null : data}
      margin={{ top: 5, right: 20, left: 20, bottom: 0 }}
    >
      <CartesianGrid stroke="#d7d7dd" vertical={cartesianXAxis} />
      {noData && noDataOverlay}
      <XAxis
        dataKey="name"
        domain={['auto', 'auto']}
        interval={xAxis ? 0 : xAxesLabelCount < 17 ? 0 : 'preserveEnd'}
        tick={{ fill: '#b5b6bd', fontSize: '12px', fontWeight: 'bold' }}
        ticks={noData ? [''] : xAxis}
        tickSize={0}
        tickMargin={8}
        type={'number'}
        scale={'time'}
        tickFormatter={noData ? null : dateTickFormatter}
      />
      <YAxis
        interval={0}
        tick={{ fill: '#b5b6bd', fontSize: '12px', fontWeight: 'bold' }}
        ticks={noData ? [0, 25, 50, 75, 100] : ticks}
        tickSize={0}
        tickMargin={5}
        tickFormatter={tickFormatter}
        domain={[!startAt ? startAt : 'dataMin', 'dataMax']}
        type="number"
        unit={type === 'percentage' ? '%' : null}
        width={type === 'percentage' ? 60 : 80}
      >
        {type === 'percentage' && (
          <Label content={<CustomYAxisPrint name={templateSection.y_axis} height={height} />} />
        )}
      </YAxis>
      <ReferenceLine y={0} stroke="#000" />
      {showReferenceLine && <ReferenceLine y={0} stroke="#b0b0b5" />}
      {chartLines}
      {noData ? null : renderLegend()}
    </LineChart>
  );
}

lineChart.defaultProps = {
  height: 270,
  pticks: null,
  startAt: null,
  type: 'percentage',
};
