import * as React from 'react';

import { TodoItem } from 'javascript/models';

import NewItem from './NewItem';
import Item from './Item';

interface IProps {
  todoItems: TodoItem[];
  handleUpdateItems(): void;
}

export default function presenter(props: IProps) {
  const { handleUpdateItems, todoItems } = props;

  const uncompletedTodoItems = todoItems
    .filter((todoItem) => !todoItem.completed)
    .map((todoItem) => {
      return <Item key={todoItem.id} todoItem={todoItem} handleUpdateItems={handleUpdateItems} />;
    });
  const completedTodoItems = todoItems
    .filter((todoItem) => todoItem.completed)
    .map((todoItem) => {
      return <Item key={todoItem.id} todoItem={todoItem} handleUpdateItems={handleUpdateItems} />;
    });

  return (
    <div className="todo-list">
      <NewItem {...{ handleUpdateItems }} />
      <div className="platform-content platform-content--margin-bottom platform-content--border-dashed-bottom">
        {uncompletedTodoItems}
      </div>
      <div className="platform-content">
        {completedTodoItems.length > 0 && <h5 className="todo-list__subtitle">Recently Ticked Items</h5>}
        {completedTodoItems}
      </div>
    </div>
  );
}
