import * as React from 'react';

import OutsideClickHandler from 'react-outside-click-handler';

const classNames = require('classnames');

interface IProps {
  selectableClients: { display: string; value: number }[];
  handleClientSelect(client: { display: string; value: string | number }): void;
}

export default function clientSelect(props: IProps) {
  const { selectableClients } = props;

  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedClient, setSelectedClient] = React.useState<any>({ display: 'All Clients', value: '*' });

  function toggleOpen() {
    setIsOpen(!isOpen);
  }

  function handleOutsideClick() {
    setIsOpen(false);
  }

  function handleAllClientSelect() {
    setSelectedClient({ display: 'All Clients', value: '*' });
    props.handleClientSelect({ display: 'All Clients', value: '*' });
    setIsOpen(false);
  }

  const dropdownOptions = selectableClients.map((client) => {
    function handleSelect() {
      setSelectedClient(client);
      props.handleClientSelect(client);
      setIsOpen(false);
    }
    return (
      <div className="dropdown__nav-link" key={client.value} onClick={handleSelect}>
        {client.display}
      </div>
    );
  });

  dropdownOptions.unshift(
    <div key={Math.random()} className="dropdown__nav-link" onClick={handleAllClientSelect}>
      All Clients
    </div>,
  );

  const dropdownClasses = classNames('dropdown dropdown--sw', { 'dropdown--active': isOpen });

  return (
    <div>
      <p className="subtitle-two">Client</p>
      <OutsideClickHandler onOutsideClick={handleOutsideClick}>
        <div className={dropdownClasses}>
          <a className="dropdown__toggle dropdown__toggle--input" onClick={toggleOpen}>
            <div className="frow frow--items-center">
              <i className="icon-users icon-fw mar-r-1" />
              <span
                style={{ width: '140px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
                className="text-normal"
              >
                {selectedClient.display}
              </span>
              <i className={`button__icon fa fa-caret-down fa-animated ${isOpen ? 'fa-flipped' : ''}`} />
            </div>
          </a>
          <div className="dropdown__content">
            <div className="dropdown__content-inner">{dropdownOptions}</div>
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  );
}
