import * as React from 'react';

import Presenter from './history/Presenter';
import { Audit } from 'javascript/models';

import ReviewContext from './ReviewContext';

export default function history() {
  const reviewContext = React.useContext(ReviewContext);
  const { review } = reviewContext.props;
  const { id } = review;
  const [audits, setAudits] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    getAudits();
  }, [review]);

  async function getAudits() {
    const { data } = await Audit.where({ own_and_associated_audits: { type: 'Review', id } })
      .per(999)
      .order({ created_at: 'desc' })
      .order({ id: 'desc' })
      .selectExtra(['username', 'log', 'note'])
      .all();

    setAudits(data);
    setIsLoading(false);
  }

  return <Presenter {...{ isLoading, audits }} />;
}
