import * as React from 'react';

import { format } from 'date-fns';

import Resolve from './Resolve';

export default function ErrorMessagesColumns(handleTableUpdate) {
  return [
    {
      Header: 'Error',
      accessor: 'errorMessage',
      columnName: 'Error',
      id: 'error_message',
      style: { whiteSpace: 'unset' },
      width: 500,
    },
    {
      Cell: (row) => {
        if (!row.original.resolvedAt) return <div />;

        return <div>{format(row.original.resolvedAt, 'DD MMM YYYY [at] HH:mm')}</div>;
      },
      Header: 'Resolved At',
      accessor: 'resolvedAt',
      columnName: 'Resolved At',
      id: 'resolved_at',
    },
    {
      Cell: (row) => {
        if (!row.original.resolver) return <div />;

        return <div>{row.original.resolver.fullName}</div>;
      },
      Header: 'Resolved By',
      accessor: 'resolvedBy',
      columnName: 'Resolved By',
      id: 'resolved_by',
    },
    {
      Cell: (row) => {
        return <Resolve dataUploadError={row.original} handleTableUpdate={handleTableUpdate} />;
      },
      Header: 'Resolve',
      sortable: false,
      width: 75,
    },
  ];
}
