import * as React from 'react';

import { Note } from 'javascript/models/Models';
import { THasManyAttachedRcvd } from 'javascript/models/shared/types';

import * as routes from 'javascript/application/routes';
import ajaxRequest from 'components/shared/utilities/ajaxRequest';
import { Frow, Column } from 'components/frow';

interface IProps {
  note: Note;
}

const Attachments = (props: IProps) => {
  const { note } = props;

  if (!note.isPersisted) return null;

  const [attachments, setAttachments] = React.useState(note.attachments as THasManyAttachedRcvd);

  const handleDeleteAttachment = async (selectedAttachment) => {
    await ajaxRequest({
      includeCsrfToken: true,
      method: 'DELETE',
      path: routes.platform_note_attachment_path(note.id, this),
    });
    setAttachments((localAttachments) =>
      localAttachments.filter((attachment) => attachment.id !== selectedAttachment.id),
    );
  };

  const formattedAttachments = attachments
    .filter((attachment) => !!attachment.id)
    .map((attachment) => (
      <span key={attachment.id}>
        <i
          className="icon-cross icon-clickable icon-0-9x text-brand-blue mar-r-1"
          onClick={() => {
            handleDeleteAttachment(attachment);
          }}
        />
        <a className="link link--blue link--underline" href={attachment.path} target="_blank">
          {attachment.name}
        </a>{' '}
        <br />
      </span>
    ));

  if (!formattedAttachments.length) return null;

  return (
    <p className="mar-b-0 text-small" style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
      <Frow direction="column">
        <Column>
          <span className="small-uppercase">Saved Attachments</span>
        </Column>
      </Frow>
    </p>
  );
};

export default Attachments;
