import * as React from 'react';

const classNames = require('classnames');

import lodashStartcase from 'lodash.startcase';

import Back from './statuses/Back';
import ImportData from './statuses/ImportData';
import OnHold from './statuses/OnHold';
import Send from './statuses/Send';
// import ReviewHistory from './ReviewHistory';
import SubmitReview from './statuses/SubmitReview';
import Terminated from './statuses/Terminated';
import VerifyReview from './statuses/VerifyReview';

import ReviewContext from './ReviewContext';
import Context from '../../../Context';

import Regenerate from './shared/Regenerate';

export default function stageIndicator() {
  const context = React.useContext(Context);
  const reviewContext = React.useContext(ReviewContext);
  const { review } = reviewContext.props;
  const { status } = review.attributes;
  const portfolioStatus = context.props.portfolio.attributes.status;

  const statusIndex = review.attributes.statuses.indexOf(review.attributes.status);

  const stageIndicators = review.attributes.statuses.map((status, index) => {
    const statusClasses = classNames(
      'status-indicator',
      'status-indicator--no-text',
      { 'status-indicator--blue': index === statusIndex },
      { 'status-indicator--tick': statusIndex > index },
      { 'status-indicator--green': review.attributes.status === 'sent' },
    );

    return (
      <div
        key={status}
        data-number={index === 0 || index === 1 || index === review.attributes.statuses.length - 1 ? '' : index - 1}
        className={statusClasses}
      />
    );
  });

  const noReviewIndicator = (
    <React.Fragment>
      <div className="status-indicator status-indicator--no-text status-indicator--blue" />
      <div
        className={`status-indicator status-indicator--no-text ${status === 'no_review' && 'status-indicator--green'}`}
      />
    </React.Fragment>
  );

  function renderNextStageButton() {
    switch (review.attributes.status) {
      case 'awaiting_data':
        return <ImportData />;
      case 'in_progress':
        return <ImportData />;
      case 'in_review':
        return <SubmitReview />;
      case 'verification':
        return <VerifyReview />;
      case 'ready_to_send':
        return <Send />;
      case 'on_hold':
        return <OnHold />;
      case 'terminated':
        return <Terminated />;
      default:
        return null;
    }
  }

  const showBackButton = status !== 'awaiting_data' && status !== 'terminated' && status !== 'on_hold';

  return (
    <div className="workflow-overview">
      <div className="frow frow--gutters frow--items-center" style={{ width: '100%' }}>
        <div className="col-sm-1-3">
          <div className="workflow-overview__stage-indicators">
            {['component_part', 'loan'].includes(portfolioStatus) ? noReviewIndicator : stageIndicators}
          </div>
        </div>
        <div className="col-sm-1-3 text-center">
          <div className="workflow-overview__stage-name">{lodashStartcase(review.attributes.status)}</div>
        </div>
        <div className="col-sm-1-3">
          <div className="frow frow--items-center frow--justify-end text-right" style={{ width: '100%' }}>
            <Regenerate
              reviewId={review.attributes.id}
              reviewStatus={review.attributes.status}
              additionalClasses="button--icon-no-background"
            />
            {showBackButton && <Back />}
            {renderNextStageButton()}
          </div>
        </div>
      </div>
    </div>
  );
}
