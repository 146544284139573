import * as React from 'react';

import { format, parse, subYears } from 'date-fns';

import lodashDebounce from 'lodash.debounce';

const classNames = require('classnames');

import ReactAreaChart from 'components/shared/charting/recharts/ReactAreaChart';

import { PortfolioReturn } from 'javascript/models';

interface IProps {
  id: string;
}

export default function chart(props: IProps) {
  const { id } = props;

  const [isLoading, setIsLoading] = React.useState(true);
  const [meta, setMeta] = React.useState({ maxData: 0, minData: 0 });
  const [portfolioReturns, setPortfolioReturns] = React.useState([]);
  const [selectedYear, setSelectedYear] = React.useState(8);

  const debouncedRequest = lodashDebounce(getPortfolioReturns, 200);

  React.useEffect(() => {
    debouncedRequest();
  }, [selectedYear]);

  const chartData = portfolioReturns.map((portfolioReturn) => {
    return { name: portfolioReturn.attributes.valueDate, amount: portfolioReturn.attributes.overallReturn };
  });

  React.useEffect(() => {
    if (portfolioReturns) {
      setIsLoading(false);
    }
  }, [portfolioReturns]);

  async function getPortfolioReturns() {
    const { data, meta } = await PortfolioReturn.where({
      portfolio_returnable_id: id,
      portfolio_returnable_type: 'PortfolioBenchmark',
      value_date: { gte: format(subYears(parse(new Date()), selectedYear), 'YYYY-MM-DD') },
    })
      .order({ value_date: 'asc' })
      .stats({ data: ['max', 'min'] })
      .per(1000)
      .all();

    setMeta({ maxData: meta.stats.data.max, minData: meta.stats.data.min });
    setPortfolioReturns(data);
  }

  function yearOption() {
    const yearOptions = [1, 2, 3, 4, 5, 6, 7, 8];
    return yearOptions.map((yearNumber, index) => {
      const tabClasses = classNames('button-group__button', {
        'button-group__button--active': selectedYear === yearNumber,
      });
      function selectYear() {
        setSelectedYear(yearNumber);
      }
      return (
        <div className={tabClasses} key={index} onClick={selectYear}>
          {yearNumber}Y
        </div>
      );
    });
  }

  return (
    <React.Fragment>
      <div className="button-group">{yearOption()}</div>
      <ReactAreaChart
        data={chartData}
        hideTooltip
        isLoading={isLoading}
        maxData={meta.maxData}
        minData={meta.minData}
      />
    </React.Fragment>
  );
}
