import * as React from 'react';
import ReactRailsUJS from 'react_ujs';
import ReactTooltip from 'react-tooltip';
import { Tree, TreeNode } from 'react-organizational-chart';
import {
  portalClientPortfolioPath,
  structurePortalClientPortfolioPath,
} from 'javascript/application/ts_routes';
import Loading from 'components/shared/Loading';
import useAjaxRequest from 'components/shared/customHooks/useAjaxRequest';

import { purple } from 'components/shared/colours';

const structure = ({ currentReviewPeriod, portfolioObject }) => {
  const { client_id, id } = portfolioObject;

  const [structureData, setStructureData] = React.useState<any>();

  React.useEffect(() => {
    // If you navigate around to much the JS seems to break and the structure loses all its styles this is
    // trying to stop that
    document.addEventListener('turbolinks:before-render', () => {
      ReactRailsUJS.unmountComponents('.portfolio-structure');
    });
  }, []);

  React.useEffect(() => {
    ReactRailsUJS.detectEvents();
    getStructureData({ review_period: currentReviewPeriod });
  }, [currentReviewPeriod]);

  const requestPath = structurePortalClientPortfolioPath(client_id, id);
  const [getStructureData, , isLoading] = useAjaxRequest({
    method: 'GET',
    path: requestPath,
    stateSetter: setStructureData,
  });

  interface Node {
    id: number;
    name: string;
    manager: string;
    reference: string;
    value: string;
    current: boolean;
  }
  interface IStyledNode {
    clientId: number;
    node: Node;
  }

  const StyledNode = ({ clientId, node }: IStyledNode) => {
    const handleClick = React.useCallback(() => {
      window.location.href = portalClientPortfolioPath(clientId, node.id);
    }, []);

    const nodeStyles: React.CSSProperties = {
      backgroundColor: node.current ? purple : 'rgba(255, 255, 255, 0.05)',
    };

    return (
      <>
        <div
          className="text-white text-small hierarchy-node"
          style={nodeStyles}
          onClick={handleClick}
          data-tip={`${node.name}\n${node.manager}`}
        >
          <div className="frow frow--direction-column frow--justify-center frow--full-height frow--content-center">
            <span>{node.reference}</span>
            <span>{node.value}</span>
          </div>
        </div>
        <ReactTooltip effect="solid" border />
      </>
    );
  };

  const emptyNode = <span />;

  const returnChildNodes = (node) => {
    if (node.children.length > 0) {
      if (node.children.length === 1) {
        // if there is only one child, insert an empty node between the current node and the next node.
        // This gives the vertical line the correct length.
        const onlyChild = node.children[0];
        return (
          <>
            <TreeNode key={node.reference} label={<StyledNode {...{ clientId: client_id, node }} />}>
              <TreeNode key={`${onlyChild.reference}-empty-node`} label={emptyNode}>
                <TreeNode
                  key={onlyChild.reference}
                  label={<StyledNode clientId={onlyChild.client_id} node={onlyChild} />}
                />
              </TreeNode>
            </TreeNode>
          </>
        );
      }
      return (
        <TreeNode key={node.reference} label={<StyledNode {...{ clientId: client_id, node }} />}>
          {node.children.map((childNode) => returnChildNodes(childNode))}
        </TreeNode>
      );
    }

    return <TreeNode key={node.reference} label={<StyledNode {...{ clientId: client_id, node }} />} />;
  };

  const hierarchyChart = (
    <Tree
      lineWidth={'2px'}
      lineColor={'#b517ff'}
      lineBorderRadius={'0'}
      label={<StyledNode node={structureData} {...{ clientId: client_id }} />}
    >
      {structureData &&
        structureData.children.length > 0 &&
        structureData.children.map((childNode) => returnChildNodes(childNode))}
    </Tree>
  );

  return <>{isLoading ? <Loading /> : hierarchyChart}</>;
};

export default structure;
