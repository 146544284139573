import * as React from 'react';

import PortfolioData from './PortfolioData';

export default function presenter() {
  return (
    <div className="platform-panel">
      <PortfolioData />
    </div>
  );
}
