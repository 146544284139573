import * as React from 'react';

const classNames = require('classnames');

import Conditions from '../Conditions';

import ReactSelect from 'components/shared/forms/ReactSelect';

import { UploadTemplateAdditionalAttribute } from 'javascript/models';
import Context from '../../Context';

interface IProps {
  index: number;
  uploadTemplateAdditionalAttribute: UploadTemplateAdditionalAttribute;
  handleDestroyAttribute(attributeId): void;
}

export default function attribute(props: IProps) {
  const { handleDestroyAttribute, index } = props;

  const context = React.useContext(Context);
  const { attributeOptions } = context.props;

  const [uploadTemplateAdditionalAttribute, setUploadTemplateAdditionalAttribute] = React.useState(
    props.uploadTemplateAdditionalAttribute,
  );

  function handleValueChange(selectedOption) {
    uploadTemplateAdditionalAttribute.rowValue = selectedOption.value;
    const updatedAttribute = uploadTemplateAdditionalAttribute.dup();
    setUploadTemplateAdditionalAttribute(updatedAttribute);
    updateAttribute();
  }

  function handleRadioChange(event) {
    uploadTemplateAdditionalAttribute.row = event.target.value;
    const updatedAttribute = uploadTemplateAdditionalAttribute.dup();
    setUploadTemplateAdditionalAttribute(updatedAttribute);
    updateAttribute();
  }

  async function updateAttribute() {
    const success = await uploadTemplateAdditionalAttribute.save();
  }

  function handleDestroy() {
    handleDestroyAttribute(uploadTemplateAdditionalAttribute.id);
  }

  const options = [
    { label: 'Use Sheet Name', value: 'sheet_name' },
    { label: 'Use Workbook Name', value: 'workbook_name' },
  ];

  const formattedOptions = options.map((option) => {
    return (
      <div key={option.value} className="pretty pretty--large pretty--with-label p-round">
        <input
          type="radio"
          name={`option_${index}`}
          id={`option_${index}`}
          value={option.value}
          onChange={handleRadioChange}
          defaultChecked={uploadTemplateAdditionalAttribute.row === option.value}
        />
        <div className="state p-blue-circle">
          <label className="text-white">{option.label}</label>
        </div>
      </div>
    );
  });

  let selectedLabel = '';
  let selectedOption = null;
  if (uploadTemplateAdditionalAttribute.rowValue) {
    selectedLabel = attributeOptions.find((option) => {
      return option.value === uploadTemplateAdditionalAttribute.rowValue;
    }).label;
    selectedOption = { value: uploadTemplateAdditionalAttribute.rowValue, label: selectedLabel };
  }

  return (
    <div>
      <div className="card" style={{ width: '250px', minHeight: '300px' }}>
        <div className="platform-content">
          <div className="card__inner">
            <h4
              className="card__title card__title--small"
              style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
            >
              <span className="small-uppercase">Attribute {index + 1}</span> <br /> <br />
            </h4>
            <div className="mar-v-1">
              <ReactSelect
                id=""
                maxMenuHeight={200}
                name=""
                options={attributeOptions}
                handleChange={handleValueChange}
                theme="dark"
                value={selectedOption}
              />
            </div>
            <div className="mar-v-2">
              <label htmlFor="" className="form__label">
                Attribute Option
              </label>
              <div className="frow frow--gutters">{formattedOptions}</div>
            </div>
            <div className="mar-v-2">
              <div className="frow frow--justify-center">
                <Conditions
                  conditionable={uploadTemplateAdditionalAttribute}
                  conditionOptions={[{ label: 'Match Condition', value: 'match_condition' }]}
                  conditionableType="UploadTemplateAdditionalAttribute"
                />
                <span className="text-white mar-h-1">-</span>
                <span className="link link--secondary" onClick={handleDestroy}>
                  Remove
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
