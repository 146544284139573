import * as React from 'react';
import { sanitize } from 'dompurify';
import lodashStartcase from 'lodash.startcase';

export default function table({ bulkImport }) {
  const { data } = bulkImport.preview;

  const tableHeaders = data[0].map((portfolioHash, index) => {
    return (
      <th key={index} style={{ minWidth: '100px' }}>
        {lodashStartcase(portfolioHash.attribute)}
      </th>
    );
  });

  const tableRows = data.map((portfolio, index) => {
    const tableCells = portfolio.map((portfolioHash, innerIndex) => {
      return (
        <td
          key={`${portfolioHash.attribute}_${innerIndex}`}
          dangerouslySetInnerHTML={{ __html: sanitize(portfolioHash.attribute_value) }}
        />
      );
    });

    return <tr key={index}>{tableCells}</tr>;
  });

  return (
    <div style={{ overflowX: 'scroll' }}>
      <table className="styled-table">
        <thead>
          <tr>{tableHeaders}</tr>
        </thead>
        <tbody>{tableRows}</tbody>
      </table>
    </div>
  );
}
