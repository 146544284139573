import * as React from 'react';

import GraphitiTableWrapper from 'components/shared/GraphitiTableWrapper';

import columns from './table/columns';

import { ManagerUser } from 'javascript/models';

interface IProps {
  newPath?: string;
  scope?: { [key: string]: string | { [key: string]: string } };
}

export default function table(props: IProps) {
  const { newPath } = props;

  let scope = ManagerUser.includes([]);

  if (props.scope) {
    scope = scope.where(props.scope);
  }

  return (
    <GraphitiTableWrapper
      scope={scope}
      columns={columns()}
      hasHeader
      isSearchable
      newPath={newPath}
      newText="Create User"
      searchPlaceholder="Search by name or email"
      title="Users"
      identifier="managerUser:index"
    />
  );
}
