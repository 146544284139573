import * as React from 'react';

import Loading from 'components/shared/Loading';
import FormRow from 'components/shared/forms/FormRow';
import ReactSelect from 'components/shared/forms/ReactSelect';
import ISelectOption, { ISelectProps } from 'components/interfaces/ISelectOption';
import { ISecurityAttributes } from '../Form';
import { Security } from 'javascript/models';
import eventSetProperty from 'javascript/helpers/eventSetProperty';

export interface ISecurityInformationProps {
  clusterProps: ISelectProps;
  handleInfoSubmit: () => void;
  handleOverwriteClassificationsChange: (event: any) => void;
  overwriteClassifications: boolean;
  isSubmitting: boolean;
  security: Security;
  securityType: string;
}

export default function securityInformation(props: ISecurityInformationProps) {
  const {
    clusterProps,
    handleInfoSubmit,
    handleOverwriteClassificationsChange,
    overwriteClassifications,
    isSubmitting,
    security,
    securityType,
  } = props;

  const isinOnChange = eventSetProperty(security, 'isin');
  const nameOnChange = eventSetProperty(security, 'name');
  const shortNameOnChange = eventSetProperty(security, 'shortName');

  if (!security) return <Loading />;

  const isinInput = (
    <>
      <div className="frow col-sm-1-2 pad-l-2">
        <label htmlFor="security_isin" className="form__label">
          ISIN
        </label>
        <input
          className="pad-v-0 pad-h-1"
          style={{ minHeight: '38px' }}
          type="text"
          placeholder="e.g. abcdefg..."
          name="isin"
          defaultValue={security.isin || ''}
          onChange={isinOnChange}
        />
      </div>

      <div className="frow frow--row-end col-sm-2-2">
        <div className="col-sm-1-2 pad-l-2">
          <p>ISINs must be 12 charaters long and unique.</p>
        </div>
      </div>
    </>
  );

  return (
    <fieldset className="form__fieldset pad-b-1">
      <h4 className="form__fieldset-label">General Information</h4>
      <div className="frow">
        <div className="frow col-sm-1-1">
          <div className="frow col-sm-1-2 pad-r-2">
            <label htmlFor="security_name" className="form__label">
              Security Name
            </label>
            <input
              className="pad-v-0 pad-h-1"
              style={{ minHeight: '38px' }}
              type="text"
              placeholder="e.g. abcdefg..."
              name="name"
              defaultValue={security.name || ''}
              onChange={nameOnChange}
            />
          </div>
          <div className="frow col-sm-1-2 pad-l-2">
            <label htmlFor="security_cluster" className="form__label">
              Security Cluster
            </label>
            <ReactSelect {...{ ...clusterProps }} />
          </div>
        </div>
        <div className="frow frow--justify-between frow--items-center col-mc-1-1 mar-t-3">
          <div className="frow col-sm-1-2 pad-r-2">
            <label htmlFor="security_name" className="form__label">
              Security Short Name
            </label>
            <input
              className="pad-v-0 pad-h-1"
              style={{ minHeight: '38px' }}
              type="text"
              placeholder="e.g. abcdefg..."
              name="shortName"
              defaultValue={security.shortName || ''}
              onChange={shortNameOnChange}
            />
          </div>
          {securityType === 'manual' && isinInput}
        </div>
        <div className="frow col-sm-1-1 mar-t-3">
          <div className="col-mc-1-2">
            <label htmlFor="security_overwrite_classifications" className="form__label">
              {' '}
              Security Overwrite Classifications
            </label>
            <div className="pretty p-icon">
              <input
                type="checkbox"
                name="security[overwrite_classifications]"
                checked={overwriteClassifications}
                onChange={handleOverwriteClassificationsChange}
              />
              <div className="state p-blue">
                <i className="icon-tick icon icon-push-down-small" />
                <label className="text-white">Overwrite Classifications</label>
              </div>
            </div>
          </div>
          <div className="frow frow--justify-end col-mc-1-2 mar-t-2">
            <button
              className="button button--compact mar-r-2"
              disabled={isSubmitting}
              onClick={handleInfoSubmit} // Don't return out of this
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </fieldset>
  );
}
