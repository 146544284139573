import React, { useEffect, useState } from 'react';
import { Administrator } from 'javascript/models';
import Loading from 'components/shared/Loading';

interface Props {
  administratorId: number;
}

const Photo = ({ administratorId }: Props) => {
  const [administrator, setAdministrator] = useState<Administrator>();
  const [loading, setLoading] = useState(true);
  const [photo, setPhoto] = useState<string>('');

  const getAdministrator = async () => {
    setAdministrator((await Administrator.find(administratorId)).data);
    setLoading(false);
  };

  useEffect(() => {
    getAdministrator();
  }, [administratorId]);

  useEffect(() => {
    if (administrator) {
      setPhoto(administrator.reportPhoto);
    }
  }, [administrator]);

  const updateAdmin = async (_event) => {
    administrator.reportPhoto = photo;
    await administrator.save({ displayQueryResult: true });
  };

  const handleChange = (event) => {
    setPhoto(event.target.value);
  };

  const image = (shape) => (
    <img
      src={photo}
      alt="Administrator"
      style={{ height: '100%', width: '100%', borderRadius: shape === 'circle' ? '100%' : 0, objectFit: 'cover' }}
    />
  );

  if (loading) {
    return <Loading />;
  } else {
    return (
      <div className="platform-panel">
        <header className="platform-panel__header">
          <h3 className="platform-panel__title">Administrator Report Photo</h3>
        </header>

        <div className="platform-content--padding-all">
          <div className="frow">
            <h4 className="subtitle-two text-white">Photo URL</h4>
            <input name="report_photo" type="text" value={photo} onChange={handleChange} />

            <div className="frow frow--row mar-t-4">
              {photo && (
                <>
                  <div style={{ height: 100, width: 100, marginRight: 10 }}>{image('circle')}</div>
                  <div style={{ height: 100, width: 100 }}>{image('square')}</div>
                </>
              )}
              {!photo && <div>No url provided</div>}
            </div>
          </div>

          <div className="frow frow--justify-start mar-t-4">
            <button className="button" onClick={updateAdmin}>
              Save
            </button>
          </div>
        </div>
      </div>
    );
  }
};

export default Photo;
