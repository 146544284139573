import * as React from 'react';

const reactRailsUJS = require('react_ujs');

import Presenter from './trackedJobs/Presenter';

import { Administrator } from 'javascript/models';

export default function trackedJobs({ administratorId }) {
  const [isShowing, setIsShowing] = React.useState(sessionStorage.getItem('trackedJobs:visible') === 'true');
  const [trackedJobs, setTrackedJobs] = React.useState([]);
  const [outstandingJobs, setOutstandingJobs] = React.useState(0);

  function handleClick() {
    sessionStorage.setItem('trackedJobs:visible', isShowing ? 'false' : 'true');
    setIsShowing(!isShowing);
  }

  React.useEffect(() => {
    if (isShowing) {
      getData();
    }
  }, [isShowing]);

  React.useEffect(() => {
    document.addEventListener('trackedJob:new', getData);
    // Because of turbolinks the clean up is never run. So the event is never removed. This means we can be left with
    // like 20 of these listeners on the page. We are explicitly unmounting this component
    // On before-render to avoid this
    document.addEventListener('turbolinks:before-render', () => {
      reactRailsUJS.unmountComponents('.job-tracker');
    });
    getData();
  }, []);

  async function getData() {
    const { data } = await Administrator.select([])
      .selectExtra(['outstanding_tracked_jobs'])
      .where({ tracked_jobs: { viewable: { match: true } } })
      .includes(['tracked_jobs'])
      .find(administratorId);

    setOutstandingJobs(data.outstandingTrackedJobs);
    setTrackedJobs(data.trackedJobs);

    if (!isShowing) return;
    const runningJobs = data.trackedJobs.filter((job) => ['queued', 'processing'].includes(job.status));

    if (runningJobs.length > 0) {
      setTimeout(() => {
        getData();
      }, 10000);
    }
  }

  if (trackedJobs.length < 1) return null;

  return <Presenter {...{ handleClick, isShowing, outstandingJobs, trackedJobs }} />;
}
