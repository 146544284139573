import * as React from 'react';

import Entity from './Entity';

import { isLoading } from 'components/shared/HigherOrderComponents';

interface IProps {
  entityId: number;
  isLoading: boolean;
  structure: any;
}

function entityLevels(props: IProps) {
  const { entityId, structure } = props;

  const levels = Object.keys(structure).map((key, index) => {
    return (
      <div className="platform-content platform-content--padding-all platform-content--border-bottom" key={index}>
        <div className="frow frow--items-center">
          <div className="col-mc-1-1">
            <div className="frow frow--gutters-2x frow--items-center frow--justify-center">
              {structure[key].map((entity) => (
                <Entity key={entity.entity.id} entity={entity} entityId={entityId} />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  });

  return <div>{levels}</div>;
}

export default isLoading()(entityLevels);
