import * as React from 'react';

import useVisibility from 'components/shared/customHooks/useVisibility';

import Button from 'components/shared/forms/Button';
import CustomModal from 'components/shared/CustomModal';
import FormRow from 'components/shared/forms/FormRow';
import QuarterPicker from 'components/shared/forms/QuarterPicker';
import ReactSelect from 'components/shared/forms/ReactSelect';
import SliceAndDice from './create/SliceAndDice';

import { Extract } from 'javascript/models';
import ISelectOption from 'components/interfaces/ISelectOption';
import { createAlert } from 'components/shared/Utils';
import { Client } from 'javascript/models';

interface IProps {
  clientOptions: ISelectOption[];
  companyId: string;
  currencyOptions: ISelectOption[];
  extractTypes: ISelectOption[];
  riskOptions: ISelectOption[];
  serviceTypeOptions: ISelectOption[];
  startQuarter: string;
  handleNewExtract(): void;
}

export default function create(props: IProps) {
  const {
    clientOptions,
    companyId,
    currencyOptions,
    extractTypes,
    handleNewExtract,
    riskOptions,
    serviceTypeOptions,
    startQuarter,
  } = props;

  const { isOpen, handleOpen, handleClose } = useVisibility(false);
  const [extractType, setExtractType] = React.useState();
  const [option, setOption] = React.useState();
  const [navOneClientOptions, setNavOneClientOptions] = React.useState<ISelectOption[]>([]);

  function handleExtractTypeChange(selectedOption) {
    setExtractType(selectedOption.value);
  }

  function handleDateChange(selectedOption) {
    setOption({ review_date: selectedOption });
  }

  function handleClientChange(selectedOption) {
    setOption({ client_id: selectedOption.value });
  }

  function handleOptionChange(selectedOption) {
    setOption(selectedOption.value);
  }

  async function handleSubmit() {
    if (!extractType || !option) return;

    const extract = new Extract({ extractType, options: option });
    const success = extract.save();

    if (success) {
      setExtractType(null);
      setOption(null);
      handleClose();
      handleNewExtract();
    } else {
      createAlert('error', 'Ensure all required fields are completed', false);
    }
  }

  const defaultType = extractType ? extractTypes.find((extract) => extract.value === extractType) : null;

  const getNavOneClients = async () => {
    const { data: clients } = await Client.where({ adminSystem: 'nav_one' }).all();
    setNavOneClientOptions(clients.map((client) => ({ label: client.name, value: client.id })));
  }

  React.useEffect(() => {
    getNavOneClients();
  }, []);

  function renderRelevantOptions() {
    if (!extractType) return;

    if (extractType === 'enhance_list') {
      return (
        <FormRow>
          <QuarterPicker
            defaultValue={option}
            id="options"
            name="extract[options]"
            theme="light"
            label="Select a Quarter"
            startDate={startQuarter}
            handleChange={handleDateChange}
            reverseDates
          />
        </FormRow>
      );
    }

    if (extractType === 'client_status') {
      return (
        <FormRow>
          <label htmlFor="extract_options" className="form__label">
            Client
          </label>
          <ReactSelect
            options={clientOptions}
            id="extract_options"
            name="extract[options]"
            theme="light"
            handleChange={handleClientChange}
          />
        </FormRow>
      );
    }

    if (extractType === 'slice_and_dice') {
      return (
        <SliceAndDice
          {...{
            clientOptions,
            companyId,
            currencyOptions,
            handleOptionChange,
            riskOptions,
            serviceTypeOptions,
            startQuarter,
          }}
        />
      );
    }

    if (extractType === 'nav_one') {
      return(
        <FormRow>
          <label htmlFor="extract_options" className="form__label">Client</label>
          <ReactSelect
            options={navOneClientOptions}
            id="extract_options"
            name="extract[options]"
            theme="light"
            handleChange={handleClientChange}
            />
        </FormRow>
      );
    }
  }

  return (
    <div>
      <button className="button button--secondary button--compact" onClick={handleOpen}>
        New Extract
      </button>
      <CustomModal isOpen={isOpen} title="Create Extract" handleClose={handleClose} modifiers={['visible-overflow']}>
        <div className="modal__content">
          <div className="form form--light">
            <div className="frow frow--gutters">
              <FormRow>
                <label htmlFor="" className="form__label">
                  Extract Type
                </label>
                <ReactSelect
                  defaultValue={defaultType}
                  options={extractTypes}
                  id="extract_type"
                  name="extract[extract_type]"
                  theme="light"
                  handleChange={handleExtractTypeChange}
                />
              </FormRow>
              {renderRelevantOptions()}
              <div className="col-mc-1-1">
                <Button
                  buttonClasses="button--light-themed"
                  text="Create"
                  isDisabled={!extractType || !option}
                  handleClick={handleSubmit}
                />
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );
}
