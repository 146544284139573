import * as React from 'react';

import { platformReviewsPath } from 'javascript/application/ts_routes';

import lastDateInQuarter from 'components/shared/lastDateInQuarter';
import ReactPieChart from 'components/shared/charting/recharts/ReactPieChart';
import InfoBox from '../../../dashboard/reviewOverview/InfoBox';

const classNames = require('classnames');
const numeral = require('numeral');

interface IProps {
  admin: string;
  client_type: string;
  companyId: number;
  data: any;
  isLoading: boolean;
  quarter: number;
  totalCount: number;
  totalValue: number;
  valueData: any;
  year: number;
}

export default function presenter(props: IProps) {
  const { admin, client_type, companyId, data, isLoading, quarter, totalCount, totalValue, valueData, year } = props;

  const infoBoxes = data
    .filter((data) => data.overview)
    .map((dataObject, index) => {
      const contentClasses = classNames('platform-panel__content', {
        'platform-panel__content--right-bordered': index + 1 !== data.filter((data) => data.overview).length,
      });
      return (
        <div className="col-md-1-5" key={index}>
          <div className={contentClasses}>
            <InfoBox {...{ admin, client_type, companyId, dataObject, isLoading, totalCount, quarter, year }} />
          </div>
        </div>
      );
    });

  const valueInfoBoxes = valueData.map((dataObject, index) => {
    const contentClasses = classNames('platform-panel__content', {
      'platform-panel__content--right-bordered': index + 1 !== valueData.length,
    });
    return (
      <div className="col-md-1-5" key={index}>
        <div className={contentClasses}>
          <InfoBox
            {...{ admin, client_type, companyId, dataObject, isLoading, quarter, year }}
            totalCount={totalValue}
            isCurrency
            currencySymbol="£"
          />
        </div>
      </div>
    );
  });

  const link = platformReviewsPath({
    filters: {
      current_owner_id: [admin],
      end_date: [lastDateInQuarter(quarter, year)],
      'portfolios.client_type': [client_type],
    },
  });

  function renderTitle() {
    if (quarter && year) {
      return `Quarter ${quarter} ${year} Overview`;
    }
    return 'Current Quarter Overview';
  }

  return (
    <div>
      <div className="platform-panel">
        <header className="platform-panel__header">
          <div className="frow frow--items-center frow--justify-between">
            <h3 className="platform-panel__title">{renderTitle()}</h3>
            <a className="button button--secondary button--compact" href={link}>
              View all <i className="icon-arrow-right-top icon-0-8x mar-l-1" />
            </a>
          </div>
        </header>
        <div className="frow">
          <div className="col-md-2-5">
            <div className="platform-panel__content platform-panel__content--right-bordered">
              <ReactPieChart
                {...isLoading}
                data={data.filter((data) => data.overview)}
                height={380}
                labelText={totalCount}
                noData={!totalCount || totalCount === 0}
              />
            </div>
          </div>
          {infoBoxes}
        </div>
      </div>
      <div className="platform-panel">
        <header className="platform-panel__header">
          <div className="frow frow--items-center frow--justify-between">
            <h3 className="platform-panel__title">{renderTitle()}</h3>
            <a className="button button--secondary button--compact" href={link}>
              View all <i className="icon-arrow-right-top icon-0-8x mar-l-1" />
            </a>
          </div>
        </header>
        <div className="frow">
          <div className="col-md-2-5">
            <div className="platform-panel__content platform-panel__content--right-bordered">
              <ReactPieChart
                {...isLoading}
                data={valueData}
                height={380}
                labelText={`£${numeral(totalValue).format('0,0')}`}
                noData={!totalValue || totalValue === 0}
              />
            </div>
          </div>
          {valueInfoBoxes}
        </div>
      </div>
    </div>
  );
}
