import * as React from 'react';

const classNames = require('classnames');

import Button from 'components/shared/forms/Button';
import CustomModal from 'components/shared/CustomModal';

import useVisibility from 'components/shared/customHooks/useVisibility';

import {
  ReportTemplateSection,
  ReportTemplatesReportTemplateSection,
} from 'javascript/models';
import Context from '../Context';

export default function emptySection({ canEdit, rowId, sectionIndex }) {
  const context = React.useContext(Context);
  const { getData } = context.actions;
  const { isOpen, handleClose, handleOpen } = useVisibility(false);

  const [isSaving, setIsSaving] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [selectedComponent, setSelectedComponent] = React.useState();

  React.useEffect(() => {
    getSections();
  }, []);

  async function getSections() {
    const { data } = await ReportTemplateSection.per(999).all();

    const options = data.map((section) => {
      return { label: section.name, value: section.id };
    });

    setOptions(options);
  }

  const editableSectionClasses = classNames(
    { 'editable-section': canEdit },
    { 'editable-section--disabled': !canEdit },
  );

  async function handleSave() {
    setIsSaving(true);
    const joinObject = new ReportTemplatesReportTemplateSection({
      position: sectionIndex + 1,
      report_template_row_id: rowId,
      report_template_section_id: selectedComponent,
    });

    const success = await joinObject.save();

    if (success) {
      setIsSaving(false);
      handleClose();
      getData();
    }
  }

  const componentOptions = options.map((option, index) => {
    function handleSelect() {
      setSelectedComponent(option.value);
    }

    return (
      <div className="col-mc-1-3" key={`${option.name}_${index}`}>
        <div className="pretty pretty--large pretty--with-label p-round">
          <input
            type="radio"
            name="component"
            value={option.value}
            checked={selectedComponent === option.value}
            onChange={handleSelect}
          />
          <div className="state p-blue-circle">
            <label className="">{option.label}</label>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className={editableSectionClasses} style={{ height: '100%', width: '100%', minHeight: '34px' }}>
      <div className="editable-section__popout">
        <div className="button button--icon" onClick={handleOpen}>
          <i className="icon-plus icon-fw" />
        </div>
      </div>
      <CustomModal isOpen={isOpen} handleClose={handleClose} title="Edit Section">
        <div className="modal__content" style={{ minHeight: '300px' }}>
          <div className="form form--light">
            <div className="frow frow--gutters">
              <div className="col-mc-1-1">
                <label className="form__label">Component</label>
                <div className="frow frow--gutters">{componentOptions}</div>
              </div>
              <div className="col-mc-1-1">
                <Button
                  buttonClasses="button button--light-themed"
                  handleClick={handleSave}
                  text="Save"
                  isSubmitting={isSaving}
                  submittingText="Updating Report"
                  isDisabled={!selectedComponent}
                />
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );
}
