import { addDays, addMonths, addQuarters, endOfMonth, endOfQuarter, subQuarters } from 'date-fns';

export default function dateRange(startDate, stopDate) {
  const dateArray = [];
  let currentDate = startDate;
  while (currentDate <= stopDate) {
    dateArray.push(currentDate);
    currentDate = addDays(currentDate, 1);
  }
  return dateArray;
}

export const quarterDateRange = (startDate: Date, stopDate: Date, inclusive = true) => {
  const dateArray: Date[] = [];
  const targetDate = inclusive ? stopDate : endOfQuarter(subQuarters(stopDate, 1));
  let currentDate = endOfQuarter(startDate);
  while (currentDate <= targetDate) {
    dateArray.push(currentDate);
    currentDate = endOfQuarter(addQuarters(currentDate, 1));
  }

  return dateArray;
};

export function monthDateRange(startDate: Date, stopDate: Date): Date[] {
  const dateArray = [];
  let currentDate = endOfMonth(startDate);
  while (currentDate <= endOfMonth(stopDate)) {
    dateArray.push(currentDate);
    currentDate = endOfMonth(addMonths(currentDate, 1));
  }
  return dateArray;
}
