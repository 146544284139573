import * as React from 'react';

import OutsideClickHandler from 'react-outside-click-handler';

import QuarterSelect from '../../dashboard/reviewFilters/QuarterSelect';
import ClientTypeSelect from '../../dashboard/reviewFilters/ClientTypeSelect';
import UserSelect from '../../dashboard/reviewFilters/UserSelect';

interface IProps {
  currentQuarter: number;
  currentYear: number;
  jurisdictions: { display: string; value: string }[];
  selectableAdmins: { display: string; icon: string; value: number }[];
  selectableClientTypes: { display: string; icon: string; value: number }[];
  selectableQuarters: { display: string; value: string }[];
}

export default function reviewFilters(props: IProps) {
  const { currentQuarter, currentYear, selectableAdmins, selectableClientTypes, selectableQuarters } = props;

  const [selectedClientType, setSelectedClientType] = React.useState('*');
  const [selectedUser, setSelectedUser] = React.useState<string | number>('*');
  const [isUserSelectOpen, setIsUserSelectOpen] = React.useState(false);
  const [selectedQuarter, setSelectedQuarter] = React.useState({ quarter: currentQuarter, year: currentYear });
  const [isQuarterSelectOpen, setIsQuarterSelectOpen] = React.useState(false);

  React.useEffect(() => {
    dispatchEvent();
  }, [selectedClientType, selectedUser, selectedQuarter]);

  function handleOutsideQuarterSelectClick() {
    setIsQuarterSelectOpen(false);
  }

  function handleQuarterSelectOpen() {
    setIsQuarterSelectOpen(!isQuarterSelectOpen);
  }

  function handleQuarterSelect(quarter: number, year: number) {
    setSelectedQuarter({ quarter, year });
    setIsQuarterSelectOpen(false);
  }

  function handleClientTypeSelect(value) {
    setSelectedClientType(value);
  }

  function handleUserSelectOpen() {
    setIsUserSelectOpen(!isUserSelectOpen);
  }

  function handleUserSelect(value: number) {
    setSelectedUser(value);
    setIsUserSelectOpen(false);
  }

  function dispatchEvent() {
    const customEvent = new CustomEvent('dropdownFilters:change', {
      detail: {
        ...selectedQuarter,
        admin: selectedUser,
        client_type: selectedClientType,
      },
    });
    document.dispatchEvent(customEvent);
  }

  return (
    <div className="frow frow--gutters-2x frow--items-center">
      <div>
        <p className="subtitle-two">Time Period</p>
        <OutsideClickHandler onOutsideClick={handleOutsideQuarterSelectClick}>
          <QuarterSelect
            {...{ currentQuarter, currentYear, isQuarterSelectOpen, selectedQuarter, selectableQuarters }}
            handleQuarterSelect={handleQuarterSelect}
            handleQuarterSelectOpen={handleQuarterSelectOpen}
          />
        </OutsideClickHandler>
      </div>
      <div>
        <p className="subtitle-two">View</p>
        <UserSelect {...{ isUserSelectOpen, handleUserSelect, handleUserSelectOpen, selectableAdmins, selectedUser }} />
      </div>
      <div>
        <p className="subtitle-two">Client Type</p>
        <ClientTypeSelect
          handleClientTypeSelect={handleClientTypeSelect}
          selectedClientType={selectedClientType}
          selectableClientTypes={selectableClientTypes}
        />
      </div>
    </div>
  );
}
