import * as React from 'react';

import FormRow from 'components/shared/forms/FormRow';
import Context from '../Context';

const charges = () => {
  const context = React.useContext(Context);
  const { portfolio } = context.state;
  const { amc, ter, ongoingChargesFigure } = portfolio.portfolioMandate;

  return (
    <fieldset className="form__fieldset">
      <h4 className="form__fieldset-label">Charges</h4>
      <div className="frow frow--gutters">
        <FormRow>
          <label htmlFor="risk" className="form__label">
            AMC
          </label>
          <input type="text" name="portfolio[portfolio_mandate_attributes][amc]" defaultValue={amc} />
        </FormRow>
        <FormRow>
          <label htmlFor="risk" className="form__label">
            TER
          </label>
          <input type="text" name="portfolio[portfolio_mandate_attributes][ter]" defaultValue={ter} />
        </FormRow>
        <FormRow>
          <label htmlFor="risk" className="form__label">
            OCF
          </label>
          <input
            type="number"
            step={0.01}
            name="portfolio[portfolio_mandate_attributes][ongoing_charges_figure]"
            defaultValue={ongoingChargesFigure}
          />
        </FormRow>
      </div>
    </fieldset>
  );
};

export default charges;
