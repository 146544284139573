import * as React from 'react';

import ReactTooltip from 'react-tooltip';
import startcase from 'lodash.startcase';
import truncate from 'lodash.truncate';
import { HeadingThree, SubtitleTwo } from 'components/shared/Typography';
import { Portfolio } from 'javascript/models/portal/Portfolio';
import { format } from 'date-fns';
import Loading from 'components/shared/Loading';

export default function overview({ client, clientId, selectedDate }) {
  const { fee_currency, jurisdiction, live_portfolios, relationship_manager, region } = client;

  const [isLoading, setIsLoading] = React.useState(true);
  const [meta, setMeta] = React.useState<any>();

  React.useEffect(() => {
    setIsLoading(true);
    getPortfolios().then(() => setIsLoading(false));
  }, [selectedDate]);

  async function getPortfolios() {
    const scope = Portfolio.per(0)
      .where({ review_date: format(selectedDate, 'YYYY-MM-DD'), status: 'live', client_id: clientId })
      .stats({ total: 'count', count: ['reviews'] });
    const { meta } = await scope.all();

    setMeta(meta);
  }

  function renderReviewCount() {
    if (isLoading) return <Loading color="white" size="sm" height="36px" width="36px" />;

    return <HeadingThree text={meta.stats.count.reviews} />;
  }

  return (
    <div className="card card--rounded card--dark card--full-height">
      <div className="platform-content platform-content--padding-all h-100">
        <div className="frow frow--gutters">
          <div className="col-sm-1-3 col-lg-1-6">
            <div>
              <div className="frow frow--nowrap">
                <i className="fas fa-chart-line mar-r-1" />
                <SubtitleTwo text="Portfolios" additionalClasses="mar-b-0" />
              </div>
              <div>
                <HeadingThree text={live_portfolios} />
              </div>
            </div>
          </div>
          <div className="col-sm-1-3 col-lg-1-6">
            <div>
              <div className="frow frow--nowrap">
                <i className="fas fa-chart-line mar-r-1" />
                <SubtitleTwo text="Reviews for period" additionalClasses="mar-b-0" />
              </div>
              <div>{renderReviewCount()}</div>
            </div>
          </div>
          <div className="col-sm-1-3 col-lg-1-6">
            <div className="frow frow--nowrap">
              <i className="fas fa-pound-sign mar-r-1" />
              <SubtitleTwo text="Base Currency" additionalClasses="mar-b-0" />
            </div>
            <HeadingThree text={fee_currency ? fee_currency.toUpperCase() : 'TBC'} />
          </div>
          <div className="col-sm-1-3 col-lg-1-6">
            <div>
              <div className="frow frow--nowrap">
                <i className="icon-account mar-r-1" />
                <SubtitleTwo text="Relationship Manager" additionalClasses="mar-b-0" />
              </div>
              <div data-tip={relationship_manager} data-for="client-rm">
                <HeadingThree text={truncate(relationship_manager, { length: 18 })} />
                <ReactTooltip effect="solid" id="client-rm" />
              </div>
            </div>
          </div>
          <div className="col-sm-1-3 col-lg-1-6">
            <div>
              <div className="frow frow--nowrap">
                <i className="fas fa-globe-europe mar-r-1" />
                <SubtitleTwo text="Region" additionalClasses="mar-b-0" />
              </div>
              <div>
                <HeadingThree text={region ? startcase(region) : 'TBC'} />
              </div>
            </div>
          </div>
          <div className="col-sm-1-3 col-lg-1-6">
            <div>
              <div className="frow frow--nowrap">
                <i className="fas fa-map-marker-alt mar-r-1" />
                <SubtitleTwo text="Jurisdiction" additionalClasses="mar-b-0" />
              </div>
              <div>
                <HeadingThree text={jurisdiction ? startcase(jurisdiction) : 'TBC'} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
