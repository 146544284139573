import * as React from 'react';

import {
  platformAccountingDashboardPath,
  platformRelationshipManagersDashboardPath,
  platformRootPath,
} from 'javascript/application/ts_routes';

export interface IDashSwitcherProps {
  managerEnabled: boolean;
  accountingEnabled: boolean;
}

interface IHrefObj {
  className?: string;
  display: string;
  enabled: 'true' | 'false';
  href?: string;
  key?: number;
}

/*
 * 'enabled' property of IHrefObj needs to be a string for destructuring into a React component.
 *   A number would probably also be acceptable. A boolean does work but will throw a warning.
 */
export default function dashSwitcher(props: IDashSwitcherProps) {
  const { managerEnabled, accountingEnabled } = props;

  const userButton: IHrefObj = { display: 'User', enabled: 'true', href: platformRootPath() };
  const managerButton: IHrefObj = { display: 'Manager', enabled: managerEnabled ? 'true' : 'false' };
  const accountingButton: IHrefObj = { display: 'Accounting', enabled: accountingEnabled ? 'true' : 'false' };

  const activePath = (path) => window.location.pathname === path;

  if (managerEnabled) managerButton.href = platformRelationshipManagersDashboardPath();
  if (accountingEnabled) accountingButton.href = platformAccountingDashboardPath();

  const renderATag = (button, idx) => {
    button.key = idx;
    button.className = [
      button.enabled === 'true' ? '' : 'button-group__button--disabled',
      activePath(button.href) ? 'button-group__button--active' : '',
      'button-group__button',
    ].join(' ');

    return <a {...button}>{button.display}</a>;
  };

  return (
    <div className="frow frow--direction-column">
      <p className="subtitle-two">Switch View</p>
      <div className="button-group">{[userButton, managerButton, accountingButton].map(renderATag)}</div>
    </div>
  );
}
