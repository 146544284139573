import * as React from 'react';

import Chart from './Chart';

import Metric from './Metric';
import Table from './Table';

import { changeFromPrevious } from 'components/shared/Utils';

export default function presenter({ id, isLoading, portfolioReturns }) {
  return (
    <div className="platform-panel">
      <header className="platform-panel__header">
        <h3 className="platform-panel__title">Returns</h3>
      </header>
      <div className="platform-content platform-content--padding-all">
        <div className="frow frow--gutters-2x">
          <div className="col-md-1-2">
            <Chart id={id} />
          </div>
          <div className="col-md-1-2">
            <div className="frow frow--gutters">
              <div className="col-tn-1-2">
                <Metric
                  attributes={portfolioReturns}
                  {...{ changeFromPrevious }}
                  format="0,0.0000"
                  name="Return"
                  suffix="%"
                  valueKey="monthReturn"
                  isLoading={isLoading}
                />
              </div>
              <div className="col-tn-1-2">
                <Metric
                  attributes={portfolioReturns}
                  {...{ changeFromPrevious }}
                  format="0,0.0000"
                  name="Overall"
                  suffix="%"
                  valueKey="overallReturn"
                  isLoading={isLoading}
                />
              </div>
            </div>
            <div className="mar-t-3">
              <div className="">
                <div style={{ height: '450px', overflow: 'scroll', paddingRight: '10px' }}>
                  <Table {...{ id }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
