import * as React from 'react';

import Filters from './Filters';
import Loading from 'components/shared/Loading';
import New from './New';
import ReportTemplate from './ReportTemplate';

import {
  platformReportTemplateBucketsPath,
  platformReportTemplatesPath,
} from 'javascript/application/ts_routes';

export default function presenter(props) {
  const { bucketId, canCreate, fetchData, handleFilterChange, isLoading, reportTemplates, reportBuckets } = props;

  function renderTemplates() {
    if (isLoading) return <Loading />;

    if (reportTemplates.length < 1) {
      return (
        <div className="col-mc-1-1">
          <div className="frow frow--centered-column">
            <div>
              <p className="text-white text-small">There are currently no report templates</p>
            </div>
          </div>
        </div>
      );
    }

    return reportTemplates.map((template) => {
      return <ReportTemplate key={template.id} {...{ fetchData, reportBuckets, template }} />;
    });
  }

  function renderNew() {
    if (!canCreate) return;

    return (
      <div className="frow frow--row-end mar-t-2">
        <div>
          <New />
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <section className="platform-panel platform-panel--no-background">
        <div className="frow frow--gutters frow--justify-between frow--items-center">
          <div>
            <div className="frow frow--items-center frow--gutters">
              <div>
                <p className="subtitle-two">{reportTemplates.length} Report Templates</p>
                <h2 className="heading-one text-lh-1">Report Templates</h2>
              </div>
              <div>
                <span className="horizontal-divider hide-tn-inline" />
              </div>
              <div>
                <p className="subtitle-two">Switch Views</p>
                <div className="button-group">
                  <a
                    href={platformReportTemplateBucketsPath()}
                    className={`button-group__button ${bucketId && 'button-group__button--active'}`}
                  >
                    Buckets
                  </a>
                  <a
                    href={platformReportTemplatesPath()}
                    className={`button-group__button ${!bucketId && 'button-group__button--active'}`}
                    style={{ width: '170px' }}
                  >
                    All Templates
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div>
            <Filters {...{ handleFilterChange }} />
          </div>
        </div>
        {renderNew()}
      </section>
      <section className="platform-panel">
        <div className="platform-content platform-content--padding-all">
          <div className="frow frow--gutters">{renderTemplates()}</div>
        </div>
      </section>
    </React.Fragment>
  );
}
