import * as React from 'react';

import { format, lastDayOfQuarter, subMonths } from 'date-fns';

import CustomModal from 'components/shared/CustomModal';
import useVisibility from 'components/shared/customHooks/useVisibility';
import Dropzone from 'components/shared/forms/Dropzone';

interface IProps {
  closeModal: boolean;
  isSubmitting: boolean;
  isSubmittable: boolean;
  handleChange(event): void;
  handleSubmit(event): void;
  handleUploadedFiles(files): void;
}

export default function presenter(props: IProps) {
  const { closeModal, handleChange, handleSubmit, handleUploadedFiles, isSubmitting, isSubmittable } = props;
  const { handleClose, handleOpen, isOpen } = useVisibility(false);

  React.useEffect(() => {
    if (closeModal) {
      handleClose();
    }
  }, [closeModal]);

  const endOfLastQtr = format(lastDayOfQuarter(subMonths(new Date(), 3)), 'YYYY-MM-DD');

  return (
    <div>
      <button className="button button--secondary button--compact" onClick={handleOpen}>
        Upload Valuation
      </button>
      <CustomModal isOpen={isOpen} handleClose={handleClose} title="Upload Valuation">
        <div className="modal__content">
          <form action="" className="form form--light" onSubmit={handleSubmit}>
            <div className="frow frow--gutters">
              <div className="col-mc-1-1">
                <div className="h-100">
                  <Dropzone isSubmitting={isSubmitting} onFileUpload={handleUploadedFiles} />
                </div>
              </div>
              <div className="col-mc-1-1">
                <label htmlFor="" className="form__label">
                  Valuation date
                </label>
                <input type="date" name="valuation[value_date]" onChange={handleChange} defaultValue={endOfLastQtr} />
              </div>
              <div className="col-mc-1-1">
                <button className="button button--light-themed button--full-width" disabled={!isSubmittable}>
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </CustomModal>
    </div>
  );
}
