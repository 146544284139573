import * as React from 'react';

import Form from './notes/Form';
import Comment from '../../../portfolios/show/portfolioData/reviews/show/comments/Comment';

import { isLoading } from 'components/shared/HigherOrderComponents';
import useVisibility from 'components/shared/customHooks/useVisibility';
import CustomModal from 'components/shared/CustomModal';
import { NotesNotable } from 'javascript/models';
import { DataUpload, Note } from 'javascript/models';

interface IProps {
  currentUserId: number;
  dataUpload: DataUpload;
  dataUploadId: number;
  handleNewNote(): void;
}

function notes(props: IProps) {
  const { currentUserId, dataUpload, dataUploadId, handleNewNote } = props;

  const [notes, setNotes] = React.useState([]);

  const { isOpen, handleOpen, handleClose } = useVisibility(false);

  React.useEffect(() => {
    if (dataUpload.notes) {
      setNotes(dataUpload.notes);
    }
  }, [dataUpload]);

  const formattedNotes = notes.reverse().map((note) => {
    return (
      <div key={note.id} style={{ width: '100%' }}>
        <Comment {...{ note }} />
      </div>
    );
  });

  function renderNotes() {
    if (notes.length > 0) {
      return formattedNotes;
    }

    return <p className="mar-v-0 text-small">This review doesn't have any comments yet.</p>;
  }

  async function handleFormSubmit(comment: string) {
    const newNote = new Note({
      body: comment,
      author_id: currentUserId,
      note_type: 'general',
      note_category: 'note',
      status: 'normal',
    });

    const success = await newNote.save();
    if (success) {
      const notesNotable = new NotesNotable({
        note_id: newNote.id,
        notable_type: 'DataUpload',
        notable_id: dataUploadId,
      });

      const notableSuccess = await notesNotable.save();
      if (notableSuccess) {
        handleClose();
        handleNewNote();
      }
    }
  }

  return (
    <React.Fragment>
      <header className="platform-panel__header">
        <div className="frow frow--justify-between frow--items-center">
          <h2 className="subtitle-one text-white">Upload Notes</h2>
          <button className="button button--secondary" onClick={handleOpen}>
            Add Note <i className="icon-plus icon-fw mar-l-1 icon-push-down-1" />
          </button>
        </div>
        <CustomModal isOpen={isOpen} title="Create Note" handleClose={handleClose}>
          <Form {...{ handleFormSubmit }} />
        </CustomModal>
      </header>
      <div className="platform-content platform-content--padding-all">
        <div className="frow frow--gutters">{renderNotes()}</div>
      </div>
    </React.Fragment>
  );
}

export default isLoading()(notes);
