import * as React from 'react';
import { DirectUpload } from 'activestorage';

import useVisibility from 'components/shared/customHooks/useVisibility';
import CustomModal from 'components/shared/CustomModal';
import Dropzone from 'components/shared/forms/Dropzone';
import { createAlert } from 'components/shared/Utils';

import { platformClientFactsheetsPath } from 'javascript/application/ts_routes';
import CsrfToken from 'components/shared/CsrfToken';
import Loading from 'components/shared/Loading';

export default function factsheetUpload({ clientId }) {
  const { isOpen, handleOpen, handleClose } = useVisibility(false);

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isFormSubmitting, setIsFormSubmitting] = React.useState(false);
  const [signedId, setSignedId] = React.useState();

  async function handleUploadedFiles(files) {
    if (files.length < 1) return;

    setIsSubmitting(true);

    files.forEach((file, index) => {
      const upload = new DirectUpload(file, '/rails/active_storage/direct_uploads');
      upload.create((error, blob) => {
        if (error) {
          setIsSubmitting(false);
          createAlert('error', 'There was an error uploading this file', false);
          handleClose();
        } else {
          setIsSubmitting(false);
          const signedId = blob.signed_id;
          setSignedId(signedId);
        }
      });
    });
  }

  function handleFormSubmit() {
    setIsFormSubmitting(true);
  }

  function renderForm() {
    if (isFormSubmitting) {
      return <Loading flexDirection="column" text="This can take a while" textColor="grey" />;
    }

    return (
      <div className="h-100">
        <Dropzone
          acceptedFiles=".xlsx"
          isLarge
          isSubmitting={isSubmitting}
          onFileUpload={handleUploadedFiles}
          shouldShowFiles
        />
      </div>
    );
  }

  return (
    <React.Fragment>
      <div onClick={handleOpen}>Upload Factsheet</div>
      <CustomModal isOpen={isOpen} handleClose={handleClose} title="Upload Factsheet">
        <div className="modal__content">
          <div className="form form--light mar-b-1">
            <div className="frow frow--gutters">
              <div className="col-mc-1-1">{renderForm()}</div>
              <div className="col-mc-1-1">
                <form action={platformClientFactsheetsPath(clientId)} method="post" onSubmit={handleFormSubmit}>
                  <input type="hidden" value={signedId} name="factsheet[upload]" />
                  <input type="hidden" value={CsrfToken()} name="authenticity_token" />
                  <button className="button button--light-themed mar-t-2" disabled={!signedId || isFormSubmitting}>
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </React.Fragment>
  );
}
