import * as React from 'react';

import { ClientLetterOfEngagement } from 'javascript/models';

import { Frow, Column } from 'components/frow';
import DatePicker from 'react-datepicker';
import Dropzone from 'components/shared/forms/Dropzone';
import { DirectUpload } from 'activestorage';

import { format, parse } from 'date-fns';

type Props = {
  latestDocument: ClientLetterOfEngagement | null;
};

const LetterOfEngagement = ({ latestDocument }: Props) => {
  const [signatureDate, setSignatureDate] = React.useState<Date | null>(null);
  const [blobSignedId, setBlobSignedId] = React.useState<string>('');
  const [isProcessing, setIsProcessing] = React.useState<boolean>(false);

  function handleFileUpload(files) {
    if (files.length < 1) return;

    setIsProcessing(true);
    const upload = new DirectUpload(files[0], '/rails/active_storage/direct_uploads');

    upload.create((error, blob) => {
      if (!error) {
        setBlobSignedId(blob.signed_id);
      }
      setIsProcessing(false);
    });
  }

  return (
    <>
      <h4 className="form__fieldset-label">Letter of Engagement</h4>
      <Frow gutterSize={1}>
        <Column>
          {latestDocument && (
            <Frow gutterSize={3} itemAlignment="center" columnContext={{ maxColumns: 5 }}>
              <Column columnSpan={2}>
                <label className="form__label">Latest document</label>
                <div className="text-white text-small">{latestDocument.filename}</div>
                <div className="text-white text-small">
                  Upload date: {format(parse(latestDocument.createdAt), 'YYYY MMM DD [at] HH:mm')}
                </div>
                <div className="text-white text-small">Signature date: {latestDocument.signatureDate}</div>
              </Column>
            </Frow>
          )}

          <Frow gutterSize={3} itemAlignment="center" columnContext={{ maxColumns: 5 }}>
            <Column columnSpan={3}>
              <label className="form__label">Upload New Document</label>
              <Dropzone
                handleRemoveFile={() => setBlobSignedId('')}
                onFileUpload={handleFileUpload}
                multiple={false}
                isSubmitting={isProcessing}
                shouldShowFiles={true}
                theme="dark"
              />
              <input type="hidden" name="client[letters_of_engagement_attributes][0][document]" value={blobSignedId} />
            </Column>
          </Frow>

          <Frow gutterSize={3} itemAlignment="center" columnContext={{ maxColumns: 5 }}>
            <Column columnSpan={2}>
              <label className="form__label">Signature date</label>
              <DatePicker
                name="client[letters_of_engagement_attributes][0][signature_date]"
                id="signature_date"
                selected={signatureDate}
                onChange={(date) => setSignatureDate(date)}
                dateFormat="yyyy/MM/dd"
                disabled={blobSignedId == ''}
                autoComplete="off"
              />
            </Column>

            <Column columnSpan={3} />
          </Frow>
        </Column>
      </Frow>
    </>
  );
};

export default LetterOfEngagement;
