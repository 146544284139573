import * as React from 'react';
import { DirectUpload } from 'activestorage';
import ReactSelect from 'components/shared/forms/ReactSelect';
import Button from 'components/shared/forms/Button';
import ISelectOption from 'components/interfaces/ISelectOption';
import FormFilesDropzone, { IFormFilesDropzoneProps } from 'components/shared/forms/FormFilesDropzone';
import { ClientTile } from 'javascript/models';
import Frow from '../../frow/Frow';

interface IProps {
  isSubmitting?: boolean;
  resource?: ClientTile;
  resourceTypes: ISelectOption[];
  saveButtonText?: string;
  handleChange?(event: any): void;
  handleManualChange(attribute, value): void;
}

const form = (props: IProps) => {
  const { handleManualChange, resource, resourceTypes } = props;
  const [storedFiles, setStoredFiles] = React.useState(resource.image ? [resource.image] : []);

  const optionColor = {
    blog: 'mid-orange',
    general: 'grey-40',
    news: 'mid-aqua',
    professional_bodies: 'mid-blue',
    index_providers: 'dark-red',
    regulators: 'dark-green',
  };

  const numberedResourceTypes = resourceTypes.map((item, index) => {
    const label = `${index + 1}. ${item.label}`;
    const value = item.value;
    return { label, value };
  });

  const formatOptionLabel = ({ label, value }) => (
    <div className="frow frow--direction-row frow--justify-start frow--items-center frow--full-width">
      <div
        className={`option-box option-box--${optionColor[value]}`}
        style={value === 'general' ? { boxShadow: '0 0 4px 0 #7f7f7f' } : {}}
      />
      <div>{label}</div>
    </div>
  );

  const customSelectStyles = {
    control: (provided, state) => ({
      ...provided,
      height: '50px',
    }),
    option: (provided, state) => ({
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      paddingBottom: '16px',
      paddingTop: '16px',
      width: '100%',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      height: '20px !important',
    }),
  };

  const handleSelectChange = (selectedValue) => handleManualChange('resourceType', selectedValue.value);

  React.useEffect(() => {
    handleSelectChange(numberedResourceTypes[0]);
  }, []);

  const onDetatchFile = () => {
    resource.image = null;
    setStoredFiles([]);
  };

  const setLocalStoreFiles = (files) => {
    // FormFilesDropzone expects an array of files.
    // However in this form we are only uploading/removing a single file at a time.
    // This is why we use file = files[0]
    if (!files) return;
    const file = files[0];
    resource.image = file.blob.signed_id;
    setStoredFiles([file]);
  };

  const dropzoneProps: IFormFilesDropzoneProps = {
    disableBottomMargin: true,
    dropzoneProps: {
      acceptedFiles: '.jpeg, .png, .jpg',
      multiple: false,
      theme: 'light',
    },
    fileDisplayHeight: '30px',
    fileDisplayWidth: '30px',
    inputLabel: 'Image',
    inputName: 'image',
    onDetatchFile,
    updateStoredFiles: setLocalStoreFiles,
    showFilePreview: true,
    storedFiles,
  };

  return (
    <div className="frow frow--gutters-2x">
      <div className="col-sm-1-1">
        <div className="frow frow--gutters">
          <div className="col-mc-1-1">
            <label htmlFor="title" className="form__label">
              Name
            </label>
            <input
              className="form__input-grey"
              type="text"
              name="clientResource[name]"
              id="name"
              onChange={props.handleChange}
              defaultValue={resource.name}
              placeholder="E.g. Enhance Blog"
            />
          </div>
          <div className="col-mc-1-1">
            <label htmlFor="title" className="form__label">
              URL
            </label>
            <input
              className="form__input-grey"
              type="text"
              name="clientResource[url]"
              id="url"
              onChange={props.handleChange}
              defaultValue={resource.url}
              placeholder="E.g. https://enhance.group"
            />
          </div>
          <div className="col-mc-1-1">
            <label htmlFor="" className="form__label">
              Choose a Type
            </label>
            <ReactSelect
              theme="dark"
              customSelectStyles={customSelectStyles}
              options={numberedResourceTypes}
              name="clientTile[resourceType]"
              id="resource_type"
              isClearable={false}
              defaultValue={numberedResourceTypes[0]}
              handleChange={handleSelectChange}
              formatOptionLabel={formatOptionLabel}
            />
          </div>
          <div className="col-mc-1-1">
            <Frow direction="column">
              <FormFilesDropzone {...dropzoneProps} />
              <p className="helper-text mar-v-1">Accepted files types are jpg and png</p>
            </Frow>
          </div>
          <div className="col-mc-1-1">
            <label htmlFor="title" className="form__label">
              Description
            </label>
            <input
              className="form__input-grey"
              type="text"
              name="clientResource[description]"
              id="description"
              onChange={props.handleChange}
              defaultValue={resource.description}
              placeholder="E.g. Intelligent insights to optimise performance for our public and private clients."
            />
          </div>
        </div>
      </div>
      <div className="col-mc-2-7 mar-b-2">
        <Button
          buttonClasses="button--full-width button--highlight"
          style={{ minWidth: 'unset' }}
          text={props.saveButtonText ? props.saveButtonText : 'Save'}
          isSubmitting={props.isSubmitting}
          submittingText="Saving"
        />
      </div>
    </div>
  );
};

export default form;
