import * as React from 'react';
import { DataUpload } from 'javascript/models';
import { createAlert } from 'components/shared/Utils';
import Presenter from './upload/Presenter';

export interface IUploadProps {
  managerId: number;
}

const upload = (props: IUploadProps) => {
  const { managerId } = props;

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const handleUploadedFiles = (overwrite: boolean) => async (files) => {
    if (files.length < 1) return;

    const handleFile = async (file) => {
      const dataUpload = new DataUpload();
      dataUpload.managerId = managerId;
      dataUpload.attachment = file.blob.signed_id;
      dataUpload.overwrite = overwrite;

      if (await dataUpload.save({ with: 'manager' })) {
        const customEvent = new CustomEvent('dataUploads:new');
        document.dispatchEvent(customEvent);
        const trackedJobEvent = new CustomEvent('trackedJob:new');
        document.dispatchEvent(trackedJobEvent);

        createAlert('success', `${file.name} was uploaded successfully`, 2500);
      } else {
        createAlert('error', `${file.name} failed to upload`, 2500);
      }
    };

    setIsSubmitting(true);
    Promise.all(files.map(handleFile)).then(() => setIsSubmitting(false));
  };

  return <Presenter {...{ handleUploadedFiles, isSubmitting }} />;
};

export default upload;
