import * as React from 'react';

import pluralize from 'pluralize';

import AssignModal from './AssignModal';

interface IProps {
  administrators: { display: string; value: number }[];
  companyId: number;
  handleReviewsAssigned(): void;
  selection: string[];
}

export default function assignSelection(props: IProps) {
  const { companyId, selection } = props;

  if (selection.length < 1) {
    return null;
  }

  const [isOpen, setIsOpen] = React.useState(false);

  function handleClose() {
    setIsOpen(false);
  }

  return (
    <div className="frow frow--items-center">
      <p className="text-small text-white mar-v-0 mar-r-2">{pluralize('item', selection.length, true)} selected</p>
      <button className="button button--compact button--no-min-width" onClick={() => setIsOpen(true)}>
        Assign
      </button>
      <AssignModal
        administrators={props.administrators}
        companyId={companyId}
        handleClose={handleClose}
        handleReviewsAssigned={props.handleReviewsAssigned}
        isOpen={isOpen}
        selection={selection}
      />
    </div>
  );
}
