import * as React from 'react';
import Context from '../../../../Context';

const headerLogo = require('../../../../../../../../images/logos/plus-divide-white.svg').default;

export default function monitoringHeader() {
  const context = React.useContext(Context);
  const { reportTemplate } = context.state;
  return (
    <header className="pdf-header pdf-header--blue">
      <div>
        <img src={headerLogo} alt="" className="pdf-header__logo" />
        <span className="pdf-header__logo-text">Monitoring</span>
      </div>
      <div className="pdf-header__title-wrap">
        <table className="pdf-hidden-table">
          <tbody>
            <tr>
              <td className="" style={{ width: '34%', verticalAlign: 'top' }}>
                <div className="pdf-tiny-subtitle text-white">
                  Report Type: <span className="pdf-tiny-subtitle--bold text-white">{reportTemplate.reportType}</span>
                </div>
                <div className="pdf-tiny-subtitle text-white">
                  Review Date: <span className="pdf-tiny-subtitle--bold text-white">Date</span>
                </div>
              </td>
              <td style={{ width: '66%' }}>
                <div>
                  <h3 className="pdf-subtitle text-white">{reportTemplate.displayName}</h3>
                  <h1 className="pdf-title text-white">Entity name</h1>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </header>
  );
}
