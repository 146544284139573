import * as React from 'react';

import Presenter from './reviews/Presenter';

import { Review } from 'javascript/models';

interface IProps {
  dashboardPath: string;
  initialDate: string;
  managerId: number;
}

export default function reviews(props: IProps) {
  const { dashboardPath, initialDate, managerId } = props;

  const [selectedDate, setSelectedDate] = React.useState(initialDate);
  const [data, setData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    document.addEventListener('managerPortal:Filters:Change', handleFilterChange);
    return () => {
      document.removeEventListener('managerPortal:Filters:Change', handleFilterChange);
    };
  }, []);

  function handleFilterChange(event) {
    setSelectedDate(event.detail.selectedDate);
  }

  React.useEffect(() => {
    getReviews();
  }, [selectedDate]);

  async function getReviews() {
    const { data } = await Review.select(['upload_errors', 'status', 'data_received_on'])
      .selectExtra(['reference', 'manager_reference', 'client'])
      .where({ manager_id: managerId, end_date: selectedDate })
      .all();

    setIsLoading(false);
    setData(data);
  }

  return <Presenter {...{ dashboardPath, data, isLoading }} />;
}
