import * as React from 'react';

import { UploadTemplateCondition } from 'javascript/models';

interface IProps {
  uploadTemplateCondition: UploadTemplateCondition;
  handleConditionChange(conditions): void;
}

export default function conditions(props: IProps) {
  const { handleConditionChange } = props;

  let initialConditions = [];

  function conditionsExist() {
    return props.uploadTemplateCondition.conditions !== undefined && props.uploadTemplateCondition.conditions !== null;
  }

  if (conditionsExist()) {
    initialConditions = props.uploadTemplateCondition.conditions;
  }

  const [conditions, setConditions] = React.useState(initialConditions);

  React.useEffect(() => {
    if (conditionsExist()) {
      setConditions(props.uploadTemplateCondition.conditions);
    }
  }, [props.uploadTemplateCondition.conditions]);

  React.useEffect(() => {
    handleConditionChange(conditions);
  }, [conditions]);

  function handleAddCondition() {
    const allConditions = [...conditions];
    allConditions.push('');
    setConditions(allConditions);
  }

  const allConditions = conditions.map((condition, index) => {
    function handleConditionChange(event) {
      const newConditions = [...conditions];
      const conditionIndex = newConditions.indexOf(condition);
      newConditions.splice(conditionIndex, 1, event.target.value);
      setConditions(newConditions);
    }

    return (
      <div key={index} style={{ width: '100%' }}>
        <input type="text" placeholder="Add a condition..." onChange={handleConditionChange} defaultValue={condition} />
      </div>
    );
  });

  return (
    <div className="col-mc-1-1">
      <label htmlFor="conditions" className="form__label">
        Condition Strings
      </label>
      <div className="frow frow--direction-column frow--gutters">{allConditions}</div>
      <div className="mar-v-2">
        <div className="frow frow--gutters">
          <div>
            <button
              className="button button--secondary button--secondary-light-themed"
              type="button"
              onClick={handleAddCondition}
            >
              Add another condition string
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
