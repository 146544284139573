import * as React from 'react';

import { filter, propEq } from 'ramda';

import { SecurityClassification } from 'javascript/models';

import Charges from './mandate/Charges';
import DocumentInfo from './mandate/DocumentInfo';
import Objectives from './mandate/Objectives';
import RiskCurrency from './mandate/RiskCurrency';
import StrategicAllocation from './mandate/StrategicAllocation';
import TacticalAllocations from './mandate/TacticalAllocations';
import MandateFlags from './mandate/MandateFlags';

import Context from './Context';
import Loading from 'components/shared/Loading';

const Mandate = () => {
  const context = React.useContext(Context);

  const { portfolio } = context.state;
  const mandate = portfolio.portfolioMandate;

  const mandateHiddenId = () => {
    if (!mandate) return;

    return <input type="hidden" name="portfolio[portfolio_mandate_attributes][id]" value={mandate.id} />;
  };

  const [criterionOptions, setCriterionOptions] = React.useState();

  const getCriterionOptions = async () => {
    setCriterionOptions(await SecurityClassification.allByType({ uiSelectable: true }));
  };

  React.useEffect(() => {
    getCriterionOptions();
  }, []);

  const assetClasses =
    criterionOptions &&
    filter(propEq('source', 'enhance'), criterionOptions['Securities::Classifications::AssetClass']);

  return (
    <React.Fragment>
      {mandateHiddenId()}
      <RiskCurrency />
      <Objectives />
      <Charges />
      <DocumentInfo />
      {assetClasses ? <StrategicAllocation assetClasses={assetClasses} /> : <Loading />}
      {assetClasses ? <TacticalAllocations assetClasses={assetClasses} /> : <Loading />}
      <MandateFlags criterionOptions={criterionOptions} />
    </React.Fragment>
  );
};

export default Mandate;
