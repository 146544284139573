import * as React from 'react';
import { format } from 'date-fns';
import { Frow } from 'components/frow';

export default function columns() {
  return [
    {
      Header: 'Client grouping',
      accessor: 'clientGrouping',
      columnName: 'Client grouping',
      id: 'client-grouping',
    },
    {
      Header: 'Entity',
      accessor: 'entity',
      columnName: 'Entity',
      id: 'entity',
    },
    {
      Cell: (row) => {
        return (
          <>
            {row.original.lettersOfEngagement.map((letterOfEngagment) => {
              return (
                <div className="frow">
                  <a href={letterOfEngagment.documentUrl} className="link link--secondary">
                    {letterOfEngagment.filename}
                  </a>
                </div>
              );
            })}
          </>
        );
      },
      Header: 'Letters of Engagement',
      accessor: 'filename',
      columnName: 'Documents',
      id: 'document_filename',
    },
    {
      Cell: (row) => {
        return (
          <>
            {row.original.lettersOfEngagement.map((letterOfEngagment) => {
              return (
                <div className="frow" style={{ lineHeight: 1.54 }}>
                  {format(letterOfEngagment.createdAt, 'YYYY/MM/DD')}
                </div>
              );
            })}
          </>
        );
      },
      Header: 'Upload date',
      accessor: 'uploadDate',
      columnName: 'Upload date',
      id: 'upload-date',
    },
    {
      Cell: (row) => {
        return (
          <>
            {row.original.lettersOfEngagement.map((letterOfEngagment) => {
              return (
                <div className="frow" style={{ lineHeight: 1.54 }}>
                  {format(letterOfEngagment.signatureDate, 'YYYY/MM/DD')}
                </div>
              );
            })}
          </>
        );
      },
      Header: 'Signature date',
      accessor: 'signatureDate',
      columnName: 'Signature date',
      id: 'signature-date',
    },
    {
      Header: 'Comments',
      accessor: 'comments',
      columnName: 'Comments',
      id: 'comments',
    },
    {
      Cell: (row) => {
        return (
          <a href={row.original.editPath} style={{ display: 'block' }}>
            <div className="rt-tr-clickable-icon">
              <i className="icon-arrow-right-top icon-0-8x" />
            </div>
          </a>
        );
      },
      Header: '',
      sortable: false,
    },
  ];
}
