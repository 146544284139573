import * as React from 'react';

import WorldMap from 'components/shared/charting/WorldMap';

export default function mapVisual({ templateSection }) {
  const countryPercentage = {
    Brazil: 10,
    Canada: 10,
    Germany: 20,
    Spain: 5,
    'United Kingdom': 25,
    'United States of America': 30,
  };

  const continentPercentage = {
    Europe: 50,
    'North America': 40,
    'South America': 10,
  };

  return <WorldMap {...{ continentPercentage, countryPercentage }} />;
}
