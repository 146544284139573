import { Attr, Model } from 'spraypaint';
import { ApplicationRecord } from './ApplicationRecord';

@Model()
export class PortfoliosBenchmark extends ApplicationRecord {
  public static jsonapiType = 'portfolios_benchmarks';
  @Attr() public benchmarkType: string;
  @Attr() public portfolioId: number;
  @Attr() public primaryBenchmark: boolean;
  @Attr() public secondaryBenchmark: boolean;
  @Attr() public tertiaryBenchmark: boolean;
  @Attr() public portfolioBenchmarkId: number;
}
