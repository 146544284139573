import * as React from 'react';

import { User } from 'javascript/models';

import Loading from 'components/shared/Loading';
import Form from './form/Form';

export type TClientOptions = { label: string; value: string }[];
interface IUserForm {
  portalManager: boolean;
  userId: number;
}

const UserForm = ({ portalManager, userId }: IUserForm) => {
  const [user, setUser] = React.useState<User>(new User());
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    (async () => {
      if (userId) await getUser();
      setLoading(false);
    })();
  }, []);

  const getUser = async () => {
    const { data } = await User.includes([{ clients: { roles: 'roleable' }, roles: 'roleable' }]).find(userId);
    setUser(data);
  };

  return loading ? <Loading /> : <Form {...{ portalManager, user }} />;
};

export default UserForm;
