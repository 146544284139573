import * as React from 'react';

import lodashStartcase from 'lodash.startcase';
import { getQuarter } from 'date-fns';
import { SubtitleTwo } from 'components/shared/Typography';
import ReactSelect from 'components/shared/forms/ReactSelect';
import * as ReactTooltip from 'react-tooltip';
import lodashTruncate from 'lodash.truncate';
import useVisibility from 'components/shared/customHooks/useVisibility';
import CustomModal from 'components/shared/CustomModal';
import QuarterIndicator from 'components/shared/QuarterIndicator';
import Settings from '../../show/header/Settings';

const infoBar = ({
  portfolioObject,
  layout,
  layoutDisplay,
  reviewPeriods,
  setCurrentReviewPeriod,
  setItem,
  setLayoutDisplay,
}) => {
  const {
    isOpen: isReorderOpen,
    handleClose: handleReorderClose,
    handleOpen: handleReorderOpen,
  } = useVisibility(false);

  const {
    isOpen: isBenchmarkOpen,
    handleClose: handleBenchmarkClose,
    handleOpen: handleBenchmarkOpen,
  } = useVisibility(false);

  const viewMandateButton = (
    <div>
      <a
        href={portfolioObject.mandatePath}
        rel="noreferrer"
        className="button button--secondary button--no-min-width"
        target="_blank"
      >
        View Mandate
        <i className="icon-arrow-right-top icon-fw icon-0-9x mar-l-1" />
      </a>
    </div>
  );

  const formatBenchmarkType = (benchmarkType: string) => {
    if (benchmarkType.toLowerCase().includes('managers')) {
      return "Manager's Benchmark Proxy".toUpperCase();
    }
    return lodashStartcase(`${benchmarkType}  Benchmark`);
  };

  const customSelectStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: '2px',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: 'white',
      marginRight: '5px !important',
    }),
    singleValue: () => ({
      height: '18px',
    }),
  };

  const formatOptionLabel = React.useCallback(
    ({ label, value }) => (
      <div className="frow frow--direction-row frow--justify-start frow--items-center frow--full-width">
        <QuarterIndicator currentQuarter={getQuarter(value)} height="18px" />
        <div>{label}</div>
      </div>
    ),
    [],
  );

  const systemReference = (portfolio) => {
    const maxlen = 55 - portfolioObject.entityName.length;
    const systemref = portfolio.admin_system_reference
      ? `(${lodashTruncate(portfolio.admin_system_reference, { length: maxlen, omission: '...' })})`
      : '';
    return <span className="text-white mar-b-2 text-small">{systemref}</span>;
  };

  const admin_system = () => {
    return (
      <div data-tip={portfolioObject.admin_system_reference} style={{ display: 'inline-block' }}>
        {systemReference(portfolioObject)}
        <ReactTooltip effect="solid" />
      </div>
    );
  };

  const handleSelectChange = (optionObj) => setCurrentReviewPeriod(optionObj.value);

  return (
    <div className="platform-content pad-v-1">
      <div className="frow frow--items-center frow--justify-between">
        <div>
          <div className="frow">
            <div>
              <img
                src={portfolioObject.clientIcon}
                alt="client icon"
                style={{ height: '55px' }}
                className="mar-r-2 mar-t-1"
              />
            </div>
            <div className="frow frow--direction-column frow--justify-center">
              <div className="heading-one">
                {portfolioObject.entityName}
                {admin_system()}
              </div>
              <span className="text-small text-grey-40">{portfolioObject.reference}</span>
            </div>
          </div>
        </div>
        <div>
          <div className="frow frow--gutters frow--items-center">
            {portfolioObject.mandatePath && viewMandateButton}
            <div>
              <button className="button button--secondary button--no-min-width" onClick={handleBenchmarkOpen}>
                Benchmark Info
                <i className="icon-arrow-right-top icon-fw icon-0-9x mar-l-1" />
              </button>
            </div>
            <div style={{ minWidth: '180px', paddingBottom: '37px' }}>
              <SubtitleTwo text="Review Period" />
              <ReactSelect
                customSelectStyles={customSelectStyles}
                defaultValue={reviewPeriods[0]}
                options={reviewPeriods}
                theme="dark"
                id="review_date"
                name="review_date"
                // Disabled ESLint as there are very limited performance gains
                // if useCallback was used instead of an arrow function.
                // eslint-disable-next-line
                handleChange={handleSelectChange}
                formatOptionLabel={formatOptionLabel}
              />
            </div>
            <div>
              <button className="button button--no-min-width button--secondary" onClick={handleReorderOpen}>
                <i className="fas fa-cog" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <Settings
        isOpen={isReorderOpen}
        handleClose={handleReorderClose}
        {...{ layout, setItem, layoutDisplay, setLayoutDisplay }}
      />

      <CustomModal
        isOpen={isBenchmarkOpen}
        title="Benchmark Information"
        handleClose={handleBenchmarkClose}
        modifiers={['dark']}
      >
        <div className="modal__content">
          {Object.keys(portfolioObject.benchmarks).map((benchmarkType) => {
            if (!portfolioObject.benchmarks[benchmarkType]) return null;

            const benchmark = portfolioObject.benchmarks[benchmarkType];

            return (
              <div key={benchmarkType}>
                <dl className="definition-list mar-b-1" key={benchmarkType}>
                  <dt>{formatBenchmarkType(benchmarkType)}</dt>
                  <dd>{lodashStartcase(portfolioObject.benchmarkTypes[benchmarkType])}</dd>
                </dl>
                <p className="text-white text-small mar-t-1 mar-b-2">{benchmark || 'Not Applicable'}</p>
              </div>
            );
          })}
        </div>
      </CustomModal>
    </div>
  );
};

export default infoBar;
