import * as React from 'react';

import OutsideClickHandler from 'react-outside-click-handler';

import QuarterSelect from '../../platform/dashboard/reviewFilters/QuarterSelect';

import useVisibility from 'components/shared/customHooks/useVisibility';

interface IProps {
  quarter: number;
  selectableQuarters: { display: string; value: string }[];
  year: number;
}

export default function filters(props: IProps) {
  const { quarter, selectableQuarters, year } = props;
  const [selectedQuarter, setSelectedQuarter] = React.useState({ quarter, year });
  const [selectedDate, setSelectedDate] = React.useState();

  const { isOpen, handleClose, toggleOpen } = useVisibility(false);

  function handleQuarterSelect(quarter: number, year: number, value: string) {
    setSelectedQuarter({ quarter, year });
    setSelectedDate(value);
    handleClose();
  }

  React.useEffect(() => {
    if (!selectedDate) return;

    const customEvent = new CustomEvent('managerPortal:Filters:Change', { detail: { selectedDate } });
    document.dispatchEvent(customEvent);
  }, [selectedDate]);

  return (
    <OutsideClickHandler onOutsideClick={handleClose}>
      <QuarterSelect
        {...{ quarter, year, handleQuarterSelect, selectedQuarter, selectableQuarters }}
        handleQuarterSelectOpen={toggleOpen}
        isQuarterSelectOpen={isOpen}
      />
    </OutsideClickHandler>
  );
}
