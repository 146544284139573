import * as React from 'react';

import { DataUploadError } from 'javascript/models';
import { createAlert } from 'components/shared/Utils';

interface IProps {
  dataUploadError: DataUploadError;
  handleTableUpdate(): void;
}

export default function resolve(props: IProps) {
  const { dataUploadError, handleTableUpdate } = props;

  function handleChange() {
    updateError();
  }

  async function updateError() {
    dataUploadError.resolved = !dataUploadError.resolved;
    const success = await dataUploadError.save();
    if (success) {
      handleTableUpdate();
      if (dataUploadError.resolved) {
        createAlert('success', 'Error resolved', 1500);
      } else {
        createAlert('warning', 'Error marked as unresolved', 1500);
      }
    } else {
      createAlert('error', 'There was an problem resolved this error', 1500);
    }
  }

  return (
    <div className="text-center">
      <div className="pretty pretty--table p-icon p-smooth mar-r-0">
        <input type="checkbox" checked={dataUploadError.resolved} onChange={handleChange} />
        <div className="state p-blue">
          <i className="icon-tick icon" />
          <label />
        </div>
      </div>
    </div>
  );
}
