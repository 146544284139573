import * as React from 'react';

export const CustomLegend = (props) => {
  const { payload } = props;

  const legends = payload.map((payloadItem, index) => {
    const lineStyles = {
      borderColor: payloadItem.color,
      borderStyle: 'solid',
      borderWidth: '1px',
      marginRight: '15px',
      width: '45px',
    };

    if (payloadItem.payload.strokeDasharray) {
      lineStyles.borderStyle = 'dashed';
    }

    return (
      <div key={index} className="col-md-1-2">
        <div className="frow frow--items-center">
          <span style={lineStyles} />
          <span className="text-white small-uppercase">{payloadItem.dataKey}</span>
        </div>
      </div>
    );
  });

  return <div className="frow frow--gutters mar-t-4">{legends}</div>;
};
