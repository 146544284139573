import * as React from 'react';

import { ReportTemplatesReportTemplateSection } from 'javascript/models';

export function legendOptions(selectedLegend, handleLegendChange) {
  const options = ReportTemplatesReportTemplateSection.legendOptions.map((legend) => {
    function handleChange() {
      handleLegendChange(legend.value);
    }
    return (
      <div className="col-tn-1-3" key={legend.value}>
        <div className="pretty pretty--large pretty--with-label p-round">
          <input
            type="radio"
            value={legend.value}
            id="timePeriod"
            onChange={handleChange}
            checked={selectedLegend === legend.value}
          />
          <div className="state p-blue-circle">
            <label>{legend.label}</label>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="col-mc-1-1">
      <label className="form__label" htmlFor="">
        Legend Position
      </label>
      <div className="frow ">{options}</div>
    </div>
  );
}
