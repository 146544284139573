import * as React from 'react';

export default function potentiallyBlank({ handleAttributeChange, uploadTemplateCondition }) {
  const [blankOptionValue, setBlankOptionValue] = React.useState(uploadTemplateCondition.blankOption);

  const blankOptions = [
    { label: 'Find first value above', value: 'value_above' },
    { label: 'Find first value below', value: 'value_below' },
    { label: 'Fill with', value: 'fill_with' },
  ];

  function handleChange(event) {
    const attribute = event.target.id;
    const value = event.target.value;
    if (attribute === 'blankOption') {
      setBlankOptionValue(value);
    }
    handleAttributeChange(attribute, value);
  }

  const options = blankOptions.map((option) => {
    return (
      <div key={option.value} className="pretty pretty--large pretty--with-label p-round">
        <input
          type="radio"
          id="blankOption"
          name="blankOption"
          value={option.value}
          onChange={handleChange}
          defaultChecked={uploadTemplateCondition.blankOption === option.value}
        />
        <div className="state p-blue-circle">
          <label>{option.label}</label>
        </div>
      </div>
    );
  });

  function renderText() {
    if (blankOptionValue !== 'fill_with') return;

    return (
      <div className="col-mc-1-1">
        <label htmlFor="" className="form__label">
          Fill With
        </label>
        <input
          type="text"
          defaultValue={uploadTemplateCondition.blankText}
          id="blankText"
          name="blankText"
          onChange={handleChange}
        />
      </div>
    );
  }

  return (
    <div
      className="platform-content platform-content--padding-bottom platform-content--border-dashed-bottom-grey
        platform-content--spacing-bottom"
    >
      <p className="text-small">If this column is blank we should:</p>
      <div className="frow frow--centered-column frow--gutters">
        <div className="col-mc-1-1">
          <div className="col-mc-1-1">{options}</div>
        </div>
        {renderText()}
      </div>
    </div>
  );
}
