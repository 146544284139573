import * as React from 'react';

import Context from '../Context';
import { chartColorsV2 } from './chartColors';
import MultiLayerPieChart from 'components/shared/charting/recharts/MultiLayerPieChart';

interface IDataObject {
  name: string;
  value: number;
  color?: string;
  parent?: string;
}

export default function multiLayerPieChart({ colors, templateSection }) {
  const context = React.useContext(Context);
  const { reportTemplate } = context.state;
  const chartColors = chartColorsV2();

  const dataArray = [];

  const assetClasses = [
    { name: 'Cash', value: 10 },
    { name: 'Fixed Income', value: 35 },
    { name: 'Equity', value: 35 },
    { name: 'Hedge Fund', value: 15 },
    { name: 'Other', value: 5 },
  ];

  const instrument = [
    { name: 'Cash Instrument', value: 10, parent: 'Cash' },
    { name: 'Bond', value: 35, parent: 'Fixed Income' },
    { name: 'Stock', value: 25, parent: 'Equity' },
    { name: 'Preferred Stock', value: 5, parent: 'Equity' },
    { name: 'Mutual Fund', value: 5, parent: 'Equity' },
    { name: 'Mutual Fund', value: 15, parent: 'Hedge Fund' },
    { name: 'Hedge Fund', value: 5, parent: 'Other' },
  ];

  const industry = [
    { name: 'N/A', value: 10, parent: 'Cash' },
    { name: 'Energy', value: 35, parent: 'Fixed Income' },
    { name: 'Financials', value: 25, parent: 'Equity' },
    { name: 'Health Care', value: 5, parent: 'Equity' },
    { name: 'Industrials', value: 5, parent: 'Equity' },
    { name: 'Unclassified', value: 15, parent: 'Hedge Fund' },
    { name: 'Unclassified', value: 5, parent: 'Other' },
  ];

  const country = [
    { name: 'UK', value: 5, parent: 'Cash' },
    { name: 'US', value: 5, parent: 'Cash' },
    { name: 'UK', value: 35, parent: 'Fixed Income' },
    { name: 'Global', value: 25, parent: 'Equity' },
    { name: 'US', value: 5, parent: 'Equity' },
    { name: 'Argentina', value: 5, parent: 'Equity' },
    { name: 'Global', value: 15, parent: 'Hedge Fund' },
    { name: 'Global', value: 5, parent: 'Other' },
  ];

  const currency = [
    { name: 'GBP', value: 5, parent: 'Cash' },
    { name: 'USD', value: 5, parent: 'Cash' },
    { name: 'GBP', value: 35, parent: 'Fixed Income' },
    { name: 'USD', value: 35, parent: 'Equity' },
    { name: 'CHF', value: 15, parent: 'Hedge Fund' },
    { name: 'USD', value: 5, parent: 'Other' },
  ];

  const dividend = [
    { name: 'Retained', value: 10, parent: 'Cash' },
    { name: 'Paid Away', value: 35, parent: 'Fixed Income' },
    { name: 'Retained', value: 35, parent: 'Equity' },
    { name: 'Retained', value: 15, parent: 'Hedge Fund' },
    { name: 'aid Away', value: 5, other: 'Other' },
  ];

  const credit = [
    { name: 'A', value: 10, parent: 'Cash' },
    { name: 'A', value: 35, parent: 'Fixed Income' },
    { name: 'B', value: 35, parent: 'Equity' },
    { name: 'B', value: 15, parent: 'Hedge Fund' },
    { name: 'Unrated', value: 5, other: 'Other' },
  ];

  const subInstrument = [
    { name: 'Cash', value: 10, parent: 'Cash' },
    { name: 'Fund', value: 35, parent: 'Fixed Income' },
    { name: 'Fund', value: 35, parent: 'Equity' },
    { name: 'Fund', value: 15, parent: 'Hedge Fund' },
    { name: 'Fund', value: 5, other: 'Other' },
  ];

  if (templateSection && templateSection.pieChartLayers) {
    if (templateSection.pieChartLayers.includes('asset_class')) {
      dataArray.push(assetClasses);
    }
    if (templateSection.pieChartLayers.includes('instrument')) {
      dataArray.push(instrument);
    }
    if (templateSection.pieChartLayers.includes('industry')) {
      dataArray.push(industry);
    }
    if (templateSection.pieChartLayers.includes('country_of_domicile')) {
      dataArray.push(country);
    }
    if (templateSection.pieChartLayers.includes('country_of_risk')) {
      dataArray.push(country);
    }
    if (templateSection.pieChartLayers.includes('currency')) {
      dataArray.push(currency);
    }
    if (templateSection.pieChartLayers.includes('dividend_distribution_policy')) {
      dataArray.push(dividend);
    }
    if (templateSection.pieChartLayers.includes('credit_rating')) {
      dataArray.push(credit);
    }
    if (templateSection.pieChartLayers.includes('sub_instrument')) {
      dataArray.push(subInstrument);
    }
  } else {
    dataArray.push(assetClasses);
    dataArray.push(instrument);
  }

  const formattedDataArray = dataArray.map((dataObject: IDataObject[]) => {
    return dataObject.map((entry, index) => {
      entry.color = chartColors(reportTemplate, colors, entry.parent || entry.name, index, false);
      return entry;
    });
  });

  return <MultiLayerPieChart height={250} dataArray={formattedDataArray} />;
}
