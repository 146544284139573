import * as React from 'react';

import Loading from 'components/shared/Loading';
import AuditDisplay from '../../../../../../audits/AuditDisplay';

export default function presenter({ audits, isLoading }) {
  function renderAudits() {
    if (isLoading) return <Loading />;

    if (audits.length < 1) return <p className="text-small">This review doesn't have any history yet.</p>;

    const sanitizedAudits = audits.filter((audit, index) => {
      if (index === 0) return true;

      return audit.log !== audits[index - 1].log;
    });

    const auditCards = sanitizedAudits.map((audit) => {
      return (
        <div key={audit.id} className="col-mc-1-1">
          <AuditDisplay {...{ audit }} />
        </div>
      );
    });

    return (
      <div className="frow frow--gutters" style={{ width: '100%' }}>
        {auditCards}
      </div>
    );
  }

  return (
    <div className="platform-content platform-content--padding-vertical">
      <div className="frow frow--items-center frow--justify-between mar-b-2">
        <h4 className="platform-panel__title">Review History</h4>
      </div>
      <div className="frow frow--gutters frow--direction-column">{renderAudits()}</div>
    </div>
  );
}
