import * as React from 'react';

import { groupBy, chunk } from 'lodash';

import { Security, SecurityStateClassificationWeighting } from 'javascript/models';

import Loading from 'components/shared/Loading';
import ClassificationBreakdown from './classificationWrapper/ClassificationBreakdown';

interface IProps {
  securityId: number;
}

interface IGroupedClassificationDetails {
  [key: string]: SecurityStateClassificationWeighting[];
}

const classificationWrapper = (props: IProps) => {
  const { securityId } = props;

  const [isLoading, setIsLoading] = React.useState(true);
  const [groupedSecurityValues, setGroupedSecurityValues] = React.useState<IGroupedClassificationDetails>({});

  const getSecurity = async () => {
    if (securityId) {
      const { data } = await Security.includes([
        { current_state: { rootAlias: { classificationWeightings: { classification: 'rootAlias' } } } },
      ]).find(securityId);

      const { currentState } = data;
      if (currentState) {
        setGroupedSecurityValues(groupBy(currentState.rootAlias.classificationWeightings, 'valueType'));
      }
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    getSecurity();
  }, []);

  const renderClassificationDetails = () => {
    if (isLoading) return <Loading />;

    const chunkedSecurityArray: [string, SecurityStateClassificationWeighting[]][][] = chunk(
      Object.entries(groupedSecurityValues),
      3,
    );

    return chunkedSecurityArray.map((securityValueRow, index) => (
      <div className="frow" key={index}>
        {renderClassificationRow(securityValueRow)}
      </div>
    ));
  };

  const renderClassificationRow = (securityValueRow: [string, SecurityStateClassificationWeighting[]][]) =>
    securityValueRow.map(([valueType, classificationWeightings], index) => (
      <ClassificationBreakdown {...{ valueType, classificationWeightings, key: index, index }} />
    ));

  return <div>{renderClassificationDetails()}</div>;
};

export default classificationWrapper;
