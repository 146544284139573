import { Attr, Model } from 'spraypaint';
import { ApplicationRecord } from './ApplicationRecord';

@Model()
export class Answer extends ApplicationRecord {
  public static jsonapiType = 'answers';
  @Attr() public administratorId: string;
  @Attr() public body: string;
  @Attr() public reviewId: string;
  @Attr() public question: string;
}
