/* TODO(CO): Refactor inline with function components */
import * as React from 'react';

import InfoBox from './InfoBox';
import ItemsByStatus from './ItemsByStatus';
import ReactPieChart from '../../../shared/charting/recharts/ReactPieChart';

import IPieChartData from '../../../shared/charting/recharts/interfaces/IPieChartData';
import lastDateInQuarter from '../../../shared/lastDateInQuarter';

import { IFilterOptions } from '../shared/IFilterOptions';

const routes = require('../../../../javascript/application/routes.js');
const classNames = require('classnames');

interface IProps {
  admin: number | string;
  canCreateReviews: boolean;
  client_type: number | string;
  companyId: number;
  data: IPieChartData[];
  filterOptions?: IFilterOptions;
  isLoading: boolean;
  quarter: number;
  totalCount: number;
  year: number;
  relationshipManager?: number;
}

class Presenter extends React.Component<IProps, {}> {
  renderData() {
    const { admin, client_type, companyId, data, filterOptions, isLoading, quarter, totalCount, year } = this.props;
    const filteredData = data.filter((data) => data.overview);
    return filteredData.map((dataObject, index) => {
      const contentClasses = classNames('platform-panel__content', {
        'platform-panel__content--right-bordered': index + 1 !== filteredData.length,
      });
      return (
        <div className="col-md-1-6" key={index}>
          <div className={contentClasses}>
            <InfoBox
              {...{ admin, client_type, companyId, dataObject, filterOptions, isLoading, totalCount, quarter, year }}
            />
          </div>
        </div>
      );
    });
  }

  renderTitle() {
    const { quarter, year } = this.props;
    if (quarter && year) {
      return `Quarter ${quarter} ${year} Overview`;
    }
    return 'Current Quarter Overview';
  }

  render() {
    const {
      admin,
      canCreateReviews,
      client_type,
      companyId,
      isLoading,
      data,
      filterOptions,
      totalCount,
      quarter,
      year,
    } = this.props;
    return (
      <div>
        <div className="platform-panel">
          <header className="platform-panel__header">
            <div className="frow frow--items-center frow--justify-between">
              <h3 className="platform-panel__title">{this.renderTitle()}</h3>
              <a
                className="button button--secondary button--compact"
                href={routes.platform_reviews_path({
                  filters: {
                    current_owner_id: [admin],
                    end_date: [lastDateInQuarter(quarter, year)],
                    client_type: [client_type],
                  },
                })}
              >
                View all <i className="icon-arrow-right-top icon-0-8x mar-l-1" />
              </a>
            </div>
          </header>
          <div className="frow">
            <div className="col-md-2-6">
              <div className="platform-panel__content platform-panel__content--right-bordered">
                <ReactPieChart
                  data={this.props.data.filter((data) => data.overview)}
                  height={380}
                  isLoading={this.props.isLoading}
                  labelText={this.props.totalCount}
                  noData={!totalCount || totalCount === 0}
                />
              </div>
            </div>
            {this.renderData()}
          </div>
        </div>
        <ItemsByStatus
          {...{ admin, canCreateReviews, client_type, companyId, filterOptions, isLoading, quarter, year }}
          data={data.filter((data) => data.status)}
        />
      </div>
    );
  }
}

export default Presenter;
