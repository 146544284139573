import * as React from 'react';

import { format } from 'date-fns';
import TextareaAutosize from 'react-autosize-textarea';

import Attachments from './Attachments';
import Button from 'components/shared/forms/Button';
import Dropzone from 'components/shared/forms/Dropzone';
import PortfolioSelect from './PortfolioSelect';
import ReactSelect from 'components/shared/forms/ReactSelect';

import Context from '../../../Context';

import { Note } from 'javascript/models/Models';
import ISelectOption from 'components/interfaces/ISelectOption';
import { IHasManyAttachedRcvd } from 'javascript/models/shared/types';

interface IProps {
  currentUser?: string;
  isSubmitting?: boolean;
  note?: Note;
  tags: ISelectOption[];
  setUpdateOnClose: any;
  handleClose(): void;
  handleSuccessfulFormSubmission(): void;
  handleUploadedFiles(files): void;
}

export default function form(props: IProps) {
  const { handleClose, note, tags, handleUploadedFiles: onFileUpload } = props;
  const context = React.useContext(Context);
  const { id } = context.props.portfolio;

  function defaultValue(attribute) {
    if (!note) {
      return '';
    }

    return note[attribute];
  }

  function tagSelect() {
    if (!tags) return;

    let defaultValues;
    if (note) {
      defaultValues = tags.filter((tag) => note.tagList.includes(tag.value));
    }

    return (
      <ReactSelect
        createable
        defaultValues={defaultValues}
        options={tags}
        theme="light"
        id="tag_list"
        name="note[tag_list][]"
        isMulti
      />
    );
  }

  const author = note ? note.author.fullName : props.currentUser;

  const attachments =
    note &&
    (note.attachments as IHasManyAttachedRcvd[]).map((file) => (
      <input type="hidden" name="note[attachments][]" value={file.signed_id} />
    ));

  const date = note ? note.createdAt : new Date();

  return (
    <div className="frow frow--gutters-2x">
      <div className="col-sm-2-3">
        <div className="frow frow--gutters">
          <div className="col-mc-1-1">
            <label htmlFor="note_category" className="form__label">
              Category
            </label>
            <div className="form__select-wrapper">
              <select name="note[note_category]" id="note_category" defaultValue={defaultValue('noteCategory')}>
                <option value="">Please Select</option>
                <option value="email">Email</option>
                <option value="note">Note</option>
              </select>
            </div>
          </div>
          <div className="col-mc-1-1">
            <label htmlFor="note_type" className="form__label">
              Type
            </label>
            <div className="form__select-wrapper">
              <select name="note[note_type]" id="note_type" defaultValue={defaultValue('noteType')}>
                <option value="">Please Select</option>
                <option value="general">General</option>
                <option value="action_point_response">Action Point Response</option>
                <option value="mandate">Mandate</option>
              </select>
            </div>
          </div>
          <div className="col-mc-1-1">
            <label htmlFor="note_type" className="form__label">
              Tags
            </label>
            {tagSelect()}
          </div>
          <div className="col-mc-1-1">
            <label htmlFor="body" className="form__label">
              Body
            </label>
            <TextareaAutosize
              defaultValue={defaultValue('body')}
              style={{ minHeight: 110, maxHeight: 300 }}
              name="note[body]"
              cols={10}
              id="body"
            />
          </div>
          <div className="col-mc-1-1">
            <label htmlFor="status" className="form__label">
              Status
            </label>
            <div className="form__select-wrapper">
              <select name="note[status]" id="status" defaultValue={defaultValue('status')}>
                <option value="">Please Select</option>
                <option value="normal">Normal</option>
                <option value="high_priority">High Priority</option>
              </select>
            </div>
          </div>
          <div className="col-mc-1-1">
            <input type="hidden" name="note[portfolio_ids][]" value={id} />
            <label htmlFor="status" className="form__label">
              Portfolios <span>(The note will be automatically added to this portfolio)</span>
            </label>
            <PortfolioSelect id={id} selected={!!note ? note.portfolios : null} />
          </div>
          <div className="col-mc-1-1">
            <label htmlFor="status" className="form__label">
              New Attachments
            </label>
            <Dropzone onFileUpload={onFileUpload} />
          </div>
        </div>
      </div>
      <div className="col-sm-1-3">
        <p className="mar-t-0 text-small">
          <span className="small-uppercase">Author</span>
          <br />
          <span className="text-black">{author}</span>
        </p>
        <p className="mar-b-0 text-small">
          <span className="small-uppercase">Date</span>
          <br />
          <span className="text-black">{format(date, 'D MMM YYYY')}</span>
        </p>
        <Attachments
          handleSuccessfulFormSubmission={props.handleSuccessfulFormSubmission}
          note={note}
          setUpdateOnClose={props.setUpdateOnClose}
        />
        {attachments}
      </div>
      <div className="col-sm-2-3">
        <div className="frow frow--gutters">
          <div className="col-mc-1-1">
            <Button
              buttonClasses="button--full-width button--light-themed"
              style={{ minWidth: 'unset' }}
              text="Save"
              isSubmitting={props.isSubmitting}
              submittingText="Saving"
            />
          </div>
          <div className="col-sm-1-1">
            <Button
              buttonClasses="button--full-width button--outlined-secondary"
              style={{ minWidth: 'unset' }}
              text="Cancel"
              inputType="button"
              handleClick={handleClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
