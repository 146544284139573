import * as React from 'react';

import * as ReactTooltip from 'react-tooltip';
import lodashTruncate from 'lodash.truncate';

import { DirectUpload } from 'activestorage';
import { createAlert } from 'components/shared/Utils';
import Dropzone from 'components/shared/forms/Dropzone';

import StyledWarning from 'components/shared/styledAlerts/StyledWarning';
import Loading from 'components/shared/Loading';
import { ActionPointContext } from './../Show';
import { THasManyAttachedRcvd } from 'javascript/models/shared/types';

export default function uploader() {
  const { actionPoint, currentUser, phantomLogin, getActionPoint, client } = React.useContext(ActionPointContext);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const { documents } = (actionPoint as unknown) as { documents: THasManyAttachedRcvd };

  async function handleUploadedFiles(files) {
    if (phantomLogin) return;
    if (files.length < 1) return;

    setIsSubmitting(true);

    files.forEach((file, index) => {
      const upload = new DirectUpload(file, '/rails/active_storage/direct_uploads');
      upload.create((error, blob) => {
        if (error) {
          setIsSubmitting(false);
          createAlert('error', 'There was an error uploading this file', 2000);
        } else {
          createUpload({ name: blob.filename, signed_id: blob.signed_id }).then(() => getActionPoint());
          setIsSubmitting(false);
        }
      });
    });
  }

  async function createUpload(blob) {
    const currentDocuments = documents;
    actionPoint.documents = [...currentDocuments, blob];
    await actionPoint.save();
  }

  function renderAttachments() {
    if (!actionPoint) {
      return <p className="helper-text text-white">No Files uploaded</p>;
    }

    const formattedFiles = documents.map((file, index) => {
      return (
        <div key={index}>
          <a
            className="tag tag--blue tag--inline tag--small"
            href={file.path}
            target="_blank"
            data-tip={file.name}
            data-for="document-file-name"
          >
            {lodashTruncate(file.name)}
          </a>
        </div>
      );
    });

    return (
      <div className="frow frow--gutters">
        {formattedFiles}
        <ReactTooltip effect="solid" id="document-file-name" />
      </div>
    );
  }

  const PermissionedDropzone = () => {
    if (!currentUser.hasPermission('action_point_document_uploader', client.id))
      return (
        <StyledWarning message="You need the action point document uploader permission in order to upload attachments to action points" />
      );
    if (phantomLogin) return <StyledWarning message="You cannot upload documents when signed in as a different user" />;

    return (
      <Dropzone
        shouldDiscardFiles
        isSubmitting={isSubmitting}
        onFileUpload={handleUploadedFiles}
        resetFiles
        shouldShowFiles={false}
        theme="dark"
        multiple
      />
    );
  };

  const PermissionedAttachments = () => {
    if (currentUser.hasPermission('action_point_attachments_viewer', client.id)) return renderAttachments();

    return (
      <StyledWarning message="You need the action points attachment viewer permission to view action point attachments" />
    );
  };

  if (!currentUser) return <Loading />;

  return (
    <section className="platform-panel">
      <header className="platform-panel__header">
        <h2 className="platform-panel__title">Documents</h2>
      </header>
      <div className="platform-content platform-content--padding-all">
        <div
          className="platform-content platform-content--border-bottom platform-content--padding-bottom
          platform-content--spacing-bottom"
        >
          <PermissionedAttachments />
        </div>
        <div className="h-100">
          <PermissionedDropzone />
        </div>
      </div>
    </section>
  );
}
