import * as React from 'react';

import concatMerge from 'javascript/helpers/concatMerge';

import { ActionPoint, Review } from 'javascript/models';

import useAsyncFunctionInjector, { beforeAction } from 'components/shared/customHooks/useAsyncFunctionInjector';

import Loading from 'components/shared/Loading';
import { createAlert } from 'components/shared/Utils';

import CloseModal from '../../../modals/context/CloseModal';
import Main from './form/Main';

export interface IFormProps {
  actionPoint?: ActionPoint;
  review: Review;
}

const Form = (props: IFormProps) => {
  const { actionPoint: actionPointProp, review: reviewProp } = props;

  const [localActionPoint, setLocalActionPoint] = React.useState<ActionPoint>();
  const [review, setReview] = React.useState<Review>();

  const closeAPModal = React.useContext(CloseModal);

  const getReviewData = async () => {
    const reviewM = await Review.includes('action_points').find(reviewProp.id);

    if (!actionPointProp.isPersisted) {
      actionPointProp.firstIncludedDate = reviewM.data.endDate;
    }

    setReview(reviewM.data);
  };

  React.useEffect(() => {
    getReviewData();
  }, [reviewProp]);

  const getActionPointData = async () => {
    const actionPointData = await ActionPoint.includes(['author', 'deferral_notes', 'complete_notes'])
      .order({ 'deferral_notes.created_at': 'desc' })
      .find(actionPointProp.id);

    if (!actionPointData) {
      createAlert('error', 'There was an error retrieving this action point.', 2000);
      return null;
    }

    const newActionPoint = actionPointData.data;

    setLocalActionPoint(newActionPoint);
  };

  React.useEffect(() => {
    if (actionPointProp) {
      if (actionPointProp.isPersisted) {
        getActionPointData();
      } else {
        setLocalActionPoint(actionPointProp);
      }
    } else {
      setLocalActionPoint(new ActionPoint());
    }
  }, [actionPointProp]);

  const save = React.useCallback(
    function (action, args, result) {
      if (action === beforeAction) {
        if (!this.isPersisted) this.reviews = [review];

        return [concatMerge(args[0] || {}, { with: ['reviews'], displayQueryResult: true })];
      } else if (result) {
        closeAPModal();
      }
    },
    [localActionPoint, review],
  );

  const actionPoint = useAsyncFunctionInjector(save, 'save', localActionPoint);

  return actionPoint && review ? <Main {...{ actionPoint, review }} /> : <Loading />;
};

export default Form;
