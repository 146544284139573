import * as React from 'react';

import { Editor } from '@tinymce/tinymce-react';

export default function tinyMce({ handleChange, height = 200, initialValue }) {
  const handleEditorChange = (e) => {
    handleChange(e.target.getContent());
  };

  const initializeSettings = {
    height,
    menubar: false,
    toolbar:
      'undo redo | bold italic | fontsizeselect \
      alignleft aligncenter alignright alignjustify | \
      bullist numlist outdent indent | removeformat',
  };

  return (
    <Editor
      apiKey="dzp6qet4k3kw69eborf1jslf7twcbeam4ht2hkgam117q77q"
      initialValue={initialValue}
      init={initializeSettings}
      onChange={handleEditorChange}
    />
  );
}
