import { Attr, BelongsTo, Model } from 'spraypaint';
import { ApplicationRecord } from './ApplicationRecord';

@Model()
export class TodoItem extends ApplicationRecord {
  public static jsonapiType = 'todo_items';
  @Attr() public administratorId: number;
  @Attr() public position: number;
  @Attr() public completed: boolean;
  @Attr() public completedAt: string;
  @Attr() public body: string;
}
