import * as React from 'react';

import InfoBar from './InfoBar';
import PortfolioInfo from './PortfolioInfo';
import PortfolioSelect from './PortfolioSelect';
import QuickSearch from './QuickSearch';

export default function presenter({ handleDeleteTab, portfolio, viewablePortfolios, terminationReasons }) {
  return (
    <div className="platform-panel">
      <div className="platform-panel__inner platform-panel__inner--ruled pad-a-0">
        <div className="frow frow--items-center frow--justify-between">
          <PortfolioSelect {...{ handleDeleteTab, portfolio, viewablePortfolios }} />
          <QuickSearch />
        </div>
      </div>
      <div className="platform-panel__inner platform-panel__inner--ruled pad-a-0" style={{ position: 'relative' }}>
        <InfoBar {...{ portfolio, terminationReasons }} />
      </div>
      <PortfolioInfo {...{ portfolio }} />
    </div>
  );
}
