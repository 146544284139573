import * as React from 'react';

import AdditionalPages from './pages/AdditionalPages';
import CoverPage from './pages/CoverPage';
import LandscapeAdditionalPagesWrapper from './pages/landscape/AdditionalPagesWrapper';
import LandscapeCoverPage from './pages/landscape/CoverPage';
import LandscapeBackPage from './pages/landscape/BackPage';

import Context from './Context';
import Loading from 'components/shared/Loading';

export default function report() {
  const context = React.useContext(Context);
  const { reportTemplate } = context.state;

  const [numberOfPages, setNumberOfPages] = React.useState(0);
  const [templateSections, setTemplateSections] = React.useState([]);
  const [templateRows, setTemplateRows] = React.useState([]);
  const [component, setComponent] = React.useState(null);

  const dummyContainerRef = React.createRef<HTMLDivElement>();

  const isLandscape = reportTemplate && reportTemplate.orientation === 'landscape';

  React.useEffect(() => {
    if (!reportTemplate) return;

    let total = 0;
    let overallTemplateSections = [];
    if (isLandscape) {
      // If the report is landscape we start with one empty array to represent the first page which is blank
      overallTemplateSections = [[]];
    }
    let localTemplateSections = [];
    reportTemplate.reportTemplateRows.forEach((row, index) => {
      const rowHeight = row.height || 4.1;

      // The blank page filler has a height of 100 or 140 for landscape, we don't want this to actually take a whole
      // page. Just push the rest of the stuff onto a new page
      if (parseFloat(rowHeight) == 100 || parseFloat(rowHeight) == 140 || row.repeating) {
        // 26 is the section id for theme pages these are treated differently since they are a single page and only
        // available on the landscape report. Double instead of triple equals because it comes through as a sting not
        // a number
        if (
          (isLandscape &&
            ['26', '39'].includes(row.reportTemplatesReportTemplateSections[0].reportTemplateSectionId)) ||
          row.repeating
        ) {
          if (localTemplateSections.length > 0) {
            overallTemplateSections.push(localTemplateSections);
            localTemplateSections = [];
          }
          localTemplateSections.push(row);
          overallTemplateSections.push(localTemplateSections);
        } else if (row.reportTemplatesReportTemplateSections[0].reportTemplateSectionId == 31) {
          if (localTemplateSections.length > 0) {
            overallTemplateSections.push(localTemplateSections);
            localTemplateSections = [];
          }
          localTemplateSections.push(row);
          overallTemplateSections.push(localTemplateSections);
        } else {
          localTemplateSections.push(row);
          overallTemplateSections.push(localTemplateSections);
        }
        localTemplateSections = [];
        total = 0;
        return;
      }

      // If total plus the section height is more than 100 than add the local sections to the overall list, restart
      // the local array, and set the total to be the height of the new element in the new array.
      let coverPageSize = 60; // Doesn't matter for landscape reports - there are no sections on page 1
      if (reportTemplate.theme === 'custom') {
        coverPageSize = Math.floor(100 - 6.37 - reportTemplate.reportTemplateTheme.headerHeight - 2.37);
      }
      // Landscape height is a bit of a guess so need to keep an eye on it
      const additionalPageSize = isLandscape ? 90 : 85;
      const percentageAvailable = overallTemplateSections.length < 1 ? coverPageSize : additionalPageSize;

      if (total + parseFloat(rowHeight) > percentageAvailable) {
        overallTemplateSections.push(localTemplateSections);
        localTemplateSections = [row];
        total = parseFloat(rowHeight);
        // If this one happens to be the last one we need to add the local array to the overall array
        if (index + 1 === reportTemplate.reportTemplateRows.length) {
          overallTemplateSections.push(localTemplateSections);
        }
        // If this is the last one (but doesn't push us over the 100 marks we add the section into the local array and
        // add the local array into the overall array
      } else if (index + 1 === reportTemplate.reportTemplateRows.length) {
        localTemplateSections.push(row);
        overallTemplateSections.push(localTemplateSections);
        // If we are not over 100 and this isn't the last section we just add the section into the local array and
        // increase the total by the height of the section
      } else {
        localTemplateSections.push(row);
        total += parseFloat(rowHeight);
      }
    });
    if (isLandscape) {
      overallTemplateSections.push([]);
    }
    let totalPages = overallTemplateSections.length < 1 ? 1 : overallTemplateSections.length;
    if (isLandscape && overallTemplateSections.length === 1) {
      totalPages = 2;
      overallTemplateSections.push([]);
    }
    setNumberOfPages(totalPages);
    setTemplateRows(overallTemplateSections);
    setTemplateSections(overallTemplateSections);
    setComponent(reportTemplate.component);
  }, [reportTemplate]);

  if (!reportTemplate)
    return (
      <div className="pdf-page">
        <Loading height="100%" />
      </div>
    );
  if (numberOfPages === 0)
    return (
      <div className="pdf-page">
        <Loading height="100%" />
      </div>
    );

  return Array.apply(null, Array(numberOfPages))
    .map((x, i) => i)
    .map((pageNumber, index) => {
      if (index === 0) {
        if (isLandscape) return <LandscapeCoverPage key={index} {...{ dummyContainerRef, index }} />;

        return <CoverPage key={index} {...{ dummyContainerRef, index }} templateRows={templateRows[index]} />;
      }

      let startAt = 0;
      for (let i = 0; i < index; i++) {
        startAt += templateSections[i].length;
      }

      if (isLandscape) {
        if (index === numberOfPages - 1) {
          return <LandscapeBackPage key={index} {...{ index, startAt }} />;
        }
        return (
          <LandscapeAdditionalPagesWrapper
            key={index}
            {...{ dummyContainerRef, index, startAt }}
            templateRows={templateRows[index]}
          />
        );
      }

      return (
        <AdditionalPages key={index} {...{ dummyContainerRef, index, startAt }} templateRows={templateRows[index]} />
      );
    });
}
