import * as React from 'react';

import { format, addQuarters, parse } from 'date-fns';

import { ActionPoint, Note, Review } from 'javascript/models';

import { Column } from 'components/frow';

import QuarterPicker from 'components/shared/forms/QuarterPicker';

export interface INewFieldsProps {
  actionPoint: ActionPoint;
  note: Note;
  review: Review;
}

export default function newFields(props: INewFieldsProps) {
  const { actionPoint, note, review } = props;

  if (note.isPersisted) return null;

  const updateDeferredUntil = (optionValue: string) => {
    note.additionalInfo = JSON.stringify({ deferredUntil: optionValue });
    actionPoint.deferredUntil = optionValue;
  };

  const startDateStr = React.useMemo(() => {
    let startDate = parse(actionPoint.deferredUntil || review.endDate);
    const rEndDate = parse(review.endDate);
    startDate = startDate > rEndDate ? startDate : rEndDate;
    startDate = addQuarters(startDate, 1);
    return format(startDate);
  }, []);

  React.useEffect(() => updateDeferredUntil(startDateStr), [startDateStr]);

  return (
    <Column>
      <QuarterPicker
        name="action_point[deferred_until]"
        id="deferred_until"
        label="Deferred Until"
        startDate={startDateStr}
        handleChange={updateDeferredUntil}
        defaultValue={startDateStr}
      />
    </Column>
  );
}
