import React, { useState } from 'react';
import { Frow, Column } from 'components/frow';
import { default as RCSlider } from 'rc-slider';

type Props = {
  value: number;
  label: string;
  onChange?: (value: number) => void;
  negative?: boolean;
  reverse?: boolean;
  name?: string;
  dotSlider?: boolean;
  min?: number;
  max?: number;
};

const Slider = (props: Props) => {
  const { value, label, onChange, negative, reverse, name, dotSlider, min, max } = props;

  const convertedValue = negative ? -value : value;

  const [sliderValue, setSliderValue] = useState(value ? convertedValue : 0);
  const displayValue = negative ? parseInt(`-${sliderValue}`) : sliderValue;

  const handleOnChange = (value) => {
    setSliderValue(value);

    if (onChange) {
      onChange(value);
    }
  };

  const midPoint = max - (max - min) / 2;

  const midpointMark = () => {
    if (min && max) {
      return { [midPoint]: { style: { color: '#fff' }, label: `${midPoint}%` } };
    }
  };

  const dotStyles = {
    backgroundColor: '#fff',
    borderColor: '#fff',
    boxShadow: 'none',
  };

  return (
    <Frow className="mar-b-3">
      <Column>
        <label className="form__label">{label}</label>
        <Frow justifyContent="between" itemAlignment="center">
          <Column columnSpan={10} maxColumns={11}>
            <RCSlider
              value={sliderValue}
              onChange={handleOnChange}
              reverse={reverse}
              trackStyle={[dotSlider ? dotStyles : {}]}
              marks={midpointMark()}
              min={min}
              max={max}
            />
            <input type="hidden" name={name} value={displayValue} />
          </Column>
          <Column columnSpan={1} maxColumns={11}>
            <div className="text-white text-right">{displayValue}%</div>
          </Column>
        </Frow>
      </Column>
    </Frow>
  );
};

export default Slider;
