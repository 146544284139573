import * as React from 'react';

interface IRadio {
  label: string;
  value: any;
}

const useRadioSelect = (
  radios: IRadio[],
  initialIdx: number,
  labelClass = 'text-white',
): [JSX.Element, { current: number }, () => void] => {
  const [selectedRadio, setSelectedRadio] = React.useState(initialIdx);
  const selectedRadioRef = React.useRef(selectedRadio);

  const resetRadioSelection = () => {
    setSelectedRadio(initialIdx);
    selectedRadioRef.current = initialIdx;
  };

  if (!radios || !radios.length) return [null, { current: null }, resetRadioSelection];

  const radioToggle = radios.map((radio, index) => {
    const handleTypeChange = () => {
      setSelectedRadio(index);
      selectedRadioRef.current = index;
    };

    return (
      <div key={radio.value} className="pretty pretty--large pretty--with-label p-round">
        <input
          type="radio"
          value={radio.value}
          id="column_type"
          onChange={handleTypeChange}
          checked={index === selectedRadioRef.current}
        />
        <div className="state p-purple-circle">
          <label className={labelClass}>{radio.label}</label>
        </div>
      </div>
    );
  });

  return [<>{radioToggle}</>, selectedRadioRef, resetRadioSelection];
};

export default useRadioSelect;
