import * as React from 'react';

import { format, parse } from 'date-fns';

export default function columns() {
  return [
    {
      Header: 'Name',
      accessor: 'name',
      columnName: 'Name',
      id: 'name',
    },
    {
      Cell: (row) => {
        const activeTemplate = row.original.activeUploadTemplate;
        return (
          <div className={`table-icon table-icon--${activeTemplate ? 'success' : 'warning'}`}>
            {activeTemplate ? 'Yes' : 'No'}
          </div>
        );
      },
      Header: 'Active Upload Template',
      accessor: 'activeUploadTemplate',
      columnName: 'Active Upload Template',
      id: 'active_upload_template',
    },
    {
      Cell: (row) => {
        if (!row.original.lastDataUploadedOn) {
          return <div>Never</div>;
        }
        return <div>{format(parse(row.original.lastDataUploadedOn), 'DD MMM YYYY')}</div>;
      },
      Header: 'Last Data Uploaded Date',
      accessor: 'lastDataUploadDate',
      columnName: 'Last Data Uploaded Date',
      id: 'last_data_uploaded_on',
    },
    {
      Header: 'User Count',
      accessor: 'managerUserCount',
      columnName: 'User Count',
      id: 'user_count',
    },
    {
      Header: 'Portfolio Count',
      accessor: 'portfolioCount',
      columnName: 'Portfolio Count',
      id: 'portfolio_count',
    },
    {
      Cell: (row) => {
        return (
          <a href={row.original.uploadTemplatesShowPath} style={{ display: 'block' }}>
            <div className="rt-tr-clickable-icon">
              <i className="icon-arrow-right-top icon-0-8x" />
            </div>
          </a>
        );
      },
      Header: '',
      sortable: false,
    },
  ];
}
