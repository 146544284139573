import * as React from 'react';

import {
  CartesianGrid,
  Label,
  Legend,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Surface,
  Symbols,
  XAxis,
  YAxis,
} from 'recharts';

import { CustomXAxis, CustomYAxis } from './CustomComponents';

import { series1, series2, series3, series4, series5, series6, series7, series8, series9 } from './ScatterChartData';
import { chartColorsV2 } from './chartColors';
import Context from '../Context';

export const CustomLegend = (props) => {
  const { payload, templateSection } = props;

  const legends = payload.map((payloadItem, index) => {
    const legendTitle = payloadItem.payload.data.name;

    const size = ['circle, square'].includes(payloadItem.payload.legendType) ? 50 : 30;

    return (
      <div key={index} className="col-mc-1-1" style={{ marginBottom: '4px' }}>
        <div className="frow frow--items-center">
          <div className="mar-r-1">
            <Surface width={15} height={15}>
              <Symbols
                cx={8}
                cy={8}
                type={payloadItem.payload.legendType}
                size={size}
                fill={payloadItem.payload.fill}
                stroke={payloadItem.payload.stroke}
              />
            </Surface>
          </div>
          <span style={{ fontSize: '0.7rem', whiteSpace: 'nowrap' }}>
            {legendTitle && templateSection ? legendTitle : `Series ${index + 1}`}
          </span>
        </div>
      </div>
    );
  });

  return (
    <div style={{ border: '1px solid #e4e4e8', padding: '5px', width: '125px' }} className="mar-l-2">
      <table className="pdf-hidden-table" style={{ height: '100%' }}>
        <tbody>
          <tr>
            <td style={{ verticalAlign: 'middle' }}>
              <div className="frow frow--direction-column">{legends}</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export const CustomVerticalLegend = (props) => {
  const { payload, templateSection } = props;

  const legends = payload.map((payloadItem, index) => {
    const legendTitle = payloadItem.payload.data.name;

    const size = ['circle, square'].includes(payloadItem.payload.legendType) ? 50 : 30;

    return (
      <div className="frow frow--items-center" key={index}>
        <div className="mar-r-1">
          <Surface width={15} height={15}>
            <Symbols
              cx={8}
              cy={8}
              type={payloadItem.payload.legendType}
              size={size}
              fill={payloadItem.payload.fill}
              stroke={payloadItem.payload.stroke}
            />
          </Surface>
        </div>
        <div style={{ fontSize: '0.7rem', whiteSpace: 'nowrap' }} className="mar-r-2">
          {legendTitle && templateSection ? legendTitle : `Series ${index + 1}`}
        </div>
      </div>
    );
  });

  return (
    <div style={{ border: '1px solid #e4e4e8', textAlign: 'center' }} className="mar-t-1">
      <div className="frow frow--justify-center">{legends}</div>
    </div>
  );
};

export default function scatterChart({ colors, templateSection }) {
  const context = React.useContext(Context);
  const { reportTemplate } = context.state;
  const legendRight = templateSection && templateSection.legendPosition === 'right';
  const chartColors = chartColorsV2();

  let series = [series1, series2, series3, series4, series5, series6, series7, series8, series9];

  if (templateSection && templateSection.scatterChartSeries) {
    series = templateSection.scatterChartSeries.map((dataPoint, index) => {
      const dataObject = series[index];
      dataObject['name'] = dataPoint;
      return dataObject;
    });
  }

  const shapes: ('circle' | 'cross' | 'diamond' | 'square' | 'star' | 'triangle' | 'wye')[] = [
    'circle',
    'diamond',
    'square',
    'triangle',
    'cross',
    'star',
    'wye',
  ];

  const scatters = series.map((series, index) => {
    const name = series['name'] ? series['name'] : '';
    const color = chartColors(reportTemplate, colors, name, index, !templateSection);
    const fill = index > 6 ? '#ffffff' : color;
    const stroke = color;
    const shape = shapes[index % shapes.length];
    return (
      <Scatter
        key={index}
        data={series}
        fill={fill}
        shape={shape}
        legendType={shapes[index % shapes.length]}
        isAnimationActive={false}
        stroke={stroke}
      />
    );
  });

  let height = 225;
  if (templateSection && templateSection.size === 'large') {
    height = 350;
  }

  function renderLegend() {
    if (legendRight) {
      return (
        <Legend
          layout="vertical"
          verticalAlign="top"
          align="right"
          content={<CustomLegend {...{ templateSection }} />}
        />
      );
    }
    return <Legend content={<CustomVerticalLegend {...{ templateSection }} />} iconType="line" />;
  }

  return (
    <div>
      <ResponsiveContainer width="100%" height={height}>
        <ScatterChart width={730} height={height} margin={{ top: 5, right: 0, left: 0, bottom: 20 }}>
          <CartesianGrid stroke="#d7d7dd" />
          <XAxis
            dataKey="xAxis"
            interval={0}
            tick={{ fill: '#b5b6bd', fontSize: '8px', fontWeight: 'bold' }}
            tickCount={7}
            tickSize={0}
            tickMargin={8}
            tickFormatter={() => 'LABEL'}
            type="number"
          >
            <Label content={<CustomXAxis />} />
          </XAxis>
          <YAxis
            dataKey="y"
            interval={0}
            tick={{ fill: '#b5b6bd', fontSize: '8px', fontWeight: 'bold' }}
            tickCount={12}
            tickSize={0}
            tickMargin={5}
            tickFormatter={() => 'LABEL'}
          >
            <Label content={<CustomYAxis height={height} />} />
          </YAxis>
          {renderLegend()}
          {scatters}
        </ScatterChart>
      </ResponsiveContainer>
    </div>
  );
}
