import * as React from 'react';
import { Cell, Label, Legend, Pie, PieChart, ResponsiveContainer } from 'recharts';

import { chartColorsV2 } from './chartColors';
import Context from '../Context';

export const CustomLegend = (props) => {
  const { payload } = props;

  const legends = payload.map((payloadItem, index) => {
    const lineStyles = {
      background: payloadItem.payload.fill,
      border: `2px solid ${payloadItem.payload.stroke}`,
      borderRadius: '50%',
      display: 'inline-block',
      height: '10px',
      width: '10px',
    };

    return (
      <div key={index} className="col-mc-1-1" style={{ marginBottom: '8px', fontSize: '0.8rem' }}>
        <div
          className={`platform-content ${index + 1 !== payload.length && 'platform-content--border-bottom-light-grey'}`}
          style={{ paddingBottom: index + 1 !== payload.length ? '5px' : '0px' }}
        >
          <table className="pdf-hidden-table">
            <tbody>
              <tr>
                <td style={{ fontSize: '8px', width: '15%' }}>
                  <span style={lineStyles} />
                </td>
                <td style={{ width: '50%', fontSize: '8px' }}>{props.data[index].name}</td>
                <td style={{ fontSize: '8px' }} className="text-bold text-right">{`${props.data[index].value}%`}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  });

  return (
    <div style={{ border: '1px solid #e4e4e8', padding: '15px 5px 10px', width: '120px' }}>
      <div className="frow frow--direction-column">{legends}</div>
    </div>
  );
};

export default function pieChart({ colors, templateSection }) {
  const context = React.useContext(Context);
  const { reportTemplate } = context.state;
  const chartColors = chartColorsV2();

  const defaultData = [
    { name: 'Series 1', value: 10 },
    { name: 'Series 2', value: 35 },
    { name: 'Series 3', value: 35 },
    { name: 'Series 4', value: 15 },
    { name: 'Series 5', value: 5 },
  ];

  const allocationData = [
    { name: 'Cash', value: 3 },
    { name: 'Fixed Income', value: 27 },
    { name: 'Equity', value: 40 },
    { name: 'Hedge Fund', value: 20 },
    { name: 'Other', value: 10 },
  ];

  const currencyData = [
    { name: 'GBP', value: 70 },
    { name: 'USD', value: 10 },
    { name: 'EUR', value: 7 },
    { name: 'CHF', value: 3 },
    { name: 'Other', value: 5 },
  ];

  const constituentData = [
    { name: 'EIDEMO0001', value: 55 },
    { name: 'EIDEMO0002', value: 45 },
  ];

  const [data, setData] = React.useState<{ name: string; value: number }[]>(defaultData);

  React.useEffect(() => {
    if (templateSection && templateSection.pieChartType) {
      switch (templateSection.pieChartType) {
        case 'allocation':
        case 'saa':
          setData(allocationData);
          return;
        case 'currency':
          setData(currencyData);
          return;
        case 'constituent_portfolios':
          setData(constituentData);
          return;
        default:
          setData(defaultData);
          return;
      }
    }
  }, [templateSection]);

  const pieData = data.map((entry, index) => {
    return (
      <Cell key={index} fill={chartColors(reportTemplate, colors, entry.name, index, !templateSection)} stroke="none" />
    );
  });

  let height = 175;
  if (templateSection && templateSection.size === 'medium') height = 250;
  if (templateSection && templateSection.size === 'large') height = 350;

  let labelSize = 8;
  if (templateSection && templateSection.size === 'medium') labelSize = 11;
  if (templateSection && templateSection.size === 'large') labelSize = 14;

  return (
    <div>
      <ResponsiveContainer width="100%" height={height}>
        <PieChart height={height}>
          <Pie
            data={data}
            nameKey="name"
            dataKey="value"
            innerRadius="55%"
            startAngle={90}
            endAngle={-270}
            isAnimationActive={false}
          >
            {pieData}
            <Label position="center" fill="#000" fontSize={labelSize} fontWeight="bold">
              LABEL
            </Label>
          </Pie>
          <Legend layout="vertical" verticalAlign="middle" align="right" content={<CustomLegend {...{ data }} />} />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}
