import * as React from 'react';

import { DirectUpload } from 'activestorage';

import Presenter from '../securityClassificationsForm/classificationUploads/upload/Presenter';

import { ClassificationUpload } from 'javascript/models';

export default function upload({ securityClassificationStateId }: { securityClassificationStateId: string }) {
  const [files, setFiles] = React.useState([]);
  const [description, setDescription] = React.useState('');
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isSubmittable, setIsSubmittable] = React.useState(false);
  const [closeModal, setCloseModal] = React.useState(false);

  React.useEffect(() => {
    if (closeModal) {
      setCloseModal(false);
    }
  }, [closeModal]);

  React.useEffect(() => {
    if (files.length > 0 && !!description) {
      setIsSubmittable(true);
    }
  }, [files, description]);

  function handleUploadedFiles(files) {
    if (files.length < 1) return;
    setFiles(files);
  }

  function handleChange(event) {
    setDescription(event.target.value);
  }

  function handleSubmit(event) {
    event.preventDefault();
    if (files.length < 1 && !description) return;

    setIsSubmitting(true);

    files.forEach((file) => {
      const upload = new DirectUpload(file, '/rails/active_storage/direct_uploads');

      upload.create((error, blob) => {
        if (error) {
          setIsSubmitting(false);
        } else {
          createClassificationUpload(blob.signed_id).then(() => {
            setFiles([]);
            setIsSubmitting(false);
            setCloseModal(true);
            const customEvent = new CustomEvent('classification_upload:uploaded');
            document.dispatchEvent(customEvent);
          });
        }
      });
    });
  }

  async function createClassificationUpload(signedId) {
    const classificationUpload = new ClassificationUpload({
      description,
      import: signedId,
      security_classification_state_id: securityClassificationStateId,
    });
    await classificationUpload.save();
  }

  return (
    <Presenter {...{ closeModal, handleUploadedFiles, handleChange, handleSubmit, isSubmitting, isSubmittable }} />
  );
}
