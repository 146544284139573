import * as React from 'react';

import { DragDropContext } from 'react-beautiful-dnd';
const classNames = require('classnames');

import General from './General';
import Report from './Report';
import Sections from './Sections';
import Theme from './Theme';

import Context from './Context';

export default function presenter() {
  const context = React.useContext(Context);
  const { reportTemplate, selectedTab } = context.state;
  const { getData, handleDragEnd } = context.actions;
  const { canEdit } = context.props;
  const [tab, setTab] = React.useState(canEdit ? selectedTab : 'report');

  function handleChange() {
    getData();
    setTab(this);
  }

  const sectionsSelector = (
    <div style={{ height: '920px', overflowY: 'scroll' }}>
      <Sections />
    </div>
  );

  const themeClasses = (thisTab, bordered = false) => {
    return classNames('tabbed-nav__tab', { 'tabbed-nav__tab--right-bordered': bordered }, 'col-flex-grow-1', {
      'tabbed-nav__tab--active': tab === thisTab,
    });
  };

  const nav = (
    <div className="tabbed-nav">
      <div className={themeClasses('general', true)} onClick={handleChange.bind('general')}>
        <span className="text-bold letter-spacing-1">General</span>
      </div>
      <div className={themeClasses('theme', true)} onClick={handleChange.bind('theme')}>
        <span className="text-bold letter-spacing-1">Theme</span>
      </div>
      <div className={themeClasses('report')} onClick={handleChange.bind('report')}>
        <span className="text-bold letter-spacing-1">Report</span>
      </div>
    </div>
  );

  return (
    <React.Fragment>
      <div className="platform-panel">
        {canEdit && nav}
        <div className="platform-content--padding-all">
          <div style={{ display: tab === 'general' ? 'block' : 'none' }}>
            <General {...{ reportTemplate }} />
          </div>
          <div style={{ display: tab === 'theme' ? 'block' : 'none' }}>
            <Theme {...{ reportTemplate }} />
          </div>
          <div style={{ display: tab === 'report' ? 'block' : 'none' }}>
            <DragDropContext onDragEnd={handleDragEnd}>
              <section className="frow frow--justify-center frow--gutters-2x">
                <div style={{ height: '920px', overflowY: 'scroll' }}>
                  <Report />
                </div>
                {canEdit && sectionsSelector}
              </section>
            </DragDropContext>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
