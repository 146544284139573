import * as React from 'react';

import { format, parse } from 'date-fns';
const numeral = require('numeral');

export default function columns() {
  return [
    {
      Cell: (row) => {
        return <div>{format(row.original.date, 'DD MMM YYYY')}</div>;
      },
      Header: 'Date',
      accessor: 'date',
      id: 'date',
    },
    {
      Cell: (row) => {
        return <div>{numeral(row.original.balance).format('0,0.00')}</div>;
      },
      Header: 'Balance',
      accessor: 'balance',
      id: 'balance',
    },
    {
      Cell: (row) => {
        return <div>{numeral(row.original.cash_in).format('0,0.00')}</div>;
      },
      Header: 'In',
      accessor: 'cash_in',
      id: 'cash_in',
    },
    {
      Cell: (row) => {
        return <div>{numeral(Math.abs(row.original.cash_out)).format('0,0.00')}</div>;
      },
      Header: 'Out',
      accessor: 'cash_out',
      id: 'cash_out',
    },
  ];
}
