import * as React from 'react';

const classNames = require('classnames');

interface IProps {
  buttonClasses?: string;
  disabledText?: string;
  hasError?: boolean;
  hasIcon?: boolean;
  icon?: string;
  inputType?: 'button' | 'submit' | 'reset';
  isDisabled?: boolean;
  isSubmitting?: boolean;
  name?: string;
  style?: any;
  submittingText?: string;
  successText?: string;
  text: string;
  type?: 'success' | 'error' | 'info' | 'warning' | 'error-outline';
  value?: string;
  handleClick?(value?: any): void;
  children?: JSX.Element;
}

interface IState {
  disabledText: string;
  isProvidingFeedback: boolean;
  submittingText: string;
}

class Button extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps> = {
    hasError: false,
    inputType: 'submit',
    isDisabled: false,
    isSubmitting: false,
    type: 'info',
  };

  constructor(props: IProps) {
    super(props);
    this.state = {
      disabledText: props.disabledText || props.text,
      isProvidingFeedback: false,
      submittingText: props.submittingText || props.text,
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleProvidingFeedback = this.handleProvidingFeedback.bind(this);
  }

  public componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.props.successText) {
      return;
    }

    if (prevProps.isSubmitting && !this.props.isSubmitting) {
      this.setState({ isProvidingFeedback: true }, () => {
        this.handleProvidingFeedback();
      });
    }
  }

  public componentWillUnmount() {
    this.setState = () => {};
  }

  public render() {
    return (
      <button
        disabled={this.props.isDisabled || this.state.isProvidingFeedback}
        className={this.buttonClasses()}
        onClick={this.handleClick}
        style={this.props.style}
        type={this.props.inputType}
        name={this.props.name}
        value={this.props.value}
      >
        {this.renderText()}
        {this.renderIcon()}
        {this.props.children && this.props.children}
      </button>
    );
  }

  private handleProvidingFeedback() {
    return setTimeout(() => this.setState({ isProvidingFeedback: false }), 1500);
  }

  private handleClick() {
    if (this.props.handleClick) {
      this.props.handleClick();
    }
  }

  private renderText() {
    if (this.state.isProvidingFeedback && this.props.successText && !this.props.hasError) {
      return (
        <React.Fragment>
          {this.props.successText}
          <i className="fa fa-check mar-l-1 animated fadeIn" />
        </React.Fragment>
      );
    }
    if (this.props.isDisabled) {
      return this.state.disabledText;
    }
    if (this.props.isSubmitting) {
      return (
        <React.Fragment>
          {this.state.submittingText}
          <i className="fa fa-circle-notch fa-spin mar-l-1" />
        </React.Fragment>
      );
    }
    return this.props.text;
  }

  private renderIcon() {
    if (!this.props.hasIcon) return;

    return <i className={`icon-${this.props.icon} icon-fw icon-push-down-1 mar-l-1`} />;
  }

  private buttonClasses() {
    return classNames('button', { 'button--disabled': this.props.isSubmitting }, this.props.buttonClasses);
  }
}

export default Button;
