import * as React from 'react';

import { Alert, Intent } from '@blueprintjs/core';
import { DataUpload } from 'javascript/models';
import Button from 'components/shared/forms/Button';
import useVisibility from 'components/shared/customHooks/useVisibility';

interface IProps {
  dataUpload: DataUpload;
  isLoading: boolean;
  handleReProcess(): void;
}

export default function reProcess(props: IProps) {
  const { dataUpload, handleReProcess, isLoading } = props;

  if (isLoading) return null;

  const { handleOpen, handleClose, isOpen } = useVisibility(false);

  const confirmReprocess = () => handleOpen();

  return (
    <>
      <Button
        submittingText="Processing"
        isSubmitting={dataUpload.processing}
        text="Re-Process File"
        buttonClasses="button--secondary"
        handleClick={confirmReprocess}
      />
      <Alert
        isOpen={isOpen}
        intent={Intent.DANGER}
        cancelButtonText="Cancel"
        canEscapeKeyCancel
        canOutsideClickCancel
        confirmButtonText="Confirm"
        onCancel={handleClose}
        onConfirm={handleReProcess}
        onClose={handleClose}
      >
        <p>Reprocessing will cause the data upload to overwrite existing data.</p>
      </Alert>
    </>
  );
}
