import * as React from 'react';

import { equals } from 'ramda';

/*
 * A hook which produces a component and records the props used on the component into state
 *
 * @remark - Ramda.equals is used for comparison of props object to prevent unnecessary re-renders.
 *
 * @param ReplacementComponent - An optional component to provide which will be used under the capturing component
 *
 * @returns [Component, state]
 */
const useComponentProxy = <PropT,>(
  ReplacementComponent = (_props: PropT) => <></>,
): [(props: PropT) => JSX.Element, PropT] => {
  const [proxyProps, setProxyProps] = React.useState<PropT>();
  const ProxyComponent = React.useCallback(
    (props: PropT) => (
      setProxyProps((previous: PropT) => (equals(previous, props) ? previous : props)),
      (<ReplacementComponent {...props} />)
    ),
    [ReplacementComponent],
  );
  return [ProxyComponent, proxyProps];
};

export default useComponentProxy;
