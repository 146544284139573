import * as React from 'react';

import CloseModal from 'components/modals/context/CloseModal';

import { Frow, Column } from 'components/frow';

import Button from 'components/shared/forms/Button';

import useReviewTransitionReasons from '../useReviewTransitionReasons';

import { IStatusTransition } from '../Actions';

interface IReviewReasonsModalProps {
  transitionObject: IStatusTransition;
  isSubmitting: boolean;
}

const ReviewReasonsModal = (props: IReviewReasonsModalProps) => {
  const { transitionObject, isSubmitting } = props;
  const closeModal = React.useContext(CloseModal);

  const [reasonsJSX, selectedReason, clearSelectedReason] = useReviewTransitionReasons();

  const onClick = React.useCallback(async () => {
    await transitionObject.onClick(selectedReason)();
    closeModal();
    clearSelectedReason();
  }, [selectedReason]);

  return (
    <div className="form form--light">
      <Frow gutterSize={1}>
        {reasonsJSX}
        <Button
          buttonClasses="button--compact button--light-themed mar-r-1"
          isDisabled={!selectedReason}
          isSubmitting={isSubmitting}
          handleClick={onClick}
          submittingText="Changing Status"
          text="Submit"
        />
      </Frow>
    </div>
  );
};

export default ReviewReasonsModal;
