import React, { useState } from 'react';

import Dropzone from 'components/shared/forms/Dropzone';
import Button from 'components/shared/forms/Button';
import { DirectUpload } from 'activestorage';
import DatePicker from 'react-datepicker';
import { Frow, Column } from 'components/frow';
import CustomModal from 'components/shared/CustomModal';

interface Props {
  consultancyClient: any;
  lettersOfEngagement: any;
}

export default function form(props: Props) {
  const { consultancyClient, lettersOfEngagement } = props;

  const { client_grouping, entity, comments } = consultancyClient;

  const [blobSignedId, setBlobSignedId] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [signatureDate, setSignatureDate] = useState<Date | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedLetterOfEngagement, setSelectedLetterOfEngagement] = useState(null);

  function handleFileUpload(files) {
    if (files.length < 1) return;

    setIsProcessing(true);
    const upload = new DirectUpload(files[0], '/rails/active_storage/direct_uploads');

    upload.create((error, blob) => {
      if (!error) {
        setBlobSignedId(blob.signed_id);
      }
      setIsProcessing(false);
    });
  }

  function handleModalOpen(letterOfEngagement) {
    setSelectedLetterOfEngagement(letterOfEngagement);
    setModalOpen(true);
  }

  return (
    <>
      <Frow gutterSize={3} itemAlignment="center" columnContext={{ maxColumns: 5 }}>
        <Column columnSpan={2}>
          <label htmlFor="name" className="form__label">
            Client grouping
          </label>
          <input type="string" name="consultancy_client[client_grouping]" defaultValue={client_grouping} step={0.01} />
        </Column>
      </Frow>

      <Frow gutterSize={3} itemAlignment="center" columnContext={{ maxColumns: 5 }}>
        <Column columnSpan={2}>
          <label htmlFor="name" className="form__label">
            Entity
          </label>
          <input type="string" name="consultancy_client[entity]" defaultValue={entity} step={0.01} />
        </Column>
      </Frow>

      <Frow gutterSize={3} itemAlignment="center" columnContext={{ maxColumns: 5 }}>
        <Column columnSpan={2}>
          <label htmlFor="name" className="form__label">
            Comments
          </label>
          <input type="string" name="consultancy_client[comments]" defaultValue={comments} step={0.01} />
        </Column>
      </Frow>

      <Frow gutterSize={3} itemAlignment="center" columnContext={{ maxColumns: 5 }}>
        <Column columnSpan={2}>
          <label className="form__label">Upload Letter of Engagement</label>
          <Dropzone
            handleRemoveFile={() => setBlobSignedId('')}
            onFileUpload={handleFileUpload}
            multiple={false}
            isSubmitting={isProcessing}
            shouldShowFiles={true}
            theme="dark"
          />
          <input
            type="hidden"
            name="consultancy_client[letters_of_engagement_attributes][0][document]"
            value={blobSignedId}
          />
        </Column>
      </Frow>

      <Frow gutterSize={3} itemAlignment="center" columnContext={{ maxColumns: 5 }}>
        <Column columnSpan={2}>
          <label className="form__label">Signature date</label>
          <DatePicker
            name="consultancy_client[letters_of_engagement_attributes][0][signature_date]"
            id="signature_date"
            selected={signatureDate}
            onChange={(date) => setSignatureDate(date)}
            dateFormat="yyyy/MM/dd"
            autoComplete="off"
            disabled={blobSignedId == ''}
          />
        </Column>
      </Frow>

      {lettersOfEngagement &&
        lettersOfEngagement.map((letter, index) => {
          return (
            <Frow gutterSize={3} itemAlignment="center" columnContext={{ maxColumns: 5 }} key={index}>
              <Column columnSpan={2}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <a className="link link--secondary" href={letter.document_url}>
                    {letter.filename}
                  </a>

                  <a onClick={() => handleModalOpen(letter)}>
                    <div className="rt-tr-clickable-icon">
                      <i className="icon-bin icon-0-8x text-white" />
                    </div>
                  </a>
                </div>
              </Column>
            </Frow>
          );
        })}

      <CustomModal
        isOpen={modalOpen}
        title="Delete Letter of Engagement"
        handleClose={() => setModalOpen(false)}
        modifiers={['dark']}
      >
        <div className="modal__content form form--dark">
          <p className="mar-t-0 text-white">Are you sure you want to delete this letter of engagement?</p>

          {selectedLetterOfEngagement && (
            <>
              <Frow gutterSize={3}>
                <div className="col-md-1-2">
                  <a href={selectedLetterOfEngagement.document_url}>{selectedLetterOfEngagement.filename}</a>
                </div>
              </Frow>

              <Frow gutterSize={3}>
                <div className="col-md-1-2">
                  <a
                    href={selectedLetterOfEngagement.delete_url}
                    className="button button--danger"
                    data-method="delete"
                  >
                    Confirm Delete
                  </a>
                </div>
              </Frow>
            </>
          )}
        </div>
      </CustomModal>

      <Frow gutterSize={3} itemAlignment="center" columnContext={{ maxColumns: 5 }}>
        <Column columnSpan={2}>
          <Button
            buttonClasses="button--full-width button--highlight"
            style={{ minWidth: 'unset' }}
            text={'Save'}
            submittingText="Saving"
            isDisabled={isProcessing}
          />
        </Column>
      </Frow>
    </>
  );
}
