import * as React from 'react';
import { sanitize } from 'dompurify';
import simpleFormat from 'components/shared/utilities/simpleFormat';
import Context from '../Context';

export default function textSmall({ templateSection }) {
  const text = templateSection && templateSection.text ? templateSection.text : 'Emphasised text or a quotation';
  const context = React.useContext(Context);
  const { reportTemplate } = context.state;

  let color = '#3990a8';
  if (reportTemplate && reportTemplate.reportTemplateTheme) {
    color = reportTemplate.reportTemplateTheme.primaryColor;
  }

  const styles = {
    borderLeftColor: color,
  };

  return (
    <div
      className="pdf-body-copy-wrap pdf-body-copy-wrap--quote"
      dangerouslySetInnerHTML={{ __html: simpleFormat(sanitize(text), 'pdf-body-copy', `color: ${color}`) }}
      style={styles}
    />
  );
}
