import * as React from 'react';

import CustomModal from 'components/shared/CustomModal';
import useVisibility from 'components/shared/customHooks/useVisibility';
import { Answer } from 'javascript/models';
import ReviewContext from '../ReviewContext';

interface IProps {
  answer: Answer;
  index: number;
}

export default function answerDisplay(props: IProps) {
  const context = React.useContext(ReviewContext);
  const { handleReviewUpdate } = context.actions;
  const [answer, setAnswer] = React.useState(props.answer);
  const [body, setBody] = React.useState(props.answer.body || '');

  const { index } = props;
  const { isOpen, handleOpen, handleClose } = useVisibility(false);

  function handleFormSubmission(event) {
    event.preventDefault();
  }

  function handleChange(event) {
    setBody(event.target.value);
  }

  async function handleUpdate() {
    if (!body) return;

    answer.body = body;
    const success = await answer.save();
    setAnswer(answer);

    if (success) {
      handleReviewUpdate();
      handleClose();
    }
  }

  const noAnswer = <span className="text-grey helper-text">Not answered</span>;

  return (
    <div className="text-white">
      <div>
        <span className="mar-r-1" style={{ display: 'inline-block' }}>
          {index + 1}.
        </span>{' '}
        {answer.question}
      </div>
      <div className="mar-t-1">
        <i className="icon-edit icon-fw icon-clickable mar-r-2" onClick={handleOpen} />
        {answer.body ? answer.body : noAnswer}
      </div>
      <CustomModal isOpen={isOpen} title="Edit Answer" handleClose={handleClose}>
        <div className="modal__content">
          <form className="form form--light" onSubmit={handleFormSubmission}>
            <div className="frow frow--gutters">
              <div className="col-mc-1-1">
                <label htmlFor={`body_${index}`} className="form__label">
                  Answer
                </label>
                <textarea name="body" id={`body_${index}`} rows={4} onChange={handleChange} value={body} />
              </div>
              <div className="col-mc-1-1">
                <button className="button" disabled={!body} onClick={handleUpdate}>
                  Update
                </button>
              </div>
            </div>
          </form>
        </div>
      </CustomModal>
    </div>
  );
}
