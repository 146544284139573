import * as React from 'react';

import { Alert, Intent } from '@blueprintjs/core';
import Loading from 'components/shared/Loading';
import { hasProps } from 'components/shared/HigherOrderComponents';

export default function confirm(props) {
  const { alertOpen, handleAlertClose, confirm, isSubmitting } = props;

  return (
    <Alert
      isOpen={alertOpen}
      onClose={handleAlertClose}
      canEscapeKeyCancel
      canOutsideClickCancel
      cancelButtonText="Cancel"
      confirmButtonText="Submit"
      intent={Intent.PRIMARY}
      onCancel={handleAlertClose}
      onConfirm={confirm}
      portalContainer={document.body}
      icon="updated"
    >
      {isSubmitting ? <Loading /> : props.children}
    </Alert>
  );
}
