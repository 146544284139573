import * as React from 'react';

import Presenter from './balances/Presenter';

export default function balances() {
  const [selectedYear, setSelectedYear] = React.useState(0);

  function handleYearChange(year) {
    setSelectedYear(year);
  }

  return <Presenter {...{ handleYearChange, selectedYear }} />;
}
