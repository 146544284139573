import React from 'react';
import ReactSelect from 'components/shared/forms/ReactSelect';

import { pluck } from 'ramda';

const useReactSelect = (reactSelectOptions) => {
  const defaultOptions = [].concat(reactSelectOptions.defaultOption || reactSelectOptions.defaultOptions);
  const [selectedOptions, setSelectedOptions] = React.useState(defaultOptions);

  const handleChange = (options) => setSelectedOptions([].concat(pluck('value', [].concat(options))));

  return [selectedOptions, <ReactSelect {...{ ...reactSelectOptions, handleChange }} />];
};

export default useReactSelect;
