import * as React from 'react';

import { format, parse } from 'date-fns';
const numeral = require('numeral');

export default function columns() {
  const formattedRow = (row, attribute) => {
    return <div className="frow frow--items-center">{numeral(row.original[attribute]).format('0,0.00')}%</div>;
  };

  return [
    {
      Cell: (row) => {
        return <div>{format(parse(row.original.value_date), 'DD MMM YYYY')}</div>;
      },
      Header: 'Date',
      accessor: 'value_date',
      id: 'value_date',
    },
    {
      Cell: (row) => formattedRow(row, 'gbp'),
      Header: 'GBP',
      accessor: 'gbp',
      id: 'gbp',
    },
    {
      Cell: (row) => formattedRow(row, 'usd'),
      Header: 'USD',
      accessor: 'usd',
      id: 'usd',
    },
    {
      Cell: (row) => formattedRow(row, 'eur'),
      Header: 'EUR',
      accessor: 'eur',
      id: 'eur',
    },
    {
      Cell: (row) => formattedRow(row, 'jpy'),
      Header: 'JPY',
      accessor: 'jpy',
      id: 'jpy',
    },
    {
      Cell: (row) => formattedRow(row, 'hkd'),
      Header: 'HKD',
      accessor: 'hkd',
      id: 'hkd',
    },
    {
      Cell: (row) => formattedRow(row, 'chf'),
      Header: 'CHF',
      accessor: 'chf',
      id: 'chf',
    },
    {
      Cell: (row) => formattedRow(row, 'sgd'),
      Header: 'SGD',
      accessor: 'sgd',
      id: 'sgd',
    },
    {
      Cell: (row) => formattedRow(row, 'zar'),
      Header: 'ZAR',
      accessor: 'zar',
      id: 'zar',
    },
    {
      Cell: (row) => formattedRow(row, 'cad'),
      Header: 'CAD',
      accessor: 'cad',
      id: 'cad',
    },
    {
      Cell: (row) => formattedRow(row, 'aud'),
      Header: 'AUD',
      accessor: 'aud',
      id: 'aud',
    },
    {
      Cell: (row) => formattedRow(row, 'oth'),
      Header: 'OTH',
      accessor: 'oth',
      id: 'oth',
    },
    {
      Cell: (row) => formattedRow(row, 'tbc'),
      Header: 'Unclassified',
      accessor: 'tbc',
      id: 'tbc',
    },
    {
      Cell: (row) => formattedRow(row, 'sum'),
      Header: 'SUM',
      accessor: 'sum',
      id: 'sum',
    },
  ];
}
