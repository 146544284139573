import * as React from 'react';

import useVisibility from 'components/shared/customHooks/useVisibility';
import CustomModal from 'components/shared/CustomModal';
import { portalDownloadsPath } from 'javascript/application/ts_routes';
import portalNotificationChannel from 'javascript/channels/portalNotificationChannel';

const notifications = () => {
  const { isOpen, handleOpen, handleClose } = useVisibility(false);

  React.useEffect(() => {
    const subscription = portalNotificationChannel((notification) => {
      // Handle notification received over WebSocket
      if (notification.type === 'reportDownload' && notification.readyToDownload) {
        handleOpen();
      }
    });

    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, []);

  return (
    <CustomModal isOpen={isOpen} title="Download Ready" handleClose={handleClose}>
      <div className="modal__content">
        <p className="text-small mar-v-0">
          Your download is ready you can get if from the{' '}
          <a href={portalDownloadsPath()} className="link link--blue">
            downloads page
          </a>
          .
        </p>
      </div>
    </CustomModal>
  );
};

export default notifications;
