import * as React from 'react';

import { Dialog, Intent } from '@blueprintjs/core';

import pluralize from 'pluralize';

import { startCase } from 'lodash';
import { filter, map, pipe } from 'ramda';

import ModalWithTrigger from 'components/modals/ModalWithTrigger';
import ajaxRequest from 'components/shared/utilities/ajaxRequest';

import AssignModal from '../AssignModal';
import useVisibility from 'components/shared/customHooks/useVisibility';

import { moveStagePlatformReviewsPath } from 'javascript/application/ts_routes';
import { createAlert } from 'components/shared/Utils';
import Loading from 'components/shared/Loading';

import ReviewReasonsModalContent from './actions/ReviewReasonsModalContent';

import { IReasonsObject } from './useReviewTransitionReasons';

import Confirm from './Confirm';

interface IProps {
  administrators: { display: string; value: number }[];
  canBulkMove: boolean;
  canVerify: boolean;
  companyId: number;
  selection: string[];
  selectedQueue: string;
  handleReviewsUpdated(): void;
}

type TEvent =
  | 'back_to_verification'
  | 'back_to_in_review'
  | 'back_to_in_progress'
  | 'back_to_awaiting_data'
  | 'to_in_progress'
  | 'to_in_review'
  | 'to_verification'
  | 'to_ready_to_send';

interface IStatusTransitionPartial {
  label?: string;
  event: TEvent;
  onClick?: (backwardsTransitionReason?) => (reactEvent?) => void;
  if?: boolean;
}

export interface IStatusTransition extends Required<Omit<IStatusTransitionPartial, 'onClick'>> {
  onClick: (backwardsTransitionReason?) => (reactEvent?) => void;
  isBackwardsTransition: boolean;
}

export interface IStatusForwardTransition extends Omit<IStatusTransition, 'onClick'> {
  onClick: (reactEvent?) => void;
}

const Actions = (props: IProps) => {
  const { canBulkMove, canVerify, companyId, handleReviewsUpdated, selection, selectedQueue } = props;
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const { isOpen, handleOpen, handleClose } = useVisibility(false);

  const {
    isOpen: verifyAlertOpen,
    handleClose: handleVerifyAlertClose,
    handleOpen: handleVerifyAlertOpen,
  } = useVisibility(false);

  if (selection.length < 1) {
    return null;
  }

  const handleMove = (destinationStage: TEvent) => (
    backwardsTransitionReason: IReasonsObject = undefined,
  ) => async () => {
    if (isSubmitting) return;

    const transformed_reasons = {
      ...backwardsTransitionReason,
      additionalInfo: backwardsTransitionReason && backwardsTransitionReason.reason,
    };

    delete transformed_reasons.reason;

    const params = {
      review_ids: selection,
      transition: destinationStage,
      reason_for_transition_backwards: transformed_reasons,
    };

    try {
      const response = await ajaxRequest<{ problems: string }, typeof params>({
        beforeSend: () => setIsSubmitting(true),
        method: 'PATCH',
        params,
        path: moveStagePlatformReviewsPath(),
      });

      if (response.problems) {
        createAlert('error', `Could not move the reviews for ${response.problems}`, false);
      } else {
        createAlert('success', 'All reviews successfully moved', 1500);
      }
    } catch (e) {
      createAlert('error', 'An unknown error occured', false);
    } finally {
      handleReviewsUpdated();
      setIsSubmitting(false);
    }
  };

  const stateTransitonFactory = (transition: IStatusTransitionPartial): IStatusTransition => {
    const newTransition = {
      label: `Move ${startCase(transition.event)}`,
      if: true,
      // Defaults before here
      ...transition,
      // Replacements after here
      onClick: handleMove(transition.event),
    };

    Object.defineProperty(newTransition, 'isBackwardsTransition', {
      get() {
        return this.event.startsWith('back_to');
      },
    });

    return (newTransition as unknown) as IStatusTransition;
  };

  const transitionTrigger = (transition: IStatusTransition) => {
    const TriggerComponent = ({ handleOpen: onClick }) => (
      <div className="dropdown__nav-link" key={transition.event} onClick={onClick}>
        {transition.label}
      </div>
    );

    let jsx;

    if (transition.isBackwardsTransition) {
      jsx = (
        <ModalWithTrigger
          modifiers={['visible-overflow']}
          trigger={TriggerComponent}
          title="Additional Information Required"
        >
          <ReviewReasonsModalContent isSubmitting={isSubmitting} transitionObject={transition} />
        </ModalWithTrigger>
      );
    } else {
      const forwardTransitionObject: IStatusForwardTransition = {
        ...transition,
        onClick: transition.onClick(),
      };

      jsx = <TriggerComponent handleOpen={forwardTransitionObject.onClick} />;
    }

    return jsx;
  };

  const availableStageMoves: IStatusTransitionPartial[] = [];

  switch (selectedQueue) {
    case 'sent':
    case 'ready_to_send':
      availableStageMoves.push({ event: 'back_to_verification' });
    case 'verification':
      availableStageMoves.push({ event: 'back_to_in_review' });
    case 'in_review':
      availableStageMoves.push({ event: 'back_to_in_progress' });
    case 'in_progress':
      availableStageMoves.push({ event: 'back_to_awaiting_data' });
  }

  const stageTransitionMap = new Map<string, IStatusTransitionPartial>([
    ['awaiting_data', { event: 'to_in_progress' }],
    ['in_progress', { event: 'to_in_review' }],
    ['in_review', { event: 'to_verification' }],
    [
      'verification',
      { event: 'to_ready_to_send', label: 'Verify', onClick: () => () => () => handleVerifyAlertOpen, if: canVerify },
    ],
  ]);

  const nextStage = stageTransitionMap.get(selectedQueue);
  if (nextStage) availableStageMoves.unshift(nextStage);

  const stageTransitionListItems = pipe(
    map(stateTransitonFactory),
    filter((transitionObject) => transitionObject.if),
    map(transitionTrigger),
  )(availableStageMoves);

  return (
    <div className="frow frow--items-center">
      <p className="text-small text-white mar-v-0 mar-r-2">{pluralize('item', selection.length, true)} selected</p>
      <div className="dropdown dropdown--hover dropdown--sw button button button--compact">
        Actions
        <i className="mar-l-1 button__icon icon-chevron-down icon-tiny icon-push-up-2" />
        <div className="dropdown__content" style={{ width: '170%' }}>
          <div className="dropdown__nav-link" onClick={handleOpen}>
            Assign
          </div>
          {canBulkMove && stageTransitionListItems}
        </div>
      </div>
      <AssignModal
        administrators={props.administrators}
        companyId={companyId}
        handleClose={handleClose}
        handleReviewsAssigned={handleReviewsUpdated}
        isOpen={isOpen}
        selection={selection}
      />
      <Dialog
        isOpen={isSubmitting}
        canEscapeKeyClose={false}
        usePortal={false}
        canOutsideClickClose={false}
        isCloseButtonShown={false}
        title="Moving Reviews"
      >
        <Loading flexDirection="column" size="lg" />
      </Dialog>
      <Confirm
        {...{
          alertOpen: verifyAlertOpen,
          confirm: handleMove('to_verification')(),
          handleAlertClose: handleVerifyAlertClose,
          isSubmitting,
        }}
      />
    </div>
  );
};

export default Actions;
