import { useState } from 'react';

export default function useSectionSize(initialState, changeHandler) {
  const [selectedSize, setSelectedSize] = useState(initialState);

  function handleSizeChange(size) {
    setSelectedSize(size);
    changeHandler(size, 'size');
  }

  return {
    handleSizeChange,
    selectedSize,
  };
}
