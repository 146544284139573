import * as React from 'react';

import * as ReactTooltip from 'react-tooltip';

import { format, parse } from 'date-fns';

export default function columns() {
  return [
    {
      Cell: (row) => {
        return (
          <div>
            <span data-tip={row.original.title}>{row.original.title}</span>
            <ReactTooltip effect="solid" delay={300} />
          </div>
        );
      },
      Header: 'title',
      accessor: 'title',
      columnName: 'title',
      id: 'title',
    },
    {
      Cell: (row) => {
        return <div>{format(parse(row.original.commentaryDate), 'DD-MM-YYYY')}</div>;
      },
      Header: 'Commentary Date',
      accessor: 'commentary_date',
      columnName: 'Commentary Date',
      id: 'commentary_date',
    },
    {
      Cell: (row) => {
        return (
          <a href={row.original.editPath} style={{ display: 'block' }}>
            <div className="rt-tr-clickable-icon">
              <i className="icon-arrow-right-top icon-0-8x" />
            </div>
          </a>
        );
      },
      Header: '',
      sortable: false,
    },
  ];
}
