import * as React from 'react';
import { ActionPoint } from 'javascript/models';
import { Note } from 'javascript/models';

import { format, parse } from 'date-fns';

import ModalWithTrigger from '../../../../modals/ModalWithTrigger';
import DeferralForm from '../DeferralForm';

import ThemeContext from 'components/shared/context/ThemeContext';

import Frow from '../../../../frow/Frow';
import Column from '../../../../frow/Column';

import quarterFormat from 'javascript/helpers/quarterFormat';

export interface IAdditionalDeferralInfo {
  deferredUntil: string;
  originalNote: string;
}

export interface IDeferralNotesListProps {
  actionPoint: ActionPoint;
}

const DeferralNotesList = (props: IDeferralNotesListProps) => {
  const { actionPoint } = props;

  const theme = React.useContext(ThemeContext);

  const deferralNotes = actionPoint.deferralNotes;

  const selectableNotes = deferralNotes.map((note: Note) => {
    let additionalInfo: IAdditionalDeferralInfo = { deferredUntil: null, originalNote: null };

    try {
      additionalInfo = { ...additionalInfo, ...JSON.parse(note.additionalInfo) };
    } catch {}

    const ModalTrigger = ({ handleOpen }) => {
      const createdString = `Created: ${format(note.createdAt, 'DD/MM/YYYY')}`;

      const DeferralString = () => {
        if (!additionalInfo.deferredUntil) return null;

        return (
          <>
            <br />
            {`Deferred Until: ${quarterFormat(additionalInfo.deferredUntil)}`}
          </>
        );
      };

      return (
        <div className="button-group__button" onClick={handleOpen}>
          {createdString}
          <DeferralString />
        </div>
      );
    };

    const modalProps = {
      buttonText: 'Edit deferral note',
      isDisabled: false,
      title: 'Edit deferral note',
      trigger: ModalTrigger,
    };

    const unDeferOnEvent = (_event: React.SyntheticEvent) => {
      note.isMarkedForDestruction = true;

      actionPoint.canPerformAutomaticStateReversion(note);

      actionPoint.save();
    };

    return (
      <Column key={note.id} breakpointSize="mc" columnSpan={1} maxColumns={1}>
        <Frow itemAlignment="center">
          <Column breakpointSize="mc" columnSpan={4} maxColumns={5}>
            <ModalWithTrigger {...modalProps}>
              <DeferralForm {...{ actionPoint, note }} />
            </ModalWithTrigger>
          </Column>
          <Column breakpointSize="mc" columnSpan={1} maxColumns={5}>
            <button className="button button--secondary button--danger button--icon mar-l-1" onClick={unDeferOnEvent}>
              <i className="icon-bin icon-fw" />
            </button>
          </Column>
        </Frow>
      </Column>
    );
  });

  return (
    <div className={`button-group button-group--${theme.classNameModifier}`}>
      <Frow direction="row" additionalClassNames="frow--full-width">
        {selectableNotes}
      </Frow>
    </div>
  );
};

export default DeferralNotesList;
