import * as React from 'react';

const classNames = require('classnames');

import Presenter from './show/Presenter';

import Context from '../../Context';
import ReviewContext from './show/ReviewContext';
import { Review } from 'javascript/models';
import Alert from 'components/shared/Alert';

interface IProps {
  errorMessage: string;
  review: any;
  reviews: Review[];
  isLoading: boolean;
  handleBackClick(): void;
  handleReviewSelect(id: string): void;
  handleReviewUpdate(): void;
}

export default function show(props: IProps) {
  const context = React.useContext(Context);
  const currentUser = context.props.currentUser;

  const { errorMessage, handleBackClick, handleReviewSelect, handleReviewUpdate, reviews } = props;

  // Because we are transitioning this out (using react transition) these can be a point when there is no review but
  // this component is still on the page, so we need to have an id in that case
  const review = React.useMemo(() => (props.review ? props.review : { attributes: { statuses: [] } }), [props.review]);

  const selectableReviews = reviews.map((selectableReview) => {
    const buttonClasses = classNames('button-group__button', {
      'button-group__button--active': selectableReview.id === review.id,
    });
    function selectReview() {
      handleReviewSelect(selectableReview.id);
    }
    return (
      <div className={buttonClasses} key={selectableReview.id} onClick={selectReview}>
        {selectableReview.attributes.timePeriod}
      </div>
    );
  });

  const renderError = (
    <div className="platform-content platform-content--padding-all">
      <Alert
        type="error"
        title="Something has gone wrong..."
        message="This usually occurs when the system is unable to calculate performance metrics. Please check the
        returns and balances for this review date. Support have been notified of the error, but please contact them
        if the problem persists."
      />
    </div>
  );

  const contextValues = {
    actions: { handleReviewUpdate },
    props: { currentUser, review },
    state: {},
  };

  return (
    <ReviewContext.Provider value={contextValues}>
      <div className="frow">
        <div className="col-md-1-12">
          <div className="platform-panel__content platform-panel__content--padded platform-panel__content--right-bordered">
            <div className="link link--left-arrow" onClick={handleBackClick}>
              Back
            </div>
            <div className="button-group button-group--vertical mar-t-4">{selectableReviews}</div>
          </div>
        </div>
        <div className="col-md-11-12">{errorMessage ? renderError : <Presenter />}</div>
      </div>
    </ReviewContext.Provider>
  );
}
