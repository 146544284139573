import { Attr, Model } from 'spraypaint';
import { ApplicationRecord } from './ApplicationRecord';

@Model()
export class Commentary extends ApplicationRecord {
  public static jsonapiType = 'commentaries';
  @Attr() public body: string;
  @Attr() public companyId: number;
  @Attr() public commentaryType: string;
  @Attr() public title: string;
}
