import * as React from 'react';

import columns from './table/columns';
import GraphitiTableWrapper from 'components/shared/GraphitiTableWrapper';

import { EntityRelationshipRole } from 'javascript/models';

interface IProps {
  newPath?: string;
}

export default function table(props: IProps) {
  const { newPath } = props;
  const scope = EntityRelationshipRole.includes([]);

  return (
    <GraphitiTableWrapper
      scope={scope}
      columns={columns()}
      hasHeader
      isSearchable
      newPath={newPath}
      newText="Add Relationship Role"
      searchPlaceholder="Search by name"
      title="Entity Relationship Roles"
    />
  );
}
