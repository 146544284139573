import React from 'react';

import { Scope } from 'spraypaint';
import ReactSelect from 'components/shared/forms/ReactSelect';
import ISelectOption from 'components/interfaces/ISelectOption';

interface IContactFilter {
  scope: Scope;
  setContactsFilter: React.Dispatch<React.SetStateAction<number[]>>;
}

const ContactFilter = ({ scope, setContactsFilter }: IContactFilter) => {
  const [contactOptions, setContactOptions] = React.useState<ISelectOption[]>([]);

  const handleChange = (selectedOptions) => {
    if (selectedOptions) return setContactsFilter(selectedOptions.map((contactRecord) => contactRecord.value));
    setContactsFilter([]);
  };

  const getContactFilterOptions = async () => {
    const {
      meta: {
        stats: {
          whole_dataset: { all_contacts },
        },
      },
    } = await scope.selectExtra([]).stats({ wholeDataset: 'all_contacts' }).per(0).all();

    setContactOptions(
      all_contacts.data.map((contact: { first_name: string; last_name: string; id: number }) => ({
        label: `${contact.first_name} ${contact.last_name}`,
        value: contact.id,
      })),
    );
  };

  React.useEffect(() => {
    getContactFilterOptions();
  }, []);

  return (
    <ReactSelect
      options={contactOptions}
      theme="dark"
      id="contact-filter"
      name="contact-filter"
      handleChange={handleChange}
      isMulti
      placeholder="Filter by contact"
    />
  );
};

export default ContactFilter;
