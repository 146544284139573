import * as React from 'react';

const reactRailsUJS = require('react_ujs');

import OutsideClickHandler from 'react-outside-click-handler';

import { pipe, map, flatten, uniq, isNil, reject } from 'ramda';

import ClientSelect from './ClientSelect';
import QuarterSelect from '../../dashboard/reviewFilters/QuarterSelect';
import ClientTypeSelect from '../../dashboard/reviewFilters/ClientTypeSelect';
import JurisdictionSelect from './JurisdictionSelect';
import ManagerSelect, { IPropsDDO } from './ManagerSelect';

import { Client } from 'javascript/models';
import { Administrator } from 'javascript/models';
import { Portfolio } from 'javascript/models';

export interface IReviewFiltersProps {
  currentQuarter: number;
  currentUserID: number;
  currentYear: number;
  jurisdictions: { display: string; value: string }[];
  selectableClients: { display: string; icon: string; value: number }[];
  selectableClientTypes: { display: string; icon: string; value: number }[];
  selectableManagers: {}[];
  selectableQuarters: { display: string; value: string }[];
  handleRMAdminSelect(option): void;
}

export default function reviewFilters(props: IReviewFiltersProps) {
  const {
    currentQuarter,
    currentUserID,
    currentYear,
    jurisdictions,
    selectableClients,
    selectableClientTypes,
    selectableQuarters,
    handleRMAdminSelect,
  } = props;

  const retreiveRelationshipManagers = async (setState) => {
    const clientsRelationshipManagersPromise = Client.includes([
      'monitoring_relationship_manager',
      'advisory_relationship_manager',
    ]).all();

    const portfoliosRelationshipManagersPromise = Portfolio.includes(['portfolio_relationship_manager'])
      .where({ portfolio_relationship_manager_id: { gte: 0 } })
      .all();

    const { data: clientsRelationshipManagers } = await clientsRelationshipManagersPromise;
    const { data: portfoliosRelationshipManagers } = await portfoliosRelationshipManagersPromise;

    const extractRMsFromClient = pipe(
      map((client: Client) => [client.monitoringRelationshipManager, client.advisoryRelationshipManager]),
      flatten,
      reject(isNil),
    );

    const clientRMs = extractRMsFromClient(clientsRelationshipManagers);
    const portfolioRMs = map((portfolio) => portfolio.portfolioRelationshipManager, portfoliosRelationshipManagers);

    const convertRMsToDropdownOptions = pipe(
      map((relationshipManager: Administrator) => ({
        display: relationshipManager.fullName,
        value: relationshipManager.id,
      })),
      uniq,
    );

    setState([
      { display: 'All Relationship Managers', value: '*' },
      ...convertRMsToDropdownOptions([...portfolioRMs, ...clientRMs]),
    ]);
  };

  const [allRelationshipManagers, setAllRelationshipManagers] = React.useState<IPropsDDO[]>([
    { display: 'All Relationship Managers', value: '*' },
  ]);
  const [selectedRelationshipManager, setSelectedRelationshipManager] = React.useState<IPropsDDO>({
    display: 'All Relationship Managers',
    value: '*',
  });
  const [selectedClient, setSelectedClient] = React.useState({ display: 'All Clients', value: '*' });
  const [selectedClientType, setSelectedClientType] = React.useState('*');
  const [selectedJurisdiction, setSelectedJurisdiction] = React.useState({ display: 'All Jurisdictions', value: '*' });
  const [selectedQuarter, setSelectedQuarter] = React.useState({ quarter: currentQuarter, year: currentYear });
  const [isQuarterSelectOpen, setIsQuarterSelectOpen] = React.useState(false);

  React.useEffect(() => {
    retreiveRelationshipManagers(setAllRelationshipManagers);
    return;
  }, []);

  const overviewReady = React.useCallback(() => {
    dispatchEvent();
  }, []);

  React.useEffect(() => {
    document.addEventListener('dropdownOverview:ready', overviewReady);
    document.addEventListener('turbolinks:before-render', () => {
      reactRailsUJS.unmountComponents('.review-filters');
    });
    return () => {
      document.removeEventListener('dropdownOverview:ready', overviewReady);
    };
  }, []);

  React.useEffect(() => {
    dispatchEvent();
  }, [selectedClientType, selectedClient, selectedJurisdiction, selectedQuarter, selectedRelationshipManager]);

  function handleClientSelect(client) {
    setSelectedClient(client);
  }

  function handleJurisdictionSelect(jurisdiction: { display: string; value: string }) {
    setSelectedJurisdiction(jurisdiction);
  }

  function handleOutsideQuarterSelectClick() {
    setIsQuarterSelectOpen(false);
  }

  function handleQuarterSelectOpen() {
    setIsQuarterSelectOpen(!isQuarterSelectOpen);
  }

  function handleQuarterSelect(quarter: number, year: number) {
    setSelectedQuarter({ quarter, year });
    setIsQuarterSelectOpen(false);
  }

  function handleClientTypeSelect(value) {
    setSelectedClientType(value);
  }

  function handleManagerSelect(value) {
    handleRMAdminSelect(value);
    setSelectedRelationshipManager(value);
  }

  function dispatchEvent() {
    const customEvent = new CustomEvent('dropdownFilters:change', {
      detail: {
        ...selectedQuarter,
        admin: '*',
        client: selectedClient.value,
        client_type: selectedClientType,
        jurisdiction: selectedJurisdiction.value,
        relationship_manager: selectedRelationshipManager.value,
      },
    });
    document.dispatchEvent(customEvent);
  }

  return (
    <div className="frow frow--gutters-2x frow--items-center">
      <div>
        <p className="subtitle-two">Time Period</p>
        <OutsideClickHandler onOutsideClick={handleOutsideQuarterSelectClick}>
          <QuarterSelect
            {...{ currentQuarter, currentYear, isQuarterSelectOpen, selectedQuarter, selectableQuarters }}
            handleQuarterSelect={handleQuarterSelect}
            handleQuarterSelectOpen={handleQuarterSelectOpen}
          />
        </OutsideClickHandler>
      </div>
      <ClientSelect {...{ handleClientSelect, selectableClients }} />
      <JurisdictionSelect {...{ handleJurisdictionSelect, jurisdictions, selectedJurisdiction }} />
      <div>
        <p className="subtitle-two">Client Type</p>
        <ClientTypeSelect
          handleClientTypeSelect={handleClientTypeSelect}
          selectedClientType={selectedClientType}
          selectableClientTypes={selectableClientTypes}
        />
      </div>

      <ManagerSelect
        options={allRelationshipManagers}
        selectedOption={selectedRelationshipManager}
        handleSelect={handleManagerSelect}
      />
    </div>
  );
}
