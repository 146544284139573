import * as React from 'react';

import { keys } from 'ramda';

import { SecurityState, SecurityClassification, SecurityStateClassificationWeighting } from 'javascript/models';

import { useSpraypaintQueryAll } from 'components/shared/customHooks/useSpraypaintQuery';

import ClassificationSection from './classificationValuesSections/ClassificationSection';

interface IClassificationWeightingsSectionProps {
  selectedState: SecurityState;
}

const classificationWeightingsSection = (props: IClassificationWeightingsSectionProps) => {
  const { selectedState } = props;

  const staticDataScope = React.useMemo(
    () => SecurityStateClassificationWeighting.per(0).stats({ staticData: 'valueTypeClassificationClassMap' }),
    [],
  );
  const {
    meta: {
      stats: {
        static_data: { value_type_classification_class_map: typeMap },
      },
    },
  } = useSpraypaintQueryAll(staticDataScope, {
    meta: { stats: { static_data: { value_type_classification_class_map: {} } } },
  });

  const [groupedClassifications, setGroupedClassifications] = React.useState();

  React.useEffect(
    () => (SecurityClassification.allByType({ uiSelectable: true }).then(setGroupedClassifications), undefined),
    [],
  );

  const classificationTypes = keys(typeMap);

  const classificationSections = classificationTypes.map((classificationType) => (
    <ClassificationSection
      key={classificationType}
      {...{
        groupedClassifications,
        classificationType,
        selectedState,
        typeMap,
      }}
    />
  ));

  return <>{classificationSections}</>;
};

export default classificationWeightingsSection;
