import * as React from 'react';
import { platformSecurityPath } from './../../../../javascript/application/ts_routes';

export default function columns() {
  const finmasonLogo = require('../../../../images/logos/FINMASONlogo_transparentbg_crop.png').default;
  const enhanceLogo = require('../../../../images/logos/logo_grey_2.svg').default;

  const classifiedByLogo = (classifiedBy: string) => {
    switch (classifiedBy) {
      case 'fin_mason':
        return <img style={{ height: '30px', width: '30px' }} src={finmasonLogo} alt="finmason" />;
      case 'enhance':
        return <img style={{ height: '25px', width: '25px' }} src={enhanceLogo} alt="enhance" />;
      default:
        return '-';
    }
  };

  return [
    {
      Header: 'Name',
      accessor: 'name',
      columnName: 'Name',
      id: 'name',
    },
    {
      Header: 'ISIN',
      accessor: 'isin',
      columnName: 'isin',
      id: 'isin',
      sortable: false,
    },
    {
      Cell: (row) => {
        const classified: boolean = row.original.classified;
        return (
          <div className={`table-icon table-icon--${classified ? 'success' : 'warning'}`}>
            {classified ? 'Yes' : 'No'}
          </div>
        );
      },
      Header: 'Classified',
      accessor: 'classified',
      columnName: 'Classified',
      filterOptions: {
        key: 'is_classified',
        options: [
          { display: 'Yes', value: 'true' },
          { display: 'No', value: 'false' },
        ],
      },
      id: 'classified',
      sortable: false,
    },
    {
      Cell: (row) => {
        const currentState = row.original.currentState;
        const verified: boolean = currentState && currentState.verified;
        return (
          <div className={`table-icon table-icon--${verified ? 'success' : 'warning'}`}>{verified ? 'Yes' : 'No'}</div>
        );
      },
      Header: 'Verified',
      accessor: 'currentState.verified',
      columnName: 'verified',
      filterOptions: {
        key: 'is_verified',
        options: [
          { display: 'Yes', value: 'true' },
          { display: 'No', value: 'false' },
        ],
      },
      id: 'verified',
      sortable: false,
    },
    {
      Cell: (row) => {
        const currentState = row.original.currentState;
        const classifiedBy: string = currentState && currentState.classifiedBy;
        return <div>{classifiedByLogo(classifiedBy)}</div>;
      },
      Header: 'Classified By',
      accessor: 'currentState.classifiedBy',
      columnName: 'Classified By',
      filterOptions: {
        key: 'classified_by',
        options: [
          { display: 'FinMason', value: 'fin_mason' },
          { display: 'Enhance', value: 'enhance' },
          { display: 'Unclassified', value: 'unclassified' },
        ],
      },
      id: 'classifiedBy',
      sortable: false,
    },
    {
      Cell: (row) => {
        return (
          <a href={platformSecurityPath(row.original.id)} style={{ display: 'block' }}>
            <div className="rt-tr-clickable-icon">
              <i className="icon-arrow-right-top icon-0-8x" />
            </div>
          </a>
        );
      },
      Header: '',
      sortable: false,
    },
  ];
}
