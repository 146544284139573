import React from 'react';

const PermissionCheckbox = ({ permissionable, canEditPermissions, permissionGroup, permissionType }) => {
  const [checked, setChecked] = React.useState(permissionable.hasPermission(permissionType));
  const handleCheckboxClick = () => {
    setChecked(permissionable.togglePermission(permissionType));
  };
  return (
    <div className="col-md-1-2">
      <div className="pretty pretty--with-label p-icon">
        <input type="checkbox" checked={checked} onChange={handleCheckboxClick} disabled={!canEditPermissions} />
        <div className="state p-blue">
          <i className="icon-tick icon icon-push-down-small" />
          <label className="text-white">{permissionGroup[permissionType].description}</label>
        </div>
      </div>
    </div>
  );
};

export default PermissionCheckbox;
