/* eslint-disable  */
import { Attr, BelongsTo, Model } from 'spraypaint';
import { ApplicationRecord } from './ApplicationRecord';
import { ReportTemplate } from './ReportTemplate';

@Model()
export class ReportTemplateSection extends ApplicationRecord {
  public static jsonapiType = 'report_template_sections';
  @Attr() public name: string;
  @Attr() public sectionType: string;
}

// tslint:disable-next-line: max-classes-per-file
@Model()
export class ReportTemplatesReportTemplateSection extends ApplicationRecord {
  public static jsonapiType = 'report_templates_report_template_sections';
  public static legendOptions = [
    { value: 'bottom', label: 'Bottom' },
    { value: 'right', label: 'Right' },
  ];
  public static legendKeys = [
    { value: 'enhance_reference', label: 'Enhance Reference' },
    { value: 'portfolio_name', label: 'Portfolio Name' },
    { value: 'entity_name', label: 'Entity Name' },
    { value: 'investment_manager', label: 'Investment Manager' },
    { value: 'manager_reference', label: 'Manager Reference' },
  ];
  public static barChartOptions = [
    { value: 'return', label: 'Monthly Returns' },
    { value: 'discrete', label: 'Discrete Returns' },
  ];
  public static barChartSubOptions = [
    { value: 'qtd', label: 'QTD' },
    { value: 'ytd', label: 'YTD' },
    { value: '1_year', label: '1 Year' },
    { value: '3_years', label: '3 Years' },
    { value: '5_years', label: '5 Years' },
    { value: 'inception', label: 'Inception (Ann.)' },
    { value: 'last_year', label: 'Last Year' },
    { value: '2_yrs_ago', label: '2 Yrs Ago' },
    { value: '3_yrs_ago', label: '3 Yrs Ago' },
    { value: '4_yrs_ago', label: '4 Yrs Ago' },
    { value: '5_yrs_ago', label: '5 Yrs Ago' },
  ];
  public static horizontalBarOptions = [
    { value: 'saa_over_under', label: 'SAA over / under weight' },
    { value: 'taa_ranges', label: 'TAA Ranges' },
  ];

  public static listOptions = [
    { value: 'additional_benchmark', label: 'Additional Benchmark' },
    { value: 'admin_system_reference', label: 'Admin System Reference' },
    { value: 'admin_system', label: 'Admin System' },
    { value: 'amc', label: 'AMC' },
    { value: 'assets_under_management', label: 'AUM' },
    { value: 'base_currency', label: 'Base Currency' },
    { value: 'benchmark_used', label: 'Benchmark Used' },
    { value: 'cash', label: 'Cash' },
    { value: 'consolidated_risk_profile', label: 'Consolidated Risk Profile' },
    { value: 'custodian_reference', label: 'Custodian Reference' },
    { value: 'custodian', label: 'Custodian' },
    { value: 'data_frequency', label: 'Data Frequency' },
    { value: 'data_points', label: 'Data Points' },
    { value: 'document_dated', label: 'Document Dated' },
    { value: 'document_type', label: 'Reference Document' },
    { value: 'enhance_benchmark', label: 'Enhance Benchmark' },
    { value: 'equity', label: 'Equity' },
    { value: 'fixed_income', label: 'Fixed Income' },
    { value: 'gross_leverage_ratio_percentage', label: 'Gross Leverage Ratio (%)' },
    { value: 'gross_leverage_ratio', label: 'Gross Leverage Ratio' },
    { value: 'inception_date', label: 'Inception Date' },
    { value: 'inception_value', label: 'Inception Value' },
    { value: 'individual_risk_profile', label: 'Individual Risk Profile' },
    { value: 'jobstream_number', label: 'Jobstream Number' },
    { value: 'manager_benchmark', label: 'Manager`s Benchmark Proxy' },
    { value: 'manager_contact', label: 'Manager Contact' },
    { value: 'manager', label: 'Manager' },
    { value: 'net_leverage_ratio_percentage', label: 'Net Leverage Ratio (%)' },
    { value: 'net_leverage_ratio', label: 'Net Leverage Ratio' },
    { value: 'ocf', label: 'OCF' },
    { value: 'peer_group_high', label: 'Peer Group High' },
    { value: 'peer_group_index', label: 'Peer Group Index' },
    { value: 'peer_group_low', label: 'Peer Group Low' },
    { value: 'peer_group_med', label: 'Peer Group Medium' },
    { value: 'primary_contact', label: 'Primary Contact' },
    { value: 'rating', label: 'Rating' },
    { value: 'reference', label: 'Portfolio Reference' },
    { value: 'review_period', label: 'Review Period' },
    { value: 'risk_profile', label: 'Risk Profile' },
    { value: 'secondary_contact', label: 'Secondary Contact' },
    { value: 'service', label: 'Service' },
    { value: 'structure_service_type', label: 'Structure Service Type' },
    { value: 'target_return', label: 'Target Return Benchmark' },
    { value: 'ter', label: 'TER' },
    { value: 'value', label: 'Value' },
    { value: 'additional_portfolio_attribute', label: 'Additional Portfolio Attribute' },
  ];
  public static mapOptions = [
    { value: 'country_of_domicile', label: 'Country of Domicile' },
    { value: 'country_of_risk', label: 'Country of Risk' },
  ];
  public static scatterTypeOptions = [
    { value: 'risk_return', label: 'Risk-Return' },
    { value: 'up_down_capture', label: 'Up-Down Capture' },
  ];
  public static tableOptions = [
    { value: 'custom', label: 'Custom' },
    { value: 'asset_movements', label: 'Asset Movements' },
    { value: 'sharpe', label: 'Sharpe Ratio' },
    { value: 'beta', label: 'Beta Analysis' },
    { value: 'alpha', label: "Jenson's Alpha" },
    { value: 'sortino', label: 'Sortino' },
    { value: 'correlation', label: 'Correlation' },
    { value: 'capture_ratio', label: 'Capture Ratio' },
    { value: 'mandate_positioning', label: 'Mandate Positioning' },
    { value: 'comparison', label: 'Comparison' },
    { value: 'net_gross_movements', label: 'Net/Gross Movements' },
    { value: 'private_assets', label: 'Private Assets' },
    { value: 'traditional_vs_private_summary', label: 'Traditional & Private Summary' },
  ];
  public static typeOptions = [
    { value: 'return', label: 'Return' },
    { value: 'value', label: 'Value' },
    { value: 'drawdown', label: 'Drawdown' },
    { value: 'excess_return', label: 'Excess Return' },
    { value: 'net_gross', label: 'Net/Gross' },
  ];
  public static longTimePeriodOptions = [
    { value: '1_year', label: '1 Year' },
    { value: '3_years', label: '3 Years' },
    { value: '5_years', label: '5 Years' },
    { value: 'inception', label: 'Since Inception' },
    { value: 'review_period', label: 'Review Period' },
  ];
  public static multiPieLayerOptions = [
    { value: 'asset_class', label: 'Asset Class' },
    { value: 'instrument', label: 'Instrument' },
    { value: 'industry', label: 'Industry' },
    { value: 'country_of_domicile', label: 'Country of Domicile' },
    { value: 'country_of_risk', label: 'Country of Risk' },
    { value: 'currency', label: 'Currency' },
    { value: 'dividend_distribution_policy', label: 'Dividend Distribution' },
    { value: 'credit_rating', label: 'Credit Rating' },
    { value: 'sub_instrument', label: 'Sub Instrument' },
  ];
  public static portfolioInformationOptions = [
    { value: 'mandate', label: 'Mandate' },
    { value: 'manager', label: 'Manager' },
    { value: 'consolidated', label: 'Consolidated' },
  ];
  public static timePeriodOptions = [
    { value: '3_years', label: '3 Years' },
    { value: 'inception', label: 'Since Inception' },
    { value: 'ytd', label: 'YTD' },
    { value: '1_year', label: '1 Year' },
    { value: '5_years', label: '5 Years' },
    { value: 'review_period', label: 'Review Period' },
  ];
  public static sizeOptions = [
    { value: 'small', label: 'Small' },
    { value: 'medium', label: 'Medium' },
    { value: 'large', label: 'Large' },
  ];
  public static consolidatedTargetOptions = [
    { value: 'consolidated_portfolio', label: 'Consolidated Portfolio' },
    { value: 'constituent_portfolio_1', label: 'Constituent Portfolio 1' },
    { value: 'constituent_portfolio_2', label: 'Constituent Portfolio 2' },
    { value: 'constituent_portfolio_3', label: 'Constituent Portfolio 3' },
    { value: 'constituent_portfolio_4', label: 'Constituent Portfolio 4' },
  ];
  public static constituentTargetOptions = [{ value: 'constituent_portfolio', label: 'Constituent Portfolio' }];
  public static targetOptions = [{ value: 'portfolio', label: 'Portfolio' }];
  public static multiPietargetOptions = [
    { value: 'portfolio', label: 'Portfolio' },
    { value: 'fixed_income', label: 'Fixed Income' },
    { value: 'equity', label: 'Equity' },
    { value: 'hedge_fund', label: 'Hedge Fund' },
    { value: 'other', label: 'Other' },
  ];
  public static pieChartTypeOptions = [
    { value: 'allocation', label: 'Allocation' },
    { value: 'currency', label: 'Currency' },
    { value: 'saa', label: 'Strategic Asset Allocation' },
    { value: 'cluster', label: 'Cluster' },
    { value: 'instrument', label: 'Instrument Type' },
    { value: 'domicile', label: 'Country of Domicile' },
    { value: 'risk', label: 'Country of Risk' },
    { value: 'industry', label: 'Industry' },
    { value: 'distribution_policy', label: 'Distribution Policy' },
    { value: 'manager_split', label: 'Manager split' },
    { value: 'additional_attribute', label: 'Additinal Holdings Attribute' },
    { value: 'private_assets', label: 'Private Assets' },
  ];
  public static seriesOptions = [
    'Portfolio',
    'Benchmark 1',
    'Benchmark 2',
    'Benchmark 3',
    'Peer Group',
    'Peer Group Low',
    'Peer Group Med.',
    'Peer Group High',
    'Cash',
    'Fixed Income',
    'Equity',
  ];
  @Attr() public height: number;
  @Attr() public reportTemplateId: string;
  @Attr() public reportTemplateSectionId: string;
  @Attr() public reportTemplateRowId: string;
  @Attr() public legendPosition: string;
  @Attr() public position: number;
  @Attr() public subtitle: string;
  @Attr() public size: string;
  @Attr() public text: string;
  @Attr() public componentId: string;
  // Spacing
  @Attr() public bottomSpacing: boolean;
  @Attr() public topSpacing: boolean;
  // Theming
  @Attr() public color: string;
  @Attr() public width: number;
  // Area Chart
  @Attr() public areaChartDetails: { target: string; type: string; timePeriod: string };
  @Attr({ persist: false }) public areaChartTarget: string;
  @Attr({ persist: false }) public areaChartType: string[];
  @Attr({ persist: false }) public areaChartTimePeriod: string;
  // Bar Chart
  @Attr() public barChartDetails: { series: string[]; timePeriod: string; type: string };
  @Attr({ persist: false }) public barChartDiscretePeriods: string;
  @Attr({ persist: false }) public barChartSeries: string[];
  @Attr({ persist: false }) public barChartTarget: string;
  @Attr({ persist: false }) public barChartType: string;
  @Attr({ persist: false }) public barChartLabel: string;
  @Attr({ persist: false }) public barChartTimePeriod: string;
  // Correlation Matrix
  @Attr() public correlationDetails: { series: string[] };
  @Attr({ persist: false }) public correlationSeries: string[];
  @Attr({ persist: false }) public correlationTimePeriod: string[];
  // Scatter Chart
  @Attr() public scatterChartDetails: { series: string[]; type: string };
  @Attr({ persist: false }) public scatterChartSeries: string[];
  @Attr({ persist: false }) public scatterChartType: string;
  @Attr({ persist: false }) public scatterChartTimePeriod: string;
  // Table
  @Attr() public tableDetails: { rows: string[]; columns: string[] };
  @Attr({ persist: false }) public tableColumns: string[];
  @Attr({ persist: false }) public tableComparison: string[];
  @Attr({ persist: false }) public tableComparisonReturnOptions: string[];
  @Attr({ persist: false }) public tableHeaderDisplay: boolean;
  @Attr({ persist: false }) public tableRows: string[];
  @Attr({ persist: false }) public tableSeries: string[];
  @Attr({ persist: false }) public tableTimePeriod: string[];
  @Attr({ persist: false }) public tableType: string;
  @Attr({ persist: false }) public tableColumnNameFormat: string;
  // Line Chart
  @Attr() public lineChartDetails: { series: string[]; timePeriod: string; type: string };
  @Attr({ persist: false }) public lineChartSeries: string[];
  @Attr({ persist: false }) public lineChartType: string;
  @Attr({ persist: false }) public lineChartTimePeriod: string;
  @Attr({ persist: false }) public lineChartComparisonMeasure: string;
  // List
  @Attr() public listDetails: { listItems: string[] };
  @Attr({ persist: false }) public listItems: string[];
  @Attr({ persist: false }) public listItemParameters: { [key: number]: any };
  // Pie Chart
  @Attr() public pieChartDetails: { target: string; type: string };
  @Attr({ persist: false }) public pieChartTarget: string;
  @Attr({ persist: false }) public pieChartType: string[];
  @Attr({ persist: false }) public pieChartLayers: string[];
  // General Section
  @Attr() public sectionDetails: { type: string };
  @Attr({ persist: false }) public sectionType: string[];

  // Associations
  @BelongsTo() public reportTemplateSection: ReportTemplateSection;
  @BelongsTo() public reportTemplate: ReportTemplate;
}
