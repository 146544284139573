import * as React from 'react';

const classNames = require('classnames');

import { Alert, Intent } from '@blueprintjs/core';

import Edit from './section/Edit';
import { sectionDisplay } from '../sectionDisplay';
import useVisibility from 'components/shared/customHooks/useVisibility';
import Context from '../Context';

export default function section({ canEdit, dummyContainerRef, row, templateSection }) {
  const context = React.useContext(Context);
  const { handleDestroy } = context.actions;
  const { reportTemplate } = context.state;
  const sectionRef = React.useRef(null);

  const { isOpen, handleClose, handleOpen } = useVisibility(false);

  function confirmDelete() {
    handleDestroy(templateSection);
  }

  const uneditableSectionTypes = ['blank_page_filler', 'market_commentary'];

  const sectionNeedsEditing = !uneditableSectionTypes.includes(templateSection.reportTemplateSection.sectionType);

  let colors = ['#3990a8', '#5d5f6b', '#b5b6bd', '#78ae6e', '#CFB538'];

  if (reportTemplate) {
    const theme = reportTemplate.reportTemplateTheme;
    colors = [
      theme.secondaryColorOne,
      theme.secondaryColorTwo,
      theme.secondaryColorThree,
      theme.secondaryColorFour,
      theme.secondaryColorFive,
    ];
  }

  const editableSectionClasses = classNames(
    'h-100',
    { 'editable-section': canEdit },
    { 'editable-section--disabled': !canEdit },
  );

  return (
    <div key={templateSection.id} className={editableSectionClasses}>
      <div className="editable-section__popout">
        {sectionNeedsEditing && <Edit {...{ dummyContainerRef, row, templateSection }} />}
        <div className="button button--secondary button--danger button--icon" onClick={handleOpen}>
          <i className="icon-bin icon-fw" />
        </div>
      </div>
      <div ref={sectionRef}>
        {sectionDisplay(templateSection.reportTemplateSection.sectionType, templateSection, colors, sectionRef)}
      </div>
      <Alert
        isOpen={isOpen}
        onClose={handleClose}
        canEscapeKeyCancel
        canOutsideClickCancel
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        intent={Intent.DANGER}
        onCancel={handleClose}
        onConfirm={confirmDelete}
        portalContainer={document.body}
      >
        <p className="mar-t-0">Are you sure you want to delete this section?</p>
      </Alert>
    </div>
  );
}
