import * as React from 'react';

import Attributes from './Attributes';
import Columns from './Columns';
import FormRow from 'components/shared/forms/FormRow';
import Loading from 'components/shared/Loading';

import { UploadTemplateSheet } from 'javascript/models';

interface IProps {
  uploadTemplateSheet: UploadTemplateSheet;
  handleDestroySheet(sheetId: string): void;
}

export default function templateSheet(props: IProps) {
  const { handleDestroySheet } = props;
  const [uploadTemplateSheet, setUploadTemplateSheet] = React.useState(props.uploadTemplateSheet);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    setUploadTemplateSheet(props.uploadTemplateSheet);
  }, [props.uploadTemplateSheet]);

  function handleChange(event) {
    if (!event.target.value) return;

    const id = event.target.id;
    if (id === 'name') {
      uploadTemplateSheet.name = event.target.value;
    }

    if (id === 'startRow') {
      uploadTemplateSheet.startRow = event.target.value;
      setIsLoading(true);
    }

    updateSheet().then(() => setIsLoading(false));
  }

  async function updateSheet() {
    const success = await uploadTemplateSheet.save().then(() => {
      getSheet(uploadTemplateSheet.id);
    });
  }

  async function getSheet(id) {
    const { data } = await UploadTemplateSheet.includes([
      'upload_template_additional_attributes',
      { upload_template_sheet_columns: 'upload_template_conditions' },
    ]).find(id);
    setUploadTemplateSheet(data);
  }

  function handleDestroy() {
    handleDestroySheet(uploadTemplateSheet.id);
  }

  if (isLoading) return <Loading />;

  return (
    <div className="platform-content platform-content--padding-bottom">
      <FormRow>
        <label htmlFor="" className="form__label">
          Sheet name
        </label>
        <input type="text" defaultValue={uploadTemplateSheet.name} onBlur={handleChange} id="name" name="name" />
      </FormRow>
      <FormRow
        className="col-mc-1-1"
        note="This will usually be 1 but in some cases we might need to start from a different row. Changing this
              will reparse the example file if one is attached."
      >
        <label htmlFor="startRow" className="form__label">
          Start row
        </label>
        <input type="number" defaultValue={uploadTemplateSheet.startRow} onBlur={handleChange} id="startRow" />
      </FormRow>
      <div className="mar-t-2">
        <Columns {...{ uploadTemplateSheet }} />
      </div>
      <div className="mar-t-2">
        <Attributes {...{ uploadTemplateSheet }} />
      </div>
      <button className="button button--secondary button--danger mar-t-2" onClick={handleDestroy}>
        Remove Sheet
      </button>
    </div>
  );
}
