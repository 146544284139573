import * as React from 'react';

const numeral = require('numeral');

export default function quarterlyReturnMetric({ isLoading, lastQuarterReturn }) {
  if (isLoading) {
    return (
      <div className="metric">
        <p className="metric__title">Return (Last period)</p>
        <p className="metric__value" />
      </div>
    );
  }

  function renderReturn() {
    if (lastQuarterReturn == 0) {
      return (
        <React.Fragment>
          <p className="metric__value" />-
        </React.Fragment>
      );
    }
    return <p className="metric__value">{numeral(lastQuarterReturn).format('0,0.00')}%</p>;
  }

  return (
    <div className="metric">
      <p className="metric__title">Return (Last Quarter)</p>
      {renderReturn()}
    </div>
  );
}
