import * as React from 'react';
import * as $ from 'jquery';

import { ReviewReport } from 'javascript/models';
import ReviewContext from './ReviewContext';
import CsrfToken from 'components/shared/CsrfToken';

import { platformReviewReviewReportsPath } from 'javascript/application/ts_routes';
import { createAlert } from 'components/shared/Utils';
import { Intent, Menu, MenuItem, Popover, Position } from '@blueprintjs/core';
import Button from 'components/shared/forms/Button';

export default function generatedReports() {
  const reviewContext = React.useContext(ReviewContext);
  const { review } = reviewContext.props;
  const { status } = review.attributes;
  const { id } = review;
  const { handleReviewUpdate } = reviewContext.actions;

  const [isLoading, setIsLoading] = React.useState(true);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const [reports, setReports] = React.useState([]);
  const [ungeneratedReports, setUngeneratedReports] = React.useState([]);

  React.useEffect(() => {
    getData();
  }, [review]);

  const isDisabled = !['ready_to_send', 'sent'].includes(status);

  async function getData() {
    const { data } = await ReviewReport.where({ review_id: review.id, viewing_type: 'live' })
      .selectExtra(['name'])
      .all();

    const pendingReports = data.filter((report) => !report.reportGenerated);

    setUngeneratedReports(pendingReports);
    setIsLoading(false);
    setReports(data);
  }

  React.useEffect(() => {
    if (ungeneratedReports.length > 0) {
      ungeneratedReports.forEach((report) => {
        setTimeout(() => {
          getSingleReport(report);
        }, 10000);
      });
    } else {
      const customEvent = new CustomEvent('reviews:status:update');
      document.dispatchEvent(customEvent);
    }
  }, [ungeneratedReports]);

  async function getSingleReport(report, iterCount = 0) {
    const { data } = await ReviewReport.selectExtra(['name']).find(report.id);

    if (data.reportGenerated) {
      getData();
    } else if (iterCount < 60) {
      setTimeout(() => {
        getSingleReport(data, iterCount + 1);
      }, 10000);
    }
  }

  function handleGenerateReports() {
    $.ajax({
      beforeSend: () => setIsSubmitting(true),
      cache: false,
      complete: () => setIsSubmitting(false),
      data: { authenticity_token: CsrfToken() },
      dataType: 'json',
      error: () => setIsSubmitting(false),
      success: () => {
        createAlert('success', 'Reports are being regenerated', 1500);
        setIsSubmitting(false);
        handleReviewUpdate();
      },
      type: 'POST',
      url: platformReviewReviewReportsPath(id),
    });
  }

  const customLoader = (
    <div className="frow">
      <span>
        <i className="fas fa-circle-notch fa-spin text-brand-windows-blue mar-r-1" />
      </span>
      <span className="text-white">Report generating...</span>
    </div>
  );

  const generatedReports = reports.map((report) => {
    function renderViewReport() {
      if (!report.reportGenerated) return customLoader;

      return (
        <a href={report.reportPath} target="_blank" className="card__link mar-t-1">
          View Report
          <i className="icon-arrow-right-top icon-1x icon-fw mar-l-1" />
        </a>
      );
    }

    async function handleDelete() {
      const success = await report.destroy();

      if (success) getData();
    }

    return (
      <div className="col-md-1-3" key={report.id}>
        <div className="card">
          <div className="card__inner">
            <div className="frow frow--items-start frow--justify-between frow--nowrap">
              <p className="status-indicator status-indicator--sage mar-v-0" />
              <div style={{ cursor: 'pointer' }}>
                <Popover usePortal={false} position={Position.BOTTOM}>
                  <div className="mar-r-1">
                    <i className="icon-overflow-horizontal-white icon text-white" />
                  </div>
                  <Menu>
                    {/*<MenuItem text="Edit Filename" />*/}
                    <MenuItem text="Delete" intent={Intent.DANGER} onClick={handleDelete} />
                  </Menu>
                </Popover>
              </div>
            </div>
            <p className="text-white text-small mar-t-1">{report.filename}</p>
            <p className="text-white text-large mar-t-4">{report.name}</p>
            {renderViewReport()}
          </div>
        </div>
      </div>
    );
  });

  const noReports = <p className="text-white">There are no generated reports</p>;

  return (
    <div
      className="platform-content platform-content--border-bottom platform-content--spacing-horizontal
      platform-content--padding-bottom platform-content--padding-vertical"
    >
      <div className="frow frow--gutters">
        <div className="col-mc-1-1">
          <div className="frow frow--items-center frow--justify-between">
            <div className="col-md-3-5 col-lg-4-5">
              <p className="platform-panel__title">Generated Reports</p>
            </div>
            <div className="col-md-2-5 col-lg-1-5 text-right">
              <div>
                <Button
                  buttonClasses="button--secondary"
                  isDisabled={isDisabled || reports.some((report) => !report.reportGenerated)}
                  isSubmitting={isSubmitting}
                  handleClick={handleGenerateReports}
                  submittingText="Regenerating Reports"
                  text=" Regenerate Reports"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-mc-1-1">
          <div className="frow frow--gutters">{!isLoading && reports.length > 0 ? generatedReports : noReports}</div>
        </div>
      </div>
    </div>
  );
}
