import * as React from 'react';

import Table from '../portfolios/Table';

interface IProps {
  userId: string;
  clientTypes: string[];
  currencies: string[];
  isExportable: boolean;
  riskOptions: string[];
  statuses: string[];
}

export default function show(props: IProps) {
  const { userId, clientTypes, currencies, isExportable, riskOptions, statuses } = props;

  const defaultScope = { primary_contact_id: userId };

  const [currentTable, setCurrentTable] = React.useState('primary');
  const [selectedTab, setSelectedTab] = React.useState<'primary' | 'secondary' | 'emap'>('primary');
  const [scope, setScope] = React.useState<{ [key: string]: string }>(defaultScope);

  function handleTabSelect() {
    setSelectedTab(this);
  }

  React.useEffect(() => {
    if (selectedTab === 'primary') {
      setScope(defaultScope);
    }
    if (selectedTab === 'secondary') {
      setScope({ secondary_contact_id: userId });
    }
    if (selectedTab === 'emap') {
      setScope({ emap_contact_id: userId });
    }
  }, [selectedTab]);

  React.useEffect(() => {
    setCurrentTable(selectedTab);
  }, [scope]);

  function renderTable() {
    if (currentTable === 'primary') {
      return (
        <Table key={1} {...{ clientTypes, currencies, isExportable, riskOptions, scope, statuses }} hasHeader={false} />
      );
    }
    if (currentTable === 'secondary') {
      return (
        <Table key={2} {...{ clientTypes, currencies, isExportable, riskOptions, scope, statuses }} hasHeader={false} />
      );
    }
    if (currentTable === 'emap') {
      return (
        <Table key={3} {...{ clientTypes, currencies, isExportable, riskOptions, scope, statuses }} hasHeader={false} />
      );
    }
  }

  const classes = 'tabbed-nav__tab col-flex-grow-1 tabbed-nav__tab--right-bordered';

  return (
    <div className="platform-panel">
      <div className="tabbed-nav">
        <div
          className={`${classes} ${selectedTab === 'primary' ? 'tabbed-nav__tab--active' : ''}`}
          onClick={handleTabSelect.bind('primary')}
        >
          <span className="text-bold letter-spacing-1">Primary Portfolios</span>
        </div>
        <div
          className={`${classes} ${selectedTab === 'secondary' ? 'tabbed-nav__tab--active' : ''}`}
          onClick={handleTabSelect.bind('secondary')}
        >
          <span className="text-bold letter-spacing-1">Secondary Portfolios</span>
        </div>
        <div
          className={`${classes} ${selectedTab === 'emap' ? 'tabbed-nav__tab--active' : ''}`}
          onClick={handleTabSelect.bind('emap')}
        >
          <span className="text-bold letter-spacing-1">EMAP Portfolios</span>
        </div>
      </div>
      <div className="platform-content platform-content--padding-all">{renderTable()}</div>
    </div>
  );
}
