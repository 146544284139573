import * as React from 'react';
import * as $ from 'jquery';

import Alert from 'components/shared/Alert';

import Loading from 'components/shared/Loading';
import { ReviewReport } from 'javascript/models';
import { ajaxErrorString } from 'components/shared/Utils';

export default function preview({ url }) {
  const [error, setError] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [reviewReport, setReviewReport] = React.useState(null);
  const [reviewPath, setReviewPath] = React.useState(null);

  React.useEffect(() => {
    if (reviewReport) {
      setTimeout(() => {
        getReviewReport();
      }, 10000);
    }
  }, [reviewReport]);

  React.useEffect(() => {
    $.ajax({
      dataType: 'json',
      error: (jqXhr) => setError(ajaxErrorString(jqXhr)),
      success: (response) => setReviewReport(response),
      type: 'GET',
      url,
    });
  }, []);

  async function getReviewReport() {
    const { data } = await ReviewReport.find(reviewReport.id);

    if (data.reportGenerated) {
      setReviewPath(data.reportPath);
      setIsLoading(false);
    } else {
      setTimeout(() => {
        getReviewReport();
      }, 5000);
    }
  }

  const loading = (
    <Loading
      size="lg"
      text="Generating Preview. Please do not leave the page (you can use another tab to continue using the app)."
      flexDirection="column"
    />
  );

  const previewCard = (
    <div className="frow">
      <div className="col-md-1-3">
        <a href={reviewPath} className="card card--clickable">
          <div className="card__inner">
            <p className="card__title">View Preview</p>
          </div>
        </a>
      </div>
    </div>
  );

  const errorAlert = (
    <div>
      <Alert message={error} title="Unable to generate preview" type="error" modifier="no-spacing" />
      <div className="link link--left-arrow" onClick={window.close}>
        Close Tab
      </div>
    </div>
  );

  return (
    <section className="platform-panel">
      <div className="platform-content platform-content--padding-all">
        {error ? errorAlert : isLoading ? loading : previewCard}
      </div>
    </section>
  );
}
