import * as React from 'react';

import GraphitiTableWrapper from 'components/shared/GraphitiTableWrapper';
import PrivateAssetsColumns from './PrivateAssetsColumns';

import { PrivateAsset } from 'javascript/models';
import { Portfolio } from 'javascript/models';
import PrivateAssetForm, { IPrivateAssetFormProps } from './privateAssets/PrivateAssetForm';

export interface IPrivateAssetsProps {
  portfolio: Portfolio;
}

export interface ICategoryOptions {
  [key: string]: ISubCategoryOptions;
}

export interface ISubCategoryOptions {
  [key: string]: number;
}

export default function privateAssets(props: IPrivateAssetsProps) {
  const { portfolio } = props;

  const [shouldUpdateTable, setShouldUpdateTable] = React.useState(false);
  const [categoryOptions, setCategoryOptions] = React.useState();

  async function getCategoryOptions() {
    const data = await PrivateAsset.per(0).stats({ options: 'sub_categories' }).all();

    setCategoryOptions(data.meta.stats.options.sub_categories);
  }

  React.useEffect(() => {
    getCategoryOptions();
  }, []);

  const triggerTableUpdate = () => {
    setShouldUpdateTable(true);
  };

  React.useEffect(() => {
    if (shouldUpdateTable) setShouldUpdateTable(false);
  }, [shouldUpdateTable]);

  const scope = PrivateAsset.includes(['balances', 'current_value'])
    .where({ portfolio_id: portfolio.id })
    .order({ category: 'desc' })
    .order({ sub_category: 'desc' });

  const privateAssetFormProps: IPrivateAssetFormProps = {
    buttonContent: 'Add New Private Asset',
    categoryOptions,
    modalTitle: 'Add New Private Asset',
    portfolioId: portfolio.id,
    triggerTableUpdate,
  };

  const buttonContent = <i className="icon-edit icon-fw" />;

  const tablePrivateAssetFormProps: IPrivateAssetFormProps = {
    ...privateAssetFormProps,
    buttonContent,
    buttonIcon: true,
    modalTitle: 'Edit Private Asset',
  };

  return (
    <>
      <div className="platform-panel platform-panel--no-background frow frow--justify-end mar-b-0">
        <PrivateAssetForm {...privateAssetFormProps} />
      </div>
      <GraphitiTableWrapper
        scope={scope}
        columns={PrivateAssetsColumns(tablePrivateAssetFormProps)}
        isSearchable={false}
        hasHeader={false}
        hasColumnSelector={false}
        shouldUpdateTable={shouldUpdateTable}
      />
    </>
  );
}
