import React, { useState, useEffect } from 'react';
import { Frow } from 'components/frow';

type Props = {
  onToggle?: (value: boolean) => void;
  label: string;
  checked: boolean;
  param?: string;
  classNames?: string;
};

export default function Checkbox(props: Props) {
  const { label, onToggle, param, classNames } = props;
  const [checked, setChecked] = useState(props.checked);

  useEffect(() => {
    setChecked(props.checked);
  }, [props.checked]);

  function onClick() {
    if (onToggle) {
      onToggle(!checked);
    }
    setChecked(!checked);
  }

  return (
    <Frow className={classNames}>
      <span className="pretty pretty--table p-icon p-smooth mar-r-0">
        <input type="checkbox" onChange={() => onClick()} checked={checked} />
        {param && <input type="hidden" name={param} value={checked ? 1 : 0} />}
        <span className="state p-primary frow frow--items-center" style={{ alignItems: 'center' }}>
          <i className="icon fa fa-check" />
          <label className="text-white">{label}</label>
        </span>
      </span>
    </Frow>
  );
}
