import * as React from 'react';

import { format, getQuarter, getYear } from 'date-fns';
import classNames from 'classnames';
import { startCase, truncate } from 'lodash';

import { ActionPoint, Client } from 'javascript/models';
import { portalClientPortfolioActionPointPath } from 'javascript/application/ts_routes';

import { Frow } from 'components/frow';

import { grey90 } from 'components/shared/colours';
import QuarterIndicator from 'components/shared/QuarterIndicator';

export interface IActionPointCardProps {
  actionPoint: ActionPoint;
  width: string;
  client: Pick<Client, 'id' | 'managesActionPointsInPortal'>;
}

const ActionPointCard = ({ client, actionPoint, width }: IActionPointCardProps) => {
  const {
    id,
    body,
    createdAt,
    firstIncludedDate,
    commentCount,
    documentCount,
    status,
    assignee,
    portfolio,
    entityName,
  } = actionPoint;

  const cardStyles = {
    background: grey90,
    borderRadius: '2px',
    height: '305px',
    marginRight: '20px',
    minWidth: width,
    width,
  };

  function systemReference(portfolio) {
    const systemref = portfolio.admin_system_reference ? `(${portfolio.admin_system_reference})` : '';
    return <span className="text-white mar-b-2 text-small">{systemref}</span>;
  }

  const AssigneeDisplay = ({ assigneeName }: { assigneeName: string }) => (
    <div
      className="user-icon mar-h-0"
      data-letter={assigneeName[0]}
      data-for="action-point-icon"
      data-tip={`Assigned to: ${assigneeName}`}
    />
  );

  const tagClasses = classNames(
    'tag',
    'mar-l-0',
    { 'tag--red': status === 'inProgress' },
    { 'tag--orange': status === 'inReview' },
    { 'tag--mid-green': status === 'released' },
  );

  const assigneeJSX = client.managesActionPointsInPortal === true && (
    <Frow justifyContent="between" fullWidth className="mar-b-1">
      <span className={tagClasses}>{startCase(status)}</span>
      {assignee && <AssigneeDisplay assigneeName={assignee.fullName} />}
    </Frow>
  );

  return (
    <div className="frow frow--direction-column frow--justify-between pad-a-2" style={cardStyles}>
      <div className="frow frow--direction-column frow--justify-start">
        <Frow
          justifyContent="between"
          itemAlignment="center"
          contentAlignment="between"
          additionalClassNames={'frow--full-width'}
        >
          <span className="text-grey-40 text-small">{portfolio.reference}</span>
          <div>
            <a
              href={portalClientPortfolioActionPointPath(client.id, portfolio.id, id)}
              style={{ display: 'block' }}
              target="_blank"
              rel="noreferrer"
            >
              <div className="text-white rt-tr-clickable-icon">
                <i className="icon-arrow-right-top icon-0-8x" />
              </div>
            </a>
          </div>
        </Frow>

        <div className="mar-t-3 frow frow--direction-column">
          <span className="text-white text-large mar-b-1 col-mc-1-3" data-tip="Date Added" data-for="action-point-icon">
            {format(createdAt, "D MMM 'YY")}
          </span>
          <span className="text-white text-small">{truncate(entityName, { length: 40, omission: '...' })}</span>
          {systemReference(portfolio)}
          <span className="text-white">{truncate(body, { length: 140 })}</span>
        </div>
      </div>

      <Frow justifyContent="between" itemAlignment="end" additionalClassNames={'frow--full-width'}>
        <Frow justifyContent="between" itemAlignment="end" additionalClassNames={'frow--full-width'}>
          {assigneeJSX}
          <span
            className="text-white frow frow--direction-row frow--items-center"
            data-tip="First Included"
            data-for="action-point-icon"
          >
            <QuarterIndicator currentQuarter={getQuarter(firstIncludedDate)} />Q{getQuarter(firstIncludedDate)}{' '}
            {getYear(firstIncludedDate)}
          </span>

          <div className="frow text-white">
            <div data-tip="Comments" data-for="action-point-icon">
              <i className="far fa-comment" style={{ marginRight: '5px' }} />
              {commentCount}
            </div>
            <div className="mar-l-3" data-tip="Attachments" data-for="action-point-icon">
              <i className="icon-document" style={{ marginRight: '5px' }} />
              {documentCount}
            </div>
          </div>
        </Frow>
      </Frow>
    </div>
  );
};

export default ActionPointCard;
