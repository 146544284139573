import * as React from 'react';

import { camelCase, snakeCase } from 'lodash';
import { prop } from 'ramda';

import { SecurityClassification } from 'javascript/models';

import ReactPieChart from 'components/shared/charting/recharts/ReactPieChart';

import Context from '../Context';
import useSharedState from 'components/shared/customHooks/useSharedState';
import AssetSlider from './strategicAssetAllocations/AssetSlider';

import { useDefaultTheme } from 'components/shared/customHooks/useThemeColours';

import { Frow } from 'components/frow';

interface IAssetClassState {
  [key: string]: number;
}

export interface IStrategicAllocationProps {
  assetClasses: SecurityClassification[];
}

const StrategicAllocation = (props: IStrategicAllocationProps) => {
  const { assetClasses } = props;

  const context = React.useContext(Context);

  const { portfolio } = context.state;
  const mandate = portfolio.portfolioMandate;

  const [useAssetClassState, sharedState] = useSharedState<IAssetClassState>();
  const [assetClassValues, _setAssetClassValues] = sharedState;

  const totalAssetValue = Object.values(assetClassValues).reduce((sum, assetClassValue) => sum + assetClassValue, 0);
  const unassignedValue = 100 - totalAssetValue;

  const getColour = useDefaultTheme('assetClass');

  const assetClassNames = assetClasses.map(prop('name'));

  const assetClassSliders = assetClassNames.map((assetClass: string, index) => {
    const assetClassState = useAssetClassState(assetClass, mandate[`${camelCase(assetClass)}Strategic`] || 0);
    const assetClassValue = assetClassState[0];

    const color = getColour(assetClass);
    return {
      color,
      jsx: (
        <React.Fragment key={`${assetClass}-${index}`}>
          <input
            type="hidden"
            name={`portfolio[portfolio_mandate_attributes][${snakeCase(assetClass)}_strategic]`}
            value={assetClassValue}
          />
          <AssetSlider
            assetClass={assetClass}
            availableSpace={unassignedValue}
            sliderState={assetClassState}
            color={color}
          />
        </React.Fragment>
      ),
      name: assetClass,
      value: assetClassValue,
    };
  });

  const pieChartData = [
    ...assetClassSliders,
    { name: 'Unassigned', value: unassignedValue < 1 ? 0 : unassignedValue, color: '#ffffff' },
  ];

  return (
    <fieldset className="form__fieldset">
      <h4 className="form__fieldset-label">Strategic Allocations</h4>
      <div className="frow frow--gutters">
        <div className="col-md-2-5">
          <Frow gutterSize={1}>{assetClassSliders.map((slider) => slider.jsx)}</Frow>
        </div>
        <div className="col-sm-3-5">
          <ReactPieChart animationDuration={500} data={pieChartData} height={300} labelText="" displayLegend />
        </div>
      </div>
    </fieldset>
  );
};

export default StrategicAllocation;
