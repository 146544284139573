import actioncable from 'actioncable';

let consumer;

function createChannel(...args) {
  if (!consumer) {
    consumer = actioncable.createConsumer();
  }

  return consumer.subscriptions.create(...args);
}

export default createChannel;
