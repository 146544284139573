import * as React from 'react';
import {
  addDays,
  addQuarters,
  differenceInCalendarWeeks,
  endOfQuarter,
  format,
  isAfter,
  isToday,
  startOfQuarter,
  startOfYear,
} from 'date-fns';
import range from 'components/shared/utilities/range';
import dateRange from 'components/shared/utilities/dateRange';

export default function individual({ writingTeamMembers, frequency, meta, selectedDate, tracker }) {
  const {
    writing_complete_week_admin,
    writing_complete_day_admin,
    writing_complete_week,
    writing_complete_day,
  } = meta.stats.grouped_by;
  const { for_writing } = meta.stats.total;
  const { overallTargets, overallTargetsDaily } = tracker;

  const nextQuarter = endOfQuarter(addQuarters(selectedDate, 1));
  const numberOfWeeksToAdd = differenceInCalendarWeeks(startOfQuarter(nextQuarter), startOfYear(nextQuarter));

  function tableRows() {
    if (frequency === 'Weekly') {
      let cumulativeTarget = 0;
      let cumulativeTotal = 0;
      const currentWeek = differenceInCalendarWeeks(new Date(), startOfYear(nextQuarter)) - numberOfWeeksToAdd + 1;
      return range(1, 13).map((num, index) => {
        const processed = writing_complete_week_admin[num + numberOfWeeksToAdd] || {};
        const totalProcessed = writing_complete_week[num + numberOfWeeksToAdd] || 0;
        const target = overallTargets[`week_${num}`] || 0;
        const targetNumber = for_writing * (target / 100);

        let totalAdminProcessed = 0;
        const tableCells = writingTeamMembers.map((teamMember) => {
          const adminProcessed = processed[teamMember.id] || 0;
          totalAdminProcessed += adminProcessed;
          return <td key={`cell-${index}-${teamMember.name}`}>{adminProcessed !== 0 ? adminProcessed : '-'}</td>;
        });

        const otherTotal = totalProcessed - totalAdminProcessed;

        cumulativeTotal += totalProcessed;
        cumulativeTarget += targetNumber;
        const isThisWeek = currentWeek === num;

        return (
          <tr key={`data-targets-admin-${index}`} className={`${isThisWeek && 'styled-table__highlighted-row'}`}>
            <td>{num}</td>
            {tableCells}
            <td>{otherTotal !== 0 ? otherTotal.toFixed(0) : '-'}</td>
            <td>{totalProcessed !== 0 ? totalProcessed : '-'}</td>
            <td>{cumulativeTotal !== 0 ? cumulativeTotal.toFixed(0) : '-'}</td>
            <td>{targetNumber !== 0 ? targetNumber.toFixed(0) : '-'}</td>
            <td>{cumulativeTarget !== 0 ? cumulativeTarget.toFixed(0) : '-'}</td>
            <td>{currentWeek >= num ? (cumulativeTotal - cumulativeTarget).toFixed(0) : '-'}</td>
          </tr>
        );
      });
    }

    if (frequency === 'Daily') {
      let cumulativeTarget = 0;
      let cumulativeTotal = 0;

      return dateRange(addDays(selectedDate, 1), nextQuarter).map((date, index) => {
        const formattedDate = format(date, 'YYYY-MM-DD');

        const processed = writing_complete_day_admin[formattedDate] || {};
        const totalProcessed = writing_complete_day[formattedDate] || 0;
        const target = overallTargetsDaily[index + 1] || 0;
        const targetNumber = for_writing * (target / 100);

        let totalAdminProcessed = 0;
        const tableCells = writingTeamMembers.map((teamMember) => {
          const adminProcessed = processed[teamMember.id] || 0;
          totalAdminProcessed += adminProcessed;
          return <td key={`cell-${index}-${teamMember.name}`}>{adminProcessed !== 0 ? adminProcessed : '-'}</td>;
        });

        const otherTotal = totalProcessed - totalAdminProcessed;

        cumulativeTotal += totalProcessed;
        cumulativeTarget += targetNumber;

        const today = isToday(date);

        return (
          <tr key={`data-targets-admin-${index}`} className={`${today && 'styled-table__highlighted-row'}`}>
            <td>
              {index + 1} ({format(date, 'Do MMM')})
            </td>
            {tableCells}
            <td>{otherTotal !== 0 ? otherTotal.toFixed(0) : '-'}</td>
            <td>{totalProcessed !== 0 ? totalProcessed : '-'}</td>
            <td>{cumulativeTotal !== 0 ? cumulativeTotal.toFixed(0) : '-'}</td>
            <td>{targetNumber !== 0 ? targetNumber.toFixed(0) : '-'}</td>
            <td>{cumulativeTarget !== 0 ? cumulativeTarget.toFixed(0) : '-'}</td>
            <td>{isAfter(new Date(), date) ? (cumulativeTotal - cumulativeTarget).toFixed(0) : ''}</td>
          </tr>
        );
      });
    }
  }

  const tableHeaders = writingTeamMembers.map((teamMember, index) => {
    return <th key={teamMember.name}>{teamMember.name}</th>;
  });

  return (
    <React.Fragment>
      <table className="styled-table styled-table--alt">
        <thead>
          <tr>
            <th>{frequency === 'Weekly' ? 'Week' : 'Day'}</th>
            {tableHeaders}
            <th>Other</th>
            <th>Total</th>
            <th>Cum. Total</th>
            <th>Target</th>
            <th>Cum. Target</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>{tableRows()}</tbody>
      </table>
    </React.Fragment>
  );
}
