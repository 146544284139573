import * as React from 'react';

import AssignModal from './AssignModal';

interface IProps {
  administrators: { display: string; value: number }[];
  companyId: number;
  iconButton?: boolean;
  id: string;
  handleReviewsAssigned(): void;
}

export default function assignOwner(props: IProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  const { administrators, companyId, iconButton, id } = props;

  function handleOpen(event) {
    event.preventDefault();
    setIsOpen(true);
  }

  function handleClose() {
    setIsOpen(false);
  }

  function renderButton() {
    if (iconButton) {
      return <i className="icon-edit icon-clickable icon-fw mar-l-1" onClick={handleOpen} />;
    }
    return (
      <button
        className="button button--outlined button--compact button--no-min-width small-uppercase"
        onClick={handleOpen}
      >
        Assign
      </button>
    );
  }

  return (
    <React.Fragment>
      {renderButton()}
      <AssignModal
        administrators={administrators}
        companyId={companyId}
        handleClose={handleClose}
        handleReviewsAssigned={props.handleReviewsAssigned}
        isOpen={isOpen}
        selection={[id]}
      />
    </React.Fragment>
  );
}
