import * as React from 'react';

import { Balance } from 'javascript/models';
import { format, parse } from 'date-fns';
const numeral = require('numeral');

export interface IColumnsProps {
  handleDeleteBalance: (balance: Balance) => void;
}

export default function columns(props: IColumnsProps) {
  return [
    {
      Cell: (row) => {
        return <div>{format(parse(row.original.valueDate), 'DD MMM YYYY')}</div>;
      },
      Header: 'Value Date',
      accessor: 'valueDate',
      id: 'valueDate',
    },
    {
      Cell: (row) => {
        return <div>{row.original.currency.toUpperCase()}</div>;
      },
      Header: 'Currency',
      accessor: 'currency',
      id: 'currency',
    },
    {
      Cell: (row) => {
        return <div>{numeral(row.original.amount).format('0,0.00')}</div>;
      },
      Header: 'Value',
      accessor: 'amount',
      id: 'value',
    },
    {
      Cell: (row) => {
        const handleDeleteClick = () => props.handleDeleteBalance(row.original);
        return (
          <button type="button" className="button button--danger button--icon" onClick={handleDeleteClick}>
            <i className="icon-bin icon-fw" />
          </button>
        );
      },
      Header: '',
      accessor: 'deleteBalance',
      id: 'deleteBalance',
      width: 54.15,
    },
  ];
}
