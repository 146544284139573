import * as React from 'react';
import { TableProps } from 'react-table';
const classNames = require('classnames');

export default function (props: TableProps) {
  const className = classNames('-loading', { '-active': props.loading });

  return (
    <div className={className}>
      <div className="-loading-inner">
        <i className="fas fa-2x fa-circle-notch fa-spin text-brand-blue" />
      </div>
    </div>
  );
}
