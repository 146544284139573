import React from 'react';
import { snakeCase } from 'lodash';
import IPieChartData from '../charting/recharts/interfaces/IPieChartData';

// TODO Refactor to create state inside of hook.
const useChartFilter = (
  data: IPieChartData[], // contains opacity information.
  setData: React.Dispatch<React.SetStateAction<IPieChartData[]>>,
  dataObjFilterKey: string, // The property on the data which we want to filter on.
  filterArr: string[],
) => {
  React.useEffect(() => {
    const newData = data.map((item) => {
      let itemValue = item[dataObjFilterKey];

      if (!Number.isInteger(itemValue)) {
        itemValue = Array.isArray(item[dataObjFilterKey])
          ? item[dataObjFilterKey][0]
          : snakeCase(item[dataObjFilterKey]);
      }
      item.opacity = !filterArr || !filterArr.length || filterArr.includes(itemValue) ? '1' : '0.5';
      return item;
    });

    if (newData) setData(newData);
  }, [filterArr]);
};

export default useChartFilter;
