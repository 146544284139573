import * as React from 'react';

import Card from './noteCards/Card';
import Loading from './Loading';

export default function noteCards({ dropdown, expanded, isLoading, notes }) {
  const formattedNotes = notes.map((note) => {
    return <Card key={note.id} {...{ dropdown, expanded, note }} />;
  });

  return <div className="frow frow--gutters">{isLoading ? <Loading /> : formattedNotes}</div>;
}
