import React from 'react';

import FlagsSelector from 'components/shared/classificationFlags/FlagsSelector';

import Context from '../Context';

const MandateFlags = ({ criterionOptions }) => {
  const context = React.useContext(Context);
  const {
    portfolio: { portfolioMandate: mandate },
  } = context.state;

  return (
    <fieldset className="form__fieldset">
      <div className="frow frow--justify-between mar-b-2">
        <h4 className="form__fieldset-label">Mandate Flags</h4>
      </div>
      {mandate.flags && (
        <FlagsSelector
          criterionOptions={criterionOptions}
          flags={mandate.flags}
          namePrefix={'portfolio[portfolio_mandate_attributes][flags_attributes]'}
        />
      )}
    </fieldset>
  );
};

export default MandateFlags;
