import * as React from 'react';

import GraphitiTableWrapper from 'components/shared/GraphitiTableWrapper';

import columns from './table/columns';
import { DataUpload } from 'javascript/models';

interface IProps {
  managerId: string;
}

export default function table(props: IProps) {
  const { managerId } = props;
  const [shouldUpdateTable, setShouldUpdateTable] = React.useState(false);

  React.useEffect(() => {
    document.addEventListener('dataUploads:new', updateUploads);
    return () => {
      document.removeEventListener('dataUploads:new', updateUploads);
    };
  }, []);

  function updateUploads(_) {
    setShouldUpdateTable(true);
  }

  React.useEffect(() => {
    if (shouldUpdateTable) {
      setShouldUpdateTable(false);
    }
  }, [shouldUpdateTable]);

  const scope = DataUpload.where({ manager_id: managerId }).order({ created_at: 'desc' });

  return (
    <GraphitiTableWrapper
      scope={scope}
      columns={columns()}
      hasHeader
      shouldUpdateTable={shouldUpdateTable}
      title="Uploaded Files"
      identifier="dataUploads:index"
    />
  );
}
