import lodashStartcase from 'lodash.startcase';
import pluralize from 'pluralize';

export default function humanizeCriterion(criterion: string, type: string) {
  const humanizeCountry = (criterion) => {
    const wordArray = criterion.split(' ');
    const titleArray = wordArray.map((word) => (['and', 'of', 'the'].includes(word) ? word : lodashStartcase(word)));
    if (titleArray.includes('republic') || titleArray.includes('islands')) titleArray.unshift('the');
    return titleArray.join(' ');
  };

  const uniqueHumanizations = {
    cash: 'Cash',
    fixed_income: 'Fixed Income assets',
    other: 'Other assets',
    certificate_of_deposit: 'certificates of deposit',
    consumer_discretionary: 'the Consumer (Discretionary) sector',
    consumer_staples: 'the Consumer (Staples) sector',
    other_sector: 'unclassified sectors',
    united_arab_emirates: 'the United Arab Emirates',
    bahamas: 'the Bahamas',
    congo: 'the Congo',
    ivory_coast: 'the Ivory Coast',
    united_kingdom_gb_and_ni: 'the United Kingdom',
    gambia: 'the Gambia',
    south_georgia_and_the_south_sandwich_islands: 'South Georgia and the South Sandwich Islands',
    heard_and_mcdonald_isles: 'the Heard and McDonald Islands',
    isle_of_man: 'the Isle of Man',
    kiribati_islands: 'Kiribati',
    comoros: 'the Comoros',
    maldives: 'the Maldives',
    netherlands: 'the Netherlands',
    philippines: 'the Philippines',
    seychelles: 'the Seychelles',
    slovak_rep: 'Slovakia',
    suinam: 'Suriname',
    usa: 'the United States of America',
    st_vincent_and_grenadines: 'St Vincent and the Grenadines',
    virgin_islands_us: 'the U.S. Virgin Islands',
  };

  const standardHumanizations = {
    country: humanizeCountry(criterion),
    currency: criterion.toUpperCase(),
    instrument: lodashStartcase(pluralize(criterion)),
    sector: `the ${lodashStartcase(criterion)} sector`,
  };

  return uniqueHumanizations[criterion] || standardHumanizations[type] || lodashStartcase(criterion);
}
