import * as React from 'react';

export default function (isDragActive) {
  return (
    <div className="frow frow--centered-column" style={{ height: '100%' }}>
      <i className={`icon-upload icon-4x icon-fw ${isDragActive ? 'text-white' : 'text-grey'}`} />
      <h3 className="heading-one text-grey text-normal mar-t-3 text-center">
        Drag and drop, or click to select attachments
      </h3>
      <p className="text-grey">Please note duplicate files will overwrite existing data</p>
    </div>
  );
}
