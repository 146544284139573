import * as React from 'react';

import ReactSelect from 'components/shared/forms/ReactSelect';

interface IProps {
  portfolioBenchmark: {
    reference: string;
    id: string;
  };
  index: number;
}

const SelectedBenchmark = (props: IProps) => {
  const { portfolioBenchmark, index } = props;

  const [options, setOptions] = React.useState([]);

  React.useEffect(() => {
    getBenchmarks();
  }, []);

  const getBenchmarks = async () => {
    const benchmarkOptions = [{ label: portfolioBenchmark.reference, value: portfolioBenchmark.id }];
    setOptions(benchmarkOptions);
  };

  return (
    <div className={'col-mc-1-1'}>
      <div className="frow frow--gutters frow--items-center">
        <div className="col-md-1-2">
          <label htmlFor="" className="form__label">
            Benchmark
          </label>
          <ReactSelect
            theme="dark"
            id="component_id"
            name={`portfolio_benchmark[benchmarks_components_attributes][${index}][component_id]`}
            options={options}
            defaultValue={{
              label: portfolioBenchmark.reference,
              value: portfolioBenchmark.id,
            }}
          />
        </div>
        <div className="col-md-1-2">
          <label htmlFor="" className="form__label">
            Proportion
          </label>
          <input
            type="number"
            max={100}
            step={0.01}
            defaultValue={0}
            name={`portfolio_benchmark[benchmarks_components_attributes][${index}][proportion]`}
            placeholder="50"
          />
        </div>
      </div>
    </div>
  );
};

export default SelectedBenchmark;
