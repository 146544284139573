import { ApplicationRecord } from '../ApplicationRecord';
/**
 * Returns a class based off ApplicationRecord which contains methods for working with permissions
 *
 * @param permissionResource - The spraypaint permission model
 * @param unpermissionedModel - The spraypaint model which will gain the permission methods
 * @returns A class which extends `unpermissionedModel` and contains permission the methods
 */

const generateRecordWithPermission = (permissionResource) => <Model extends { new (...args): ApplicationRecord }>(
  unpermissionedModel: Model,
) =>
  class PermissionedClass extends unpermissionedModel {
    public permissions;
    public getPermission(permissionType: string, allowMarkedForDestruction = false) {
      const permission = this.permissions.find((permission) => permission.permissionType == permissionType);
      if (!permission) return false;

      return allowMarkedForDestruction ? permission : permission.isMarkedForDestruction ? false : permission;
    }

    /**
     * Toggles a permission
     *
     * @param permissionType - The permission type used to create/delete the permission
     *
     * @returns Boolean (true if creating a role, false if destroying a role)
     * @example
     * const role = (await ClientUserRole.includes('permissions').find(1)).data
     * const roleCreated = role.togglePermission('mandate_viewer')
     */
    public togglePermission(permissionType: string): boolean {
      const permission = this.getPermission(permissionType, true);
      if (permission) {
        permission.isMarkedForDestruction = !permission.isMarkedForDestruction;
        return !permission.isMarkedForDestruction;
      }
      this.permissions.push(new permissionResource({ permissionType }));
      return true;
    }

    public hasPermission(permissionType: string) {
      return this.getPermission(permissionType) ? true : false;
    }
  };

export default generateRecordWithPermission;
