import * as React from 'react';

const companyLogo = require('../../../../../../images/logos/plus-divide-grey.svg').default;

export default function header({ color, theme, logo }) {
  return (
    <header className="pdf-secondary-header">
      <div className="pdf-secondary-header__top-bar" style={{ background: color }} />
      <div className="pdf-secondary-header__inner">
        <table className="pdf-hidden-table">
          <tbody>
            <tr>
              <td style={{ width: '34%' }}>
                <img src={theme === 'custom' ? logo : companyLogo} alt="" className="pdf-secondary-header__logo" />
              </td>
              <td style={{ width: '26%' }}>
                <div className="pdf-tiny-subtitle">
                  Structure: <span className="pdf-tiny-subtitle--bold">Sample Portfolio 1</span>
                </div>
                <div className="pdf-tiny-subtitle">
                  Manager: <span className="pdf-tiny-subtitle--bold">Investment Manager 1</span>
                </div>
              </td>
              <td style={{ width: '20%' }}>
                <div className="pdf-tiny-subtitle">
                  Reference: <span className="pdf-tiny-subtitle--bold">EISCC0052</span>
                </div>
                <div className="pdf-tiny-subtitle">
                  Risk profile: <span className="pdf-tiny-subtitle--bold">Balanced</span>
                </div>
              </td>
              <td style={{ width: '20%' }}>
                <div className="pdf-tiny-subtitle">
                  Base Currency: <span className="pdf-tiny-subtitle--bold">USD</span>
                </div>
                <div className="pdf-tiny-subtitle">
                  Review Date: <span className="pdf-tiny-subtitle--bold">31 Dec 2018</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </header>
  );
}
