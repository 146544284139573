/**
 * File generated by js-routes 2.2.0
 * Based on Rails 6.0.4.4 routes of Mosaic::Application
 */
const __jsr = ((that) => {
  const hasProp = (value, key) => Object.prototype.hasOwnProperty.call(value, key);
  let NodeTypes;
  (function (NodeTypes) {
    NodeTypes[(NodeTypes['GROUP'] = 1)] = 'GROUP';
    NodeTypes[(NodeTypes['CAT'] = 2)] = 'CAT';
    NodeTypes[(NodeTypes['SYMBOL'] = 3)] = 'SYMBOL';
    NodeTypes[(NodeTypes['OR'] = 4)] = 'OR';
    NodeTypes[(NodeTypes['STAR'] = 5)] = 'STAR';
    NodeTypes[(NodeTypes['LITERAL'] = 6)] = 'LITERAL';
    NodeTypes[(NodeTypes['SLASH'] = 7)] = 'SLASH';
    NodeTypes[(NodeTypes['DOT'] = 8)] = 'DOT';
  })(NodeTypes || (NodeTypes = {}));
  const Root = that;
  const isBroswer = typeof window !== 'undefined';
  const ModuleReferences = {
    CJS: {
      define(routes) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        module.exports = routes;
      },
      isSupported() {
        return typeof module === 'object';
      },
    },
    AMD: {
      define(routes) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        define([], function () {
          return routes;
        });
      },
      isSupported() {
        return typeof define === 'function' && !!define.amd;
      },
    },
    UMD: {
      define(routes) {
        if (ModuleReferences.AMD.isSupported()) {
          ModuleReferences.AMD.define(routes);
        } else {
          if (ModuleReferences.CJS.isSupported()) {
            try {
              ModuleReferences.CJS.define(routes);
            } catch (error) {
              if (error.name !== 'TypeError') throw error;
            }
          }
        }
      },
      isSupported() {
        return ModuleReferences.AMD.isSupported() || ModuleReferences.CJS.isSupported();
      },
    },
    ESM: {
      define() {
        // Module can only be defined using ruby code generation
      },
      isSupported() {
        // Its impossible to check if "export" keyword is supported
        return true;
      },
    },
    NIL: {
      define(routes) {
        Utils.namespace(Root, null, routes);
      },
      isSupported() {
        return !null || !!Root;
      },
    },
    DTS: {
      // Acts the same as ESM
      define(routes) {
        ModuleReferences.ESM.define(routes);
      },
      isSupported() {
        return ModuleReferences.ESM.isSupported();
      },
    },
  };
  class ParametersMissing extends Error {
    constructor(...keys) {
      super(`Route missing required keys: ${keys.join(', ')}`);
      this.keys = keys;
      Object.setPrototypeOf(this, Object.getPrototypeOf(this));
      this.name = ParametersMissing.name;
    }
  }
  const UriEncoderSegmentRegex = /[^a-zA-Z0-9\-._~!$&'()*+,;=:@]/g;
  const ReservedOptions = ['anchor', 'trailing_slash', 'subdomain', 'host', 'port', 'protocol'];
  class UtilsClass {
    constructor() {
      this.configuration = {
        prefix: '',
        default_url_options: {},
        special_options_key: '_options',
        serializer: null || this.default_serializer.bind(this),
      };
    }
    default_serializer(value, prefix) {
      if (this.is_nullable(value)) {
        return '';
      }
      if (!prefix && !this.is_object(value)) {
        throw new Error('Url parameters should be a javascript hash');
      }
      prefix = prefix || '';
      const result = [];
      if (this.is_array(value)) {
        for (const element of value) {
          result.push(this.default_serializer(element, prefix + '[]'));
        }
      } else if (this.is_object(value)) {
        for (let key in value) {
          if (!hasProp(value, key)) continue;
          let prop = value[key];
          if (this.is_nullable(prop) && prefix) {
            prop = '';
          }
          if (this.is_not_nullable(prop)) {
            if (prefix) {
              key = prefix + '[' + key + ']';
            }
            result.push(this.default_serializer(prop, key));
          }
        }
      } else {
        if (this.is_not_nullable(value)) {
          result.push(encodeURIComponent(prefix) + '=' + encodeURIComponent('' + value));
        }
      }
      return result.join('&');
    }
    serialize(object) {
      return this.configuration.serializer(object);
    }
    extract_options(number_of_params, args) {
      const last_el = args[args.length - 1];
      if (
        (args.length > number_of_params && last_el === 0) ||
        (this.is_object(last_el) && !this.looks_like_serialized_model(last_el))
      ) {
        if (this.is_object(last_el)) {
          delete last_el[this.configuration.special_options_key];
        }
        return {
          args: args.slice(0, args.length - 1),
          options: last_el,
        };
      } else {
        return { args, options: {} };
      }
    }
    looks_like_serialized_model(object) {
      return (
        this.is_object(object) &&
        !(this.configuration.special_options_key in object) &&
        ('id' in object || 'to_param' in object || 'toParam' in object)
      );
    }
    path_identifier(object) {
      const result = this.unwrap_path_identifier(object);
      return this.is_nullable(result) || result === false ? '' : '' + result;
    }
    unwrap_path_identifier(object) {
      let result = object;
      if (!this.is_object(object)) {
        return object;
      }
      if ('to_param' in object) {
        result = object.to_param;
      } else if ('toParam' in object) {
        result = object.toParam;
      } else if ('id' in object) {
        result = object.id;
      } else {
        result = object;
      }
      return this.is_callable(result) ? result.call(object) : result;
    }
    partition_parameters(parts, required_params, default_options, call_arguments) {
      // eslint-disable-next-line prefer-const
      let { args, options } = this.extract_options(parts.length, call_arguments);
      if (args.length > parts.length) {
        throw new Error('Too many parameters provided for path');
      }
      let use_all_parts = args.length > required_params.length;
      const parts_options = {
        ...this.configuration.default_url_options,
      };
      for (const key in options) {
        const value = options[key];
        if (!hasProp(options, key)) continue;
        use_all_parts = true;
        if (parts.includes(key)) {
          parts_options[key] = value;
        }
      }
      options = {
        ...this.configuration.default_url_options,
        ...default_options,
        ...options,
      };
      const keyword_parameters = {};
      const query_parameters = {};
      for (const key in options) {
        if (!hasProp(options, key)) continue;
        const value = options[key];
        if (this.is_reserved_option(key)) {
          keyword_parameters[key] = value;
        } else {
          if (!this.is_nullable(value) && (value !== default_options[key] || required_params.includes(key))) {
            query_parameters[key] = value;
          }
        }
      }
      const route_parts = use_all_parts ? parts : required_params;
      let i = 0;
      for (const part of route_parts) {
        if (i < args.length) {
          const value = args[i];
          if (!hasProp(parts_options, part)) {
            query_parameters[part] = value;
            ++i;
          }
        }
      }
      return { keyword_parameters, query_parameters };
    }
    build_route(parts, required_params, default_options, route, absolute, args) {
      const { keyword_parameters, query_parameters } = this.partition_parameters(
        parts,
        required_params,
        default_options,
        args,
      );
      const missing_params = required_params.filter(
        (param) => !hasProp(query_parameters, param) || this.is_nullable(query_parameters[param]),
      );
      if (missing_params.length) {
        throw new ParametersMissing(...missing_params);
      }
      let result = this.get_prefix() + this.visit(route, query_parameters);
      if (keyword_parameters.trailing_slash) {
        result = result.replace(/(.*?)[/]?$/, '$1/');
      }
      const url_params = this.serialize(query_parameters);
      if (url_params.length) {
        result += '?' + url_params;
      }
      result += keyword_parameters.anchor ? '#' + keyword_parameters.anchor : '';
      if (absolute) {
        result = this.route_url(keyword_parameters) + result;
      }
      return result;
    }
    visit(route, parameters, optional = false) {
      switch (route[0]) {
        case NodeTypes.GROUP:
          return this.visit(route[1], parameters, true);
        case NodeTypes.CAT:
          return this.visit_cat(route, parameters, optional);
        case NodeTypes.SYMBOL:
          return this.visit_symbol(route, parameters, optional);
        case NodeTypes.STAR:
          return this.visit_globbing(route[1], parameters, true);
        case NodeTypes.LITERAL:
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
          return route[1];
        default:
          throw new Error('Unknown Rails node type');
      }
    }
    is_not_nullable(object) {
      return !this.is_nullable(object);
    }
    is_nullable(object) {
      return object === undefined || object === null;
    }
    visit_cat(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      [_type, left, right],
      parameters,
      optional,
    ) {
      const left_part = this.visit(left, parameters, optional);
      let right_part = this.visit(right, parameters, optional);
      if (
        optional &&
        ((this.is_optional_node(left[0]) && !left_part) || (this.is_optional_node(right[0]) && !right_part))
      ) {
        return '';
      }
      // if left_part ends on '/' and right_part starts on '/'
      if (left_part[left_part.length - 1] === '/' && right_part[0] === '/') {
        // strip slash from right_part
        // to prevent double slash
        right_part = right_part.substring(1);
      }
      return left_part + right_part;
    }
    visit_symbol(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      [_type, key],
      parameters,
      optional,
    ) {
      const value = this.path_identifier(parameters[key]);
      delete parameters[key];
      if (value.length) {
        return this.encode_segment(value);
      }
      if (optional) {
        return '';
      } else {
        throw new ParametersMissing(key);
      }
    }
    encode_segment(segment) {
      return segment.replace(UriEncoderSegmentRegex, (str) => encodeURIComponent(str));
    }
    is_optional_node(node) {
      return [NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT].includes(node);
    }
    build_path_spec(route, wildcard = false) {
      let key;
      switch (route[0]) {
        case NodeTypes.GROUP:
          return '(' + this.build_path_spec(route[1]) + ')';
        case NodeTypes.CAT:
          return this.build_path_spec(route[1]) + this.build_path_spec(route[2]);
        case NodeTypes.STAR:
          return this.build_path_spec(route[1], true);
        case NodeTypes.SYMBOL:
          key = route[1];
          if (wildcard) {
            return (key.startsWith('*') ? '' : '*') + key;
          } else {
            return ':' + key;
          }
          break;
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
        case NodeTypes.LITERAL:
          return route[1];
        default:
          throw new Error('Unknown Rails node type');
      }
    }
    visit_globbing(route, parameters, optional) {
      const key = route[1];
      let value = parameters[key];
      delete parameters[key];
      if (this.is_nullable(value)) {
        return this.visit(route, parameters, optional);
      }
      if (this.is_array(value)) {
        value = value.join('/');
      }
      const result = this.path_identifier(value);
      return false ? result : encodeURI(result);
    }
    get_prefix() {
      const prefix = this.configuration.prefix;
      return prefix.match('/$') ? prefix.substring(0, prefix.length - 1) : prefix;
    }
    route(parts_table, route_spec, absolute = false) {
      const required_params = [];
      const parts = [];
      const default_options = {};
      for (const [part, { r: required, d: value }] of Object.entries(parts_table)) {
        parts.push(part);
        if (required) {
          required_params.push(part);
        }
        if (this.is_not_nullable(value)) {
          default_options[part] = value;
        }
      }
      const result = (...args) => {
        return this.build_route(parts, required_params, default_options, route_spec, absolute, args);
      };
      result.requiredParams = () => required_params;
      result.toString = () => {
        return this.build_path_spec(route_spec);
      };
      return result;
    }
    route_url(route_defaults) {
      const hostname = route_defaults.host || this.current_host();
      if (!hostname) {
        return '';
      }
      const subdomain = route_defaults.subdomain ? route_defaults.subdomain + '.' : '';
      const protocol = route_defaults.protocol || this.current_protocol();
      let port = route_defaults.port || (!route_defaults.host ? this.current_port() : undefined);
      port = port ? ':' + port : '';
      return protocol + '://' + subdomain + hostname + port;
    }
    current_host() {
      var _a;
      return (
        (isBroswer &&
          ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0
            ? void 0
            : _a.hostname)) ||
        ''
      );
    }
    current_protocol() {
      var _a, _b;
      return (
        (isBroswer &&
          ((_b =
            (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0
              ? void 0
              : _a.protocol) === null || _b === void 0
            ? void 0
            : _b.replace(/:$/, ''))) ||
        'http'
      );
    }
    current_port() {
      var _a;
      return (
        (isBroswer &&
          ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0
            ? void 0
            : _a.port)) ||
        ''
      );
    }
    is_object(value) {
      return typeof value === 'object' && Object.prototype.toString.call(value) === '[object Object]';
    }
    is_array(object) {
      return object instanceof Array;
    }
    is_callable(object) {
      return typeof object === 'function' && !!object.call;
    }
    is_reserved_option(key) {
      return ReservedOptions.includes(key);
    }
    namespace(object, namespace, routes) {
      const parts = (namespace === null || namespace === void 0 ? void 0 : namespace.split('.')) || [];
      if (parts.length === 0) {
        return;
      }
      for (let index = 0; index < parts.length; index++) {
        const part = parts[index];
        if (index < parts.length - 1) {
          object = object[part] || (object[part] = {});
        } else {
          object[part] = routes;
        }
      }
    }
    configure(new_config) {
      this.configuration = { ...this.configuration, ...new_config };
      return this.configuration;
    }
    config() {
      return { ...this.configuration };
    }
    is_module_supported(name) {
      return ModuleReferences[name].isSupported();
    }
    ensure_module_supported(name) {
      if (!this.is_module_supported(name)) {
        throw new Error(`${name} is not supported by runtime`);
      }
    }
    define_module(name, module) {
      this.ensure_module_supported(name);
      ModuleReferences[name].define(module);
    }
  }
  const Utils = new UtilsClass();
  // We want this helper name to be short
  const __jsr = {
    r(parts_table, route_spec, absolute) {
      return Utils.route(parts_table, route_spec, absolute);
    },
  };
  const result = {
    ...__jsr,
    configure: (config) => {
      return Utils.configure(config);
    },
    config: () => {
      return Utils.config();
    },
    serialize: (object) => {
      return Utils.serialize(object);
    },
    ...{},
  };
  Utils.define_module('ESM', result);
  return result;
})(this);
export const configure = __jsr.configure;

export const config = __jsr.config;

export const serialize = __jsr.serialize;

/**
 * Generates rails route to
 * /administrators/invitation/accept(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const accept_administrator_invitation_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'administrators'],
    [2, [7, '/'], [2, [6, 'invitation'], [2, [7, '/'], [2, [6, 'accept'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /platform/administrators/companies/:company_id/invitation/accept(.:format)
 * @param {any} company_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const accept_platform_administrator_company_invitation_path = __jsr.r({ company_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'administrators'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'companies'],
            [
              2,
              [7, '/'],
              [
                2,
                [3, 'company_id'],
                [
                  2,
                  [7, '/'],
                  [2, [6, 'invitation'], [2, [7, '/'], [2, [6, 'accept'], [1, [2, [8, '.'], [3, 'format']]]]]],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /portal/invitation/accept(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const accept_user_invitation_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'portal'],
    [2, [7, '/'], [2, [6, 'invitation'], [2, [7, '/'], [2, [6, 'accept'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /administrators/invitation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const administrator_invitation_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'administrators'], [2, [7, '/'], [2, [6, 'invitation'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /administrators/password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const administrator_password_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'administrators'], [2, [7, '/'], [2, [6, 'password'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /administrators/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const administrator_session_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'administrators'], [2, [7, '/'], [2, [6, 'sign_in'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /administrators/unlock(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const administrator_unlock_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'administrators'], [2, [7, '/'], [2, [6, 'unlock'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /api/v1/action_points/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_action_point_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [2, [7, '/'], [2, [6, 'action_points'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/action_points(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_action_points_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [2, [7, '/'], [2, [6, 'v1'], [2, [7, '/'], [2, [6, 'action_points'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/action_points_reviews/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_action_points_review_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [2, [6, 'action_points_reviews'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/administrators/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_administrator_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [2, [7, '/'], [2, [6, 'administrators'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/administrators(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_administrators_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [2, [7, '/'], [2, [6, 'v1'], [2, [7, '/'], [2, [6, 'administrators'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/answers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_answer_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [2, [7, '/'], [2, [6, 'answers'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/answers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_answers_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [2, [6, 'api'], [2, [7, '/'], [2, [6, 'v1'], [2, [7, '/'], [2, [6, 'answers'], [1, [2, [8, '.'], [3, 'format']]]]]]]],
]);

/**
 * Generates rails route to
 * /api/v1/asset_mappings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_asset_mappings_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [2, [7, '/'], [2, [6, 'v1'], [2, [7, '/'], [2, [6, 'asset_mappings'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/attachments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_attachment_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [2, [7, '/'], [2, [6, 'attachments'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/audits/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_audit_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [2, [7, '/'], [2, [6, 'audits'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/audits(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_audits_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [2, [6, 'api'], [2, [7, '/'], [2, [6, 'v1'], [2, [7, '/'], [2, [6, 'audits'], [1, [2, [8, '.'], [3, 'format']]]]]]]],
]);

/**
 * Generates rails route to
 * /api/v1/balances(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_balances_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [2, [7, '/'], [2, [6, 'v1'], [2, [7, '/'], [2, [6, 'balances'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/bulk_imports/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_bulk_import_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [2, [7, '/'], [2, [6, 'bulk_imports'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/bulk_imports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_bulk_imports_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [2, [7, '/'], [2, [6, 'v1'], [2, [7, '/'], [2, [6, 'bulk_imports'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/cash_flows(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_cash_flows_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [2, [7, '/'], [2, [6, 'v1'], [2, [7, '/'], [2, [6, 'cash_flows'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/classification_uploads/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_classification_upload_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [2, [6, 'classification_uploads'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/classification_uploads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_classification_uploads_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [2, [7, '/'], [2, [6, 'v1'], [2, [7, '/'], [2, [6, 'classification_uploads'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/clients/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_client_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [2, [7, '/'], [2, [6, 'clients'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/client_tiles/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_client_tile_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [2, [7, '/'], [2, [6, 'client_tiles'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/client_tiles(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_client_tiles_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [2, [7, '/'], [2, [6, 'v1'], [2, [7, '/'], [2, [6, 'client_tiles'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/clients(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_clients_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [2, [6, 'api'], [2, [7, '/'], [2, [6, 'v1'], [2, [7, '/'], [2, [6, 'clients'], [1, [2, [8, '.'], [3, 'format']]]]]]]],
]);

/**
 * Generates rails route to
 * /api/v1/cluster_mappings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_cluster_mappings_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [2, [7, '/'], [2, [6, 'v1'], [2, [7, '/'], [2, [6, 'cluster_mappings'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/clusters(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_clusters_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [2, [7, '/'], [2, [6, 'v1'], [2, [7, '/'], [2, [6, 'clusters'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/commentaries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_commentaries_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [2, [7, '/'], [2, [6, 'v1'], [2, [7, '/'], [2, [6, 'commentaries'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/commentaries/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_commentary_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [2, [7, '/'], [2, [6, 'commentaries'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/data_chase_templates/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_data_chase_template_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [2, [6, 'data_chase_templates'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/data_chase_templates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_data_chase_templates_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [2, [7, '/'], [2, [6, 'v1'], [2, [7, '/'], [2, [6, 'data_chase_templates'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/data_chasers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_data_chaser_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [2, [7, '/'], [2, [6, 'data_chasers'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/data_chasers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_data_chasers_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [2, [7, '/'], [2, [6, 'v1'], [2, [7, '/'], [2, [6, 'data_chasers'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/data_notes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_data_note_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [2, [7, '/'], [2, [6, 'data_notes'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/data_notes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_data_notes_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [2, [7, '/'], [2, [6, 'v1'], [2, [7, '/'], [2, [6, 'data_notes'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/data_uploads/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_data_upload_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [2, [7, '/'], [2, [6, 'data_uploads'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/data_upload_errors/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_data_upload_error_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [2, [7, '/'], [2, [6, 'data_upload_errors'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/data_upload_errors(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_data_upload_errors_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [2, [7, '/'], [2, [6, 'v1'], [2, [7, '/'], [2, [6, 'data_upload_errors'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/data_uploads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_data_uploads_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [2, [7, '/'], [2, [6, 'v1'], [2, [7, '/'], [2, [6, 'data_uploads'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/entities(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_entities_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [2, [7, '/'], [2, [6, 'v1'], [2, [7, '/'], [2, [6, 'entities'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/entities/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_entity_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [2, [7, '/'], [2, [6, 'entities'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/entity_groupings/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_entity_grouping_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [2, [7, '/'], [2, [6, 'entity_groupings'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/entity_groupings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_entity_groupings_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [2, [7, '/'], [2, [6, 'v1'], [2, [7, '/'], [2, [6, 'entity_groupings'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/entity_relationship_roles/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_entity_relationship_role_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [2, [6, 'entity_relationship_roles'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/entity_relationship_roles(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_entity_relationship_roles_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [2, [6, 'v1'], [2, [7, '/'], [2, [6, 'entity_relationship_roles'], [1, [2, [8, '.'], [3, 'format']]]]]],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/extra_settings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_extra_settings_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [2, [7, '/'], [2, [6, 'v1'], [2, [7, '/'], [2, [6, 'extra_settings'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/extra_settings_types(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_extra_settings_types_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [2, [7, '/'], [2, [6, 'v1'], [2, [7, '/'], [2, [6, 'extra_settings_types'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/extracts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_extract_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [2, [7, '/'], [2, [6, 'extracts'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/extracts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_extracts_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [2, [7, '/'], [2, [6, 'v1'], [2, [7, '/'], [2, [6, 'extracts'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/flags(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_flags_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [2, [6, 'api'], [2, [7, '/'], [2, [6, 'v1'], [2, [7, '/'], [2, [6, 'flags'], [1, [2, [8, '.'], [3, 'format']]]]]]]],
]);

/**
 * Generates rails route to
 * /api/v1/holdings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_holdings_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [2, [7, '/'], [2, [6, 'v1'], [2, [7, '/'], [2, [6, 'holdings'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/investment_risk_assessments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_investment_risk_assessment_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [2, [6, 'investment_risk_assessments'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/investment_risk_assessments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_investment_risk_assessments_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [2, [6, 'v1'], [2, [7, '/'], [2, [6, 'investment_risk_assessments'], [1, [2, [8, '.'], [3, 'format']]]]]],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/managers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_manager_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [2, [7, '/'], [2, [6, 'managers'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/manager_users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_manager_users_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [2, [7, '/'], [2, [6, 'v1'], [2, [7, '/'], [2, [6, 'manager_users'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/managers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_managers_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [2, [7, '/'], [2, [6, 'v1'], [2, [7, '/'], [2, [6, 'managers'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/mandates/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_mandate_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [2, [7, '/'], [2, [6, 'mandates'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/market_commentaries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_market_commentaries_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [2, [7, '/'], [2, [6, 'v1'], [2, [7, '/'], [2, [6, 'market_commentaries'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/notes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_note_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [2, [7, '/'], [2, [6, 'notes'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/notes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_notes_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [2, [6, 'api'], [2, [7, '/'], [2, [6, 'v1'], [2, [7, '/'], [2, [6, 'notes'], [1, [2, [8, '.'], [3, 'format']]]]]]]],
]);

/**
 * Generates rails route to
 * /api/v1/notes_notables/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_notes_notable_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [2, [7, '/'], [2, [6, 'notes_notables'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/notes_notables(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_notes_notables_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [2, [7, '/'], [2, [6, 'v1'], [2, [7, '/'], [2, [6, 'notes_notables'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/notifications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_notifications_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [2, [7, '/'], [2, [6, 'v1'], [2, [7, '/'], [2, [6, 'notifications'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/parent_managers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_parent_managers_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [2, [7, '/'], [2, [6, 'v1'], [2, [7, '/'], [2, [6, 'parent_managers'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/permissions/administrators/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_permissions_administrator_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'permissions'],
            [2, [7, '/'], [2, [6, 'administrators'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/permissions/administrators(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_permissions_administrators_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [2, [6, 'permissions'], [2, [7, '/'], [2, [6, 'administrators'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/permissions/client_users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_permissions_client_user_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'permissions'],
            [2, [7, '/'], [2, [6, 'client_users'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/permissions/client_users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_permissions_client_users_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [2, [6, 'permissions'], [2, [7, '/'], [2, [6, 'client_users'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/portal/portfolios/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_portal_portfolio_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'portal'],
            [2, [7, '/'], [2, [6, 'portfolios'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/portal/portfolios(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_portal_portfolios_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [2, [7, '/'], [2, [6, 'portal'], [2, [7, '/'], [2, [6, 'portfolios'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/portal/reviews(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_portal_reviews_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [2, [7, '/'], [2, [6, 'portal'], [2, [7, '/'], [2, [6, 'reviews'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/portfolios/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_portfolio_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [2, [7, '/'], [2, [6, 'portfolios'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/portfolio_benchmarks/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_portfolio_benchmark_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [2, [6, 'portfolio_benchmarks'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/portfolio_benchmark_uploads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_portfolio_benchmark_uploads_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [2, [6, 'v1'], [2, [7, '/'], [2, [6, 'portfolio_benchmark_uploads'], [1, [2, [8, '.'], [3, 'format']]]]]],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/portfolio_benchmarks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_portfolio_benchmarks_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [2, [7, '/'], [2, [6, 'v1'], [2, [7, '/'], [2, [6, 'portfolio_benchmarks'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/portfolio_manual_uploads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_portfolio_manual_uploads_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [2, [6, 'v1'], [2, [7, '/'], [2, [6, 'portfolio_manual_uploads'], [1, [2, [8, '.'], [3, 'format']]]]]],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/portfolio_returns(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_portfolio_returns_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [2, [7, '/'], [2, [6, 'v1'], [2, [7, '/'], [2, [6, 'portfolio_returns'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/portfolios(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_portfolios_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [2, [7, '/'], [2, [6, 'v1'], [2, [7, '/'], [2, [6, 'portfolios'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/portfolios_benchmarks/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_portfolios_benchmark_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [2, [6, 'portfolios_benchmarks'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/portfolios_benchmarks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_portfolios_benchmarks_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [2, [7, '/'], [2, [6, 'v1'], [2, [7, '/'], [2, [6, 'portfolios_benchmarks'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/private_assets/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_private_asset_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [2, [7, '/'], [2, [6, 'private_assets'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/private_assets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_private_assets_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [2, [7, '/'], [2, [6, 'v1'], [2, [7, '/'], [2, [6, 'private_assets'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/report_templates/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_report_template_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [2, [7, '/'], [2, [6, 'report_templates'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/report_template_buckets/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_report_template_bucket_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [2, [6, 'report_template_buckets'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/report_template_buckets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_report_template_buckets_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [2, [6, 'v1'], [2, [7, '/'], [2, [6, 'report_template_buckets'], [1, [2, [8, '.'], [3, 'format']]]]]],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/report_template_rows/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_report_template_row_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [2, [6, 'report_template_rows'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/report_template_rows(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_report_template_rows_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [2, [7, '/'], [2, [6, 'v1'], [2, [7, '/'], [2, [6, 'report_template_rows'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/report_template_sections/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_report_template_section_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [2, [6, 'report_template_sections'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/report_template_sections(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_report_template_sections_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [2, [6, 'v1'], [2, [7, '/'], [2, [6, 'report_template_sections'], [1, [2, [8, '.'], [3, 'format']]]]]],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/report_template_themes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_report_template_theme_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [2, [6, 'report_template_themes'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/report_templates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_report_templates_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [2, [7, '/'], [2, [6, 'v1'], [2, [7, '/'], [2, [6, 'report_templates'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/report_templates_report_template_sections/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_report_templates_report_template_section_path = __jsr.r(
  { id: { r: true }, format: { d: 'jsonapi' } },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'api'],
      [
        2,
        [7, '/'],
        [
          2,
          [6, 'v1'],
          [
            2,
            [7, '/'],
            [
              2,
              [6, 'report_templates_report_template_sections'],
              [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]],
            ],
          ],
        ],
      ],
    ],
  ],
);

/**
 * Generates rails route to
 * /api/v1/report_templates_report_template_sections(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_report_templates_report_template_sections_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [2, [7, '/'], [2, [6, 'report_templates_report_template_sections'], [1, [2, [8, '.'], [3, 'format']]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/reviews/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_review_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [2, [7, '/'], [2, [6, 'reviews'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/review_reports/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_review_report_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [2, [7, '/'], [2, [6, 'review_reports'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/review_reports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_review_reports_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [2, [7, '/'], [2, [6, 'v1'], [2, [7, '/'], [2, [6, 'review_reports'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/review_transitions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_review_transition_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [2, [7, '/'], [2, [6, 'review_transitions'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/review_transitions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_review_transitions_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [2, [7, '/'], [2, [6, 'v1'], [2, [7, '/'], [2, [6, 'review_transitions'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/reviews(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_reviews_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [2, [6, 'api'], [2, [7, '/'], [2, [6, 'v1'], [2, [7, '/'], [2, [6, 'reviews'], [1, [2, [8, '.'], [3, 'format']]]]]]]],
]);

/**
 * Generates rails route to
 * /api/v1/roles/client_users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_roles_client_user_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'roles'],
            [2, [7, '/'], [2, [6, 'client_users'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/roles/client_users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_roles_client_users_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [2, [7, '/'], [2, [6, 'roles'], [2, [7, '/'], [2, [6, 'client_users'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/securities(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_securities_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [2, [7, '/'], [2, [6, 'v1'], [2, [7, '/'], [2, [6, 'securities'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/securities/classifications/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_securities_classification_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'securities'],
            [
              2,
              [7, '/'],
              [2, [6, 'classifications'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]],
            ],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/securities/classifications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_securities_classifications_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [2, [6, 'securities'], [2, [7, '/'], [2, [6, 'classifications'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/securities/states/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_securities_state_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'securities'],
            [2, [7, '/'], [2, [6, 'states'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/securities/state_classification_weightings/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_securities_state_classification_weighting_path = __jsr.r(
  { id: { r: true }, format: { d: 'jsonapi' } },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'api'],
      [
        2,
        [7, '/'],
        [
          2,
          [6, 'v1'],
          [
            2,
            [7, '/'],
            [
              2,
              [6, 'securities'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [6, 'state_classification_weightings'],
                  [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ],
);

/**
 * Generates rails route to
 * /api/v1/securities/state_classification_weightings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_securities_state_classification_weightings_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'securities'],
            [2, [7, '/'], [2, [6, 'state_classification_weightings'], [1, [2, [8, '.'], [3, 'format']]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/securities/states(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_securities_states_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [2, [7, '/'], [2, [6, 'securities'], [2, [7, '/'], [2, [6, 'states'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/securities/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_security_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [2, [7, '/'], [2, [6, 'securities'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/template_action_points/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_template_action_point_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [2, [6, 'template_action_points'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/template_action_points(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_template_action_points_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [2, [7, '/'], [2, [6, 'v1'], [2, [7, '/'], [2, [6, 'template_action_points'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/todo_items/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_todo_item_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [2, [7, '/'], [2, [6, 'todo_items'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/todo_items(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_todo_items_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [2, [7, '/'], [2, [6, 'v1'], [2, [7, '/'], [2, [6, 'todo_items'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/tracked_jobs/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_tracked_job_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [2, [7, '/'], [2, [6, 'tracked_jobs'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/tracked_reviews/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_tracked_review_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [2, [7, '/'], [2, [6, 'tracked_reviews'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/tracked_reviews(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_tracked_reviews_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [2, [7, '/'], [2, [6, 'v1'], [2, [7, '/'], [2, [6, 'tracked_reviews'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/trackers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_tracker_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [2, [7, '/'], [2, [6, 'trackers'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/trackers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_trackers_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [2, [7, '/'], [2, [6, 'v1'], [2, [7, '/'], [2, [6, 'trackers'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/upload_templates/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_upload_template_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [2, [7, '/'], [2, [6, 'upload_templates'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/upload_template_additional_attributes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_upload_template_additional_attribute_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'upload_template_additional_attributes'],
            [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/upload_template_additional_attributes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_upload_template_additional_attributes_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [2, [7, '/'], [2, [6, 'upload_template_additional_attributes'], [1, [2, [8, '.'], [3, 'format']]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/upload_template_conditions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_upload_template_condition_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [2, [6, 'upload_template_conditions'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/upload_template_conditions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_upload_template_conditions_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [2, [6, 'v1'], [2, [7, '/'], [2, [6, 'upload_template_conditions'], [1, [2, [8, '.'], [3, 'format']]]]]],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/upload_template_sheets/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_upload_template_sheet_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [2, [6, 'upload_template_sheets'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/upload_template_sheet_columns/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_upload_template_sheet_column_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [2, [6, 'upload_template_sheet_columns'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/upload_template_sheet_columns(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_upload_template_sheet_columns_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [2, [6, 'v1'], [2, [7, '/'], [2, [6, 'upload_template_sheet_columns'], [1, [2, [8, '.'], [3, 'format']]]]]],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/upload_template_sheets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_upload_template_sheets_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [2, [7, '/'], [2, [6, 'v1'], [2, [7, '/'], [2, [6, 'upload_template_sheets'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/upload_templates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_upload_templates_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [2, [7, '/'], [2, [6, 'v1'], [2, [7, '/'], [2, [6, 'upload_templates'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_user_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [2, [7, '/'], [2, [6, 'users'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_users_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [2, [6, 'api'], [2, [7, '/'], [2, [6, 'v1'], [2, [7, '/'], [2, [6, 'users'], [1, [2, [8, '.'], [3, 'format']]]]]]]],
]);

/**
 * Generates rails route to
 * /api/v1/valuations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_valuation_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [2, [7, '/'], [2, [6, 'valuations'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/valuations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_valuations_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [2, [7, '/'], [2, [6, 'v1'], [2, [7, '/'], [2, [6, 'valuations'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /auth/azure_oauth2/callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const auth_azure_oauth2_callback_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'auth'],
    [2, [7, '/'], [2, [6, 'azure_oauth2'], [2, [7, '/'], [2, [6, 'callback'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /auth/xero_oauth2/callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const auth_xero_oauth2_callback_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'auth'],
    [2, [7, '/'], [2, [6, 'xero_oauth2'], [2, [7, '/'], [2, [6, 'callback'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /manager_portal/data_uploads/bulk_create(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bulk_create_manager_portal_data_uploads_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'manager_portal'],
    [2, [7, '/'], [2, [6, 'data_uploads'], [2, [7, '/'], [2, [6, 'bulk_create'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /platform/dashboard/reviews/bulk_create(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bulk_create_platform_dashboard_reviews_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'dashboard'],
        [2, [7, '/'], [2, [6, 'reviews'], [2, [7, '/'], [2, [6, 'bulk_create'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/managers/:manager_id/data_uploads/bulk_create(.:format)
 * @param {any} manager_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bulk_create_platform_manager_data_uploads_path = __jsr.r({ manager_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'managers'],
        [
          2,
          [7, '/'],
          [
            2,
            [3, 'manager_id'],
            [
              2,
              [7, '/'],
              [2, [6, 'data_uploads'], [2, [7, '/'], [2, [6, 'bulk_create'], [1, [2, [8, '.'], [3, 'format']]]]]],
            ],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/administrators_notifications/bulk_update(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bulk_update_platform_administrators_notifications_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'administrators_notifications'],
        [2, [7, '/'], [2, [6, 'bulk_update'], [1, [2, [8, '.'], [3, 'format']]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /data_provision_guidelines(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const data_provision_guidelines_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'data_provision_guidelines'], [1, [2, [8, '.'], [3, 'format']]]],
]);

/**
 * Generates rails route to
 * /delivery_options/api(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delivery_options_api_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'delivery_options'], [2, [7, '/'], [2, [6, 'api'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /delivery_options/data_mapping(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delivery_options_data_mapping_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'delivery_options'], [2, [7, '/'], [2, [6, 'data_mapping'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /delivery_options/example_file(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delivery_options_example_file_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'delivery_options'], [2, [7, '/'], [2, [6, 'example_file'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /delivery_options/portal_download(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delivery_options_portal_download_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'delivery_options'], [2, [7, '/'], [2, [6, 'portal_download'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /delivery_options/portal_upload(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delivery_options_portal_upload_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'delivery_options'], [2, [7, '/'], [2, [6, 'portal_upload'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /delivery_options/sftp_delivery(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delivery_options_sftp_delivery_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'delivery_options'], [2, [7, '/'], [2, [6, 'sftp_delivery'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /administrators/sign_out(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_administrator_session_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'administrators'], [2, [7, '/'], [2, [6, 'sign_out'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /manager_portal/sign_out(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_manager_user_session_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'manager_portal'], [2, [7, '/'], [2, [6, 'sign_out'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /platform/administrators/companies/:company_id/sign_out(.:format)
 * @param {any} company_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_platform_administrator_company_session_path = __jsr.r({ company_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'administrators'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'companies'],
            [
              2,
              [7, '/'],
              [2, [3, 'company_id'], [2, [7, '/'], [2, [6, 'sign_out'], [1, [2, [8, '.'], [3, 'format']]]]]],
            ],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /portal/sign_out(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_user_session_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'portal'], [2, [7, '/'], [2, [6, 'sign_out'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /rails/active_storage/direct_uploads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const direct_uploads_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'rails'],
    [
      2,
      [7, '/'],
      [2, [6, 'active_storage'], [2, [7, '/'], [2, [6, 'direct_uploads'], [1, [2, [8, '.'], [3, 'format']]]]]],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/clients/:client_id/factsheets/download(.:format)
 * @param {any} client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_platform_client_factsheets_path = __jsr.r({ client_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'clients'],
        [
          2,
          [7, '/'],
          [
            2,
            [3, 'client_id'],
            [
              2,
              [7, '/'],
              [2, [6, 'factsheets'], [2, [7, '/'], [2, [6, 'download'], [1, [2, [8, '.'], [3, 'format']]]]]],
            ],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/bulk_imports/contacts/download_template(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_template_platform_bulk_imports_contacts_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'bulk_imports'],
        [
          2,
          [7, '/'],
          [2, [6, 'contacts'], [2, [7, '/'], [2, [6, 'download_template'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/bulk_portfolio_imports/download_template(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_template_platform_bulk_portfolio_imports_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'bulk_portfolio_imports'],
        [2, [7, '/'], [2, [6, 'download_template'], [1, [2, [8, '.'], [3, 'format']]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/report_templates/:id/duplicate(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const duplicate_platform_report_template_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'report_templates'],
        [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'duplicate'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /administrators/password/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_administrator_password_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'administrators'],
    [2, [7, '/'], [2, [6, 'password'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/bulk_imports/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_api_v1_bulk_import_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'bulk_imports'],
            [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/client_tiles/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_api_v1_client_tile_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'client_tiles'],
            [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/commentaries/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_api_v1_commentary_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'commentaries'],
            [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/data_chase_templates/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_api_v1_data_chase_template_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'data_chase_templates'],
            [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/data_upload_errors/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_api_v1_data_upload_error_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'data_upload_errors'],
            [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/entity_groupings/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_api_v1_entity_grouping_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'entity_groupings'],
            [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/entity_relationship_roles/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_api_v1_entity_relationship_role_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'entity_relationship_roles'],
            [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/extracts/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_api_v1_extract_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'extracts'],
            [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/investment_risk_assessments/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_api_v1_investment_risk_assessment_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'investment_risk_assessments'],
            [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/notes/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_api_v1_note_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'notes'],
            [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/notes_notables/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_api_v1_notes_notable_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'notes_notables'],
            [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/permissions/client_users/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_api_v1_permissions_client_user_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'permissions'],
            [
              2,
              [7, '/'],
              [
                2,
                [6, 'client_users'],
                [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
              ],
            ],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/report_templates/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_api_v1_report_template_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'report_templates'],
            [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/report_template_buckets/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_api_v1_report_template_bucket_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'report_template_buckets'],
            [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/report_template_rows/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_api_v1_report_template_row_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'report_template_rows'],
            [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/report_template_sections/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_api_v1_report_template_section_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'report_template_sections'],
            [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/report_templates_report_template_sections/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_api_v1_report_templates_report_template_section_path = __jsr.r(
  { id: { r: true }, format: { d: 'jsonapi' } },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'api'],
      [
        2,
        [7, '/'],
        [
          2,
          [6, 'v1'],
          [
            2,
            [7, '/'],
            [
              2,
              [6, 'report_templates_report_template_sections'],
              [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
            ],
          ],
        ],
      ],
    ],
  ],
);

/**
 * Generates rails route to
 * /api/v1/reviews/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_api_v1_review_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'reviews'],
            [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/review_reports/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_api_v1_review_report_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'review_reports'],
            [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/review_transitions/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_api_v1_review_transition_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'review_transitions'],
            [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/roles/client_users/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_api_v1_roles_client_user_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'roles'],
            [
              2,
              [7, '/'],
              [
                2,
                [6, 'client_users'],
                [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
              ],
            ],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/template_action_points/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_api_v1_template_action_point_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'template_action_points'],
            [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/todo_items/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_api_v1_todo_item_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'todo_items'],
            [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/tracked_reviews/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_api_v1_tracked_review_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'tracked_reviews'],
            [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/trackers/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_api_v1_tracker_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'trackers'],
            [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/upload_templates/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_api_v1_upload_template_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'upload_templates'],
            [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/upload_template_additional_attributes/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_api_v1_upload_template_additional_attribute_path = __jsr.r(
  { id: { r: true }, format: { d: 'jsonapi' } },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'api'],
      [
        2,
        [7, '/'],
        [
          2,
          [6, 'v1'],
          [
            2,
            [7, '/'],
            [
              2,
              [6, 'upload_template_additional_attributes'],
              [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
            ],
          ],
        ],
      ],
    ],
  ],
);

/**
 * Generates rails route to
 * /api/v1/upload_template_conditions/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_api_v1_upload_template_condition_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'upload_template_conditions'],
            [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/upload_template_sheets/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_api_v1_upload_template_sheet_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'upload_template_sheets'],
            [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/upload_template_sheet_columns/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_api_v1_upload_template_sheet_column_path = __jsr.r({ id: { r: true }, format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'upload_template_sheet_columns'],
            [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /manager_portal/data_uploads/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_manager_portal_data_upload_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'manager_portal'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'data_uploads'],
        [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /manager_portal/manager_users/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_manager_portal_manager_user_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'manager_portal'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'manager_users'],
        [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /manager_portal/reviews/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_manager_portal_review_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'manager_portal'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'reviews'],
        [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /manager_portal/password/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_manager_user_password_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'manager_portal'],
    [2, [7, '/'], [2, [6, 'password'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /platform/accounting/monitoring/postings/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_platform_accounting_monitoring_posting_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'accounting'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'monitoring'],
            [
              2,
              [7, '/'],
              [
                2,
                [6, 'postings'],
                [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
              ],
            ],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/administrators/companies/:company_id/password/edit(.:format)
 * @param {any} company_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_platform_administrator_company_password_path = __jsr.r({ company_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'administrators'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'companies'],
            [
              2,
              [7, '/'],
              [
                2,
                [3, 'company_id'],
                [2, [7, '/'], [2, [6, 'password'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
              ],
            ],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/administrators_notifications/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_platform_administrators_notification_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'administrators_notifications'],
        [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/asset_mappings/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_platform_asset_mapping_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'asset_mappings'],
        [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/bulk_portfolio_imports/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_platform_bulk_portfolio_import_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'bulk_portfolio_imports'],
        [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/clients/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_platform_client_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'clients'],
        [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/clients/:client_id/extra_settings/edit(.:format)
 * @param {any} client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_platform_client_extra_settings_path = __jsr.r({ client_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'clients'],
        [
          2,
          [7, '/'],
          [
            2,
            [3, 'client_id'],
            [
              2,
              [7, '/'],
              [2, [6, 'extra_settings'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]],
            ],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/clients/:client_id/investment_risk_assessments/:id/edit(.:format)
 * @param {any} client_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_platform_client_investment_risk_assessment_path = __jsr.r(
  { client_id: { r: true }, id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'platform'],
      [
        2,
        [7, '/'],
        [
          2,
          [6, 'clients'],
          [
            2,
            [7, '/'],
            [
              2,
              [3, 'client_id'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [6, 'investment_risk_assessments'],
                  [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ],
);

/**
 * Generates rails route to
 * /platform/client_tiles/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_platform_client_tile_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'client_tiles'],
        [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/cluster_mappings/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_platform_cluster_mapping_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'cluster_mappings'],
        [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/companies/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_platform_company_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'companies'],
        [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/companies/:company_id/administrators/:administrator_id/reviews/edit(.:format)
 * @param {any} company_id
 * @param {any} administrator_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_platform_company_administrator_reviews_path = __jsr.r(
  { company_id: { r: true }, administrator_id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'platform'],
      [
        2,
        [7, '/'],
        [
          2,
          [6, 'companies'],
          [
            2,
            [7, '/'],
            [
              2,
              [3, 'company_id'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [6, 'administrators'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [3, 'administrator_id'],
                      [
                        2,
                        [7, '/'],
                        [2, [6, 'reviews'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]],
                      ],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ],
);

/**
 * Generates rails route to
 * /platform/table_data_modifier/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_platform_table_data_modifier_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'table_data_modifier'],
        [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/entities/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_platform_entity_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'entities'],
        [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/entity_relationship_roles/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_platform_entity_relationship_role_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'entity_relationship_roles'],
        [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/managers/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_platform_manager_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'managers'],
        [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/managers/:manager_id/manager_users/:id/edit(.:format)
 * @param {any} manager_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_platform_manager_manager_user_path = __jsr.r(
  { manager_id: { r: true }, id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'platform'],
      [
        2,
        [7, '/'],
        [
          2,
          [6, 'managers'],
          [
            2,
            [7, '/'],
            [
              2,
              [3, 'manager_id'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [6, 'manager_users'],
                  [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ],
);

/**
 * Generates rails route to
 * /platform/managers/:manager_id/upload_templates/edit(.:format)
 * @param {any} manager_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_platform_manager_upload_templates_path = __jsr.r({ manager_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'managers'],
        [
          2,
          [7, '/'],
          [
            2,
            [3, 'manager_id'],
            [
              2,
              [7, '/'],
              [2, [6, 'upload_templates'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]],
            ],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/market_commentaries/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_platform_market_commentary_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'market_commentaries'],
        [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/notes/:note_id/attachments/:id/edit(.:format)
 * @param {any} note_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_platform_note_attachment_path = __jsr.r({ note_id: { r: true }, id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'notes'],
        [
          2,
          [7, '/'],
          [
            2,
            [3, 'note_id'],
            [
              2,
              [7, '/'],
              [
                2,
                [6, 'attachments'],
                [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
              ],
            ],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/parent_managers/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_platform_parent_manager_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'parent_managers'],
        [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/portfolios/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_platform_portfolio_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'portfolios'],
        [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/portfolio_benchmarks/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_platform_portfolio_benchmark_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'portfolio_benchmarks'],
        [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/portfolios/:portfolio_id/notes/:id/edit(.:format)
 * @param {any} portfolio_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_platform_portfolio_note_path = __jsr.r({ portfolio_id: { r: true }, id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'portfolios'],
        [
          2,
          [7, '/'],
          [
            2,
            [3, 'portfolio_id'],
            [
              2,
              [7, '/'],
              [
                2,
                [6, 'notes'],
                [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
              ],
            ],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/portfolios/:portfolio_id/performance_numbers/:id/edit(.:format)
 * @param {any} portfolio_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_platform_portfolio_performance_number_path = __jsr.r(
  { portfolio_id: { r: true }, id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'platform'],
      [
        2,
        [7, '/'],
        [
          2,
          [6, 'portfolios'],
          [
            2,
            [7, '/'],
            [
              2,
              [3, 'portfolio_id'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [6, 'performance_numbers'],
                  [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ],
);

/**
 * Generates rails route to
 * /platform/portfolios/:portfolio_id/reviews/:review_id/reports/:id/edit(.:format)
 * @param {any} portfolio_id
 * @param {any} review_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_platform_portfolio_review_report_path = __jsr.r(
  { portfolio_id: { r: true }, review_id: { r: true }, id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'platform'],
      [
        2,
        [7, '/'],
        [
          2,
          [6, 'portfolios'],
          [
            2,
            [7, '/'],
            [
              2,
              [3, 'portfolio_id'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [6, 'reviews'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [3, 'review_id'],
                      [
                        2,
                        [7, '/'],
                        [
                          2,
                          [6, 'reports'],
                          [
                            2,
                            [7, '/'],
                            [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ],
);

/**
 * Generates rails route to
 * /platform/report_templates/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_platform_report_template_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'report_templates'],
        [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/reviews/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_platform_review_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'reviews'],
        [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/reviews/:review_id/commentaries/edit(.:format)
 * @param {any} review_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_platform_review_commentaries_path = __jsr.r({ review_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'reviews'],
        [
          2,
          [7, '/'],
          [
            2,
            [3, 'review_id'],
            [2, [7, '/'], [2, [6, 'commentaries'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/securities/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_platform_security_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'securities'],
        [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/trackers/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_platform_trackers_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [2, [7, '/'], [2, [6, 'trackers'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /platform/users/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_platform_user_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'users'],
        [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/xero_tenants/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_platform_xero_tenant_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'xero_tenants'],
        [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_rails_conductor_inbound_email_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'rails'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'conductor'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'action_mailbox'],
            [
              2,
              [7, '/'],
              [
                2,
                [6, 'inbound_emails'],
                [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
              ],
            ],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /portal/password/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_user_password_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'portal'],
    [2, [7, '/'], [2, [6, 'password'], [2, [7, '/'], [2, [6, 'edit'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /platform/portfolios/:id/investment_policy_statements/:investment_policy_statement_id(.:format)
 * @param {any} id
 * @param {any} investment_policy_statement_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const investment_policy_statement_platform_portfolio_path = __jsr.r(
  { id: { r: true }, investment_policy_statement_id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'platform'],
      [
        2,
        [7, '/'],
        [
          2,
          [6, 'portfolios'],
          [
            2,
            [7, '/'],
            [
              2,
              [3, 'id'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [6, 'investment_policy_statements'],
                  [2, [7, '/'], [2, [3, 'investment_policy_statement_id'], [1, [2, [8, '.'], [3, 'format']]]]],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ],
);

/**
 * Generates rails route to
 * /manager_portal/dashboard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manager_portal_dashboard_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'manager_portal'], [2, [7, '/'], [2, [6, 'dashboard'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /manager_portal/data_uploads/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manager_portal_data_upload_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'manager_portal'],
    [2, [7, '/'], [2, [6, 'data_uploads'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /manager_portal/data_uploads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manager_portal_data_uploads_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'manager_portal'], [2, [7, '/'], [2, [6, 'data_uploads'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /manager_portal/manager(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manager_portal_manager_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'manager_portal'], [2, [7, '/'], [2, [6, 'manager'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /manager_portal/manager_users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manager_portal_manager_user_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'manager_portal'],
    [2, [7, '/'], [2, [6, 'manager_users'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /manager_portal/reviews/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manager_portal_review_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'manager_portal'],
    [2, [7, '/'], [2, [6, 'reviews'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /manager_portal/reviews(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manager_portal_reviews_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'manager_portal'], [2, [7, '/'], [2, [6, 'reviews'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /manager_portal(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manager_portal_root_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'manager_portal'], [1, [2, [8, '.'], [3, 'format']]]],
]);

/**
 * Generates rails route to
 * /manager_portal/password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manager_user_password_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'manager_portal'], [2, [7, '/'], [2, [6, 'password'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /manager_portal/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manager_user_session_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'manager_portal'], [2, [7, '/'], [2, [6, 'sign_in'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /manager_portal/unlock(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manager_user_unlock_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'manager_portal'], [2, [7, '/'], [2, [6, 'unlock'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /platform/portfolios/:id/mandates/:mandate_id(.:format)
 * @param {any} id
 * @param {any} mandate_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mandate_platform_portfolio_path = __jsr.r({ id: { r: true }, mandate_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'portfolios'],
        [
          2,
          [7, '/'],
          [
            2,
            [3, 'id'],
            [
              2,
              [7, '/'],
              [2, [6, 'mandates'], [2, [7, '/'], [2, [3, 'mandate_id'], [1, [2, [8, '.'], [3, 'format']]]]]],
            ],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/reviews/move_stage(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const move_stage_platform_reviews_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [2, [7, '/'], [2, [6, 'reviews'], [2, [7, '/'], [2, [6, 'move_stage'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /administrators/invitation/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_administrator_invitation_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'administrators'],
    [2, [7, '/'], [2, [6, 'invitation'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /administrators/password/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_administrator_password_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'administrators'],
    [2, [7, '/'], [2, [6, 'password'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /administrators/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_administrator_session_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'administrators'], [2, [7, '/'], [2, [6, 'sign_in'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /administrators/unlock/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_administrator_unlock_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'administrators'],
    [2, [7, '/'], [2, [6, 'unlock'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/bulk_imports/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_api_v1_bulk_import_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [2, [7, '/'], [2, [6, 'bulk_imports'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/client_tiles/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_api_v1_client_tile_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [2, [7, '/'], [2, [6, 'client_tiles'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/commentaries/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_api_v1_commentary_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [2, [7, '/'], [2, [6, 'commentaries'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/data_chase_templates/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_api_v1_data_chase_template_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [2, [6, 'data_chase_templates'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/data_upload_errors/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_api_v1_data_upload_error_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [2, [6, 'data_upload_errors'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/entity_groupings/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_api_v1_entity_grouping_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [2, [7, '/'], [2, [6, 'entity_groupings'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/entity_relationship_roles/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_api_v1_entity_relationship_role_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [2, [6, 'entity_relationship_roles'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/extracts/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_api_v1_extract_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [2, [7, '/'], [2, [6, 'extracts'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/investment_risk_assessments/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_api_v1_investment_risk_assessment_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [2, [6, 'investment_risk_assessments'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/notes/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_api_v1_note_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [2, [7, '/'], [2, [6, 'notes'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/notes_notables/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_api_v1_notes_notable_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [2, [7, '/'], [2, [6, 'notes_notables'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/permissions/client_users/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_api_v1_permissions_client_user_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'permissions'],
            [2, [7, '/'], [2, [6, 'client_users'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/report_templates/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_api_v1_report_template_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [2, [7, '/'], [2, [6, 'report_templates'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/report_template_buckets/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_api_v1_report_template_bucket_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [2, [6, 'report_template_buckets'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/report_template_rows/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_api_v1_report_template_row_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [2, [6, 'report_template_rows'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/report_template_sections/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_api_v1_report_template_section_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [2, [6, 'report_template_sections'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/report_templates_report_template_sections/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_api_v1_report_templates_report_template_section_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'report_templates_report_template_sections'],
            [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/reviews/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_api_v1_review_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [2, [7, '/'], [2, [6, 'reviews'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/review_reports/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_api_v1_review_report_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [2, [7, '/'], [2, [6, 'review_reports'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/review_transitions/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_api_v1_review_transition_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [2, [6, 'review_transitions'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/roles/client_users/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_api_v1_roles_client_user_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'roles'],
            [2, [7, '/'], [2, [6, 'client_users'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/template_action_points/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_api_v1_template_action_point_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [2, [6, 'template_action_points'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/todo_items/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_api_v1_todo_item_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [2, [7, '/'], [2, [6, 'todo_items'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/tracked_reviews/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_api_v1_tracked_review_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [2, [7, '/'], [2, [6, 'tracked_reviews'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/trackers/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_api_v1_tracker_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [2, [7, '/'], [2, [6, 'trackers'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/upload_templates/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_api_v1_upload_template_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [2, [7, '/'], [2, [6, 'upload_templates'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/upload_template_additional_attributes/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_api_v1_upload_template_additional_attribute_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'upload_template_additional_attributes'],
            [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/upload_template_conditions/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_api_v1_upload_template_condition_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [2, [6, 'upload_template_conditions'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/upload_template_sheets/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_api_v1_upload_template_sheet_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [2, [6, 'upload_template_sheets'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /api/v1/upload_template_sheet_columns/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_api_v1_upload_template_sheet_column_path = __jsr.r({ format: { d: 'jsonapi' } }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'api'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'v1'],
        [
          2,
          [7, '/'],
          [2, [6, 'upload_template_sheet_columns'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /manager_portal/data_uploads/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_manager_portal_data_upload_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'manager_portal'],
    [2, [7, '/'], [2, [6, 'data_uploads'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /manager_portal/reviews/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_manager_portal_review_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'manager_portal'],
    [2, [7, '/'], [2, [6, 'reviews'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /manager_portal/password/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_manager_user_password_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'manager_portal'],
    [2, [7, '/'], [2, [6, 'password'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /manager_portal/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_manager_user_session_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'manager_portal'], [2, [7, '/'], [2, [6, 'sign_in'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /manager_portal/unlock/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_manager_user_unlock_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'manager_portal'],
    [2, [7, '/'], [2, [6, 'unlock'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /platform/accounting/monitoring/postings/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_platform_accounting_monitoring_posting_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'accounting'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'monitoring'],
            [2, [7, '/'], [2, [6, 'postings'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/administrators/companies/:company_id/invitation/new(.:format)
 * @param {any} company_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_platform_administrator_company_invitation_path = __jsr.r({ company_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'administrators'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'companies'],
            [
              2,
              [7, '/'],
              [
                2,
                [3, 'company_id'],
                [
                  2,
                  [7, '/'],
                  [2, [6, 'invitation'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/administrators/companies/:company_id/password/new(.:format)
 * @param {any} company_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_platform_administrator_company_password_path = __jsr.r({ company_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'administrators'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'companies'],
            [
              2,
              [7, '/'],
              [
                2,
                [3, 'company_id'],
                [2, [7, '/'], [2, [6, 'password'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]]],
              ],
            ],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/administrators/companies/:company_id/sign_in(.:format)
 * @param {any} company_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_platform_administrator_company_session_path = __jsr.r({ company_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'administrators'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'companies'],
            [
              2,
              [7, '/'],
              [2, [3, 'company_id'], [2, [7, '/'], [2, [6, 'sign_in'], [1, [2, [8, '.'], [3, 'format']]]]]],
            ],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/administrators/companies/:company_id/unlock/new(.:format)
 * @param {any} company_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_platform_administrator_company_unlock_path = __jsr.r({ company_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'administrators'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'companies'],
            [
              2,
              [7, '/'],
              [
                2,
                [3, 'company_id'],
                [2, [7, '/'], [2, [6, 'unlock'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]]],
              ],
            ],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/administrators_notifications/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_platform_administrators_notification_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [2, [6, 'administrators_notifications'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/asset_mappings/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_platform_asset_mapping_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [2, [7, '/'], [2, [6, 'asset_mappings'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /platform/bulk_imports/contacts/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_platform_bulk_imports_contacts_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'bulk_imports'],
        [2, [7, '/'], [2, [6, 'contacts'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/bulk_portfolio_imports/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_platform_bulk_portfolio_import_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [2, [6, 'bulk_portfolio_imports'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/clients/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_platform_client_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [2, [7, '/'], [2, [6, 'clients'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /platform/clients/:client_id/extra_settings/new(.:format)
 * @param {any} client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_platform_client_extra_settings_path = __jsr.r({ client_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'clients'],
        [
          2,
          [7, '/'],
          [
            2,
            [3, 'client_id'],
            [
              2,
              [7, '/'],
              [2, [6, 'extra_settings'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]],
            ],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/clients/:client_id/investment_risk_assessments/new(.:format)
 * @param {any} client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_platform_client_investment_risk_assessment_path = __jsr.r({ client_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'clients'],
        [
          2,
          [7, '/'],
          [
            2,
            [3, 'client_id'],
            [
              2,
              [7, '/'],
              [
                2,
                [6, 'investment_risk_assessments'],
                [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]],
              ],
            ],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/client_tiles/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_platform_client_tile_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [2, [7, '/'], [2, [6, 'client_tiles'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /platform/cluster_mappings/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_platform_cluster_mapping_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [2, [7, '/'], [2, [6, 'cluster_mappings'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /platform/companies/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_platform_company_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [2, [7, '/'], [2, [6, 'companies'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /platform/companies/:company_id/administrators/:administrator_id/reviews/new(.:format)
 * @param {any} company_id
 * @param {any} administrator_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_platform_company_administrator_reviews_path = __jsr.r(
  { company_id: { r: true }, administrator_id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'platform'],
      [
        2,
        [7, '/'],
        [
          2,
          [6, 'companies'],
          [
            2,
            [7, '/'],
            [
              2,
              [3, 'company_id'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [6, 'administrators'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [3, 'administrator_id'],
                      [
                        2,
                        [7, '/'],
                        [2, [6, 'reviews'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]],
                      ],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ],
);

/**
 * Generates rails route to
 * /platform/table_data_modifier/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_platform_table_data_modifier_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [2, [7, '/'], [2, [6, 'table_data_modifier'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /platform/entities/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_platform_entity_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [2, [7, '/'], [2, [6, 'entities'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /platform/entity_relationship_roles/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_platform_entity_relationship_role_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [2, [6, 'entity_relationship_roles'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/managers/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_platform_manager_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [2, [7, '/'], [2, [6, 'managers'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /platform/managers/:manager_id/manager_users/new(.:format)
 * @param {any} manager_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_platform_manager_manager_user_path = __jsr.r({ manager_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'managers'],
        [
          2,
          [7, '/'],
          [
            2,
            [3, 'manager_id'],
            [2, [7, '/'], [2, [6, 'manager_users'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/managers/:manager_id/upload_templates/new(.:format)
 * @param {any} manager_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_platform_manager_upload_templates_path = __jsr.r({ manager_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'managers'],
        [
          2,
          [7, '/'],
          [
            2,
            [3, 'manager_id'],
            [
              2,
              [7, '/'],
              [2, [6, 'upload_templates'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]],
            ],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/market_commentaries/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_platform_market_commentary_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [2, [7, '/'], [2, [6, 'market_commentaries'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /platform/notes/:note_id/attachments/new(.:format)
 * @param {any} note_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_platform_note_attachment_path = __jsr.r({ note_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'notes'],
        [
          2,
          [7, '/'],
          [
            2,
            [3, 'note_id'],
            [2, [7, '/'], [2, [6, 'attachments'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/parent_managers/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_platform_parent_manager_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [2, [7, '/'], [2, [6, 'parent_managers'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /platform/portfolios/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_platform_portfolio_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [2, [7, '/'], [2, [6, 'portfolios'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /platform/portfolio_benchmarks/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_platform_portfolio_benchmark_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [2, [7, '/'], [2, [6, 'portfolio_benchmarks'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /platform/portfolios/:portfolio_id/notes/new(.:format)
 * @param {any} portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_platform_portfolio_note_path = __jsr.r({ portfolio_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'portfolios'],
        [
          2,
          [7, '/'],
          [
            2,
            [3, 'portfolio_id'],
            [2, [7, '/'], [2, [6, 'notes'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/portfolios/:portfolio_id/performance_numbers/new(.:format)
 * @param {any} portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_platform_portfolio_performance_number_path = __jsr.r({ portfolio_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'portfolios'],
        [
          2,
          [7, '/'],
          [
            2,
            [3, 'portfolio_id'],
            [
              2,
              [7, '/'],
              [2, [6, 'performance_numbers'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]],
            ],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/portfolios/:portfolio_id/reviews/:review_id/reports/new(.:format)
 * @param {any} portfolio_id
 * @param {any} review_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_platform_portfolio_review_report_path = __jsr.r(
  { portfolio_id: { r: true }, review_id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'platform'],
      [
        2,
        [7, '/'],
        [
          2,
          [6, 'portfolios'],
          [
            2,
            [7, '/'],
            [
              2,
              [3, 'portfolio_id'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [6, 'reviews'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [3, 'review_id'],
                      [
                        2,
                        [7, '/'],
                        [2, [6, 'reports'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]],
                      ],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ],
);

/**
 * Generates rails route to
 * /platform/report_templates/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_platform_report_template_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [2, [7, '/'], [2, [6, 'report_templates'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /platform/reviews/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_platform_review_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [2, [7, '/'], [2, [6, 'reviews'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /platform/reviews/:review_id/commentaries/new(.:format)
 * @param {any} review_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_platform_review_commentaries_path = __jsr.r({ review_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'reviews'],
        [
          2,
          [7, '/'],
          [
            2,
            [3, 'review_id'],
            [2, [7, '/'], [2, [6, 'commentaries'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/securities/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_platform_security_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [2, [7, '/'], [2, [6, 'securities'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /platform/users/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_platform_user_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [2, [7, '/'], [2, [6, 'users'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /platform/xero_tenants/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_platform_xero_tenant_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [2, [7, '/'], [2, [6, 'xero_tenants'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_rails_conductor_inbound_email_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'rails'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'conductor'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'action_mailbox'],
            [
              2,
              [7, '/'],
              [2, [6, 'inbound_emails'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]],
            ],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /portal/invitation/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_invitation_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'portal'],
    [2, [7, '/'], [2, [6, 'invitation'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /portal/password/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_password_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'portal'],
    [2, [7, '/'], [2, [6, 'password'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /portal/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_session_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'portal'], [2, [7, '/'], [2, [6, 'sign_in'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /portal/unlock/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_unlock_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'portal'],
    [2, [7, '/'], [2, [6, 'unlock'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /users/terms_and_conditions/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_terms_and_condition_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'users'],
    [2, [7, '/'], [2, [6, 'terms_and_conditions'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /users/token_verifications/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_token_verification_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'users'],
    [2, [7, '/'], [2, [6, 'token_verifications'], [2, [7, '/'], [2, [6, 'new'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /platform/managers/:manager_id/upload_templates/parse_file(.:format)
 * @param {any} manager_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const parse_file_platform_manager_upload_templates_path = __jsr.r({ manager_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'managers'],
        [
          2,
          [7, '/'],
          [
            2,
            [3, 'manager_id'],
            [
              2,
              [7, '/'],
              [2, [6, 'upload_templates'], [2, [7, '/'], [2, [6, 'parse_file'], [1, [2, [8, '.'], [3, 'format']]]]]],
            ],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/accounting/dashboard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_accounting_dashboard_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [2, [7, '/'], [2, [6, 'accounting'], [2, [7, '/'], [2, [6, 'dashboard'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /platform/accounting/dashboard/reviews(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_accounting_dashboard_reviews_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'accounting'],
        [2, [7, '/'], [2, [6, 'dashboard'], [2, [7, '/'], [2, [6, 'reviews'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/accounting/monitoring/postings/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_accounting_monitoring_posting_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'accounting'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'monitoring'],
            [2, [7, '/'], [2, [6, 'postings'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/accounting/monitoring/postings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_accounting_monitoring_postings_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'accounting'],
        [2, [7, '/'], [2, [6, 'monitoring'], [2, [7, '/'], [2, [6, 'postings'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/administrators/companies/:company_id/invitation(.:format)
 * @param {any} company_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_administrator_company_invitation_path = __jsr.r({ company_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'administrators'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'companies'],
            [
              2,
              [7, '/'],
              [2, [3, 'company_id'], [2, [7, '/'], [2, [6, 'invitation'], [1, [2, [8, '.'], [3, 'format']]]]]],
            ],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/administrators/companies/:company_id/password(.:format)
 * @param {any} company_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_administrator_company_password_path = __jsr.r({ company_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'administrators'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'companies'],
            [
              2,
              [7, '/'],
              [2, [3, 'company_id'], [2, [7, '/'], [2, [6, 'password'], [1, [2, [8, '.'], [3, 'format']]]]]],
            ],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/administrators/companies/:company_id/sign_in(.:format)
 * @param {any} company_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_administrator_company_session_path = __jsr.r({ company_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'administrators'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'companies'],
            [
              2,
              [7, '/'],
              [2, [3, 'company_id'], [2, [7, '/'], [2, [6, 'sign_in'], [1, [2, [8, '.'], [3, 'format']]]]]],
            ],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/administrators/companies/:company_id/unlock(.:format)
 * @param {any} company_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_administrator_company_unlock_path = __jsr.r({ company_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'administrators'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'companies'],
            [2, [7, '/'], [2, [3, 'company_id'], [2, [7, '/'], [2, [6, 'unlock'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/administrators_notifications/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_administrators_notification_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [2, [6, 'administrators_notifications'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/administrators_notifications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_administrators_notifications_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'platform'], [2, [7, '/'], [2, [6, 'administrators_notifications'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /platform/asset_mappings/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_asset_mapping_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [2, [7, '/'], [2, [6, 'asset_mappings'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /platform/asset_mappings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_asset_mappings_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'platform'], [2, [7, '/'], [2, [6, 'asset_mappings'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /platform/nav_one_clients/:client_no/bank_account_transactions/:bank_no(.:format)
 * @param {any} client_no
 * @param {any} bank_no
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_bank_account_transaction_path = __jsr.r(
  { client_no: { r: true }, bank_no: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'platform'],
      [
        2,
        [7, '/'],
        [
          2,
          [6, 'nav_one_clients'],
          [
            2,
            [7, '/'],
            [
              2,
              [3, 'client_no'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [6, 'bank_account_transactions'],
                  [2, [7, '/'], [2, [3, 'bank_no'], [1, [2, [8, '.'], [3, 'format']]]]],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ],
);

/**
 * Generates rails route to
 * /platform/bulk_portfolio_imports/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_bulk_portfolio_import_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [2, [7, '/'], [2, [6, 'bulk_portfolio_imports'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /platform/bulk_portfolio_imports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_bulk_portfolio_imports_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'platform'], [2, [7, '/'], [2, [6, 'bulk_portfolio_imports'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /platform/clients/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_client_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [2, [7, '/'], [2, [6, 'clients'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /platform/clients/:client_id/contacts(.:format)
 * @param {any} client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_client_contacts_path = __jsr.r({ client_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'clients'],
        [2, [7, '/'], [2, [3, 'client_id'], [2, [7, '/'], [2, [6, 'contacts'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/clients/:client_id/extra_settings(.:format)
 * @param {any} client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_client_extra_settings_path = __jsr.r({ client_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'clients'],
        [
          2,
          [7, '/'],
          [2, [3, 'client_id'], [2, [7, '/'], [2, [6, 'extra_settings'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/clients/:client_id/factsheets(.:format)
 * @param {any} client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_client_factsheets_path = __jsr.r({ client_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'clients'],
        [2, [7, '/'], [2, [3, 'client_id'], [2, [7, '/'], [2, [6, 'factsheets'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/clients/:client_id/investment_risk_assessments/:id(.:format)
 * @param {any} client_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_client_investment_risk_assessment_path = __jsr.r(
  { client_id: { r: true }, id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'platform'],
      [
        2,
        [7, '/'],
        [
          2,
          [6, 'clients'],
          [
            2,
            [7, '/'],
            [
              2,
              [3, 'client_id'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [6, 'investment_risk_assessments'],
                  [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ],
);

/**
 * Generates rails route to
 * /platform/clients/:client_id/investment_risk_assessments(.:format)
 * @param {any} client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_client_investment_risk_assessments_path = __jsr.r({ client_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'clients'],
        [
          2,
          [7, '/'],
          [
            2,
            [3, 'client_id'],
            [2, [7, '/'], [2, [6, 'investment_risk_assessments'], [1, [2, [8, '.'], [3, 'format']]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/nav_one_clients/:client_no(.:format)
 * @param {any} client_no
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_client_no_path = __jsr.r({ client_no: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [2, [7, '/'], [2, [6, 'nav_one_clients'], [2, [7, '/'], [2, [3, 'client_no'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /platform/clients/:client_id/sent_reviews(.:format)
 * @param {any} client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_client_sent_reviews_path = __jsr.r({ client_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'clients'],
        [
          2,
          [7, '/'],
          [2, [3, 'client_id'], [2, [7, '/'], [2, [6, 'sent_reviews'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/client_tiles/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_client_tile_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [2, [7, '/'], [2, [6, 'client_tiles'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /platform/client_tiles(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_client_tiles_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'platform'], [2, [7, '/'], [2, [6, 'client_tiles'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /platform/clients/:client_id/xero/postings(.:format)
 * @param {any} client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_client_xero_postings_path = __jsr.r({ client_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'clients'],
        [
          2,
          [7, '/'],
          [
            2,
            [3, 'client_id'],
            [2, [7, '/'], [2, [6, 'xero'], [2, [7, '/'], [2, [6, 'postings'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/clients/:client_id/zipped_reviews(.:format)
 * @param {any} client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_client_zipped_reviews_path = __jsr.r({ client_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'clients'],
        [
          2,
          [7, '/'],
          [2, [3, 'client_id'], [2, [7, '/'], [2, [6, 'zipped_reviews'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/clients(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_clients_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'platform'], [2, [7, '/'], [2, [6, 'clients'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /platform/cluster_mappings/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_cluster_mapping_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [2, [7, '/'], [2, [6, 'cluster_mappings'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /platform/cluster_mappings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_cluster_mappings_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'platform'], [2, [7, '/'], [2, [6, 'cluster_mappings'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /platform/commentaries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_commentaries_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'platform'], [2, [7, '/'], [2, [6, 'commentaries'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /platform/companies(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_companies_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'platform'], [2, [7, '/'], [2, [6, 'companies'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /platform/companies/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_company_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [2, [7, '/'], [2, [6, 'companies'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /platform/companies/:company_id/administrators/:id(.:format)
 * @param {any} company_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_company_administrator_path = __jsr.r({ company_id: { r: true }, id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'companies'],
        [
          2,
          [7, '/'],
          [
            2,
            [3, 'company_id'],
            [2, [7, '/'], [2, [6, 'administrators'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/companies/:company_id/administrators/:administrator_id/reviews(.:format)
 * @param {any} company_id
 * @param {any} administrator_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_company_administrator_reviews_path = __jsr.r(
  { company_id: { r: true }, administrator_id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'platform'],
      [
        2,
        [7, '/'],
        [
          2,
          [6, 'companies'],
          [
            2,
            [7, '/'],
            [
              2,
              [3, 'company_id'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [6, 'administrators'],
                  [
                    2,
                    [7, '/'],
                    [2, [3, 'administrator_id'], [2, [7, '/'], [2, [6, 'reviews'], [1, [2, [8, '.'], [3, 'format']]]]]],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ],
);

/**
 * Generates rails route to
 * /platform/companies/:company_id/administrators(.:format)
 * @param {any} company_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_company_administrators_path = __jsr.r({ company_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'companies'],
        [
          2,
          [7, '/'],
          [2, [3, 'company_id'], [2, [7, '/'], [2, [6, 'administrators'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/companies/:company_id/company_switcher(.:format)
 * @param {any} company_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_company_company_switcher_path = __jsr.r({ company_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'companies'],
        [
          2,
          [7, '/'],
          [2, [3, 'company_id'], [2, [7, '/'], [2, [6, 'company_switcher'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/table_data_modifier/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_table_data_modifier_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [2, [7, '/'], [2, [6, 'table_data_modifier'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /platform/table_data_modifier(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_table_data_modifier_index_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'platform'], [2, [7, '/'], [2, [6, 'table_data_modifier'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /platform/dashboard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_dashboard_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'platform'], [2, [7, '/'], [2, [6, 'dashboard'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /platform/dashboard/reviews(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_dashboard_reviews_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [2, [7, '/'], [2, [6, 'dashboard'], [2, [7, '/'], [2, [6, 'reviews'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /platform/data_chase_templates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_data_chase_templates_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'platform'], [2, [7, '/'], [2, [6, 'data_chase_templates'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /platform/entities(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_entities_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'platform'], [2, [7, '/'], [2, [6, 'entities'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /platform/entities/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_entity_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [2, [7, '/'], [2, [6, 'entities'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /platform/entity_relationship_roles/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_entity_relationship_role_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [2, [6, 'entity_relationship_roles'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/entity_relationship_roles(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_entity_relationship_roles_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'platform'], [2, [7, '/'], [2, [6, 'entity_relationship_roles'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /platform/entities/:entity_id/structure_graphs(.:format)
 * @param {any} entity_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_entity_structure_graphs_path = __jsr.r({ entity_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'entities'],
        [
          2,
          [7, '/'],
          [2, [3, 'entity_id'], [2, [7, '/'], [2, [6, 'structure_graphs'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/extracts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_extracts_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'platform'], [2, [7, '/'], [2, [6, 'extracts'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /platform/investment_restrictions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_investment_restriction_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [2, [6, 'investment_restrictions'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/nav_one_clients/:client_no/investment_transactions/:portfolio_no(.:format)
 * @param {any} client_no
 * @param {any} portfolio_no
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_investment_transaction_path = __jsr.r(
  { client_no: { r: true }, portfolio_no: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'platform'],
      [
        2,
        [7, '/'],
        [
          2,
          [6, 'nav_one_clients'],
          [
            2,
            [7, '/'],
            [
              2,
              [3, 'client_no'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [6, 'investment_transactions'],
                  [2, [7, '/'], [2, [3, 'portfolio_no'], [1, [2, [8, '.'], [3, 'format']]]]],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ],
);

/**
 * Generates rails route to
 * /platform/managers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_manager_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [2, [7, '/'], [2, [6, 'managers'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /platform/managers/:manager_id/data_uploads/:id(.:format)
 * @param {any} manager_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_manager_data_upload_path = __jsr.r({ manager_id: { r: true }, id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'managers'],
        [
          2,
          [7, '/'],
          [
            2,
            [3, 'manager_id'],
            [2, [7, '/'], [2, [6, 'data_uploads'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/managers/:manager_id/manager_users/:id(.:format)
 * @param {any} manager_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_manager_manager_user_path = __jsr.r({ manager_id: { r: true }, id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'managers'],
        [
          2,
          [7, '/'],
          [
            2,
            [3, 'manager_id'],
            [2, [7, '/'], [2, [6, 'manager_users'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/managers/:manager_id/manager_users/:manager_user_id/portfolios(.:format)
 * @param {any} manager_id
 * @param {any} manager_user_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_manager_manager_user_portfolios_path = __jsr.r(
  { manager_id: { r: true }, manager_user_id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'platform'],
      [
        2,
        [7, '/'],
        [
          2,
          [6, 'managers'],
          [
            2,
            [7, '/'],
            [
              2,
              [3, 'manager_id'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [6, 'manager_users'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [3, 'manager_user_id'],
                      [2, [7, '/'], [2, [6, 'portfolios'], [1, [2, [8, '.'], [3, 'format']]]]],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ],
);

/**
 * Generates rails route to
 * /platform/managers/:manager_id/manager_users(.:format)
 * @param {any} manager_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_manager_manager_users_path = __jsr.r({ manager_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'managers'],
        [
          2,
          [7, '/'],
          [2, [3, 'manager_id'], [2, [7, '/'], [2, [6, 'manager_users'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/managers/:manager_id/portfolios(.:format)
 * @param {any} manager_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_manager_portfolios_path = __jsr.r({ manager_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'managers'],
        [2, [7, '/'], [2, [3, 'manager_id'], [2, [7, '/'], [2, [6, 'portfolios'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/managers/:manager_id/upload_templates(.:format)
 * @param {any} manager_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_manager_upload_templates_path = __jsr.r({ manager_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'managers'],
        [
          2,
          [7, '/'],
          [2, [3, 'manager_id'], [2, [7, '/'], [2, [6, 'upload_templates'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/manager_uploads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_manager_uploads_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'platform'], [2, [7, '/'], [2, [6, 'manager_uploads'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /platform/managers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_managers_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'platform'], [2, [7, '/'], [2, [6, 'managers'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /platform/market_commentaries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_market_commentaries_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'platform'], [2, [7, '/'], [2, [6, 'market_commentaries'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /platform/market_commentaries/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_market_commentary_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [2, [7, '/'], [2, [6, 'market_commentaries'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /platform/nav_one_clients(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_nav_one_clients_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'platform'], [2, [7, '/'], [2, [6, 'nav_one_clients'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /platform/notes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_note_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [2, [7, '/'], [2, [6, 'notes'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /platform/notes/:note_id/attachments/:id(.:format)
 * @param {any} note_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_note_attachment_path = __jsr.r({ note_id: { r: true }, id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'notes'],
        [
          2,
          [7, '/'],
          [
            2,
            [3, 'note_id'],
            [2, [7, '/'], [2, [6, 'attachments'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/notes/:note_id/attachments(.:format)
 * @param {any} note_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_note_attachments_path = __jsr.r({ note_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'notes'],
        [2, [7, '/'], [2, [3, 'note_id'], [2, [7, '/'], [2, [6, 'attachments'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/notes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_notes_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'platform'], [2, [7, '/'], [2, [6, 'notes'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /platform/notifications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_notifications_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'platform'], [2, [7, '/'], [2, [6, 'notifications'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /platform/parent_managers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_parent_manager_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [2, [7, '/'], [2, [6, 'parent_managers'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /platform/parent_managers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_parent_managers_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'platform'], [2, [7, '/'], [2, [6, 'parent_managers'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /platform/portfolios/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_portfolio_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [2, [7, '/'], [2, [6, 'portfolios'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /platform/portfolios/:portfolio_id/asset_holdings(.:format)
 * @param {any} portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_portfolio_asset_holdings_path = __jsr.r({ portfolio_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'portfolios'],
        [
          2,
          [7, '/'],
          [2, [3, 'portfolio_id'], [2, [7, '/'], [2, [6, 'asset_holdings'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/portfolios/:portfolio_id/balances(.:format)
 * @param {any} portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_portfolio_balances_path = __jsr.r({ portfolio_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'portfolios'],
        [2, [7, '/'], [2, [3, 'portfolio_id'], [2, [7, '/'], [2, [6, 'balances'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/portfolio_benchmarks/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_portfolio_benchmark_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [2, [7, '/'], [2, [6, 'portfolio_benchmarks'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /platform/portfolio_benchmarks/:portfolio_benchmark_id/portfolio_benchmarks/composite_returns(.:format)
 * @param {any} portfolio_benchmark_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_portfolio_benchmark_portfolio_benchmarks_composite_returns_path = __jsr.r(
  { portfolio_benchmark_id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'platform'],
      [
        2,
        [7, '/'],
        [
          2,
          [6, 'portfolio_benchmarks'],
          [
            2,
            [7, '/'],
            [
              2,
              [3, 'portfolio_benchmark_id'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [6, 'portfolio_benchmarks'],
                  [2, [7, '/'], [2, [6, 'composite_returns'], [1, [2, [8, '.'], [3, 'format']]]]],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ],
);

/**
 * Generates rails route to
 * /platform/portfolio_benchmarks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_portfolio_benchmarks_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'platform'], [2, [7, '/'], [2, [6, 'portfolio_benchmarks'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /platform/portfolios/:portfolio_id/currency_holdings(.:format)
 * @param {any} portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_portfolio_currency_holdings_path = __jsr.r({ portfolio_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'portfolios'],
        [
          2,
          [7, '/'],
          [2, [3, 'portfolio_id'], [2, [7, '/'], [2, [6, 'currency_holdings'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/nav_one_clients/:client_no/portfolio_details/:portfolio_no(.:format)
 * @param {any} client_no
 * @param {any} portfolio_no
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_portfolio_detail_path = __jsr.r(
  { client_no: { r: true }, portfolio_no: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'platform'],
      [
        2,
        [7, '/'],
        [
          2,
          [6, 'nav_one_clients'],
          [
            2,
            [7, '/'],
            [
              2,
              [3, 'client_no'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [6, 'portfolio_details'],
                  [2, [7, '/'], [2, [3, 'portfolio_no'], [1, [2, [8, '.'], [3, 'format']]]]],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ],
);

/**
 * Generates rails route to
 * /platform/portfolios/:portfolio_id/download(.:format)
 * @param {any} portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_portfolio_download_path = __jsr.r({ portfolio_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'portfolios'],
        [2, [7, '/'], [2, [3, 'portfolio_id'], [2, [7, '/'], [2, [6, 'download'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/portfolios/:portfolio_id/holdings(.:format)
 * @param {any} portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_portfolio_holdings_path = __jsr.r({ portfolio_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'portfolios'],
        [2, [7, '/'], [2, [3, 'portfolio_id'], [2, [7, '/'], [2, [6, 'holdings'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/portfolios/:portfolio_id/notes/:id(.:format)
 * @param {any} portfolio_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_portfolio_note_path = __jsr.r({ portfolio_id: { r: true }, id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'portfolios'],
        [
          2,
          [7, '/'],
          [
            2,
            [3, 'portfolio_id'],
            [2, [7, '/'], [2, [6, 'notes'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/portfolios/:portfolio_id/notes(.:format)
 * @param {any} portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_portfolio_notes_path = __jsr.r({ portfolio_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'portfolios'],
        [2, [7, '/'], [2, [3, 'portfolio_id'], [2, [7, '/'], [2, [6, 'notes'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/portfolios/:portfolio_id/performance_numbers/:id(.:format)
 * @param {any} portfolio_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_portfolio_performance_number_path = __jsr.r(
  { portfolio_id: { r: true }, id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'platform'],
      [
        2,
        [7, '/'],
        [
          2,
          [6, 'portfolios'],
          [
            2,
            [7, '/'],
            [
              2,
              [3, 'portfolio_id'],
              [
                2,
                [7, '/'],
                [2, [6, 'performance_numbers'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]],
              ],
            ],
          ],
        ],
      ],
    ],
  ],
);

/**
 * Generates rails route to
 * /platform/portfolios/:portfolio_id/performance_numbers(.:format)
 * @param {any} portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_portfolio_performance_numbers_path = __jsr.r({ portfolio_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'portfolios'],
        [
          2,
          [7, '/'],
          [2, [3, 'portfolio_id'], [2, [7, '/'], [2, [6, 'performance_numbers'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/portfolios/:portfolio_id/portfolio_returns(.:format)
 * @param {any} portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_portfolio_portfolio_returns_path = __jsr.r({ portfolio_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'portfolios'],
        [
          2,
          [7, '/'],
          [2, [3, 'portfolio_id'], [2, [7, '/'], [2, [6, 'portfolio_returns'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/portfolios/:portfolio_id/return_series(.:format)
 * @param {any} portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_portfolio_return_series_index_path = __jsr.r({ portfolio_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'portfolios'],
        [
          2,
          [7, '/'],
          [2, [3, 'portfolio_id'], [2, [7, '/'], [2, [6, 'return_series'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/portfolios/:portfolio_id/reviews/:id(.:format)
 * @param {any} portfolio_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_portfolio_review_path = __jsr.r({ portfolio_id: { r: true }, id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'portfolios'],
        [
          2,
          [7, '/'],
          [
            2,
            [3, 'portfolio_id'],
            [2, [7, '/'], [2, [6, 'reviews'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/portfolios/:portfolio_id/reviews/:review_id/reports/:id(.:format)
 * @param {any} portfolio_id
 * @param {any} review_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_portfolio_review_report_path = __jsr.r(
  { portfolio_id: { r: true }, review_id: { r: true }, id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'platform'],
      [
        2,
        [7, '/'],
        [
          2,
          [6, 'portfolios'],
          [
            2,
            [7, '/'],
            [
              2,
              [3, 'portfolio_id'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [6, 'reviews'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [3, 'review_id'],
                      [
                        2,
                        [7, '/'],
                        [2, [6, 'reports'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]],
                      ],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ],
);

/**
 * Generates rails route to
 * /platform/portfolios/:portfolio_id/reviews/:review_id/reports/:report_id/preview(.:format)
 * @param {any} portfolio_id
 * @param {any} review_id
 * @param {any} report_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_portfolio_review_report_preview_path = __jsr.r(
  { portfolio_id: { r: true }, review_id: { r: true }, report_id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'platform'],
      [
        2,
        [7, '/'],
        [
          2,
          [6, 'portfolios'],
          [
            2,
            [7, '/'],
            [
              2,
              [3, 'portfolio_id'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [6, 'reviews'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [3, 'review_id'],
                      [
                        2,
                        [7, '/'],
                        [
                          2,
                          [6, 'reports'],
                          [
                            2,
                            [7, '/'],
                            [
                              2,
                              [3, 'report_id'],
                              [2, [7, '/'], [2, [6, 'preview'], [1, [2, [8, '.'], [3, 'format']]]]],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ],
);

/**
 * Generates rails route to
 * /platform/portfolios/:portfolio_id/reviews/:review_id/reports(.:format)
 * @param {any} portfolio_id
 * @param {any} review_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_portfolio_review_reports_path = __jsr.r(
  { portfolio_id: { r: true }, review_id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'platform'],
      [
        2,
        [7, '/'],
        [
          2,
          [6, 'portfolios'],
          [
            2,
            [7, '/'],
            [
              2,
              [3, 'portfolio_id'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [6, 'reviews'],
                  [
                    2,
                    [7, '/'],
                    [2, [3, 'review_id'], [2, [7, '/'], [2, [6, 'reports'], [1, [2, [8, '.'], [3, 'format']]]]]],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ],
);

/**
 * Generates rails route to
 * /platform/portfolios/:portfolio_id/reviews(.:format)
 * @param {any} portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_portfolio_reviews_path = __jsr.r({ portfolio_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'portfolios'],
        [2, [7, '/'], [2, [3, 'portfolio_id'], [2, [7, '/'], [2, [6, 'reviews'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/portfolios/:portfolio_id/statuses(.:format)
 * @param {any} portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_portfolio_statuses_path = __jsr.r({ portfolio_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'portfolios'],
        [2, [7, '/'], [2, [3, 'portfolio_id'], [2, [7, '/'], [2, [6, 'statuses'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/portfolios/:portfolio_id/valuations(.:format)
 * @param {any} portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_portfolio_valuations_path = __jsr.r({ portfolio_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'portfolios'],
        [
          2,
          [7, '/'],
          [2, [3, 'portfolio_id'], [2, [7, '/'], [2, [6, 'valuations'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/portfolios/:portfolio_id/viewed_portfolios(.:format)
 * @param {any} portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_portfolio_viewed_portfolios_path = __jsr.r({ portfolio_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'portfolios'],
        [
          2,
          [7, '/'],
          [2, [3, 'portfolio_id'], [2, [7, '/'], [2, [6, 'viewed_portfolios'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/portfolios(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_portfolios_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'platform'], [2, [7, '/'], [2, [6, 'portfolios'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /platform/react/:component(/:args)(.:format)
 * @param {any} component
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_react_path = __jsr.r({ component: { r: true }, args: {}, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'react'],
        [2, [7, '/'], [2, [3, 'component'], [2, [1, [2, [7, '/'], [3, 'args']]], [1, [2, [8, '.'], [3, 'format']]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/relationship_managers/dashboard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_relationship_managers_dashboard_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [2, [6, 'relationship_managers'], [2, [7, '/'], [2, [6, 'dashboard'], [1, [2, [8, '.'], [3, 'format']]]]]],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/relationship_managers/dashboard/reviews(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_relationship_managers_dashboard_reviews_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'relationship_managers'],
        [2, [7, '/'], [2, [6, 'dashboard'], [2, [7, '/'], [2, [6, 'reviews'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/report_designs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_report_designs_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'platform'], [2, [7, '/'], [2, [6, 'report_designs'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /platform/report_templates/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_report_template_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [2, [7, '/'], [2, [6, 'report_templates'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /platform/report_template_buckets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_report_template_buckets_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'platform'], [2, [7, '/'], [2, [6, 'report_template_buckets'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /platform/report_templates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_report_templates_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'platform'], [2, [7, '/'], [2, [6, 'report_templates'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /platform/reviews/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_review_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [2, [7, '/'], [2, [6, 'reviews'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /platform/reviews/:review_id/action_points/:id(.:format)
 * @param {any} review_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_review_action_point_path = __jsr.r({ review_id: { r: true }, id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'reviews'],
        [
          2,
          [7, '/'],
          [
            2,
            [3, 'review_id'],
            [2, [7, '/'], [2, [6, 'action_points'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/reviews/:review_id/action_points(.:format)
 * @param {any} review_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_review_action_points_path = __jsr.r({ review_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'reviews'],
        [
          2,
          [7, '/'],
          [2, [3, 'review_id'], [2, [7, '/'], [2, [6, 'action_points'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/reviews/:review_id/action_points_reviews/:id(.:format)
 * @param {any} review_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_review_action_points_review_path = __jsr.r(
  { review_id: { r: true }, id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'platform'],
      [
        2,
        [7, '/'],
        [
          2,
          [6, 'reviews'],
          [
            2,
            [7, '/'],
            [
              2,
              [3, 'review_id'],
              [
                2,
                [7, '/'],
                [2, [6, 'action_points_reviews'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]],
              ],
            ],
          ],
        ],
      ],
    ],
  ],
);

/**
 * Generates rails route to
 * /platform/reviews/:review_id/commentaries(.:format)
 * @param {any} review_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_review_commentaries_path = __jsr.r({ review_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'reviews'],
        [
          2,
          [7, '/'],
          [2, [3, 'review_id'], [2, [7, '/'], [2, [6, 'commentaries'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/reviews/:review_id/rating/:id(.:format)
 * @param {any} review_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_review_rating_path = __jsr.r({ review_id: { r: true }, id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'reviews'],
        [
          2,
          [7, '/'],
          [
            2,
            [3, 'review_id'],
            [2, [7, '/'], [2, [6, 'rating'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/reviews/:review_id/report_templates(.:format)
 * @param {any} review_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_review_report_templates_path = __jsr.r({ review_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'reviews'],
        [
          2,
          [7, '/'],
          [2, [3, 'review_id'], [2, [7, '/'], [2, [6, 'report_templates'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/reviews/:review_id/return_series(.:format)
 * @param {any} review_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_review_return_series_index_path = __jsr.r({ review_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'reviews'],
        [
          2,
          [7, '/'],
          [2, [3, 'review_id'], [2, [7, '/'], [2, [6, 'return_series'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/reviews/:review_id/review_reports(.:format)
 * @param {any} review_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_review_review_reports_path = __jsr.r({ review_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'reviews'],
        [
          2,
          [7, '/'],
          [2, [3, 'review_id'], [2, [7, '/'], [2, [6, 'review_reports'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/reviews/:review_id/statuses(.:format)
 * @param {any} review_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_review_statuses_path = __jsr.r({ review_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'reviews'],
        [2, [7, '/'], [2, [3, 'review_id'], [2, [7, '/'], [2, [6, 'statuses'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/reviews/:review_id/transitions(.:format)
 * @param {any} review_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_review_transitions_path = __jsr.r({ review_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'reviews'],
        [2, [7, '/'], [2, [3, 'review_id'], [2, [7, '/'], [2, [6, 'transitions'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/reviews(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_reviews_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'platform'], [2, [7, '/'], [2, [6, 'reviews'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /platform(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_root_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'platform'], [1, [2, [8, '.'], [3, 'format']]]],
]);

/**
 * Generates rails route to
 * /platform/searches(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_searches_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'platform'], [2, [7, '/'], [2, [6, 'searches'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /platform/securities(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_securities_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'platform'], [2, [7, '/'], [2, [6, 'securities'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /platform/securities/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_security_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [2, [7, '/'], [2, [6, 'securities'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /platform/security_classification_states/classification_uploads/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_security_classification_states_classification_upload_path = __jsr.r(
  { id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'platform'],
      [
        2,
        [7, '/'],
        [
          2,
          [6, 'security_classification_states'],
          [
            2,
            [7, '/'],
            [2, [6, 'classification_uploads'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]],
          ],
        ],
      ],
    ],
  ],
);

/**
 * Generates rails route to
 * /platform/security_classification_states/classification_uploads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_security_classification_states_classification_uploads_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'security_classification_states'],
        [2, [7, '/'], [2, [6, 'classification_uploads'], [1, [2, [8, '.'], [3, 'format']]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/securities/:security_id/download(.:format)
 * @param {any} security_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_security_download_path = __jsr.r({ security_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'securities'],
        [2, [7, '/'], [2, [3, 'security_id'], [2, [7, '/'], [2, [6, 'download'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/template_action_points(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_template_action_points_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'platform'], [2, [7, '/'], [2, [6, 'template_action_points'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /platform/testing(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_testing_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'platform'], [2, [7, '/'], [2, [6, 'testing'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /platform/trackers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_tracker_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [2, [7, '/'], [2, [6, 'trackers'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /platform/trackers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_trackers_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'platform'], [2, [7, '/'], [2, [6, 'trackers'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /platform/users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_user_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [2, [7, '/'], [2, [6, 'users'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /platform/users/:user_id/invite(.:format)
 * @param {any} user_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_user_invite_path = __jsr.r({ user_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'users'],
        [2, [7, '/'], [2, [3, 'user_id'], [2, [7, '/'], [2, [6, 'invite'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/users/:user_id/login_as(.:format)
 * @param {any} user_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_user_login_as_path = __jsr.r({ user_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'users'],
        [2, [7, '/'], [2, [3, 'user_id'], [2, [7, '/'], [2, [6, 'login_as'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_users_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'platform'], [2, [7, '/'], [2, [6, 'users'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /platform/xero_tenants/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_xero_tenant_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [2, [7, '/'], [2, [6, 'xero_tenants'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /platform/xero_tenants/:xero_tenant_id/branding_themes(.:format)
 * @param {any} xero_tenant_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_xero_tenant_branding_themes_path = __jsr.r({ xero_tenant_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'xero_tenants'],
        [
          2,
          [7, '/'],
          [2, [3, 'xero_tenant_id'], [2, [7, '/'], [2, [6, 'branding_themes'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/xero_tenants(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const platform_xero_tenants_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'platform'], [2, [7, '/'], [2, [6, 'xero_tenants'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /portal/action_points(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const portal_action_points_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'portal'], [2, [7, '/'], [2, [6, 'action_points'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /portal/clients/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const portal_client_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'portal'],
    [2, [7, '/'], [2, [6, 'clients'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /portal/clients/:client_id/aum_breakdown(.:format)
 * @param {any} client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const portal_client_aum_breakdown_path = __jsr.r({ client_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'portal'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'clients'],
        [
          2,
          [7, '/'],
          [2, [3, 'client_id'], [2, [7, '/'], [2, [6, 'aum_breakdown'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /portal/clients/:client_id/manager_breakdowns(.:format)
 * @param {any} client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const portal_client_manager_breakdowns_path = __jsr.r({ client_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'portal'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'clients'],
        [
          2,
          [7, '/'],
          [2, [3, 'client_id'], [2, [7, '/'], [2, [6, 'manager_breakdowns'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /portal/clients/:client_id/portfolios/:id(.:format)
 * @param {any} client_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const portal_client_portfolio_path = __jsr.r({ client_id: { r: true }, id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'portal'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'clients'],
        [
          2,
          [7, '/'],
          [
            2,
            [3, 'client_id'],
            [2, [7, '/'], [2, [6, 'portfolios'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /portal/clients/:client_id/portfolios/:portfolio_id/action_points/:id(.:format)
 * @param {any} client_id
 * @param {any} portfolio_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const portal_client_portfolio_action_point_path = __jsr.r(
  { client_id: { r: true }, portfolio_id: { r: true }, id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'portal'],
      [
        2,
        [7, '/'],
        [
          2,
          [6, 'clients'],
          [
            2,
            [7, '/'],
            [
              2,
              [3, 'client_id'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [6, 'portfolios'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [3, 'portfolio_id'],
                      [
                        2,
                        [7, '/'],
                        [2, [6, 'action_points'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]],
                      ],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ],
);

/**
 * Generates rails route to
 * /portal/clients/:client_id/portfolios/:portfolio_id/asset_allocations(.:format)
 * @param {any} client_id
 * @param {any} portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const portal_client_portfolio_asset_allocations_path = __jsr.r(
  { client_id: { r: true }, portfolio_id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'portal'],
      [
        2,
        [7, '/'],
        [
          2,
          [6, 'clients'],
          [
            2,
            [7, '/'],
            [
              2,
              [3, 'client_id'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [6, 'portfolios'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [3, 'portfolio_id'],
                      [2, [7, '/'], [2, [6, 'asset_allocations'], [1, [2, [8, '.'], [3, 'format']]]]],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ],
);

/**
 * Generates rails route to
 * /portal/clients/:client_id/portfolios/:portfolio_id/currency_allocations(.:format)
 * @param {any} client_id
 * @param {any} portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const portal_client_portfolio_currency_allocations_path = __jsr.r(
  { client_id: { r: true }, portfolio_id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'portal'],
      [
        2,
        [7, '/'],
        [
          2,
          [6, 'clients'],
          [
            2,
            [7, '/'],
            [
              2,
              [3, 'client_id'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [6, 'portfolios'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [3, 'portfolio_id'],
                      [2, [7, '/'], [2, [6, 'currency_allocations'], [1, [2, [8, '.'], [3, 'format']]]]],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ],
);

/**
 * Generates rails route to
 * /portal/clients/:client_id/portfolios/:portfolio_id/drawdown_series(.:format)
 * @param {any} client_id
 * @param {any} portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const portal_client_portfolio_drawdown_series_index_path = __jsr.r(
  { client_id: { r: true }, portfolio_id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'portal'],
      [
        2,
        [7, '/'],
        [
          2,
          [6, 'clients'],
          [
            2,
            [7, '/'],
            [
              2,
              [3, 'client_id'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [6, 'portfolios'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [3, 'portfolio_id'],
                      [2, [7, '/'], [2, [6, 'drawdown_series'], [1, [2, [8, '.'], [3, 'format']]]]],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ],
);

/**
 * Generates rails route to
 * /portal/clients/:client_id/portfolios/:portfolio_id/return_metrics(.:format)
 * @param {any} client_id
 * @param {any} portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const portal_client_portfolio_return_metrics_path = __jsr.r(
  { client_id: { r: true }, portfolio_id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'portal'],
      [
        2,
        [7, '/'],
        [
          2,
          [6, 'clients'],
          [
            2,
            [7, '/'],
            [
              2,
              [3, 'client_id'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [6, 'portfolios'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [3, 'portfolio_id'],
                      [2, [7, '/'], [2, [6, 'return_metrics'], [1, [2, [8, '.'], [3, 'format']]]]],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ],
);

/**
 * Generates rails route to
 * /portal/clients/:client_id/portfolios/:portfolio_id/return_series(.:format)
 * @param {any} client_id
 * @param {any} portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const portal_client_portfolio_return_series_index_path = __jsr.r(
  { client_id: { r: true }, portfolio_id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'portal'],
      [
        2,
        [7, '/'],
        [
          2,
          [6, 'clients'],
          [
            2,
            [7, '/'],
            [
              2,
              [3, 'client_id'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [6, 'portfolios'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [3, 'portfolio_id'],
                      [2, [7, '/'], [2, [6, 'return_series'], [1, [2, [8, '.'], [3, 'format']]]]],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ],
);

/**
 * Generates rails route to
 * /portal/clients/:client_id/portfolios/:portfolio_id/risk_metrics(.:format)
 * @param {any} client_id
 * @param {any} portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const portal_client_portfolio_risk_metrics_path = __jsr.r(
  { client_id: { r: true }, portfolio_id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'portal'],
      [
        2,
        [7, '/'],
        [
          2,
          [6, 'clients'],
          [
            2,
            [7, '/'],
            [
              2,
              [3, 'client_id'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [6, 'portfolios'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [3, 'portfolio_id'],
                      [2, [7, '/'], [2, [6, 'risk_metrics'], [1, [2, [8, '.'], [3, 'format']]]]],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ],
);

/**
 * Generates rails route to
 * /portal/clients/:client_id/portfolios/:portfolio_id/risk_return_series(.:format)
 * @param {any} client_id
 * @param {any} portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const portal_client_portfolio_risk_return_series_index_path = __jsr.r(
  { client_id: { r: true }, portfolio_id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'portal'],
      [
        2,
        [7, '/'],
        [
          2,
          [6, 'clients'],
          [
            2,
            [7, '/'],
            [
              2,
              [3, 'client_id'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [6, 'portfolios'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [3, 'portfolio_id'],
                      [2, [7, '/'], [2, [6, 'risk_return_series'], [1, [2, [8, '.'], [3, 'format']]]]],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ],
);

/**
 * Generates rails route to
 * /portal/clients/:client_id/portfolios/:portfolio_id/strategic_allocations(.:format)
 * @param {any} client_id
 * @param {any} portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const portal_client_portfolio_strategic_allocations_path = __jsr.r(
  { client_id: { r: true }, portfolio_id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'portal'],
      [
        2,
        [7, '/'],
        [
          2,
          [6, 'clients'],
          [
            2,
            [7, '/'],
            [
              2,
              [3, 'client_id'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [6, 'portfolios'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [3, 'portfolio_id'],
                      [2, [7, '/'], [2, [6, 'strategic_allocations'], [1, [2, [8, '.'], [3, 'format']]]]],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ],
);

/**
 * Generates rails route to
 * /portal/clients/:client_id/portfolios/:portfolio_id/sub_asset_allocations(.:format)
 * @param {any} client_id
 * @param {any} portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const portal_client_portfolio_sub_asset_allocations_path = __jsr.r(
  { client_id: { r: true }, portfolio_id: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'portal'],
      [
        2,
        [7, '/'],
        [
          2,
          [6, 'clients'],
          [
            2,
            [7, '/'],
            [
              2,
              [3, 'client_id'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [6, 'portfolios'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [3, 'portfolio_id'],
                      [2, [7, '/'], [2, [6, 'sub_asset_allocations'], [1, [2, [8, '.'], [3, 'format']]]]],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ],
);

/**
 * Generates rails route to
 * /portal/downloads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const portal_downloads_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'portal'], [2, [7, '/'], [2, [6, 'downloads'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /portal/market_resources(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const portal_market_resources_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'portal'], [2, [7, '/'], [2, [6, 'market_resources'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /portal(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const portal_root_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'portal'], [1, [2, [8, '.'], [3, 'format']]]],
]);

/**
 * Generates rails route to
 * /platform/reports/investment_risk_assessments/preview(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const preview_platform_reports_investment_risk_assessments_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'reports'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'investment_risk_assessments'],
            [2, [7, '/'], [2, [6, 'preview'], [1, [2, [8, '.'], [3, 'format']]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /platform/report_templates/:id/publish(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const publish_platform_report_template_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'report_templates'],
        [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'publish'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /rails/active_storage/representations/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_path = __jsr.r(
  { signed_blob_id: { r: true }, variation_key: { r: true }, filename: { r: true }, format: {} },
  [
    2,
    [7, '/'],
    [
      2,
      [6, 'rails'],
      [
        2,
        [7, '/'],
        [
          2,
          [6, 'active_storage'],
          [
            2,
            [7, '/'],
            [
              2,
              [6, 'representations'],
              [
                2,
                [7, '/'],
                [
                  2,
                  [3, 'signed_blob_id'],
                  [
                    2,
                    [7, '/'],
                    [
                      2,
                      [3, 'variation_key'],
                      [2, [7, '/'], [2, [5, [3, 'filename']], [1, [2, [8, '.'], [3, 'format']]]]],
                    ],
                  ],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ],
);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'rails'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'conductor'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'action_mailbox'],
            [2, [7, '/'], [2, [6, 'inbound_emails'], [2, [7, '/'], [2, [3, 'id'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format)
 * @param {any} inbound_email_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_reroute_path = __jsr.r({ inbound_email_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'rails'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'conductor'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'action_mailbox'],
            [
              2,
              [7, '/'],
              [2, [3, 'inbound_email_id'], [2, [7, '/'], [2, [6, 'reroute'], [1, [2, [8, '.'], [3, 'format']]]]]],
            ],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_emails_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'rails'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'conductor'],
        [
          2,
          [7, '/'],
          [2, [6, 'action_mailbox'], [2, [7, '/'], [2, [6, 'inbound_emails'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /rails/active_storage/direct_uploads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_direct_uploads_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'rails'],
    [
      2,
      [7, '/'],
      [2, [6, 'active_storage'], [2, [7, '/'], [2, [6, 'direct_uploads'], [1, [2, [8, '.'], [3, 'format']]]]]],
    ],
  ],
]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_key/*filename(.:format)
 * @param {any} encoded_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_disk_service_path = __jsr.r({ encoded_key: { r: true }, filename: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'rails'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'active_storage'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'disk'],
            [
              2,
              [7, '/'],
              [2, [3, 'encoded_key'], [2, [7, '/'], [2, [5, [3, 'filename']], [1, [2, [8, '.'], [3, 'format']]]]]],
            ],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /rails/info(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_info_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'rails'], [2, [7, '/'], [2, [6, 'info'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /rails/info/properties(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_info_properties_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'rails'],
    [2, [7, '/'], [2, [6, 'info'], [2, [7, '/'], [2, [6, 'properties'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /rails/info/routes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_info_routes_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'rails'],
    [2, [7, '/'], [2, [6, 'info'], [2, [7, '/'], [2, [6, 'routes'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /rails/mailers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mailers_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'rails'], [2, [7, '/'], [2, [6, 'mailers'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mailgun/inbound_emails/mime(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mailgun_inbound_emails_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'rails'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'action_mailbox'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'mailgun'],
            [
              2,
              [7, '/'],
              [2, [6, 'inbound_emails'], [2, [7, '/'], [2, [6, 'mime'], [1, [2, [8, '.'], [3, 'format']]]]]],
            ],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_emails_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'rails'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'action_mailbox'],
        [
          2,
          [7, '/'],
          [2, [6, 'mandrill'], [2, [7, '/'], [2, [6, 'inbound_emails'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_health_check_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'rails'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'action_mailbox'],
        [
          2,
          [7, '/'],
          [2, [6, 'mandrill'], [2, [7, '/'], [2, [6, 'inbound_emails'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /rails/action_mailbox/postmark/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_postmark_inbound_emails_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'rails'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'action_mailbox'],
        [
          2,
          [7, '/'],
          [2, [6, 'postmark'], [2, [7, '/'], [2, [6, 'inbound_emails'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /rails/action_mailbox/relay/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_relay_inbound_emails_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'rails'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'action_mailbox'],
        [2, [7, '/'], [2, [6, 'relay'], [2, [7, '/'], [2, [6, 'inbound_emails'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /rails/action_mailbox/sendgrid/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_sendgrid_inbound_emails_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'rails'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'action_mailbox'],
        [
          2,
          [7, '/'],
          [2, [6, 'sendgrid'], [2, [7, '/'], [2, [6, 'inbound_emails'], [1, [2, [8, '.'], [3, 'format']]]]]],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_path = __jsr.r({ signed_id: { r: true }, filename: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'rails'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'active_storage'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'blobs'],
            [
              2,
              [7, '/'],
              [2, [3, 'signed_id'], [2, [7, '/'], [2, [5, [3, 'filename']], [1, [2, [8, '.'], [3, 'format']]]]]],
            ],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /manager_portal/manager/regenerate_key(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const regenerate_key_manager_portal_manager_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'manager_portal'],
    [2, [7, '/'], [2, [6, 'manager'], [2, [7, '/'], [2, [6, 'regenerate_key'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /platform/reviews/:id/regenerate(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const regenerate_platform_review_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'reviews'],
        [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'regenerate'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /administrators/invitation/remove(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const remove_administrator_invitation_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'administrators'],
    [2, [7, '/'], [2, [6, 'invitation'], [2, [7, '/'], [2, [6, 'remove'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /platform/administrators/companies/:company_id/invitation/remove(.:format)
 * @param {any} company_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const remove_platform_administrator_company_invitation_path = __jsr.r({ company_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'administrators'],
        [
          2,
          [7, '/'],
          [
            2,
            [6, 'companies'],
            [
              2,
              [7, '/'],
              [
                2,
                [3, 'company_id'],
                [
                  2,
                  [7, '/'],
                  [2, [6, 'invitation'], [2, [7, '/'], [2, [6, 'remove'], [1, [2, [8, '.'], [3, 'format']]]]]],
                ],
              ],
            ],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /portal/invitation/remove(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const remove_user_invitation_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'portal'],
    [2, [7, '/'], [2, [6, 'invitation'], [2, [7, '/'], [2, [6, 'remove'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /users/token_verifications/new/resend(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const resend_new_users_token_verification_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'users'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'token_verifications'],
        [2, [7, '/'], [2, [6, 'new'], [2, [7, '/'], [2, [6, 'resend'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const root_path = __jsr.r({}, [7, '/']);

/**
 * Generates rails route to
 * /static_assets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const static_assets_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'static_assets'], [1, [2, [8, '.'], [3, 'format']]]],
]);

/**
 * Generates rails route to
 * /platform/report_templates/:id/status_update(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const status_update_platform_report_template_path = __jsr.r({ id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'report_templates'],
        [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'status_update'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /portal/clients/:client_id/portfolios/:id/structure(.:format)
 * @param {any} client_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const structure_portal_client_portfolio_path = __jsr.r({ client_id: { r: true }, id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'portal'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'clients'],
        [
          2,
          [7, '/'],
          [
            2,
            [3, 'client_id'],
            [
              2,
              [7, '/'],
              [
                2,
                [6, 'portfolios'],
                [2, [7, '/'], [2, [3, 'id'], [2, [7, '/'], [2, [6, 'structure'], [1, [2, [8, '.'], [3, 'format']]]]]]],
              ],
            ],
          ],
        ],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /styles(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const styles_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'styles'], [1, [2, [8, '.'], [3, 'format']]]],
]);

/**
 * Generates rails route to
 * /platform/reviews/unassign(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unassign_platform_reviews_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [2, [7, '/'], [2, [6, 'reviews'], [2, [7, '/'], [2, [6, 'unassign'], [1, [2, [8, '.'], [3, 'format']]]]]]],
  ],
]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_token(.:format)
 * @param {any} encoded_token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_rails_disk_service_path = __jsr.r({ encoded_token: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'rails'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'active_storage'],
        [2, [7, '/'], [2, [6, 'disk'], [2, [7, '/'], [2, [3, 'encoded_token'], [1, [2, [8, '.'], [3, 'format']]]]]]],
      ],
    ],
  ],
]);

/**
 * Generates rails route to
 * /portal/invitation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_invitation_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'portal'], [2, [7, '/'], [2, [6, 'invitation'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /portal/password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_password_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'portal'], [2, [7, '/'], [2, [6, 'password'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /portal/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_session_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'portal'], [2, [7, '/'], [2, [6, 'sign_in'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /portal/unlock(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_unlock_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'portal'], [2, [7, '/'], [2, [6, 'unlock'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /users/terms_and_conditions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_terms_and_conditions_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'users'], [2, [7, '/'], [2, [6, 'terms_and_conditions'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /users/token_verifications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_token_verifications_path = __jsr.r({ format: {} }, [
  2,
  [7, '/'],
  [2, [6, 'users'], [2, [7, '/'], [2, [6, 'token_verifications'], [1, [2, [8, '.'], [3, 'format']]]]]],
]);

/**
 * Generates rails route to
 * /platform/reviews/:review_id/statuses/validate(.:format)
 * @param {any} review_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const validate_platform_review_statuses_path = __jsr.r({ review_id: { r: true }, format: {} }, [
  2,
  [7, '/'],
  [
    2,
    [6, 'platform'],
    [
      2,
      [7, '/'],
      [
        2,
        [6, 'reviews'],
        [
          2,
          [7, '/'],
          [
            2,
            [3, 'review_id'],
            [2, [7, '/'], [2, [6, 'statuses'], [2, [7, '/'], [2, [6, 'validate'], [1, [2, [8, '.'], [3, 'format']]]]]]],
          ],
        ],
      ],
    ],
  ],
]);
