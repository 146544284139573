import * as React from 'react';
import * as $ from 'jquery';
import turbolinks from 'turbolinks';

import { DirectUpload } from 'activestorage';
import {
  platformBulkPortfolioImportPath,
  platformBulkPortfolioImportsPath,
  platformDashboardPath,
} from 'javascript/application/ts_routes';

import Presenter from './bulkPortfolioImport/Presenter';
import CsrfToken from 'components/shared/CsrfToken';
import { ajaxErrorString, createAlert } from 'components/shared/Utils';

export default function bulkPortfolioImport() {
  const [responseData, setResponseData] = React.useState({
    noData: true,
    portfolios: [],
    bulk_portfolio_import_id: null,
  });
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  function handleSubmit() {
    $.ajax({
      beforeSend: () => setIsSubmitting(true),
      complete: () => setIsProcessing(false),
      data: { authenticity_token: CsrfToken() },
      dataType: 'json',
      error: (jqXhr) => {
        createAlert('error', ajaxErrorString(jqXhr), false);
      },
      success: () => {
        turbolinks.visit(platformDashboardPath());
      },
      type: 'PATCH',
      url: platformBulkPortfolioImportPath(responseData.bulk_portfolio_import_id),
    });
  }

  function handleFileUpload(files) {
    if (files.length < 1) return;

    setIsProcessing(true);
    const upload = new DirectUpload(files[0], '/rails/active_storage/direct_uploads');

    upload.create((error, blob) => {
      if (error) {
        // Dump out
      } else {
        const signedId = blob.signed_id;
        createBulkImport(signedId);
      }
    });
  }

  function createBulkImport(signedId) {
    $.ajax({
      complete: () => setIsProcessing(false),
      data: { authenticity_token: CsrfToken(), bulk_portfolio_import: { import: signedId } },
      dataType: 'json',
      error: (jqXhr) => {
        createAlert('error', ajaxErrorString(jqXhr), false);
      },
      success: (response) => {
        setResponseData(response);
      },
      type: 'POST',
      url: platformBulkPortfolioImportsPath(),
    });
  }

  return <Presenter {...{ handleFileUpload, handleSubmit, isProcessing, isSubmitting, responseData }} />;
}
