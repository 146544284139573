import * as React from 'react';

import lodashStartcase from 'lodash.startcase';

export default function columns() {
  return [
    {
      Header: 'Name',
      accessor: 'name',
      columnName: 'Name',
      id: 'name',
    },
    {
      Cell: (row) => {
        return <div>{lodashStartcase(row.original.connectionDirection)}</div>;
      },
      Header: 'Connection Direction',
      accessor: 'connectionDirection',
      columnName: 'Connection Direction',
      id: 'connection_direction',
    },
    {
      Cell: () => {
        return <div />;
      },
      Header: '',
      sortable: false,
    },
  ];
}
