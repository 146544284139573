import { Attr, Model } from 'spraypaint';
import { ApplicationRecord } from './ApplicationRecord';

@Model()
export class Audit extends ApplicationRecord {
  public static jsonapiType = 'audits';

  @Attr() public action: string;
  @Attr() public associatedId: string;
  @Attr() public associatedType: string;
  @Attr() public auditableId: string;
  @Attr() public auditableType: string;
  @Attr() public auditedChanges: any;
  @Attr() public comment: any;
  @Attr() public createdAt: any;
  @Attr() public log: string;
  @Attr() public note: string;
  @Attr() public userId: string;
  @Attr() public username: string;
  @Attr() public userType: string;
}
