import * as React from 'react';

import { useSpraypaintQueryAll } from 'components/shared/customHooks/useSpraypaintQuery';

import { useDefaultTheme } from 'components/shared/colours';
import Loading from 'components/shared/Loading';
import useRerender from 'components/shared/customHooks/useRerender';

import { Frow } from 'components/frow';

import { Flag, SecurityClassification } from 'javascript/models';

import FlagSelector from './FlagSelector';

interface IFlagsSelectorProps {
  flags: Flag[];
  namePrefix: string;
  criterionOptions?: { [key: string]: SecurityClassification[] };
}

const FlagsSelector = (props: IFlagsSelectorProps) => {
  const { criterionOptions: localCriterionOptions, flags, namePrefix } = props;

  const flagTypeScope = React.useMemo(
    () => Flag.per(0).stats({ staticData: 'flag_type_classification_class_map' }),
    [],
  );

  const {
    meta: { stats },
  } = useSpraypaintQueryAll(flagTypeScope);
  const flagTypeMap = (stats && stats.static_data.flag_type_classification_class_map) || {};

  const [criterionOptions, setCriterionOptions] = React.useState(localCriterionOptions);

  const getCriterionOptions = async () => {
    if (criterionOptions) return;

    setCriterionOptions(await SecurityClassification.allByType({ uiSelectable: true }));
  };

  React.useEffect(() => {
    getCriterionOptions();
  }, []);

  const rerender = useRerender();

  const handleNewFlag = React.useCallback(() => {
    flags.push(
      new Flag({
        aggregate: false,
        criterion: null,
        flagType: null,
        id: '',
        maximum: 100,
        minimum: 0,
      }),
    );

    rerender();
  }, [flags]);

  const colourGenerator = useDefaultTheme('all');

  if (!criterionOptions || !flagTypeMap) return <Loading />;

  return (
    <>
      <Frow justifyContent="end" className="mar-b-2">
        <button className="button button--secondary button--compact" type="button" onClick={handleNewFlag}>
          Add New Flag
        </button>
      </Frow>
      {flags.map((flag, index: number) => (
        <FlagSelector
          key={index}
          index={index}
          flag={flag}
          criterionOptions={criterionOptions}
          flagTypeMap={flagTypeMap}
          backgroundColorGenerator={colourGenerator}
          namePrefix={namePrefix}
        />
      ))}
    </>
  );
};

export default FlagsSelector;
