import * as React from 'react';

export default function questionAnswers() {
  return (
    <table className="pdf-hidden-table">
      <thead>
        <tr>
          <th className="pdf-body-copy" style={{ width: '5%', padding: '0 0 10px', borderBottom: 'none' }}>
            No.
          </th>
          <th className="pdf-body-copy" style={{ width: '70%', padding: '0 0 10px', borderBottom: 'none' }}>
            Check
          </th>
          <th className="pdf-body-copy" style={{ width: '25%', padding: '0 0 10px', borderBottom: 'none' }}>
            Result
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="col-mc-1-6 pdf-body-copy" style={{ width: '5%', paddingBottom: '5px' }}>
            1.
          </td>
          <td className="col-mc-3-6 pdf-body-copy" style={{ width: '70%', paddingBottom: '5px' }}>
            Mandate Held On File:
          </td>
          <td className="col-mc-2-6 pdf-body-copy" style={{ width: '25%', paddingBottom: '5px' }}>
            Yes
          </td>
        </tr>
        <tr>
          <td className="col-mc-1-6 pdf-body-copy" style={{ width: '5%', paddingBottom: '5px' }}>
            2.
          </td>
          <td className="col-mc-3-6 pdf-body-copy" style={{ width: '70%', paddingBottom: '5px' }}>
            Risk Relative To Risk Capacity:
          </td>
          <td className="col-mc-2-6 pdf-body-copy" style={{ width: '25%', paddingBottom: '5px' }}>
            Red
          </td>
        </tr>
        <tr>
          <td className="col-mc-1-6 pdf-body-copy" style={{ width: '5%', paddingBottom: '5px' }}>
            3.
          </td>
          <td className="col-mc-3-6 pdf-body-copy" style={{ width: '70%', paddingBottom: '5px' }}>
            Stock Specific Risk In Line:
          </td>
          <td className="col-mc-2-6 pdf-body-copy" style={{ width: '25%', paddingBottom: '5px' }}>
            Yes
          </td>
        </tr>
        <tr>
          <td className="col-mc-1-6 pdf-body-copy" style={{ width: '5%', paddingBottom: '5px' }}>
            4.
          </td>
          <td className="col-mc-3-6 pdf-body-copy" style={{ width: '70%', paddingBottom: '5px' }}>
            Foreign Currency Risk In Line:
          </td>
          <td className="col-mc-2-6 pdf-body-copy" style={{ width: '25%', paddingBottom: '5px' }}>
            Yes
          </td>
        </tr>
        <tr>
          <td className="col-mc-1-6 pdf-body-copy" style={{ width: '5%', paddingBottom: '5px' }}>
            5.
          </td>
          <td className="col-mc-3-6 pdf-body-copy" style={{ width: '70%', paddingBottom: '5px' }}>
            Asset Allocation Appropriate:
          </td>
          <td className="col-mc-2-6 pdf-body-copy" style={{ width: '25%', paddingBottom: '5px' }}>
            Yes
          </td>
        </tr>
      </tbody>
    </table>
  );
}
