import * as React from 'react';

import * as colorFunctions from 'color';

import MultiLayerPieChart from 'components/shared/charting/recharts/MultiLayerPieChart';

export default function multiLayerPieChart({ chartData, theme, height, width }) {
  const {
    primary_color,
    secondary_color,
    tertiary_color,
    cash_color,
    fixed_income_color,
    equity_color,
    hedge_fund_color,
    other_color,
    gbp_color,
    usd_color,
    eur_color,
    jpy_color,
    hkd_color,
    chf_color,
    sgd_color,
    zar_color,
    cad_color,
    aud_color,
    nzd_color,
    sek_color,
    dkk_color,
    tbc_color,
    oth_color,
    secondary_color_one,
    secondary_color_two,
    secondary_color_three,
    secondary_color_four,
    secondary_color_five,
  } = theme;

  const colors = [
    primary_color,
    secondary_color,
    tertiary_color,
    cash_color,
    fixed_income_color,
    equity_color,
    hedge_fund_color,
    other_color,
    gbp_color,
    usd_color,
    eur_color,
    jpy_color,
    hkd_color,
    chf_color,
    sgd_color,
    zar_color,
    cad_color,
    aud_color,
    nzd_color,
    sek_color,
    dkk_color,
    tbc_color,
    oth_color,
    secondary_color_one,
    secondary_color_two,
    secondary_color_three,
    secondary_color_four,
    secondary_color_five,
  ];

  const formattedDataArray = chartData.map((dataObject) => {
    return dataObject.map((entry) => {
      entry.value = parseFloat(entry.value);
      return entry;
    });
  });

  formattedDataArray[0].forEach((topLevelItem, index) => {
    topLevelItem.color = colors[index];
  });

  return (
    <MultiLayerPieChart
      height={height}
      dataArray={formattedDataArray}
      isResponsive={false}
      labelSize={12}
      width={width}
    />
  );
}
