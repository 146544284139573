import * as React from 'react';

import { ReportTemplatesReportTemplateSection } from 'javascript/models';
import Context from '../../../Context';

export default function stackedAreaChart({ attributes, handleChange, handleManualChange, isRepeating }) {
  const context = React.useContext(Context);
  const { reportTemplate } = context.state;

  const isConsolidated = reportTemplate.consolidated;

  const [target, setTarget] = React.useState(attributes.areaChartTarget);
  const [timePeriod, setTimePeriod] = React.useState(attributes.areaChartTimePeriod);
  const [selectedSize, setSelectedSize] = React.useState(attributes.size);
  const [type, setType] = React.useState(attributes.areaChartType);

  function updateAttributes() {
    const value = { target, type, time_period: timePeriod };
    handleManualChange(value, 'areaChartDetails');
  }

  React.useEffect(() => {
    updateAttributes();
  }, [target, type, timePeriod]);

  function handleTargetChange() {
    setTarget(this);
  }
  function handleTypeChange() {
    setType(this);
  }
  function handlePeriodChange() {
    setTimePeriod(this);
  }
  function handleSizeChange() {
    setSelectedSize(this);
    handleManualChange(this, 'size');
  }

  let scopedTargetOptions = [{ label: 'Portfolio', value: 'portfolio' }];
  if (isConsolidated) {
    scopedTargetOptions = [{ label: 'Consolidated Portfolio', value: 'consolidated_portfolio' }];
  }

  if (isRepeating) {
    scopedTargetOptions = [{ label: 'Constituent Portfolio', value: 'constituent_portfolio' }];
  }

  const targetOptions = scopedTargetOptions.map((option) => {
    return (
      <div className="col-tn-1-3" key={option.value}>
        <div className="pretty pretty--large pretty--with-label p-round">
          <input
            type="radio"
            value={option.value}
            id="area_target"
            onChange={handleTargetChange.bind(option.value)}
            checked={target === option.value}
          />
          <div className="state p-blue-circle">
            <label>{option.label}</label>
          </div>
        </div>
      </div>
    );
  });

  const typeOptions = ReportTemplatesReportTemplateSection.pieChartTypeOptions.map((option) => {
    return (
      <div className="col-tn-1-3" key={option.value}>
        <div className="pretty pretty--large pretty--with-label p-round">
          <input
            type="radio"
            value={option.value}
            id="pie_type"
            onChange={handleTypeChange.bind(option.value)}
            checked={type === option.value}
          />
          <div className="state p-blue-circle">
            <label>{option.label}</label>
          </div>
        </div>
      </div>
    );
  });

  const timePeriodOptions = ReportTemplatesReportTemplateSection.timePeriodOptions.map((timePeriodOption) => {
    return (
      <div className="col-tn-1-3" key={timePeriodOption.value}>
        <div className="pretty pretty--large pretty--with-label p-round">
          <input
            type="radio"
            value={timePeriodOption.value}
            id="timePeriod"
            onChange={handlePeriodChange.bind(timePeriodOption.value)}
            checked={timePeriod === timePeriodOption.value}
          />
          <div className="state p-blue-circle">
            <label>{timePeriodOption.label}</label>
          </div>
        </div>
      </div>
    );
  });

  const sizeOptions = ReportTemplatesReportTemplateSection.sizeOptions.map((size) => {
    return (
      <div className="col-tn-1-3" key={size.value}>
        <div className="pretty pretty--large pretty--with-label p-round">
          <input
            type="radio"
            value={size.value}
            id="size"
            onChange={handleSizeChange.bind(size.value)}
            checked={selectedSize === size.value}
          />
          <div className="state p-blue-circle">
            <label>{size.label}</label>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="frow frow--gutters">
      <div className="col-mc-1-1">
        <label className="form__label" htmlFor="">
          Target
        </label>
        <div className="frow">{targetOptions}</div>
      </div>
      <div className="col-mc-1-1">
        <label className="form__label" htmlFor="">
          Type
        </label>
        <div className="frow">{typeOptions}</div>
      </div>
      <div className="col-mc-1-1">
        <label className="form__label" htmlFor="">
          Time Period
        </label>
        <div className="frow ">{timePeriodOptions}</div>
      </div>
      <div className="col-mc-1-1">
        <label className="form__label" htmlFor="">
          Size
        </label>
        <div className="frow ">{sizeOptions}</div>
      </div>
    </div>
  );
}
