import * as React from 'react';

import * as classNames from 'classnames';
import { getQuarter, getYear } from 'date-fns';

import { SubtitleOne } from 'components/shared/Typography';
import { Portfolio } from 'javascript/models/portal/Portfolio';
import Loading from 'components/shared/Loading';

export default function rating({ portfolioObject }) {
  const { id } = portfolioObject;

  const [isLoading, setIsLoading] = React.useState(true);
  const [portfolio, setPortfolio] = React.useState<Portfolio>();

  React.useEffect(() => {
    getPortfolio().then(() => setIsLoading(false));
  }, []);

  async function getPortfolio() {
    const { data } = await Portfolio.includes(['most_recent_review']).find(id);

    setPortfolio(data);
  }

  function renderRating() {
    const { mostRecentReview } = portfolio;
    if (!mostRecentReview) {
      return <p className="text-white">No reviews have been completed for this portfolio</p>;
    }

    const { endDate, rating } = mostRecentReview;
    const roundedRating = Math.round(rating);

    const ratingBoxDecimalClasses = classNames(
      'rating-box',
      'rating-box-decimal',
      { 'rating-box-decimal--one': roundedRating === 1 },
      { 'rating-box-decimal--two': roundedRating === 2 },
      { 'rating-box-decimal--three': roundedRating === 3 },
      { 'rating-box-decimal--four': roundedRating === 4 },
      { 'rating-box-decimal--five': roundedRating === 5 },
    );

    return (
      <div className="mar-v-2">
        <p className="helper-text text-white">
          Rating for Q{getQuarter(endDate)} {getYear(endDate)}
        </p>
        <div className={ratingBoxDecimalClasses} style={{ fontSize: '9.4rem' }}>
          {rating ? rating : 'N/A'}
        </div>
      </div>
    );
  }

  function ratingBoxClass(ratingNum) {
    let classString = 'rating-box rating-box--large rating-box--dark-theme';
    if (portfolio) {
      const { mostRecentReview } = portfolio;
      const { rating } = mostRecentReview;
      const roundedRating = Math.round(rating);
      let humanizedNumber = 'zero';
      if (ratingNum === 1) humanizedNumber = 'one';
      if (ratingNum === 2) humanizedNumber = 'two';
      if (ratingNum === 3) humanizedNumber = 'three';
      if (ratingNum === 4) humanizedNumber = 'four';
      if (ratingNum === 5) humanizedNumber = 'five';
      if (ratingNum === roundedRating) {
        classString += ` rating-box--${humanizedNumber}`;
      }
    }
    return classString;
  }

  return (
    <div className="col-md-1-2 col-lg-1-3">
      <div className="card card--rounded card--dark card--full-height">
        <div className="platform-content platform-content--padding-all h-100">
          <SubtitleOne text="Latest Rating" additionalClasses="text-white mar-b-1" />
          <div className="frow frow--direction-column" style={{ height: '90%' }}>
            {isLoading ? <Loading /> : renderRating()}
            <div style={{ marginTop: 'auto' }}>
              <div className="frow frow--gutters">
                <div>
                  <div className={ratingBoxClass(1)}>1</div>
                </div>
                <div>
                  <div className={ratingBoxClass(2)}>2</div>
                </div>
                <div>
                  <div className={ratingBoxClass(3)}>3</div>
                </div>
                <div>
                  <div className={ratingBoxClass(4)}>4</div>
                </div>
                <div>
                  <div className={ratingBoxClass(5)}>5</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
