import * as React from 'react';

import { Menu, MenuItem, Popover } from '@blueprintjs/core';
import Context from '../Context';

export default function colorSelect({ color = '#777881', index, top = 23 }) {
  const context = React.useContext(Context);
  const { handleColorChange } = context.actions;

  const styles = {
    position: 'absolute' as any,
    right: '20px',
    top: `${top}px`,
  };

  return (
    <React.Fragment>
      <div style={styles}>
        <Popover usePortal={false}>
          <i className="fas fa-brush fa-clickable" style={{ color }} />
          <Menu>
            <MenuItem text="White" onClick={() => handleColorChange(index, 'white')} />
            <MenuItem text="Grey" onClick={() => handleColorChange(index, 'grey')} />
          </Menu>
        </Popover>
      </div>
    </React.Fragment>
  );
}
