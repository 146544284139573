import React from 'react';
import Select from 'react-select';

import { Administrator, Client, ClientUserPermission, ClientUserRole, User } from 'javascript/models';
import { Role } from 'javascript/models/Role';

import GraphitiTableWrapper from 'components/shared/GraphitiTableWrapper';
import { TPermissionGroups } from 'components/platform/clients/Show';
import CurrentUserContext from 'components/shared/context/CurrentUserContext';
import Loading from 'components/shared/Loading';

import columns from './columns';

import ClientRoleModal, { IRoleModalProps } from './ClientRoleModal';

export interface IClientRoles {
  clientId: number;
}

const ClientRoles = ({ clientId }: IClientRoles) => {
  const [currentUser, setCurrentUser] = React.useState<User | Administrator>(null);
  const [roleable, setRoleable] = React.useState<Client>(null);
  const [assignedRoles, setAssignedRoles] = React.useState<Role<any>[]>([]);
  const [scope, setScope] = React.useState(null);
  const [availablePerms, setAvailablePerms] = React.useState<TPermissionGroups>();

  const context = React.useContext(CurrentUserContext);

  React.useEffect(() => {
    if (context != null) {
      getClient();
      getAavailablePerms();
    }
    setCurrentUser(context as User);
  }, [context]);

  const tableColumns = columns({ roleable });

  const getAavailablePerms = async () => {
    const { meta } = await ClientUserPermission.stats({ staticData: 'permissions' }).all();
    const permissionsObject = meta.stats.static_data.permissions;
    setAvailablePerms(permissionsObject.available_permissions);
  };

  const getClient = async () => {
    const { data } = await Client.select([])
      .includes(['users', { roles: 'roleable' }])
      .find(clientId);
    const userlist = data.users.map((cur) => cur.id, []);
    setRoleable(data);
    setScope(User.where({ id: userlist }).includes({ roles: ['permissions', 'roleable'] }));
  };

  const aroundModalClose = (handleClose) => {
    handleClose();
  };

  const clientRolesModalProps: Partial<IRoleModalProps> = {
    modalWithTriggerProps: {
      aroundOnClose: aroundModalClose,
      modifiers: ['dark'],
    },
  };

  const noRolesDefined = () => {
    return <div className="col-mc-1-1 text-white text-small">No Roles defined</div>;
  };

  const renderRoles = () => {
    if (assignedRoles.length === 0) {
      return noRolesDefined();
    }
  };

  const newRole = (props) => {
    const { handleEditChange } = props;
    return <input type="text" id="rolename" name="rolename" onChange={handleEditChange} />;
  };

  const editRole = (props) => {
    const { selectedValue, options, handleSelChange } = props;
    return (
      <Select
        className="react-select-dark"
        classNamePrefix="react-select-dark"
        value={selectedValue}
        name="roles[role_id]"
        options={options}
        onChange={handleSelChange}
      />
    );
  };

  const addView: Partial<IRoleModalProps> = {
    addOrEdit: 'add',
  };
  const editView: Partial<IRoleModalProps> = {
    addOrEdit: 'edit',
  };

  const addModal = () => {
    let element = [
      { modal: addView, role: newRole },
      { modal: editView, role: editRole },
    ].map((type) => {
      return (
        <div className="frow frow--items-center">
          <div className="col-md-2-5 col-lg-1-5 text-right platform-content--padding-right">
            {availablePerms && (
              <ClientRoleModal
                addOrEdit={type.modal.addOrEdit}
                roleable={roleable}
                availablePerms={availablePerms}
                {...clientRolesModalProps}
                formContent={type.role}
              />
            )}
          </div>
        </div>
      );
    });

    return <div className="frow frow--items-center frow--justify-between">{element}</div>;
  };

  return (
    <div className="platform-content platform-content--spacing-horizontal platform-content--padding-top">
      {currentUser && scope && availablePerms ? (
        <GraphitiTableWrapper
          buttonText="button text"
          className=""
          searchPlaceholder="Search by name"
          scope={scope}
          columns={tableColumns}
          buttonComponent={addModal}
          hasColumnSelector={false}
          hasHeader
          isSearchable
          identifier="portal:roles:index"
        />
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default ClientRoles;
