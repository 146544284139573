import * as React from 'react';
import * as ReactTooltip from 'react-tooltip';

import { DirectUpload, Blob as ASBlob } from 'activestorage';
import lodashTruncate from 'lodash.truncate';

import Dropzone from 'components/shared/forms/Dropzone';
import { createAlert } from 'components/shared/Utils';

import ThemeContext from 'components/shared/context/ThemeContext';

import Frow from '../../../../frow/Frow';
import { THasManyAttachedRcvd } from 'javascript/models/shared/types';

export interface IFileUploadProps {
  accessor: string;
  model: any;
}

interface BlobWithId extends ASBlob {
  id: string;
}
/*
 * Possible duplication, these files should be merged where possible:
 * @see components/shared/Dropzone.tsx
 * @see components/shared/StandaloneDropzone.tsx
 * @see components/shared/FormFilesDropzone.tsx
 * @see Issue (#1328)
 *
 * With a bit of work to rename this and adjust functionality
 * to allow deleting this could become a generic implementation
 */

const fileUpload = (props: IFileUploadProps) => {
  const { model, accessor } = props;

  const [files, setFiles] = React.useState(model[accessor] || []);

  const theme = React.useContext(ThemeContext);

  if (!files) return null;

  const handleUploadedFiles = (uploadedFiles: File[]) => {
    if (uploadedFiles.length < 1) return;

    uploadedFiles.forEach((file) => {
      const upload = new DirectUpload(file, '/rails/active_storage/direct_uploads');
      upload.create((error, blob) => {
        if (error) {
          createAlert('error', 'There was an error uploading this file', 2000);
        } else {
          model[accessor] = model[accessor] ? [...model[accessor], blob] : [blob];
          const path = `/rails/active_storage/blobs/${blob.signed_id}/${blob.filename}`;

          const blobWithId = blob as BlobWithId;
          setFiles((previous: THasManyAttachedRcvd) => [
            ...previous,
            {
              path,
              name: blobWithId.filename,
              signed_id: blobWithId.signed_id,
              id: blobWithId.id,
            },
          ]);
        }
      });
    });
  };

  const formattedFiles = files.map((file) => (
    <div key={file.signed_id}>
      <a
        className="tag tag--blue tag--inline tag--small"
        href={file.path}
        target="_blank"
        data-tip={file.name}
        data-for="document-file-name"
        rel="noreferrer"
      >
        {lodashTruncate(file.name)}
      </a>
      <ReactTooltip effect="solid" id="document-file-name" />
    </div>
  ));

  return (
    <>
      <div className="mar-b-2">
        <Frow gutterSize={1}>{formattedFiles}</Frow>
      </div>
      <Dropzone
        shouldDiscardFiles
        onFileUpload={handleUploadedFiles}
        resetFiles
        shouldShowFiles={false}
        theme={theme.classNameModifier}
        multiple
      />
    </>
  );
};

export default fileUpload;
