import * as React from 'react';

import GraphitiTableWrapper from 'components/shared/GraphitiTableWrapper';

import Create from './Create';
import columns from './table/columns';

import { Extract } from 'javascript/models';
import ISelectOption from 'components/interfaces/ISelectOption';

interface IProps {
  clientOptions: ISelectOption[];
  companyId: string;
  currencyOptions: ISelectOption[];
  extractTypes: ISelectOption[];
  riskOptions: ISelectOption[];
  serviceTypeOptions: ISelectOption[];
  startQuarter: string;
}

export default function graphitiTable(props: IProps) {
  const { clientOptions, companyId, currencyOptions, extractTypes, riskOptions, serviceTypeOptions, startQuarter } =
    props;
  const [updateTable, setUpdateTable] = React.useState(false);

  function handleNewExtract() {
    setUpdateTable(true);
  }

  React.useEffect(() => {
    if (updateTable) {
      setUpdateTable(false);
    }
  }, [updateTable]);

  const scope = Extract.selectExtra(['client_name']).includes(['administrator']).order({ created_at: 'desc' });

  return (
    <React.Fragment>
      <div className="platform-panel platform-panel--no-background">
        <div className="frow frow--justify-between frow--items-center">
          <div className="frow frow--items-center">
            <h2 className="platform-panel__title mar-r-3">Extracts</h2>
          </div>
          <Create
            {...{
              clientOptions,
              companyId,
              currencyOptions,
              extractTypes,
              handleNewExtract,
              riskOptions,
              serviceTypeOptions,
              startQuarter,
            }}
          />
        </div>
      </div>
      <GraphitiTableWrapper
        scope={scope}
        columns={columns()}
        shouldUpdateTable={updateTable}
        identifier="extracts:index"
      />
    </React.Fragment>
  );
}
