import * as React from 'react';

import Loading from './Loading';

// https://medium.com/dailyjs/react-composing-higher-order-components-hocs-3a5288e78f55
const branch = (test, Pass, Fail) => (props) => {
  return test ? <Pass {...props} /> : Fail ? <Fail {...props} /> : null;
};

// Assumes there is a prop of isLoading
export const isLoading = (loadingProps = {}) => (WrappedComponent) => {
  const IsLoading = (props) => {
    return branch(props.isLoading, hasProps(loadingProps)(Loading), WrappedComponent)(props);
  };

  return IsLoading;
};

// https://medium.com/dailyjs/react-composing-higher-order-components-hocs-3a5288e78f55#01bd
export const hasProps = (injectedProps) => (WrappedComponent) => {
  const HasProps = (props) => <WrappedComponent {...injectedProps} {...props} />;

  return HasProps;
};
