import * as React from 'react';
import * as $ from 'jquery';

import CustomModal from 'components/shared/CustomModal';
import Presenter from './commentaries/Presenter';

import IReviewCommentaries from 'components/interfaces/IReviewCommentaries';
import ICommentaries from 'components/interfaces/ICommentaries';

interface IProps {
  commentaries: ICommentaries;
  reviewCommentaries: IReviewCommentaries;
  reviewCommentaryType: string;
  updatePath: string;
  userId: string;
}

export default function commentaries(props: IProps) {
  const { commentaries, reviewCommentaries, reviewCommentaryType, updatePath, userId } = props;

  function handleSubmit(event) {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);
    $.ajax({
      contentType: false,
      data: formData,
      dataType: 'json',
      processData: false,
      success: () => window.close(),
      type: 'patch',
      url: updatePath,
    });
  }

  function handleClose() {
    window.close();
  }

  const modifiers = reviewCommentaryType === 'longform' ? ['large'] : [];

  return (
    <CustomModal isOpen title="Edit Commentary" modifiers={modifiers}>
      <Presenter
        {...{ commentaries, handleClose, handleSubmit, reviewCommentaries, reviewCommentaryType, updatePath, userId }}
      />
    </CustomModal>
  );
}
