import * as React from 'react';
import * as $ from 'jquery';

import Button from 'components/shared/forms/Button';
import CustomModal from 'components/shared/CustomModal';
import FormTag from 'components/shared/forms/FormTag';
import csrfToken from 'components/shared/CsrfToken';
import ReactSelect from 'components/shared/forms/ReactSelect';

const routes = require('../../../javascript/application/routes.js');

interface IProps {
  administrators: { display: string; value: number }[];
  companyId: number;
  isOpen: boolean;
  selection: string[];
  status?: string;
  handleClose(): void;
  handleReviewsAssigned(): void;
}

export default function assignModal(props: IProps) {
  const { administrators, companyId, isOpen, selection, status } = props;
  const [selectedAdmin, setSelectedAdmin] = React.useState(null);

  function handleSelectChange(value) {
    setSelectedAdmin(value);
  }

  function handleFormSubmission(event) {
    event.preventDefault();
    let url = routes.unassign_platform_reviews_path();
    if (selectedAdmin.value !== 'is null') {
      url = routes.platform_company_administrator_reviews_path(companyId, selectedAdmin.value);
    }

    $.ajax({
      cache: false,
      data: { status, review_ids: selection, authenticity_token: csrfToken() },
      dataType: 'json',
      success: () => {
        setSelectedAdmin(null);
        props.handleClose();
        props.handleReviewsAssigned();
      },
      type: 'PATCH',
      url: url,
    });
  }

  const administratorOptions = administrators.map((admin) => {
    return { value: admin.value.toString(), label: admin.display };
  });

  return (
    <CustomModal
      handleClose={props.handleClose}
      isOpen={isOpen}
      title="Assign Owner"
      modifiers={['small', 'visible-overflow']}
    >
      <div className="modal__content">
        <FormTag className="form--light" method="patch" onSubmit={handleFormSubmission} url="placeholder">
          <div className="frow frow--gutters-2x">
            <div className="col-mc-1-1">
              <div className="frow frow--gutters frow--items-center frow--nowrap">
                <label htmlFor="administrator_id" className="form__label form__label--inline">
                  Owner
                </label>
                <ReactSelect
                  theme="light"
                  name="administrator_id"
                  id="administrator_id"
                  options={administratorOptions}
                  handleChange={handleSelectChange}
                  value={selectedAdmin}
                />
              </div>
            </div>
            <div className="col-mc-1-1">
              <Button
                buttonClasses="button--compact button--full-width button--light-themed"
                text="Update"
                isDisabled={!selectedAdmin}
              />
            </div>
          </div>
        </FormTag>
      </div>
    </CustomModal>
  );
}
