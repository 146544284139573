import * as React from 'react';

const reactRailsUJS = require('react_ujs');

import OutsideClickHandler from 'react-outside-click-handler';

import ClientTypeSelect from './reviewFilters/ClientTypeSelect';
import Presenter from './reviewFilters/Presenter';
import QuarterSelect from './reviewFilters/QuarterSelect';
import UserSelect from './reviewFilters/UserSelect';
import useLocalStorage from 'components/shared/customHooks/useLocalStorage';

interface IProps {
  currentQuarter: number;
  currentYear: number;
  selectableAdmins: { display: string; value: number }[];
  selectableClientTypes: { display: string; icon: string; value: number }[];
  selectableQuarters: { display: string; value: string }[];
}

export default function reviewFilters(props: IProps) {
  const { currentQuarter, currentYear, selectableAdmins, selectableClientTypes, selectableQuarters } = props;

  const { storedValue: selectedClientType, setItem: setSelectedClientType } = useLocalStorage(
    'dashboard:user:client_type',
    '*',
  );
  const { storedValue: selectedUser, setItem: setSelectedUser } = useLocalStorage('dashboard:user:user', '*');
  const { storedValue: selectedQuarter, setItem: setSelectedQuarter } = useLocalStorage('dashboard:user:quarter', {
    quarter: props.currentQuarter,
    year: props.currentYear,
  });

  const [isQuarterSelectOpen, setIsQuarterSelectOpen] = React.useState(false);
  const [isUserSelectOpen, setIsUserSelectOpen] = React.useState(false);

  const overviewReady = React.useCallback(() => {
    dispatchEvent();
  }, []);

  React.useEffect(() => {
    document.addEventListener('dropdownOverview:ready', overviewReady);
    document.addEventListener('turbolinks:before-render', () => {
      reactRailsUJS.unmountComponents('.review-filters');
    });
    return () => {
      document.removeEventListener('dropdownOverview:ready', overviewReady);
    };
  }, []);

  function handleOutsideClick() {
    setIsQuarterSelectOpen(false);
    setIsUserSelectOpen(false);
  }

  function dispatchEvent() {
    const customEvent = new CustomEvent('dropdownFilters:change', {
      detail: {
        ...selectedQuarter,
        admin: selectedUser,
        client_type: selectedClientType,
      },
    });
    document.dispatchEvent(customEvent);
  }

  React.useEffect(() => {
    dispatchEvent();
  }, [selectedClientType, selectedQuarter, selectedUser]);

  function handleQuarterSelectOpen() {
    setIsQuarterSelectOpen(!isQuarterSelectOpen);
  }

  function handleQuarterSelect(quarter: number, year: number) {
    setIsQuarterSelectOpen(false);
    setSelectedQuarter({ quarter, year });
  }

  function quarterSelect() {
    return (
      <QuarterSelect
        {...{ currentQuarter, currentYear, isQuarterSelectOpen, selectedQuarter, selectableQuarters }}
        handleQuarterSelect={handleQuarterSelect}
        handleQuarterSelectOpen={handleQuarterSelectOpen}
      />
    );
  }

  function handleUserSelectOpen() {
    setIsUserSelectOpen(!isUserSelectOpen);
  }

  function handleUserSelect(value: number) {
    setIsUserSelectOpen(false);
    setSelectedUser(value);
  }

  function userSelect() {
    return (
      <UserSelect
        {...{ isUserSelectOpen, selectedUser }}
        handleUserSelect={handleUserSelect}
        handleUserSelectOpen={handleUserSelectOpen}
        selectableAdmins={selectableAdmins}
      />
    );
  }

  function handleClientTypeSelect(value: number | string) {
    setSelectedClientType(value);
  }

  function clientTypeSelect() {
    return (
      <ClientTypeSelect
        handleClientTypeSelect={handleClientTypeSelect}
        selectedClientType={selectedClientType}
        selectableClientTypes={selectableClientTypes}
      />
    );
  }

  return (
    <OutsideClickHandler onOutsideClick={handleOutsideClick}>
      <Presenter clientTypeSelect={clientTypeSelect()} quarterSelect={quarterSelect()} userSelect={userSelect()} />
    </OutsideClickHandler>
  );
}
