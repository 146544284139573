import * as React from 'react';

import ReactSelect, { IReactSelectProps } from 'components/shared/forms/ReactSelect';

export interface ISelectProps extends Pick<IReactSelectProps, 'id' | 'name' | 'options'> {
  defaultValue?: string;
  label?: string;
  readOnly?: boolean;
  theme?: IReactSelectProps['theme'];
  handleChange: (optionValue: Parameters<IReactSelectProps['handleChange']>[0]['value']) => void;
}

export default function select(props: ISelectProps) {
  const { defaultValue, id, label, name, options, theme } = props;

  const handleChange: IReactSelectProps['handleChange'] = (option) => {
    if (props.handleChange) {
      props.handleChange(option.value);
    }
  };

  let defaultSelectOption = null;
  if (defaultValue) {
    defaultSelectOption = options.find((option) => option.value === defaultValue);
  }

  const displayLabel = (
    <label htmlFor={id} className="form__label">
      {label}
    </label>
  );

  return (
    <div style={{ width: '100%' }}>
      {label && displayLabel}
      <ReactSelect
        defaultValue={defaultSelectOption}
        id={id}
        name={name}
        options={options}
        handleChange={handleChange}
        theme={theme}
        isDisabled={props.readOnly}
      />
    </div>
  );
}

select.defaultProps = {
  readOnly: false,
  theme: 'light',
};
