import * as React from 'react';

import Manager from './portfolioInformation/Manager';
import Mandate from './portfolioInformation/Mandate';
import Consolidated from './portfolioInformation/Consolidated';

export default function portfolioInformation({ templateSection }) {
  const sectionType = templateSection && templateSection.sectionType;

  switch (sectionType) {
    case 'manager':
      return <Manager />;
    case 'mandate':
      return <Mandate />;
    case 'consolidated':
      return <Consolidated />;
    default:
      return <Mandate />;
  }
}
