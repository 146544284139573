import * as React from 'react';

import * as numeral from 'numeral';

export const CustomLabel = (props) => {
  const { height, labelSize, percentage, value, x, y } = props;
  const textAnchor = value < 0 ? 'right' : 'left';
  const xShift = value < 0 ? labelSize * 1 : -(labelSize * 4);
  return (
    <text
      x={x + xShift}
      y={y + 5 + height / 2}
      fill="#b5b6bd"
      fontSize={labelSize}
      fontWeight="bold"
      textAnchor={textAnchor}
    >
      {numeral(value).format('0.00')}
      {percentage && '%'}
    </text>
  );
};
