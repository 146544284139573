import * as React from 'react';
declare var require: any;
const classNames = require('classnames');

import IAttribute from './interfaces/IAttribute';

interface IProps {
  attribute: IAttribute;
  updateModel(event: React.SyntheticEvent): void;
}

interface IState {
  isEditable: boolean;
}

class Input extends React.Component<IProps, IState> {
  private static inputType(type) {
    return type;
  }

  private inputRef: React.RefObject<HTMLInputElement>;

  constructor(props: IProps) {
    super(props);
    this.state = {
      isEditable: false,
    };
    this.handleBlur = this.handleBlur.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.inputRef = React.createRef();
  }

  public render() {
    const { attribute } = this.props;
    const { isEditable } = this.state;

    return (
      <div className="col-mc-1-1">
        <label htmlFor={attribute.id} className="form__label">
          {attribute.label}
        </label>
        <span className={this.inputClasses()} onClick={this.handleClick}>
          <input
            ref={this.inputRef}
            disabled={!isEditable || !this.props.attribute.isUpdateable}
            type={Input.inputType(attribute.type) || 'text'}
            defaultValue={attribute.defaultValue}
            name={attribute.name}
            onChange={this.handleUpdate}
            onBlur={this.handleBlur}
          />
        </span>
      </div>
    );
  }

  private handleBlur() {
    this.setState({ isEditable: false });
  }

  private handleClick() {
    this.setState({ isEditable: true }, () => this.inputRef.current.focus());
  }

  private handleUpdate(event: React.SyntheticEvent) {
    event.persist();
    this.props.updateModel(event);
  }

  private inputClasses() {
    return classNames('form__input', {
      'form__input--edit': !this.state.isEditable && this.props.attribute.isUpdateable,
    });
  }
}

export default Input;
