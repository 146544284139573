import { pipe, prop, project, uniq } from 'ramda';

/*
 * Iterate over an array of objects taking a property named 'series' then taking properties from the resulting object
 *.  with the names provided by argument extractProps
 * @return [ { [key in extractProps]: value from series } ]
 */
const extractSeries = (data, extractProps = ['label', 'type']) =>
  uniq(data.flatMap(pipe(prop('series'), project(extractProps))));

export default extractSeries;
