import * as React from 'react';

import { Column } from 'react-table';

import useLocalStorage from '../../customHooks/useLocalStorage';

import Checkbox, { IProps as ICheckboxProps } from '../components/Checkbox';
import ColumnFilter, { IColumnFilterProps, IFilterParams, filterSelect } from '../components/ColumnFilter';
import ColumnSelector from '../components/ColumnSelector';
import HeaderCheckBox, { IProps as IHeaderCheckBoxProps } from '../components/HeaderCheckBox';

export interface IRevisedColumn<Data> extends Column<Data> {
  columnName?: string;
  filterOptions?: IFilterParams;
  eval?: string;
}

export interface IRevisedColumnsOptions<Data> {
  columns: IRevisedColumn<Data>[];
  csvExport: boolean;
  data: Data[];
  handleApplyFilters: IColumnFilterProps<Data>['handleApplyFilters'];
  /* eslint-disable @typescript-eslint/indent */
  handleUpdateSelection: ICheckboxProps['handleUpdateSelection'] & IHeaderCheckBoxProps<Data>['handleUpdateSelection'];
  /* eslint-enable @typescript-eslint/indent */
  hasColumnSelector: boolean;
  identifier: string;
  selectable: boolean;
}

const applyFilterColumnHeaders = (columns, handleApplyFilters) => {
  const [filterableColumns, _] = filterSelect(columns, 'filterOptions');
  filterableColumns.forEach((column) => {
    column.Header = <ColumnFilter {...{ column, handleApplyFilters }} />;
  });
};

const hideUnselectedColumns = (columns, selectedColumns) =>
  columns.forEach((column) => {
    column.show = selectedColumns.includes(column.columnName) || column.columnName === undefined;
  });

const addCheckboxColumn = (columns, data, handleUpdateSelection) =>
  columns.unshift({
    Cell: (row) => <Checkbox key={row.original.id} handleUpdateSelection={handleUpdateSelection} row={row} />,
    Header: <HeaderCheckBox handleUpdateSelection={handleUpdateSelection} data={data} />,
    accessor: 'id',
    className: '-checkbox',
    headerClassName: '-checkbox',
    maxWidth: 42,
    sortable: false,
  });

const addColumnSelector = (columns, selectedColumns, csvExport, handleColumnSelect) => {
  const lastColumn = columns[columns.length - 1];

  const allColumnHeaders = columns.map((column) => column.columnName);

  lastColumn.maxWidth = 60;
  lastColumn.Header = (
    <ColumnSelector
      csvExport={csvExport}
      columnHeaders={allColumnHeaders}
      handleColumnSelect={handleColumnSelect}
      selectedColumns={selectedColumns}
    />
  );
};

const performColumnProcessing = (options, selectedColumns, handleColumnSelect) => {
  const {
    columns,
    csvExport,
    data,
    handleApplyFilters,
    handleUpdateSelection,
    hasColumnSelector,
    selectable,
  } = options;

  const localColumns = [...columns];

  applyFilterColumnHeaders(localColumns, handleApplyFilters);

  hideUnselectedColumns(localColumns, selectedColumns);

  if (selectable) addCheckboxColumn(localColumns, data, handleUpdateSelection);

  const shownColumnCount = localColumns.filter((column) => column.show).length;
  const lastColumn = localColumns[localColumns.length - 1];

  // It's unknown why this value is being set
  lastColumn.maxWidth = 80;

  // The casting below is because auto isn't assignable to maxWidth but this was the code that existed before
  //   the type information was added.
  if (shownColumnCount === 1 || !hasColumnSelector) lastColumn.maxWidth = ('auto' as unknown) as number;

  if (hasColumnSelector) addColumnSelector(localColumns, selectedColumns, csvExport, handleColumnSelect);

  return localColumns;
};

const processColumns = <Data extends unknown>(options: IRevisedColumnsOptions<Data>) => {
  const { columns, data, identifier, selectable } = options;

  const allColumnHeaders = columns.map((column) => column.columnName);

  const { setCurrentIdentifier, storedValue: selectedColumns, setItem: setSelectedColumns } = useLocalStorage<
    string[],
    string[]
  >(identifier, allColumnHeaders);

  const handleColumnSelect = React.useCallback((header: string) => {
    setSelectedColumns((localSelectedColumns: string[]) => localSelectedColumns.toggle(header));
  }, []);

  React.useEffect(() => setCurrentIdentifier(identifier), [identifier]);

  // Because any interaction with data is guarded by selectable there's no need for this to update when data changes
  //   if selectable isn't set
  const memoAttributes = { ...options, selectedColumns, data: selectable && data };
  // If useMemo triggers we need to force the columns array to be new too.
  return React.useMemo(
    () => performColumnProcessing(options, selectedColumns, handleColumnSelect),
    Object.values(memoAttributes),
  );
};

export default processColumns;
