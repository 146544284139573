import * as React from 'react';

import { Alert, Intent } from '@blueprintjs/core';

import lodashTruncate from 'lodash.truncate';

import Form from './Form';

import CustomModal from 'components/shared/CustomModal';
import CustomAlert from 'components/shared/Alert';
import { graphitiErrorString } from 'components/shared/Utils';
import useGraphitiForm from 'components/shared/customHooks/useGraphitiForm';

export default function display({ confirmDelete, handleSuccessfulFormSubmission, templateActionPoint }) {
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = React.useState(false);

  function handleConfirmOpen() {
    handleClose();
    setIsConfirmOpen(true);
  }
  function handleConfirmClose() {
    setIsConfirmOpen(false);
  }

  const { resource, handleChange, handleSubmit } = useGraphitiForm(templateActionPoint, handleFormSubmission);

  function handleClose() {
    setIsOpen(false);
    setErrorMessage(null);
  }

  function handleOpen() {
    setIsOpen(true);
  }

  function handleConfirmDelete() {
    confirmDelete(templateActionPoint);
  }

  async function handleFormSubmission(event) {
    const success = await resource.save();
    if (success) {
      handleClose();
      handleSuccessfulFormSubmission();
    } else {
      setErrorMessage(graphitiErrorString(resource));
    }
  }

  return (
    <div className="col-sm-1-3 col-lg-1-5">
      <div className="card card--clickable card--fixed-height" onClick={handleOpen}>
        <div className="card__inner" style={{ height: '200px' }}>
          <div className="frow frow--nowrap frow--direction-column frow--full-height">
            <div>
              <p className="status-indicator status-indicator--red mar-v-0 text-white small-uppercase" />
            </div>
            <div className="" style={{ marginTop: 'auto' }}>
              <p className="card__title">{lodashTruncate(templateActionPoint.title)}</p>
              <p className="card__subtitle">Click to edit</p>
            </div>
          </div>
        </div>
      </div>
      <CustomModal isOpen={isOpen} title="Edit Commentary" handleClose={handleClose} modifiers={['large']}>
        <div className="modal__content">
          <CustomAlert message={errorMessage} modifier="dark" title="Missing Fields" type="error" />
          <form className="form form--light" onSubmit={handleSubmit}>
            <Form {...{ resource, handleChange }} />
          </form>
          <div className="frow frow--gutters">
            <div className="col-md-1-2">
              <button className="button button--full-width button--danger" onClick={handleConfirmOpen}>
                Delete
              </button>
            </div>
            <div className="col-md-1-2">
              <button className="button button--full-width button--outlined-secondary" onClick={handleClose}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </CustomModal>
      <Alert
        isOpen={isConfirmOpen}
        onClose={handleConfirmClose}
        canEscapeKeyCancel
        canOutsideClickCancel
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        intent={Intent.DANGER}
        onCancel={handleConfirmClose}
        onConfirm={handleConfirmDelete}
        portalContainer={document.body}
      >
        <p className="mar-t-0">Are you sure you want to delete this action point?</p>
      </Alert>
    </div>
  );
}
