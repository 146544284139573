import * as React from 'react';

import Destroy from './Destroy';
import Edit from './Edit';

import { format, parse } from 'date-fns';

export default function columns(handleValuationUpdate) {
  return [
    {
      Header: 'Name',
      accessor: 'fileName',
      columnName: 'Name',
      id: 'fileName',
      sortable: false,
    },
    {
      Cell: (row) => {
        return <div>{format(parse(row.original.valueDate), 'DD MMM YYYY')}</div>;
      },
      Header: 'Value Date',
      accessor: 'valueDate',
      columnName: 'Value Date',
      id: 'value_date',
    },
    {
      Cell: (row) => {
        return <div>{format(parse(row.original.uploadedAt), 'DD MMM YYYY [at] HH:mm')}</div>;
      },
      Header: 'Uploaded At',
      accessor: 'uploadedAt',
      columnName: 'Uploaded At',
      id: 'uploadedAt',
      sortable: false,
    },
    {
      Cell: (row) => {
        return <div>{row.original.uploadedBy}</div>;
      },
      Header: 'Uploaded By',
      accessor: 'uploadedBy',
      columnName: 'Uploaded By',
      id: 'uploadedBy',
      sortable: false,
    },
    {
      Cell: (row) => {
        return (
          <div className="frow">
            <Edit valuation={row.original} {...{ handleValuationUpdate }} />
            <Destroy valuation={row.original} {...{ handleValuationUpdate }} />
          </div>
        );
      },
      Header: 'Actions',
      accessor: 'actions',
      columnName: 'Actions',
      id: 'actions',
      sortable: false,
    },
    {
      Cell: (row) => {
        return (
          <a href={row.original.showPath} style={{ display: 'block' }} target="_blank">
            <div className="frow">
              <div className="rt-tr-clickable-icon mar-r-1">
                <i className="icon-arrow-right-top icon-0-8x" />
              </div>
            </div>
          </a>
        );
      },
      Header: '',
      sortable: false,
    },
  ];
}
