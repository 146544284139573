import * as React from 'react';

import { platformPortfolioPath } from 'javascript/application/ts_routes';
import useLocalStorage from 'components/shared/customHooks/useLocalStorage';

const classNames = require('classnames');

export default function portfolioSelect({ handleDeleteTab, viewablePortfolios, portfolio }) {
  const navElement = React.useRef(null);
  const { storedValue: startPosition, setItem: setStartPosition } = useLocalStorage(
    'portfolios:portfolio_select',
    0,
    true,
  );

  const [width, setWidth] = React.useState(window.innerWidth);

  // This should reset the start position back to one when we have a brand new portfolio
  React.useEffect(() => {
    if (viewablePortfolios[0].id == portfolio.id) {
      setStartPosition(0);
    }
  }, []);

  React.useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    // We won't be able to get the ref until the DOM is rendered so we basically keep trying until we can
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [width]);

  function handleStartPositionChange(startNumber: number) {
    if (startNumber < 0) {
      return;
    }

    if (startNumber + (size() - 1) >= viewablePortfolios.length) {
      return;
    }

    setStartPosition(startNumber);
  }

  function size() {
    if (!width) {
      return 0;
    }

    let windowSize = 8;
    if (width >= 1550) {
      windowSize = 8;
    } else if (width >= 1366) {
      windowSize = 6;
    } else if (width >= 1009) {
      windowSize = 4;
    } else if (width >= 676) {
      windowSize = 2;
    } else {
      windowSize = 1;
    }
    return windowSize;
  }

  function handleDelete(event, id: number) {
    event.stopPropagation();
    event.preventDefault();
    if (viewablePortfolios.length > 1) {
      if (startPosition !== 0) {
        setStartPosition(startPosition - 1);
      }
      handleDeleteTab(id);
    }
  }

  function renderPortfolioOptions() {
    return viewablePortfolios.slice(startPosition, startPosition + size()).map((vPortfolio) => {
      const active = portfolio.attributes.id === vPortfolio.id;
      const tabClass = classNames('tabbed-nav__tab', 'tabbed-nav__tab--right-bordered', {
        'tabbed-nav__tab--active': active,
      });
      return (
        <a href={platformPortfolioPath(vPortfolio.id)} className={tabClass} key={vPortfolio.id}>
          {!active && <span className="tabbed-nav__delete-tab" onClick={(e) => handleDelete(e, vPortfolio.id)} />}
          {vPortfolio.name}
        </a>
      );
    });
  }

  const previousClass = classNames(
    'tabbed-nav__tab',
    'tabbed-nav__tab--pagination',
    'tabbed-nav__tab--pagination-previous',
    { 'tabbed-nav__tab--pagination-disabled': startPosition === 0 },
    { hidden: size() >= viewablePortfolios.length },
  );

  const nextClass = classNames(
    'tabbed-nav__tab',
    'tabbed-nav__tab--pagination',
    'tabbed-nav__tab--pagination-next',
    { 'tabbed-nav__tab--pagination-disabled': startPosition + size() >= viewablePortfolios.length },
    { hidden: size() >= viewablePortfolios.length },
  );

  return (
    <div className="tabbed-nav tabbed-nav--no-border" ref={navElement}>
      <a className={previousClass} onClick={() => handleStartPositionChange(startPosition - 1)}>
        <i className="icon-chevron-left icon-1x" />
      </a>
      {renderPortfolioOptions()}
      <a className={nextClass} onClick={() => handleStartPositionChange(startPosition + 1)}>
        <i className="icon-chevron-right icon-1x" />
      </a>
    </div>
  );
}
