import * as React from 'react';

import { format, parse } from 'date-fns';

import { Review } from 'javascript/models';

interface IProps {
  review: Review;
}

export default function reviewBox(props: IProps) {
  const { review } = props;

  const { dataReceivedOn, reference, managerReference, client } = review;

  let status = '';
  let statusColor = '';
  if (review.status !== 'awaiting_data') {
    status = 'Data Received';
    statusColor = 'mid-green';
  } else {
    if (review.uploadErrors) {
      status = 'Error';
      statusColor = 'pale-red';
    } else {
      status = 'Awaiting Data';
      statusColor = 'blue';
    }
  }

  const dataReceivedDate = (
    <p className="mar-v-0" style={{ paddingLeft: '30px' }}>
      {format(parse(dataReceivedOn), 'DD MMM YYYY')}
    </p>
  );

  return (
    <div className="col-sm-1-3 col-lg-1-5">
      <div className="card card--clickable card--fixed-height">
        <div className="card__inner">
          <div className="frow frow--nowrap frow--direction-column frow--full-height">
            <div>
              <p className={`status-indicator status-indicator--${statusColor} mar-v-0 text-white small-uppercase`}>
                {status}
              </p>
              {dataReceivedOn && dataReceivedDate}
            </div>
            <div className="mar-t-7">
              <p className="card__title">{managerReference}</p>
              <p className="card__subtitle">{reference}</p>
            </div>
          </div>
        </div>
        <div className="card__footer">
          <p className="mar-v-0 text-white text-small text-uppercase">{client}</p>
        </div>
      </div>
    </div>
  );
}
