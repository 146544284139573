import React from 'react';
import { User } from 'javascript/models';

const ReactivateUserButton = ({ userId }) => {
  const handleReactivateUser = async () => {
    const user = (await User.find(userId)).data;
    user.discardedAt = null;
    await user.save({ displayQueryResult: true });
    location.reload();
  };
  return (
    <button onClick={handleReactivateUser} className="button button--secondary button--compact">
      Reactivate
    </button>
  );
};

export default ReactivateUserButton;
