import * as React from 'react';

import Button from 'components/shared/forms/Button';
import CustomModal from 'components/shared/CustomModal';
import useVisibility from 'components/shared/customHooks/useVisibility';
import QuarterPicker from 'components/shared/forms/QuarterPicker';
import { format, parse } from 'date-fns';

import { Review } from 'javascript/models';
import Context from '../../Context';
import { graphitiErrorString } from 'components/shared/Utils';
import Alert from 'components/shared/Alert';

const newReview = ({ getReviews }) => {
  const context = React.useContext(Context);
  const { id } = context.props.portfolio;
  const { isOpen, handleOpen, handleClose } = useVisibility(false);

  const [errorMessage, setErrorMessage] = React.useState<string>();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [quarter, setQuarter] = React.useState();
  const [fee, setFee] = React.useState();

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const newReview = new Review({
      endDate: quarter,
      fee,
      reviewableId: id,
      reviewableType: 'Portfolio',
    });
    const success = await newReview.save();
    setIsSubmitting(false);

    if (success) {
      setQuarter(null);
      setFee(null);
      handleClose();
      getReviews();
    } else {
      setErrorMessage(graphitiErrorString(newReview));
    }
  };

  const handleChange = (selectedQuarter) => {
    setQuarter(selectedQuarter);
  };

  const handleFeeInputChange = (event) => {
    const suppliedFee = event.target.value;
    if (suppliedFee && suppliedFee > 0) {
      setFee(suppliedFee);
    }
  };

  const startDate = format(parse(new Date(new Date().setFullYear(new Date().getFullYear() - 4))), 'YYYY-MM-DD');

  return (
    <div>
      <div className="button button--secondary button--no-min-width" onClick={handleOpen}>
        Add Review <i className="icon-plus icon-fw mar-l-1 icon-push-down-1" />
      </div>
      <CustomModal {...{ isOpen, handleClose }} title="Create review" modifiers={['visible-overflow']}>
        <div className="modal__content">
          <div className="form form--light">
            <Alert message={errorMessage} modifier="dark" title="Missing Fields" type="error" />
            <div className="frow frow--gutters-2x">
              <div className="col-mc-1-1">
                <label className="form__label">Review end date</label>
                <QuarterPicker
                  {...{ handleChange, startDate }}
                  label="Review end date"
                  theme="light"
                  name="review[end_date]"
                  id="review_end_date"
                  optionCount={17}
                  reverseDates
                />
              </div>
              <div className="col-mc-1-1">
                <label className="form__label">Review Fee</label>
                <input type="number" min={1} onChange={handleFeeInputChange} />
              </div>
              <div className="col-mc-1-1">
                <Button
                  buttonClasses="button--light-themed"
                  text="Create"
                  isSubmitting={isSubmitting}
                  submittingText="Creating"
                  isDisabled={!quarter}
                  handleClick={handleSubmit}
                />
              </div>
              <div className="col-mc-1-1">
                <p className="helper-text">
                  The start date will be determined by the review time period set on the portfolio, or the date from
                  which we have performance information for the portfolio whichever is latest.
                </p>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default newReview;
