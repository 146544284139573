import * as React from 'react';

import FormRow from 'components/shared/forms/FormRow';
import Context from '../Context';

export default function charges() {
  const context = React.useContext(Context);
  const { portfolio } = context.state;

  const { incomeRequirement, investmentObjective, restrictions, returnObjective, riskObjective, timeHorizon } =
    portfolio.portfolioMandate;

  return (
    <fieldset className="form__fieldset">
      <h4 className="form__fieldset-label">Objectives</h4>
      <div className="frow frow--gutters">
        <FormRow>
          <label htmlFor="risk" className="form__label">
            Investment Objective
          </label>
          <input
            type="text"
            name="portfolio[portfolio_mandate_attributes][investment_objective]"
            defaultValue={investmentObjective}
          />
        </FormRow>
        <FormRow>
          <label htmlFor="risk" className="form__label">
            Return Objective
          </label>
          <input
            type="text"
            name="portfolio[portfolio_mandate_attributes][return_objective]"
            defaultValue={returnObjective}
          />
        </FormRow>
        <FormRow>
          <label htmlFor="risk" className="form__label">
            Risk Objective
          </label>
          <input
            type="text"
            name="portfolio[portfolio_mandate_attributes][risk_objective]"
            defaultValue={riskObjective}
          />
        </FormRow>
        <FormRow>
          <label htmlFor="risk" className="form__label">
            Time Horizon
          </label>
          <input type="text" name="portfolio[portfolio_mandate_attributes][time_horizon]" defaultValue={timeHorizon} />
        </FormRow>
        <FormRow>
          <label htmlFor="risk" className="form__label">
            Income Requirement
          </label>
          <input
            type="text"
            name="portfolio[portfolio_mandate_attributes][income_requirement]"
            defaultValue={incomeRequirement}
          />
        </FormRow>
        <FormRow>
          <label htmlFor="risk" className="form__label">
            Restrictions
          </label>
          <input type="text" name="portfolio[portfolio_mandate_attributes][restrictions]" defaultValue={restrictions} />
        </FormRow>
      </div>
    </fieldset>
  );
}
