import * as React from 'react';

import turbolinks from 'turbolinks';

import IAdministrator from 'components/interfaces/IAdministrator';

import Table from 'components/shared/reactTable/Table';

interface IProps {
  data: IAdministrator[];
  isLoading: boolean;
}

class Presenter extends React.Component<IProps> {
  columns() {
    return [
      {
        Header: 'Name',
        accessor: 'attributes.full_name',
      },
      {
        Header: '',
        Cell: (row) => {
          return (
            <a href={row.original.links.edit_path}>
              <div className="rt-tr-clickable-icon">&#x2197;</div>
            </a>
          );
        },
      },
    ];
  }

  render() {
    return (
      <Table
        columns={this.columns()}
        data={this.props.data}
        identifier="administrators-table"
        isLoading={this.props.isLoading}
        getTrProps={(state, rowInfo, column) => {
          return {
            className: `rt-tr-clickable`,
            onClick: () => turbolinks.visit(rowInfo.original.links.show_path),
          };
        }}
      />
    );
  }
}

export default Presenter;
