import { Attr, BelongsTo, Model, HasMany } from 'spraypaint';

import { pipe, flatten, pluck, uniq, not, equals, compose } from 'ramda';

import { ApplicationRecord } from './ApplicationRecord';

import generateRecordWithRole from './roles/RecordWithRoleFactory';

import { Client } from './Client';
import { ClientUserRole } from './roles/ClientUser';
import { Portfolio } from './Portfolio';

const extractUsersFromRoles = (roles: ClientUserRole[]): User[] => pipe(pluck('users'), flatten, uniq)(roles);

interface IRoleModificationHash {
  roles_to_add?: string[];
  roles_to_remove?: string[];
}

@Model()
export class User extends generateRecordWithRole(ApplicationRecord) {
  public static jsonapiType = 'users';
  @Attr() public fullName: string;
  @Attr() public firstName: string;
  @Attr() public groupContact: boolean;
  @Attr() public lastName: string;
  @Attr() public email: string;
  @Attr() public signInCount: number;
  @Attr() public portfolioCount: number;
  @Attr() public primaryCount: number;
  @Attr() public phoneNumber: string;
  @Attr() public secondaryCount: number;
  @Attr() public emapCount: number;
  @Attr() public lastSignInAt: string;
  @Attr() public showPath: string;
  @Attr({ dirtyChecker: compose(not, equals) }) public clientIds: string[];
  @Attr() public uiLogin: boolean;
  @Attr() public discarded: boolean;
  @Attr() public discardedAt: Date;
  /*
   * Used to add/remove roles from a client user
   *
   * @see User#removeRoles and User#addRoles
   */
  @Attr() public roleModificationHash: IRoleModificationHash;
  @BelongsTo() public client: Client;

  @HasMany(ClientUserRole) public roles: ClientUserRole[];
  @HasMany() public clients: Client[];

  @HasMany(Portfolio) public primaryContactPortfolios: Portfolio[];
  @HasMany(Portfolio) public emapPortfolios: Portfolio[];

  public static async clientUsersWithPermission(clientId: string, permission: string | string[]) {
    const {
      data: { roles },
    } = await Client.select(['id'])
      .includes({ roles: 'users' })
      .where({ roles: { hasPermission: permission } })
      .find(clientId);

    return extractUsersFromRoles(roles);
  }

  public static sortUsersByAttribute({ users, attr }: { users: User[]; attr: keyof User }) {
    return users.sort((a, b) => {
      if (a[attr] < b[attr]) {
        return -1;
      }
      if (a[attr] > b[attr]) {
        return 1;
      }
      return 0;
    });
  }

  public static translateUserToFirstPosition({ user, usersArray }: { user: User; usersArray: User[] }) {
    const userIndex = usersArray.findIndex((element) => user.id === element.id);
    if (userIndex !== -1) {
      usersArray.unshift(...usersArray.splice(userIndex, 1));
    }
  }

  public removeRoles(...roles: ClientUserRole[]): this {
    const roleIds = pluck('id', roles);
    this.roleModificationHash = { roles_to_remove: roleIds };
    this.roles = this.roles.filter((role) => !roleIds.includes(role.id));
    return this;
  }

  public addRoles(...roles: ClientUserRole[]): this {
    this.roleModificationHash = { roles_to_add: pluck('id', roles) };
    this.roles.push(...roles);
    return this;
  }

  public get name(): string {
    return this.fullName || `${this.firstName} ${this.lastName}`;
  }

  public static get withPermissions() {
    return this.includes({ roles: ['permissions', 'client'] });
  }
}
