import * as React from 'react';

import * as numeral from 'numeral';
import { Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, XAxis, YAxis } from 'recharts';

import { CustomLabel } from './reactHorizontalBarChart/ChartComponents';

interface IProps {
  barSize?: number;
  data: { name: string; value: number; color: string }[];
  labelSize?: number;
  height: number;
  percentage: boolean;
  isResponsive?: boolean;
  width?: number;
}

export default function reactHorizontalBarChart(props: IProps) {
  const { barSize, data, height, labelSize, percentage, isResponsive, width } = props;

  const minData = Math.min(...data.map((dataItem) => dataItem.value));
  const maxData = Math.max(...data.map((dataItem) => dataItem.value));
  const highestPoint = Math.max(Math.abs(minData), Math.abs(maxData));

  const cells = data.map((dataItem) => {
    return <Cell key={dataItem.name} fill={dataItem.color} />;
  });

  const lowNumber = -Math.abs(highestPoint);
  const highNumber = Math.abs(highestPoint);
  const tickGap = highNumber / 3;
  const ticks = [
    lowNumber,
    lowNumber + tickGap,
    lowNumber + tickGap * 2,
    0,
    highNumber - tickGap * 2,
    highNumber - tickGap,
    highNumber,
  ];

  const tickFormatter = (tick) => `${numeral(tick).format('0.00')}${percentage ? '%' : ''}`;
  const labelSizePixel = `${labelSize}px`;

  if (isResponsive) {
    return (
      <ResponsiveContainer width="100%" height={height}>
        <BarChart
          width={500}
          height={height}
          data={data}
          margin={{ top: 5, right: 15, left: 30, bottom: 0 }}
          layout="vertical"
        >
          <CartesianGrid stroke="#d7d7dd" horizontal={false} />
          <XAxis
            type="number"
            domain={[lowNumber, highNumber]}
            tickCount={9}
            tickFormatter={tickFormatter}
            tick={{ fill: '#b5b6bd', fontSize: labelSizePixel, fontWeight: 'bold' }}
            ticks={ticks}
          />
          <YAxis
            type="category"
            dataKey="name"
            axisLine={false}
            tickLine={false}
            tick={{ fill: '#b5b6bd', fontSize: labelSizePixel, fontWeight: 'bold' }}
          />
          <Bar dataKey="value" barSize={barSize} label={<CustomLabel {...{ labelSize, percentage }} />}>
            {cells}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    );
  }

  return (
    <BarChart
      width={width}
      height={height}
      data={data}
      margin={{ top: 5, right: 15, left: 30, bottom: 0 }}
      layout="vertical"
    >
      <CartesianGrid stroke="#d7d7dd" horizontal={false} />
      <XAxis
        type="number"
        domain={[lowNumber, highNumber]}
        tickFormatter={tickFormatter}
        tick={{ fill: '#b5b6bd', fontSize: labelSizePixel, fontWeight: 'bold' }}
        ticks={ticks}
      />
      <YAxis
        type="category"
        dataKey="name"
        axisLine={false}
        tickLine={false}
        tick={{ fill: '#b5b6bd', fontSize: labelSizePixel, fontWeight: 'bold' }}
      />
      <Bar
        dataKey="value"
        isAnimationActive={false}
        barSize={barSize}
        label={<CustomLabel {...{ labelSize, percentage }} />}
      >
        {cells}
      </Bar>
    </BarChart>
  );
}

reactHorizontalBarChart.defaultProps = {
  barSize: 45,
  isResponsive: true,
  labelSize: 8,
  width: 450,
};
