import { Attr, Model } from 'spraypaint';
import { ApplicationRecord } from './ApplicationRecord';

interface IOverallTargets {
  week_1: number;
  week_2: number;
  week_3: number;
  week_4: number;
  week_5: number;
  week_6: number;
  week_7: number;
  week_8: number;
  week_9: number;
  week_10: number;
  week_11: number;
  week_12: number;
  week_13: number;
}

@Model()
export class Tracker extends ApplicationRecord {
  public static jsonapiType = 'trackers';
  @Attr() public trackerDate: string;
  @Attr() public team: string;
  @Attr() public overallTargets: IOverallTargets;
  @Attr() public overallTargetsDaily: any;
}
