import * as React from 'react';
import Table from 'components/shared/reactTable/Table';
import columns from './columns';

export default function table({ managers, portfolioCount }) {
  return (
    <React.Fragment>
      <p className="text-tiny text-white mar-b-1">
        Please note that this is only displaying portfolios for which we have balances on the selected date, and
        consolidated portfolios are excluded to prevent double counting of AUM.
      </p>
      <Table
        className="-sticky-header -sticky-header"
        columns={columns()}
        data={managers}
        hasColumnSelector={false}
        hasPagination={false}
        manual={false}
        meta={{ count: managers.length }}
        pageSize={managers.length}
      />
      <p className="text-tiny text-white mar-b-1">Total number of portfolios: {portfolioCount}</p>
    </React.Fragment>
  );
}
