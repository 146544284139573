import * as React from 'react';

import * as ReactTooltip from 'react-tooltip';
import { DirectUpload } from 'activestorage';
import lodashTruncate from 'lodash.truncate';

import useVisibility from 'components/shared/customHooks/useVisibility';
import CustomModal from 'components/shared/CustomModal';

import { HeadingThree, SubtitleOne, SubtitleTwo } from 'components/shared/Typography';
import Dropzone from 'components/shared/forms/Dropzone';
import { createAlert } from 'components/shared/Utils';
import Comment from '../comments/Comment';
import Form from '../../../../../../managers/dataUploads/show/notes/Form';
import { ActionPoint } from 'javascript/models';
import { Note } from 'javascript/models';
import { NotesNotable } from 'javascript/models';

export default function info({ actionPoint, isInfoOpen, handleInfoClose }) {
  const { isOpen, handleClose, handleOpen } = useVisibility(false);
  const { isOpen: isNoteOpen, handleClose: handleNoteClose, handleOpen: handleNoteOpen } = useVisibility(false);
  const [isSubmitting, setIsSubmitting] = React.useState();
  const [actionPointObject, setActionPointObject] = React.useState(actionPoint);

  React.useEffect(() => {
    setActionPointObject(actionPoint);
  }, [actionPoint]);

  const notes =
    actionPointObject.notes &&
    actionPointObject.notes.filter((note: Note) => note.noteType === 'action_point_response');

  React.useEffect(() => {
    if (isInfoOpen) {
      handleOpen();
    } else {
      handleClose();
    }
  }, [isInfoOpen]);

  async function getActionPoint() {
    const { data } = await ActionPoint.includes({ notes: 'author' }).find(actionPointObject.id);

    setActionPointObject(data);
  }

  function onClose() {
    handleInfoClose();
  }

  async function createUpload(signedId) {
    actionPoint.documents = [...actionPoint.documents.map((doc) => doc.signed_id), signedId];
    await actionPoint.save();
    setActionPointObject(actionPoint);
  }

  async function handleUploadedFiles(files) {
    if (files.length < 1) return;

    setIsSubmitting(true);

    files.forEach((file, index) => {
      const upload = new DirectUpload(file, '/rails/active_storage/direct_uploads');
      upload.create((error, blob) => {
        if (error) {
          setIsSubmitting(false);
          createAlert('error', 'There was an error uploading this file', 2000);
        } else {
          const signedId = blob.signed_id;
          createUpload(signedId).then(() => getActionPoint());
          setIsSubmitting(false);
        }
      });
    });
  }

  const formattedFiles = actionPoint.documents.map((file, index) => {
    return (
      <div key={index}>
        <a
          className="tag tag--blue tag--inline tag--small"
          href={file.path}
          target="_blank"
          data-tip={file.name}
          data-for="document-file-name"
        >
          {lodashTruncate(file.name)}
        </a>
      </div>
    );
  });

  async function handleFormSubmit(comment: string) {
    const newNote = new Note({
      body: comment,
      note_category: 'note',
      note_type: 'action_point_response',
      status: 'normal',
    });

    const success = await newNote.save();
    if (success) {
      const notesNotable = new NotesNotable({
        note_id: newNote.id,
        notable_type: 'ActionPoint',
        notable_id: actionPointObject.id,
      });

      const notableSuccess = await notesNotable.save();
      if (notableSuccess) {
        handleNoteClose();
        getActionPoint();
      }
    }
  }

  async function handleDelete(noteId: string, destroy: boolean) {
    const updatedNote = new Note({ id: noteId });
    const success = await updatedNote.destroy();
    if (success) {
      getActionPoint();
    }
  }

  async function handleUpdate(comment: string, noteId: string) {
    const updatedNote = new Note({ id: noteId });
    updatedNote.isPersisted = true;
    updatedNote.body = comment;
    const success = await updatedNote.save();
    if (success) {
      getActionPoint();
    }
  }

  const formattedNotes = notes.reverse().map((note) => {
    return (
      <div key={note.id} style={{ width: '100%' }}>
        <Comment {...{ handleDelete, handleUpdate, note }} theme="dark" />
      </div>
    );
  });

  function renderNotes() {
    if (notes.length > 0) {
      return formattedNotes;
    }

    return <p className="mar-v-0 text-small">No comments to display.</p>;
  }

  return (
    <CustomModal isOpen={isOpen} title="Action Point Info" handleClose={onClose} modifiers={['large', 'dark']}>
      <div className="modal__content">
        <div className="platform-content platform-content--border-bottom-grey platform-content--padding-bottom">
          <p className="text-small text-white mar-t-0 mar-b-2">{actionPointObject.body}</p>
        </div>
        <div className="platform-content platform-content--border-bottom-grey platform-content--padding-vertical">
          <SubtitleTwo text="Attachments" additionalClasses="text-white" />
          <div className="frow frow--gutters mar-b-2">
            {formattedFiles}
            <ReactTooltip effect="solid" id="document-file-name" />
          </div>
          <div className="h-100">
            <Dropzone
              shouldDiscardFiles
              isSubmitting={isSubmitting}
              onFileUpload={handleUploadedFiles}
              resetFiles
              shouldShowFiles={false}
              theme="dark"
              multiple
            />
          </div>
        </div>
        <div className="platform-content platform-content--padding-vertical">
          <div className="frow frow--justify-between frow--items-center mar-b-2">
            <SubtitleTwo text="Comments" additionalClasses="text-white" />
            <button className="button button--compact" onClick={handleNoteOpen}>
              Add Comment <i className="icon-plus icon-fw mar-l-1 icon-push-down-1" />
            </button>
          </div>
          <div className="frow frow--gutters">{renderNotes()}</div>
        </div>
      </div>
      <CustomModal isOpen={isNoteOpen} title="Create Note" handleClose={handleNoteClose}>
        <Form {...{ handleFormSubmit }} />
      </CustomModal>
    </CustomModal>
  );
}
