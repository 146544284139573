import { Attr, Model, BelongsTo } from 'spraypaint';
import { ApplicationRecord } from './ApplicationRecord';
import { Security } from './Security';
import { Portfolio } from './Portfolio';

@Model()
export class Holding extends ApplicationRecord {
  public static jsonapiType = 'holdings';

  private static _assetClasses: Promise<string[]>;

  @Attr() public holdableType: string;
  @Attr() public holdableId: string;
  @Attr() public description: string;
  @Attr() public isin: string;
  @Attr() public assetClass: string;
  @Attr() public quantity: string;
  @Attr() public sedol: string;
  @Attr() public currency: string;
  @Attr() public percentage: number;
  @Attr() public valueBaseCcy: string;
  @Attr() public valueDate: Date;
  @Attr() public valueInstrumentCcy: string;
  @Attr() public batchNumber: string;
  @Attr() public discardedAt: Date;
  @Attr() public useLookthroughAssetClass: boolean;
  @Attr() public activeAssetClasses: string;
  @Attr() public headlineAssetClass: string;

  @BelongsTo(Security) public security: Security;
  @BelongsTo(Portfolio) public holdable: Portfolio;

  public static get allAvailableAssetClasses() {
    const requestStat = async (stat) => {
      const { meta } = await this.stats(stat).per(0).all();
      return meta.stats.static_data.asset_classes;
    };

    if (!this._assetClasses) this._assetClasses = requestStat({ staticData: 'asset_classes' });
    return this._assetClasses;
  }

  public static holdingsWithoutLiveState() {
    return this.where({ isin: { '!eq': 'null' }, hasLiveSecurityState: false });
  }
}
