import * as React from 'react';
import * as ReactDOM from 'react-dom';

export class Alerter {
  public static create(
    props: Pick<IAlertProps, 'text' | 'cancelButtonText' | 'confirmButtonText' | 'intent' | 'onConfirm' | 'onCancel'>,
  ) {
    const container = document.body;
    const containerElement = document.createElement('div');
    container.appendChild(containerElement);
    const alert = <Alert {...props} isOpen />;
    const alertContainer = ReactDOM.render<IAlertProps>(alert, containerElement) as Alerter;
    return alertContainer;
  }
}

import { Alert as BlueprintAlert, IAlertProps as IBlueprintAlertProps } from '@blueprintjs/core';
import useVisibility from './customHooks/useVisibility';

export interface IAlertProps
  extends Omit<IBlueprintAlertProps, 'canEscapeKeyClose' | 'canOutsideClickCancel' | 'portalContainer'> {
  text: string;
}

export function Alert({ text, ...props }: IAlertProps) {
  const { isOpen, handleClose } = useVisibility(props.isOpen);
  const turbolinksFixPortalDocumentBody = document.body;

  const defaultProps = {
    canEscapeKeyClose: false,
    canOutsideClickCancel: false,
    portalContainer: turbolinksFixPortalDocumentBody,
  };

  return (
    <BlueprintAlert {...props} {...defaultProps} {...{ isOpen }} onClose={handleClose}>
      <p>{text}</p>
    </BlueprintAlert>
  );
}
