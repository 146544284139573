import * as React from 'react';

import { format } from 'date-fns';
import { SubtitleTwo } from '../../../shared/Typography';
import ClientPortalCard from '../../shared/ClientPortalCard';
import Loading from '../../../shared/Loading';

import { portalClientAumBreakdownPath, ScalarType } from 'javascript/application/ts_routes';
import useAjaxRequest from '../../../shared/customHooks/useAjaxRequest';
export interface IPortalTile {
  title?: string;
  children?: JSX.Element;
  tileModifier?: JSX.Element;
}

interface IAssetsByCurrency {
  name: string;
  value: string;
  pct: number;
  value_base_currency: number;
}

interface IData {
  data: IAssetsByCurrency[];
  meta: {
    count: { sent: number; total: number };
  };
}

interface IAssetsUnderManagement {
  clientId: ScalarType;
  selectedDate: Date;
}

const AssetsUnderManagement = ({ clientId, selectedDate }: IAssetsUnderManagement) => {
  const [data, setData] = React.useState<IData>();

  const path = portalClientAumBreakdownPath(clientId);

  const [getBreakdownData, isError, isLoading] = useAjaxRequest<{ date: string }, IData>({
    method: 'GET',
    path,
    stateSetter: setData,
  });

  React.useEffect(() => {
    getBreakdownData({ date: format(selectedDate, 'YYYY-MM-DD') });
  }, [selectedDate]);

  const AumContent = () => {
    const sentCount = data.meta.count.sent;
    const totalCount = data.meta.count.total;
    return (
      <>
        <div className="frow">
          <div className="col-md-1-2">
            <div>
              <SubtitleTwo
                text={`Total ${format(selectedDate, "DD MMM 'YY")}`}
                additionalClasses="text-grey-40 text-weight-500"
              />
              <p className="text-large text-white mar-t-0 text-normal" style={{ fontSize: '3rem' }}>
                {data.data[0].value}
              </p>
            </div>
            <div>
              <SubtitleTwo text={'Portfolios'} additionalClasses="text-grey-40 text-weight-500" />
              <p className="text-large text-white mar-t-0 text-normal" style={{ fontSize: '3rem' }}>
                {sentCount && totalCount ? `${sentCount}/${totalCount}` : sentCount}
              </p>
            </div>
          </div>
          <div className="col-md-1-2">
            <table className="styled-table styled-table--alt">
              <tbody>
                {data.data
                  .filter((item) => item.name !== 'Total')
                  .map((item) => (
                    <tr key={item.name}>
                      <td>{item.name}</td>
                      <td style={{ textAlign: 'left' }}>{item.value}</td>
                      <td style={{ textAlign: 'right', fontSize: '1.8rem' }}>{item.pct}%</td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <p className="text-grey-40 text-small">
              Split of portfolios by main currencies (by % exposure), for completed reviews in the selected quarter.
            </p>
            <p className="text-grey-40 text-small">
              Note: Consolidated portfolios are excluded to avoid double counting of AUM.
            </p>
          </div>
        </div>
      </>
    );
  };
  return (
    <ClientPortalCard title={'Assets Under Management'}>
      {isLoading || isError ? <Loading /> : <AumContent />}
    </ClientPortalCard>
  );
};

export default AssetsUnderManagement;
