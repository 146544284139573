import * as React from 'react';

import FormRow from 'components/shared/forms/FormRow';
import Context from '../../Context';
import useVisibility from 'components/shared/customHooks/useVisibility';
import ChildPortfolioSelect from './shared/ChildPortfolioSelect';

export default function consolidated({ consolidated, handleConsolidatedChange }) {
  const context = React.useContext(Context);

  const { client, portfolio } = context.state;
  const { isOpen, setIsOpen } = useVisibility(consolidated);

  React.useEffect(() => {
    setIsOpen(consolidated);
  }, [consolidated]);

  function handleClick() {
    handleConsolidatedChange(!isOpen);
  }

  return (
    <React.Fragment>
      <FormRow
        note="Consolidated portfolios are made up of 'Live' portfolios. They received a separate report. All of the
        constituent portfolios also receive an E-MAP"
        className="col-mc-1-1"
      >
        <label htmlFor="currency" className="form__label">
          Consolidated
        </label>
        <div className="pretty p-icon">
          <input type="hidden" value="0" name="portfolio[consolidated]" />
          <input
            type="checkbox"
            name="portfolio[consolidated]"
            value="1"
            checked={consolidated}
            onChange={handleClick}
          />
          <div className="state p-blue">
            <i className="icon-tick icon icon-push-down-small" />
            <label className="text-white">Consolidated Portfolio</label>
          </div>
        </div>
      </FormRow>
      <ChildPortfolioSelect {...{ isOpen, client, portfolio }} />
    </React.Fragment>
  );
}
