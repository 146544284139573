import * as React from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import useVisibility from 'components/shared/customHooks/useVisibility';

import { portalClientPortfolioRiskMetricsPath } from 'javascript/application/ts_routes';
import { format, parse } from 'date-fns';
import Loading from 'components/shared/Loading';
import useAjaxRequest from 'components/shared/customHooks/useAjaxRequest';
import Table from 'components/shared/reactTable/Table';
import Frow from 'components/frow/Frow';
import ClientPortalCard from '../../../shared/ClientPortalCard';
import { selectableComparisons } from './selectableComparisons';
import columns from './risk/columns';

const Risk = ({ currentReviewPeriod, portfolioObject }) => {
  const { benchmarkTypes, client_id, id } = portfolioObject;
  const [tableData, setTableData] = React.useState<{ data: any[] }>();
  const [selectedMetrics, setSelectedMetrics] = React.useState([
    'three_year',
    'three_year_downside',
    'positive_periods',
    'negative_periods',
    'max_drawdown',
  ]);
  const [selectedComparisons, setSelectedComparisons] = React.useState([
    ...(Object.values(benchmarkTypes) as string[]),
    'cash',
    'equity',
  ]);
  const { isOpen: isMetricOpen, toggleOpen: toggleMetricOpen, handleClose: handleMetricClose } = useVisibility(false);
  const {
    isOpen: isComparisonOpen,
    toggleOpen: toggleComparisonOpen,
    handleClose: handleComparisonClose,
  } = useVisibility(false);

  const path = portalClientPortfolioRiskMetricsPath(client_id, id);

  interface IRequestParams {
    metrics: string[];
    comparisons: string[];
    review_period: string;
  }

  const [getMetrics, , isLoading] = useAjaxRequest<IRequestParams, any>({
    method: 'GET',
    path,
    stateSetter: setTableData,
  });

  React.useEffect(() => {
    if (selectedComparisons.length > 0 && selectedMetrics.length > 0) {
      getMetrics({
        comparisons: selectedComparisons,
        metrics: selectedMetrics,
        review_period: currentReviewPeriod,
      });
    }
  }, [selectedMetrics, selectedComparisons, currentReviewPeriod]);

  const handleSelectedChange = (metric) => {
    setSelectedMetrics(selectedMetrics.toggle(metric.value));
  };

  const handleComparisonChange = (comparison) => {
    setSelectedComparisons(selectedComparisons.toggle(comparison.value));
  };

  const selectableMetrics = [
    { label: '1 Yr Std. Dev.', value: 'one_year' },
    { label: '3 Yr Std. Dev.', value: 'three_year' },
    { label: '5 Yr Std. Dev.', value: 'five_year' },
    { label: 'Std. Dev. (Inception)', value: 'overall' },
    { label: '3 Yr Downside Std. Dev.', value: 'three_year_downside' },
    { label: '5 Yr Downside Std. Dev.', value: 'five_year_downside' },
    { label: 'Downside Std. Dev. (Inception)', value: 'downside' },
    { label: 'Positive Periods', value: 'positive_periods' },
    { label: 'Negative Periods', value: 'negative_periods' },
    { label: 'Positive Periods (%)', value: 'positive_periods_pct' },
    { label: 'Max Drawdown', value: 'max_drawdown' },
  ];

  const metricMenuItems = selectableMetrics.map((metric) => {
    const handleChange = () => {
      handleSelectedChange(metric);
    };
    return (
      <div key={`risk-${metric.value}`} className="dropdown__nav-link">
        <div className="pretty p-icon">
          <input
            type="checkbox"
            value={metric.value}
            id="series"
            onChange={handleChange}
            checked={selectedMetrics.includes(metric.value)}
          />
          <div className="state p-blue">
            <i className="icon-tick icon" />
            <label>{metric.label}</label>
          </div>
        </div>
      </div>
    );
  });

  const metricsToggle = (
    <div className={`dropdown dropdown--floating dropdown--middle ${isMetricOpen && 'dropdown--active'}`}>
      <OutsideClickHandler onOutsideClick={handleMetricClose}>
        <div className="dropdown-toggle" onClick={toggleMetricOpen}>
          <div className="frow frow--items-center text-hoverable">
            <i className="icon-filter-list icon-0-9x mar-r-1 text-white" />
            <span className="text-small text-white">Metrics</span>
          </div>
        </div>
        <div className="dropdown__content dropdown__content--with-arrow" style={{ width: '250px', left: '-100%' }}>
          {metricMenuItems}
        </div>
      </OutsideClickHandler>
    </div>
  );

  const comparisonMenuItems = selectableComparisons(portfolioObject).map((comparison) => {
    const handleChange = () => {
      handleComparisonChange(comparison);
    };

    return (
      <div key={`risk-${comparison.value}`} className="dropdown__nav-link">
        <div className="pretty p-icon">
          <input
            type="checkbox"
            value={comparison.value}
            id="series"
            onChange={handleChange}
            checked={selectedComparisons.includes(comparison.value)}
          />
          <div className="state p-blue">
            <i className="icon-tick icon" />
            <label>{comparison.label}</label>
          </div>
        </div>
      </div>
    );
  });

  const comparisonToggle = (
    <div className={`dropdown dropdown--floating dropdown--middle ${isComparisonOpen && 'dropdown--active'}`}>
      <OutsideClickHandler onOutsideClick={handleComparisonClose}>
        <div className="dropdown-toggle" onClick={toggleComparisonOpen}>
          <div className="frow frow--items-center text-hoverable">
            <i className="icon-filter-list icon-0-9x mar-r-1 text-white" />
            <span className="text-small text-white">Comparisons</span>
          </div>
        </div>
        <div className="dropdown__content dropdown__content--with-arrow" style={{ width: '250px', left: '-100%' }}>
          {comparisonMenuItems}
        </div>
      </OutsideClickHandler>
    </div>
  );

  const renderTable = () => {
    if (!tableData) {
      return;
    }
    return (
      <Table
        className="-wrap-headers-overflow"
        columns={columns(selectedMetrics)}
        data={tableData.data}
        hasColumnSelector={false}
        hasPagination={false}
        isLoading={isLoading}
        manual={false}
        meta={{ count: tableData.data.length }}
      />
    );
  };

  const titleControls = (
    <Frow itemAlignment="start" gutterSize={1}>
      {metricsToggle}
      {comparisonToggle}
    </Frow>
  );

  const cardProps = {
    subTitle: format(parse(currentReviewPeriod), "DD MMM 'YY"),
    title: 'Risk',
    titleControls,
  };

  return (
    <div className="col-lg-1-1">
      <ClientPortalCard {...cardProps}>{isLoading ? <Loading /> : renderTable()}</ClientPortalCard>
    </div>
  );
};

export default Risk;
