import { Attr, Model, BelongsTo } from 'spraypaint';
import { ApplicationRecord } from '../ApplicationRecord';
import { SecurityState } from './State';
import { SecurityClassification } from './Classification';

@Model()
export class SecurityStateClassificationWeighting extends ApplicationRecord {
  public static jsonapiType = 'securities/state_classification_weightings';
  @Attr() public id: string;
  @Attr() public classificationId: string;
  @Attr() public valueType: string;
  @Attr() public weighting: number;
  @Attr() public classificationSource: string;
  @Attr() public securityClassificationStateId: number;

  @BelongsTo(SecurityState) public state: SecurityState;
  @BelongsTo(SecurityClassification) public classification: SecurityClassification;
}
