import React, { useState } from 'react';
import { Frow } from 'components/frow';
import { Collapse } from '@blueprintjs/core';
import { Icon } from '@blueprintjs/core';

const ResultCell = ({ values, breachCountText }) => {
  const [isOpen, setIsOpen] = useState(false);
  const firstValue = values[0];
  const restOfValues = values.slice(1);

  const CellContents = ({ content }) => {
    const padding = values.length > 1 ? 'mar-t-2' : '';
    return (
      <>
        {content.map((item, index) => (
          <Frow key={index} className={padding}>
            <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{item}</div>
          </Frow>
        ))}
      </>
    );
  };

  const CollapsibleCell = () => (
    <Frow direction="column">
      <Frow>
        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', marginTop: '2px', fontWeight: 500 }}>
          {breachCountText || firstValue}
        </div>
        <div className="pad-l-1">
          <Icon icon={isOpen ? 'caret-up' : 'caret-down'} />
        </div>
      </Frow>

      <Collapse isOpen={isOpen}>
        <CellContents content={breachCountText ? values : restOfValues} />
      </Collapse>
    </Frow>
  );

  return (
    <div onClick={() => setIsOpen(!isOpen)} className="frow frow--center">
      {restOfValues.length > 0 || breachCountText ? <CollapsibleCell /> : <CellContents content={[firstValue]} />}
    </div>
  );
};

export default ResultCell;
