import * as React from 'react';

import * as ReactTooltip from 'react-tooltip';

import lodashStartcase from 'lodash.startcase';

import { platformClientPath, platformEntityPath, platformManagerPath } from 'javascript/application/ts_routes';

export default function graphitiColumns(currencies, clientTypes, riskOptions, statuses) {
  return [
    {
      Header: 'Portfolio',
      accessor: 'reference',
      columnName: 'Portfolio',
      id: 'reference',
    },
    {
      Cell: (row) => {
        return (
          <div className="frow frow--items-center" data-tip={row.original.client && row.original.client.name}>
            <a href={platformClientPath(row.original.client.id)} className="link link--secondary">
              {row.original.client && row.original.client.name}
            </a>
            <ReactTooltip effect="solid" delayShow={300} />
          </div>
        );
      },
      Header: 'Client',
      accessor: 'clientName',
      columnName: 'Client',
      eval: 'client.name',
      id: 'client_name',
    },
    {
      Cell: (row) => {
        return (
          <div className="frow frow--items-center">
            <img alt="Client type icon" src={row.original.clientTypeIcon} className="mar-r-1 client-type-table-icon" />
            {lodashStartcase(row.original.clientType)}
          </div>
        );
      },
      Header: 'Client Type',
      accessor: 'clientType',
      columnName: 'Client Type',
      filterOptions: {
        key: 'client_type',
        options: clientTypes.map((type) => ({ display: type, value: type })),
      },
      id: 'client_type',
    },
    {
      Cell: (row) => {
        if (row.original.entity) {
          return (
            <div className="frow frow--items-center" data-tip={row.original.entity.name}>
              <a href={platformEntityPath(row.original.entity.id)} className="link link--secondary">
                {row.original.entity.name}
              </a>
              <ReactTooltip effect="solid" delayShow={300} />
            </div>
          );
        }
        return <div />;
      },
      Header: 'Entity',
      accessor: 'entity',
      columnName: 'Entity',
      eval: 'entity.name',
      id: 'entity_name',
    },
    {
      Cell: (row) => {
        return (
          <div className="frow frow--items-center" data-tip={row.original.manager && row.original.manager.name}>
            <a
              href={platformManagerPath(row.original.manager && row.original.manager.name)}
              className="link link--secondary"
            >
              {row.original.manager && row.original.manager.name}
            </a>
            <ReactTooltip effect="solid" delayShow={300} />
          </div>
        );
      },
      Header: 'Manager',
      accessor: 'managerName',
      columnName: 'Manager',
      eval: 'manager.name',
      id: 'manager_name',
    },
    {
      Cell: (row) => {
        return <div>{lodashStartcase(row.original.risk)}</div>;
      },
      Header: 'Risk Profile',
      accessor: 'risk',
      columnName: 'Risk Profile',
      filterOptions: {
        key: 'risk',
        options: riskOptions.map((option) => ({ display: lodashStartcase(option), value: option })),
      },
      id: 'risk',
      sortable: false,
    },
    {
      Cell: (row) => {
        return <div>{row.original.currency.toUpperCase()}</div>;
      },
      Header: 'Currency',
      accessor: 'currency',
      columnName: 'Currency',
      filterOptions: {
        key: 'currency',
        options: currencies.map((currency) => ({ display: currency.toUpperCase(), value: currency })),
      },
      id: 'currency',
      sortable: false,
    },
    {
      Cell: (row) => {
        return <div>{lodashStartcase(row.original.attributes.status)}</div>;
      },
      Header: 'Status',
      accessor: 'status',
      columnName: 'status',
      filterOptions: {
        key: 'status',
        options: statuses.map((status) => ({ display: lodashStartcase(status), value: status })),
      },
      id: 'status',
      // selectedCustomFilters: statuses.filter(status => status !== 'terminated'),
    },
    {
      Cell: (row) => {
        return (
          <a href={row.original.showPath} style={{ display: 'block' }}>
            <div className="rt-tr-clickable-icon">
              <i className="icon-arrow-right-top icon-0-8x" />
            </div>
          </a>
        );
      },
      Header: '',
      sortable: false,
    },
  ];
}
