import * as React from 'react';
import * as $ from 'jquery';

import Presenter from './structure/Presenter';

import useDebounce from 'components/shared/customHooks/useDebounce';

import { Entity } from 'javascript/models';
import { EntityGrouping } from 'javascript/models';

interface IProps {
  entityGrouping: any;
  entityId: number;
  path: string;
}

export default function structure(props: IProps) {
  const { entityId, path } = props;
  const [isLoading, setIsLoading] = React.useState(true);
  const [name, setName] = React.useState(props.entityGrouping.name);
  const [structure, setStructure] = React.useState(null);

  const debouncedInputValue = useDebounce(name, 600);

  const entityGrouping = new EntityGrouping({
    id: props.entityGrouping.id,
    name: props.entityGrouping.name,
  });

  if (props.entityGrouping.id) {
    entityGrouping.isPersisted = true;
  }

  React.useEffect(() => {
    if (debouncedInputValue) {
      updateEntityGrouping();
    }
  }, [debouncedInputValue]);

  React.useEffect(() => {
    getStructure();
  }, []);

  React.useEffect(() => {
    if (!structure) return;

    setIsLoading(false);
  }, [structure]);

  function getStructure() {
    $.ajax({
      cache: false,
      dataType: 'json',
      success: (response) => {
        setStructure(response);
      },
      type: 'GET',
      url: path,
    });
  }

  function updateGrouping(event) {
    setName(event.target.value);
  }

  async function updateEntityGrouping() {
    entityGrouping.name = name;
    const success = await entityGrouping.save();

    if (success) {
      const entity = new Entity({ id: entityId });
      entity.isPersisted = true;
      entity.entityGroupingId = entityGrouping.id;
      entity.save();
    }
  }

  return <Presenter {...{ entityGrouping, entityId, isLoading, structure, updateGrouping }} />;
}
