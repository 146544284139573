import React from 'react';

import Frow from 'components/frow/Frow';
import Loading from 'components/shared/Loading';
import StyledWarning from 'components/shared/styledAlerts/StyledWarning';

import { ActionPointContext } from '../../../Show';
import InReviewButton from './InReviewButton';

const InReviewActions = () => {
  const { isLoading, currentUser, client } = React.useContext(ActionPointContext);

  if (isLoading) return <Loading />;

  const currentUserHasPermission = currentUser.hasPermission('action_point_closer', client.id);

  if (!currentUserHasPermission)
    return <StyledWarning message="You need the action point closer permission to reject or release action points" />;

  return (
    <Frow justifyContent="start">
      <div className="mar-r-1">
        <InReviewButton action="reject" />
      </div>
      <InReviewButton action="release" />
    </Frow>
  );
};

export default InReviewActions;
