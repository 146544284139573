import * as React from 'react';

import { ClientTile } from 'javascript/models';
import { HeadingTwo } from 'components/shared/Typography';
import CustomModal from 'components/shared/CustomModal';
import useVisibility from 'components/shared/customHooks/useVisibility';
import useGraphitiForm from 'components/shared/customHooks/useGraphitiForm';
import { graphitiErrorString } from 'components/shared/Utils';
import Alert from 'components/shared/Alert';
import Form from '../platform/clientTiles/Form';
import ResourceCard from './resources/ResourceCard';

const resources = ({ resourceTypes }) => {
  const [errorMessage, setErrorMessage] = React.useState(null);
  const { isOpen, handleOpen, handleClose } = useVisibility(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const handleFormSubmission = async (event) => {
    const success = await resource.save();
    if (success) {
      resetResource();
      handleClose();
      getTiles();
    } else {
      setErrorMessage(graphitiErrorString(resource));
    }
    setIsSubmitting(false);
  };

  const { resource, handleChange, handleManualChange, handleSubmit, resetResource } = useGraphitiForm(
    new ClientTile(),
    handleFormSubmission,
  );

  const handleModalClose = () => {
    handleClose();
    setErrorMessage(null);
  };

  const handleFormSubmit = (event) => {
    setIsSubmitting(true);
    handleSubmit(event);
  };

  const [tiles, setTiles] = React.useState([]);

  React.useEffect(() => {
    getTiles();
  }, []);

  const getTiles = async () => {
    const { data } = await ClientTile.where({ default_and_individual: true })
      .order({ user_id: 'desc' })
      .order({ name: 'asc' })
      .per(999)
      .all();

    setTiles(data);
  };

  const handleDelete = async (tile: ClientTile) => {
    await tile.destroy();
    getTiles();
  };

  const saveButtonText = 'Add A Tile';
  const resourceCards = tiles.map((tile) => (
    <ResourceCard key={`client-tile-${tile.id}`} {...{ getTiles, handleDelete, resourceTypes, tile }} />
  ));

  return (
    <div className="frow frow--gutters-2x">
      <div className="col-mc-1-1">
        <div className="frow frow--justify-between">
          <div>
            <HeadingTwo text="External Resources" additionalClasses="text-white" />
          </div>
          <div>
            <button className="button button--secondary" onClick={handleOpen}>
              Add Tile
            </button>
          </div>
        </div>
        <CustomModal
          isOpen={isOpen}
          title="Add a tile"
          handleClose={handleModalClose}
          modifiers={['visible-overflow', 'dark']}
        >
          <div className="modal__content">
            <Alert message={errorMessage} modifier="dark" title="Missing Fields" type="error" />
            <form className="form" onSubmit={handleFormSubmit}>
              <Form {...{ resource, handleChange, handleManualChange, isSubmitting, resourceTypes, saveButtonText }} />
            </form>
          </div>
        </CustomModal>
      </div>
      <div className="col-mc-1-1">
        <div className="frow frow--gutters">{resourceCards}</div>
      </div>
    </div>
  );
};
export default resources;
