import * as React from 'react';

import { ReportTemplatesReportTemplateSection } from 'javascript/models';
import Context from '../../../Context';
import useSectionSize from './useSectionSize';

import LegendKeys from './shared/LegendKeys';
import { Frow, Column } from 'components/frow';
import ReactSelect from 'components/shared/forms/ReactSelect';

const pieChart = ({ attributes, handleChange, handleManualChange, isRepeating }) => {
  const context = React.useContext(Context);
  const { reportTemplate } = context.state;

  const isConsolidated = reportTemplate.consolidated;

  const defaultKey = (attributes.pieChartDetails && attributes.pieChartDetails.name_type) || 'enhance_reference';

  const [includePrivateAssets, setIncludePrivateAssets] = React.useState(
    (attributes.pieChartDetails && attributes.pieChartDetails.include_private_assets) || false,
  );

  const [category, setCategory] = React.useState(
    (attributes.pieChartDetails && attributes.pieChartDetails.category) || false,
  );

  const [target, setTarget] = React.useState(attributes.pieChartTarget);
  const [type, setType] = React.useState(attributes.pieChartType);
  const [additionalAttribute, setAdditionalAttribute] = React.useState(
    attributes && attributes.pieChartDetails && attributes.pieChartDetails.attribute,
  );
  const [selectedKey, setSelectedKey] = React.useState(defaultKey);
  const { selectedSize, handleSizeChange } = useSectionSize(attributes.size, handleManualChange);

  function updateAttributes() {
    const value = {
      target,
      type,
      name_type: selectedKey,
      attribute: additionalAttribute,
      include_private_assets: includePrivateAssets,
      category,
    };
    handleManualChange(value, 'pieChartDetails');
  }

  React.useEffect(() => {
    updateAttributes();
  }, [target, type, selectedKey, additionalAttribute, includePrivateAssets, category]);

  function handleTargetChange() {
    setTarget(this);
  }
  function handleTypeChange() {
    setType(this);
  }
  function handleKeyChange() {
    setSelectedKey(this);
  }

  let scopedTargetOptions = ReportTemplatesReportTemplateSection.targetOptions;
  if (isConsolidated) {
    scopedTargetOptions = ReportTemplatesReportTemplateSection.consolidatedTargetOptions;
  }

  if (isRepeating) {
    scopedTargetOptions = ReportTemplatesReportTemplateSection.constituentTargetOptions;
  }

  const targetOptions = scopedTargetOptions.map((option) => {
    return (
      <div className="col-tn-1-3" key={option.value}>
        <div className="pretty pretty--large pretty--with-label p-round">
          <input
            type="radio"
            value={option.value}
            id="pie_target"
            onChange={handleTargetChange.bind(option.value)}
            checked={target === option.value}
          />
          <div className="state p-blue-circle">
            <label>{option.label}</label>
          </div>
        </div>
      </div>
    );
  });

  const sizeOptions = ReportTemplatesReportTemplateSection.sizeOptions.map((size) => {
    function handleChange() {
      handleSizeChange(size.value);
    }

    return (
      <div className="col-tn-1-3" key={size.value}>
        <div className="pretty pretty--large pretty--with-label p-round">
          <input
            type="radio"
            value={size.value}
            id="size"
            onChange={handleChange}
            checked={selectedSize === size.value}
          />
          <div className="state p-blue-circle">
            <label>{size.label}</label>
          </div>
        </div>
      </div>
    );
  });

  let scopeTypeOptions = ReportTemplatesReportTemplateSection.pieChartTypeOptions as any;
  if (isConsolidated) {
    scopeTypeOptions = [
      ...ReportTemplatesReportTemplateSection.pieChartTypeOptions,
      { value: 'constituent_portfolios', label: 'Constituent Portfolios' },
    ];
  }

  if (isRepeating) {
    scopeTypeOptions = [...ReportTemplatesReportTemplateSection.pieChartTypeOptions];
  }

  const typeOptions = scopeTypeOptions.map((option) => (
    <div className="col-tn-1-3" key={option.value}>
      <div className="pretty pretty--large pretty--with-label p-round">
        <input
          type="radio"
          value={option.value}
          id="pie_type"
          onChange={handleTypeChange.bind(option.value)}
          checked={type === option.value}
        />
        <div className="state p-blue-circle">
          <label>{option.label}</label>
        </div>
      </div>
    </div>
  ));

  const additionalAttributeJSX =
    type !== 'additional_attribute' ? null : (
      <Column>
        <label className="form__label" htmlFor="">
          Attribute
        </label>
        <Frow>
          <input type="text" value={additionalAttribute} onChange={(e) => setAdditionalAttribute(e.target.value)} />
        </Frow>
      </Column>
    );

  const privateAssetOptions = [
    { label: 'Category', value: 'category' },
    { label: 'Sub-Category', value: 'sub_category' },
    { label: 'Traditional vs Private', value: false },
  ];

  const selectedPrivateAssetOption = privateAssetOptions.find(({ value }) => value == category);

  return (
    <Frow gutterSize={1} columnContext={{ columnSpan: 1, maxColumns: 1 }}>
      <Column>
        <label className="form__label" htmlFor="">
          Target
        </label>
        <Frow>{targetOptions}</Frow>
      </Column>
      <Column>
        <label className="form__label" htmlFor="">
          Type
        </label>
        <Frow>{typeOptions}</Frow>
      </Column>
      {additionalAttributeJSX}
      <Column>
        <label className="form__label" htmlFor="">
          Size
        </label>
        <Frow>{sizeOptions}</Frow>
      </Column>
      {type === 'private_assets' ? (
        <Column>
          <label className="form__label" htmlFor="">
            Options
          </label>
          <Frow>
            <Column>
              <ReactSelect
                options={privateAssetOptions}
                value={selectedPrivateAssetOption}
                onChange={(option) => setCategory(option.value)}
              />
            </Column>
          </Frow>
        </Column>
      ) : null}
      {['allocation', 'currency'].includes(type) ? (
        <Column>
          <label className="form__label" htmlFor="">
            Options
          </label>
          <Frow>
            <Column>
              <div className="pretty pretty--large pretty--with-label p-round">
                <input
                  type="checkbox"
                  checked={includePrivateAssets}
                  onChange={(e) => setIncludePrivateAssets(e.target.checked)}
                />
                <div className="state p-blue-circle">
                  <label>Include Private Assets</label>
                </div>
              </div>
            </Column>
          </Frow>
        </Column>
      ) : null}
      <LegendKeys {...{ handleKeyChange, selectedKey }} />
    </Frow>
  );
};

export default pieChart;
