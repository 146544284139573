import * as React from 'react';

import Color from 'color';

export interface ITableProps {
  altTable?: boolean;
  autoColumns?: boolean;
  columnWidths?: any[];
  headers?: string[];
  rows: any[][];
  suffix?: any;
  theme: any;
  totalRow: any[];
}

const Table = (props: ITableProps) => {
  const {
    altTable,
    headers,
    rows,
    totalRow,
    autoColumns,
    columnWidths,
    suffix,
    theme: { primary_color },
  } = props;

  const primaryColor = Color(primary_color);

  const headersJSX = headers && headers.length && (
    <thead>
      <tr>
        {headers.map((header, index) => (
          <th style={{ verticalAlign: 'top' }} className={index === 0 ? '' : 'text-right'}>
            {header}
          </th>
        ))}
      </tr>
    </thead>
  );

  const cellWidth = (cells, index) =>
    index === 0 && !altTable
      ? '30%'
      : (columnWidths && columnWidths[index]) || autoColumns
      ? ''
      : `${70 / (cells.length - 1)}%`;

  const spanClassName = (cellData, index) =>
    index === 0 && !altTable
      ? 'pdf-table__row-label'
      : cellData && (+cellData < 0 || (typeof cellData === 'string' && cellData.match(/^-\d+(,\d{3})*(\.\d+)?%?/)))
      ? 'pdf-table__negative-value'
      : '';

  const formatCellValue = (cellData) => {
    if (cellData === '') {
      return null;
    } else if (Number.isNaN(+cellData)) {
      return cellData;
    } else {
      return (+cellData).toLocaleString('en');
    }
  };

  const generateCell =
    (rowCells, { tdStyle = {}, spanStyle = {} } = {}) =>
    (cellData, index) => (
      <td style={{ width: cellWidth(rowCells, index), ...tdStyle }} className={index === 0 ? '' : 'text-right'}>
        <span className={spanClassName(cellData, index)} style={spanStyle}>
          {index === 0 ? cellData : formatCellValue(cellData)}
        </span>
      </td>
    );

  const rowsJSX = rows.map((cells) => <tr>{cells.map(generateCell(cells))}</tr>);

  const totalRowJSX = totalRow && (
    <tr
      style={{
        borderTopColor: primaryColor.alpha(0.4).string(),
        borderTopStyle: 'solid',
        borderTopWidth: '2px',
        background: primaryColor.alpha(0.1).string(),
        color: primaryColor.darken(0.4).string(),
        fontWeight: 'bold',
      }}
    >
      {totalRow
        .map((value) => (value === null ? undefined : value))
        .map(
          generateCell(totalRow, {
            spanStyle: {},
          }),
        )}
    </tr>
  );

  return (
    <table className="pdf-table pdf-table--striped pdf-table--print">
      {headersJSX}
      <tbody>
        {rowsJSX}
        {totalRowJSX}
      </tbody>
    </table>
  );
};

export default Table;
