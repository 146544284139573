import * as React from 'react';

import CommentaryDisplay from './commentaries/CommentaryDisplay';
import New from './commentaries/New';

import { Commentary } from 'javascript/models';

export default function commentaries() {
  const [commentaries, setCommentaries] = React.useState([]);

  function handleSuccessfulFormSubmission() {
    getCommentaries();
  }

  React.useEffect(() => {
    getCommentaries();
  }, []);

  async function confirmDelete(commentary) {
    await commentary.destroy();
    getCommentaries();
  }

  async function getCommentaries() {
    const { data } = await Commentary.per(1000).where({ commentary_type: 'bespoke' }).all();

    setCommentaries(data);
  }

  const allCommentaries = commentaries.map((commentary) => {
    return <CommentaryDisplay {...{ commentary, confirmDelete, handleSuccessfulFormSubmission }} key={commentary.id} />;
  });

  return (
    <div className="frow frow--gutters">
      {allCommentaries}
      <New handleSuccessfulFormSubmission={handleSuccessfulFormSubmission} />
    </div>
  );
}
