import React, { createContext, useState } from 'react';
import { InvestmentRestrictionsSetting, Mandate } from 'javascript/models';

interface InvestmentRestrictionsContextInterface {
  investmentRestrictionsSetting: InvestmentRestrictionsSetting | null;
  setInvestmentRestrictionsSetting: (value: any) => void;
  cashAndFixedIncomeLocked: boolean;
  setCashAndFixedIncomeLocked: (value: boolean) => void;
}

type Props = {
  children: React.ReactNode;
  investmentRestrictionsSetting: InvestmentRestrictionsSetting;
  cashAndFixedIncomeLocked?: boolean;
};

export const InvestmentRestrictionsContext = createContext<InvestmentRestrictionsContextInterface>({
  investmentRestrictionsSetting: null,
  setInvestmentRestrictionsSetting: () => {},
  cashAndFixedIncomeLocked: false,
  setCashAndFixedIncomeLocked: () => {},
});

export const InvestmentRestrictionsProvider = (props: Props) => {
  const [investmentRestrictionsSetting, setInvestmentRestrictionsSetting] = useState<InvestmentRestrictionsSetting>(
    props.investmentRestrictionsSetting,
  );
  const [cashAndFixedIncomeLocked, setCashAndFixedIncomeLocked] = useState<boolean>(props.cashAndFixedIncomeLocked);

  return (
    <InvestmentRestrictionsContext.Provider
      value={{
        investmentRestrictionsSetting,
        setInvestmentRestrictionsSetting,
        cashAndFixedIncomeLocked,
        setCashAndFixedIncomeLocked,
      }}
    >
      {props.children}
    </InvestmentRestrictionsContext.Provider>
  );
};
