import * as React from 'react';

import Graph from './Graph';
import Numbers from './Numbers';

const routes = require('../../../../javascript/application/routes');

interface IProps {
  isLoading: boolean;
  meta: {
    stats: {
      total: {
        awaiting_data_count: number;
        error_count: number;
        data_received_count: number;
        count: number;
      };
    };
  };
  selectedDate: string;
}

export default function presenter(props: IProps) {
  const { isLoading, meta, selectedDate } = props;

  const button = (
    <div className="pad-t-2">
      <a
        href={routes.manager_portal_reviews_path({ date: selectedDate })}
        className="button button--secondary-large button--full-width"
      >
        More Details
      </a>
    </div>
  );

  return (
    <section className="platform-panel mar-b-0">
      <header className="platform-panel__header">
        <h3 className="platform-panel__title">Overview</h3>
      </header>
      <div className="platform-content platform-content--border-bottom">
        <Graph {...{ isLoading, meta }} />
      </div>
      <div className="platform-content platform-content--padding-all">
        <Numbers {...{ isLoading, meta }} />
        {!isLoading && button}
      </div>
    </section>
  );
}
