import * as React from 'react';

import Context from './Context';
import FormRow from 'components/shared/forms/FormRow';
import StandaloneDropzone from 'components/shared/forms/StandaloneDropzone';
import DocumentUploader from './DocumentUploader';

export default function documents() {
  const context = React.useContext(Context);
  const { portfolio } = context.state;
  const { latestLetterOfEngagement, latestLetterOfSuitability, latestInvestmentObjectiveEvaluation } = context.props;

  const { applicationFormPath, riskProfilerPath } = portfolio;

  const appFormLink = (
    <p className="text-white mar-t-0">
      View the current application form{' '}
      <a href={applicationFormPath} target="_blank" className="link link--secondary">
        here
      </a>
    </p>
  );

  const riskProfileLink = (
    <p className="text-white mar-t-0">
      View the risk profiler{' '}
      <a href={riskProfilerPath} target="_blank" className="link link--secondary">
        here
      </a>
    </p>
  );

  return (
    <>
      <h4 className="form__fieldset-label mar-b-0">Portfolio Documents</h4>
      <p className="helper-text text-white mar-v-1">Any attachments uploaded here will overwrite existing documents</p>
      <div className="mar-b-1">
        <FormRow>
          <label htmlFor="mandate" className="form__label">
            Portfolio Application Form
          </label>
          {applicationFormPath && appFormLink}
          <StandaloneDropzone name="portfolio[monitoring_application_form]" theme="dark" />
        </FormRow>
      </div>

      <fieldset className="form__fieldset">
        <FormRow className="mar-b-0">
          <label htmlFor="mandate" className="form__label">
            Risk Profiler
          </label>
          {riskProfilerPath && riskProfileLink}
          <StandaloneDropzone name="portfolio[risk_profiler]" theme="dark" />
        </FormRow>
      </fieldset>

      <DocumentUploader
        index={0}
        title="Letter of Engagement"
        documentType="letter_of_engagement"
        latestDocument={latestLetterOfEngagement}
      />
      <DocumentUploader
        index={1}
        title="Suitability Letter"
        documentType="suitability_letter"
        latestDocument={latestLetterOfSuitability}
      />
      <DocumentUploader
        index={2}
        title="Investment Objective Evaluation"
        documentType="investment_objective_evaluation"
        latestDocument={latestInvestmentObjectiveEvaluation}
      />
    </>
  );
}
