import * as React from 'react';
import * as $ from 'jquery';
import turbolinks from 'turbolinks';

import lodashCamelcase from 'lodash.camelcase';

import {
  editPlatformPortfolioPath,
  platformPortfolioPath,
  platformPortfoliosPath,
  platformPortfolioStatusesPath,
} from 'javascript/application/ts_routes';

import Benchmarks from './Benchmarks';
import Confirm from './Confirm';
import Documents from './Documents';
import ExtraSettings from './ExtraSettings';
import Information from './Information';
import InvestmentRestrictions from '../../../shared/InvestmentRestrictions';
import Mandate from './Mandate';
import Nav from './Nav';
import Service from './Service';
import Submitting from './Submitting';

import Alert from 'components/shared/Alert';
import Button from 'components/shared/forms/Button';
import Context from './Context';
import CsrfToken from 'components/shared/CsrfToken';
import Loading from 'components/shared/Loading';
import { ajaxErrorString } from 'components/shared/Utils';
import useVisibility from 'components/shared/customHooks/useVisibility';

export default function presenter() {
  const context = React.useContext(Context);
  const { portfolio, selectedTab, tabs } = context.state;
  const { action, portfolioId } = context.props;

  const [errorMessage, setErrorMessage] = React.useState('');
  const [eventTrigger, setEventTrigger] = React.useState();
  const [formEvent, setFormEvent] = React.useState();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isPublishing, setIsPublishing] = React.useState(false);

  const portfolioMandate = portfolio && portfolio.portfolioMandate;
  const investmentRestrictionsSetting = portfolioMandate && portfolioMandate.investmentRestrictionsSetting;

  const alertableChanges = [
    'combined',
    'consolidated',
    'quant_only',
    'allocation_commentary',
    'performance_commentary',
    'rating_commentary',
    'holding_commentary',
  ];

  // This function decides if you have updated anything that will triggers the reviews to be reset
  function shouldAlert(form) {
    if (portfolio.status === 'draft') return false;
    // Anything you do on the benchmark or mandate pages requires reviews to be rerun
    if (selectedTab === 'Benchmarks' || selectedTab === 'Mandate') return true;

    const formData = new FormData(form);
    // This 'should' compare the thing in the form data with whatever is currently set on the portfolio
    // for each attribute in the array
    return alertableChanges.some((attribute) => {
      // getAll assumes there may be multiples of the same keys (e.g. the checkboxes where there is always
      // a hidden 0 value. If there is more than 1 value for the attribute we use the second value
      const formValueArray = formData.getAll(`portfolio[${attribute}]`);
      const formValue = formValueArray.length > 1 ? formValueArray[1] : formValueArray[0];
      if (!formValue) return false;

      // Because booleans are submitted as 0's or 1's we need to transform them before comparing
      const transformedFormValue = formValue === '0' ? false : formValue === '1' ? true : formValue;
      return transformedFormValue !== portfolio[lodashCamelcase(attribute)];
    });
  }

  const { isOpen: alertOpen, handleClose: handleAlertClose, handleOpen: handleAlertOpen } = useVisibility(false);

  function renderComponent() {
    switch (selectedTab) {
      case 'Documents':
        return <Documents />;
      case 'Benchmarks':
        return <Benchmarks />;
      case 'Mandate':
        return <Mandate />;
      case 'Investment Restrictions':
        return (
          <InvestmentRestrictions
            investmentRestrictionsSetting={investmentRestrictionsSetting}
            baseParam="portfolio[portfolio_mandate_attributes]"
            mandate={portfolioMandate}
          />
        );
      case 'Service':
        return <Service />;
      case 'Custom':
        return <ExtraSettings />;
      default:
        return <Information />;
    }
  }

  const formAction = action === 'update' ? platformPortfolioPath(portfolioId) : platformPortfoliosPath();

  const requiresConfirmation = !!portfolio && portfolio.alertOnUpdate;

  function handleFormSubmission(event) {
    const triggerEvent = $(document.activeElement)[0];
    setEventTrigger(triggerEvent);
    const form = event.target;
    setFormEvent(form);
    event.preventDefault();
    if (requiresConfirmation && shouldAlert(form)) {
      handleAlertOpen();
    } else {
      confirmSubmit(null, form, triggerEvent);
    }
  }

  function confirmSubmit(event, form = null, triggerEvent = null) {
    let submitForm = formEvent;
    let trigger = eventTrigger;
    if (triggerEvent) {
      trigger = triggerEvent;
    }
    if (form) {
      submitForm = form;
    }
    const formData = new FormData(submitForm);
    $.ajax({
      beforeSend: () => setIsSubmitting(true),
      complete: () => setIsSubmitting(false),
      contentType: false,
      data: formData,
      dataType: 'json',
      error: (jqXhr) => {
        setErrorMessage(ajaxErrorString(jqXhr));
        $('html, body').animate({ scrollTop: 0 }, 'fast');
      },
      processData: false,
      success: (response) => {
        if (trigger.name === 'next_action') {
          turbolinks.visit(editPlatformPortfolioPath(response.id, { tab: trigger.value }));
        } else {
          turbolinks.visit(platformPortfolioPath(response.id));
        }
      },
      type: submitForm.method,
      url: submitForm.action,
    });
  }

  const cancelPath = portfolioId ? platformPortfolioPath(portfolioId) : platformPortfoliosPath();

  let nextTabName = 'Information';
  const nextTab = tabs[tabs.map((tab) => tab.name).indexOf(selectedTab) + 1];
  if (nextTab) {
    nextTabName = nextTab.name;
  }

  function handlePublish(event) {
    event.preventDefault();
    setIsPublishing(true);
    event.target.submit();
  }

  function renderPublish() {
    if (!portfolio) return;

    if (portfolio.status === 'draft' && tabs.every((tab) => tab.complete)) {
      return (
        <form
          className="form"
          action={platformPortfolioStatusesPath(portfolioId)}
          method="POST"
          onSubmit={handlePublish}
        >
          <input type="hidden" name="_method" value="patch" />
          <input type="hidden" name="authenticity_token" value={CsrfToken()} />
          <Button
            buttonClasses={'button button--success button--compact'}
            text="Publish"
            submittingText="Publishing"
            isSubmitting={isPublishing}
          />
        </form>
      );
    }
  }

  const portfolioTitle = () => {
    if (portfolio && portfolio.id) {
      return `- ${portfolio.name ? `${portfolio.name} - ` : ''}${portfolio.reference}`;
    }
  };

  return (
    <section className="platform-panel">
      <header className="platform-panel__header">
        <div className="frow frow--justify-between frow--items-center">
          <div>
            <h3 className="platform-panel__title">
              {action === 'update' ? 'Edit' : 'New'} Portfolio {portfolioTitle()}
            </h3>
          </div>
          <div>{renderPublish()}</div>
        </div>
      </header>
      <Nav />
      <div className="platform-content platform-content--padding-all">
        <form className="form" action={formAction} method="POST" onSubmit={handleFormSubmission}>
          {action === 'update' && <input type="hidden" name="_method" value="patch" />}
          <input type="hidden" name="authenticity_token" value={CsrfToken()} />
          <Alert type="error" title="Error on submission" message={errorMessage} />
          <input type="hidden" name="portfolio[updated_at]" value={new Date().toISOString()} />
          {portfolio ? renderComponent() : <Loading />}
          <div className="frow frow--gutters">
            <div>
              <Button
                inputType="submit"
                isSubmitting={isSubmitting}
                submittingText="Saving"
                text="Save & next"
                name="next_action"
                value={nextTabName}
              />
            </div>
            <div>
              <Button inputType="submit" isSubmitting={isSubmitting} submittingText="Saving" text="Save & return" />
            </div>
          </div>
          <div className="mar-t-1">
            <a href={cancelPath} className="button button--outlined" type="button">
              Cancel
            </a>
          </div>
        </form>
        {requiresConfirmation && <Confirm {...{ alertOpen, handleAlertClose, confirmSubmit, isSubmitting }} />}
        <Submitting {...{ isSubmitting }} />
      </div>
    </section>
  );
}
