import React, { useState, useEffect } from 'react';

import { InvestmentRestrictionsSetting } from 'javascript/models';
import InvestmentRestrictions from 'components/shared/InvestmentRestrictions';
import Loading from 'components/shared/Loading';

type Props = {
  investmentRestrictionsSettingId?: string;
};

const InvestmentRestrictionsSettings = ({ investmentRestrictionsSettingId }: Props) => {
  const [investmentRestrictionsSetting, setInvestmentRestrictionsSetting] =
    useState<InvestmentRestrictionsSetting | null>(null);
  const [loading, setLoading] = useState(true);

  async function getInvestmentRestrictionSetting() {
    if (investmentRestrictionsSettingId) {
      const { data } = await InvestmentRestrictionsSetting.includes('flags').find(investmentRestrictionsSettingId);
      setInvestmentRestrictionsSetting(data);
    }

    setLoading(false);
  }

  useEffect(() => {
    getInvestmentRestrictionSetting();
  }, []);

  if (!investmentRestrictionsSetting && loading) return <Loading />;

  return <InvestmentRestrictions baseParam="client" investmentRestrictionsSetting={investmentRestrictionsSetting} />;
};

export default InvestmentRestrictionsSettings;
