import { Attr, Model, BelongsTo, HasMany } from 'spraypaint';

import { groupBy, prop } from 'ramda';

import { ApplicationRecord } from '../ApplicationRecord';
import { SecurityStateClassificationWeighting } from './StateClassificationWeighting';

type AllByTypeProps = {
  uiSelectable?: boolean;
  source?: string;
  type?: string;
};

@Model()
export class SecurityClassification extends ApplicationRecord {
  public static jsonapiType = 'securities/classifications';

  @Attr() public id: string;
  @Attr() public type: string;
  @Attr() public aliasId: string;
  @Attr() public name: string;
  @Attr() public source: string;

  @HasMany(SecurityStateClassificationWeighting)
  public StateClassificationWeighting: SecurityStateClassificationWeighting;
  @BelongsTo(SecurityClassification) public alias: SecurityClassification;
  @BelongsTo({ type: SecurityClassification, persist: false }) public rootAlias: SecurityClassification;

  public static async allByType(where: AllByTypeProps) {
    const { data: all } = await this.noLimit().where(where).all();
    return groupBy(prop('type'), all);
  }
}
