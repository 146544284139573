import * as React from 'react';

import useDebounce from 'components/shared/customHooks/useDebounce';

import OutsideClickHandler from 'react-outside-click-handler';

const classNames = require('classnames');
import turbolinks from 'turbolinks';

import Search from 'components/shared/Search';

import { Portfolio } from 'javascript/models';

export default function quickSearch() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const [isOpen, setIsOpen] = React.useState(false);
  const [portfolios, setPortfolios] = React.useState([]);

  const debouncedInputValue = useDebounce(inputValue, 300);

  function handleChange(input) {
    setInputValue(input);
  }

  function handleClose() {
    setIsOpen(false);
  }

  React.useEffect(() => {
    if (debouncedInputValue) {
      getPortfolios();
    } else {
      setPortfolios([]);
    }
  }, [debouncedInputValue]);

  React.useEffect(() => {
    setIsLoading(false);
    if (portfolios.length) {
      setIsOpen(true);
    }
  }, [portfolios]);

  async function getPortfolios() {
    const { data } = await Portfolio.where({ search: { match: debouncedInputValue } })
      .select(['id', 'reference', 'show_path'])
      .per(20)
      .all();

    setPortfolios(data);
  }

  function handleFocus() {
    if (portfolios.length) {
      setIsOpen(true);
    }
  }

  const dropdownOptions = portfolios.map((portfolio) => {
    function handleShowClick() {
      turbolinks.visit(portfolio.showPath);
    }
    return (
      <div className="dropdown__nav-link" key={portfolio.id} onClick={handleShowClick}>
        {portfolio.reference}
      </div>
    );
  });

  const dropdownClasses = classNames('dropdown', { 'dropdown--active': isOpen });

  return (
    <div className="mar-r-2">
      <div className={dropdownClasses}>
        <OutsideClickHandler onOutsideClick={handleClose}>
          <div className="dropdown__toggle">
            <Search
              handleChange={handleChange}
              handleFocus={handleFocus}
              hasClearText={false}
              isSearching={isLoading}
              modifier="focusable"
              placeholder="Quick Search"
            />
          </div>
          <div className="dropdown__content">
            <div className="dropdown__content-inner">{dropdownOptions}</div>
          </div>
        </OutsideClickHandler>
      </div>
    </div>
  );
}
