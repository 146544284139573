import * as React from 'react';
import * as numeral from 'numeral';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import useChartFilter from '../../../../shared/customHooks/useChartFilter';
import { purple } from '../../../../shared/colours';
import stateToggle from '../shared/stateToggle';
import { generateNumericTicks } from '../../../../shared/charting/utilities/ticks';

export const CustomTooltip = (props) => {
  const { active, label, payload } = props;

  if (active && payload) {
    const wrapperStyles = {
      background: 'rgba(39,42,49, 0.6)',
      cursor: 'pointer',
      padding: '12px 0',
      width: '300px',
    };

    const valueStyles = {
      color: 'white',
      fontSize: '13px',
      fontWeight: 'bold' as 'bold',
      margin: 0,
      textAlign: 'center' as any,
    };

    const labelStyles = {
      textAlign: 'center' as any,
    };

    return (
      <div style={wrapperStyles}>
        <p style={labelStyles} className="small-uppercase mar-t-0 text-white">
          {label}
        </p>
        <div>
          <div style={valueStyles} className="frow frow--items-center frow--gutters">
            <div className="col-mc-2-5 col-md-3-8 text-left">
              <span className="text-normal">Percent</span>
            </div>
            <div style={{ textAlign: 'right' }} className="col-mc-3-5 col-md-5-8">
              {payload[0].payload.pct}%
            </div>
            <div className="col-mc-2-5 col-md-3-8 text-left">
              <span className="text-normal">Value</span>
            </div>
            <div style={{ textAlign: 'right' }} className="col-mc-3-5 col-md-5-8">
              {payload[0].payload.value}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

const chart = ({ managers, filterState }) => {
  const [data, setData] = React.useState(managers);
  const tickFormatter = (tick) => `${numeral(tick).format('0')}%`;

  const [selectedElement, setSelectedElement] = filterState;

  const handleSelect = React.useCallback(
    (slice) => {
      stateToggle(setSelectedElement, false)(slice.id);
    },
    [selectedElement],
  );

  let ticks: number[] = [0, 25, 50, 75, 100];

  useChartFilter(data, setData, 'id', selectedElement);

  if (managers.length > 0) {
    const calcTickGap = ({ maxDataPoint }) => {
      if (maxDataPoint <= 5) return 1;
      if (maxDataPoint <= 10) return 2;
      if (maxDataPoint <= 25) return 5;
      if (maxDataPoint <= 50) return 10;
      return 20;
    };
    const maxYData = Math.max(...managers.map((manager) => manager.pct));
    ticks = generateNumericTicks({ tickArray: [0], minDataPoint: 0, maxDataPoint: maxYData, tickGap: calcTickGap });
  }

  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart width={500} height={500} data={data} margin={{ top: 5, right: 5, left: 10, bottom: 5 }} barSize={25}>
        <CartesianGrid stroke="#353741" strokeWidth="2" fill="#1A1A1A" />
        <XAxis dataKey="name" tick={false} />
        <YAxis
          type="number"
          tickFormatter={tickFormatter}
          tick={{ fill: '#b5b6bd', fontSize: '12px', fontWeight: 'bold' }}
          ticks={ticks}
          domain={[0, Math.max(...ticks)]}
        />
        <Tooltip content={<CustomTooltip />} cursor={{ fill: 'rgba(39,42,49, 0.6)' }} />
        <Bar dataKey="pct" fill={purple} onClick={handleSelect} cursor="pointer" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default chart;
