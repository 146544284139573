export const series1 = [
  {
    xAxis: 100,
    y: 200,
  },
];

export const series2 = [
  {
    xAxis: 200,
    y: 260,
  },
];

export const series3 = [
  {
    xAxis: 150,
    y: 120,
  },
];

export const series4 = [
  {
    xAxis: 60,
    y: 130,
  },
];

export const series5 = [
  {
    xAxis: 175,
    y: 250,
  },
];

export const series6 = [
  {
    xAxis: 30,
    y: 20,
  },
];

export const series7 = [
  {
    xAxis: 40,
    y: 70,
  },
];

export const series8 = [
  {
    xAxis: 5,
    y: 165,
  },
];

export const series9 = [
  {
    xAxis: 55,
    y: 65,
  },
];
