import * as React from 'react';

const classNames = require('classnames');

import lodashTruncate from 'lodash.truncate';

import Options from './Options';
import ReactSelect from 'components/shared/forms/ReactSelect';

import Context from '../../Context';
import { UploadTemplateSheetColumn } from 'javascript/models';
import { simpleFormat } from 'components/shared/Utils';

interface IProps {
  index: number;
  uploadTemplateSheetColumn: UploadTemplateSheetColumn;
  uploadTemplateSheetColumns: UploadTemplateSheetColumn[];
  handleDestroyColumn(columnId: string): void;
}

export default function templateSheetColumns(props: IProps) {
  const { handleDestroyColumn, index } = props;

  const context = React.useContext(Context);
  const { attributeOptions } = context.props;

  const [uploadTemplateSheetColumn, setUploadTemplateSheetColumn] = React.useState(props.uploadTemplateSheetColumn);

  React.useEffect(() => {
    setUploadTemplateSheetColumn(props.uploadTemplateSheetColumn);
  }, [props.uploadTemplateSheetColumn]);

  function handleDataFormatChange(format) {
    uploadTemplateSheetColumn.dataFormat = format;
    const updatedColumn = uploadTemplateSheetColumn.dup();
    setUploadTemplateSheetColumn(updatedColumn);
    updateColumn();
  }

  function handleValueChange(selectedOption) {
    uploadTemplateSheetColumn.value = selectedOption.value;
    const updatedColumn = uploadTemplateSheetColumn.dup();
    setUploadTemplateSheetColumn(updatedColumn);
    updateColumn();
  }

  function handleAsAtChange(selectedOption) {
    uploadTemplateSheetColumn.targetColumn = selectedOption.value;
    const updatedColumn = uploadTemplateSheetColumn.dup();
    setUploadTemplateSheetColumn(updatedColumn);
    updateColumn();
  }

  function toggleIgnore() {
    uploadTemplateSheetColumn.ignored = !uploadTemplateSheetColumn.ignored;
    const updatedColumn = uploadTemplateSheetColumn.dup();
    setUploadTemplateSheetColumn(updatedColumn);
    updateColumn();
  }

  async function updateColumn() {
    const success = await uploadTemplateSheetColumn.save();
  }

  const cardClasses = classNames('card', { 'card--disabled': uploadTemplateSheetColumn.ignored });

  let selectedLabel = '';
  let selectedOption = null;
  if (uploadTemplateSheetColumn.value) {
    selectedLabel = attributeOptions.find((option) => option.value === uploadTemplateSheetColumn.value).label;
    selectedOption = { value: uploadTemplateSheetColumn.value, label: selectedLabel };
  }

  const asAtColumns = ['portfolio_value_as_at', 'cash_in_as_at', 'cash_out_as_at'];

  function renderAsAt() {
    if (!asAtColumns.includes(uploadTemplateSheetColumn.value)) return;

    const columnOptions = props.uploadTemplateSheetColumns.map((column, index) => {
      return { value: index.toString(), label: column.columnName };
    });

    let selectedAsAtOption = null;
    if (!!uploadTemplateSheetColumn.targetColumn) {
      const selectedColumn = props.uploadTemplateSheetColumns[uploadTemplateSheetColumn.targetColumn];
      selectedAsAtOption = { value: selectedColumn.columnName, label: selectedColumn.columnName };
    }

    return (
      <ReactSelect
        id={`column[${index}]_as_at`}
        isDisabled={uploadTemplateSheetColumn.ignored}
        maxMenuHeight={200}
        name={`column[${index}]_as_at`}
        options={columnOptions}
        handleChange={handleAsAtChange}
        theme="dark"
        value={selectedAsAtOption}
      />
    );
  }

  return (
    <div>
      <div className={cardClasses} style={{ width: '250px', minHeight: '300px' }}>
        <div className="card__inner">
          <div className="platform-content platform-content--border-bottom-grey">
            <h4
              className="card__title card__title--small"
              style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
            >
              <span className="small-uppercase">Column {index + 1}</span> <br />
              {uploadTemplateSheetColumn.columnName} <br />
            </h4>
            <div className="mar-v-1">
              <ReactSelect
                id={`column[${index}]`}
                isDisabled={uploadTemplateSheetColumn.ignored}
                maxMenuHeight={200}
                name={`column[${index}]`}
                options={attributeOptions}
                handleChange={handleValueChange}
                theme="dark"
                value={selectedOption}
              />
            </div>
            {renderAsAt()}
            <Options {...{ handleDataFormatChange, handleDestroyColumn, toggleIgnore, uploadTemplateSheetColumn }} />
          </div>
        </div>
        <div className="card__inner pad-t-0">
          <table style={{ width: '100%' }}>
            <tbody>
              <tr>
                <td className="text-white text-small pad-a-1">
                  {uploadTemplateSheetColumn.firstRow
                    ? lodashTruncate(uploadTemplateSheetColumn.firstRow, { length: 25 })
                    : '-'}
                </td>
              </tr>
              <tr>
                <td className="text-white text-small pad-a-1">
                  {uploadTemplateSheetColumn.secondRow
                    ? lodashTruncate(uploadTemplateSheetColumn.secondRow, { length: 25 })
                    : '-'}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
