import * as React from 'react';

import { isLoading } from 'components/shared/HigherOrderComponents';

import { DataUpload } from 'javascript/models';

interface IProps {
  dataUpload: DataUpload;
  isLoading: boolean;
}

function fileDownload(props: IProps) {
  const { dataUpload } = props;

  return (
    <a href={dataUpload.downloadPath} className="link link--right-arrow" target="_blank">
      View File
    </a>
  );
}

export default isLoading()(fileDownload);
