import * as React from 'react';

import OutsideClickHandler from 'react-outside-click-handler';

import { pipe, prop, toLower } from 'ramda';
import { truncate } from 'lodash';
import { format, parse } from 'date-fns';

import { Note } from 'javascript/models';

import ThemeContext from 'components/shared/context/ThemeContext';

import { Column, Frow, ColumnContext } from 'components/frow';

import View from './body/View';
import Edit from './body/Edit';
import ActionsDropdown, { IStringMenuItemProps } from './ActionsDropdown';
import useRecurringRerender from '../customHooks/useRecurringRerender';

export interface IAction extends Omit<IStringMenuItemProps, 'onClick'> {
  action: (note: Note) => (event) => void;
}

interface IPresenterProps {
  note: Note;
  actions?: IAction[];
}

const Presenter = (props: IPresenterProps) => {
  const { note, actions = [] } = props;

  const theme = React.useContext(ThemeContext);

  const [editing, setEditing] = React.useState(!note.isPersisted);

  const editable = note.isPersisted && note.editable;

  const startEditingNote = React.useCallback(() => editable && setEditing(true), [editable]);
  const stopEditingNote = React.useCallback(() => setEditing(!note.isPersisted || false), [note]);

  const Component = !note.isPersisted || editing ? Edit : View;

  const localActions: IStringMenuItemProps[] = actions.map((action: IAction) => ({
    ...action,
    onClick: action.action(note),
  }));

  const actionNames = localActions.map(pipe(prop('text'), toLower));

  if (!('edit' in actionNames) && editable)
    localActions.push({ text: 'edit', onClick: () => note.editable && startEditingNote() });

  const noteCreatedAt = note.createdAt ? parse(note.createdAt) : new Date();

  const display = {
    additionalInfo: note.additionalInfo,
    authorName: truncate(note.author.fullName, { length: 12 }),
    className: `comment comment--${theme.classNameModifier}-themed`,
    creationTime: format(noteCreatedAt, 'DD MMM YYYY [at] HH:mm'),
    edited: note.hasBeenUpdated,
    showNoteActions: localActions.length > 0,
  };

  /*
   * Trigger a re-render near the start of every minute to update the created at time unless the note is persisted.
   */
  useRecurringRerender(60000, () => !note.isPersisted);

  React.useEffect(() => {
    const previousNoteSave = note.save;
    note.save = async function (...args) {
      const result = await previousNoteSave.bind(this)(...args);
      if (result) setEditing(false);
      return result;
    };
    note.save.bind(note);
  }, [note]);

  const columnContext = React.useContext(ColumnContext);

  return (
    <OutsideClickHandler onOutsideClick={stopEditingNote}>
      <Frow onDoubleClick={startEditingNote} style={{ width: '100%' }} gutterSize={1} className={display.className}>
        <ColumnContext.Provider value={{ ...columnContext, maxColumns: 12 }}>
          <Column columnSpan={2}>
            <Frow gutterSize={1} itemAlignment="center">
              <div className="user-icon mar-h-0" data-letter={display.authorName[0]} />
              <div className="text-small">{display.authorName}</div>
            </Frow>
            <p className="text-tiny mar-b-0">
              {display.creationTime} {display.edited ? 'Edited' : ''}
            </p>
          </Column>
          <Column columnSpan={display.showNoteActions ? 9 : 10}>
            <ColumnContext.Provider value={{ ...columnContext }}>
              <Component {...props} />
            </ColumnContext.Provider>
          </Column>
          {display.showNoteActions && <ActionsDropdown actions={localActions} />}
        </ColumnContext.Provider>
      </Frow>
    </OutsideClickHandler>
  );
};

export default Presenter;
