import * as React from 'react';

import Context from './shared/Context';

export interface IReportDesignIndexProps {
  canCreate: boolean;
  canEdit: boolean;
  canDestroy: boolean;
  currentUserId: number;
}

export default function index(props: IReportDesignIndexProps) {
  const contextValues = { props };
  return (
    <Context.Provider value={contextValues}>
      <p>Report Designs to go here</p>
    </Context.Provider>
  );
}
