import * as React from 'react';
import * as $ from 'jquery';

import lodashTruncate from 'lodash.truncate';
import * as ReactTooltip from 'react-tooltip';

import AssignTemplates from './reports/AssignTemplates';
import Button from 'components/shared/forms/Button';

const routes = require('../../../../../../../javascript/application/routes.js');

import Preview from './Preview';
import ReviewContext from './ReviewContext';
import Context from '../../../Context';

import IReportTemplates from 'components/interfaces/IReportTemplates';
import useVisibility from 'components/shared/customHooks/useVisibility';
import { createAlert } from 'components/shared/Utils';

export default function reports() {
  const context = React.useContext(Context);
  const reviewContext = React.useContext(ReviewContext);
  const { review } = reviewContext.props;
  const { canEdit, reviewTemplates } = review.attributes;
  const { companyId, portfolio } = context.props;
  const { handleReviewUpdate } = reviewContext.actions;
  const reviewStatus = reviewContext.props.review.attributes.status;

  const [isOpen, setIsOpen] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [reportTemplates, setReportTemplates] = React.useState([]);
  const { isOpen: isInlinePreviewOpen, toggleOpen } = useVisibility(false);

  React.useEffect(() => {
    if (!reportTemplates.length) return;

    setIsOpen(true);
    setIsSubmitting(false);
  }, [reportTemplates]);

  function handleOpen() {
    $.ajax({
      beforeSend: () => setIsSubmitting(true),
      cache: false,
      dataType: 'json',
      success: (response: IReportTemplates) => {
        let filteredReportTemplates = response.data.filter((reportTemplate) => {
          return !reportTemplate.attributes.consolidated;
        });
        if (portfolio.attributes.consolidated) {
          filteredReportTemplates = response.data;
        }
        if (filteredReportTemplates.length < 1) {
          createAlert('error', 'There are no published report templates', false);
          setIsSubmitting(false);
        }
        setReportTemplates(filteredReportTemplates);
      },
      type: 'GET',
      url: routes.platform_report_templates_path(),
    });
  }

  function handleClose() {
    setIsOpen(false);
  }

  function handleReportTemplateChange() {
    setIsOpen(false);
    handleReviewUpdate();
  }

  function handleSubmit(event) {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);
    $.ajax({
      contentType: false,
      data: formData,
      dataType: 'json',
      processData: false,
      success: () => handleReportTemplateChange(),
      type: 'patch',
      url: form.action,
    });
  }

  function renderTemplates() {
    if (!templates.length) return;

    const inlinePreview = (
      <div className="col-md-1-3 col-lg-1-4">
        <div className="card h-100">
          <div className="card__inner">
            <div className="frow frow--direction-column h-100">
              <p className="text-white text-large mar-t-0">All Reports</p>
              <div style={{ marginTop: 'auto', cursor: 'pointer' }} onClick={toggleOpen}>
                <span className="card__link">
                  Preview
                  <i className="icon-arrow-right-top icon-1x icon-fw mar-l-1" />
                </span>
              </div>
            </div>
          </div>
        </div>
        <Preview isOpen={isInlinePreviewOpen} {...{ toggleOpen }} />
      </div>
    );

    return (
      <div className="frow frow--gutters mar-t-2">
        {reviewStatus === 'verification' && inlinePreview}
        {templates}
      </div>
    );
  }

  const templates = reviewTemplates.data.map((reviewTemplate, index) => {
    const preview = (
      <div style={{ marginTop: 'auto' }}>
        <a
          href={routes.platform_portfolio_review_report_preview_path(portfolio.id, review.id, reviewTemplate.id)}
          target="_blank"
          className="card__link"
        >
          Generate Preview
          <i className="icon-arrow-right-top icon-1x icon-fw mar-l-1" />
        </a>
      </div>
    );

    return (
      <div className="col-md-1-3 col-lg-1-4" key={`${reviewTemplate.id}_${index}`}>
        <div className="card h-100">
          <div className="card__inner">
            <div className="frow frow--direction-column h-100">
              <div>
                <p className="text-white text-large mar-t-0">
                  {lodashTruncate(reviewTemplate.attributes.name, { length: 40 })}
                </p>
              </div>
              {canEdit && preview}
            </div>
          </div>
        </div>
      </div>
    );
  });

  const tooltipTitle = !canEdit ? 'You cannot edit templates at this stage' : '';

  return (
    <div
      className="platform-content platform-content--border-bottom platform-content--spacing-horizontal
      platform-content--padding-bottom"
    >
      <div className="frow frow--items-center frow--justify-between">
        <div className="col-md-3-5 col-lg-4-5">
          <p className="platform-panel__title">Reports</p>
        </div>
        <div className="col-md-2-5 col-lg-1-5 text-right">
          <div data-tip={tooltipTitle}>
            <Button
              buttonClasses="button--secondary"
              isDisabled={!canEdit}
              isSubmitting={isSubmitting}
              handleClick={handleOpen}
              hasIcon
              icon={'plus'}
              submittingText="Fetching Templates"
              text=" Add report Template"
            />
            <ReactTooltip effect="solid" />
          </div>
        </div>
      </div>
      {renderTemplates()}
      <AssignTemplates
        companyId={companyId}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        isOpen={isOpen}
        reviewId={review.id}
        reviewIds={reviewTemplates.data.map((template) => template.id)}
        reportTemplates={reportTemplates}
      />
    </div>
  );
}
