import * as React from 'react';

export default function consolidated() {
  return (
    <div>
      <ul className="pdf-list pdf-list--content">
        <li className="pdf-list__item">
          <span className="text-uppercase pdf-list__item-key" style={{ display: 'inline-block', width: '40%' }}>
            Reporting Inception
          </span>
          <span className="pdf-list__item-value">Value</span>
        </li>
        <li className="pdf-list__item">
          <span className="text-uppercase pdf-list__item-key" style={{ display: 'inline-block', width: '40%' }}>
            Account Name
          </span>
          <span className="pdf-list__item-value">Value</span>
        </li>
        <li className="pdf-list__item">
          <span className="text-uppercase pdf-list__item-key" style={{ display: 'inline-block', width: '40%' }}>
            Total Value
          </span>
          <span className="pdf-list__item-value">Value</span>
        </li>
        <li className="pdf-list__item">
          <span className="text-uppercase pdf-list__item-key" style={{ display: 'inline-block', width: '40%' }}>
            Benchmark Peer Group
          </span>
          <span className="pdf-list__item-value">Value</span>
        </li>
        <li className="pdf-list__item">
          <span className="text-uppercase pdf-list__item-key" style={{ display: 'inline-block', width: '40%' }}>
            Total Value previous Quarter
          </span>
          <span className="pdf-list__item-value">Value</span>
        </li>
        <li className="pdf-list__item">
          <span className="text-uppercase pdf-list__item-key" style={{ display: 'inline-block', width: '40%' }}>
            Total Value this Quarter
          </span>
          <span className="pdf-list__item-value">Value</span>
        </li>
      </ul>
    </div>
  );
}
