import * as React from 'react';

import Presenter from './show/Presenter';

import { DataUpload } from 'javascript/models';

interface IProps {
  currentUserId: number;
  dataUploadId: string;
  managerId: string;
  managerName: string;
}

export default function show(props: IProps) {
  const { currentUserId, dataUploadId, managerId, managerName } = props;

  const [dataUpload, setDataUpload] = React.useState();
  const [isInputDisabled, setIsInputDisabled] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    fetchData();
  }, []);

  function handleNewNote() {
    setIsLoading(true);
    fetchData();
  }

  async function handleReProcess() {
    setIsInputDisabled(true);
    dataUpload.updatedAt = new Date();
    dataUpload.processed = false;
    dataUpload.overwrite = true;

    const success = await dataUpload.save();
    if (success) {
      fetchData();
    }
    setIsInputDisabled(false);
  }

  async function fetchData() {
    const { data } = await DataUpload.includes({ notes: 'author' }).find(dataUploadId);

    setDataUpload(data);
    setIsLoading(false);

    if (data.processing) {
      setTimeout(() => {
        fetchData();
      }, 1000);
    }
  }

  return (
    <Presenter
      {...{
        currentUserId,
        dataUpload,
        dataUploadId,
        handleNewNote,
        handleReProcess,
        isInputDisabled,
        isLoading,
        managerId,
        managerName,
      }}
    />
  );
}
