import { Attr, HasMany, Model } from 'spraypaint';
import { ApplicationRecord } from './ApplicationRecord';

@Model()
export class UploadTemplateCondition extends ApplicationRecord {
  public static jsonapiType = 'upload_template_conditions';
  public static conditionOptions = [
    { label: 'Match Column', value: 'match_column' },
    { label: 'Match Condition', value: 'match_condition' },
    { label: 'Potentially Blank', value: 'potentially_blank' },
    { label: 'Change Date', value: 'move_date' },
  ];
  @Attr() public blankOption: string;
  @Attr() public blankText: string;
  @Attr() public dateChangeType: string;
  @Attr() public conditionType: string;
  @Attr() public row: string;
  @Attr() public matchType: string;
  @Attr() public matchOption: string;
  @Attr() public matchText: string;
  @Attr() public conditions: any;
  @Attr() public conditionableId: string;
  @Attr() public conditionableType: string;
}

@Model()
export class UploadTemplateSheetColumn extends ApplicationRecord {
  public static jsonapiType = 'upload_template_sheet_columns';
  @Attr() public columnName: string;
  @Attr() public dataFormat: string;
  @Attr() public firstRow: string;
  @Attr() public secondRow: string;
  @Attr() public ignored: boolean;
  @Attr() public uploadTemplateSheetId: string;
  @Attr() public value: string;
  @Attr() public targetColumn: number;
  @HasMany() public uploadTemplateConditions: UploadTemplateCondition[];
}

@Model()
export class UploadTemplateAdditionalAttribute extends ApplicationRecord {
  public static jsonapiType = 'upload_template_additional_attributes';
  @Attr() public uploadTemplateSheetId: string;
  @Attr() public row: string;
  @Attr() public rowValue: string;
  @HasMany() public uploadTemplateConditions: UploadTemplateCondition[];
}

@Model()
export class UploadTemplateSheet extends ApplicationRecord {
  public static jsonapiType = 'upload_template_sheets';
  @Attr() public name: string;
  @Attr() public uploadTemplateId: string;
  @Attr() public startRow: string;
  @HasMany() public uploadTemplateSheetColumns: UploadTemplateSheetColumn[];
  @HasMany() public uploadTemplateAdditionalAttributes: UploadTemplateAdditionalAttribute[];
}

@Model()
export class UploadTemplate extends ApplicationRecord {
  public static jsonapiType = 'upload_templates';
  @Attr() public allSheetsSame: boolean;
  @Attr() public exampleFile: any;
  @Attr() public exampleFileChanged: boolean;
  @Attr() public active: boolean;
  @Attr() public managerId: number;
  @Attr() public name: string;
  @Attr() public showPath: string;
  @HasMany() public uploadTemplateSheets: UploadTemplateSheet[];
}
