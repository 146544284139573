import * as React from 'react';

import GraphitiTableWrapper from 'components/shared/GraphitiTableWrapper';

import columns from './table/columns';

import { Client } from 'javascript/models';

export interface IGraphitiTableProps {
  hasHeader?: boolean;
  isExportable: boolean;
  newPath?: string;
  scope?: { [key: string]: string | { [key: string]: string } };
  title: string;
}

export default function graphitiTable(props: IGraphitiTableProps) {
  const { isExportable, newPath, title } = props;

  let scope = Client.selectExtra([
    'user_count',
    'portfolio_count',
    'completed_portfolio_review_count',
    'incomplete_portfolio_review_count',
    'client_type_icon',
  ]);

  if (props.scope) {
    scope = scope.where(props.scope);
  }

  return (
    <GraphitiTableWrapper
      scope={scope}
      columns={columns()}
      hasHeader
      isExportable={isExportable}
      isSearchable
      searchPlaceholder="Search by name or reference"
      newPath={newPath}
      newText="Create Client"
      title={title}
      identifier="clients:index"
    />
  );
}

graphitiTable.defaultProps = {
  hasHeader: true,
};
