import * as React from 'react';

import { camelCase, snakeCase } from 'lodash';
import { prop } from 'ramda';

import { SecurityClassification } from 'javascript/models';

import { useDefaultTheme } from 'components/shared/customHooks/useThemeColours';

import Asset from './tacticalAllocations/Asset';
import Context from '../Context';

interface ITacticalAllocationsProps {
  assetClasses: SecurityClassification[];
}

const TacticalAllocations = (props: ITacticalAllocationsProps) => {
  const { assetClasses } = props;

  const context = React.useContext(Context);
  const { portfolio } = context.state;
  const mandate = portfolio.portfolioMandate;

  const getColour = useDefaultTheme('assetClass');

  const rangeSliders = assetClasses.map(prop('name')).map((assetClass: string, index) => {
    const [minValue, maxValue] = ['Minimum', 'Maximum'].map((value) => mandate[`${camelCase(assetClass)}${value}`]);
    const color = getColour(assetClass);

    return (
      <Asset
        key={`${assetClass}-${index}`}
        assetClass={assetClass}
        backgroundColor={color}
        name={snakeCase(assetClass)}
        defaultMin={minValue}
        defaultMax={maxValue}
      />
    );
  });

  return (
    <fieldset className="form__fieldset">
      <h4 className="form__fieldset-label">Asset Allocation Tolerances</h4>
      {rangeSliders}
    </fieldset>
  );
};

export default TacticalAllocations;
