import * as React from 'react';

import { Scope } from 'spraypaint';

import { Holding } from 'javascript/models';

import GraphitiTableWrapper from 'components/shared/GraphitiTableWrapper';

import { platformPortfolioPath, platformSecurityPath } from 'javascript/application/ts_routes';

export interface IMissingLiveSecurityStatesTableProps {
  holdingScope?: Scope<Holding>;
}

const columns = [
  {
    Header: 'Portfolio Reference',
    Cell: (row) => <a href={platformPortfolioPath(row.original.holdable.id)}>{row.original.holdable.reference}</a>,
    id: 'holdableId',
  },
  {
    Header: 'Security',
    Cell: (row) => <a href={platformSecurityPath(row.original.security.id)}>{row.original.security.name}</a>,
    id: 'isin',
  },
  {
    Header: 'Date',
    Cell: (row) => <div>{row.original.valueDate}</div>,
    id: 'valueDate',
  },
];

const MissingLiveSecurityStatesTable = (props: IMissingLiveSecurityStatesTableProps) => {
  const { holdingScope } = props;

  const localHoldingScope = holdingScope || Holding.scope();

  const tableScope = React.useMemo(
    () => localHoldingScope.includes(['holdable', 'security']).holdingsWithoutLiveState(),
    [holdingScope],
  );

  return (
    <GraphitiTableWrapper
      customMeta={{ pages: Number.MAX_SAFE_INTEGER, total: NaN }}
      scope={tableScope}
      columns={columns}
      hasColumnSelector={false}
    />
  );
};

export default MissingLiveSecurityStatesTable;
