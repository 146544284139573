import * as React from 'react';
import * as $ from 'jquery';

import ReactLineChart from 'components/shared/charting/recharts/ReactLineChart';

import { props } from 'ramda';

import ReviewContext from './ReviewContext';
import Loading from 'components/shared/Loading';
import Context from '../../../Context';
import { useDefaultTheme } from 'components/shared/colours';

import { mapBenchmarkColourGenerator } from 'components/shared/colours/hacks/benchmarkColourGenerator';
import extractSeries from 'javascript/helpers/chartData/extractSeries';

const routes = require('../../../../../../../javascript/application/routes');

const graph = () => {
  const portfolioContext = React.useContext(Context);
  const context = React.useContext(ReviewContext);
  const { review } = context.props;
  const { id } = review;
  const [returnSeries, setReturnSeries] = React.useState<{ series?: any; label?: string }[]>([]);

  React.useEffect(() => {
    getReturnSeries();
  }, [id]);

  function getReturnSeries() {
    $.ajax({
      cache: false,
      dataType: 'json',
      success: (response) => setReturnSeries(response),
      type: 'GET',
      url: routes.platform_review_return_series_index_path(id),
    });
  }

  const data = returnSeries;
  if (!data.length) return <Loading height={'700px'} />;

  const maxData = Math.max(
    ...data.map((dataObjects) =>
      Math.max(...dataObjects.series.map((dataObject) => parseFloat(dataObject.value || 0))),
    ),
  );

  const minData = Math.min(
    ...data.map((dataObjects) =>
      Math.min(...dataObjects.series.map((dataObject) => parseFloat(dataObject.value || 0))),
    ),
  );

  const labelTypeMap = new Map(extractSeries(data).map(props(['label', 'type'])));
  const lineStyles = mapBenchmarkColourGenerator(useDefaultTheme, labelTypeMap);

  const lines = Array.from(labelTypeMap.keys()) as string[];
  return <ReactLineChart lines={lines} {...{ lineStyles, maxData, minData, data }} legend />;
};

export default graph;
