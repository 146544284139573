import * as React from 'react';

import classnames from 'classnames';
import { format, endOfMonth } from 'date-fns';
import lodashOrderby from 'lodash.orderby';
import lodashSortby from 'lodash.sortby';

interface IProps {
  selectedDate: Date;
  handleDateChange: (date: string) => void;
  dates: string[];
}

export default function referenceDateSelector(props: IProps) {
  const { selectedDate, handleDateChange, dates } = props;

  const stringToDate = (dateString: string) => new Date(dateString);

  const selectableReferenceDates = lodashOrderby(dates, stringToDate, 'desc').map((date) => {
    // toString both dates because JS doesn't evaluate date equality as expected.
    //  https://stackoverflow.com/questions/492994/compare-two-dates-with-javascript
    //  SO article recommeds getTime; however, we end up being 999ms out so using toString instead.

    const buttonClasses = classnames('button-group__button', {
      'button-group__button--active': new Date(date).toDateString() === selectedDate.toDateString(),
    });

    const selectReferenceDate = () => {
      handleDateChange(date);
    };

    return (
      <div className={buttonClasses} key={date.valueOf()} onClick={selectReferenceDate}>
        {format(date, 'MMM YYYY')}
      </div>
    );
  });

  return (
    <React.Fragment>
      <div className="platform-panel__content platform-panel__content--padded platform-panel__content--right-bordered">
        <div className="button-group button-group--vertical mar-t-2">
          <div>{selectableReferenceDates}</div>
        </div>
      </div>
    </React.Fragment>
  );
}
