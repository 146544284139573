import * as React from 'react';

import { useSpraypaintQueryFirst } from 'components/shared/customHooks/useSpraypaintQuery';
import Loading from 'components/shared/Loading';
import { Frow } from 'components/frow';

import { Client, Flag } from 'javascript/models';

import FlagsSelector from 'components/shared/classificationFlags/FlagsSelector';

interface IProps {
  client: { id: string };
}

const ClientFlags = (props: IProps) => {
  const {
    client: { id: clientId },
  } = props;

  const clientFlagsScope = React.useMemo(() => Client.includes({ flags: 'criterion' }).where({ id: clientId }), [
    clientId,
  ]);
  const { data: client } = useSpraypaintQueryFirst(clientFlagsScope);

  if (!client) return <Loading />;

  return (
    <>
      <Frow itemAlignment="start" className="mar-b-2">
        <h4 className="form__fieldset-label">Client Flags</h4>
      </Frow>
      <FlagsSelector flags={client.flags} namePrefix="client[flags_attributes]" />
    </>
  );
};

export default ClientFlags;
