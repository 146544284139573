import * as React from 'react';

const eventSetProperty = (model: Object, property: string, useCallback = true) => {
  const eventHandler = (event: React.SyntheticEvent) => {
    // target won't always have a value attribute.
    const target = event.target as EventTarget | { value: string | number | string[] };

    if (!('value' in target)) return console.warn('Expected event to have a value property.');

    model[property] = target.value;
  };

  return useCallback ? React.useCallback(eventHandler, [model, property]) : eventHandler;
};

export default eventSetProperty;
