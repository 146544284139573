import * as React from 'react';

import lodashStartCase from 'lodash.startcase';

import BooleanOption from 'components/shared/forms/BooleanOption';
import FormRow from 'components/shared/forms/FormRow';
import ReactSelect from 'components/shared/forms/ReactSelect';

import Aml from './fieldsets/Aml';
import Customer from './fieldsets/Customer';
import Legal from './fieldsets/Legal';
import Natural from './fieldsets/Natural';

import ISelectOption from 'components/interfaces/ISelectOption';
import IEntity from 'components/interfaces/IEntity';

interface IProps {
  administrators: ISelectOption[];
  amlRiskRatings: ISelectOption[];
  clientClassifications: ISelectOption[];
  contractOffices: ISelectOption[];
  entity: { data: IEntity };
  entityTypes: ISelectOption[];
  genders: ISelectOption[];
  occupations: ISelectOption[];
  regulators: ISelectOption[];
  serviceOffices: ISelectOption[];
  shortForm?: boolean;
  statuses: ISelectOption[];
  structureServiceTypes: ISelectOption[];
  subTypes: ISelectOption[];
  titles: ISelectOption[];
}

export default function presenter(props: IProps) {
  const {
    administrators,
    amlRiskRatings,
    clientClassifications,
    contractOffices,
    entity,
    entityTypes,
    genders,
    occupations,
    regulators,
    serviceOffices,
    shortForm,
    statuses,
    structureServiceTypes,
    subTypes,
    titles,
  } = props;

  const attributes = entity.data.attributes;

  const { alias, registrationService, subType, status, structureServiceType } = attributes;

  const [aml, setAml] = React.useState(attributes.amlChecks);
  const [customer, setCustomer] = React.useState(attributes.customer);

  function initialType() {
    if (attributes.entityType) {
      return { value: attributes.entityType, label: lodashStartCase(attributes.entityType) };
    }
    return null;
  }

  const [entityType, setEntityType] = React.useState(initialType());

  function handleEntitySelect(selectedOption) {
    setEntityType(selectedOption);
  }

  function handleAmlChange() {
    setAml(!aml);
  }

  function handleCustomerChange() {
    setCustomer(!customer);
  }

  function renderAttributes() {
    if (!entityType) return;

    if (entityType.value === 'legal_person' || entityType.value === 'legal_arrangement') {
      return <Legal {...{ entity, regulators, shortForm }} />;
    }
    if (entityType.value === 'natural_person') {
      return <Natural {...{ entity, genders, occupations, shortForm, titles }} />;
    }
  }

  const defaultStructureServiceType = structureServiceTypes.find((sst) => sst.value === structureServiceType);

  const customerCheck = (
    <FormRow>
      <label className="form__label">Customer?</label>
      <BooleanOption name="entity[customer]" changeHandler={handleCustomerChange} defaultValue={customer} />
    </FormRow>
  );

  const amlCheck = (
    <FormRow>
      <label htmlFor="entity_type" className="form__label">
        AML Checks?
      </label>
      <BooleanOption name="entity[aml_checks]" changeHandler={handleAmlChange} defaultValue={aml} />
    </FormRow>
  );

  const registrationServiceCheck = (
    <FormRow>
      <label htmlFor="entity_type" className="form__label">
        Registration Service?
      </label>
      <BooleanOption name="entity[registration_service]" defaultValue={registrationService} />
    </FormRow>
  );

  return (
    <React.Fragment>
      <fieldset className="form__fieldset">
        <h4 className="form__fieldset-label">Entity Information</h4>
        <div className="frow frow--gutters">
          <FormRow>
            <label htmlFor="entity_type" className="form__label">
              Entity Type
            </label>
            <ReactSelect
              name="entity[entity_type]"
              theme="dark"
              options={entityTypes}
              id="entity_type"
              isClearable
              handleChange={handleEntitySelect}
              value={entityType}
            />
          </FormRow>
          <FormRow>
            <label className="form__label">Alias</label>
            <input type="text" name="entity[alias]" defaultValue={alias} />
          </FormRow>
          <FormRow>
            <label htmlFor="entity_type" className="form__label">
              Structure Service Type
            </label>
            <ReactSelect
              name="entity[structure_service_type]"
              theme="dark"
              options={structureServiceTypes}
              id="structure_service_type"
              isClearable
              defaultValue={defaultStructureServiceType}
            />
          </FormRow>
          {!shortForm && customerCheck}
          {!shortForm && amlCheck}
          <FormRow>
            <label htmlFor="entity_type" className="form__label">
              Sub type
            </label>
            <ReactSelect
              name="entity[sub_type]"
              theme="dark"
              options={subTypes}
              id="entity_type"
              defaultValue={{ value: subType, label: lodashStartCase(subType) }}
            />
          </FormRow>
          {!shortForm && registrationServiceCheck}
          <FormRow>
            <label htmlFor="entity_type" className="form__label">
              Status
            </label>
            <ReactSelect
              name="entity[status]"
              theme="dark"
              options={statuses}
              id="entity_type"
              defaultValue={{ value: status, label: lodashStartCase(status) }}
            />
          </FormRow>
        </div>
      </fieldset>
      {renderAttributes()}
      {aml && !shortForm && <Aml {...{ amlRiskRatings, entity }} />}
      {customer && !shortForm && (
        <Customer {...{ administrators, clientClassifications, contractOffices, entity, serviceOffices }} />
      )}
    </React.Fragment>
  );
}
