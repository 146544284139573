import * as React from 'react';
import * as $ from 'jquery';

const classNames = require('classnames');
const numeral = require('numeral');

import BalanceMetric from './BalanceMetric';
import QuarterlyReturnMetric from './QuarterlyReturnMetric';
import OverallReturnMetric from './OverallReturnMetric';

import { platformPortfolioBalancesPath } from 'javascript/application/ts_routes';

import columns from './table/Columns';
import Table from 'components/shared/reactTable/Table';
import Loading from 'components/shared/Loading';
import Context from '../../Context';

interface IBalanceTableObject {
  date: string;
  month_return: number;
  overall: number;
  value: number;
  value_in: number;
  value_out: number;
}

export default function table({ selectedYear }) {
  const context = React.useContext(Context);
  const { id } = context.props.portfolio.attributes;

  const [isLoading, setIsLoading] = React.useState(true);
  const [data, setData] = React.useState<IBalanceTableObject[]>([]);
  const [lastQuarterReturn, setLastQuarterReturn] = React.useState();

  let isMounted = true;

  React.useEffect(() => {
    return () => (isMounted = false);
  }, []);

  function removeListener() {
    document.removeEventListener('portfolio:returns:update', getData);
  }

  React.useEffect(() => {
    document.addEventListener('turbolinks:load', removeListener);
    document.addEventListener('portfolio:returns:update', getData);
  }, []);

  React.useEffect(() => {
    getData();
  }, []);

  function getData() {
    $.ajax({
      complete: () => setIsLoading(false),
      dataType: 'json',
      success: (response) => {
        if (!isMounted) return;

        setData(response.table_object);
        setLastQuarterReturn(response.last_quarter_return);
      },
      type: 'get',
      url: platformPortfolioBalancesPath(id),
    });
  }

  if (isLoading)
    return (
      <div className="col-md-1-2">
        <Loading />
      </div>
    );

  function changeFromPrevious(current, previous, format = '0,0.0000', suffix = '%') {
    const change = current - previous;
    const metricClasses = classNames(
      'metric__change',
      { 'metric__change--positive': change > 0 },
      { 'metric__change--negative': change < 0 },
    );

    if (change > 0) {
      return (
        <div className={metricClasses}>
          <i className="icon-chevron-up icon-fw icon-0-5x mar-r-1" />
          {numeral(change).format(format)}
          {suffix}
        </div>
      );
    }
    return (
      <div className={metricClasses}>
        <i className="icon-chevron-down icon-fw icon-0-5x mar-r-1" />
        {numeral(Math.abs(change)).format(format)}
        {suffix}
      </div>
    );
  }

  return (
    <div className="col-md-1-2">
      <div className="frow frow--gutters">
        <div className="col-tn-1-3">
          <BalanceMetric {...{ data, changeFromPrevious, isLoading }} />
        </div>
        <div className="col-tn-1-3">
          <QuarterlyReturnMetric {...{ lastQuarterReturn, isLoading }} />
        </div>
        <div className="col-tn-1-3">
          <OverallReturnMetric {...{ selectedYear }} />
        </div>
      </div>
      <div className="mar-t-3">
        <div className="">
          <div>
            <Table
              className="-sticky-header"
              columns={columns()}
              data={data}
              hasColumnSelector={false}
              hasPagination={false}
              manual={false}
              meta={{ count: data.length }}
              pageSize={data.length}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
