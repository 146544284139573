import * as React from 'react';

import ISelectOption from 'components/interfaces/ISelectOption';
import IPortfolioBenchmark from 'components/interfaces/IPortfolioBenchmark';
import Presenter from './form/Presenter';

interface IProps {
  benchmarkTypes: ISelectOption[];
  currencies: ISelectOption[];
  risks: ISelectOption[];
  portfolioBenchmark: { data: IPortfolioBenchmark };
  componentBenchmarks: [];
}

const Form = (props: IProps) => <Presenter {...props} />;

export default Form;
