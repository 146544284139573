import * as React from 'react';
import { Frow } from 'components/frow';

interface ISectionProps {
  afterTitle?: JSX.Element;
  title: React.ReactNode;
  children: React.ReactNode;
}

const Section = (props: ISectionProps) => {
  const { afterTitle, title, children } = props;
  return (
    <section className="platform-panel">
      <header className="platform-panel__header">
        <Frow justifyContent="between" itemAlignment="center">
          <h3 className="platform-panel__title">{title}</h3>
          {afterTitle}
        </Frow>
      </header>
      <div className="platform-content platform-content--padding-all">{children}</div>
    </section>
  );
};
export default Section;
