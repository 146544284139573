// Adds an element to an array if it doesn't exist, or removes it if it does

interface Array<T> {
  toggle(element): Array<T>;
}

Array.prototype.toggle = function (element) {
  let newArray = [...this];

  const toggleEntry = (arr, toggleElement) =>
    arr.includes(toggleElement) ? arr.filter((arrayItem) => arrayItem !== toggleElement) : [...arr, toggleElement];

  if (Array.isArray(element))
    element.forEach((elem) => newArray.splice(0, newArray.length, ...toggleEntry(newArray, elem)));
  else newArray = toggleEntry(newArray, element);

  return newArray;
};
