import * as React from 'react';
import * as Mathjs from 'mathjs';

import ReactScrollableFeed from 'react-scrollable-feed';

import lodashFilter from 'lodash.filter';

export default function scratchPad({ currentScope, setCurrentScope }) {
  const [calculationInput, setCalculationInput] = React.useState('help');
  const [results, setResults] = React.useState<string[][]>([]);

  function changeHandler(event) {
    setCalculationInput(event.target.value);
  }

  function setScopeFunctions(): {} {
    const calculationScope = { ...currentScope };
    const combine2 =
      'combine2(percentage_p1, percentage_p2) = (p1 * (percentage_p1 /100) + p2 * (percentage_p2/100)) / (p1 + p2) * 100';
    const combine3 =
      'combine3(percentage_p1, percentage_p2, percentage_p3) = (p1 * (percentage_p1 /100) + p2 * (percentage_p2/100) + p3 * (percentage_p3/100)) / (p1 + p2 + p3) * 100';
    const combine4 =
      'combine4(percentage_p1, percentage_p2, percentage_p3) = (p1 * (percentage_p1 /100) + p2 * (percentage_p2/100) + p3 * (percentage_p3/100) + p4 * (percentage_p4/100)) / (p1 + p2 + p3 + p4) * 100';
    const percentage = 'percentage(value, total) = value/total * 100';

    Mathjs.evaluate(combine2, calculationScope);
    Mathjs.evaluate(combine3, calculationScope);
    Mathjs.evaluate(combine4, calculationScope);
    Mathjs.evaluate(percentage, calculationScope);
    return calculationScope;
  }

  function evaluateExpr() {
    if (calculationInput === 'clear') {
      setResults([]);
      setCalculationInput('');
    } else {
      const newResults = [...results];
      const newScope = { ...currentScope, ...setScopeFunctions() };
      let evaluatedExpr: string;
      try {
        evaluatedExpr = Mathjs.format(Mathjs.evaluate(calculationInput, newScope), { notation: 'fixed', precision: 2 });
      } catch (evalutationError) {
        evaluatedExpr = evalutationError.toString();
      }
      newResults.push([calculationInput, evaluatedExpr]);
      setResults(newResults);
      setCalculationInput('');

      const nonCellScope = Object.fromEntries(
        lodashFilter(Object.entries(newScope), (values: string[]) => {
          return values[0].charAt(0) !== 'r';
        }),
      );
      setCurrentScope({ ...nonCellScope, _: evaluatedExpr });
    }
  }

  React.useEffect(() => evaluateExpr(), []);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      evaluateExpr();
    }
  };

  const renderResults = () => {
    return results.map((results, index) => {
      return (
        <div key={index}>
          <div style={{ fontWeight: 'bold' }}>{results[0]}</div>
          <div className="pad-l-3 pad-t-1 pad-b-1">{results[1]}</div>
        </div>
      );
    });
  };

  return (
    <div className="mar-b-1">
      <h3 className="mar-t-0 mar-b-1">Calculation Scratch Pad</h3>
      <div className="card--light card--light-top-half card--max-height-scrolling">
        <ReactScrollableFeed {...{ forceScroll: true }}>{renderResults()}</ReactScrollableFeed>
      </div>
      <input
        type="text"
        value={calculationInput}
        onChange={changeHandler}
        onKeyDown={handleKeyDown}
        className="card--light card--light-input card--light-bottom-half"
      />
      <hr className="mar-t-2" style={{ borderBottom: '1px solid #DDD', borderWidth: '1px' }} />
    </div>
  );
}
