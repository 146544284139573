import { Attr, Model } from 'spraypaint';
import { ApplicationRecord } from './ApplicationRecord';

@Model()
export class PortfolioBenchmarkUpload extends ApplicationRecord {
  public static jsonapiType = 'portfolio_benchmark_uploads';
  @Attr() public administratorId: string;
  @Attr() public portfolioBenchmarkId: string;
  @Attr() public import: string;
  @Attr() public uploadType: string;
}
