import * as React from 'react';

const classNames = require('classnames');

import { useDropzone } from 'react-dropzone';
import { DirectUpload } from 'activestorage';

import uploading from './dropzone/uploading';
import uploadText from './dropzone/uploadText';
import Alert from '../Alert';
import { createAlert } from '../Utils';

interface IProps {
  acceptedFiles?: string;
  alert?: { type: 'info' | 'success' | 'error' | 'warning'; message: string; title: string };
  name: string;
  text?: any;
  theme?: 'light' | 'dark';
}

export default function standaloneDropzone(props: IProps) {
  const { acceptedFiles, name } = props;
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [files, setFiles] = React.useState([]);
  const [signedId, setSignedId] = React.useState();

  const onDrop = React.useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length !== 1) {
        createAlert('error', 'You can only upload one file', 1500);
        return;
      }

      setFiles(acceptedFiles);
      setIsSubmitting(true);
      const upload = new DirectUpload(acceptedFiles[0], '/rails/active_storage/direct_uploads');

      upload.create((error, blob) => {
        if (error) {
          // Dump out
        } else {
          setSignedId(blob.signed_id);
          setIsSubmitting(false);
        }
      });
    },
    [files],
  );

  function renderAlert() {
    if (!props.alert) return;

    return <Alert isCloseable modifier={'full-width'} {...props.alert} />;
  }

  function handleRemoveFile() {
    const updatedFiles = [...files];
    updatedFiles.splice(this, 1);
    setFiles(updatedFiles);
  }

  function renderUploadText() {
    if (isSubmitting) {
      return uploading(files.length, props.theme);
    }
    if (files.length) {
      return (
        <div className="frow frow--centered-column" style={{ height: '100%' }}>
          <p className="subtitle-one text-grey mar-v-0 text-normal">
            File uploaded. Drag and drop, or click to select a different attachment
          </p>
        </div>
      );
    }
    return uploadText();
  }

  function renderFiles() {
    const formattedFiles = files.map((file, index) => {
      return (
        <div key={index} className="text-small">
          {file.name} (
          <a className="link link--blue" onClick={handleRemoveFile.bind(index)}>
            remove
          </a>
          )
        </div>
      );
    });

    return <div className="frow frow--gutters mar-t-1">{formattedFiles}</div>;
  }

  const options = { onDrop, multiple: false };

  if (acceptedFiles) {
    options['accept'] = acceptedFiles;
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone(options);

  const dropzoneClasses = classNames(
    'dropzone',
    'h-100',
    { 'dropzone--dark': props.theme === 'dark' },
    { 'dropzone--dragging': isDragActive },
  );

  return (
    <div>
      <div {...getRootProps({ className: dropzoneClasses })}>
        <input {...getInputProps()} />
        {renderAlert()}
        {renderUploadText()}
      </div>
      {renderFiles()}
      {signedId && <input type="hidden" name={name} id={name} value={signedId} />}
    </div>
  );
}

standaloneDropzone.defaultProps = {
  theme: 'light',
};
