import { Model, BelongsTo, Attr } from 'spraypaint';

import { Administrator } from '../Administrator';
import { Permission } from '../Permission';

@Model()
export class AdministratorPermission extends Permission {
  @Attr public static jsonapiType = 'permissions/administrators';
  @Attr public permissionableId: number;
  @Attr public permissionType: string;
  @Attr public permissionGroup: string;
  @Attr public description: string;

  @BelongsTo() public administrator: Administrator;
}
