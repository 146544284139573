import * as React from 'react';

import { convertToPrecision, precisionTickFormatter } from 'components/shared/utilities/precisionFunctions';
import getTicks from './../../shared/utilities/getTicks';

const numeral = require('numeral');

import { Bar, BarChart, CartesianGrid, Legend, ReferenceArea, ReferenceLine, Text, XAxis, YAxis } from 'recharts';

export const CustomLegend = (props) => {
  const { payload } = props;

  const legends = payload.map((payloadItem, index) => {
    const lineStyles = {
      background: payloadItem.payload.fill,
      display: 'inline-block',
      height: '10px',
      width: '10px',
    };

    return (
      <span key={index} style={{ display: 'inline-block', breakInside: 'avoid', whiteSpace: 'nowrap' }}>
        <span style={lineStyles} className="mar-r-1" />
        <span className="mar-r-2" style={{ fontSize: props.print ? '1.26rem' : '0.7rem', whiteSpace: 'nowrap' }}>
          {payloadItem.dataKey}
        </span>
      </span>
    );
  });

  return (
    <div
      style={{ border: '1px solid #e4e4e8', padding: props.print ? '3px 0 9px' : '0 0 5px', textAlign: 'center' }}
      className="mar-b-1"
    >
      {legends}
    </div>
  );
};

const CustomHorizontalLegend = (props) => {
  const { payload } = props;

  const legends = payload.map((payloadItem, index) => {
    const lineStyles = {
      background: payloadItem.payload.fill,
      borderColor: payloadItem.payload.fill,
      borderRadius: '50%',
      borderStyle: 'solid',
      display: 'inline-block',
      height: '10px',
      marginBottom: '-2px',
      width: '10px',
    };

    if (payloadItem.payload.strokeDasharray) {
      lineStyles.borderStyle = 'dashed';
    }

    return (
      <div key={index} style={{ display: 'inline-block', breakInside: 'avoid', whiteSpace: 'nowrap' }}>
        <span style={lineStyles} className="mar-r-1" />
        <span className="mar-r-2" style={{ fontSize: props.print ? '1.26rem' : '0.7rem' }}>
          {payloadItem.dataKey}
        </span>
      </div>
    );
  });

  return (
    <div className="mar-l-4" style={{ border: '1px solid #e4e4e8', padding: props.print ? '3px 5px 9px' : '0 0 5px' }}>
      {legends}
    </div>
  );
};

const CustomizedNoDataLabel = (props) => {
  const { height, width, x, y } = props.viewBox;
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={width / 2} y={height / 2} textAnchor="middle" fill="#000" fontWeight="bold" fontSize="20px">
        Insufficient Data
      </text>
    </g>
  );
};

interface IProps {
  barSize: number;
  benchmarkTypes: any;
  format: string;
  templateSection: any;
  theme: any;
  width: number;
  height: number;
  interval: number;
  pticks: number[];
  verticalLegend: boolean;
}

const CustomTick = (props) => {
  const { barWidth, data, height, x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`} viewBox="0 0 10 10">
      <Text
        x={0}
        y={0}
        dy={height - 5}
        textAnchor="middle"
        fill="#b5b6bd"
        fontSize="12px"
        fontWeight="bold"
        width={barWidth / data.length - 10}
      >
        {payload.value}
      </Text>
    </g>
  );
};

import { chartColorsV2 } from './chartColors';

export default function barCharts(props: IProps) {
  const {
    barSize,
    benchmarkTypes,
    format,
    templateSection,
    theme,
    width,
    height,
    interval,
    pticks,
    verticalLegend,
  } = props;

  const chartColors = chartColorsV2();

  const showLabels = templateSection.show_labels;

  let bars = [];
  if (templateSection.data.length > 0) {
    bars = templateSection.data[0].series.map((data) => data.label);
  }

  const numbers = [];

  let data = [];

  // maps through data from props and converts to object with data value and data name. Also pushes values into data
  //  array
  if (templateSection.data.length > 0) {
    data = templateSection.data.map((dataPoint) => {
      const dataObject = { name: dataPoint.name };
      dataPoint.series.forEach((data) => {
        numbers.push(data.value);
        dataObject[data.label] = parseFloat(data.value).toFixed(1);
      });
      return dataObject;
    });
  }

  const maxData = Math.max(...numbers);
  const minData = Math.min(...numbers);
  const tickPoint = convertToPrecision((maxData - minData) / 4, 2);

  let ticks = getTicks({ min: minData, max: maxData, tickGap: tickPoint });

  if (pticks) {
    ticks = pticks;
  }

  const tickFormatter = precisionTickFormatter(ticks);

  function renderLegend() {
    if (verticalLegend) {
      return (
        <Legend
          content={<CustomHorizontalLegend print={print} height={height} />}
          iconType="line"
          layout="vertical"
          verticalAlign="top"
          align="right"
        />
      );
    }
    return <Legend content={<CustomLegend print={print} />} iconType="line" wrapperStyle={{ bottom: '-20px' }} />;
  }

  const chartBars = bars.map((dataPoint, index) => {
    return (
      <Bar
        key={dataPoint}
        isAnimationActive={false}
        barSize={barSize}
        dataKey={dataPoint}
        fill={chartColors(dataPoint, theme, benchmarkTypes, index)}
        label={showLabels ? { position: 'top', fontSize: '9px', textAnchor: 'start' } : false}
      />
    );
  });

  const noDataOverlay = (
    <ReferenceArea
      fill="#f6f6f8"
      x1={0}
      x2={1}
      y1={0}
      y2={1.2}
      alwaysShow
      isFront
      label={<CustomizedNoDataLabel />}
    />
  );

  const noData = templateSection.data.length < 1;

  return (
    <BarChart
      width={width}
      height={height}
      data={data}
      margin={{ top: showLabels ? 12 : 5, right: 15, left: 0, bottom: 0 }}
    >
      {<CartesianGrid stroke="#d7d7dd" vertical={false} />}
      {noData && noDataOverlay}
      <XAxis
        dataKey="name"
        interval={interval}
        tick={noData ? false : <CustomTick {...{ data }} barWidth={width} />}
        tickSize={0}
        tickMargin={8}
        type={noData ? 'number' : 'category'}
      />
      <YAxis
        interval={0}
        tick={{ fill: '#b5b6bd', fontSize: '12px', fontWeight: 'bold' }}
        ticks={noData ? null : ticks}
        tickSize={0}
        tickMargin={5}
        tickFormatter={tickFormatter}
        domain={noData ? [0, 1.2] : [parseFloat(ticks[0].toString()), parseFloat(ticks[ticks.length - 1].toString())]}
        type="number"
        unit="%"
      />
      <ReferenceLine y={0} stroke="#000" />
      {!noData && chartBars}
      {!noData && renderLegend()}
    </BarChart>
  );
}

barCharts.defaultProps = {
  barSize: 10,
  format: '0,0',
  height: 270,
  interval: 0,
  verticalLegend: false,
};
