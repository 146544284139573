import React from 'react';
import InvestmentRestrictionsTable from 'components/shared/investmentRestrictionsTable';
import ClientPortalCard from 'components/portal/shared/ClientPortalCard';
import { format } from 'date-fns';

export default function ComplianceTable({ reviewID, currentReviewPeriod }) {
  return (
    <div className="col-md-2-2">
      <ClientPortalCard title="Mandate compliance table" subTitle={format(currentReviewPeriod, "DD MMM 'YY")}>
        <div className="flex flex-col grow">
          <InvestmentRestrictionsTable reviewId={reviewID} />
        </div>
      </ClientPortalCard>
    </div>
  );
}
