import * as React from 'react';

import { update } from 'lodash';

import Actions from './index/Actions';
import columns from './index/columns';
import GraphitiTableWrapper from 'components/shared/GraphitiTableWrapper';
import QueueSelect from './index/QueueSelect';
import { Review } from 'javascript/models';
import ISelectOption from 'components/interfaces/ISelectOption';

interface IProps {
  administrators: { display: string; value: number }[];
  canBulkMove: boolean;
  canVerify: boolean;
  clientTypes: string[];
  companyId: number;
  filters: any;
  reviewFlags: ISelectOption[];
  isExportable: boolean;
  portfolioStatus: string;
  startingQueue: string;
  statuses: string[];
  quarters: { display: string; value: string }[];
  risks: string[];
  reviewTransitions: string[];
}

export default function index(props: IProps) {
  const {
    administrators,
    canBulkMove,
    canVerify,
    clientTypes,
    companyId,
    filters,
    startingQueue,
    statuses,
    quarters,
    reviewFlags,
    risks,
    reviewTransitions,
  } = props;

  const [additionalFilters, setAdditionalFilters] = React.useState(assignAdditionalFilters());
  const [selection, setSelection] = React.useState([]);
  const [selectedQueue, setSelectedQueue] = React.useState(startingQueue || '*');
  const [shouldUpdateTable, setShouldUpdateTable] = React.useState(false);
  const [portfolioStatus, setPortfolioStatus] = React.useState(props.portfolioStatus || '*');
  const [shouldResetSelection, setShouldResetSelection] = React.useState(false);

  React.useEffect(() => {
    if (shouldResetSelection) {
      setShouldResetSelection(false);
    }
  }, [shouldResetSelection]);

  React.useEffect(() => {
    if (shouldUpdateTable) {
      setShouldUpdateTable(false);
    }
  }, [shouldUpdateTable]);

  function handleSubOptionUpdate(option) {
    setSelectedQueue('awaiting_data');
    setPortfolioStatus(option);
    setShouldUpdateTable(true);
  }

  const handleReviewsUpdated = React.useCallback(() => {
    setShouldResetSelection(true);
    setShouldUpdateTable(true);
  }, []);

  function updateSelectedQueue(selectedOption) {
    setShouldResetSelection(true);
    setPortfolioStatus('*');
    setSelectedQueue(selectedOption);
  }

  React.useEffect(() => {
    setAdditionalFilters(assignAdditionalFilters());
  }, [portfolioStatus, selectedQueue]);

  function assignAdditionalFilters() {
    return {
      portfolio_status: portfolioStatus === '*' ? ['*'] : [portfolioStatus],
      status: selectedQueue === '*' ? statuses : [selectedQueue],
    };
  }

  const queueOptions = [
    { label: 'All', value: '*' },
    {
      label: 'Awaiting Data',
      subOptions: [
        { label: 'Live', value: 'live' },
        { label: 'Component', value: 'component_part' },
        { label: 'Loan', value: 'loan' },
      ],
      value: 'awaiting_data',
    },
    { label: 'In Progress', value: 'in_progress' },
    { label: 'In Review', value: 'in_review' },
    { label: 'Verification', value: 'verification' },
    { label: 'Ready to Send', value: 'ready_to_send' },
    { label: 'Sent', value: 'sent' },
    { label: 'Terminated', value: 'terminated' },
    { label: 'On Hold', value: 'on_hold' },
    { label: 'No Review', value: 'no_review' },
  ];

  function handleUpdatedSelection(selection) {
    setSelection(selection);
  }

  const extraSelects = [
    'client',
    'client_type_icon',
    'currency',
    'display_status',
    'entity_name',
    'flag_list',
    'manager_name',
    'portfolio_id',
    'reference',
    'risk',
  ];

  const scope = React.useMemo(() => {
    let scope = Review.select({
      portfolios: [],
      reviews: ['due_date', 'end_date', 'enriched_holdings', 'reviewable_id', 'status'],
    })
      .selectExtra(extraSelects)
      .includes([
        'last_review_transition',
        'current_owner',
        'previous_owner',
        { reviewable: { childPortfolios: 'reviews' } },
      ])
      .where({ active: true });

    if (selectedQueue !== '*') {
      scope = scope.where({ status: selectedQueue });
    }
    if (portfolioStatus) {
      scope = scope.where({ portfolio_status: portfolioStatus });
    }

    return scope;
  }, [selectedQueue, portfolioStatus]);

  const stringValueAdministrators = React.useMemo(
    () => administrators.map((obj) => update(obj, 'value', String)),
    [administrators],
  );

  const tableColumns = columns(
    stringValueAdministrators,
    clientTypes,
    companyId,
    filters,
    handleReviewsUpdated,
    quarters,
    reviewFlags,
    risks,
    reviewTransitions,
    selectedQueue,
  );

  return (
    <React.Fragment>
      <div className="frow frow--items-center frow--justify-between frow--gutters">
        <div>
          <QueueSelect
            {...{ handleSubOptionUpdate, selectedQueue, portfolioStatus, queueOptions, updateSelectedQueue }}
          />
        </div>
        <div>
          <Actions
            {...{ administrators, canBulkMove, canVerify, companyId, handleReviewsUpdated, selection, selectedQueue }}
          />
        </div>
      </div>
      <GraphitiTableWrapper
        additionalFilters={additionalFilters}
        defaultFilters={filters}
        scope={scope}
        columns={tableColumns}
        hasHeader
        handleUpdatedSelection={handleUpdatedSelection}
        isSearchable
        searchPlaceholder="By reference, client, entity or manager"
        selectable
        title="Reviews"
        resetSelected={shouldResetSelection}
        shouldUpdateTable={shouldUpdateTable}
        identifier={`reviews:index:${selectedQueue}`}
        initialSort={{ current_owner_id: 'asc', due_date: 'asc', reference: 'asc' }}
      />
    </React.Fragment>
  );
}
