import * as React from 'react';

import { format, getQuarter, getYear } from 'date-fns';
import { ActionPoint } from 'javascript/models';
import { portalClientPortfolioActionPointPath } from 'javascript/application/ts_routes';
import QuarterIndicator from 'components/shared/QuarterIndicator';

interface IProps {
  actionPoint: ActionPoint;
}

const ActionPointListItem = ({ actionPoint }: IProps) => {
  const {
    id,
    body,
    documents,
    notes,
    commentCount,
    documentCount,
    createdAt,
    firstIncludedDate,
    portfolio,
  } = actionPoint;

  return (
    <div className="col-mc-1-1">
      <div className="comment">
        <div
          className="platform-content platform-content--padding-bottom platform-content--border-bottom
              platform-content--spacing-bottom"
        >
          <div className="frow frow--no-wrap">
            <div className="col-mc-11-12">
              <p className="mar-v-0 text-small">{body}</p>
            </div>
            <div className="col-mc-1-12">
              <a
                href={portalClientPortfolioActionPointPath(portfolio.client_id, portfolio.id, id)}
                style={{ display: 'block' }}
                target="_blank"
                rel="noreferrer"
              >
                <div className="rt-tr-clickable-icon">
                  <i className="icon-arrow-right-top icon-0-8x" />
                </div>
              </a>
            </div>
          </div>
        </div>

        <div>
          <div className="frow frow--gutters frow--items-center">
            <div className="text-small" data-tip="Date Added" data-for="action-point-icon">
              <i style={{ marginRight: '5px' }} className="icon-history" />
              {format(createdAt, 'DD MMM YYYY')}
            </div>
            <div
              className="text-small frow frow--direction-row frow--items-center"
              data-tip="First Included"
              data-for="action-point-icon"
            >
              <QuarterIndicator currentQuarter={getQuarter(firstIncludedDate)} height="15px" />Q
              {getQuarter(firstIncludedDate)} {getYear(firstIncludedDate)}
            </div>
            <div className="text-small" data-tip="Comments" data-for="action-point-icon">
              <i className="far fa-comment" style={{ marginRight: '5px' }} />
              {commentCount}
            </div>
            <div className="text-small" data-tip="Attachments" data-for="action-point-icon">
              <i className="icon-document" style={{ marginRight: '5px' }} />
              {documentCount}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActionPointListItem;
