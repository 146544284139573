import * as React from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import { sanitize } from 'dompurify';
import { useHotkeys } from 'react-hotkeys-hook';
import { Note } from 'javascript/models';
import { Frow, Column } from 'components/frow';
import { Button } from '@blueprintjs/core';
import eventSetProperty from 'javascript/helpers/eventSetProperty';

interface IEditBodyProps {
  note: Note;
  onSave?: (note: Note) => void;
}

const EditBody = (props: IEditBodyProps) => {
  const { note, onSave } = props;

  const textareaRef = React.useRef<HTMLTextAreaElement>();

  const localOnSave = React.useCallback(async () => {
    const saveResult = await note.save({ with: ['actionPoints.id', 'author.id'], displayQueryResult: true });
    if (onSave) onSave(note);
    if (saveResult && textareaRef.current) textareaRef.current.value = '';
  }, [note, onSave]);

  useHotkeys(
    'ctrl+enter',
    () => {
      localOnSave();
    },
    {
      enableOnTags: ['TEXTAREA'],
      filter: (e) => document.activeElement === textareaRef.current,
    },
  );

  return (
    <Frow direction="column">
      <Column>
        <TextareaAutosize
          onChange={eventSetProperty(note, 'body')}
          rows={3}
          ref={textareaRef}
          placeholder="Enter note here."
          defaultValue={sanitize(note.body)}
        />
      </Column>
      <Column>
        <Frow justifyContent="end">
          <Button onClick={localOnSave}>COMMENT</Button>
        </Frow>
      </Column>
    </Frow>
  );
};

export default EditBody;
