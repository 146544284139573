import * as React from 'react';

import OutsideClickHandler from 'react-outside-click-handler';

import { format, parse } from 'date-fns';
import columns from './returns/columns';
import Loading from '../../../../shared/Loading';
import useVisibility from '../../../../shared/customHooks/useVisibility';

import { portalClientPortfolioReturnMetricsPath } from 'javascript/application/ts_routes';
import Table from '../../../../shared/reactTable/Table';
import { selectableComparisons } from './selectableComparisons';
import ClientPortalCard from '../../../shared/ClientPortalCard';
import Frow from '../../../../frow/Frow';
import useAjaxRequest from '../../../../shared/customHooks/useAjaxRequest';

export default function returns({ currentReviewPeriod, portfolioObject }) {
  const { client_id, benchmarkTypes, id } = portfolioObject;
  const [tableData, setTableData] = React.useState<{ data: any[] }>();
  const [selectedMetrics, setSelectedMetrics] = React.useState([
    'three_month',
    'year_to_date',
    'twelve_months',
    'overall_3_yr',
    'annualised_3_yr',
  ]);

  const [selectedComparisons, setSelectedComparisons] = React.useState<string[]>([
    ...(Object.values(benchmarkTypes) as string[]),
    'cash',
    'equity',
  ]);

  const { handleClose: handleMetricClose, isOpen: isMetricOpen, toggleOpen: toggleMetricOpen } = useVisibility(false);
  const {
    handleClose: handleComparisonClose,
    isOpen: isComparisonOpen,
    toggleOpen: toggleComparisonOpen,
  } = useVisibility(false);

  const path = portalClientPortfolioReturnMetricsPath(client_id, id);

  interface IRequestParams {
    metrics: string[];
    comparisons: string[];
    review_period: string;
  }

  const [getMetrics, isError, isLoading] = useAjaxRequest<IRequestParams, any>({
    method: 'GET',
    path,
    stateSetter: setTableData,
  });

  React.useEffect(() => {
    if (selectedComparisons.length > 0 && selectedMetrics.length > 0) {
      getMetrics({
        metrics: selectedMetrics,
        comparisons: selectedComparisons,
        review_period: currentReviewPeriod as string,
      });
    }
  }, [selectedMetrics, selectedComparisons, currentReviewPeriod]);

  function handleSelectedChange(metric) {
    setSelectedMetrics(selectedMetrics.toggle(metric.value));
  }

  function handleComparisonChange(comparison) {
    setSelectedComparisons(selectedComparisons.toggle(comparison.value));
  }

  const selectableMetrics = [
    { label: '3 Months', value: 'three_month' },
    { label: '6 Months', value: 'six_month' },
    { label: 'YTD', value: 'year_to_date' },
    { label: '12 Months', value: 'twelve_months' },
    { label: 'Last Year', value: 'previous_year' },
    { label: '2 Years Ago', value: 'two_years_ago' },
    { label: '3 Years Ago', value: 'three_years_ago' },
    { label: '4 Years Ago', value: 'four_years_ago' },
    { label: '5 Years Ago', value: 'five_years_ago' },
    { label: 'Annualised (3 years)', value: 'annualised_3_yr' },
    { label: 'Annualised (5 years)', value: 'annualised_5_yr' },
    { label: 'Annualised (inception)', value: 'annualised' },
    { label: 'Overall (3 years)', value: 'overall_3_yr' },
    { label: 'Overall (5 years)', value: 'overall_5_yr' },
    { label: 'Overall (inception)', value: 'overall' },
  ];

  const metricMenuItems = selectableMetrics.map((metric) => {
    function handleChange() {
      handleSelectedChange(metric);
    }
    return (
      <div key={metric.value} className="dropdown__nav-link">
        <div className="pretty p-icon">
          <input
            type="checkbox"
            value={metric.value}
            id="series"
            onChange={handleChange}
            checked={selectedMetrics.includes(metric.value)}
          />
          <div className="state p-blue">
            <i className="icon-tick icon" />
            <label>{metric.label}</label>
          </div>
        </div>
      </div>
    );
  });

  const metricsToggle = (
    <div className={`dropdown dropdown--floating dropdown--middle ${isMetricOpen && 'dropdown--active'}`}>
      <OutsideClickHandler onOutsideClick={handleMetricClose}>
        <div className="dropdown-toggle" onClick={toggleMetricOpen}>
          <div className="frow frow--items-center text-hoverable">
            <i className="icon-filter-list icon-0-9x mar-r-1 text-white" />
            <span className="text-small text-white">Metrics</span>
          </div>
        </div>
        <div className="dropdown__content dropdown__content--with-arrow" style={{ width: '250px', left: '-100%' }}>
          {metricMenuItems}
        </div>
      </OutsideClickHandler>
    </div>
  );

  const comparisonMenuItems = selectableComparisons(portfolioObject).map((comparison) => {
    function handleChange() {
      handleComparisonChange(comparison);
    }
    return (
      <div key={comparison.value} className="dropdown__nav-link">
        <div className="pretty p-icon">
          <input
            type="checkbox"
            value={comparison.value}
            id="series"
            onChange={handleChange}
            checked={selectedComparisons.includes(comparison.value)}
          />
          <div className="state p-blue">
            <i className="icon-tick icon" />
            <label>{comparison.label}</label>
          </div>
        </div>
      </div>
    );
  });

  const comparisonToggle = (
    <div className={`dropdown dropdown--floating dropdown--middle ${isComparisonOpen && 'dropdown--active'}`}>
      <OutsideClickHandler onOutsideClick={handleComparisonClose}>
        <div className="dropdown-toggle" onClick={toggleComparisonOpen}>
          <div className="frow frow--items-center text-hoverable">
            <i className="icon-filter-list icon-0-9x mar-r-1 text-white" />
            <span className="text-small text-white">Comparisons</span>
          </div>
        </div>
        <div className="dropdown__content dropdown__content--with-arrow" style={{ width: '250px', left: '-100%' }}>
          {comparisonMenuItems}
        </div>
      </OutsideClickHandler>
    </div>
  );

  function renderTable() {
    return (
      <div>
        <Table
          className="-wrap-headers-overflow"
          columns={columns(selectedMetrics)}
          data={tableData.data}
          hasColumnSelector={false}
          hasPagination={false}
          isLoading={false}
          manual={false}
          meta={{ count: tableData.data.length }}
        />
      </div>
    );
  }

  const titleControls = (
    <Frow itemAlignment="start" gutterSize={1}>
      {metricsToggle}
      {comparisonToggle}
    </Frow>
  );

  const cardProps = {
    title: 'Returns',
    subTitle: format(parse(currentReviewPeriod), "DD MMM 'YY"),
    titleControls,
  };

  return (
    <div className="col-mc-1-1">
      <ClientPortalCard {...cardProps}>{isLoading || isError ? <Loading /> : renderTable()}</ClientPortalCard>
    </div>
  );
}
