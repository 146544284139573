import * as React from 'react';

import { platformManagerUploadTemplatesPath } from 'javascript/application/ts_routes';

import Context from './Context';
import Template from './Template';
import FormTag from 'components/shared/forms/FormTag';

export default function presenter() {
  const context = React.useContext(Context);
  const { managerId } = context.props;
  const { uploadTemplates } = context.state;
  const { handleNewTemplate } = context.actions;

  const templates = uploadTemplates.map((template) => {
    return <Template key={template.id} uploadTemplate={template} />;
  });

  return (
    <div className="form">
      <div className="platform-panel">
        <header className="platform-panel__header">
          <h2 className="subtitle-one text-white">Upload Template</h2>
        </header>
        <div className="platform-content platform-content--padding-all">
          {templates}
          <button className="button button--secondary-large mar-b-2" onClick={handleNewTemplate}>
            Add Another File
          </button>
          <FormTag url={platformManagerUploadTemplatesPath(managerId)} method="patch">
            <div className="platform-content platform-content--padding-vertical platform-content--border-top">
              <button className="button button--full-width">Save Template</button>
            </div>
          </FormTag>
        </div>
      </div>
    </div>
  );
}
