import * as React from 'react';

import lodashTruncate from 'lodash.truncate';
import * as color from 'color';

import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from 'recharts';

const CustomLegend = (props) => {
  const { payload } = props;

  const legends = payload.map((payloadItem, index) => {
    const lineStyles = {
      background: payloadItem.payload.fill,
      borderRadius: '50%',
      display: 'inline-block',
      height: '10px',
      marginRight: '5px',
      width: '10px',
    };

    return (
      <span key={index} style={{ display: 'inline-block', breakInside: 'avoid', whiteSpace: 'nowrap', height: '30px' }}>
        <span style={lineStyles} />
        <span style={{ fontSize: '8px', marginRight: '10px' }}>{payloadItem.payload.name}</span>
      </span>
    );
  });

  return <div style={{ border: '1px solid #e4e4e8', padding: '10px', width: '300px' }}>{legends}</div>;
};

const CustomLabel = (props) => {
  const { labelSize } = props;
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, payload, index } = props;
  const { name } = payload;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  let rotation = -midAngle;
  if (x < cx) {
    rotation += 180;
  }
  return (
    <text
      x={x}
      y={y}
      transform={`rotate(${rotation},${x},${y})`}
      fill="white"
      textAnchor={'middle'}
      dominantBaseline="central"
      fontSize={labelSize}
    >
      {lodashTruncate(name, { length: 11, omission: '..' })}
    </text>
  );
};

interface IProps {
  dataArray: { name: string; value: number; color?: string; parent?: string }[][];
  height?: number;
  isResponsive?: boolean;
  labelSize?: number;
  width?: number;
}

export default function multiLayerPieChart(props: IProps) {
  const { dataArray, height, isResponsive, labelSize, width } = props;

  const legend = (
    <Legend layout="vertical" verticalAlign="top" align="right" content={<CustomLegend {...{ dataArray }} />} />
  );

  const cellWidth = height / 2 / dataArray.length;

  let innerRadius = 25;
  let outerRadius = cellWidth;

  const pies = dataArray.map((data, index) => {
    if (index > 0) {
      innerRadius = outerRadius;
      outerRadius += cellWidth;
    }

    const cells = data.map((entry, innerIndex) => {
      let fillColor = entry.color;
      if (!fillColor) {
        if (entry.parent && entry.parent !== 'Unknown') {
          const countSharedParent = data.filter((entryObject) => entryObject.parent === entry.parent).length;
          const countBefore = data.slice(0, innerIndex).filter((entryObject) => entryObject.parent === entry.parent)
            .length;
          let opacity = 0.8;
          if (countSharedParent > 1) {
            const opacityDegree = 1 / countSharedParent;
            opacity = opacityDegree * countBefore;
          }
          const parent = dataArray[0].find((parentObject) => parentObject.name === entry.parent);
          let parentColor = '#000';
          if (parent) {
            parentColor = parent.color;
          }
          const darkenedBaseColor = color(parentColor).darken(0.1);
          fillColor = color(darkenedBaseColor).mix(color(darkenedBaseColor).darken(0.2), opacity).string();
        }
      }
      return <Cell key={innerIndex} fill={fillColor} stroke="#fff" />;
    });

    return (
      <Pie
        key={index}
        nameKey="name"
        dataKey="value"
        isAnimationActive={false}
        {...{ data, innerRadius, outerRadius }}
        startAngle={90}
        endAngle={-270}
        label={<CustomLabel {...{ labelSize }} />}
        labelLine={false}
      >
        {cells}
      </Pie>
    );
  });

  if (isResponsive) {
    return (
      <ResponsiveContainer width="100%" height={height} className="text-huge">
        <PieChart>{pies}</PieChart>
      </ResponsiveContainer>
    );
  }

  return (
    <PieChart height={height} className="text-huge" width={width}>
      {pies}
    </PieChart>
  );
}

multiLayerPieChart.defaultProps = {
  height: 400,
  isResponsive: true,
  labelSize: 10,
  width: 400,
};
