import * as React from 'react';

import { startCase, snakeCase } from 'lodash';
import { applyTo, objOf, map, mergeAll } from 'ramda';

import { Flag, SecurityClassification } from 'javascript/models';

import RangeSlider from 'components/shared/forms/RangeSlider';
import ReactSelect from 'components/shared/forms/ReactSelect';

export interface IFlagSelectorProps {
  backgroundColorGenerator: (key: string) => string;
  criterionOptions: { [key: string]: SecurityClassification[] };
  flag: Flag;
  flagTypeMap: { [key: string]: string };
  index: number;
  namePrefix: string;
}

const FlagSelector = ({
  backgroundColorGenerator,
  criterionOptions,
  flag,
  flagTypeMap,
  index,
  namePrefix,
}: IFlagSelectorProps) => {
  const [isDeleted, setIsDeleted] = React.useState(false);
  const [selectedFlagType, setSelectedFlagType] = React.useState(flag.flagType);
  const [criterionValue, setCriterionValue] = React.useState(flag.criterion && flag.criterion.id);
  const [aggregateValue, setAggregateValue] = React.useState(flag.aggregate);

  const [min, setMin] = React.useState(flag.minimum);
  const [max, setMax] = React.useState(flag.maximum);

  const handleFlagOptionsChange = (selection) => {
    setSelectedFlagType(selection.value);
    setCriterionValue(null);
  };

  const handleCriterionChange = (selection) => {
    setCriterionValue(selection.value);
  };

  const handleAggregateChange = () => {
    setAggregateValue((previous) => !previous);
  };

  const handleDelete = () => {
    setIsDeleted(true);
  };

  const hiddenDeleteField = <input type="hidden" value="true" name={`${namePrefix}[${index}][_destroy]`} />;

  const deleteButton = (
    <div
      className="button button--secondary button--danger button--icon"
      onClick={handleDelete}
      style={{ margin: '6px 0' }}
    >
      <i className="icon-bin icon-fw" />
    </div>
  );

  const handleSlideEnd = ([newMin, newMax]) => {
    setMin(newMin);
    setMax(newMax);
  };

  const defaultValue = [min, max];
  const pushable = 0;

  const criterionSelectOptions = React.useMemo(
    () =>
      (selectedFlagType &&
        criterionOptions[flagTypeMap[selectedFlagType]].map((classification) => ({
          label: classification.name,
          value: classification.id,
        }))) ||
      [],
    [selectedFlagType, criterionOptions, flagTypeMap],
  );

  let selectedCriterion = null;
  if (criterionSelectOptions) {
    selectedCriterion = criterionSelectOptions.find((option) => option.value === criterionValue);
  }

  const hiddenIdField = <input type="hidden" name={`${namePrefix}[${index}][id]`} value={flag.id} />;

  const flagOptions = Object.getOwnPropertyNames(flagTypeMap).map((option) => ({
    label: startCase(option),
    value: snakeCase(option),
  }));

  const backgroundColor = React.useMemo(
    () => selectedCriterion && backgroundColorGenerator(selectedCriterion.label),
    [selectedCriterion],
  );

  return (
    <fieldset className={`form__fieldset pad-b-0 ${isDeleted && 'hidden'}`}>
      <div className="frow frow--gutters mar-b-1">
        <div className="col-md-2-5">
          <div className="mar-b-1 frow frow--items-center">
            <div className="col-md-1-5">
              <label htmlFor="" className="form__label">
                Flag Type
              </label>
            </div>
            <div className="col-md-4-5">
              <ReactSelect
                theme="dark"
                name={`${namePrefix}[${index}][flag_type]`}
                id="flag_type"
                options={flagOptions}
                defaultValue={{ label: startCase(selectedFlagType), value: selectedFlagType }}
                handleChange={handleFlagOptionsChange}
              />
            </div>
          </div>
          <div className="frow frow--items-center">
            <div className="col-md-1-5">
              <label htmlFor="" className="form__label">
                Criterion
              </label>
            </div>
            <div className="col-md-4-5">
              <ReactSelect
                theme="dark"
                name={`${namePrefix}[${index}][criterion_id]`}
                id="criterion"
                options={criterionSelectOptions}
                value={selectedCriterion || null}
                handleChange={handleCriterionChange}
              />
            </div>
          </div>
          <div className="frow mar-t-1">
            <div className="col-md-1-5" />
            <div className="pretty p-icon">
              <input
                type="checkbox"
                name={`${namePrefix}[${index}][aggregate]`}
                value="1"
                checked={aggregateValue}
                onChange={() => handleAggregateChange()}
              />
              <div className="state p-blue">
                <i className="icon-tick icon icon-push-down-small" />
                <label className="text-white">Aggregate across type</label>
              </div>
            </div>
          </div>
        </div>
        <div className="frow col-md-3-5 frow--row-center frow--items-center">
          <div className="mar-b-3 col-md-9-12 mar-l-3">
            <RangeSlider
              {...{
                backgroundColor,
                defaultValue,
                handleSlideEnd,
                pushable,
              }}
              showValues
            />
          </div>
          <div className="col-md-1-12 pad-l-5">{deleteButton}</div>
        </div>
        {flag.id && hiddenIdField}
        <input type="hidden" name={`${namePrefix}[${index}][maximum]`} value={max} />
        <input type="hidden" name={`${namePrefix}[${index}][minimum]`} value={min} />
        <input type="hidden" name={`${namePrefix}[${index}][aggregate]`} value={String(aggregateValue)} />
        {isDeleted && hiddenDeleteField}
      </div>
    </fieldset>
  );
};

export default FlagSelector;
