import * as React from 'react';
import { Frow, Column } from 'components/frow';

export interface IFormRowProps {
  children: React.ReactNode;
  className?: string;
  note?: React.ReactNode;
}

const FormRow = ({ className, children, note }: IFormRowProps) => (
  <Column>
    <Frow gutterSize={1} itemAlignment="center" columnContext={{ maxColumns: 5 }}>
      <Column columnSpan={2}>{children}</Column>
      <Column columnSpan={3} className="text-small text-white">
        {note || ''}
      </Column>
    </Frow>
  </Column>
);

export default FormRow;
