import * as React from 'react';

const numeral = require('numeral');

import {
  CartesianGrid,
  Label,
  Legend,
  ReferenceArea,
  ReferenceLine,
  Scatter,
  ScatterChart,
  Surface,
  Symbols,
  XAxis,
  YAxis,
} from 'recharts';

const CustomYAxis = (props) => {
  const name = props.name || 'Axis Name';
  const height = props.height;
  const { x, y } = props.viewBox;
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={-(height / 2 - 20)}
        y={10}
        textAnchor="start"
        fill="#333"
        transform="rotate(-90)"
        fontWeight="bold"
        fontSize="12px"
      >
        {name.toLocaleUpperCase()}
      </text>
    </g>
  );
};

export const CustomXAxis = (props) => {
  const name = props.name || 'Axis Name';
  const nameLength = name.length;
  const { width, x, y } = props.viewBox;
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={width / 2 - (nameLength * 10) / 2}
        y={35}
        textAnchor="middle"
        fill="#333"
        fontWeight="bold"
        fontSize="12px"
      >
        {name.toUpperCase()}
      </text>
    </g>
  );
};

export const CustomLegend = (props) => {
  const { payload, templateSection } = props;

  const legends = payload.map((payloadItem, index) => {
    const legendTitle = payloadItem.payload.data[0].name;

    const { legendType, fill, stroke } = payloadItem.payload;

    const size = ['circle', 'square'].includes(legendType) ? 100 : 75;
    const height = legendType === 'diamond' ? 18 : 15;

    return (
      <div key={index} className="col-mc-1-1" style={{ marginBottom: '8px' }}>
        <div className="frow frow--items-center">
          <span className="mar-r-1">
            <Surface width={15} height={height}>
              <Symbols cx={8} cy={8} type={legendType} size={size} fill={fill} stroke={stroke} />
            </Surface>
          </span>
          <span style={{ fontSize: '1.26rem', whiteSpace: 'nowrap' }}>
            {legendTitle && templateSection ? legendTitle : `Series ${index + 1}`}
          </span>
        </div>
      </div>
    );
  });

  return (
    <div style={{ border: '1px solid #e4e4e8', padding: '15px', width: '200px' }} className="mar-l-4">
      <table className="pdf-hidden-table mar-t-3 mar-b-2" style={{ height: '100%' }}>
        <tbody>
          <tr>
            <td style={{ verticalAlign: 'middle' }}>
              <div className="frow frow--direction-column">{legends}</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const CustomVerticalLegend = (props) => {
  const { payload } = props;

  const legends = payload.map((payloadItem, index) => {
    const legendTitle = payloadItem.payload.data[0].name;
    const { legendType, fill, stroke } = payloadItem.payload;

    const size = ['circle', 'square'].includes(legendType) ? 100 : 75;
    const height = legendType === 'diamond' ? 18 : 15;
    const cy = legendType === 'diamond' ? 10 : 8;

    return (
      <span key={index} style={{ display: 'inline-block', breakInside: 'avoid', whiteSpace: 'nowrap' }}>
        <span className="mar-r-1">
          <Surface width={15} height={height}>
            <Symbols cx={8} cy={cy} type={payloadItem.payload.legendType} size={size} fill={fill} stroke={stroke} />
          </Surface>
        </span>
        <span style={{ fontSize: '1.26rem', whiteSpace: 'nowrap' }} className="mar-r-2">
          {legendTitle}
        </span>
      </span>
    );
  });

  return (
    <div style={{ border: '1px solid #e4e4e8', textAlign: 'center', padding: '5px 0' }} className="mar-t-3">
      {legends}
    </div>
  );
};

const customisedCircle = (props) => {
  const { cx, cy, fill, size, stroke } = props;
  return <Symbols cx={cx} cy={cy} type="circle" size={size * 2} fill={fill} stroke={stroke} />;
};

const customizedTriangle = (props) => {
  const { cx, cy, fill, size, stroke } = props;
  return <Symbols cx={cx} cy={cy} type="triangle" size={size * 2} fill={fill} stroke={stroke} />;
};

const customizedDiamond = (props) => {
  const { cx, cy, fill, size, stroke } = props;
  return <Symbols cx={cx} cy={cy} type="diamond" size={size * 2} fill={fill} stroke={stroke} />;
};

const customizedSquare = (props) => {
  const { cx, cy, fill, size, stroke } = props;
  return <Symbols cx={cx} cy={cy} type="square" size={size * 2} fill={fill} stroke={stroke} />;
};

const customizedCross = (props) => {
  const { cx, cy, fill, size, stroke } = props;
  return <Symbols cx={cx} cy={cy} type="cross" size={size * 2} fill={fill} stroke={stroke} />;
};

const customizedStar = (props) => {
  const { cx, cy, fill, size, stroke } = props;
  return <Symbols cx={cx} cy={cy} type="star" size={size * 2} fill={fill} stroke={stroke} />;
};

const customizedWye = (props) => {
  const { cx, cy, fill, size, stroke } = props;
  return <Symbols cx={cx} cy={cy} type="wye" size={size * 2} fill={fill} stroke={stroke} />;
};

// Passing [0] into this function as we don't calculate the ticks for the scatter chart and want to format to 1 d.p.
const tickFormatter = precisionTickFormatter([0]);

import { chartColorsV2 } from './chartColors';
import { precisionTickFormatter } from 'components/shared/utilities/precisionFunctions';

const CustomizedNoDataLabel = (props) => {
  const { height, width, x, y } = props.viewBox;
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={width / 2} y={height / 2} textAnchor="middle" fill="#000" fontWeight="bold" fontSize="20px">
        Insufficient Data
      </text>
    </g>
  );
};

export default function scatterChart({
  benchmarkTypes,
  legendPosition,
  templateSection,
  theme,
  height = 350,
  width,
  xAxisName,
  yAxisName,
}) {
  const chartColors = chartColorsV2();

  const legendBottom = legendPosition === 'bottom';
  const scatterType: string = templateSection.scatter_type;
  const series = templateSection.data;

  const maxValueX = () => {
    let maxValue = Math.max(...series.map((dataPoint) => dataPoint[0].xAxis));
    maxValue /= 25;
    maxValue = Math.max(Math.ceil(maxValue) * 25, 100);
    return maxValue;
  };

  const maxValueY = () => {
    let maxValue = Math.max(...series.map((dataPoint) => dataPoint[0].y));
    maxValue /= 25;
    maxValue = Math.max(Math.ceil(maxValue) * 25, 100);
    return maxValue;
  };

  const shapes: ('circle' | 'cross' | 'diamond' | 'square' | 'star' | 'triangle' | 'wye')[] = [
    'circle',
    'diamond',
    'square',
    'triangle',
    'cross',
    'star',
    'wye',
  ];

  const customShapes = [
    customisedCircle,
    customizedDiamond,
    customizedSquare,
    customizedTriangle,
    customizedCross,
    customizedStar,
    customizedWye,
  ];

  const scatters = series.map((series, index) => {
    if (!series[0].xAxis) {
      return null;
    }
    const shape = shapes[index % shapes.length];
    const customShape = customShapes[index % customShapes.length];
    const color = chartColors(series[0].name, theme, benchmarkTypes, index);
    return (
      <Scatter
        key={index}
        data={series}
        fill={color}
        isAnimationActive={false}
        stroke={color}
        legendType={shape}
        shape={customShape}
      />
    );
  });

  function renderLegend() {
    if (legendBottom) return <Legend content={<CustomVerticalLegend />} />;
    return (
      <Legend layout="vertical" verticalAlign="top" align="right" content={<CustomLegend {...{ templateSection }} />} />
    );
  }

  const noData = series.every((sArray) => {
    return sArray.every((item) => !item.xAxis);
  });

  const noDataOverlay = (
    <ReferenceArea
      fill="#f6f6f8"
      x1={0}
      x2={1}
      y1={0}
      y2={1.2}
      alwaysShow
      isFront
      label={<CustomizedNoDataLabel />}
    />
  );

  return (
    <ScatterChart
      width={width}
      height={height}
      margin={{ top: scatterType === 'up_down_capture' ? 10 : 5, right: 20, left: 0, bottom: 10 }}
    >
      <CartesianGrid stroke="#d7d7dd" strokeDasharray={scatterType === 'up_down_capture' ? '3 3' : undefined} />
      {!noData && <ReferenceLine x={maxValueX() == 100 ? undefined : 100} stroke="#b0b0b5" />}
      {!noData && <ReferenceLine y={maxValueY() == 100 ? undefined : 100} stroke="#b0b0b5" />}
      {noData && noDataOverlay}
      <XAxis
        dataKey="xAxis"
        domain={scatterType === 'up_down_capture' && !noData ? [0, maxValueX()] : [0, 'auto']}
        interval={0}
        tick={{ fill: '#b5b6bd', fontSize: '12px', fontWeight: 'bold' }}
        tickCount={scatterType === 'up_down_capture' ? maxValueX() / 25 + 1 : 5}
        tickSize={0}
        tickMargin={8}
        tickFormatter={tickFormatter}
        type="number"
        unit="%"
      >
        <Label content={<CustomXAxis name={xAxisName} />} />
      </XAxis>
      <YAxis
        dataKey="y"
        domain={noData ? [0, 1.2] : scatterType === 'up_down_capture' ? [0, maxValueY()] : [0, 'auto']}
        tick={{ fill: '#b5b6bd', fontSize: '12px', fontWeight: 'bold' }}
        tickCount={scatterType === 'up_down_capture' ? maxValueY() / 25 + 1 : 7}
        tickSize={0}
        tickMargin={5}
        tickFormatter={tickFormatter}
        unit="%"
      >
        <Label content={<CustomYAxis {...{ height }} name={yAxisName} />} />
      </YAxis>
      {!noData && renderLegend()}
      {scatters}
    </ScatterChart>
  );
}
