import * as React from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { SubtitleTwo } from 'components/shared/Typography';
import CustomModal from 'components/shared/CustomModal';

const ResponsiveGridLayout = WidthProvider(Responsive);

export default function reorder({ isOpen, handleClose, layout, setItem, layoutDisplay, setLayoutDisplay }) {
  const [editedLayout, setEditedLayout] = React.useState();
  const [editedDisplay, setEditedDisplay] = React.useState(layoutDisplay);

  React.useEffect(() => {
    setEditedDisplay(layoutDisplay);
  }, [layoutDisplay]);

  function handleLayoutChange(currentLayout, allLayouts) {
    setEditedLayout(allLayouts);
  }

  const blocks = layout.lg.map((element) => {
    const handleDisplayChange = () => {
      const updatedDisplay = { ...editedDisplay };
      updatedDisplay[element.i] = !updatedDisplay[element.i];
      setEditedDisplay(updatedDisplay);
    };

    return (
      <div className="card card--dark" key={element.i} style={{ cursor: 'pointer' }}>
        <div className="platform-content platform-content--padding-all">
          <div className="frow frow--justify-between frow--gutters frow--items-center">
            <div>
              <SubtitleTwo text={element.i} additionalClasses="text-white mar-v-0" />
            </div>
            <div>
              <div className="pretty p-icon">
                <input
                  type="checkbox"
                  id={`${element.i}_visible`}
                  onChange={handleDisplayChange}
                  checked={editedDisplay[element.i]}
                  name={`${element.i}_visible`}
                  value="1"
                  style={{ zIndex: 999 }}
                />
                <div className="state p-blue">
                  <i className="icon-tick icon icon-push-down-small" />
                  <label className="text-white">Show</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  });

  function handleSaveLayout() {
    if (editedLayout) {
      setItem(editedLayout);
    }
    if (editedDisplay) {
      setLayoutDisplay(editedDisplay);
    }
    setEditedLayout(null);
    handleClose();
  }

  return (
      <div className="modal__content">
        <p className="helper-text text-white">Click and drag the blocks around to reorder</p>
        <ResponsiveGridLayout
          className="layout"
          layouts={layout}
          rowHeight={60}
          breakpoints={{ lg: 962, sm: 768 }}
          cols={{ lg: 2, sm: 1 }}
          isResizable={false}
          onLayoutChange={handleLayoutChange}
          preventCollision={false}
        >
          {blocks}
        </ResponsiveGridLayout>
        <div className="pad-l-1 pad-t-1">
          <button className="button" onClick={handleSaveLayout}>
            Save Layout
          </button>
        </div>
      </div>
  );
}
