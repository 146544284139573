import * as React from 'react';

import Loading from 'components/shared/Loading';

interface IProps {
  isLoading?: boolean;
}

export default function reactAreaChartNoData(props: IProps) {
  function message() {
    if (props.isLoading) {
      return <Loading />;
    }

    return (
      <div className="no-data-text-wrapper">
        <p className="no-data-text">No Data to display</p>
      </div>
    );
  }

  return <div className="no-data-wrapper mar-t-1">{message()}</div>;
}
