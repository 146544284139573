import * as React from 'react';
import * as $ from 'jquery';

import { ajaxErrorString } from 'components/shared/Utils';
import Dropzone from 'components/shared/forms/Dropzone';
import CsrfToken from 'components/shared/CsrfToken';

interface IProps {
  path: string;
}

export default function upload(props: IProps) {
  const { path } = props;

  const [alert, setAlert] = React.useState<{
    type: 'info' | 'success' | 'error' | 'warning';
    message: string;
    title: string;
  }>(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  function handleUploadedFiles(files) {
    if (files.length < 1) return;

    const formData = new FormData();

    formData.append('authenticity_token', CsrfToken());
    files.forEach((file) => {
      formData.append('files[]', file);
    });

    $.ajax({
      beforeSend: () => {
        setAlert(null);
        setIsSubmitting(true);
      },
      contentType: false,
      data: formData,
      dataType: 'json',
      error: (jqXhr) => {
        setAlert({ message: ajaxErrorString(jqXhr), type: 'error', title: 'Sorry, there was a problem' });
        setIsSubmitting(false);
      },
      processData: false,
      success: () => {
        setAlert({ message: 'your files were uploaded successfully', type: 'success', title: 'Thanks' });
        setIsSubmitting(false);
      },
      type: 'post',
      url: path,
    });
  }

  return (
    <div className="h-100">
      <Dropzone
        acceptedFiles=".csv, .xls, .xlt, .xlm, .xlsx, .xlsm, .xltx, .xltm, .xlsb, .xla, .xlam, .xll, .xlw"
        alert={alert}
        shouldDiscardFiles
        isLarge
        isSubmitting={isSubmitting}
        onFileUpload={handleUploadedFiles}
        shouldShowFiles={false}
        theme="dark"
      />
    </div>
  );
}
