import * as React from 'react';

import { DirectUpload } from 'activestorage';

import CustomModal from 'components/shared/CustomModal';
import Alert from 'components/shared/Alert';

import useVisibility from 'components/shared/customHooks/useVisibility';
import useGraphitiForm from 'components/shared/customHooks/useGraphitiForm';
import { Note } from 'javascript/models';
import { graphitiErrorString } from 'components/shared/Utils';
import Form from './Form';

interface IProps {
  currentUser: string;
  note: Note;
  handleSuccessfulFormSubmission(): void;
}

export default function edit(props: IProps) {
  const { currentUser, handleSuccessfulFormSubmission, note } = props;

  const { handleClose, handleOpen, isOpen } = useVisibility(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  /*
  const { resource, handleChange, handleSubmit } = useGraphitiForm(note, handleFormSubmission);

  function handleFormSubmission(_) {
    setIsSubmitting(true);
    updateResource();
  }

  async function updateResource() {
    resource.attachments = await uploadedFiles();
    const success = await resource.save();
    if (success) {
      handleClose();
      setIsSubmitting(false);
      handleSuccessfulFormSubmission();
    } else {
      setErrorMessage(graphitiErrorString(resource));
    }
  }
     */

  return (
    <React.Fragment>
      <a style={{ display: 'block', cursor: 'pointer' }} onClick={handleOpen}>
        <div className="rt-tr-clickable-icon">
          <i className="icon-arrow-right-top icon-0-8x" />
        </div>
      </a>
      <CustomModal isOpen={isOpen} title="Note" handleClose={handleClose}>
        <div className="modal__content">
          {/*
          <form action="" className="form form--light" onSubmit={handleSubmit}>
             <Form {...{ currentUser, resource, handleChange, isSubmitting, onFileUpload }} /> 
          </form>
          */}
        </div>
      </CustomModal>
    </React.Fragment>
  );
}
