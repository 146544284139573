import * as React from 'react';

import Alert from 'components/shared/Alert';
import Button from 'components/shared/forms/Button';
import FormTag from 'components/shared/forms/FormTag';

import BespokeCommentary from './BespokeCommentary';
import SectionCommentary from './SectionCommentary';

import IReviewCommentaries from 'components/interfaces/IReviewCommentaries';
import ICommentaries from 'components/interfaces/ICommentaries';

interface IProps {
  commentaries: ICommentaries;
  reviewCommentaries: IReviewCommentaries;
  reviewCommentaryType: string;
  updatePath: string;
  userId: string;
  handleClose(): void;
  handleSubmit(event: React.SyntheticEvent): void;
}

export default function standard(props: IProps) {
  const softCharacterLimit = 1300;

  const performanceCommentary = props.reviewCommentaries.data.find((commentary) => {
    return commentary.attributes.commentaryType === 'performance';
  });

  const allocationCommentary = props.reviewCommentaries.data.find((commentary) => {
    return commentary.attributes.commentaryType === 'allocation';
  });

  const holdingCommentary = props.reviewCommentaries.data.find((commentary) => {
    return commentary.attributes.commentaryType === 'holding';
  });

  const bespokeCommentary = props.reviewCommentaries.data.find((commentary) => {
    return commentary.attributes.commentaryType === 'bespoke';
  });

  const ratingCommentary = props.reviewCommentaries.data.find((commentary) => {
    return commentary.attributes.commentaryType === 'rating';
  });

  const investmentRestrictionsCommentary = props.reviewCommentaries.data.find((commentary) => {
    return commentary.attributes.commentaryType === 'investment_restrictions';
  });

  const [commentaryLengths, setCommentaryLengths] = React.useState({
    allocation: allocationCommentary ? countCharacters(allocationCommentary.attributes.commentary) : 0,
    bespoke: bespokeCommentary ? countCharacters(bespokeCommentary.attributes.commentary) : 0,
    holdings: holdingCommentary ? countCharacters(holdingCommentary.attributes.commentary) : 0,
    investmentRestrictions: investmentRestrictionsCommentary
      ? countCharacters(investmentRestrictionsCommentary.attributes.commentary)
      : 0,
    performance: performanceCommentary ? countCharacters(performanceCommentary.attributes.commentary) : 0,
    rating: ratingCommentary ? countCharacters(ratingCommentary.attributes.commentary) : 0,
  });

  function countCharacters(str) {
    if (!str) return 0;

    let count = str.length;
    const newLines = (str.match(/\n/g) || '').length;
    count += newLines * 92;
    return count;
  }

  function handleCommentaryChange(commentary, commentaryType) {
    const newCommentaryLengths = { ...commentaryLengths };
    newCommentaryLengths[commentaryType] = countCharacters(commentary);
    setCommentaryLengths(newCommentaryLengths);
  }

  const alert = (
    <Alert
      type="error"
      title="Long Commentary"
      isCloseable={false}
      message="Your commentary is very long. Depending on the report template it may break the generated report.
          Please check the preview carefully and shorten if required."
    />
  );

  const currentCount = Object.values(commentaryLengths).reduce((a, b) => a + b, 0);

  return (
    <div className="modal__content">
      {currentCount > softCharacterLimit && alert}
      <FormTag className="form form--light" method="patch" url={props.updatePath} onSubmit={props.handleSubmit}>
        <div className="frow frow--gutters">
          <div className="col-mc-1-1">
            <SectionCommentary
              index={5}
              reviewCommentary={investmentRestrictionsCommentary}
              type="investment_restrictions"
              userId={props.userId}
              handleCommentaryChange={handleCommentaryChange}
            />
          </div>
          <div className="col-mc-1-1">
            <SectionCommentary
              index={0}
              reviewCommentary={performanceCommentary}
              type="performance"
              userId={props.userId}
              handleCommentaryChange={handleCommentaryChange}
            />
          </div>
          <div className="col-mc-1-1">
            <SectionCommentary
              index={1}
              reviewCommentary={allocationCommentary}
              type="allocation"
              userId={props.userId}
              handleCommentaryChange={handleCommentaryChange}
            />
          </div>
          <div className="col-mc-1-1">
            <SectionCommentary
              index={2}
              reviewCommentary={holdingCommentary}
              type="holding"
              userId={props.userId}
              handleCommentaryChange={handleCommentaryChange}
            />
          </div>
          <div className="col-mc-1-1">
            <BespokeCommentary
              commentaries={props.commentaries}
              index={3}
              reviewCommentary={bespokeCommentary}
              type="bespoke"
              userId={props.userId}
              handleCommentaryChange={handleCommentaryChange}
            />
          </div>
          <div className="col-mc-1-1">
            <SectionCommentary
              index={4}
              reviewCommentary={ratingCommentary}
              type="rating"
              userId={props.userId}
              handleCommentaryChange={handleCommentaryChange}
            />
          </div>
          <div className="col-md-2-3">
            <Button text="Save and Close" buttonClasses="button--full-width button--light-themed" />
          </div>
          <div className="col-md-1-3">
            <Button
              inputType="button"
              text="Cancel"
              buttonClasses="button--full-width button--light-themed"
              handleClick={props.handleClose}
            />
          </div>
        </div>
      </FormTag>
    </div>
  );
}
