import * as React from 'react';

import lodashStartcase from 'lodash.startcase';

const snakeToCamel = (str) =>
  str.replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''));

const CommentaryPicker = (props) => {
  const commentaries = ['allocation_commentary', 'performance_commentary', 'rating_commentary'];

  const commentary = commentaries.map((commentaryType, index) => (
    <div className="col-mc-1-1" key={index}>
      <div className="pretty p-icon">
        <input type="hidden" name={`portfolio[${commentaryType}]`} value="0" />
        <input
          type="checkbox"
          name={`portfolio[${commentaryType}]`}
          value="1"
          defaultChecked={props.portfolio[snakeToCamel(commentaryType)]}
        />
        <div className="state p-blue">
          <i className="icon-tick icon icon-push-down-small" />
          <label className="text-white">{lodashStartcase(commentaryType)}</label>
        </div>
      </div>
    </div>
  ));

  return (
    <div className="col-mc-1-1">
      <label htmlFor="" className="form__label">
        Included Standard Commentaries
      </label>
      <div className="frow frow--gutters">{commentary}</div>
    </div>
  );
};

export default CommentaryPicker;
