import * as React from 'react';

import Uploader from './manualUpload/Uploader';

import CustomModal from 'components/shared/CustomModal';

import useVisibility from 'components/shared/customHooks/useVisibility';

export default function manualUpload({ disabled, portfolio }) {
  const { id, combined, consolidated } = portfolio.attributes;

  const { isOpen, handleOpen, handleClose } = useVisibility(false);

  const hideManualUploadButton = () => {
    if (!combined && !consolidated) {
      return (
        <button
          disabled={disabled}
          className="button button--secondary button--icon mar-r-1"
          onClick={handleOpen}
          data-tip="Upload portfolio data"
          data-for="portfolio_controls"
        >
          <i className="icon-upload icon-fw" />
        </button>
      );
    }
  };

  return (
    <React.Fragment>
      {hideManualUploadButton()}
      <CustomModal isOpen={isOpen} handleClose={handleClose} title="Upload File">
        <div className="modal__content">
          <Uploader portfolioId={id} handleUpload={handleClose} />
        </div>
      </CustomModal>
    </React.Fragment>
  );
}
