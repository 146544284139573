import { Attr, BelongsTo, Model } from 'spraypaint';
import { ApplicationRecord } from './ApplicationRecord';

@Model()
export class Valuation extends ApplicationRecord {
  public static jsonapiType = 'valuations';

  @Attr() public portfolioId: string;
  @Attr() public administratorId: string;
  @Attr() public valueDate: string;
  @Attr() public document: string;
  @Attr() public uploadedAt: string;
  @Attr() public uploadedBy: string;
  @Attr() public showPath: string;
  @Attr() public fileName: string;
}
