import { Attr, HasMany, Model, BelongsTo } from 'spraypaint';

import { apply, compose, constructN, equals, map, mergeAll, project, prop } from 'ramda';

import { ApplicationRecord } from '../ApplicationRecord';

import { Security } from '../Security';
import { ClassificationUpload } from '../ClassificationUpload';
import { Administrator } from '../Administrator';

import { SecurityStateClassificationWeighting } from './StateClassificationWeighting';

@Model()
export class SecurityState extends ApplicationRecord {
  public static jsonapiType = 'securities/states';
  public static defaultReturnScopeIncludes = [
    { rootAlias: { classification_weightings: { classification: 'rootAlias' } } },
    'author',
    'classification_uploads',
  ];
  public static defaultReturnScope = SecurityState.includes(SecurityState.defaultReturnScopeIncludes);
  private static comparableWeightingAttributes = ['classification', 'classificationId', 'valueType', 'weighting'];

  @Attr() public id: string;
  @Attr() public aliasId: string;
  @Attr() public referenceDate: string;
  @Attr() public classifiedBy: string;
  @Attr() public status: string;
  @Attr() public dateSuperseded: Date;
  @Attr() public authorId: string;
  @Attr() public securityId: string;
  @Attr() public verified: boolean;
  @Attr() public rolledOverFromStateId: string;
  @Attr({ persist: false }) public createdAt: string;
  @Attr({ persist: false }) public updatedAt: string;
  @Attr({ persist: false }) public timePeriod: string;

  @HasMany(SecurityStateClassificationWeighting)
  public classificationWeightings: SecurityStateClassificationWeighting[];
  @HasMany(ClassificationUpload) public classificationUploads: ClassificationUpload[];

  @BelongsTo(SecurityState) public alias: SecurityState;
  @BelongsTo({ type: SecurityState, persist: false }) public rootAlias: SecurityState;

  @BelongsTo(Security) public security: Security;
  @BelongsTo(Administrator) public author: Administrator;

  private extractComparableWeightingAttributes = compose(
    project(SecurityState.comparableWeightingAttributes),
    prop('classificationWeightings'),
  );

  public cloneClassificationWeightings(other_state = this.rootAlias) {
    const cloneWeightings = compose(
      map(constructN(1, SecurityStateClassificationWeighting)),
      this.extractComparableWeightingAttributes,
    );
    const securityWeightings = cloneWeightings(other_state) as SecurityStateClassificationWeighting[];

    this.classificationWeightings = securityWeightings;
  }

  public cloneClassificationWeightingsIfUnset(other_state = this.rootAlias) {
    if (this.classificationWeightings.length === 0) this.cloneClassificationWeightings(other_state);
  }

  public conditionallyPreserveAlias() {
    const mapped = map(this.extractComparableWeightingAttributes, [this, this.rootAlias]);
    if (this.aliasId && this.aliasId !== '0')
      if (apply(equals, mapped)) this.classificationWeightings = [];
      else this.aliasId = '0';
  }

  public get groupedWeightings() {
    return mergeAll(this.classificationWeightings.map((cw) => ({ [cw.valueType]: cw.classification.name })));
  }

  public save(options?) {
    this.conditionallyPreserveAlias();
    return super.save({ ...options });
  }
}
