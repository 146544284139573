import * as React from 'react';

import ReactTooltip from 'react-tooltip';
import { format } from 'date-fns';

import { User, ActionPoint, ActionPointStatus } from 'javascript/models';

import ISelectOption from 'components/interfaces/ISelectOption';
import Loading from 'components/shared/Loading';
import ReactSelect from 'components/shared/forms/ReactSelect';
import useRadioSelect from 'components/shared/customHooks/useRadioSelect';
import { Frow, Column } from 'components/frow';

import { snakeCase } from 'lodash';
import ClientPortalCard from './ClientPortalCard';
import ActionPointMapper from './ActionPointMapper';
import ActionPointStatusFilter from './ActionPointStatusFilter';
import { IPresenterProps } from '../clients/show/Presenter';

interface IActionPointProps {
  client: IPresenterProps['client'];
  selectedDate: Date;
  currentUser: User;
  portfolioId?: number;
  titleControlClass?: string;
}

interface IRenderData {
  actionPoint: ActionPoint[];
  selectedRadioRef: React.MutableRefObject<any>;
  loader: React.MutableRefObject<any>;
  client: any;
}

const ListDisplay = ({
  filteredActionPoints,
  client,
  loader,
}: {
  filteredActionPoints: ActionPoint[];
  loader: any;
  client: IActionPointProps['client'];
}) => {
  const listJsx = filteredActionPoints.map((action) => {
    const actionPointItems = <ActionPointMapper displayType="list" actionPoint={action} client={client} />;
    return (
      <div key={action.id} className="mar-t-2">
        <div className="frow frow--gutters">
          {actionPointItems}
          <ReactTooltip effect="solid" id="action-point-icon" />
        </div>
      </div>
    );
  });

  return (
    <>
      {listJsx}
      <div ref={loader} />
    </>
  );
};

const GridDisplay = ({
  filteredActionPoints,
  loader,
  client,
}: {
  filteredActionPoints: ActionPoint[];
  loader: any;
  client: IActionPointProps['client'];
}) => {
  const actionPointCards = filteredActionPoints.map((actionPoint) => (
    <ActionPointMapper {...{ displayType: 'card', key: actionPoint.id, actionPoint, width: '305px', client }} />
  ));

  return (
    <div className="frow frow--nowrap" style={{ overflowX: 'auto' }}>
      {actionPointCards}
      <div ref={loader} />
      <ReactTooltip effect="solid" id="action-point-icon" />
    </div>
  );
};

const RenderData = ({ actionPoint, selectedRadioRef, loader, client }: IRenderData) => {
  if (actionPoint.length < 1) {
    return (
      <p className="text-white helper-text mar-t-3">
        There are currently no outstanding action points for the selected period or assignee.
      </p>
    );
  }

  const displayTypeObj = {
    0: <GridDisplay filteredActionPoints={actionPoint} {...{ loader, client }} />,
    1: <ListDisplay filteredActionPoints={actionPoint} {...{ loader, client }} />,
  };

  return displayTypeObj[selectedRadioRef.current];
};

const ActionPoints = ({ client, selectedDate, currentUser, portfolioId, titleControlClass }: IActionPointProps) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [actionPoint, setActionPoint] = React.useState<ActionPoint[]>([]);
  const [assigneeFilter, setAssigneeFilter] = React.useState<ISelectOption>();
  const [assignableUserOptions, setAssignableUserOptions] = React.useState<ISelectOption[]>();
  const [loadingAssignableUsers, setLoadingAssignableUsers] = React.useState(true);
  const [statusFilter, setStatusFilter] = React.useState<ActionPointStatus>();
  const [totalCount, setTotalCount] = React.useState(0);

  const loader = React.useRef(null);
  const [page, setPage] = React.useState(1);
  const [endOfList, setEndOfList] = React.useState(false);

  React.useEffect(() => {
    setActionPoint([]);
    setEndOfList(false);
    setPage(1);
  }, [selectedDate, assigneeFilter, statusFilter]);

  React.useEffect(() => {
    getActionPoints().then(() => {
      setIsLoading(false);
    });
  }, [page, selectedDate, assigneeFilter, statusFilter]);

  const handleObserver = React.useCallback(
    (entries) => {
      if (endOfList) return;
      const target = entries[0];
      if (target.isIntersecting) {
        setPage((prev) => prev + 1);
      }
    },
    [endOfList],
  );

  React.useEffect(() => {
    if (!loader.current) return;
    const so = loader.current.parentNode;
    if (so === null) return;
    const option = {
      root: so,
      rootMargin: '20px',
      threshold: 0,
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loader.current) observer.observe(loader.current);
  }, [handleObserver, isLoading, loader.current]);

  React.useEffect(() => {
    getAssignees();
  }, []);

  const displayOptions = [
    { label: 'Grid', value: 'grid' },
    { label: 'List', value: 'list' },
  ];

  const [radioToggle, selectedRadioRef] = useRadioSelect(displayOptions, 0);

  const titleControls = client && client.managesActionPointsInPortal && (
    <Frow justifyContent="between" itemAlignment="center">
      <Column columnSpan={2} maxColumns={6}>
        <ActionPointStatusFilter setStatusFilter={setStatusFilter} />
      </Column>
      <Column columnSpan={2} maxColumns={6}>
        <ReactSelect
          name="assigneeFilter"
          isLoading={loadingAssignableUsers}
          value={assigneeFilter}
          options={assignableUserOptions}
          isClearable
          theme="dark"
          placeholder="Filter by Assignee"
          handleChange={setAssigneeFilter}
        />
      </Column>
      <Column columnSpan={1} maxColumns={5}>
        <Frow justifyContent="end">{radioToggle}</Frow>
      </Column>
    </Frow>
  );

  const getActionPoints = async () => {
    setIsLoading(true);

    const extendedFilter = {
      action_point_status: snakeCase(statusFilter),
      assignee: assigneeFilter && assigneeFilter.value,
      review_date: format(selectedDate, 'YYYY-MM-DD'),
    };

    const reviewEndDate = format(selectedDate, 'YYYY-MM-DD').toString();
    const whereCond = {
      clientId: client.id,
      extendedFilter,
      portfolioStatus: 'live',
      reviewEndDate,
      reviewStatus: 'sent',
    };

    let scope = ActionPoint.select([])
      .extraParams({ end_date: reviewEndDate })
      .selectExtra(['portfolio', 'comment_count', 'document_count', 'entity_name'])
      .includes(['notes', 'assignee'])
      .where(whereCond)
      .stats({ total: 'count' })
      .per(20)
      .page(page);

    if (portfolioId) scope = scope.where({ portfolioId });

    const { data, meta } = await scope.all();
    setTotalCount(meta.stats.total.count);
    if (data.length === 0) {
      setEndOfList(true);
      return;
    }

    setActionPoint((previouseValue) => (page === 1 ? [...data] : [...previouseValue, ...data]));
  };

  const getAssignees = async () => {
    const users = await User.clientUsersWithPermission(client.id, ['action_point_responder', 'action_point_closer']);
    User.sortUsersByAttribute({ users, attr: 'firstName' });
    User.translateUserToFirstPosition({ user: currentUser, usersArray: users });

    const userOptions = users.map((user) => ({
      label: user.fullName,
      value: user.id,
    }));

    setAssignableUserOptions(userOptions);
    setLoadingAssignableUsers(false);
  };

  return (
    <ClientPortalCard
      title={`Action Points  (${totalCount})`}
      titleControls={titleControls}
      titleControlsClassName={titleControlClass}
    >
      <div>
        <div style={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: '400px' }}>
          {isLoading && page === 1 ? (
            <Loading />
          ) : (
            <RenderData {...{ actionPoint, selectedRadioRef, loader, client }} />
          )}
        </div>
      </div>
    </ClientPortalCard>
  );
};

export default ActionPoints;
