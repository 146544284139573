import * as React from 'react';
import * as $ from 'jquery';

import * as ReactTooltip from 'react-tooltip';

import Button from 'components/shared/forms/Button';
import { ajaxErrorString } from 'components/shared/Utils';
import CsrfToken from 'components/shared/CsrfToken';

import ReviewContext from '../ReviewContext';
import AssignModal from '../../../../../../reviews/AssignModal';
import Context from '../../../../Context';
import useVisibility from 'components/shared/customHooks/useVisibility';

export default function submitReview() {
  const context = React.useContext(Context);
  const reviewContext = React.useContext(ReviewContext);
  const { review } = reviewContext.props;
  const { updateStatusPath, validateStatusPath } = review.links;
  const { id } = review;
  const { owner } = review.attributes;
  const { handleReviewUpdate } = reviewContext.actions;
  const { administrators, companyId } = context.props;

  const { isOpen, handleOpen, handleClose } = useVisibility(false);

  const [isDisabled, setIsDisabled] = React.useState(true);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  const event = 'to_verification';

  React.useEffect(() => {
    validateTransition();
    setIsSubmitting(false);
  }, [review]);

  function validateTransition() {
    $.ajax({
      cache: false,
      dataType: 'json',
      error: (jqXhr) => setErrorMessage(ajaxErrorString(jqXhr)),
      success: () => {
        setErrorMessage('');
        setIsDisabled(false);
      },
      type: 'GET',
      url: `${validateStatusPath}?transition=${event}`,
    });
  }

  function handleClick() {
    if (owner || companyId === 2) {
      handleSubmit();
    } else {
      handleOpen();
    }
  }

  function handleSubmit() {
    const reviewObject = { event };
    $.ajax({
      beforeSend: () => setIsSubmitting(true),
      complete: () => setIsSubmitting(false),
      data: { review: reviewObject, authenticity_token: CsrfToken() },
      dataType: 'json',
      success: () => {
        handleReviewUpdate();
      },
      type: 'PATCH',
      url: updateStatusPath,
    });
  }

  return (
    <div data-tip={errorMessage} data-for="submit-review-tooltip">
      <Button
        buttonClasses="button--compact mar-r-1"
        isDisabled={isDisabled}
        isSubmitting={isSubmitting}
        handleClick={handleClick}
        submittingText="Moving Stage"
        style={{ minWidth: '120px' }}
        text="Submit"
      />
      <AssignModal
        administrators={administrators}
        companyId={companyId}
        handleClose={handleClose}
        handleReviewsAssigned={handleSubmit}
        isOpen={isOpen}
        selection={[id]}
      />
      <ReactTooltip effect="solid" id="submit-review-tooltip" />
    </div>
  );
}
