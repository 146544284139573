import * as React from 'react';

export default function actionPoints({ actionPoints }) {
  const uniqueTypes = [...Array.from(new Set<any>(actionPoints.map((actionPoint) => actionPoint.type)))];

  const tableRows = uniqueTypes.map((type) => {
    const selectedActionPoints = actionPoints.filter((actionPoint) => actionPoint.type === type);

    const typeActionPoints = selectedActionPoints.map((actionPoint, index) => {
      const typeCell = (
        <td className="row-label text-capitalize" rowSpan={selectedActionPoints.length} style={{ width: '20%' }}>
          {type === 'administration' ? 'Admin' : type}
        </td>
      );

      return (
        <React.Fragment key={actionPoint.body}>
          <tr>
            {index === 0 && typeCell}
            <td className={actionPoint.new ? 'pdf-table__negative-value' : ''} style={{ width: '6%' }}>
              {actionPoint.new ? 'New' : ''}
            </td>
            <td style={{ width: '8%', fontWeight: 'normal' }}>{actionPoint.firstIncluded}</td>
            <td style={{ width: '66%', fontWeight: 'normal' }}>{actionPoint.body}</td>
          </tr>
        </React.Fragment>
      );
    });

    return <React.Fragment key={type}>{typeActionPoints}</React.Fragment>;
  });

  return (
    <div>
      <h3 className="pdf-small-subtitle mar-b-1">Action Points</h3>
      {/*<div className="pdf-divider pad-b-0" />*/}
      <table className="pdf-table">
        <thead>
          <tr>
            <th>Type</th>
            <th colSpan={2}>First Included</th>
            <th>Action point</th>
          </tr>
        </thead>
        <tbody>{tableRows}</tbody>
      </table>
    </div>
  );
}

actionPoints.defaultProps = {
  actionPoints: [
    {
      body:
        "Please forward a copy of the 'investment management agreement' with details about the investment " +
        'objectives and risk profile.',
      firstIncluded: 'QX 20XX',
      new: true,
      type: 'administration',
    },
    {
      body:
        'Confirm with the investment manager as to whether the large individual stock holding in ABC ltd is ' +
        'suitable for the risk profile of this portfolio.',
      firstIncluded: 'QX 20XX',
      new: true,
      type: 'suitability',
    },
    {
      body:
        "Please forward details of the Investment Manager's benchmark (if available). To ensure we" +
        'are using the correct indices we require identifiers and weightings of the components. Alternatively' +
        'we can load performance figures into our system.',
      firstIncluded: 'QX 20XX',
      new: false,
      type: 'suitability',
    },
  ],
};
