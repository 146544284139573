import * as React from 'react';

import { Holding, Mandate } from 'javascript/models';

const routes = require('../../../../../../javascript/application/routes.js');

import Table from 'components/shared/reactTable/Table';
import Loading from 'components/shared/Loading';
import useAjaxRequest from 'components/shared/customHooks/useAjaxRequest';

import Context from '../../Context';

import columns from './AssetColumns';

const Assets = () => {
  const context = React.useContext(Context);

  const { id, mandateId, useLookthroughAssetClass } = context.props.portfolio.attributes;

  const [assetClasses, setAssetClasses] = React.useState([]);
  const [holdings, setHoldings] = React.useState([]);
  const [mandate, setMandate] = React.useState(null);

  const getAssetClasses = async () => setAssetClasses(await Holding.allAvailableAssetClasses);

  React.useEffect(() => {
    getAssetClasses();
  }, []);

  const [getHoldings, _isError, isLoading] = useAjaxRequest({
    cache: false,
    method: 'GET',
    path: routes.platform_portfolio_asset_holdings_path(id),
    stateSetter: setHoldings,
  });

  const getMandate = async () => {
    const { data } = await Mandate.find(mandateId);

    setMandate(data);
  };

  React.useEffect(() => {
    getMandate();
  }, []);

  React.useEffect(() => {
    getHoldings(null);
  }, [id]);

  const lookthroughNotice = (
    <p>NB This portfolio is set to use its assets' lookthrough asset classes for its asset allocation</p>
  );

  if (!mandate) return <Loading />;

  const localColumns = columns(mandate, assetClasses);

  return (
    <div>
      <Table
        className="-sticky-header-large"
        columns={localColumns}
        data={holdings}
        hasColumnSelector={false}
        hasPagination={false}
        isLoading={isLoading}
        manual={false}
        meta={{ count: holdings.length }}
      />
      {useLookthroughAssetClass && lookthroughNotice}
    </div>
  );
};

export default Assets;
