import { Attr, Model } from 'spraypaint';
import { ApplicationRecord } from './ApplicationRecord';

@Model()
export class ReviewTransition extends ApplicationRecord {
  public static jsonapiType = 'review_transitions';
  @Attr() public reviewId: string;
  @Attr() public actorId: string;
  @Attr() public errorCategory: string;
  @Attr() public errorSubCategory: string;
  @Attr() public errorSource: string;
  @Attr() public toStatus: string;
  @Attr() public fromStatus: string;
  @Attr() public additionalInfo: string;
}
