import * as React from 'react';

import Draggable from 'react-draggable';
import { SubtitleTwo } from 'components/shared/Typography';

import ReviewContext from './ReviewContext';
import { ReviewReport } from 'javascript/models';
import Loading from 'components/shared/Loading';

export default function preview({ isOpen, toggleOpen }) {
  const reviewContext = React.useContext(ReviewContext);
  const { review } = reviewContext.props;

  const [reports, setReports] = React.useState([]);
  const [ungeneratedReports, setUngeneratedReports] = React.useState([]);
  const [previewDimensions, setPreviewDimensions] = React.useState({ height: 500, width: 500 });
  const [selectedReport, setSelectedReport] = React.useState<ReviewReport>();
  const [key, setKey] = React.useState(Math.random());
  // const { isOpen, toggleOpen } = useVisibility(false);

  function handleToggleOpen() {
    toggleOpen();
    if (isOpen) {
      // This forces the draggable to completely reset, so if you hide it, it always goes back to the corner
      setKey(Math.random);
    }
  }

  React.useEffect(() => {
    setPreviewDimensions({ height: 500, width: 500 });
  }, [isOpen]);

  React.useEffect(() => {
    getData();
  }, [review]);

  async function getData() {
    const { data } = await ReviewReport.where({ review_id: review.id, viewing_type: 'auto_preview' })
      .selectExtra(['name'])
      .all();

    const pendingReports = data.filter((report) => !report.reportGenerated);

    setSelectedReport(data[0]);
    setUngeneratedReports(pendingReports);
    setReports(data);
  }

  React.useEffect(() => {
    if (ungeneratedReports.length > 0) {
      ungeneratedReports.forEach((report) => {
        setTimeout(() => {
          getSingleReport(report);
        }, 10000);
      });
    } else {
      const customEvent = new CustomEvent('reviews:status:update');
      document.dispatchEvent(customEvent);
    }
  }, [ungeneratedReports]);

  async function getSingleReport(report) {
    const { data } = await ReviewReport.selectExtra(['name']).find(report.id);

    if (data.reportGenerated) {
      getData();
    } else {
      setTimeout(() => {
        getSingleReport(data);
      }, 5000);
    }
  }

  function increaseSize() {
    const newHeight = previewDimensions.height + 50;
    const newWidth = previewDimensions.width + 50;
    setPreviewDimensions({ height: newHeight, width: newWidth });
  }

  function decreaseSize() {
    const newHeight = previewDimensions.height - 50;
    const newWidth = previewDimensions.width - 50;
    setPreviewDimensions({ height: newHeight, width: newWidth });
  }

  function reportPreview() {
    if (!selectedReport) {
      return (
        <p style={{ height: isOpen ? 'auto' : 0, backgroundColor: '#272a31' }} className="text-white mar-v-0 pad-a-2">
          No report templates set
        </p>
      );
    }

    if (!selectedReport.reportGenerated) {
      return (
        <div style={{ backgroundColor: '#272a31', height: isOpen ? 'auto' : 0 }} className="pad-t-1">
          <Loading text="Generating Preview" flexDirection="column" />;
        </div>
      );
    }

    return (
      <div style={{ height: isOpen ? `${previewDimensions.height}px` : 0, backgroundColor: '#272a31', width: '100%' }}>
        <iframe src={selectedReport.reportPath} style={{ height: '100%', width: '100%', border: 'none' }} />
      </div>
    );
  }

  function reportSelect() {
    const selectableReports = reports.map((report) => {
      function handleReportSelect() {
        setSelectedReport(report);
      }
      return (
        <div
          className={`button-group__button ${report.id === selectedReport.id && 'button-group__button--active'}`}
          key={report.id}
          onClick={handleReportSelect}
        >
          {report.name}
        </div>
      );
    });

    return <div className="button-group">{selectableReports}</div>;
  }

  function externalLink() {
    if (selectedReport && selectedReport.reportGenerated) {
      return (
        <div>
          <a href={selectedReport.reportPath} target="_blank">
            <i className="fas fa-external-link-alt text-white" />
          </a>
        </div>
      );
    }
    return null;
  }

  if (!isOpen) return <div />;

  return (
    <Draggable handle={'.drag-handle'} allowAnyClick={false} key={key}>
      <div style={{ top: '10%', left: '25%', position: 'fixed', width: `${previewDimensions.width}px`, zIndex: 1000 }}>
        <div style={{ backgroundColor: '#272a31', padding: '10px', borderBottom: '1px solid #777881' }}>
          <div className="frow frow--justify-between frow--gutters frow--items-center">
            <div>
              <div className="frow frow--gutters">
                <div>
                  <i className="fas fa-clickable fa-expand-arrows-alt text-white drag-handle" />
                </div>
                <div>
                  <i className="fas fa-clickable text-white fa-plus-circle" onClick={increaseSize} />
                </div>
                <div>
                  <i className="fas fa-clickable text-white fa-minus-circle" onClick={decreaseSize} />
                </div>
                <div>
                  <SubtitleTwo text="Inline Preview" additionalClasses="text-white mar-b-0" />
                </div>
              </div>
            </div>
            <div>
              <div className="frow frow--gutters">
                {externalLink()}
                <div>
                  <i className="far fa-clickable text-white fa-times-circle" onClick={handleToggleOpen} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {reportSelect()}
        {reportPreview()}
      </div>
    </Draggable>
  );
}
