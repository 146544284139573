import * as React from 'react';

import { DirectUpload } from 'activestorage';

import Dropzone from 'components/shared/forms/Dropzone';

import { Attachment } from 'javascript/models';

export default function uploader({ handleUpload, attachmentId, imageSrc, imageName }) {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [src, setSrc] = React.useState(imageSrc);
  const [fileName, setFileName] = React.useState(imageName);

  async function handleRemove() {
    if (attachmentId) {
      const attachment = new Attachment();
      attachment.id = attachmentId;
      attachment.isPersisted = true;
      await attachment.destroy();
    }
    setSrc(null);
  }

  function onFileUpload(files) {
    if (files.length < 1) return;

    setIsSubmitting(true);
    files.forEach((file) => {
      const upload = new DirectUpload(file, '/rails/active_storage/direct_uploads');

      upload.create((error, blob) => {
        if (error) {
          // Dump out
        } else {
          setFileName(file.name);
          setSrc(URL.createObjectURL(file));
          handleUpload(blob.signed_id);
          setIsSubmitting(false);
        }
      });
    });
  }

  function renderPreview() {
    if (!src) {
      return (
        <div className="frow frow--content-center frow--items-center h-100">
          <span className="text-white">Upload an image to view the preview</span>
        </div>
      );
    }

    const imageStyles = {
      maxHeight: '200px',
      maxWidth: '100%',
      width: 'auto',
    };

    return (
      <div>
        <p className="mar-t-0">
          <span className="text-white text-small">
            <em>{fileName}</em>
          </span>
        </p>
        <img style={imageStyles} src={src} alt="" />
        <p className="helper-text mar-t-1 text-white" style={{ cursor: 'pointer' }} onClick={handleRemove}>
          Remove
        </p>
      </div>
    );
  }

  return (
    <div className="frow frow--gutters">
      <div className="col-sm-1-2">{renderPreview()}</div>
      <div className="col-sm-1-2">
        <Dropzone
          acceptedFiles=".jpeg, .png, .jpg"
          fullHeight
          multiple={false}
          theme="dark"
          onFileUpload={onFileUpload}
          isSubmitting={isSubmitting}
          shouldShowFiles={false}
        />
        <p className="helper-text mar-v-1">Accepted files types are jpg and png</p>
      </div>
    </div>
  );
}
