import React from 'react';
import PermissionBreakdown from './PermissionBreakdown';
import { Administrator } from 'javascript/models';

type TPermissions = {
  [key: string]: { description: string; value: number };
};

export type TPermissionGroups = {
  [key: string]: TPermissions;
};

interface IPermissions {
  administratorId: number;
  groupedPermissions: TPermissionGroups;
  currentPermissions: { permission_type: string }[];
  canEditPermissions: boolean;
}

const Permissions = ({ administratorId, groupedPermissions, canEditPermissions }: IPermissions) => {
  const [administrator, setAdministrator] = React.useState<Administrator>();

  const getAdministrator = async () =>
    setAdministrator((await Administrator.includes('permissions').find(administratorId)).data);

  React.useEffect(() => {
    getAdministrator();
  }, []);

  const updatePermissions = async () => await administrator.save({ with: 'permissions', displayQueryResult: true });

  const UpdatePermissionsButton = () => (
    <button className="button" onClick={updatePermissions}>
      Update Permissions
    </button>
  );

  return (
    <div className="platform-panel">
      <header className="platform-panel__header">
        <h3 className="platform-panel__title">Permissions</h3>
      </header>
      <div className="platform-content--padding-all">
        <div className="frow frow--gutters-2x">
          {administrator && (
            <PermissionBreakdown
              {...{
                permissionable: administrator,
                groupedPermissions,
                canEditPermissions,
              }}
            />
          )}
        </div>
        <div className="frow frow--justify-start mar-t-4">{canEditPermissions && <UpdatePermissionsButton />}</div>
      </div>
    </div>
  );
};

export default Permissions;
