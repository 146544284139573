import * as React from 'react';

import Slider from 'rc-slider';

import { Frow, Column } from 'components/frow';

export interface IAssetProps {
  assetClass: string;
  availableSpace: number;
  color: string;
  sliderState: any;
}

const AssetSlider = (props: IAssetProps) => {
  const { assetClass, availableSpace, color, sliderState } = props;
  const [selectedValue, setSelectedValue] = sliderState;

  const handleValueUpdate = React.useCallback(
    (value) => setSelectedValue((previous) => Math.min(previous + availableSpace, value)),
    [availableSpace],
  );

  return (
    <Column>
      <label htmlFor="" className="form__label">
        Neutral {assetClass}
      </label>
      <Frow justifyContent="between" itemAlignment="center">
        <Column columnSpan={10} maxColumns={11}>
          <Slider trackStyle={[{ backgroundColor: color }]} onChange={handleValueUpdate} value={selectedValue} />
        </Column>
        <Column columnSpan={1} maxColumns={11}>
          <div className="text-white text-right">{selectedValue}</div>
        </Column>
      </Frow>
    </Column>
  );
};

export default AssetSlider;
