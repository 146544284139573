import * as React from 'react';

import { default as useVisibility, IUseVisibility } from 'components/shared/customHooks/useVisibility';

import CustomModal from 'components/shared/CustomModal';
import ThemeContext from 'components/shared/context/ThemeContext';
import CloseModal from './context/CloseModal';

export interface ITriggerProps {
  handleOpen: () => void;
}

export interface IModalWithTriggerProps {
  aroundOnClose?: (modalClose: () => void) => void;
  buttonClassName?: string;
  buttonText?: string | JSX.Element;
  children?: JSX.Element;
  disabled?: boolean;
  externalUseVisibility?: IUseVisibility;
  modifiers?: string[];
  title: string | JSX.Element;
  trigger?: (props: ITriggerProps) => JSX.Element;
}

class OptionalParameterError extends Error {}

export default function modalWithTrigger(props: IModalWithTriggerProps) {
  const {
    aroundOnClose,
    buttonClassName,
    buttonText,
    disabled,
    externalUseVisibility,
    modifiers,
    title,
    trigger,
  } = props;

  if (!(buttonText || trigger)) throw new OptionalParameterError('Either `buttonText` or `trigger` must be supplied');

  const { isOpen, handleOpen, handleClose } = externalUseVisibility || useVisibility(false);

  const theme = React.useContext(ThemeContext);

  const defaultTrigger = ({ handleOpen }) => {
    return (
      <button className={`button ${buttonClassName}`} onClick={handleOpen} disabled={disabled}>
        {buttonText}
      </button>
    );
  };

  const TriggerElement = () => {
    const Trigger = trigger ? trigger : defaultTrigger;
    return <Trigger handleOpen={handleOpen} />;
  };

  const handleExternalClose = React.useCallback(() => {
    if (aroundOnClose) {
      aroundOnClose(handleClose);
    } else {
      handleClose();
    }
  }, [aroundOnClose, handleClose]);

  return (
    <>
      <TriggerElement />
      <CustomModal
        isOpen={isOpen}
        handleClose={handleExternalClose}
        modifiers={['large', theme.classNameModifier, ...modifiers]}
        title={title}
      >
        <div className="modal__content">
          <CloseModal.Provider value={handleExternalClose}>{props.children}</CloseModal.Provider>
        </div>
      </CustomModal>
    </>
  );
}

modalWithTrigger.defaultProps = {
  disabled: false,
};
