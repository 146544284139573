import * as React from 'react';

import { Droppable } from 'react-beautiful-dnd';

import Context from '../../Context';

const classNames = require('classnames');

export default function backPage({ index, startAt }) {
  const context = React.useContext(Context);
  const { focus, pageColors, reportTemplate } = context.state;
  const { reportTemplateTheme } = reportTemplate;

  const isCustom = reportTemplate.theme === 'custom';

  let color = 'grey';
  if (reportTemplate.theme === 'monitoring') {
    color = 'blue';
  }
  if (reportTemplate.theme === 'portfolio') {
    color = 'green';
  }
  if (reportTemplate.theme === 'consultancy') {
    color = 'orange';
  }

  let documentIssuer = '';
  let address = '';
  let fontColor = '#fff';
  if (isCustom) {
    documentIssuer = reportTemplateTheme.name;
    address = reportTemplateTheme.address;
    fontColor = reportTemplateTheme.headerFontColor;
  }

  const pageClasses = classNames(
    'pdf-page',
    'pdf-page--landscape',
    { 'pdf-page--default': (pageColors[index] && pageColors[index] === 'white') || !pageColors[index] },
    { 'pdf-page--grey': pageColors[index] && pageColors[index] === 'grey' },
  );

  const regulatedText = (
    <p className="pdf-body-copy pdf-body-copy text-white">
      Enhance Wealth Consultancy Limited is regulated by the Jersey Financial Services Commission to conduct investment
      business under the Financial Services (Jersey) Law 1998
    </p>
  );

  const coverStyles = {
    backgroundColor: reportTemplateTheme.headerColor,
    backgroundImage: `url(${reportTemplateTheme.headerBackground})`,
  };

  return (
    <div className={pageClasses}>
      <Droppable droppableId={`droppable-report-${startAt}`} isDropDisabled={focus !== 'report'}>
        {(provided, snapshot) => {
          const styles = {
            background: snapshot.isDraggingOver ? '#FAFAFA' : '',
            height: '100%',
            ...provided.droppableProps.style,
          };
          return (
            <div ref={provided.innerRef} {...provided.droppableProps} style={styles}>
              <div
                className={`pdf-header pdf-header--landscape pdf-header--${color} pdf-back-cover`}
                style={isCustom ? coverStyles : {}}
              >
                <div className="pdf-back-cover__title-wrap">
                  <div className="pdf-body-copy-wrap pdf-body-copy-wrap">
                    <p className="pdf-body-copy pdf-body-copy" style={{ color: fontColor }}>
                      {isCustom && `This document is issued by ${documentIssuer}`}
                    </p>
                    <p className="pdf-body-copy  pdf-body-copy" style={{ color: fontColor }}>
                      {address}
                    </p>
                  </div>
                </div>
              </div>
              {provided.placeholder}
            </div>
          );
        }}
      </Droppable>
    </div>
  );
}
