import * as React from 'react';

import lodashStartcase from 'lodash.startcase';
import { ResizableBox } from 'react-resizable';
import ColorPicker from './ColorPicker';
import Uploader from './Uploader';

import BooleanOption from 'components/shared/forms/BooleanOption';
import CustomHeader from '../pages/coverPage/headers/portrait/CustomHeader';
import LandscapeCustomHeader from '../pages/coverPage/headers/landscape/CustomHeader';

export default function header(props) {
  const {
    colors,
    handleAttributeChange,
    handleColorChange,
    handleHeaderBackgroundUpload,
    handleResize,
    handleResizeFinish,
    handleGetTheme,
    headerHeight,
    headerId,
    headerLogoId,
    headerLogoName,
    headerName,
    headerLogoHeight,
    headerLogoPosition,
    headerLogoSrc,
    handleHeaderLogoUpload,
    headerSrc,
    reportTemplate,
    reportTemplateTheme,
    theme,
    titleFirstLine,
    titleLetterSpacing,
    titleSecondLine,
    titleThirdLine,
    titleUppercase,
  } = props;

  const initialHeaderHeight = 300 * (colors.headerHeight / 100);

  const isLandscape = reportTemplate.orientation === 'landscape';

  const headerStyles = {
    alignItems: 'center',
    background: colors.headerColor,
    borderBottom: `2px solid ${colors.headerBorderColor}`,
    color: colors.headerFontColor,
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
  };

  const headerLogoOptions = ['left', 'right'].map((position) => {
    return (
      <div className="col-tn-1-3" key={position}>
        <div className="pretty pretty--large pretty--with-label p-round">
          <input
            type="radio"
            value={position}
            id="header_logo_position"
            name="headerLogoPosition"
            onChange={handleAttributeChange}
            defaultChecked={position === headerLogoPosition}
          />
          <div className="state p-blue-circle">
            <label className="text-white">{lodashStartcase(position)}</label>
          </div>
        </div>
      </div>
    );
  });

  function handleBooleanChange(isChecked: boolean) {
    const event = { target: { name: 'titleUppercase', value: isChecked } };
    handleAttributeChange(event);
  }

  function renderHeaderPreview() {
    if (isLandscape) {
      return (
        <div className="pdf-page pdf-page--landscape" style={{ margin: 0 }}>
          <LandscapeCustomHeader {...{ reportTemplate }} />
        </div>
      );
    }
    return (
      <div className="pdf-page" style={{ margin: 0 }}>
        <CustomHeader reportTemplate={{ reportTemplateTheme }} />
      </div>
    );
  }

  const headerHeightSelector = (
    <div className="col-md-1-1">
      <label htmlFor="" className="form__label">
        Header Height
      </label>
      <div style={{ position: 'relative' }}>
        <ResizableBox
          axis="y"
          width={225}
          height={initialHeaderHeight}
          onResize={handleResize}
          onResizeStop={handleResizeFinish}
          minConstraints={[50, 50]}
          maxConstraints={[300, 300]}
          resizeHandles={['s']}
          handleSize={[20, 20]}
        >
          <div style={headerStyles}>
            <span className="small-uppercase">Header</span>
          </div>
        </ResizableBox>
        <div style={{ background: 'white', height: `${300 - headerHeight}px`, width: '225px' }} />
      </div>
    </div>
  );

  const headerLogoPositionSelect = (
    <div className="col-md-1-5">
      <label htmlFor="" className="form__label">
        Header Logo Position
      </label>
      <div className="frow frow--gutters">{headerLogoOptions}</div>
    </div>
  );

  return (
    <fieldset className="form__fieldset">
      <h4 className="form__fieldset-label">Header</h4>
      <div className="frow frow--gutters-2x">
        <div className="col-md-1-3 col-lg-1-5">
          <ColorPicker
            attribute="headerColor"
            color={colors.headerColor}
            disabled={theme !== 'custom'}
            label="Header Background Color"
            handleColorChange={handleColorChange}
          />
        </div>
        <div className="col-md-1-3 col-lg-1-5">
          <ColorPicker
            attribute="headerBorderColor"
            color={colors.headerBorderColor}
            disabled={theme !== 'custom'}
            label="Header Bottom Border Color"
            handleColorChange={handleColorChange}
          />
        </div>
        <div className="col-md-1-3 col-lg-1-5">
          <ColorPicker
            attribute="headerFontColor"
            color={colors.headerFontColor}
            disabled={theme !== 'custom'}
            label="Header Font Color"
            handleColorChange={handleColorChange}
          />
        </div>
        <div className="col-md-1-1">
          <div className="frow frow--gutters-2x">
            {!isLandscape && headerLogoPositionSelect}
            <div className="col-md-1-5">
              <label htmlFor="" className="form__label">
                Header Logo Height
              </label>
              <input
                type="number"
                name="headerLogoHeight"
                onChange={handleAttributeChange}
                defaultValue={headerLogoHeight}
              />
            </div>
            <div className="col-md-1-5">
              <label htmlFor="" className="form__label">
                Header Letter Spacing
              </label>
              <input
                type="number"
                name="titleLetterSpacing"
                onChange={handleAttributeChange}
                defaultValue={titleLetterSpacing}
              />
            </div>
          </div>
        </div>
        <div className="col-mc-1-1">
          <div className="frow frow--gutters-2x">
            <div className="col-md-1-5">
              <label htmlFor="" className="form__label">
                Title First Line
              </label>
              <input type="text" name="titleFirstLine" onChange={handleAttributeChange} defaultValue={titleFirstLine} />
            </div>
            <div className="col-md-1-5">
              <label htmlFor="" className="form__label">
                Title Second Line
              </label>
              <input
                type="text"
                name="titleSecondLine"
                onChange={handleAttributeChange}
                defaultValue={titleSecondLine}
              />
            </div>
            <div className="col-md-1-5">
              <label htmlFor="" className="form__label">
                Title Third Line
              </label>
              <input type="text" name="titleThirdLine" onChange={handleAttributeChange} defaultValue={titleThirdLine} />
            </div>
          </div>
        </div>
        <div className="col-mc-1-1">
          <label className="form__label">Uppercase Title?</label>
          <BooleanOption name="titleUppercase" defaultValue={titleUppercase} changeHandler={handleBooleanChange} />
        </div>
        {!isLandscape && headerHeightSelector}
        <div className="col-md-1-1">
          <label htmlFor="" className="form__label">
            Header Logo
          </label>
          <Uploader
            attachmentId={headerLogoId}
            handleUpload={handleHeaderLogoUpload}
            imageName={headerLogoName}
            imageSrc={headerLogoSrc}
          />
        </div>
        <div className="col-md-1-1">
          <label htmlFor="" className="form__label">
            Header Background Image
          </label>
          <Uploader
            attachmentId={headerId}
            handleUpload={handleHeaderBackgroundUpload}
            imageName={headerName}
            imageSrc={headerSrc}
          />
        </div>
        <div className="col-md-1-1">
          <div className="frow frow--gutters-2x frow--items-center mar-b-2">
            <div>
              <h4 className="form__fieldset-label mar-b-0">Header Preview</h4>
            </div>
            <div>
              <div onClick={handleGetTheme} className="link link--right-arrow">
                Refresh
              </div>
            </div>
          </div>
          {renderHeaderPreview()}
        </div>
      </div>
    </fieldset>
  );
}
