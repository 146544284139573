import * as React from 'react';
import * as $ from 'jquery';

import CustomModal from 'components/shared/CustomModal';
import AutoResizeTextArea from 'components/shared/forms/AutoResizeTextArea';
import Button from 'components/shared/forms/Button';

import CsrfToken from 'components/shared/CsrfToken';
import { ajaxErrorString } from 'components/shared/Utils';

import ReviewContext from '../ReviewContext';

export default function edit() {
  const reviewContext = React.useContext(ReviewContext);
  const { review } = reviewContext.props;
  const { status } = review.attributes;
  const { handleReviewUpdate } = reviewContext.actions;

  const { canEdit } = review.attributes;
  const reviewRating = review.attributes.rating.data.attributes;
  const ratingUpdatePath = review.attributes.rating.data.links.updatePath;

  const [errorMessage, setErrorMessage] = React.useState('');
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);

  const [rating, setRating] = React.useState(reviewRating.value);
  const [ratingChangeReason, setRatingChangeReason] = React.useState('');

  if (!canEdit) return null;

  function handleClose() {
    setIsOpen(false);
  }

  function handleOpen() {
    setIsOpen(true);
  }

  function handleRatingChangeReason(value) {
    setRatingChangeReason(value);
  }

  function handleRatingChange(event) {
    setRating(parseInt(event.target.value, 10));
  }

  function handleSubmit() {
    if (!ratingChangeReason || !rating) return;

    $.ajax({
      beforeSend: () => setIsSubmitting(true),
      data: { rating: { value: rating, audit_comment: ratingChangeReason }, authenticity_token: CsrfToken() },
      dataType: 'json',
      error: (jqXhr) => {
        setIsSubmitting(false);
        setErrorMessage(ajaxErrorString(jqXhr));
      },
      success: () => {
        setRatingChangeReason('');
        setErrorMessage('');
        setIsSubmitting(false);
        setIsOpen(false);
        handleReviewUpdate();
      },
      type: 'PATCH',
      url: ratingUpdatePath,
    });
  }

  return (
    <React.Fragment>
      <button className="metric__button-icon" onClick={handleOpen}>
        <i className="icon-edit" />
      </button>
      <CustomModal handleClose={handleClose} isOpen={isOpen} title="Change Rating">
        <div className="modal__content">
          <div className="form form--light">
            <div className="frow frow--gutters">
              <div className="col-mc-1-1">
                <label htmlFor="" className="form__label">
                  Rating
                </label>
                <div className="frow frow--gutters">
                  <div className="col-mc-1-1">
                    <div className="pretty pretty--large pretty--with-label p-round">
                      <input
                        type="radio"
                        name="rating[value]"
                        value="1"
                        checked={rating === 1}
                        onChange={handleRatingChange}
                      />
                      <div className="state p-blue-circle">
                        <label className="">1</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-mc-1-1">
                    <div className="pretty pretty--large pretty--with-label p-round">
                      <input
                        type="radio"
                        name="rating[value]"
                        value="2"
                        checked={rating === 2}
                        onChange={handleRatingChange}
                      />
                      <div className="state p-blue-circle">
                        <label className="">2</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-mc-1-1">
                    <div className="pretty pretty--large pretty--with-label p-round">
                      <input
                        type="radio"
                        name="rating[value]"
                        value="3"
                        checked={rating === 3}
                        onChange={handleRatingChange}
                      />
                      <div className="state p-blue-circle">
                        <label className="">3</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-mc-1-1">
                    <div className="pretty pretty--large pretty--with-label p-round">
                      <input
                        type="radio"
                        name="rating[value]"
                        value="4"
                        checked={rating === 4}
                        onChange={handleRatingChange}
                      />
                      <div className="state p-blue-circle">
                        <label className="">4</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-mc-1-1">
                    <div className="pretty pretty--large pretty--with-label p-round">
                      <input
                        type="radio"
                        name="rating[value]"
                        value="5"
                        checked={rating === 5}
                        onChange={handleRatingChange}
                      />
                      <div className="state p-blue-circle">
                        <label className="">5</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-mc-1-1">
                <label htmlFor="rejection_reason" className="form__label">
                  Reason for Changing Rating
                </label>
                <AutoResizeTextArea
                  name="rejection_reason"
                  id="rejection_reason"
                  value={ratingChangeReason}
                  rows={10}
                  placeholder="Please provide a reason for changing the system generated rating"
                  onChange={handleRatingChangeReason}
                />
              </div>
              <div className="col-mc-1-1">
                <Button
                  buttonClasses="button--compact mar-r-1"
                  isDisabled={!ratingChangeReason || !rating}
                  isSubmitting={isSubmitting}
                  handleClick={handleSubmit}
                  submittingText="Changing Rating"
                  text="Submit"
                />
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </React.Fragment>
  );
}
