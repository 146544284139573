import * as React from 'react';

import { format } from 'date-fns';
import { portalClientManagerBreakdownsPath } from 'javascript/application/ts_routes';
import Loading from '../../../shared/Loading';
import useAjaxRequest from '../../../shared/customHooks/useAjaxRequest';
import useRadioSelect from '../../../shared/customHooks/useRadioSelect';
import ClientPortalCard from '../../shared/ClientPortalCard';
import RenderData from './imBreakdown/RenderData';

const imBreakdown = ({ clientId, selectedDate, filterState }) => {
  const [managers, setManagers] = React.useState();
  const [selectedType, setSelectedType] = React.useState('top_5_managers');

  React.useEffect(() => {
    getBreakdownData({ date: format(selectedDate, 'YYYY-MM-DD') });
  }, [selectedDate]);

  const requestPath = portalClientManagerBreakdownsPath(clientId);
  const [getBreakdownData, , isLoading] = useAjaxRequest<{ date: string }, any>({
    method: 'GET',
    path: requestPath,
    stateSetter: setManagers,
  });

  const radioOptions = [
    { label: 'Top 5', value: 'top_five_managers' },
    { label: 'Full List', value: 'table' },
  ];

  const [radioToggle, selectedRadioRef] = useRadioSelect(radioOptions, 0);
  React.useEffect(() => setSelectedType(radioOptions[selectedRadioRef.current].value), [selectedRadioRef.current]);

  const titleControls = <div className="frow">{radioToggle}</div>;

  return (
    <ClientPortalCard {...{ title: 'Breakdown by Investment Manager', titleControls }}>
      {isLoading ? <Loading /> : <RenderData {...{ filterState, managers, selectedType }} />}
    </ClientPortalCard>
  );
};

export default imBreakdown;
