import * as React from 'react';

import ClientRow from './ClientRow';

import Loading from 'components/shared/Loading';

export default function presenter({ clients, getClients, isLoading }) {
  const clientSections = clients.map((client) => {
    return <ClientRow key={client.id} {...{ client, getClients }} />;
  });

  const loadingSection = <Loading flexDirection="column" />;

  return (
    <div className="platform-panel">
      <header className="platform-panel__header">
        <div className="frow frow--items-center frow--justify-between">
          <h3 className="platform-panel__title">Available to Post</h3>
        </div>
      </header>
      <div className="frow">{isLoading ? loadingSection : clientSections}</div>
    </div>
  );
}
