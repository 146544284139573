import * as React from 'react';

import Attribute from './attributes/Attribute';

import {
  UploadTemplateAdditionalAttribute,
  UploadTemplateSheet,
} from 'javascript/models';

interface IProps {
  uploadTemplateSheet: UploadTemplateSheet;
}

export default function attributes(props: IProps) {
  const { uploadTemplateSheet } = props;

  const [uploadTemplateAdditionalAttributes, setUploadTemplateAdditionalAttributes] = React.useState(
    uploadTemplateSheet.uploadTemplateAdditionalAttributes,
  );

  React.useEffect(() => {
    setUploadTemplateAdditionalAttributes(uploadTemplateSheet.uploadTemplateAdditionalAttributes);
  }, [uploadTemplateSheet]);

  async function handleNewAttribute() {
    const newAttribute = new UploadTemplateAdditionalAttribute({ uploadTemplateSheetId: uploadTemplateSheet.id });
    const success = await newAttribute.save();

    if (success) {
      const allAttributes = [...uploadTemplateAdditionalAttributes];
      allAttributes.push(newAttribute);
      setUploadTemplateAdditionalAttributes(allAttributes);
    }
  }

  async function handleDestroyAttribute(attributeId) {
    const attribute = uploadTemplateAdditionalAttributes.find((attribute) => attribute.id === attributeId);
    const success = await attribute.destroy();
    if (success) {
      const newAttributes = [...uploadTemplateAdditionalAttributes];
      const index = newAttributes.indexOf(attribute);
      newAttributes.splice(index, 1);
      setUploadTemplateAdditionalAttributes(newAttributes);
    }
  }

  const attributes = uploadTemplateAdditionalAttributes.map((attribute, index) => {
    return (
      <Attribute
        key={attribute.id}
        uploadTemplateAdditionalAttribute={attribute}
        {...{ handleDestroyAttribute, index }}
      />
    );
  });

  return (
    <div>
      <label htmlFor="" className="form__label">
        Attributes
      </label>
      <div style={{ width: '100%', overflowX: 'scroll', paddingBottom: '20px' }}>
        <div className="frow frow--gutters frow--nowrap">
          {attributes}
          <div>
            <div
              className="card frow frow--centered-column frow--items-center fa-clickable"
              style={{ width: '250px', minHeight: '300px' }}
              onClick={handleNewAttribute}
            >
              <h4
                className="card__title card__title--small"
                style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
              >
                <i className="icon-plus icon-fw mar-r-1 icon-push-down-1" /> Add Attribute
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
