import * as React from 'react';

import { default as ModalWithTrigger, IModalWithTriggerProps } from './ModalWithTrigger';

import lodashStartCase from 'lodash.startcase';

export interface IModelModal {
  addOrEdit: 'add' | 'edit';
  children?: JSX.Element;
  modalWithTriggerProps?: Partial<IModalWithTriggerProps>;
  modelName: string;
}

export class ModelModalError extends Error {}

export default function modelModal(props: IModelModal) {
  const { addOrEdit, modalWithTriggerProps, modelName } = props;

  let buttonIcon: JSX.Element;
  let title: string;

  switch (addOrEdit) {
    case 'add':
      buttonIcon = <i className="icon-plus icon-fw icon-push-down-1 mar-l-1" />;
      title = `Add New ${modelName}`;
      break;
    case 'edit':
      title = `Edit ${modelName}`;
      break;
    default: {
      throw new ModelModalError('Unexpected type error, check addOrEdit');
    }
  }

  const renderButton = () => {
    return (
      <>
        {lodashStartCase(`${addOrEdit} ${modelName}`)}
        {buttonIcon}
      </>
    );
  };

  return (
    <>
      <ModalWithTrigger
        buttonClassName="button--secondary"
        buttonText={renderButton()}
        title={title}
        {...modalWithTriggerProps}
      >
        {props.children}
      </ModalWithTrigger>
    </>
  );
}
