/*
 * Takes a Map(`map`) and a generator(`proxy`) and an initial object(`obj`).
 *   Returns a Proxy which attempts to find the property mapped through `map` value in `obj` failing that
 *   invoke proxy with the same arguments as the proxy getter.
 */
const mappedKeyCachingProxy = (map, proxy, obj) => {
  const newObj = { ...obj };

  const cachingProxyGetter = {
    get: (target, property, ...rest) => {
      const mappedKey = map.get(property);
      return  target[mappedKey] = target[mappedKey] || proxy(target, property, ...rest);
    },
  };

  return new Proxy(newObj, cachingProxyGetter);
};

export default mappedKeyCachingProxy;
