import * as React from 'react';

import lodashStartcase from 'lodash.startcase';

import CustomOptions from './theme/CustomOptions';

import FormRow from 'components/shared/forms/FormRow';
import Loading from 'components/shared/Loading';
import { ReportTemplate } from 'javascript/models';
import { ReportTemplateTheme } from 'javascript/models';

interface IProps {
  reportTemplate: ReportTemplate;
}

export default function theme(props: IProps) {
  const [reportTemplate, setReportTemplate] = React.useState(props.reportTemplate);
  const [reportTemplateTheme, setReportTemplateTheme] = React.useState();
  const [theme, setTheme] = React.useState();
  const [logoSrc, setLogoSrc] = React.useState();
  const [logoId, setLogoId] = React.useState();
  const [logoName, setLogoName] = React.useState();
  const [footerSrc, setFooterSrc] = React.useState();
  const [footerId, setFooterId] = React.useState();
  const [footerName, setFooterName] = React.useState();
  const [component, setComponent] = React.useState(null);

  // Header
  const [headerSrc, setHeaderSrc] = React.useState();
  const [headerId, setHeaderId] = React.useState();
  const [headerName, setHeaderName] = React.useState();
  const [headerLogoPosition, setHeaderLogoPosition] = React.useState();
  const [headerLogoHeight, setHeaderLogoHeight] = React.useState();
  const [headerLogoSrc, setHeaderLogoSrc] = React.useState();
  const [headerLogoId, setHeaderLogoId] = React.useState();
  const [headerLogoName, setHeaderLogoName] = React.useState();
  const [titleLetterSpacing, setTitleLetterSpacing] = React.useState();
  const [titleFirstLine, setTitleFirstLine] = React.useState();
  const [titleSecondLine, setTitleSecondLine] = React.useState();
  const [titleThirdLine, setTitleThirdLine] = React.useState();
  const [titleUppercase, setTitleUppercase] = React.useState();

  // Colors
  const [colors, setColors] = React.useState();

  React.useEffect(() => {
    if (props.reportTemplate) {
      setReportTemplate(props.reportTemplate);
      setTheme(props.reportTemplate.theme);
      setComponent(props.reportTemplate.component);
      if (props.reportTemplate.reportTemplateTheme) {
        setReportTemplateTheme(props.reportTemplate.reportTemplateTheme);
        setColors(props.reportTemplate.reportTemplateTheme.attributes);

        setLogoSrc(props.reportTemplate.reportTemplateTheme.logo);
        setLogoId(props.reportTemplate.reportTemplateTheme.logoId);
        setLogoName(props.reportTemplate.reportTemplateTheme.logoName);

        setHeaderLogoSrc(props.reportTemplate.reportTemplateTheme.headerLogo);
        setHeaderLogoId(props.reportTemplate.reportTemplateTheme.headerLogoId);
        setHeaderLogoName(props.reportTemplate.reportTemplateTheme.headerLogoName);

        setHeaderSrc(props.reportTemplate.reportTemplateTheme.headerBackground);
        setHeaderId(props.reportTemplate.reportTemplateTheme.headerBackgroundId);
        setHeaderName(props.reportTemplate.reportTemplateTheme.headerBackgroundName);
        setHeaderLogoPosition(props.reportTemplate.reportTemplateTheme.headerLogoPosition);
        setHeaderLogoHeight(props.reportTemplate.reportTemplateTheme.headerLogoHeight);
        setTitleLetterSpacing(props.reportTemplate.reportTemplateTheme.titleLetterSpacing);
        setTitleFirstLine(props.reportTemplate.reportTemplateTheme.titleFirstLine);
        setTitleSecondLine(props.reportTemplate.reportTemplateTheme.titleSecondLine);
        setTitleThirdLine(props.reportTemplate.reportTemplateTheme.titleThirdLine);
        setTitleUppercase(props.reportTemplate.reportTemplateTheme.titleLetterSpacing);

        setFooterSrc(props.reportTemplate.reportTemplateTheme.footerBackground);
        setFooterId(props.reportTemplate.reportTemplateTheme.footerBackgroundId);
        setFooterName(props.reportTemplate.reportTemplateTheme.footerBackgroundName);
      }
    }
  }, [props.reportTemplate]);

  async function handleColorChange(attribute: string, color: { hex: string }) {
    reportTemplateTheme[attribute] = color.hex;
    const success = await reportTemplateTheme.save();
    if (success) {
      const newColors = { ...colors };
      newColors[attribute] = color.hex;
      setColors(newColors);
    }
  }

  async function handleAttributeChange(event) {
    reportTemplateTheme[event.target.name] = event.target.value;
    await reportTemplateTheme.save();
  }

  async function handleUpload(attribute, signedId: string) {
    reportTemplateTheme[attribute] = signedId;
    await reportTemplateTheme.save();
  }

  let themeOptions = component ? [] : ['monitoring', 'portfolio', 'consultancy', 'group', 'custom'];

  if (!reportTemplate) return <Loading />;

  async function handleThemeChange() {
    const updatedReportTemplate = reportTemplate;
    updatedReportTemplate.theme = this;
    const success = await updatedReportTemplate.save();
    if (success) {
      setTheme(this);
      handleGetTheme();
    }
  }

  async function handleGetTheme() {
    const { data } = await ReportTemplateTheme.find(reportTemplateTheme.id);
    setReportTemplateTheme(data);
    setColors(data.attributes);
  }

  // if (reportTemplate.orientation === 'landscape') {
  //   themeOptions = ['monitoring', 'portfolio', 'consultancy', 'group'];
  // }

  const themes = themeOptions.map((option) => {
    return (
      <div className="col-tn-1-3" key={option}>
        <div className="pretty pretty--large pretty--with-label p-round">
          <input
            type="radio"
            value={option}
            id="type"
            onChange={handleThemeChange.bind(option)}
            checked={theme === option}
          />
          <div className="state p-blue-circle">
            <label className="text-white">{lodashStartcase(option)}</label>
          </div>
        </div>
      </div>
    );
  });

  const componentThemes = () => <h3>Component Report Templates inherit their theme from their parent template.</h3>;

  const customOptions = (
    <CustomOptions
      {...{
        colors,
        footerId,
        footerName,
        footerSrc,
        handleAttributeChange,
        handleColorChange,
        handleGetTheme,
        handleUpload,
        headerId,
        headerLogoHeight,
        headerLogoId,
        headerLogoName,
        headerLogoPosition,
        headerLogoSrc,
        headerName,
        headerSrc,
        logoId,
        logoName,
        logoSrc,
        reportTemplate,
        reportTemplateTheme,
        theme,
        titleFirstLine,
        titleLetterSpacing,
        titleSecondLine,
        titleThirdLine,
        titleUppercase,
      }}
    />
  );

  return (
    <div className="form">
      <fieldset className="form__fieldset">
        <label htmlFor="" className="form__label">
          Theme
        </label>
        <div className="frow frow--gutters">
          <FormRow>
            <div className="frow frow--gutters">{component ? componentThemes() : themes}</div>
          </FormRow>
        </div>
      </fieldset>
      {theme === 'custom' && customOptions}
    </div>
  );
}
