import * as React from 'react';
import * as $ from 'jquery';

import FormRow from 'components/shared/forms/FormRow';
import ReactSelect from 'components/shared/forms/ReactSelect';

import { platformXeroTenantBrandingThemesPath } from 'javascript/application/ts_routes';

import ISelectOption from 'components/interfaces/ISelectOption';

interface IProps {
  options: ISelectOption[];
  selectedTenant: string;
  selectedBrandingTheme: string;
}

export default function xeroTenant(props: IProps) {
  const [selectedTenant, setSelectedTenant] = React.useState(props.selectedTenant);
  const [brandingTemplateOptions, setBrandingTemplateOptions] = React.useState([]);
  const [selectedBrandingTheme, setSelectedBrandingTheme] = React.useState(props.selectedBrandingTheme);

  function handleTenantChange(selectedOption) {
    setSelectedTenant(selectedOption.value);
  }

  function handleBrandingChange(selectedOption) {
    setSelectedBrandingTheme(selectedOption.value);
  }

  React.useEffect(() => {
    if (selectedTenant) {
      getBrandingTemplates();
    }
  }, [selectedTenant]);

  function getBrandingTemplates() {
    $.ajax({
      cache: false,
      dataType: 'json',
      success: (brandingThemes) => {
        const newBrandingThemeOptions = brandingThemes.map((theme) => {
          return { label: theme.Name, value: theme.BrandingThemeID };
        });
        setBrandingTemplateOptions(newBrandingThemeOptions);
      },
      type: 'GET',
      url: platformXeroTenantBrandingThemesPath(selectedTenant),
    });
  }

  const defaultTenant = props.options.find((option) => option.value === selectedTenant);
  let brandingTemplateSelection;
  if (selectedBrandingTheme) {
    brandingTemplateSelection = brandingTemplateOptions.find((option) => option.value === selectedBrandingTheme);
  }

  return (
    <div className="frow frow--gutters">
      <FormRow note="This is the Xero Tenant that the client will post invoices to">
        <label htmlFor="xero_tenant" className="form__label">
          Xero Tenant
        </label>
        <ReactSelect
          defaultValue={defaultTenant}
          id="xero_tenant"
          name="client[xero_tenant_id]"
          options={props.options}
          theme="dark"
          handleChange={handleTenantChange}
        />
      </FormRow>
      <FormRow note="This is the Xero branding theme that will be applied to the invoices">
        <label htmlFor="xero_branding_theme" className="form__label">
          Xero Branding Theme
        </label>
        <ReactSelect
          id="xero_tenant"
          name="client[xero_branding_theme]"
          options={brandingTemplateOptions}
          placeholder={selectedTenant ? 'Please Select' : 'Select a Xero Tenant'}
          theme="dark"
          value={brandingTemplateSelection}
          handleChange={handleBrandingChange}
        />
      </FormRow>
    </div>
  );
}
