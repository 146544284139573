import * as React from 'react';

import NextReviewQuarter from './NextReviewQuarter';
import Statuses from './itemsByStatus/Statuses';

import IPieChartData from '../../../shared/charting/recharts/interfaces/IPieChartData';

import { IFilterOptions } from '../shared/IFilterOptions';

interface IProps {
  admin: number | string;
  canCreateReviews: boolean;
  client_type: number | string;
  companyId: number;
  data: IPieChartData[];
  filterOptions?: IFilterOptions;
  isLoading: boolean;
  quarter: number;
  year: number;
}

export default function itemsByStatus(props: IProps) {
  const { admin, canCreateReviews, client_type, companyId, data, filterOptions, isLoading, quarter, year } = props;

  return (
    <div className="frow frow--gutters">
      <div className="col-md-3-4">
        <div className="platform-panel">
          <header className="platform-panel__header">
            <h3 className="platform-panel__title">Queued Items by Status</h3>
          </header>
          <div className="platform-panel__inner pad-a-5">
            <div className="frow frow--direction-column frow--gutters">
              <Statuses {...{ admin, client_type, companyId, data, filterOptions, isLoading, quarter, year }} />
            </div>
          </div>
        </div>
      </div>
      {canCreateReviews && (
        <div className="col-md-1-4">
          <div className="platform-panel">
            <header className="platform-panel__header">
              <h4 className="platform-panel__title">Next Review Period</h4>
            </header>
            <div className="platform-content platform-content--padding-all">
              <NextReviewQuarter />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
