import { Attr, Model } from 'spraypaint';
import { ApplicationRecord } from './ApplicationRecord';

@Model()
export class ManagerUser extends ApplicationRecord {
  public static jsonapiType = 'manager_users';
  @Attr() public fullName: string;
  @Attr() public email: string;
  @Attr() public signInCount: number;
  @Attr() public lastSignInAt: string;
  @Attr() public showPath: string;
}
