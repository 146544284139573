import * as React from 'react';

import { Frow, Column } from 'components/frow';
import ThemeContext from 'components/shared/context/ThemeContext';
import PermissionBreakdown from 'components/platform/administrators/permissions/PermissionBreakdown';
import { TPermissionGroups } from 'components/platform/clients/Show';
import Button from 'components/shared/forms/Button';
import CloseModal from 'components/modals/context/CloseModal';
import { Client, ClientUserRole } from 'javascript/models';
import StyledWarning from 'components/shared/styledAlerts/StyledWarning';

export interface IFormProps {
  groupedPermissions: TPermissionGroups;
  roleable: Client;
  canEditPermissions: boolean;
  formContent?: (props) => JSX.Element;
  addOrEdit: string;
}

interface IRoleSelection {
  label: string;
  value: string;
}

const Form = ({ groupedPermissions, roleable, canEditPermissions, formContent }: IFormProps) => {
  const [userRole, setUserRole] = React.useState<ClientUserRole>();
  const [roleName, setRoleName] = React.useState('');
  const [definedRoleNames, setDefinedRoleNames] = React.useState([]);
  const [definedRoles, setDefinedRoles] = React.useState<ClientUserRole[]>();
  const [roleSelection, setRoleSelection] = React.useState<IRoleSelection>();

  const theme = React.useContext(ThemeContext);
  const buttonClassName = `button--full-width button--${theme.classNameModifier}-themed`;
  const closeAPModal = React.useContext(CloseModal);

  React.useEffect(() => {
    getRolesForClient();
  }, []);

  const handleRoleEditChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRoleName(event.target.value);
  };

  const handleRoleNameChange = (event) => {
    setRoleSelection(event);
    setRoleName(event.label);
    setUserRole(definedRoles.find((role) => role.name === event.value));
  };

  const getRolesForClient = async () => {
    let roles = (await ClientUserRole.where({ roleable_id: roleable.id }).includes(['permissions']).all()).data;
    setDefinedRoles(roles);
    setDefinedRoleNames(
      roles.map((role) => {
        return { label: role.name, value: role.name };
      }, []),
    );
    setUserRole(new ClientUserRole({ client: roleable }));
  };

  const save = async () => {
    userRole.name = roleName;
    const saveResult = await userRole.save({ with: ['client', 'permissions'], displayQueryResult: true });

    if (saveResult) closeAPModal();
  };

  const localCanEditPermissions = canEditPermissions && userRole && !userRole.lockedForEditing;

  return (
    <div className={`form--${theme.classNameModifier}`}>
      <Column breakpointSize="sm" columnSpan={1} maxColumns={1}>
        <div className="platform-content--padding-all">
          <Frow gutterSize={1}>
            <Column breakpointSize="sm" columnSpan={3} maxColumns={4}>
              <div className="col-mc-1-1" key={roleSelection ? roleSelection.label : 'select'}>
                <label htmlFor="status" className="form__label">
                  Role Name:
                </label>
                {formContent({
                  selectedValue: roleSelection,
                  options: definedRoleNames,
                  handleSelChange: handleRoleNameChange,
                  handleEditChange: handleRoleEditChange,
                })}
              </div>
              {!localCanEditPermissions && (
                <StyledWarning
                  message="You cannot edit one of the 'default' permissions"
                  additionalClasses={['mar-t-1']}
                />
              )}
            </Column>
          </Frow>
        </div>

        <div className="platform-content--padding-all">
          <Frow gutterSize={2}>
            {userRole && (
              <PermissionBreakdown
                {...{
                  ...{ permissionable: userRole },
                  groupedPermissions,
                  canEditPermissions: localCanEditPermissions,
                }}
              />
            )}
          </Frow>
        </div>
        <div className="platform-content--padding-horizontal platform-content--padding-top">
          <Column breakpointSize="mc" columnSpan={1} maxColumns={1}>
            <Button
              isDisabled={!localCanEditPermissions}
              buttonClasses={buttonClassName}
              style={{ minWidth: 'unset' }}
              text="Save"
              handleClick={save}
            />
          </Column>
        </div>
      </Column>
    </div>
  );
};

export default Form;
