import * as React from 'react';
import { DirectUpload } from 'activestorage';

import Alert from 'components/shared/Alert';
import { createAlert, graphitiErrorString } from 'components/shared/Utils';
import Dropzone from 'components/shared/forms/Dropzone';

import { PortfolioManualUpload } from 'javascript/models';

export default function uploader({ handleUpload, portfolioId }) {
  const [alert, setAlert] = React.useState(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  function handleUploadedFiles(files) {
    if (files.length < 1) return;

    setIsSubmitting(true);
    const upload = new DirectUpload(files[0], '/rails/active_storage/direct_uploads');

    upload.create((error, blob) => {
      if (error) {
        setIsSubmitting(false);
        setAlert({ message: 'There was an error uploading this file', type: 'error', title: 'Upload Error' });
      } else {
        const signedId = blob.signed_id;
        createManualUpload(signedId).then(() => {
          setIsSubmitting(false);
          const customEvent = new CustomEvent('trackedJob:new');
          document.dispatchEvent(customEvent);
        });
      }
    });
  }

  async function createManualUpload(signedId) {
    const portfolioManualUpload = new PortfolioManualUpload({ portfolio_id: portfolioId, import: signedId });

    const success = await portfolioManualUpload.save();
    if (success) {
      createAlert('success', 'File Uploaded', 1500);
      handleUpload();
      // handleClose();
    } else {
      setAlert({ message: graphitiErrorString(portfolioManualUpload), type: 'error', title: 'Upload Error' });
    }
  }

  function renderAlert() {
    if (!alert) return;

    return <Alert isCloseable modifier={'full-width'} {...alert} />;
  }

  return (
    <React.Fragment>
      {renderAlert()}
      <div className="h-100">
        <Dropzone
          acceptedFiles=".csv, .xls, .xlt, .xlm, .xlsx, .xlsm, .xltx, .xltm, .xlsb, .xla, .xlam, .xll, .xlw"
          shouldDiscardFiles
          isLarge
          isSubmitting={isSubmitting}
          onFileUpload={handleUploadedFiles}
          resetFiles
          shouldShowFiles={false}
        />
      </div>
    </React.Fragment>
  );
}
