import React from 'react';

import CustomAlert, { IAlertProps } from '../Alert';

export interface IStyledWarning {
  message: string;
  additionalClasses?: IAlertProps['additionalClasses'];
}

const StyledWarning = ({ message, additionalClasses }: IStyledWarning) => (
  <CustomAlert message={message} modifier="dark" title="" type="info" additionalClasses={additionalClasses} />
);

export default StyledWarning;
