import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

const useLocalStorage = <Data extends unknown, Fallback extends unknown>(
  identifier: string,
  fallback: Fallback,
  isNumber = false,
) => {
  const [currentIdentifier, setCurrentIdentifier] = useState(identifier);

  const getValue = () => {
    let storedItem = JSON.parse(localStorage.getItem(currentIdentifier));

    if (isNumber) storedItem = parseInt(storedItem, 10);

    return storedItem ? (storedItem as Data) : fallback;
  };

  const [storedValue, setStoredValue] = useState(getValue());

  useEffect(() => setStoredValue(getValue()), [currentIdentifier]);

  const setItem: Dispatch<SetStateAction<Data>> = useCallback(
    (itemToStore) =>
      setStoredValue((previousValue) => {
        const newValue =
          typeof itemToStore === 'function'
            ? (itemToStore as (prev: Data | Fallback) => Data)(previousValue)
            : itemToStore;

        localStorage.setItem(currentIdentifier, JSON.stringify(newValue));
        return newValue;
      }),
    [currentIdentifier],
  );

  return {
    setCurrentIdentifier,
    setItem,
    storedValue,
  };
};

export default useLocalStorage;
