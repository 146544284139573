import * as React from 'react';

import TextareaAutosize from 'react-autosize-textarea';

import { ActionPoint, Note, Review } from 'javascript/models';

import { Column, Frow } from 'components/frow';

import Button from 'components/shared/forms/Button';
import ThemeContext from 'components/shared/context/ThemeContext';
import eventSetProperty from 'javascript/helpers/eventSetProperty';

import FileUpload from './form/FileUpload';

import NewFields from './deferralForm/NewFields';

export interface IDeferralFormProps {
  actionPoint: ActionPoint;
  note?: Note;
  review?: Review;
}

const DeferralForm = (props: IDeferralFormProps) => {
  const { actionPoint, note: existingNote, review } = props;

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const note = React.useMemo(() => existingNote || new Note({ notable: actionPoint }), [existingNote]);

  const theme = React.useContext(ThemeContext);

  const buttonText = {
    submittingText: 'Deferring',
    text: 'Defer',
  };

  if (note.isPersisted) {
    buttonText.text = 'Save';
    buttonText.submittingText = 'Saving';
  }

  const storeOriginalNote = () => {
    let additionalInfo = {};

    try {
      additionalInfo = JSON.parse(note.additionalInfo);
    } catch {}

    note.additionalInfo = JSON.stringify({ ...additionalInfo, originalNote: note.body });
  };
  const onSave = () => {
    storeOriginalNote();
    return actionPoint.save();
  };

  const onDefer = () => {
    actionPoint.deferralNotes = Array.from(new Set([...actionPoint.deferralNotes, note]));

    storeOriginalNote();
    actionPoint.deferredNote = note.body;
    actionPoint.assigneeId = null;
    actionPoint.assigneeType = null;
    return actionPoint.save();
  };

  const buttonAction = async () => {
    setIsSubmitting(true);
    (await note.isPersisted) ? onSave() : onDefer();
    setIsSubmitting(false);
  };

  let defaultBodyText = note.body;
  try {
    const additionalInfo = JSON.parse(note.additionalInfo);
    defaultBodyText = additionalInfo.originalNote;
  } catch {}

  return (
    <div className={`form--${theme.classNameModifier}`}>
      <Frow gutterSize={2} columnContext={{ breakpointSize: 'mc', columnSpan: 1, maxColumns: 1 }}>
        <NewFields actionPoint={actionPoint} note={note} review={review} />
        <Column>
          <label htmlFor="note_category" className="form__label">
            Deferral Note
            <span>This will be added as a note to the portfolio</span>
          </label>
          <TextareaAutosize
            defaultValue={defaultBodyText}
            style={{ minHeight: 110, maxHeight: 300 }}
            name="action_point[deferred_note]"
            cols={10}
            id="deferred_note"
            onChange={eventSetProperty(note, 'body')}
          />
        </Column>
        <Column>
          <label className="form__label">Attachments</label>
          <FileUpload accessor="attachments" model={note} />
        </Column>
      </Frow>
      <Frow gutterSize={1}>
        <Column>
          <Button
            buttonClasses={`button--full-width button--${theme.classNameModifier}-themed`}
            style={{ minWidth: 'unset' }}
            isSubmitting={isSubmitting}
            isDisabled={false}
            handleClick={buttonAction}
            {...buttonText}
          />
        </Column>
      </Frow>
    </div>
  );
};

export default DeferralForm;
