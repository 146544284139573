import * as React from 'react';

import Context from '../../Context';
import CustomFooter from './CustomFooter';

export default function footer({ index }) {
  const context = React.useContext(Context);
  const { reportTemplate } = context.state;
  const color = reportTemplate.reportTemplateTheme.footerColor;

  if (reportTemplate.theme === 'custom') {
    return <CustomFooter {...{ index, reportTemplate }} />;
  }

  return (
    <footer className="pdf-footer pdf-footer--landscape pdf-footer--fixed" style={{ background: color }}>
      <table className="pdf-hidden-table" style={{ height: '100%' }}>
        <tbody>
          <tr className="text-bold">
            <td style={{ width: '80%', fontSize: '1.3rem', verticalAlign: 'middle' }}>Report Name. Date</td>
            <td style={{ fontSize: '1.3rem', textAlign: 'right', verticalAlign: 'middle' }}>{index + 1}</td>
          </tr>
        </tbody>
      </table>
    </footer>
  );
}
