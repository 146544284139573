import * as React from 'react';

import { ReportTemplatesReportTemplateSection } from 'javascript/models';
import { Flag } from 'javascript/models';

import RangeSlider from 'components/shared/forms/RangeSlider';

import CriterionHumanizer from 'components/shared/CriterionHumanizer';
import lodashStartcase from 'lodash.startcase';

interface IProps {
  templateSection: ReportTemplatesReportTemplateSection;
  portfolioFlags: any[];
  colors: string[];
  preview: boolean;
}

export default function mandateFlags(props: IProps) {
  const { portfolioFlags, colors, preview } = props;

  const defaultAttr = {
    aggregate: true,
    maximum: 100,
    minimum: 0,
  };

  const defaultFlags: Flag[] = [
    new Flag({ ...defaultAttr, flagType: 'country_of_domicile', criterion: 'united_kingdom_gb_and_ni', maximum: 0 }),
    new Flag({ ...defaultAttr, flagType: 'instrument', criterion: 'mutual_fund', maximum: 10, aggregate: false }),
    new Flag({ ...defaultAttr, flagType: 'currency', criterion: 'gbp', minimum: 50 }),
  ];

  const flags = portfolioFlags || defaultFlags;

  const noMandate = (
    <div className="platform-content platform-content--padding-all">
      <p className="text-black text-small mar-v-0">This portfolio has no mandate information set</p>
    </div>
  );

  const noFlags = <p className="text-small text-black mar-b-0">This portfolio has no flags set</p>;

  const renderFlags = () => {
    return flags.map((flag, index) => {
      const flagType = flag.flagType || flag.flag_type;
      const humanizedCriterion = CriterionHumanizer(flag.criterion, flagType);
      const font = preview ? {} : { fontSize: '10.2px' };
      return (
        <React.Fragment key={index}>
          <tr>
            <td style={{ ...font }}>{lodashStartcase(flagType)}</td>
            <td style={{ ...font }}>{humanizedCriterion}</td>
            <td style={{ ...font }}>{flag.aggregate ? 'Total Investment' : 'Any one Investment'}</td>
            <td style={{ textAlign: 'center', ...font }}>{flag.minimum}</td>
            <td>
              <RangeSlider
                backgroundColor={colors[index % colors.length]}
                marks={{}}
                disabled
                defaultValue={[flag.minimum, flag.maximum]}
              />
            </td>
            <td style={{ textAlign: 'center', ...font }}>{flag.maximum}</td>
          </tr>
        </React.Fragment>
      );
    });
  };

  return (
    <div>
      <h3
        className={`pdf-small-subtitle ${preview ? '' : 'pdf-small-subtitle--print'} ${
          preview ? 'mar-b-1' : 'mar-b-2'
        }`}
      >
        Investment Restrictions
      </h3>
      <table className={`pdf-table ${preview ? '' : 'pdf-table--print'} mar-t-1`} style={{ width: '100%' }}>
        <thead>
          <tr>
            <th style={{ width: '17%' }}>Restriction Class</th>
            <th style={{ width: '15%' }}>Restriction</th>
            <th style={{ width: '23%' }}>Scope</th>
            <th style={{ width: '5%', textAlign: 'center' }} />
            <th style={{ width: '35%', textAlign: 'center' }}>Range</th>
            <th style={{ width: '5%', textAlign: 'center' }} />
          </tr>
        </thead>
        <tbody className="mar-t-1">{flags.length > 0 ? renderFlags() : noFlags}</tbody>
      </table>
    </div>
  );
}
