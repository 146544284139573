import * as React from 'react';

import { Alert, Intent } from '@blueprintjs/core';

import Conditions from '../Conditions';
import DataFormat from './DataFormat';
import useVisibility from 'components/shared/customHooks/useVisibility';

import { UploadTemplateSheetColumn } from 'javascript/models';

interface IProps {
  uploadTemplateSheetColumn: UploadTemplateSheetColumn;
  handleDataFormatChange(format: string): void;
  handleDestroyColumn(columnId: string): void;
  toggleIgnore(): void;
}

export default function options(props: IProps) {
  const { handleDataFormatChange, handleDestroyColumn, toggleIgnore, uploadTemplateSheetColumn } = props;

  function confirmDelete() {
    handleDestroyColumn(uploadTemplateSheetColumn.id);
  }

  const { handleClose, handleOpen, isOpen } = useVisibility(false);

  return (
    <div className="mar-v-2">
      <div className="frow frow--justify-center frow--gutters">
        <div className="col-mc-1-1 text-center">
          <Conditions conditionable={uploadTemplateSheetColumn} conditionableType={'UploadTemplateSheetColumn'} />
          <span className="text-white mar-h-1">-</span>
          <span className="link link--secondary" onClick={toggleIgnore}>
            {uploadTemplateSheetColumn.ignored ? 'Unignore' : 'Ignore'}
          </span>
          <span className="text-white mar-h-1">-</span>
          <span className="link link--secondary" onClick={handleOpen}>
            Delete
          </span>
          <Alert
            isOpen={isOpen}
            onClose={handleClose}
            canEscapeKeyCancel
            canOutsideClickCancel
            cancelButtonText="Cancel"
            confirmButtonText="Delete"
            intent={Intent.DANGER}
            onCancel={handleClose}
            onConfirm={confirmDelete}
            portalContainer={document.body}
          >
            <p className="mar-t-0">Are you sure you want to delete this column?</p>
          </Alert>
        </div>
        <div className="col-mc-1-1 text-center">
          <DataFormat {...{ handleDataFormatChange, uploadTemplateSheetColumn }} />
        </div>
      </div>
    </div>
  );
}
