import * as React from 'react';

import OutsideClickHandler from 'react-outside-click-handler';
import Notification from './Notification';
import useVisibility from 'components/shared/customHooks/useVisibility';

const classNames = require('classnames');
import { platformNotificationsPath } from 'javascript/application/ts_routes';

interface IProps {
  notifications: any;
  pageSize: number;
  totalCount: number;
  unreadCount: number;
  toggleReadStatus(id: string): void;
  handleShowMore(): void;
}

export default function presenter(props: IProps) {
  const { handleShowMore, notifications, pageSize, toggleReadStatus, totalCount, unreadCount } = props;

  const { isOpen, handleClose, toggleOpen } = useVisibility(false);

  const formattedNotifications = notifications.map((notification) => {
    return <Notification key={notification.id} {...{ notification, toggleReadStatus }} />;
  });

  const indicator = <div className="alert-indicator">{unreadCount > 9 ? '!' : unreadCount}</div>;

  const noNotifications = <div className="dropdown-nav-item">You have no unread notifications</div>;

  const showMore = (
    <section className="dropdown-nav-item">
      <span className="link link--secondary" onClick={handleShowMore}>
        Show more
      </span>
    </section>
  );

  const contentClasses = classNames('dropdown-content', 'dropdown-content--with-arrow', 'dropdown-content--floating', {
    'dropdown-content--active': isOpen,
  });

  return (
    <OutsideClickHandler onOutsideClick={handleClose}>
      <div className="dropdown dropdown--floating dropdown--middle">
        <div className="alert-indicator-wrapper" onClick={toggleOpen}>
          <div className="dropdown__toggle">
            <i className="far fa-clickable fa-bell text-white" style={{ fontSize: '2.2rem' }} />
          </div>
          {unreadCount >= 1 && indicator}
        </div>
        <div className={contentClasses} style={{ width: '300px', left: '-715%' }}>
          <div className="dropdown-content__inner">
            {notifications.length >= 1 ? formattedNotifications : noNotifications}
            {pageSize < totalCount && showMore}
          </div>
          <div className="dropdown-content__footer">
            <div className="frow frow--justify-center">
              <div>
                <a href={platformNotificationsPath()} className="link link--right-arrow">
                  See All
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  );
}
