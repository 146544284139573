import * as React from 'react';

import OutsideClickHandler from 'react-outside-click-handler';
import FormTag from 'components/shared/forms/FormTag';
import InclusionDropdown from './InclusionDropdown';

interface IProps {
  autoFocus?: boolean;
  formElement: any;
  formRef: any;
  searchPath: string;
  searches: string[];
  selectedModels: string[];
  selectableModels: string[];
  handleSelectedModel(model: string);
}

export default function presenter(props: IProps) {
  const { formRef, handleSelectedModel, searchPath, selectableModels, selectedModels } = props;

  const [openDropdown, setOpenDropdown] = React.useState(false);
  const [closeDropdown, setCloseDropdown] = React.useState(false);

  function handleFocus() {
    if (props.autoFocus) return;

    setOpenDropdown(true);
  }

  React.useEffect(() => {
    if (openDropdown) {
      setOpenDropdown(false);
    }
  }, [openDropdown]);

  React.useEffect(() => {
    if (closeDropdown) {
      setCloseDropdown(false);
    }
  }, [closeDropdown]);

  const inclusions = selectedModels.map((model, index) => {
    return <input key={index} type="hidden" name="includes[]" value={model} />;
  });

  const buttonStyles = {
    background: 'transparent',
    borderColor: 'transparent',
    outline: 'none',
    padding: '0',
  };

  function handleOutsideClick() {
    setCloseDropdown(true);
  }

  return (
    <OutsideClickHandler onOutsideClick={handleOutsideClick}>
      <FormTag method="get" url={searchPath}>
        {inclusions}
        <div className="frow frow--gutters frow--items-center">
          <div>
            <InclusionDropdown
              {...{ closeDropdown, openDropdown, selectedModels, selectableModels, handleSelectedModel }}
            />
          </div>
          <div className="col-flex-grow-1">
            <input
              type="search"
              className="platform-search__input"
              autoComplete="off"
              name="searches[]"
              defaultValue={props.searches.join(' ')}
              autoFocus={props.autoFocus}
              onFocus={handleFocus}
            />
          </div>
          <div>
            <button className="icon-arrow-right icon-1-2x icon-clickable" ref={formRef} style={buttonStyles} />
          </div>
        </div>
      </FormTag>
    </OutsideClickHandler>
  );
}
