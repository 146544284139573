import * as React from 'react';

import { identity, prop } from 'ramda';
import { filter, first, startCase, last } from 'lodash';
import { format, parse } from 'date-fns';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis, PresentationAttributes } from 'recharts';
import * as numeral from 'numeral';
import { convertToPrecision } from 'components/shared/utilities/precisionFunctions';
import { monthDateRange } from 'components/shared/utilities/dateRange';
import { CustomTooltip } from './reactLineChart/CustomTooltip';
import { generateNumericTicks } from '../utilities/ticks';
import Loading from 'components/shared/Loading';

export interface IReactLineChartProps {
  leftMargin?: number;
  rightMargin?: number;
  legend: boolean;
  lines: string[];
  lineStyles?: { [key: string]: Partial<PresentationAttributes> };
  changeMinMax?: boolean;
  data: any;
  height?: number;
  isDrawdown?: boolean;
  maxData?: number; // unused deprecated
  minData?: number; // unused deprecated
  percentageValues: boolean;
  showTooltip?: boolean;
  showXAxis?: boolean;
  showYAxis?: boolean;
  ticks?: number[];
  transformToolTipLabel?: boolean;
  useDateTickReducer?: boolean;
  loadingData?: boolean;
}

export interface ICustomLegend {
  payload: any;
}

export const CustomLegend = (props: ICustomLegend) => {
  const { payload } = props;

  const legends = payload.map((payloadItem, index) => {
    const lineStyles = {
      borderColor: payloadItem.color,
      borderStyle: 'solid',
      borderWidth: '1px',
      display: 'inline-block',
      marginBottom: '2px',
      marginRight: '10px',
      width: '25px',
    };

    if (payloadItem.payload.strokeDasharray) {
      lineStyles.borderStyle = 'dashed';
    }

    return (
      <span key={index} style={{ display: 'inline-block', breakInside: 'avoid' }}>
        <span style={lineStyles} className="mar-r-1" />
        <span className="mar-r-2" style={{ fontSize: '1.3rem', whiteSpace: 'nowrap', color: 'white' }}>
          {startCase(payloadItem.dataKey)}
        </span>
      </span>
    );
  });

  return (
    <div style={{ border: '1px solid #e4e4e8', padding: '15px 0 15px', textAlign: 'center', marginTop: '20px' }}>
      {legends}
    </div>
  );
};

const reactLineChart = (props: IReactLineChartProps) => {
  const {
    changeMinMax,
    isDrawdown,
    leftMargin,
    rightMargin,
    legend,
    lines,
    lineStyles,
    loadingData = false,
    showTooltip,
    showXAxis,
    showYAxis,
    useDateTickReducer = true,
  } = props;
  // We are expecting the data to come through with a time_code and an array of series and need to rearrange this to fit

  if (loadingData) return <Loading />;

  interface IDatum {
    name: number;
    portfolio?: number;
    [benchmark: string]: number;
  }

  const dataValues = [];

  const data: IDatum[] = props.data.map((dataItem) => {
    const item = { name: dataItem.time_code ? dataItem.time_code * 1000 : dataItem.name };
    dataItem.series.forEach((eachSeries) => {
      dataValues.push((item[eachSeries.label] = eachSeries.value));
    });
    return item;
  });

  const sortedData = dataValues
    .map((obj) => parseFloat(obj || 0))
    .sort((dataObjectA, dataObjectB) => dataObjectA - dataObjectB);

  // We always include 0 on the y axis so if the minData is larger than this, we want to use zero so that our ticks
  //  are better.
  let minData = Math.min(first(sortedData), 0);
  let maxData = last(sortedData);

  // These numbers are number slightly bigger and smaller to give some room at the top and bottom of the chart
  maxData = changeMinMax ? Math.ceil(maxData * 1.1) : maxData;
  minData = changeMinMax ? Math.floor(minData) : minData;
  const tickPoint = isDrawdown ? minData / 5 : Math.ceil((maxData - minData) / 5);

  const tickFormatter = React.useCallback((tick) => numeral(tick).format('0,0.00'), []);

  // We are subtracting 86399999 because  the #monthDateRange function returns the values for the end of the day
  //  but our values for each data point are for the beginning of the day. This number is one less than the number of
  //  milliseconds in the day.
  const dateTickReducer = (data: IDatum[]) => {
    const dateNumArray = monthDateRange(parse(first(data).name), parse(last(data).name)).map(
      (date) => date.getTime() - 86399999,
    );

    interface ITickReducer {
      dateNums: number[];
      reduction: number;
    }

    // This function incrementally reduces the number of ticks until we have sufficiently few to render the chart nicely.
    const tickReducer = ({ dateNums, reduction }: ITickReducer): number[] => {
      if (dateNums.length <= 9) return dateNums;

      const desiredRemainder = (dateNums.length - 1) % reduction;
      return tickReducer({
        dateNums: filter(dateNums, (_, index: number) => index % reduction === desiredRemainder),
        reduction: 2,
      });
    };

    return tickReducer({ dateNums: dateNumArray, reduction: 3 });
  };

  const xAxisTicks = useDateTickReducer ? dateTickReducer(data) : data.map(prop('name'));

  const xTickFormatter = useDateTickReducer ? React.useCallback((tick) => format(parse(tick), 'MMM YY'), []) : identity;

  const yTicks = generateNumericTicks({
    maxDataPoint: maxData,
    minDataPoint: minData,
    tickArray: [0],
    tickGap: Math.max(convertToPrecision(Math.abs(tickPoint), 2), 0.1),
  });

  const additionalLines = lines.map((line, index) => (
    <Line
      {...lineStyles[line]}
      animationDuration={500}
      animationEasing="linear"
      dataKey={line}
      dot={false}
      key={index}
      strokeWidth="2"
      type="linear"
    />
  ));

  const renderLegend = () => {
    if (!legend) return;

    return <Legend content={<CustomLegend payload={data} />} iconType="line" />;
  };

  const tooltip = (
    <Tooltip
      content={<CustomTooltip transformLabel={props.transformToolTipLabel} percentage={props.percentageValues} />}
      offset={0}
    />
  );

  return (
    <ResponsiveContainer width="100%" height={props.height}>
      <LineChart
        width={730}
        height={580}
        data={data}
        margin={{ top: 10, left: leftMargin, right: rightMargin, bottom: 0 }}
      >
        <CartesianGrid stroke="#353741" strokeWidth="2" fill="#1A1A1A" />
        <XAxis
          // interval={0}
          domain={['dataMin', 'dataMax']}
          tickSize={0}
          dataKey="name"
          stroke="#353741"
          tick={{ fill: 'white', fontSize: '11px', fontWeight: 'bold' }}
          tickMargin={5}
          tickFormatter={xTickFormatter}
          hide={!showXAxis}
          type={'number'}
          ticks={xAxisTicks}
        />
        <YAxis
          interval={0}
          tickSize={0}
          stroke="#353741"
          tick={{ fill: 'white', fontSize: '11px', fontWeight: 'bold' }}
          tickMargin={5}
          ticks={props.ticks ? props.ticks : yTicks}
          domain={[minData, isDrawdown ? 0 : maxData]}
          tickFormatter={tickFormatter}
          hide={!showYAxis}
        />
        {additionalLines}
        {showTooltip && tooltip}
        {renderLegend()}
      </LineChart>
    </ResponsiveContainer>
  );
};

reactLineChart.defaultProps = {
  changeMinMax: true,
  height: 600,
  isDrawdown: false,
  leftMargin: 15,
  percentageValues: true,
  showTooltip: true,
  showXAxis: true,
  showYAxis: true,
  transformToolTipLabel: true,
};

export default reactLineChart;
