import * as React from 'react';

import Presenter from './reportTemplates/Presenter';
import { ReportTemplate } from 'javascript/models';

export default function reportTemplates({ bucketId, canCreate, reportBuckets }) {
  const [isLoading, setIsLoading] = React.useState(true);
  const [reportTemplates, setReportTemplates] = React.useState<ReportTemplate[]>([]);
  const [filters, setFilters] = React.useState({ status: 'all', theme: 'all', orientation: 'all' });

  function handleFilterChange(filter, value) {
    const newFilters = { ...filters };
    newFilters[filter] = value;
    setFilters(newFilters);
  }

  React.useEffect(() => {
    fetchData().then(() => setIsLoading(false));
  }, [filters]);

  async function fetchData() {
    const templateFilters = { ...filters };
    ['status', 'theme', 'orientation'].forEach((filterType) => {
      if (templateFilters[filterType] === 'all') {
        delete templateFilters[filterType];
      }
    });

    if (bucketId) {
      templateFilters['report_template_bucket_id'] = bucketId;
    }

    const { data } = await ReportTemplate.selectExtra(['report_template_bucket_color'])
      .where(templateFilters)
      .per(999)
      .all();

    setReportTemplates(data);
  }

  return (
    <Presenter
      {...{
        bucketId,
        canCreate,
        fetchData,
        handleFilterChange,
        isLoading,
        reportBuckets,
        reportTemplates,
      }}
    />
  );
}
