import * as React from 'react';
import TextareaAutosize from 'react-autosize-textarea';

const classNames = require('classnames');

interface IProps {
  disabled?: boolean;
  label?: string;
  name: string;
  id: string;
  maxLength?: number;
  maxRows?: number;
  value?: string;
  rows?: number;
  placeholder?: string;
  style?: any;
  onChange?(event: any): void;
  handleChange?(event: any): void;
}

export default function autoResizeTextArea(props: IProps) {
  const { disabled, id, label, maxLength, maxRows, name, placeholder, rows, style } = props;

  const [value, setValue] = React.useState(props.value || '');

  React.useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  function onChange(event) {
    setValue(event.target.value);
    if (props.onChange) {
      props.onChange(event.target.value);
    }
    if (props.handleChange) {
      props.handleChange(event);
    }
  }

  const attributes: IProps = { disabled, id, maxRows, name, onChange, style, value };

  if (maxLength) {
    attributes.maxLength = maxLength;
  }
  if (rows) {
    attributes.rows = props.rows;
  }
  if (placeholder) {
    attributes.placeholder = props.placeholder;
  }

  function renderLabel() {
    if (!maxLength || !label || !value) {
      return;
    }

    const labelClasses = classNames('form__label', { 'form__label--disabled': disabled });

    const textColor = value.length > maxLength ? 'text-pale-red' : 'text-black';
    return (
      <div className="frow frow--items-top frow--justify-between">
        <label htmlFor={id} className={labelClasses}>
          {label}
        </label>
        <div className={`${textColor}`} style={{ fontSize: '1.1rem' }}>
          {value.length} / {maxLength}
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      {renderLabel()}
      <TextareaAutosize {...attributes} />
    </React.Fragment>
  );
}
