import * as React from 'react';

import lodashStartcase from 'lodash.startcase';
import { format, parse } from 'date-fns';
import { Area, AreaChart, CartesianGrid, Legend, Line, ReferenceArea, XAxis, YAxis } from 'recharts';

const CustomLegend = (props) => {
  const { payload } = props;

  const legends = payload.map((payloadItem, index) => {
    const lineStyles = {
      background: payloadItem.payload.fill,
      border: `2px solid ${payloadItem.payload.stroke}`,
      borderRadius: '50%',
      display: 'inline-block',
      height: '15px',
      marginBottom: '-2px',
      width: '15px',
    };

    return (
      <span key={index} style={{ whiteSpace: 'nowrap' }}>
        <span style={lineStyles} className="mar-r-1" />
        <span className="mar-r-2" style={{ fontSize: '1.26rem' }}>
          {lodashStartcase(payloadItem.dataKey)}
        </span>
      </span>
    );
  });

  return (
    <div style={{ border: '1px solid #e4e4e8', padding: props.print ? '3px 0 9px' : '0 0 5px', textAlign: 'center' }}>
      {legends}
    </div>
  );
};

import { chartColorsV2 } from './chartColors';

const CustomizedNoDataLabel = (props) => {
  const { height, width, x, y } = props.viewBox;
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={width / 2} y={height / 2} textAnchor="middle" fill="#000" fontWeight="bold" fontSize="20px">
        Insufficient Data
      </text>
    </g>
  );
};

export default function areaChart({ templateSection, theme, width, benchmarkTypes, height = 300, xTicks }) {
  const chartColors = chartColorsV2();

  const data = templateSection.data.map((dataPoint) => {
    const dataObject = { name: dataPoint.time_code };
    dataPoint.series.forEach((data) => {
      dataObject[data.name] = parseFloat(data.value).toFixed(2);
    });
    return dataObject;
  });

  const areas = templateSection.data[0].series.map((data) => data.name);

  const dateTickFormatter = (tick) => format(parse(tick * 1000), 'MMM YY');

  const chartAreas = areas.map((areaName, index) => {
    const colour = chartColors(areaName, theme, benchmarkTypes, index);
    return (
      <Area
        key={areaName}
        dataKey={areaName}
        stackId="1"
        fill={colour}
        isAnimationActive={false}
        fillOpacity={1}
        stroke={colour}
      />
    );
  });

  const noDataOverlay = (
    <ReferenceArea
      fill="#f6f6f8"
      x1={0}
      x2={1}
      y1={0}
      y2={100}
      alwaysShow
      isFront
      label={<CustomizedNoDataLabel />}
    />
  );

  const noData = data.length <= 1;

  const renderLegend = () => {
    return <Legend content={<CustomLegend />} iconType="line" wrapperStyle={{ bottom: '-10px' }} />;
  };

  return (
    <AreaChart
      width={width}
      height={height}
      data={noData ? null : data}
      margin={{ top: 5, right: 15, left: 0, bottom: 0 }}
    >
      <CartesianGrid stroke="#fff" />
      {noData && noDataOverlay}
      <XAxis
        dataKey="name"
        interval={xTicks ? 0 : 'preserveStartEnd'}
        tick={{ fill: '#b5b6bd', fontSize: '10px', fontWeight: 'bold' }}
        tickSize={0}
        tickMargin={8}
        ticks={noData ? [''] : xTicks}
        domain={['auto', 'auto']}
        type={'number'}
        scale={'time'}
        tickFormatter={noData ? null : dateTickFormatter}
      />
      <YAxis
        interval={0}
        domain={[0, 100]}
        tick={{ fill: '#b5b6bd', fontSize: '10px', fontWeight: 'bold' }}
        ticks={[0, 25, 50, 75, 100]}
        allowDataOverflow
        tickSize={0}
        tickMargin={5}
        tickFormatter={(tick) => `${tick}%`}
      />
      {chartAreas}
      {noData ? null : renderLegend()}
    </AreaChart>
  );
}
