import * as React from 'react';
import { sanitize } from 'dompurify';
import Consolidated from './reviewComments/Consolidated';

import Context from '../Context';

export default function reviewComments({ colors, templateSection, performance, allocation, bespoke, rating, holding }) {
  const context = React.useContext(Context);
  const { reportTemplate } = context.state;

  const isConsolidated = reportTemplate && reportTemplate.consolidated;

  if (isConsolidated) {
    return <Consolidated {...{ colors, templateSection }} />;
  }

  const performanceCommentary = (
    <p className="pdf-body-copy" dangerouslySetInnerHTML={{ __html: sanitize(performance) }} />
  );

  const allocationCommentary = (
    <p className="pdf-body-copy" dangerouslySetInnerHTML={{ __html: sanitize(allocation) }} />
  );

  const holdingCommentary = <p className="pdf-body-copy" dangerouslySetInnerHTML={{ __html: sanitize(holding) }} />;

  const bespokeCommentary = <p className="pdf-body-copy" dangerouslySetInnerHTML={{ __html: sanitize(bespoke) }} />;

  const ratingCommentary = <p className="pdf-body-copy" dangerouslySetInnerHTML={{ __html: sanitize(rating) }} />;

  return (
    <table className="pdf-hidden-table">
      <tbody>
        <tr>
          <td className="pdf-body-copy-wrap">
            {performance && performanceCommentary}
            {allocation && allocationCommentary}
            {holding && holdingCommentary}
            {bespoke && bespokeCommentary}
            {rating && ratingCommentary}
          </td>
        </tr>
      </tbody>
    </table>
  );
}

reviewComments.defaultProps = {
  allocation:
    'In terms of asset allocation the portfolio has 8.5% in cash, 32.61% in bonds, 43.04% in equities, and 15.84% in other assets.',
  bespoke:
    "We note the 'No UK Situs Assets' restriction. We are not provided with ISIN or Sedol identification numbers for the collective investment funds held so we are unable to verify whether such a restriction is being met. It is suggested that this should therefore be checked with the investment manager for the avoidance of doubt.",
  holding:
    "We note that the portfolio was in breach of its tactical asset allocation ranges for cash, fixed income and equity at the time of review. We also note that the portolio's holding in US equity was in excess of its mandated maximum.",
  performance:
    'During the second quarter of 2019 the portfolio increased by 2.84%, compared with the Enhance GBP Balanced benchmark which increased by 4.87%, and the Peer group which increased by 5.21%. Over the 36 month period covered by the review the portfolio increased by 13.92%, with the Enhance benchmark increasing by 5.39%, and the Peer group up 20.08% in comparison.',
  rating:
    'A ‘2.0’ rating has been applied as the portfolio has produced higher returns than the independent Enhance measure over the 36 month period under review, with lower levels of volatility. The portfolio has lower returns than the Peer group, with higher levels of volatility.',
};
