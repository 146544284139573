import * as React from 'react';
import * as $ from 'jquery';
import turbolinks from 'turbolinks';

import lodashStartcase from 'lodash.startcase';

import Button from 'components/shared/forms/Button';
import CsrfToken from 'components/shared/CsrfToken';
import CustomModal from 'components/shared/CustomModal';

import { platformPortfolioPath } from 'javascript/application/ts_routes';

export default function terminate({ portfolio, terminationReasons }) {
  const { id, status } = portfolio.attributes;

  if (status === 'terminated') return null;

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [terminationReason, setTerminationReason] = React.useState('');

  function handleClose() {
    setIsOpen(false);
  }

  function handleOpen() {
    setIsOpen(true);
  }

  function handleSubmit() {
    if (!terminationReason) return;

    $.ajax({
      beforeSend: () => setIsSubmitting(true),
      data: {
        authenticity_token: CsrfToken(),
        portfolio: { status: 'terminated', termination_reason: terminationReason },
      },
      dataType: 'json',
      success: () => turbolinks.visit(platformPortfolioPath(id)),
      type: 'PATCH',
      url: platformPortfolioPath(id),
    });
  }

  const rejectionReasons = terminationReasons.map((reason) => {
    function handleSelect() {
      setTerminationReason(reason);
    }

    return (
      <div className="col-mc-1-1" key={reason}>
        <div className="pretty pretty--large pretty--with-label p-round">
          <input
            type="radio"
            name="termination_reason"
            value={reason}
            checked={reason === terminationReason}
            onChange={handleSelect}
          />
          <div className="state p-blue-circle">
            <label className="">{lodashStartcase(reason)}</label>
          </div>
        </div>
      </div>
    );
  });

  return (
    <React.Fragment>
      <div
        className="button button--secondary button--danger button--icon"
        onClick={handleOpen}
        data-tip="Terminate Portfolio"
        data-for="portfolio_controls"
      >
        <i className="icon-bin icon-fw" />
      </div>
      <CustomModal isOpen={isOpen} handleClose={handleClose} title="Terminate Portfolio">
        <div className="modal__content">
          <div className="form form--light">
            <div className="frow frow--gutters">
              <div className="col-mc-1-1">
                <label htmlFor="termination_reason" className="form__label">
                  Reason for Termination
                </label>
                <div className="frow frow--gutters">{rejectionReasons}</div>
              </div>
              <div className="col-mc-1-1">
                <Button
                  buttonClasses="button--light-themed button--compact mar-r-1"
                  isDisabled={!terminationReason}
                  isSubmitting={isSubmitting}
                  handleClick={handleSubmit}
                  submittingText="Terminating Portfolio"
                  text="Submit"
                />
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </React.Fragment>
  );
}
