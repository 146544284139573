import * as React from 'react';

import Select from 'react-select';

import useDebounce from 'components/shared/customHooks/useDebounce';
import { Entity } from 'javascript/models';
import Context from '../../Context';

interface IProps {
  companyId: string;
  defaultValue: { label: string; value: string };
  entityId: string;
}

export default function entitySelect(props: IProps) {
  const context = React.useContext(Context);

  const { state } = context;

  const { companyId } = props;
  const [isLoading, setIsLoading] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);

  const debouncedInputValue = useDebounce(inputValue, 600);

  React.useEffect(() => {
    setIsLoading(false);
  }, [options]);

  React.useEffect(() => {
    if (state.selectedEntity) {
      setSelectedEntity(state.selectedEntity);
      const newOptions = [...options];
      newOptions.push(state.selectedEntity);
      setOptions(newOptions);
    }
  }, [state.selectedEntity]);

  function handleInputChange(event) {
    setInputValue(event.target.value);
  }

  React.useEffect(() => {
    if (debouncedInputValue && debouncedInputValue.length > 4) {
      getEntities();
    }
  }, [debouncedInputValue]);

  async function getEntities() {
    const { data } = await Entity.where({ company_id: companyId, search: { match: inputValue } })
      .per(1000)
      .all();

    const newOptions = data.map((entity) => ({ value: parseInt(entity.id, 10), label: entity.name }));
    setOptions(newOptions);
  }

  const defaultValue = props.defaultValue && props.defaultValue.value ? props.defaultValue : null;

  const [selectedEntity, setSelectedEntity] = React.useState(defaultValue);

  function handleChange(selectedOption) {
    setSelectedEntity(selectedOption);
  }

  return (
    <React.Fragment>
      <label htmlFor="entity_id" className="form__label">
        Owning Entity
      </label>
      <input
        type="text"
        placeholder="Search for entities to populate the select box below"
        onChange={handleInputChange}
        className="mar-b-1"
      />
      <Select
        className="react-select-dark"
        classNamePrefix="react-select-dark"
        defaultValue={defaultValue}
        isSearchable
        isLoading={isLoading}
        id="entity_id"
        name="portfolio[entity_id]"
        options={options}
        placeholder="Select an entity"
        value={selectedEntity}
        onChange={handleChange}
      />
    </React.Fragment>
  );
}
