import * as React from 'react';

import PrivateAssetForm, { IPrivateAssetFormProps } from './privateAssets/PrivateAssetForm';

import lodashStartcase from 'lodash.startcase';

import { format, parse } from 'date-fns';

export default function privateAssetsColumns(props: IPrivateAssetFormProps) {
  const renderIcons = (row) => {
    const handleDelete = () => {
      row.original.destroy();
      props.triggerTableUpdate();
    };

    return (
      <div className="frow frow--justify-end">
        <PrivateAssetForm {...{ ...props, privateAsset: row.original }} />
        <button type="button" className="button button--danger button--icon mar-l-1" onClick={handleDelete}>
          <i className="icon-bin icon-fw" />
        </button>
      </div>
    );
  };

  return [
    {
      Cell: (row) => {
        return <div>{lodashStartcase(row.original.category)}</div>;
      },
      Header: 'Category',
      accessor: 'category',
      columnName: 'Category',
      id: 'category',
    },
    {
      Cell: (row) => {
        return <div>{lodashStartcase(row.original.subCategory)}</div>;
      },
      Header: 'Sub-Category',
      accessor: 'subCategory',
      columnName: 'Sub-Category',
      id: 'subCategory',
    },
    {
      Header: 'Description',
      accessor: 'description',
      columnName: 'Description',
      id: 'description',
      width: Math.round(window.innerWidth * 0.2),
    },
    {
      Cell: (row) => {
        const currentValue = row.original.currentValue;

        return (
          <div>{currentValue && format(parse(new Date(currentValue.valueDate).toDateString()), 'DD MMM YYYY')}</div>
        );

        // format(parse(row.original.value_date), 'DD MMM YYYY')
        // return <div>{row.original.currentValue && new Date(row.original.currentValue.valueDate).toDateString()}</div>;
      },
      Header: 'Latest Valuation Date',
      accessor: 'currentValue.valueDate',
      columnName: 'Latest Valuation Date',
      id: 'latestValuationDate',
    },
    {
      Cell: (row) => {
        const value = row.original.currentValue && row.original.currentValue.amount;
        const currency = row.original.currentValue && row.original.currentValue.currency.toUpperCase();
        const displayValue = value
          ? value.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
          : '-';

        return <div>{`${currency || ''}${displayValue}`}</div>;
      },
      Header: 'Latest Valuation',
      accessor: 'currentValue.amount',
      columnName: 'Latest Valuation',
      id: 'latestValuation',
    },
    {
      Cell: (row) => {
        const value = row.original.currentValue && row.original.currentValue.valueBaseCcy;
        return (
          <div>{value ? value.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '-'}</div>
        );
      },
      Header: 'Latest Valuation (Base Currency)',
      accessor: 'currentValue.valueBaseCcy',
      columnName: 'Latest Valuation (Base Currency)',
      id: 'latestValuationBaseCcy',
    },
    {
      Cell: (row) => renderIcons(row),
      Header: '',
      columnName: 'Edit Button',
      id: 'editButton',
    },
  ];
}
