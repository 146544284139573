import { Attr, Model } from 'spraypaint';

import { ApplicationRecord } from './ApplicationRecord';

@Model()
export class ClassificationUpload extends ApplicationRecord {
  public static jsonapiType = 'classification_uploads';
  @Attr() public securityClassificationStateId: string;
  @Attr() public administratorId: string;
  @Attr() public description: string;
  @Attr() public import: string;
  @Attr() public uploadedAt: string;
  @Attr() public uploadedBy: string;
  @Attr() public showPath: string;
  @Attr() public fileName: string;
}
