import * as React from 'react';

import GraphitiTableWrapper from 'components/shared/GraphitiTableWrapper';

import columns from './table/columns';

import { ConsultancyClient } from 'javascript/models';

export interface IGraphitiTableProps {
  hasHeader?: boolean;
  newPath?: string;
  scope?: { [key: string]: string | { [key: string]: string } };
  title: string;
}

export default function graphitiTable(props: IGraphitiTableProps) {
  const { newPath, title } = props;

  const scope = ConsultancyClient.includes('letters_of_engagement')
    .where({ lettersOfEngagement: { withDiscarded: false } })
    .order({ clientGrouping: 'desc' });

  return (
    <GraphitiTableWrapper
      scope={scope}
      columns={columns()}
      hasHeader
      newPath={newPath}
      newText="Add Consultancy Client"
      title={title}
      identifier="consultancyClients:index"
    />
  );
}

graphitiTable.defaultProps = {
  hasHeader: true,
};
