import * as React from 'react';

const useEventTrigger = <Payload,>(eventType: string, payload: Payload) => {
  const initialLoadRef = React.useRef(true);
  const { current: initialLoad } = initialLoadRef;

  const triggerEvent = () => document.dispatchEvent(new CustomEvent<Payload>(eventType, payload));

  React.useEffect(() => (initialLoad || triggerEvent(), undefined), [payload]);

  React.useEffect(() => (setTimeout(() => (initialLoadRef.current = false), 0), undefined), []);

  return triggerEvent;
};

export default useEventTrigger;
