import * as React from 'react';

import lodashStartcase from 'lodash.startcase';
import * as R from 'ramda';
import FormRow from 'components/shared/forms/FormRow';
import ReactSelect from 'components/shared/forms/ReactSelect';
import Context from '../Context';

const RiskCurrency = () => {
  const context = React.useContext(Context);

  const { portfolio } = context.state;
  const { currencyOptions, riskOptions, serviceTypeOptions } = context.props;

  const { assetsUnderManagement, currency, risk, serviceType } = portfolio;

  const defaultServiceType = serviceTypeOptions.find((option) => option.value === serviceType);
  const defaultRisk = riskOptions.find((option) => option.value === risk);
  const indexOrder = [0, 1, 8, 2, 9, 10, 3, 11, 12, 4, 13, 5, 6, 7];
  const getrisk = (idx: number) => riskOptions[idx];
  const sortedRisk = R.map(getrisk, indexOrder);
  return (
    <fieldset className="form__fieldset">
      <h4 className="form__fieldset-label">Portfolio Information</h4>
      <div className="frow frow--gutters">
        <FormRow>
          <label htmlFor="risk" className="form__label">
            Risk Profile
          </label>
          <ReactSelect theme="dark" name="portfolio[risk]" id="risk" options={sortedRisk} defaultValue={defaultRisk} />
        </FormRow>
        <FormRow note="" className="col-mc-1-1">
          <label htmlFor="currency" className="form__label">
            Currency
          </label>
          <ReactSelect
            theme="dark"
            name="portfolio[currency]"
            id="currency"
            maxMenuHeight={150}
            options={currencyOptions}
            defaultValue={{ label: !!currency ? currency.toUpperCase() : '', value: currency }}
          />
        </FormRow>
        <FormRow note="" className="col-mc-1-1">
          <label htmlFor="client_type" className="form__label">
            Service Type
          </label>
          <ReactSelect
            isClearable
            theme="dark"
            name="portfolio[service_type]"
            id="client_type"
            options={serviceTypeOptions}
            defaultValue={defaultServiceType}
          />
        </FormRow>
        <FormRow note="" className="col-mc-1-1">
          <label htmlFor="client_type" className="form__label">
            AUM
          </label>
          <input type="number" name="portfolio[assets_under_management]" defaultValue={assetsUnderManagement} />
        </FormRow>
      </div>
    </fieldset>
  );
};

export default RiskCurrency;
