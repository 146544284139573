import * as React from 'react';
import { Intent, ProgressBar } from '@blueprintjs/core';

export default function progressBar({ animate, animationSpeed, value }) {
  const [progressValue, setProgressValue] = React.useState(animate ? 0 : value);

  React.useEffect(() => {
    if (animate) {
      setTimeout(() => {
        updateValue();
      }, 50);
    }
  }, []);

  React.useEffect(() => {
    if (progressValue < value) {
      setTimeout(() => {
        updateValue();
      }, 50);
    }
  }, [progressValue]);

  function updateValue() {
    const interval = value / animationSpeed;
    const newProgressValue = progressValue + interval;
    setProgressValue(newProgressValue);
  }

  return (
    <ProgressBar
      animate={false}
      stripes={false}
      intent={Intent.PRIMARY}
      value={progressValue}
      className="progress-bar--animate-1"
    />
  );
}
