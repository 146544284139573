import * as React from 'react';

import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
const numeral = require('numeral');

import ReactAreaChartNoData from './ReactAreaChartNoData';
import { CustomTooltip } from './reactAreaChart/CustomTooltip';

import IAreaChartData from './interfaces/IAreaChartData';

interface IProps {
  data: IAreaChartData[];
  maxData?: number;
  minData?: number;
  hideTooltip?: boolean;
  isLoading?: boolean;
}

export default function reactAreaChart(props: IProps) {
  const { hideTooltip, data } = props;

  if (data.length < 1 || props.isLoading) {
    return <ReactAreaChartNoData isLoading={props.isLoading} />;
  }

  const amounts = data.map((dataPoint) => dataPoint.amount);
  // These numbers are number slightly bigger and smaller to give some room at the top and bottom of the chart
  const maxData = (props.maxData || Math.max(...amounts)) * 1.05;
  const minData = (props.minData || Math.min(...amounts)) * 0.95;
  const tickPoint = (maxData - minData) / 5;

  const tickFormatter = (tick) => numeral(tick).format('0,0');

  const ticks = [minData + tickPoint, minData + tickPoint * 2, minData + tickPoint * 3, minData + tickPoint * 4, ''];

  function renderTooltip() {
    if (hideTooltip) return;

    return <Tooltip content={<CustomTooltip />} offset={0} />;
  }

  return (
    <ResponsiveContainer width="100%" height={500}>
      <AreaChart width={730} height={250} data={data} margin={{ top: 10, left: 0, bottom: 0 }}>
        <CartesianGrid stroke="#353741" strokeWidth="2" fill="#1A1A1A" />
        <XAxis
          dataKey="name"
          mirror
          stroke="#353741"
          tick={{ fill: 'white', fontSize: '11px', fontWeight: 'bold' }}
          tickSize={0}
          tickMargin={15}
        />
        <YAxis
          interval={0}
          tickSize={0}
          stroke="#353741"
          tick={{ fill: 'white', fontSize: '11px', fontWeight: 'bold' }}
          tickMargin={15}
          ticks={ticks}
          mirror
          domain={[minData, maxData]}
          tickFormatter={tickFormatter}
        />
        {renderTooltip()}
        <Area
          animationDuration={500}
          animationEasing="linear"
          type="linear"
          dataKey="amount"
          stroke="#3990A8"
          fillOpacity={0.1}
          fill="#3990A8"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}
