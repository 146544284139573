import * as React from 'react';
import { Surface, Symbols } from 'recharts';

export const CustomLegend = (props) => {
  const { payload } = props;

  const legends = payload.map((payloadItem, index) => {
    const legendTitle = payloadItem.payload.data[0].name;

    return (
      <div className="frow frow--items-center" key={index}>
        <div className="mar-r-1">
          <Surface width={15} height={15}>
            <Symbols
              cx={8}
              cy={8}
              type={payloadItem.payload.legendType}
              size={120}
              fill={payloadItem.payload.fill}
              stroke={payloadItem.payload.stroke}
            />
          </Surface>
        </div>
        <div style={{ fontSize: '1.2rem', whiteSpace: 'nowrap' }} className="mar-r-2 text-white">
          {legendTitle}
        </div>
      </div>
    );
  });

  return (
    <div style={{ border: '1px solid #e4e4e8', textAlign: 'center' }} className="mar-t-2 pad-a-1">
      <div className="frow frow--justify-center frow--items-center">{legends}</div>
    </div>
  );
};
