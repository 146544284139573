import React from 'react';

import { ActionPoint, Client, User } from 'javascript/models';

import Loading from 'components/shared/Loading';

import Uploader from './show/Uploader';
import Notes from './show/Notes';
import Information from './show/Information';

export interface IActionPointShow {
  actionPointId: string;
  currentUserId: string;
  client: Pick<Client, 'id' | 'managesActionPointsInPortal'>;
  phantomLogin: boolean;
  portfolioId: string;
  portfolioReference: string;
}

interface IActionPointContext extends Omit<IActionPointShow, 'actionPointId' | 'currentUserId'> {
  actionPoint: ActionPoint;
  currentUser: User;
  getActionPoint: () => Promise<void>;
  isLoading: boolean;
  setActionPoint: React.Dispatch<React.SetStateAction<ActionPoint>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const ActionPointContext = React.createContext<IActionPointContext>(null);
export { ActionPointContext };

const Show = ({
  actionPointId,
  currentUserId,
  client,
  phantomLogin,
  portfolioId,
  portfolioReference,
}: IActionPointShow) => {
  const [currentUser, setCurrentUser] = React.useState<User>();
  const [actionPoint, setActionPoint] = React.useState<ActionPoint>();
  const [isLoading, setIsLoading] = React.useState(true);

  const getActionPoint = async () => {
    const { data } = await ActionPoint.includes(['assignee', 'deferralNotes', { notes: 'author' }])
      .where({ notes: { noteType: 'action_point_response' } })
      .find(actionPointId);
    setActionPoint(data);
  };

  const getCurrentUser = async () => {
    const { data } = await User.withPermissions.find(currentUserId);
    setCurrentUser(data);
  };

  const fetchData = async () => {
    getActionPoint();
    getCurrentUser();
  };

  const refetchActionPoint = async () => {
    setIsLoading(true);
    await getActionPoint();
    setIsLoading(false);
  };

  React.useEffect(() => {
    fetchData().then(() => setIsLoading(false));
  }, []);

  const context = {
    actionPoint,
    client,
    currentUser,
    getActionPoint: refetchActionPoint,
    isLoading,
    phantomLogin,
    portfolioId,
    portfolioReference,
    setActionPoint,
    setIsLoading,
  };

  if (isLoading || !actionPoint || !currentUser) return <Loading />;

  return (
    <ActionPointContext.Provider value={context}>
      <div>
        <Information />
        <Uploader />
        <Notes />
      </div>
    </ActionPointContext.Provider>
  );
};

export default Show;
