import * as React from 'react';

export default function newRow({ newRowColumnHandler, id, column }) {
  const onClickHandler = () => newRowColumnHandler(id, column);

  return (
    <div className="frow frow--justify-around">
      <div onClick={onClickHandler} className="button button--icon-small">
        <i className="icon-plus" />
      </div>
      <div>{`${column ? 'c' : 'r'}${id}`}</div>
    </div>
  );
}
