import * as React from 'react';
import { format } from 'date-fns';
import useComponentProxy from 'components/shared/customHooks/useComponentProxy';
import ClientPortalCard from 'components/portal/shared/ClientPortalCard';
import { Frow, Column } from 'components/frow';
import { TAnyHeaderProps } from 'components/shared/graphitiTable/components/Header';
import { isEmpty, debounce } from 'lodash';
import Loading from 'components/shared/Loading';
import GraphitiTableWrapper from 'components/shared/GraphitiTableWrapper';
import { createAlert } from 'components/shared/Utils';
import { Portfolio } from 'javascript/models/portal/Portfolio';
import { Checkbox } from '@blueprintjs/core';
import columns from './portfolios/columns';
import ContactFilter from './portfolios/ContactFilter';

const portfolios = ({ clientId, currencies, clientTypes, riskOptions, selectedDate, filterState }) => {
  const [externalFilters, setExternalFilters] = filterState;
  const [shouldUpdateTable, setShouldUpdateTable] = React.useState(false);
  const [holdTerminated, setHoldTerminated] = React.useState(false);
  const [additionalExtraParams, setAdditionalExtraParams] = React.useState({
    selected_date: format(selectedDate, 'YYYY-MM-DD'),
  });
  const [contactsFilter, setContactsFilter] = React.useState<number[]>([]);
  const [HeaderComponent, headerComponentProps] = useComponentProxy<TAnyHeaderProps>();
  const {
    Button = undefined,
    buttonProps = undefined,
    Search = undefined,
    searchProps = undefined,
  } = headerComponentProps || {};

  React.useEffect(() => {
    setShouldUpdateTable(true);
  }, [additionalExtraParams]);

  React.useEffect(() => {
    resetFilters();
    setAdditionalExtraParams({ selected_date: format(selectedDate, 'YYYY-MM-DD') });
  }, [selectedDate]);

  React.useEffect(() => {
    if (shouldUpdateTable) {
      setShouldUpdateTable(false);
    }
  }, [shouldUpdateTable]);

  const toggleHoldTerminated = (e) => {
    setHoldTerminated((prev) => !prev);
  };

  let scope = Portfolio.selectExtra([
    'client_type_icon',
    'portfolio_type',
    'balance_at_selected_date',
    'enriched_data',
    'historic_ratings',
    'rating',
    'review_status_at_selected_date',
  ]);

  scope = scope
    .where({ status: holdTerminated ? ['hold', 'terminated', 'live'] : ['live'], clientId })
    .includes(['entity', 'manager', 'primary_contact', 'secondary_contact', 'tertiary_contact']);

  const debouncedAlert = debounce(createAlert, 250);

  React.useEffect(() => {
    if (isEmpty(externalFilters)) return;
    debouncedAlert('success', 'Filters applied', 1500);
  }, [externalFilters]);

  const resetFilters = React.useCallback(() => {
    setExternalFilters({});
  }, []);

  const newColumns = columns(clientId, currencies, clientTypes, riskOptions, selectedDate, externalFilters, scope);

  const titleControls = headerComponentProps ? (
    <Frow justifyContent="between" itemAlignment="center">
      <Column columnSpan={5} maxColumns={6}>
        <Frow justifyContent="start" itemAlignment="center">
          <Column columnSpan={1} maxColumns={3}>
            <Search {...searchProps} />
          </Column>
          <Column columnSpan={1} maxColumns={3}>
            <ContactFilter scope={scope} setContactsFilter={setContactsFilter} />
          </Column>
        </Frow>
      </Column>

      <Column columnSpan={1} maxColumns={6}>
        <div>
          <div className="pretty p-icon">
            <input
              type="checkbox"
              onChange={toggleHoldTerminated}
              checked={holdTerminated}
              value="1"
              style={{ zIndex: 999 }}
            />
            <div className="state p-blue">
              <i className="icon-tick icon icon-push-down-small" />
              <label className="text-white">Show On Hold and Terminated</label>
            </div>
          </div>
        </div>
      </Column>

      <Column columnSpan={1} maxColumns={6}>
        <Button {...buttonProps} />
      </Column>
    </Frow>
  ) : (
    <Loading />
  );

  return (
    <ClientPortalCard title="Portfolios" titleControls={titleControls} titleControlsClassName="col-mc-10-11">
      <GraphitiTableWrapper
        buttonText={Object.keys(externalFilters).length > 0 ? 'Clear Filters' : null}
        className=""
        handleButtonClick={resetFilters}
        scope={scope}
        columns={newColumns}
        hasHeader
        headerComponent={HeaderComponent}
        isSearchable
        identifier="portal:portfolios:index"
        {...{
          additionalExtraParams,
          additionalFilters: { ...externalFilters, reviewDate: selectedDate, allContacts: contactsFilter },
          shouldUpdateTable,
        }}
      />
    </ClientPortalCard>
  );
};

export default portfolios;
