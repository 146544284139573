import * as React from 'react';

import {
  addDays,
  addQuarters,
  differenceInCalendarWeeks,
  endOfQuarter,
  format,
  isToday,
  startOfQuarter,
  startOfYear,
} from 'date-fns';
import dateRange from 'components/shared/utilities/dateRange';
import range from 'components/shared/utilities/range';

export default function overall({ frequency, meta, selectedDate, tracker }) {
  const nextQuarter = endOfQuarter(addQuarters(selectedDate, 1));
  const numberOfWeeksToAdd = differenceInCalendarWeeks(startOfQuarter(nextQuarter), startOfYear(nextQuarter));

  const { data_complete_week, data_complete_day } = meta.stats.grouped_by;
  const { for_data } = meta.stats.total;
  const { overallTargets, overallTargetsDaily } = tracker;

  function tableRows() {
    if (frequency === 'Weekly') {
      let cumulativeTarget = 0;
      let totalProcessed = 0;
      const currentWeek = differenceInCalendarWeeks(new Date(), startOfYear(nextQuarter)) - numberOfWeeksToAdd + 1;
      return range(1, 13).map((num, index) => {
        const processed = data_complete_week[num + numberOfWeeksToAdd] || 0;
        const target = overallTargets[`week_${num}`] || 0;
        const targetNumber = for_data * (target / 100);
        totalProcessed += processed;

        cumulativeTarget += targetNumber;
        const isThisWeek = currentWeek === num;

        return (
          <tr key={`data-targets-${index}`} className={`${isThisWeek && 'styled-table__highlighted-row'}`}>
            <td>{num}</td>
            <td>{target !== 0 ? target : '-'}</td>
            <td>{targetNumber !== 0 ? targetNumber.toFixed(0) : '-'}</td>
            <td>{processed !== 0 ? processed : '-'}</td>
            <td>{cumulativeTarget !== 0 ? cumulativeTarget.toFixed(0) : '-'}</td>
            {/*<td>Sent</td>*/}
            <td>{totalProcessed !== 0 ? totalProcessed.toFixed(0) : '-'}</td>
            <td>{currentWeek >= num ? (totalProcessed - cumulativeTarget).toFixed(0) : '-'}</td>
          </tr>
        );
      });
    }

    if (frequency === 'Daily') {
      let cumulativeTarget = 0;
      let totalProcessed = 0;
      return dateRange(addDays(selectedDate, 1), nextQuarter).map((date, index) => {
        const formattedDate = format(date, 'YYYY-MM-DD');

        const processed = data_complete_day[formattedDate] || 0;
        const target = overallTargetsDaily[index + 1] || 0;
        const targetNumber = for_data * (target / 100);

        totalProcessed += processed;
        cumulativeTarget += targetNumber;
        const today = isToday(date);

        return (
          <tr key={`data-targets-${index}`} className={`${today && 'styled-table__highlighted-row'}`}>
            <td>
              {index + 1} ({format(date, 'Do MMM')})
            </td>
            <td>{target !== 0 ? target.toFixed(2) : '-'}</td>
            <td>{targetNumber !== 0 ? targetNumber.toFixed(0) : '-'}</td>
            <td>{processed !== 0 ? processed : '-'}</td>
            <td>{cumulativeTarget !== 0 ? cumulativeTarget.toFixed(0) : '-'}</td>
            {/*<td>Sent</td>*/}
            <td>{totalProcessed !== 0 ? totalProcessed.toFixed(0) : '-'}</td>
            <td>{(totalProcessed - cumulativeTarget).toFixed(0)}</td>
          </tr>
        );
      });
    }
  }

  return (
    <table className="styled-table styled-table--alt">
      <thead>
        <tr>
          <th>{frequency === 'Weekly' ? 'Week' : 'Day'}</th>
          <th>% Complete</th>
          <th>{frequency === 'Weekly' ? 'Weekly Target' : 'Daily Target'}</th>
          <th>Processed</th>
          <th>Cum. Target</th>
          <th>Cum. Processed</th>
          <th>Status</th>
          {/*<th>Sent</th>*/}
        </tr>
      </thead>
      <tbody>{tableRows()}</tbody>
    </table>
  );
}
