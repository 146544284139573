import { ApplicationRecord } from '../ApplicationRecord';

/**
 * Returns a class based off ApplicationRecord which contains a method for checking permissions through roles
 *
 * @param unpermissionedModel - The spraypaint model which will gain methods for working with permissions through roles
 * @returns modelWithRoles - A class which extends `unpermissionedModel` and contains a `hasPermission` method
 */
const generateRecordWithRole = <Model extends { new (...args): ApplicationRecord }>(unpermissionedModel: Model) =>
  class modelWithRoles extends unpermissionedModel {
    public roles;

    /**
     * Checks if a user has a permission within the scope of a provided client
     *
     * @param permissionType - The permission type to check for
     * @param client - The client object used to get the appropriate role
     *
     * @returns boolean
     *
     * @example
     * const user = (await User.withPermissions.find(1)).data
     * const hasMandateViewerPermission = user.hasPermission('mandate_viewer', '1')
     */
    public hasPermission(permissionType: string, clientId: string): boolean {
      const roles = this.roles.filter((role) => role.client.id == clientId);
      return roles.some((role) => role.hasPermission(permissionType));
    }
  };

export default generateRecordWithRole;
