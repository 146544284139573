import * as React from 'react';

import { Note as NoteModel } from 'javascript/models';

import CurrentUserContext from 'components/shared/context/CurrentUserContext';

import NoteComponent from './Note';
import { Column, Frow } from 'components/frow';

export interface INotesProps {
  notes: NoteModel[];
  newNoteAttributes: Partial<NoteModel>;
  editAction?: any;
  afterCreate?: any;
}

const renderNote = (note: NoteModel) => (
  <Column key={note.id}>
    <NoteComponent {...{ note }} />
  </Column>
);

const Presenter = (props: INotesProps) => {
  const { afterCreate, notes, newNoteAttributes } = props;

  const currentUser = React.useContext(CurrentUserContext);

  const notesJSX = <>{notes.map(renderNote)}</>;

  const newNote = new NoteModel({
    author: currentUser,
    ...newNoteAttributes,
  });

  React.useEffect(() => {
    const previousNewNoteSave = newNote.save;
    newNote.save = async function (...args) {
      const wasPersisted = this.isPersisted;
      const result = await previousNewNoteSave.bind(this)(...args);
      if (result && !wasPersisted) afterCreate(newNote);
      return result;
    };
    newNote.save.bind(newNote);
  }, [newNote]);

  return (
    <Frow gutterSize={2} direction="column">
      {notesJSX}
      <Column>
        <NoteComponent note={newNote} />
      </Column>
    </Frow>
  );
};

export default Presenter;
