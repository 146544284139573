import { Attr, BelongsTo, Model } from 'spraypaint';
import { ApplicationRecord } from './ApplicationRecord';
import { SecurityClassification } from './securities/Classification';

@Model()
export class Flag extends ApplicationRecord {
  public static jsonapiType = 'flags';
  @Attr() public id: string;
  @Attr() public criterionId: string;
  @Attr() public flagableId: string;
  @Attr() public flagableType: string;

  @Attr() public flagType: string;
  @Attr() public value: number;
  @Attr() public minimum: number;
  @Attr() public maximum: number;

  @Attr() public aggregate: boolean;
  @Attr() public enabled: boolean;
  @Attr() public generateActionPoint: boolean;
  @Attr() public inheritFromClient: boolean;
  @Attr() public useDefaults: boolean;

  @BelongsTo(SecurityClassification) public criterion: SecurityClassification;
}
