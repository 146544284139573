import { mergeWith } from 'lodash';
import { curryN, __ } from 'ramda';

const customizer = (objValue, srcValue) => {
  if (Array.isArray(objValue)) return objValue.concat(srcValue);
};

const concatMerge = curryN(3, mergeWith)(__, __, customizer);

export default concatMerge;
