import * as React from 'react';

export interface IThemeContext {
  classNameModifier: 'dark' | 'light' | null;
}

const ThemeContext: IThemeContext = {
  classNameModifier: null,
};

export default React.createContext(ThemeContext);
