import * as React from 'react';

import CustomModal from 'components/shared/CustomModal';
import useVisibility from 'components/shared/customHooks/useVisibility';

import FormRow from 'components/shared/forms/FormRow';

export default function edit({ handleValuationUpdate, valuation }) {
  const { isOpen, handleClose, handleOpen } = useVisibility(false);
  const [fileName, setFileName] = React.useState(valuation.fileName);
  const [valueDate, setValueDate] = React.useState(valuation.valueDate);

  function handleChange(event) {
    setFileName(event.target.value);
  }

  function handleDateChange(event) {
    setValueDate(event.target.value);
  }

  async function handleSubmit(event) {
    event.preventDefault();
    valuation.fileName = fileName;
    valuation.valueDate = valueDate;
    const success = await valuation.save();

    if (success) {
      handleClose();
      handleValuationUpdate();
    }
  }

  return (
    <React.Fragment>
      <div className="button button--secondary button--icon mar-r-1" onClick={handleOpen}>
        <i className="icon-edit icon-fw" />
      </div>
      <CustomModal isOpen={isOpen} handleClose={handleClose} title="Add Comment">
        <div className="modal__content">
          <form className="form form--light" onSubmit={handleSubmit}>
            <div className="frow frow--gutters">
              <FormRow>
                <label htmlFor="" className="form__label">
                  File name
                </label>
                <input type="text" value={fileName} onChange={handleChange} />
              </FormRow>
              <FormRow>
                <label htmlFor="" className="form__label">
                  File name
                </label>
                <input type="date" name="valuation[value_date]" onChange={handleDateChange} value={valueDate} />
              </FormRow>
              <FormRow>
                <button className="button button--full-width button--light-themed">Update</button>
              </FormRow>
            </div>
          </form>
        </div>
      </CustomModal>
    </React.Fragment>
  );
}
