import React from 'react';
import Frow from 'components/frow/Frow';
import Chart from './Chart';
import Table from './Table';

interface IManagerData {
  id: number;
  name: string;
  value: string;
  pct: string;
  opacity?: string;
}

interface IRenderData {
  filterState: string[];
  managers: { data: IManagerData[]; meta: { count: number } };
  selectedType: string | undefined;
}

const RenderData = ({ managers, filterState, selectedType }: IRenderData) => {
  if (selectedType === 'top_five_managers') {
    return (
      <>
        <Chart managers={managers.data.slice(0, 5)} filterState={filterState} />
        <Frow justifyContent="center">
          <div>
            <p className="text-small text-grey-40">
              Breakdown of assets under management for the top 5 investment managers, based on the portfolios with a
              completed review for the selected quarter.
            </p>
            <p className="text-small text-grey-40">
              Note: Consolidated portfolios are excluded to avoid double counting of assets under management "AUM".
            </p>
          </div>
        </Frow>
      </>
    );
  }
  return <Table {...{ managers: managers.data, portfolioCount: managers.meta.count }} />;
};

export default RenderData;
