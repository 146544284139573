import * as React from 'react';
import { toast, ToastContainer, ToastContent, ToastOptions } from 'react-toastify';

export interface IAlertArgs extends Pick<ToastOptions, 'autoClose' | 'type'> {
  content: ToastContent;
  options?: ToastOptions;
}

export interface IAlertProps {
  alerts: IAlertArgs[];
}

export interface ICloseButtonProps {
  closeToast?: (event: any) => void; // Optional because react-toastify doesn't expose the correct type
  [key: string]: any;
}

const CloseButton = ({ closeToast }: ICloseButtonProps) => {
  return <i className="icon-close icon-clickable Toastify__close-button" onClick={closeToast} />;
};

export default function alert(props: IAlertProps) {
  const { alerts } = props;

  const triggerAlert = (alert: IAlertArgs) => {
    if (alert.content) {
      toast(alert.content, { type: alert.type, autoClose: alert.autoClose, ...alert.options });
    }
  };

  const onEventTriggerAlert = (event: CustomEvent<IAlertArgs>) => {
    triggerAlert(event.detail);
  };

  React.useEffect(() => {
    alerts.map(triggerAlert);
  }, [alerts]);

  function removeListener() {
    document.removeEventListener('alerts:new', onEventTriggerAlert);
  }

  React.useEffect(() => {
    document.addEventListener('turbolinks:load', removeListener);
    document.addEventListener('alerts:new', onEventTriggerAlert);
  }, []);

  return <ToastContainer autoClose={false} closeButton={<CloseButton />} />;
}
