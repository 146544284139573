import * as React from 'react';

const classNames = require('classnames');

import lodashStartcase from 'lodash.startcase';
import { format } from 'date-fns';

import { Review } from 'javascript/models';

interface IProps {
  index: number;
  review: Review;
  reviewCount: number;
}

export default function reviewRow(props: IProps) {
  const { index, review, reviewCount } = props;

  function handleClick(event) {
    // This is to stop clicks on the rows from closing the parent
    event.stopPropagation();
  }

  const platformContentClasses = classNames(
    'platform-content',
    'platform-content--padding-all',
    { 'platform-content--border-bottom-mc': !(index + 1 === reviewCount) },
    { 'pad-b-0': index + 1 === reviewCount },
  );

  const { endDate, status } = review;
  return (
    <div className="col-mc-1-1" onClick={handleClick}>
      <div className={platformContentClasses}>
        <div className="frow frow--gutters">
          <div className="col-sm-2-12">
            <span className="small-uppercase">
              End Date: <span className="text-white">{format(endDate, 'DD MMM YYYY')}</span>
            </span>
          </div>
          <div className="col-sm-2-12">
            <span className="small-uppercase">
              Status: <span className="text-white">{lodashStartcase(status)}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
