import * as React from 'react';

import Context from '../../Context';

import ConsultingHeader from '../coverPage/headers/landscape/ConsultingHeader';
import GroupHeader from '../coverPage/headers/landscape/GroupHeader';
import MonitoringHeader from '../coverPage/headers/landscape/MonitoringHeader';
import PortfolioHeader from '../coverPage/headers/landscape/PortfolioHeader';
import CustomHeader from '../coverPage/headers/landscape/CustomHeader';

export default function coverPage({ dummyContainerRef, index }) {
  const context = React.useContext(Context);
  const { reportTemplate } = context.state;

  function renderHeader() {
    switch (reportTemplate.theme) {
      case 'consultancy':
        return <ConsultingHeader />;
      case 'group':
        return <GroupHeader />;
      case 'monitoring':
        return <MonitoringHeader />;
      case 'portfolio':
        return <PortfolioHeader />;
      case 'custom':
        return <CustomHeader {...{ reportTemplate }} />;
    }
  }

  return (
    <div className="pdf-page pdf-page--landscape">
      {renderHeader()}
      <div style={{ opacity: 0 }} ref={dummyContainerRef} />
    </div>
  );
}
