import * as React from 'react';

import * as numeral from 'numeral';
import * as colorFunctions from 'color';

import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Rectangle,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { CustomTooltip } from './reactFloatingBarChart/ChartComponents';

interface IDataItem {
  color: string;
  currentValue: number;
  lowerValue: number;
  name: string;
  strategicValue: number;
  trueValue?: number;
  upperValue: number;
}

interface IProps {
  barSize?: number;
  data: IDataItem[];
  labelSize?: number;
  height: number;
  percentage: boolean;
  isResponsive?: boolean;
  width?: number;
  labelBold?: boolean;
  labelColor?: string;
  lineStroke?: string;
  lineWidth?: number;
  showTicks?: boolean;
  showTooltip?: boolean;
}

const CustomShape = (props) => {
  const { fill, height, lastItem, lineStroke, lineWidth, width, x, y } = props;
  const yPosition = lastItem ? y + height : y;
  const xPosition = x === 0 ? lineWidth : x;
  return <Rectangle width={width} height={height} x={xPosition} y={yPosition} fill={fill} stroke={lineStroke} />;
};

export default function reactFloatingBarChart(props: IProps) {
  const {
    barSize,
    data,
    height,
    isResponsive,
    labelBold,
    labelColor,
    labelSize,
    lineStroke,
    lineWidth,
    percentage,
    showTicks,
    showTooltip,
    width,
  } = props;

  const cells = data.map((dataItem) => {
    return <Cell key={dataItem.name} fill={dataItem.color} />;
  });

  const tickFormatter = (tick) => `${numeral(tick).format('0.00')}${percentage ? '%' : ''}`;
  const labelSizePixel = `${labelSize}px`;

  const transformedData = data.map((dataItem) => {
    dataItem.trueValue = dataItem.upperValue - dataItem.lowerValue;
    return dataItem;
  });

  const currentValueReferenceAreas = data.map((dataItem, index) => {
    const x1 = dataItem.currentValue === 0 ? 0 : dataItem.currentValue - lineWidth / 2;
    const x2 = dataItem.currentValue === 0 ? lineWidth : dataItem.currentValue + lineWidth / 2;
    const y1 = dataItem.name;
    const y2 = index === data.length - 1 ? data[index - 1].name : data[index + 1].name;
    const lastItem = index === data.length - 1;
    const fillColor = colorFunctions(dataItem.color).lighten(0.4).toString();
    return (
      <ReferenceArea
        fill={fillColor}
        key={`current-reference-${dataItem.name}`}
        {...{ x1, x2, y1, y2 }}
        isFront
        height={barSize + 5}
        shape={<CustomShape {...{ lastItem, lineStroke, lineWidth }} />}
      />
    );
  });

  const strategicValueReferenceAreas = data.map((dataItem, index) => {
    const x1 = dataItem.strategicValue === 0 ? 0 : dataItem.strategicValue - lineWidth / 2;
    const x2 = dataItem.strategicValue === 0 ? lineWidth : dataItem.strategicValue + lineWidth / 2;
    const y1 = dataItem.name;
    const y2 = index === data.length - 1 ? data[index - 1].name : data[index + 1].name;
    const lastItem = index === data.length - 1;
    const fillColor = colorFunctions(dataItem.color).darken(0.4).toString();
    return (
      <ReferenceArea
        fill={fillColor}
        key={`strategic-reference-${dataItem.name}`}
        {...{ x1, x2, y1, y2 }}
        isFront
        height={barSize + 5}
        shape={<CustomShape {...{ lastItem, lineStroke, lineWidth }} />}
      />
    );
  });

  const tooltip = <Tooltip content={<CustomTooltip />} cursor={{ fill: 'rgba(39,42,49, 0.6)' }} />;

  if (isResponsive) {
    return (
      <ResponsiveContainer width="100%" height={height}>
        <BarChart
          height={height}
          data={transformedData}
          margin={{ top: 5, right: showTicks ? 15 : 0, left: showTicks ? 30 : -50, bottom: 0 }}
          layout="vertical"
        >
          <CartesianGrid stroke="#d7d7dd" horizontal={false} />
          <XAxis
            type="number"
            domain={[0, 100]}
            tickFormatter={tickFormatter}
            tick={{ fill: labelColor, fontSize: labelSizePixel, fontWeight: labelBold ? 'bold' : 'normal' }}
            ticks={[0, 12.5, 25, 37.5, 50, 62.5, 75, 87.5, 100]}
          />
          <YAxis
            type="category"
            dataKey="name"
            axisLine={false}
            tickLine={false}
            tick={
              showTicks
                ? { fill: labelColor, fontSize: labelSizePixel, fontWeight: labelBold ? 'bold' : 'normal' }
                : false
            }
          />
          <Bar
            dataKey="lowerValue"
            isAnimationActive={false}
            barSize={barSize}
            stackId="default"
            fillOpacity={0}
            fill="#FFF"
          />
          <Bar dataKey="trueValue" barSize={barSize} stackId="default">
            {cells}
          </Bar>
          {showTooltip && tooltip}
          {currentValueReferenceAreas}
          {strategicValueReferenceAreas}
        </BarChart>
      </ResponsiveContainer>
    );
  }

  return (
    <BarChart
      width={width}
      height={height}
      data={transformedData}
      margin={{ top: 5, right: 15, left: 30, bottom: 0 }}
      layout="vertical"
    >
      <CartesianGrid stroke="#d7d7dd" horizontal={false} />
      <XAxis
        type="number"
        domain={[0, 100]}
        tickFormatter={tickFormatter}
        tick={{ fill: labelColor, fontSize: labelSizePixel, fontWeight: 'bold' }}
        ticks={[0, 25, 50, 75, 100]}
      />
      <YAxis
        type="category"
        dataKey="name"
        axisLine={false}
        tickLine={false}
        tick={{ fill: labelColor, fontSize: labelSizePixel, fontWeight: 'bold' }}
      />
      {/* This is the gap to where the bar actually starts which is transparent */}
      <Bar
        dataKey="lowerValue"
        isAnimationActive={false}
        barSize={barSize}
        stackId="default"
        fillOpacity={0}
        fill="#FFF"
      />
      {/* This is the actual colored bar to give the illusion its floating */}
      <Bar dataKey="trueValue" isAnimationActive={false} barSize={barSize} stackId="default">
        {cells}
      </Bar>
      {currentValueReferenceAreas}
      {strategicValueReferenceAreas}
    </BarChart>
  );
}

reactFloatingBarChart.defaultProps = {
  barSize: 45,
  isResponsive: true,
  labelBold: true,
  labelColor: '#b5b6bd',
  labelSize: 8,
  lineStroke: '#b5b6bd',
  lineWidth: 0.5,
  showTicks: true,
  showTooltip: false,
  width: 450,
};
