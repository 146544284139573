import * as React from 'react';

import pluralize from 'pluralize';

export default function (fileCount, theme) {
  return (
    <div className="frow frow--centered-column" style={{ height: '100%' }}>
      <h3 className="heading-one text-grey text-normal mar-t-3">
        <i className="fas fa-circle-notch fa-spin text-brand-windows-blue mar-r-2" style={{ margin: 'auto' }} />
        <span className={theme === 'dark' ? 'text-white' : ''}>Uploading {pluralize('file', fileCount, true)}...</span>
      </h3>
    </div>
  );
}
