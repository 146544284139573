import * as React from 'react';

import pluralize from 'pluralize';

interface Props {
  pluraliseItem?: boolean;
  counterText?: string;
  selection: string[];
  itemType: string;
}

const SelectionCounter = ({ pluraliseItem = false, counterText = 'selected', selection, itemType }: Props) => {
  const numberOfItems = selection.length;
  if (numberOfItems < 1) {
    return null;
  }
  const formatText = () => {
    if (pluraliseItem) {
      return `${pluralize(itemType, numberOfItems, true)} ${counterText}`;
    }
    return `${numberOfItems} ${itemType} ${counterText}`;
  };

  return (
    <div className="frow frow--items-center">
      <span className="text-small text-white mar-v-0 mar-r-2 mar-b-2">{formatText()}</span>
    </div>
  );
};

export default SelectionCounter;
