import React from 'react';

import ReactSelect from 'components/shared/forms/ReactSelect';
import ISelectOption from 'components/interfaces/ISelectOption';
import { startCase } from 'lodash';
import { ActionPointStatus } from 'javascript/models';

interface IActionPointStatusFilter {
  setStatusFilter: React.Dispatch<React.SetStateAction<ActionPointStatus>>;
}

const ActionPointStatusFilter = ({ setStatusFilter }: IActionPointStatusFilter) => {
  const statusOptions: ISelectOption[] = ['all', 'inProgress', 'inReview', 'released'].map((value) => ({
    label: startCase(value),
    value,
  }));

  const handleChange = (selectedOption) => setStatusFilter(selectedOption.value);

  return (
    <ReactSelect
      options={statusOptions}
      theme="dark"
      id="statusFilter"
      placeholder="Filter by Status"
      name="status-filter"
      handleChange={handleChange}
    />
  );
};

export default ActionPointStatusFilter;
