import * as React from 'react';

import { isLoading } from 'components/shared/HigherOrderComponents';

interface IProps {
  meta: {
    stats: {
      total: {
        awaiting_data_count: number;
        error_count: number;
        data_received_count: number;
        count: number;
      };
    };
  };
}

function numbers(props: IProps) {
  const { meta } = props;

  return (
    <div className="frow frow--direction-column frow--gutters">
      <div className="col-mc-1-1">
        <div className="card">
          <div className="card__inner">
            <div className="frow frow--items-center frow--justify-between frow--nowrap">
              <p className="status-indicator status-indicator--mid-green mar-v-0 subtitle-one text-white">
                Data received
              </p>
              <span className="subtitle-one text-white">{meta.stats.total.data_received_count}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-mc-1-1">
        <div className="card">
          <div className="card__inner">
            <div className="frow frow--items-center frow--justify-between frow--nowrap">
              <p className="status-indicator status-indicator--dark-aqua mar-v-0 subtitle-one text-white">
                Awaiting Data
              </p>
              <span className="subtitle-one text-white">{meta.stats.total.awaiting_data_count}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-mc-1-1">
        <div className="card">
          <div className="card__inner">
            <div className="frow frow--items-center frow--justify-between frow--nowrap">
              <p className="status-indicator status-indicator--pale-red mar-v-0 subtitle-one text-white">Error</p>
              <span className="subtitle-one text-white">{meta.stats.total.error_count}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default isLoading()(numbers);
