import * as React from 'react';

export const CustomXAxis = (props) => {
  const name = props.name;
  const { x, y, width } = props.viewBox;
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={width / 2 - 40} y={20} textAnchor="start" fill="#fff" fontWeight="bold" fontSize="12px">
        {name.toUpperCase()}
      </text>
    </g>
  );
};

export const CustomYAxis = (props) => {
  const height = props.height || 150;
  const name = props.name;
  const { x, y } = props.viewBox;
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={-(height / 2) + 35}
        y={45}
        textAnchor="start"
        fill="#fff"
        transform="rotate(-90)"
        fontWeight="bold"
        fontSize="12px"
      >
        {name.toLocaleUpperCase()}
      </text>
    </g>
  );
};
