import * as React from 'react';

import GraphitiTableWrapper from 'components/shared/GraphitiTableWrapper';

import columns from './table/columns';

import { AssetMapping } from 'javascript/models';

interface IProps {
  newPath?: string;
  title: string;
}

export default function graphitiTable(props: IProps) {
  const { newPath, title } = props;

  const scope = AssetMapping.includes([]);

  return (
    <GraphitiTableWrapper
      scope={scope}
      columns={columns()}
      hasHeader
      isExportable={false}
      isSearchable
      searchPlaceholder="Search by name"
      newPath={newPath}
      newText="Add Asset Mapping"
      title={title}
      identifier="assetMapping:index"
    />
  );
}
