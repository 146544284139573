import * as React from 'react';

import ReactSelect from 'components/shared/forms/ReactSelect';

import useDebounce from 'components/shared/customHooks/useDebounce';
import { PortfolioBenchmark } from 'javascript/models';

interface IProps {
  componentBenchmark: {
    name: string;
    proportion: string;
    id: string;
    component_id: string;
    _destroy: boolean;
  };
  index: number;
  handleRemoveClick(): void;
}

export default function componentBenchmark(props: IProps) {
  const { componentBenchmark, handleRemoveClick, index } = props;

  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);

  const debouncedInputValue = useDebounce(inputValue, 300);

  function handleInputChange(input) {
    setInputValue(input);
  }

  React.useEffect(() => {
    if (debouncedInputValue) {
      getBenchmarks();
    } else {
      setOptions([]);
    }
  }, [debouncedInputValue]);

  async function getBenchmarks() {
    const { data } = await PortfolioBenchmark.where({ search: { match: debouncedInputValue } })
      .per(50)
      .all();

    const benchmarkOptions = data.map((benchmark) => ({ label: benchmark.reference, value: benchmark.id }));
    setOptions(benchmarkOptions);
  }

  return (
    <div className={`col-mc-1-1 ${componentBenchmark._destroy ? 'hidden' : ''}`}>
      <input
        type="hidden"
        name={`portfolio_benchmark[benchmarks_components_attributes][${index}][id]`}
        value={componentBenchmark.id}
      />
      <input
        type="hidden"
        name={`portfolio_benchmark[benchmarks_components_attributes][${index}][_destroy]`}
        value={componentBenchmark._destroy ? '1' : '0'}
      />
      <div className="frow frow--gutters frow--items-center">
        <div className="col-md-1-2">
          <label htmlFor="" className="form__label">
            Benchmark
          </label>
          <ReactSelect
            theme="dark"
            id="component_id"
            name={`portfolio_benchmark[benchmarks_components_attributes][${index}][component_id]`}
            options={options}
            handleInputChange={handleInputChange}
            defaultValue={{ label: componentBenchmark.name, value: componentBenchmark.component_id }}
          />
        </div>
        <div className="col-md-1-2">
          <label htmlFor="" className="form__label">
            Proportion
          </label>
          <input
            type="number"
            max={100}
            step={0.01}
            defaultValue={componentBenchmark.proportion}
            name={`portfolio_benchmark[benchmarks_components_attributes][${index}][proportion]`}
            placeholder="50"
          />
        </div>
        <div className="col-mc-1-1">
          <a className="link link--small link--underline" onClick={handleRemoveClick.bind(index)}>
            Remove
          </a>
        </div>
      </div>
    </div>
  );
}
