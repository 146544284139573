import * as React from 'react';

import startCase from 'lodash.startcase';

export default function columns(headers) {
  const allColumns = [
    {
      Header: '',
      accessor: 'name',
      columnName: 'Name',
      id: 'name',
      sortable: false,
    },
  ] as any;

  const headerOrder = [
    'one_year',
    'three_year',
    'five_year',
    'overall',
    'three_year_downside',
    'five_year_downside',
    'downside',
    'negative_periods',
    'positive_periods',
    'positive_periods_pct',
    'max_drawdown',
  ];

  const headerMap = {
    downside: { name: 'Downside Std. Dev. (inception)', pct: true },
    five_year: { name: '5 Yr Std. Dev.', pct: true },
    five_year_downside: { name: '5 Yr Downside Std. Dev.', pct: true },
    max_drawdown: { name: 'Max Drawdown', pct: true },
    negative_periods: { name: 'Negative Periods', pct: false },
    one_year: { name: '1 Yr Std. Dev.', pct: true },
    overall: { name: 'Std. Dev. (Inception)', pct: true },
    positive_periods: { name: 'Positive Periods', pct: false },
    positive_periods_pct: { name: 'Positive Periods %', pct: true },
    three_year: { name: '3 Yr Std. Dev.', pct: true },
    three_year_downside: { name: '3 Yr Downside Std. Dev.', pct: true },
  };

  const sortedHeaders = headers.sort((...compare: string[]) => {
    const [fst, snd] = compare.map((arg) => headerOrder.indexOf(arg));
    return fst - snd;
  });

  sortedHeaders.forEach((header) => {
    allColumns.push({
      Cell: (row) => {
        if (row.original[header]) {
          return (
            <div>
              {row.original[header]}
              {headerMap[header].pct ? '%' : ''}
            </div>
          );
        }
        return <div />;
      },
      Header: headerMap[header] ? `${headerMap[header].name}` : '',
      accessor: header,
      columnName: `${startCase(header)}`,
      id: header,
      sortable: false,
    });
  });

  return allColumns;
}
