import * as React from 'react';
import turbolinks from 'turbolinks';

import { Alert, Intent } from '@blueprintjs/core';
import useVisibility from 'components/shared/customHooks/useVisibility';
import { Review } from 'javascript/models';
import ReviewContext from './ReviewContext';
import Context from '../../../Context';

export default function deleteReview() {
  const context = React.useContext(Context);
  const portfolioId = context.props.portfolio.id;
  const reviewContext = React.useContext(ReviewContext);
  const { id } = reviewContext.props.review.attributes;

  const { isOpen, handleOpen, handleClose } = useVisibility(false);

  async function confirmDelete() {
    const review = new Review({ id });
    review.isPersisted = true;
    const success = await review.destroy();

    if (success) {
      turbolinks.visit(`/platform/portfolios/${portfolioId}`);
    }
  }

  return (
    <React.Fragment>
      <button className="button button--secondary button--danger" onClick={handleOpen}>
        Delete Review
      </button>
      <Alert
        isOpen={isOpen}
        onClose={handleClose}
        canEscapeKeyCancel
        canOutsideClickCancel
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        intent={Intent.DANGER}
        onCancel={handleClose}
        onConfirm={confirmDelete}
        portalContainer={document.body}
      >
        <p className="mar-t-0">Are you sure you want to delete this review?</p>
      </Alert>
    </React.Fragment>
  );
}
