import * as React from 'react';

import Loading from 'components/shared/Loading';

import Section from './Section';

import { ExtraSetting } from 'javascript/models';

export default function extraSettings({ isOpen, portfolio }) {
  // We are getting so much information in the serializer I'm trying to lighten the load by only getting this stuff
  // if we absolutely have to
  const [extraSettings, setExtraSettings] = React.useState([]);
  const [isDataFetched, setIsDataFetched] = React.useState(false);

  React.useEffect(() => {
    if (isOpen && !isDataFetched) {
      getData();
    }
  }, [isOpen]);

  async function getData() {
    const { data } = await ExtraSetting.where({ portfolio_id: portfolio.id }).all();

    setExtraSettings(data);
    setIsDataFetched(true);
  }

  function chunkArray(myArray, chunkSize) {
    const arrayLength = myArray.length;
    const tempArray = [];

    for (let index = 0; index < arrayLength; index += chunkSize) {
      const myChunk = myArray.slice(index, index + chunkSize);
      // Do something if you want with the group
      tempArray.push(myChunk);
    }

    return tempArray;
  }

  function renderExtraSettings() {
    if (!isDataFetched)
      return (
        <div className="col-md-1-1">
          <Loading />
        </div>
      );

    if (extraSettings.length < 1) {
      return (
        <div className="col-mc-1-1">
          <div className="platform-content platform-content--spacing-horizontal">
            <p className="text-white text-small">No extra settings set</p>
          </div>
        </div>
      );
    }

    return chunkArray(extraSettings, 4).map((chunkedArray, index) => {
      const attributes = chunkedArray.map((extraSetting) => {
        return { name: extraSetting.extraSettingsType, value: extraSetting.description };
      });

      return <Section key={index} bordered={index % 3 === 0} attributes={attributes} title="Extra Settings" />;
    });
  }

  return (
    <React.Fragment>
      <div className="platform-content platform-content--spacing-horizontal platform-content--border-bottom h-100">
        <div className="frow">{renderExtraSettings()}</div>
      </div>
    </React.Fragment>
  );
}
