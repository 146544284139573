import { Attr, Model } from 'spraypaint';
import { ApplicationRecord } from './ApplicationRecord';

@Model()
export class ReviewReport extends ApplicationRecord {
  public static jsonapiType = 'review_reports';
  @Attr() public reviewId: string;
  @Attr() public reportTemplateId: string;
  @Attr() public viewingType: string;
  @Attr() public reportGenerated: boolean;
  @Attr() public reportPath: string;
  @Attr() public name: string;
  @Attr() public filename: string;
}
