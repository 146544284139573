import * as React from 'react';

import { isLoading } from '../../../../shared/HigherOrderComponents';
import lastDateInQuarter from '../../../../shared/lastDateInQuarter';

const classNames = require('classnames');
const routes = require('../../../../../javascript/application/routes.js');

import IPieChartData from '../../../../shared/charting/recharts/interfaces/IPieChartData';

import { IFilterOptions } from '../../shared/IFilterOptions';

interface IProps {
  admin: number | string;
  client_type: number | string;
  companyId: number;
  data: IPieChartData[];
  filterOptions?: IFilterOptions;
  isLoading: boolean;
  quarter: number;
  relationship_manager: number | '*';
  year: number;
}

function statuses(props: IProps) {
  const { admin, client_type, data, filterOptions, quarter, year } = props;

  let totalValue = 0;
  if (data.length) {
    totalValue = data.map((data) => data.value).reduce((total, value) => total + value);
  }

  function statusIndicatorClasses(index: number) {
    return classNames('status-indicator', 'status-indicator--mid-aqua', {
      'status-indicator--dark-aqua': [0, 1].includes(index),
    });
  }

  function progressBarFillClasses(index: number) {
    return classNames('status-progress__fill', 'status-progress__fill--mid-aqua', {
      'status-progress__fill--dark-aqua': [0, 1].includes(index),
    });
  }

  function progressWidth(data) {
    return ((data.value / totalValue) * 100).toFixed(2);
  }

  return data.map((data, index) => {
    const filterObject = {
      filters: {
        current_owner_id: [admin],
        end_date: [lastDateInQuarter(quarter, year)],
        client_type: [client_type],
        status: data.statuses,
        ...filterOptions,
      },
    };

    if (data.name === 'Awaiting Data (live)') {
      filterObject['portfolio_status'] = 'live';
    }
    if (data.name === 'Awaiting Data (component)') {
      filterObject['portfolio_status'] = 'component_part';
    }
    return (
      <div className="status-progress" key={index}>
        <div className="frow frow--items-center">
          <div className="col-sm-2-8">
            <p
              className={`status-progress__title ${statusIndicatorClasses(index)}`}
              data-number={[0, 1].includes(index) ? '' : index - 1}
            >
              {data.name}
            </p>
          </div>
          <div className="col-sm-6-8">
            <div className="status-progress__bar">
              <div className="frow frow--items-center" style={{ height: '100%' }}>
                <div className="col-flex-grow-1" style={{ height: '100%' }}>
                  <div className="status-progress__bar-inner">
                    <div className={progressBarFillClasses(index)} style={{ width: `${progressWidth(data)}%` }} />
                    <span className="status-progress__value">{data.value}</span>
                  </div>
                </div>
                <a
                  href={routes.platform_reviews_path(filterObject)}
                  className="button button--secondary button--inline mar-h-1"
                >
                  <i className="icon-arrow-right-top icon-0-9x" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  });
}

export default isLoading()(statuses);
