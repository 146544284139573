import * as React from 'react';

import GraphitiTableWrapper from 'components/shared/GraphitiTableWrapper';

import columns from './table/columns';

import { PortfolioReturn } from 'javascript/models';

export default function table({ id }) {
  const scope = PortfolioReturn.where({
    portfolio_returnable_type: 'PortfolioBenchmark',
    portfolio_returnable_id: id,
  }).order({ value_date: 'desc' });

  return <GraphitiTableWrapper columns={columns()} hasColumnSelector={false} scope={scope} />;
}
