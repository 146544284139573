import React from 'react';

import { ActionPoint } from 'javascript/models';
import ActionPointListItem from './ActionPointListItem';
import ActionPointCard, { IActionPointCardProps } from './ActionPointCard';

type DisplayTypeProps = { displayType: 'list'; width?: never } | { displayType: 'card'; width: string };

interface CommonProps extends Pick<IActionPointCardProps, 'actionPoint' | 'client'> {}
type IProps = CommonProps & DisplayTypeProps;

const ActionPointMapper = ({ actionPoint, displayType, width, client }: IProps) => {
  const displayTypeObj = {
    card: <ActionPointCard key={`card-${actionPoint.id}`} {...{ actionPoint, client, width }} />,
    list: <ActionPointListItem key={`list-${actionPoint.id}`} {...{ actionPoint, client }} />,
  };

  return displayTypeObj[displayType];
};

export default ActionPointMapper;
