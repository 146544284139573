import * as React from 'react';

export default function blankComponent({ templateSection }) {
  const height = templateSection ? templateSection.height : 1.2;
  const pixelHeight = (height / 100) * 842;
  return (
    <div className="pdf-body-copy" style={{ height: `${pixelHeight}px`, opacity: 0.9, fontSize: '0.9rem' }}>
      Intentionally Blank
    </div>
  );
}
