import * as React from 'react';

import ActionPoints from './sectionTypes/ActionPoints';
import BarChart from './sectionTypes/BarChart';
import BetaComparison from './sectionTypes/BetaComparison';
import BlankComponent from './sectionTypes/BlankComponent';
import BlankPageFiller from './sectionTypes/BlankPageFiller';
import ComponentSection from './sectionTypes/ComponentSection';
import Content from './sectionTypes/Content';
import CorrelationMatrix from './sectionTypes/CorrelationMatrix';
import DataTable from './sectionTypes/DataTable';
import Divider from './sectionTypes/Divider';
import ExtraSettings from './sectionTypes/ExtraSettings';
import HorizontalBarChart from './sectionTypes/HorizontalBarChart';
import LineChart from './sectionTypes/LineChart';
import List from './sectionTypes/List';
import LongformCommentary from './sectionTypes/LongformCommentary';
import MapVisual from './sectionTypes/MapVisual';
import MarketCommentary from './sectionTypes/MarketCommentary';
import MultiLayerPieChart from './sectionTypes/MultiLayerPieChart';
import PieChart from './sectionTypes/PieChart';
import PortfolioInformation from './sectionTypes/PortfolioInformation';
import Quote from './sectionTypes/Quote';
import Rating from './sectionTypes/Rating';
import RatingBox from './sectionTypes/RatingBox';
import RatingInfo from './sectionTypes/RatingInfo';
import RatingOverTime from './sectionTypes/RatingOverTime';
import RelationshipManager from './sectionTypes/RelationshipManager';
import Repeating from './sectionTypes/Repeating';
import ReviewComments from './sectionTypes/ReviewComments';
import ScatterChart from './sectionTypes/ScatterChart';
import StackedAreaChart from './sectionTypes/StackedAreaChart';
import Subheading from './sectionTypes/Subheading';
import Subtitle from './sectionTypes/Subtitle';
import TextMedium from './sectionTypes/TextMedium';
import TextSmall from './sectionTypes/TextSmall';
import ThemePage from './sectionTypes/ThemePage';
import Title from './sectionTypes/Title';
import QuestionAnswers from './sectionTypes/QuestionAnswers';
import MandateFlags from './sectionTypes/MandateFlags';

const defaultColors = ['#3990a8', '#5d5f6b', '#b5b6bd', '#78ae6e', '#CFB538'];

function section(type, templateSection, colors, sectionRef) {
  switch (type) {
    case 'action_points':
      return <ActionPoints />;
    case 'area_chart':
      return <StackedAreaChart {...{ colors, templateSection }} />;
    case 'bar_chart':
      return <BarChart {...{ colors, templateSection }} />;
    case 'beta_comparison':
      return <BetaComparison {...{ colors, templateSection }} />;
    case 'blank_component':
      return <BlankComponent {...{ templateSection }} />;
    case 'blank_page_filler':
      return <BlankPageFiller />;
    case 'component_section':
      return <ComponentSection {...{ templateSection }} />;
    case 'contents':
      return <Content {...{ sectionRef, templateSection }} />;
    case 'correlation_matrix':
      return <CorrelationMatrix {...{ templateSection }} />;
    case 'data_table':
      return <DataTable {...{ templateSection }} />;
    case 'divider':
      return <Divider {...{ templateSection }} />;
    case 'extra_settings':
      return <ExtraSettings {...{ templateSection }} />;
    case 'line_chart':
      return <LineChart {...{ colors, templateSection }} />;
    case 'list':
      return <List {...{ templateSection }} />;
    case 'longform_commentary':
      return <LongformCommentary {...{ templateSection }} />;
    case 'horizontal_bar_chart':
      return <HorizontalBarChart {...{ templateSection }} />;
    case 'mandate_flags':
      return <MandateFlags {...{ portfolioFlags: null, templateSection, colors, preview: true }} />;
    case 'map_visual':
      return <MapVisual {...{ templateSection }} />;
    case 'market_commentary':
      return <MarketCommentary />;
    case 'multi_layer_pie_chart':
      return <MultiLayerPieChart {...{ colors, templateSection }} />;
    case 'pie_chart':
      return <PieChart {...{ colors, templateSection }} />;
    case 'portfolio_information':
      return <PortfolioInformation {...{ templateSection }} />;
    case 'quote':
      return <Quote {...{ templateSection }} />;
    case 'rating':
      return <Rating {...{ templateSection }} />;
    case 'rating_box':
      return <RatingBox {...{ templateSection }} />;
    case 'rating_info':
      return <RatingInfo />;
    case 'repeating':
      return <Repeating {...{ templateSection }} />;
    case 'rating_over_time':
      return <RatingOverTime {...{ colors, templateSection }} />;
    case 'relationship_manager':
      return <RelationshipManager {...{ templateSection }} />;
    case 'review_comments':
      return <ReviewComments {...{ colors, templateSection }} />;
    case 'scatter_chart':
      return <ScatterChart {...{ colors, templateSection }} />;
    case 'subheading':
      return <Subheading {...{ templateSection }} />;
    case 'subtitle':
      return <Subtitle {...{ templateSection }} />;
    case 'title':
      return <Title {...{ templateSection }} />;
    case 'text_medium':
      return <TextMedium {...{ templateSection }} />;
    case 'text_small':
      return <TextSmall {...{ templateSection }} />;
    case 'theme_page':
      return <ThemePage {...{ templateSection }} />;
    case 'question_answers':
      return <QuestionAnswers />;
    default:
      return <div>{type}</div>;
  }
}

export function sectionDisplay(type, templateSection = null, colors = defaultColors, sectionRef = null) {
  const topSpacing = templateSection ? templateSection.topSpacing : true;
  const bottomSpacing = templateSection ? templateSection.bottomSpacing : true;

  return (
    <div style={{ paddingTop: topSpacing ? '9px' : '0', paddingBottom: bottomSpacing ? '9px' : '0' }}>
      {section(type, templateSection, colors, sectionRef)}
    </div>
  );
}
