import * as React from 'react';
import * as $ from 'jquery';

import Alert from 'components/shared/Alert';
import Button from 'components/shared/forms/Button';
import CsrfToken from 'components/shared/CsrfToken';
import CustomModal from 'components/shared/CustomModal';
import FormRow from 'components/shared/forms/FormRow';
import ReactSelect from 'components/shared/forms/ReactSelect';
import useVisibility from 'components/shared/customHooks/useVisibility';

import { ajaxErrorString } from 'components/shared/Utils';
import { platformManagersPath } from 'javascript/application/ts_routes';

import Context from '../../Context';

export default function managerCreate() {
  const context = React.useContext(Context);
  const { handleManagerCreation } = context.actions;
  const { managerFormOptions } = context.props;

  const { handleOpen, handleClose, isOpen } = useVisibility(false);

  const [errorMessage, setErrorMessage] = React.useState();
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  function handleSubmit(event) {
    setIsSubmitting(true);
    event.preventDefault();
    event.stopPropagation();
    const form = event.target;
    const formData = new FormData(form);
    $.ajax({
      complete: () => setIsSubmitting(false),
      contentType: false,
      data: formData,
      dataType: 'json',
      error: (jqXhr) => {
        setErrorMessage(ajaxErrorString(jqXhr));
      },
      processData: false,
      success: (response) => {
        handleManagerCreation(response);
        handleClose();
      },
      type: 'POST',
      url: platformManagersPath(),
    });
  }

  return (
    <React.Fragment>
      <button className="button button--secondary button--compact" type="button" onClick={handleOpen}>
        Create New Manager
      </button>
      <CustomModal isOpen={isOpen} handleClose={handleClose} title="New Manager" modifiers={['dark']}>
        <div className="modal__content">
          <Alert type="error" title="Error on submission" message={errorMessage} />
          <form action="" className="form" onSubmit={handleSubmit}>
            <input type="hidden" name="authenticity_token" value={CsrfToken()} />
            <fieldset className="form__fieldset">
              <h4 className="form__fieldset-label">Manager</h4>
              <div className="frow frow--gutters">
                <FormRow>
                  <label htmlFor="manager_name" className="form__label">
                    Name
                  </label>
                  <input type="text" name="manager[name]" id="manager_name" />
                </FormRow>
                <FormRow>
                  <label htmlFor="parent_manager_id" className="form__label">
                    Parent Manager
                  </label>
                  <ReactSelect
                    id="parent_manager_id"
                    isClearable
                    name="manager[parent_manager_id]"
                    theme="dark"
                    options={managerFormOptions.parentManagerOptions}
                  />
                </FormRow>
              </div>
            </fieldset>
            <fieldset className="form__fieldset">
              <h4 className="form__fieldset-label">Primary Manager</h4>
              <div className="frow frow--gutters">
                <FormRow>
                  <label htmlFor="manager_user_first_name" className="form__label">
                    First Name
                  </label>
                  <input
                    type="text"
                    name="manager[manager_users_attributes][0][first_name]"
                    id="manager_user_first_name"
                  />
                </FormRow>
                <FormRow>
                  <label htmlFor="manager_user_last_name" className="form__label">
                    Last Name
                  </label>
                  <input
                    type="text"
                    name="manager[manager_users_attributes][0][last_name]"
                    id="manager_user_last_name"
                  />
                </FormRow>
                <FormRow>
                  <label htmlFor="manager_user_email" className="form__label">
                    Email
                  </label>
                  <input type="text" name="manager[manager_users_attributes][0][email]" id="manager_user_email" />
                </FormRow>
                <FormRow>
                  <label htmlFor="manager_user_phone_number" className="form__label">
                    Phone Number
                  </label>
                  <input
                    type="text"
                    name="manager[manager_users_attributes][0][phone_number]"
                    id="manager_user_phone_number"
                  />
                </FormRow>
              </div>
            </fieldset>
            <div className="col-mc-1-1">
              <Button text="Create" isSubmitting={isSubmitting} submittingText="Creating" />
            </div>
          </form>
        </div>
      </CustomModal>
    </React.Fragment>
  );
}
