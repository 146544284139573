import * as React from 'react';

import ReactPieChart from 'components/shared/charting/recharts/ReactPieChart';
import PieChartTableLegend from '../../../show/PieChartTableLegend';
import { SubtitleOne } from 'components/shared/Typography';

export default function consolidatedBreakdown({ portfolioObject, structureData }) {
  const [selectedNode, setSelectedNode] = React.useState(
    structureData.children.find((child) => child.reference === portfolioObject.reference),
  );

  const colors = [
    '#3990A8',
    '#777881',
    '#78AE6E',
    '#CFB538',
    '#CF7838',
    '#d42727',
    '#f1af09',
    '#4eacd0',
    '#516657',
    '#6c6644',
    '#6F5344',
    '#f6f6f8',
  ];

  const totalValue = structureData.children
    .map((child) => {
      return parseFloat(child.value_float);
    })
    .reduce((accumulator, item) => accumulator + item, 0);

  const formattedPieData = structureData.children.map((childNode, index) => {
    const nodeValue = parseFloat(childNode.value_float);
    const percentageValue = (nodeValue / totalValue) * 100;
    return { name: childNode.reference, color: colors[index], value: percentageValue };
  });

  function handleSelect(selectedNodeName) {
    const node = structureData.children.find((child) => child.reference === selectedNodeName);
    setSelectedNode(node);
  }

  function handleNodeSelect(parentNode, selectedNodeName) {
    const node = parentNode.children.find((child) => child.reference === selectedNodeName);
    setSelectedNode(node);
  }

  const noNodeSelected = (
    <p className="helper-text text-white">Select one of the child portfolios to see its breakdown</p>
  );

  return (
    <div className="frow frow--gutters-2x">
      <div className="col-md-1-2">
        <div className="frow frow--gutters-2x">
          <div className="col-md-1-2">
            <SubtitleOne text={structureData.name} additionalClasses="text-white" />
            <ReactPieChart
              animationDuration={500}
              animationBegin={200}
              data={formattedPieData}
              height={300}
              labelText="Breakdown"
              labelSize="1.5rem"
              noData={formattedPieData.length < 1}
              displayLegend={false}
              activeShape
              setSelectedSlice={handleSelect}
            />
          </div>
          <PieChartTableLegend headerTitle="Breakdown" data={formattedPieData} />
        </div>
      </div>
      <div className="col-md-1-2">
        {selectedNode ? <ChildPieChart {...{ colors, handleNodeSelect, selectedNode }} /> : noNodeSelected}
      </div>
    </div>
  );
}

function ChildPieChart({ colors, handleNodeSelect, selectedNode }) {
  function handleSelect(selectedNodeName) {
    handleNodeSelect(selectedNode, selectedNodeName);
  }

  if (selectedNode.children.length > 0) {
    const summedTotalValue = selectedNode.children
      .map((child) => {
        return child.value_float;
      })
      .reduce((accumulator, item) => accumulator + item, 0);

    const formattedPieData = selectedNode.children.map((childNode, index) => {
      const totalValue = parseFloat(summedTotalValue);
      const nodeValue = parseFloat(childNode.value_float);
      const percentageValue = (nodeValue / totalValue) * 100;
      return { name: childNode.reference, color: colors[index], value: percentageValue };
    });

    return (
      <div className="frow frow--gutters-2x">
        <div className="col-md-1-2">
          <SubtitleOne text={selectedNode.name} additionalClasses="text-white" />
          <ReactPieChart
            animationDuration={500}
            animationBegin={200}
            data={formattedPieData}
            height={300}
            labelText="Breakdown"
            labelSize="1.5rem"
            noData={formattedPieData.length < 1}
            displayLegend={false}
            noDataText="No further breakdown"
            activeShape
            setSelectedSlice={handleSelect}
          />
        </div>
        <PieChartTableLegend headerTitle="Breakdown" data={formattedPieData} />
      </div>
    );
  }

  const assetColors = ['#3990a8', '#78AE6E', '#CF7838', '#5d5f6b', '#CFB538', '#516657'];

  const formattedPieData = selectedNode.assetAllocation.map((dataItem, index) => {
    dataItem.color = assetColors[index];
    dataItem.value = parseFloat(dataItem.value);
    return dataItem;
  });

  return (
    <div className="frow frow--gutters-2x">
      <div className="col-md-1-2">
        <ReactPieChart
          animationDuration={500}
          animationBegin={200}
          activeShape
          data={formattedPieData}
          height={300}
          labelText={selectedNode.reference}
          labelSize="1.5rem"
          noData={formattedPieData.length < 1}
          displayLegend={false}
        />
      </div>
      <PieChartTableLegend headerTitle="Asset Class" data={formattedPieData} />
    </div>
  );
}
