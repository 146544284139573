import * as React from 'react';

import Button from 'components/shared/forms/Button';
import CustomModal from 'components/shared/CustomModal';
import useVisibility from 'components/shared/customHooks/useVisibility';
import Loading from 'components/shared/Loading';
// import ProgressBar from 'components/shared/forms/ProgressBar';

import { DataChaser } from 'javascript/models';
import { createAlert } from 'components/shared/Utils';

export default function summary({ emailType, getTemplates, label }) {
  const { isOpen, handleClose, handleOpen } = useVisibility(false);
  const {
    handleClose: handleMoreDetailsClose,
    handleOpen: handleMoreDetailsOpen,
    isOpen: isMoreDetailsOpen,
  } = useVisibility(false);

  const [isLoading, setIsLoading] = React.useState(true);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [dataChaser, setDataChaser] = React.useState();

  async function handleSendEmails() {
    setIsSubmitting(true);
    dataChaser.status = 'sending';
    const success = await dataChaser.save();
    if (success) {
      createAlert('success', 'Emails Sent', 1500);
      getTemplates();
      handleClose();
    }
    setIsSubmitting(false);
  }

  async function handleButtonClick() {
    handleOpen();
    const newDataChaser = new DataChaser({ emailType });
    const success = await newDataChaser.save();

    if (success) {
      setDataChaser(newDataChaser);
      setIsLoading(false);
    }
  }

  function showSummary() {
    const { portfolios } = dataChaser.preview;

    // Put inside a function instead of a variable so that it only happens when needed
    function portfolioRows() {
      return portfolios.map((portfolio, index) => {
        return (
          <tr key={index}>
            <td>{portfolio.reference}</td>
            <td>{portfolio.emails}</td>
          </tr>
        );
      });
    }

    return (
      <div>
        <p className="mar-t-0">
          {portfolios.length} emails will be sent. See the full list{' '}
          <span onClick={handleMoreDetailsOpen} className="link link--blue">
            here
          </span>
          .
        </p>
        <div className="frow frow--gutters frow--items-center">
          <div className="col-sm-1-3">
            <Button
              text="Send Emails"
              handleClick={handleSendEmails}
              buttonClasses="button--light-themed"
              isSubmitting={isSubmitting}
              submittingText="Sending Emails"
            />
          </div>
          {/*<div className="col-sm-2-3">*/}
          {/*  {isSubmitting && <ProgressBar animate={true} animationSpeed={60} value={0.90} />}*/}
          {/*</div>*/}
        </div>
        <CustomModal modifiers={['dark']} isOpen={isMoreDetailsOpen} handleClose={handleMoreDetailsClose}>
          <div className="modal__content">
            <table className="styled-table">
              <thead>
                <tr>
                  <td>Portfolio Reference</td>
                  <td>Emails</td>
                </tr>
              </thead>
              <tbody>{portfolioRows()}</tbody>
            </table>
          </div>
        </CustomModal>
      </div>
    );
  }

  return (
    <React.Fragment>
      <button onClick={handleButtonClick} className="button">
        {label}
      </button>
      <CustomModal title={label} {...{ isOpen, handleClose }}>
        <div className="modal__content">{isLoading ? <Loading /> : showSummary()}</div>
      </CustomModal>
    </React.Fragment>
  );
}
