import * as React from 'react';
import * as ReactTooltip from 'react-tooltip';

import { startCase } from 'lodash';

import { Security, SecurityState } from 'javascript/models';
import { platformSecurityClassificationStatesClassificationUploadPath } from 'javascript/application/ts_routes';

import { IClassificationSelectProps } from 'components/interfaces/SecurityInterfaces';

import CustomModal from 'components/shared/CustomModal';
import ReactSelect from 'components/shared/forms/ReactSelect';
import useVisibility from 'components/shared/customHooks/useVisibility';
import { createAlert } from 'components/shared/Utils';

import DraftClassificationOptions from '../../form/securityClassificationsForm/classificationEdit/DraftClassificationOptions';

import ClassificationWeightingsSection from './classificationEdit/ClassificationWeightingsSection';

type arglessHandlerFunc = () => void;
type oneArgHandlerFunc = (verification: boolean) => () => void;
type handlerFunc = arglessHandlerFunc | oneArgHandlerFunc;
interface IProps {
  isSubmitting: boolean;
  createDraftClassificationState: any;
  handleDateChange: (date: string) => void;
  selectProps: IClassificationSelectProps;
  selectedState: SecurityState;
  selectedStateGroup: SecurityState[];
  security: Security;
  submitHandlers: handlerFunc[];
}

const verificationTagMap = {
  true: { className: 'tag--green' },
  false: { className: 'tag--orange' },
};

const verifiedLabel = (selectedState) => (
  <span className={`tag mar-l-0 ${verificationTagMap[(selectedState.verified && 'true') || 'false'].className}`}>{`${
    selectedState.verified ? '' : 'Not'
  } Verified`}</span>
);

const statusTagMap = {
  live: { className: 'tag--green' },
  draft: { className: 'tag--dark-orange' },
  superseded: { className: 'tag--slate-grey' },
};

const statusTag = (status: string) => (
  <span className={`tag tag--inline mar-l-0 ${statusTagMap[status].className}`}>{startCase(status)}</span>
);

const classificationEdit = (props: IProps) => {
  const {
    createDraftClassificationState,
    selectProps,
    selectedState,
    security,
    handleDateChange,
    submitHandlers,
    isSubmitting,
  } = props;

  const handleConfirmation: oneArgHandlerFunc = submitHandlers[0] as oneArgHandlerFunc;
  const [__, handleDraftSave, _, handleDelete] = submitHandlers as arglessHandlerFunc[];

  const { isOpen, handleOpen, handleClose } = useVisibility(false);

  const handleCreateDraftClassificationState = (referenceDate: string, useCurrentState: boolean) => {
    createDraftClassificationState(new Date(referenceDate), useCurrentState ? selectedState : undefined);
    handleClose();
    createAlert('success', 'Successfully created.', 1500);
  };

  const setDisabled =
    isSubmitting || selectedState.classifiedBy === 'fin_mason' || selectedState.status === 'live' || !selectedState.id;

  const handlePublishOrVerifyButton = () => {
    if (selectedState.classifiedBy === 'fin_mason')
      if (selectedState.verified)
        return (
          <button type="button" className="button button--compact mar-r-2" disabled>
            Verified
          </button>
        );
      else
        return (
          <button type="button" className="button button--compact mar-r-2" onClick={handleConfirmation(true)}>
            Verify
          </button>
        );
    else
      return (
        <button
          type="button"
          className="button button--compact mar-r-2"
          disabled={isSubmitting || selectedState.status !== 'draft'}
          onClick={handleConfirmation(false)}
        >
          Publish
        </button>
      );
  };

  const findRolledOverState = () => security.states.find((state) => state.id === selectedState.rolledOverFromStateId);

  const selectRolledOverState = () => {
    handleDateChange(findRolledOverState().referenceDate);
  };

  const rolledOverLink = (
    <button className="button button--secondary button--compact mar-t-1" type="button" onClick={selectRolledOverState}>
      Rolled over from here
    </button>
  );

  return (
    <div>
      <div className="form__fieldset pad-b-1 pad-t-1">
        <div className="frow col-3-5 row--justify-between">
          <div className="frow col-3-5 frow--justify-between col-flex-grow-1">
            <h4 style={{ height: '15px' }} className="form__fieldset-label">
              Classification States
            </h4>
            <div>{verifiedLabel(selectedState)}</div>
            <div>
              <span
                data-tip="Please save draft classification prior to uploading data."
                data-for="classification_controls"
                data-tip-disable={!setDisabled || selectedState.status === 'live'}
              >
                <a
                  style={!selectedState.id ? { opacity: '0.5', color: 'currentColor', pointerEvents: 'none' } : null}
                  href={platformSecurityClassificationStatesClassificationUploadPath(selectedState.id)}
                  rel="noreferrer"
                  target="_blank"
                  className="button button--secondary button--no-min-width"
                >
                  Uploads
                  <i className="icon-arrow-right-top icon-fw icon-0-9x mar-l-1" />
                </a>
              </span>
            </div>
          </div>
          <div className="frow frow--row-end col-sm-2-5">
            {handlePublishOrVerifyButton()}
            <button
              type="button"
              className="button button--compact "
              disabled={isSubmitting || selectedState.status !== 'draft'}
              onClick={handleDraftSave}
            >
              Save Draft
            </button>
          </div>
        </div>
        <div className="frow frow--justify-between">
          <div className="frow col-sm-5-7 mar-b-2">
            <div className="frow">
              <label htmlFor="security_cluster" className="form__label">
                Select Classification State
              </label>
              <div className="col-sm-1">
                <ReactSelect {...selectProps} />
              </div>
              <div className="mar-l-2 mar-t-1">{statusTag(selectedState.status)}</div>
            </div>
            {selectedState.rolledOverFromStateId && rolledOverLink}
          </div>
          <div className="frow frow--direction-column">
            <div className="mar-b-2 mar-t-2">
              <button type="button" className="button button--secondary" onClick={handleOpen}>
                <i className="icon-plus icon-fw" /> Create Classification
              </button>
            </div>
            <div className="mar-b-2">
              <button
                type="button"
                className="button button--danger button--secondary"
                disabled={setDisabled}
                onClick={handleDelete}
              >
                <i className="icon-bin icon-fw" /> Delete Classification
              </button>
            </div>
          </div>
        </div>
        <CustomModal
          isOpen={isOpen}
          handleClose={handleClose}
          title="Draft Classification Options"
          modifiers={['visible-overflow']}
        >
          <div className="modal__content">
            <DraftClassificationOptions {...{ selectedState, handleCreateDraftClassificationState }} />
          </div>
        </CustomModal>
      </div>
      <ClassificationWeightingsSection {...{ selectedState }} />
      <ReactTooltip effect="solid" id="classification_controls" />
    </div>
  );
};

export default classificationEdit;
