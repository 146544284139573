import { Model, BelongsTo, Attr } from 'spraypaint';

import { ClientUserRole } from '../roles/ClientUser';
import { Permission } from '../Permission';

@Model()
export class ClientUserPermission extends Permission {
  @Attr public static jsonapiType = 'permissions/client_users';
  @Attr public permissionableId: number;
  @Attr public permissionType: string;
  @Attr public permissionGroup: string;
  @Attr public description: string;

  @BelongsTo(ClientUserRole) public role: ClientUserRole;
}
