import * as React from 'react';

import { DirectUpload } from 'activestorage';

import { format, lastDayOfQuarter, subMonths } from 'date-fns';

import Presenter from './upload/Presenter';

import { Valuation } from 'javascript/models';

export default function upload({ portfolioId }: { portfolioId: string }) {
  const endOfLastQtr = format(lastDayOfQuarter(subMonths(new Date(), 3)), 'YYYY-MM-DD');

  const [files, setFiles] = React.useState([]);
  const [valueDate, setValueDate] = React.useState(endOfLastQtr);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isSubmittable, setIsSubmittable] = React.useState(false);
  const [closeModal, setCloseModal] = React.useState(false);

  React.useEffect(() => {
    if (closeModal) {
      setCloseModal(false);
    }
  }, [closeModal]);

  React.useEffect(() => {
    if (files.length > 0 && !!valueDate) {
      setIsSubmittable(true);
    }
  }, [files, valueDate]);

  function handleUploadedFiles(files) {
    if (files.length < 1) return;
    setFiles(files);
  }

  function handleChange(event) {
    setValueDate(event.target.value);
  }

  function handleSubmit(event) {
    event.preventDefault();
    if (files.length < 1 && !valueDate) return;

    setIsSubmitting(true);

    const signedIds = [];
    files.forEach((file) => {
      const upload = new DirectUpload(file, '/rails/active_storage/direct_uploads');

      upload.create((error, blob) => {
        if (error) {
          setIsSubmitting(false);
        } else {
          createValuation(blob.signed_id).then(() => {
            setFiles([]);
            setIsSubmitting(false);
            setValueDate(endOfLastQtr);
            setCloseModal(true);
            const customEvent = new CustomEvent('valuation:uploaded');
            document.dispatchEvent(customEvent);
          });
        }
      });
    });
  }

  async function createValuation(signedId) {
    const valuation = new Valuation({ document: signedId, portfolio_id: portfolioId, valueDate });
    await valuation.save();
  }

  return (
    <Presenter {...{ closeModal, handleUploadedFiles, handleChange, handleSubmit, isSubmitting, isSubmittable }} />
  );
}
