import * as React from 'react';

export default function apiKey({ apiKey }) {
  const [showKey, setShowKey] = React.useState(false);

  const handleShowClick = () => {
    setShowKey(true);
  };

  if (showKey) {
    return <span>{apiKey ? apiKey : 'None set'}</span>;
  }

  return (
    <a className="link link--blue" onClick={handleShowClick}>
      Click to reveal API key
    </a>
  );
}
