import * as React from 'react';
import * as $ from 'jquery';

import AutoResizeTextArea from 'components/shared/forms/AutoResizeTextArea';
import Button from 'components/shared/forms/Button';
import CsrfToken from 'components/shared/CsrfToken';
import ReviewContext from '../ReviewContext';

interface IProps {
  transition: string;
  handleClose?(): void;
}

// This is no longer used, but leaving it here in case we ever want this functionality in future.
// It allows you to add an audit comment when moving the review forward in the process (which creates a review comment)
export default function approvalComment(props: IProps) {
  const reviewContext = React.useContext(ReviewContext);
  const { transition } = props;
  const { updateStatusPath } = reviewContext.props.review.links;
  const { handleReviewUpdate } = reviewContext.actions;

  const [reviewComment, setReviewComment] = React.useState('');

  function handleChange(value) {
    setReviewComment(value);
  }

  function handleSubmit() {
    const reviewObject = { event: transition };
    if (reviewComment) {
      reviewObject['audit_comment'] = reviewComment;
    }
    $.ajax({
      data: { review: reviewObject, authenticity_token: CsrfToken() },
      dataType: 'json',
      success: () => {
        if (props.handleClose) {
          props.handleClose();
        }
        handleReviewUpdate();
      },
      type: 'PATCH',
      url: updateStatusPath,
    });
  }

  return (
    <div className="modal__content">
      <div className="form form--light">
        <div className="frow frow--gutters">
          <div className="col-mc-1-1">
            <label htmlFor="rejection_reason" className="form__label">
              Add a Comment (optional)
            </label>
            <AutoResizeTextArea
              name="rejection_reason"
              id="rejection_reason"
              value={reviewComment}
              rows={10}
              placeholder="Add a comment to this review"
              onChange={handleChange}
            />
          </div>
          <div className="col-mc-1-1">
            <Button
              buttonClasses="button--compact button--light-themed mar-r-1"
              handleClick={handleSubmit}
              text="Submit"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
