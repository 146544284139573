import * as React from 'react';
import * as $ from 'jquery';
import Button from 'components/shared/forms/Button';
import CustomModal from 'components/shared/CustomModal';
import CsrfToken from 'components/shared/CsrfToken';
import ReviewContext from '../ReviewContext';
import useReviewTransitionReasons from 'components/platform/reviews/index/useReviewTransitionReasons';
import { createAlert, ajaxErrorString } from 'components/shared/Utils';
import { upperFirst } from 'lodash';

const back = () => {
  const reviewContext = React.useContext(ReviewContext);
  const review = reviewContext.props.review;
  const { handleReviewUpdate } = reviewContext.actions;
  const { updateStatusPath } = reviewContext.props.review.links;
  const { status } = review.attributes;

  const [event, setEvent] = React.useState('');
  const [isOpen, setIsOpen] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const [reasonsJSX, selectedReason, clearSelectedReason] = useReviewTransitionReasons();

  React.useEffect(() => {
    setIsSubmitting(false);
  }, [review]);

  function handleModelClose() {
    setIsOpen(false);
  }

  function handleClick() {
    setIsOpen(true);
  }

  const handleSubmit = () => {
    const transition_hash = {
      error_category: selectedReason.errorCategory,
      error_sub_category: selectedReason.errorSubCategory,
      error_source: selectedReason.errorSource,
    };

    $.ajax({
      beforeSend: () => setIsSubmitting(true),
      data: {
        review: {
          event: `back_to_${event}`,
          audit_comment: selectedReason.reason,
          transition_hash,
        },
        authenticity_token: CsrfToken(),
      },
      dataType: 'json',
      error: (jqXhr) => {
        setIsSubmitting(false);
        createAlert('error', upperFirst(ajaxErrorString(jqXhr)), 5000);
      },
      success: () => {
        setIsOpen(false);
        setEvent('');
        clearSelectedReason();
        handleReviewUpdate();
      },
      type: 'PATCH',
      url: updateStatusPath,
    });
  };

  const options = [];

  switch (status) {
    case 'no_review':
      options.push({ value: 'awaiting_data', label: 'Awaiting Data' });
      break;
    case 'in_progress':
      options.push({ value: 'awaiting_data', label: 'Awaiting Data' });
      break;
    case 'in_review':
      options.push({ value: 'awaiting_data', label: 'Awaiting Data' }, { value: 'in_progress', label: 'In Progress' });
      break;
    case 'verification':
      options.push(
        { value: 'awaiting_data', label: 'Awaiting Data' },
        { value: 'in_progress', label: 'In Progress' },
        { value: 'in_review', label: 'In Review' },
      );
      break;
    case 'ready_to_send':
      options.push(
        { value: 'awaiting_data', label: 'Awaiting Data' },
        { value: 'in_progress', label: 'In Progress' },
        { value: 'in_review', label: 'In Review' },
        { value: 'verification', label: 'Verification' },
      );
      break;
    case 'sent':
      options.push(
        { value: 'awaiting_data', label: 'Awaiting Data' },
        { value: 'in_progress', label: 'In Progress' },
        { value: 'in_review', label: 'In Review' },
        { value: 'verification', label: 'Verification' },
        { value: 'ready_to_send', label: 'Ready to Send' },
      );
      break;
  }

  const optionCheckboxes = options.map((option) => {
    function handleEventChange() {
      setEvent(option.value);
    }
    return (
      <div className="col-mc-1-1" key={option.value}>
        <div className="pretty pretty--large pretty--with-label p-round">
          <input type="radio" name="event" value="1" checked={option.value === event} onChange={handleEventChange} />
          <div className="state p-blue-circle">
            <label className="">{option.label}</label>
          </div>
        </div>
      </div>
    );
  });

  let buttonText = 'Back';

  if (status === 'verification') {
    buttonText = 'Reject';
  }

  const reasonsSelected = selectedReason.errorCategory && selectedReason.errorSubCategory && selectedReason.errorSource;

  return (
    <React.Fragment>
      <button className="button button--compact mar-r-1" style={{ minWidth: '120px' }} onClick={handleClick}>
        {buttonText}
      </button>
      <CustomModal
        isOpen={isOpen}
        handleClose={handleModelClose}
        title="Move To Previous Stage"
        modifiers={['visible-overflow']}
      >
        <div className="modal__content">
          <div className="form form--light">
            <div className="frow frow--gutters">
              <div className="col-mc-1-1">
                <label htmlFor="event" className="form__label">
                  Select the stage to move back to
                </label>
                <div className="frow frow--gutters">{optionCheckboxes}</div>
              </div>
              {reasonsJSX}
              <div className="col-mc-1-1">
                <Button
                  buttonClasses="button--compact button--light-themed mar-r-1"
                  isDisabled={!reasonsSelected || !event}
                  isSubmitting={isSubmitting}
                  handleClick={handleSubmit}
                  submittingText="Changing Status"
                  text="Submit"
                />
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </React.Fragment>
  );
};

export default back;
