import * as React from 'react';

import { format, parse, subYears } from 'date-fns';

const numeral = require('numeral');

import { PortfolioReturn } from 'javascript/models';
import Context from '../../Context';

export default function overallReturnMetric({ selectedYear }) {
  const context = React.useContext(Context);
  const { id } = context.props.portfolio.attributes;
  const { mostRecentHoldingsDate } = context.props;

  const [overallReturn, setOverallReturn] = React.useState(null);

  let isMounted = true;

  React.useEffect(() => {
    return () => (isMounted = false);
  }, []);

  React.useEffect(() => {
    getPortfolioReturns();
  }, [selectedYear]);

  async function getPortfolioReturns() {
    let scope = PortfolioReturn.where({ portfolio_returnable_id: id, portfolio_returnable_type: 'Portfolio' })
      .select([])
      .stats({ data: ['overall_return'] });

    if (selectedYear !== 0) {
      scope = scope.where({
        value_date: { gt: format(subYears(parse(mostRecentHoldingsDate), selectedYear), 'YYYY-MM-DD') },
      });
    }

    const { meta } = await scope.all();
    if (!isMounted) return;

    setOverallReturn(meta.stats.data.overall_return);
  }

  return (
    <div className="metric">
      <p className="metric__title">Overall (over selected period)</p>
      <p className="metric__value">{overallReturn ? `${numeral(overallReturn).format('0,0.00')}%` : ''}</p>
      {!overallReturn && '-'}
    </div>
  );
}
