import * as React from 'react';

import { Draggable, Droppable } from 'react-beautiful-dnd';

import Search from 'components/shared/Search';
import { ReportTemplateSection } from 'javascript/models';

import { sectionDisplay } from './sectionDisplay';
import Context from './Context';

export default function sections() {
  const context = React.useContext(Context);
  const { reportTemplate } = context.state;

  const isLandscape = reportTemplate && reportTemplate.orientation === 'landscape';

  const [isLoading, setIsLoading] = React.useState(true);
  const [sections, setSections] = React.useState([]);
  const [selectedTab, setSelectedTab] = React.useState<string>('text');
  const [visibleSections, setVisibleSections] = React.useState([]);

  React.useEffect(() => {
    getData();
  }, []);

  async function getData() {
    const { data } = await ReportTemplateSection.per(999).all();

    setSections(data);
    setVisibleSections(data);
    setIsLoading(false);
  }

  const placeholderSections = [1, 2, 3, 4, 5].map((num) => {
    return (
      <div className="col-mc-1-1" key={num}>
        <div className="bp3-skeleton" style={{ height: '200px', width: '100%' }} />
      </div>
    );
  });

  let colors = ['#3990a8', '#5d5f6b', '#b5b6bd', '#78ae6e', '#CFB538'];

  if (reportTemplate) {
    const theme = reportTemplate.reportTemplateTheme;
    colors = [
      theme.secondaryColorOne,
      theme.secondaryColorTwo,
      theme.secondaryColorThree,
      theme.secondaryColorFour,
      theme.secondaryColorFive,
    ];
  }

  function handleTabChange() {
    setSelectedTab(this);
  }

  const sectionFilter = {
    graph: [
      'beta_comparison',
      'contents',
      'correlation_matrix',
      'rating_box',
      'rating',
      'data_table',
      'line_chart',
      'pie_chart',
      'multi_layer_pie_chart',
      'horizontal_bar_chart',
      'area_chart',
      'bar_chart',
      'scatter_chart',
      'action_points',
      'list',
      'rating_info',
      'rating_over_time',
      'relationship_manager',
      'market_commentary',
      'map_visual',
      'mandate_flags',
    ],
    spacer: ['divider', 'blank_component', 'blank_page_filler'],
    text: [
      'title',
      'text_medium',
      'subtitle',
      'subheading',
      'text_small',
      'quote',
      'review_comments',
      'longform_commentary',
      'question_answers',
      'extra_settings',
      'portfolio_information',
    ],
  };

  if (isLandscape) {
    sectionFilter.spacer.push('theme_page');
    sectionFilter.spacer.push('repeating');
    sectionFilter.spacer.push('component_section');
  }

  const visibleFilteredSections = visibleSections.filter((section) => {
    return sectionFilter[selectedTab].includes(section.sectionType);
  });

  const selectableSections = visibleFilteredSections.map((section) => {
    return (
      <Draggable draggableId={section.id} index={0} key={section.sectionType}>
        {(provided, snapshot) => {
          const style = {
            backgroundColor: snapshot.isDragging ? '#FAFAFA' : '',
            ...provided.draggableProps.style,
          };
          return (
            <div
              className="col-mc-1-1"
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={style}
            >
              <div className="draggable-section">
                <div>{sectionDisplay(section.sectionType, null, colors, null)}</div>
              </div>
            </div>
          );
        }}
      </Draggable>
    );
  });

  function handleSearch(searchTerm: string) {
    if (!searchTerm) {
      setVisibleSections(sections);
      return;
    }
    const filteredSections = sections.filter((section) => section.name.toLowerCase().includes(searchTerm));
    setVisibleSections(filteredSections);
  }

  const tabClasses = 'tabbed-nav__tab tabbed-nav__tab--right-bordered col-flex-grow-1';

  return (
    <div className="platform-panel" style={{ width: '600px', background: 'white' }}>
      <div className="tabbed-nav tabbed-nav--light-themed">
        <div
          className={`${tabClasses} ${selectedTab === 'text' && 'tabbed-nav__tab--active'} `}
          onClick={handleTabChange.bind('text')}
        >
          Text Components
        </div>
        <div
          className={`${tabClasses} ${selectedTab === 'spacer' && 'tabbed-nav__tab--active'}`}
          onClick={handleTabChange.bind('spacer')}
        >
          Spacer / Divider Components
        </div>
        <div
          className={`${tabClasses} ${selectedTab === 'graph' && 'tabbed-nav__tab--active'}`}
          onClick={handleTabChange.bind('graph')}
        >
          Graphs, Tables, Lists & Ratings
        </div>
      </div>
      <div className="platform-content platform-content--padding-top platform-content--padding-2x-horizontal">
        <Search hasClearText={false} handleChange={handleSearch} modifier="light-themed" />
        <p className="helper-text mar-t-1">Click and drag the section into the report</p>
      </div>
      <div className="platform-content platform-content--padding-vertical platform-content--padding-2x-horizontal">
        <Droppable droppableId="droppable-section-selector">
          {(provided, snapshot) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <div className="frow frow--gutters frow--direction-column">
                {isLoading ? placeholderSections : selectableSections}
              </div>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </div>
  );
}
