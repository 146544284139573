import * as React from 'react';

import ReactFloatingBarChart from 'components/shared/charting/recharts/ReactFloatingBarChart';
import ReactHorizontalBarChart from 'components/shared/charting/recharts/ReactHorizontalBarChart';

export default function horizontalBarChart({ templateSection }) {
  const type = templateSection && templateSection.barChartType ? templateSection.barChartType : 'saa_over_under';

  if (type === 'saa_over_under') {
    const data = [
      { name: 'Cash', value: 1, color: '#3990A8' },
      { name: 'Fixed Income', value: -20, color: '#78AE6E' },
      { name: 'Equity', value: 5, color: '#CFB538' },
      { name: 'Hedge Fund', value: -5, color: '#CF7838' },
      { name: 'Other', value: -15, color: '#3B596B' },
    ];

    return <ReactHorizontalBarChart barSize={20} {...{ data }} height={200} percentage />;
  }

  const data = [
    { name: 'Cash', lowerValue: 0, upperValue: 50, color: '#3990A8', currentValue: 1, strategicValue: 5 },
    { name: 'Fixed Income', lowerValue: 15, upperValue: 40, color: '#78AE6E', currentValue: 22, strategicValue: 25 },
    { name: 'Equity', lowerValue: 30, upperValue: 90, color: '#CFB538', currentValue: 52, strategicValue: 50 },
    { name: 'Hedge Fund', lowerValue: 0, upperValue: 15, color: '#CF7838', currentValue: 20, strategicValue: 15 },
    { name: 'Other', lowerValue: 0, upperValue: 5, color: '#3B596B', currentValue: 10, strategicValue: 5 },
  ];

  return (
    <React.Fragment>
      <ReactFloatingBarChart barSize={20} {...{ data }} height={200} percentage />
      <p className="helper-text" style={{ fontSize: '0.7rem' }}>
        Lighter line is current allocation, darker line is strategic allocation.
      </p>
    </React.Fragment>
  );
}
