import * as React from 'react';

const numeral = require('numeral');

import Rating from './Rating';

import ReviewContext from './ReviewContext';
import Context from '../../../Context';

import PortfolioReviewStatuses from './overview/PortfolioReviewStatuses';
import { Portfolio } from 'javascript/models';

export default function overview() {
  const reviewContext = React.useContext(ReviewContext);
  const context = React.useContext(Context);
  const { childPortfolios, currency } = context.props.portfolio.attributes;
  const { review } = reviewContext.props;
  const { balance, balanceSterling } = review.attributes;

  const [liveChildPortfolios, setLiveChildPortfolios] = React.useState<Portfolio[]>();
  const hasChildPortfolios = childPortfolios && childPortfolios.length;
  const getLiveChildPortfolios = async () => {
    if (!childPortfolios || !childPortfolios.length) return null;

    const liveChildPortfolioReq = await Portfolio.where({
      id: childPortfolios.map((portfolio) => portfolio.id),
      status_scope: { not_terminated: true },
    }).all();

    setLiveChildPortfolios(liveChildPortfolioReq.data);
  };

  React.useEffect(() => {
    getLiveChildPortfolios();
  }, [childPortfolios]);

  const annualisedReturns = review.attributes.metrics.returns.find((portfolioReturn) => {
    return portfolioReturn.name === 'Annualised';
  });

  let annualisedOverall = 0;
  if (annualisedReturns) {
    annualisedOverall = annualisedReturns.portfolio;
  }

  const risks = review.attributes.metrics.risks.find((portfolioReturn) => {
    return portfolioReturn.name === 'Overall';
  });

  let standardDeviation = 0;
  if (risks) {
    standardDeviation = risks.portfolio;
  }

  const percentageReturn = (num) => {
    if (num !== 0) {
      return `${numeral(num).format('0,0.00')}%`;
    }
    return '-';
  };
  const RenderPortfolioReviewStatuses = () =>
    !(liveChildPortfolios && hasChildPortfolios) ? null : (
      <div className="col-md-1-1 mar-b-2">
        <div className="metric">
          <p className="metric__title">Component Reviews</p>
          <div className="metric__value metric__value--medium">
            <PortfolioReviewStatuses review={review} portfolios={liveChildPortfolios} />
          </div>
        </div>
      </div>
    );

  return (
    <div
      className="platform-content platform-content--border-right
        platform-content--full-height platform-content--padding-right"
    >
      <div className="frow frow--direction-column">
        <div className="col-mc-1-1 mar-b-2">
          <p className="subtitle-one text-white">Overview</p>
        </div>
        <div className="col-mc-1-1 mar-b-2">
          <Rating />
        </div>
        <div className="col-md-1-1 mar-b-2">
          <div className="metric">
            <p className="metric__title">Value ({currency})</p>
            <p className="metric__value metric__value--medium">{numeral(balance).format('0,0.00')}</p>
          </div>
        </div>
        <div className="col-md-1-1 mar-b-2">
          <div className="metric">
            <p className="metric__title">Value (GBP)</p>
            <p className="metric__value metric__value--medium">{numeral(balanceSterling).format('0,0.00')}</p>
          </div>
        </div>
        <div className="col-md-1-1 mar-b-2">
          <div className="metric">
            <p className="metric__title">Annualised Overall</p>
            <p className="metric__value metric__value--medium">{percentageReturn(annualisedOverall)}</p>
          </div>
        </div>
        <div className="col-md-1-1 mar-b-2">
          <div className="metric">
            <p className="metric__title">Standard Deviation</p>
            <p className="metric__value metric__value--medium">{percentageReturn(standardDeviation)}</p>
          </div>
        </div>
        <RenderPortfolioReviewStatuses />
      </div>
    </div>
  );
}
