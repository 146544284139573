// Function to returns ticks for a given max and min of data
import { convertToPrecision } from './precisionFunctions';

interface IProps {
  max: number;
  min: number;
  tickGap?: number;
}

export default function getTicks(props: IProps) {
  const { min, max } = props;
  // This attempts to ensure that the tickGap is reasonable and avoids some edge cases.
  const tickGap = convertToPrecision(Math.max(props.tickGap, max / 6, Math.abs(min / 6), 0.1), 2);

  const ticks = [0];
  if (max > 0) {
    positiveTicks(max, ticks, tickGap);
  }
  if (min < 0) {
    negativeTicks(min, ticks, tickGap);
  }
  return ticks;
}

// Recursive function that will continue to add ticks using the tickgap until the largest tick is greater than max
function positiveTicks(max: number, ticks: number[], tickGap: number) {
  const largestTick = Math.max(...ticks);
  if (largestTick <= max) {
    ticks.push(largestTick + tickGap);
    positiveTicks(max, ticks, tickGap);
  }
}

// Recursive function that will continue to add ticks using the tickgap until the smallest tick is smaller than min
function negativeTicks(min: number, ticks: number[], tickGap: number) {
  const smallestTick = Math.min(...ticks);
  if (smallestTick >= min) {
    ticks.push(smallestTick - tickGap);
    negativeTicks(min, ticks, tickGap);
  }
}
