import * as React from 'react';

import * as numeral from 'numeral';
import {
  CartesianGrid,
  Label,
  Legend,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  customisedCircle,
  customizedCross,
  customizedDiamond,
  customizedSquare,
  customizedStar,
  customizedTriangle,
  customizedWye,
  CustomLegend,
  CustomTooltip,
  CustomXAxis,
  CustomYAxis,
} from './reactScatterChart/ChartComponents';

const tickFormatter = (tick) => numeral(tick).format('0,0.0');

interface IProps {
  colors: { [key: string]: string };
  hasLegend?: boolean;
  legendPosition?: string;
  height: number;
  series: any;
  xAxisName: string;
  yAxisName: string;
}

export default function reactScatterChart(props: IProps) {
  const { colors, hasLegend, height, series, xAxisName, yAxisName } = props;

  const shapes: ('circle' | 'cross' | 'diamond' | 'square' | 'star' | 'triangle' | 'wye')[] = [
    'circle',
    'diamond',
    'square',
    'triangle',
    'cross',
    'star',
    'wye',
  ];

  const customShapes = [
    customisedCircle,
    customizedDiamond,
    customizedSquare,
    customizedTriangle,
    customizedCross,
    customizedStar,
    customizedWye,
  ];

  const scatters = series.map((series, index) => {
    const shape = shapes[index % shapes.length];
    const customShape = customShapes[index % customShapes.length];
    const colour = colors[series[0].name];
    return (
      <Scatter
        key={index}
        data={series}
        fill={colour}
        isAnimationActive={false}
        stroke={colour}
        legendType={shape}
        shape={customShape}
      />
    );
  });

  const legend = <Legend layout="vertical" verticalAlign="top" align="right" content={<CustomLegend />} />;

  return (
    <ResponsiveContainer height={height} width="100%">
      <ScatterChart width={500} height={height} margin={{ top: 5, right: 15, left: 0, bottom: 10 }}>
        <CartesianGrid stroke="#353741" strokeWidth="2" fill="#1A1A1A" />
        <XAxis
          dataKey="xAxis"
          domain={[0, 'auto']}
          interval={0}
          tick={{ fill: '#FFF', fontSize: '12px', fontWeight: 'bold' }}
          tickCount={5}
          tickSize={0}
          tickMargin={8}
          tickFormatter={tickFormatter}
          type="number"
          unit="%"
        >
          <Label content={<CustomXAxis name={xAxisName} />} />
        </XAxis>
        <YAxis
          dataKey="y"
          domain={[0, 'auto']}
          tick={{ fill: '#FFF', fontSize: '12px', fontWeight: 'bold' }}
          tickCount={7}
          tickSize={0}
          tickMargin={5}
          tickFormatter={tickFormatter}
          unit="%"
        >
          <Label content={<CustomYAxis {...{ height }} name={yAxisName} />} />
        </YAxis>
        {scatters}
        {hasLegend && legend}
        <Tooltip content={<CustomTooltip />} />
      </ScatterChart>
    </ResponsiveContainer>
  );
}
