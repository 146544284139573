import * as React from 'react';

import { both, compose, equals, not, prop } from 'ramda';
import { startCase } from 'lodash';

import { SecurityState, SecurityStateClassificationWeighting, SecurityClassification } from 'javascript/models';

import ClassificationSelect from './classificationSection/ClassificationSelect';
import useRerender from 'components/shared/customHooks/useRerender';
import { Frow, Column } from 'components/frow';
import Loading from 'components/shared/Loading';

interface IProps {
  classificationType: string;
  groupedClassifications: any;
  selectedState: SecurityState;
  typeMap: any;
}

const ClassificationSection = (props: IProps) => {
  const { classificationType, groupedClassifications, selectedState, typeMap } = props;

  const rerender = useRerender();

  const handleNewClassification = () => {
    const newClassification = new SecurityStateClassificationWeighting({
      valueType: classificationType,
      weighting: 0,
    });
    selectedState.classificationWeightings.push(newClassification);
    rerender();
  };

  const activeClassificationWeightings = selectedState.classificationWeightings.filter(
    both(compose(not, prop('isMarkedForDestruction')), compose(equals(classificationType), prop('valueType'))),
  );

  if (!groupedClassifications || !typeMap) return <Loading />;

  const classificationSelects = activeClassificationWeightings.map((securityValue, index) => (
    <ClassificationSelect
      key={index}
      {...{
        classificationType,
        classifications: groupedClassifications[typeMap[classificationType]],
        index,
        securityValue,
        selectedClassificationState: selectedState,
      }}
    />
  ));

  const disableAdditionalClassificationButton = () => {
    if (selectedState.status !== 'draft') return true;
    if (classificationType !== 'headline_asset_class') return false;

    return activeClassificationWeightings.length !== 0;
  };

  const addAdditionalClassificationButton = (
    <Frow justifyContent="between">
      <div className="mar-b-1">
        <button
          className="button button--secondary button--compact"
          type="button"
          disabled={disableAdditionalClassificationButton()}
          onClick={handleNewClassification}
        >
          {`Add New ${startCase(classificationType)} Classification`}
        </button>
      </div>
    </Frow>
  );

  return (
    <fieldset className="form__fieldset">
      <Frow gutterSize={1}>
        <Column>
          <Frow justifyContent="between">
            <label htmlFor="" className="form__label">
              {startCase(classificationType)}
            </label>
            {addAdditionalClassificationButton}
          </Frow>
          <Frow gutterSize={1} contentAlignment="around">
            {classificationSelects}
          </Frow>
        </Column>
      </Frow>
    </fieldset>
  );
};

export default ClassificationSection;
