import React from 'react';

import { User } from 'javascript/models';

import Loading from 'components/shared/Loading';

import ClientSelection from './ClientSelection';
import RoleSelection from './RoleSelection';

interface IClientRoles {
  user: User;
}

const ClientRoles = ({ user }: IClientRoles) => (
  <fieldset className="form__fieldset">
    <ClientSelection {...{ user }} />
    {user.clients.length > 0 ? <RoleSelection {...{ user }} /> : <Loading />}
  </fieldset>
);

export default ClientRoles;
