import * as React from 'react';

import { Droppable } from 'react-beautiful-dnd';

const classNames = require('classnames');

import ColorSelect from './ColorSelect';

import ConsultingFooter from './footers/ConsultingFooter';
import CustomFooter from './footers/CustomFooter';
import GroupFooter from './footers/GroupFooter';
import MonitoringFooter from './footers/MonitoringFooter';
import PortfolioFooter from './footers/PortfolioFooter';

import ConsultingHeader from './coverPage/headers/portrait/ConsultingHeader';
import CustomHeader from './coverPage/headers/portrait/CustomHeader';
import GroupHeader from './coverPage/headers/portrait/GroupHeader';
import MonitoringHeader from './coverPage/headers/portrait/MonitoringHeader';
import PortfolioHeader from './coverPage/headers/portrait/PortfolioHeader';

import Rows from '../report/Rows';
import Context from '../Context';

export default function coverPage({ dummyContainerRef, index, templateRows }) {
  const context = React.useContext(Context);
  const { canEdit } = context.props;
  const { focus, pageColors, reportTemplate } = context.state;

  function renderHeader() {
    switch (reportTemplate.theme) {
      case 'consultancy':
        return <ConsultingHeader />;
      case 'group':
        return <GroupHeader />;
      case 'monitoring':
        return <MonitoringHeader />;
      case 'portfolio':
        return <PortfolioHeader />;
      case 'custom':
        return <CustomHeader {...{ reportTemplate }} />;
    }
  }

  function renderFooter() {
    switch (reportTemplate.theme) {
      case 'consultancy':
        return <ConsultingFooter {...{ index }} />;
      case 'group':
        return <GroupFooter {...{ index }} />;
      case 'monitoring':
        return <MonitoringFooter {...{ index }} />;
      case 'portfolio':
        return <PortfolioFooter {...{ index }} />;
      case 'custom':
        return <CustomFooter {...{ index }} />;
    }
  }

  const pageClasses = classNames(
    'pdf-page',
    { 'pdf-page--default': pageColors[index] && pageColors[index] === 'white' },
    { 'pdf-page--grey': pageColors[index] && pageColors[index] === 'grey' },
  );

  return (
    <div className={pageClasses}>
      {renderHeader()}
      <div className="pdf-main" style={{ height: `${100 - 6.37 - reportTemplate.reportTemplateTheme.headerHeight}%` }}>
        <ColorSelect {...{ index }} />
        <Droppable droppableId={`droppable-report-${index}`} isDropDisabled={focus !== 'report'}>
          {(provided, snapshot) => {
            const styles = {
              background: snapshot.isDraggingOver ? '#EEE' : '',
              height: '100%',
              transition: 'background-color 0.2s ease',
              ...provided.droppableProps.style,
            };
            return (
              <div ref={provided.innerRef} {...provided.droppableProps} style={styles}>
                <Rows {...{ canEdit, dummyContainerRef, templateRows }} />
                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
      </div>
      {renderFooter()}
      <div style={{ opacity: 0 }} ref={dummyContainerRef} />
    </div>
  );
}
