import * as React from 'react';

import ActionPoints from './ActionPoints';
import Answers from './Answers';
import Commentary from './Commentary';
import Comments from './Comments';
import Data from './Data';
import DeleteReview from './DeleteReview';
import GeneratedReports from './GeneratedReports';
import History from './History';
import Info from './Info';
import Overview from './Overview';
import Reports from './Reports';
import StageIndicator from './StageIndicator';
import Context from '../../../Context';
import ReviewContext from './ReviewContext';

export default function presenter() {
  const context = React.useContext(Context);
  const { canManageReviews } = context.props;
  const { status } = context.props.portfolio.attributes;
  const reviewContext = React.useContext(ReviewContext);
  const reviewStatus = reviewContext.props.review.attributes.status;

  const { answers } = context.props.portfolio.attributes;

  const isRealReview = !['component_part', 'loan'].includes(status);

  function renderReviewAttributes() {
    if (!isRealReview) {
      return (
        <div className="platform-content platform-content--padding-horizontal">
          <p className="text-white text-small">
            A review is not being generated because this portfolio is marked as a component part
          </p>
        </div>
      );
    }

    return (
      <React.Fragment>
        <Reports />
        <GeneratedReports />
        <Commentary />
        {answers && <Answers />}
        <ActionPoints />
      </React.Fragment>
    );
  }

  return (
    <div className="platform-content platform-content--spacing-all">
      <StageIndicator />
      <Info />
      <div className="platform-content platform-content--padding-vertical platform-content--border-bottom">
        <div className="frow">
          <div className="col-md-3-12 col-lg-2-12">
            <Overview />
          </div>
          <div className="col-md-9-12 col-lg-10-12">{renderReviewAttributes()}</div>
        </div>
      </div>
      {isRealReview && <Data />}
      <Comments />
      <History />
      {canManageReviews && reviewStatus === 'awaiting_data' && <DeleteReview />}
    </div>
  );
}
