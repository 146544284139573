import * as React from 'react';
import { Manager } from 'javascript/models';

import Presenter from 'components/shared/notes/Presenter';
import Loading from 'components/shared/Loading';
import Section from 'components/platform/Section';
import CurrentUserContext from 'components/shared/context/CurrentUserContext';
import { Administrator } from 'javascript/models';

interface INotesProps {
  managerId: string;
  currentUserId: string;
}

const Notes = (props: INotesProps) => {
  const { currentUserId, managerId } = props;

  const [manager, setManager] = React.useState<Manager>();
  const [currentUser, setCurrentUser] = React.useState();

  const getCurrentUser = async () => setCurrentUser((await Administrator.find(currentUserId)).data);
  React.useEffect(() => {
    getCurrentUser();
  }, [currentUserId]);

  const getManager = async () => {
    const { data } = await Manager.includes({ notes: 'author' }).find(managerId);
    setManager(data);
  };

  React.useEffect(() => {
    getManager();
  }, []);

  const newNoteAttributes = {
    manager: [manager],
    note_category: 'note',
    note_type: 'general',
    status: 'normal',
  };

  return (
    <Section title="Notes">
      <CurrentUserContext.Provider value={currentUser}>
        {(currentUser && manager && <Presenter notes={manager.notes} newNoteAttributes={newNoteAttributes} />) || (
          <Loading />
        )}
      </CurrentUserContext.Provider>
    </Section>
  );
};

export default Notes;
