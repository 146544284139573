import { Attr, Model } from 'spraypaint';
import { ApplicationRecord } from './ApplicationRecord';

@Model()
export class ClusterMapping extends ApplicationRecord {
  public static jsonapiType = 'cluster_mappings';
  @Attr() public cluster: string;
  @Attr() public assetClass: string;
  @Attr() public continentalGroup: string;
  @Attr() public emOrDm: string;
  @Attr() public regionalTilt: string;
  @Attr() public country: string;
  @Attr() public styleTilt: string;
  @Attr() public risk: string;
  @Attr() public capitalisation: string;
  @Attr() public currencyBias: string;
}
