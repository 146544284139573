import * as React from 'react';
import * as $ from 'jquery';

import { platformAdministratorsNotificationPath } from 'javascript/application/ts_routes';

import { setCallback } from 'javascript/client/notification';
import CsrfToken from 'components/shared/CsrfToken';

import Presenter from './notifications/Presenter';

import { Notification } from 'javascript/models';

export default function notifications() {
  const [notifications, setNotifications] = React.useState([]);
  const [pageSize, setPageSize] = React.useState(5);
  const [totalCount, setTotalCount] = React.useState(0);
  const [unreadCount, setUnreadCount] = React.useState(0);

  React.useEffect(() => {
    setTimeout(() => {
      getNotifications();
    }, 0);
  }, [pageSize]);

  async function getNotifications() {
    const { data, meta } = await Notification.where({ viewed_status: { unviewed: true } })
      .order({ notify_on: 'desc' })
      .order({ id: 'desc' })
      .stats({ total: ['unread_count', 'count'] })
      .per(pageSize)
      .all();

    setUnreadCount(meta.stats.total.unread_count);
    setTotalCount(meta.stats.total.count);
    setNotifications(data);
  }

  function handleShowMore() {
    setPageSize(pageSize + 5);
  }

  setCallback((response) => {
    const { notification } = response;
    if (notification) {
      const allNotifications = [...notifications];
      const updatedNotification = { ...notification };
      updatedNotification.notifyOn = updatedNotification.notify_on;
      updatedNotification.viewed = false;
      setUnreadCount(unreadCount + 1);
      allNotifications.unshift(updatedNotification);
      setNotifications(allNotifications);
    }
  });

  async function toggleReadStatus(id: string) {
    $.ajax({
      data: { authenticity_token: CsrfToken() },
      dataType: 'json',
      success: () => {
        let newUnreadCount = unreadCount;
        const updatedNotifications = [
          ...notifications.map((notification) => {
            if (notification.id === id) {
              notification.viewed = !notification.viewed;
              if (notification.viewed) {
                newUnreadCount -= 1;
              } else {
                newUnreadCount += 1;
              }
            }
            return notification;
          }),
        ];
        setUnreadCount(newUnreadCount);
        setNotifications(updatedNotifications);
      },
      type: 'PATCH',
      url: platformAdministratorsNotificationPath(id),
    });
  }

  return <Presenter {...{ handleShowMore, notifications, pageSize, toggleReadStatus, totalCount, unreadCount }} />;
}
