import * as React from 'react';

import TinyMce from 'components/shared/forms/TinyMce';

export default function textSmall({ attributes, handleChange }) {
  function handleTextChange(content) {
    const event = { target: { name: 'text', value: content } };
    handleChange(event);
  }

  return (
    <div className="frow frow--gutters">
      <div className="col-mc-1-1">
        <label className="form__label" htmlFor="">
          Body Text
        </label>
        <TinyMce initialValue={attributes.text || ''} handleChange={handleTextChange} />
      </div>
    </div>
  );
}
