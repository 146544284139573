import { Attr, Model } from 'spraypaint';

import { ApplicationRecord } from './ApplicationRecord';

@Model()
export class PortfolioReturn extends ApplicationRecord {
  public static jsonapiType = 'portfolio_returns';
  @Attr() public monthReturn: number;
  @Attr() public overallReturn: number;
  @Attr() public valueDate: string;
}
