import * as React from 'react';
import * as numeral from 'numeral';
import { Surface, Symbols } from 'recharts';

export const customisedCircle = (props) => {
  const { cx, cy, fill, size, stroke } = props;
  return <Symbols cx={cx} cy={cy} type="circle" size={size * 2} fill={fill} stroke={stroke} />;
};

export const customizedTriangle = (props) => {
  const { cx, cy, fill, size, stroke } = props;
  return <Symbols cx={cx} cy={cy} type="triangle" size={size * 2} fill={fill} stroke={stroke} />;
};

export const customizedDiamond = (props) => {
  const { cx, cy, fill, size, stroke } = props;
  return <Symbols cx={cx} cy={cy} type="diamond" size={size * 2} fill={fill} stroke={stroke} />;
};

export const customizedSquare = (props) => {
  const { cx, cy, fill, size, stroke } = props;
  return <Symbols cx={cx} cy={cy} type="square" size={size * 2} fill={fill} stroke={stroke} />;
};

export const customizedCross = (props) => {
  const { cx, cy, fill, size, stroke } = props;
  return <Symbols cx={cx} cy={cy} type="cross" size={size * 2} fill={fill} stroke={stroke} />;
};

export const customizedStar = (props) => {
  const { cx, cy, fill, size, stroke } = props;
  return <Symbols cx={cx} cy={cy} type="star" size={size * 2} fill={fill} stroke={stroke} />;
};

export const customizedWye = (props) => {
  const { cx, cy, fill, size, stroke } = props;
  return <Symbols cx={cx} cy={cy} type="wye" size={size * 2} fill={fill} stroke={stroke} />;
};

export const CustomYAxis = (props) => {
  const name = props.name || 'Axis Name';
  const height = props.height;
  const { x, y } = props.viewBox;
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={-(height / 2 - 20)}
        y={10}
        textAnchor="middle"
        fill="#FFF"
        transform="rotate(-90)"
        fontWeight="bold"
        fontSize="12px"
      >
        {name.toLocaleUpperCase()}
      </text>
    </g>
  );
};

export const CustomXAxis = (props) => {
  const name = props.name || 'Axis Name';
  const nameLength = name.length;
  const { width, x, y } = props.viewBox;
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={width / 2 - (nameLength * 10) / 2}
        y={35}
        textAnchor="start"
        fill="#FFF"
        fontWeight="bold"
        fontSize="12px"
      >
        {name.toUpperCase()}
      </text>
    </g>
  );
};

export const CustomLegend = (props) => {
  const { payload } = props;

  const legends = payload.map((payloadItem, index) => {
    const legendTitle = payloadItem.payload.data[0].name;

    const { legendType, fill, stroke } = payloadItem.payload;

    const size = ['circle', 'square'].includes(legendType) ? 100 : 75;
    const height = legendType === 'diamond' ? 18 : 15;

    return (
      <div key={index} className="col-mc-1-1" style={{ marginBottom: '8px' }}>
        <div className="frow frow--items-center">
          <span className="mar-r-1">
            <Surface width={15} height={height}>
              <Symbols cx={8} cy={8} type={legendType} size={size} fill={fill} stroke={stroke} />
            </Surface>
          </span>
          <span style={{ fontSize: '1.26rem', whiteSpace: 'nowrap', color: '#fff' }}>{legendTitle}</span>
        </div>
      </div>
    );
  });

  return (
    <div style={{ border: '1px solid #e4e4e8', padding: '15px', width: '200px' }} className="mar-l-4">
      <table className="pdf-hidden-table mar-t-3 mar-b-2" style={{ height: '100%' }}>
        <tbody>
          <tr>
            <td style={{ verticalAlign: 'middle' }}>
              <div className="frow frow--direction-column">{legends}</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export const CustomTooltip = (props) => {
  const { active, payload } = props;

  if (active) {
    const xAxis = payload[0].payload;
    const wrapperStyles = {
      background: 'rgba(39,42,49, 0.6)',
      padding: '12px 0',
      width: '300px',
    };

    const valueStyles = {
      color: 'white',
      fontSize: '13px',
      fontWeight: 'bold' as 'bold',
      margin: 0,
      textAlign: 'center' as any,
    };

    const labelStyles = {
      textAlign: 'center' as any,
    };

    return (
      <div style={wrapperStyles}>
        <p style={labelStyles} className="small-uppercase mar-t-0 text-white">
          {xAxis.name}
        </p>
        <div>
          <div style={valueStyles} className="frow frow--items-center frow--gutters">
            <div className="col-mc-2-5 col-md-3-8 text-left">
              <span className="text-normal">Risk</span>
            </div>
            <div className="col-mc-3-5 col-md-5-8">{numeral(xAxis.xAxis).format('0,0.00')}%</div>
            <div className="col-mc-2-5 col-md-3-8 text-left">
              <span className="text-normal">Return</span>
            </div>
            <div className="col-mc-3-5 col-md-5-8">{numeral(xAxis.y).format('0,0.00')}%</div>
          </div>
        </div>
      </div>
    );
  }

  return null;
};
