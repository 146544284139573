import * as React from 'react';

import ModalWithTrigger, { IModalWithTriggerProps } from './ModalWithTrigger';
import ThemeContext from 'components/shared/context/ThemeContext';

export interface IModalGraph {
  title: string;
  children: JSX.Element;
}

const ModalGraph = ({ title, children }: IModalGraph) => {
  const buttonText = (
    <>
      View Larger
      <i className="icon-arrow-right-top icon-0-9x mar-l-1" />
    </>
  );

  const modalWithTriggerProps: IModalWithTriggerProps = {
    buttonText,
    buttonClassName: 'button--secondary button--no-min-width',
    title,
    modifiers: ['rounded'],
  };

  return (
    <ThemeContext.Provider value={{ classNameModifier: 'dark' }}>
      <ModalWithTrigger {...modalWithTriggerProps}>{children}</ModalWithTrigger>
    </ThemeContext.Provider>
  );
};

export default ModalGraph;
