import * as React from 'react';

import OutsideClickHandler from 'react-outside-click-handler';
import useVisibility from 'components/shared/customHooks/useVisibility';
const classNames = require('classnames');

const clientTypeLogo = require('../../../../images/icons/white-plus-divide.svg').default;

interface IProps {
  selectedClientType: number | string;
  selectableClientTypes: { display: string; icon: string; value: number }[];
  handleClientTypeSelect(value: number | string): void;
}

export default function clientTypeSelect(props: IProps) {
  const { handleClose, handleOpen, isOpen, toggleOpen } = useVisibility(false);

  const iconStyles = { width: '16px', height: '18px', marginRight: '13px' };

  function handleSelectOption(value: string | number) {
    handleClose();
    props.handleClientTypeSelect(value);
  }

  function renderDropdownOptions() {
    const clientTypes = props.selectableClientTypes.map((clientType, index) => {
      return (
        <div className="dropdown__nav-link" key={index} onClick={() => handleSelectOption(clientType.value)}>
          <div className="frow frow--items-center">
            <img src={clientType.icon} alt="Client Type" style={iconStyles} />
            {clientType.display}
          </div>
        </div>
      );
    });
    clientTypes.unshift(
      <div key={Math.random()} className="dropdown__nav-link" onClick={() => handleSelectOption('*')}>
        <div className="frow frow--items-center">
          <img src={clientTypeLogo} alt="Client Type" style={iconStyles} />
          All Client Types
        </div>
      </div>,
    );
    return clientTypes;
  }

  function selectedClientType() {
    const clientType = props.selectableClientTypes.find((clientType) => {
      return clientType.value === props.selectedClientType;
    });
    if (clientType) {
      return (
        <div className="frow frow--items-center">
          <img src={clientType.icon} alt="Client Type" style={iconStyles} />
          <span style={{ width: '140px' }} className="text-normal">
            {clientType.display}
          </span>
          <i className={`button__icon fa fa-caret-down fa-animated ${isOpen ? 'fa-flipped' : ''}`} />
        </div>
      );
    }
    return (
      <div className="frow frow--items-center">
        <img src={clientTypeLogo} alt="Client Type" style={iconStyles} />
        <span style={{ width: '140px' }} className="text-normal">
          All Client Types
        </span>
        <i className={`button__icon fa fa-caret-down fa-animated ${isOpen ? 'fa-flipped' : ''}`} />
      </div>
    );
  }

  const dropdownClasses = classNames('dropdown dropdown--sw', { 'dropdown--active': isOpen });

  return (
    <div className={dropdownClasses}>
      <OutsideClickHandler onOutsideClick={handleClose}>
        <a className="dropdown__toggle dropdown__toggle--input" onClick={toggleOpen}>
          {selectedClientType()}
        </a>
        <div className="dropdown__content">
          <div className="dropdown__content-inner">{renderDropdownOptions()}</div>
        </div>
      </OutsideClickHandler>
    </div>
  );
}
