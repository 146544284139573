import * as React from 'react';

import { prop } from 'ramda';

import DualListBox from 'react-dual-listbox';

import Search from 'components/shared/Search';

import { Portfolio } from 'javascript/models';

export interface IPortfolioOption {
  label: string;
  value: string;
}

const PortfolioSelect = (props) => {
  const [inputValue, setInputValue] = React.useState('');
  const [isSearching, setIsSearching] = React.useState(false);

  function handleInputChange(searchTerm){
    setInputValue(searchTerm);
  };

  const portfolioOptions = (portfolios) =>
    portfolios &&
    portfolios.map((portfolio) => ({ value: portfolio.id, label: portfolio.reference }));

  const portfolioOptionsArr = portfolioOptions(props.selected) || [];

  const [options, setOptions] = React.useState(portfolioOptionsArr);
  const [selected, setSelected] = React.useState([
    { label: 'Currently associated portfolios', options: portfolioOptionsArr },
  ]);

  const onlyUnique = (value, index, self) => (self.indexOf(value) === index);


  React.useEffect(() => {
    if (inputValue && inputValue.length > 2) {
      setIsSearching(true);
      getPortfolios().then(() => setIsSearching(false));
    }
  }, [inputValue]);

  const getPortfolios = async () => {
    const { data } = await Portfolio.includes(['entity', 'client'])
      .where({ search: { fuzzy_match: inputValue } })
      .select(['id', 'reference'])
      .per(10)
      .all();

    const clients = data.map((portfolio) => portfolio.client.name).filter(onlyUnique);
    const entities = data.map((portfolio) => portfolio.entity.name).filter(onlyUnique);

    const clientOptions = clients.map((clientName) => ({
      label: clientName,
      options: portfolioOptions(
        data.filter(
          (portfolio) =>
            portfolio.client.name === clientName &&
            (!selected ||
              !(selected.flatMap(prop('options')) as any)
                .filter((selection) => selection !== undefined)
                .map((option) => option.value.toString())
                .includes(portfolio.id)),
        ),
      ),
    }));

    const entityOptions = entities.map((entityName) => ({
      label: entityName,
      options: portfolioOptions(
        data.filter(
          (portfolio) =>
            portfolio.entity.name === entityName &&
            !clientOptions.map((option) => option.options.includes(portfolio)) &&
            !(selected.flatMap(prop('options')) as IPortfolioOption[])
              .map((option) => option.value.toString())
              .includes(portfolio.id),
        ),
      ),
    }));

    const currentSelection = [...selected];

    setOptions(currentSelection.concat(clientOptions, entityOptions));
  };

  const portfolioIds = (selected.flatMap(prop('options')) as IPortfolioOption[])
    .filter((selection) => selection !== undefined)
    .map((selection) => selection.value)
    .map((id) => {
      if (props.id === id.toString()) {
        return null;
      }

      return <input key={id} type="hidden" name="note[portfolio_ids][]" value={id} />;
    });

  function handleChange(selection){
      setSelected(selection);
  };

  const icons = {
    moveAllLeft: <span className="icon-chevron-double-left" />,
    moveAllRight: <span className="icon-chevron-double-right" />,
    moveLeft: <span className="icon-chevron-single-left" />,
    moveRight: <span className="icon-chevron-single-right" />,
  };

  return (
    <React.Fragment>
      <Search
        handleChange={handleInputChange}
        hasClearText={false}
        isSearching={isSearching}
        placeholder="Start typing to search..."
        modifier="light"
        shiftIconLeft={false}
      />
      <p className="helper-text mar-v-1 mar-l-1">You can search by portfolio reference or client name</p>
      <DualListBox
        filterPlaceholder="Filter your search..."
        options={options}
        selected={selected}
        onChange={handleChange}
        simpleValue={false}
        icons={icons}
      />
      {portfolioIds}
    </React.Fragment>
  );
};

export default PortfolioSelect;
