import * as React from 'react';

import TextareaAutosize from 'react-autosize-textarea';
import { TemplateActionPoint } from 'javascript/models';
import { ActionPoint } from 'javascript/models';

import CompanyContext from 'components/shared/context/CompanyContext';

import eventSetProperty from 'javascript/helpers/eventSetProperty';
import Frow from '../../../../frow/Frow';
import Column from '../../../../frow/Column';

import FileUpload from './FileUpload';
export interface ICommonFieldProps {
  actionPoint: ActionPoint;
}

type TNoteType = 'completeNotes' | 'deferralNotes';

export default function commonFields(props: ICommonFieldProps) {
  const { actionPoint } = props;

  const [actionPointBody, setActionPointBody] = React.useState(actionPoint.body);
  const [selectableOptions, setSelectableOptions] = React.useState([]);

  const companyId = React.useContext(CompanyContext).id;

  const updateBodyStateAndModel = (body) => {
    actionPoint.body = body;
    setActionPointBody(actionPoint.body);
  };

  const handleSelect = (event) => {
    const body = actionPoint.body ? `${actionPoint.body}\r\n\r\n${event.target.value}` : event.target.value;
    updateBodyStateAndModel(body);
  };

  const onBodyChange = (event) => {
    const body = event.target.value;
    updateBodyStateAndModel(body);
  };

  async function getSelectOptions() {
    const { data } = await TemplateActionPoint.where({ company_id: companyId }).per(1000).all();

    const newSelectableOptions = data.map((templateActionPoint) => ({
      label: templateActionPoint.title,
      value: templateActionPoint.body,
    }));

    setSelectableOptions(newSelectableOptions);
  }

  React.useEffect(() => {
    getSelectOptions();
  }, []);

  const selectOptions = selectableOptions.map((option) => (
    <option key={option.label} value={option.value}>
      {option.label}
    </option>
  ));

  const getLastNote = (noteType: TNoteType) => {
    const notes = actionPoint[noteType];
    return notes && notes.length && notes[0]; // notes are ordered in descending order
  };

  const updateNote = (noteType: TNoteType) => {
    const lastNote = getLastNote(noteType);

    return eventSetProperty(lastNote, 'body');
  };

  const updateCompleteNote = (event) => {
    updateNote('completeNotes')(event);
    eventSetProperty(actionPoint, 'completedNote')(event);
  };

  const LatestDeferralNoteSection = () => {
    const note = getLastNote('deferralNotes');

    if (!note) return null;

    let defaultBodyText = note.body;
    try {
      const additionalInfo = JSON.parse(note.additionalInfo);
      defaultBodyText = additionalInfo.originalNote;
    } catch {}

    const storeOriginalNote = () => {
      let additionalInfo = {};

      try {
        additionalInfo = JSON.parse(note.additionalInfo);
      } catch {}

      note.additionalInfo = JSON.stringify({ ...additionalInfo, originalNote: note.body });
    };

    const updateDeferralNote = (event) => {
      updateNote('deferralNotes')(event);
      eventSetProperty(actionPoint, 'deferredNote')(event);
      storeOriginalNote();
    };

    return (
      <>
        <Column>
          <label htmlFor="completed_note" className="form__label">
            Latest Deferral Note
          </label>
          <TextareaAutosize
            defaultValue={defaultBodyText}
            style={{ minHeight: 110, maxHeight: 300 }}
            cols={10}
            id="completed_note"
            onChange={updateDeferralNote}
          />
        </Column>
        <Column>
          <label className="form__label">Deferral Note Attachments</label>
          <FileUpload accessor="attachments" model={note} />
        </Column>
      </>
    );
  };

  const CompleteNoteSection = () => {
    const note = getLastNote('completeNotes');

    if (!(actionPoint.completed && note)) return null;

    return (
      <>
        <Column>
          <label htmlFor="completed_note" className="form__label">
            Completion Note
          </label>
          <TextareaAutosize
            style={{ minHeight: 110, maxHeight: 300 }}
            name="action_point[completed_note]"
            cols={10}
            id="completed_note"
            defaultValue={actionPoint.completedNote}
            onChange={updateCompleteNote}
          />
        </Column>
        <Column>
          <label className="form__label">Completion Note Attachments</label>
          <FileUpload accessor="attachments" model={note} />
        </Column>
      </>
    );
  };

  return (
    <Frow gutterSize={1} columnContext={{ breakpointSize: 'mc', maxColumns: 1, columnSpan: 1 }}>
      <Column>
        <label htmlFor="note_category" className="form__label">
          Type
        </label>
        <div className="form__select-wrapper">
          <select
            name="action_point[action_point_type]"
            id="note_category"
            defaultValue={actionPoint.actionPointType}
            onChange={eventSetProperty(actionPoint, 'actionPointType')}
          >
            <option value="">Please Select</option>
            <option value="administration">Administration</option>
            <option value="suitability">Suitability</option>
            <option value="technical">Technical</option>
          </select>
        </div>
      </Column>

      <Column>
        <label htmlFor="body" className="form__label">
          Action Point
        </label>
        <div className="form__select-wrapper mar-b-2">
          <select id="template_action_point" name="template_action_point" onChange={handleSelect}>
            <option value="">Please Select</option>
            {selectOptions}
          </select>
        </div>
        <TextareaAutosize
          style={{ minHeight: 110, maxHeight: 300 }}
          name="action_point[body]"
          cols={10}
          id="body"
          value={actionPointBody}
          onChange={onBodyChange}
        />
      </Column>

      <LatestDeferralNoteSection />

      <CompleteNoteSection />
    </Frow>
  );
}
