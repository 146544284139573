import * as React from 'react';

import AdminInfo from './information/AdminInfo';
import Client from './information/Client';
import Contacts from './information/Contacts';
import DataChase from './information/DataChase';
import Entity from './information/Entity';
import Manager from './information/Manager';

export default function information() {
  return (
    <React.Fragment>
      <Client />
      <Entity />
      <Contacts />
      <DataChase />
      <Manager />
      <AdminInfo />
    </React.Fragment>
  );
}
