import * as React from 'react';

const geoUrl = require('./worldMap/topojsonWorld.json');

import { ComposableMap, Geographies, Geography, Marker } from 'react-simple-maps';

interface IProps {
  countryPercentage: any;
  continentPercentage: any;
  height?: string;
  width?: string;
}

export default function worldMap(props: IProps) {
  const { countryPercentage, continentPercentage, height, width } = props;
  const hsl = (countryName) => {
    if (countryPercentage.hasOwnProperty(countryName)) {
      const percentage = Math.ceil(countryPercentage[countryName] / 10);
      return `hsla(193, ${20 + 8 * percentage}%, 44%, 1)`;
    }
    return 'hsla(193, 10%, 44%, 1)';
  };

  const markers = [
    { coordinates: [-105, 52], name: 'North America' },
    { coordinates: [10, 50], name: 'Europe' },
    { coordinates: [105, 40], name: 'Asia' },
    { coordinates: [25, 0], name: 'Africa' },
    { coordinates: [-60, -10], name: 'South America' },
    { coordinates: [135, -25], name: 'Oceania' },
  ];

  const formattedMarkers = markers.map(({ name, coordinates }) => {
    let percentage = 0;
    if (continentPercentage.hasOwnProperty(name)) {
      percentage = Math.round(continentPercentage[name]);
    }
    let circleSize = 20;
    let textSize = 12;
    if (percentage > 10) {
      textSize = 5 + percentage / 5;
      circleSize = percentage * 0.75;
    }
    if (percentage > 50) {
      textSize = 15;
      circleSize = 40;
    }
    return (
      <Marker key={name} coordinates={coordinates}>
        <circle r={circleSize} fill="rgba(78, 172, 208, 0.9)" />
        <text textAnchor="middle" y={2} style={{ fontFamily: 'Noto Sans', fill: '#fff' }} fontSize={textSize}>
          {percentage}%
        </text>
      </Marker>
    );
  });

  const unclassifiedPercentage = () => {
    if (continentPercentage.hasOwnProperty('Unclassified') && continentPercentage['Unclassified'] > 0) {
      return (
        <Marker coordinates={[-160, -67]}>
          <text textAnchor="middle" y={20} style={{ fontFamily: 'Noto Sans', fill: '#3d3f4b' }} fontSize={12}>
            *{Math.round(continentPercentage['Unclassified'])}% Unclassified
          </text>
        </Marker>
      );
    }

    return <rect width={0} height={0} />;
  };

  return (
    <div>
      <ComposableMap
        projection="geoMercator"
        projectionConfig={{ scale: 140 }}
        width={900}
        height={500}
        style={{ width, height }}
        data-tip=""
      >
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map((geo) => (
              <Geography
                key={geo.rsmKey}
                geography={geo}
                fill={hsl(geo.properties.NAME)}
                stroke="#EAEAEC"
                strokeOpacity={0.2}
              />
            ))
          }
        </Geographies>
        {formattedMarkers}
        {unclassifiedPercentage()}
      </ComposableMap>
    </div>
  );
}

worldMap.defaultProps = {
  height: 'auto',
  width: '100%',
};
