import * as React from 'react';
import { Bar, BarChart, Cell, XAxis, ResponsiveContainer, YAxis } from 'recharts';
import { lightLime, midYellow, grey90, midGreen, midOrange, darkRed } from 'components/shared/colours';
import useChartFilter from 'components/shared/customHooks/useChartFilter';
import Frow from '../../../frow/Frow';
import stateToggle from './shared/stateToggle';
import { snakeCase } from 'lodash';

interface IProps {
  data: { name: string; value: number; opacity?: string }[];
  filterState: [string[], (filterKey: string) => (newValue: any) => void];
}

const ratingBreakdownBarChart = (props: IProps) => {
  const [selectedElement, setSelectedElement] = props.filterState;
  const [data, setData] = React.useState(props.data);

  const handleSelect = React.useCallback(
    (slice) => {
      stateToggle(setSelectedElement, false)(slice);
    },
    [selectedElement],
  );
  useChartFilter(data, setData, 'name', selectedElement);

  const tickFormatterObj = {
    '5': 'rating-box rating-box--five',
    '4': 'rating-box rating-box--four',
    '1': 'rating-box rating-box--one',
    '3': 'rating-box rating-box--three',
    '2': 'rating-box rating-box--two',
    null: 'no-rating-box',
  };

  const ratingStyle = {
    border: '1px solid',
    cursor: 'pointer',
    fontFamily: 'Rubik',
    fontSize: '1.27rem',
    padding: '7.5px 9.5px 6.5px 10.5px',
    width: '29px',
  };

  const barColours = [midGreen, lightLime, midYellow, midOrange, darkRed, grey90];

  const tickFormatter = (tickFormatterProps) => {
    const { payload, x, y } = tickFormatterProps;
    const rating = payload.value;
    return (
      <foreignObject x={x - 30} y={y - 15.5} width={32} height={32}>
        <Frow
          style={ratingStyle}
          additionalClassNames={`${tickFormatterObj[rating]}`}
          justifyContent="center"
          itemAlignment="center"
          onClick={() => handleSelect(rating)}
        >
          {rating !== 'null' ? rating : ''}
        </Frow>
      </foreignObject>
    );
  };

  const renderLabel = (labelProps) => {
    const { x, y, value, index } = labelProps;

    return (
      <text x={x + 10} y={y + 23} style={{ fill: index === 5 ? 'white' : 'black', fontSize: '1.8rem' }}>
        {value ? value : ''}
      </text>
    );
  };

  const sumOfValues = data.reduce((partialSum, dataObj) => partialSum + dataObj.value, 0);

  return (
    <ResponsiveContainer width="100%" aspect={3.0}>
      <BarChart layout="vertical" data={data} barCategoryGap={2.5}>
        <XAxis type="number" hide domain={[0, sumOfValues]} />
        <YAxis axisLine={false} tickLine={false} dataKey="name" type="category" tick={tickFormatter} />
        <Bar dataKey="value" label={renderLabel} background={{ fill: '#fff', opacity: '0.1' }}>
          {data.map((_, index) => (
            <Cell key={`cell-${index}`} fill={barColours[index]} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default ratingBreakdownBarChart;
