/* eslint-disable react/display-name */
// The above eslint-disable disables warnings which occur due
// to sections of code like the one below:
// Cell: (row) => (...)

import * as React from 'react';
import * as ReactTooltip from 'react-tooltip';
import { format, parse, subQuarters } from 'date-fns';
import { startCase } from 'lodash';

import { Entity, Manager } from 'javascript/models';
import { quarterDateRange } from 'components/shared/utilities/dateRange';
import { portalClientPortfolioPath } from 'javascript/application/ts_routes';
import { IRevisedColumn } from 'components/shared/reactTable/utilities/processColumns';

const graphitiColumns = <Data,>(
  clientId,
  currencies,
  clientTypes,
  riskOptions,
  selectedDate,
  externalFilters,
  scope,
): IRevisedColumn<Data>[] => {
  const lastFourQuartersArr = (): Date[] => {
    const dateObj = parse(selectedDate);
    const previousYear = subQuarters(dateObj, 4);
    return quarterDateRange(previousYear, dateObj, false);
  };

  const typeOptions = [
    { display: 'Live', value: 'live' },
    { display: 'Component part', value: 'component' },
    { display: 'Consolidated', value: 'consolidated' },
    { display: 'Combined', value: 'combined' },
  ];

  const ratingOptions = {
    1: 'one',
    2: 'two',
    3: 'three',
    4: 'four',
    5: 'five',
  };

  const valueToOption = (value) => ({ display: value, value });

  const ratingStyle = (ratingValue) => ratingOptions[ratingValue] || '';

  const statBasedOption = (type, recordClass) => async () => {
    const result = await scope.selectExtra([]).stats({ wholeDataset: type }).per(0).all();

    return result.meta.stats.whole_dataset[type].data
      .map((option) => {
        const record = new recordClass(option);
        return { display: record.name, value: String(record.id) };
      })
      .sort((compA: { display: string }, compB: { display: string }) => compA.display.localeCompare(compB.display));
  };

  const selectableEntities = React.useCallback(statBasedOption('entities', Entity), [scope]);
  const selectableManagers = React.useCallback(statBasedOption('managers', Manager), [scope]);

  return [
    {
      Cell: (row) => (row.original.name ? `${row.original.reference} - ${row.original.name}` : row.original.reference),
      Header: 'Portfolio',
      accessor: 'reference',
      columnName: 'Portfolio',
      headerClassName: 'hide-md-flex',
      id: 'reference',
      width: 120,
    },
    {
      Cell: (row) => {
        return (
          <div className="frow frow--items-center">
            <img alt="Client type icon" src={row.original.clientTypeIcon} className="mar-r-1 client-type-table-icon" />
            {startCase(row.original.clientType)}
          </div>
        );
      },
      Header: 'Client Type',
      accessor: 'clientType',
      className: 'hide-md-flex',
      columnName: 'Client Type',
      filterOptions: {
        key: 'client_type',
        options: clientTypes.map((type) => ({ display: type, value: type })),
      },
      headerClassName: 'hide-md-flex',
      sortable: true,
      id: 'client_type',
    },
    {
      Cell: (row) => {
        if (row.original.entity) {
          return (
            <div className="frow frow--items-center" data-tip={row.original.entity.name}>
              {row.original.entity.name}
              <ReactTooltip effect="solid" delayShow={300} />
            </div>
          );
        }
        return <div />;
      },
      Header: 'Entity',
      accessor: 'entity',
      className: 'hide-sm-flex',
      columnName: 'Entity',
      filterOptions: {
        key: 'entity_id',
        options: selectableEntities,
        selectedOptions: externalFilters.entity_id,
      },
      eval: 'entity.name',
      headerClassName: 'hide-sm-flex',
      sortable: true,
      id: 'entity_name',
    },
    {
      Cell: (row) => {
        if (row.original.adminSystemReference) {
          return (
            <div className="frow frow--items-center" data-tip={row.original.adminSystemReference}>
              {row.original.adminSystemReference}
              <ReactTooltip effect="solid" delayShow={300} />
            </div>
          );
        }
        return <div />;
      },
      Header: 'Ref#',
      accessor: 'adminSystemReference',
      className: 'hide-sm-flex',
      columnName: 'Ref#',
      headerClassName: 'hide-sm-flex',
      sortable: true,
      id: 'admin_system_reference',
    },
    {
      Cell: (row) => (
        <div className="frow frow--items-center" data-tip={row.original.manager.name}>
          {row.original.manager.name}
          <ReactTooltip effect="solid" delayShow={300} />
        </div>
      ),
      Header: 'Manager',
      accessor: 'managerName',
      className: 'hide-sm-flex',
      columnName: 'Manager',
      filterOptions: {
        key: 'manager_id',
        options: selectableManagers,
        selectedOptions: externalFilters.manager_id,
      },
      eval: 'manager.name',
      headerClassName: 'hide-sm-flex',
      sortable: true,
      id: 'manager_name',
    },
    {
      Cell: (row) => <div>{startCase(row.original.risk)}</div>,
      Header: 'Risk Profile',
      accessor: 'risk',
      className: 'hide-md-flex',
      columnName: 'Risk Profile',
      filterOptions: {
        key: 'risk',
        options: riskOptions.map((option) => ({ display: startCase(option), value: option })),
      },
      headerClassName: 'hide-md-flex',
      id: 'risk',
      sortable: true,
    },
    {
      Cell: (row) => <div>{row.original.currency.toUpperCase()}</div>,
      Header: 'Currency',
      accessor: 'currency',
      className: 'hide-md-flex',
      columnName: 'Currency',
      filterOptions: {
        key: 'currency',
        options: currencies.map((currency) => ({ display: currency.toUpperCase(), value: currency })),
      },
      headerClassName: 'hide-md-flex',
      id: 'currency',
      sortable: true,
      width: 120,
    },
    {
      Header: 'Type',
      accessor: 'portfolioType',
      className: 'hide-md-flex',
      columnName: 'Type',
      filterOptions: {
        key: 'portfolio_type',
        options: typeOptions,
      },
      headerClassName: 'hide-md-flex',
      id: 'portfolio_type',
      sortable: true,
      width: 100,
    },
    {
      Header: `Balance (${format(selectedDate, 'MMM YY')})`,
      accessor: 'balanceAtSelectedDate',
      className: 'hide-md-flex',
      columnName: 'Balance',
      headerClassName: 'hide-md-flex',
      id: 'balance_at_selected_date',
      sortable: true,
    },
    {
      Cell: (row) => {
        const textColor = row.original.enrichedData === 'Yes' ? 'gold' : 'light-grey';
        let tooltip = 'There is no review for this period';
        if (row.original.enrichedData === 'No Review') {
          return <div data-tip={tooltip}>N/A</div>;
        }
        tooltip = 'We do not have holdings level data for this review';
        if (row.original.enrichedData === 'Yes') {
          tooltip = 'We have holdings level data for this review';
        }
        return (
          <div data-tip={tooltip}>
            <i className={`fas fa-2x text-brand-${textColor} fa-medal`} />
          </div>
        );
      },
      Header: 'Data',
      accessor: 'enrichedData',
      className: 'hide-md-flex',
      columnName: 'Data',
      headerClassName: 'hide-md-flex',
      id: 'enriched_data',
      sortable: true,
      width: 70,
    },
    {
      Cell: (row) => {
        const containerStyles = { paddingRight: '5px', width: '31px', display: 'inline-block' };
        const ratings = lastFourQuartersArr().map((date, index) => {
          const formattedDate = format(date, 'YYYY-MM-DD');
          const rating = row.original.historicRatings.filter((ratingObj) => ratingObj.date === formattedDate)[0];

          if (!rating) return <span style={containerStyles} />;

          const roundedRating = Math.round(rating.value);

          if (rating.value === 'N/A') {
            return (
              <span key={index} style={containerStyles}>
                <span className="no-rating-box" style={{ padding: '0.6rem 1.25rem' }} />
              </span>
            );
          }
          return (
            <span key={index} style={containerStyles}>
              <span
                className={`rating-box rating-box--${ratingStyle(roundedRating)}`}
                style={{
                  padding: '0.6rem 0.6rem',
                  fontSize: '0.9rem',
                  border: '1px solid',
                }}
              >
                {rating.value}
              </span>
            </span>
          );
        });
        return <div>{ratings}</div>;
      },
      Header: () => (
        <div>
          Historic Ratings
          <br />
          (Earliest First)
        </div>
      ),
      accessor: 'historicRating',
      columnName: 'Historic Ratings',
      headerClassName: 'hide-md-flex',
      sortable: false,
      width: 150,
    },
    {
      Cell: (row) => {
        const currentRating = () => {
          if (!row.original.rating) return <></>;
          if (row.original.rating === 'N/A') {
            return <span className="no-rating-box" style={{ padding: '0.8rem 1.4rem' }} />;
          }
          return (
            <span
              className={`rating-box rating-box--${ratingStyle(Math.round(row.original.rating))}`}
              style={{ padding: '0.53rem 0.5rem', border: '1px solid' }}
            >
              {row.original.rating}
            </span>
          );
        };

        return <div>{currentRating()}</div>;
      },
      Header: `Rating (${format(selectedDate, 'MMM YY')})`,
      accessor: 'rating',
      columnName: 'Rating',
      filterOptions: {
        key: 'rating',
        options: Object.getOwnPropertyNames(ratingOptions).map(valueToOption),
        selectedOptions: externalFilters.rating,
      },
      headerClassName: 'hide-md-flex',
      id: 'rating',
      sortable: true,
      width: 110,
    },
    {
      Cell: (row) => (
        <a href={portalClientPortfolioPath(clientId, row.original.id)} style={{ display: 'block' }}>
          <div className="rt-tr-clickable-icon">
            <i className="icon-arrow-right-top icon-0-8x" />
          </div>
        </a>
      ),
      Header: '',
      sortable: false,
    },
  ];
};

export default graphitiColumns;
