import * as Color from 'color';

import { camelCase, fill } from 'lodash';
import { mergeAll } from 'ramda';

/*
 * Currently expected to be a hex string of either rgb, rgba.
 */
export type TColourValue = string;

export interface IThemeProperty {
  [key: string]: TColourValue;
}

/*
 * There are a few notes to state about the keys required:
 *  - The `useThemeColours` function/hook requires:
 *    - a `general` property exists and contains an array of `TColourValue`
 *    - a `shared` property exists and contains a `IThemeProperty` this provides the ability to preserve the same colour
 *      for values that may occur in multiple different sections.
 *  - The other keys are dependant on the usage of `useThemeColour`.
 *  */
export type TTheme<ThemeSections> =
  | {
      [key in keyof ThemeSections]: IThemeProperty;
    }
  | {
      general: IThemeProperty;
      shared: IThemeProperty;
    };

const { ceil, log2, floor } = Math;

const modulateColour = (colour: string, phase: number) => {
  const newColour = Color(colour);

  if (phase === 0) return newColour;

  const lighten = phase % 2 === 0;
  const amount = 2 ** -floor(phase / 2 + 0.5);

  const operation = lighten ? 'lighten' : 'darken';

  return newColour[operation](amount);
};

/*
 * This function stetches `value` to a minimum bit size `length`
 *
 * - Find how many copies of `value` would produce enough bits
 * - Join the bits together
 *  - Shift previous value left
 *  - Increment value
 *  - XOR both values
 *
 * To increase entropy we add the index to subseque
 *
 * eslint-disable no-bitwise
 */
const padValue = (length) => (value) => {
  const valueLength = floor(log2(value)) + 1;
  const valueFactor = ceil(length / valueLength);

  const paddedValue = fill(Array(valueFactor), value).reduce((a, b, index) => (a << valueLength) ^ (b + index));
  return paddedValue;
};

/* This function is designed to create a variable sized hash from a string.
 *
 * This creates a simple hash limited by `maxHashValue` it does this by:
 *  - Finding `mask` the closest power of 2 greater than or equal to the length(`maxHashValue`)
 *      of `maxHashValue` for the purpose of finding a suitable value for
 *  the compression function
 *  - Pads and iterates over hash values as `value` in the compression function:
 *    - Takes lowest `log2(n)-1` bits of `value` and xors with the hash
 *    - Shifts any remaining bits down and repeats the last step until none remain
 *  - Taking the hash which is bound to `mask` we use modular arithmetic to
 *      reduce the top bound to be `maxHashValue`
 *
 *  This can roughly be thought of as breaking the input into `mask` bit sized bytes xoring them together
 *  and obtaining the result modulo `maxHashValue`
 *
 * Limitations:
 *  - Distribution - Not every value is guaranteed to be as likely to occur as any other
 *      there are many factors that contribute to this.
 *  - This isn't designed to be and is not suitable as a cryptographic hash.
 *
 */
const XORhash = (maxHashValue) => (values: number[]) => {
  const maskLength = ceil(log2(maxHashValue));
  const mask = 2 ** (maskLength + 1) - 1;

  let result = 0;
  values.map(padValue(maskLength ** 2)).forEach((value) => {
    let preXor = value;
    while (preXor) {
      result ^= preXor & mask;
      preXor >>= maskLength;
    }
  });

  return result % maxHashValue;
};

/* This function takes a string and returns an array on numbers for hashing.
 *
 * - Select the lowest 5 bits from the UTF8 code point (case insensitive alphabetical index)
 */
const prepareUTF8AplhaHashInput = (label: string) =>
  label
    .split('')
    .map((char) => char.charCodeAt(0))
    .map((num) => num & 0x1f);
/* eslint-enable no-bitwise */

/*
 * This function creates a hash function for UTF-8 alpha characters.
 *
 * This function wraps the input to `hashFunction` with `prepareUTF8AplhaHashInput`
 */
const UTF8AlphaHashGen = (hashFunction) => (label: string) => hashFunction(prepareUTF8AplhaHashInput(label));

/* This function's purpose is to serve as a colour generator for a graph.
 *
 * This function performs serveral operations to provide some useful properties:
 *  - Interpret a theme object, providing the functionality required for the `general` & `shared` properties
 *     mentioned in @see TTheme
 *  - Prevent the same colour appearing twice on the same graph (this isn't guaranteed but is unlikely to occur unless
 *     deliberately chosen theme colours are selected.)
 *  - Make a best effort attempt to provide consistent colours for unknown labels that use the same theme.
 */
export const useThemeColours = <Theme extends TTheme<any>>(sectionType: keyof Theme | 'all' = 'all', theme: Theme) => {
  const chartColourCache = {};

  const selectedSections = sectionType === 'all' ? Object.values(theme) : [theme[sectionType]];
  const localThemeColours = mergeAll([theme.shared, ...selectedSections]);
  const generalThemeColours = Object.values(theme.general);

  const totalColours = generalThemeColours.length;
  const labelColourHash = UTF8AlphaHashGen(XORhash(totalColours));

  /*
   * This is the colour generator which should be invoked with the name of the entity a colour is required for
   *
   * This function also performs caching of the used colour values and modulates the output colours accordingly.
   */
  return (label: string): string => {
    const localName = camelCase(label);

    const selectedColour = localThemeColours[localName] || generalThemeColours[labelColourHash(localName)];

    chartColourCache[selectedColour] = (chartColourCache[selectedColour] || 0) + 1;

    return modulateColour(selectedColour, chartColourCache[selectedColour] - 1).hex();
  };
};

/*
 * A function which returns a useThemeColours hook for a particular theme.
 */
export const useThemeFactory = <Theme extends TTheme<any>>(theme: Theme) => (sectionType: keyof Theme | 'all') =>
  useThemeColours(sectionType, theme);
