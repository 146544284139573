// /* eslint-disable  */
import * as React from 'react';

import lodashStartcase from 'lodash.startcase';

import { prop, pipe, map } from 'ramda';

import { Note, Portfolio } from 'javascript/models';
import { Alert, Intent, Menu, MenuItem, Popover, Position } from '@blueprintjs/core';
import ReactSelect from 'components/shared/forms/ReactSelect';

import Search from 'components/shared/Search';
import NoteCards from 'components/shared/NoteCards';
import Context from '../../Context';
import { createAlert } from 'components/shared/Utils';
import useVisibility from 'components/shared/customHooks/useVisibility';
import Edit from './notes/Edit';
import NewNote from './notes/NewNote';

const Notes = () => {
  const context = React.useContext(Context);
  const { currentUser, portfolio } = context.props;

  // Used for the filters
  const [categories, setCategories] = React.useState();
  const [tags, setTags] = React.useState();
  const [types, setTypes] = React.useState();

  const [selectedFilters, setSelectedFilters] = React.useState({});

  const [expanded, setExpanded] = React.useState(false);
  const [editingNote, setEditingNote] = React.useState();
  const [noteForDeletion, setNoteForDeletion] = React.useState();
  const [isLoading, setIsLoading] = React.useState(true);
  const [notes, setNotes] = React.useState([]);
  const [isDeletingNotes, setIsDeletingNotes] = React.useState(false);
  const [isSearching, setIsSearching] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState();
  const [noteCount, setNoteCount] = React.useState(20);
  const [totalCount, setTotalCount] = React.useState(0);

  const { isOpen, handleClose, handleOpen, setIsOpen } = useVisibility(false);

  const handleSearchChange = React.useCallback((searchValue) => {
    setSearchTerm(searchValue);
  }, []);

  function handleSuccessfulFormSubmission() {
    getNotes();
  }

  React.useEffect(() => {
    getNotes().then(() => setIsLoading(false));
  }, [searchTerm, selectedFilters, noteCount]);

  const getNotes = async () => {
    const { data: portfolioData } = await Portfolio.select({
      portfolios: [''],
      managers: [''],
      clients: [''],
      notes: ['id'],
    })
      .includes([{ client: 'notes', manager: 'notes' }, 'notes'])
      .find(portfolio.id);

    const takeNoteIds = pipe(prop('notes'), map(prop('id')));

    const noteIds = [portfolioData, portfolioData.manager, portfolioData.client].flatMap(takeNoteIds);

    await populateNotes(noteIds);
  };

  const populateNotes = async (noteIds) => {
    if (!noteIds.length) return;

    const { data, meta } = await Note.order({ created_at: 'desc' })
      .includes(['author', 'portfolios'])
      .selectExtra(['notable_type', 'tag_list'])
      .where({ id: noteIds })
      .where({ search: searchTerm })
      .where(selectedFilters)
      .stats({ list: ['all_tags', 'all_categories', 'all_types'], total: 'count' })
      .per(noteCount)
      .all();

    const tagOptions = meta.stats.list.all_tags.map((tag) => ({ value: tag, label: tag }));
    const categoryOptions = meta.stats.list.all_categories.map((category: string) => ({
      label: lodashStartcase(category),
      value: category,
    }));
    const typeOptions = meta.stats.list.all_types.map((type) => ({ value: type, label: lodashStartcase(type) }));

    setTotalCount(meta.stats.total.count);
    setCategories(categoryOptions);
    setTags(tagOptions);
    setTypes(typeOptions);
    setNotes(data);
  };

  const handleEdit = (note) => {
    setEditingNote(note);
    handleOpen();
  };

  const handleDelete = (note) => {
    setNoteForDeletion(note);
    setIsDeletingNotes(true);
  };

  const deleteNote = async (note) => {
    try {
      await note.destroy();
      const newListOfNotes = notes.filter((item) => item !== noteForDeletion);
      setNotes(newListOfNotes);
      createAlert('success', 'Note successfully deleted', 1500);
    } catch (error) {
      createAlert('error', 'Error deleting notes. Please try again', 1500);
    } finally {
      handleCloseOfDeleteConfirmation();
    }
  };

  const handleCloseOfDeleteConfirmation = () => {
    setIsDeletingNotes(false);
  };

  const renderEdit = () => {
    if (!editingNote) return;

    return <Edit {...{ isOpen, handleClose, handleSuccessfulFormSubmission, tags }} note={editingNote} />;
  };

  function handleExpandClick() {
    setExpanded(!expanded);
  }

  const dropdown = (note: Note) => {
    if (!note.editable) return;

    const editClick = () => handleEdit(note);
    const deleteClick = () => handleDelete(note);

    return (
      <div className="col-sm-1-12 text-right">
        <Popover usePortal={false} position={Position.BOTTOM}>
          <div className="mar-r-1">
            <i className="icon-overflow-horizontal-white icon text-white" />
          </div>
          <Menu>
            {<MenuItem text="Edit" onClick={editClick} />}
            {<MenuItem text="Delete" intent={Intent.DANGER} onClick={deleteClick} />}
          </Menu>
        </Popover>
      </div>
    );
  };

  function handleMoreClick() {
    setNoteCount(noteCount + 20);
  }

  function handleFilterChange(selectedOption) {
    const newFilters = { ...selectedFilters };
    newFilters[this] = selectedOption.map((option) => option.value);
    setSelectedFilters(newFilters);
  }

  const forSelectOptions = [
    { value: 'Portfolio', label: 'Portfolio' },
    { value: 'Client', label: 'Client' },
    { value: 'Manager', label: 'Manager' },
  ];

  const renderMore = (
    <button className="button button--secondary button--compact mar-t-1" onClick={handleMoreClick}>
      Load More
    </button>
  );

  return (
    <div className="platform-panel__inner">
      <div className="platform-panel__inner platform-panel__inner--top-ruled pad-h-0">
        <div className="frow frow--items-center frow--justify-between mar-b-2">
          <h2 className="heading-two">Notes</h2>
          <NewNote
            currentUser={currentUser}
            handleSuccessfulFormSubmission={handleSuccessfulFormSubmission}
            tags={tags}
          />
        </div>
        <div className="frow frow--gutters frow--items-center frow--justify-between mar-b-2">
          <div>
            <Search
              handleChange={handleSearchChange}
              isSearching={isSearching}
              placeholder="Search notes"
              width="280px"
            />
          </div>
          <div>
            <button className="button button--secondary button--compact" onClick={handleExpandClick}>
              {expanded ? 'Collapse All' : 'Expand All'}
            </button>
          </div>
        </div>
        <div className="frow frow--gutters frow--items-center frow--justify-between mar-b-2">
          <div>
            <div className="frow frow--gutters frow--items-center">
              <div>
                <p className="subtitle-two mar-v-0">Tags</p>
              </div>
              <div style={{ width: '200px' }}>
                <ReactSelect
                  name="tag_filter"
                  id="tag_filter"
                  theme="dark"
                  options={tags}
                  handleChange={handleFilterChange.bind('tags')}
                  isMulti
                />
              </div>
              <div>
                <p className="subtitle-two mar-v-0">For</p>
              </div>
              <div style={{ width: '200px' }}>
                <ReactSelect
                  name="for_filter"
                  id="for_filter"
                  theme="dark"
                  options={forSelectOptions}
                  handleChange={handleFilterChange.bind('for')}
                  isMulti
                />
              </div>
              <div>
                <p className="subtitle-two mar-v-0">Category</p>
              </div>
              <div style={{ width: '200px' }}>
                <ReactSelect
                  name="category_filter"
                  id="category_filter"
                  theme="dark"
                  options={categories}
                  isMulti
                  handleChange={handleFilterChange.bind('note_category')}
                />
              </div>
              <div>
                <p className="subtitle-two mar-v-0">Type</p>
              </div>
              <div style={{ width: '200px' }}>
                <ReactSelect
                  name="type_filter"
                  id="type_filter"
                  theme="dark"
                  options={types}
                  isMulti
                  handleChange={handleFilterChange.bind('note_type')}
                />
              </div>
            </div>
          </div>
        </div>
        <NoteCards {...{ expanded, isLoading, notes, dropdown }} />
        {noteCount < totalCount && renderMore}
        {renderEdit()}
        <Alert
          intent={Intent.DANGER}
          isOpen={isDeletingNotes}
          onCancel={() => handleCloseOfDeleteConfirmation()}
          onConfirm={() => deleteNote(noteForDeletion)}
          cancelButtonText="No"
          confirmButtonText="Yes"
          icon="trash"
        >
          <p>Are you sure you want to delete?</p>
        </Alert>
      </div>
    </div>
  );
};

export default Notes;
