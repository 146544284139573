import * as React from 'react';

import useGraphitiForm from 'components/shared/customHooks/useGraphitiForm';
import { graphitiErrorString } from 'components/shared/Utils';
import CustomModal from 'components/shared/CustomModal';
import CustomAlert from 'components/shared/Alert';
import { Alert, Intent } from '@blueprintjs/core';

import lodashStartcase from 'lodash.startcase';

import Form from './Form';

export default function tile({ clientTile, confirmDelete, handleSuccessfulFormSubmission, resourceTypes }) {
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  function handleConfirmOpen() {
    handleClose();
    setIsConfirmOpen(true);
  }
  function handleConfirmClose() {
    setIsConfirmOpen(false);
  }

  const { resource, handleChange, handleManualChange, handleSubmit } = useGraphitiForm(
    clientTile,
    handleFormSubmission,
  );

  function handleClose() {
    setIsOpen(false);
    setErrorMessage(null);
  }

  function handleOpen() {
    setIsOpen(true);
  }

  async function handleFormSubmission(event) {
    const success = await resource.save();
    if (success) {
      handleClose();
      handleSuccessfulFormSubmission();
    } else {
      setErrorMessage(graphitiErrorString(resource));
    }
    setIsSubmitting(false);
  }

  function handleConfirmDelete() {
    confirmDelete(clientTile);
  }

  function statusColor() {
    switch (clientTile.resourceType) {
      case 'news':
        return 'mid-orange';
      case 'blog':
        return 'dark-aqua';
      case 'general':
        return 'mid-green';
      case 'regulators':
        return 'dark-red';
      case 'professional_bodies':
        return 'yellow';
      case 'index_providers':
        return 'dark-aqua';
      default:
        return 'grey';
    }
  }

  function handleFormSubmit(event) {
    setIsSubmitting(true);
    handleSubmit(event);
  }

  return (
    <div className="col-sm-1-3 col-lg-1-5">
      <div className="card card--clickable card--fixed-height" onClick={handleOpen}>
        <div className="card__inner" style={{ height: '200px' }}>
          <div className="frow frow--nowrap frow--direction-column frow--full-height">
            <div>
              <p className={`status-indicator status-indicator--${statusColor()} mar-v-0 text-white small-uppercase`}>
                {lodashStartcase(clientTile.resourceType)}
              </p>
            </div>
            <div className="" style={{ marginTop: 'auto' }}>
              <p className="card__title">{clientTile.name}</p>
              <p className="card__subtitle">Click to edit</p>
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        isOpen={isOpen}
        title="Edit Client Resource"
        handleClose={handleClose}
        modifiers={['visible-overflow']}
      >
        <div className="modal__content">
          <CustomAlert message={errorMessage} modifier="dark" title="Missing Fields" type="error" />
          <form className="form form--light" onSubmit={handleFormSubmit}>
            <Form {...{ resource, handleChange, handleManualChange, isSubmitting, resourceTypes }} />
          </form>
          <div className="frow frow--gutters">
            <div className="col-md-1-2">
              <button className="button button--full-width button--danger" onClick={handleConfirmOpen}>
                Delete
              </button>
            </div>
            <div className="col-md-1-2">
              <button className="button button--full-width button--outlined-secondary" onClick={handleClose}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </CustomModal>
      <Alert
        isOpen={isConfirmOpen}
        onClose={handleConfirmClose}
        canEscapeKeyCancel
        canOutsideClickCancel
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        intent={Intent.DANGER}
        onCancel={handleConfirmClose}
        onConfirm={handleConfirmDelete}
        portalContainer={document.body}
      >
        <p className="mar-t-0">Are you sure you want to delete this resource?</p>
      </Alert>
    </div>
  );
}
