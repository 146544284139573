import * as React from 'react';

export default function consolidatedPortfolio({ large, showHeaders }) {
  const headers = (
    <thead>
      <tr>
        <th style={{ verticalAlign: 'top', width: '15%' }}>Portfolio</th>
        <th className="text-right" style={{ verticalAlign: 'top', width: '25%' }}>
          Number of Portfolios
        </th>
        <th className="text-right" style={{ verticalAlign: 'top', width: '20%' }}>
          Total Value
        </th>
        <th className="text-right" style={{ verticalAlign: 'top', width: '20%' }}>
          Currency
        </th>
        <th className="text-right" style={{ verticalAlign: 'top', width: '20%' }}>
          Risk Strategy
        </th>
      </tr>
    </thead>
  );

  return (
    <table className={`pdf-table pdf-table--striped ${large && 'pdf-table--large'}`}>
      {showHeaders && headers}
      <tbody>
        <tr>
          <td>Value</td>
          <td className="text-right">Value</td>
          <td className="text-right" style={{ paddingRight: '6px' }}>
            Value
          </td>
          <td className="text-right" style={{ paddingRight: '6px' }}>
            Value
          </td>
          <td className="text-right" style={{ paddingRight: '6px' }}>
            Value
          </td>
        </tr>
      </tbody>
    </table>
  );
}
