import * as React from 'react';

import lodashStartcase from 'lodash.startcase';

export default function divider({ attributes, handleChange, handleManualChange, isRepeating }) {
  const colorOptions = ['default', 'primary', 'secondary', 'tertiary'].map((colorOption) => {
    return (
      <div key={colorOption} className="pretty pretty--large pretty--with-label p-round">
        <input
          type="radio"
          value={colorOption}
          id="column_type"
          name="color"
          onChange={handleChange}
          defaultChecked={attributes.color === colorOption}
        />
        <div className="state p-blue-circle">
          <label>{lodashStartcase(colorOption)}</label>
        </div>
      </div>
    );
  });

  return (
    <div className="frow frow--gutters">
      <div className="col-mc-1-1">
        <label className="form__label" htmlFor="">
          Width
        </label>
        <input type="number" name="width" onChange={handleChange} defaultValue={attributes.width} />
      </div>
      <div className="col-mc-1-1">
        <label className="form__label" htmlFor="">
          Color
        </label>
        {colorOptions}
      </div>
    </div>
  );
}
