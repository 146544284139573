import * as React from 'react';

import ReviewContext from './ReviewContext';

export default function warnings() {
  const context = React.useContext(ReviewContext);
  const { review } = context.props;
  const { warnings } = review.attributes.metrics;

  const formattedWarnings = warnings.map((warning) => {
    return (
      <li key={warning} className="list__item">
        {warning}
      </li>
    );
  });

  return (
    <div className="alert alert--warning mar-t-2">
      <p className="alert__message">This review has the following warnings:</p>
      <ul className="list">{formattedWarnings}</ul>
    </div>
  );
}
