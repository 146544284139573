import * as React from 'react';
import * as $ from 'jquery';

import { platformPortfolioViewedPortfoliosPath } from 'javascript/application/ts_routes';

import Presenter from './details/Presenter';
import CsrfToken from 'components/shared/CsrfToken';

interface IProps {
  viewablePortfolios: { id: string }[];
  portfolio: any;
  terminationReasons: string[];
}

export default function details(props: IProps) {
  const { terminationReasons } = props;
  const portfolio = props.portfolio.data;

  const [viewablePortfolios, setViewablePortfolios] = React.useState(props.viewablePortfolios);

  function handleDeleteTab(id: string) {
    const updatedPortfolios = viewablePortfolios.filter((data) => data.id !== id);

    updateViewedPortfolios(id);
    setViewablePortfolios(updatedPortfolios);
  }

  function updateViewedPortfolios(id) {
    $.ajax({
      data: { authenticity_token: CsrfToken() },
      dataType: 'json',
      type: 'DELETE',
      url: platformPortfolioViewedPortfoliosPath(id),
    });
  }

  return <Presenter {...{ handleDeleteTab, portfolio, viewablePortfolios, terminationReasons }} />;
}
