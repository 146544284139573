import * as React from 'react';

import Allocations from './portfolioData/Allocations';
import Balances from './portfolioData/Balances';
import PortfolioSectionSelect from './PortfolioSectionSelect';
import Reviews from './portfolioData/Reviews';

import Context from './Context';

export default function portfolioData() {
  const [tab, setTab] = React.useState('review');

  const context = React.useContext(Context);
  const { defaultReviewId } = context.props;
  const [selectedReviewId, setSelectedReviewId] = React.useState(defaultReviewId);

  function handleSectionChange(section: string) {
    setTab(section);
  }

  function renderSection() {
    if (tab === 'Review & Notes') {
      return <Reviews {...{ selectedReviewId, setSelectedReviewId }} />;
    }

    if (tab === 'Allocations') {
      return <Allocations />;
    }

    if (tab === 'Balances') {
      return <Balances />;
    }
  }

  return (
    <div>
      <PortfolioSectionSelect handleSectionChange={handleSectionChange} />
      {renderSection()}
    </div>
  );
}
